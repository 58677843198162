import React from 'react'

import { Input } from 'reactstrap'

import { PrimaryButton } from '../widgets/Button.js'


const ReportIdInput = ({value, on_change, button_text, on_button_click}) => {

  const is_valid_internal_or_external_report_id = value.length >= 8

  function on_key_down(e) {
    if (e.keyCode === 13 && on_button_click) {
      e.preventDefault()
      if (is_valid_internal_or_external_report_id) {
        on_button_click(value)
      }
    }
  }

  return (
    <div className='d-flex'>
      <Input value={value}
             onChange={(e) => on_change(e.target.value)}
             onKeyDown={on_key_down}
             autoFocus={true}
      />
      { button_text && on_button_click &&
        <PrimaryButton
          onClick={() => on_button_click(value)}
          disabled={!is_valid_internal_or_external_report_id}
          className='ml-1'
        >
          {button_text}
        </PrimaryButton>
      }
    </div>
  )
}

export default ReportIdInput
