import React, { useEffect } from 'react'

import PatentsContainer from '../patent_family_list/PatentsContainer.js'
import { ALL_FAMILIES_ID } from '../../model/spec_ids.js'
import { scroll_window_to_top } from '../../utils/viewer_utils.js'

import s from './ReportFamiliesView.module.scss'

const ReportFamiliesView = (
  {
    base_path,
    subset_id,
    selections,
    minimal_selections,
    internal_report_id,
    report_title,
    data_creation_date,
    report_input,
    report_has_scores,
    ref_data,
    on_back_to_report,
    user_settings,

    training_set,
    id_to_tsfam,
    id_to_tsfam_pending,
    id_to_tsfam_error,
    phrases_to_highlight,
    no_highlighting,
    eval_training_set_id,
    set_label,
    eval_classifier_data,

    is_family_tagging_mode_on,
    family_tagging_search_phrase,
    on_toggle_family_tagging_mode,
    on_update_family_tagging_search_phrase
  }) => {

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  const SPEC =   {
    id: ALL_FAMILIES_ID,
    name: 'Report families list',
    get_query: () => {
      return {
        "distinct": true,
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    }
  }

  return (
    <PatentsContainer
      base_path={base_path}
      is_clickthrough={false}
      subset_id={subset_id}
      spec={SPEC}
      on_close={on_back_to_report}
      on_next_item={() => {}}
      on_prev_item={() => {}}
      selections={selections}
      minimal_selections={minimal_selections}
      internal_report_id={internal_report_id}
      report_title={report_title}
      data_creation_date={data_creation_date}
      report_input={report_input}
      report_has_scores={report_has_scores}
      controls_row_className={s.controls_row} // for setting sticky top
      table_header_className={s.table_header} // for setting sticky top
      family_details_controls_row_className={s.family_details_controls_row} // for setting sticky top
      ref_data={ref_data}
      user_settings={user_settings || {}}
      // Eval classifier stuff
      training_set={training_set}
      id_to_tsfam={id_to_tsfam}
      id_to_tsfam_pending={id_to_tsfam_pending}
      id_to_tsfam_error={id_to_tsfam_error}
      phrases_to_highlight={phrases_to_highlight}
      no_highlighting={no_highlighting}
      eval_training_set_id={eval_training_set_id}
      set_label={set_label}
      eval_classifier_data={eval_classifier_data}

      is_family_tagging_mode_on={is_family_tagging_mode_on}
      family_tagging_search_phrase={family_tagging_search_phrase}
      on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
      on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}
    />
  )
}

export default ReportFamiliesView