import React from 'react'
import { DropdownItem, Label } from 'reactstrap'
import cn from 'classnames'

import BaseDropdown from '../widgets/BaseDropdown.js'
import { COUNT_OPTIONS, EXPERIMENTAL_COUNT_OPTIONS } from '../../utils/knn_search.js'
import { withUser } from '../UserContext.js'
import { is_cipher_engineering } from '../../utils/user_permissions.js'
import { format_integer_with_comma } from '../../utils/utils.js'

import s from './ResultsCountSelector.module.scss'

const ResultsCountSelector = ({user, selected, on_select, disabled, className, btnClassName}) => {
  const options = [...COUNT_OPTIONS, ...is_cipher_engineering(user) ? EXPERIMENTAL_COUNT_OPTIONS : []]

  return (
    <div className={className}>
      <Label className='my-auto mr-1'>Number of results:</Label>
      <BaseDropdown
        label={format_integer_with_comma(selected)}
        className='my-auto'
        buttonClassName={cn(s.options, btnClassName)}

        disabled={disabled}
      >
        {options.map(item => {
          return (
            <DropdownItem key={item} disabled={item === selected} onClick={() => on_select(item)} className='d-flex justify-content-end'>{format_integer_with_comma(item)}</DropdownItem>
          )
        })}
      </BaseDropdown>
    </div>
  )
}

export default withUser(ResultsCountSelector)