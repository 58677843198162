import React from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../../widgets/Button.js'

import s from './ProgressPrompt.module.scss'

const ProgressPrompt = ({is_wizard_final_step, can_wizard_continue, on_step_complete, children}) => {

  const button_label = is_wizard_final_step ? 'Create report' : 'Next'

  return (
    <div className='d-sm-flex'>
      <div className='my-auto'>
        {children}
        {' '}
        <span>When you’re done with selections, click ‘{button_label}’ {is_wizard_final_step ? '' : 'to continue'}</span>
      </div>
      <PrimaryButton className={cn('ml-0 ml-sm-2', s.btn)} onClick={on_step_complete} disabled={!can_wizard_continue}>{button_label}</PrimaryButton>
    </div>
  )
}

export default ProgressPrompt