import React from 'react'
import cn from 'classnames'

import { PaneHeader } from '../widgets/PaneHeader.js'
import { format_dispute_party_name } from '../../utils/disputes_utils.js'
import { format_dispute_status } from '../../utils/utils.js'

import s from './PartyPanel.module.scss'

export const PartyPanel = ({party}) => {
  const {name, role, outcome, end_date, is_npe = ''} = party

  return (
    <div className={cn('p-3', s.block)}>
      <div className='font-weight-bold'>{format_dispute_party_name(name, role)}</div>
      <div className='d-flex justify-content-between'>
        <div>
          <PaneHeader text='Status'/>
          <div>{format_dispute_status(outcome)}</div>
        </div>
        <div>
          <PaneHeader text='End date'/>
          <div>{end_date || '-'}</div>
        </div>
        <div>
          <PaneHeader text='Is NPE?'/>
          <div>{is_npe.toString()}</div>
        </div>
      </div>
    </div>
  )
}