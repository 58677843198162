import React from 'react'
import { withRouter } from 'react-router-dom'

import { PrimaryButton } from '../widgets/Button.js'
import { DashboardSmallTile } from './DashboardTile.js'
import { new_report_from_existing } from '../../utils/report_builder_utils.js'
import { get_new_report_help_url } from '../../utils/help_utils.js'
import { TileHelpLink } from './TileHelpLink.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { CREATE_REPORT } from '../../constants/paths.js'
import TileTitle from './TileTitle.js'

const NewReportPanel = ({user_has_classifiers, history})  => {

  function on_click_from_custom_new_report_btn() {
    track_report_builder_event('action="select_builder" type="normal" context="dashboard"')
    new_report_from_existing(null, history)
  }

  function on_click_from_wizard_new_report_btn() {
    track_report_builder_event('action="select_builder" type="wizard" context="dashboard"')
    history.push(CREATE_REPORT)
  }

  return (
    <DashboardSmallTile title={<TileTitle title='New report'/>} hint={<TileHelpLink help_url={get_new_report_help_url()} />}>
      <DashboardTileInner>
        <DashboardTileDesc>
          {user_has_classifiers &&
            'Create a new classifier or clustered report of selected organisations, custom portfolios and/or patent upload'
          }
          {!user_has_classifiers &&
            'Create a new report of selected organisations, custom portfolios and/or patent upload'
          }
        </DashboardTileDesc>
        <div className='w-100 mt-3 mt-md-auto'>
          <div className='d-sm-flex justify-content-between'>
            <PrimaryButton className='w-100 w-sm-50 mr-sm-1' onClick={on_click_from_wizard_new_report_btn} title='Report wizard'>
              Report wizard
            </PrimaryButton>
            <PrimaryButton className='w-100 w-sm-50 ml-sm-1 mt-2 mt-sm-0' onClick={on_click_from_custom_new_report_btn} title='Custom report builder' >
              Custom builder
            </PrimaryButton>
          </div>
        </div>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(NewReportPanel)