import React from 'react'

import ReportInputParams from './ReportInputParams.js'
import ProgressPrompt from './ProgressPrompt.js'

const ReportInputParamsStep = (
  {
    selected_stream_id,
    is_multi_class,
    is_tech_landscape,

    multi_label,
    negatives_processing,
    group_by_owner_level,
    portfolio_roll_up_limit,

    on_change_multi_label_handler,
    on_change_negatives_processing_handler,
    on_change_group_by_owner_level,
    on_change_portfolio_roll_up_limit,

    on_complete,
    className
  }) => {
  return (
    <div className={className}>

      <ProgressPrompt
        is_wizard_final_step={true}
        can_wizard_continue={true}
        on_step_complete={on_complete}
      >
        <span>Finalise your options below then click ‘Create report’</span>
      </ProgressPrompt>

      <ReportInputParams
        selected_stream_id={selected_stream_id}
        is_multi_class={is_multi_class}
        is_tech_landscape={is_tech_landscape}

        multi_label={multi_label}
        negatives_processing={negatives_processing}
        group_by_owner_level={group_by_owner_level}
        portfolio_roll_up_limit={portfolio_roll_up_limit}

        on_change_multi_label_handler={on_change_multi_label_handler}
        on_change_negatives_processing_handler={on_change_negatives_processing_handler}
        on_change_group_by_owner_level={on_change_group_by_owner_level}
        on_change_portfolio_roll_up_limit={on_change_portfolio_roll_up_limit}

        className='mt-3'
      />

    </div>
  )
}

export default ReportInputParamsStep