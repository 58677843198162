import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { InfoPopover } from '../../widgets/Tooltip.js'
import ScrollableList from '../../widgets/ScrollableList.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import DropdownChevron from '../../widgets/DropdownChevron.js'
import { add_or_remove_item_from_array } from '../../../utils/utils.js'

import cs from '../../cipher_styles.module.scss'
import s from './ColumnFilter.module.scss'

export const ColumnFilter = ({label, values, selected_value_ids, on_change_selection, className}) => {

  const is_all_selected = (values || []).every(item => _.contains(selected_value_ids, item.id))
  const selected_items = values.filter(item => selected_value_ids.indexOf(item.id) > -1)
  const button_label = is_all_selected ? 'All' : selected_items.map(item => item.name).join(', ')

  function on_toggle_item_id(item_id) {
    on_change_selection(add_or_remove_item_from_array(selected_value_ids, item_id))
  }

  return (
    <div className={cn('d-flex align-items-center', className)}>
      <span>{label}</span>

      <InfoPopover
        interactive={true}
        arrow={false}
        placement='bottom-start'
        toggler={(<span className={cn('d-flex px-2 py-1 ml-1', s.menu_toggle)} title={button_label}>{button_label}<DropdownChevron className='ml-1' /></span>)}
        className={cn('p-1', s.filter_menu)}
      >
        <ScrollableList>
          {(values|| []).map((item, idx) =>
            <CheckboxAndLabel
              key={idx}
              is_checked={_.contains(selected_value_ids, item.id)}
              label={item.name}
              on_click={() => on_toggle_item_id(item.id)}
              className={cn(cs.font_size_small, s.item)}
            />
          )}
        </ScrollableList>

      </InfoPopover>
    </div>
  )
}