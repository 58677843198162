import React from 'react'
import cn from 'classnames'

import s from './ColourFinder.module.scss'

const ColourSquare = ({className}) => {
  return (<div className={cn(s.square, className)}></div>)
}

const ColourFinder = () => {
  return (
    <div>

      <table>
        <tbody>
        <tr>
          <td colSpan="2" style={{width:'5rem'}}>Colour</td>
          <td style={{width:'30rem'}}>Usage</td>
          <td>Note</td>
        </tr>
        <tr>
          <td><ColourSquare className={s.blue_steel} /></td>
          <td>Blue Steel</td>
          <td>$block_bg_dominant_4, $heading_2</td>
          <td>not used in C3.1</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.nile_blue} /></td>
          <td>Nile Blue</td>
          <td>$block_bg_dominant_2, $block_bg_dominant_3, $heading_1, $icon_color_3</td>
          <td>Cipher primary brand color</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.nile_blue_80} /></td>
          <td>Nile Blue 80</td>
          <td>$task_active_completed, $sidebar_link_disabled</td>
          <td>Cipher primary brand color</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.flint_grey} /></td>
          <td>Flint grey</td>
          <td>$button_secondary_darker</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.blacker_dread} /></td>
          <td>Blacker Dread</td>
          <td>$black_text_1</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.dove_gray} /></td>
          <td>Dove Gray</td>
          <td>$block_bg_0, $grey_text_2</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.dusty_grey} /></td>
          <td>Dusty Grey</td>
          <td>$grey_text_3</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.grey_chateau} /></td>
          <td>Grey Chateau</td>
          <td>$block_bg_1, $action_inactive, $button_secondary, $task_active_running, $tag_shape</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.iron} /></td>
          <td>Iron</td>
          <td>$block_bg_2, $grey_text_4</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.ironish} /></td>
          <td>Ironish</td>
          <td>$block_bg_3, $grey_text_5, $grey_fine_line</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.porcelain} /></td>
          <td>Porcelain</td>
          <td>$block_bg_4, $pale_header_link, $task_waiting, $progress_bar_remaining</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.haze} /></td>
          <td>Haze</td>
          <td>$block_bg_5</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.mist} /></td>
          <td>Mist</td>
          <td>$block_bg_6</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.alizarin_crimson} /></td>
          <td>Alizarin Crimson</td>
          <td>$action, $icon_color_2, $button_primary, $negative_label</td>
          <td>Cipher primary brand colour</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.mexican_red} /></td>
          <td>Mexican Red</td>
          <td>$action_selected_1</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.envy_green} /></td>
          <td>Envy Green</td>
          <td>$positive_label</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.peach} /></td>
          <td>Peach</td>
          <td>$cu_warning</td>
          <td></td>
        </tr>

        <tr>
          <td><ColourSquare className={s.bright_turquoise} /></td>
          <td>Bright Turquoise</td>
          <td>$org_bg</td>
          <td>Cipher secondary brand colour</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.tonys_pink} /></td>
          <td>Tonys Pink</td>
          <td></td>
          <td>Cipher secondary brand colour</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.tonys_pink_40} /></td>
          <td>Tonys Pink 40</td>
          <td>$filter_highlight</td>
          <td>Cipher secondary brand colour</td>
        </tr>

        <tr><td colSpan="4" className='text-center'>Chart groups</td></tr>

        <tr>
          <td><ColourSquare className={s.aqua_island} /></td>
          <td>Aqua Island</td>
          <td></td>
          <td>Portfolio size</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.gray} /></td>
          <td>Gray</td>
          <td></td>
          <td>Portfolio activity</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.pine_glade} /></td>
          <td>Pine Glade</td>
          <td></td>
          <td>Geography</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.wheat} /></td>
          <td>Wheat</td>
          <td></td>
          <td>Age</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.tacao} /></td>
          <td>Tacao</td>
          <td></td>
          <td>Cost</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.petite_orchid} /></td>
          <td>Petite Orchid</td>
          <td></td>
          <td>Disputes</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.pale_leaf} /></td>
          <td>Pale Leaf</td>
          <td></td>
          <td>Score</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.light_wisteria} /></td>
          <td>Light Wisteria</td>
          <td></td>
          <td>Portfolio trends</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.biloba_flower} /></td>
          <td>Biloba Flower</td>
          <td></td>
          <td>Stats</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.portage} /></td>
          <td>Portage</td>
          <td></td>
          <td>PVIX</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.gothic} /></td>
          <td>Gothic</td>
          <td></td>
          <td>Selected</td>
        </tr>

        <tr>
          <td><ColourSquare className={s.cornflower} /></td>
          <td>Cornflower</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>

    </div>
  )
}

export default ColourFinder