import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap'

import {
  AGE_GROUP_ID,
  COST_GROUP_ID,
  GEOGRAPHY_GROUP_ID,
  LITIGATION_GROUP_ID,
  PATENTING_ACTIVITY_GROUP_ID,
  PORTFOLIO_SIZE_GROUP_ID,
  PORTFOLIO_STATS_GROUP_ID,
  PORTFOLIO_TRENDS_GROUP_ID,
  SCORE_GROUP_ID,
  PVIX_SCORE_GROUP_ID,
  SELECTED_GROUP_ID
} from '../../model/spec_groups/spec_group_ids.js'

import { DISPLAY_ALL, DISPLAY_COMPACT, DISPLAY_NAVIGATION } from '../../utils/spec_group_utils.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import DatasetThumbnail, { DatasetShowMore, NoDatasets } from './DatasetThumbnail.js'
import { COMPACT_VIEW_THRESHOLD, get_mutual_items_count } from '../../utils/viewer_utils.js'
import { get_as_map } from '../../utils/utils.js'
import TextLink from '../widgets/TextLink.js'
import { EnlargeIcon, InfoIcon, ShrinkIcon } from '../widgets/IconSet.js'
import RefreshThumbnailsControl from './RefreshThumbnailsControl.js'
import { is_IE11 } from '../../utils/browser_utils.js'
import DatasetGroupQuickNav from './DatasetGroupQuickNav.js'
import { get_help_article_url, on_click_from_help_link } from '../../utils/help_utils.js'
import { get_only_saved_items, SELECTED_ITEMS_SELECTION_ORDER } from '../../utils/main_items_selection_utils.js'
import { SPEC_ID_TO_GROUP } from '../../model/spec_groups.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'

import DragAndDropSortArea from '../dnd/DragAndDropSortArea.js'
import DragAndDropOverlay from '../dnd/DragAndDropOverlay.js'
import { DragAndDropSortableItem } from '../dnd/DragAndDropSortableItem.js'

import s from './DatasetGroup.module.scss'
import dsg from './dataset_groups.module.scss'

const BROWSER_IS_IE_11 = is_IE11()

const SPEC_GROUP_TO_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec,
  [AGE_GROUP_ID]: dsg.age_spec,
  [COST_GROUP_ID]: dsg.cost_spec,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec,
  [SCORE_GROUP_ID]: dsg.score_spec,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec,
  [SELECTED_GROUP_ID]: dsg.selected_spec
}

const SPEC_GROUP_TO_THUMBNAIL_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec_thumbnail,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec_thumbnail,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec_thumbnail,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec_thumbnail,
  [AGE_GROUP_ID]: dsg.age_spec_thumbnail,
  [COST_GROUP_ID]: dsg.cost_spec_thumbnail,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec_thumbnail,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec_thumbnail,
  [SCORE_GROUP_ID]: dsg.score_spec_thumbnail,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec_thumbnail,
  [SELECTED_GROUP_ID]: dsg.selected_spec_thumbnail
}

const SpecCanonicalGroupHeader = ({items, item_idx}) => {
  if (!items || item_idx == null) return null
  const item = items[item_idx] || {}

  if (!item) return null
  const {spec_id} = item || {}
  const {id:group_id, short_name, name} = SPEC_ID_TO_GROUP[spec_id]

  function is_prev_item_from_different_group() {
    if (item_idx === 0) return true

    const {spec_id:prev_spec_id} = items[item_idx-1] || {}
    const {id:prev_group_id}  = SPEC_ID_TO_GROUP[prev_spec_id] || {}

    return prev_group_id !== group_id
  }

  const include_group_label = is_prev_item_from_different_group()

  return (
    <div className={cn({'pl-1': include_group_label})}>
      <div className={cn(s.group_heading, SPEC_GROUP_TO_CLASSNAME[group_id])}>
        { include_group_label &&
          <span className={cn('px-2')}>{short_name || name}</span>
        }
      </div>
    </div>
  )
}

const SORTABLE_AREA_ID = 'sortable_charts'

const DatasetGroup = (
  {
    display_mode,
    show_selected_icon,
    group,
    groups,
    selected_items,
    zoomed_dataset_id,
    is_group_collapsed,
    toggle_group,

    show_all_datasets_refresh,
    enable_all_dataset_refresh,
    all_datasets_refresh_handler,

    ref_data,
    deref_data,
    report_series_sort,
    show_charts_in_thumbnails,
    minimal_selections,
    selections,
    deref_data_for_thumbnails,
    selected_charts_display_order_preference,
    data_creation_date,
    spec_id_to_fetch_obj,
    show_selected_only,

    on_datasets_select,
    on_datasets_deselect,
    on_datasets_reshuffle,
    on_group_zoom,
    on_dataset_zoom,

    className
  }) => {

  const [dnd_active_spec_id, set_dnd_active_spec_id] = useState(null)
  const [dnd_over_spec_id, set_dnd_over_spec_id] = useState(null)
  const [items_to_display, set_items_to_display] = useState([])

  const spec_id_to_selected_items = get_as_map(selected_items || [], 'spec_id')
  const { id: group_id, name, children, help_article } = group
  const is_selected_group = group_id === SELECTED_GROUP_ID

  const selected_items_ids = get_only_saved_items(selected_items).map(item => item.spec_id)
  const group_spec_ids = (children || []).map(item => item.spec_id)

  const is_browser_ie11_in_compact_view = BROWSER_IS_IE_11 && (display_mode === DISPLAY_COMPACT)
  const is_compact_view = (display_mode === DISPLAY_COMPACT) && !is_browser_ie11_in_compact_view
  const is_ribbon_view  = (display_mode === DISPLAY_NAVIGATION) || is_browser_ie11_in_compact_view
  const is_full_view    = (display_mode === DISPLAY_ALL)

  const selected_count = get_mutual_items_count(group_spec_ids, selected_items_ids)
  const items_to_display_by_spec_id = get_as_map(items_to_display, 'spec_id')

  const is_dnd_enabled = is_selected_group && (selected_charts_display_order_preference === SELECTED_ITEMS_SELECTION_ORDER) && !BROWSER_IS_IE_11

  const is_child_count_over_threshold = is_compact_view && (children.length > COMPACT_VIEW_THRESHOLD + 1)

  useEffect(() => {
    const items = (is_compact_view && is_child_count_over_threshold) ? children.slice(0, COMPACT_VIEW_THRESHOLD) : children
    set_items_to_display(items || [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  function handle_chart_sort_change(active_spec_id, over_spec_id) {
    if (active_spec_id !== over_spec_id) {
      track_report_viewer_event(`obj="dataset" action="drag_and_drop"`)

      const ids = (items_to_display || []).map(item => item.spec_id)
      const old_index = ids.indexOf(active_spec_id)
      const new_index = ids.indexOf(over_spec_id)
      const selected_item = items_to_display[old_index]
      const filtered_items = items_to_display.filter(item => item.spec_id !== selected_item.spec_id)
      filtered_items.splice(new_index, 0, selected_item)

      set_items_to_display(filtered_items)
      on_datasets_reshuffle(filtered_items)
    }

    set_dnd_active_spec_id(null)
    set_dnd_over_spec_id(null)
  }

  const help_link = help_article ? get_help_article_url(help_article) : null

  return (
    <div>
      {is_ribbon_view &&
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <div className={cn('px-1 d-flex justify-content-between', SPEC_GROUP_TO_CLASSNAME[group_id], s.group_selector)}>
              {!is_browser_ie11_in_compact_view &&
                <>
                  {groups && (groups.length > 0) &&
                    <UncontrolledDropdown>
                      <SelectDropdownToggleStatic chevron_left className={cn(s.group_selector_btn)}>
                        <span className={cn(s.heading, s.name)}>{name}</span>
                      </SelectDropdownToggleStatic>
                      <DropdownMenu>
                        {groups.map((item, i) => {
                          const {name, children, id} = item
                          const no_children = !children || (children.length === 0)

                          const group_spec_ids = children.map(item => item.spec_id)

                          const selected_spec_ids = group_spec_ids.filter(item => selected_items_ids.indexOf(item) > -1)

                          const spec_id_to_zoom = show_selected_only ? selected_spec_ids[0] : group_spec_ids[0]

                          return (
                            <DropdownItem
                              onClick={() => on_group_zoom(item, spec_id_to_zoom)}
                              disabled={((id === group_id) || no_children)}
                              className={cn('d-flex justify-content-between')}
                              key={i}
                            >
                              <span className='mr-2'>
                                {name}
                              </span>
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }

                  {is_selected_group &&
                    <span className={cn(s.heading, s.name)}>{name}</span>
                  }
                </>
              }
              {is_browser_ie11_in_compact_view && <span className={cn(s.heading, s.name)}>{name}</span>}

              {!is_browser_ie11_in_compact_view &&
                <div className='d-flex'>
                  <RefreshThumbnailsControl
                    show_all_datasets_refresh={show_all_datasets_refresh}
                    enable_all_dataset_refresh={enable_all_dataset_refresh}
                    all_datasets_refresh_handler={all_datasets_refresh_handler}
                    className={'ml-2 my-auto'}
                  />
  
                  {help_link &&
                    <TextLink element='a' target='_blank' className='ml-2 my-auto' href={help_link} onClick={() => on_click_from_help_link(help_link)}><InfoIcon/></TextLink>
                  }
                  
                  <TextLink className={'ml-2 my-auto'} onClick={toggle_group}>
                    {is_group_collapsed && <EnlargeIcon />}
                    {!is_group_collapsed && <ShrinkIcon />}
                  </TextLink>
                </div>
              }
            </div>
          </div>

        </div>
      }

      <div
        className={cn('p-1', SPEC_GROUP_TO_CLASSNAME[group_id], {[s.group_wrapper__compact]: is_compact_view}, {'mb-3': is_browser_ie11_in_compact_view}, className)}
      >
        {is_compact_view &&
          <div className={cn('d-flex justify-content-between',
            {'pb-1': !is_group_collapsed},
            s.heading
          )}>
            <div className={cn('d-flex justify-content-between w-100')}>
              <div className={cn('my-auto w-100', s.name)} onClick={() => on_group_zoom(group, items_to_display[0].spec_id)}>
                {name}
              </div>

              <div className='d-flex'>
                {help_link &&
                  <TextLink element='a' target='_blank' className='mr-2' href={help_link} onClick={() => on_click_from_help_link(help_link)}><InfoIcon/></TextLink>
                }
                <DatasetGroupQuickNav
                  group_id={group_id}
                  items={children}
                  on_click={(spec_id) => on_group_zoom(group, spec_id)}
                  selected_items_ids={selected_items_ids}
                  selected_count={selected_count}
                />
              </div>
            </div>
          </div>
        }

        {!is_group_collapsed &&
          <DragAndDropSortArea
            contsiner_id={SORTABLE_AREA_ID}
            sortable_items={items_to_display}
            on_drag_start={set_dnd_active_spec_id}
            on_drag_over={set_dnd_over_spec_id}
            on_drag_end={handle_chart_sort_change}
          >
            <div
              className={cn(
              'd-flex',
                SORTABLE_AREA_ID,
                s.group,
                {'flex-wrap px-1': is_compact_view || is_full_view},
                {'justify-content-between': is_compact_view},
                {[s.group__compact]: is_compact_view},
                {'justify-content-start': is_full_view},
                {'flex-nowrap p-1 w-100': is_ribbon_view},
                {[s.group__scrollable]: is_ribbon_view}
              )}
              id={SORTABLE_AREA_ID}
            >
              {items_to_display.length === 0 &&
                <NoDatasets
                  message={
                    <span className='d-flex'>
                      No charts to display.
                    </span>
                  }
                  className='m-auto'
                />
              }

              {items_to_display.map((spec, i) => {
                const {spec_id} = spec || {}

                const is_zoomed = (spec_id === zoomed_dataset_id)

                const deref_data_for_display = is_zoomed ? deref_data : (deref_data_for_thumbnails || deref_data)

                return (
                  <DragAndDropSortableItem
                    key={spec_id}
                    id={spec_id}
                    active_id={dnd_active_spec_id}
                    over_id={dnd_over_spec_id}
                    is_enabled={is_dnd_enabled}
                  >

                    {is_selected_group &&
                      <SpecCanonicalGroupHeader
                        items={items_to_display}
                        item_idx={i}
                      />
                    }

                    <DatasetThumbnail
                      key={i}
                      spec={{...spec, ...(spec_id_to_selected_items[spec_id] || {})}}
                      is_selected={selected_items_ids.indexOf(spec_id) > -1}
                      on_dataset_zoom={(spec_id) =>  is_compact_view ? on_group_zoom(group, spec_id) : on_dataset_zoom(group_id, spec_id)}
                      on_dataset_select={(spec_id) => on_datasets_select([spec_id])}
                      on_dataset_deselect={(spec_id) => on_datasets_deselect([spec_id])}
                      fetch_obj={spec_id_to_fetch_obj[spec_id]}
                      is_zoomed={is_zoomed}
                      show_selected_icon={show_selected_icon}

                      ref_data={ref_data}
                      deref_data={deref_data_for_display}
                      report_series_sort={report_series_sort}
                      minimal_selections={minimal_selections}
                      selections={selections}
                      data_creation_date={data_creation_date}

                      show_charts_in_thumbnails={show_charts_in_thumbnails}
                      thumbnail_className={SPEC_GROUP_TO_THUMBNAIL_CLASSNAME[group_id]}
                    />

                  </DragAndDropSortableItem>
                )
              })}

              {is_child_count_over_threshold && items_to_display.length &&
                <DatasetShowMore
                  count={children.length - items_to_display.length}
                  spec={children[COMPACT_VIEW_THRESHOLD]}
                  on_dataset_zoom={(spec_id) => on_group_zoom(group, spec_id)}
                />
              }
            </div>

            {is_dnd_enabled &&
              <DragAndDropOverlay>
                {(dnd_active_spec_id != null) &&

                  <DatasetThumbnail
                    spec={{ ...items_to_display_by_spec_id[dnd_active_spec_id], ...(spec_id_to_selected_items[dnd_active_spec_id] || {}) }}
                    is_selected={false}

                    fetch_obj={spec_id_to_fetch_obj[dnd_active_spec_id]}
                    is_zoomed={true}
                    show_selected_icon={show_selected_icon}

                    ref_data={ref_data}
                    deref_data={deref_data_for_thumbnails || deref_data}
                    report_series_sort={report_series_sort}
                    minimal_selections={minimal_selections}
                    selections={selections}
                    data_creation_date={data_creation_date}

                    show_charts_in_thumbnails={show_charts_in_thumbnails}
                    thumbnail_className={SPEC_GROUP_TO_THUMBNAIL_CLASSNAME[group_id]}
                  />
                }

              </DragAndDropOverlay>
            }
          </DragAndDropSortArea>
        }
      </div>
    </div>
  )
}

export default DatasetGroup