import React from 'react'
import cn from 'classnames'

import { SAMPLE_LIMIT } from '../../model/family_view.js'
import { PatentLink } from '../widgets/PatentLink.js'
import TextLink from '../widgets/TextLink.js'
import { Label } from './FamilyDetailsLabel.js'
import {
  PATENT_LINKS_OPTIONS,
} from '../../model/patent_links.js'
import { TileContent } from './TileContent.js'
import { FAMILY, GRAPH } from '../../constants/paths.js'
import { is_array_non_empty_non_null } from '../../utils/utils.js'

import cs from '../cipher_styles.module.scss'
import s from './FamilyPatentsDisplay.module.scss'

export const FamilyPatentsDisplay = (
  {
    title,
    patent_list=[],
    patent_link_mode_id,
    set_patent_link_mode_id,
    on_show_full_set, // optional
    is_limit,
    show_graph_link,
    show_patent_link_mode_switch,
    cipher_family_id,
    error_fetching
}) => {

  function get_patents_to_display() {
    return is_limit ? patent_list.slice(0, Math.min(patent_list.length, SAMPLE_LIMIT)) : patent_list
  }

  const is_list_empty = !is_array_non_empty_non_null(patent_list)

  const patents =  is_list_empty ? [] : get_patents_to_display()

  // Link mode stuff

  return (
    <div className='block' key={title+cipher_family_id}>
      <Label className='d-flex justify-content-between'>
        {title &&
          <span className='mr-2'>{title}</span>
        }
        {show_graph_link && !is_list_empty &&
          <TextLink
            className={cn(cs.font_size_smaller, 'ml-2 my-auto')}
            element='a'
            target='_blank'
            href={`${FAMILY}/${cipher_family_id}/${GRAPH}`}
          >
            See family tree
          </TextLink>
        }
      </Label>

      { show_patent_link_mode_switch && !is_list_empty &&
        <div className='d-flex my-2'>
          <span className={cs.font_size_smaller}>links as:</span>
          {PATENT_LINKS_OPTIONS.map((item, i) => {
            const {id, label} = item
            const is_selected = (id === patent_link_mode_id)
            return (
              <TextLink key={i} className={cn([cs.font_size_smaller, 'ml-2 my-auto', {[s.option__selected]: is_selected, [cs.pointer_events_disabled]: is_selected}])} onClick={() => set_patent_link_mode_id(id)} >{label}</TextLink>
            )
          })}
        </div>
      }

      {error_fetching &&
        <div>There was an error fetching patent data.</div>
      }

      {is_list_empty &&
        <TileContent>(none)</TileContent>
      }

      {!is_list_empty &&
        <div className='d-flex flex-wrap'>
          {patents.map((patent, i) => (
            <span key={i} className={cn(s.patent_link_item, 'mr-2')} >
              <PatentLink
                patent_number={patent}
                patent_link_mode_id={patent_link_mode_id}
              />
            </span>
          ))}

          {on_show_full_set && (patent_list.length > patents.length) &&
           <span
             className={cn('mr-3')}
           >
             <TextLink
               onClick={on_show_full_set}
               className='font-weight-bold'
             >
               Show full set
             </TextLink>
           </span>
          }
        </div>
      }

      {!on_show_full_set && !is_list_empty && (patent_list.length > patents.length) &&
        <div className=''>...</div>
      }

    </div>
  )
}