import React, { useEffect } from 'react'

import ContainerFixedWidth from '../ContainerFixedWidth.js'
import { track_visit_event } from '../../utils/tracking_utils.js'
import FamilyFetchAndDisplay from './FamilyFetchAndDisplay.js'
import { withUser } from '../UserContext.js'
import { is_user_not_authenticated } from '../../utils/user_permissions.js'

import s from './FamilyView.module.scss'

const FamilyView = ({user, history, match, selected_family}) => {
  useEffect(() => {
    track_visit_event('page="family"')
  }, [])

  const user_not_logged_in = is_user_not_authenticated(user)

  return (
    <ContainerFixedWidth>

      <FamilyFetchAndDisplay
        history={history}
        match={match}
        selected_family={selected_family}
        top_className={s.controls_row}
        allow_public_access={user_not_logged_in}
      />

    </ContainerFixedWidth>
  )
}

export default withUser(FamilyView)
