import React from 'react'
import cn from 'classnames'

import { PAGE_SIZES } from '../../model/patent_family_list_page_sizes.js'
import PageControl from '../PageControl.js'
import PageSizeControl from '../PageSizeControl.js'

export const PaginationControls = ({num_pages, selected_page_size, on_change_page_size, current_page, on_change_current_page, available_page_sizes, className}) => {
  return (
    <div className={cn('d-flex align-content-end', className)}>
      <PageSizeControl
        page_sizes={available_page_sizes || PAGE_SIZES}
        selected_page_size={selected_page_size}
        on_change_page_size={on_change_page_size}
      />
      <PageControl
        className='ml-3'
        current_page={current_page}
        num_pages={num_pages}
        on_change_current_page={on_change_current_page}
      />
    </div>
  )
}