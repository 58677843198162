import React from 'react'

import { FamilyPatentsDisplay } from './FamilyPatentsDisplay.js'
import { Label } from './FamilyDetailsLabel.js'
import Spinner from '../widgets/Spinner.js'
import FamiliesListDisplay from './FamiliesListDisplay.js'

export const FamilyCitationsSample = (
  {
    is_fetching,
    title,
    citations_list,
    patent_link_mode_id,
    set_patent_link_mode_id,
    on_show_full_set,
    error_fetching,
    is_list_of_families,
    allow_public_access,
}) => {
  if (is_fetching) {
    return (
      <div>
        <Label>{title}</Label>
        <Spinner/>
      </div>
    )
  }

  if (is_list_of_families) {
    return (
      <FamiliesListDisplay
        title={title}
        families_list={citations_list}
        is_limit={true}
        error_fetching={error_fetching}
        on_show_full_set={on_show_full_set}
        allow_public_access={allow_public_access}
      />
    )
  }

  return (
    <FamilyPatentsDisplay
      title={title}
      patent_list={citations_list}

      patent_link_mode_id={patent_link_mode_id}
      set_patent_link_mode_id={set_patent_link_mode_id}
      is_limit={true}
      on_show_full_set={on_show_full_set}

      error_fetching={error_fetching}
    />
  )
}