export const RAW_BODY = {
  "name": "18k families upload (2023-02-03)",
  "report_type": "default",
  "portfolios": [
    {
      "type": "patent_families",
      "name": "18k families upload (2023-02-03)",
      "pat_fam_ids": [
        14756071,
        45656587,
        27260814,
        54098329,
        55621082,
        12164025,
        55966265,
        27558200,
        9976577,
        7918063,
        63150972,
        51793953,
        31164682,
        30950106,
        44713979,
        33844388,
        43975236,
        3092903,
        11455368,
        53487414,
        10571838,
        48691413,
        45296337,
        15515565,
        14456050,
        19855571,
        59325880,
        31150908,
        28782256,
        3241914,
        51258699,
        34259475,
        46182554,
        24530347,
        60541064,
        41390501,
        44970342,
        59295425,
        27095487,
        15434541,
        56100379,
        40707084,
        46045130,
        28930099,
        39463011,
        17114792,
        6903533,
        6940430,
        38935496,
        50662005,
        27653467,
        27411114,
        43670561,
        45301746,
        55670592,
        6940430,
        22931794,
        61176804,
        52914827,
        7350040,
        53521653,
        62168273,
        55621103,
        15622961,
        7350040,
        45654965,
        41149938,
        19222552,
        53089061,
        1152405,
        28930099,
        28478285,
        41185215,
        45189202,
        61651249,
        38150936,
        15442122,
        4608280,
        41512948,
        8616096,
        38925457,
        46748217,
        45015604,
        7730544,
        30950106,
        46601826,
        58970612,
        42201323,
        45784635,
        55513920,
        24480249,
        3898748,
        34024692,
        53330125,
        46527117,
        25635896,
        21465010,
        6540700,
        30217323,
        20836761,
        21590592,
        6056653,
        18922385,
        35559554,
        4325027,
        61099912,
        51033745,
        55251534,
        38420197,
        16071317,
        31512017,
        48708293,
        38905167,
        63038289,
        45357258,
        50425296,
        27946297,
        55686271,
        33453152,
        42086344,
        50424909,
        20190425,
        28035924,
        53048660,
        18524649,
        23083374,
        44411812,
        42703997,
        51829022,
        52324506,
        32562213,
        4907708,
        43034960,
        64630506,
        4325027,
        19503656,
        17522987,
        53541825,
        7114035,
        12896467,
        4608119,
        53494082,
        16188861,
        1205813,
        44123102,
        27644405,
        17796620,
        29978439,
        61630889,
        942511,
        15710729,
        19975979,
        17311774,
        32104738,
        3138388,
        31372930,
        48473137,
        32570045,
        27418075,
        6655914,
        27411114,
        24099722,
        46566578,
        12408494,
        63262512,
        43821761,
        23375246,
        2521796,
        18800866,
        44435617,
        41149938,
        38409122,
        16488446,
        41165196,
        45654965,
        12366652,
        27095487,
        48265677,
        18512769,
        15476190,
        44411814,
        53680243,
        41436101,
        55779101,
        24480249,
        24174955,
        60483505,
        8449564,
        20282555,
        19884308,
        3263795,
        23736963,
        38905478,
        27630063,
        46428301,
        4621725,
        49989365,
        54410448,
        61632213,
        7955548,
        22401149,
        16598122,
        18647685,
        27057558,
        42329762,
        12043434,
        3441423,
        44321639,
        41488305,
        19701359,
        42077429,
        26053963,
        6056653,
        24174955,
        15910368,
        55160107,
        31324576,
        43578683,
        11783557,
        13190041,
        45374262,
        38905478,
        34027009,
        28730738,
        61099912,
        2521796,
        56066884,
        36057548,
        47595604,
        17127754,
        40273266,
        12287019,
        13944727,
        63261105,
        8373210,
        1417637,
        11532871,
        52649339,
        20480436,
        51038815,
        24109027,
        6549007,
        46045130,
        37647584,
        27291879,
        8375328,
        12738950,
        44713961,
        60373099,
        55372738,
        45465946,
        40799895,
        45024355,
        47804248,
        942511,
        41021205,
        48888107,
        41961606,
        3138388,
        5391503,
        45301746,
        41228383,
        1417637,
        16598128,
        51258699,
        52102830,
        43664300,
        32059832,
        969841,
        22274604,
        28313244,
        52140941,
        21453555,
        52225633,
        63151881,
        50573819,
        49987452,
        18880797,
        27558634,
        55227572,
        25635903,
        34217085,
        35559554,
        18556907,
        25551336,
        27137685,
        49007995,
        39463011,
        5383390,
        22655476,
        31513925,
        36638521,
        15442131,
        11783690,
        32962575,
        16398317,
        59411767,
        8631544,
        7237819,
        45362117,
        33085075,
        3132840,
        50219309,
        50424909,
        27407624,
        12174942,
        11522024,
        14792112,
        14792121,
        59284800,
        45373232,
        20868404,
        52129497,
        16143938,
        46159628,
        12043434,
        291771,
        27946297,
        34568040,
        25830002,
        31373037,
        20103307,
        8373211,
        46415105,
        7010887,
        11521575,
        3898396,
        51333990,
        53264110,
        7765649,
        17936592,
        10728771,
        3241772,
        44214843,
        32082762,
        12437201,
        22988080,
        21138521,
        30217323,
        4887050,
        53336573,
        28930359,
        16188861,
        24168824,
        30385955,
        42212675,
        11096261,
        1933643,
        2865689,
        11366467,
        34263863,
        10091562,
        9690042,
        42897935,
        12872918,
        11455368,
        55670632,
        31791733,
        54818218,
        53362423,
        30950106,
        18808076,
        45358449,
        6316445,
        52893105,
        51931072,
        31546884,
        25342617,
        52914827,
        23903303,
        19808178,
        36925333,
        62510706,
        45469274,
        37647584,
        28509590,
        12113298,
        28466083,
        16398317,
        19808178,
        12287019,
        5383390,
        21289127,
        30950106,
        7010887,
        49188633,
        46161961,
        21362354,
        21453541,
        31535659,
        35268545,
        21446449,
        42657467,
        50106070,
        5639611,
        33147983,
        27455319,
        52074511,
        59007538,
        44713981,
        10728777,
        24200834,
        28866919,
        54760546,
        18922385,
        52153827,
        63861943,
        20103363,
        51173072,
        51773398,
        6523800,
        19120421,
        41504647,
        62867265,
        19431689,
        39673573,
        16951891,
        15442121,
        22670888,
        26450906,
        7701122,
        29243150,
        19896094,
        11455368,
        12257108,
        44191406,
        17378882,
        48805654,
        28532924,
        31372926,
        49188539,
        17714673,
        41488148,
        43373349,
        31426186,
        31531833,
        54273615,
        64811881,
        60774533,
        23943703,
        63861943,
        45357436,
        3019733,
        10091562,
        7350040,
        56166693,
        53479881,
        52169001,
        55691537,
        24516815,
        50514204,
        42440159,
        55667574,
        45842244,
        43670561,
        62078036,
        32059725,
        54944541,
        8660072,
        23341130,
        18922385,
        32071196,
        21869094,
        3888836,
        22724887,
        7583468,
        61243217,
        483586,
        20836761,
        6535345,
        17969778,
        43907023,
        12430500,
        51333940,
        50790262,
        51287144,
        49387709,
        49040133,
        60231654,
        28930099,
        29913599,
        45181491,
        15211667,
        39317862,
        23954112,
        40596682,
        16162033,
        19430529,
        31372764,
        33147983,
        28452425,
        50428920,
        1417617,
        60481974,
        46664689,
        28313014,
        28842546,
        31150908,
        54105331,
        6958922,
        46888338,
        45071761,
        3266435,
        17904254,
        41934979,
        26160614,
        34027009,
        27537014,
        32978537,
        15896525,
        20836762,
        39165935,
        46566875,
        44432829,
        10716485,
        41149938,
        61531887,
        13842492,
        38409398,
        45364305,
        55513973,
        45374264,
        44411814,
        56367295,
        46046575,
        33453152,
        28357659,
        53023928,
        41359859,
        6548824,
        19884369,
        24530347,
        48954676,
        18524643,
        45293290,
        43034959,
        41149938,
        25945555,
        59325880,
        45357258,
        6167091,
        15904721,
        41228383,
        6903532,
        45599166,
        15248364,
        27630063,
        52692914,
        45358449,
        16586852,
        44928851,
        28850813,
        27420054,
        6980331,
        12993356,
        21621036,
        63964211,
        616767,
        16598099,
        22475493,
        21138521,
        64543436,
        13464162,
        47331232,
        55922565,
        59009083,
        62317890,
        23231378,
        34027009,
        11783557,
        33298196,
        3898504,
        7314845,
        35035962,
        15442133,
        9690054,
        1010817,
        46091234,
        449230,
        11522024,
        30852309,
        54918708,
        11236974,
        483585,
        48385522,
        42369750,
        54210625,
        45117483,
        12451280,
        3266549,
        6986383,
        1197940,
        41165206,
        63077287,
        42178586,
        41480877,
        49188551,
        44822300,
        22903608,
        20549657,
        40543459,
        37729673,
        46542969,
        19167635,
        29539338,
        46045130,
        39536921,
        45189251,
        24950415,
        61612029,
        41149938,
        55165161,
        63262090,
        17122859,
        33595310,
        29197976,
        43034959,
        23341130,
        53697334,
        12366652,
        53134992,
        15904721,
        29197976,
        27653467,
        53093405,
        27638524,
        16113260,
        45500465,
        51333995,
        24200834,
        53046798,
        45117483,
        44214843,
        52022094,
        30380055,
        9690054,
        48807194,
        48809207,
        13209170,
        23253973,
        15083230,
        23375246,
        51083082,
        64500187,
        31546886,
        53215338,
        46160229,
        9202050,
        48202126,
        28927907,
        49188551,
        35261974,
        48277393,
        13464162,
        2545038,
        8373210,
        19724993,
        48186740,
        17543549,
        46694089,
        28842546,
        13133531,
        4506072,
        7895877,
        31505722,
        29750013,
        50155991,
        30950106,
        39178271,
        12638219,
        32919133,
        9202050,
        7730554,
        30394252,
        38905173,
        33453152,
        29266807,
        54911227,
        17309802,
        38935495,
        42212675,
        54389695,
        49353590,
        449206,
        58884818,
        12445146,
        23083374,
        7016948,
        50023247,
        46045130,
        9286325,
        60482069,
        61321140,
        41150326,
        22324026,
        45842244,
        31517705,
        24823562,
        39487961,
        26451491,
        34263863,
        27653463,
        48442922,
        5432164,
        14918064,
        12366652,
        11621804,
        30924553,
        22438159,
        942511,
        45500465,
        42897928,
        22152980,
        16599495,
        62077071,
        50575470,
        15634859,
        23400804,
        55287746,
        6549007,
        52116965,
        52137988,
        35559552,
        37746838,
        48550897,
        18647685,
        45357258,
        62164036,
        51038815,
        62163933,
        3898485,
        13560677,
        46862439,
        3263795,
        30998773,
        35024759,
        5406884,
        48954651,
        4907623,
        36057548,
        33147983,
        60372506,
        55704025,
        18260103,
        31081245,
        8616138,
        1020192,
        3397069,
        54105331,
        62867250,
        27407624,
        43907023,
        14673720,
        4887232,
        11783551,
        2398199,
        34026954,
        19441925,
        18861511,
        46094502,
        39371726,
        38409397,
        52914827,
        45015616,
        2950089,
        20868393,
        13190249,
        48806085,
        15747205,
        3856495,
        3241648,
        60483520,
        18223194,
        40543459,
        54410448,
        22932108,
        15248364,
        17373349,
        55970789,
        28581868,
        48277391,
        22730034,
        23690799,
        32319000,
        47127032,
        30432907,
        41454883,
        9676224,
        40668839,
        50790262,
        60888219,
        48265416,
        28221931,
        55779101,
        61580907,
        41572175,
        12043439,
        49159277,
        24516815,
        5115079,
        30826828,
        4325026,
        25853364,
        56262648,
        4325027,
        24372121,
        44314317,
        41360289,
        9962069,
        40197829,
        48737513,
        15685294,
        62864997,
        31488141,
        33865652,
        59557530,
        41228383,
        21969462,
        29010351,
        4877476,
        61176847,
        8375328,
        3294089,
        19724978,
        27802643,
        41149938,
        41150326,
        40471843,
        49824451,
        24372121,
        48806085,
        6548824,
        7314854,
        31512035,
        7583468,
        31169375,
        45529502,
        46668039,
        14717762,
        53200538,
        45066988,
        1217971,
        27455302,
        28699273,
        59947852,
        742777,
        19884308,
        5377469,
        1651220,
        39178271,
        55732538,
        31546887,
        54105331,
        31512043,
        24250125,
        4523004,
        42657466,
        51083542,
        7010887,
        27601138,
        22175186,
        44505336,
        3138388,
        23375246,
        60541064,
        35779664,
        19142710,
        33503842,
        13794620,
        61962499,
        24200685,
        15910353,
        18861886,
        23793371,
        13477416,
        24530509,
        31324576,
        54785542,
        45375729,
        45357258,
        51931072,
        3241698,
        43532543,
        53023928,
        27793829,
        45363322,
        61458285,
        16496193,
        25438404,
        7730549,
        56171620,
        3898748,
        783678,
        11645310,
        21341572,
        22651649,
        31512018,
        47732529,
        45015587,
        40270883,
        31546854,
        31620680,
        53862911,
        48462323,
        63862590,
        55621082,
        18861512,
        39442397,
        47736568,
        62511868,
        4782303,
        13190249,
        10769914,
        942511,
        47266404,
        17311691,
        45139953,
        48806085,
        12164045,
        48462323,
        45357258,
        32059552,
        45599166,
        59451805,
        19422930,
        15442133,
        39442397,
        33085075,
        30076393,
        28864339,
        20103313,
        28499234,
        62262398,
        978398,
        50155991,
        16598128,
        16853979,
        9690048,
        61580922,
        12717901,
        27653463,
        63359898,
        44820415,
        4325027,
        55939058,
        45761493,
        7314854,
        40197829,
        3856484,
        42703685,
        1004474,
        17869102,
        61320468,
        59666665,
        39401857,
        33147983,
        20868404,
        44191406,
        53093405,
        27736537,
        63861943,
        39505458,
        28357659,
        42201323,
        62077089,
        29197745,
        54105331,
        39175604,
        31657205,
        51174008,
        61099912,
        48548310,
        25303992,
        51903731,
        32319000,
        4325025,
        23083445,
        31517705,
        55691537,
        47266405,
        48734130,
        42664166,
        61612029,
        30386110,
        16188861,
        33167054,
        27653463,
        41446107,
        35779664,
        15211667,
        26867299,
        14088065,
        31373039,
        3132729,
        38935217,
        15442122,
        49763658,
        42897930,
        30986479,
        6944705,
        60887516,
        8631788,
        45212280,
        46681438,
        4086294,
        30328621,
        45041931,
        9286450,
        40239240,
        54657678,
        50574296,
        64542816,
        16599495,
        4608280,
        6540700,
        33266858,
        1585364,
        15296422,
        45358442,
        45357258,
        54589874,
        59279051,
        27291877,
        26980168,
        45462132,
        20549657,
        24541812,
        59505617,
        31192536,
        20844170,
        54814347,
        48963305,
        59939100,
        40762417,
        38905170,
        54112105,
        28466083,
        49679400,
        41536344,
        7187825,
        53043992,
        181652,
        47323602,
        23083445,
        45374271,
        62163370,
        55378821,
        5354834,
        16488446,
        31373040,
        46311477,
        7314845,
        7010887,
        3241655,
        22931036,
        30443079,
        53541825,
        6549007,
        44409505,
        3241698,
        1651243,
        41360289,
        6316444,
        13444640,
        34387863,
        45293290,
        28782306,
        25342617,
        38925457,
        21969462,
        6958922,
        19503656,
        20868393,
        4886900,
        43599170,
        59302762,
        11070087,
        13133531,
        63039042,
        4875083,
        23083258,
        49468181,
        9690054,
        14279810,
        48386715,
        31556304,
        56227575,
        15442133,
        18260103,
        38905168,
        31512068,
        11621816,
        13133531,
        47124840,
        15710729,
        4649257,
        40471937,
        42657465,
        16586852,
        4875083,
        1417637,
        24200660,
        44123025,
        4608119,
        44843087,
        5383388,
        3241674,
        8877011,
        21717249,
        54193613,
        18223194,
        52102830,
        48277247,
        41494364,
        56584588,
        3856484,
        9903202,
        7350040,
        42206220,
        14817961,
        13754119,
        18556884,
        28782306,
        53157411,
        15271487,
        13944727,
        16520408,
        18223194,
        53832723,
        15442120,
        49468181,
        279367,
        24200660,
        45024356,
        10097717,
        16806098,
        46748217,
        3241674,
        49199887,
        14205558,
        44164676,
        27057535,
        5639611,
        5432164,
        17309802,
        3241640,
        7918063,
        9690125,
        31512023,
        24862611,
        54191023,
        52137988,
        28866919,
        3241646,
        42262298,
        11783553,
        49554271,
        14279810,
        19120426,
        1021691,
        48462322,
        47349971,
        9976668,
        24168669,
        30567942,
        37532905,
        22526387,
        49864829,
        51641922,
        33154692,
        24804066,
        8081130,
        16951986,
        42897928,
        42897935,
        13465577,
        24261286,
        30379991,
        46046583,
        4506072,
        54098204,
        42905365,
        1197665,
        28879511,
        49763658,
        51287182,
        46601826,
        16598099,
        46593597,
        15442141,
        2521796,
        18880798,
        39895420,
        48335773,
        16598119,
        3411835,
        20097758,
        17284691,
        35261974,
        19884369,
        49987452,
        51903,
        49826230,
        23231368,
        12445070,
        41228387,
        28602445,
        44843084,
        54757302,
        7730513,
        54012497,
        45364325,
        35420672,
        32562213,
        17424098,
        30950106,
        30826828,
        28732501,
        13697844,
        20843823,
        30567576,
        12408390,
        13089549,
        23375246,
        13586112,
        30758281,
        25635901,
        7350040,
        1750714,
        16188939,
        45374268,
        41360267,
        12174937,
        19120613,
        44973473,
        17543549,
        20190909,
        61243217,
        5464723,
        45357257,
        45905906,
        44123102,
        42535896,
        51642742,
        54931446,
        14756068,
        48265677,
        15442119,
        46311477,
        13623170,
        51259337,
        19120325,
        16520403,
        31372927,
        38118054,
        2545038,
        44839914,
        47266405,
        51333989,
        34295964,
        43771788,
        46448272,
        46449553,
        10780124,
        27601140,
        61097371,
        24099722,
        45374436,
        15083230,
        25945555,
        51287182,
        5464723,
        35163856,
        59666665,
        24884055,
        22765180,
        6548824,
        28557782,
        8204668,
        34038020,
        17309801,
        24101913,
        279367,
        19724962,
        29965020,
        10728783,
        52195634,
        44840244,
        3241669,
        35779664,
        29812367,
        25955048,
        15442114,
        62077692,
        51641975,
        28278767,
        31512020,
        37746838,
        4131118,
        54889651,
        49199887,
        3863165,
        2545038,
        59126908,
        31112348,
        48691413,
        35024759,
        38935495,
        6535345,
        49825206,
        43975236,
        11825871,
        59281993,
        17127754,
        23375367,
        35257029,
        44191406,
        2834615,
        449204,
        31517705,
        45374268,
        6549007,
        45057269,
        17311691,
        61649920,
        783678,
        50142605,
        56043855,
        43599152,
        44711370,
        48531688,
        27638524,
        6548824,
        30394252,
        31479820,
        28795776,
        62656145,
        10212959,
        8240387,
        61653481,
        20844170,
        38150936,
        11783553,
        41180504,
        10780124,
        50155991,
        58885510,
        4095941,
        59831476,
        38069367,
        13560677,
        28782256,
        27407624,
        47845372,
        45905906,
        23341130,
        16520409,
        40512385,
        28842174,
        64630328,
        13190249,
        47103610,
        53215338,
        44123439,
        51827308,
        29266807,
        9690045,
        4188129,
        27736537,
        15942722,
        45357258,
        15622961,
        12627105,
        49987452,
        51258488,
        16972752,
        49764057,
        45294223,
        46887653,
        14088065,
        29750013,
        49679829,
        609721,
        45642454,
        23375246,
        30385955,
        11783547,
        23375367,
        23534322,
        45521077,
        35559552,
        44843090,
        51829022,
        19132357,
        45358449,
        4325027,
        61632674,
        24174955,
        45364288,
        54760385,
        58150,
        54136431,
        17869090,
        56507996,
        6530424,
        13944727,
        4325027,
        25371436,
        44115045,
        43664295,
        7897331,
        27407624,
        31685691,
        10553189,
        25272111,
        60027068,
        28699273,
        45521077,
        45655066,
        16951895,
        51903731,
        26695005,
        29677162,
        31372933,
        61653400,
        12627105,
        20836762,
        56052705,
        18891482,
        18922373,
        13077343,
        23888025,
        16972576,
        49987452,
        942281,
        49554233,
        23337830,
        18524648,
        19132357,
        31112348,
        34295964,
        22670888,
        4717578,
        30443097,
        37746838,
        15211667,
        51903731,
        15444856,
        32104741,
        4703118,
        49864829,
        6143733,
        54676169,
        19002843,
        16063425,
        20190909,
        623219,
        58970612,
        28060474,
        6996136,
        7700927,
        45357219,
        24200660,
        20094198,
        2865461,
        31757295,
        35559552,
        61100498,
        2521796,
        22268401,
        61649841,
        47762999,
        41938136,
        46159628,
        14816203,
        21289127,
        24950412,
        56459489,
        48088591,
        58903254,
        28971168,
        23701480,
        40270884,
        50186998,
        11236974,
        9776999,
        41398605,
        34032086,
        16586852,
        61580771,
        8812280,
        33454846,
        62078041,
        9962069,
        7897459,
        53609300,
        27630027,
        20320940,
        43670562,
        6535345,
        2028092,
        17522987,
        2398199,
        8375328,
        12445146,
        38905472,
        59947852,
        48262052,
        51793953,
        41504583,
        46609407,
        41149938,
        2782317,
        25618599,
        36638521,
        31164778,
        23070354,
        45373232,
        6549007,
        12302733,
        44928851,
        25817137,
        15083230,
        14817961,
        55668871,
        6309394,
        55651536,
        52116965,
        4621725,
        55165161,
        31512068,
        45374463,
        60483505,
        53741024,
        18260129,
        4887047,
        51334053,
        1159830,
        21465010,
        41228384,
        291771,
        5354662,
        17869085,
        18556885,
        30826818,
        41398605,
        18380031,
        9286311,
        62657367,
        19120614,
        3241647,
        41536344,
        38409119,
        23915350,
        30379991,
        44314317,
        50424872,
        52324506,
        41228384,
        42451953,
        28842174,
        44840175,
        35057938,
        30444351,
        7918063,
        17378882,
        23947743,
        44123439,
        44808451,
        6707132,
        15622961,
        59743628,
        1159830,
        33207148,
        55845513,
        29145102,
        59009153,
        31373035,
        63744211,
        38905174,
        23368644,
        53697334,
        61531898,
        60538197,
        6309394,
        50662385,
        18260129,
        53609300,
        54013197,
        32990042,
        3898500,
        47569632,
        23861064,
        26000966,
        45790410,
        13420046,
        31447523,
        8660072,
        21708349,
        45905906,
        30950106,
        47737295,
        46601826,
        43373349,
        45374241,
        25050924,
        22672842,
        48548310,
        53480193,
        6549007,
        59302762,
        51853493,
        27537014,
        18647685,
        60541064,
        20320940,
        63150972,
        34295964,
        24950412,
        14505212,
        30444195,
        41359859,
        30217159,
        53048660,
        45357258,
        15266742,
        22350758,
        47596327,
        53680243,
        45189251,
        27411114,
        45182963,
        52264955,
        18922373,
        52200907,
        44840175,
        50574761,
        14756071,
        23690799,
        23287120,
        17309802,
        42657467,
        56538709,
        31672314,
        24950415,
        7256010,
        6944750,
        16010978,
        52958739,
        62168273,
        15634856,
        12726942,
        44713980,
        32185070,
        52324506,
        9676224,
        28128989,
        50744881,
        16520408,
        33085075,
        52309116,
        23375246,
        43975236,
        20844170,
        20480436,
        20868404,
        1152405,
        7044926,
        51334023,
        23876409,
        51333940,
        60481974,
        31512035,
        7918063,
        60379401,
        30998773,
        9215981,
        27612338,
        16951881,
        13452335,
        64051977,
        53832723,
        24168821,
        44973473,
        7730559,
        12164025,
        17309802,
        41360289,
        43532543,
        21297607,
        6523800,
        38409322,
        12043434,
        22470811,
        25531700,
        44214843,
        32562058,
        45015572,
        2798770,
        21633555,
        40537189,
        45374435,
        28278767,
        12430522,
        55408124,
        30589520,
        42657466,
        45654965,
        12164025,
        34518584,
        45357258,
        12451270,
        40141851,
        59956724,
        62164015,
        4907627,
        19231933,
        29057022,
        17311778,
        7314851,
        42703997,
        29010351,
        1465319,
        27730216,
        54169459,
        6309394,
        16063425,
        13190249,
        16488446,
        60097256,
        31150908,
        44191406,
        8660072,
        15904721,
        38978763,
        34497229,
        28231189,
        53046798,
        16951876,
        16845652,
        31324576,
        6535345,
        12745276,
        50424872,
        41454880,
        2950089,
        19120427,
        12726942,
        7918063,
        35261974,
        26053963,
        44840175,
        46741109,
        25593858,
        46527117,
        17122859,
        43342488,
        46625527,
        54022222,
        62078038,
        41228384,
        41158753,
        6700684,
        40261276,
        29741014,
        55621074,
        17869102,
        29017796,
        30735406,
        42897935,
        10770094,
        44011215,
        44318248,
        21453540,
        45057236,
        39536921,
        16113266,
        46089167,
        24168670,
        49246229,
        38150932,
        59661690,
        45296337,
        49188633,
        18922373,
        36485444,
        34497229,
        12302733,
        49679829,
        10553189,
        44843084,
        44806684,
        23337830,
        45357258,
        55845513,
        42206220,
        14108209,
        12285258,
        44638982,
        39442397,
        27638523,
        31112348,
        28295737,
        27291880,
        25189327,
        53330125,
        4106907,
        20549657,
        47499482,
        51827308,
        31513925,
        25817137,
        13074140,
        54657678,
        291771,
        6549007,
        44840242,
        9805874,
        60541064,
        38409320,
        3283531,
        28290602,
        12738950
      ],
      "group_by_owner": true,
      "meta": {
        "type": "patent_upload",
        "lines": [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44,
          45,
          46,
          47,
          48,
          49,
          50,
          51,
          52,
          53,
          54,
          55,
          56,
          57,
          58,
          59,
          60,
          61,
          62,
          63,
          64,
          65,
          66,
          67,
          68,
          69,
          70,
          71,
          72,
          73,
          74,
          75,
          76,
          77,
          78,
          79,
          80,
          81,
          82,
          83,
          84,
          85,
          86,
          87,
          88,
          89,
          90,
          91,
          92,
          93,
          94,
          95,
          96,
          97,
          98,
          99,
          100,
          101,
          102,
          103,
          104,
          105,
          106,
          107,
          108,
          109,
          110,
          111,
          112,
          113,
          114,
          115,
          116,
          117,
          118,
          119,
          120,
          121,
          122,
          123,
          124,
          125,
          126,
          127,
          128,
          129,
          130,
          131,
          132,
          133,
          134,
          135,
          136,
          137,
          138,
          139,
          140,
          141,
          142,
          143,
          144,
          145,
          146,
          147,
          148,
          149,
          150,
          151,
          152,
          153,
          154,
          155,
          156,
          157,
          158,
          159,
          160,
          161,
          162,
          163,
          164,
          165,
          166,
          167,
          168,
          169,
          170,
          171,
          172,
          173,
          174,
          175,
          176,
          177,
          178,
          179,
          180,
          181,
          182,
          183,
          184,
          185,
          186,
          187,
          188,
          189,
          190,
          191,
          192,
          193,
          194,
          195,
          196,
          197,
          198,
          199,
          200,
          201,
          202,
          203,
          204,
          205,
          206,
          207,
          208,
          209,
          210,
          211,
          212,
          213,
          214,
          215,
          216,
          217,
          218,
          219,
          220,
          221,
          222,
          223,
          224,
          225,
          226,
          227,
          228,
          229,
          230,
          231,
          232,
          233,
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371,
          372,
          373,
          374,
          375,
          376,
          377,
          378,
          379,
          380,
          381,
          382,
          383,
          384,
          385,
          386,
          387,
          388,
          389,
          390,
          391,
          392,
          393,
          394,
          395,
          396,
          397,
          398,
          399,
          400,
          401,
          402,
          403,
          404,
          405,
          406,
          407,
          408,
          409,
          410,
          411,
          412,
          413,
          414,
          415,
          416,
          417,
          418,
          419,
          420,
          421,
          422,
          423,
          424,
          425,
          426,
          427,
          428,
          429,
          430,
          431,
          432,
          433,
          434,
          435,
          436,
          437,
          438,
          439,
          440,
          441,
          442,
          443,
          444,
          445,
          446,
          447,
          448,
          449,
          450,
          451,
          452,
          453,
          454,
          455,
          456,
          457,
          458,
          459,
          460,
          461,
          462,
          463,
          464,
          465,
          466,
          467,
          468,
          469,
          470,
          471,
          472,
          473,
          474,
          475,
          476,
          477,
          478,
          479,
          480,
          481,
          482,
          483,
          484,
          485,
          486,
          487,
          488,
          489,
          490,
          491,
          492,
          493,
          494,
          495,
          496,
          497,
          498,
          499,
          500,
          501,
          502,
          503,
          504,
          505,
          506,
          507,
          508,
          509,
          510,
          511,
          512,
          513,
          514,
          515,
          516,
          517,
          518,
          519,
          520,
          521,
          522,
          523,
          524,
          525,
          526,
          527,
          528,
          529,
          530,
          531,
          532,
          533,
          534,
          535,
          536,
          537,
          538,
          539,
          540,
          541,
          542,
          543,
          544,
          545,
          546,
          547,
          548,
          549,
          550,
          551,
          552,
          553,
          554,
          555,
          556,
          557,
          558,
          559,
          560,
          561,
          562,
          563,
          564,
          565,
          566,
          567,
          568,
          569,
          570,
          571,
          572,
          573,
          574,
          575,
          576,
          577,
          578,
          579,
          580,
          581,
          582,
          583,
          584,
          585,
          586,
          587,
          588,
          589,
          590,
          591,
          592,
          593,
          594,
          595,
          596,
          597,
          598,
          599,
          600,
          601,
          602,
          603,
          604,
          605,
          606,
          607,
          608,
          609,
          610,
          611,
          612,
          613,
          614,
          615,
          616,
          617,
          618,
          619,
          620,
          621,
          622,
          623,
          624,
          625,
          626,
          627,
          628,
          629,
          630,
          632,
          633,
          634,
          635,
          636,
          637,
          638,
          639,
          640,
          641,
          642,
          643,
          644,
          645,
          646,
          647,
          648,
          649,
          650,
          651,
          652,
          653,
          654,
          655,
          656,
          657,
          658,
          659,
          660,
          661,
          662,
          663,
          664,
          665,
          666,
          667,
          668,
          669,
          670,
          671,
          672,
          673,
          674,
          675,
          676,
          677,
          678,
          679,
          680,
          681,
          682,
          683,
          684,
          685,
          686,
          687,
          688,
          689,
          690,
          691,
          692,
          693,
          694,
          695,
          696,
          697,
          698,
          699,
          700,
          701,
          702,
          703,
          704,
          705,
          706,
          707,
          708,
          709,
          710,
          711,
          712,
          713,
          714,
          715,
          716,
          717,
          718,
          719,
          720,
          721,
          722,
          723,
          724,
          725,
          726,
          727,
          728,
          729,
          730,
          731,
          732,
          733,
          734,
          735,
          736,
          737,
          738,
          739,
          740,
          741,
          742,
          743,
          744,
          745,
          746,
          747,
          748,
          749,
          750,
          751,
          752,
          753,
          754,
          755,
          756,
          757,
          758,
          759,
          760,
          761,
          762,
          763,
          764,
          765,
          766,
          767,
          768,
          769,
          770,
          771,
          772,
          773,
          774,
          775,
          776,
          777,
          778,
          779,
          780,
          781,
          782,
          784,
          785,
          786,
          787,
          788,
          789,
          790,
          791,
          792,
          793,
          794,
          795,
          796,
          797,
          798,
          799,
          800,
          801,
          802,
          803,
          804,
          805,
          806,
          807,
          808,
          809,
          810,
          811,
          812,
          813,
          814,
          815,
          816,
          817,
          818,
          819,
          820,
          821,
          822,
          823,
          824,
          825,
          826,
          827,
          828,
          829,
          830,
          831,
          832,
          833,
          834,
          835,
          836,
          837,
          838,
          839,
          840,
          841,
          842,
          843,
          844,
          845,
          846,
          847,
          848,
          849,
          850,
          851,
          852,
          853,
          854,
          855,
          856,
          857,
          858,
          859,
          860,
          861,
          862,
          863,
          864,
          865,
          866,
          867,
          868,
          869,
          870,
          871,
          872,
          873,
          874,
          875,
          876,
          877,
          878,
          879,
          880,
          881,
          882,
          883,
          884,
          885,
          886,
          887,
          888,
          889,
          890,
          891,
          892,
          893,
          894,
          895,
          896,
          897,
          898,
          899,
          900,
          901,
          902,
          903,
          904,
          905,
          906,
          907,
          908,
          909,
          910,
          911,
          912,
          913,
          914,
          915,
          916,
          917,
          918,
          919,
          920,
          921,
          922,
          923,
          924,
          925,
          926,
          927,
          928,
          929,
          930,
          931,
          932,
          933,
          934,
          935,
          936,
          937,
          938,
          939,
          940,
          941,
          942,
          943,
          944,
          945,
          946,
          947,
          948,
          949,
          950,
          951,
          952,
          953,
          954,
          955,
          956,
          957,
          958,
          959,
          960,
          961,
          962,
          963,
          964,
          965,
          966,
          967,
          968,
          969,
          970,
          971,
          972,
          973,
          974,
          975,
          976,
          977,
          978,
          979,
          980,
          981,
          982,
          983,
          984,
          985,
          986,
          987,
          988,
          989,
          990,
          991,
          992,
          993,
          994,
          995,
          996,
          997,
          998,
          999,
          1000,
          1001,
          1002,
          1003,
          1004,
          1005,
          1006,
          1007,
          1008,
          1009,
          1010,
          1011,
          1012,
          1013,
          1014,
          1015,
          1016,
          1017,
          1018,
          1019,
          1020,
          1021,
          1022,
          1023,
          1024,
          1025,
          1026,
          1027,
          1028,
          1029,
          1030,
          1031,
          1032,
          1033,
          1034,
          1035,
          1036,
          1037,
          1038,
          1039,
          1040,
          1041,
          1042,
          1043,
          1044,
          1045,
          1046,
          1047,
          1048,
          1049,
          1050,
          1051,
          1052,
          1053,
          1054,
          1055,
          1056,
          1057,
          1058,
          1059,
          1060,
          1061,
          1062,
          1063,
          1064,
          1065,
          1066,
          1067,
          1068,
          1069,
          1070,
          1071,
          1072,
          1073,
          1074,
          1075,
          1076,
          1077,
          1078,
          1079,
          1080,
          1081,
          1082,
          1083,
          1084,
          1085,
          1086,
          1087,
          1088,
          1089,
          1090,
          1091,
          1092,
          1093,
          1094,
          1095,
          1096,
          1097,
          1098,
          1099,
          1100,
          1101,
          1102,
          1103,
          1104,
          1105,
          1106,
          1107,
          1108,
          1109,
          1110,
          1111,
          1112,
          1113,
          1114,
          1115,
          1116,
          1117,
          1118,
          1119,
          1120,
          1121,
          1122,
          1123,
          1124,
          1125,
          1126,
          1127,
          1128,
          1129,
          1130,
          1131,
          1132,
          1133,
          1134,
          1135,
          1136,
          1137,
          1138,
          1139,
          1140,
          1141,
          1142,
          1143,
          1144,
          1145,
          1146,
          1147,
          1148,
          1149,
          1150,
          1151,
          1152,
          1153,
          1154,
          1155,
          1156,
          1157,
          1158,
          1159,
          1160,
          1161,
          1162,
          1163,
          1164,
          1165,
          1166,
          1167,
          1168,
          1169,
          1170,
          1171,
          1172,
          1173,
          1174,
          1175,
          1176,
          1177,
          1178,
          1179,
          1180,
          1181,
          1182,
          1183,
          1184,
          1185,
          1186,
          1187,
          1188,
          1189,
          1190,
          1191,
          1192,
          1193,
          1194,
          1195,
          1196,
          1197,
          1198,
          1199,
          1200,
          1201,
          1202,
          1203,
          1204,
          1205,
          1206,
          1207,
          1208,
          1209,
          1210,
          1211,
          1212,
          1213,
          1214,
          1215,
          1216,
          1217,
          1218,
          1219,
          1220,
          1221,
          1222,
          1223,
          1224,
          1225,
          1226,
          1227,
          1228,
          1229,
          1230,
          1231,
          1232,
          1233,
          1234,
          1235,
          1236,
          1237,
          1238,
          1239,
          1240,
          1241,
          1242,
          1243,
          1244,
          1245,
          1246,
          1247,
          1248,
          1249,
          1250,
          1251,
          1252,
          1253,
          1254,
          1255,
          1256,
          1257,
          1258,
          1259,
          1260,
          1261,
          1262,
          1263,
          1264,
          1265,
          1266,
          1267,
          1268,
          1269,
          1270,
          1271,
          1272,
          1273,
          1274,
          1275,
          1276,
          1277,
          1278,
          1279,
          1280,
          1281,
          1282,
          1283,
          1284,
          1285,
          1286,
          1287,
          1288,
          1289,
          1290,
          1291,
          1292,
          1293,
          1294,
          1295,
          1296,
          1297,
          1298,
          1299,
          1300,
          1301,
          1302,
          1303,
          1304,
          1305,
          1306,
          1307,
          1308,
          1309,
          1310,
          1311,
          1312,
          1313,
          1314,
          1315,
          1316,
          1317,
          1318,
          1319,
          1320,
          1321,
          1322,
          1323,
          1324,
          1325,
          1326,
          1327,
          1328,
          1329,
          1330,
          1331,
          1332,
          1333,
          1334,
          1335,
          1336,
          1337,
          1338,
          1339,
          1340,
          1341,
          1342,
          1343,
          1344,
          1345,
          1346,
          1347,
          1348,
          1349,
          1350,
          1351,
          1352,
          1353,
          1354,
          1355,
          1356,
          1357,
          1358,
          1359,
          1360,
          1361,
          1362,
          1363,
          1364,
          1365,
          1366,
          1367,
          1368,
          1369,
          1370,
          1371,
          1372,
          1373,
          1374,
          1375,
          1376,
          1377,
          1378,
          1379,
          1380,
          1381,
          1382,
          1383,
          1384,
          1385,
          1386,
          1387,
          1388,
          1389,
          1390,
          1391,
          1392,
          1393,
          1394,
          1395,
          1396,
          1397,
          1398,
          1399,
          1400,
          1401,
          1402,
          1403,
          1404,
          1405,
          1406,
          1407,
          1408,
          1409,
          1410,
          1411,
          1412,
          1413,
          1414,
          1415,
          1416,
          1417,
          1418,
          1419,
          1420,
          1421,
          1422,
          1423,
          1424,
          1425,
          1426,
          1427,
          1428,
          1429,
          1430,
          1431,
          1432,
          1433,
          1434,
          1435,
          1436,
          1437,
          1438,
          1439,
          1440,
          1441,
          1442,
          1443,
          1444,
          1445,
          1446,
          1447,
          1448,
          1449,
          1450,
          1451,
          1452,
          1453,
          1454,
          1455,
          1456,
          1457,
          1458,
          1459,
          1460,
          1461,
          1462,
          1463,
          1464,
          1465,
          1466,
          1467,
          1468,
          1469,
          1470,
          1471,
          1472,
          1473,
          1474,
          1475,
          1476,
          1477,
          1478,
          1479,
          1480,
          1481,
          1482,
          1483,
          1484,
          1485,
          1486,
          1487,
          1488,
          1489,
          1490,
          1491,
          1492,
          1493,
          1494,
          1495,
          1496,
          1497,
          1498,
          1499,
          1500,
          1501,
          1502,
          1503,
          1504,
          1505,
          1506,
          1507,
          1508,
          1509,
          1510,
          1511,
          1512,
          1513,
          1514,
          1515,
          1516,
          1517,
          1518,
          1519,
          1520,
          1521,
          1522,
          1523,
          1524,
          1525,
          1526,
          1527,
          1528,
          1529,
          1530,
          1531,
          1532,
          1533,
          1534,
          1535,
          1536,
          1537,
          1538,
          1539,
          1540,
          1541,
          1542,
          1543,
          1544,
          1545,
          1546,
          1547,
          1548,
          1549,
          1550,
          1551,
          1552,
          1553,
          1554,
          1555,
          1556,
          1557,
          1558,
          1559,
          1560,
          1561,
          1562,
          1563,
          1564,
          1565,
          1566,
          1567,
          1568,
          1569,
          1570,
          1571,
          1572,
          1573,
          1574,
          1575,
          1576,
          1577,
          1578,
          1579,
          1580,
          1581,
          1582,
          1583,
          1584,
          1585,
          1586,
          1587,
          1588,
          1589,
          1590,
          1591,
          1592,
          1593,
          1594,
          1595,
          1596,
          1597,
          1598,
          1599,
          1600,
          1601,
          1602,
          1603,
          1604,
          1605,
          1606,
          1607,
          1608,
          1609,
          1610,
          1611,
          1612,
          1613,
          1614,
          1615,
          1616,
          1618,
          1619,
          1620,
          1621,
          1622,
          1623,
          1624,
          1625,
          1626,
          1627,
          1628,
          1629,
          1630,
          1631,
          1632,
          1633,
          1634,
          1635,
          1636,
          1637,
          1638,
          1639,
          1640,
          1641,
          1642,
          1643,
          1644,
          1645,
          1646,
          1647,
          1648,
          1649,
          1650,
          1651,
          1652,
          1653,
          1654,
          1655,
          1656,
          1657,
          1658,
          1659,
          1660,
          1661,
          1662,
          1663,
          1664,
          1665,
          1666,
          1667,
          1668,
          1669,
          1670,
          1671,
          1672,
          1673,
          1674,
          1675,
          1676,
          1677,
          1678,
          1679,
          1680,
          1681,
          1682,
          1683,
          1684,
          1685,
          1686,
          1687,
          1688,
          1689,
          1690,
          1691,
          1692,
          1693,
          1694,
          1695,
          1696,
          1697,
          1698,
          1699,
          1700,
          1701,
          1702,
          1703,
          1704,
          1705,
          1706,
          1707,
          1708,
          1709,
          1710,
          1711,
          1712,
          1713,
          1714,
          1715,
          1716,
          1717,
          1718,
          1719,
          1720,
          1721,
          1722,
          1723,
          1724,
          1725,
          1726,
          1727,
          1728,
          1729,
          1730,
          1731,
          1732,
          1733,
          1734,
          1735,
          1736,
          1737,
          1738,
          1739,
          1740,
          1741,
          1742,
          1743,
          1744,
          1745,
          1746,
          1747,
          1748,
          1749,
          1750,
          1751,
          1752,
          1753,
          1754,
          1755,
          1756,
          1757,
          1758,
          1759,
          1760,
          1761,
          1762,
          1763,
          1764,
          1765,
          1766,
          1767,
          1768,
          1769,
          1770,
          1771,
          1772,
          1773,
          1774,
          1775,
          1776,
          1777,
          1778,
          1779,
          1780,
          1781,
          1782,
          1783,
          1784,
          1785,
          1786,
          1787,
          1788,
          1789,
          1790,
          1791,
          1792,
          1793,
          1794,
          1795,
          1796,
          1797,
          1798,
          1799,
          1800,
          1801,
          1802,
          1803,
          1804,
          1805,
          1806,
          1807,
          1808,
          1809,
          1810,
          1811,
          1812,
          1813,
          1814,
          1815,
          1816,
          1817,
          1818,
          1819,
          1820,
          1821,
          1822,
          1823,
          1824,
          1825,
          1826
        ],
        "inputs": [
          "US9309598B2",
          "US9681497B2",
          "US8074677B2",
          "US10460916B2",
          "US20190078206A1",
          "US10096494B2",
          "US20190108974A1",
          "US6598615B1",
          "US20150228503A1",
          "US7306696B2",
          "US10670393B1",
          "US20180366351A1",
          "US20130092655A1",
          "US10453656B2",
          "US9305797B2",
          "US7972469B2",
          "US9362148B2",
          "US8951429B1",
          "US9805965B2",
          "US20180259848A1",
          "US9200950B2",
          "US8753474B2",
          "US9040422B2",
          "US6557248B1",
          "US9202708B1",
          "US6613689B2",
          "US10580657B2",
          "US6582861B2",
          "US20180053631A1",
          "US8475674B2",
          "US10177018B2",
          "US5423945A",
          "US9039908B2",
          "US9478433B1",
          "US10555412B2",
          "US9447365B2",
          "US20140224767A1",
          "US20190206660A1",
          "US6831742B1",
          "US10522380B2",
          "US10283324B1",
          "US6635573B2",
          "US20190279893A1",
          "US10679885B2",
          "US20130040231A1",
          "US7276447B1",
          "US7648914B2",
          "US9875923B2",
          "US20130288474A1",
          "US10319624B2",
          "US9589832B2",
          "US9741593B2",
          "US20170011891A1",
          "US8206552B2",
          "US20190088518A1",
          "US9558981B2",
          "US8987140B2",
          "US20190371577A1",
          "US20200185200A1",
          "US9725799B2",
          "US20180277340A1",
          "US20200294771A1",
          "US20190078204A1",
          "US10189141B2",
          "US20180010234A1",
          "US10008368B2",
          "US20190085467A1",
          "US5938943A",
          "US10403507B2",
          "US9601396B2",
          "US20200243368A1",
          "US5737177A",
          "US6727655B2",
          "US20140248718A1",
          "US10546731B1",
          "US20150299050A1",
          "US9548201B2",
          "US9754800B2",
          "US7196283B2",
          "US9418867B2",
          "US20150183187A1",
          "US20170092525A1",
          "US8337661B2",
          "US20150170879A1",
          "US20200185192A1",
          "US20180134612A1",
          "US20190326123A1",
          "US6185839B1",
          "US20100028813A1",
          "US10600688B2",
          "US9852923B2",
          "US9624593B2",
          "US6676760B2",
          "US20190341236A1",
          "US9155134B2",
          "US20140065797A1",
          "US6575622B2",
          "US9318304B2",
          "US20190043697A1",
          "US20160148821A1",
          "US6635578B1",
          "US8334657B2",
          "US10796922B2",
          "US9034199B2",
          "US20170301520A1",
          "US20190363003A1",
          "US9872373B1",
          "US10497567B2",
          "US8937800B2",
          "US7815812B2",
          "US20130107415A1",
          "US8158522B2",
          "US9161428B2",
          "US20200161155A1",
          "US9093371B2",
          "US10522371B2",
          "US8771536B2",
          "US10714372B2",
          "US10825708B2",
          "US6072685A",
          "US20200118845A1",
          "US9343272B1",
          "US5919382A",
          "US10373804B2",
          "US7645357B2",
          "US9728437B2",
          "US20140179108A1",
          "US9570275B2",
          "US10319603B2",
          "US10685807B2",
          "US7807578B2",
          "US9305804B2",
          "US8313578B2",
          "US20200291528A1",
          "US20180366302A1",
          "US5907221A",
          "US9520294B2",
          "US10544505B2",
          "US6251217B1",
          "US9165786B1",
          "US8880227B2",
          "US20180261516A1",
          "US7585384B2",
          "US6355557B2",
          "US8980763B2",
          "US8765234B2",
          "US5683517A",
          "US6581275B2",
          "US20200024735A1",
          "US9850568B2",
          "US6960413B2",
          "US5925212A",
          "US20160056059A1",
          "US20130122707A1",
          "US9984912B2",
          "US20130098873A1",
          "US20110151590A1",
          "US6673636B2",
          "US6403491B1",
          "US9155184B2",
          "US20200066556A1",
          "US7943005B2",
          "US20100108263A1",
          "US7229930B2",
          "US20200181771A1",
          "US8765574B2",
          "US7833401B2",
          "US9583369B2",
          "US9359679B2",
          "US5552016A",
          "US9896769B2",
          "US10537013B2",
          "US9472435B2",
          "US6005376A",
          "US10410841B2",
          "US20070134416A1",
          "US6559942B2",
          "US7094704B2",
          "US7727413B2",
          "US5614060A",
          "US8921234B2",
          "US10424507B2",
          "US7777599B2",
          "US20200227276A1",
          "US20180076049A1",
          "US20200035454A1",
          "US10490406B2",
          "US9945033B2",
          "US8931512B2",
          "US20200027742A1",
          "US9209012B2",
          "US6440864B1",
          "US10026636B2",
          "US10170280B2",
          "US20100099266A1",
          "US8993419B1",
          "US9999907B2",
          "US10497579B2",
          "US20200027769A1",
          "US20150181684A1",
          "US10283397B2",
          "US9653282B2",
          "US9613822B2",
          "US9564341B1",
          "US8133817B2",
          "US9299537B2",
          "US9543163B2",
          "US7635648B2",
          "US8734664B2",
          "US20070091535A1",
          "US9159574B2",
          "US10153139B2",
          "US7868556B2",
          "US10475626B2",
          "US9390923B2",
          "US10204781B1",
          "US8080479B2",
          "US8133819B2",
          "US20190295826A1",
          "US9976211B2",
          "US20140273451A1",
          "US9281226B2",
          "US8034734B2",
          "US6692903B2",
          "US10790123B2",
          "US9869012B2",
          "US10424487B2",
          "US7431795B2",
          "US6730174B2",
          "US10395904B2",
          "US7030335B2",
          "US9885117B2",
          "US10604831B2",
          "US20200185248A1",
          "US10121688B2",
          "US6352611B1",
          "US20120088371A1",
          "US10692880B2",
          "US10438796B2",
          "US20180016678A1",
          "US9449843B1",
          "US10584068B2",
          "US9666466B2",
          "US7674353B2",
          "US8741775B2",
          "US7718539B2",
          "US10727096B2",
          "US20140199833A1",
          "US10553400B2",
          "US10573555B2",
          "US5772832A",
          "US6942929B2",
          "US7967944B2",
          "US6949203B2",
          "US10119188B2",
          "US8802572B2",
          "US8840725B2",
          "US7624003B2",
          "US9735037B2",
          "US20110304078A1",
          "US20120241091A1",
          "US20180218873A1",
          "US6123791A",
          "US9496167B2",
          "US10770321B2",
          "US10163696B2",
          "US8066895B2",
          "US7737042B2",
          "US9601301B2",
          "US20120255635A1",
          "US9788405B2",
          "US10319591B2",
          "US10224210B2",
          "US10578510B2",
          "US20200176233A1",
          "US20170352567A1",
          "US9957192B2",
          "US20070254483A1",
          "US6633391B1",
          "US10811232B2",
          "US20140011337A1",
          "US20090014323A1",
          "US10336656B2",
          "US8722547B2",
          "US20200037399A1",
          "US7901952B2",
          "US9540731B2",
          "US8293430B2",
          "US8608852B2",
          "US5900062A",
          "US8501626B2",
          "US8367227B2",
          "US9406523B2",
          "US10595365B2",
          "US5910221A",
          "US5710486A",
          "US10707086B2",
          "US7265382B2",
          "US7672110B2",
          "US9484220B2",
          "US20140190632A1",
          "US9909213B2",
          "US10177002B2",
          "US10504754B2",
          "US20200273728A1",
          "US8773020B2",
          "US8513939B2",
          "US5688357A",
          "US5753044A",
          "US10727075B2",
          "US10770269B2",
          "US9842744B2",
          "US10319636B2",
          "US9240315B1",
          "US9708713B2",
          "US9564296B2",
          "US5676759A",
          "US9236266B2",
          "US6379575B1",
          "US7094613B2",
          "US20130098553A1",
          "US20160133441A1",
          "US9520315B2",
          "US8089046B2",
          "US10242888B2",
          "US9136273B1",
          "US20150064809A1",
          "US20180061684A1",
          "US20180254203A1",
          "US5507874A",
          "US8741778B2",
          "US6538872B1",
          "US8707754B2",
          "US20170345691A1",
          "US5900064A",
          "US8778204B2",
          "US7909961B2",
          "US20170226637A1",
          "US10109464B2",
          "US9472378B2",
          "US10395896B2",
          "US20170140900A1",
          "US7972467B2",
          "US8002946B2",
          "US20200163159A1",
          "US8129029B2",
          "US6320736B1",
          "US9493879B2",
          "US5484485A",
          "US7018934B2",
          "US8647438B2",
          "US5883017A",
          "US9385028B2",
          "US10354843B2",
          "US6415198B1",
          "US9536769B1",
          "US20190090338A1",
          "US7718538B2",
          "US10510540B2",
          "US20180254208A1",
          "US10535502B2",
          "US9530637B2",
          "US10056284B2",
          "US9236265B2",
          "US20180204747A1",
          "US10224180B2",
          "US8709953B2",
          "US20180197758A1",
          "US10573497B2",
          "US7011039B1",
          "US6361644B1",
          "US7910488B2",
          "US20200118844A1",
          "US9390964B2",
          "US7618516B2",
          "US7601272B2",
          "US7105361B2",
          "US10745807B2",
          "US6020686A",
          "US6572732B2",
          "US9903020B2",
          "US20140083978A1",
          "US6507155B1",
          "US10615006B2",
          "US20190157113A1",
          "US8740206B2",
          "US5349313A",
          "US6592709B1",
          "US9646843B2",
          "US8933628B2",
          "US7746473B2",
          "US7554334B2",
          "US9023734B2",
          "US10780447B2",
          "US5612850A",
          "US6504126B2",
          "US7879731B2",
          "US10813370B2",
          "US20190189330A1",
          "US9093389B2",
          "US6490146B2",
          "US10186444B2",
          "US9653310B1",
          "US10354889B2",
          "US9966240B2",
          "US10242908B2",
          "US20200234923A1",
          "US9368369B2",
          "US10410900B2",
          "US9947549B1",
          "US9420639B2",
          "US7431797B2",
          "US8956809B2",
          "US20200152423A1",
          "US6478924B1",
          "US6613666B2",
          "US20160042961A1",
          "US9378969B2",
          "US7431857B2",
          "US9922840B2",
          "US6368517B1",
          "US8083963B2",
          "US7718029B2",
          "US9472410B2",
          "US5451784A",
          "US8282736B2",
          "US8257546B2",
          "US20110094683A1",
          "US7250309B2",
          "US8951384B2",
          "US20110180233A1",
          "US20160068969A1",
          "US20140034239A1",
          "US10453696B2",
          "US5893643A",
          "US8974684B2",
          "US20180337026A1",
          "US20200312680A1",
          "US20190333776A1",
          "US20160258064A1",
          "US20200234921A1",
          "US9488315B2",
          "US6639783B1",
          "US5730801A",
          "US20180010235A1",
          "US10535549B2",
          "US20180261686A1",
          "US10319604B2",
          "US10510575B2",
          "US20050067103A1",
          "US9839109B1",
          "US20140069584A1",
          "US20190088531A1",
          "US6077384A",
          "US20090221150A1",
          "US20200075367A1",
          "US7674394B2",
          "US10541184B2",
          "US8920597B2",
          "US20200066563A1",
          "US10490418B2",
          "US9653267B2",
          "US6773544B2",
          "US9885567B2",
          "US9449846B2",
          "US10242847B2",
          "US20200083047A1",
          "US9177824B2",
          "US20170229291A1",
          "US9299582B2",
          "US5683539A",
          "US8969212B2",
          "US20120103526A1",
          "US20190348317A1",
          "US9850573B1",
          "US20180053666A1",
          "US20120154974A1",
          "US8501629B2",
          "US10497573B2",
          "US10020218B2",
          "US6589879B2",
          "US6562189B1",
          "US7422654B2",
          "US20130298942A1",
          "US20120285621A1",
          "US8279577B2",
          "US9925639B2",
          "US10656194B2",
          "US8956500B2",
          "US6369349B2",
          "US6033478A",
          "US9960049B2",
          "US6280584B1",
          "US10443126B1",
          "US6518195B1",
          "US9978606B2",
          "US6524432B1",
          "US7244334B2",
          "US20180327892A1",
          "US7955986B2",
          "US8148977B2",
          "US6458516B1",
          "US8473247B2",
          "US9068265B2",
          "US6432830B1",
          "US6015761A",
          "US9051219B2",
          "US20200111643A1",
          "US5942889A",
          "US7368394B2",
          "US20200051839A1",
          "US7846848B2",
          "US9218997B2",
          "US6304424B1",
          "US6462928B1",
          "US20170350017A1",
          "US10790153B2",
          "US6583065B1",
          "US9111722B2",
          "US10170279B2",
          "US20190070639A1",
          "US20140273460A1",
          "US9449845B2",
          "US10283370B1",
          "US20140335679A1",
          "US20190221463A1",
          "US10468233B2",
          "US10553404B2",
          "US7105100B2",
          "US20190194817A1",
          "US10204805B2",
          "US9721807B2",
          "US20110120651A1",
          "US8187415B2",
          "US20190139743A1",
          "US20130008604A1",
          "US20170350018A1",
          "US5891350A",
          "US10347500B1",
          "US9991134B2",
          "US9214377B2",
          "US10607816B2",
          "US20180211811A1",
          "US7964512B2",
          "US10774436B2",
          "US6444084B1",
          "US9218944B2",
          "US10566206B2",
          "US20180319205A1",
          "US10233547B2",
          "US9093390B2",
          "US10586718B2",
          "US6716302B2",
          "US6759286B2",
          "US7316761B2",
          "US9373522B1",
          "US20200243303A1",
          "US7658969B2",
          "US10431435B2",
          "US7309448B2",
          "US20140083362A1",
          "US20200286717A1",
          "US7479304B2",
          "US7779784B2",
          "US10256112B1",
          "US20190189401A1",
          "US20200103294A1",
          "US9165804B2",
          "US8623527B2",
          "US10658161B2",
          "US6653237B2",
          "US9064812B2",
          "US10790180B2",
          "US6009830A",
          "US9419107B2",
          "US9741545B2",
          "US8435902B2",
          "US9564297B2",
          "US10010912B2",
          "US8970226B2",
          "US7807579B2",
          "US10177017B1",
          "US7695983B2",
          "US9280051B2",
          "US6853141B2",
          "US5948703A",
          "US20180337074A1",
          "US9536710B2",
          "US9269590B2",
          "US20110269314A1",
          "US6900133B2",
          "US20110236806A1",
          "US6198616B1",
          "US20200168463A1",
          "US20090159566A1",
          "US6264852B1",
          "US9267742B2",
          "US8264154B2",
          "US6362109B1",
          "US10424464B2",
          "US6168672B1",
          "US20080213496A1",
          "US20100101729A1",
          "US7256134B2",
          "US6983892B2",
          "US10304715B2",
          "US8075729B2",
          "US10424485B2",
          "US20190096634A1",
          "US20200066493A1",
          "US20190330748A1",
          "US20190272998A1",
          "US20200185203A1",
          "US20200017972A1",
          "US6490145B1",
          "US8541312B2",
          "US20090188624A1",
          "US10468282B2",
          "US10319649B2",
          "US8067067B2",
          "US10049891B1",
          "US9911579B2",
          "US8679983B2",
          "US8603921B2",
          "US10431429B2",
          "US7655571B2",
          "US7795153B2",
          "US10163606B2",
          "US10685861B2",
          "US10770329B2",
          "US10043674B1",
          "US20170110292A1",
          "US20170271179A1",
          "US20180122655A1",
          "US20170213758A1",
          "US10056233B2",
          "US20110097901A1",
          "US8513889B2",
          "US20150311043A1",
          "US8546263B2",
          "US8202441B2",
          "US7371467B2",
          "US10497578B2",
          "US10770267B1",
          "US8778207B2",
          "US10790126B2",
          "US9114438B2",
          "US20140034241A1",
          "US6849554B2",
          "US20200140620A1",
          "US10386126B2",
          "US8349128B2",
          "US8398814B2",
          "US7846264B2",
          "US10796888B2",
          "US9622375B2",
          "US20120222813A1",
          "US20100327873A1",
          "US6589869B2",
          "US20100140222A1",
          "US6054013A",
          "US6368975B1",
          "US20150083042A1",
          "US9443753B2",
          "US8236105B2",
          "US10204796B2",
          "US20170314125A1",
          "US10546728B2",
          "US7879250B2",
          "US9159606B1",
          "US8772162B2",
          "US8580693B2",
          "US9287095B2",
          "US6270617B1",
          "US9394615B2",
          "US8988848B2",
          "US7737040B2",
          "US10763081B2",
          "US7553679B2",
          "US10177050B2",
          "US8758858B2",
          "US10403542B2",
          "US8623672B2",
          "US10290469B2",
          "US10593594B2",
          "US8916021B2",
          "US10468285B2",
          "US10265742B2",
          "US10504746B2",
          "US9991148B2",
          "US20150214066A1",
          "US10573527B2",
          "US20190382880A1",
          "US10062587B2",
          "US5780359A",
          "US6623596B1",
          "US9666414B2",
          "US9576815B2",
          "US6818140B2",
          "US7786019B2",
          "US10012248B2",
          "US8529776B2",
          "US8578879B2",
          "US7829471B2",
          "US5451290A",
          "US8016948B2",
          "US9368370B2",
          "US5995235A",
          "US7482178B2",
          "US20200087776A1",
          "US20190122861A1",
          "US10131126B2",
          "US6444040B1",
          "US7768765B2",
          "US20200075290A1",
          "US10770270B2",
          "US9972477B2",
          "US5641359A",
          "US20190136372A1",
          "US10577286B2",
          "US10553397B2",
          "US10026621B2",
          "US9212099B2",
          "US7250373B2",
          "US8211808B2",
          "US9478432B2",
          "US9704723B2",
          "US20200090907A1",
          "US20180112311A1",
          "US20200090972A1",
          "US20150064880A1",
          "US6605177B2",
          "US20100180426A1",
          "US8956980B1",
          "US9418858B2",
          "US5574410A",
          "US8435419B2",
          "US8270141B2",
          "US9184021B2",
          "US7354866B2",
          "US6369348B2",
          "US20190304827A1",
          "US10763150B2",
          "US20160260588A1",
          "US6685799B2",
          "US20150200042A1",
          "US6902681B2",
          "US6898065B2",
          "US10443125B2",
          "US20200152425A1",
          "US10607867B2",
          "US9384997B2",
          "US6602434B1",
          "US9305748B2",
          "US8920599B2",
          "US7375947B2",
          "US7696117B2",
          "US9449796B2",
          "US7431859B2",
          "US8895449B1",
          "US8252410B2",
          "US9082591B2",
          "US10186400B2",
          "US8324525B2",
          "US6893533B2",
          "US10062578B2",
          "US9970095B2",
          "US10573493B2",
          "US7115517B2",
          "US20150060013A1",
          "US8562742B2",
          "US20190311884A1",
          "US7620511B2",
          "US6460550B2",
          "US10468267B2",
          "US8445078B2",
          "US6036878A",
          "US9275834B1",
          "US10636629B2",
          "US6238588B1",
          "US8360003B2",
          "US20160215883A1",
          "US20070000611A1",
          "US5910011A",
          "US8101525B2",
          "US9721789B1",
          "US7736914B2",
          "US10211033B2",
          "US6273022B1",
          "US10676819B2",
          "US20190341233A1",
          "US6686293B2",
          "US10032604B2",
          "US10636675B2",
          "US10755941B2",
          "US5534108A",
          "US9299538B2",
          "US8658541B2",
          "US7682984B2",
          "US8629370B2",
          "US9012302B2",
          "US9412563B2",
          "US7575007B2",
          "US10766824B2",
          "US20200035463A1",
          "US10153133B2",
          "US9355863B2",
          "US9343289B2",
          "US10395964B2",
          "US6586886B1",
          "US20110217832A1",
          "US6944006B2",
          "US20200152431A1",
          "US5242538A",
          "US7718559B2",
          "US20190244792A1",
          "US9928987B2",
          "US7695987B2",
          "US5885469B1",
          "US10217627B2",
          "US20190371578A1",
          "US7375038B2",
          "US6325861B1",
          "US8845816B2",
          "US9595451B1",
          "US9745663B2",
          "US9267739B2",
          "US7790334B2",
          "US10612121B2",
          "US10453655B2",
          "US20130134129A1",
          "US9879348B2",
          "US9245762B2",
          "US10274270B2",
          "US20120018402A1",
          "US5737178A",
          "US20100015357A1",
          "US6361705B1",
          "US8101025B2",
          "US10770349B2",
          "US6500357B1",
          "US20160099162A1",
          "US7968469B2",
          "US8642481B2",
          "US20200234971A1",
          "US6673199B1",
          "US10453694B2",
          "US9472416B2",
          "US6924191B2",
          "US8383002B2",
          "US10692728B2",
          "US8871650B2",
          "US20200140996A1",
          "US10271416B2",
          "US20080179282A1",
          "US9779953B2",
          "US9887096B2",
          "US10636690B2",
          "US20190157115A1",
          "US8012366B2",
          "US6401652B1",
          "US6632321B2",
          "US9472434B2",
          "US9012030B2",
          "US10448494B1",
          "US10566205B2",
          "US7067432B2",
          "US7034963B2",
          "US7141757B2",
          "US10727058B2",
          "US20160276196A1",
          "US20160005833A1",
          "US8231799B2",
          "US20070026665A1",
          "US20130344285A1",
          "US9805914B2",
          "US7884025B2",
          "US10361080B2",
          "US10037883B2",
          "US9659792B2",
          "US9934942B1",
          "US9587789B2",
          "US9165783B2",
          "US20200118798A1",
          "US7879185B2",
          "US9287147B2",
          "US20190393053A1",
          "US10410889B2",
          "US7943006B2",
          "US9190293B2",
          "US20190127280A1",
          "US20170175284A1",
          "US6647918B1",
          "US6592673B2",
          "US7754610B2",
          "US7635546B2",
          "US20130105085A1",
          "US6623595B1",
          "US8357264B2",
          "US7649729B2",
          "US8961804B2",
          "US5866483A",
          "US10656029B2",
          "US8404598B2",
          "US20200234928A1",
          "US20190078200A1",
          "US7540971B2",
          "US7186943B2",
          "US6962644B2",
          "US20200118829A1",
          "US5545289A",
          "US9869013B2",
          "US5643394A",
          "US10501843B2",
          "US20100224322A1",
          "US20170110475A1",
          "US6387287B1",
          "US20110094994A1",
          "US8905074B2",
          "US8962488B2",
          "US9023732B2",
          "US7771606B2",
          "US9850591B2",
          "US20190341289A1",
          "US6481886B1",
          "US9287386B2",
          "US7132618B2",
          "US20060000802A1",
          "US6120640A",
          "US20170256435A1",
          "US9520302B2",
          "US9754767B2",
          "US20200098586A1",
          "US8937021B2",
          "US20190271076A1",
          "US9773695B2",
          "US6490144B1",
          "US20150221481A1",
          "US20200013632A1",
          "US7436645B2",
          "US20200013610A1",
          "US20200194242A1",
          "US8333842B2",
          "US20150079370A1",
          "US20190105837A1",
          "US8299391B2",
          "US9472412B2",
          "US6677712B2",
          "US10557202B2",
          "US9196462B2",
          "US20150001180A1",
          "US20170329352A1",
          "US20190385891A1",
          "US10679870B2",
          "US7879510B2",
          "US6373022B2",
          "US8999856B2",
          "US8440019B2",
          "US20190362944A1",
          "US10008366B2",
          "US20200234922A1",
          "US20130309785A1",
          "US9741539B2",
          "US6449871B1",
          "US20200075369A1",
          "US20130059403A1",
          "US10563303B2",
          "US7832354B2",
          "US6818096B2",
          "US20180040457A1",
          "US10600623B2",
          "US8414736B2",
          "US7718543B2",
          "US10504702B2",
          "US6652710B2",
          "US20150075717A1",
          "US20170229326A1",
          "US20180358244A1",
          "US20200118861A1",
          "US9378930B2",
          "US20110079918A1",
          "US7848840B2",
          "US20200066495A1",
          "US10115572B2",
          "US7754997B2",
          "US7585778B2",
          "US10460921B2",
          "US20090142247A1",
          "US20200144067A1",
          "US8936696B2",
          "US5790365A",
          "US5792562A",
          "US9129777B2",
          "US20150042017A1",
          "US20130288037A1",
          "US9978596B2",
          "US20190189492A1",
          "US20140099794A1",
          "US9533332B2",
          "US9514953B2",
          "US20190338418A1",
          "US7217665B2",
          "US8607731B2",
          "US8313664B2",
          "US10553398B2",
          "US7262865B2",
          "US8002945B2",
          "US9293568B2",
          "US8617351B2",
          "US10242893B2",
          "US20170352566A1",
          "US20200283897A1",
          "US7221553B2",
          "US9324576B2",
          "US9595423B2",
          "US7670688B2",
          "US5336363A",
          "US6572814B2",
          "US20140263173A1",
          "US20140271097A1",
          "US20180342375A1",
          "US20190206712A1",
          "US8940642B2",
          "US8656953B2",
          "US8232212B2",
          "US10424463B2",
          "US9741566B2",
          "US10490435B2",
          "US6599437B2",
          "US10820377B2",
          "US10727080B2",
          "US20110130007A1",
          "US20190385880A1",
          "US20090112520A1",
          "US20130284369A1",
          "US10774006B2",
          "US10378108B2",
          "US9818621B2",
          "US10032606B2",
          "US8097088B1",
          "US10043684B1",
          "US6380095B1",
          "US6598559B1",
          "US20160225652A1",
          "US9006106B2",
          "US20200087788A1",
          "US20190067070A1",
          "US9698015B2",
          "US20200152500A1",
          "US20130098552A1",
          "US8771423B2",
          "US10460968B2",
          "US20190157114A1",
          "US20110265951A1",
          "US9405287B1",
          "US20170221750A1",
          "US20180274100A1",
          "US20190263722A1",
          "US8941969B2",
          "US8616224B2",
          "US6924088B2",
          "US10020170B2",
          "US9576809B2",
          "US6943039B2",
          "US5880037A",
          "US10170282B2",
          "US10340124B2",
          "US10008399B2",
          "US8982530B2",
          "US7169625B2",
          "US20110201134A1",
          "US6016131A",
          "US9064815B2",
          "US9748366B2",
          "US7879183B2",
          "US20190214230A1",
          "US9499898B2",
          "US6521080B2",
          "US20200161098A1",
          "US9481608B2",
          "US10784085B2",
          "US9975758B2",
          "US9502218B2",
          "US10622229B2",
          "US7320942B2",
          "US9406540B2",
          "US20190131113A1",
          "US9508831B2",
          "US9368364B2",
          "US20130284372A1",
          "US10256123B2",
          "US9299575B2",
          "US6896763B2",
          "US6068784A",
          "US7371485B2",
          "US5399237A",
          "US7829243B2",
          "US9390937B2",
          "US9540736B2",
          "US8617672B2",
          "US6641697B2",
          "US20190019655A1",
          "US8778574B2",
          "US9639097B2",
          "US9129911B2",
          "US20110303639A1",
          "US8721798B2",
          "US5540824A",
          "US7777152B2",
          "US20180337057A1",
          "US7848898B2",
          "US10770346B2",
          "US8382939B2",
          "US9269546B2",
          "US20190169444A1",
          "US9580806B2",
          "US6326597B1",
          "US9797037B2",
          "US20130315795A1",
          "US9209279B1",
          "US7244313B1",
          "US7790047B2",
          "US20190326099A1",
          "US10559451B2",
          "US6613691B1",
          "US20200190653A1",
          "US10115566B2",
          "US7286948B1",
          "US20200066566A1",
          "US20150371865A1",
          "US9725302B1",
          "US9865434B2",
          "US20180226232A9",
          "US8018164B2",
          "US8034180B2",
          "US7828987B2",
          "US9520314B2",
          "US8496756B2",
          "US20160155612A1",
          "US6508911B1",
          "US8748322B1",
          "US9564338B1",
          "US5459632A",
          "US7838433B2",
          "US7247218B2",
          "US8616043B2",
          "US7652774B2",
          "US9401310B2",
          "US9947559B2",
          "US6677242B1",
          "US20180337024A1",
          "US10600639B2",
          "US10249507B2",
          "US20110265883A1",
          "US5463460A",
          "US8822876B2",
          "US20170229315A1",
          "US10079165B2",
          "US8475625B2",
          "US9236255B2",
          "US8617347B2",
          "US6933243B2",
          "US20150228513A1",
          "US7883633B2",
          "US7838432B2",
          "US7988815B2",
          "US20160204009A1",
          "US9768034B1",
          "US10629473B2",
          "US6027601A",
          "US20160307742A1",
          "US8048806B2",
          "US20160043099A1",
          "US9627231B2",
          "US9132436B2",
          "US10730798B2",
          "US8092605B2",
          "US20190180981A1",
          "US20140335695A1",
          "US10460915B2",
          "US10755900B2",
          "US8084757B2",
          "US6309978B1",
          "US9653311B1",
          "US10249526B2",
          "US20190295824A1",
          "US9896376B2",
          "US20170250060A1",
          "US8206829B2",
          "US20150371889A1",
          "US9812341B2",
          "US7541292B2",
          "US6015476A",
          "US8274645B2",
          "US9378978B2",
          "US6984585B2",
          "US7771895B2",
          "US6432259B1",
          "US20140345803A1",
          "US20130334199A1",
          "US9850161B2",
          "US20140356768A1",
          "US10818564B2",
          "US8980760B2",
          "US8492980B2",
          "US10249470B2",
          "US6919168B2",
          "US10643854B2",
          "US10553485B2",
          "US20150170943A1",
          "US20180323042A1",
          "US9312106B2",
          "US7942969B2",
          "US20110008969A1",
          "US6491835B1",
          "US9741546B2",
          "US8927390B2",
          "US6921723B1",
          "US6776873B1",
          "US20160149733A1",
          "US8123902B2",
          "US6933239B2",
          "US5465154A",
          "US8114525B2",
          "US6566272B2",
          "US10283321B2",
          "US20120322235A1",
          "US20180105922A1",
          "US8932947B1",
          "US7077973B2",
          "US10199224B2",
          "US9604249B2",
          "US8757603B2",
          "US7504041B2",
          "US20170229289A1",
          "US6413867B1",
          "US6528751B1",
          "US10580651B2",
          "US8916793B2",
          "US9960776B2",
          "US10177023B2",
          "US9412608B2",
          "US6712927B1",
          "US20190242480A1",
          "US20190027394A1",
          "US20150345029A1",
          "US7838434B2",
          "US20150371861A1",
          "US8287650B2",
          "US6356097B1",
          "US20180046206A1",
          "US6370006B1",
          "US9425058B2",
          "US8894805B2",
          "US7981812B2",
          "US20180096825A1",
          "US9659803B2",
          "US8368308B2",
          "US20180061679A1",
          "US20070068456A1",
          "US7316199B2",
          "US8002899B2",
          "US8143138B2",
          "US8647990B2",
          "US8017029B2",
          "US20200024194A1",
          "US8568553B2",
          "US9257265B2",
          "US7682518B2",
          "US6513452B2",
          "US10366867B2",
          "US9214315B2",
          "US5350488A",
          "US10699921B2",
          "US9576788B2",
          "US7771894B2",
          "US10260160B2",
          "US10622194B2",
          "US6589437B1",
          "US6074488A",
          "US7846254B2",
          "US6911399B2",
          "US10734202B2",
          "US8992689B2",
          "US6726804B2",
          "US6310755B1",
          "US10607817B2",
          "US20140209244A1",
          "US8454756B2",
          "US20200051825A1",
          "US7115523B2",
          "US9305810B2",
          "US9472379B2",
          "US10794681B2",
          "US10062575B2",
          "US20200312630A1",
          "US8632689B2",
          "US20070202706A1",
          "US6852639B2",
          "US10170336B1",
          "US9338871B2",
          "US9773648B2",
          "US9711334B2",
          "US20190228952A1",
          "US20080176149A1",
          "US9070633B2",
          "US5392018A",
          "US9412579B2",
          "US9711366B2",
          "US10347522B2",
          "US8419893B2",
          "US20120094499A1",
          "US20200234981A1",
          "US10141166B2",
          "US9883549B2",
          "US8231736B2",
          "US7987814B2",
          "US5716484A",
          "US20140367043A1",
          "US10096496B2",
          "US9425062B2",
          "US9617188B2",
          "US9190247B2",
          "US9553102B2",
          "US20200035535A1",
          "US6192827B1",
          "US20170316963A1",
          "US10654147B2",
          "US20090214825A1",
          "US10170278B2",
          "US8747684B2",
          "US9735002B2",
          "US9663870B2",
          "US6475335B1",
          "US6554954B2",
          "US6972154B2",
          "US20200135458A1",
          "US8878926B2",
          "US8652297B2",
          "US20200185256A1",
          "US9872341B2",
          "US9090046B2",
          "US9248509B2",
          "US9673069B2",
          "US8314033B2",
          "US20180105932A1",
          "US20190198392A1",
          "US9196514B2",
          "US20190267213A1",
          "US10276354B2",
          "US6500299B1",
          "US9799491B2",
          "US9691645B2",
          "US8293656B2",
          "US9887121B2",
          "US10090181B2",
          "US9659751B2",
          "US6642127B2",
          "US10718719B2",
          "US20200294836A1",
          "US10544500B2",
          "US6278600B1",
          "US10424467B2",
          "US20180151401A1",
          "US10546729B2",
          "US7365014B2",
          "US20150221535A1",
          "US8980758B1",
          "US20180337021A1",
          "US6613682B1",
          "US9184055B2",
          "US20190105751A1",
          "US20140158048A1",
          "US20180093919A1",
          "US10811233B2",
          "US7977245B2",
          "US9874524B2",
          "US20140253887A1",
          "US20100186663A1",
          "US5916689A",
          "US9831097B2",
          "US10373810B2",
          "US6897154B2",
          "US20100018648A1",
          "US20120138472A1",
          "US10582570B2",
          "US8633423B2",
          "US9275887B2",
          "US7955516B2",
          "US7994872B2",
          "US10364197B2",
          "US8987139B2",
          "US10062579B2",
          "US8226769B2",
          "US9669653B2",
          "US20180108517A1",
          "US20200027717A1",
          "US20180261429A1",
          "US9443700B2",
          "US10283329B2",
          "US20140357083A1",
          "US10128086B1",
          "US9355856B2",
          "US10410845B2",
          "US10403534B2",
          "US10196728B2",
          "US10468235B2",
          "US10440777B2",
          "US20140151331A1",
          "US8009938B2",
          "US7097716B2",
          "US10147620B2",
          "US7967996B2",
          "US10551328B2",
          "US6607675B1",
          "US10593560B2",
          "US20130045605A1",
          "US8237517B2",
          "US10332772B2",
          "US20160042968A1",
          "US10103010B2",
          "US9659791B2",
          "US9685374B1",
          "US20130098872A1",
          "US20200124399A1",
          "US20170121813A1",
          "US10490429B2",
          "US20190119815A1",
          "US8771539B2",
          "US10707061B2",
          "US20120118510A1",
          "US6462481B1",
          "US7780862B2",
          "US10773995B2",
          "US10672591B2",
          "US10204795B2",
          "US6248250B1",
          "US7780864B2",
          "US8663391B2",
          "US8092695B2",
          "US7632419B1",
          "US20070006971A1",
          "US6694915B1",
          "US10818561B2",
          "US20070295455A1",
          "US7879186B2",
          "US9947517B1",
          "US6352049B1",
          "US20130122712A1",
          "US9754765B2",
          "US10186428B2",
          "US6964928B2",
          "US20180366378A1",
          "US5883778A",
          "US7846347B2",
          "US6838635B2",
          "US6913652B2",
          "US10319600B1",
          "US6603538B1",
          "US5491603A",
          "US6265318B1",
          "US9916967B2",
          "US10157731B2",
          "US20160132042A1",
          "US5427621A",
          "US6695946B2",
          "US20190233343A1",
          "US20190362983A1",
          "US20180100228A1",
          "US5756400A",
          "US10672642B2",
          "US8877001B2",
          "US8329593B2",
          "US10418229B2",
          "US6921727B2",
          "US6617794B2",
          "US20160181067A1",
          "US20190157047A1",
          "US6818562B2",
          "US10770568B2",
          "US8759223B2",
          "US6494958B1",
          "US7813103B2",
          "US20170323813A1",
          "US7695633B2",
          "US6592707B2",
          "US6406924B1",
          "US6706138B2",
          "US9896770B2",
          "US20200013588A1",
          "US9287134B2",
          "US6625497B2",
          "US20200075368A1",
          "US9831111B2",
          "US7270761B2",
          "US10745805B2",
          "US7964818B2",
          "US7975558B2",
          "US20090221149A1",
          "US9520303B2",
          "US7510665B2",
          "US9362131B2",
          "US7359177B2",
          "US20080280212A9",
          "US9597734B2",
          "US9162236B2",
          "US10615047B2",
          "US6846746B2",
          "US10062585B2",
          "US9059398B2",
          "US9017765B2",
          "US10131994B2",
          "US6447637B1",
          "US20120318773A1",
          "US8871312B2",
          "US8668837B2",
          "US10249479B2",
          "US9761416B2",
          "US9890086B2",
          "US20190198295A1",
          "US8801952B1",
          "US7879151B2",
          "US7521000B2",
          "US9373546B2",
          "US20190088543A1",
          "US9892888B2",
          "US10811296B2",
          "US10283320B2",
          "US9553147B2",
          "US10297458B2",
          "US20190189474A1",
          "US9257293B2",
          "US20200043734A1",
          "US20180292756A1",
          "US9478434B2",
          "US9312104B2",
          "US20180061616A1",
          "US8440049B2",
          "US6353210B1",
          "US10811226B2",
          "US5685916A",
          "US20150107618A1",
          "US9653320B2",
          "US7510976B2",
          "US20130260564A1",
          "US6081334A",
          "US9299583B1",
          "US9396989B2",
          "US20200135491A1",
          "US7520999B2",
          "US20110265884A1",
          "US9373517B2",
          "US20130276980A1",
          "US7919722B2",
          "US10256075B2",
          "US9111877B2",
          "US10829855B2",
          "US10312048B2",
          "US9870897B2",
          "US5522131A",
          "US10067070B2",
          "US9610591B2",
          "US6991739B2",
          "US9852903B2",
          "US7808651B2",
          "US20120291952A1",
          "US9881805B2",
          "US9916998B2",
          "US9358702B2",
          "US6900596B2",
          "US9687953B2",
          "US20190252154A1",
          "US9991109B2",
          "US7605008B2",
          "US20200276785A1",
          "US6709609B2",
          "US20190189399A1",
          "US20130098555A1",
          "US20200215566A1",
          "US9948214B2",
          "US7846845B2",
          "US20190259580A1",
          "US10607815B2",
          "US10699879B2",
          "US10504697B2",
          "US20170358431A1",
          "US9355862B2",
          "US20180265972A1",
          "US10586696B2",
          "US7942965B2",
          "US9287124B2",
          "US6614051B1",
          "US6409933B1",
          "US9023227B2",
          "US20140311581A1",
          "US7253115B2",
          "US5187454A",
          "US9824862B2",
          "US9502258B2",
          "US10541171B2",
          "US10580620B2",
          "US20100276391A1",
          "US8858745B2",
          "US9805939B2",
          "US9281190B2",
          "US10017857B2",
          "US7479464B2",
          "US9330887B2",
          "US20180251406A1",
          "US9884787B2",
          "US20190214229A1",
          "US10083834B2",
          "US10504700B2",
          "US9837284B2",
          "US10448495B1",
          "US7743670B2",
          "US20200271442A1",
          "US6129807A",
          "US9287093B2",
          "US6942813B2",
          "US20170221783A1",
          "US5980686A",
          "US10026597B2",
          "US20190348258A1",
          "US9153442B2",
          "US9978632B2",
          "US6027606A",
          "US6617216B1",
          "US10410921B2",
          "US9362130B2",
          "US10468276B2",
          "US6391790B1",
          "US20180151402A1",
          "US9355922B2",
          "US10697061B2",
          "US10625277B2",
          "US20170352565A1",
          "US10465294B2",
          "US7838430B2",
          "US20160237570A1",
          "US7585685B2",
          "US9437451B2",
          "US20190198389A1",
          "US7946759B2",
          "US10170277B2",
          "US20070048882A1",
          "US9412603B2",
          "US7264688B1",
          "US20180213608A1",
          "US10593518B1",
          "US20150380220A1",
          "US6486492B1",
          "US9064816B2",
          "US6914009B2",
          "US20200266022A1",
          "US20190148121A1",
          "US7780866B2",
          "US20170365443A1",
          "US9613783B2",
          "US20070017898A1",
          "US20180166311A1",
          "US8110086B2",
          "US10083816B2",
          "US10820378B2",
          "US9653291B2",
          "US9343327B2",
          "US9240359B2",
          "US6767824B2",
          "US20180061618A1",
          "US6414648B1",
          "US20180061696A1",
          "US20190382888A1",
          "US20190204032A1",
          "US8130382B2",
          "US20190302174A1",
          "US8808563B2",
          "US8527081B2",
          "US7967930B2",
          "US9460898B2",
          "US5585012A",
          "US10784089B2",
          "US10504765B2",
          "US20080110567A1",
          "US10256079B2",
          "US9263278B2",
          "US10770328B2",
          "US7521370B2",
          "US20180269039A1",
          "US20150380215A1",
          "US20160155657A1",
          "US10818481B2",
          "US9070536B2",
          "US9837249B2",
          "US7976671B2",
          "US6353206B1",
          "US9685356B2",
          "US7846849B2",
          "US8596336B2",
          "US20110253670A1",
          "US5746875A",
          "US6535779B1",
          "US8956886B2",
          "US20170092470A1",
          "US20130224960A1",
          "US10510602B2",
          "US7775236B2",
          "US9034770B2",
          "US20190385824A1",
          "US9123762B2",
          "US7846846B2",
          "US9449850B2",
          "US9960052B2",
          "US6960416B2",
          "US20190264314A1",
          "US20200091018A1",
          "US9627216B2",
          "US10008404B2",
          "US7807064B2",
          "US9299580B2",
          "US9117855B2",
          "US8876024B2",
          "US5885469A",
          "US5423918A",
          "US20170069466A1",
          "US20180337047A1",
          "US9593421B2",
          "US7374696B2",
          "US10563297B2",
          "US10535544B2",
          "US20190276366A1",
          "US6931619B2",
          "US8118938B2",
          "US20180053630A1",
          "US10790120B2",
          "US20130292057A1",
          "US7326872B2",
          "US6916399B1",
          "US10593553B2",
          "US9659753B2",
          "US6599842B2",
          "US8076247B2",
          "US9472417B2",
          "US20150294843A1",
          "US10577690B2",
          "US8017526B2",
          "US6685798B1",
          "US7419551B2",
          "US6388253B1",
          "US7969581B2",
          "US8801896B2",
          "US20180366306A1",
          "US20200238303A1",
          "US5849136A",
          "US6593244B1",
          "US8895889B2",
          "US10465288B2",
          "US8043433B2",
          "US7374636B2",
          "US10608145B2",
          "US20200075370A1",
          "US9082590B2",
          "US8992792B2",
          "US20120000888A1",
          "US6652712B2",
          "US6312554B1",
          "US20190078199A1",
          "US9753463B2",
          "US8679982B2",
          "US7846497B2",
          "US9978564B2",
          "US5783101A",
          "US20090272717A1",
          "US8932959B2",
          "US10573496B2",
          "US9449794B2",
          "US7544251B2",
          "US7910013B2",
          "US9721760B2",
          "US8048328B2",
          "US20110198034A1",
          "US20130273313A1",
          "US20190221441A1",
          "US9607856B2",
          "US9960019B2",
          "US10593523B2",
          "US20130240144A1",
          "US7812278B2",
          "US10249475B2",
          "US20190326100A1",
          "US9846130B2",
          "US9299574B2",
          "US20140202634A1",
          "US5777289A",
          "US20160027673A1",
          "US10688750B2",
          "US8512509B2",
          "US10385459B2",
          "US6297468B1",
          "US8236133B2",
          "US6894245B2",
          "US7758763B2",
          "US8158526B2",
          "US9614524B1",
          "US8980754B2",
          "US6036877A",
          "US10381200B2",
          "US9269587B2",
          "US9349605B1",
          "US8382999B2",
          "US20180096819A1",
          "US20130344701A1",
          "US7128806B2",
          "US7220937B2",
          "US10784132B2",
          "US5753137A",
          "US9440886B2",
          "US20140209242A1",
          "US5641375A",
          "US10791617B2",
          "US20130277333A1",
          "US9646807B2",
          "US10685862B2",
          "US10446418B2"
        ],
        "publications": [
          "US9309598B2",
          "US9681497B2",
          "US8074677B2",
          "US10460916B2",
          "US20190078206A1",
          "US10096494B2",
          "US20190108974A1",
          "US6598615B1",
          "US20150228503A1",
          "US7306696B2",
          "US10670393B1",
          "US20180366351A1",
          "US20130092655A1",
          "US10453656B2",
          "US9305797B2",
          "US7972469B2",
          "US9362148B2",
          "US8951429B1",
          "US9805965B2",
          "US20180259848A1",
          "US9200950B2",
          "US8753474B2",
          "US9040422B2",
          "US6557248B1",
          "US9202708B1",
          "US6613689B2",
          "US10580657B2",
          "US6582861B2",
          "US20180053631A1",
          "US8475674B2",
          "US10177018B2",
          "US5423945A",
          "US9039908B2",
          "US9478433B1",
          "US10555412B2",
          "US9447365B2",
          "US20140224767A1",
          "US20190206660A1",
          "US6831742B1",
          "US10522380B2",
          "US10283324B1",
          "US6635573B2",
          "US20190279893A1",
          "US10679885B2",
          "US20130040231A1",
          "US7276447B1",
          "US7648914B2",
          "US9875923B2",
          "US20130288474A1",
          "US10319624B2",
          "US9589832B2",
          "US9741593B2",
          "US20170011891A1",
          "US8206552B2",
          "US20190088518A1",
          "US9558981B2",
          "US8987140B2",
          "US20190371577A1",
          "US20200185200A1",
          "US9725799B2",
          "US20180277340A1",
          "US20200294771A1",
          "US20190078204A1",
          "US10189141B2",
          "US20180010234A1",
          "US10008368B2",
          "US20190085467A1",
          "US5938943A",
          "US10403507B2",
          "US9601396B2",
          "US20200243368A1",
          "US5737177A",
          "US6727655B2",
          "US20140248718A1",
          "US10546731B1",
          "US20150299050A1",
          "US9548201B2",
          "US9754800B2",
          "US7196283B2",
          "US9418867B2",
          "US20150183187A1",
          "US20170092525A1",
          "US8337661B2",
          "US20150170879A1",
          "US20200185192A1",
          "US20180134612A1",
          "US20190326123A1",
          "US6185839B1",
          "US20100028813A1",
          "US10600688B2",
          "US9852923B2",
          "US9624593B2",
          "US6676760B2",
          "US20190341236A1",
          "US9155134B2",
          "US20140065797A1",
          "US6575622B2",
          "US9318304B2",
          "US20190043697A1",
          "US20160148821A1",
          "US6635578B1",
          "US8334657B2",
          "US10796922B2",
          "US9034199B2",
          "US20170301520A1",
          "US20190363003A1",
          "US9872373B1",
          "US10497567B2",
          "US8937800B2",
          "US7815812B2",
          "US20130107415A1",
          "US8158522B2",
          "US9161428B2",
          "US20200161155A1",
          "US9093371B2",
          "US10522371B2",
          "US8771536B2",
          "US10714372B2",
          "US10825708B2",
          "US6072685A",
          "US20200118845A1",
          "US9343272B1",
          "US5919382A",
          "US10373804B2",
          "US7645357B2",
          "US9728437B2",
          "US20140179108A1",
          "US9570275B2",
          "US10319603B2",
          "US10685807B2",
          "US7807578B2",
          "US9305804B2",
          "US8313578B2",
          "US20200291528A1",
          "US20180366302A1",
          "US5907221A",
          "US9520294B2",
          "US10544505B2",
          "US6251217B1",
          "US9165786B1",
          "US8880227B2",
          "US20180261516A1",
          "US7585384B2",
          "US6355557B2",
          "US8980763B2",
          "US8765234B2",
          "US5683517A",
          "US6581275B2",
          "US20200024735A1",
          "US9850568B2",
          "US6960413B2",
          "US5925212A",
          "US20160056059A1",
          "US20130122707A1",
          "US9984912B2",
          "US20130098873A1",
          "US20110151590A1",
          "US6673636B2",
          "US6403491B1",
          "US9155184B2",
          "US20200066556A1",
          "US7943005B2",
          "US20100108263A1",
          "US7229930B2",
          "US20200181771A1",
          "US8765574B2",
          "US7833401B2",
          "US9583369B2",
          "US9359679B2",
          "US5552016A",
          "US9896769B2",
          "US10537013B2",
          "US9472435B2",
          "US6005376A",
          "US10410841B2",
          "US20070134416A1",
          "US6559942B2",
          "US7094704B2",
          "US7727413B2",
          "US5614060A",
          "US8921234B2",
          "US10424507B2",
          "US7777599B2",
          "US20200227276A1",
          "US20180076049A1",
          "US20200035454A1",
          "US10490406B2",
          "US9945033B2",
          "US8931512B2",
          "US20200027742A1",
          "US9209012B2",
          "US6440864B1",
          "US10026636B2",
          "US10170280B2",
          "US20100099266A1",
          "US8993419B1",
          "US9999907B2",
          "US10497579B2",
          "US20200027769A1",
          "US20150181684A1",
          "US10283397B2",
          "US9653282B2",
          "US9613822B2",
          "US9564341B1",
          "US8133817B2",
          "US9299537B2",
          "US9543163B2",
          "US7635648B2",
          "US8734664B2",
          "US20070091535A1",
          "US9159574B2",
          "US10153139B2",
          "US7868556B2",
          "US10475626B2",
          "US9390923B2",
          "US10204781B1",
          "US8080479B2",
          "US8133819B2",
          "US20190295826A1",
          "US9976211B2",
          "US20140273451A1",
          "US9281226B2",
          "US8034734B2",
          "US6692903B2",
          "US10790123B2",
          "US9869012B2",
          "US10424487B2",
          "US7431795B2",
          "US6730174B2",
          "US10395904B2",
          "US7030335B2",
          "US9885117B2",
          "US10604831B2",
          "US20200185248A1",
          "US10121688B2",
          "US6352611B1",
          "US20120088371A1",
          "US10692880B2",
          "US10438796B2",
          "US20180016678A1",
          "US9449843B1",
          "US10584068B2",
          "US9666466B2",
          "US7674353B2",
          "US8741775B2",
          "US7718539B2",
          "US10727096B2",
          "US20140199833A1",
          "US10553400B2",
          "US10573555B2",
          "US5772832A",
          "US6942929B2",
          "US7967944B2",
          "US6949203B2",
          "US10119188B2",
          "US8802572B2",
          "US8840725B2",
          "US7624003B2",
          "US9735037B2",
          "US20110304078A1",
          "US20120241091A1",
          "US20180218873A1",
          "US6123791A",
          "US9496167B2",
          "US10770321B2",
          "US10163696B2",
          "US8066895B2",
          "US7737042B2",
          "US9601301B2",
          "US20120255635A1",
          "US9788405B2",
          "US10319591B2",
          "US10224210B2",
          "US10578510B2",
          "US20200176233A1",
          "US20170352567A1",
          "US9957192B2",
          "US20070254483A1",
          "US6633391B1",
          "US10811232B2",
          "US20140011337A1",
          "US20090014323A1",
          "US10336656B2",
          "US8722547B2",
          "US20200037399A1",
          "US7901952B2",
          "US9540731B2",
          "US8293430B2",
          "US8608852B2",
          "US5900062A",
          "US8501626B2",
          "US8367227B2",
          "US9406523B2",
          "US10595365B2",
          "US5910221A",
          "US5710486A",
          "US10707086B2",
          "US7265382B2",
          "US7672110B2",
          "US9484220B2",
          "US20140190632A1",
          "US9909213B2",
          "US10177002B2",
          "US10504754B2",
          "US20200273728A1",
          "US8773020B2",
          "US8513939B2",
          "US5688357A",
          "US5753044A",
          "US10727075B2",
          "US10770269B2",
          "US9842744B2",
          "US10319636B2",
          "US9240315B1",
          "US9708713B2",
          "US9564296B2",
          "US5676759A",
          "US9236266B2",
          "US6379575B1",
          "US7094613B2",
          "US20130098553A1",
          "US20160133441A1",
          "US9520315B2",
          "US8089046B2",
          "US10242888B2",
          "US9136273B1",
          "US20150064809A1",
          "US20180061684A1",
          "US20180254203A1",
          "US5507874A",
          "US8741778B2",
          "US6538872B1",
          "US8707754B2",
          "US20170345691A1",
          "US5900064A",
          "US8778204B2",
          "US7909961B2",
          "US20170226637A1",
          "US10109464B2",
          "US9472378B2",
          "US10395896B2",
          "US20170140900A1",
          "US7972467B2",
          "US8002946B2",
          "US20200163159A1",
          "US8129029B2",
          "US6320736B1",
          "US9493879B2",
          "US5484485A",
          "US7018934B2",
          "US8647438B2",
          "US5883017A",
          "US9385028B2",
          "US10354843B2",
          "US6415198B1",
          "US9536769B1",
          "US20190090338A1",
          "US7718538B2",
          "US10510540B2",
          "US20180254208A1",
          "US10535502B2",
          "US9530637B2",
          "US10056284B2",
          "US9236265B2",
          "US20180204747A1",
          "US10224180B2",
          "US8709953B2",
          "US20180197758A1",
          "US10573497B2",
          "US7011039B1",
          "US6361644B1",
          "US7910488B2",
          "US20200118844A1",
          "US9390964B2",
          "US7618516B2",
          "US7601272B2",
          "US7105361B2",
          "US10745807B2",
          "US6020686A",
          "US6572732B2",
          "US9903020B2",
          "US20140083978A1",
          "US6507155B1",
          "US10615006B2",
          "US20190157113A1",
          "US8740206B2",
          "US5349313A",
          "US6592709B1",
          "US9646843B2",
          "US8933628B2",
          "US7746473B2",
          "US7554334B2",
          "US9023734B2",
          "US10780447B2",
          "US5612850A",
          "US6504126B2",
          "US7879731B2",
          "US10813370B2",
          "US20190189330A1",
          "US9093389B2",
          "US6490146B2",
          "US10186444B2",
          "US9653310B1",
          "US10354889B2",
          "US9966240B2",
          "US10242908B2",
          "US20200234923A1",
          "US9368369B2",
          "US10410900B2",
          "US9947549B1",
          "US9420639B2",
          "US7431797B2",
          "US8956809B2",
          "US20200152423A1",
          "US6478924B1",
          "US6613666B2",
          "US20160042961A1",
          "US9378969B2",
          "US7431857B2",
          "US9922840B2",
          "US6368517B1",
          "US8083963B2",
          "US7718029B2",
          "US9472410B2",
          "US5451784A",
          "US8282736B2",
          "US8257546B2",
          "US20110094683A1",
          "US7250309B2",
          "US8951384B2",
          "US20110180233A1",
          "US20160068969A1",
          "US20140034239A1",
          "US10453696B2",
          "US5893643A",
          "US8974684B2",
          "US20180337026A1",
          "US20200312680A1",
          "US20190333776A1",
          "US20160258064A1",
          "US20200234921A1",
          "US9488315B2",
          "US6639783B1",
          "US5730801A",
          "US20180010235A1",
          "US10535549B2",
          "US20180261686A1",
          "US10319604B2",
          "US10510575B2",
          "US20050067103A1",
          "US9839109B1",
          "US20140069584A1",
          "US20190088531A1",
          "US6077384A",
          "US20090221150A1",
          "US20200075367A1",
          "US7674394B2",
          "US10541184B2",
          "US8920597B2",
          "US20200066563A1",
          "US10490418B2",
          "US9653267B2",
          "US6773544B2",
          "US9885567B2",
          "US9449846B2",
          "US10242847B2",
          "US20200083047A1",
          "US9177824B2",
          "US20170229291A1",
          "US9299582B2",
          "US5683539A",
          "US8969212B2",
          "US20120103526A1",
          "US20190348317A1",
          "US9850573B1",
          "US20180053666A1",
          "US20120154974A1",
          "US8501629B2",
          "US10497573B2",
          "US10020218B2",
          "US6589879B2",
          "US6562189B1",
          "US7422654B2",
          "US20130298942A1",
          "US20120285621A1",
          "US8279577B2",
          "US9925639B2",
          "US10656194B2",
          "US8956500B2",
          "US6369349B2",
          "US6033478A",
          "US9960049B2",
          "US6280584B1",
          "US10443126B1",
          "US6518195B1",
          "US9978606B2",
          "US6524432B1",
          "US7244334B2",
          "US20180327892A1",
          "US7955986B2",
          "US8148977B2",
          "US6458516B1",
          "US8473247B2",
          "US9068265B2",
          "US6432830B1",
          "US6015761A",
          "US9051219B2",
          "US20200111643A1",
          "US5942889A",
          "US7368394B2",
          "US20200051839A1",
          "US7846848B2",
          "US9218997B2",
          "US6304424B1",
          "US6462928B1",
          "US20170350017A1",
          "US10790153B2",
          "US6583065B1",
          "US9111722B2",
          "US10170279B2",
          "US20190070639A1",
          "US20140273460A1",
          "US9449845B2",
          "US10283370B1",
          "US20140335679A1",
          "US20190221463A1",
          "US10468233B2",
          "US10553404B2",
          "US7105100B2",
          "US20190194817A1",
          "US10204805B2",
          "US9721807B2",
          "US20110120651A1",
          "US8187415B2",
          "US20190139743A1",
          "US20130008604A1",
          "US20170350018A1",
          "US5891350A",
          "US10347500B1",
          "US9991134B2",
          "US9214377B2",
          "US10607816B2",
          "US20180211811A1",
          "US7964512B2",
          "US10774436B2",
          "US6444084B1",
          "US9218944B2",
          "US10566206B2",
          "US20180319205A1",
          "US10233547B2",
          "US9093390B2",
          "US10586718B2",
          "US6716302B2",
          "US6759286B2",
          "US7316761B2",
          "US9373522B1",
          "US20200243303A1",
          "US7658969B2",
          "US10431435B2",
          "US7309448B2",
          "US20140083362A1",
          "US20200286717A1",
          "US7479304B2",
          "US7779784B2",
          "US10256112B1",
          "US20190189401A1",
          "US20200103294A1",
          "US9165804B2",
          "US8623527B2",
          "US10658161B2",
          "US6653237B2",
          "US9064812B2",
          "US10790180B2",
          "US6009830A",
          "US9419107B2",
          "US9741545B2",
          "US8435902B2",
          "US9564297B2",
          "US10010912B2",
          "US8970226B2",
          "US7807579B2",
          "US10177017B1",
          "US7695983B2",
          "US9280051B2",
          "US6853141B2",
          "US5948703A",
          "US20180337074A1",
          "US9536710B2",
          "US9269590B2",
          "US20110269314A1",
          "US6900133B2",
          "US20110236806A1",
          "US6198616B1",
          "US20200168463A1",
          "US20090159566A1",
          "US6264852B1",
          "US9267742B2",
          "US8264154B2",
          "US6362109B1",
          "US10424464B2",
          "US6168672B1",
          "US20080213496A1",
          "US20100101729A1",
          "US7256134B2",
          "US6983892B2",
          "US10304715B2",
          "US8075729B2",
          "US10424485B2",
          "US20190096634A1",
          "US20200066493A1",
          "US20190330748A1",
          "US20190272998A1",
          "US20200185203A1",
          "US20200017972A1",
          "US6490145B1",
          "US8541312B2",
          "US20090188624A1",
          "US10468282B2",
          "US10319649B2",
          "US8067067B2",
          "US10049891B1",
          "US9911579B2",
          "US8679983B2",
          "US8603921B2",
          "US10431429B2",
          "US7655571B2",
          "US7795153B2",
          "US10163606B2",
          "US10685861B2",
          "US10770329B2",
          "US10043674B1",
          "US20170110292A1",
          "US20170271179A1",
          "US20180122655A1",
          "US20170213758A1",
          "US10056233B2",
          "US20110097901A1",
          "US8513889B2",
          "US20150311043A1",
          "US8546263B2",
          "US8202441B2",
          "US7371467B2",
          "US10497578B2",
          "US10770267B1",
          "US8778207B2",
          "US10790126B2",
          "US9114438B2",
          "US20140034241A1",
          "US6849554B2",
          "US20200140620A1",
          "US10386126B2",
          "US8349128B2",
          "US8398814B2",
          "US7846264B2",
          "US10796888B2",
          "US9622375B2",
          "US20120222813A1",
          "US20100327873A1",
          "US6589869B2",
          "US20100140222A1",
          "US6054013A",
          "US6368975B1",
          "US20150083042A1",
          "US9443753B2",
          "US8236105B2",
          "US10204796B2",
          "US20170314125A1",
          "US10546728B2",
          "US7879250B2",
          "US9159606B1",
          "US8772162B2",
          "US8580693B2",
          "US9287095B2",
          "US6270617B1",
          "US9394615B2",
          "US8988848B2",
          "US7737040B2",
          "US10763081B2",
          "US7553679B2",
          "US10177050B2",
          "US8758858B2",
          "US10403542B2",
          "US8623672B2",
          "US10290469B2",
          "US10593594B2",
          "US8916021B2",
          "US10468285B2",
          "US10265742B2",
          "US10504746B2",
          "US9991148B2",
          "US20150214066A1",
          "US10573527B2",
          "US20190382880A1",
          "US10062587B2",
          "US5780359A",
          "US6623596B1",
          "US9666414B2",
          "US9576815B2",
          "US6818140B2",
          "US7786019B2",
          "US10012248B2",
          "US8529776B2",
          "US8578879B2",
          "US7829471B2",
          "US5451290A",
          "US8016948B2",
          "US9368370B2",
          "US5995235A",
          "US7482178B2",
          "US20200087776A1",
          "US20190122861A1",
          "US10131126B2",
          "US6444040B1",
          "US7768765B2",
          "US20200075290A1",
          "US10770270B2",
          "US9972477B2",
          "US5641359A",
          "US20190136372A1",
          "US10577286B2",
          "US10553397B2",
          "US10026621B2",
          "US9212099B2",
          "US7250373B2",
          "US8211808B2",
          "US9478432B2",
          "US9704723B2",
          "US20200090907A1",
          "US20180112311A1",
          "US20200090972A1",
          "US20150064880A1",
          "US6605177B2",
          "US20100180426A1",
          "US8956980B1",
          "US9418858B2",
          "US5574410A",
          "US8435419B2",
          "US8270141B2",
          "US9184021B2",
          "US7354866B2",
          "US6369348B2",
          "US20190304827A1",
          "US10763150B2",
          "US20160260588A1",
          "US6685799B2",
          "US20150200042A1",
          "US6902681B2",
          "US6898065B2",
          "US10443125B2",
          "US20200152425A1",
          "US10607867B2",
          "US9384997B2",
          "US6602434B1",
          "US9305748B2",
          "US8920599B2",
          "US7375947B2",
          "US7696117B2",
          "US9449796B2",
          "US7431859B2",
          "US8895449B1",
          "US8252410B2",
          "US9082591B2",
          "US10186400B2",
          "US8324525B2",
          "US6893533B2",
          "US10062578B2",
          "US9970095B2",
          "US10573493B2",
          "US7115517B2",
          "US20150060013A1",
          "US8562742B2",
          "US20190311884A1",
          "US7620511B2",
          "US6460550B2",
          "US10468267B2",
          "US8445078B2",
          "US6036878A",
          "US9275834B1",
          "US10636629B2",
          "US6238588B1",
          "US8360003B2",
          "US20160215883A1",
          "US20070000611A1",
          "US5910011A",
          "US8101525B2",
          "US9721789B1",
          "US7736914B2",
          "US10211033B2",
          "US6273022B1",
          "US10676819B2",
          "US20190341233A1",
          "US6686293B2",
          "US10032604B2",
          "US10636675B2",
          "US10755941B2",
          "US5534108A",
          "US9299538B2",
          "US8658541B2",
          "US7682984B2",
          "US8629370B2",
          "US9012302B2",
          "US9412563B2",
          "US7575007B2",
          "US10766824B2",
          "US20200035463A1",
          "US10153133B2",
          "US9355863B2",
          "US9343289B2",
          "US10395964B2",
          "US6586886B1",
          "US20110217832A1",
          "US6944006B2",
          "US20200152431A1",
          "US5242538A",
          "US7718559B2",
          "US20190244792A1",
          "US9928987B2",
          "US7695987B2",
          "US5885469B1",
          "US10217627B2",
          "US20190371578A1",
          "US7375038B2",
          "US6325861B1",
          "US8845816B2",
          "US9595451B1",
          "US9745663B2",
          "US9267739B2",
          "US7790334B2",
          "US10612121B2",
          "US10453655B2",
          "US20130134129A1",
          "US9879348B2",
          "US9245762B2",
          "US10274270B2",
          "US20120018402A1",
          "US5737178A",
          "US20100015357A1",
          "US6361705B1",
          "US8101025B2",
          "US10770349B2",
          "US6500357B1",
          "US20160099162A1",
          "US7968469B2",
          "US8642481B2",
          "US20200234971A1",
          "US6673199B1",
          "US10453694B2",
          "US9472416B2",
          "US6924191B2",
          "US8383002B2",
          "US10692728B2",
          "US8871650B2",
          "US20200140996A1",
          "US10271416B2",
          "US20080179282A1",
          "US9779953B2",
          "US9887096B2",
          "US10636690B2",
          "US20190157115A1",
          "US8012366B2",
          "US6401652B1",
          "US6632321B2",
          "US9472434B2",
          "US9012030B2",
          "US10448494B1",
          "US10566205B2",
          "US7067432B2",
          "US7034963B2",
          "US7141757B2",
          "US10727058B2",
          "US20160276196A1",
          "US20160005833A1",
          "US8231799B2",
          "US20070026665A1",
          "US20130344285A1",
          "US9805914B2",
          "US7884025B2",
          "US10361080B2",
          "US10037883B2",
          "US9659792B2",
          "US9934942B1",
          "US9587789B2",
          "US9165783B2",
          "US20200118798A1",
          "US7879185B2",
          "US9287147B2",
          "US20190393053A1",
          "US10410889B2",
          "US7943006B2",
          "US9190293B2",
          "US20190127280A1",
          "US20170175284A1",
          "US6647918B1",
          "US6592673B2",
          "US7754610B2",
          "US7635546B2",
          "US20130105085A1",
          "US6623595B1",
          "US8357264B2",
          "US7649729B2",
          "US8961804B2",
          "US5866483A",
          "US10656029B2",
          "US8404598B2",
          "US20200234928A1",
          "US20190078200A1",
          "US7540971B2",
          "US7186943B2",
          "US6962644B2",
          "US20200118829A1",
          "US5545289A",
          "US9869013B2",
          "US5643394A",
          "US10501843B2",
          "US20100224322A1",
          "US20170110475A1",
          "US6387287B1",
          "US20110094994A1",
          "US8905074B2",
          "US8962488B2",
          "US9023732B2",
          "US7771606B2",
          "US9850591B2",
          "US20190341289A1",
          "US6481886B1",
          "US9287386B2",
          "US7132618B2",
          "US20060000802A1",
          "US6120640A",
          "US20170256435A1",
          "US9520302B2",
          "US9754767B2",
          "US20200098586A1",
          "US8937021B2",
          "US20190271076A1",
          "US9773695B2",
          "US6490144B1",
          "US20150221481A1",
          "US20200013632A1",
          "US7436645B2",
          "US20200013610A1",
          "US20200194242A1",
          "US8333842B2",
          "US20150079370A1",
          "US20190105837A1",
          "US8299391B2",
          "US9472412B2",
          "US6677712B2",
          "US10557202B2",
          "US9196462B2",
          "US20150001180A1",
          "US20170329352A1",
          "US20190385891A1",
          "US10679870B2",
          "US7879510B2",
          "US6373022B2",
          "US8999856B2",
          "US8440019B2",
          "US20190362944A1",
          "US10008366B2",
          "US20200234922A1",
          "US20130309785A1",
          "US9741539B2",
          "US6449871B1",
          "US20200075369A1",
          "US20130059403A1",
          "US10563303B2",
          "US7832354B2",
          "US6818096B2",
          "US20180040457A1",
          "US10600623B2",
          "US8414736B2",
          "US7718543B2",
          "US10504702B2",
          "US6652710B2",
          "US20150075717A1",
          "US20170229326A1",
          "US20180358244A1",
          "US20200118861A1",
          "US9378930B2",
          "US20110079918A1",
          "US7848840B2",
          "US20200066495A1",
          "US10115572B2",
          "US7754997B2",
          "US7585778B2",
          "US10460921B2",
          "US20090142247A1",
          "US20200144067A1",
          "US8936696B2",
          "US5790365A",
          "US5792562A",
          "US9129777B2",
          "US20150042017A1",
          "US20130288037A1",
          "US9978596B2",
          "US20190189492A1",
          "US20140099794A1",
          "US9533332B2",
          "US9514953B2",
          "US20190338418A1",
          "US7217665B2",
          "US8607731B2",
          "US8313664B2",
          "US10553398B2",
          "US7262865B2",
          "US8002945B2",
          "US9293568B2",
          "US8617351B2",
          "US10242893B2",
          "US20170352566A1",
          "US20200283897A1",
          "US7221553B2",
          "US9324576B2",
          "US9595423B2",
          "US7670688B2",
          "US5336363A",
          "US6572814B2",
          "US20140263173A1",
          "US20140271097A1",
          "US20180342375A1",
          "US20190206712A1",
          "US8940642B2",
          "US8656953B2",
          "US8232212B2",
          "US10424463B2",
          "US9741566B2",
          "US10490435B2",
          "US6599437B2",
          "US10820377B2",
          "US10727080B2",
          "US20110130007A1",
          "US20190385880A1",
          "US20090112520A1",
          "US20130284369A1",
          "US10774006B2",
          "US10378108B2",
          "US9818621B2",
          "US10032606B2",
          "US8097088B1",
          "US10043684B1",
          "US6380095B1",
          "US6598559B1",
          "US20160225652A1",
          "US9006106B2",
          "US20200087788A1",
          "US20190067070A1",
          "US9698015B2",
          "US20200152500A1",
          "US20130098552A1",
          "US8771423B2",
          "US10460968B2",
          "US20190157114A1",
          "US20110265951A1",
          "US9405287B1",
          "US20170221750A1",
          "US20180274100A1",
          "US20190263722A1",
          "US8941969B2",
          "US8616224B2",
          "US6924088B2",
          "US10020170B2",
          "US9576809B2",
          "US6943039B2",
          "US5880037A",
          "US10170282B2",
          "US10340124B2",
          "US10008399B2",
          "US8982530B2",
          "US7169625B2",
          "US20110201134A1",
          "US6016131A",
          "US9064815B2",
          "US9748366B2",
          "US7879183B2",
          "US20190214230A1",
          "US9499898B2",
          "US6521080B2",
          "US20200161098A1",
          "US9481608B2",
          "US10784085B2",
          "US9975758B2",
          "US9502218B2",
          "US10622229B2",
          "US7320942B2",
          "US9406540B2",
          "US20190131113A1",
          "US9508831B2",
          "US9368364B2",
          "US20130284372A1",
          "US10256123B2",
          "US9299575B2",
          "US6896763B2",
          "US6068784A",
          "US7371485B2",
          "US5399237A",
          "US7829243B2",
          "US9390937B2",
          "US9540736B2",
          "US8617672B2",
          "US6641697B2",
          "US20190019655A1",
          "US8778574B2",
          "US9639097B2",
          "US9129911B2",
          "US20110303639A1",
          "US8721798B2",
          "US5540824A",
          "US7777152B2",
          "US20180337057A1",
          "US7848898B2",
          "US10770346B2",
          "US8382939B2",
          "US9269546B2",
          "US20190169444A1",
          "US9580806B2",
          "US6326597B1",
          "US9797037B2",
          "US20130315795A1",
          "US9209279B1",
          "US7244313B1",
          "US7790047B2",
          "US20190326099A1",
          "US10559451B2",
          "US6613691B1",
          "US20200190653A1",
          "US10115566B2",
          "US7286948B1",
          "US20200066566A1",
          "US20150371865A1",
          "US9725302B1",
          "US9865434B2",
          "US20180226232A9",
          "US8018164B2",
          "US8034180B2",
          "US7828987B2",
          "US9520314B2",
          "US8496756B2",
          "US20160155612A1",
          "US6508911B1",
          "US8748322B1",
          "US9564338B1",
          "US5459632A",
          "US7838433B2",
          "US7247218B2",
          "US8616043B2",
          "US7652774B2",
          "US9401310B2",
          "US9947559B2",
          "US6677242B1",
          "US20180337024A1",
          "US10600639B2",
          "US10249507B2",
          "US20110265883A1",
          "US5463460A",
          "US8822876B2",
          "US20170229315A1",
          "US10079165B2",
          "US8475625B2",
          "US9236255B2",
          "US8617347B2",
          "US6933243B2",
          "US20150228513A1",
          "US7883633B2",
          "US7838432B2",
          "US7988815B2",
          "US20160204009A1",
          "US9768034B1",
          "US10629473B2",
          "US6027601A",
          "US20160307742A1",
          "US8048806B2",
          "US20160043099A1",
          "US9627231B2",
          "US9132436B2",
          "US10730798B2",
          "US8092605B2",
          "US20190180981A1",
          "US20140335695A1",
          "US10460915B2",
          "US10755900B2",
          "US8084757B2",
          "US6309978B1",
          "US9653311B1",
          "US10249526B2",
          "US20190295824A1",
          "US9896376B2",
          "US20170250060A1",
          "US8206829B2",
          "US20150371889A1",
          "US9812341B2",
          "US7541292B2",
          "US6015476A",
          "US8274645B2",
          "US9378978B2",
          "US6984585B2",
          "US7771895B2",
          "US6432259B1",
          "US20140345803A1",
          "US20130334199A1",
          "US9850161B2",
          "US20140356768A1",
          "US10818564B2",
          "US8980760B2",
          "US8492980B2",
          "US10249470B2",
          "US6919168B2",
          "US10643854B2",
          "US10553485B2",
          "US20150170943A1",
          "US20180323042A1",
          "US9312106B2",
          "US7942969B2",
          "US20110008969A1",
          "US6491835B1",
          "US9741546B2",
          "US8927390B2",
          "US6921723B1",
          "US6776873B1",
          "US20160149733A1",
          "US8123902B2",
          "US6933239B2",
          "US5465154A",
          "US8114525B2",
          "US6566272B2",
          "US10283321B2",
          "US20120322235A1",
          "US20180105922A1",
          "US8932947B1",
          "US7077973B2",
          "US10199224B2",
          "US9604249B2",
          "US8757603B2",
          "US7504041B2",
          "US20170229289A1",
          "US6413867B1",
          "US6528751B1",
          "US10580651B2",
          "US8916793B2",
          "US9960776B2",
          "US10177023B2",
          "US9412608B2",
          "US6712927B1",
          "US20190242480A1",
          "US20190027394A1",
          "US20150345029A1",
          "US7838434B2",
          "US20150371861A1",
          "US8287650B2",
          "US6356097B1",
          "US20180046206A1",
          "US6370006B1",
          "US9425058B2",
          "US8894805B2",
          "US7981812B2",
          "US20180096825A1",
          "US9659803B2",
          "US8368308B2",
          "US20180061679A1",
          "US20070068456A1",
          "US7316199B2",
          "US8002899B2",
          "US8143138B2",
          "US8647990B2",
          "US8017029B2",
          "US20200024194A1",
          "US8568553B2",
          "US9257265B2",
          "US7682518B2",
          "US6513452B2",
          "US10366867B2",
          "US9214315B2",
          "US5350488A",
          "US10699921B2",
          "US9576788B2",
          "US7771894B2",
          "US10260160B2",
          "US10622194B2",
          "US6589437B1",
          "US6074488A",
          "US7846254B2",
          "US6911399B2",
          "US10734202B2",
          "US8992689B2",
          "US6726804B2",
          "US6310755B1",
          "US10607817B2",
          "US20140209244A1",
          "US8454756B2",
          "US20200051825A1",
          "US7115523B2",
          "US9305810B2",
          "US9472379B2",
          "US10794681B2",
          "US10062575B2",
          "US20200312630A1",
          "US8632689B2",
          "US20070202706A1",
          "US6852639B2",
          "US10170336B1",
          "US9338871B2",
          "US9773648B2",
          "US9711334B2",
          "US20190228952A1",
          "US20080176149A1",
          "US9070633B2",
          "US5392018A",
          "US9412579B2",
          "US9711366B2",
          "US10347522B2",
          "US8419893B2",
          "US20120094499A1",
          "US20200234981A1",
          "US10141166B2",
          "US9883549B2",
          "US8231736B2",
          "US7987814B2",
          "US5716484A",
          "US20140367043A1",
          "US10096496B2",
          "US9425062B2",
          "US9617188B2",
          "US9190247B2",
          "US9553102B2",
          "US20200035535A1",
          "US6192827B1",
          "US20170316963A1",
          "US10654147B2",
          "US20090214825A1",
          "US10170278B2",
          "US8747684B2",
          "US9735002B2",
          "US9663870B2",
          "US6475335B1",
          "US6554954B2",
          "US6972154B2",
          "US20200135458A1",
          "US8878926B2",
          "US8652297B2",
          "US20200185256A1",
          "US9872341B2",
          "US9090046B2",
          "US9248509B2",
          "US9673069B2",
          "US8314033B2",
          "US20180105932A1",
          "US20190198392A1",
          "US9196514B2",
          "US20190267213A1",
          "US10276354B2",
          "US6500299B1",
          "US9799491B2",
          "US9691645B2",
          "US8293656B2",
          "US9887121B2",
          "US10090181B2",
          "US9659751B2",
          "US6642127B2",
          "US10718719B2",
          "US20200294836A1",
          "US10544500B2",
          "US6278600B1",
          "US10424467B2",
          "US20180151401A1",
          "US10546729B2",
          "US7365014B2",
          "US20150221535A1",
          "US8980758B1",
          "US20180337021A1",
          "US6613682B1",
          "US9184055B2",
          "US20190105751A1",
          "US20140158048A1",
          "US20180093919A1",
          "US10811233B2",
          "US7977245B2",
          "US9874524B2",
          "US20140253887A1",
          "US20100186663A1",
          "US5916689A",
          "US9831097B2",
          "US10373810B2",
          "US6897154B2",
          "US20100018648A1",
          "US20120138472A1",
          "US10582570B2",
          "US8633423B2",
          "US9275887B2",
          "US7955516B2",
          "US7994872B2",
          "US10364197B2",
          "US8987139B2",
          "US10062579B2",
          "US8226769B2",
          "US9669653B2",
          "US20180108517A1",
          "US20200027717A1",
          "US20180261429A1",
          "US9443700B2",
          "US10283329B2",
          "US20140357083A1",
          "US10128086B1",
          "US9355856B2",
          "US10410845B2",
          "US10403534B2",
          "US10196728B2",
          "US10468235B2",
          "US10440777B2",
          "US20140151331A1",
          "US8009938B2",
          "US7097716B2",
          "US10147620B2",
          "US7967996B2",
          "US10551328B2",
          "US6607675B1",
          "US10593560B2",
          "US20130045605A1",
          "US8237517B2",
          "US10332772B2",
          "US20160042968A1",
          "US10103010B2",
          "US9659791B2",
          "US9685374B1",
          "US20130098872A1",
          "US20200124399A1",
          "US20170121813A1",
          "US10490429B2",
          "US20190119815A1",
          "US8771539B2",
          "US10707061B2",
          "US20120118510A1",
          "US6462481B1",
          "US7780862B2",
          "US10773995B2",
          "US10672591B2",
          "US10204795B2",
          "US6248250B1",
          "US7780864B2",
          "US8663391B2",
          "US8092695B2",
          "US7632419B1",
          "US20070006971A1",
          "US6694915B1",
          "US10818561B2",
          "US20070295455A1",
          "US7879186B2",
          "US9947517B1",
          "US6352049B1",
          "US20130122712A1",
          "US9754765B2",
          "US10186428B2",
          "US6964928B2",
          "US20180366378A1",
          "US5883778A",
          "US7846347B2",
          "US6838635B2",
          "US6913652B2",
          "US10319600B1",
          "US6603538B1",
          "US5491603A",
          "US6265318B1",
          "US9916967B2",
          "US10157731B2",
          "US20160132042A1",
          "US5427621A",
          "US6695946B2",
          "US20190233343A1",
          "US20190362983A1",
          "US20180100228A1",
          "US5756400A",
          "US10672642B2",
          "US8877001B2",
          "US8329593B2",
          "US10418229B2",
          "US6921727B2",
          "US6617794B2",
          "US20160181067A1",
          "US20190157047A1",
          "US6818562B2",
          "US10770568B2",
          "US8759223B2",
          "US6494958B1",
          "US7813103B2",
          "US20170323813A1",
          "US7695633B2",
          "US6592707B2",
          "US6406924B1",
          "US6706138B2",
          "US9896770B2",
          "US20200013588A1",
          "US9287134B2",
          "US6625497B2",
          "US20200075368A1",
          "US9831111B2",
          "US7270761B2",
          "US10745805B2",
          "US7964818B2",
          "US7975558B2",
          "US20090221149A1",
          "US9520303B2",
          "US7510665B2",
          "US9362131B2",
          "US7359177B2",
          "US20080280212A9",
          "US9597734B2",
          "US9162236B2",
          "US10615047B2",
          "US6846746B2",
          "US10062585B2",
          "US9059398B2",
          "US9017765B2",
          "US10131994B2",
          "US6447637B1",
          "US20120318773A1",
          "US8871312B2",
          "US8668837B2",
          "US10249479B2",
          "US9761416B2",
          "US9890086B2",
          "US20190198295A1",
          "US8801952B1",
          "US7879151B2",
          "US7521000B2",
          "US9373546B2",
          "US20190088543A1",
          "US9892888B2",
          "US10811296B2",
          "US10283320B2",
          "US9553147B2",
          "US10297458B2",
          "US20190189474A1",
          "US9257293B2",
          "US20200043734A1",
          "US20180292756A1",
          "US9478434B2",
          "US9312104B2",
          "US20180061616A1",
          "US8440049B2",
          "US6353210B1",
          "US10811226B2",
          "US5685916A",
          "US20150107618A1",
          "US9653320B2",
          "US7510976B2",
          "US20130260564A1",
          "US6081334A",
          "US9299583B1",
          "US9396989B2",
          "US20200135491A1",
          "US7520999B2",
          "US20110265884A1",
          "US9373517B2",
          "US20130276980A1",
          "US7919722B2",
          "US10256075B2",
          "US9111877B2",
          "US10829855B2",
          "US10312048B2",
          "US9870897B2",
          "US5522131A",
          "US10067070B2",
          "US9610591B2",
          "US6991739B2",
          "US9852903B2",
          "US7808651B2",
          "US20120291952A1",
          "US9881805B2",
          "US9916998B2",
          "US9358702B2",
          "US6900596B2",
          "US9687953B2",
          "US20190252154A1",
          "US9991109B2",
          "US7605008B2",
          "US20200276785A1",
          "US6709609B2",
          "US20190189399A1",
          "US20130098555A1",
          "US20200215566A1",
          "US9948214B2",
          "US7846845B2",
          "US20190259580A1",
          "US10607815B2",
          "US10699879B2",
          "US10504697B2",
          "US20170358431A1",
          "US9355862B2",
          "US20180265972A1",
          "US10586696B2",
          "US7942965B2",
          "US9287124B2",
          "US6614051B1",
          "US6409933B1",
          "US9023227B2",
          "US20140311581A1",
          "US7253115B2",
          "US5187454A",
          "US9824862B2",
          "US9502258B2",
          "US10541171B2",
          "US10580620B2",
          "US20100276391A1",
          "US8858745B2",
          "US9805939B2",
          "US9281190B2",
          "US10017857B2",
          "US7479464B2",
          "US9330887B2",
          "US20180251406A1",
          "US9884787B2",
          "US20190214229A1",
          "US10083834B2",
          "US10504700B2",
          "US9837284B2",
          "US10448495B1",
          "US7743670B2",
          "US20200271442A1",
          "US6129807A",
          "US9287093B2",
          "US6942813B2",
          "US20170221783A1",
          "US5980686A",
          "US10026597B2",
          "US20190348258A1",
          "US9153442B2",
          "US9978632B2",
          "US6027606A",
          "US6617216B1",
          "US10410921B2",
          "US9362130B2",
          "US10468276B2",
          "US6391790B1",
          "US20180151402A1",
          "US9355922B2",
          "US10697061B2",
          "US10625277B2",
          "US20170352565A1",
          "US10465294B2",
          "US7838430B2",
          "US20160237570A1",
          "US7585685B2",
          "US9437451B2",
          "US20190198389A1",
          "US7946759B2",
          "US10170277B2",
          "US20070048882A1",
          "US9412603B2",
          "US7264688B1",
          "US20180213608A1",
          "US10593518B1",
          "US20150380220A1",
          "US6486492B1",
          "US9064816B2",
          "US6914009B2",
          "US20200266022A1",
          "US20190148121A1",
          "US7780866B2",
          "US20170365443A1",
          "US9613783B2",
          "US20070017898A1",
          "US20180166311A1",
          "US8110086B2",
          "US10083816B2",
          "US10820378B2",
          "US9653291B2",
          "US9343327B2",
          "US9240359B2",
          "US6767824B2",
          "US20180061618A1",
          "US6414648B1",
          "US20180061696A1",
          "US20190382888A1",
          "US20190204032A1",
          "US8130382B2",
          "US20190302174A1",
          "US8808563B2",
          "US8527081B2",
          "US7967930B2",
          "US9460898B2",
          "US5585012A",
          "US10784089B2",
          "US10504765B2",
          "US20080110567A1",
          "US10256079B2",
          "US9263278B2",
          "US10770328B2",
          "US7521370B2",
          "US20180269039A1",
          "US20150380215A1",
          "US20160155657A1",
          "US10818481B2",
          "US9070536B2",
          "US9837249B2",
          "US7976671B2",
          "US6353206B1",
          "US9685356B2",
          "US7846849B2",
          "US8596336B2",
          "US20110253670A1",
          "US5746875A",
          "US6535779B1",
          "US8956886B2",
          "US20170092470A1",
          "US20130224960A1",
          "US10510602B2",
          "US7775236B2",
          "US9034770B2",
          "US20190385824A1",
          "US9123762B2",
          "US7846846B2",
          "US9449850B2",
          "US9960052B2",
          "US6960416B2",
          "US20190264314A1",
          "US20200091018A1",
          "US9627216B2",
          "US10008404B2",
          "US7807064B2",
          "US9299580B2",
          "US9117855B2",
          "US8876024B2",
          "US5885469A",
          "US5423918A",
          "US20170069466A1",
          "US20180337047A1",
          "US9593421B2",
          "US7374696B2",
          "US10563297B2",
          "US10535544B2",
          "US20190276366A1",
          "US6931619B2",
          "US8118938B2",
          "US20180053630A1",
          "US10790120B2",
          "US20130292057A1",
          "US7326872B2",
          "US6916399B1",
          "US10593553B2",
          "US9659753B2",
          "US6599842B2",
          "US8076247B2",
          "US9472417B2",
          "US20150294843A1",
          "US10577690B2",
          "US8017526B2",
          "US6685798B1",
          "US7419551B2",
          "US6388253B1",
          "US7969581B2",
          "US8801896B2",
          "US20180366306A1",
          "US20200238303A1",
          "US5849136A",
          "US6593244B1",
          "US8895889B2",
          "US10465288B2",
          "US8043433B2",
          "US7374636B2",
          "US10608145B2",
          "US20200075370A1",
          "US9082590B2",
          "US8992792B2",
          "US20120000888A1",
          "US6652712B2",
          "US6312554B1",
          "US20190078199A1",
          "US9753463B2",
          "US8679982B2",
          "US7846497B2",
          "US9978564B2",
          "US5783101A",
          "US20090272717A1",
          "US8932959B2",
          "US10573496B2",
          "US9449794B2",
          "US7544251B2",
          "US7910013B2",
          "US9721760B2",
          "US8048328B2",
          "US20110198034A1",
          "US20130273313A1",
          "US20190221441A1",
          "US9607856B2",
          "US9960019B2",
          "US10593523B2",
          "US20130240144A1",
          "US7812278B2",
          "US10249475B2",
          "US20190326100A1",
          "US9846130B2",
          "US9299574B2",
          "US20140202634A1",
          "US5777289A",
          "US20160027673A1",
          "US10688750B2",
          "US8512509B2",
          "US10385459B2",
          "US6297468B1",
          "US8236133B2",
          "US6894245B2",
          "US7758763B2",
          "US8158526B2",
          "US9614524B1",
          "US8980754B2",
          "US6036877A",
          "US10381200B2",
          "US9269587B2",
          "US9349605B1",
          "US8382999B2",
          "US20180096819A1",
          "US20130344701A1",
          "US7128806B2",
          "US7220937B2",
          "US10784132B2",
          "US5753137A",
          "US9440886B2",
          "US20140209242A1",
          "US5641375A",
          "US10791617B2",
          "US20130277333A1",
          "US9646807B2",
          "US10685862B2",
          "US10446418B2"
        ]
      }
    }
  ],
  "technology_partitioning": {
    "type": "classifier",
    "classifier_sources": [
      {
        "id": "d3b58ddc1da6c3822d6eebd556b4339482faad88__XL_efcd89e00ae815cc",
        "name": "Application Specific Ics: Audio and Video Processing",
        "path": [
          "AST Product Taxonomy",
          "Application Specific Ics"
        ],
        "version": "latest",
        "description": "The category includes patents related to circuits involved in processing of audio/video signals."
      }
    ],
    "multi_label": false,
    "negatives_processing": {type: 'include'},
    "threshold": 0.5
  },
  "meta": {
    "type": "classifier_evaluation",
    "evaluation_classifier_id": "6567268c-e3fd-4632-b69b-ffe8054ae851"
  },
  "input_schema_version": "1",
  "data_source_id": "default"
}