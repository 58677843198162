import React, { Component } from 'react'
import Joyride from 'react-joyride'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'

import { CIPHER_RED } from '../constants/colours.js'
import { get_joyride_steps, joyride_callback, joyride_fix } from '../utils/joyride_utils.js'
import { fetch_user_settings } from '../utils/user_settings_utils.js'
import { HAS_VIEWED_INTRO_TOUR } from '../model/user_settings.js'
import { send_error_to_sentry } from '../utils/sentry_utils.js'
import { track_user_event } from '../utils/tracking_utils.js'
import { withUser } from './UserContext.js'


const SHOW_TOUR_PARAM = 'show_tour'

class WelcomeTour extends Component {
  constructor(props) {
    super(props)

    this.state = {
      run_tour: false,
      joyride_key: 0
    }

    this.callback = this.callback.bind(this)
    this.do_welcome_tour = this.do_welcome_tour.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    const query_params = qs.parse(location.search)
    if (query_params[SHOW_TOUR_PARAM]) {
      this.do_welcome_tour()
      return
    }

    fetch_user_settings()
      .then(user_settings => {
        const has_viewed_intro_tour = user_settings[HAS_VIEWED_INTRO_TOUR]

        if (!has_viewed_intro_tour) {
          // User has not yet seen the tour, so show it to them.
          this.do_welcome_tour()
        }
      })
      .catch(error => {
        // No point in notifying user.
        // But log error in sentry.
        send_error_to_sentry(error, {})
      })

  }

  do_welcome_tour() {
    track_user_event('obj="welcome-tour" action="start"')
    const { joyride_key } = this.state // This is a hack to force React to unmount Joyride each time tour is triggered.// This allows us to trigger the tour multiple times if required.

    this.setState({ run_tour: true, joyride_key: joyride_key + 1}, joyride_fix())
  }

  callback(tour) {
    const {history, location} = this.props
    joyride_callback(tour, history, location, SHOW_TOUR_PARAM)
  }

  render() {
    const {run_tour, joyride_key} = this.state
    const {user} = this.props

    const steps = get_joyride_steps(user)

    return (
      <Joyride
        key={joyride_key}
        beaconComponent={() => { return null }} // seems like this is the only way to disable the beacon (flashing things).
        // i.e. disableBeacon flag messes up the "close" button
        // see https://github.com/gilbarbara/react-joyride/issues/357
        steps={steps}
        run={run_tour}
        callback={this.callback}
        continuous={true} // show multiple steps with "next/back" buttons
        disableOverlayClose={true}
        showSkipButton={false}
        locale={{last: 'Finish'}}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 10000
            }
          }
        }}
        styles={{
          tooltipTitle: {
            fontWeight: 'bold',
            textAlign: 'center',
          },
          tooltipContainer: {
            textAlign: 'left',
          },
          tooltipFooter: {
            marginTop: 0,
            //justifyContent: 'flex-end', // align buttons to the right. But breaks IE11 (so leave for now)
          },
          options: {
            zIndex: 10000,
            primaryColor: CIPHER_RED
          }
        }}
      />
    )
  }
}

export default withRouter(withUser(WelcomeTour))