import React from 'react'
import cn from 'classnames'

import PatentFamilyListActions from '../../patent_family_list/PatentFamilyListActions.js'
import { FAMILIES_TO_DOWNLOAD_THRESHOLD } from '../../../utils/patent_family_list_utils.js'
import { track_download_event } from '../../../utils/tracking_utils.js'
import { CSV_FILE_EXT, get_clean_filename, MIMETYPE_CSV, trigger_download } from '../../../utils/download_utils.js'
import { is_status_not_found, to_csv } from './matched_patents_table_utils.js'
import { ID_TO_STATUS, IGNORE, STATUS } from './matched_patents_table_model.js'
import { COLUMNS } from './table_columns.js'

const MatchedPatentsExportControls = (
  {
    lines,
    input_file,
    disabled,

    className
  }) => {

  const total_lines = (lines || []).length
  function download_as_csv() {
    track_download_event('obj="patent_upload" format="CSV"')
    const prefix = input_file ? input_file.name.split('.').slice(0, -1).join('.') : null
    const filename = get_clean_filename( `${prefix ? `${prefix}_` : ''}matches` + CSV_FILE_EXT)
    trigger_download(get_data_as_csv(), MIMETYPE_CSV, filename)
  }

  function get_data_as_csv() {
    const columns = COLUMNS.map(column => column.label)
    const data = lines.map(line => {
      return COLUMNS.map(column => column.field).map((field, i) => {
        const value = line[field]
        if (field === STATUS) {
          return ID_TO_STATUS[value].value
        }

        if (field === IGNORE) {
          return is_status_not_found(line[STATUS])? '-' : (value ? 'Yes' : 'No')
        }

        return value || '-'
      })
    })

    return to_csv([columns, ...data])
  }

  return (
    <div className={cn('d-flex', className)}>
      <PatentFamilyListActions
        download_as_csv_handler={download_as_csv}
        list_no_results={(total_lines === 0) || disabled}
        list_too_long={total_lines > FAMILIES_TO_DOWNLOAD_THRESHOLD}
      />
    </div>
  )
}

export default MatchedPatentsExportControls