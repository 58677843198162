import {Autocomplete, capitalize, Skeleton, TextField} from '@mui/material'
import {DataGridPro, GridColumns, GridRowId, GridRowParams, GridSortModel} from '@mui/x-data-grid-pro'
import cn from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'
import {withRouter} from 'react-router'
import _ from 'underscore'
import {Nav, NavItem} from 'reactstrap'

import {HISTORY, PROJECT, REPORT} from '../../constants/paths.js'
import {useInterval} from '../../hooks/general_hooks.js'
import {DESCENDING} from '../../model/sort_directions.js'
import {
  add_project_files,
  add_project_reports,
  delete_project_files,
  get_enum_key_by_value,
  get_project,
  get_project_file_history,
  get_project_files,
  get_project_reports,
  PostEntityPermission,
  PostProjectPermissions,
  remove_project_reports,
  update_project_permissions,
  update_user_project_settings,
} from '../../utils/project_and_versioning_utils'
import {fetch_report_history, fetch_report_owners} from '../../utils/report_history_utils.js'
import {get_report_viewer_url, report_has_bespoke_viewer} from '../../utils/report_url_utils.js'
import {send_error_to_sentry} from '../../utils/sentry_utils'
import {get_long_date_string} from '../../utils/time_utils.js'
import {track_project_viewer_event} from '../../utils/tracking_utils.js'
import {
  fetch_all_active_keycloak_groups,
  fetch_all_active_keycloak_users,
  fetch_my_keycloak_groups, fetch_users_in_keycloak_group
} from '../../utils/user_group_utils.js'
import {is_admin, is_aistemos} from '../../utils/user_permissions.js'
import {get_as_map} from '../../utils/utils.js'
import ContainerFixedWidth from '../ContainerFixedWidth.js'
import ErrorModal from '../ErrorModal.js'
import PageControl from '../PageControl.js'
import PageSizeControl from '../PageSizeControl.js'
import ReportManagementTable from '../report_management/components/ReportManagementTable.js'
import {NO_FILTER} from '../report_management/model/filters.js'
import {PAGE_SIZES} from '../report_management/model/page_sizes.js'
import {
  CREATED_AT_FIELD_ID,
  ID_TO_REPORT_FIELD,
  IS_SELECTED_FIELD_ID,
  NAME_FIELD_ID,
  OWNER_FIELD_ID,
  STATUS_FIELD_ID
} from '../report_management/model/report_fields.js'
import {
  filter_reports,
  get_new_selected_external_report_ids,
  sort_reports
} from '../report_management/utils/sort_and_filter_utils.js'

import {withUser} from '../UserContext'
import {PrimaryButton} from '../widgets/Button.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import {
  BallotCheckIcon,
  ExpandClosedIcon,
  ExpandOpenedIcon,
  GroupIcon,
  TrashIcon,
  UnlinkIcon,
  UploadIcon,
  UserIcon
} from '../widgets/IconSet.js'
import Modal from '../widgets/Modal.js'
import {PaneHeader} from '../widgets/PaneHeader.js'
import Spinner from '../widgets/Spinner.js'
import TextLink, {NavLink} from '../widgets/TextLink.js'
import FileUploadDropzone from './FileUploadDropzone'
import {Entity, EntityPermissions, EntityType, Project, ProjectUserViewState, Report, UserFile} from './model/project'
import ItemHeaderUndoButton from '../viewer/ItemHeaderUndoButton.js'
import {
  DownloadProjectFile,
  DownloadAllProjectFiles
} from './ProjectControls.js'
import {PROJECT_TRACKING_CONTEXT} from '../../constants/project.js'

import s from './ProjectView.module.scss'

export interface ProjectViewProps {
  user?: any,
  project_id: string,
  history?: any
}

type UserFileHistoryMap = { [key: string]: UserFile[] }
type PermissionRow = Entity & EntityPermissions

const ProjectView = (props: ProjectViewProps) => {
  enum ProjectViewTabs {
    BackButton= 'back',
    ReportsAndFiles = 'reports_and_files',
    Permissions = 'permissions'
  }
  // Data
  const [project, set_project] = useState<Project | null>(null)
  const [project_files, set_project_files] = useState<UserFile[]>([])
  const [project_reports, set_project_reports] = useState<Report[]>([])

  const view_state: ProjectUserViewState = {
    reports_table: {
      sort_field_id: CREATED_AT_FIELD_ID,
      sort_direction_id: DESCENDING,
      page_size: Number.MAX_SAFE_INTEGER,
    },
    files_table: {
      sortModel: [{ field: 'filename', sort: 'asc' }]
    },
    permissions_table: {
      sortModel: [{ field: 'entity_type', sort: 'desc' }, {field: 'entity_id', sort: 'asc'}]
    }
  }

  const mui_table_classes = {
    root: s.custom_MuiDataGrid_root,
    cell: s.custom_MuiDataGrid_cell,
    columnHeaders: s.custom_MuiDataGrid_columnHeaders,
    columnHeader: s.custom_MuiDataGrid_columnHeader,
    virtualScroller: s.custom_MuiDataGrid_virtualScroller,
    iconButtonContainer: s.custom_MuiDataGrid_iconButtonContainer
  }
  const mui_details_table_classes = {
    ...mui_table_classes,
    root: s.custom_inner_MuiDataGrid_root,
    columnHeaders: s.files_history_table_header,
    virtualScroller: s.files_history_table_virtualScroller
  }

  // Conditional rendering state
  const [is_fetching, set_is_fetching] = useState<boolean>(true)
  const [can_view, set_can_view] = useState<boolean>(false)
  const [can_edit, set_can_edit] = useState<boolean>(false)
  const [current_tab_view, set_current_tab_view] = useState<ProjectViewTabs>(ProjectViewTabs.ReportsAndFiles)
  const [is_fetching_reports, set_is_fetching_reports] = useState<boolean>(false)
  const [is_fetching_files, set_is_fetching_files] = useState<boolean>(false)
  const [is_uploading_files, set_is_uploading_files] = useState<boolean>(false)
  const [is_deleting_file, set_is_deleting_file] = useState<boolean>(false)
  const [is_loading_files_history, set_is_loading_files_history] = useState<boolean>(false)
  const [is_loading_permission_rows,set_is_loading_permission_rows] = useState<boolean>(false)
  const [error_on_action, set_error_on_action] = useState<any>(null)

  // Report management table state
  const report_fields = [
    ...can_edit ? [IS_SELECTED_FIELD_ID] : [],
    NAME_FIELD_ID,
    OWNER_FIELD_ID,
    CREATED_AT_FIELD_ID,
    STATUS_FIELD_ID
  ].map(field_id => ID_TO_REPORT_FIELD[field_id])

  const [user_reports, set_user_reports] = useState<Report[]>([])
  const [sort_field_id, set_sort_field_id] = useState<string>(view_state.reports_table.sort_field_id)
  const [sort_direction_id, set_sort_direction_id] = useState<string>(view_state.reports_table.sort_direction_id)
  const [page_size, set_page_size] = useState<number>(view_state.reports_table.page_size)
  const [page_number, set_page_number] = useState<number>(0)
  const [num_pages, set_num_page] = useState<number>(0)

  // const [report_search_input, set_report_search_input] = useState('')
  const [selected_reports_external_ids, set_selected_reports_external_ids] = useState<string[]>([])
  const [report_owners, set_report_owners] = useState<{ [key: string]: string }>({})
  const [table_reports_view, set_table_reports_view] = useState<Report[]>([])
  const [is_select_all_reports, set_is_select_all_reports] = useState<boolean>()
  const [total_selected_reports, set_total_selected_reports] = useState<number>(0)

  function toggle_select_all_reports() {
    if (!is_select_all_reports) {
      track_project_viewer_event(`obj="project" action="select_all_reports" context="${PROJECT_TRACKING_CONTEXT}"`)
      set_selected_reports_external_ids(_.map(project_reports, p => p.external_report_id))
    } else {
      track_project_viewer_event(`obj="project" action="deselect_all_reports" context="${PROJECT_TRACKING_CONTEXT}"`)
      set_selected_reports_external_ids([])
    }
  }
  function toggle_selected_reports(reports_to_toggle: Report[], is_selected: boolean) {
    track_project_viewer_event(`obj="project" action="select_reports" context="${PROJECT_TRACKING_CONTEXT}"`)
    const selected_reports_external_ids_updated = get_new_selected_external_report_ids(
      reports_to_toggle.map(report => report.external_report_id),
      is_selected,
      selected_reports_external_ids
    )
    set_selected_reports_external_ids(selected_reports_external_ids_updated)
  }
  function on_change_sort_field_id_and_sort_direction_id(sort_field: string, sort_direction: string) {
    set_sort_field_id(sort_field)
    set_sort_direction_id(sort_direction)
  }
  async function on_unlink_reports_from_project() {
    if (project && can_edit) {
      track_project_viewer_event(`obj="project" action="remove_reports_from_project" context="${PROJECT_TRACKING_CONTEXT}"`)
      await remove_project_reports(project.project_id, {external_report_ids: selected_reports_external_ids})
      set_selected_reports_external_ids([])
      await load_project_data()
    }
  }

  useEffect(() => {
    const has_data_rows = project_reports.length > 0
    const start_index = page_number * page_size

    // Filter and paginate
    let new_reports_view = filter_reports(project_reports, report_owners, null, NO_FILTER, null, null)
    new_reports_view = sort_reports(new_reports_view, report_owners, sort_field_id, sort_direction_id)
    const pre_slice_count = new_reports_view.length
    new_reports_view = new_reports_view.slice(start_index, start_index + page_size)
    set_num_page(!has_data_rows ? 0 : Math.ceil(pre_slice_count / page_size))

    // Add the report owner name
    new_reports_view = new_reports_view.map((r: Report) => ({...r, owner_name: report_owners[r.external_report_id]}))
    set_table_reports_view(new_reports_view)
  }, [project_reports, report_owners, sort_field_id, sort_direction_id, page_size, page_number])
  useEffect(() => {
    set_is_select_all_reports(project_reports.length > 0 && project_reports.length === selected_reports_external_ids.length)
  }, [project_reports, selected_reports_external_ids])
  useEffect(() => {
    set_total_selected_reports(selected_reports_external_ids.length)
  }, [selected_reports_external_ids])
  useEffect(() => {
    set_page_number(0)
  }, [page_size])
  useEffect(() => {
    fetch_report_history(null, true, false)
      .then(set_user_reports)
  }, [project])

  // File management table state
  const files_fields: GridColumns = [
    {field: 'filename', headerName: 'Name', flex: 1, disableReorder: true, resizable: false,},
    {
      field: 'size', headerName: 'Size', flex: 1, disableReorder: true, resizable: false,
      valueFormatter: (params => {
        const value = Number(params.value)
        const size_in_kb = value * 0.001
        const size_in_mb = value * 0.000001
        return size_in_mb < 1 ? (size_in_kb.toFixed(2) + 'KB') : (size_in_mb.toFixed(2) + 'MB')
      })
    },
    {
      field: 'last_modified', headerName: 'Last Modified', flex: 1, disableReorder: true, resizable: false,
      valueFormatter: (params => get_long_date_string(params.value))
    },
    {
      field: '_', headerName: 'Actions', sortable: false, disableReorder: true, resizable: false,
      renderCell: function render_actions_cell(params) {
        const file_meta: UserFile = params.row

        const {s3_resource_id, filename} = file_meta || {}

        return (
          <div className={cn('d-flex', 'flex-wrap', 'flex-grow-1', 'align-items-right', 'justify-content-end')}>
            {/* @ts-ignore */}
            <DownloadProjectFile
              key={s3_resource_id}
              project_id={props.project_id}
              file_meta={file_meta}
              is_disabled={!can_view}

              className='mr-2'
            />
            {/* @ts-ignore */}
            <TextLink
              onClick={async () => {
                track_project_viewer_event(`obj="project" action="delete_file" context="${PROJECT_TRACKING_CONTEXT}"`)
                set_is_deleting_file(true)
                try {
                  await delete_project_files(props.project_id, {resource_ids: [s3_resource_id]})
                  set_project_files(_.filter(project_files, p => p.s3_resource_id !== s3_resource_id))
                  set_is_deleting_file(false)
                } catch (error) {
                  send_error_to_sentry(error, {})
                  set_is_deleting_file(false)
                  set_error_on_action({error, action:'deleting project file'})
                }
              }}
              title={`Delete ${filename}`}
              disable={!can_edit}
            >
              <TrashIcon/>
            </TextLink>
          </div>
        )
      }
    }
  ]

  const [is_file_upload_modal_open, set_is_file_upload_modal_open] = useState<boolean>(false)
  const [files_to_upload, set_files_to_upload] = useState<File[]>([])
  const [file_rows_expanded, set_file_rows_expanded] = useState<GridRowId[]>([])
  const [files_history, set_files_history] = useState<UserFileHistoryMap>({})
  const [files_table_sort_model, set_files_table_sort_model] = useState<GridSortModel>(view_state.files_table.sortModel)

  async function upload_project_files() {
    track_project_viewer_event(`obj="project" action="upload_files" context="${PROJECT_TRACKING_CONTEXT}"`)
    set_is_uploading_files(true)
    await add_project_files(props.project_id, files_to_upload)
    set_files_to_upload([])
    set_is_uploading_files(false)
    set_is_file_upload_modal_open(false)
  }
  const handle_detail_panel_expanded_row_ids_change = useCallback((new_ids: GridRowId[]) => {
    set_file_rows_expanded(new_ids)
  }, [])

  // Permissions management table state
  const permissions_fields: GridColumns = [
    { field: 'entity_type', headerName: 'Type', disableReorder: true, resizable: false, width: 80, headerAlign: 'center', align: 'center',
      renderCell: function render_entity_type_cell(params) {
        const perm_row: PermissionRow = params.row
        return (
          <div className={cn('d-flex', 'flex-wrap', 'flex-grow-1', 'align-items-center', 'justify-content-start', s.icon_cipher_red)}>
            {perm_row.entity_type === EntityType.Group && (
              // @ts-expect-error
              <TextLink>
                <GroupIcon />
                <span className={cn('ml-2')}>Group</span>
              </TextLink>
            )}
            {perm_row.entity_type === EntityType.User && (
              // @ts-expect-error
              <TextLink>
                <UserIcon/>
                <span className={cn('ml-2')}>User</span>
              </TextLink>
            )}
          </div>
        )
      }
    },
    { field: 'entity_id', headerName: 'Name', flex: 1, disableReorder: true, resizable: false,
      renderCell: function render_entity_name_cell(params) {
        const perm_row: PermissionRow = params.row

        const group = perm_row.entity_type === EntityType.Group && all_active_groups[perm_row.entity_id]
        const user = perm_row.entity_type === EntityType.User && all_active_users[perm_row.entity_id]

        const user_display_text = user ? (user.firstName && user.lastName ? `${user.firstName} ${user.lastName} (${user.username})` : user.username) : 'Unknown'

        return (
          <span>
            {group && group.name}
            {!group && user_display_text}
          </span>
        )
      }
    },
    { field: 'edit', headerName: 'Edit', width: 30, sortable: false, disableReorder: true, resizable: false, headerAlign: 'center', align: 'center',
      renderCell: function render_checkbox_edit(params) {
        const perm_row: PermissionRow = params.row
        const row_copy = {...perm_row}
        return (
          // @ts-expect-error
          <CheckboxStatic
            onClick={() => {
              row_copy.edit = !perm_row.edit
              set_permissions_rows(_.union(_.filter(permissions_rows, r => r !== perm_row), [row_copy]))
            }}
            is_checked={perm_row.edit}
            is_disabled={perm_row.entity_id === props.user.user_id}
          />
        )
      }
    },
    { field: 'view', headerName: 'View', width: 30, sortable: false, disableReorder: true, resizable: false, headerAlign: 'center', align: 'center',
      renderCell: function render_checkbox_view(params) {
        const perm_row: PermissionRow = params.row
        const row_copy = {...perm_row}
        return (
          // @ts-expect-error
          <CheckboxStatic
            onClick={() => {
              row_copy.view = !perm_row.view
              set_permissions_rows(_.union(_.filter(permissions_rows, r => r !== perm_row), [row_copy]))
            }}
            is_checked={perm_row.view}
            is_disabled={perm_row.entity_id === props.user.user_id}
          />
        )
      }
    },
  ]
  const [current_project_permission_rows, set_current_project_permission_rows] = useState<PermissionRow[]>([])
  const [permissions_rows, set_permissions_rows] = useState<PermissionRow[]>([])
  const [updated_permissions_rows, set_updated_permissions_rows] = useState<PermissionRow[]>([])
  const [all_active_groups, set_all_active_groups] = useState<any>(null)
  const [all_active_users, set_all_active_users] = useState<any>(null)
  const [sharable_groups_users_list, set_sharable_groups_users_list] = useState<any[]>([])
  const [permissions_table_sort_model, set_permissions_table_sort_model] = useState<GridSortModel>(view_state.permissions_table.sortModel)

  async function on_update_project_permissions() {
    track_project_viewer_event(`obj="project" action="update_permissions" context="${PROJECT_TRACKING_CONTEXT}"`)
    set_is_loading_permission_rows(true)

    const new_user_perms: PostEntityPermission[] = _.map(
      _.filter(updated_permissions_rows, r => r.entity_type === EntityType.User),
      r => {return {entity_uuid: r.entity_id, permissions: {view: r.view, edit: r.edit }}}
    )
    const new_group_perms: PostEntityPermission[] = _.map(
      _.filter(updated_permissions_rows, r => r.entity_type === EntityType.Group),
      r => {return {entity_uuid: r.entity_id, permissions: {view: r.view, edit: r.edit }}}
    )
    const payload: PostProjectPermissions = {
      users: new_user_perms,
      groups: new_group_perms
    }
    try {
      await update_project_permissions(props.project_id, payload)
      await load_project_meta()
    } finally {
      set_is_loading_permission_rows(false)
    }
  }
  function are_permission_rows_identical(a: PermissionRow, b: PermissionRow) {
    return a.entity_id === b.entity_id && a.entity_type === b.entity_type && a.view === b.view && a.edit === b.edit
  }

  useEffect(() => {
    if (!project || !project.permissions) { return }

    set_is_loading_permission_rows(true)
    let current_permission_rows: PermissionRow[] = []

    for (const key of Object.keys(project.permissions.group)) {
      current_permission_rows.push({entity_id: key, entity_type: EntityType.Group, ...project.permissions.group[key]})
    }
    for (const key of Object.keys(project.permissions.user)) {
      current_permission_rows.push({entity_id: key, entity_type: EntityType.User, ...project.permissions.user[key]})
    }

    // Filter out  stuff that has view/edit false. Table keeps track but no need to display these in the ui
    current_permission_rows = _.filter(current_permission_rows, r => r.view || r.edit)

    set_permissions_rows(current_permission_rows)
    set_current_project_permission_rows(current_permission_rows)

    // Skip reload this if we have it already
    if (all_active_users && all_active_groups) {
      set_is_loading_permission_rows(false)
    } else {
      load_kc_users_groups()
        .then(() => {
          set_is_loading_permission_rows(false)
        })
        .catch(error => {
          send_error_to_sentry(error, {})
          set_is_loading_permission_rows(false)
          set_error_on_action({error, action: 'failed loading user share list'})
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])
  useEffect(() => {
    // Short circuit redundant updates when stuff is either undefined or empty
    if ((!all_active_users || !all_active_groups) || (all_active_users.length === 0 && all_active_groups.length === 0)) { return }

    const all_groups_arr = _.map(_.values(all_active_groups), g => {return {entity_type: EntityType.Group, ...g}})
    const all_users_arr = _.map(_.values(all_active_users), u => {return {entity_type: EntityType.User, ...u}})
    set_sharable_groups_users_list(_.union(all_groups_arr, all_users_arr))
  }, [all_active_users, all_active_groups])
  useEffect(() => {
    set_updated_permissions_rows(_.filter(permissions_rows,p => !_.find(current_project_permission_rows, c => are_permission_rows_identical(p,c))))
  }, [current_project_permission_rows, permissions_rows])
  useEffect(() => {
    if (!can_edit && current_tab_view === ProjectViewTabs.Permissions) {
      set_current_tab_view(ProjectViewTabs.ReportsAndFiles)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [can_edit])

  // Data load function and reload hooks
  async function get_report_owners() {
    try {
      set_report_owners(await fetch_report_owners())
    } catch (err) {
      send_error_to_sentry(err, {})
    }
  }
  async function load_project_reports() {
    if (!project) {
      return
    }

    // Load project reports
    try {
      track_project_viewer_event(`obj="project" action="load_project_reports" context="${PROJECT_TRACKING_CONTEXT}"`)
      set_is_fetching_reports(true)
      set_project_reports(await get_project_reports(project.project_id))
      set_is_fetching_reports(false)
      await get_report_owners()
    } catch (error) {
      // Permission error
      set_is_fetching_reports(false)
      set_error_on_action({error, action:'loading project reports'})
    }
  }
  async function load_project_meta() {
    // Load project
    try {
      track_project_viewer_event(`obj="project" action="load_project" context="${PROJECT_TRACKING_CONTEXT}"`)
      const p = await get_project(props.project_id)
      set_project(p)
      set_is_fetching(false)
      set_can_view(p.current_user_permissions.view)
      set_can_edit(p.current_user_permissions.edit)
    } catch (error) {
      // Permission error
      set_is_fetching(false)
      set_can_view(false)
      set_can_edit(false)
    }
  }
  async function load_project_data() {
    if (!project) {
      return
    }

    // Load project reports
    load_project_reports()

    // Load project files
    try {
      track_project_viewer_event(`obj="project" action="load_project_files" context="${PROJECT_TRACKING_CONTEXT}"`)
      set_is_fetching_files(true)
      set_project_files(await get_project_files(project.project_id))
      set_is_fetching_files(false)
    } catch (error) {
      set_is_fetching_files(false)
      set_error_on_action({error, action:'loading project files'})
    }
  }
  async function load_files_history() {
    if (!project || !project_files || project_files.length === 0) {
      return
    }

    const files_history: UserFileHistoryMap = {}
    await Promise.all(project_files.map(
      file => get_project_file_history(project.project_id, file.s3_resource_id)
        .then(res => files_history[file.s3_resource_id] = _.filter(res, p => p.id !== file.id))
    ))
    set_files_history(files_history)
  }
  async function load_kc_users_groups() {
    // Load everything if admin otherwise only active user Group and its user list
    if (is_admin(props.user)) {
      set_all_active_groups(get_as_map(await fetch_all_active_keycloak_groups(), 'id', null))
      set_all_active_users(get_as_map(await fetch_all_active_keycloak_users(), 'id', null))
    } else{
      set_all_active_groups(get_as_map(await fetch_my_keycloak_groups(), 'id', null))
      set_all_active_users(get_as_map(await fetch_users_in_keycloak_group(props.user.group_ids[0]), 'id', null))
    }
  }

  useEffect(() => {
    load_project_meta()
      .catch(error => {send_error_to_sentry(error, {})})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project_id])
  useEffect(() => {
    // Exit early if it's still uploading, hook gets triggered on flip back and should only refresh after upload completes.
    if (is_uploading_files) {
      return
    }

    set_is_fetching_files(true)
    load_project_data()
      .then(() => {
        set_is_fetching_files(false)
      })
      .catch(error => {send_error_to_sentry(error, {})})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.project_id, is_uploading_files])
  useEffect(() => {
    if (!project_files || project_files.length === 0) {
      return
    }

    set_is_loading_files_history(true)
    load_files_history()
      .then(() => set_is_loading_files_history(false))
      .catch(error => {
        set_is_loading_files_history(false)
        send_error_to_sentry(error, {})
        set_error_on_action({error, action: 'loading files history'})
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_files])

  // Refresh every 3 minutes to capture multiple users working it
  useInterval(() => {
    load_project_meta()
      .catch(error => {send_error_to_sentry(error, {})})
  }, 3 * 60 * 1000, false)

  // User state update hooks
  useEffect(() => {
    if (!is_fetching) {
      const new_view_state: ProjectUserViewState = {
        reports_table: { sort_field_id, sort_direction_id, page_size},
        files_table: {sortModel: files_table_sort_model},
        permissions_table: {sortModel: permissions_table_sort_model}
      }
      track_project_viewer_event(`obj="project" action="update_user_settings" context="${PROJECT_TRACKING_CONTEXT}"`)
      update_user_project_settings(props.project_id, new_view_state)
        .catch(error => {send_error_to_sentry(error, {})})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort_field_id, sort_direction_id, page_size, files_table_sort_model, permissions_table_sort_model])

  // Render methods
  function render_reports_table(): JSX.Element {
    return (
      <div>
        {can_edit && is_fetching_reports ? (
          <Skeleton variant="rectangular" animation="wave" height={45} className={cn('')}/>
        ) : (
          <div>
            {/* Report management buttons */}
            <div className={cn('d-flex', 'align-items-center')}>
              {can_edit &&
                <React.Fragment>
                  <div className={cn(s.user_select_field, 'mr-2')}>
                    <Autocomplete
                      options={user_reports}
                      size='small'
                      value={null}
                      multiple={false}
                      freeSolo={false}
                      filterSelectedOptions={true}
                      onChange={async (_event, selection) => {
                        if (selection) {
                          await add_project_reports(props.project_id,{external_report_ids: [selection.external_report_id]})
                          await load_project_reports()
                        }
                      }}
                      disabled={!can_edit || user_reports.length === 0}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={'standard'}
                          label={'Add report to project'}
                          placeholder={'Select ...'}
                        />
                      )}
                      getOptionLabel={(option: Report) => option.title}
                      getOptionDisabled={(option: Report) => _.some(project_reports, r => r.external_report_id === option.external_report_id)}
                    />
                  </div>
                  {/* @ts-expect-error*/}
                  <TextLink className="ml-1 d-flex"
                            disable={project_reports.length === 0}
                            onClick={toggle_select_all_reports}
                            title={is_select_all_reports ? 'Deselect all reports' : 'Select all reports'}>
                    {/* @ts-expect-error*/}
                    <CheckboxStatic
                      is_checked={is_select_all_reports}
                      is_partial={total_selected_reports > 0}
                      is_disabled={project_reports.length === 0}
                    />
                    <span className="ml-1">{is_select_all_reports ? 'Deselect all reports' : 'Select all reports'}</span>
                  </TextLink>
                  {/* @ts-expect-error*/}
                  <TextLink className="ml-3"
                            onClick={on_unlink_reports_from_project}
                            disable={total_selected_reports === 0 || !can_edit}
                            title="Remove from project">
                    <UnlinkIcon/>
                    <span
                      className="ml-1">
                  Remove {total_selected_reports ? `${total_selected_reports}` : ''} from project
                </span>
                  </TextLink>
                </React.Fragment>
              }
              {/* Right aligned items */}
              <div className={cn('d-flex', 'align-items-center', 'ml-auto', s.page_size_controls)}>

                <PageSizeControl
                  className={cn('')}
                  page_sizes={PAGE_SIZES}
                  selected_page_size={page_size}
                  on_change_page_size={set_page_size}
                />
                <PageControl
                  className={cn('ml-3')}
                  current_page={page_number}
                  num_pages={num_pages}
                  on_change_current_page={set_page_number}
                />
              </div>
            </div>
          </div>
        )}
        <ReportManagementTable
          className={cn('mt-3')}
          fields={report_fields}
          field_id_to_render_fn={{
            [NAME_FIELD_ID]: function NameField(report: Report) {
              const has_bespoke_viewer = report_has_bespoke_viewer(report.report_type)
              return (
                <div>
                  <div className='d-flex flex-wrap mb-1'>
                    { has_bespoke_viewer &&
                      <span className='mr-1'>{capitalize(report.report_type)}:</span>
                    }
                    {/* @ts-expect-error */}
                    <TextLink
                      element='a'
                      className={s.report_ext_btn}
                      href={`${PROJECT}/${props.project_id}${get_report_viewer_url(report.report_type, report.external_report_id)}`}
                      target='_blank'
                      title='Open in new tab'
                    >
                      {report.title}
                    </TextLink>
                  </div>
                  <div>
                    <span>
                      {has_bespoke_viewer &&
                        // @ts-expect-error
                        <TextLink
                          className='small mr-1'
                          element='a'
                          href={`${PROJECT}/${props.project_id}${REPORT}/${report.external_report_id}`}
                          target='_blank'
                          title='Open in new tab'
                        >
                          [open in main Cipher]
                        </TextLink>
                      }
                    </span>
                  </div>
                </div>
              )
            }
          }}
          field_id_to_className={{
            [NAME_FIELD_ID]: s.report_name_field,
          }}
          reports={table_reports_view}
          selected_external_report_ids={selected_reports_external_ids}
          selected_sort_field_id={sort_field_id}
          selected_sort_direction_id={sort_direction_id}
          on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}

          min_rows={1}

          user={props.user}
          toggle_selected={toggle_selected_reports}
          no_data_text={is_fetching_reports ? null : 'No reports in project'}
          loading_text='Loading report history'
          loading={is_fetching_reports}
        />
      </div>
    )
  }
  function render_files_table(): JSX.Element {
    return (
      <div className='d-flex mt-3'>
        <DataGridPro
          rows={project_files}
          columns={files_fields}
          getRowId={row => row.s3_resource_id}

          checkboxSelection={false}
          disableColumnMenu={true}
          hideFooterRowCount={true}
          disableSelectionOnClick={true}
          disableMultipleSelection={true}
          hideFooter={true}
          hideFooterSelectedRowCount={true}

          getDetailPanelContent={render_file_details_panel}
          getDetailPanelHeight={() => 'auto'}
          detailPanelExpandedRowIds={file_rows_expanded}
          onDetailPanelExpandedRowIdsChange={handle_detail_panel_expanded_row_ids_change}

          rowHeight={30}
          autoHeight={true}
          loading={is_fetching_files || is_deleting_file || is_uploading_files}
          initialState={{
            sorting: { sortModel: files_table_sort_model},
          }}
          sortModel={files_table_sort_model}
          onSortModelChange={(sort_model) => {set_files_table_sort_model(sort_model)}}
          components={{
            Header: function files_grid_header(): JSX.Element {
              return (
                <div
                  className={cn('d-flex', 'flex-wrap', 'align-items-center', 'justify-content-start')}
                  style={{height: '40px'}}
                >
                  {project &&
                    <DownloadAllProjectFiles
                      project_id={project.project_id}
                      is_disabled={project_files.length === 0}
                      text_label='Download all files'
                      className={cn('mr-4', 'ml-2')}
                    />
                  }
                  {can_edit && is_aistemos(props.user) &&
                    // @ts-expect-error
                    <TextLink
                      className={cn('mr-2')}
                      onClick={() => set_is_file_upload_modal_open(true)}
                      disable={!(can_edit && is_aistemos(props.user))}
                      title='Upload project files'
                    >
                      <UploadIcon/>
                      <span className={cn('ml-1')}>Upload files</span>
                    </TextLink>
                  }
                </div>
              )
            },
            DetailPanelExpandIcon: function chev_down(): JSX.Element {
              return (
                <span className='d-flex flex-row'>
                <div className={cn('d-flex', 'align-items-center', s.icon_cipher_red)}>
                  <ExpandClosedIcon/>
                </div>
              </span>
              )
            },
            DetailPanelCollapseIcon: function chev_up(): JSX.Element {
              return (
                <span className='d-flex flex-row'>
                <div className={cn('d-flex', 'align-items-center', s.icon_cipher_red)}>
                  <ExpandOpenedIcon/>
                </div>
              </span>
              )
            },
            NoRowsOverlay: function no_row_overlay(): JSX.Element {
              return (
                <div className={cn(s.no_rows_overlay)}>No files</div>
              )
            }
          }}
          classes={mui_table_classes}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? s.row_even : s.row_odd
          }
        />
      </div>
    )
  }
  function render_permissions_table(): JSX.Element {
    return (
      <div className={cn('d-flex', 'mt-3')}>
        <DataGridPro
          rows={permissions_rows}
          columns={permissions_fields}
          getRowId={row => `${row.entity_type}-${row.entity_id}`}

          checkboxSelection={false}
          disableColumnMenu={true}
          hideFooterRowCount={true}
          disableSelectionOnClick={true}
          disableMultipleSelection={true}
          hideFooter={true}
          hideFooterSelectedRowCount={true}

          loading={is_loading_permission_rows}
          rowHeight={40}
          autoHeight={true}
          components={{
            Header: function permissions_grid_header(): JSX.Element {
             return (
               <div className={cn('d-flex', 'flex-wrap', 'align-items-center', 'justify-content-between', 'mb-3')}>
                 <div className={cn(s.user_select_field,)}>
                   <Autocomplete
                     options={sharable_groups_users_list}
                     size='small'
                     value={null}
                     multiple={false}
                     freeSolo={false}
                     filterSelectedOptions={true}
                     onChange={(_event, selection) => {
                       const new_perm_row : PermissionRow = {
                         entity_type: selection.entity_type,
                         entity_id: selection.id,
                         edit: false,
                         view: false
                       }
                       set_permissions_rows(_.union(permissions_rows, [new_perm_row]))
                     }}
                     disabled={!can_edit || sharable_groups_users_list.length === 0}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         variant={'standard'}
                         label={'Add new user and/or group permissions'}
                         placeholder={'Select ...'}
                       />
                     )}
                     getOptionLabel={(option:any) => option.name ? option.name : option.username}
                     getOptionDisabled={(option:any) => !!_.find(permissions_rows, r => r.entity_type === option.entity_type && r.entity_id === option.id)}
                     groupBy={(option:any) => `${EntityType[option.entity_type]}s`}
                   />
                 </div>
                 {/* @ts-expect-error */}
                 <TextLink
                   disable={!can_edit || updated_permissions_rows.length === 0 || is_loading_permission_rows}
                   onClick={on_update_project_permissions}
                   className={cn('mr-2')}
                 >
                   <BallotCheckIcon/>
                   <span className={cn('ml-1')}>Update permissions</span>
                 </TextLink>
               </div>
             )
            },
            NoRowsOverlay: function no_row_overlay(): JSX.Element {
              return (
                <div className={cn(s.no_rows_overlay)}>No permissions</div>
              )
            }
          }}
          initialState={{
            sorting: {sortModel: permissions_table_sort_model},
          }}
          sortModel={permissions_table_sort_model}
          onSortModelChange={(sort_model) => {set_permissions_table_sort_model(sort_model)}}
          getRowClassName={(params) =>
            _.filter(updated_permissions_rows, p => are_permission_rows_identical(p, params.row)).length > 0 ? s.row_modified :
              params.indexRelativeToCurrentPage % 2 === 0 ? s.row_even : s.row_odd
          }
          classes={mui_table_classes}
        />
      </div>
    )
  }

  function render_file_details_panel(params: GridRowParams<UserFile>): JSX.Element {
    const user_file = params.row
    const columns: GridColumns = [
      ...files_fields.slice(0, -1),
      {
        field: '_', headerName: 'Actions', sortable: false, disableReorder: true, resizable: false,
        renderCell: function render_actions_cell(params) {
          const file_meta: UserFile = params.row
          return (
            <div
              className={cn('d-flex', 'flex-wrap', 'flex-grow-1', 'align-items-right', 'justify-content-end')}>
              {/* @ts-ignore */}

              <DownloadProjectFile
                project_id={props.project_id}
                file_meta={file_meta}
                is_disabled={!can_view}
              />
            </div>
          )
        }
      }
    ]

    return (
      <div className={cn('d-flex', 'flex-grow-1', s.files_details_panel)}>
        {is_loading_files_history || !files_history[user_file.s3_resource_id] ? (
          <Spinner size='md' className={cn('d-flex')}/>
        ) :
          (files_history[user_file.s3_resource_id].length === 0 ? (
            <span>No file history</span>
          ) : (
            <DataGridPro
              rows={files_history[user_file.s3_resource_id]}
              columns={columns}

              checkboxSelection={false}
              disableColumnMenu={true}
              hideFooterRowCount={true}
              disableSelectionOnClick={true}
              disableMultipleSelection={true}
              hideFooterSelectedRowCount={true}
              hideFooter={true}

              rowHeight={30}
              autoHeight={true}
              classes={mui_details_table_classes}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? s.row_even_inner : s.row_odd_inner
              }
            />
          ))
        }
      </div>
    )
  }
  function render_file_upload_modal(): JSX.Element {
    return (
      //@ts-ignore
      <Modal
        className={s.medium_modal}
        on_hide={() => set_is_file_upload_modal_open(false)}
        bodyClassName={s.upload_files_modal_body}
        no_close_button={true}
        title={'Upload project files'}
        footer={(
          <div>
            <PrimaryButton
              onClick={upload_project_files}
              disabled={!(can_edit && is_aistemos(props.user)) || files_to_upload.length === 0 || is_uploading_files}
            >
              Upload
            </PrimaryButton>
          </div>
        )}
      >
        <FileUploadDropzone
          dropzone_options={{
            maxFiles: 20,
            maxFilesize: 100,
            autoFocus: true,
            onDropAccepted: set_files_to_upload,
            disabled: is_uploading_files
          }}/>
      </Modal>
    )
  }

  function render(): JSX.Element {
    return (
      //@ts-ignore
      <ContainerFixedWidth>
        {is_fetching &&
          <Spinner size='md' className={cn('d-flex')}/>
        }
        {!is_fetching && can_view &&
          <div>
            <Nav tabs>
              <NavItem key={ProjectViewTabs.BackButton} style={{marginRight: 20}}>
                {/* @ts-expect-error */}
                <ItemHeaderUndoButton className={s.navbar_back_button}
                  level_1_path={`${PROJECT}/${props.project_id}`}
                  on_close={()=> props.history.push(HISTORY)}
                />
              </NavItem>
              <NavItem key={ProjectViewTabs.ReportsAndFiles}>
                <NavLink active={current_tab_view === ProjectViewTabs.ReportsAndFiles}
                         onClick={() => set_current_tab_view(ProjectViewTabs.ReportsAndFiles)}>
                  Reports & Files
                </NavLink>
              </NavItem>
              {can_edit &&
                <NavItem key={ProjectViewTabs.Permissions}>
                  <NavLink
                    active={current_tab_view === ProjectViewTabs.Permissions}
                     onClick={() => set_current_tab_view(ProjectViewTabs.Permissions)}
                  >
                    {get_enum_key_by_value(ProjectViewTabs, ProjectViewTabs.Permissions)}
                  </NavLink>
                </NavItem>
              }
            </Nav>

            {current_tab_view === ProjectViewTabs.ReportsAndFiles &&
              <React.Fragment>
                {(can_edit || table_reports_view.length > 0) &&
                  <React.Fragment>
                    {/* @ts-expect-error */}
                    <PaneHeader text='Reports' className={cn('mt-3')} />
                    {render_reports_table()}
                  </React.Fragment>
                }
                {/* @ts-expect-error */}
                <PaneHeader text='Files' className={cn('mt-3')} />
                {render_files_table()}
              </React.Fragment>
            }
            {current_tab_view === ProjectViewTabs.Permissions && render_permissions_table()}
          </div>
        }

        {/* Modals */}
        {is_file_upload_modal_open && render_file_upload_modal()}

        {!is_fetching && !can_view &&
          <div>You do not have permission to view this project.</div>
        }

        {/* Error modal  */}
        {error_on_action &&
          // @ts-expect-error
          <ErrorModal
            on_hide={() => set_error_on_action(null)}
            error={error_on_action.error}
            context={error_on_action.action}
          />
        }

      </ContainerFixedWidth>
    )
  }
  return render()
}

export default withRouter(withUser(ProjectView))
