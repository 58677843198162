import React from 'react'
import cn from 'classnames'

import TextLink from './TextLink.js'
import { CheckIcon, RemoveFromBasketIcon } from './IconSet.js'

const ConfirmOrCancel = ({is_valid, on_submit, on_cancel, className}) => {
  return (
    <div className={cn('d-flex', className)}>
      <TextLink
        disable={!is_valid}
        onClick={on_submit}
        className={'p-1'}
        title='Confirm'
      >
        <CheckIcon />
      </TextLink>
      <TextLink
        onClick={on_cancel}
        className={'p-1'}
        title='Cancel'
      >
        <RemoveFromBasketIcon />
      </TextLink>
    </div>
  )
}

export default ConfirmOrCancel