import React from 'react'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import { RadiobuttonWithLabel } from '../../widgets/RadiobuttonWithLabel.js'
import { PrimaryButton } from '../../widgets/Button.js'
import BaseDropdown from '../../widgets/BaseDropdown.js'
import {
  ACCESSION_DATE_CUT_OFF_OPTIONS,
  LANDSCAPE_TECH_TYPE_BINARY_CLASS_OPTION,
  LANDSCAPE_TECH_TYPE_UTT_OPTION
} from './builder_wizard.js'
import { RemoveFromBasketIcon } from '../../widgets/IconSet.js'

import cs from '../../cipher_styles.module.scss'
import s from './StreamParams.module.scss'

const Wrapper = ({title, on_close, className, children}) => {
  return (
    <div className={className}>
      <div className={cn('p-1 d-flex justify-content-between', cs.font_size_larger, s.heading)}>
        {title}
        {on_close &&
          <span className={cn('my-auto mr-1', cs.cursor_pointer, cs.font_size_small, cs.cipher_red_text)} onClick={on_close}><RemoveFromBasketIcon /></span>
        }
      </div>

      <div className={s.block}>
        {children}
      </div>
    </div>
  )
}

const ConfirmButton = ({disabled, on_click, className}) => {
  return (
    <div className={className}>
      <PrimaryButton
        size='sm'
        disabled={disabled}
        onClick={on_click}
      >
        OK
      </PrimaryButton>
    </div>
  )
}

const TechTypeSelect = ({selected_landscape_tech_type, on_landscape_tech_type_select_handler, className}) => {
  return (
    <div className={className}>
      {[LANDSCAPE_TECH_TYPE_UTT_OPTION, LANDSCAPE_TECH_TYPE_BINARY_CLASS_OPTION].map((item, i) => {
        const {id, label} = item || {}
        return (
          <RadiobuttonWithLabel
            key={i}
            label={label}
            is_checked={id === selected_landscape_tech_type}
            on_click={() => on_landscape_tech_type_select_handler(id)}
          />
        )
      })}
    </div>
  )
}

export const NewFilingsReportParamsSelect = (
  {
    user_can_select_tech_type,
    accession_date_threshold,
    on_accession_date_change_handler,
    selected_landscape_tech_type,
    on_landscape_tech_type_select_handler,
    on_confirm,
    on_close,

    className
  }) => {
  return (
    <Wrapper className={className} title='Start with:' on_close={on_close}>
      <div className={cn('p-1', s.stream_options)}>
        {user_can_select_tech_type &&
          <TechTypeSelect
            selected_landscape_tech_type={selected_landscape_tech_type}
            on_landscape_tech_type_select_handler={on_landscape_tech_type_select_handler}
            className='mb-1'
          />
        }

        <div className='d-flex'>
          <div className='my-auto mr-1'>in the last</div>
          <div className='d-flex'>
            <BaseDropdown
              label={accession_date_threshold}
            >
              {ACCESSION_DATE_CUT_OFF_OPTIONS.map((item, i) => {
                return (
                  <DropdownItem key={i} onClick={() => on_accession_date_change_handler(item)} disabled={accession_date_threshold===item}>{item}</DropdownItem>
                )
              })}
            </BaseDropdown>

            <div className='my-auto mx-1'>days</div>
          </div>

          <ConfirmButton
            disabled={!accession_date_threshold}
            on_click={on_confirm}
            className='ml-2 d-flex align-items-end'
          />
        </div>
      </div>
    </Wrapper>
  )
}

export const TechLandscapeParamsSelect = (
  {
    selected_landscape_tech_type,
    on_landscape_tech_type_select_handler,
    on_confirm,
    on_close,
    className
  }) => {
  return (
    <Wrapper className={className} title='Start with:' on_close={on_close}>
      <div className={cn('d-flex p-1', s.stream_options)}>
        <div className={cn('d-flex p-1', s.stream_options)}>
          <TechTypeSelect
            selected_landscape_tech_type={selected_landscape_tech_type}
            on_landscape_tech_type_select_handler={on_landscape_tech_type_select_handler}
          />
          <ConfirmButton
            className='ml-2 d-flex align-items-end'
            disabled={selected_landscape_tech_type == null}
            on_click={on_confirm}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export const PortfolioTypeSelect = (
  {
    portfolio_types,
    selected_portfolio_type,
    on_portfolio_type_select_handler,
    on_confirm,
    on_close,
    className
  }) => {
  return (
    <Wrapper className={className} title='Start with:' on_close={on_close}>
      <div className={cn('d-flex p-1', s.stream_options)}>
        <div>
          {portfolio_types.map((item, i) => {
            const {id, label} = item || {}
            return (
              <RadiobuttonWithLabel
                key={i}
                label={label}
                is_checked={id === selected_portfolio_type}
                on_click={() => on_portfolio_type_select_handler(id)}
              />
            )
          })}
        </div>
        <ConfirmButton
          className='ml-2 d-flex align-items-end'
          disabled={selected_portfolio_type == null}
          on_click={on_confirm}
        />
      </div>
    </Wrapper>
  )
}