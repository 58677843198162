import React, { useState } from 'react'
import cn from 'classnames'

import TextLink from './TextLink.js'
import { EditIcon } from './IconSet.js'
import EditableTextLink from '../report_management/components/EditableTextLink.js'

import s from './EditableText.module.scss'

const EditableText = ({text, on_confirm, on_normalise, on_check_if_valid, invalid_message, edit_icon_on_hover, className, textClassName}) => {
  const [is_in_edit, set_is_in_edit] = useState(false)

  function on_validation(text) {
    if (on_check_if_valid) {
      const normalised_text = on_normalise ? on_normalise(text) : text
      return on_check_if_valid(normalised_text)
    }

    return true
  }

  function on_change(text) {
    const normalised_text = on_normalise ? on_normalise(text) : text
    on_confirm(normalised_text)
    set_is_in_edit(false)
  }

  return (
    <div className={className}>
      {!is_in_edit &&
        <div className={cn('d-flex my-auto p-1', s.text__static, textClassName)} onClick={() => set_is_in_edit(true)}>
          <span>{text}</span>
          <TextLink
            title='Change'
            onClick={() => set_is_in_edit(true)}
            className={cn('px-1', {[s.edit_control_on_hover]: edit_icon_on_hover})}
          >
            <EditIcon/>
          </TextLink>
        </div>
      }

      {is_in_edit &&
        <EditableTextLink
          is_edit={true}
          link_text={text}
          on_confirm={on_change}
          on_cancel={() => set_is_in_edit(false)}
          is_valid={on_validation}
          invalid_message={invalid_message}
        />
      }
    </div>
  )
}

export default EditableText