import React, { useState, useEffect } from 'react'

import { LanguageIcon } from '../widgets/IconSet.js'
import Tooltip from '../widgets/Tooltip.js'
import Spinner from '../widgets/Spinner.js'
import { translate_name } from '../../utils/organisation_utils.js'
import ErrorBody from '../ErrorBody.js'
import { LANGUAGE_BY_ID } from '../../constants/languages.js'
import { useToggle } from '../../hooks/general_hooks.js'
import { GoogleSearchLink } from '../widgets/GoogleSearchLink.js'

import s from './TranslatedNamePopup.module.scss'

const TranslatedNamePopup = ({org_name, popup_reference, className}) => {
  const [open, toggle_open] = useToggle(false)
  const [show_spinner, set_show_spinner] = useState(true)

  const [name_translated, set_name_translated] = useState(null)
  const [source_language, set_source_language] = useState(null)

  const [translation_error, set_translation_error] = useState(null)

  useEffect(() => {
    if (open) {
      set_show_spinner(true)
      translate_name(org_name)
        .then(translation => {
          const {translatedText, detectedSourceLanguage} = translation

          const language = LANGUAGE_BY_ID[detectedSourceLanguage]

          set_name_translated(translatedText)
          set_source_language(language ? language.name : detectedSourceLanguage)
          set_show_spinner(false)
        })
        .catch(error => {
          set_translation_error(error)
        })
    } else {
      set_name_translated(null)
      set_source_language(null)
      set_translation_error(null)
    }
  }, [open, org_name])


  return (
    <div className={className}>
      <Tooltip
        on_show={toggle_open}
        on_hide={toggle_open}
        toggler={(
          <span id={popup_reference} className={s.icon}>{(!name_translated) ? <LanguageIcon/> : <GoogleSearchLink label={<LanguageIcon/>} search_string={name_translated} />}</span>
        )}
      >
        {show_spinner &&
          <div className='d-block'>
            <Spinner />
          </div>
        }

        {!show_spinner && name_translated &&
          <div>
            {name_translated} (source: {source_language})
          </div>
        }

        {!show_spinner && translation_error &&
          <ErrorBody
            error={translation_error}
            context='fetching translation for organisation name'
          />
        }
      </Tooltip>
    </div>
  )
}

export default TranslatedNamePopup