import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router'

import { withUser } from '../UserContext.js'
import { extract_external_report_id } from '../../utils/report_url_utils.js'
import { save_user_state_to_history } from '../../utils/report_history_utils.js'
import { fetch_report_metadata, update_report_title } from '../../utils/report_created_utils.js'
import {
  create_new_tag,
  fetch_all_tags,
  update_reports_saved,
  update_saved_reports_with_tag
} from '../../utils/report_management_utils.js'
import Viewer from './Viewer.js'
import ScopeModal from '../classifiers_editor/components/ScopeModal.js'
import { fetch_report_input, fetch_report_summary_with_status } from '../../utils/report_reader_utils.js'
import { check_or_poll_for_final_report_status, is_completed_status, is_failed_status } from '../../utils/report_status_utils.js'
import { get_ref_data_from_data_summary, get_timeranges_from_data_summary } from '../../utils/ref_data_utils.js'
import { get_path_to_classifier_ui } from '../../utils/report_utils.js'
import {
  set_custom_url, set_referrer_url,
  track_classifiers_event,
  track_report_management_event,
  track_report_viewer_event, track_visit_event
} from '../../utils/tracking_utils.js'

import { get_as_map, get_cipher_hostname } from '../../utils/utils.js'
import { EVALUATE } from '../classifiers_editor/constants/classifier_paths.js'
import { alphabetise_tags } from '../report_management/utils/sort_and_filter_utils.js'
import ErrorModal from '../ErrorModal.js'
import {
  fetch_training_set_info,
  fetch_training_set_patfams,
  fetch_phrases_to_highlight,
  fetch_user_settings as fetch_ts_user_settings,
  get_eval_training_set_id,
  fetch_latest_build_log_entry,
  fetch_any_built_training_sets_meta
} from '../../utils/training_set_grpc_utils.js'
import { get_user_state_from_url, remove_user_state_from_url } from '../../utils/user_state_utils.js'
import {
  save_to_report_history,
  scroll_window_to_top,
  set_favicon, show_title_in_browser
} from '../../utils/viewer_utils.js'
import { get_is_show_scope, hide_scope_modal } from '../classifiers_editor/utils/scope_modal_utils.js'
import { useClassifierKeyboardShortcuts } from '../classifiers_editor/components/keyboard_shortcuts/classifier_keyboard_utils.js'
import { useClassifierScopeState } from '../classifiers_editor/hooks/core_classifier_state_hooks.js'
import { eval_report_build_notification_in_browser } from '../classifiers_editor/utils/browser_notifications.js'
import { prepare_pathname_for_tracking } from '../../utils/tracking_hashed_id_utils.js'

const ViewerContainer = ({ history }) => {

  const external_report_id = extract_external_report_id(window.location.href)

  const [is_initial_fetch, set_is_initial_fetch] = useState(true)
  const [initial_data, set_initial_data] = useState(null)
  const [choreo_status, set_choreo_status] = useState(null)
  const [is_report_data_fetch, set_is_report_data_fetch] = useState(false)

  const {
    latest_built_classifier_version,
    training_set_info,
    phrases_to_highlight,
    ts_user_settings,
    set_latest_built_classifier_version,
    set_training_set_info,
    set_phrases_to_highlight,
    set_ts_user_settings,

    ts_state,
    set_ts_state,

    on_change_phrases_to_highlight,
    on_change_classifier_title,
    on_change_classifier_notes,
    on_change_classifier_description,
    on_change_no_highlighting,

    set_label,

    error_updating_scope,
    set_error_updating_scope,
  } = useClassifierScopeState()

  const [error_fetching_initial_data, set_error_fetching_initial_data] = useState(null)
  const [error_fetching_report_data, set_error_fetching_report_data] = useState(null)
  const [error_updating_user_state, set_error_updating_user_state] = useState(null)
  const [error_renaming_report, set_error_renaming_report] = useState(null)
  const [error_updating_saved_status, set_error_updating_saved_status] = useState(null)
  const [error_tagging_report, set_error_tagging_report] = useState(null)

  const is_show_scope = get_is_show_scope()

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  useEffect(() => {
    set_favicon(is_initial_fetch)
  }, [is_initial_fetch])

  useClassifierKeyboardShortcuts({
    disable: (training_set_info == null),
    history
  })

  const fetch_eval_classifier_data = useCallback((report_type, eval_training_set_id) => {
    if (!eval_training_set_id) {
      // this isn't an evaluation report; no need to fetch eval data
      return Promise.resolve({})
    }

    track_classifiers_event('obj="evaluation_report" action="open_evaluation_report"')

    return Promise.all([
      fetch_latest_build_log_entry(eval_training_set_id, false /* no fail build events, only successes */),
      fetch_training_set_info(eval_training_set_id),
      fetch_training_set_patfams(eval_training_set_id),
      fetch_phrases_to_highlight(eval_training_set_id),
      fetch_ts_user_settings(eval_training_set_id),
    ])
      .then(([ { latest_built_classifier_version }, training_set_info, training_set_patfams, phrases_to_highlight, ts_user_settings ]) => {
        const id_to_tsfam = get_as_map(training_set_patfams, 'id')
        const eval_training_set_path_to_ui = `${get_path_to_classifier_ui(eval_training_set_id)}/${EVALUATE}`
        return {
          latest_built_classifier_version,
          training_set_info,
          eval_training_set_path_to_ui,
          training_set_patfams,
          id_to_tsfam,
          id_to_tsfam_pending: {},
          id_to_tsfam_error: {},
          phrases_to_highlight,
          ts_user_settings,
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.indexOf('7 PERMISSION_DENIED') !== -1) {
          // User does not own the classifier, so we can't fetch this data
          // For now, we still show them the eval report (but obviously it will be missing training set related data)
          return {}
        }

        throw err
      })
  }, [])

  useEffect(() => {
    let did_cancel = false
    const custom_url = `${get_cipher_hostname()}${prepare_pathname_for_tracking(window.location.pathname)}`
    set_custom_url(custom_url)
    set_referrer_url(custom_url)
    track_visit_event('page="report"')
    const url_user_state = get_user_state_from_url()

    if (url_user_state) {
      // DEBUG flow
      // user_state is in the url
      // Example:
      // http://localhost:3000/report/771a6a30fc?user_state_string=%7B%22created_at%22%3A%222018-08-09T16%3A51%3A38.196Z%22%2C%22main_items%22%3A%5B%7B%22spec_id%22%3A%22ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID%22%2C%22item_id%22%3A0%7D%5D%2C%22last_updated%22%3A%222018-08-10T11%3A23%3A20.023Z%22%2C%22selected_main_item_id%22%3A0%7D
      const clean_url = remove_user_state_from_url()

      save_user_state_to_history(external_report_id, url_user_state)
        .catch(error => {
          set_error_updating_user_state(error)
        })

      window.location.replace(clean_url)            // redirect to the clean url (will load state from db)
    }

    set_is_initial_fetch(true)

    set_initial_data({...initial_data, external_report_id})
    Promise.all([
      fetch_report_metadata(external_report_id),
      fetch_all_tags()
    ])
      .then(([report_metadata, available_tags]) => {
        const { evaluation_classifier_id, title } = report_metadata

        show_title_in_browser(title)

        const eval_training_set_id = get_eval_training_set_id(evaluation_classifier_id)
        const initial_report_metadata = { ...report_metadata, eval_training_set_id }
        set_initial_data({ initial_report_metadata, external_report_id, available_tags })

        return [initial_report_metadata, available_tags]
      })
      .then(([initial_report_metadata, available_tags]) => {
        const { internal_report_id, report_type, evaluation_classifier_id, title, last_viewed, created_by_user } = initial_report_metadata
        save_to_report_history(external_report_id, false, window.location.pathname)

        return check_or_poll_for_final_report_status(internal_report_id, set_choreo_status)
          .then(final_report_status => {
            const {status} = final_report_status

            // notify users in the browser when evaluation reports they have themselves created complete or fail
            if (created_by_user && evaluation_classifier_id && !last_viewed) {
              fetch_any_built_training_sets_meta([evaluation_classifier_id])
                .then(({training_sets}) => {
                  const {name: classifier_name} = training_sets[0] || {}
                  eval_report_build_notification_in_browser(title, classifier_name, external_report_id, is_completed_status(status))
                })
            }

            if (is_failed_status(status)) {
              const message = JSON.stringify(final_report_status, null, 2)
              const error = new Error(message)
              set_choreo_status(final_report_status)
              set_error_fetching_initial_data(error)
              set_is_initial_fetch(false)
              return
            }

            return Promise.all([
              fetch_report_summary_with_status(internal_report_id),
              fetch_report_input(internal_report_id),
              fetch_eval_classifier_data(report_type, get_eval_training_set_id(evaluation_classifier_id))
            ])
              .catch(error => { throw error })
              .then(([{report_summary, build_status}, report_input, eval_classifier_data]) => {
                const {size, size_grouped, has_scores} = report_summary
                const {has_complete_disputes, is_truncated} = build_status.meta || {}

                const ref_data = get_ref_data_from_data_summary(report_summary)

                const families_count = {total_families_count: size, grouped_families_count: size_grouped}

                const timerange_field_id_to_default_extent = get_timeranges_from_data_summary(report_summary)

                return {
                  available_tags,
                  choreo_status,
                  ref_data,
                  eval_classifier_data,
                  families_count,
                  timerange_field_id_to_default_extent,
                  has_complete_disputes,
                  has_scores,
                  is_truncated,
                  report_input
                }
              })
              .then((
                {
                  available_tags,
                  choreo_status,
                  ref_data,
                  eval_classifier_data,
                  families_count,
                  timerange_field_id_to_default_extent,
                  has_complete_disputes,
                  has_scores,
                  is_truncated,
                  report_input
                }) => {
                if (!did_cancel) {
                  const { latest_built_classifier_version, training_set_info, phrases_to_highlight, ts_user_settings, eval_training_set_path_to_ui, training_set_patfams, id_to_tsfam, id_to_tsfam_error, id_to_tsfam_pending } = eval_classifier_data

                  save_to_report_history(external_report_id, true, window.location.pathname)
                  set_initial_data({
                    external_report_id,
                    report_input,
                    initial_report_metadata,
                    available_tags,
                    ref_data,
                    families_count,
                    timerange_field_id_to_default_extent,
                    has_complete_disputes,
                    report_has_scores: has_scores,
                    is_truncated,
                    eval_training_set_path_to_ui
                  })
                  set_choreo_status(choreo_status)

                  // Classifier scope data
                  set_latest_built_classifier_version(latest_built_classifier_version)
                  set_training_set_info(training_set_info)
                  set_phrases_to_highlight(phrases_to_highlight)
                  set_ts_user_settings(ts_user_settings)

                  // Classifier label data
                  set_ts_state({ training_set_patfams, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error })

                  set_is_initial_fetch(false)
                }
              })
          })
      })
      .catch(error => {
        if (!did_cancel) {
          set_error_fetching_initial_data(error)
          set_is_initial_fetch(false)
        }
      })

    return () => {
      did_cancel = true
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [external_report_id, fetch_eval_classifier_data])

  useEffect(() => {
    if (!is_report_data_fetch || is_initial_fetch) return
    Promise.all([
      fetch_report_metadata(external_report_id),
      fetch_all_tags()
    ])
      .then(([report_metadata, available_tags]) => {
        const { evaluation_classifier_id } = report_metadata
        // Get training set id (ensures works for both classifiers v1 and v2)
        const eval_training_set_id = get_eval_training_set_id(evaluation_classifier_id)
        const initial_report_metadata = { ...report_metadata, eval_training_set_id }
        set_initial_data({ ...initial_data, initial_report_metadata, available_tags })
        set_is_report_data_fetch(false)
      })

      .catch(error => {
        set_error_fetching_report_data(error)
        set_is_report_data_fetch(false)
        throw error
      })

  }, [is_report_data_fetch, is_initial_fetch, external_report_id, initial_data])

  function rename_report (new_report_title) {
    track_report_viewer_event('obj="report" action="rename" context="viewer"')
    update_report_title(external_report_id, new_report_title)
      .then(() => {
        //refresh with new title
        const {initial_report_metadata} = initial_data
        const updated_metadata = {...initial_report_metadata, title: new_report_title}
        set_initial_data({...initial_data, initial_report_metadata: updated_metadata})
        show_title_in_browser(new_report_title)
        set_is_report_data_fetch(is_initial_fetch)
      })
      .catch(error => {
        set_error_renaming_report(error)
        throw error
      })
  }

  function save_report() {
    track_report_viewer_event(`obj="report" action="save" context="viewer"`)

    update_reports_saved([external_report_id], true)
      .then(() => {
        const {initial_report_metadata} = initial_data
        const updated_metadata = {...initial_report_metadata, is_saved: true}
        set_initial_data({...initial_data, initial_report_metadata: updated_metadata})
        set_is_report_data_fetch(is_initial_fetch)
      })
      .catch(error => {
        set_error_updating_saved_status(error)
        throw error
      })
  }

  function tag_or_untag_report(tag, to_tag) {
    track_report_management_event(`obj="report" action="${to_tag ? 'add_tag' : 'remove_tag'}" context="viewer"`)

    const {tag_id} = tag
    update_saved_reports_with_tag([external_report_id], tag_id, to_tag)
      .catch(error => {
        set_error_tagging_report(error)
        throw error
      })
      .then(() => {
        const {initial_report_metadata} = initial_data
        const {tag_ids} = initial_report_metadata
        const updated_tag_ids = to_tag ? [...tag_ids, tag_id] : tag_ids.filter(t => t !== tag_id)
        const updated_metadata = {...initial_report_metadata, tag_ids: updated_tag_ids}
        set_initial_data({...initial_data, initial_report_metadata: updated_metadata})
        set_is_report_data_fetch(is_initial_fetch)
      })
  }

  function create_new_tag_and_add_to_report(tag_name) {
    track_report_management_event(`obj="report" action="create_and_add" context="viewer"`)

    const { initial_report_metadata, available_tags } = initial_data
    const { tag_ids } = initial_report_metadata

    create_new_tag([external_report_id], tag_name)
      .catch(error => {
        set_error_tagging_report(error)
        throw error
      })
      .then(new_tag => {
        const available_tags_updated = alphabetise_tags([...available_tags, new_tag])
        const tag_ids_updated = [...tag_ids, new_tag.tag_id]
        const updated_metadata = {...initial_report_metadata, tag_ids: tag_ids_updated}
        set_initial_data({...initial_data, initial_report_metadata: updated_metadata, available_tags: available_tags_updated})
        set_is_report_data_fetch(is_initial_fetch)
      })
  }

  return (
    <>
      {error_renaming_report &&
        <ErrorModal
          on_hide={() => set_error_renaming_report(null)}
          error={error_renaming_report}
          context={'renaming report'}
        />
      }

      {error_tagging_report &&
        <ErrorModal
          on_hide={() => set_error_tagging_report(null)}
          error={error_tagging_report}
          context={'tagging report'}
        />
      }

      {error_updating_saved_status &&
        <ErrorModal
          on_hide={() => set_error_updating_saved_status(null)}
          error={error_updating_saved_status}
          context={'updating saved status of report'}
        />
      }

      {error_updating_user_state &&
        <ErrorModal
          on_hide={() => set_error_updating_user_state(null)}
          error={error_updating_user_state}
          context={'updating user state with url data'}
        />
      }

      {error_updating_scope &&
        <ErrorModal
          on_hide={() => set_error_updating_scope(null)}
          error={error_updating_scope}
          context={'updating scope data'}
        />
      }

      {error_fetching_report_data &&
        <ErrorModal
          on_hide={() => set_error_fetching_report_data(null)}
          error={error_fetching_report_data}
          context={'refreshing report info'}
        />
      }

      <Viewer
        is_initial_fetch={is_initial_fetch || is_report_data_fetch}
        fetch_initial_error={error_fetching_initial_data}
        initial_data={initial_data}
        choreo_status={choreo_status}
        eval_classifier_data={!training_set_info ? null : {...ts_state, latest_built_classifier_version, training_set_info, phrases_to_highlight, no_highlighting: ts_user_settings ? ts_user_settings.no_highlighting : null }}

        rename_report={rename_report}
        save_report={save_report}
        tag_or_untag_report={tag_or_untag_report}
        create_new_tag_and_add_to_report={create_new_tag_and_add_to_report}
        set_label={set_label}
        on_change_phrases_to_highlight={on_change_phrases_to_highlight}
      />

      {(is_show_scope && training_set_info != null) &&
        <ScopeModal
          on_hide={() => hide_scope_modal(history)}

          title={training_set_info.name}
          description={training_set_info.description}
          notes={training_set_info.notes}
          phrases_to_highlight={phrases_to_highlight}
          no_highlighting={ts_user_settings.no_highlighting}

          on_change_title={on_change_classifier_title}
          on_change_description={on_change_classifier_description}
          on_change_notes={on_change_classifier_notes}
          on_change_phrases_to_highlight={on_change_phrases_to_highlight}
          on_change_no_highlighting={on_change_no_highlighting}
        />
      }
    </>
  )
}

export default withUser(withRouter(ViewerContainer))