import React from 'react'
import cn from 'classnames'

import DatePicker from 'react-datepicker'
import { EditIcon } from '../widgets/IconSet.js'

import s from './AlertScheduleDatePicker.module.scss'
import cs from '../cipher_styles.module.scss'


const AlertScheduleDatePicker = ({min_date, id, max_date, on_select, selected_date, is_open, on_open, handle_close, className}) => {
  return (
    <div
      title='Reschedule'
      className='d-flex'
      onClick={on_open}
    >
      <DatePicker
        className={cn(s.date_input, className)}
        id={id || 'alert-schedule-datepicker'}
        selected={selected_date}
        onChange={on_select}
        minDate={min_date}
        maxDate={max_date}
        peekNextMonth
        open={is_open}
        onClickOutside={handle_close}
        showMonthDropdown={false}
        showYearDropdown={false}
        onChangeRaw={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        dateFormat='yyyy-MM-dd'
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport'
          }
        }}
      />
      <div className={cn('my-auto', 'pr-2', cs.cursor_pointer, cs.cipher_red_text)}>
        <EditIcon/>
      </div>
    </div>
  )
}

export default AlertScheduleDatePicker