import React from 'react'
import { withRouter, matchPath } from 'react-router'
import cn from 'classnames'
import { RouteWithTracing } from '../RouteWithTracing'

import {
  ChevronRightIcon,
  BackwardIcon,
  ForwardIcon
} from '../widgets/IconSet.js'
import DatasetSelectToggle from './DatasetSelectToggle.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import SpecHelpLink from './SpecHelpLink.js'
import ItemHeaderUndoButton from './ItemHeaderUndoButton.js'
import ItemHeaderButton from './ItemHeaderButton.js'

import s from './ItemHeader.module.scss'

const ItemHeader = (
  {
    className,
    children,

    history,

    on_close,
    on_next_item,
    on_prev_item,

    group_name,
    spec_name,
    short_spec_name,
    spec_id,
    item,
    level_1_path,

    level_2_label,
    level_2_path,

    level_3_label,
    level_3_path,

    hide_prev_next_nav,
    refresh,
    is_selected,
    on_dataset_select,
    on_dataset_deselect,

    return_btn_ref
}) => {

  const path = window.location.pathname

  const level_1_exact_match = level_1_path == null ? null : matchPath(path, { path: level_1_path, exact: true })

  const { description, get_description, help_article } = item || {}
  const description_to_display = get_description ? get_description({item}) : description

  return (
    <div className={cn('d-flex align-items-center flex-wrap flex-lg-nowrap justify-content-between', s.header, className)}>

      {/* Buttons */}
      <div className={cn('d-flex order-2 order-lg-1 mt-2 mt-lg-0 mr-2 pb-1', {[s.nav_wrapper]: !hide_prev_next_nav})}>

        <ItemHeaderUndoButton
          return_btn_ref={return_btn_ref}
          on_refresh={refresh}
          on_close={on_close}
          level_1_path={level_1_path}
          level_2_path={level_2_path}
          level_3_path={level_3_path}

          className='pt-1'
        />

        {!hide_prev_next_nav &&
          <>
            <ItemHeaderButton
              className={cn('ml-2 my-auto flex-grow-0 flex-shrink-0', s.nav_icon__spec)}
              is_disabled={!on_prev_item || !level_1_exact_match}
              on_click={on_prev_item}
            >
              <BackwardIcon/>
            </ItemHeaderButton>
            <ItemHeaderButton
              className={cn( 'ml-2 my-auto flex-grow-0 flex-shrink-0', s.nav_icon__spec, s.nav_icon__next)}
              is_disabled={!on_next_item || !level_1_exact_match}
              on_click={on_next_item}
            >
              <ForwardIcon/>
            </ItemHeaderButton>
          </>
        }

        {spec_id &&
          <div className={cn( s.nav_icon, s.nav_icon__spec, 'my-auto ml-2 p-0')}>
            <DatasetSelectToggle
              spec_id={spec_id}
              is_selected={is_selected}
              on_dataset_select={on_dataset_select}
              on_dataset_deselect={on_dataset_deselect}
              className={cn( s.select_dataset_toggle)}
            />
          </div>
        }
      </div>

      <span className={cn('d-flex flex-wrap align-items-center order-1 order-lg-2 w-100 w-lg-auto', s.level_items)}>

        {/* Level 1 */}
        {level_1_path &&
          <RouteWithTracing
            path={level_1_path}
            render={({ match }) => {
              return (
                <>
                  <a
                    className={cn(
                      'd-flex',
                      'flex-wrap',
                      s.breadcrumb_link,
                      { [s.breadcrumb_link__selected]: match.isExact }
                    )}
                    title={`${group_name}: ${spec_name}`}
                    href={level_1_path}
                    onClick={(event) => {
                      event.preventDefault()
                      history.push(level_1_path)
                    }}
                  >
                    {group_name && <span className={cn('mr-1', s.label__group)}>{group_name}</span>}
                    <span
                      className={cn(s.label__limit_width)}
                    >
                      {level_1_exact_match && <span>{spec_name}</span>}
                      {!level_1_exact_match && <span>{short_spec_name}</span>}
                    </span>
                  </a>
                  {match.isExact && description_to_display &&
                    <InfoPopover interactive={true} placement={'top'} buttonClassName='ml-1'>
                      <div className={s.description}>
                        <span>{description_to_display}</span>
                        <SpecHelpLink help_article={help_article} className='ml-1'/>
                      </div>
                    </InfoPopover>
                  }
                </>
            )
            }}
          />
        }

        {/* Level 2 */}
        {level_2_path &&
          <RouteWithTracing
            path={level_2_path}
            render={({ match }) => (
              <span>
                <span className='ml-1'>
                  <ChevronRightIcon />
                </span>
                <a
                  className={cn(
                    'ml-1',
                    'd-inline-flex',
                    s.breadcrumb_link,
                    {
                      [s.breadcrumb_link__selected]: match.isExact
                    }
                  )}
                  href={level_2_path}
                  title={level_2_label}
                  onClick={(event) => {
                    event.preventDefault()
                    if (match.isExact) {
                      return
                    }
                    history.push(level_2_path)
                  }}
                >
                  <span
                    className={cn(s.label__limit_width)}
                  >
                    <span>{level_2_label}</span>
                  </span>
                </a>
              </span>
            )}
          />
        }

        {/* Level 3 */}
        {level_3_path &&
          <RouteWithTracing
            path={level_3_path}
            render={({ match }) => (
              <span>
                <span className='ml-1'>
                  <ChevronRightIcon />
                </span>
                <a
                  className={cn(
                    'ml-1',
                    s.breadcrumb_link,
                    { [s.breadcrumb_link__selected]: match.isExact }
                  )}
                  href={level_3_path}
                  title={level_3_label}
                  onClick={(event) => {
                    event.preventDefault()
                    if (match.isExact) {
                      return
                    }
                    history.push(level_3_path)
                  }}
                >
                  <span>{level_3_label}</span>
                </a>
              </span>
            )}
          />
        }

      </span>

      {/* Extra stuff */}


      <div className='my-auto order-3'>
        {children}
      </div>

    </div>
  )
}

export default withRouter(ItemHeader)