import _ from 'underscore'

import {
  CLASSIFIER_LANDSCAPE_REPORT_TYPE,
  DEFAULT_REPORT_TYPE
} from '../../../constants/constants.js'
import { BUILD_CLASSIFIER_REPORT, BUILD_REPORT } from '../../../constants/paths.js'
import { TYPE_PATENT_FAMILIES } from '../../../model/portfolio_basket.js'
import {
  build_technology_basket_for_single_classifier,
  save_evaluation_report_input
} from '../../../utils/report_builder_utils.js'
import { build_classifier_sync, get_latest_built_classifier } from '../../../utils/training_set_grpc_utils.js'
import { ID_TO_ENGINE_ITEM } from '../model/classifier_engines.js'
import { get_clean_classifier_description } from '../../../utils/classifier_description_utils.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../../../constants/report_input.js'
import {
  get_negatives_processing,
  NEGATIVES_PROCESSING_EXCLUDE,
  NEGATIVES_PROCESSING_INCLUDE
} from '../../../model/negatives_processing.js'

export function get_eval_report_builder_input(
  {
    report_title,
    classifier_id,
    training_set_id,
    training_set_name,
    start_report,
    patfam_ids /* optional */,
    portfolio_name /* optional */,
    negatives_processing /* optional */,
    training_set_description /* optional */,
    no_classifier /* optional */
  }) {
  // If patfam_ids are specified, create portfolio_basket and portfolio_meta (otherwise leave empty)

  const portfolio_basket = !patfam_ids ? [] : [{
    name: portfolio_name,
    pat_fam_ids: patfam_ids,
    type: TYPE_PATENT_FAMILIES,
    group_by_owner: true
  }]

  const report_options = get_report_options(report_title, {negatives_processing: negatives_processing || get_negatives_processing({type: NEGATIVES_PROCESSING_INCLUDE})})

  return {
    start_report,
    portfolio_basket,
    technology_basket: no_classifier ? {} : build_technology_basket_for_single_classifier(classifier_id, training_set_name, training_set_description),
    report_options,
    report_type: DEFAULT_REPORT_TYPE,
    evaluation_classifier_id: training_set_id
  }
}

export function get_eval_landscape_report_builder_input(report_title, classifier_id, training_set_id, training_set_name, training_set_description) {
  const description = get_clean_classifier_description(training_set_description)

  return {
    selected_classifiers: [{
      path: [],
      classifier_id: classifier_id,
      name: training_set_name,
      ...description ? {description} : {}
    }],
    report_name: report_title,
    should_prepare_report: true,
    report_type: CLASSIFIER_LANDSCAPE_REPORT_TYPE,
    evaluation_classifier_id: training_set_id
  }
}

export function update_eval_report_input({report_input, report_name}) {
  const { technology_partitioning } = report_input
  const { include_negatives /*deprecated by negatives_processing*/, negatives_processing } = technology_partitioning || {}
  const updated_technology_partitioning = technology_partitioning && !negatives_processing ?
    {...technology_partitioning, negatives_processing: get_negatives_processing({type: include_negatives ? NEGATIVES_PROCESSING_INCLUDE : NEGATIVES_PROCESSING_EXCLUDE})} :
    technology_partitioning

  return { ...report_input, name: report_name, technology_partitioning: updated_technology_partitioning }
}

export function get_rerun_existing_report_input(report_input, classifier_id, classifier_title, start_report, classifier_description) {
  const { portfolios, meta, name, technology_partitioning } = report_input
  const { portfolio_meta } = meta

  return {
    start_report,
    portfolio_basket: portfolios,
    portfolio_meta,
    technology_basket: build_technology_basket_for_single_classifier(classifier_id, classifier_title, classifier_description),
    report_options: get_report_options(name, technology_partitioning),
    report_type: DEFAULT_REPORT_TYPE,
    evaluation_classifier_id: classifier_id,
  }
}

function get_report_options(report_name, technology_partitioning) {
  // There may be a technology_partitioning from a previous report, so to try to use the same values
  const {
    negatives_processing,
    multi_label=false,
    threshold=CLASSIFIER_SCORE_THRESHOLD_DEFAULT
  } = technology_partitioning || {}

  return {
    auto_report_name: report_name,
    negatives_processing,
    multi_label,
    threshold
  }
}

export function get_and_rebuild_classifier(training_set_id) {
  // TODO: switch this to use correct build...

  // SYNC BUILD
  // Get latest build
  return get_latest_built_classifier(training_set_id)
    .then(({ latest_built_classifier_metadata }) => {
      const { classifier_engine: metadata_engine_id } = latest_built_classifier_metadata || {}

      // Use engine and default params from latest build
      const engine_id = metadata_engine_id
      const engine = ID_TO_ENGINE_ITEM[engine_id]
      const { get_build_params } = engine
      const params = get_build_params() // default params

      // Rebuild
      return build_classifier_sync(training_set_id, engine_id, params)
    })

  /*
  // ASYNC BUILD
  // Get latest build
  return get_latest_built_classifier(training_set_id)
    .then(({ latest_built_classifier_metadata }) => {
      const { classifier_engine: metadata_engine_id } = latest_built_classifier_metadata || {}

      // Use engine and default params from latest build
      const engine_id = metadata_engine_id
      const engine = ID_TO_ENGINE_ITEM[engine_id]
      const { get_build_params } = engine
      const params = get_build_params() // default params

      // Rebuild
      return create_build_classifier_job(training_set_id, engine_id, params)
      .then(() => poll_build_classifier_job_status_till_not_building(training_set_id))
      .then(() => fetch_latest_build_log_entry(training_set_id, true))
      .then((log_entry) => {
        const { event } = log_entry
        if (event === BUILD_CLASSIFIER_FAILED_ID) {
          const err = Error('Classifier Build Job failed (see service logs for more details)')
          err.log_event = event
          throw err
        }
        return event
      })
    })
    */
}

export function trigger_evaluation_report(history, user, report_builder_input, query_string) {
  // Save input to local storage
  save_evaluation_report_input(user, report_builder_input)

  // Redirect to the builder (it will pick up data from local storage)
  const url = BUILD_REPORT + (query_string ? `?${query_string}` : '')
  history.push(url)
}

export function trigger_landscape_evaluation_report(history, user, landscape_report_builder_input) {
  // Save input to local storage
  save_evaluation_report_input(user, landscape_report_builder_input)

  // Redirect to the landscape builder (it will pick up data from local storage)
  history.push(BUILD_CLASSIFIER_REPORT)
}

export function get_owner_to_patfam_ids(ids_A, owners_A, ids_B, owners_B) {
  const owner_to_patfam_ids = {}

  // We use mutation here, as the arrays are potentially large, and an immutable approach can take too much time/memory.

  ids_A.forEach((id, idx) => {
    const owner = owners_A[idx]
    const owner_ids = owner_to_patfam_ids[owner] || []
    owner_ids.push(id)
    owner_to_patfam_ids[owner] = owner_ids
  })

  ids_B.forEach((id, idx) => {
    const owner = owners_B[idx]
    const owner_ids = owner_to_patfam_ids[owner] || []
    owner_ids.push(id)
    owner_to_patfam_ids[owner] = owner_ids
  })

  const result = _.mapObject(owner_to_patfam_ids, ids => _.unique(ids))

  return result
}

export function validate_comparison_tech_response(response) {
  const { data } = response
  return data[1].length === 1
}
