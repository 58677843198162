import React, { useState } from 'react'
import cn from 'classnames'

import ProgressPrompt from './ProgressPrompt.js'
import OrgSearchIncludeSimilar from '../../orgs/OrgSearchIncludeSimilar.js'
import { PrimaryButton } from '../../widgets/Button.js'
import { get_org_as_portfolio_item } from '../../../model/portfolio_basket.js'

import cs from '../../cipher_styles.module.scss'

const PortfolioSimilarOrgs = ({is_wizard_final_step, on_step_complete}) => {
  const [selected_org, set_selected_org] = useState(null)
  const [selected_similar_orgs, set_selected_similar_orgs] = useState([])

  function on_selected_org(org) {
    set_selected_org(org)
  }

  function on_search_reset() {
    set_selected_org(null)
    set_selected_similar_orgs([])
  }

  function on_finish() {
    const portfolios_to_add = [get_org_as_portfolio_item(selected_org), ...(selected_similar_orgs || []).map(get_org_as_portfolio_item)]

    on_step_complete(portfolios_to_add)
  }

  return (
    <div>
      <div className='d-sm-flex'>
        <ProgressPrompt
          is_wizard_final_step={is_wizard_final_step}
          can_wizard_continue={selected_org != null}
          on_step_complete={on_finish}
        >
          <span>Search for organisations and add them to the report.</span>
        </ProgressPrompt>
        {selected_org &&
          <PrimaryButton
            outline
            onClick={on_search_reset}
            className={cn('ml-sm-2 mt-2 mt-sm-0', cs.white_space_nowrap)}
          >
            Search again
          </PrimaryButton>
        }
      </div>
      <OrgSearchIncludeSimilar
        selected_org={selected_org}
        on_org_select_handler={(item) => on_selected_org(item.org)}
        selected_similar_orgs={selected_similar_orgs}
        on_update_selected_similar_orgs_handler={set_selected_similar_orgs}

        similar_orgs_limit={12}
        className='mt-3'
      />

    </div>
  )
}

export default PortfolioSimilarOrgs