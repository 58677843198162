import React from 'react'
import cn from 'classnames'

import Plot from './CustomPlotLazy.js'

import Legend from './Legend.js'
import ChartAndLegendHolder from './ChartAndLegendHolder.js'
import { BASE_LAYOUT_PROPS, BASE_OTHER_PROPS } from './base_plotly_props.js'

import {
  get_keys_to_value,
  get_value,
  get_value_column_idx,
  get_key_items_sorted_by_value,
  IS_NEXT_AGGLOM
} from '../../utils/column_data_utils.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import { CIPHER_BLACK, CIPHER_WHITE, DEFAULT_1D_COLOR_SCHEME } from '../../constants/colours.js'
import { is_report_series_sort_by_size } from '../../utils/report_utils.js'
import { KEY_COLUMN_IDX_1D } from '../../model/column_data.js'
import { should_hide_next_agglom_item, THUMBNAIL_MAX_SERIES } from '../../utils/main_items_utils.js'
import { get_as_map, sum } from '../../utils/utils.js'
import { limit_long_text_with_ellipsis } from '../../utils/name_utils.js'

import s from './PieChart.module.scss'
// NOTE: adds global css overrides to plotly.override.scss

const NORMAL_MARGIN = {
  t: 20, // leave space for tooltips ?
  r: 10,
  b: 20,
  l: 10
}

const THUMBNAIL_MARGIN = {
  t: 10, // leave space for tooltips ?
  r: 10,
  b: 20,
  l: 10
}

function get_plotly_data({ spec, data, key_dims, item, report_series_sort }) {
  const { plotly_data } = get_processed_data({ spec, data, key_dims, item, report_series_sort, is_download: true })
  return plotly_data
}

function get_axis_titles(spec) {
  const { column_names } = spec
  const [ x_axis_title, y_axis_title ] = column_names // we'll use the value column as the y_axis (useful for showing data as table)
  return { x_axis_title, y_axis_title }
}

function get_csv_value_rows({ spec, data, key_dims, item, report_series_sort }) {
  const { plotly_data } = get_processed_data({ spec, data, key_dims, item, report_series_sort, is_download: true })

  const { labels, values } = plotly_data
  if (!values.length) {
    return []
  }

  const rows = labels.map((label, i) => {
    return [label, values[i]]
  })

  return rows
}

function get_processed_data({ spec, data, key_dims, item, report_series_sort, report_region_column, is_thumbnail, is_download }) {
  const hide_next = should_hide_next_agglom_item(spec, item, 0)

  const { full_length_value_formatter } = spec
  const key = get_key_from_spec(spec, report_region_column)

  const key_items = key_dims[KEY_COLUMN_IDX_1D] // Should be only one dimension

  const key_items_filtered = hide_next ? key_items.filter(item => (!item[IS_NEXT_AGGLOM])) : key_items

  const query_key = key[0] // query i.e. 'PFTP.portfolio_id'

  const value_column_idx = get_value_column_idx(data)

  const keys_to_value = get_keys_to_value(data, [KEY_COLUMN_IDX_1D], value_column_idx, spec.show_average)

  // Sort items by value descending (but make sure "NEXT" / "OTHER" agglom goes at the end)
  const __key_items_sorted =  is_report_series_sort_by_size(report_series_sort) ? get_key_items_sorted_by_value(key_items_filtered, keys_to_value) : key_items_filtered
  const key_items_sorted = is_thumbnail ? __key_items_sorted.slice(0, THUMBNAIL_MAX_SERIES) : __key_items_sorted

  const key_items_sorted_with_colours = key_items_sorted
    .map((item, idx) => ({...item, color: item.color || DEFAULT_1D_COLOR_SCHEME[idx % DEFAULT_1D_COLOR_SCHEME.length]})) // add a default colour if none given

  const key_items_sorted_no_zeros = key_items_sorted_with_colours.filter(item => get_value(keys_to_value, [item.id]))

  const values = key_items_sorted_no_zeros.map(item => get_value(keys_to_value, [item.id]) || 0)

  const plotly_data = {
    values,
    text:   full_length_value_formatter ? values.map(value => full_length_value_formatter(value)) : values,
    labels: key_items_sorted_no_zeros.map(item => is_download ? item.name : limit_long_text_with_ellipsis(item.name, 75)), // use short_name (plotly tooltip does not handle long names well, and namelength property does not seem to work)
    ids:    key_items_sorted_no_zeros.map(item => item.id),
    marker: {
      colors: key_items_sorted_no_zeros.map(item => item.color),
      line: {
        color: CIPHER_WHITE, // color of line enclosing each wedge
        width: 1 // in px
      }
    }
  }

  let keys_to_value_filtered = {}

  key_items_sorted_no_zeros.forEach(item => {
    const {id} = item
    keys_to_value_filtered = {...keys_to_value_filtered, [id]: keys_to_value[id]}
  })

  return {
    plotly_data,
    key_items_sorted,
    query_key,
    keys_to_value: keys_to_value_filtered
  }
}

// Plotly click handler bug workaround:
// It seems Plotly does not rebind the click handler on update.
// So in order to make sure it can access the latest data, we store this in a mutable variable here.
// Hopefully they will fix this soon.
let _key_items_sorted_by_id = null

const PieChart = ({ spec, data, key_dims, item, is_thumbnail, selections, set_families_subselections, set_selected, report_series_sort }) => {
  const {report_region_column} = selections
  const { plotly_data, key_items_sorted, query_key, keys_to_value } = get_processed_data({ spec, data, key_dims, item, report_series_sort, report_region_column, is_thumbnail })

  // Workaround for click handler (see above)
  if (!is_thumbnail) {
    _key_items_sorted_by_id = get_as_map(key_items_sorted, 'id')
  }

  const { value_formatter, no_clickthrough } = spec

  // By default, Plotly adds a label to every pie slice.
  // But for small slices, the labels appear outside the slice, which is ugly when there are 100s or 1000s of slices.
  // Here, we explicitly turn off the labels for slices that are less than 1%.
  // See https://github.com/plotly/plotly.js/issues/452
  const { values } = plotly_data
  const total = sum(values)
  const text_positions = values.map(value => value/total > 0.01 ? 'inside' : 'none')

  // See https://plot.ly/javascript/reference/#pie
  const pie = (
    <div className={cn(
      {
        [s.pie]: !is_thumbnail,
        [s.pie__thumbnail]: is_thumbnail,
        piechart_pointer_cursor: !no_clickthrough // Plotly standard cursor is "default", so we need to override it to "pointer" when we have clickthroughs
      })}>

      <Plot
        {...BASE_OTHER_PROPS}
        useResizeHandler={true}
        data={[
          {
            ...plotly_data,
            type: 'pie',
            hole: .4,
            direction: 'clockwise', // 'clockwise' or 'counterclockwise'
            rotation: 0, // start angle - number between -360 and 360
            // hoverinfo: // Set tooltip content. Any combination of "label", "text", "value", "percent", "name" joined with a "+" OR "all" or "none" or "skip".
            // hoverlabel: {
            //   namelength: 20 // Limit tooltip length. Hmmmm seems like this does not work
            // },
            hoverinfo: "label+text+percent",
            sort: false,
            textinfo: 'percent', // Any combination of "label", "text", "value", "percent" joined with a "+" OR "none".
            ...(is_thumbnail ? {
              // For thumbails, hide the labels.
              // Setting textinfo 'none' should do this, but resize can throw 'cannot find property color' errors.
              // As a workaround, we hide the labels by setting texttemplate to an empty string (this overrides textinfo)
              texttemplate: ' ',
            } : {}),
            textposition: text_positions,
            hoverlabel: {
              bgcolor: CIPHER_BLACK // black background for tooltips
            }
          }

        ]}
        layout={{
          ...BASE_LAYOUT_PROPS,
          margin: is_thumbnail ? THUMBNAIL_MARGIN : NORMAL_MARGIN,

          hovermode:  !is_thumbnail
        }}
        config={{displayModeBar: false}}
        onClick= {function({points}) {
          if (is_thumbnail) {
            // show the dataset (set_main_item_id), as click does NOT bubble up.
            set_selected()
            return
          }

          if (!points || !points.length) {
            return
          }
          const { value, id } = points[0]
          const [idx] = id
          const item = _key_items_sorted_by_id[idx] // Workaround: read data from mutable variable (see above)

          if (!item) {
            // No item anymore (DOM may have been updated already (i.e. by some network call)).
            // So do nothing.
            return
          }

          handle_clickthrough(item, value)
        }}
      />
    </div>
  )

  const legend = is_thumbnail ? null : (
    <Legend
      items={key_items_sorted}
      keys_to_value={keys_to_value}
      value_formatter={value_formatter}
      show_total={true}
      no_clickthrough={no_clickthrough}
      on_click_item={(item, value) => handle_clickthrough(item, value)}
    />
  )

  function handle_clickthrough(item, value) {
    const {is_total} = item
    const items = is_total ? key_items_sorted : [item]

    set_families_subselections(
      [{ key: query_key, items }], // subselections
      value                                // value (can be useful occasionally)
    )
  }

  return (
    <ChartAndLegendHolder
      chart={pie}
      legend={legend} // may be null
    />
  )
}

PieChart.get_plotly_data = get_plotly_data

PieChart.get_axis_titles = get_axis_titles

PieChart.get_csv_value_rows = get_csv_value_rows

export default PieChart
