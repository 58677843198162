import React from 'react'
import * as d3 from 'd3'

import { CIPHER_BLUE } from '../../../constants/colours.js'
import { BASE_COLOUR } from '../utils/executive_summary_constants.js'

const TOP_MARGIN = 5
const COLUMN_MARGIN = 6

function total(data, dims) {
    return data[dims[0]] + data[dims[1]]
}

class MiniColumnChart extends React.Component {

  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  componentDidMount() {
    const
        containerWidth   = this.props.width || 100,
        containerHeight  = this.props.height || 100,
        values           = this.props.values,
        xdims            = this.props.xdims,
        stacked_dims     = this.props.stacked_dims,
        colours          = this.props.colours || [BASE_COLOUR, CIPHER_BLUE]


    // For now, assume values is a 2d array

    // Num items
    const num_items = xdims.length

    // Render chart
    const margin = {top: TOP_MARGIN, right: 0, bottom: 20, left: 0},
        width = containerWidth - margin.left - margin.right,
        height = containerHeight - margin.top - margin.bottom

    // Column width
    const total_column_widths = width - (num_items * COLUMN_MARGIN)
    const column_width = total_column_widths / num_items

    const stack = d3.stack()
        .keys(stacked_dims)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone)

    const stacked_values = stack(values)

    // Get max value
    const max_value = d3.max(values, function(d) { return total(d, stacked_dims) })

    const x = d3.scaleBand()
      .rangeRound([0, width + COLUMN_MARGIN]) // the last margin overflows
      .domain(xdims)

    const y = d3.scaleLinear()
      .rangeRound([height, 0])
      .domain([0, max_value])
      .nice()

    const container_node = this.container_ref.current
    const canvas = d3.select(container_node)

    let layer = canvas.append('g')
        .selectAll('.layer')
        .data(stacked_values)
        .enter().append('g')
        .attr('class', 'layer')
        .style('fill', function(d, i) { return colours[i] })

    layer.selectAll('rect')
        .data(function(d) { return d })
        .enter().append('rect')
        .attr('x', function(d) { return x(d.data.bucket) })
        .attr('y', function(d) { return y(d[1]) })
        .attr('height', function(d) { return y(d[0]) - y(d[1]) })
        .attr('width', column_width)
  }

  render() {
    return (
      <g transform={'translate(0, ' + TOP_MARGIN + ')'} ref={this.container_ref} className='mini-column-chart'/>
    )
  }
}

export default MiniColumnChart
