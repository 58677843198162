import React from 'react'
import _ from 'underscore'
import cn from 'classnames'
import {DropdownMenu, UncontrolledDropdown, DropdownItem, FormFeedback, FormGroup, Label, Input} from 'reactstrap'
import DatePicker from 'react-datepicker'

import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import { SELECTED_PORTFOLIOS } from '../../model/deref.js'
import { get_as_map } from '../../utils/utils.js'
import {
  APRIORI_CUTOFF_PARAM_NAME,
  FILING_STRATEGY_PARAM_NAME,
  OVERLAP_PARAM_NAME,
  STATUS_FILTERS_PARAM_NAME,
  VERSION_PARAM_NAME
} from '../../model/hyperscripts.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { can_select_nd_version } from '../../utils/user_permissions.js'

import 'react-datepicker/dist/react-datepicker.css'
import '../../css_overrides/react-datepicker.override.scss'

import s from './HyperscriptSetupParams.module.scss'

export const CheckboxList = ({setup_params, current_param_values, on_change, is_inline_list, disabled, user, report_has_scores}) => {
  return (
    <>
      {setup_params.map((item, i) => {
        const {label, id, check_if_available} = item

        const is_available = check_if_available ? check_if_available({user, report_has_scores}) : true
        if (!is_available) return null

        const is_checked = current_param_values[id]
        return (
          <CheckboxAndLabel
            label={label}
            is_checked={is_checked}
            is_disabled={disabled}
            className={is_inline_list ? 'mr-3' : ''}

            on_click={() => {
              const updated_values = {...current_param_values, [id]: !is_checked}
              on_change(updated_values)
            }}
            key={i}
          />
      )})}
    </>
  )
}

export const NdReportSetupParams = ({ user, current_param_values, on_change, disabled }) => {
  function on_version_change(selected_version) {
    on_change({...current_param_values, [VERSION_PARAM_NAME]: selected_version})
  }

  const selected_version = current_param_values[VERSION_PARAM_NAME] || 1

  return (
    <>
      <FormGroup className='mb-2'>
        <Label className={cn('ml-0 pr-2')}>
          Exclude families expiring before (optional):
        </Label>
        <DatePicker
          selected={current_param_values['expiry_cutoff']}
          onChange={date => on_change({...current_param_values, expiry_cutoff: date})}
          disabled={disabled}
          isClearable
          peekNextMonth
          showMonthDropdown
          scrollableMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          onChangeRaw={(e) => e.preventDefault()}
          dateFormat='yyyy/MM/dd'
          popperPlacement='right'
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport'
            }
          }}
        />
      </FormGroup>

      {can_select_nd_version(user) &&
        <FormGroup className='d-flex mb-2'>
          <Label >Version:</Label>
          <RadiobuttonWithLabel className='ml-2' label='1' is_checked={selected_version === 1} on_click={() => on_version_change(1)}/>
          <RadiobuttonWithLabel className='ml-1' label='2' is_checked={selected_version === 2} on_click={() => on_version_change(2)}/>
        </FormGroup>
      }
    </>
  )
}

export const PublicationsSetupParams = ({setup_params, current_param_values, on_change, disabled, user, report_has_scores}) => {
  return <CheckboxList
    setup_params={setup_params}
    current_param_values={current_param_values}
    on_change={on_change}
    disabled={disabled}
    user={user}
    report_has_scores={report_has_scores}
  />
}

export const BenchmarkMetricsSetupParams = ({setup_params, current_param_values, on_change, disabled}) => {
  return <CheckboxList
    setup_params={setup_params}
    current_param_values={current_param_values}
    on_change={on_change}
    disabled={disabled}
  />
}

export const SPIFSetupParams = ({setup_params, current_param_values, on_change, disabled}) => {
  return <CheckboxList
    setup_params={setup_params}
    current_param_values={current_param_values}
    on_change={on_change}
    disabled={disabled}
  />
}

export const OverlapSetupParams = ({current_param_values, on_change, disabled}) => {
  const is_org_selected = current_param_values[OVERLAP_PARAM_NAME]

  function on_click(is_org_selected) {
    on_change({[OVERLAP_PARAM_NAME]: is_org_selected})
  }

  return (
    <FormGroup className={cn(s.block__overlap)}>
      <Label>Measure overlap of:</Label>
      <RadiobuttonWithLabel label='technologies'  is_disabled={disabled} is_checked={!is_org_selected} on_click={() => on_click(false)}/>
      <RadiobuttonWithLabel label='organisations' is_disabled={disabled} is_checked={is_org_selected}  on_click={() => on_click(true)} />
    </FormGroup>
  )
}

export const FilingStrategySetupParams = ({deref_data, current_param_values, on_change, disabled}) => {
  const selections = deref_data || {}

  const selected_portfolios = selections[SELECTED_PORTFOLIOS] || []

  const portfolio_by_id = get_as_map(selected_portfolios, 'id')
  const selected_portfolio = portfolio_by_id[current_param_values[FILING_STRATEGY_PARAM_NAME]] || {}

  const portfolio_by_name = _.indexBy(selected_portfolios, (item) => (item.name+item.id)) //names are no unique

  const multiple_portfolios = selected_portfolios.length >= 2

  return (
    <FormGroup>
      <UncontrolledDropdown disabled={disabled}>
        <SelectDropdownToggleStatic>
          {selected_portfolio.name || 'Select organisation'}
        </SelectDropdownToggleStatic>
        <DropdownMenu className={s.menu}>
          {Object.keys(portfolio_by_name || []).sort().map((name_and_id, i) => {
            const item = portfolio_by_name[name_and_id]
            const {id, name} = item

            return (
              <DropdownItem key={i} onClick={() => on_change({[FILING_STRATEGY_PARAM_NAME]: id})}>
                {name}
              </DropdownItem>
          )})}

        </DropdownMenu>
      </UncontrolledDropdown>

      <FormFeedback className={cn({'d-block': !multiple_portfolios})} valid={multiple_portfolios}> Please make sure the report includes multiple organisations.</FormFeedback>
    </FormGroup>
  )
}

export const GeoComparisonSetupParams = ({current_param_values, on_change}) => {
  const filing_status_options = {
    'Granted patents': `granted`,
    Applications: `pending`,
    Both: `granted,pending`
  }
  const selected_filing_status = _.invert(filing_status_options)[current_param_values[STATUS_FILTERS_PARAM_NAME]]
  return (
    <FormGroup className={cn(s.block__overlap)}>
      <Label>Filing status:</Label>
      {Object.keys(filing_status_options).map((o_key, idx) => {
        return (
          <RadiobuttonWithLabel
            label={o_key} key={idx}
            is_checked={o_key===selected_filing_status}
            on_click={() => on_change({[STATUS_FILTERS_PARAM_NAME]: filing_status_options[o_key]})}
          />
        )
      })}
    </FormGroup>
  )
}

export const TrendspotterSetupParams = ({current_param_values, on_change}) => {
  function is_valid_cutoff(value) {
    return 1 >= value && 5 <= value
  }

  const selected_apriori_cutoff_year = current_param_values[APRIORI_CUTOFF_PARAM_NAME]
  return (
    <FormGroup className={cn(s.flex_form_group)}>
      <Label>Apriori cutoff years:</Label>
      <Input
        className={cn(s.number_input, 'ml-2')}
        type='number'
        valid={is_valid_cutoff(selected_apriori_cutoff_year)}
        value={selected_apriori_cutoff_year}
        min={1}
        max={5}
        onChange={(e) => on_change({[APRIORI_CUTOFF_PARAM_NAME]: e.target.value})}
        bsSize={'sm'}
      />
    </FormGroup>
  )
}