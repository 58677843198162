import React from 'react'
import { withRouter } from 'react-router-dom'

import { BUILD_UTT_LANDSCAPE_REPORT } from '../../constants/paths.js'

import { track_report_builder_event } from '../../utils/tracking_utils.js'

import { DashboardSmallTile } from './DashboardTile.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { DashboardTileInner } from './DashboardTileInner.js'

import { PrimaryButton } from '../widgets/Button.js'
import { get_utt_landscape_report_help_url } from '../../utils/help_utils.js'
import { TileHelpLink } from './TileHelpLink.js'
import TileTitle from './TileTitle.js'

const UTTLandscapeReportPanel = ({ history }) => {

  function on_click_from_new_report_btn() {
    track_report_builder_event('action="select_builder" report_type="utt_landscape_report" context="dashboard"')
    history.push(BUILD_UTT_LANDSCAPE_REPORT)
  }

  const title = (
    <TileTitle
      title='UTT Landscape Report'
    />
  )

  return (
    <DashboardSmallTile
      title={title}
      hint={<TileHelpLink help_url={get_utt_landscape_report_help_url()} />}
    >
      <DashboardTileInner>
        <DashboardTileDesc>
          Create a new report for all owners, by finding all patent families in a single technology of the Universal Technology Taxonomy (UTT).
        </DashboardTileDesc>
        <PrimaryButton className='w-100 mt-3 mt-md-auto' onClick={on_click_from_new_report_btn}>Start</PrimaryButton>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(UTTLandscapeReportPanel)