import React from 'react'
import cn from 'classnames'

import MultiLabelOptions from '../MultiLabelOptions.js'
import NegativesProcessingOptions from '../NegativesProcessingOptions.js'
import OwnerLevelControl from '../OwnerLevelControl.js'
import PortfolioRollupLimitControl from '../PortfolioRollupLimitControl.js'
import { ND_REPORT_STREAM_ID } from './report_streams.js'

const ReportInputParams = (
  {
    selected_stream_id,
    is_tech_landscape,
    is_multi_class,

    multi_label,
    negatives_processing,
    group_by_owner_level,
    portfolio_roll_up_limit,

    on_change_multi_label_handler,
    on_change_negatives_processing_handler,
    on_change_group_by_owner_level,
    on_change_portfolio_roll_up_limit,

    className
  }) => {

  const { type: negatives_processing_type } = negatives_processing || {}

  const show_multi_label_as_dropdown = is_tech_landscape

  return (
    <div className={className}>

      {is_multi_class &&
        <MultiLabelOptions
          technology_multi_label={multi_label}
          update_technology_multi_label={on_change_multi_label_handler}
          show_as_dropdown={show_multi_label_as_dropdown}
          className={cn([{'d-flex': show_multi_label_as_dropdown}])}
          labelClassName={cn([{'my-auto mr-1': show_multi_label_as_dropdown}])}
        />
      }

      {!is_tech_landscape &&
        <NegativesProcessingOptions
          selected={negatives_processing_type}
          on_select={on_change_negatives_processing_handler}
          is_nd_report={selected_stream_id === ND_REPORT_STREAM_ID}
          className='mt-3'
        />
      }

      {is_tech_landscape &&
        <OwnerLevelControl
          selected_level={group_by_owner_level}
          on_select={on_change_group_by_owner_level}
          className='d-flex mt-3'
        />
      }

      {is_tech_landscape &&
        <PortfolioRollupLimitControl
          portfolio_roll_up_limit={portfolio_roll_up_limit}
          set_portfolio_roll_up_limit={on_change_portfolio_roll_up_limit}
          className='mt-3'
        />
      }

    </div>
  )
}

export default ReportInputParams