import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { ALL_STATUS_IDS, ALL_STATUSES } from '../../model/statuses.js'
import { ID_TO_FIELD } from '../../model/patent_family_fields.js'
import { PAGE_SIZES } from '../../model/patent_family_list_page_sizes.js'
import { SORT_DIRECTIONS, ID_TO_SORT_DIRECTION } from '../../model/sort_directions.js'
import { TABLE_LINE_RESTRICTIONS, ID_TO_TABLE_LINE_RESTRICTION } from '../../model/table_line_restrictions.js'
import { TABLE, CARDS } from '../../model/patent_family_list_views.js'

import { NO_FILTER } from '../classifiers_editor/model/filters.js'

import { format_integer_with_comma } from '../../utils/utils.js'

import PatentFamilyListGuideLink from './PatentFamilyListGuideLink.js'
import PatentFamiliesTable from './PatentFamiliesTable.js'
import PatentFamilyListControlsRow from './PatentFamilyListControlsRow.js'

import PatentCardList from '../classifiers_editor/components/PatentCardList.js'

import SearchBar from '../widgets/SearchBar.js'
import Spinner from '../widgets/Spinner.js'
import { useOnScreen } from '../../hooks/general_hooks.js'
import { CONTEXT_REPORT } from '../../constants/context.js'

function get_is_filter_applied(status_ids, score_filter_id) {
  if (status_ids && status_ids.length !== ALL_STATUS_IDS.length) {
    return true
  }

  if (score_filter_id !== NO_FILTER) {
    return true
  }

  return false
}

const PatentFamiliesListWithControls = (
  {
    className,

    search_input_ref,
    next_search_phrase,
    on_change_from_search_input,
    on_clear_search_input,
    apply_search_input,

    controls_row_className,
    table_header_className,

    page_top,
    table_top,

    status_ids,
    on_change_status_ids,

    all_sort_fields,
    sort_field_id,
    on_change_sort_field_id,
    sort_direction_id,
    on_change_sort_direction_id,
    on_change_sort_field_id_and_sort_direction_id,

    patent_family_list_view_id,
    on_change_patent_family_list_view_id,

    all_fields,
    selected_field_ids,
    default_selected_field_ids,
    on_change_selected_field_ids,

    table_line_restriction_id,
    on_change_table_line_restriction_id,
    patent_link_option_id,

    page_size,
    on_page_size_change,
    page,
    num_pages,
    on_page_change,

    subidx,
    on_change_subidx,
    on_show_detail,

    patent_families,
    has_results,

    total,
    total_patents,
    fetching_data,
    fetching_pat_families_error,

    // Eval classifier stuff
    eval_training_set_id,
    eval_classifier_data,
    score_filter_id,
    on_change_score_filter_id,
    custom_score_range,
    on_change_custom_score_range,
    score_filter_groups,
    is_classifier_evaluation_report,
    has_classifier_builder_access,
    set_label,
    search_phrases,
    search_colours,
    no_highlighting,
    highlight_prefix_only,

    is_bulk_tagging_available,
    viewable_family_tags,
    selected_family_tags,
    fetching_tags_error,
    on_family_tags_filter_change,
    apply_tag_filter,
    show_filter_tags_spinner,
    change_apply_tag_filter,
    selected_family_ids,
    set_selected_family_ids,
    return_btn_ref,
    is_all_families_view,
    level_1_path,
    level_2_path,
    level_3_path,
    refresh,
    on_close,
  }
) => {

  const selected_fields = all_fields.filter(field => _.contains(selected_field_ids, field.id))

  const total_number_of_families_in_report_formatted = format_integer_with_comma(total)
  const total_patents_formatted = total_patents != null ? format_integer_with_comma(total_patents) : null

  const is_filter_applied = get_is_filter_applied(status_ids, score_filter_id)

  const is_header_return_btn_visible = useOnScreen(return_btn_ref)

  const show_classifier_controls = is_classifier_evaluation_report && has_classifier_builder_access

  return (
    <div className={cn(className)}>

      {/* Search Bar */}
      <div className={cn('')}>
        <PatentFamilyListGuideLink />
      </div>
      <SearchBar
        className={cn('')}
        search_input_ref={search_input_ref}
        search_input={next_search_phrase || ''}
        on_change_search_input={on_change_from_search_input}
        on_clear={on_clear_search_input}
        do_search={apply_search_input}
        search_is_fetching={fetching_data}
        is_search_valid={true}
        autofocus={false} // Don't autofocus on input, as it disables prev/next patent keyboard shortcuts
        no_button={true}
      />

      {/* Controls */}
      <PatentFamilyListControlsRow
        className={cn('mt-3', controls_row_className)}
        context={CONTEXT_REPORT} // for tracking

        statuses={ALL_STATUSES}
        selected_status_ids={status_ids}
        on_change_status_ids={on_change_status_ids}

        sort_fields={all_sort_fields}
        id_to_sort_field={ID_TO_FIELD}
        selected_sort_field_id={sort_field_id}
        on_change_sort_field_id={on_change_sort_field_id}
        sort_directions={SORT_DIRECTIONS}
        id_to_sort_direction={ID_TO_SORT_DIRECTION}
        selected_sort_direction_id={sort_direction_id}
        on_change_sort_direction_id={on_change_sort_direction_id}

        score_filter_id={score_filter_id}
        on_change_score_filter_id={on_change_score_filter_id}
        custom_score_range={custom_score_range}
        on_change_custom_score_range={on_change_custom_score_range}
        score_filter_groups={score_filter_groups}

        patent_family_list_view_id={patent_family_list_view_id}
        on_change_patent_family_list_view_id={on_change_patent_family_list_view_id}

        table_line_restrictions={TABLE_LINE_RESTRICTIONS}
        id_to_table_line_restriction={ID_TO_TABLE_LINE_RESTRICTION}
        selected_table_line_restriction_id={table_line_restriction_id}
        on_change_table_line_restriction_id={on_change_table_line_restriction_id}

        all_fields={all_fields}
        selected_field_ids={selected_field_ids}
        default_selected_field_ids={default_selected_field_ids}
        on_change_selected_field_ids={on_change_selected_field_ids}
        fields_disabled={!has_results || fetching_data} // TODO: do we need this????

        on_family_tags_filter_change={on_family_tags_filter_change}
        user_viewable_family_tags={viewable_family_tags}
        selected_family_tags={selected_family_tags}
        fetching_tags_error={fetching_tags_error}
        apply_tag_filter={apply_tag_filter}
        change_apply_tag_filter={change_apply_tag_filter}
        show_filter_tags_spinner={show_filter_tags_spinner}
        family_tags_enabled={is_bulk_tagging_available}

        page_sizes={PAGE_SIZES}
        selected_page_size={page_size}
        on_change_page_size={on_page_size_change}

        current_page={page}
        num_pages={num_pages}
        on_change_current_page={on_page_change}

        show_return_control={!is_header_return_btn_visible && !fetching_data && !is_all_families_view}
        level_1_path={level_1_path}
        level_2_path={level_2_path}
        level_3_path={level_3_path}
        refresh={refresh}
        on_close={on_close}
      />

      {fetching_data &&
        <Spinner className={cn('mt-3')}/>
      }

      <div className={cn('mt-3')}>
        {!fetching_data && !fetching_pat_families_error && total_number_of_families_in_report_formatted != null &&
          <span className='mr-1 my-auto w-75'>
            This subset contains {total_number_of_families_in_report_formatted} patent families
            {(total_patents_formatted != null && !is_filter_applied) && <span>{` (${total_patents_formatted} patents)`}</span>}
          </span>
        }

        {!fetching_data && selected_family_ids && is_bulk_tagging_available &&
          <span>{`(${selected_family_ids.length} selected)`}</span>
        }
      </div>

      {(patent_family_list_view_id === CARDS) &&
        <PatentCardList
          className={'mt-3'}
          top_margin={page_top}

          patents={patent_families}
          subidx={subidx}
          on_show_details={on_show_detail}

          num_pages={num_pages}
          current_page={page}
          on_change_current_page={on_page_change}
          on_change_subidx={on_change_subidx}
          is_fetching={fetching_data}

          // Eval classifier stuff
          eval_training_set_id={eval_training_set_id}
          eval_classifier_data={eval_classifier_data}
          show_classifier_controls={show_classifier_controls}
          set_label={set_label}
          search_phrases={search_phrases}
          search_colours={search_colours}
          no_highlighting={no_highlighting}
          highlight_prefix_only={highlight_prefix_only}
        />
      }

      {/*
          Always render the table component, as it holds scroll_x state.
          When "fetching_data" or "error", it renders an empty div.
       */}
      {(patent_family_list_view_id === TABLE) &&
        <PatentFamiliesTable
          className={'mt-3'}
          headerClassName={cn(table_header_className)}
          page_top={page_top}
          table_top={table_top}

          patfams={patent_families}
          selected_patfams={selected_family_ids}
          set_selected_patfams={set_selected_family_ids}
          bulk_select={is_bulk_tagging_available}

          subidx={subidx}
          fields={selected_fields}
          selected_table_line_restriction_id={table_line_restriction_id}
          selected_patent_link_option_id={patent_link_option_id}
          selected_sort_field_id={sort_field_id} // relates to 'fields' prop
          selected_sort_direction_id={sort_direction_id}
          on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
          on_change_subidx={on_change_subidx}
          on_show_detail={on_show_detail}
          is_fetching={fetching_data}

          // Eval classifier stuff
          show_classifier_controls={show_classifier_controls}
          set_label={set_label}
          search_phrases={search_phrases}
          search_colours={search_colours}
          no_highlighting={no_highlighting}
          highlight_prefix_only={highlight_prefix_only}
          eval_training_set_id={eval_training_set_id}
          eval_classifier_data={eval_classifier_data}
        />
      }

    </div>
  )
}

export default PatentFamiliesListWithControls