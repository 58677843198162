import React from 'react'
import cn from 'classnames'

import ButtonGroup from '../widgets/ButtonGroup.js'

const SimpleSortControl = ({ className, available_sort_items, sort_id, on_change_sort_id }) => {

  return (

    <div className={cn('d-inline-flex', 'align-items-center', className)}>

      <span className={'mr-1'}>
        Sort
      </span>

      <ButtonGroup
        size={'xs'}
        items={available_sort_items}
        selected_id={sort_id}
        on_change_selected_id={on_change_sort_id}
        className={className}
        btnClassName={cn('px-1')}
      />
    </div>
  )
}

export default SimpleSortControl