import React from 'react'

import { BannerWithSpinnerLogo, ReportInProgressBanner } from './ReportInProgressBanner.js'
import { is_creator } from '../../utils/user_permissions.js'
import ReportProgress from './ReportProgress.js'
import { is_forbidden, is_not_found } from '../../utils/axios_utils.js'
import { withUser } from '../UserContext.js'
import ErrorFetchingReportData from '../ErrorFetchingReportData.js'

const ReportInitialFetchDisplay = (
  {
    user,
    show_banner_with_spinner,
    show_choreo_progress,
    created_by_user,
    is_eval_report,
    internal_report_id,
    external_report_id,
    notify_when_complete,
    choreo_status,
    fetch_initial_error
  }) => {
  return (
    <div className='w-100'>
      {show_banner_with_spinner &&
        <div className='mt-3'>
          <BannerWithSpinnerLogo title='Loading data...' />
        </div>
      }

      {show_choreo_progress &&
        <div className='mt-3'>
          <ReportInProgressBanner
            user_is_creator={is_creator(user)}
            is_eval_report={is_eval_report}
            notify_when_complete={notify_when_complete}
            external_report_id={external_report_id}
          />
          <div className='mt-3'>
            <ReportProgress
              choreo_status={choreo_status}
            />
          </div>
        </div>
      }

      {fetch_initial_error && !is_forbidden(fetch_initial_error) && !is_not_found(fetch_initial_error) &&
        <ErrorFetchingReportData
          error_fetching_report_data={fetch_initial_error}
          internal_report_id={internal_report_id}
          external_report_id={external_report_id}
          created_by_user={created_by_user}
        />
      }

      {fetch_initial_error && is_not_found(fetch_initial_error) &&
        <div className='text-center'>Report not found.</div>
      }

      {fetch_initial_error && is_forbidden(fetch_initial_error) &&
        <div className='text-center'>You do not have permission to access this report.</div>
      }

    </div>
  )
}

export default withUser(ReportInitialFetchDisplay)