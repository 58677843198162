import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import {
  ORG_SET_TAG_GROUPS,
  ORG_SET_TAG_GROUPS_BY_ID,
  ORG_SET_TAGS_BY_ID,
  TAG_GROUP_PRODUCT
} from '../../model/org_sets.js'

import { get_selected_products } from '../../utils/org_sets_utils.js'

import s from './OrgTagSelector.module.scss'

const Tag = ({tag, on_click, selected, className}) => {
  return (
    <div className={cn(['p-1', className, s.tag, {[s.tag__selected]: selected}])} onClick={() => on_click(tag)}>{(ORG_SET_TAGS_BY_ID[tag] || {}).name || tag}</div>
  )
}

const TagGroup = ({group, tags, selected_tags, on_select_handler, on_deselect_handler, view_only_selected}) => {
  if (!tags || tags.length === 0) {
    return null
  }

  const tags_to_show = tags.map((tag) => {
    const full_tag = `${group.id}:${tag.id}`
    const selected = (selected_tags.indexOf(full_tag) !== -1)

    const on_click_handler = selected ? on_deselect_handler : on_select_handler

    const tag_display = <Tag
      key={full_tag}
      tag={full_tag}
      on_click={view_only_selected ? () => {} : on_click_handler}
      selected={selected}
      className='mt-1 mr-2'
    />

    if (view_only_selected) {
      return selected ? tag_display : null
    }

    return (
      tag_display
    )
  })

  if (tags_to_show.filter(item => item != null).length === 0) {
    return null
  }

  return (
    <div>
      <div className={cn('my-1', s.tag_group_label)}>{group.label}</div>
      <div className='d-flex flex-wrap'>
        {tags_to_show}
      </div>
    </div>
  )
}

const OrgTagSelector = ({selected_tags, on_select_handler, on_deselect_handler, view_only_selected}) => {
  const selected_products = get_selected_products(selected_tags)

  if (selected_products.length === 0) {
    return (
      <TagGroup
        group={ORG_SET_TAG_GROUPS_BY_ID[TAG_GROUP_PRODUCT]}
        tags={ORG_SET_TAG_GROUPS_BY_ID[TAG_GROUP_PRODUCT].tags}
        selected_tags={[]}
        on_select_handler={on_select_handler}
        on_deselect_handler={on_deselect_handler}
      />
    )
  }

  return (
    <div>
      {ORG_SET_TAG_GROUPS.map((item, i) => {
        const {tags} = item

        const tags_for_product = tags.filter(item => {
          const { products } = item
          return !products || _.intersection(products, selected_products).length !== 0
        })

        return (
          <TagGroup
            key={i}
            group={item}
            tags={tags_for_product}
            selected_tags={selected_tags}
            on_select_handler={on_select_handler}
            on_deselect_handler={on_deselect_handler}
            view_only_selected={view_only_selected}
          />
        )})}
    </div>
  )
}

export default OrgTagSelector