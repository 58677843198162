import * as d3 from 'd3'
import moment from 'moment'

import { APP4_API_DOMAIN, CDN_API_DOMAIN__LN } from '../constants/urls.js'
import sha1 from 'js-sha1'

export function json_make_copy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function compare_iso_date_string(iso_date_string_1, iso_date_string_2) {
  if (iso_date_string_1 < iso_date_string_2) {
    return -1
  } else if (iso_date_string_1 > iso_date_string_2) {
    return 1
  } else {
    return 0
  }
}

export function is_number(n) {
  // see http://stackoverflow.com/questions/9716468/is-there-any-function-like-isnumeric-in-javascript-to-validate-numbers
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function is_int(value) {
  // see http://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript/14794066#14794066
  return !isNaN(value) &&
    (parseInt(Number(value), 10) === value) &&
    !isNaN(parseInt(value, 10))
}

/**
 * From http://phpjs.org/functions/number_format/
 * @returns {string}
 */
export function number_format(number, decimals, dec_point, thousands_sep) {
  //  discuss at: http://phpjs.org/functions/number_format/
  // original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: davook
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Theriault
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // bugfixed by: Michael White (http://getsprink.com)
  // bugfixed by: Benjamin Lupton
  // bugfixed by: Allan Jensen (http://www.winternet.no)
  // bugfixed by: Howard Yeend
  // bugfixed by: Diogo Resende
  // bugfixed by: Rival
  // bugfixed by: Brett Zamir (http://brett-zamir.me)
  //  revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  //  revised by: Luke Smith (http://lucassmith.name)
  //    input by: Kheang Hok Chin (http://www.distantia.ca/)
  //    input by: Jay Klehr
  //    input by: Amir Habibi (http://www.residence-mixte.com/)
  //    input by: Amirouche
  //   example 1: number_format(1234.56);
  //   returns 1: '1,235'
  //   example 2: number_format(1234.56, 2, ',', ' ');
  //   returns 2: '1 234,56'
  //   example 3: number_format(1234.5678, 2, '.', '');
  //   returns 3: '1234.57'
  //   example 4: number_format(67, 2, ',', '.');
  //   returns 4: '67,00'
  //   example 5: number_format(1000);
  //   returns 5: '1,000'
  //   example 6: number_format(67.311, 2);
  //   returns 6: '67.31'
  //   example 7: number_format(1000.55, 1);
  //   returns 7: '1,000.6'
  //   example 8: number_format(67000, 5, ',', '.');
  //   returns 8: '67.000,00000'
  //   example 9: number_format(0.9, 0);
  //   returns 9: '1'
  //  example 10: number_format('1.20', 2);
  //  returns 10: '1.20'
  //  example 11: number_format('1.20', 4);
  //  returns 11: '1.2000'
  //  example 12: number_format('1.2000', 3);
  //  returns 12: '1.200'
  //  example 13: number_format('1 000,50', 2, '.', ' ');
  //  returns 13: '100 050.00'
  //  example 14: number_format(1e-8, 8, '.', '');
  //  returns 14: '0.00000001'

  number = (number + '')
    .replace(/[^0-9+\-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      const k = Math.pow(10, prec)
      return '' + (Math.round(n * k) / k)
        .toFixed(prec)
    }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
    .split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '')
    .length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1)
      .join('0')
  }
  return s.join(dec)
}

export function format_integer(d) {
  if (!is_number(d)) {
    return d
  }
  return Math.round(d)
}

export function format_integer_with_comma(d) {
  if (d == null)
    return null

  const int = format_integer(d)
  if (!is_int(int)) {
    return d
  }

  // We decided that toLocaleString() was just too badly/differently supported on browsers so we'd
  // rather have a consistent (but possibly wrongly localised) thousand separator.
  return number_format(int, 0, '.', ',')
}

export function to_date(date, no_value_msg = '') {
  return date ? extract_date_string_from_date(new Date(date)) : no_value_msg
}

export function to_local_date(date, no_value_msg = '') {
  return date ? extract_date_string_from_date(new Date(date)) : no_value_msg
}

export function to_local_datetime(date, no_value_msg = '') {
  if (!date) {
    return no_value_msg
  }
  const local_date = new Date(date)

  return `${extract_date_string_from_date(local_date)} ${extract_time_string_from_date(local_date)}`
}

export function extract_date_string_from_date(date) {
  return moment(date).format('YYYY-MM-DD')
}

function extract_time_string_from_date(date) {
  return moment(date).format('HH:mm:ss')
}

/**
 * Given an array of item objects, returns a map from key -> item.
 * @param {} items The array of items
 * @param {} key The key name to use i.e. 'id'
 * @param {} prefix Optional prefix to add to each key
 * @returns {}
 */
export function get_as_map(items, key, prefix) {
  prefix = prefix || '' // optional prefix
  key = key || 'id'

  return items.reduce(function (acc, item) {
    acc[prefix + item[key]] = item
    return acc
  }, {})
}

/**
 * Given an array of values, returns a map from val -> true.
 * @param {} arr The values
 * @returns {}
 */
export function get_array_as_val_to_true(arr) {
  // Would be nicer to use ES6 Set.
  // But IE11 does not support this fully, hence this crappy workaround.
  return arr.reduce((acc, d) => {
    acc[d] = true // mutate (as immutable may be too slow)
    return acc
  }, {})
}


export function timestamp_to_formatted_string(timestamp) {
  const format = d3.timeFormat("%Y-%m-%d")
  return format(new Date(timestamp))
}

export function disable_event_when_enter_pressed(e) {
  //if enter pressed
  if (e.which === 13) {
    e.preventDefault()
  }
}

/**
 * Returns sum of the array of values.
 */
export function sum(values) {
  return values.reduce((acc, d) => {
    return acc + (d || 0) // handle null values as 0
  }, 0)
}
export function is_array_non_empty_non_null(arr) {
  return arr && Array.isArray(arr) && arr.length > 0
}

export function is_sets_equal(a, b) {
  return a.size === b.size && [...a].every(value => b.has(value))
}

export function json_into_params_string(params) {
  return Object.keys(params || {}).map((param_name => (`${param_name}=${params[param_name]}`))).join('&')
}

export function format_string_first_character_capitalised(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export function format_string_only_first_character_capitalised(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function get_object_values(object) {
  //function to get an arrray with object values as Object.values(object) is experimental and not working in IE11

  if (!object) return []
  return Object.keys(object).map(key => object[key])
}

export function get_key_by_value(object, value) {
  return parseInt(Object.keys(object).find(key => object[key].value === value))
}

export function get_as_key_to_val(keys, val) {
  const key_to_val = {}
  keys.forEach(key => {
    key_to_val[key] = val
  })
  return key_to_val
}

export function is_ln_environment() {
  const {host} = window.location
  return (host.indexOf('.aws.cipher.ai') !== -1)
}

export function is_ln_preprod_environment() {
  const {host} = window.location
  return (host.indexOf('app2.cipher.ai') !== -1)
}

export function is_dev_environment() {
  const {host} = window.location
  return (host.indexOf('.test.aistemos.com') !== -1 || host.indexOf('localhost') !== -1)
}

export function get_cipher_hostname() {
  const {host, protocol} = window.location
  return `${protocol}//${host}`
}

export function is_prod_cdn_environment() {
  const {host} = window.location
  return startsWith(host, 'app.cipher.ai') || startsWith(host, 'app31.cipher.ai')
}

export function is_app4_environment() {
  const {host} = window.location
  return startsWith(host, 'app4.cipher.ai')
}

export function get_domain_prefix() {
  // Since we are not fetching via axios, we need to explicitly specify domain (i.e. api.cipher.ai ),
  const is_prod = is_prod_cdn_environment()
  const is_ln_preprod = is_ln_preprod_environment()
  if (is_prod) {
    // app.cipher.ai -> api.aws.cipher.ai
    return CDN_API_DOMAIN__LN
  }
  if (is_ln_preprod) {
    // app2.cipher.ai -> api.aws.cipher.ai
    return CDN_API_DOMAIN__LN
  }
  if (is_app4_environment()) {
    // app4.cipher.ai -> api4.aws.cipher.ai
    return APP4_API_DOMAIN
  }
  return get_cipher_hostname()
}

export function get_data_version_from_report_id(report_id) {
  const data_version = report_id.split('-')[1]

  if (is_number(data_version)) return parseInt(data_version)

  return -1
}

export function get_timestamp_from_report_id(report_id) {
  const report_timestamp = report_id.split('-')[2]  || null
  if (!report_timestamp) {
    return null
  }
  return moment.unix(report_timestamp).utc().format()
}

export function flatten_arrays(arr, result = []) {
  for (let i = 0, length = arr.length; i < length; i++) {
    const value = arr[i]
    if (Array.isArray(value)) {
      flatten_arrays(value, result)
    } else {
      result.push(value)
    }
  }
  return result
}

export function pluralise_text(num, text, plural) {
  return num === 1 ? text : (plural || text + 's')
}

export function contains(input, lower_case_search_string) {
  if (!input) {
    return false
  }
  const input_lower_case = input.toLowerCase ? input.toLowerCase() : input + ''
  return input_lower_case.indexOf(lower_case_search_string) !== -1
}

export function prepare_search_param_for_url(search_string) {
  if (!search_string) return ''

  return search_string.split(' ').map(item => (encodeURIComponent(item))).join('+')
}

export function remove_non_breaking_spaces(text, max_limit) {
  const nbsp = '\xa0'
  const nbsp_pattern = new RegExp(nbsp, 'g')

  //sometimes non-breaking spaces could be added on purpose so we leave them if they don't occur too often (defined by max_limit)
  if ((text.match(nbsp_pattern) || []).length > max_limit ) {
    return remove_double_spaces(text.replace(nbsp_pattern, ' '))
  }

  return text
}

export function remove_double_spaces(string) {
  if (string == null) {
    return string
  }

  if (!string.replace) {
    return string + '' // coerce to string
  }

  // remove double spaces
  return string.replace(/  +/g, ' ')
}

/**
 * Returns true if the string is empty (i.e "    ") or null
 */
export function is_empty_or_null(string) {
  if (!string) {
    return true
  }

  return (string.trim().length === 0)
}

export function replace_tabs_with_spaces(string) {
  if (string == null) {
    return string
  }

  if (!string.replace) {
    return string + '' // coerce to string
  }

  // remove tabs
  return string.replace(/\t+/g, ' ')
}

export function replace_linebreaks_with_spaces(string) {
  if (string == null) {
    return string
  }

  if (!string.replace) {
    return string + ''
  }

  return string.replace(/\n/g, ' ')
}

export function is_array_subset(a=[], b=[]) {
  return [...b].every(item => a.indexOf(item) !== -1)
}

export function generate_random_hash(length=5) {
  return Math.random().toString(36).substr(2, length)
}

export function format_dispute_status(status) {
  return format_string_first_character_capitalised(status || '').replace(/_/g, ' ')
}

export function calculate_duration(from, to) {
  if (!from) return null

  const start = moment(from, 'YYYY-MM-DD')
  const end = moment(to || moment(), 'YYYY-MM-DD')

  return moment.duration(end.diff(start))
}

export function format_duration(duration) {
  if (!duration) return ''

  const DAY_UNIT = 'day'
  const MONTH_UNIT = 'month'
  const YEAR_UNIT = 'year'
  const DAYS_IN_YEAR = 365
  const DAYS_IN_MONTH = 30


  const duration_in_days = Math.ceil(duration.asDays())

  if (duration_in_days < DAYS_IN_MONTH) {
    return format_duration_value_with_unit(duration_in_days, DAY_UNIT)
  }

  const duration_in_months = Math.ceil(duration.asMonths())

  if (duration_in_days < DAYS_IN_YEAR) {
    return format_duration_value_with_unit(Math.round(duration_in_months), MONTH_UNIT)
  }

  const duration_in_years = duration.asYears()

  const duration_full_years = Math.floor(duration_in_years)

  const duration_full_months = duration_in_months - (duration_full_years * 12)

  if (duration_full_months !== 12) {
    return format_duration_value_with_unit(duration_full_years, YEAR_UNIT) + ' ' + format_duration_value_with_unit(duration_full_months, MONTH_UNIT)
  }

  return format_duration_value_with_unit(duration_full_years + 1, YEAR_UNIT)
}

function format_duration_value_with_unit(value, unit) {
  return `${value}\xa0${unit}${(value !== 1) ? 's' : ''}`
}

export function limit_length(input, max_length) {
  return (input && input.slice) ? input.slice(0, max_length) : input
}

export function get_is_windows_os() {
  return navigator.userAgent.indexOf("Win") !== -1
}

export function escape_reg_exp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function startsWith(str, prefix) {
  // Returns true if str starts with prefix.
  return str.slice(0, prefix.length) === prefix
}

export function endsWith(str, suffix) {
  // Returns true if str ends with suffix.
  return str.indexOf(suffix, str.length - suffix.length) !== -1
}

export function delay(time) {
  return new Promise(resolve => window.setTimeout(resolve, time));
}

export function remove_from_array_by_idx(arr, idx) {
  if ((idx == null) || (idx < 0)) return arr

  const new_arr = [...arr || []]
  new_arr.splice(idx, 1)

  return new_arr
}

export function add_or_remove_item_from_array(arr=[], item) {
  return (arr.indexOf(item) > -1) ?
    arr.filter(id => id !== item) :
    [...arr, item]
}

export function array_to_wordy_string(string_array) {
  // nicely format an array of strings into a comma-separated list, with the last two items separated by 'and'
  // eg: ['one', 'two', 'three', 'four'] becomes 'one, two, three and four'
  const joined_array = string_array.join(', ')
  const last_comma_index = joined_array.lastIndexOf(',')
  return joined_array.substr(0, last_comma_index) + ' and' + joined_array.substr(last_comma_index + 1)
}

export function get_hashed_id_with_salt(id, salt) {
  return sha1(`${salt}${id}`)
}
