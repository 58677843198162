import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import DatasetGroup from './DatasetGroup.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { DISPLAY_COMPACT } from '../../utils/spec_group_utils.js'
import { scroll_to_y } from '../../utils/scroll_utils.js'
import { get_only_saved_items } from '../../utils/main_items_selection_utils.js'
import RefreshThumbnailsControl from './RefreshThumbnailsControl.js'
import { is_IE11 } from '../../utils/browser_utils.js'
import { ChartTagsDisplay } from './ChartTag.js'

import s from './DatasetGroupsView.module.scss'

const BROWSER_IS_IE_11 = is_IE11()

const PAGE_ID = 'report_overview'

const SCROLL_Y = 'SCROLL_Y'

function get_scroll_y_key(external_report_id) {
  return [SCROLL_Y, PAGE_ID, external_report_id].join('_')
}

function save_scroll_y(scroll_y, external_report_id) {
  const key = get_scroll_y_key(external_report_id)
  window.sessionStorage.setItem(key, scroll_y)
}

function fetch_scroll_y(external_report_id) {
  const key = get_scroll_y_key(external_report_id)
  return window.sessionStorage.getItem(key)
}

const DatasetGroupsView = (
  {
    groups,
    show_selected_only,
    on_selected_only_toggle,
    selected_dataset_tags,
    on_filter_datasets_by_tags,
    on_clear_selected_dataset_tags,

    show_all_datasets_refresh,
    enable_all_dataset_refresh,
    all_datasets_refresh_handler,

    selected_items,
    spec_id_to_fetch_obj,
    data_creation_date,
    ref_data,
    deref_data,
    deref_data_for_thumbnails,
    internal_report_id,
    external_report_id,
    report_series_sort,
    show_charts_in_thumbnails,
    minimal_selections,
    selections,

    on_datasets_select,
    on_datasets_deselect,
    on_dataset_zoom,
    on_group_zoom

  }) => {
  const [apply_scroll, set_apply_scroll] = useState(true)

  useEffect(() => {
    function handle_scroll() {
      const scroll_y = window.scrollY
      save_scroll_y(scroll_y, external_report_id)
    }

    window.addEventListener('scroll', handle_scroll)
    return () => {
      // clean up
      window.removeEventListener('scroll', handle_scroll)
    }
  }, [external_report_id])

  useEffect(() => {
    if (apply_scroll) {
      const scroll_y = fetch_scroll_y(external_report_id)
      scroll_to_y(scroll_y) // immediate scroll

      // unset (to prevent action repeating in next componentDidUpdate)
      set_apply_scroll(false)
    }
  }, [apply_scroll, external_report_id])

  function on_clear_tags() {
    on_clear_selected_dataset_tags()
  }

  const group_props = {
    ref_data,
    deref_data,
    internal_report_id,
    report_series_sort,
    show_charts_in_thumbnails,
    minimal_selections,
    selections,
    deref_data_for_thumbnails,
    data_creation_date,
    spec_id_to_fetch_obj,
    show_selected_only,

    on_datasets_select,
    on_datasets_deselect,
    on_group_zoom,
    on_dataset_zoom
  }

  const no_selected_items = get_only_saved_items(selected_items).length === 0

  return (
    <>
      <div className='d-flex mb-3 flex-wrap flex-md-nowrap'>
        <div className='d-flex order-0'>
          <PaneHeader text='Overview'/>
          <RefreshThumbnailsControl
            show_all_datasets_refresh={show_all_datasets_refresh}
            enable_all_dataset_refresh={enable_all_dataset_refresh}
            all_datasets_refresh_handler={all_datasets_refresh_handler}
            className={'ml-2 pt-1'}
          />
        </div>

        <ChartTagsDisplay
          on_clear={on_clear_tags}
          on_select={on_filter_datasets_by_tags}
          selected_tags={selected_dataset_tags}
          className={'order-2 order-md-1 ml-md-2'}
          selected_only_enabled={!no_selected_items}
          on_selected_only_click={() => on_selected_only_toggle({should_show_selected_only: !show_selected_only})}
          show_selected_only={show_selected_only}
        />
      </div>

      {(!groups || groups.length === 0) &&
        <div className='text-center'>No charts to display</div>
      }

      <div className={cn('mx-auto', {[s.wrapper]: !BROWSER_IS_IE_11})}>

        <div className={s.block}>
          {groups.map((group, i) => {
            return (
              <DatasetGroup
                key={i}
                display_mode={DISPLAY_COMPACT}
                group={group}
                selected_items={selected_items}

                {...group_props}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default DatasetGroupsView