import React from 'react'
import cn from 'classnames'

import SelectedMark from './SelectedMark.js'
import Connector from './Connector.js'

import s from './ClassifierGroupsSelector.module.scss'
import cs from '../../cipher_styles.module.scss'

const ClassifierGroupsSelector = ({
  name, is_selected, is_disabled, is_anything_selected, is_in_basket, on_select_handler, className
}) => {
  return (
    <div className={cn('d-flex', className)}>
      <div
        className={cn(['d-flex py-1 pl-1 pr-3', cs.cursor_pointer, s.heading, {[s.heading__selected]: is_selected}, {[s.heading__group_displayed]: is_anything_selected}, {[s.heading__disabled]: is_disabled}])}
        onClick={on_select_handler}
      >
        <SelectedMark is_selected={is_in_basket} className={s.selected_mark}/>
        <span>{name}</span>
      </div>
      {is_selected &&
        <Connector className={cn('d-none d-sm-block', s.connect)} />
      }
    </div>
  )
}

export default ClassifierGroupsSelector