import React from 'react'
import cn from 'classnames'

import { SortedADecIconInline , SortedAscIconInline, ToggleDownIconInline, ToggleUpIconInline } from '../widgets/IconSet.js'

import { ASCENDING, DESCENDING } from '../../model/sort_directions.js'

import s from './SortingColumnHeaderCell.module.scss'

/**
 * Custom column header cell for ReactTable,
 * including up/down buttons for sorting.
 * @param {} field Object with 'id' and 'name' properties.
 * @param {} selected_sort_field_id
 * @param {} selected_sort_direction_id
 * @param {} on_change_sort_field_id_and_sort_direction_id
 * @param {} sort_disabled
 * @returns {}
 */
const SortingColumnHeaderCell = ({
  field,
  selected_sort_field_id,
  selected_sort_direction_id,
  on_change_sort_field_id_and_sort_direction_id,
  sort_disabled
}) => {
  const { sortable, name } = field

  const no_sort = ((sortable === false) || sort_disabled)

  const is_sorted_by   = (selected_sort_field_id === field.id)
  const is_sorted_desc = is_sorted_by && (selected_sort_direction_id === DESCENDING)
  const is_sorted_asc  = is_sorted_by && (selected_sort_direction_id === ASCENDING)

  function toggle() {
    const direction = is_sorted_by && selected_sort_direction_id === DESCENDING ? ASCENDING : DESCENDING
    on_change_sort_field_id_and_sort_direction_id(field.id, direction)
  }

  return (
    <div
      className={cn('d-flex justify-content-between', s.header_wrapper, {[s.header_wrapper__no_sort]: no_sort})}
    >
      <div
        className={cn('text-left', s.label, {[s.label__sortable]: !no_sort})}
        onClick={no_sort ? null : toggle}
      >
        {name}
      </div>

      {!no_sort &&
        <div
          className={cn({[s.sort_icon_container__sortable]: !no_sort }, 'ml-1')}
        >
          {/* Up chevron */}
          {is_sorted_asc ?
            <SortedAscIconInline
              className={s.sort_icon}
              onClick={() => {
                on_change_sort_field_id_and_sort_direction_id(field.id, ASCENDING)
              }}
            />
            :
            <ToggleUpIconInline
              className={cn(s.sort_icon)}
              onClick={() => {
                on_change_sort_field_id_and_sort_direction_id(field.id, ASCENDING)
              }}
            />
          }

          {/* Down chevron */}
          {is_sorted_desc ?
            <SortedADecIconInline
              className={s.sort_icon}
              onClick={() => {
                on_change_sort_field_id_and_sort_direction_id(field.id, DESCENDING)
              }}
            />
            :
            <ToggleDownIconInline
              className={s.sort_icon}
              onClick={() => {
                on_change_sort_field_id_and_sort_direction_id(field.id, DESCENDING)
              }}
            />
          }

        </div>
      }

    </div>
  )
}

export default SortingColumnHeaderCell