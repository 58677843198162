import {
  ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
  AVERAGE_COST_BY_PORTFOLIO_ID,
  AVG_PRIORITY_YEAR_BY_ORG_ID,
  CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  CAGR_ACTIVE_FAMILIES_BY_TECH_ID,
  FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  FAMILY_FILINGS_BY_PORTFOLIO_WITHIN_TIMEFRAME_ID,
  NUM_FAMILIES_BY_COST_BAND_ID,
  NUM_FAMILIES_BY_PRIO_YEAR_ID,
  NUM_FAMILIES_BY_PVIX_BAND_ID,
  PATFAMS_WITH_GRANTS_BY_REGION_ID,
  PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_PORTFOLIO_ID
} from './spec_ids.js'
import { STATUS_ACTIVE, STATUS_GRANTED } from '../constants/specs_params.js'
import { SELECTED_PORTFOLIOS, SELECTED_TECH_AREAS } from './deref.js'
import { DECK_ALIVE_PATFAM_STATUS_DIMS, STATUS_ACTIVE_ID, STATUS_COLUMN_NAME, STATUS_GRANTED_ID } from './statuses.js'
import { get_filtered_dim_keys, get_since_2000_year_range_clause, ID_TO_SPEC } from './specs.js'
import { get_as_map, is_array_non_empty_non_null } from '../utils/utils.js'
import { get_year_items } from '../utils/item_utils.js'
import {
  get_extent_as_report_reader_clause,
  get_last_n_years_by_month_created_extent,
  get_last_n_years_extent,
  get_since_1980_extent,
  get_year_10_years_ago
} from '../utils/time_range_utils.js'
import { SMALLEST_MEANINGFUL_CAGR_VALUE } from '../utils/patenting_activity_utils.js'
import { get_dim_keys_with_colours } from '../utils/column_data_utils.js'
import {
  DECK_DEFAULT_COLUMN_COLOR,
  DECK_MISC_SPOTLIGHT_COLOR,
  DECK_TARGET_ORG_COLUMN_COLOUR
} from '../constants/colours.js'
import { COST_DATA_START_YEAR } from './time_ranges.js'
import { TECHNOLOGY_COLUMN_NAME } from './technologies.js'
import { ORGANISATION_COLUMN_NAME } from './organisation.js'
import { COST_BUCKET_KEY, PVIX_SCORE_BUCKET_KEY } from '../constants/report_reader.js'
import { IS_TARGET_ORG_TAG } from '../constants/report_deck.js'
import {
  in_tech_areas_constraint,
  sort_deck_timeseries_legend,
  get_portfolios_excluding_server_rollup_and_private_owner,
  constrain_to_portfolio_if_not_target_org
} from '../utils/deck_spec_utils.js'

export const DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID = 'trFoP'
export const DECK_TOP_OWNERS_IN_LANDSCAPE_ID = 'khPHU'
export const DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID = '98JMT'
export const DECK_CAGR_FAMILY_FILINGS_BY_TECH_ID = 'Lking'
export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_ID = 'uE1WR'
export const DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID = 'NMn2D'
export const DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_ID = 'gCC5y'
export const DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_ID = 'XMZ6i'
export const DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID = 'dC00V'
export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_ID = 'eanak'
export const DECK_PVIX_MEAN_BY_PORTFOLIO_ID = 'KQxW4'
export const DECK_AVERAGE_COST_BY_PORTFOLIO_ID = 'hXMbQ'
export const DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID = 'GUhaF'
export const DECK_AVG_PRIORITY_YEAR_BY_ORG_ID = 'NBaX7'
export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_ID = '435WQ'
export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_ID = 'QJPVK'
export const DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_ID = 'tTPIr'
export const DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_ID = '9ESK4'

export const DECK_PVIX_DISTRIBUTION_LIST_ORGS_ID = '0PW3K'
export const DECK_PVIX_DISTRIBUTION_TARGET_ORG_ID = 'WLxiA'
export const DECK_COST_DISTRIBUTION_LIST_ORGS_ID = 'yzYVF'
export const DECK_COST_DISTRIBUTION_TARGET_ORG_ID = 'KcfGv'
export const DECK_PRIO_YEAR_DISTRIBUTION_LIST_ORGS_ID = 'c6ngy'
export const DECK_PRIO_YEAR_DISTRIBUTION_TARGET_ORG_ID = 'R1ook'

// temporarily include 'is_own_organisation' tag used for test report...
export const IS_TARGET_ORG_CONSTRAINT = `IN CLT.tag ('${IS_TARGET_ORG_TAG}', 'is_own_organisation')`

export const DECK_SPECS = [
  {
    ...ID_TO_SPEC[ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID],
    id: DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
    get_query: (params) => {
      const {status_filter} = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      return {
        'key': ['TTTA.technology_area_id', 'PF.status'],
        'value': ['COUNT DISTINCT PF.pat_fam_id'],
        constraint: [`IN PF.status (${status})`]
      }
    },
    get_column_names: ({status_filter}) => ([TECHNOLOGY_COLUMN_NAME, STATUS_COLUMN_NAME, `${(status_filter === STATUS_ACTIVE_ID) ? 'Active' : 'Granted'} families`]),
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECH_AREAS]),
        get_filtered_dim_keys(data, 1, DECK_ALIVE_PATFAM_STATUS_DIMS)
      ]
    },
    can_apply_status_switch: true,
  },
  {
    ...ID_TO_SPEC[ACTIVE_FAMILIES_BY_PORTFOLIO_ID],
    id: DECK_TOP_OWNERS_IN_LANDSCAPE_ID,
    can_apply_status_switch: true,
    get_query: (params) => {
      const {status_filter, spotlighted_tech_areas} = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      const optional_constraints = is_array_non_empty_non_null(spotlighted_tech_areas) ? [in_tech_areas_constraint(spotlighted_tech_areas)] : []
      return {
        'key': ['PFTP.portfolio_id'],
        'value': ['COUNT DISTINCT PF.pat_fam_id'],
        constraint: [`IN PF.status (${status})`, ...optional_constraints],
        sort: ['DESC COUNT DISTINCT PF.pat_fam_id'],
        limit: 22 // only need to 20 to display (include two extra in case 'Next X' and 'Private owner'/ 'Individual owners' are among them)
      }
    },
    column_widths: [42, null, 150, 110],
    can_sort_data: false,
    refresh_on_spotlight_techs: true,
    get_key_dims: ({data, deref_data}) => {
      return [
        get_portfolios_excluding_server_rollup_and_private_owner(get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])).slice(0, 20)
      ]
    },
    get_column_names: ({status_filter}) => (['Rank', 'Organisation', 'Company List', `${(status_filter === STATUS_ACTIVE_ID) ? 'Active' : 'Granted'} families`])
  },
  {
    ...ID_TO_SPEC[FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID],
    id: DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
    get_query: (params) => {
      const {status_filter} = params || {}
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      return {
        'key': [year_column, 'TTTA.technology_area_id'],
        'value': ['COUNT DISTINCT PF.pat_fam_id']
      }
    },
    get_column_names: ({status_filter}) => ([`${(status_filter === STATUS_ACTIVE_ID) ? 'Publication' : 'Granted'} year`, TECHNOLOGY_COLUMN_NAME, 'Patent families']),
    get_default_selected_timerange: (data_creation_date) => get_last_n_years_by_month_created_extent(data_creation_date, 10),
    sort_legend_function: ({legend_items, ref_data, selections}) => sort_deck_timeseries_legend({legend_items, ref_data, selections, column: TECHNOLOGY_COLUMN_NAME}),
    get_key_dims: ({data, deref_data, data_creation_date}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECH_AREAS]),
      ]
    },
    can_apply_status_switch: true,
  },
  {
    ...ID_TO_SPEC[CAGR_ACTIVE_FAMILIES_BY_TECH_ID],
    id: DECK_CAGR_FAMILY_FILINGS_BY_TECH_ID,
    get_query: (params) => {
      const {status_filter} = params || {}
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      return {
        'key': ['TTTA.technology_area_id', year_column],
        'value': ['COUNT DISTINCT PF.pat_fam_id'],
        'constraint': [`> COUNT DISTINCT PF.pat_fam_id ${SMALLEST_MEANINGFUL_CAGR_VALUE}`]
      }
    },
    get_column_names: ({status_filter}) => ([TECHNOLOGY_COLUMN_NAME, `${(status_filter === STATUS_ACTIVE_ID) ? 'Published' : 'Granted'} families growth rate`]),
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECH_AREAS])
      ]
    },
    get_query_for_clickthroughs: ({data_creation_date, selected_timerange, status_filter}) => {
      const extent = selected_timerange || get_last_n_years_extent(data_creation_date, 5)
      const in_timerange_constraint = get_extent_as_report_reader_clause(extent)
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      return {
        'key': ['TTTA.technology_area_id', year_column],
        'value': ['COUNT DISTINCT PF.pat_fam_id'],
        'constraint': [`BETWEEN ${year_column} ${in_timerange_constraint}`]
      }
    },
    can_apply_status_switch: true,
  },
  {
    ...ID_TO_SPEC[PATFAMS_WITH_GRANTS_BY_REGION_ID],
    id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_ID,
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], [DECK_DEFAULT_COLUMN_COLOR])
      ]
    },
    include_relative_values: true,
  },
  {
    ...ID_TO_SPEC[PATFAMS_WITH_GRANTS_BY_REGION_ID],
    id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_ID,
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], [DECK_MISC_SPOTLIGHT_COLOR])
      ]
    },
    spotlighted_tech_areas_only: true,
    include_relative_values: true,
  },
  {
    ...ID_TO_SPEC[ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID],
    id: DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
    get_query: (params) => {
      const {status_filter} = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      return {
        'key': ['PFTP.portfolio_id', 'TTTA.technology_area_id'],
        'value': ['COUNT DISTINCT PF.pat_fam_id'],
        constraint: [`IN PF.status (${status})`]
      }
    },
    no_rollups: () => {return true},
    get_column_names: ({status_filter}) => ([ORGANISATION_COLUMN_NAME, TECHNOLOGY_COLUMN_NAME, `${(status_filter === STATUS_ACTIVE_ID) ? 'Active' : 'Granted'} families`]),
    get_key_dims: ({data, deref_data}) => {
      return [
        get_portfolios_excluding_server_rollup_and_private_owner(get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])),
        get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECH_AREAS])
      ]
    },
    can_apply_status_switch: true,
  },
  {
    ...ID_TO_SPEC[ACTIVE_FAMILIES_BY_PORTFOLIO_ID],
    id: DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
    get_query: (params) => {
      const {status_filter} = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${status})`]
      }
    },
    get_column_names: ({status_filter}) => ([ORGANISATION_COLUMN_NAME, `${(status_filter === STATUS_ACTIVE_ID) ? 'Active' : 'Granted'} families`]),
    can_apply_status_switch: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[FAMILY_FILINGS_BY_PORTFOLIO_WITHIN_TIMEFRAME_ID],
    id: DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_ID,
    get_column_names: ({status_filter}) => ([ORGANISATION_COLUMN_NAME, `Patent families ${(status_filter === STATUS_ACTIVE_ID) ? 'published' : 'granted'}`]),
    can_apply_status_switch: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[FAMILY_FILINGS_BY_PORTFOLIO_WITHIN_TIMEFRAME_ID],
    id: DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_ID,
    get_column_names: ({status_filter}) => ([ORGANISATION_COLUMN_NAME, `Patent families ${(status_filter === STATUS_ACTIVE_ID) ? 'published' : 'granted'}`]),
    get_key_dims: ({data, deref_data}) => {
      return [
        get_portfolios_excluding_server_rollup_and_private_owner(get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]))
      ]
    },
    can_apply_status_switch: true,
    no_rollups: () => {return true},
  },
  {
    ...ID_TO_SPEC[CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID],
    id: DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_ID,
    get_query: (params) => {
      const {status_filter} = params || {}
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      return {
        "key": ["PFTP.portfolio_id", year_column],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`> COUNT DISTINCT PF.pat_fam_id ${SMALLEST_MEANINGFUL_CAGR_VALUE}`]
      }
    },
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
    get_column_names: ({status_filter}) => ([ORGANISATION_COLUMN_NAME, `${(status_filter === STATUS_ACTIVE_ID) ? 'Published' : 'Granted'} families growth rate`]),
    get_query_for_clickthroughs: ({data_creation_date, selected_timerange, status_filter}) => {
      const extent = selected_timerange || get_last_n_years_extent(data_creation_date, 5)
      const in_timerange_constraint = get_extent_as_report_reader_clause(extent)
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      return {
        "key": ["PFTP.portfolio_id", year_column ],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`BETWEEN ${year_column} ${in_timerange_constraint}`]
      }
    },
    can_apply_status_switch: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID],
    id: DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
    get_query: (params) => {
      //TODO constrain by status? check/ match with description
      const {status_filter} = params || {}
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      return {
        'key': [year_column, "PFTP.portfolio_id"],
        'value': ['COUNT DISTINCT PF.pat_fam_id']
      }
    },
    get_column_names: ({status_filter}) => ([`${(status_filter === STATUS_ACTIVE_ID) ? 'Publication' : 'Granted'} year`, ORGANISATION_COLUMN_NAME, 'Patent families']),
    get_default_selected_timerange: (data_creation_date) => get_last_n_years_by_month_created_extent(data_creation_date, 10),
    sort_legend_function: ({legend_items, ref_data, selections}) => sort_deck_timeseries_legend({legend_items, ref_data, selections, column: ORGANISATION_COLUMN_NAME}),
    can_apply_status_switch: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[PATFAMS_WITH_GRANTS_BY_REGION_ID],
    id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_ID,
    get_query: ({region_column}) => {
      return {
        "key": [`TTR.${region_column}`],
        "value": ["COUNT DISTINCT PFT.pat_fam_id"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`, IS_TARGET_ORG_CONSTRAINT]
      }
    },
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], [DECK_TARGET_ORG_COLUMN_COLOUR])
      ]
    },
    include_relative_values: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[PATFAMS_WITH_GRANTS_BY_REGION_ID],
    id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_ID,
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], [DECK_DEFAULT_COLUMN_COLOR])
      ]
    },
    list_orgs_only: true,
    exclude_target_org: true,
    include_relative_values: true,
  },
  {
    ...ID_TO_SPEC[PVIX_MEAN_BY_PORTFOLIO_ID],
    id: DECK_PVIX_MEAN_BY_PORTFOLIO_ID,
    get_query: (params) => {
      const { status_filter } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score", `IN PF.status (${status})`]
      }
    },
    can_apply_status_switch: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[NUM_FAMILIES_BY_PVIX_BAND_ID],
    id: DECK_PVIX_DISTRIBUTION_TARGET_ORG_ID,
    get_query: (params) => {
      const { status_filter } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      return {
        "key":[PVIX_SCORE_BUCKET_KEY],
        "value": ["COUNT DISTINCT PFTP.pat_fam_id"],
        "constraint": ["NOTNULL PF.pvix_score", `IN PF.status (${status})`, IS_TARGET_ORG_CONSTRAINT],
      }
    },
    can_apply_status_switch: true,
    root_chart_spec_id: DECK_PVIX_MEAN_BY_PORTFOLIO_ID,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[NUM_FAMILIES_BY_PVIX_BAND_ID],
    id: DECK_PVIX_DISTRIBUTION_LIST_ORGS_ID,
    get_query: (params) => {
      const { status_filter, clickthrough_item } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED

      const portfolios_constraint = constrain_to_portfolio_if_not_target_org(clickthrough_item)

      return {
        "key": [PVIX_SCORE_BUCKET_KEY],
        "value": ["COUNT DISTINCT PFTP.pat_fam_id"],
        "constraint": ["NOTNULL PF.pvix_score", `IN PF.status (${status})`],
        ...portfolios_constraint
      }
    },
    can_apply_status_switch: true,
    can_apply_clickthrough_item_switch: true,
    list_orgs_only: true,
    exclude_target_org: true,
    root_chart_spec_id: DECK_PVIX_MEAN_BY_PORTFOLIO_ID
  },
  {
    ...ID_TO_SPEC[AVERAGE_COST_BY_PORTFOLIO_ID],
    id: DECK_AVERAGE_COST_BY_PORTFOLIO_ID,
    get_query: (params) => {
      const { status_filter, data_creation_date } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["/ SUM PFC.cost COUNT DISTINCT PFC.pat_fam_id"],
        "constraint": [`BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`, `BETWEEN PFC.year ${since_2000_years_clause}`, `IN PF.status (${status})`]
      }
    },
    can_apply_status_switch: true,
    default_status: STATUS_GRANTED_ID,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[NUM_FAMILIES_BY_COST_BAND_ID],
    id: DECK_COST_DISTRIBUTION_TARGET_ORG_ID,
    get_query: (params) => {
      const { status_filter, data_creation_date } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": [COST_BUCKET_KEY],
        "value": ["COUNT DISTINCT PFC.pat_fam_id"],
        "constraint": [
          `BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`,
          `BETWEEN PFC.year ${since_2000_years_clause}`,
          `IN PF.status (${status})`,
          'NOTNULL PFC.cost',
          IS_TARGET_ORG_CONSTRAINT
        ]
      }
    },
    can_apply_status_switch: true,
    root_chart_spec_id: DECK_AVERAGE_COST_BY_PORTFOLIO_ID,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[NUM_FAMILIES_BY_COST_BAND_ID],
    id: DECK_COST_DISTRIBUTION_LIST_ORGS_ID,
    get_query: (params) => {
      const { status_filter, data_creation_date, clickthrough_item } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)

      const portfolios_constraint = constrain_to_portfolio_if_not_target_org(clickthrough_item)

      return {
        "key": [COST_BUCKET_KEY],
        "value": ["COUNT DISTINCT PFC.pat_fam_id"],
        "constraint": [
          `BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`,
          `BETWEEN PFC.year ${since_2000_years_clause}`,
          `IN PF.status (${status})`,
          'NOTNULL PFC.cost'
        ],
        ...portfolios_constraint
      }
    },
    can_apply_status_switch: true,
    can_apply_clickthrough_item_switch: true,
    list_orgs_only: true,
    exclude_target_org: true,
    root_chart_spec_id: DECK_AVERAGE_COST_BY_PORTFOLIO_ID
  },
  {
    ...ID_TO_SPEC[PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID],
    id: DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[AVG_PRIORITY_YEAR_BY_ORG_ID],
    id: DECK_AVG_PRIORITY_YEAR_BY_ORG_ID,
    get_query: (params) => {
      const { status_filter } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      return {
        key: ["PFTP.portfolio_id"],
        value: ["ROUND AVG PF.priority_year 2"],
        constraint: [`IN PF.status (${status})`]
      }
    },
    can_apply_status_switch: true,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[NUM_FAMILIES_BY_PRIO_YEAR_ID],
    id: DECK_PRIO_YEAR_DISTRIBUTION_TARGET_ORG_ID,
    get_query: (params) => {
      const { status_filter } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED

      return {
        "key": [`PF.priority_year`],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PF.status (${status})`, IS_TARGET_ORG_CONSTRAINT],
        "sort": [`PF.priority_year`]
      }
    },
    can_apply_status_switch: true,
    root_chart_spec_id: DECK_AVG_PRIORITY_YEAR_BY_ORG_ID,
    list_orgs_only: true
  },
  {
    ...ID_TO_SPEC[NUM_FAMILIES_BY_PRIO_YEAR_ID],
    id: DECK_PRIO_YEAR_DISTRIBUTION_LIST_ORGS_ID,
    get_query: (params) => {
      const { status_filter, clickthrough_item } = params || {}
      const status = (status_filter === STATUS_ACTIVE_ID) ? STATUS_ACTIVE : STATUS_GRANTED
      const portfolios_constraint = constrain_to_portfolio_if_not_target_org(clickthrough_item)

      return {
        "key": [`PF.priority_year`],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PF.status (${status})`],
        ...portfolios_constraint,
        "sort": [`PF.priority_year`]
      }
    },
    can_apply_status_switch: true,
    can_apply_clickthrough_item_switch: true,
    list_orgs_only: true,
    exclude_target_org: true,
    root_chart_spec_id: DECK_AVG_PRIORITY_YEAR_BY_ORG_ID
  }
]

export const ID_TO_DECK_SPEC = get_as_map(DECK_SPECS, 'id')