import React from 'react'
import { DropdownToggle } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'
import {is_aistemos} from '../../../utils/user_permissions'
import {withUser} from '../../UserContext'

import { TagSelectorDropdown } from './TagSelectorDropdown.js'
import {TrashIcon, SaveIcon, UnsaveIcon, TagIcon, AddToProjectIcon} from '../../widgets/IconSet.js'
import TextLink from '../../widgets/TextLink.js'
import CheckboxStatic from '../../widgets/CheckboxStatic.js'
import { TagCheckboxItem } from './TagCheckboxItem.js'

import s from './ManageSelectedReportsControl.module.scss'

const ManageSelectedReportsControl = ({ user, disabled, total_selected, selected_and_saveable_reports, tags, toggle_select_all, is_select_all, on_delete_selected,
                                        on_save_or_unsave_selected, edit_tags_for_selected, is_editing_tags_for_selected,
                                        select_tag, tag_search_string, handle_update_tag_search_string, handle_create_new_tag, handle_manage_tags, on_add_reports_to_project
                                      }) => {


  const all_tag_ids = (tags ||[]).map(tag => tag.tag_id)

  const total_selected_and_saveable = (selected_and_saveable_reports ? selected_and_saveable_reports.length : total_selected)
  const has_selections = total_selected > 0
  const has_saveable_selections = total_selected_and_saveable > 0


  const selected_tag_ids = all_tag_ids.filter(tag_id => _.every(selected_and_saveable_reports, report => _.contains(report.tag_ids, tag_id)))
  const partial_tag_ids = all_tag_ids.filter(tag_id => !_.contains(selected_tag_ids, tag_id)).filter(tag_id => _.some(selected_and_saveable_reports, report => _.contains(report.tag_ids, tag_id)))

  const all_selected_reports_are_saved = has_saveable_selections && _.all(selected_and_saveable_reports, report => report.is_saved)

  return (
    <div className='d-flex'>
      {toggle_select_all &&
        <TextLink className='ml-1 d-flex' disable={disabled} onClick={toggle_select_all} title={is_select_all ? 'Deselect all reports' : 'Select all reports'}>
          <CheckboxStatic
            is_checked={is_select_all}
            is_partial={has_selections}
            is_disabled={disabled}
          />
          <span className='ml-1'>{is_select_all ? 'Deselect all reports' : 'Select all reports'}</span>
        </TextLink>
      }
      {on_save_or_unsave_selected && !all_selected_reports_are_saved &&
        <TextLink className='ml-3' onClick={() => on_save_or_unsave_selected(true)} disable={!has_saveable_selections} title={'Save selected'}>
          <SaveIcon/>
          <span className='ml-1'>Save&nbsp;{has_saveable_selections ? <span>{total_selected_and_saveable}&nbsp;</span> : <span/>}selected</span>
        </TextLink>
      }
      {on_save_or_unsave_selected && all_selected_reports_are_saved &&
        <TextLink className='ml-3' onClick={() => on_save_or_unsave_selected(false)} disable={!has_saveable_selections} title='Unsave selected'>
          <UnsaveIcon/>
          <span className='ml-1'>Unsave&nbsp;{total_selected_and_saveable ? <span>{total_selected_and_saveable}&nbsp;</span> : <span/>}selected</span>
        </TextLink>
      }
      {edit_tags_for_selected &&
        <TagSelectorDropdown
          toggle_button={(
            <DropdownToggle
              className={cn(
                s.tag_dropdown_toggle,
                {[s.tag_dropdown_toggle_disabled]: !has_saveable_selections}
                )}
              disabled={!has_saveable_selections}
              tag='span'
              title='Tag selected'>
              <TagIcon/>
              <span className='ml-1'>Tag&nbsp;{total_selected_and_saveable ? <span>{total_selected_and_saveable}&nbsp;</span> : <span/>}selected</span>
            </DropdownToggle>
          )}
          handle_edit_tag_selections={edit_tags_for_selected}
          is_open={is_editing_tags_for_selected}
          tags={tags}
          selected_tag_ids={selected_tag_ids}
          partial_tag_ids={partial_tag_ids}
          search_string={tag_search_string}
          handle_update_search_string={handle_update_tag_search_string}
          handle_create_new_tag={handle_create_new_tag}
          handle_manage_tags={handle_manage_tags}
          tag_render_function={(tag) => (<TagCheckboxItem tag={tag} key={tag.tag_id} select_tag={select_tag} />)}
        />

      }
      {is_aistemos(user) && on_add_reports_to_project &&
        <TextLink className='ml-3' onClick={on_add_reports_to_project} disable={!has_selections} title='Add to project'>
          <AddToProjectIcon/>
          <span className='ml-1'>Add&nbsp;{total_selected ? <span>{total_selected}&nbsp;</span> : <span/>}selected to project</span>
        </TextLink>
      }

      {on_delete_selected &&
        <TextLink className='ml-3' onClick={on_delete_selected} disable={!has_selections} title='Delete selected'>
          <TrashIcon/>
          <span className='ml-1'>Delete&nbsp;{total_selected ? <span>{total_selected}&nbsp;</span> : <span/>}selected</span>
        </TextLink>
      }
    </div>
  )
}


export default withUser(ManageSelectedReportsControl)