import React, { useEffect, useState } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import qs from 'query-string'

import ContainerFullWidth from './ContainerFullWidth.js';
import ErrorBody from './ErrorBody.js'
import Spinner from './widgets/Spinner.js'
import TextLink from './widgets/TextLink.js'
import { Card } from './widgets/Block.js'

import { withUser } from './UserContext.js'
import { fetch_classifier_alert_content, get_subscription_display_name, UNKNOWN_ALERT_TECH_NAME } from '../utils/alerts_utils.js'
import { run_classifier_alert_report } from '../utils/report_builder_utils.js'
import { BUILD_CLASSIFIER_ALERT_REPORT, SUBSCRIPTIONS } from '../constants/paths.js'
import { track_visit_event, track_report_builder_event } from '../utils/tracking_utils.js'
import { find_classifier_match_in_trees } from '../utils/classifier_tree_utils.js'
import { get_classifier_groups } from '../utils/classifier_group_utils.js';
import { is_view_only_user } from '../utils/user_permissions.js'
import ReportBuilderNoAccessPanel from './builder/ReportBuilderNoAccessPanel.js'

const ALERT_TECH_ID_REGEX = new RegExp(`^.*\\${BUILD_CLASSIFIER_ALERT_REPORT}\\/([0-9]+).*$`)

const ClassifierAlertReportBuilder = ({ user, location }) => {
  const [show_spinner, set_show_spinner] = useState(false)
  const [report_id, set_report_id] = useState(null)
  const [alert_not_found, set_alert_not_found] = useState(false) // not quite an error; this could happen from clicking a very old link
  const [error_building_report, set_error_building_report] = useState(null)

  function fetch_alerts_report_input_and_build_report({from_date, to_date, territory, event_type}) {
    const alert_tech_id = get_alert_tech_id_from_url()

    Promise.all([
      fetch_classifier_alert_content(alert_tech_id, event_type, territory, from_date, to_date),
      get_classifier_groups(user, false /* false here excludes nd */)
    ])
      .then(([alert_data, classifier_groups]) => {
        const {description, alert_technology, to_date, from_date, patfams} = alert_data
        // find the relevant classifier in the user's account or in a taxonomy they have access to
        const classifier = find_classifier_match_in_trees(classifier_groups, alert_technology.classifier_id)

        const subscription_name = get_subscription_display_name(alert_technology, classifier)
        const report_title = `${description} in ${subscription_name} (${from_date} to ${to_date})`

        // if we couldn't find the classifier but alert data exists, fall back to a default tech name
        const technology_cluster_name = classifier ? classifier.name : UNKNOWN_ALERT_TECH_NAME
        // build the report
        run_classifier_alert_report(report_title, patfams, technology_cluster_name)
          .then(report_id => {
            set_show_spinner(false)
            set_report_id(report_id)
          })
      })
      .catch(error => {
        set_show_spinner(false)
        if (error.status === 404) {
          // probably not an error, probably someone edited the url...
          set_alert_not_found(true)
          return
        }
        set_error_building_report(error)
        throw error
      })
  }

  useEffect(() => {
    document.title = 'Cipher: New and updated families report'
    track_report_builder_event('action="build_report" report_type="classifier_alert" context="builder"')

    if (is_view_only_user(user)) {
      return
    }

    const query_params = qs.parse(location.search)
    const {alert_date, from_date} = query_params

    if (alert_date || !from_date) {
      // from_date is a required param, and alert_date is only found in very old alerts (2019 or older) which are no longer supported
      set_alert_not_found(true)
      return
    }

    fetch_alerts_report_input_and_build_report(query_params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (is_view_only_user(user)) {
    return (
      <ReportBuilderNoAccessPanel/>
    )
  }

  if (report_id) {
    return (
      <Redirect to={`/report/${report_id}/families`} />
    )
  }

  return (
    <ContainerFullWidth>
      {show_spinner &&
        <div className='text-center mt-3'>
          <Spinner/>
          <div>Fetching alert data...</div>
        </div>
      }
      {alert_not_found &&
        <Card className='mt-3' title='Alert not found'>
          <p className='mt-2'>
            To edit your alert preferences, go to
            <TextLink
              className='ml-1'
              element={Link}
              to={SUBSCRIPTIONS}
              onClick={() => {track_visit_event('page="subscribe-alerts"')}}
            >
              Subscriptions
            </TextLink>.
          </p>
        </Card>
      }
      {error_building_report &&
        <ErrorBody
          error={error_building_report}
          context='building report from a classifier alert'
        />
      }
    </ContainerFullWidth>
  )
}

function get_alert_tech_id_from_url() {
  const url = window.location.href
  const matches = url.match(ALERT_TECH_ID_REGEX)
  if (matches && matches.length >= 2) {
    return matches[1]
  }
  return null
}

export default withRouter(withUser(ClassifierAlertReportBuilder))