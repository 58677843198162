import React from 'react'
import CheckboxStatic from '../../widgets/CheckboxStatic.js'

export const TagCheckboxItem = ({tag, select_tag}) => {

  return (
    <span className='d-flex align-items-center'
          onClick={() => select_tag(tag, !tag.is_checked)}>
      <CheckboxStatic
        is_checked={tag.is_checked}
        is_partial={tag.is_partial}
      />
      <span className='pb-1 ml-2'>{tag.name}</span>
    </span>
  )
}