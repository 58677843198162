import React from 'react'
import TextLink from './TextLink.js'
import { GoogleSearchIcon } from './IconSet.js'

import { prepare_search_param_for_url } from '../../utils/utils.js'

import './GoogleSearchLink.override.scss'

export const GoogleSearchLink = ({search_string, label, className}) => {
  const url = `https://www.google.com/search?q="${prepare_search_param_for_url(search_string)}"`

  return (
    <TextLink
      element='a'
      href={url}
      target='_blank'
      className={className}
      title='Search with Google'
    >
      {label || <span className='google_search_icon_wrapper'><GoogleSearchIcon/></span>}
    </TextLink>
  )
}