import _ from 'underscore'

// Labels used internally in the client
export const POSITIVE = 'positive'
export const NEGATIVE = 'negative'
export const TEST_POSITIVE = 'test_positive'
export const TEST_NEGATIVE = 'test_negative'
export const IGNORE   = 'ignore'
export const UNKNOWN  = 'unknown'

// Enums used by the TrainingSetService for labelling
export const PAT_FAM_LABEL_UNKNOWN       = 0
export const PAT_FAM_LABEL_POSITIVE      = 1
export const PAT_FAM_LABEL_NEGATIVE      = 2
export const PAT_FAM_LABEL_IGNORE        = 3
export const PAT_FAM_LABEL_TEST_POSITIVE = 4
export const PAT_FAM_LABEL_TEST_NEGATIVE = 5

export const LABEL_TO_ENUM = {
  [POSITIVE]: PAT_FAM_LABEL_POSITIVE,
  [NEGATIVE]: PAT_FAM_LABEL_NEGATIVE,
  [TEST_POSITIVE]: PAT_FAM_LABEL_TEST_POSITIVE,
  [TEST_NEGATIVE]: PAT_FAM_LABEL_TEST_NEGATIVE,
  [IGNORE]  : PAT_FAM_LABEL_IGNORE,
  [UNKNOWN] : PAT_FAM_LABEL_UNKNOWN
}

export const ENUM_TO_LABEL = _.invert(LABEL_TO_ENUM)