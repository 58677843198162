import React from 'react'
import cn from 'classnames'

import { UndoIcon } from './IconSet.js'

import s from './Button.module.scss'

const SIZE_TO_CLASS = {
  xs: s.__xs,
  sm: cn('btn-sm', s.__sm),
  lg: 'btn-lg'
}

/**
 * @param {} size (optional) can be 'sm' or 'lg' (default is medium).
 * @param {} disabled (optional) if true, button is disabled.
 * @param {} outline (optional) if true, outline version of button is shown.
 */
export const PrimaryButton = ({ className, title, children, size, disabled, onClick, onMouseMove, outline }) => {
  return (
    <button
      className={cn([
        'btn',
        SIZE_TO_CLASS[size],
        s.__primary,
        {
          [s.__outline]: outline,
          [s.__disabled]: disabled
        },
        className
      ])}
      disabled={disabled}
      onClick={onClick}
      title={title || ''}
      onMouseMove={onMouseMove}
    >
      {children}
    </button>
  )
}

/**
 * @param {} size (optional) can be 'sm' or 'lg' (default is medium).
 * @param {} disabled (optional) if true, button is disabled.
 * @param {} outline (optional) if true, outline version of button is shown.
 */
export const SecondaryButton = ({ className, title, children, size, disabled, onClick, outline }) => {
  return (
    <button
      className={cn([
        'btn',
        SIZE_TO_CLASS[size],
        s.__secondary,
        {
          [s.__outline]: outline,
          [s.__disabled]: disabled
        },
        className
      ])}
      disabled={disabled}
      onClick={onClick}
      title={title || ''}
    >
      {children}
    </button>
  )
}

/**
 * @param {} is_on i.e. true/false
 * @param {} size (optional) can be 'sm' or 'lg' (default is medium).
 * @param {} disabled (optional) if true, button is disabled.
 */
export const OnOffButton = ({ className, is_on, children, size, disabled, onClick, title }) => {
  return (
    <button
      className={cn([
        'btn',
        s.__grouped,
        SIZE_TO_CLASS[size],
        {
          [s.__secondary]: is_on,  // Weirdly, Damon's design has Selected button with Secondary colours
          [s.__primary]: !is_on,
          [s.__outline]: !is_on,   // ... and Deselected buttons with Primary colour (but outlined)
          [s.__disabled]: disabled
        },
        className
      ])}
      disabled={disabled}
      onClick={onClick}
      title={title || ''}
    >
      {children}
    </button>
  )
}

export const BackBtn = ({on_click, className}) => {
  return (
    <PrimaryButton
      size={'xs'}
      className={cn('my-auto', s.small_btn_icon, className)}
      onClick={on_click}
    >
      <UndoIcon/>
    </PrimaryButton>
  )
}