import React, { useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { remove_roles_for_keycloak_group } from '../../utils/user_group_utils.js'
import { remove_user_roles } from '../../utils/user_management_utils.js'

import Spinner from '../widgets/Spinner.js'
import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

import ErrorBody from '../ErrorBody.js'

import s from './RemoveRoleModal.module.scss'


const RemoveRoleModal = ({
  role_to_delete,
  group,  // optional: for group-level roles
  user,   // optional: for user-level roles

  local_roles,       // local state
  set_local_roles,   // for calling on success

  id_to_child_roles,     // For updating composite roles after a delete.
  set_id_to_child_roles, // For updating composite roles after a delete.

  on_close,
}) => {

  const [is_deleting,    set_is_deleting]    = useState(false)
  const [error_deleting, set_error_deleting] = useState(null)

  function do_delete() {
    set_is_deleting(true)

    Promise.resolve(true)
      .then(() => {
        if (group) {
          return remove_roles_for_keycloak_group(group.id, [role_to_delete])
        }
        if (user == null) {
          throw new Error('No group or user provided')
        }
        return remove_user_roles(user.id, [role_to_delete])
      })
      .catch(err => {
        // FAIL
        set_error_deleting(err)
        throw err
      })
      .then(() => {
        // SUCCESS
        // update local state
        const new_local_roles = local_roles.filter(r => r.id !== role_to_delete.id)
        set_local_roles(new_local_roles)

        // update composite roles
        const new_id_to_child_roles = _.omit(id_to_child_roles, (roles, id, obj) => id === role_to_delete.id)
        set_id_to_child_roles(new_id_to_child_roles)

        // close modal
        on_close()
      })
      .finally(() => {
        set_is_deleting(false)
      })
  }

  return (
    <Modal
      className={cn(s.modal_container)}
      title={`Confirm remove`}
      close_label={'Cancel'}
      on_hide={on_close}
      footer={
        <div>
          {is_deleting &&
            <Spinner
              size={'sm'}
              className={cn('mr-1')}
            />
          }
          <PrimaryButton
            size={'md'}
            onClick={do_delete}
            disabled={is_deleting || error_deleting}
          >
            Remove
          </PrimaryButton>
        </div>
      }
    >

      {(!error_deleting) &&
        <div>
          Remove role '{role_to_delete.name}' from
          {(group != null) ? ` group '${group.name}'` : ` user '${user.username}'`}
          {' ?'}
        </div>
      }

      {/* ERRORS */}
      {error_deleting &&
        <ErrorBody
          error={error_deleting}
          context={'deleting role'}
        />
      }

    </Modal>
  )
}

export default RemoveRoleModal
