import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import UserCreatedCompanyListsPanel from './UserCreatedCompanyListsPanel.js'
import OrgListAdminLink from './OrgListAdminLink.js'

import cs from '../cipher_styles.module.scss'
import s from './UserCreatedCompanyLists.module.scss'

const UserCreatedCompanyLists = ({company_lists, on_list_select_handler, className}) => {

  const [shared_lists, my_lists] = _.partition(company_lists, list => list.is_shared)

  const lists_to_display = []
  if (my_lists && !_.isEmpty(my_lists)) {
    lists_to_display.push({lists: my_lists, label: 'My lists'})
  }
  if (shared_lists && !_.isEmpty(shared_lists)) {
    lists_to_display.push({lists: shared_lists, label: 'Shared lists'})
  }

  return (
    <div className={className}>
      <div className={cn('d-flex', s.heading_wrapper)}>
        <span className={cn(cs.font_size_larger, s.heading)}>Saved lists</span>
        <OrgListAdminLink label='Manage' className='ml-3 my-auto' />
      </div>
      {lists_to_display.map((item, i) => {
        const {lists, label} = item
        return (
          <UserCreatedCompanyListsPanel
            key={i}
            label={label}
            company_lists={lists}
            on_list_select_handler={on_list_select_handler}
            className='pl-2 mt-2'
          />
        )
      })}
    </div>
  )
}

export default UserCreatedCompanyLists