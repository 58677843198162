import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { AddNewIcon } from '../widgets/IconSet.js'

const AddRoleLink = ({on_click, disable, className}) => {
  return (
    <TextLink
      className={cn(className)}
      onClick={on_click}
      disable={disable}
    >
      <AddNewIcon/>
      <span className='ml-1'>
      Add role
      </span>
    </TextLink>
  )
}

export default AddRoleLink