import React, { Component } from 'react'
import { Input, Nav, NavItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import { share_report } from '../../../utils/report_sharing_utils.js'
import {
  fetch_all_active_keycloak_groups, fetch_my_keycloak_groups, fetch_users_in_keycloak_group,
  create_new_keycloak_user_group, is_valid_email_address, is_valid_group_name
} from '../../../utils/user_group_utils.js'
import { IS_WAM_USER, is_admin } from '../../../utils/user_permissions.js'
import { track_report_viewer_event } from '../../../utils/tracking_utils.js'
import { is_array_non_empty_non_null } from '../../../utils/utils.js'

import { PrimaryButton } from '../../widgets/Button.js'
import Modal from '../../widgets/Modal.js'
import Spinner from '../../widgets/Spinner.js'
import TextLink, { NavLink } from '../../widgets/TextLink.js'
import { AddNewIcon, AddNewUserIcon, RemoveIcon, ErrorIcon } from '../../widgets/IconSet.js'
import { TileDropdown } from '../../dashboard_tiles/TileDropdown.js'
import ErrorBody from '../../ErrorBody.js'
import { withUser } from '../../UserContext.js'
import ReportShareableLink from '../../viewer/ReportShareableLink.js'
import { REPORT_STATE } from '../../../constants/paths.js'
import SupportInfoDisplay from '../../widgets/SupportInfoDisplay.js'
import ConfirmOrCancel from '../../widgets/ConfirmOrCancel.js'

import s from './ShareReportModal.module.scss'

const MAX_DROPDOWN_HEIGHT = 500
const MAX_MESSAGE_LENGTH_CHARS = 5000

class ShareReportModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      is_fetching_groups: true,
      is_fetching_users: false,
      is_sharing_report: false,
      user_groups: null,
      selected_group: null,
      new_group_name: null,
      users: null,
      recipient_email: null,
      share_message: null,
      is_entering_email_address: false,
      is_entering_new_group_name: false,
      error_fetching_groups: null,
      error_creating_group: null,
      error_fetching_users: null,
      error_sharing_report: null,
      success_message: null,
      recipient_account_is_disabled: false,
      is_shareable_link_requested: true,
      state_id: null,
      previous_user_state: null
    }
  }

  componentDidMount() {
    if (is_admin(this.props.user)) {
      // only users with admin permissions can share outside their organisation
      return this.fetch_all_groups()
    }
    this.fetch_groups_for_this_user_only()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.user_state, prevProps.user_state)) {
      this.setState({ state_id: null, previous_user_state: this.props.user_state })
    }
  }

  fetch_groups_for_this_user_only() {
    fetch_my_keycloak_groups()
      .then(user_groups => {
        if (!is_array_non_empty_non_null(user_groups)) {
          throw Error('Unable to share report. No organisation group found for user.')
        }

        const selected_group = user_groups[0]

        this.fetch_users(selected_group.id)
          .then(users => {
            this.setState({
              user_groups,
              selected_group,
              users,
              is_fetching_groups: false,
              is_fetching_users: false
            })
          })
      })
      .catch(error => {
        this.setState({error_fetching_groups: error, is_fetching_groups: false})
        throw error
      })
  }

  fetch_all_groups() {
    fetch_all_active_keycloak_groups()
      .catch(error => {
        this.setState({error_fetching_groups: error, is_fetching_groups: false})
        throw error
      })
      .then(group_data => {
        const user_groups = _.sortBy(group_data, g => g.name.toLowerCase())

        this.setState({
          user_groups,
          is_fetching_groups: false
        })
      })
  }

  fetch_users(group_id) {
    this.setState({is_fetching_users: true, error_fetching_users: null, success_message: null, recipient_account_is_disabled: false})

    return fetch_users_in_keycloak_group(group_id)
      .catch(error => {
        this.setState({error_fetching_users: error, is_fetching_users: false})
        throw error
      })
      .then(user_data => {
        // format user data for dropdown selection
        return user_data.map(u => ({id: u.username, name: u.username}))
      })
  }

  show_new_group_name_input() {
    this.setState({
      is_entering_new_group_name: true,
      selected_group: null,
      users: [],
      recipient_email: null,
      success_message: null,
      recipient_account_is_disabled: false
    })
  }

  hide_new_group_name_input() {
    this.setState({is_entering_new_group_name: false, new_group_name: null})
  }

  on_change_new_group_name(e) {
    this.setState({new_group_name: e.target.value})
  }

  new_group_name_is_valid() {
    return is_valid_group_name(this.state.new_group_name)
  }

  create_new_user_group() {
    const {new_group_name} = this.state
    const is_subscriber = false // new orgs added in this way should always be in "trial" rather than "subscriber" mode

    this.setState({is_fetching_users: true, error_creating_group: null})

    create_new_keycloak_user_group(new_group_name, is_subscriber)
      .catch(error => {
        this.setState({error_creating_group: error, is_fetching_users: false})
        throw error
      })
      .then(new_group => {
        const {user_groups} = this.state
        this.setState({
          user_groups: [new_group, ...user_groups],
          selected_group: new_group,
          users: [],
          recipient_email: null,
          new_group_name: null,
          is_entering_new_group_name: false,
          is_entering_email_address: true, // presumably we want to create a new user in this group now
          is_fetching_users: false
        })
      })
  }

  select_group_and_display_users(group_id) {
    this.fetch_users(group_id)
      .then(users => {
        const {user_groups} = this.state
        const selected_group = _.find(user_groups, g => g.id === group_id)

        this.setState({
          selected_group,
          users,
          recipient_email: null,
          is_entering_email_address: false,
          is_fetching_users: false,
          success_message: null,
          recipient_account_is_disabled: false
        })
      })
  }

  select_recipient(user_email) {
    this.setState({recipient_email: user_email, success_message: null, recipient_account_is_disabled: false})
  }

  show_recipient_email_input() {
    this.setState({is_entering_email_address: true, recipient_email: null, success_message: null, recipient_account_is_disabled: false})
  }

  on_change_recipient_email(e) {
    const recipient_email = e.target.value.replace(/\s+/g, '')
    this.setState({recipient_email, success_message: null, recipient_account_is_disabled: false})
  }

  recipient_email_is_valid() {
    return is_valid_email_address(this.state.recipient_email)
  }

  cancel_input_recipient_email() {
    this.setState({recipient_email: null, is_entering_email_address: false, success_message: null, recipient_account_is_disabled: false})
  }

  on_change_share_message(e) {
    this.setState({share_message: e.target.value, success_message: null, recipient_account_is_disabled: false})
  }

  share_message_is_valid() {
    const {share_message} = this.state
    return !share_message || share_message.length <= MAX_MESSAGE_LENGTH_CHARS
  }

  share_report_with_user() {
    this.setState({is_sharing_report: true, error_sharing_report: null})

    const {external_report_id, selections, main_items} = this.props
    const {recipient_email, selected_group, share_message} = this.state

    // form user_state param from selections (so we should be sharing exactly what the user can see)
    const user_state = {"created_at": new Date().toISOString(), selections, main_items}

    // strip out any html tags and trailing whitespace from free-text message
    const cleaned_up_message = share_message ? share_message.replace(/<(?:.|\n)*?>/gm, '').trim() : null

    share_report(external_report_id, user_state, recipient_email, selected_group, cleaned_up_message)
      .then(status => {
        const new_user_created = (status === 201)

        const success_message = this.get_success_message(new_user_created)

        track_report_viewer_event(`action="share" obj="report" context="viewer" with="${new_user_created? 'new' : 'existing'} user"`)

        this.setState({
          is_sharing_report: false,
          error_sharing_report: null,
          success_message
        })
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data === "Account disabled") {
          // recipient's account has been disabled on keycloak (support will need to re-enable/ reset permissions if desired)
          return this.setState({is_sharing_report: false, recipient_account_is_disabled: true})
        }

        if (error.status && error.status === 404) {
          const {recipient_email} = this.state
          const success_message = `Report could not be shared with ${recipient_email} because it is not a valid email address.`
          return this.setState({
            is_sharing_report: false,
            error_sharing_report: null,
            success_message
          })
        }

        this.setState({error_sharing_report: error, is_sharing_report: false})
        throw error
      })
  }

  get_success_message(new_user_created) {
    const {recipient_email} = this.state
    if (new_user_created) {
      return `Login details and report link successfully shared with new lite user ${recipient_email}`
    }
    return `Report successfully shared with ${recipient_email}`
  }

  build_modal_footer() {
    const { is_sharing_report, success_message, recipient_email, recipient_account_is_disabled } = this.state
    return (
      <div className={s.share_report_modal_footer}>

        <span className={s.success_message}>{success_message}</span>

        {recipient_account_is_disabled &&
          <span className={s.account_disabled_message}>
            <span className={'mr-1'}>
              <ErrorIcon/>
            </span>

            <SupportInfoDisplay
              message={`Unable to share. The email address ${recipient_email} is linked to a disabled Cipher account.`}
            />
          </span>
        }

        {is_sharing_report &&
          <div className='mr-1'>
            <Spinner/>
          </div>
        }
        <PrimaryButton
          onClick={() => this.share_report_with_user()}
          disabled={!this.recipient_email_is_valid() || !this.share_message_is_valid() || is_sharing_report || success_message || recipient_account_is_disabled}
        >
          Share report
        </PrimaryButton>
      </div>
    )
  }

  toggle_shareable_link_on() {
    track_report_viewer_event('obj="shareable_report_link" action="toggle_on"')
    this.setState({is_shareable_link_requested: true})
  }

  toggle_shareable_link_off() {
    track_report_viewer_event('obj="shareable_report_link" action="toggle_off"')
    this.setState({is_shareable_link_requested: false})
  }

  update_state_id(state_id) {
    this.setState({state_id})
  }

  render() {
    const { user, on_close, report_title, external_report_id, user_state, is_open, link_only, share_url } = this.props
    const {user_groups, selected_group, new_group_name, users, recipient_email, share_message, is_fetching_groups,
      is_fetching_users, is_sharing_report, is_entering_new_group_name, is_entering_email_address, error_fetching_groups,
      error_fetching_users, error_creating_group, error_sharing_report, success_message, is_shareable_link_requested, state_id} = this.state

    const new_lite_user_text = `New lite user${is_admin(user) && selected_group ? ` in ${selected_group.name}` : ''}`

    const is_wam_user = user[IS_WAM_USER]

    return (
      <Modal
        is_open={is_open}
        on_hide={on_close}
        title={`Share '${report_title}'`}
        close_label={success_message ? 'Done' : 'Cancel'}
        footer={is_shareable_link_requested ? null : this.build_modal_footer()}
        className={s.share_report_modal}
      >

        {!link_only &&
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={is_shareable_link_requested}
                  onClick={this.toggle_shareable_link_on.bind(this)}
                >
                  Get link
                </NavLink>
              </NavItem>

              {!is_wam_user && // for now, WAM users can not create "lite" accounts (as user creation in WAM is complicated)
                <NavItem>
                  <NavLink
                    active={!is_shareable_link_requested}
                    onClick={this.toggle_shareable_link_off.bind(this)}
                  >
                    Send via Cipher email
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </div>
        }

        {is_shareable_link_requested &&
          <div className='mt-3 px-2'>
            <ReportShareableLink
              is_shareable_link_requested={is_shareable_link_requested}
              external_report_id={external_report_id}
              user_state={user_state}
              state_id={state_id}
              set_state_id={this.update_state_id.bind(this)}
              share_url={share_url || REPORT_STATE}
            />
          </div>
        }

        {!is_shareable_link_requested &&
          <>

            {is_fetching_groups &&
              <Spinner/>
            }

            {error_fetching_groups &&
              <ErrorBody
                error={error_fetching_groups}
                context={'fetching user groups'}
              />
            }

            {user_groups &&
              <div className='mt-3'>

                {!is_entering_new_group_name && user_groups && user_groups.length > 1 &&
                  <div className='d-flex mb-3 px-2'>
                    <div className={s.share_report_selection_field}>
                      <TileDropdown
                        label='Select organisation'
                        selected_value={selected_group ? selected_group.name : null}
                        items={user_groups || []}
                        on_change_handler={this.select_group_and_display_users.bind(this)}
                        max_height={MAX_DROPDOWN_HEIGHT}
                        disabled={is_sharing_report}
                      />
                    </div>

                    {is_admin(user) &&
                      <div className={s.share_report_selection_controls}>
                        <TextLink
                          onClick={this.show_new_group_name_input.bind(this)}
                          className={s.clickable_icon}
                          disable={is_entering_email_address || is_sharing_report}
                        >
                          <AddNewIcon />
                          <span className='ml-1'>
                            Create new organisation
                          </span>
                        </TextLink>
                      </div>
                    }
                  </div>
                }
                {is_admin(user) && is_entering_new_group_name &&
                  <div className='d-flex mb-3 px-2'>
                    <div className={s.share_report_selection_field}>
                      <Input
                        value={new_group_name || ''}
                        onChange={this.on_change_new_group_name.bind(this)}
                        invalid={!this.new_group_name_is_valid()}
                        placeholder='Aistemos'
                        autoFocus
                      />
                    </div>

                    <ConfirmOrCancel
                      on_submit={this.create_new_user_group.bind(this)}
                      on_cancel={this.hide_new_group_name_input.bind(this)}
                      is_valid={this.new_group_name_is_valid()}
                      className={s.share_report_selection_controls}
                    />

                  </div>
                }

                {!is_entering_email_address &&
                  <div className='d-flex mb-3 px-2'>
                    <div className={s.share_report_selection_field}>
                      <TileDropdown
                        label={`Select ${is_admin(user) ? 'existing ' : ''}user`}
                        selected_value={recipient_email}
                        items={users || []}
                        on_change_handler={this.select_recipient.bind(this)}
                        max_height={MAX_DROPDOWN_HEIGHT}
                        disabled={!selected_group || !users || is_sharing_report}
                      />
                    </div>

                    {!is_fetching_users &&
                      <div className={s.share_report_selection_controls}>
                        <TextLink
                          onClick={this.show_recipient_email_input.bind(this)}
                          className={s.clickable_icon}
                          disable={!selected_group || is_fetching_users || is_sharing_report}
                        >
                          <AddNewUserIcon />
                          <span className='ml-1'>
                            {new_lite_user_text}
                          </span>
                        </TextLink>
                      </div>
                    }
                    {is_fetching_users &&
                      <Spinner/>
                    }
                  </div>
                }

                {is_entering_email_address &&
                  <div className='d-flex mb-3 px-2'>
                    <div className={s.share_report_selection_field}>
                      <Input
                        value={recipient_email || ''}
                        onChange={this.on_change_recipient_email.bind(this)}
                        invalid={!this.recipient_email_is_valid()}
                        placeholder='user@aistemos.com'
                        autoFocus
                      />
                    </div>
                    <div className={s.share_report_selection_controls}>
                      <TextLink
                        onClick={this.cancel_input_recipient_email.bind(this)}
                        className={s.clickable_icon}
                        title="Cancel"
                      >
                        <RemoveIcon />
                      </TextLink>
                    </div>
                  </div>
                }

                <div className={cn('d-flex mb-3 px-2', s.share_report_message_input)}>
                  <Input
                    type='textarea'
                    value={share_message || ''}
                    onChange={this.on_change_share_message.bind(this)}
                    rows='5'
                    placeholder='Message (optional)'
                    invalid={!this.share_message_is_valid()}
                  />
                </div>

                {error_fetching_users &&
                  <ErrorBody
                    error={error_fetching_users}
                    context={'fetching users in group'}
                  />
                }

                {error_creating_group &&
                  <ErrorBody
                    error={error_creating_group}
                    context={'creating new user group'}
                  />
                }

                {error_sharing_report &&
                  <ErrorBody
                    error={error_sharing_report}
                    context={'error sharing report'}
                  />
                }
              </div>
            }
          </>
        }
      </Modal>
    )
  }
}

export default withUser(ShareReportModal)