import React from 'react'

import PatentFamilyGlobalSearch from '../../patent_family_list/PatentFamilyGlobalSearch.js'

import list_view_styles from '../builder_list_view_styles.module.scss'

const PortfolioBooleanSearch = ({is_wizard_final_step, can_wizard_continue,  on_select_portfolios_handler}) => {
  return (
    <PatentFamilyGlobalSearch
      is_wizard={true}
      is_wizard_final_step={is_wizard_final_step}
      can_wizard_continue={can_wizard_continue}
      results_action_handler={on_select_portfolios_handler}

      controls_row_className={list_view_styles.controls_row} // for setting sticky top
      table_header_className={list_view_styles.table_header} // for setting sticky top
    />
  )
}

export default PortfolioBooleanSearch