import React, { useState } from 'react'
import cn from 'classnames'

import { withReportBasket } from './ReportBasketContext.js'
import PortfolioBasketSaveControl from './PortfolioBasketSaveControl.js'
import Modal from '../widgets/Modal.js';
import { EditIcon } from '../widgets/IconSet.js'
import { PrimaryButton } from '../widgets/Button.js'
import TextLink from '../widgets/TextLink.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import {
  get_basket_portfolio_total_size,
  PORTFOLIO_SEARCH_TYPE_BY_ID,
  PORTFOLIO_SIZE_NO_PASS_THRESHOLD,
  PORTFOLIO_SIZE_WARNING_THRESHOLD,
  REPORT_BUILDER_MODE_BY_ID
} from '../../utils/report_builder_utils.js'
import { EDIT_CLASSIFIER_SELECTION_LINK_TEXT, SELECT_CLASSIFIERS_LINK_TEXT } from '../../constants/technology_basket.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { is_aistemos, is_cipher_engineering } from '../../utils/user_permissions.js'
import { ReportBuilderBasketWarning, ReportBuilderBasketStopMessage } from './ReportBuilderBasketWarning.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import LightSpinner from '../widgets/LightSpinner.js'
import ReportBuilderPortfolioInBasket from './ReportBuilderPortfolioInBasket.js'

import s from './ReportBuilderBasket.module.scss'

const TECHNOLOGY_BASKET_LENGTH_LIMIT = 10
const PORTFOLIO_BASKET_LENGTH_LIMIT = 10

const ReportBuilderBasket = (
  {
    portfolio_basket,
    portfolio_basket_sizes,
    portfolio_basket_orgs_total_size,
    get_similar_orgs_handler,
    delete_from_portfolio_basket_handler,
    rename_portfolio_basket_item,
    technology_basket,
    clear_portfolio_basket_handler,
    show_technologies_basket,
    clear_technology_basket_handler,
    report_builder_change_mode_handler,
    report_builder_change_search_mode_handler,
    create_report_handler,
    report_builder_mode,
    report_builder_search_mode,
    portfolio_basket_save_handler,
    user_company_lists,
    user,
    is_calculate_orgs_total_size
  }) => {
  const [item_in_edit, set_item_in_edit] = useState(null)

  const [selected_search, set_selected_search] = useState(null)
  const [selected_search_title, set_selected_search_title] = useState(null)
  const [selected_total_items_msg, set_selected_total_items_msg] = useState('')
  const [selected_group, set_selected_group] = useState(null)
  const [show_all_portfolios, set_show_all_portfolios] = useState(false)

  function is_basket_empty() {
    return (portfolio_basket.length === 0)
  }

  function on_click_from_similar_orgs(i) {
    track_report_builder_event('obj="similar_orgs" context="portfolio_basket" action="show"')
    get_similar_orgs_handler(i)
  }

  function on_click_from_search_phrase(phrase, title, families_count) {
    track_report_builder_event('obj="basket_item" action="show_search" context="portfolio_basket"')
    set_selected_search(phrase)
    set_selected_search_title(title)
    if (families_count != null) {
      const grouped_families_size = `(${format_integer_with_comma(families_count)} families)`
      set_selected_total_items_msg(grouped_families_size)
    }
    else {
      set_selected_total_items_msg('')
    }
  }

  function on_click_from_org_group(group) {
    track_report_builder_event('obj="basket_item" action="show_group" context="portfolio_basket"')
    set_selected_group(group)
  }

  function on_click_from_rename(i) {
    set_item_in_edit(item_in_edit === i ? null : i)
  }
  
  function get_portfolio_basket_items() {
    if (is_basket_empty()) return []

    const portfolios = (portfolio_basket.length > PORTFOLIO_BASKET_LENGTH_LIMIT + 1)? [...portfolio_basket].slice(0, PORTFOLIO_BASKET_LENGTH_LIMIT) : portfolio_basket
    const portfolios_tail_length = portfolio_basket.length - portfolios.length

    const item_names = portfolio_basket.map(item => item.name)

    const portfolio_basket_items = (show_all_portfolios ? portfolio_basket : portfolios).map(function(item, i) {
      return (
        <ReportBuilderPortfolioInBasket
          key={i}
          item={item}
          item_size={portfolio_basket_sizes[i]}

          item_names={item_names}
          is_in_edit_mode={item_in_edit === i}

          on_click_from_search_phrase={on_click_from_search_phrase}
          on_click_from_similar_orgs={() => on_click_from_similar_orgs(i)}
          on_click_from_org_group={on_click_from_org_group}
          toggle_rename_mode={() => on_click_from_rename(i)}

          delete_from_portfolio_basket_handler={() => delete_from_portfolio_basket_handler(i)}
          rename_item={(new_name) => rename_portfolio_basket_item(i, new_name)}
        />
      )
    })

    if (portfolios_tail_length) {
      portfolio_basket_items.push(<TextLink className='mt-1' key={'portfolios_switch'} onClick={() => set_show_all_portfolios(!show_all_portfolios)}>{show_all_portfolios ? 'Show less' :  `+ ${portfolios_tail_length} more`}</TextLink>)
    }

    return portfolio_basket_items
  }

  const portfolio_basket_items = get_portfolio_basket_items()
  const portfolio_size = get_basket_portfolio_total_size(portfolio_basket, portfolio_basket_sizes, portfolio_basket_orgs_total_size)
  const is_portfolio_basket_empty = is_basket_empty()
  const classifier_names = Object.keys(technology_basket || {}).map(classifier_id => technology_basket[classifier_id].name)

  const has_classifiers = (classifier_names.length > 0)
  const selected_technology_names = (classifier_names.length > TECHNOLOGY_BASKET_LENGTH_LIMIT + 1) ? classifier_names.slice(0, TECHNOLOGY_BASKET_LENGTH_LIMIT) : classifier_names

  const classifiers_tail_length = classifier_names.length - selected_technology_names.length

  const is_report_big = portfolio_size > PORTFOLIO_SIZE_WARNING_THRESHOLD
  const is_report_too_big = !has_classifiers ? (portfolio_size > PORTFOLIO_SIZE_NO_PASS_THRESHOLD) : false // Only limit non-classifier reports (classifier reports are truncated, so are safe to run)

  const is_report_builder_in_classifier_mode = report_builder_mode === REPORT_BUILDER_MODE_BY_ID.technology
  const can_switch_to_org_sets = !is_report_builder_in_classifier_mode && report_builder_search_mode !== PORTFOLIO_SEARCH_TYPE_BY_ID['org_sets']

  return (
    <div className={s.basket_container}>

      {selected_search &&
        <Modal
          className={''}
          on_hide={() => set_selected_search(null)}
          title={selected_search_title}
        >
          <div>
            <span className={s.search_term}>{selected_search} </span> <span className={s.search_term_size}>{selected_total_items_msg} </span>
          </div>
        </Modal>
      }

      {selected_group &&
        <Modal
          on_hide={() => set_selected_group(null)}
          title={selected_group.name || 'Custom organisation group'}
        >
          <ul className='list-unstyled'>
          {(selected_group.members || []).map((item, i) => (
            <li key={i}>{item.name}</li>
            )
          )}
          </ul>
        </Modal>
      }

      {(is_report_big || is_report_too_big) &&
        <span>
          {is_report_big && (!is_report_too_big) &&
            <ReportBuilderBasketWarning light />
          }
          {is_report_too_big &&
            <ReportBuilderBasketStopMessage
              light
              user_has_classifiers={show_technologies_basket}
            />
          }
        </span>
      }

      <div className={cn(s.basket_header_wrapper, 'p-2')}>
        <PaneHeader
          text='In report'
          hint={is_calculate_orgs_total_size && <div className={cn( 'my-auto', s.header_info)}><LightSpinner size='xs'/><span className='ml-2'>calculating size</span></div>}
          className={s.basket_header}
        />
      </div>

      <div className={cn(s.basket_section_header, 'p-2 d-flex flex-wrap')}>
        <span className='mr-1'>Organisations</span>
        <PortfolioBasketSaveControl
          portfolio_basket={portfolio_basket}
          handle_portfolio_basket_saved={portfolio_basket_save_handler}
          user_company_lists={user_company_lists}
          switch_to_org_sets_handler={() => report_builder_change_search_mode_handler(PORTFOLIO_SEARCH_TYPE_BY_ID['org_sets'])}
          can_switch_to_org_sets={can_switch_to_org_sets}
        />
        <TextLink className='ml-auto' onClick={clear_portfolio_basket_handler}>Clear all</TextLink>
      </div>

      {is_cipher_engineering(user) &&
        <div className={'px-2'}>Basket size: {format_integer_with_comma(portfolio_size)}</div>
      }
      
      <div className={cn(s.basket_items_container, 'p-2')}>
        {portfolio_basket_items}
      </div>

      {show_technologies_basket &&
        <div>
          <div className={cn(s.basket_section_header, 'p-2 d-flex flex-wrap')}>
            <span className='mr-auto'>Technologies</span>
            <TextLink onClick={clear_technology_basket_handler}>Reset</TextLink>
          </div>

          <div className={cn(s.basket_items_container, 'p-2')}>
            <TextLink className='w-100 d-flex justify-content-between' onClick={report_builder_change_mode_handler}>
              <PaneHeader className={s.basket_classifier_select_link} text={(selected_technology_names.length !== 0) ? EDIT_CLASSIFIER_SELECTION_LINK_TEXT : SELECT_CLASSIFIERS_LINK_TEXT} />
              <EditIcon/>
            </TextLink>

            {selected_technology_names &&
              <ul className='list-unstyled'>
                {selected_technology_names.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}

                {classifiers_tail_length !== 0 &&
                  <li className='mt-1'>
                    <TextLink onClick={report_builder_change_mode_handler} disable={is_report_builder_in_classifier_mode}>
                      + {classifiers_tail_length} more
                    </TextLink>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      }

      <div>
        <PrimaryButton className='w-100' disabled={is_portfolio_basket_empty || (is_report_too_big && !is_aistemos(user)) || is_calculate_orgs_total_size} onClick={create_report_handler}>
          Next
        </PrimaryButton>
      </div>
    </div>
  )
}

export default withReportBasket(ReportBuilderBasket)