import moment from 'moment/moment.js'

import { pluralise_text } from '../../../utils/utils.js'
import {
  PORTFOLIO_BOOLEAN_SEARCH_OPTION,
  PORTFOLIO_ORG_LIST_OPTION,
  PORTFOLIO_ORG_SEARCH_OPTION,
  PORTFOLIO_TEXT_UPLOAD_OPTION,
  PORTFOLIO_FILE_UPLOAD_OPTION,
  PORTFOLIO_KNN_SEARCH_OPTION,
} from './builder_wizard.js'
import { EXPORT_SUBPATH, SELECTED_SUBPATH } from '../../../constants/viewer_paths.js'
import { GOOGLE_VALUATION_ID, ND_REPORT_ID, SPIF_ID } from '../../../model/hyperscripts.js'
import {
  has_any_cipher_product,
  has_cipher_product,
  has_hs_google_valuation,
  has_utt
} from '../../../utils/user_permissions.js'
import { PRODUCT_ND } from '../../../constants/cipher_product_ids.js'
import {
  build_classifier_landscape_report_input,
  build_generic_builder_report_input,
  build_utt_landscape_report_input
} from '../../../utils/report_input_utils.js'
import {
  DEFAULT_REPORT_TYPE,
  MAX_REPORT_NAME_LENGTH,
  ND_REPORT_TYPE,
  UTT_REPORT_TYPE
} from '../../../constants/constants.js'
import {
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../../../model/technology_basket.js'
import { CACHED_REPORT_THRESHOLD_RIGID, pick_report_name } from '../../../utils/report_builder_utils.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../../../constants/report_input.js'
import {
  get_negatives_processing,
  NEGATIVES_PROCESSING_EXCLUDE,
  NEGATIVES_PROCESSING_INCLUDE,
  NEGATIVES_PROCESSING_UTT
} from '../../../model/negatives_processing.js'
import {
  BENCHMARKING_PRESET,
  COMPETITIVE_INTELLIGENCE_PRESET,
  TECH_TRENDS_PRESET
} from '../../../model/chart_sets.js'

export const TECH_LANDSCAPES_GROUP_ID = 'tech_landscapes_group'
export const PATENTS_CLASSIFICATION_GROUP_ID = 'patents_classification_group'
export const PATENTS_ASSESSMENT_GROUP_ID = 'patents_assessment_group'
export const DATA_INTEGRATION_GROUP_ID = 'data_integration_group'

export const TECH_LANDSCAPE_STREAM_ID = 'CzVdZ'
export const NEW_FILINGS_IN_TECH_STREAM_ID = 'z5a66'
export const GOOGLE_VALUATION_REPORT_STREAM_ID = 'XgqmT'
export const QUALITY_REPORT_STREAM_ID = 'LaZi2'
export const TECH_TRENDS_REPORT_STREAM_ID = 'wU0Hv'
export const BENCHMARKING_REPORT_STREAM_ID = 'w1BkS'
export const COMPETITIVE_INTELLIGENCE_REPORT_STREAM_ID = 'FHx8H'
export const ND_REPORT_STREAM_ID = 'IthAy'
export const CLASSIFY_ORG_PORTFOLIO_STREAM_ID = 'KuhNm'
export const CLASSIFY_FAMILIES_LIST_STREAM_ID = 'T5qyw'
export const SPIF_REPORT_STREAM_ID = 'HQfnT'
export const SIMILAR_PORTFOLIOS_REPORT_STREAM_ID = 'ZmmeR'
export const NN_TECH_NAME_REPORT_STREAM_ID = 'pLeWV'

const STREAM_PROPS = {
  id: null, /*mandatory*/
  auto_name: null, /*mandatory*/
  label: null, /*mandatory*/
  short_label: null,
  portfolios: [PORTFOLIO_ORG_SEARCH_OPTION, PORTFOLIO_ORG_LIST_OPTION],
  report_endpoint: `/${SELECTED_SUBPATH}`,
  get_default_report_name: () => {return ''},
  get_report_input: build_stream_report_input_include_negatives,
  chart_set: null
}

function get_report_input({ report_name, portfolios, portfolios_to_cluster=[], classifiers, technology_partitioning, negatives_processing, multi_label, utt_version, report_type }) {
  const {type: technology_partitioning_type, use_superclasses} = technology_partitioning || {}

  return build_generic_builder_report_input({
    report_name,
    report_type: report_type ? report_type : technology_partitioning_type === TECH_PARTITIONING_TYPE_UTT ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE,
    portfolios,
    portfolios_to_cluster,
    classifiers,
    negatives_processing: (negatives_processing != null) ? negatives_processing : get_negatives_processing({type: NEGATIVES_PROCESSING_EXCLUDE}),
    multi_label: (multi_label != null) ? multi_label : false,
    threshold: CLASSIFIER_SCORE_THRESHOLD_DEFAULT,
    use_utt_superclasses: use_superclasses,
    utt_version
  })
}

function build_stream_report_input_include_negatives({ report_name, portfolios, portfolios_to_cluster=[], classifiers, technology_partitioning, multi_label, utt_version, has_utt, report_type }) {
  const negatives_processing = get_negatives_processing({type: has_utt ? NEGATIVES_PROCESSING_UTT : NEGATIVES_PROCESSING_INCLUDE, utt_version})

  return get_report_input({
    report_name,
    portfolios,
    portfolios_to_cluster,
    classifiers,
    technology_partitioning,
    negatives_processing,
    multi_label,
    utt_version,
    report_type
  })
}

function get_patent_upload_report_name({prefix, portfolios}) {
  const {pat_fam_ids} = (portfolios || [])[0] || {}

  const families_count_suffix = pat_fam_ids ? ` ${pat_fam_ids.length} ${pluralise_text(pat_fam_ids.length, 'family', 'families')}` : ''

  return `${prefix} ${moment(new Date()).format('YYYY-MM-DD HH:mm')}${families_count_suffix}`
}

function get_tech_partitioning_report_name_part(technology_partitioning) {
  const { type, use_superclasses } = technology_partitioning || {}

  if (!type) return ''

  if (type === TECH_PARTITIONING_TYPE_UTT) return `, UTT${use_superclasses ? ' super' : ''}`

  if (type === TECH_PARTITIONING_TYPE_CLUSTERING) return ', clustered'

  if (type === TECH_PARTITIONING_TYPE_CLASSIFIER) return ', classifiers'

  return ''
}

function get_portfolios_and_tech_partitioning_report_name({default_name, portfolio_names, technology_partitioning}) {
  if ((portfolio_names || []).length === 0) return default_name

  const tech_partitioning_report_name_part = get_tech_partitioning_report_name_part(technology_partitioning)
  const suffix = tech_partitioning_report_name_part !== '' ? `${tech_partitioning_report_name_part}` : ''

  return `${pick_report_name(portfolio_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - suffix.length)}${suffix}`
}

function get_similar_portfolios_report_name({default_name, portfolio_names, technology_partitioning}) {
  if ((portfolio_names || []).length === 0) return default_name

  const name = portfolio_names[0]

  const tech_partitioning_report_name_part = get_tech_partitioning_report_name_part(technology_partitioning)
  const suffix = tech_partitioning_report_name_part !== '' ? `${tech_partitioning_report_name_part}` : ''
  const suffix_extended = portfolio_names.length > 1 ? ` and similar${suffix}` : suffix

  return `${pick_report_name([{name}], MAX_REPORT_NAME_LENGTH - suffix_extended.length)}${suffix_extended}`
}

function get_nn_tech_search_report_name({default_name, portfolios, technology_partitioning}) {
  if ((portfolios || []).length === 0) return default_name

  const { name } = portfolios[0] || {}

  const tech_partitioning_report_name_part = get_tech_partitioning_report_name_part(technology_partitioning)
  const suffix = tech_partitioning_report_name_part !== '' ? `${tech_partitioning_report_name_part}` : ''
  const suffix_extended = ` patents${suffix}`

  return `${pick_report_name([{name}], MAX_REPORT_NAME_LENGTH - suffix_extended.length)}${suffix_extended}`
}

function get_tech_landscape_report_name({default_name, suffix, portfolio_names, classifier_names}) {
  if ((classifier_names || []).length === 0) return default_name

  const portfolio_names_suffix = (portfolio_names || []).length > 0 ? portfolio_names.join(' '): ''

  const suffix_extended = ` - ${suffix}${portfolio_names_suffix.trim() !== '' ? `, ${portfolio_names_suffix.trim()}` : ''}`

  return `${pick_report_name(classifier_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - suffix_extended.length)}${suffix_extended}`
}

function check_if_landscape_report_available({user, user_has_classifiers}) {
  return has_utt(user) || has_any_cipher_product(user) || user_has_classifiers
}

const TECH_LANDSCAPE_STREAM = {
  id: TECH_LANDSCAPE_STREAM_ID,
  auto_name: 'tech_landscape_stream',
  label: 'Explore the landscape for one or more technologies',
  short_label: 'Technology landscape',
  get_default_report_name: ({portfolio_names, classifier_names}) => get_tech_landscape_report_name({default_name: 'Technology landscape', suffix: 'landscape', portfolio_names, classifier_names}),
  check_if_available: check_if_landscape_report_available,
  get_report_input: ({ report_name, portfolios, classifiers=[], group_by_owner_level, portfolio_roll_up_limit, multi_label, utt_version }) => {
    const {is_utt, classifier_id} = (classifiers || [])[0] || {}

    return is_utt ?
      build_utt_landscape_report_input({
        report_name,
        classifier: { id: classifier_id },
        group_by_owner_level,
        portfolio_roll_up_limit,
        utt_version
      }) :
      build_classifier_landscape_report_input({
        report_name,
        portfolios,
        classifiers,
        group_by_owner_level,
        portfolio_roll_up_limit,
        multi_label
      })
  }
}

const NEW_FILINGS_IN_TECH_STREAM = {
  id: NEW_FILINGS_IN_TECH_STREAM_ID,
  auto_name: 'new_families_in_tech_landscape_stream',
  label: 'Find new families in a technology space',
  short_label: 'Find new families',
  get_default_report_name: ({portfolio_names, classifier_names}) => get_tech_landscape_report_name({default_name: 'New families in technology space', suffix: 'new families', portfolio_names, classifier_names}),
  check_if_available: check_if_landscape_report_available,
  get_report_input: ({ report_name, portfolios, classifiers, group_by_owner_level, portfolio_roll_up_limit, multi_label, utt_version }) => {
    const {is_utt} = (classifiers || [])[0] || {}

    return build_generic_builder_report_input({
      report_name,
      report_type: is_utt ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE,
      portfolios,
      group_by_owner_level,
      portfolio_roll_up_limit,
      classifiers: is_utt ? null : classifiers,
      negatives_processing: get_negatives_processing({type: NEGATIVES_PROCESSING_EXCLUDE}),
      multi_label,
      threshold: CLASSIFIER_SCORE_THRESHOLD_DEFAULT,
      utt_class_ids: is_utt ? classifiers.map(item => item.classifier_id) : null,
      use_utt_superclasses: false,
      utt_version
    })
  },
  cache_threshold: CACHED_REPORT_THRESHOLD_RIGID,
}

const NN_TECH_NAME_REPORT_STREAM = {
  id: NN_TECH_NAME_REPORT_STREAM_ID,
  auto_name: 'nn_tech_name',
  label: 'Find families by technology name',
  short_label: 'Technology search report',
  get_default_report_name: ({portfolios, technology_partitioning}) => get_nn_tech_search_report_name({default_name: 'Technology name search report', portfolios, technology_partitioning}),
  portfolios: [PORTFOLIO_KNN_SEARCH_OPTION],
  get_report_input: ({ report_name, portfolios, classifiers, technology_partitioning, multi_label, utt_version, has_utt, report_type }) => build_stream_report_input_include_negatives({ report_name, portfolios, portfolios_to_cluster: [0], classifiers, technology_partitioning, multi_label, utt_version, has_utt, report_type })
}

const GOOGLE_VALUATION_REPORT_STREAM = {
  id: GOOGLE_VALUATION_REPORT_STREAM_ID,
  auto_name: 'google_valuation_report_stream',
  label: 'Create Google Valuation report',
  short_label: 'Valuation report',
  portfolios: [PORTFOLIO_TEXT_UPLOAD_OPTION],
  report_endpoint: `/${EXPORT_SUBPATH}/${GOOGLE_VALUATION_ID}`,
  get_default_report_name:  ({portfolios}) => get_patent_upload_report_name({prefix: 'Valuation', portfolios}),
  check_if_available: ({user}) => {return has_hs_google_valuation(user)},
  get_report_input: ({ report_name, portfolios, classifiers }) => build_stream_report_input_include_negatives({report_name, portfolios: portfolios.map(item => ({...item, group_by_owner: false})), classifiers}),
}

const ND_REPORT_STREAM = {
  id: ND_REPORT_STREAM_ID,
  auto_name: 'nd_report_stream',
  label: 'Create n/d report',
  short_label: 'n/d report',
  portfolios: [PORTFOLIO_ORG_SEARCH_OPTION],
  report_endpoint: `/${EXPORT_SUBPATH}/${ND_REPORT_ID}`,
  get_default_report_name: ({portfolio_names}) => {
    if ((portfolio_names || []).length === 0) {
      return 'n/d report'
    }

    const prefix = 'n/d '
    return `${prefix}${pick_report_name(portfolio_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - prefix.length)}`
  },
  check_if_available: ({user}) => {return has_cipher_product(user, PRODUCT_ND)},
  get_report_input: ({ report_name, portfolios, classifiers, multi_label, negatives_processing }) => get_report_input({report_name, portfolios, classifiers, multi_label, negatives_processing, report_type: ND_REPORT_TYPE})
}

const TECH_TRENDS_STREAM = {
  ...STREAM_PROPS,

  id: TECH_TRENDS_REPORT_STREAM_ID,
  auto_name: 'tech_trends_report_stream',
  label: 'Compare technologies',
  short_label: 'Technology trends report',
  get_default_report_name: ({portfolio_names, technology_partitioning}) => get_portfolios_and_tech_partitioning_report_name({default_name: 'Technology trends report', portfolio_names, technology_partitioning}),
  chart_set: TECH_TRENDS_PRESET
}

const BENCHMARKING_REPORT_STREAM = {
  ...STREAM_PROPS,

  id: BENCHMARKING_REPORT_STREAM_ID,
  auto_name: 'benchmarking_report_stream',
  label: 'Benchmark against competitors',
  short_label: 'Benchmarking report',
  get_default_report_name: ({portfolio_names, technology_partitioning}) => get_portfolios_and_tech_partitioning_report_name({default_name: 'Benchmarking report', portfolio_names, technology_partitioning}),
  chart_set: BENCHMARKING_PRESET,
}

const COMPETITIVE_INTELLIGENCE_REPORT_STREAM = {
  ...STREAM_PROPS,

  id: COMPETITIVE_INTELLIGENCE_REPORT_STREAM_ID,
  auto_name: 'competitive_intelligence_report_stream',
  label: 'Build competitive intelligence report',
  short_label: 'Competitive intelligence report',
  get_default_report_name: ({portfolio_names, technology_partitioning}) => get_portfolios_and_tech_partitioning_report_name({default_name: 'Competitive intelligence report', portfolio_names, technology_partitioning}),
  chart_set: COMPETITIVE_INTELLIGENCE_PRESET,
}

const CLASSIFY_ORG_PORTFOLIO_STREAM = {
  id: CLASSIFY_ORG_PORTFOLIO_STREAM_ID,
  auto_name: 'classify_org_stream',
  label: 'Classify the assets of an organisation, or group of organisations',
  short_label: 'Organisation report',
  portfolios: [PORTFOLIO_ORG_SEARCH_OPTION, PORTFOLIO_ORG_LIST_OPTION],
  get_default_report_name: ({portfolio_names, technology_partitioning}) => get_portfolios_and_tech_partitioning_report_name({default_name: 'Organisation report', portfolio_names, technology_partitioning}),
  get_report_input: build_stream_report_input_include_negatives,
}

const CLASSIFY_FAMILIES_LIST_STREAM = {
  id: CLASSIFY_FAMILIES_LIST_STREAM_ID,
  auto_name: 'classify_upload_stream',
  label: 'Classify a list of patents',
  portfolios: [PORTFOLIO_TEXT_UPLOAD_OPTION, PORTFOLIO_FILE_UPLOAD_OPTION, PORTFOLIO_BOOLEAN_SEARCH_OPTION],
  get_default_report_name: ({portfolio_names, technology_partitioning}) => get_portfolios_and_tech_partitioning_report_name({default_name: 'Patent upload report', portfolio_names, technology_partitioning}),
  get_report_input: ({ report_name, portfolios, portfolios_to_cluster=[], classifiers, technology_partitioning, multi_label, negatives_processing, utt_version, has_utt, report_type }) => {

    return get_report_input({
      report_name,
      portfolios,
      portfolios_to_cluster,
      classifiers,
      technology_partitioning,
      negatives_processing: negatives_processing != null ? negatives_processing : get_negatives_processing({type: has_utt ? NEGATIVES_PROCESSING_UTT : NEGATIVES_PROCESSING_INCLUDE, utt_version}),
      multi_label,
      utt_version,
      report_type
    })
  },
}

const SPIF_REPORT_STREAM = {
  id: SPIF_REPORT_STREAM_ID,
  auto_name: 'spif_export_stream',
  label: 'Create an export file in SPIF format',
  short_label: 'SPIF export',
  portfolios: [PORTFOLIO_TEXT_UPLOAD_OPTION],
  report_endpoint: `/${EXPORT_SUBPATH}/${SPIF_ID}`,
  get_default_report_name:  ({portfolios}) => get_patent_upload_report_name({prefix: 'SPIF export', portfolios}),
  get_report_input: get_report_input,
}

const SIMILAR_PORTFOLIOS_REPORT_STREAM = {
  id: SIMILAR_PORTFOLIOS_REPORT_STREAM_ID,
  auto_name: 'similar_portfolios_report_stream',
  label: 'Classify assets of similar organisations',
  short_label: 'Similar organisations report',
  portfolios: [PORTFOLIO_ORG_SEARCH_OPTION],
  get_default_report_name: ({portfolio_names, technology_partitioning}) => get_similar_portfolios_report_name({default_name: 'Similar organisations report', portfolio_names, technology_partitioning}),
  get_report_input: build_stream_report_input_include_negatives,
}

export const REPORT_STREAM_GROUPS = [
  {
    id: TECH_LANDSCAPES_GROUP_ID,
    label: 'Landscapes',
    streams: [
      TECH_LANDSCAPE_STREAM, NEW_FILINGS_IN_TECH_STREAM, NN_TECH_NAME_REPORT_STREAM
    ]
  },
  {
    id: PATENTS_CLASSIFICATION_GROUP_ID,
    label: 'Classify patents',
    streams: [
      CLASSIFY_ORG_PORTFOLIO_STREAM, CLASSIFY_FAMILIES_LIST_STREAM
    ]
  },
  {
    id: PATENTS_ASSESSMENT_GROUP_ID,
    label: 'Assess portfolios',
    streams: [
      ND_REPORT_STREAM,
      GOOGLE_VALUATION_REPORT_STREAM,
      TECH_TRENDS_STREAM,
      BENCHMARKING_REPORT_STREAM,
      COMPETITIVE_INTELLIGENCE_REPORT_STREAM,
      SIMILAR_PORTFOLIOS_REPORT_STREAM
    ]
  },
  {
    id: DATA_INTEGRATION_GROUP_ID,
    label: 'Share patent data with other systems',
    streams: [
      SPIF_REPORT_STREAM
    ]
  }
]

function get_report_streams_to_groups() {
  const streams = {}

  REPORT_STREAM_GROUPS.forEach(group => {
    const {id: group_id, streams:group_streams} = group

    if (group_streams && group_streams.length > 0 ) {
      group_streams.forEach(stream => {
        const {id} = stream

        streams[id] = {...stream, group_id}
      })
    }
  })
  return streams
}

export const ID_TO_REPORT_STREAM = get_report_streams_to_groups()
export const NO_SWITCH_TO_ADVANCED_BUILDER_REPORT_STREAMS = [TECH_LANDSCAPE_STREAM_ID, GOOGLE_VALUATION_REPORT_STREAM_ID, ND_REPORT_STREAM_ID]

export const TECH_PARTITIONING_REPORT_STREAMS = [
  CLASSIFY_ORG_PORTFOLIO_STREAM_ID,
  CLASSIFY_FAMILIES_LIST_STREAM_ID,
  QUALITY_REPORT_STREAM_ID,
  TECH_TRENDS_REPORT_STREAM_ID,
  BENCHMARKING_REPORT_STREAM_ID,
  COMPETITIVE_INTELLIGENCE_REPORT_STREAM_ID,
  SIMILAR_PORTFOLIOS_REPORT_STREAM_ID,
  NN_TECH_NAME_REPORT_STREAM_ID
]

export const TECH_LANDSCAPE_STREAMS = [TECH_LANDSCAPE_STREAM_ID, NEW_FILINGS_IN_TECH_STREAM_ID]