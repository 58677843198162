import React, { Component } from 'react'
import _ from 'underscore'
import moment from 'moment'

import { fetch_training_sets } from '../../utils/training_set_grpc_utils.js'
import { track_visit_event } from '../../utils/tracking_utils.js'

import { CUSTOM_CLASSIFIER_UI_BASE_URL, CUSTOM_CLASSIFIERS_UI_BASE_URL } from '../../constants/urls.js'

import FeedbackModal from '../FeedbackModal.js'

import { Link } from '../widgets/Link.js'
import Spinner from '../widgets/Spinner.js'
import TextLink from '../widgets/TextLink.js'

import { DashboardSmallTile } from './DashboardTile.js'
import { TileHelpLink } from './TileHelpLink.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { FEEDBACK_CATEGORIES_BY_ID } from '../../model/feedback.js'
import { FeedbackOutlineIcon } from '../widgets/IconSet.js'

import ErrorBody from '../ErrorBody.js'
import { EDIT, OWNER } from '../classifiers_editor/model/permission_levels.js'
import { PrimaryButton } from '../widgets/Button.js'


const MAX_TECHNOLOGIES = 3

class RecentClassifiersPanel extends Component {

  constructor(props) {
    super(props)

    this.state = {
      technologies: null,
      show_spinner: true,
      show_support_modal: false
    }

    this.on_show_support_modal = this.on_show_support_modal.bind(this)
  }

  componentDidMount() {
    fetch_training_sets()
      .then(training_set_infos => {
        // Only show training sets that user has edit permission (i.e. EDIT, OWNER)
        const training_set_infos_filtered = training_set_infos.filter(ts => _.contains([EDIT, OWNER], ts.requester_permission))
        const training_set_infos_filtered_and_sorted = _.sortBy(training_set_infos_filtered, (ts) => -ts.modified_at.seconds)
        this.setState({ show_spinner: false, technologies: training_set_infos_filtered_and_sorted })
      })
      .catch(error => {
        this.setState({ show_spinner: false, fetching_user_technologies_error: error })
        throw error
      })
  }

  on_show_support_modal(is_show) {
    this.setState({show_support_modal: is_show})
  }

  render() {
    const { show_spinner, show_support_modal, technologies, fetching_user_technologies_error } = this.state

    const has_classifiers = technologies && technologies.length > 0

    return (
      <DashboardSmallTile title='Classifier training' hint={
        <TileHelpLink
          on_click={() => this.on_show_support_modal(true)}
          icon={<FeedbackOutlineIcon/>}
        />}>
        {show_spinner &&
          <div className='text-center'>
            <Spinner/>
          </div>
        }

        {show_support_modal &&
          <FeedbackModal
            is_open={true}
            on_hide={() => this.on_show_support_modal(false)}
            title={'Request classifier training support'}
            category={FEEDBACK_CATEGORIES_BY_ID.help.label}
            subject={'Classifier training'}
          />
        }

        {fetching_user_technologies_error &&
          <ErrorBody
            error={fetching_user_technologies_error}
            context='fetching user technologies'
            show_details_in_modal={true}
          />
        }

        {!show_spinner && !fetching_user_technologies_error && technologies &&
          <DashboardTileInner>
            <div className='my-3'>
              {has_classifiers &&
                <div className='mb-2'>Recently edited classifiers:</div>
              }
              {
                technologies.slice(0, Math.min(technologies.length, MAX_TECHNOLOGIES)).map((technology, i) => (
                  <div key={i}>
                    <span>{moment(technology.modified_at.seconds * 1000).fromNow()}</span>&nbsp;
                    <TextLink
                      target='_blank'
                      href={CUSTOM_CLASSIFIER_UI_BASE_URL+technology.alias}
                      element={Link}
                      className={'p-0'}
                      onClick={() => {track_visit_event(`page="classifier" classifier="${technology.alias}"`)}}
                    >
                      {technology.name}
                    </TextLink>
                  </div>
                ))
              }
            </div>

            <div className='w-100 mt-3 mt-md-auto'>
              <div className='d-sm-flex justify-content-between'>
                {has_classifiers &&
                  <Link
                    target='_blank'
                    href={CUSTOM_CLASSIFIERS_UI_BASE_URL}
                    className={'w-100 w-sm-50 mr-sm-2 d-block'}
                    onClick={() => {track_visit_event('page="custom-classifiers"')}}
                    title='See all classifiers'
                  >
                    <PrimaryButton className={'w-100'}>
                      See all
                    </PrimaryButton>
                  </Link>
                }
                <Link
                  target='_blank'
                  href={`${CUSTOM_CLASSIFIERS_UI_BASE_URL}?create_new=true`}
                  className={'w-100 w-sm-50 mt-2 mt-sm-0 d-block'}
                  onClick={() => {track_visit_event('page="custom-classifiers-create-new-from-homepage"')}}
                  title='Create a new classifier'
                >
                  <PrimaryButton className={'w-100'}>
                    Create new
                  </PrimaryButton>
                </Link>
              </div>
            </div>
          </DashboardTileInner>
        }

      </DashboardSmallTile>
    )
  }

}

export default RecentClassifiersPanel