import React from 'react'
import cn from 'classnames'

import { CARDS, TABLE } from '../../model/patent_family_list_views.js'

import { OnOffButton } from '../widgets/Button.js'
import { TableViewIcon, CardsViewIcon } from '../widgets/IconSet.js'

import s from './PatentFamilyListViewSelector.module.scss'

const PatentFamilyListViewSelector = ({
  className,
  size,
  patent_family_list_views,
  patent_family_list_view_id,
  on_change_patent_family_list_view_id
}) => {
  return (

    <div className={cn('d-flex', 'align-items-center', className)}>
      {patent_family_list_views.map((item, i) => {
        const { id, name } = item
        const is_selected = (id === patent_family_list_view_id)
        return (
          <OnOffButton
            key={i}
            className={cn('px-1', 'py-0')}
            is_on={is_selected}
            size={size}
            onClick={on_change_patent_family_list_view_id.bind(null, item.id)}
            title={name}
          >
            {(id === TABLE) &&
              <TableViewIcon
                className={cn(s.label_icon)}
              />
            }
            {(id === CARDS) &&
              <CardsViewIcon
                className={cn(s.label_icon)}
              />
            }
          </OnOffButton>
        )
      })}
    </div>    
  )
}

export default PatentFamilyListViewSelector