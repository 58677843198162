import React from 'react'
import cn from 'classnames'

import s from './DefaultPageContainer.module.scss'

const DefaultPageContainer = ({ children, className }) => {
  return (
    <div className={cn(s.page_container, className)}>
      {children}
    </div>
  )
}

export default DefaultPageContainer