import React from 'react'

import { Label } from './FamilyDetailsLabel.js'
import { TileContent } from './TileContent.js'
import { parse_territories } from '../../utils/family_view_utils.js'

export const FamilyTerritories = ({family}) => {
  const { pendingTerritories, grantedTerritories, expiredTerritories, firstFilingCountry } = family
  return (
    <div>
      <Label>Pending territories</Label>
      <TileContent>{parse_territories(pendingTerritories)}</TileContent>
      <Label>Granted territories</Label>
      <TileContent>{parse_territories(grantedTerritories)}</TileContent>
      <Label>Expired territories</Label>
      <TileContent>{parse_territories(expiredTerritories)}</TileContent>
      {firstFilingCountry && (['DD', 'SU', 'YU'].indexOf(firstFilingCountry) === -1) &&
        <>
          <Label>First filing country</Label>
          <TileContent>{firstFilingCountry}</TileContent>
        </>
      }
    </div>
  )
}