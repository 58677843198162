import React from 'react'
import cn from 'classnames'

import OrgName from './OrgName.js'
import PatentSamplePopup from './PatentSamplePopup.js'
import LitigationSamplePopup from './LitigationSamplePopup.js'
import AddressDisplay from './AddressDisplay.js'

import s from './OrganisationSimple.module.scss'

const OrganisationSimpleDetails = ({org, className}) => {
  const {address} = org
  const address_for_display = (address && address !== '') ? address : null

  return (
    <div className={className}>
      <div className={cn(s.icon_wrapper, 'mr-1')}>
        <PatentSamplePopup
          org={org}
        />
      </div>

      <div className={cn(s.icon_wrapper, 'mr-1')}>
        <LitigationSamplePopup
          org={org}
        />
      </div>

      <div className={cn(s.address_wrapper, 'mr-1')}>
        <AddressDisplay
          address={address_for_display}
        />
      </div>
    </div>
  )
}

const OrganisationSimple = ({org, className, children}) => {

  const show_details = !children || (children.length === 0)

  return (
    <div className={cn( s.wrapper, className)}>
      <div className='mb-1'>
        <OrgName
          org={org}
        />
      </div>
      <div className={s.detail_wrapper}>
        {children}

        {show_details &&
          <OrganisationSimpleDetails
            org={org}
            className='d-flex flex-wrap'
          />
        }
      </div>
    </div>
  )
}

export default OrganisationSimple
