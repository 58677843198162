import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { PaneHeader, Heading } from '../../widgets/PaneHeader.js'
import TextLink from '../../widgets/TextLink.js'
import { BUILD_REPORT } from '../../../constants/paths.js'
import { PrimaryButton } from '../../widgets/Button.js'
import { get_normalised_name, is_valid_report_name } from '../../../utils/name_utils.js'
import { MAX_REPORT_NAME_LENGTH } from '../../../constants/constants.js'
import EditableText from '../../widgets/EditableText.js'
import { NO_SWITCH_TO_ADVANCED_BUILDER_REPORT_STREAMS } from './report_streams.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'

import s from './ReportNameAndControls.module.scss'

const ReportNameAndControls = (
  {
    selected_stream_id,
    report_name,
    is_ignore_cached_reports,
    can_switch_to_advanced_builder,
    on_set_report_name_handler,
    on_switch_to_advanced_builder_handler,
    on_ignore_cached_reports_toggle,
  }) => {
  if (!selected_stream_id) return (
    <div className={s.block}>
      <div className={cn('d-sm-flex', s.block__intro)}>
        <PaneHeader text='Report shortcuts'/>
        <div className='ml-sm-1 my-auto mr-3 mr-sm-0'>
          <span>Create our most commonly used reports in a few clicks or go to the </span>
          <TextLink element={Link} to={BUILD_REPORT}>custom report builder</TextLink>
        </div>
      </div>
    </div>
  )

  return (
    <div className={cn('d-sm-flex justify-content-between', s.block)}>
      <div className='d-flex w-100 pr-4 pr-sm-0'>
        <Heading text='Name' className='mr-2'/>

        <EditableText
          text={report_name}
          on_confirm={on_set_report_name_handler}
          on_normalise={get_normalised_name}
          on_check_if_valid={is_valid_report_name}
          invalid_message={`Report name cannot be empty or longer than ${MAX_REPORT_NAME_LENGTH} characters.`}
          className='w-100'
          textClassName={s.report_name__static}
        />
      </div>

      <div className={cn('d-flex justify-content-start justify-content-sm-end mt-2 mt-sm-0', s.controls_wrapper)}>
        <CheckboxAndLabel
          label='Ignore cached reports'
          is_checked={is_ignore_cached_reports}
          on_click={on_ignore_cached_reports_toggle}
          checkboxClassName='my-auto'
          className='mr-1 my-auto'
        />

        {NO_SWITCH_TO_ADVANCED_BUILDER_REPORT_STREAMS.indexOf(selected_stream_id) === -1 &&
          <PrimaryButton
            outline size='xs'
            className='mr-1'
            onClick={on_switch_to_advanced_builder_handler}
            disabled={!can_switch_to_advanced_builder}
            title='Save progress and switch to custom builder'
          >
            Custom builder
          </PrimaryButton>
        }
      </div>
    </div>
  )
}

export default ReportNameAndControls