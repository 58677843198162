import axios from 'axios'
import moment from 'moment'
import _ from 'underscore'
import qs from 'query-string'

import {
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../model/technology_basket.js'
import {
  ALERT_BASE_INPUT_TYPE_CLASSIFIER,
  ALERT_BASE_INPUT_TYPE_FAMILY,
  ALERT_BASE_INPUT_TYPE_ORG,
  ALERT_BASE_INPUT_TYPE_SEARCH, ALERT_FILTER_TYPE_TERRITORY, ALERT_FILTER_TYPE_TERRITORY_TO_EXCLUDE,
  ALERT_FREQUENCY_ID_BIWEEKLY,
  ALERT_FREQUENCY_ID_DAILY,
  ALERT_FREQUENCY_ID_MONTHLY,
  ALERT_FREQUENCY_ID_QUARTERLY,
  ALERT_FREQUENCY_ID_WEEKLY,
  ALERT_PUBLICATION_TYPE_APPLICATION,
  ALERT_REPORT_FOCUS_FAMILIES,
  ALERT_TECH_PARTITIONING_TYPE_CLASSIFIER,
  ALERT_TECH_PARTITIONING_TYPE_CLUSTERING,
  ALERT_TECH_PARTITIONING_TYPE_CUSTOM,
  ALERT_TECH_PARTITIONING_TYPE_UTT,
  ALERT_TYPE_ID_DELTA,
  ALERT_TYPE_ID_NEW_FAMILIES,
  ALERT_TYPE_ID_NEW_FILINGS,
  ALERT_TYPE_ID_PVIX_INCREASE,
  ALERT_TYPE_ID_STATUS_CHANGE
} from '../constants/alert_reports.js'
import { get_negatives_processing, NEGATIVES_PROCESSING_EXCLUDE } from '../model/negatives_processing.js'
import {
  can_create_alert_for_report_type,
  is_classifier_landscape_report_type,
  is_custom_clustered_report_type,
  is_set_theory_report_type,
  is_utt_landscape_report_type
} from './report_utils.js'
import { is_assignee, is_organisation } from './organisation_utils.js'
import { COUNTRIES, ID_TO_COUNTRY } from '../constants/countries.js'
import { STATUS_GRANTED_ID, STATUS_PENDING_ID } from '../model/statuses.js'
import {
  ALERT_REPORTS_BASE_URL,
  CUSTOM_CLASSIFIER_UI_BASE_URL,
  CUSTOM_CLASSIFIERS_UI_BASE_URL
} from '../constants/urls.js'
import { add_source_err_to_target_err, CONFIG_NO_CACHE, JSON_POST_HEADER } from './axios_utils.js'
import { TYPE_PATENT_FAMILIES, TYPE_TECH_SEARCH } from '../model/portfolio_basket.js'
import { TYPE_AGGLOMERATED_ASSIGNEES, TYPE_ASSIGNEE, TYPE_ORGANISATION } from '../model/organisation.js'
import {
  ALERT_REPORT_TYPES,
  ALERT_REPORT_TYPES_BY_ID,
  ALL_ALERT_STATUSES,
  DEFAULT_SELECTED_PUBLICATION_TYPES
} from '../model/alert_reports.js'
import { array_to_wordy_string } from './utils.js'
import { PATENT_FAMILIES_S3_PORTFOLIO_TYPE } from '../model/portfolios.js'
import { BUILD_CLASSIFIER_REPORT, BUILD_REPORT, HISTORY } from '../constants/paths.js'
import { fetch_report_metadata } from './report_created_utils.js'
import { get_new_report_from_existing_url } from './report_builder_utils.js'
import { send_error_to_sentry } from './sentry_utils.js'
import { EVALUATE } from '../components/classifiers_editor/constants/classifier_paths.js'
import { is_valid_report_name } from './name_utils.js'


const REPORT_AGE_CUTOFF_DAYS = 28
export const ALL_TERRITORY_IDS = _.keys(ID_TO_COUNTRY)
export const ALL_ALERT_TERRITORIES = COUNTRIES.map(country => ({...country, id: country.country_code}))
const ALL_TERRITORIES_COUNT = ALL_TERRITORY_IDS.length
const CUSTOM_ALERT_REPORT_TECH_PARTITIONING = {partitioning_type: ALERT_TECH_PARTITIONING_TYPE_CUSTOM, params: null}


export function fetch_active_alerts() {
  return axios.get(ALERT_REPORTS_BASE_URL, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch active report-based alerts: ')
    })
}

export function fetch_alert_to_modify(alert_id) {
  return axios.get(`${ALERT_REPORTS_BASE_URL}/${alert_id}?is_owner=true`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch alert to modify: ')
    })
}

export function unsubscribe_alert(alert_id) {
  return axios.delete(`${ALERT_REPORTS_BASE_URL}/subscriber/${alert_id}`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to remove alert subscription: ')
    })
}

export function update_alert(alert_id, params_to_update) {
  return axios.patch(`${ALERT_REPORTS_BASE_URL}/${alert_id}`, params_to_update, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to remove alert subscription: ')
    })
}

function can_use_as_alert_base_report({report_input, alert_type, filtering_params}) {
  // check if this report can be used by the alerts system, or if it will need to generate its own base report for alerting
  if (alert_type === ALERT_TYPE_ID_NEW_FAMILIES && !is_delta_alert(alert_type, filtering_params)) {
    return true
  }
  const {technology_partitioning, report_type} = report_input
  const {type: tech_partitioning_type, include_negatives, negatives_processing} = technology_partitioning
  if (!is_classifier_landscape_report_type(report_type) && tech_partitioning_type === TECH_PARTITIONING_TYPE_CLASSIFIER) {
    // for non-landscape reports with negatives excluded, we'll usually need to run a fresh report
    return include_negatives || (negatives_processing && negatives_processing.type !== NEGATIVES_PROCESSING_EXCLUDE)
  }
  return true
}

export function save_alert_from_report({report_input, report_title, alert_setup_params, internal_report_id, data_creation_date}) {
  const {alert_id, alert_name, frequency, alert_type, start_date, empty_alerts, filters: filtering_params} = alert_setup_params

  const first_base_report = {}

  if (can_use_as_alert_base_report({report_input, alert_type, filtering_params})) {
    first_base_report.internal_report_id = internal_report_id
    first_base_report.data_creation_date = data_creation_date
  }

  const alert_input = translate_report_input_to_alert({
    report_input,
    filtering_params,
    alert_name: alert_name || report_title,
    alert_frequency_id: frequency,
    alert_type_id: alert_type,
    start_date,
    skip_empty_alerts: !empty_alerts,
    first_base_report,
    original_report_internal_id: internal_report_id
  })
  return alert_id ? update_alert_input(alert_id, alert_input) : save_alert(alert_input)
}

export function save_alert(alert_input) {
  return axios.post(`${ALERT_REPORTS_BASE_URL}/alert_input`, alert_input, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to save new alert subscription: ')
    })
}

export function update_alert_input(alert_id, alert_input) {
  return axios.put(`${ALERT_REPORTS_BASE_URL}/alert/${alert_id}/alert_input`, alert_input, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update alert subscription: ')
    })
}

export function report_is_too_old_for_alerting(data_creation_date) {
  const cut_off_date = moment().subtract(REPORT_AGE_CUTOFF_DAYS, 'days').toDate()
  return new Date(data_creation_date) < cut_off_date
}

export function get_available_alert_types(alert_focus, user_has_pvix) {
  return ALERT_REPORT_TYPES
    .filter(alert_type => {
      if (alert_type.id === ALERT_TYPE_ID_PVIX_INCREASE) {
        return user_has_pvix
      } else if (alert_type.id === ALERT_TYPE_ID_NEW_FAMILIES) {
        return alert_focus !== 'families'
      }
      return true
    })
}

function get_input_types_for_alert(report_input) {
  const {portfolios} = report_input
  return portfolios.map(portfolio => {
    const {type} = portfolio
    if (_.contains([TYPE_AGGLOMERATED_ASSIGNEES, TYPE_ASSIGNEE], type)) {
      // for alerting purposes, we treat all org types the same
      return TYPE_ORGANISATION
    }
    return type
  })
}

export function get_alert_focus_description(report_input) {
  const portfolio_types = get_input_types_for_alert(report_input)
  // input portfolios for an alert need to all be of the same type (eg all orgs)
  return get_readable_alert_input_type_description(portfolio_types[0], portfolio_types.length > 1)
}

export function is_families_based(report_input) {
  return get_alert_focus_description(report_input) === ALERT_REPORT_FOCUS_FAMILIES
}

export function is_alert_params_valid({current_param_values, is_report_families_based}) {
  const {alert_name: input_alert_name, alert_type, filters} = current_param_values
  if (is_report_families_based && alert_type === ALERT_TYPE_ID_NEW_FAMILIES) {
    return false
  }
  if (filters.territory && !filters.territory.length) {
    return false
  }
  if (alert_type === ALERT_TYPE_ID_STATUS_CHANGE && filters.status.length === 0) {
    return false
  } else if (alert_type === ALERT_TYPE_ID_NEW_FILINGS && filters.pub_type.length === 0) {
    return false
  }
  return input_alert_name === null || is_valid_report_name(input_alert_name)
}

function get_readable_alert_input_type_description(portfolio_type, is_multiple) {
  switch (portfolio_type) {
    case TYPE_ORGANISATION:
      return `organisation${is_multiple ? 's' : ''}`
    case TYPE_TECH_SEARCH:
      return 'search'
    case PATENT_FAMILIES_S3_PORTFOLIO_TYPE:
      return 'technology landscape'
    default:
      return ALERT_REPORT_FOCUS_FAMILIES
  }
}

export function can_use_report_for_alerting({report_input, report_type, created_by_user, data_creation_date, is_incomplete}) {
  if (!report_input || !report_type || !created_by_user || !data_creation_date) {
    return false
  }
  return get_reason_alert_cannot_be_built_from_input({report_input, report_type, created_by_user, data_creation_date, is_incomplete}) === null
}

export function get_reason_alert_cannot_be_built_from_input({report_input, report_type, created_by_user, data_creation_date, is_incomplete, alert_id}) {
  if (!created_by_user) {
    return 'Users can only create alerts from reports they themselves have built.'
  }
  if (is_incomplete) {
    return get_no_alert_message('Incomplete/ truncated classifier landscape reports')
  }
  if (report_is_too_old_for_alerting(data_creation_date) && !alert_id) {
    return get_no_alert_message(`Reports older than ${REPORT_AGE_CUTOFF_DAYS} days`)
  }
  const portfolio_types = _.uniq(get_input_types_for_alert(report_input))
  if (portfolio_types.length > 1) {
    const types_string = array_to_wordy_string(portfolio_types)
    return get_no_alert_message(`Reports which combine multiple types of portfolio inputs, for example ${types_string}`)
  }
  if (can_create_alert_for_report_type(report_type)) {
    return null
  }
  return get_reason_by_report_type(report_type)
}

function get_reason_by_report_type(report_type) {
  if (is_utt_landscape_report_type(report_type)) {
    return get_no_alert_message('UTT classifier landscape reports')
  } else if (is_custom_clustered_report_type(report_type)) {
    return get_no_alert_message('Reports with custom technology clusters')
  } else if (is_set_theory_report_type(report_type)) {
    return get_no_alert_message('Reports build using the set theory builder')
  }
  return get_no_alert_message('Reports of this type')
}

function get_no_alert_message(report_description) {
  return `${report_description} can not be used as the basis for alerts.`
}

export function translate_report_input_to_alert({report_input, filtering_params, alert_name, alert_type_id, alert_frequency_id, start_date, skip_empty_alerts, first_base_report, original_report_internal_id}) {
  const {report_type, portfolios, meta} = report_input
  const {evaluation_classifier_id} = meta || {}

  const params = {
    report_input,
    filtering_params,
    evaluation_classifier_id,
    alert_name,
    alert_type_id: alert_type_id || ALERT_TYPE_ID_NEW_FAMILIES,
    alert_frequency_id: alert_frequency_id || ALERT_FREQUENCY_ID_WEEKLY,
    start_date,
    skip_empty_alerts,
    first_base_report,
    original_report_internal_id
  }
  if (is_classifier_landscape_report_type(report_type)) {
    return build_landscape_report_alert_from_report_input(params)
  }
  if (_.all(portfolios, portfolio => portfolio.type === TYPE_PATENT_FAMILIES)) {
    return build_patent_upload_report_alert(params)
  }
  if (_.all(portfolios, portfolio => portfolio.type === TYPE_TECH_SEARCH)) {
    return build_boolean_search_report_alert(params)
  }
  if (_.all(portfolios, portfolio => _.contains([TYPE_ORGANISATION, TYPE_ASSIGNEE, TYPE_AGGLOMERATED_ASSIGNEES], portfolio.type))) {
    return build_org_report_alert(params)
  }
  return {}
}

export function find_first_alert_date(frequency) {
  const today = moment().startOf('day').toDate()
  return find_next_alert_date(frequency, today)
}

export function find_next_date_for_saved_alert(alert) {
  const today = moment().startOf('day').toDate()
  const {last_alert, next_alert, last_base_report_run, alert_frequency_id} = alert
  if (next_alert) {
    // next alert date has been manually set in the db
    const next_alert_date = new Date(next_alert)
    return next_alert_date > today ? next_alert_date : today
  }
  // calculate the next alert date from the last alerted/ first base report run
  const alert_start_period = last_alert || last_base_report_run || today // presumably this alert has only just been set up
  const calculated_next_alert_date = find_next_alert_date(alert_frequency_id, alert_start_period)
  return calculated_next_alert_date > today ? calculated_next_alert_date : today
}

function find_next_alert_date(frequency, alert_start_period) {
  let calculated_alert_date = calculate_next_alert_date_from_frequency(frequency, moment(alert_start_period))
  return calculated_alert_date.toDate()
}

function calculate_next_alert_date_from_frequency(frequency, alert_start_period_moment) {
  switch (frequency) {
    case ALERT_FREQUENCY_ID_DAILY:
      return alert_start_period_moment.add(1, 'days')
    case ALERT_FREQUENCY_ID_BIWEEKLY:
      return alert_start_period_moment.add(14, 'days')
    case ALERT_FREQUENCY_ID_MONTHLY:
      return alert_start_period_moment.add(1, 'months')
    case ALERT_FREQUENCY_ID_QUARTERLY:
      return alert_start_period_moment.add(3, 'months')
    case ALERT_FREQUENCY_ID_WEEKLY:
    default:
      return alert_start_period_moment.add(7, 'days')
  }
}


function is_single_category_alert(alert_type_id) {
  return [ALERT_TYPE_ID_NEW_FAMILIES, ALERT_TYPE_ID_PVIX_INCREASE, ALERT_TYPE_ID_DELTA].indexOf(alert_type_id) > -1
}

function extract_tech_partitioning(report_input) {
  const {technology_partitioning} = report_input
  const {type, use_superclasses, version_id} = technology_partitioning
  switch (type) {
    case TECH_PARTITIONING_TYPE_CLASSIFIER:
      const params = extract_classifier_params(report_input)
      return {partitioning_type: ALERT_TECH_PARTITIONING_TYPE_CLASSIFIER, params}
    case TECH_PARTITIONING_TYPE_UTT:
      return {partitioning_type: ALERT_TECH_PARTITIONING_TYPE_UTT, params: {use_superclasses, version_id}}
    case TECH_PARTITIONING_TYPE_CLUSTERING:
      return {partitioning_type: ALERT_TECH_PARTITIONING_TYPE_CLUSTERING}
    default:
      return CUSTOM_ALERT_REPORT_TECH_PARTITIONING
  }
}

function extract_classifier_params(report_input) {
  const {technology_partitioning} = report_input
  const {classifier_sources=[], threshold, negatives_processing, include_negatives, multi_label} = technology_partitioning
  return {
    // only classifier ids are saved to the alerts db as the latest names and paths should be fetched every time an alert is generated
    classifiers: classifier_sources.map(classifier => ({id: classifier.id})),
    threshold,
    multi_label,
    ...(include_negatives ? {include_negatives} : {}),
    ...(negatives_processing ? {negatives_processing} : {})
  }
}

function build_patent_upload_report_alert({report_input, ...params}) {
  const {portfolios} = report_input
  // combines all patent uploads in one family list... not very sophisticated but should suit most alerting use cases
  const pat_fam_ids = _.flatten(portfolios.map(portfolio => portfolio.pat_fam_ids))
  const base_input = {input_type: ALERT_BASE_INPUT_TYPE_FAMILY, inputs: _.uniq(pat_fam_ids)}
  const tech_partitioning = extract_tech_partitioning(report_input)

  return build_alert_report_input({
    ...params,
    base_input,
    tech_partitioning
  })
}

function build_boolean_search_report_alert({report_input, ...params}) {
  const {portfolios} = report_input
  const inputs = portfolios.map(portfolio => portfolio.search_term)
  const base_input = {input_type: ALERT_BASE_INPUT_TYPE_SEARCH, inputs}
  const tech_partitioning = extract_tech_partitioning(report_input)

  return build_alert_report_input({
    ...params,
    base_input,
    tech_partitioning
  })
}

function build_org_report_alert({report_input, ...params}) {
  // TODO later: if this is a report that's been built from (a) whole company list(s), use the company list type input instead
  // eg {type: ALERT_BASE_INPUT_TYPE_COMPANY_LIST, [{'company_list_id': 231231}]
  // ... but we might need to check the source list to see if it matches, as individual orgs could have been removed from the basket after list(s) were added
  const {portfolios} = report_input
  const inputs = portfolios.map(org => {
    const assignee_ids = is_assignee(org) ? [org.id] : org.assignee_ids
    return {
      name: org.name,
      ...(is_organisation(org) ? {org_id: org.id} : {}),
      ...(assignee_ids ? {assignee_ids} : {})
    }
  })

  const base_input = {input_type: ALERT_BASE_INPUT_TYPE_ORG, inputs}
  const tech_partitioning = extract_tech_partitioning(report_input)

  return build_alert_report_input({
    ...params,
    base_input,
    tech_partitioning
  })
}

/**
 * Basic eval classifier delta alert
 * TODO later: add toggle in classifiers UI/ similar
 */
export function build_basic_eval_classifier_alert({alert_name, evaluation_classifier_id, alert_type_id}) {
  const classifier_params = {
    classifiers: [{id: evaluation_classifier_id}],
    multi_label: false,
    threshold: 0.5,
    negatives_processing: get_negatives_processing({type: NEGATIVES_PROCESSING_EXCLUDE})
  }
  return build_landscape_report_alert_input({
    alert_name,
    alert_type_id,
    classifier_params,
    evaluation_classifier_id,
    filters: {},
    alert_frequency_id: ALERT_FREQUENCY_ID_WEEKLY,
    skip_empty_alerts: true
  })
}

function build_landscape_report_alert_from_report_input({report_input, ...params}) {
  const classifier_params = extract_classifier_params(report_input)
  return build_landscape_report_alert_input({
    ...params,
    classifier_params
  })
}

function build_landscape_report_alert_input({alert_type_id, classifier_params, ...params}) {
  const base_input = {input_type: ALERT_BASE_INPUT_TYPE_CLASSIFIER, inputs: classifier_params}

  // if alert_type is single category, tech_partitioning can use classifiers; elsewise alerts will need to use custom partitioning to include extra data in the generated reports
  const tech_partitioning = is_single_category_alert(alert_type_id) ? {partitioning_type: ALERT_TECH_PARTITIONING_TYPE_CLASSIFIER, params: classifier_params} : CUSTOM_ALERT_REPORT_TECH_PARTITIONING

  return build_alert_report_input({
    ...params,
    alert_type_id,
    base_input,
    tech_partitioning
  })
}

function should_include_status_filter(status_filter_values, alert_type_id) {
  return alert_type_id === ALERT_TYPE_ID_STATUS_CHANGE && !_.isEqual(status_filter_values, ALL_ALERT_STATUSES)
}

function should_include_pub_type_filter(pub_type_filter_values, alert_type_id) {
  return alert_type_id === ALERT_TYPE_ID_NEW_FILINGS && !_.isEqual(pub_type_filter_values, DEFAULT_SELECTED_PUBLICATION_TYPES)
}

function extract_filters(filtering_params, alert_type_id) {
  const applicable_filters = {}
  const {
    status: status_filter_values,
    pub_type: pub_type_filter_values,
    territory: territory_filter_values,
    pvix_threshold
  } = filtering_params || {}
  // territory include/ exclude filter
  if (territory_filter_values) {
    if (territory_filter_values.length > (ALL_TERRITORIES_COUNT / 2)) {
      // most territories are selected so we can make this an exclusive filter
      applicable_filters[ALERT_FILTER_TYPE_TERRITORY_TO_EXCLUDE] = ALL_TERRITORY_IDS.filter(country_code => territory_filter_values.indexOf(country_code) === -1)
    } else {
      applicable_filters[ALERT_FILTER_TYPE_TERRITORY] = Array.from(territory_filter_values)
    }
  }
  // status filter
  if (status_filter_values && should_include_status_filter(status_filter_values, alert_type_id)) {
    applicable_filters.status = status_filter_values
  }
  // pub types (pub status) filter
  if (pub_type_filter_values && should_include_pub_type_filter(pub_type_filter_values, alert_type_id)) {
    // the alerts backend doubles up on the status filter to handle pub types
    applicable_filters.status = pub_type_filter_values.map(value => value === ALERT_PUBLICATION_TYPE_APPLICATION ? STATUS_PENDING_ID : STATUS_GRANTED_ID)
  }
  // PVIX threshold filter
  if (alert_type_id === ALERT_TYPE_ID_PVIX_INCREASE) {
    applicable_filters.pvix_threshold = {threshold: pvix_threshold, alert_once_only: true}
  }
  return applicable_filters
}

function is_delta_alert(alert_type_id, filtering_params) {
  return alert_type_id === ALERT_TYPE_ID_NEW_FAMILIES && !filtering_params.new_families_only
}

function build_alert_report_input({alert_type_id: broad_alert_type_id, filtering_params, alert_frequency_id, ...params}) {
  const filters = extract_filters(filtering_params, broad_alert_type_id)

  const alert_type_id = is_delta_alert(broad_alert_type_id, filtering_params) ? ALERT_TYPE_ID_DELTA : broad_alert_type_id

  return {
    ...params,
    alert_frequency_id: alert_frequency_id || ALERT_FREQUENCY_ID_WEEKLY,
    filters,
    alert_type_id
  }
}

export function get_alert_display_name(alert_type_id) {
  if (alert_type_id === ALERT_TYPE_ID_DELTA) {
    return ALERT_REPORT_TYPES[ALERT_TYPE_ID_NEW_FAMILIES].name
  }
  return ALERT_REPORT_TYPES_BY_ID[alert_type_id].name
}

export function can_edit_alert_params(alert) {
  return alert.is_owner > 0
}

export function rebuild_alert_report(alert, external_report_id, history) {
  const {alert_id, evaluation_classifier_id} = alert
  const url_param = qs.stringify({alert: alert_id})
  if (!external_report_id) {
    if (evaluation_classifier_id) {
      history.push(`${CUSTOM_CLASSIFIER_UI_BASE_URL}${evaluation_classifier_id}/${EVALUATE}`)
    } else {
      // go to the main report builder
      history.push(`${BUILD_REPORT}?${url_param}`)
    }
    return
  }
  fetch_report_metadata(external_report_id)
    .then(report_metadata => {
      const {report_type} = report_metadata
      if (!is_classifier_landscape_report_type(report_type)) {
        const url = get_new_report_from_existing_url(external_report_id, report_type)
        history.push(`${url}&${url_param}`)
      } else {
        // chances are in this case the original classifier is no longer available, so the user needs to choose a new one
        const url = evaluation_classifier_id ? CUSTOM_CLASSIFIERS_UI_BASE_URL : `${BUILD_CLASSIFIER_REPORT}?${url_param}`
        history.push(url)
      }
    })
    .catch(err => {
      // problem fetching metadata... send error to sentry and redirect to main report builder
      send_error_to_sentry(err, {alert, external_report_id})
      history.push(`${BUILD_REPORT}?${url_param}`)
    })
}

export function go_to_alert_report_history(alert, history) {
  const {alert_report_name, evaluation_classifier_id} = alert
  if (evaluation_classifier_id) {
    history.push(`${CUSTOM_CLASSIFIER_UI_BASE_URL}${evaluation_classifier_id}/${EVALUATE}`)
    return
  }
  const url_param = qs.stringify({search: alert_report_name})
  history.push(`${HISTORY}?${url_param}`)
}