import React from 'react'
import ReactCheckboxTree from 'react-checkbox-tree'
import cn from 'classnames'

import CheckboxStatic from './CheckboxStatic.js'
import { ExpandClosedIcon, ExpandOpenedIcon } from './IconSet.js'

import s from './CheckboxTree.module.scss'

const CheckboxTree = (
  {
    nodes,
    checked,
    expanded,
    on_check,
    on_expand,
    show_expand_all,
    on_click,
    expand_on_click,
    only_leaf_checkboxes,
    check_icon,
    uncheck_icon,
    className,
  }) => {

  const tree_control_classNames = cn(s.tree_control, className)
  return (
    <ReactCheckboxTree

      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={on_check}
      onExpand={on_expand}
      showExpandAll={show_expand_all}
      onClick={on_click}
      expandOnClick={expand_on_click}
      onlyLeafCheckboxes={only_leaf_checkboxes}
      optimisticToggle
      icons={{
        check: check_icon || <CheckboxStatic is_checked={true} />,
        uncheck: uncheck_icon || <CheckboxStatic is_checked={false} />,
        halfCheck: <CheckboxStatic is_checked={false} is_partial={true} />,
        expandClose: <span className={cn(tree_control_classNames, 'pb-1')}><ExpandClosedIcon /></span>,
        expandOpen: <span className={cn(tree_control_classNames, 'pb-1')}><ExpandOpenedIcon /></span>,
        expandAll: <span className={tree_control_classNames}>[expand]</span>,
        collapseAll: <span className={tree_control_classNames}>[collapse]</span>,
        parentClose: null,
        parentOpen: null,
        leaf: null,
      }}

    />
  )
}

export default CheckboxTree