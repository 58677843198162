import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import { Chip } from '@mui/material'

import { withUser } from '../UserContext.js'
import {
  fetch_all_active_keycloak_groups,
  fetch_all_active_keycloak_users,
  fetch_users_in_keycloak_group
} from '../../utils/user_group_utils'
import { has_view_admin_page } from '../../utils/user_permissions.js'

import {
  from_str_to_tag_permission,
  TagPermission,
  UUIDPermissions,
  UUIDType
} from './family_tag_utils'

import { EntityPermission } from './EntityPermission'


interface FamilyTagSharingProps {
  user: any,
  permissions: Array<any>,
  set_permissions: Function,
  allow_groups_sharing: boolean,

}

const USER_STR_MARKER = '(USER) '


export const FamilyTagSharing = ({
                                   user,
                                   permissions,
                                   set_permissions,
                                   allow_groups_sharing,
                                 }: FamilyTagSharingProps) => {
  const [cipher_entities, set_cipher_entities] = useState<Array<any>>([])
  const [selected_cipher_entity, set_selected_cipher_entity] = useState<Array<any>>([])
  const [permission_selected, set_permission_selected] = useState<TagPermission | string>(TagPermission.VIEW)
  const is_admin_view = has_view_admin_page(user) && allow_groups_sharing

  const owners = permissions.filter((permission) =>(permission.level === TagPermission.EDIT))
  const viewers = permissions.filter((permission) =>(permission.level === TagPermission.VIEW))
  const taggers = permissions.filter((permission) =>(permission.level === TagPermission.TAG))

  useEffect(() => {
    let did_cancel = false
    Promise.all([
      is_admin_view ? fetch_all_active_keycloak_users() : fetch_users_in_keycloak_group(user.group_ids[0]),   // users
      is_admin_view ? fetch_all_active_keycloak_groups() : Promise.resolve(null),                 // groups
    ])
      .then(([users, groups]) => {
        if (!did_cancel) {
          const users_to_share = (users && users.filter((user: any) => (user.email != null && user.id != null)).map((user: any) => {
            return {...user, display_name: user.email, type:UUIDType.USER}
          })) || []
          const groups_to_share = (groups && groups.filter((group: any) => (group.name != null && group.id != null)).map((group: any) => {
            return {...group, display_name: group.name, type:UUIDType.GROUP}
          })) || []

          const merged_entities = [...users_to_share, ...groups_to_share]
          set_cipher_entities(merged_entities)
        }
      })
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user_id])

  return (
    <div>
      <EntityPermission
        entities={cipher_entities}
        placeholder={allow_groups_sharing? 'Share with individuals or group' : 'Share with individuals'}
        disabled={false}
        selected_entity={selected_cipher_entity}
        set_selected_entity={set_selected_cipher_entity}
        selected_permission={from_str_to_tag_permission(permission_selected)}
        set_selected_permission={set_permission_selected}
        update_permissions={update_permissions}
        className={''}
      />
      <div />
      <div className={'mt-3 d-flex flex-row '}>
        <div className={'w-50'}>
          <div className={'mb-1'}>Taggers</div>
          <div>
            {taggers.map((permission, i) => {
              return (
                <Chip
                  label={permission.name}
                  variant={'outlined'}
                  size={'small'}
                  key={i}
                  className={'mr-1'}
                  onDelete={() => remove_displayed_permission(permission)}
                />)
            })}
          </div>
        </div>
        <div className={'ml-2 w-50'}>
          <div className={'mb-1'}>Viewers</div>
          <div>
            {viewers.map((permission, i) => {
              return (
                <Chip
                  label={permission.name}
                  variant={'outlined'}
                  size={'small'}
                  key={i}
                  className={'mr-1'}
                  onDelete={() => remove_displayed_permission(permission)}
                />)
            })}
          </div>
        </div>
      </div>
      <div className={'mt-3'}>
        <div className={'mb-1'}>Owners</div>
        <div>
          {owners.map((permission, i) => {
            return (
              <Chip
                label={permission.name}
                variant={'outlined'}
                size={'small'}
                key={i}
                className={'mr-1'}
                onDelete={() => remove_displayed_permission(permission)}
              />)
          })}
        </div>
      </div>
      {_.isEmpty(permissions) &&
      <div className={'d-block invalid-feedback'}>
        Add permissions for at least one user or group
      </div>
      }
    </div>

  )

  function remove_displayed_permission(selected_permission: any) {
    const new_permissions = permissions.filter((permission) =>
       (permission.uuid !== selected_permission.uuid || permission.level !== selected_permission.level))
    set_permissions(new_permissions)
  }

  function is_user_entity(user:string) {
    return user.indexOf(USER_STR_MARKER) === 0
  }

  function update_permissions() {
    const new_permissions = [...permissions]
    if (!_.isEmpty(selected_cipher_entity)) {
      const items: Array<UUIDPermissions> = selected_cipher_entity.map((user: any) => ({
        uuid: user.id,
        name: user.label,
        level: from_str_to_tag_permission(permission_selected),
        type: is_user_entity(user.label)? UUIDType.USER : UUIDType.GROUP
      }))
      new_permissions.push(...items)
    }
    set_permissions(new_permissions)
  }
}


export default withUser(FamilyTagSharing)