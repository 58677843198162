import React, { Component } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { Pane } from '../widgets/Block.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { PrimaryButton } from '../widgets/Button.js'
import ClassifiersPanel from './ClassifiersPanel.js'
import Grid from '../widgets/Grid.js'
import Classifiers from './Classifiers.js'

import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { get_object_values } from '../../utils/utils.js'
import GridElement from '../widgets/GridElement.js'
import IndirectlySelectedHint from '../widgets/IndirectlySelectedHint.js'
import {
  get_classifier_for_report_input,
  get_group_classifiers_ids,
  get_group_indirectly_selected_classifiers,
  get_group_selected_classifiers,
  get_filtered_classifiers
} from '../../utils/classifier_group_utils.js'

import s from './ClassifiersDisplay.module.scss'

class ClassifiersDisplay extends Component {
  constructor(props) {
    super(props)

    this.on_classifiers_show_selected_only       = this.on_classifiers_show_selected_only.bind(this)
    this.on_classifiers_select_all               = this.on_classifiers_select_all.bind(this)
    this.on_classifiers_deselect_all             = this.on_classifiers_deselect_all.bind(this)
    this.on_classifiers_search                   = this.on_classifiers_search.bind(this)

    this.update_classifiers_selection            = this.update_classifiers_selection.bind(this)

    this.state = {
      show_selected_only: false,
      search_phrase: ''
    }
  }

  get_classifiers_field_for_source(source) {
    return `classifiers_${source}`
  }

  on_classifiers_show_selected_only() {
    track_report_builder_event('obj="classifier_tree" action="show" option="selected"')
    this.setState({show_selected_only: !this.state.show_selected_only})
  }

  on_classifiers_select_all() {
    track_report_builder_event('obj="classifier_tree" action="select" option="all"')
    const {classifier_groups, update_selections_handler, technology_basket} = this.props

    const { search_phrase } = this.state

    let all_filtered_classifiers = []

    classifier_groups.forEach(group => {
      const filtered = get_filtered_classifiers(search_phrase, group)
      all_filtered_classifiers = [...all_filtered_classifiers, ...filtered]
    })

    const selected_classifiers = get_object_values(technology_basket || {})
    const selection = _.uniq([...selected_classifiers, ...all_filtered_classifiers])
    update_selections_handler(selection)
  }

  on_classifiers_deselect_all() {
    track_report_builder_event('obj="classifier_tree" action="select" option="none"')
    const {classifier_groups, update_selections_handler, technology_basket} = this.props

    const { search_phrase } = this.state

    let all_filtered_classifier_ids = []

    classifier_groups.forEach(group => {
      const filtered = get_filtered_classifiers(search_phrase, group).map(item => item.classifier_id)
      all_filtered_classifier_ids = [...all_filtered_classifier_ids, ...filtered]
    })

    const selected_classifiers = get_object_values(technology_basket || {})
    const selection = selected_classifiers.filter(item => all_filtered_classifier_ids.indexOf(item.classifier_id) === -1)
    update_selections_handler(selection)
  }

  on_classifiers_search(search_phrase) {
    track_report_builder_event('obj="classifier_tree" action="search"', true)
    this.setState({ search_phrase })
  }

  update_classifiers_selection(selection, classifiers) {
    const {update_selections_handler, technology_basket} = this.props
    const selected_classifiers = get_object_values(technology_basket || {})
    const selected_classifier_ids = (selected_classifiers || []).map(s => s.classifier_id)

    const tree_classifiers_ids = get_group_classifiers_ids(classifiers)

    const unselected_tree_classifiers_ids = _.difference(tree_classifiers_ids, selection.map(item => item.classifier_id))
    const selected_classifiers_filtered = (selected_classifiers || []).filter(item => unselected_tree_classifiers_ids.indexOf(item.classifier_id) === -1)
    const updated_selected_classifiers = [...selected_classifiers_filtered, ...selection.filter(item => selected_classifier_ids.indexOf(item.classifier_id) === -1).map((classifier) => get_classifier_for_report_input(classifier))]

    update_selections_handler(updated_selected_classifiers)
  }

  render() {
    const {
      can_edit_technology_filters,
      report_builder_change_mode_handler,
      is_subscription_display,
      show_classifier_tree_controls,
      classifier_groups,

      check_icon,
      uncheck_icon,
      is_selection_permitted,
      unpermitted_selection_handler,
      technology_basket
    } = this.props

    const classifier_groups__clean = classifier_groups.filter(group => group && group.children && group.children.length > 0)
    const { search_phrase } = this.state

    return (
      <div>
        {(can_edit_technology_filters || is_subscription_display) &&

          <div>
            {report_builder_change_mode_handler &&
              <Pane className='p-3 d-flex'>
                <PaneHeader text={'Select classifiers to apply'} className='mr-auto my-auto' />
                <PrimaryButton size='sm' onClick={report_builder_change_mode_handler}>Done</PrimaryButton>
              </Pane>
            }

            <div className={cn(s.technology_container, 'mt-lg-1 mb-lg-3 py-3 px-sm-3')}>
              <ClassifiersPanel
                search_phrase={search_phrase}
                classifier_tree_search_handler={this.on_classifiers_search}
                classifier_tree_show_selected_only_handler={this.on_classifiers_show_selected_only}
                classifier_tree_select_all_handler={this.on_classifiers_select_all}
                classifier_tree_deselect_all_handler={this.on_classifiers_deselect_all}
                show_classifier_tree_controls={(show_classifier_tree_controls != null) ? show_classifier_tree_controls : true}
                show_select_all={!is_subscription_display}
              />

              <Grid className='mt-3'>
                {classifier_groups__clean.map((classifier_group, idx) => {
                  const { show_selected_only, search_phrase } = this.state

                  const all_selected_classifiers = get_object_values(technology_basket || {})
                  const selected_from_source = get_group_selected_classifiers(classifier_group, all_selected_classifiers)
                  const selected_indirectly = get_group_indirectly_selected_classifiers(classifier_group, all_selected_classifiers)

                  return (
                    <GridElement key={idx}>
                      <div className={cn('p-2', s.classifiers_wrapper)}>
                        <Classifiers
                          classifiers_data={classifier_group}
                          update_selection_handler={(selections) => this.update_classifiers_selection(selections, classifier_group)}
                          search_phrase={search_phrase}
                          selected_classifiers={selected_from_source}
                          selected_indirectly={selected_indirectly}
                          show_selected_only={show_selected_only}
                          toggle_show_selected_only_handler={this.on_classifiers_show_selected_only}
                          prohibit_parent_level_selection={is_subscription_display}

                          check_icon={check_icon}
                          uncheck_icon={uncheck_icon}

                          is_selection_permitted={is_selection_permitted}
                          unpermitted_selection_handler={unpermitted_selection_handler}
                        />

                        {selected_indirectly.length > 0 &&
                          <IndirectlySelectedHint className='my-2' />
                        }
                      </div>
                    </GridElement>
                  )
                })}

              </Grid>
            </div>
          </div>
        }

        {!can_edit_technology_filters && !is_subscription_display &&
          <Pane className='p-3 d-flex'>
            <PaneHeader text={'Technology filters are not available'} className='mr-auto my-auto' />
            <PrimaryButton onClick={report_builder_change_mode_handler}>Edit portfolios</PrimaryButton>
          </Pane>
        }
      </div>
    )
  }
}

export default ClassifiersDisplay