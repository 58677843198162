import React from 'react'
import cn from 'classnames'

import { TabularViewIcon } from './IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './TableThumbnail.module.scss'

const TableThumbnail = ({label, thumbnail_className}) => {
  return (
    <div className={cn('d-flex flex-column justify-content-center align-items-center mx-auto', s.block)}>
      <div className={thumbnail_className}>
        <TabularViewIcon custom_style={{height: '100%', width: '100%', opacity: '0.4'}}/>
      </div>
      <div className={cn(s.label, cs.font_size_small)}>{label}</div>
    </div>
  )
}

export default TableThumbnail