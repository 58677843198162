import React, { useState } from 'react'
import cn from 'classnames'

import { NEW_FILINGS_IN_TECH_STREAM_ID, TECH_LANDSCAPE_STREAM_ID } from './report_streams.js'
import TechnologiesDisplay from './TechnologiesDisplay.js'
import ProgressPrompt from './ProgressPrompt.js'
import ButtonGroup from '../../widgets/ButtonGroup.js'
import {
  GEO_LANDSCAPES,
  ID_TO_GEO_LANDSCAPE,
  STEP_TECHNOLOGY_BINARY_CLASS,
  STEP_TECHNOLOGY_UTT
} from './builder_wizard.js'
import { get_boolean_search_as_portfolio_item, TYPE_TECH_SEARCH } from '../../../model/portfolio_basket.js'
import { is_aistemos } from '../../../utils/user_permissions.js'
import { AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT, CLASSIFIER_LANDSCAPE_LIMIT } from '../../../utils/report_builder_utils.js'
import { withUser } from '../../UserContext.js'

import s from './TechnologyStep.module.scss'

const TechnologyStep = (
  {
    user,
    is_fetching,
    is_final,
    portfolios,
    classifier_groups,
    utt_group,

    selected_stream_id,
    selected_classifier_group_id,
    classifiers,

    tech_type,

    on_select_classifier_group,
    on_select_classifiers,
    on_complete,
    className
  }) => {

  const [geo_option, set_geo_option] = useState('all')

  const classifiers_landscape_limit = is_aistemos(user) ? AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT : CLASSIFIER_LANDSCAPE_LIMIT

  const apply_limit = selected_stream_id === TECH_LANDSCAPE_STREAM_ID

  const can_continue = (classifiers || []).length !== 0

  function on_create_report() {
    const geo_landscape_option = ID_TO_GEO_LANDSCAPE[geo_option]

    if (geo_landscape_option) {
      return run_geo_landscape_report(geo_landscape_option)
    }

    return on_complete()
  }

  function run_geo_landscape_report(geo_landscape_option) {
    const {name, search_phrase} = geo_landscape_option || {}

    const bool_search_portfolios = (portfolios || []).filter(item => item.type === TYPE_TECH_SEARCH)
    const search_phrases = bool_search_portfolios.map(item => item.search_term)
    const names = bool_search_portfolios.map(item => item.name)

    const portfolio_item = {...get_boolean_search_as_portfolio_item([...search_phrases, search_phrase].join(' AND ')), name: [...names, name].join(', ')}
    on_complete({portfolios: [portfolio_item]})
  }

  const geo_filters = GEO_LANDSCAPES.filter(item => {
    const {check_if_available} = item

    if (!check_if_available) return true

    return check_if_available({user})
  })

  return (
    <div className={className}>
      <ProgressPrompt
        is_wizard_final_step={is_final}
        can_wizard_continue={can_continue}
        on_step_complete={on_create_report}
      >
        <span>
          {selected_stream_id === TECH_LANDSCAPE_STREAM_ID && `Select ${tech_type === STEP_TECHNOLOGY_UTT ? 'one UTT technology' : `up to ${classifiers_landscape_limit} classifiers`}.` }
          {selected_stream_id === NEW_FILINGS_IN_TECH_STREAM_ID && `Select as many ${tech_type === STEP_TECHNOLOGY_UTT ? 'technologies' : 'classifiers'} as needed.` }
        </span>
      </ProgressPrompt>

      <div className={cn('d-sm-flex mt-2', s.progress_options_wrapper)}>
        {can_continue && tech_type === STEP_TECHNOLOGY_BINARY_CLASS &&
          <>
            <span className={cn('my-auto mr-2 pb-2', s.landscape_options_label)}>Landscape report options:</span>

            <ButtonGroup
              size='sm'
              items={[{id: 'all', name: 'All families'}, ...geo_filters]}
              selected_id={geo_option}
              on_change_selected_id={set_geo_option}
              className={cn('d-flex flex-nowrap w-100', s.landscape_options_wrapper)}
              btnClassName={s.landscape_option_btn}
            />
          </>
        }
      </div>

      <TechnologiesDisplay
        is_fetching={is_fetching}
        classifier_groups={tech_type === STEP_TECHNOLOGY_BINARY_CLASS ? classifier_groups : null}
        utt_group={tech_type === STEP_TECHNOLOGY_UTT ? utt_group : null}

        selected_classifier_group_id={selected_classifier_group_id}
        selected_classifiers={classifiers}

        classifiers_landscape_limit={ apply_limit ? classifiers_landscape_limit : null}
        utt_landscape_limit={apply_limit ? 1 : null}

        on_select_classifier_group={on_select_classifier_group}
        on_select_classifiers={on_select_classifiers}
      />
    </div>
  )
}

export default withUser(TechnologyStep)