import React, { useState } from 'react'
import cn from 'classnames'

import { Label, LabelInline } from './FamilyDetailsLabel.js'
import { TileContent } from './TileContent.js'
import CpcWithHover from './CpcWithHover.js'
import AllCpcsModal from './AllCpcsModal.js'

import cs from '../cipher_styles.module.scss'

export function FamilyCpcCodes({ family, highlighter_props }) {

  const [show_all, set_show_all] = useState(false)
  const {cpcCodes: cpc_codes, primaryCpc, patFamId} = family

  const has_cpc_codes = (cpc_codes != null) && (cpc_codes.length > 0)

  const has_primary_cpc_code = has_cpc_codes && (cpc_codes.indexOf(primaryCpc) > -1)

  const secondary_cpc_codes = has_primary_cpc_code ? (cpc_codes || []).filter(cpc_code => cpc_code !== primaryCpc) : cpc_codes

  return (
    <div key={`cpc_codes_${patFamId}`}>
      <Label className={cn('d-flex')}>
        <span>CPC codes</span>
        {has_cpc_codes &&
          <span
            className={cn('ml-auto', cs.font_size_smaller, cs.cipher_red_text, cs.cursor_pointer)}
            onClick={() => {set_show_all(true)}}
          >
            <span>show all</span>
          </span>
        }
      </Label>

      {has_primary_cpc_code &&
        <div className='d-flex mb-2'>
          <LabelInline>primary</LabelInline>
          <TileContent>
            <CpcWithHover
              cpc_codes={[primaryCpc]}
              highlighter_props={highlighter_props}
            />
          </TileContent>
        </div>
      }

      <TileContent className='d-flex flex-wrap'>
        {has_cpc_codes && secondary_cpc_codes.map((cpc_code, i) => {
          return (
            <CpcWithHover
              key={i}
              cpc_codes={[cpc_code]}
              highlighter_props={highlighter_props}
              show_separator={i < secondary_cpc_codes.length - 1}
              className='mr-1'
            />
        )})}

        {!has_cpc_codes && <span>(none)</span>}
      </TileContent>

      {show_all &&
        <AllCpcsModal
          cpc_codes={cpc_codes}
          primary={primaryCpc}
          highlighter_props={highlighter_props}
          on_close={() => { set_show_all(false)}}
        />
      }

    </div>
  )
}