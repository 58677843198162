import React from 'react'

import ErrorBody from './ErrorBody.js'
import DefaultPageContainer from './DefaultPageContainer.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import Header from './header/Header.js'
import Footer from './Footer.js'
import ContainerFullWidth from './ContainerFullWidth.js'

/**
 * Generic ErrorBoundary.
 * Just renders the error (no extra header or footer)
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error, errorInfo) {
    this.setState( { error, errorInfo })
    // No need to send error to Sentry here (ErrorBody below does this)
  }

  render() {
    const { error, errorInfo } = this.state

    const { with_fake_header_and_footer } = this.props

    if (error) {
      // render fallback UI
      // NOTE: during development, react rethrows the error (so fallback UI is not shown).
      // But during prod, the error is shown.

      if (with_fake_header_and_footer) {
        // Add a fake header/footer (useful for top-level problems)
        return (
          <DefaultPageContainer>
            <Header
              no_controls={true}
            />
            <DefaultContentContainer>
              <ContainerFullWidth>
                <div className='mt-5'>
                  <ErrorBody
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                  />
                </div>
              </ContainerFullWidth>
            </DefaultContentContainer>
            <Footer/>
          </DefaultPageContainer>
        )
      }

      return (
        <div className=''>
          <ErrorBody
            error={error}
            errorInfo={errorInfo}
          />
        </div>
      )
    }

    // No error, so render children untouched
    return this.props.children
  }
}

export default ErrorBoundary
