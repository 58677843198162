import React, { useContext, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router'

import { REPORT } from '../../constants/paths.js'

import { get_utt_classes } from '../../utils/static_data_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { is_valid_report_name, normalise_name } from '../../utils/name_utils.js'
import {
  build_report,
  get_version_appropriate_existing_reports,
  save_new_report_with_existing_internal_id
} from '../../utils/report_builder_utils.js'
import { is_poweruser, is_creator } from '../../utils/user_permissions.js'
import { is_element_vertically_onscreen } from '../../utils/scroll_utils.js'
import { get_data_version } from '../../utils/domain_utils.js'

import { DEFAULT_GROUP_BY_OWNER_LEVEL } from '../../model/group_by_owner_level.js'

import ErrorBody from '../ErrorBody.js'
import ErrorModal from '../ErrorModal.js'
import { withUser } from '../UserContext.js'
import OwnerLevelControl from './OwnerLevelControl.js'
import PortfolioRollupLimitControl from './PortfolioRollupLimitControl.js'
import ExistingReportInfo from '../ExistingReportInfo.js'
import CreateReportButtonGroup from '../CreateReportButtonGroup.js'

import Spinner from '../widgets/Spinner.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import ReportNameInput from '../ReportNameInput.js'
import { Heading } from '../widgets/PaneHeader.js'
import TextLink from '../widgets/TextLink.js'
import {
  get_default_utt_landscape_report_name,
  transform_classes_to_groups
} from '../../utils/utt_utils.js'
import { build_utt_landscape_report_input } from '../../utils/report_input_utils.js'
import { check_for_existing_and_in_progress_reports } from '../../utils/choreo_utils.js'
import { get_default_utt_version } from '../../utils/user_settings_utils.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { Highlighter } from '../widgets/Highlighter.js'
import ReportBuilderNoAccessPanel from './ReportBuilderNoAccessPanel.js'
import { get_list_of_hashed_classifier_ids } from '../../utils/tracking_hashed_id_utils.js'

import s from './UTTLandscapeReportBuilder.module.scss'

const SelectedClassifierDisplay = ({selected_classifier, wrapper_ref}) => {
  const {group, name, scope} = selected_classifier

  return (
    <div ref={wrapper_ref}>
      <h6 className={cn(s.selected_classifier_label)}>
        {group}: {name}
      </h6>

      <div className={cn(s.selected_classifier_scope, 'p-3')}>
        {scope}
      </div>
    </div>
  )
}

const ContentWrapper = ({children}) => {
  return (
    <div className='m-4 w-100'>
      {children}
    </div>
  )
}

const GroupDisplay = ({selected_id, group, on_select_classifier_handler, search_input, className}) => {
  const { name: group_name, classifiers } = group
  return (
    <div className={className}>
      <Heading text={group_name} />

      <div className='d-flex flex-wrap'>
        {classifiers.map((classifier, i) => {

          const { id, name } = classifier
          const is_selected = (id === selected_id)

          return (
            <TextLink
              key={i}
              className={cn('mr-4', s.classifier_label, {[s.classifier_label__selected]: is_selected})}
              onClick={() => on_select_classifier_handler(classifier)}
            >
              <Highlighter search_words={[search_input]} text_to_highlight={name} />
            </TextLink>
          )
        })}
      </div>

    </div>
  )
}

const UTTLandscapeReportBuilder = ({ user, history }) => {

  const { user_settings } = useContext(UserSettingsContext)
  const utt_version = get_default_utt_version(user_settings)

  const [classifier_groups, set_classifier_groups] = useState(null)
  const [is_fetching_classifier_groups, set_is_fetching_classifier_groups] = useState(true)
  const [error_fetching_classifier_groups, set_error_classifier_groups] = useState(null)

  const [search_input, set_search_input] = useState('')

  const [selected_classifier, set_selected_classifier] = useState(null)

  const [data_version, set_data_version] = useState(null)
  const [existing_report, set_existing_report] = useState(null)
  const [in_progress_report, set_in_progress_report] = useState(null)
  const [is_fetching_existing_reports, set_is_fetching_existing_reports] = useState(false)
  const [error_fetching_existing_reports, set_error_fetching_existing_reports] = useState(null)

  const [auto_report_name, set_auto_report_name] = useState('')
  const [user_report_name, set_user_report_name] = useState(null)
  const [group_by_owner_level, set_group_by_owner_level] = useState(null)
  const [portfolio_roll_up_limit, set_portfolio_roll_up_limit] = useState(null)

  const [is_creating_report, set_is_creating_report] = useState(false)
  const [error_creating_report, set_error_creating_report] = useState(null)

  const [is_creating_from_existing, set_is_creating_from_existing] = useState(false)
  const [error_creating_from_existing, set_error_creating_from_existing] = useState(null)

  const scope_ref = useRef()

  const is_report_name_valid = is_valid_report_name(user_report_name || auto_report_name)
  const is_create_disabled = (!is_report_name_valid || is_fetching_existing_reports || is_creating_report || is_creating_from_existing)

  // On mount, fetch taxonomies JSON, data version
  useEffect(() => {
    get_data_version()
    .catch(err => {
      set_error_classifier_groups(err)
      throw err
    })
    .then(({data_version}) => {
      set_data_version(data_version)
    })
  }, [])

  useEffect(() => {
    set_is_fetching_classifier_groups(true)
    set_classifier_groups(null)

    get_utt_classes(utt_version)
      .then(id_to_classifier => {
        const classifier_groups = transform_classes_to_groups(id_to_classifier)
        set_classifier_groups(classifier_groups)
        set_is_fetching_classifier_groups(false)
      })
      .catch(err => {
        set_error_classifier_groups(err)
        throw err
      })
  }, [utt_version])

  // When selections change, check for existing reports
  useEffect(() => {
    if (!selected_classifier) {
      return
    }
    do_check_existing()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_classifier, portfolio_roll_up_limit, group_by_owner_level])

  if (is_fetching_classifier_groups) {
    return (
      <ContentWrapper>
        <Spinner />
      </ContentWrapper>
    )
  }

  if (error_fetching_classifier_groups) {
    return (
      <ContentWrapper>
        <ErrorBody
          error={error_fetching_classifier_groups}
          context={'fetching UTT taxonomy'}
        />
      </ContentWrapper>
    )
  }

  function on_select_classifier(classifier) {
    const default_report_name = get_default_utt_landscape_report_name(classifier)
    set_auto_report_name(default_report_name)
    set_selected_classifier(classifier)

    const is_scope_onscreen = is_element_vertically_onscreen(scope_ref.current)
    if (!is_scope_onscreen) {
      scope_ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
    }
  }

  function do_check_existing() {
    set_error_fetching_existing_reports(null)
    set_is_fetching_existing_reports(true)
    const report_input = build_new_report_input()
    check_for_existing_and_in_progress_reports(report_input)
      .catch(err => {
        set_error_fetching_existing_reports(err)
        set_existing_report(null)
        set_in_progress_report(null)
        throw err
      })
      .then(({ running_report, completed_report }) => {
        const { existing_report, in_progress_report } = get_version_appropriate_existing_reports(completed_report, running_report, data_version)
        set_existing_report(existing_report)
        set_in_progress_report(in_progress_report)
      })
      .finally(() => {
        set_is_fetching_existing_reports(false)
      })
  }

  function create_report() {
    const classifier_ids_for_tracking = get_list_of_hashed_classifier_ids([selected_classifier.classifier_id])
    track_report_builder_event(`action="build_report" report_type="utt_landscape" context="builder" classifier_ids="${classifier_ids_for_tracking}"`)
    set_is_creating_report(true)

    const report_input = build_new_report_input()
    const report_name = normalise_name(user_report_name || auto_report_name)
    build_report(report_input, report_name)
      .catch(error => {
        set_error_creating_report(error)
        set_is_creating_report(false)
        throw error
      })
      .then((external_report_id) => {
        const url = `${REPORT}/${external_report_id}`
        history.replace({ pathname: url })
      })
  }

  function build_new_report_input() {
    const report_name = normalise_name(auto_report_name)
    return build_utt_landscape_report_input({
      report_name,
      classifier: selected_classifier,
      group_by_owner_level,
      portfolio_roll_up_limit,
      utt_version,
      user
    })
  }

  function create_report_from_existing(existing_internal_report_id) {
    set_is_creating_from_existing(true)
    const report_input = build_new_report_input()
    const report_name = normalise_name(user_report_name || auto_report_name)
    save_new_report_with_existing_internal_id({existing_internal_report_id, report_name, report_input})
      .catch(err => {
        set_error_creating_from_existing(err)
        set_is_creating_from_existing(false)
        throw err
      })
      .then((external_report_id) => {
        const url = `${REPORT}/${external_report_id}`
        history.replace({ pathname: url })
      })
  }

  function split_groups_into_columns() {
    const groups = classifier_groups || []

    const column_threshold = Math.ceil(groups.length / 2)

    if (column_threshold === 0) return [[], []]

    const classifiers_left_column = classifier_groups.slice(0, column_threshold)
    const classifiers_right_column = classifier_groups.slice(column_threshold, groups.length)

    return [classifiers_left_column, classifiers_right_column]
  }

  const [classifiers_left_column, classifiers_right_column] = split_groups_into_columns()
  /*const classifiers_left_column = filter_classes_by_search_phrase(left_column, search_input)
  const classifiers_right_column = filter_classes_by_search_phrase(right_column, search_input)*/

  if (!is_creator(user)) {
    return <ReportBuilderNoAccessPanel/>
  }

  return (
    <ContentWrapper>
      <div className={s.header}>UNIVERSAL TECHNOLOGY TAXONOMY (UTT)</div>

      <div className='d-md-flex mt-3'>

        <div className='w-100 w-md-50 pr-0 pr-md-2 mb-3 mb-md-0'>

          <ClearableSearchInput
            placeholder={'Search...'}
            value={search_input}
            handle_change={set_search_input}
            auto_focus={true}
            show_clear
          />

          <div className={cn('d-flex mt-2')}>

            <div className='w-50'>
              {classifiers_left_column.map((group, idx) => {
                return (
                  <GroupDisplay
                    key={idx}
                    group={group}
                    on_select_classifier_handler={on_select_classifier}
                    selected_id={(selected_classifier || {}).id}
                    search_input={search_input}
                    className='mt-2'
                  />
                )
              })}
            </div>
            <div className='w-50'>
              {classifiers_right_column.map((group, idx) => {
                return (
                  <GroupDisplay
                    key={idx}
                    group={group}
                    on_select_classifier_handler={on_select_classifier}
                    selected_id={(selected_classifier || {}).id}
                    search_input={search_input}
                    className='mt-2'
                  />
                )
              })}
            </div>

          </div>
        </div>

        <div className='w-100 w-md-50  pl-0 pl-md-2'>
          {selected_classifier &&
            <div className={cn('sticky-top', s.right_column)}>

              <SelectedClassifierDisplay
                key={selected_classifier.id}
                selected_classifier={selected_classifier}
                wrapper_ref={scope_ref}
              />

              <div className='mt-5'>
                <ReportNameInput
                  report_name={user_report_name || auto_report_name}
                  on_change={set_user_report_name}
                  on_key_up={null}
                  className='flex-grow-1'
                />

                <OwnerLevelControl
                  selected_level={group_by_owner_level || DEFAULT_GROUP_BY_OWNER_LEVEL}
                  on_select={(group_by_owner_level) => {
                    set_group_by_owner_level(group_by_owner_level)
                  }}
                  className={cn('d-flex mt-3')}
                />

                {is_poweruser(user) &&
                  <PortfolioRollupLimitControl
                    portfolio_roll_up_limit={portfolio_roll_up_limit}
                    set_portfolio_roll_up_limit={(portfolio_roll_up_limit) => {
                      set_portfolio_roll_up_limit(portfolio_roll_up_limit)
                    }}
                    className='mt-3'
                  />
                }

                <hr/>

                {existing_report &&
                  <ExistingReportInfo
                    existing_report={existing_report}
                  />
                }

                <div className={cn('mt-3')}>

                  <CreateReportButtonGroup
                    existing_report={existing_report}
                    in_progress_report={in_progress_report}
                    disabled={is_create_disabled}
                    build_report={create_report}
                    build_from_existing={create_report_from_existing}
                  />

                  {(is_fetching_existing_reports || is_creating_report || is_creating_from_existing) &&
                    <Spinner
                      size={'sm'}
                      className={cn('ml-2')}
                    />
                  }

                </div>

                {error_creating_report &&
                  <div className={cn('mt-3')}>
                    <ErrorModal
                      error={error_creating_report}
                      context={'creating UTT report'}
                      on_hide={() => { set_error_creating_report(null)}}
                    />
                  </div>
                }

                {error_creating_from_existing &&
                  <div className={cn('mt-3')}>
                    <ErrorModal
                      error={error_creating_from_existing}
                      context={'creating UTT report from existing'}
                      on_hide={() => { set_error_creating_from_existing(null)}}
                    />
                  </div>
                }

                {error_fetching_existing_reports &&
                  <ErrorModal
                    error={error_fetching_existing_reports}
                    context={'fetching existing reports'}
                    on_hide={() => { set_error_fetching_existing_reports(null)}}
                  />
                }
              </div>

            </div>
          }
        </div>
      </div>

    </ContentWrapper>
  )
}

export default withRouter(withUser(UTTLandscapeReportBuilder))