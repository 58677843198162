export const AUTOMOTIVE_ORGSETS = 'automotive_orgsets'
export const IA_ORGSETS         = 'ia_orgsets'
export const PRODUCT_ABB     = 'abb'
export const PRODUCT_AMATDEMO   = 'amatdemo'
export const PRODUCT_AMAZON     = 'amazon'
export const PRODUCT_APPLE      = 'apple'
export const PRODUCT_ARISTOCRAT = 'aristocrat'
export const PRODUCT_ARM        = 'arm'
export const PRODUCT_ASM        = 'asm'
export const PRODUCT_AUTOMOTIVE = 'automotive'
export const PRODUCT_BAE        = 'bae'
export const PRODUCT_BAEAIR     = 'baeair'
export const PRODUCT_BAEAIRNEW  = 'baeairnew'
export const PRODUCT_BAEELECTRICAL = 'baeelectrical'
export const PRODUCT_BAELAND    = 'baeland'
export const PRODUCT_BAEMARITIME = 'baemaritime'
export const PRODUCT_BAESUSTAINABILITY = 'baesustainability'
export const PRODUCT_BANKOFAMERICA = 'bankofamerica'
export const PRODUCT_BAT        = 'bat'
export const PRODUCT_BIOPLASTICS  = 'bioplastics'
export const PRODUCT_BIOTECH4   = 'biotech4.0'
export const PRODUCT_BIOTRONIK  = 'biotronik'
export const PRODUCT_BLOCK      = 'block'
export const PRODUCT_BOREALIS   = 'borealis'
export const PRODUCT_BOSTONSCIENTIFIC = 'bostonscientific'
export const PRODUCT_CAMBRIDGE  = 'cambridge'
export const PRODUCT_CENTRICA   = 'centrica'
export const PRODUCT_CONTINENTAL   = 'continental'
export const PRODUCT_CORBION    = 'corbion'
export const PRODUCT_CORNING    = 'corning'
export const PRODUCT_CROWDSTRIKE = 'crowdstrike'
export const PRODUCT_CSDENTAL   = 'csdental'
export const PRODUCT_DAIMLER    = 'daimler'
export const PRODUCT_DROPBOX    = 'dropbox'
export const PRODUCT_ELANCO     = 'elanco'
export const PRODUCT_ELECTROLUX = 'electrolux'
export const PRODUCT_FACEBOOK   = 'facebook'
export const PRODUCT_FERRARI    = 'ferrari'
export const PRODUCT_FINANCIALSERVICES = 'financialservices'
export const PRODUCT_FINTECH    = 'fs'
export const PRODUCT_FIRMENICH  = 'firmenich'
export const PRODUCT_FMCG       = 'fmcg'
export const PRODUCT_GEA        = 'gea'
export const PRODUCT_GM         = 'gm'
export const PRODUCT_GOOGLE     = 'google'
export const PRODUCT_GPA        = 'gpa'
export const PRODUCT_HALLIBURTON = 'halliburton'
export const PRODUCT_IA         = 'ia'
export const PRODUCT_INFINEON   = 'infineon'
export const PRODUCT_INSTAGRAM  = 'instagram'
export const PRODUCT_INTEL      = 'intel'
export const PRODUCT_INTERDIGITAL = 'interdigital'
export const PRODUCT_JTI        = 'jti'
export const PRODUCT_KYNDRYL    = 'kyndryl'
export const PRODUCT_LYFT       = 'lyft'
export const PRODUCT_MERCEDESBENZ2013 = 'mercedesbenz2023'
export const PRODUCT_MESSENGER  = 'messenger'
export const PRODUCT_METAREALITYLABS = 'metarealitylabs'
export const PRODUCT_MINTEL     = 'mintel'
export const PRODUCT_MINTEL_DEMO = 'mintel_demo'
export const PRODUCT_MOTIONAL   = 'motional'
export const PRODUCT_ND         = 'nd'
export const PRODUCT_NISSAN     = 'nissan'
export const PRODUCT_NOKIA      = 'nokia'
export const PRODUCT_NVIDIA     = 'nvidia'
export const PRODUCT_NVMDEMO    = 'nvmdemo'
export const PRODUCT_OIN        = 'oin'
export const PRODUCT_REDHAT     = 'redhat'
export const PRODUCT_REDHAT2020 = 'redhat2020'
export const PRODUCT_RENISHAW   = 'renishaw'
export const PRODUCT_RICHARDSONOLIVER = 'richardsonoliver'
export const PRODUCT_ROLLSROYCE = 'rollsroyce'
export const PRODUCT_RPX        = 'rpx'
export const PRODUCT_SAAB       = 'saab'
export const PRODUCT_SABIC      = 'sabic'
export const PRODUCT_SANOFI     = 'sanofi'
export const PRODUCT_SAP        = 'sap'
export const PRODUCT_SCHOTT     = 'schott'
export const PRODUCT_SEAGATE    = 'seagate'
export const PRODUCT_SERVICENOW = 'servicenow'
export const PRODUCT_SKOLTECH   = 'skoltech'
export const PRODUCT_SUSTAINABILITY = 'sustainability'
export const PRODUCT_SWIFT      = 'swift'
export const PRODUCT_SYNOPSYS   = 'synopsys'
export const PRODUCT_TATELYLE   = 'tatelyle'
export const PRODUCT_TCH        = 'tch'
export const PRODUCT_TECHINSIGHTS = 'techinsights'
export const PRODUCT_TECHINSIGHTS_DEMO = 'ti_demo'
export const PRODUCT_THERMOFISHER = 'thermofisher'
export const PRODUCT_TOPSOE     = 'topsoe'
export const PRODUCT_TTI        = 'tti'
export const PRODUCT_TTIDEMO    = 'ttidemo'
export const PRODUCT_TUSIMPLE   = 'tusimple'
export const PRODUCT_UNILEVER   = 'unilever'
export const PRODUCT_UTC        = 'utc'
export const PRODUCT_VMWARE     = 'vmware'
export const PRODUCT_WHATSAPP   = 'whatsapp'
export const PRODUCT_WIGGIN     = 'wiggin'
export const PRODUCT_WORKDAY    = 'workday'
export const PRODUCT_XPERI      = 'xperi'

// this should probably be everything except n/d
export const PRODUCTS_WITH_ALERTS = [
  PRODUCT_ABB,
  PRODUCT_AMATDEMO,
  PRODUCT_AMAZON,
  PRODUCT_APPLE,
  PRODUCT_ARISTOCRAT,
  PRODUCT_ARM,
  PRODUCT_ASM,
  PRODUCT_AUTOMOTIVE,
  PRODUCT_BAE,
  PRODUCT_BAEAIR,
  PRODUCT_BAEAIRNEW,
  PRODUCT_BAEELECTRICAL,
  PRODUCT_BAELAND,
  PRODUCT_BAEMARITIME,
  PRODUCT_BAESUSTAINABILITY,
  PRODUCT_BANKOFAMERICA,
  PRODUCT_BAT,
  PRODUCT_BIOPLASTICS,
  PRODUCT_BIOTECH4,
  PRODUCT_BIOTRONIK,
  PRODUCT_BLOCK,
  PRODUCT_BOREALIS,
  PRODUCT_BOSTONSCIENTIFIC,
  PRODUCT_CAMBRIDGE,
  PRODUCT_CENTRICA,
  PRODUCT_CONTINENTAL,
  PRODUCT_CORBION,
  PRODUCT_CORNING,
  PRODUCT_CROWDSTRIKE,
  PRODUCT_CSDENTAL,
  PRODUCT_DAIMLER,
  PRODUCT_DROPBOX,
  PRODUCT_ELANCO,
  PRODUCT_ELECTROLUX,
  PRODUCT_FACEBOOK,
  PRODUCT_FERRARI,
  PRODUCT_FINANCIALSERVICES,
  PRODUCT_FINTECH,
  PRODUCT_FIRMENICH,
  PRODUCT_FMCG,
  PRODUCT_GEA,
  PRODUCT_GM,
  PRODUCT_GOOGLE,
  PRODUCT_GPA,
  PRODUCT_HALLIBURTON,
  PRODUCT_IA,
  PRODUCT_INFINEON,
  PRODUCT_INSTAGRAM,
  PRODUCT_INTEL,
  PRODUCT_INTERDIGITAL,
  PRODUCT_JTI,
  PRODUCT_KYNDRYL,
  PRODUCT_LYFT,
  PRODUCT_MERCEDESBENZ2013,
  PRODUCT_MESSENGER,
  PRODUCT_METAREALITYLABS,
  PRODUCT_MINTEL,
  PRODUCT_MINTEL_DEMO,
  PRODUCT_MOTIONAL,
  PRODUCT_NISSAN,
  PRODUCT_NOKIA,
  PRODUCT_NVIDIA,
  PRODUCT_NVMDEMO,
  PRODUCT_OIN,
  PRODUCT_REDHAT,
  PRODUCT_REDHAT2020,
  PRODUCT_RENISHAW,
  PRODUCT_RICHARDSONOLIVER,
  PRODUCT_ROLLSROYCE,
  PRODUCT_RPX,
  PRODUCT_SAAB,
  PRODUCT_SABIC,
  PRODUCT_SANOFI,
  PRODUCT_SAP,
  PRODUCT_SCHOTT,
  PRODUCT_SEAGATE,
  PRODUCT_SERVICENOW,
  PRODUCT_SKOLTECH,
  PRODUCT_SUSTAINABILITY,
  PRODUCT_SWIFT,
  PRODUCT_SYNOPSYS,
  PRODUCT_TATELYLE,
  PRODUCT_TCH,
  PRODUCT_TECHINSIGHTS,
  PRODUCT_TECHINSIGHTS_DEMO,
  PRODUCT_THERMOFISHER,
  PRODUCT_TOPSOE,
  PRODUCT_TTI,
  PRODUCT_TTIDEMO,
  PRODUCT_TUSIMPLE,
  PRODUCT_UNILEVER,
  PRODUCT_UTC,
  PRODUCT_VMWARE,
  PRODUCT_WHATSAPP,
  PRODUCT_WIGGIN,
  PRODUCT_WORKDAY,
  PRODUCT_XPERI,
]
