import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import { REPORT } from '../../constants/paths.js'

import { is_aistemos, is_creator, is_poweruser } from '../../utils/user_permissions.js'
import {
  is_same_classifier,
} from '../../utils/classifier_tree_utils.js'
import {
  is_valid_report_name, normalise_name,
  remove_not_allowed_chars_from_text
} from '../../utils/name_utils.js'
import { get_data_version } from '../../utils/domain_utils.js'
import {
  AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT,
  CLASSIFIER_LANDSCAPE_LIMIT,
  get_classifier_landscape_report_name,
  get_evaluation_report_input,
  build_report,
  get_version_appropriate_existing_reports,
  save_new_report_with_existing_internal_id
} from '../../utils/report_builder_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { build_classifier_landscape_report_input } from '../../utils/report_input_utils.js'
import { check_for_existing_and_in_progress_reports } from '../../utils/choreo_utils.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../../constants/report_input.js'
import { AVAILABLE_SCORE_THRESHOLDS } from '../../model/technology_basket.js'

import { withUser } from '../UserContext.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import PortfolioRollupLimitControl from './PortfolioRollupLimitControl.js'

import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import Modal from '../widgets/Modal.js'

import ClassifiersDisplay from '../classifiers/ClassifiersDisplay.js'
import ErrorBody from '../ErrorBody.js'
import ContainerFixedWidth from '../ContainerFixedWidth.js'
import ReportNameInput from '../ReportNameInput.js'
import ErrorModal from '../ErrorModal.js'

import ClassifiersBasket from '../classifiers/ClassifiersBasket.js'
import ClassifiersDisplayContainer from '../classifiers/ClassifiersDisplayContainer.js'
import ClassifiersBasketContainer from '../classifiers/ClassifiersBasketContainer.js'
import MultiLabelOptions from './MultiLabelOptions.js'
import { DEFAULT_GROUP_BY_OWNER_LEVEL } from '../../model/group_by_owner_level.js'
import OwnerLevelControl from './OwnerLevelControl.js'
import ExistingReportInfo from '../ExistingReportInfo.js'
import CreateReportButtonGroup from '../CreateReportButtonGroup.js'
import { get_classifier_groups } from '../../utils/classifier_group_utils.js'
import { save_eval_report_selected_charts_in_state } from '../../utils/report_state_utils.js'
import { SELECTED_SUBPATH } from '../../constants/viewer_paths.js'
import ReportBuilderNoAccessPanel from './ReportBuilderNoAccessPanel.js'
import ScoreThresholdControl from './ScoreThesholdControl.js'

import s from './ClassifierLandscapeReportBuilder.module.scss'
import { get_list_of_hashed_classifier_ids } from '../../utils/tracking_hashed_id_utils.js'

const ClassifierLandscapeReportBuilder = ({user, history}) => {
  const [show_spinner, set_show_spinner] = useState(true)
  const [classifier_groups, set_classifier_groups] = useState([])
  const [selected_classifiers, set_selected_classifiers] = useState([])
  const [auto_report_name, set_auto_report_name] = useState(null)
  const [user_report_name, set_user_report_name] = useState(null)
  const [group_by_owner_level, set_group_by_owner_level] = useState(null)
  const [multi_label, set_multi_label] = useState(true)
  const [score_threshold, set_score_threshold] = useState(CLASSIFIER_SCORE_THRESHOLD_DEFAULT)

  const [evaluation_classifier_id, set_evaluation_classifier_id] = useState(null)

  const [is_creating_report, set_is_creating_report] = useState(false)

  const [show_create_modal, set_show_create_modal] = useState(false)
  const [show_limit_alert, set_show_limit_alert] = useState(false)

  const [fetch_classifiers_error, set_fetch_classifiers_error] = useState(null)
  const [report_error, set_report_error] = useState(null)

  const [portfolio_roll_up_limit, set_portfolio_roll_up_limit] = useState(null)

  const [data_version, set_data_version] = useState(null)
  const [existing_report, set_existing_report] = useState(null)
  const [in_progress_report, set_in_progress_report] = useState(null)
  const [is_fetching_existing_reports, set_is_fetching_existing_reports] = useState(false)
  const [error_fetching_existing_reports, set_error_fetching_existing_reports] = useState(null)
  const [is_creating_from_existing, set_is_creating_from_existing] = useState(false)
  const [error_creating_from_existing, set_error_creating_from_existing] = useState(null)

  useEffect(() => {
    // Check for eval report input (local storage)
    const evaluation_report_input = get_evaluation_report_input(user)
    if (evaluation_report_input) {
      const {
        selected_classifiers,
        report_name,
        should_prepare_report,
        evaluation_classifier_id,
      } = evaluation_report_input

      set_selected_classifiers(selected_classifiers)
      set_auto_report_name(report_name)
      set_show_create_modal(should_prepare_report)
      set_evaluation_classifier_id(evaluation_classifier_id)
    }
  }, [user])

  // When selections change, check for existing reports
  useEffect(() => {
    do_check_existing()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_classifiers, portfolio_roll_up_limit, group_by_owner_level, multi_label])

  useEffect(() => {
    Promise.all([
      get_data_version(),
      get_classifier_groups(user, true /* include nd */)
    ])
      .catch((error) => {
        set_show_spinner(false)
        set_fetch_classifiers_error(error)
        throw error
      })
      .then(([{ data_version }, classifier_groups]) => {
        set_show_spinner(false)
        set_classifier_groups(classifier_groups)
        set_data_version(data_version)
      })
  }, [user])

  useEffect(() => {
    if (show_create_modal) {
      set_auto_report_name(get_classifier_landscape_report_name(selected_classifiers))
    }

  }, [show_create_modal, selected_classifiers])

  function select_classifier(selections) {
    set_selected_classifiers(_.uniq(selections, s => s.classifier_id))
  }

  function do_check_existing() {
    if (selected_classifiers.length === 0) {
      set_error_fetching_existing_reports(null)
      set_is_fetching_existing_reports(false)
      set_existing_report(null)
      set_in_progress_report(null)
      return
    }

    const report_input = build_new_report_input()
    check_for_existing_and_in_progress_reports(report_input)
      .catch(err => {
        set_error_fetching_existing_reports(err)
        set_existing_report(null)
        set_in_progress_report(null)
        throw err
      })
      .then(({ running_report, completed_report }) => {
        const { existing_report, in_progress_report } = get_version_appropriate_existing_reports(completed_report, running_report, data_version)
        set_existing_report(existing_report)
        set_in_progress_report(in_progress_report)
      })
      .finally(() => {
        set_is_fetching_existing_reports(false)
      })
  }

  function start_report() {
    const classifier_ids_for_tracking = selected_classifiers ? get_list_of_hashed_classifier_ids(selected_classifiers.map(item => item.classifier_id)) : null
    const classifier_ids_part = selected_classifiers ? ` classifier_ids="${classifier_ids_for_tracking}"` : ''

    track_report_builder_event(`action="build_report" report_type="classifier_landscape" context="builder" classifier_count="${selected_classifiers.length}" classifiers="yes"${classifier_ids_part}`)
    set_is_creating_report(true)

    const report_input = build_new_report_input()
    const report_name = normalise_name(user_report_name || auto_report_name)

    build_report(report_input, report_name)
      .catch(error => {
        set_report_error(error)
        set_is_creating_report(false)
        throw error
      })
      .then(external_report_id => {

        if (evaluation_classifier_id !== null) {
          return save_eval_report_selected_charts_in_state(external_report_id)
        }

        return external_report_id
      })
      .then((external_report_id) => {
        const url = `${REPORT}/${external_report_id}${evaluation_classifier_id != null ? `/${SELECTED_SUBPATH}` : ''}`
        history.replace({ pathname: url })
      })
  }

  function create_report_from_existing(existing_internal_report_id) {
    set_is_creating_from_existing(true)
    const report_input = build_new_report_input()
    const report_name = normalise_name(user_report_name || auto_report_name)
    save_new_report_with_existing_internal_id({existing_internal_report_id, report_input, report_name})
      .catch(err => {
        set_error_creating_from_existing(err)
        throw err
      })
      .then((external_report_id) => {
        const url = `${REPORT}/${external_report_id}`
        history.replace({ pathname: url })
      })
      .finally(() => {
        set_is_creating_from_existing(false)
      })
  }

  function build_new_report_input() {
    const report_name = normalise_name(auto_report_name)
    return build_classifier_landscape_report_input(
      {
        report_name,
        group_by_owner_level,
        portfolio_roll_up_limit,
        classifiers: selected_classifiers,
        multi_label,
        evaluation_classifier_id,
        threshold: score_threshold
      }
    )
  }

  function on_change_from_report_name_input(value) {
    set_user_report_name(remove_not_allowed_chars_from_text(value))
  }

  function remove_from_basket(classifier) {
      const updated_selected_classifiers = selected_classifiers.filter(selected => !is_same_classifier(classifier, selected))
      set_selected_classifiers(updated_selected_classifiers)
  }

  function on_click_from_basket() {
    set_show_create_modal(true)
  }

  if (fetch_classifiers_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={fetch_classifiers_error}
          context={'fetching classifiers'}
        />
      </ContainerFullWidth>
    )
  }

  if (show_spinner) return (
    <ContainerFixedWidth>
      <Pane className='w-100 text-center' light>
        <div>Loading classifiers...</div>
        <Spinner/>
      </Pane>
    </ContainerFixedWidth>
  )

  const has_classifiers = (classifier_groups.length > 0)

  const basket_limit = is_aistemos(user) ? AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT : CLASSIFIER_LANDSCAPE_LIMIT

  const is_multi_class = selected_classifiers.length > 1

  const is_report_name_valid = is_valid_report_name(user_report_name || auto_report_name)
  const is_create_disabled = (!is_report_name_valid || is_fetching_existing_reports || is_creating_report || is_creating_from_existing)

  return (
    <div className='d-lg-flex flex-grow-1 flex-shrink-1'>
      {show_create_modal &&
        <Modal
          on_hide={() => set_show_create_modal(false)}
          title={(
            <span>
              Classifier landscape report
              {is_creating_report && <Spinner size={'sm'} className='ml-3'/>}
            </span>
            )}
          footer={(
            <span>
              {is_fetching_existing_reports &&
                <Spinner
                  size={'sm'}
                  className={cn('ml-2')}
                />
              }

              {is_creating_from_existing &&
                <Spinner
                  size={'sm'}
                  className={cn('ml-2')}
                />
              }

              <CreateReportButtonGroup
                existing_report={existing_report}
                in_progress_report={in_progress_report}
                disabled={is_create_disabled}
                build_report={start_report}
                build_from_existing={create_report_from_existing}
              />
            </span>
          )}
        >
          <ReportNameInput
            report_name={user_report_name || auto_report_name}
            on_change={on_change_from_report_name_input}
          />

          <OwnerLevelControl
            selected_level={group_by_owner_level || DEFAULT_GROUP_BY_OWNER_LEVEL}
            on_select={set_group_by_owner_level}
            className='d-flex mb-2'
          />

          <ScoreThresholdControl
            score_threshold_objs={AVAILABLE_SCORE_THRESHOLDS}
            selected_score_threshold_value={score_threshold}
            on_change_score_threshold={set_score_threshold}
            user={user}
            className={cn('d-flex', 'mt-3')}
          />

          {is_multi_class &&
            <MultiLabelOptions
              technology_multi_label={multi_label}
              update_technology_multi_label={set_multi_label}
              className={cn('mb-2 mb-sm-1')}
            />
          }

          {is_poweruser(user) &&
            <PortfolioRollupLimitControl
              portfolio_roll_up_limit={portfolio_roll_up_limit}
              set_portfolio_roll_up_limit={set_portfolio_roll_up_limit}
              className={'mt-3'}
            />
          }

          {existing_report &&
            <ExistingReportInfo
              className={cn(s.existing_report_section, 'mt-3')}
              existing_report={existing_report}
            />
          }
        </Modal>
      }

      {error_fetching_existing_reports &&
        <ErrorModal
          error={error_fetching_existing_reports}
          context={'fetching existing reports'}
          on_hide={() => { set_error_fetching_existing_reports(null)}}
        />
      }

      {error_creating_from_existing &&
        <ErrorModal
          error={error_creating_from_existing}
          context={'creating new report from existing'}
          on_hide={() => { set_error_creating_from_existing(null)}}
        />
      }

      {report_error &&
        <ErrorModal
          on_hide={() => set_report_error(null)}
          error={report_error}
          context='creating landscape report'
        />
      }

      {show_limit_alert &&
        <Modal on_hide={() => set_show_limit_alert(false)} title={''}>
          Sorry, you have already reached the maximum allowed number ({basket_limit}) for selected classifiers.
        </Modal>
      }

      {!(is_creator(user) && has_classifiers) &&
        <ReportBuilderNoAccessPanel/>
      }

      {!has_classifiers &&
        <div>
          No classifiers available on this Cipher account.
        </div>
      }

      {has_classifiers && is_creator(user) &&
        <ClassifiersDisplayContainer className='order-lg-0'>
          <ClassifiersDisplay
            classifier_groups={classifier_groups}
            technology_basket={selected_classifiers}
            update_selections_handler={select_classifier}
            is_subscription_display={true}
            show_classifier_tree_controls={true}
            is_basket={true}

            is_selection_permitted={selected_classifiers.length < basket_limit}
            unpermitted_selection_handler={() => set_show_limit_alert(true)}
          />
        </ClassifiersDisplayContainer>
      }
      {has_classifiers && is_creator(user) &&
        <ClassifiersBasketContainer className='order-lg-1'>
          <ClassifiersBasket
            selected_classifiers={selected_classifiers}
            remove_from_basket={remove_from_basket}
            submit_basket_handler={on_click_from_basket}

            heading_text={`Selected classifiers ${selected_classifiers.length > 0 ? `(${selected_classifiers.length})`: ''}`}
            empty_basket_text={`Choose up to ${basket_limit} classifiers to build a landscape report.`}
          />

        </ClassifiersBasketContainer>
      }
    </div>
  )
}

export default withRouter(withUser(ClassifierLandscapeReportBuilder))