import React from 'react'
import cn from 'classnames'

import s from './FamilyDetailsLabel.module.scss'

export const Label = ({className, children}) => {
  return(
    <div className={cn('fs-unmask',s.label, className)}>{children}</div>
  )
}

export const LabelInline = ({className, children}) => {
  return(
    <div className={cn('fs-unmask', s.label_inline, className)}>{children}</div>
  )
}

export const Heading = ({children}) => {
  return(
    <div className={cn(['mb-1 fs-unmask', s.heading])}>{children}</div>
  )
}