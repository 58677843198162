import React from 'react'
import cn from 'classnames'

import TextLink from './TextLink.js'
import { get_patent_url } from '../../utils/patent_utils.js'

export function get_patent_links(patent_numbers) {
  return patent_numbers.map((patent_number, i) => (
    <span
      key={i}
    >
      <PatentLink
        patent_number={(patent_number || '').trim()}
        no_google={true}
        className={cn('mr-1', 'd-inline-block')}
      />
    </span>
  ))
}

export const PatentLink = ({ patent_number, patent_link_mode_id, className }) => {

  const patent_url = get_patent_url(patent_number, patent_link_mode_id)

  if (!patent_url) {
    return (
      <span className={className}>
        {patent_number}
      </span>
    )
  }

  return (
    <TextLink
      className={className}
      href={patent_url}
      target='_blank'
      element='a'
    >
      <span>{patent_number}</span>
    </TextLink>
  )
}
