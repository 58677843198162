import React from 'react'
import cn from 'classnames'
import { Modal as RSModal, ModalHeader as RSModalHeader, ModalBody as RSModalBody, ModalFooter as RSModalFooter } from 'reactstrap'

import { PrimaryButton, SecondaryButton } from './Button.js'

import s from './Modal.module.scss'

/**
 * Renders a modal.
 * Children are rendered in the body of the modal.
 */

const ModalContainer = ({className, is_open, on_hide, backdrop, contentClassName, zIndex, inner_ref, children, no_close_on_esc, centered}) => {
  if (is_open === undefined) {
    is_open = true // by default we render the modal
  }

  if (backdrop === undefined) {
    backdrop = true
  }

  return (
    <RSModal
      isOpen={is_open}
      toggle={on_hide}
      className={cn(s['modal__wide'], className)}
      fade={false}
      backdrop={backdrop}
      zIndex={zIndex || 10000}
      contentClassName={contentClassName}
      innerRef={inner_ref}
      keyboard={!no_close_on_esc}
      centered={centered}
    >
      {children}
    </RSModal>
  )
}

const ModalHeader = ({on_hide, title, headerClassName}) => {
  return (
    <RSModalHeader
      toggle={on_hide}
      // workaround to make set className on title tag (ReactStrap weirdly does not expose this)
      tag={({ className, children }) => (
        <h5
          className={cn(className, headerClassName)}
        >
          {children}
        </h5>
      )}
    >
      {title}
    </RSModalHeader>
  )
}

const ModalBody = ({className, children}) => {
  return (
    <RSModalBody className={cn(className)}>
      {children}
    </RSModalBody>
  )
}

const ModalFooter = ({footer, on_hide, close_label, on_retry, retry_label, on_clear, clear_label, no_close_button}) => {
  return (
    <RSModalFooter className='py-0 py-lg-2'>
      {footer}
      {on_clear &&
       <PrimaryButton
         onClick={() => {
           on_clear()
           on_hide()
         }}
       >
         {clear_label || 'Clear error'}
       </PrimaryButton>
      }
      {on_retry &&
       <PrimaryButton
         onClick={() => {
           on_retry()
           on_hide()
         }}
       >
         {retry_label || 'Retry'}
       </PrimaryButton>
      }
      {on_hide && !no_close_button &&
        <SecondaryButton onClick={on_hide}>{close_label || 'Close'}</SecondaryButton>
      }
    </RSModalFooter>
  )
}

const Modal = (
  {
    className,
    contentClassName,
    bodyClassName,
    headerClassName,
    is_open,
    on_hide,
    title,
    close_label,
    header,
    footer,
    backdrop,
    children,
    on_retry,
    retry_label,
    zIndex,
    inner_ref,
    no_footer,
    on_clear,
    clear_label,
    no_close_button,
    no_close_on_esc,
    centered
  }) => {
  return (
    <ModalContainer
      is_open={is_open}
      on_hide={on_hide}
      className={className}
      contentClassName={contentClassName}
      backdrop={backdrop}
      zIndex={zIndex}
      inner_ref={inner_ref}
      no_close_on_esc={no_close_on_esc}
      centered={centered}
    >
      {header}
      {!header &&
        <ModalHeader
          headerClassName={headerClassName}
          on_hide={on_hide}
          title={title}
        />
      }
      <ModalBody
        className={bodyClassName}
      >
        {children}
      </ModalBody>
      {!no_footer &&
        <ModalFooter
          footer={footer}
          no_close_button={no_close_button}
          on_hide={on_hide}
          close_label={close_label}
          on_retry={on_retry}
          retry_label={retry_label}
          on_clear={on_clear}
          clear_label={clear_label}
        />
      }
    </ModalContainer>
  )
}

export const ScrollModal = ({className, contentClassName, bodyClassName, ...other_props}) => {
  return (
    <Modal
      {...other_props}
      className={cn(s.modal_scroll_container, className)}
      contentClassName={cn(s.modal_scroll_content, contentClassName)}
      bodyClassName={cn(s.modal_scroll_body, bodyClassName)}
    />
  )
}

export default Modal