import axios, {AxiosError, AxiosPromise} from 'axios'

import {OCYPOD_BASE_URL} from '../constants/urls'
import {add_source_err_to_target_err} from './axios_utils'
import {ObjectMap} from 'Cipher'

export interface JobQueueSettings {
  timeout?: string,
  heartbeat_timeout?: string,
  expires_after?: string,
  retries?: number,
  retry_delays?: Array<string>
}
export interface PostJobRequest extends JobQueueSettings {
  input: OcypodPayloadInput,
  tags?: Array<string>
}
export enum GROUP_MODES {
  OP_DESC='operand_desc',
  OP_ASC='operand_asc',
  REPORT_TIMESTAMP='report_creation_date',
  CPC='cpc',
  ALL='all'
}

export type OcypodPayloadInput = SetTheoryReportPayload | LotNetSlideDeckPayload | MulticlassReportPayload

export interface SetTheoryReportPayload {
  operands: ObjectMap<string>,
  report_name: string,
  owner_id: string,
  formula: string,
  group_tech_mode: GROUP_MODES,
  group_portfolio_mode: GROUP_MODES,
  operable_selection: ObjectMap<boolean>,
  use_new_choreo?: boolean
}
export interface LotNetSlideDeckPayload {
  target_report_id: string,
  owner_id: string
}

export interface MulticlassReportPayload{
  report_id: string,
  owner_id: string,
  owner_name: string,
  report_name: string,
  portfolio?: Array<number>,
  technology?: Array<number>,
  territory?: Array<string>,
  superclass?: boolean
}

export function queue_ocypod_job(payload: PostJobRequest, job_queue: string): AxiosPromise {
  return axios.post(`${OCYPOD_BASE_URL}/queue/${job_queue}/job`, payload)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to queue set theory report job: ')
    })
}