import React, { useState } from 'react'

import { PrimaryButton } from './Button.js'
import { AddToClipboardIcon } from './IconSet.js'
import { InfoPopover } from './Tooltip.js'
import { WRAPPER_ELEMENT_PARENT } from '../../constants/tooltip.js'
import { is_IE11 } from '../../utils/browser_utils.js'

const BROWSER_IS_IE_11 = is_IE11()

const CopyToClipboard = ({text_to_copy, label, btn_size, className, disabled, input_ref, title, trigger_element, on_click, on_error}) => {
  const [is_success_tooltip_open, set_is_success_tooltip_open] = useState(false)

  function create_hidden_textarea(text) {
    let textarea = document.createElement('textarea')
    textarea.value = text
    // make the textarea out of viewport
    textarea.style.position = 'fixed'
    textarea.style.left = '-999999px'
    textarea.style.top = '-999999px'
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)
    return textarea
  }

  function copy_to_clipboard(text) {
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text)
    } else {
      const input = (input_ref) ? input_ref.current : create_hidden_textarea(text)
      input.focus()
      input.select()
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej()

        if (!input_ref) {
          input.remove()
        }
      })
    }
  }

  const do_copy = (text_to_copy) => {
    if (on_click) {
      on_click()
    }

    if (BROWSER_IS_IE_11) {
      window.clipboardData.setData('Text', text_to_copy)
    } else {
      copy_to_clipboard(text_to_copy)
        .then(() => {
          set_is_success_tooltip_open(true)
        })
        .catch((error) => {
          if (on_error) {
            on_error(error)
          }
        })
    }
  }

  const Trigger = trigger_element || PrimaryButton

  return (
    <>
      <Trigger size={btn_size} className={className} disabled={disabled} onClick={() => do_copy(text_to_copy)} onMouseMove={() => set_is_success_tooltip_open(false)} title={title}>{label || <AddToClipboardIcon />}</Trigger>
      {is_success_tooltip_open &&
        <InfoPopover
          no_toggler={true}
          static_trigger={<span></span>}
          visible_by_default={true}
          placement='bottom'
          is_in_modal={true}
          contentClassName='px-1 py-0'
          interactive={false}
          on_hide={() => set_is_success_tooltip_open(false)}
          wrapper={WRAPPER_ELEMENT_PARENT}
        >
          Copied!
        </InfoPopover>
      }
    </>
  )
}

export default CopyToClipboard