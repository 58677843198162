import React from 'react'
import qs from 'query-string'
import _ from 'underscore'
import $ from 'jquery'
import { ACTIONS, EVENTS } from 'react-joyride'

import { PRODUCT_AUTOMOTIVE, PRODUCT_IA } from '../constants/cipher_product_ids.js'
import {
  has_cipher_product,
  has_classifiers_edit,
} from '../utils/user_permissions.js'

import {
  NEW_REPORT_TAG,
  QUICK_ORGANISATION_SEARCH_TAG,
  CUSTOM_CLASSIFIERS_TAG,
  ALERT_SUBSCRIPTIONS_TAG,
  AUTO_INSIGHTS_TAG,
  IA_INSIGHTS_TAG,
  FEEDBACK_TAG,
  SUPPORT_AND_TRAINING_TAG,
  CLASSIFIER_REPORT_TAG, MULTI_SEARCH_TAG
} from '../model/joyride_intro_tour.js'

import { save_user_setting } from './user_settings_utils.js'
import { HAS_VIEWED_INTRO_TOUR } from '../model/user_settings.js'
import { send_error_to_sentry } from './sentry_utils.js'

/**
 * Returns array of steps for joyride tour (i.e. shown to new users).
 * https://github.com/gilbarbara/react-joyride
 */
export function get_joyride_steps(user) {
  const is_automotive = has_cipher_product(user, PRODUCT_AUTOMOTIVE)
  const is_cipher_ia = has_cipher_product(user, PRODUCT_IA)
  const is_classifiers = has_classifiers_edit(user)

  const new_report_end_phrases = [
    'enter an organisation',
    'a technology',
    'upload patents',
    ...is_automotive ? ['access the automotive technology library'] : [],
    ...is_classifiers ? ['view your own user technologies'] : []
  ]

  const new_report_end_string = new_report_end_phrases.slice(0, -1).join(', ') + ' or ' + new_report_end_phrases.slice(-1)[0] + '.'

  return [
    {
      title: 'Welcome to Cipher!',
      content: (
        <div>
          <p>Here are some steps to help you get started.</p>
        </div>
      ),
      target: '#root',
      placement: 'center'
    },

    {
      title: 'Search Cipher',
      content: (
        <div>
          <p>Explore patent data by technologies, organizations, patent numbers, or technology definition in free form.</p>
        </div>
      ),
      target: '.' + MULTI_SEARCH_TAG,
      placement: 'bottom'
    },

    {
      title: 'New report',
      content: (
        <div>
          <p>Click &#39;Start&#39; to generate reports.</p>
          <p>Choose to {new_report_end_string}</p>
        </div>
      ),
      target: '.' + NEW_REPORT_TAG,
      placement: 'bottom'
    },

    {
      title: 'Organisation search',
      content: (
        <div>
          <p>Perform a search for a company&#39;s patent portfolio in one single click.</p>
        </div>
      ),
      target: '.' + QUICK_ORGANISATION_SEARCH_TAG,
      placement: 'bottom'
    },

    ...(is_classifiers ? [{
      title: 'Classifier landscape',
      content: (
        <div>
          <p>Select a classifier and Cipher will automatically build a report.</p>
        </div>
      ),
      target: '.' + CLASSIFIER_REPORT_TAG,
      placement: 'bottom'
    }] : []),

    ...(is_classifiers ? [{
      title: 'Cipher Alerts',
      content: (
        <div>
          <p>Create alerts from your Cipher reports to monitor organisations, landscapes or lists of families.</p>
        </div>
      ),
      target: '.' + ALERT_SUBSCRIPTIONS_TAG,
      placement: 'bottom'
    }] : []),

    ...(is_classifiers ? [{
      title: 'Custom Classifiers',
      content: (
        <div>
          <p>Build Custom Classifiers by accessing the UI here.</p>
        </div>
      ),
      target: '.' + CUSTOM_CLASSIFIERS_TAG,
      placement: 'bottom'
    }] : []),

    ...(is_automotive ? [{
      title: 'Automotive Instant Insights',
      content: (
        <div>
          <p>Create a report to find out where the most established OEMs and Tier 1 suppliers are innovating.</p>
        </div>
      ),
      target: '.' + AUTO_INSIGHTS_TAG,
      placement: 'bottom'
    }] : []),

    ...(is_cipher_ia ? [{
      title: 'Industrial Automation Instant Insights',
      content: (
        <div>
          <p>Create a report to find out where the most established companies are innovating.</p>
        </div>
      ),
      target: '.' + IA_INSIGHTS_TAG,
      placement: 'bottom'
    }] : []),

    {
      title: 'Send us feedback',
      content: (
        <div>
          <p>The &#39;feedback&#39; button serves as part of our commitment to improve your Cipher experience.</p>
          <p>Use this to send us general feedback or report errors.</p>
        </div>
      ),
      target: '.' + FEEDBACK_TAG,
      placement: 'bottom'
    },

    {
      title: 'Support and training',
      content: (
        <div>
          <p>
            Schedule time with the Customer Experience team by accessing our real-time availability
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://calendly.com/cipher-support/help"
            >
              here
            </a>
            .
          </p>
        </div>
      ),
      target: '.' + SUPPORT_AND_TRAINING_TAG,
      placement: 'bottom'
    }

  ]
}

export function joyride_callback(tour, history, location, param_name) {
  const { action, type } = tour

  if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
    // Save user settings in db
    save_user_setting(HAS_VIEWED_INTRO_TOUR, true)
      .catch(error => {
        // No point in notifying user.
        // But log error in sentry.
        send_error_to_sentry(error, {})
      })

    // Make sure "show_tour" flag is not in url.
    const query_params = qs.parse(location.search)
    if (query_params[param_name]) {
      const new_query = _.omit(query_params, param_name)
      history.replace({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})
    }
  }
}

export function joyride_fix() {
  return () => {
    window.setTimeout(() => {
      // Workaround to fix intermitent Joyride not rendering bug.
      // See https://github.com/gilbarbara/react-joyride/issues/361
      const el = $('.__floater')
      el.css('visibility', 'visible')
      el.css('opacity', 1)

      // Workaround to show overlay on first step (for some reason Joyride is not doing this)
      el.parent().prev().append('<div class="joyride-overlay" style="cursor: pointer; height: 1933px; pointer-events: auto; bottom: 0px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; z-index: 10000; background-color: rgba(0, 0, 0, 0.5); mix-blend-mode: hard-light;"></div>')

    }, 1000)
  }
}