import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { send_error_to_sentry } from '../../utils/sentry_utils'
import { Label } from '../family_view/FamilyDetailsLabel.js'
import { withUser } from '../UserContext.js'
import {
  add_tag_value,
  contains_tag_value,
  fetch_executable_tags_for_families,
  Tag,
  add_tag_value_for_families,
  untag_families,
  remove_tag_value,
  do_nothing_func,
  tag_families,
  is_editable,
  is_single_choice,
  is_multi_choice,
  fetch_viewing_tags_for_families,
} from './family_tag_utils'
import { can_tag_families } from '../../utils/user_permissions.js'
import TextLink from '../widgets/TextLink.js'
import { CogIcon, TagsIcon, TagsOutlineIcon } from '../widgets/IconSet.js'
import { TagsDisplay } from './TagsDisplay'

import { FamilyTagSelector } from './FamilyTagSelector'
import Spinner from '../widgets/Spinner'
import FamilyTagShapeButton from './FamilyTagShapeDisplay'

import s from './FamilyTags.module.scss'


interface FamilyTagProps {
  user: any,
  heading: string,
  className: string,
  family_ids: Array<number>,
  user_tags: Array<Tag>,
  error_fetching_custom_tags: string,
  search_input: string,
  set_search_input: Function,
  is_advanced_mode_on: boolean,
  on_toggle_advanced_mode: Function,
  is_multiple_tagging: boolean,
  on_close_selector: Function,
  notify_tag_change?: Function,
  show_tag_mngt_ui: boolean,
  set_show_tag_mngt_ui: Function,
}

const FamilyTags = (
  {
    user,
    heading,
    className,
    family_ids,
    user_tags,
    error_fetching_custom_tags,
    search_input,
    set_search_input,
    is_advanced_mode_on,
    on_toggle_advanced_mode,
    is_multiple_tagging,
    on_close_selector,
    notify_tag_change,
    show_tag_mngt_ui,
    set_show_tag_mngt_ui
  }: FamilyTagProps) => {

  const tag_button_ref = React.createRef()

  const [selected_tags, set_selected_tags] = useState<Array<Tag>>([])
  const [read_only_tags, set_read_only_tags] = useState<Array<Tag>>([])
  const [selected_families, set_selected_families] = useState<Array<number>>([])
  const [input_families_toggle, set_input_families_toggle] = useState(false)
  const [fetch_family_tags_error, set_fetch_family_tags_error] = useState(null)
  const [show_spinner, set_show_spinner] = useState(false)
  const [error_tagging, set_error_tagging] = useState(null)
  const [is_changing_tags, set_is_changing_tags] = useState(false)
  const can_user_tag_families = can_tag_families(user)


  useEffect(() => {
    let did_cancel = false
    if (!did_cancel) {

      const are_equal = JSON.stringify(family_ids) === JSON.stringify(selected_families)
      set_selected_families(family_ids)
      if (!are_equal) {
        set_input_families_toggle(!input_families_toggle)
      }
    }
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family_ids])

  useEffect(() => {
    let did_cancel = false
    if (!did_cancel) {
      set_show_spinner(true)
      set_selected_tags(show_spinner ? [] : selected_tags)
      Promise.all([fetch_executable_tags_for_families(family_ids), fetch_viewing_tags_for_families(family_ids)])
        .catch(error => {
          if (!did_cancel) {
            set_show_spinner(false)
            set_fetch_family_tags_error(error)
          }
          throw error
        })
        .then(([removable_tags_for_families, read_only_tags_for_families]: any) => {
          if (!did_cancel) {
            set_fetch_family_tags_error(null)
            const removable_tags = _.flatten(removable_tags_for_families || [])
            const read_only_tags = _.flatten(read_only_tags_for_families || [])
            set_selected_tags(removable_tags)
            set_read_only_tags(read_only_tags)
            set_show_spinner(false)
          }
        })
    }
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input_families_toggle])


  function remove_selection(selected_tag: Tag) {
    untag_families(family_ids, selected_tag)
      .then(() => {
        set_error_tagging(null)
        set_is_changing_tags(false)
        const new_selection: Array<Tag> = remove_tag_value(selected_tags, selected_tag)
        set_selected_tags(new_selection)
        notify_change_to_parent(family_ids, new_selection, false)
      })
      .catch((err) => {
        set_is_changing_tags(false)
        set_error_tagging(err)
        send_error_to_sentry(err, {})
      })
  }

  function change_selection(selected_tag: Tag) {
    const unselected_tags: Array<Tag> = selected_tags.filter(tag => (selected_tag.id === tag.id))
    if (!_.isEmpty(unselected_tags)) {
      const unselected_tag = unselected_tags[0]
      untag_families(family_ids, unselected_tag)
        .then(() => {
          set_error_tagging(null)
          tag_families(family_ids, selected_tag)
            .then(() => {
              set_is_changing_tags(false)
              set_error_tagging(null)
              const new_selection: Array<Tag> = add_tag_value(selected_tags, selected_tag)
              set_selected_tags(new_selection)
              notify_change_to_parent(family_ids, new_selection, false)
            })
            .catch((err: any) => {
              set_is_changing_tags(false)
              set_error_tagging(err)
              send_error_to_sentry(err, {})
            })
        })
        .catch((err) => {
          set_is_changing_tags(false)
          set_error_tagging(err)
          send_error_to_sentry(err, {})
        })
    } else {
      tag_families(family_ids, selected_tag)
        .then(() => {
          set_is_changing_tags(false)
          set_error_tagging(null)
          const new_selection: Array<Tag> = add_tag_value(selected_tags, selected_tag)
          set_selected_tags(new_selection)
          notify_change_to_parent(family_ids, new_selection, false)
        })
        .catch((err: any) => {
          set_is_changing_tags(false)
          set_error_tagging(err)
          send_error_to_sentry(err, {})
        })
    }
  }

  function add_selection(selected_tag: Tag) {
    tag_families(family_ids, selected_tag)
      .then(() => {
        set_is_changing_tags(false)
        set_error_tagging(null)
        const new_selection: Array<Tag> = add_tag_value(selected_tags, selected_tag)
        set_selected_tags(new_selection)
        notify_change_to_parent(family_ids, new_selection, false)
      })
      .catch((err: any) => {
        set_is_changing_tags(false)
        set_error_tagging(err)
        send_error_to_sentry(err, {})
      })
  }

  function add_new_tag_value(selected_tag: Tag, is_new: boolean) {
    add_tag_value_for_families(selected_families, selected_tag)
      .then((results: any) => {
        const {new_tag_value_id} = results.data
        set_is_changing_tags(false)
        set_error_tagging(null)
        selected_tag.values[0].id = new_tag_value_id
        const new_selection: Array<Tag> = add_tag_value(selected_tags, selected_tag)
        set_selected_tags(new_selection)
        notify_change_to_parent(family_ids, new_selection, is_new)
        return new_tag_value_id
      })
      .catch((err: any) => {
        set_is_changing_tags(false)
        set_error_tagging(err)
        send_error_to_sentry(err, {})
      })
  }

  function on_tag_change(selected_tag: Tag): any {
    set_is_changing_tags(true)
    const is_multiple_choice_tag = is_multi_choice(selected_tag)
    const is_single_choice_tag = is_single_choice(selected_tag)
    const is_editable_tag = is_editable(selected_tag)
    const already_selected = is_tag_value_selected(selected_tag)
    const is_new = selected_tag.values.filter((tag_value) => tag_value.id === -1).length > 0

    if (is_editable_tag && is_new) {
      add_new_tag_value(selected_tag, is_new)
    } else {
      if (already_selected && (is_multiple_choice_tag || is_editable_tag)) {
        remove_selection(selected_tag)
      }
      if (is_single_choice_tag && !already_selected) {
        change_selection(selected_tag)
      }
      if ((is_multiple_choice_tag || is_editable_tag) && !already_selected) {
        add_selection(selected_tag)
      }
      if ((already_selected && is_single_choice_tag)) {
        set_is_changing_tags(false)
      }
    }
  }

  function on_remove_tag(tag_to_remove: Tag): any {
    set_is_changing_tags(true)
    const selected_tag_to_remove = selected_tags
      .filter(tag => (tag.id === tag_to_remove.id))[0]
    untag_families(family_ids, selected_tag_to_remove)
      .then(() => {
        set_is_changing_tags(false)
        set_error_tagging(null)
        const new_selection: Array<Tag> = selected_tags.filter((tag: Tag) => (tag.id !== tag_to_remove.id))
        set_selected_tags(new_selection)
        notify_change_to_parent(family_ids, new_selection, false)
      })
      .catch((err) => {
        set_is_changing_tags(false)
        set_error_tagging(err)
        send_error_to_sentry(err, {})
      })
  }

  function is_tag_value_selected(tag: Tag): boolean {
    const filtered_tags = selected_tags.filter(s_tag => contains_tag_value(s_tag, tag))
    return filtered_tags.length > 0
  }

  function change_advanced_mode() {
    if (on_toggle_advanced_mode) {
      on_toggle_advanced_mode()
    }
  }

  function notify_change_to_parent(family_ids: Array<number>, new_selection: Array<Tag>, new_tag_value: boolean) {
    if (notify_tag_change) {
      notify_tag_change(family_ids, new_selection, new_tag_value)
    }
  }


  const any_data_fetch_error = error_fetching_custom_tags || fetch_family_tags_error || error_tagging
  const tags_for_families = _.uniq([...selected_tags, ...read_only_tags], false, (tag) => (tag.id))

  function render(): JSX.Element {
    return (
      <div className={className}>
        {show_spinner &&
          <span className={'d-flex align-items-center'}>
            <Spinner size='sm'/>
            {!is_multiple_tagging &&
              <span>Fetching data ...</span>
            }
          </span>
        }
        {any_data_fetch_error && !show_spinner &&
          <span className='d-block'>
            {error_fetching_custom_tags && <span>Error fetching family tags</span>}
            {fetch_family_tags_error && <span>Error fetching tags for this family</span>}
            {error_tagging && <span>Error tagging/untagging this family</span>}
          </span>
        }
        {!is_multiple_tagging && !show_spinner && !any_data_fetch_error && !show_tag_mngt_ui &&
          <div>
            <div className={cn('d-flex justify-content-between mb-2')}>
              {!show_spinner &&
                <Label className={''}>{heading || 'Tags'}</Label>
              }
              {can_user_tag_families &&
                <span>
                  {/*// @ts-expect-error*/}
                  <TextLink
                    onClick={() => set_show_tag_mngt_ui(true)}
                    disable={is_multiple_tagging}
                    className={'mr-2'}
                    title={'organise tags'}
                  >
                     <CogIcon/>
                  </TextLink>
                  {/*// @ts-expect-error*/}
                  <TextLink
                    onClick={change_advanced_mode}
                    disable={is_multiple_tagging}
                    title={'see tags'}
                  >
                    {is_advanced_mode_on ? <TagsIcon/> : <TagsOutlineIcon/>}
                  </TextLink>
                </span>
              }
            </div>
            <div className={cn('flex-wrap w-100', s.content)}>
              {tags_for_families.length === 0 &&
                <span>
                  None
                </span>
              }
              {tags_for_families.length > 0 &&
                tags_for_families.map((tag: Tag, i) => {
                  const is_clickable_tag: boolean = _.contains(selected_tags, tag)

                  return (
                    <FamilyTagShapeButton
                      key={i}
                      className={''}
                      ref={tag_button_ref}
                      tag={tag}
                      family_id={family_ids[0]}
                      on_close={() => on_remove_tag(tag)}
                      disabled={!can_user_tag_families || !is_clickable_tag}
                    />
                  )
                })
              }
            </div>
            {can_user_tag_families && is_advanced_mode_on &&
              <TagsDisplay
                on_tag_select={on_tag_change}
                is_tag_selected={is_tag_value_selected}
                user_tags={user_tags || []}
                on_remove_tag={on_remove_tag}
                search_input={search_input}
                set_search_input={set_search_input}
                is_changing={false}
              />
            }
          </div>
        }
        {is_multiple_tagging && !show_spinner && !any_data_fetch_error &&
          can_user_tag_families && !is_advanced_mode_on && !show_tag_mngt_ui &&
          <FamilyTagSelector
            on_tag_select={on_tag_change}
            is_tag_selected={is_tag_value_selected}
            is_changing_tags={is_changing_tags}
            user_tags={user_tags || []}
            on_remove_tag={on_remove_tag}
            search_input={search_input}
            set_search_input={set_search_input}
            className={''}
            disabled={family_ids.length === 0}
            on_close={on_close_selector ? on_close_selector : do_nothing_func}
          />
        }
      </div>
    )
  }

  return render()
}

export default withUser(FamilyTags)