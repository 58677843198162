import axios from 'axios'

import { FEEDBACK_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { get_report_state_url, save_report_state } from './viewer_utils.js'

export function get_url_with_user_state(params) {
  const {external_report_id, user_state, location, idp_meta} = params

  if (!external_report_id) {
    return new Promise((resolve) => {
      resolve({url: null})
    })
  } else {
    return save_report_state({external_report_id, user_state})
      .catch(err => {
        const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to generate user state url for feedback email: ')
        throw wrapped_err
      })
      .then(state_id => {
        return {url: get_report_state_url({location, external_report_id, state_id, idp_meta})}
      })
  }
}


export function send_feedback_email({url, category, subject, comment, additional_comment, user_state_url}) {
  const data = { url, category, subject, comment, additional_comment, user_state_url }

  return axios.post(`${FEEDBACK_BASE_URL}`, data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to send email via email provider: ')
      throw wrapped_err
    })
}