import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import _ from 'underscore'

import ContainerFullWidth from '../../ContainerFullWidth.js'
import StreamsDisplay from './StreamsDisplay.js'
import { BUILD_REPORT, CREATE_REPORT } from '../../../constants/paths.js'
import {
  GOOGLE_VALUATION_REPORT_STREAM_ID,
  ID_TO_REPORT_STREAM,
  ND_REPORT_STREAM_ID,
  NEW_FILINGS_IN_TECH_STREAM_ID,
  NO_SWITCH_TO_ADVANCED_BUILDER_REPORT_STREAMS,
  SIMILAR_PORTFOLIOS_REPORT_STREAM_ID,
  TECH_LANDSCAPE_STREAM_ID,
  TECH_LANDSCAPE_STREAMS,
  TECH_PARTITIONING_REPORT_STREAMS
} from './report_streams.js'
import {
  ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION,
  ACCESSION_DATE_CUT_OFF_OPTIONS,
  ALL_LANDSCAPE_TECH_TYPE_OPTIONS,
  ALL_PORTFOLIO_SEARCH_OPTIONS,
  PORTFOLIO_UPLOAD_OPTIONS,
  STEP_INTRO,
  STEP_PORTFOLIO,
  STEP_PORTFOLIO_BOOLEAN_SEARCH,
  STEP_PORTFOLIO_KNN_SEARCH,
  STEP_REPORT_INPUT_PARAMS,
  STEP_TECHNOLOGY,
  STEP_TECHNOLOGY_BINARY_CLASS,
  STEP_TECHNOLOGY_SPLIT,
  STEP_TECHNOLOGY_UTT
} from './builder_wizard.js'
import ProgressDisplay from './ProgressDisplay.js'
import {
  CACHED_REPORT_THRESHOLD_STRICT,
  existing_or_new_report,
  get_portfolio_basket_orgs_total_size,
  PORTFOLIO_SIZE_NO_PASS_THRESHOLD,
  PORTFOLIO_SIZE_WARNING_THRESHOLD,
} from '../../../utils/report_builder_utils.js'
import { has_utt, is_poweruser, is_creator } from '../../../utils/user_permissions.js'
import { withUser } from '../../UserContext.js'
import Spinner from '../../widgets/Spinner.js'
import PortfolioStep from './PortfolioStep.js'
import TechnologyPartitioningStep from './TechnologyPartitioningStep.js'
import TechnologyStep from './TechnologyStep.js'
import ErrorModal from '../../ErrorModal.js'
import {
  get_accession_date_portfolio_item,
  get_report_name,
  get_stream_technology_partitioning,
  get_stream_url,
  is_tech_landscape_stream,
  is_utt_among_classifiers,
  save_stream_input,
  should_select_technology_partitioning,
  STREAM_PARAMS_DELIMITER
} from './builder_wizard_utils.js'
import { UserSettingsContext } from '../../UserSettingsContext.js'
import { get_default_utt_version, should_include_unrelated_technologies } from '../../../utils/user_settings_utils.js'
import { get_utt_classes } from '../../../utils/static_data_utils.js'
import { transform_classes_to_classifier_tree } from '../../../utils/utt_utils.js'
import { PRODUCT_GOOGLE, PRODUCT_ND } from '../../../constants/cipher_product_ids.js'
import ReportInputParamsStep from './ReportInputParamsStep.js'
import { track_report_builder_event, track_visit_event } from '../../../utils/tracking_utils.js'
import ReportNameAndControls from './ReportNameAndControls.js'
import {
  get_classifier_group_by_id,
  get_classifier_groups,
  get_group_classifiers
} from '../../../utils/classifier_group_utils.js'
import { generate_input_id } from '../../../utils/knn_search.js'
import { is_array_non_empty_non_null, sum } from '../../../utils/utils.js'
import { ReportBuilderBasketStopMessage, ReportBuilderBasketWarning } from '../ReportBuilderBasketWarning.js'
import { TECH_PARTITIONING_TYPE_CLASSIFIER } from '../../../model/technology_basket.js'
import {
  get_negatives_processing,
  NEGATIVES_PROCESSING_EXCLUDE,
  NEGATIVES_PROCESSING_INCLUDE
} from '../../../model/negatives_processing.js'
import { DEFAULT_GROUP_BY_OWNER_LEVEL } from '../../../model/group_by_owner_level.js'
import { DEFAULT_PORTFOLIO_ROLLUP_LIMIT } from '../../../model/portfolio_rollup_limits.js'
import { get_bool_search_items_sizes } from '../../../utils/patent_family_list_utils.js'
import { save_report_state } from '../../../utils/report_state_utils.js'
import ReportBuilderNoAccessPanel from '../ReportBuilderNoAccessPanel.js'
import { get_list_of_hashed_classifier_ids } from '../../../utils/tracking_hashed_id_utils.js'

const BuilderWizard = ({user, match, history, location}) => {

  const { stream_id: stream_id_from_url='', stream_params='' } = match.params || {}

  const { user_settings } = useContext(UserSettingsContext)

  const user_has_utt = has_utt(user)
  const user_is_poweruser = is_poweruser(user)

  const utt_version = get_default_utt_version(user_settings)
  const default_negatives_processing = get_negatives_processing({
    type: should_include_unrelated_technologies(user_settings) ? NEGATIVES_PROCESSING_INCLUDE : NEGATIVES_PROCESSING_EXCLUDE,
    utt_version
  })
  const default_multi_label = false

  const [is_initial_fetch, set_is_initial_fetch] = useState(true)
  const [classifier_groups, set_classifier_groups] = useState(null)
  const [utt_classes_group, set_utt_classes_group] = useState(null)

  const [selected_stream_id, set_selected_stream_id] = useState(null)
  const [steps, set_steps] = useState([STEP_INTRO])
  const [portfolio_type, set_portfolio_type] = useState(null)
  const [landscape_tech_type, set_landscape_tech_type] = useState(null)
  const [selected_classifier_group_id, set_selected_classifier_group_id] = useState(null)
  const [is_ignore_cached_reports, set_is_ignore_cached_reports] = useState(false)

  const [report_name, set_report_name] = useState(null)
  const [portfolios, set_portfolios] = useState(null)
  const [classifiers, set_classifiers] = useState(null)
  const [technology_partitioning, set_technology_partitioning] = useState(null)
  const [negatives_processing, set_negatives_processing] = useState(default_negatives_processing)
  const [multi_label, set_multi_label] = useState(default_multi_label)
  const [group_by_owner_level, set_group_by_owner_level] = useState(DEFAULT_GROUP_BY_OWNER_LEVEL)
  const [portfolio_roll_up_limit, set_portfolio_roll_up_limit] = useState(DEFAULT_PORTFOLIO_ROLLUP_LIMIT)

  const [portfolio_size_total, set_portfolio_size_total] = useState(null)
  const [start_report, set_start_report] = useState(false)
  const [report_id, set_report_id] = useState(null)
  const [report_build_error, set_report_build_error] = useState(null)

  const is_report_big = portfolio_size_total > PORTFOLIO_SIZE_WARNING_THRESHOLD
  const is_report_too_big = _.contains([TECH_PARTITIONING_TYPE_CLASSIFIER], (technology_partitioning || {}).type ) ? false : (portfolio_size_total > PORTFOLIO_SIZE_NO_PASS_THRESHOLD)
  const user_has_classifiers = (classifier_groups || []).length > 0

  const report_name_to_display = get_report_name({
    stream_id: selected_stream_id,
    portfolio_type,

    portfolios,
    technology_partitioning,
    classifiers,
    classifier_groups,
    user_report_name: report_name,
  })

  const on_progress_reset = useCallback(() => {
    set_selected_stream_id(null)
    set_steps([STEP_INTRO])

    set_portfolios(null)
    set_selected_classifier_group_id(null)
    set_classifiers(null)
    set_technology_partitioning(null)
    set_report_name(null)
    set_negatives_processing(default_negatives_processing)
    set_multi_label(default_multi_label)
    set_landscape_tech_type(null)

    history.replace({pathname: CREATE_REPORT})
  }, [history, default_negatives_processing, default_multi_label])

  function on_progress_reset_from_portfolio() {
    set_selected_classifier_group_id(null)
    set_classifiers(null)
    set_technology_partitioning(null)
    set_negatives_processing(default_negatives_processing)
    set_multi_label(default_multi_label)

    const updated_steps = [...steps].slice(0, steps.indexOf(STEP_PORTFOLIO) + 1)
    set_steps(updated_steps)
  }

  function on_progress_reset_from_technology() {
    set_multi_label(default_multi_label)
    set_group_by_owner_level(DEFAULT_GROUP_BY_OWNER_LEVEL)
    set_portfolio_roll_up_limit(DEFAULT_PORTFOLIO_ROLLUP_LIMIT)

    const updated_steps = [...steps].slice(0, steps.indexOf(STEP_TECHNOLOGY) + 1)
    set_steps(updated_steps)
  }

  const get_utt = useCallback(() => {
    if (!user_has_utt) return Promise.resolve(null)

    return get_utt_classes(utt_version)
  }, [user_has_utt, utt_version])

  const get_portfolios_to_cluster = useCallback(({portfolios, stream_id, is_report_big}) => {
    if (is_report_big) return []

    return (stream_id === SIMILAR_PORTFOLIOS_REPORT_STREAM_ID) ? [0] : (portfolios || []).map((item, i) => (i))
  }, [])

  useEffect(() => {
    document.title = 'Cipher: Report wizard'
    track_visit_event('page="wizard"')
  }, [location, history])

  useEffect(() => {
    let did_cancel = false
    set_is_initial_fetch(true)

    Promise.all([
      get_classifier_groups(user, true),
      get_utt()
    ])
      .then(([classifier_groups_response, utt]) => {
        if (!did_cancel) {
          set_classifier_groups(classifier_groups_response || [])
          set_utt_classes_group(utt != null ? transform_classes_to_classifier_tree(utt) : null)
          set_is_initial_fetch(false)
        }
      })
      .catch(() => {
        if (!did_cancel) {
          set_is_initial_fetch(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [user, get_utt])

  const read_stream_url_params = useCallback((stream, stream_params) => {

    if (!stream) return {}

    const {id} = stream || {}

    if (is_tech_landscape_stream(id) && !(user_has_classifiers || user_has_utt)) return {}

    const default_tech_type = user_has_utt ? STEP_TECHNOLOGY_UTT : STEP_TECHNOLOGY_BINARY_CLASS
    const user_can_select_tech_type = user_has_classifiers && user_has_utt

    if (id === TECH_LANDSCAPE_STREAM_ID) {
      const tech_type_from_url = _.contains(ALL_LANDSCAPE_TECH_TYPE_OPTIONS, stream_params) && user_can_select_tech_type ? stream_params : null
      return {tech_type_param: tech_type_from_url || default_tech_type}
    }

    if (id === NEW_FILINGS_IN_TECH_STREAM_ID) {
      const params_array = stream_params.split(STREAM_PARAMS_DELIMITER)

      return params_array.reduce((acc, param) => {
        if (_.contains(ALL_LANDSCAPE_TECH_TYPE_OPTIONS, param)) {
          return user_can_select_tech_type ? {...acc, tech_type_param: param} : acc
        }

        if (_.contains(ACCESSION_DATE_CUT_OFF_OPTIONS, param * 1)) {
          return {...acc, accession_date_param: param}
        }

        return acc
      }, { portfolio_type_param: STEP_PORTFOLIO_BOOLEAN_SEARCH, accession_date_param: ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION, tech_type_param: default_tech_type })
    }

    return _.contains(ALL_PORTFOLIO_SEARCH_OPTIONS, stream_params) ? {portfolio_type_param: stream_params} : {}
  }, [user_has_classifiers, user_has_utt])

  useEffect(() => {
    if (is_initial_fetch) return

    if (selected_stream_id && !stream_id_from_url) {
      on_progress_reset()
      return
    }

    if (!stream_id_from_url || selected_stream_id) return

    const stream = ID_TO_REPORT_STREAM[stream_id_from_url]

    const {short_label, label} = stream || {}
    document.title = `Cipher: Report wizard${short_label || label ? ` - ${short_label || label}` : ''}`

    const { tech_type_param, portfolio_type_param, accession_date_param } = read_stream_url_params(stream, stream_params)

    if (!stream || (!tech_type_param && !portfolio_type_param)) {
      on_progress_reset()
      return
    }

    set_selected_stream_id(stream_id_from_url)

    const { portfolios } = stream

    set_landscape_tech_type(tech_type_param)

    const {id: default_portfolio_id} = (portfolios || [])[0] || {}
    set_portfolio_type(portfolio_type_param || default_portfolio_id)

    if (stream_id_from_url === NEW_FILINGS_IN_TECH_STREAM_ID) {
      const portfolio = get_accession_date_portfolio_item(accession_date_param)
      set_portfolios([portfolio])
    }

    const next_step = _.contains(TECH_LANDSCAPE_STREAMS, stream_id_from_url) ? STEP_TECHNOLOGY : STEP_PORTFOLIO
    set_steps([...steps, next_step])

  }, [is_initial_fetch, stream_id_from_url, stream_params, steps, selected_stream_id, on_progress_reset, read_stream_url_params])

  useEffect(() => {

    if ((portfolios || []).length === 0) {
      set_portfolio_size_total(0)
      return
    }

    if (_.contains([...PORTFOLIO_UPLOAD_OPTIONS, STEP_PORTFOLIO_KNN_SEARCH], portfolio_type)) {
      set_portfolio_size_total(sum(portfolios.map(item => (item.pat_fam_ids || []).length)))
      return
    }

    if (STEP_PORTFOLIO_BOOLEAN_SEARCH === portfolio_type) {
      const search_terms = portfolios.map(item => (item.search_term))
      get_bool_search_items_sizes(search_terms)
        .catch(error => {
          throw error
        })
        .then(search_terms_to_sizes => {
          const sizes = search_terms.map(item => (search_terms_to_sizes || {})[item] || 0)

          set_portfolio_size_total(sum(sizes))
        })
      return
    }

    get_portfolio_basket_orgs_total_size(portfolios)
      .catch(error => {
        throw error
      })
      .then(organisations_total_size => {
        set_portfolio_size_total(organisations_total_size)
      })

  }, [portfolios, portfolio_type])

  useEffect(() => {
    if (!start_report) return

    const {get_report_input, cache_threshold: stream_cache_threshold, auto_name, chart_set} = ID_TO_REPORT_STREAM[selected_stream_id] || {}

    if (!get_report_input) {
      set_start_report(false)
      return
    }

    const report_input = get_report_input({
      report_name: auto_name || selected_stream_id, //generic report name for caching
      portfolios,
      portfolios_to_cluster: get_portfolios_to_cluster({portfolios, is_report_big, stream_id: selected_stream_id}),
      classifiers,
      technology_partitioning,
      multi_label,
      negatives_processing: is_report_big ? {type: NEGATIVES_PROCESSING_EXCLUDE} : negatives_processing,
      group_by_owner_level,
      portfolio_roll_up_limit,
      utt_version,
      has_utt: user_has_utt
    })

    const {report_type} = report_input || {}

    const classifier_ids_for_tracking = classifiers ? get_list_of_hashed_classifier_ids(classifiers.map(item => item.classifier_id)) : null
    const classifier_ids_part = classifiers ? ` classifier_ids="${classifier_ids_for_tracking}"` : ''

    track_report_builder_event(`action="build_report" report_type="${report_type}" stream_id="${selected_stream_id}" classifiers="${classifiers ? 'yes' : 'no'}" context="wizard"${classifier_ids_part}`)

    const cache_threshold = is_ignore_cached_reports ? null : stream_cache_threshold || CACHED_REPORT_THRESHOLD_STRICT

    existing_or_new_report(report_input, report_name_to_display, cache_threshold)
      .then(external_report_id => {

        if (chart_set != null) {
          const {items: chart_set_items} = chart_set || {}

          const main_items = (chart_set_items || []).map(item => ({...item, is_temp: false}))

          return save_report_state(external_report_id, {main_items})
        }

        return external_report_id
      })
      .then(external_report_id => {
        set_report_id(external_report_id)
        set_start_report(false)
      })
      .catch((error) => {
        set_report_build_error(error)
        set_start_report(false)
      })

  }, [
    start_report,
    selected_stream_id,
    portfolio_type,
    report_name,
    portfolios,
    classifiers,
    technology_partitioning,
    multi_label,
    negatives_processing,
    group_by_owner_level,
    portfolio_roll_up_limit,
    utt_version,
    user_has_utt,
    report_name_to_display,
    is_report_big,
    is_ignore_cached_reports,
    get_portfolios_to_cluster
  ])

  function on_stream_select({stream_id, portfolio_type, landscape_tech_type, accession_date_threshold}) {
    const url = get_stream_url(stream_id, {portfolio_type, landscape_tech_type, accession_date_threshold})
    history.push({pathname: url})
  }

  function on_select_portfolios(items, can_complete) {
    set_portfolios(items)

    if ((items || []).length === 0) {
      set_steps(steps.slice(0, steps.indexOf(STEP_PORTFOLIO) + 1))
      set_technology_partitioning(null)
      set_classifiers(null)
    }

    if (can_complete) {
      on_complete_select_portfolios_step()
    }
  }

  function on_select_classifiers(items) {
    set_classifiers(items)

    const is_tech_landscape = _.contains(steps, STEP_TECHNOLOGY)
    if (((items || []).length === 0) && is_tech_landscape) {
      set_technology_partitioning(null)
      set_steps(steps.slice(0, steps.indexOf(STEP_TECHNOLOGY) + 1))
      set_multi_label(default_multi_label)
      set_group_by_owner_level(DEFAULT_GROUP_BY_OWNER_LEVEL)
      set_portfolio_roll_up_limit(DEFAULT_PORTFOLIO_ROLLUP_LIMIT)
    }
  }

  function on_select_technology_split(selected_technology_split) {
    const {type: selected_type} = selected_technology_split || {}
    const {type} = technology_partitioning || {}

    if (selected_type !== type) {
      set_classifiers(null)
    }

    set_technology_partitioning(selected_technology_split)
  }

  function on_complete_select_portfolios_step() {
    const select_technology_partition_enabled = should_select_technology_partitioning({stream_id: selected_stream_id, user_has_utt, user_has_classifiers})

    if (selected_stream_id === GOOGLE_VALUATION_REPORT_STREAM_ID) {
      set_classifiers(get_group_classifiers(get_classifier_group_by_id(classifier_groups, PRODUCT_GOOGLE)))
    }

    set_technology_partitioning(get_stream_technology_partitioning({stream_id: selected_stream_id, user_has_classifiers, user_has_utt, utt_version}))

    if (!select_technology_partition_enabled) {
      set_start_report(true)
      return
    }

    const is_nd_report_stream = selected_stream_id === ND_REPORT_STREAM_ID

    if (is_nd_report_stream) {
      set_classifiers(get_group_classifiers(get_classifier_group_by_id(classifier_groups, PRODUCT_ND)))
    }

    set_steps([...steps, is_nd_report_stream ? STEP_REPORT_INPUT_PARAMS : STEP_TECHNOLOGY_SPLIT])
  }

  function on_complete_technology_partitioning_step() {
    if (_.contains(TECH_PARTITIONING_REPORT_STREAMS, selected_stream_id)) {
      set_start_report(true)
    }
  }

  function on_complete_technology_step(params) {
    const {portfolios} = params || {}

    if (portfolios) {
      set_portfolios(portfolios)
    }

    if (is_step_final(STEP_TECHNOLOGY)) {
      set_start_report(true)
      return
    }

    set_steps([...steps, STEP_REPORT_INPUT_PARAMS])
  }

  function on_update_negatives_processing(type) {
    set_negatives_processing(get_negatives_processing({type, utt_version}))
  }

  function on_complete_report_input_params_step() {
    set_start_report(true)
  }

  function on_step_click(step_id) {
    if (step_id === STEP_INTRO) {
      on_progress_reset()
    }

    if (step_id === STEP_PORTFOLIO) {
      on_progress_reset_from_portfolio()
    }

    if (step_id === STEP_TECHNOLOGY) {
      on_progress_reset_from_technology()
    }
  }

  function can_switch_to_advanced_builder() {
    if (_.contains(NO_SWITCH_TO_ADVANCED_BUILDER_REPORT_STREAMS, selected_stream_id)) return false
    if (is_utt_among_classifiers(classifiers || [])) return false
    if (is_array_non_empty_non_null(portfolios) || is_array_non_empty_non_null(classifiers)) return true
    return false
  }

  function on_switch_to_advanced_builder() {
    const {get_report_input} = ID_TO_REPORT_STREAM[selected_stream_id] || {}

    if (!get_report_input) {
      set_start_report(false)
      return
    }

    const report_input = get_report_input({
      report_name: report_name_to_display,
      portfolios,
      portfolios_to_cluster: get_portfolios_to_cluster({portfolios, is_report_big, stream_id: selected_stream_id}),
      classifiers,
      technology_partitioning,
      multi_label,
      negatives_processing,
      group_by_owner_level,
      portfolio_roll_up_limit,
      utt_version,
      has_utt: user_has_utt
    })

    save_stream_input(report_input, generate_input_id())
      .then((id) => {
        history.push(`${BUILD_REPORT}?input=${id}`)
      })
  }

  function is_step_final(step) {
    switch (step) {
      case STEP_INTRO:
      case STEP_TECHNOLOGY: return !user_is_poweruser
      case STEP_PORTFOLIO: return !should_select_technology_partitioning({stream_id: selected_stream_id, user_has_utt, user_has_classifiers})
      case STEP_TECHNOLOGY_SPLIT:
      case STEP_REPORT_INPUT_PARAMS: return true
      default: return false;
    }
  }

  const current_step = steps[steps.length - 1]

  if (!is_creator(user)) {
    return (
      <ReportBuilderNoAccessPanel/>
    )
  }

  if (report_id) {
    const {report_endpoint} = ID_TO_REPORT_STREAM[selected_stream_id] || {}
    return (
      <Redirect to={`/report/${report_id}${report_endpoint || ''}`} />
    )
  }

  if (is_initial_fetch) {
    return (
      <ContainerFullWidth>
        <div className='w-100 mt-3'>
          <Spinner />
        </div>
      </ContainerFullWidth>
    )
  }

  return (
    <ContainerFullWidth>
      <div className='w-100 mt-3'>
        <ReportNameAndControls
          selected_stream_id={selected_stream_id}
          portfolios={portfolios}
          classifiers={classifiers}
          technology_partitioning={technology_partitioning}
          report_name={report_name_to_display}
          is_ignore_cached_reports={is_ignore_cached_reports}
          can_switch_to_advanced_builder={can_switch_to_advanced_builder()}
          on_set_report_name_handler={set_report_name}
          on_switch_to_advanced_builder_handler={on_switch_to_advanced_builder}
          on_ignore_cached_reports_toggle={() => set_is_ignore_cached_reports(!is_ignore_cached_reports)}
        />
      </div>

      {is_report_big && !is_report_too_big &&
        <ReportBuilderBasketWarning />
      }

      {is_report_too_big &&
        <ReportBuilderBasketStopMessage
          user_has_classifiers={user_has_classifiers}
        />
      }

      <ProgressDisplay
        selected_stream_id={selected_stream_id}
        steps={steps}
        is_current_step_final={is_step_final(current_step)}

        portfolio_size_total={portfolio_size_total}
        portfolio_type={portfolio_type}
        technology_partitioning={technology_partitioning}
        portfolios={portfolios || []}
        classifiers={classifiers || []}

        on_step_click_handler={on_step_click}
        on_update_portfolios_handler={on_select_portfolios}
        on_update_classifiers_handler={on_select_classifiers}
        className='mt-2'
      />

      {start_report &&
        <div><Spinner /></div>
      }

      {current_step === STEP_INTRO && !start_report &&
        <StreamsDisplay
          selected_stream={selected_stream_id}
          on_select_stream_handler={on_stream_select}
          user_has_utt={user_has_utt}
          user_has_classifiers={user_has_classifiers}

          className='w-100'
        />
      }

      {current_step === STEP_PORTFOLIO && !start_report &&
        <PortfolioStep
          is_final={is_step_final(STEP_PORTFOLIO)}

          stream_id={selected_stream_id}
          portfolio_type={portfolio_type}
          portfolios={portfolios}

          on_select_portfolios={on_select_portfolios}
          on_complete={on_complete_select_portfolios_step}

          className='my-2'
        />
      }

      {current_step === STEP_TECHNOLOGY_SPLIT && !start_report &&
        <TechnologyPartitioningStep
          is_fetching={is_initial_fetch}
          classifier_groups={classifier_groups}

          selected_stream_id={selected_stream_id}
          selected_classifier_group_id={selected_classifier_group_id}
          technology_partitioning={technology_partitioning}
          classifiers={classifiers}
          user_has_utt={user_has_utt}
          user_has_classifiers={user_has_classifiers}
          utt_version={utt_version}
          is_report_big={is_report_big}
          is_report_too_big={is_report_too_big}

          on_select_technology_split={on_select_technology_split}
          on_select_classifier_group={set_selected_classifier_group_id}
          on_select_classifiers={on_select_classifiers}
          on_complete={on_complete_technology_partitioning_step}

          className='my-2'
        />
      }

      {current_step === STEP_TECHNOLOGY && !start_report &&
        <TechnologyStep
          is_fetching={is_initial_fetch}
          is_final={is_step_final(STEP_TECHNOLOGY)}

          portfolios={portfolios}
          classifier_groups={classifier_groups}
          utt_group={utt_classes_group}

          selected_stream_id={selected_stream_id}
          selected_classifier_group_id={selected_classifier_group_id}
          user_has_utt={user_has_utt}
          classifiers={classifiers}

          utt_version={utt_version}
          tech_type={landscape_tech_type}

          on_select_classifier_group={set_selected_classifier_group_id}
          on_select_classifiers={on_select_classifiers}
          on_complete={on_complete_technology_step}

          className='my-2'
        />
      }

      {current_step === STEP_REPORT_INPUT_PARAMS && !start_report &&
        <ReportInputParamsStep
          selected_stream_id={selected_stream_id}
          is_multi_class={(classifiers || []).length > 1}
          is_tech_landscape={_.contains(steps, STEP_TECHNOLOGY)}

          multi_label={multi_label}
          negatives_processing={negatives_processing}
          group_by_owner_level={group_by_owner_level}
          portfolio_roll_up_limit={portfolio_roll_up_limit}

          on_change_multi_label_handler={set_multi_label}
          on_change_negatives_processing_handler={on_update_negatives_processing}
          on_change_group_by_owner_level={set_group_by_owner_level}
          on_change_portfolio_roll_up_limit={set_portfolio_roll_up_limit}

          on_complete={on_complete_report_input_params_step}

          className='my-2'
        />
      }

      {report_build_error &&
        <ErrorModal
          error={report_build_error}
          on_hide={() => set_report_build_error(null)}
          context='building a report'
        />
      }
    </ContainerFullWidth>
  )
}

export default withRouter(withUser(BuilderWizard))