import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { PrimaryButton } from '../widgets/Button.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { BUILD_VALUATION_REPORT } from '../../constants/paths.js'
import TileTitle from './TileTitle.js'

const NewGoogleValuationReportPanel = ({history}) => {

  function on_click_from_new_report_btn() {
    track_report_builder_event('action="select_builder" report_type="valuation" context="dashboard"')
    history.push(BUILD_VALUATION_REPORT)
  }

  const title = (
    <TileTitle
      title='Valuation report'
    />
  )

  return (
    <DashboardSmallTile title={title} >
      <DashboardTileInner>
        <DashboardTileDesc>
          Create a new valuation report using the Google Taxonomy
        </DashboardTileDesc>
        <PrimaryButton className='w-100 mt-3 mt-md-auto' onClick={on_click_from_new_report_btn}>Start</PrimaryButton>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(NewGoogleValuationReportPanel)