import React, { useEffect, useState } from 'react'
import _ from 'underscore'

import { TileDropdown } from '../dashboard_tiles/TileDropdown.js'
import { fetch_user_settings_by_id } from '../../utils/user_settings_utils.js'
import { fetch_users_in_keycloak_group } from '../../utils/user_group_utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import Spinner from '../widgets/Spinner.js'
import { fetch_group_settings } from '../../utils/user_group_settings_utils.js'
import { ChartSetsModal } from './chart_sets/ChartSetsDisplay.js'

const UserGroupSearch = (
  {
    show_spinner,
    groups,
    selected_group,
    selected_group_chart_sets,
    selected_user,
    selected_user_chart_sets,
    on_select_group_handler,
    on_get_group_settings_handler,
    on_select_user_handler,
    on_get_user_settings_handler,
    on_get_group_users_handler,
    select_user_label,
  }) => {

  const [group_users, set_group_users] = useState(null)
  const [is_fetching_users, set_is_fetching_users] = useState(false)

  useEffect(() => {
    if (!selected_group) return

    const {id: group_id} = selected_group

    set_is_fetching_users(true)
    Promise.all([
      fetch_users_in_keycloak_group(group_id),
      fetch_group_settings(group_id)
    ])
      .catch(error => {
        set_is_fetching_users(false)
        send_error_to_sentry(error, {})
        throw error
      })
      .then(([user_data, settings_response]) => {
        set_group_users(user_data)
        if (on_get_group_settings_handler) {
          on_get_group_settings_handler(settings_response)
        }

        if(on_get_group_users_handler) {
          on_get_group_users_handler(user_data)
        }

        set_is_fetching_users(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_group])

  useEffect(() => {
    if (!selected_user) return

    const {id} = selected_user

    fetch_user_settings_by_id(id)
      .catch(error => {
        // No point in notifying user, but log error in sentry.
        send_error_to_sentry(error, {})
        throw error
      })
      .then(settings_response => {
        on_get_user_settings_handler(settings_response)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_user])


  function on_group_select(group_id) {
    const selected_group = _.find(groups, g => g.id === group_id)

    on_select_user_handler(null)
    on_get_user_settings_handler({})
    on_select_group_handler(selected_group)
  }

  function on_user_select(user_id) {
    const selected_user = _.find(group_users, g => g.username === user_id)
    on_select_user_handler(selected_user)
  }

  return (
    <div className='d-flex'>

      {!show_spinner &&
        <div className='d-flex'>
          <TileDropdown
            label='Select organisation'
            selected_value={selected_group ? selected_group.name : null}
            items={groups || []}
            on_change_handler={on_group_select}
            max_height={500}
            enable_search={true}
          />

          {selected_group_chart_sets != null &&
            <ChartSetsModal
              sets={selected_group_chart_sets}
              className='ml-1 my-auto'
            />
          }

        </div>
      }

      {(show_spinner || is_fetching_users) &&
        <div className='text-center'><Spinner size='xs'/> </div>
      }

      {!is_fetching_users && group_users &&
        <div className='d-flex ml-3'>
          <TileDropdown
            label={select_user_label || 'Select user'}
            disabled={group_users.length === 0}
            selected_value={(selected_user || {}).username || ''}
            items={(group_users || []).map(u => ({id: u.username, name: u.username}))}
            on_change_handler={on_user_select}
            max_height={500}
            enable_search={true}
          />

          {selected_user_chart_sets != null &&
            <ChartSetsModal
              sets={selected_user_chart_sets}
              className='ml-1 my-auto'
            />
          }
        </div>
      }
    </div>
  )
}

export default UserGroupSearch