import React, { useEffect, useState } from 'react'

import AdminPageWrapper from './AdminPageWrapper.js'
import TextLink from '../widgets/TextLink.js'
import { ADMIN, CHART_SETS, CREATE_USERS, FTAGS_UI, ROLE_OVERVIEW, USER_SETTINGS } from '../../constants/paths.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { withUser } from '../UserContext.js'
import { is_cipher_engineering } from '../../utils/user_permissions.js'
import { get_groups_and_children_tsv } from '../../utils/spec_group_utils.js'
import { MIMETYPE_TSV, trigger_download } from '../../utils/download_utils.js'

const AdminLanding = ({user}) => {

  const [is_export_datasets, set_is_export_datasets] = useState(false)

  useEffect(() => {
    if (!is_export_datasets) return

    const specs_tsv = get_groups_and_children_tsv()
    set_is_export_datasets(false)
    trigger_download(specs_tsv, MIMETYPE_TSV, 'Cipher_datasets.tsv')
  }, [is_export_datasets])

  return (
    <AdminPageWrapper className='p-3 mx-auto'>
      <div className='d-flex flex-column'>
        <PaneHeader text='Available pages' />

        <TextLink element='a' href={`${ADMIN}${CREATE_USERS}`}>Create new users</TextLink>
        <TextLink element='a' href={`${ADMIN}${ROLE_OVERVIEW}`}>Role overview</TextLink>
        <TextLink element='a' href={`${ADMIN}${USER_SETTINGS}`}>Manage user settings</TextLink>
        <TextLink element='a' href={`${ADMIN}${CHART_SETS}`}>Manage chart sets</TextLink>
        <TextLink element='a' href={`${ADMIN}${FTAGS_UI}`}>Manage family tags</TextLink>

        {is_cipher_engineering(user) &&
          <TextLink onClick={() => set_is_export_datasets(true)}>Export datasets</TextLink>
        }
      </div>
    </AdminPageWrapper>
  )
}

export default withUser(AdminLanding)