import React from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import cn from 'classnames'
import domtoimage from 'dom-to-image'

import { ALL_FAMILIES_ID } from '../../model/spec_ids.js'
import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from '../../model/spec_groups.js'
import { ID_TO_VIEW } from '../../model/views.js'
import { LINE_ID, BAR_ID, COLUMN_CLUSTER_ID, COLUMN_STACK_ID } from '../../model/view_ids.js'
import { get_spec_group_and_view } from '../../model/main_items.js'
import { START_DATE_FIELD } from '../../model/disputes.js'
import { DESCENDING } from '../../model/sort_directions.js'
import {
  is_data_empty,
  get_processed_data_and_key_dims,
  has_empty_key_dims
} from '../../utils/column_data_utils.js'
import {
  get_data_export_input,
  fetch_powerpoint_document,
  fetch_excel_document,
  trigger_download,
  is_too_big_for_microsoft_download,
  is_dom_to_png_available,
  pre_download_png,
  post_download_png,
  get_download_filename,
  MIMETYPE_PPTX,
  MIMETYPE_XLSX,
  MIMETYPE_CSV,
  EXCEL_FILE_EXT,
  POWERPOINT_FILE_EXT,
  PNG_FILE_EXT,
  CSV_FILE_EXT
} from '../../utils/download_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import ErrorModal from '../ErrorModal.js'
import ErrorBody from '../ErrorBody.js'
import ErrorBoundary from '../ErrorBoundary.js'
import {RouteWithTracing} from '../RouteWithTracing'
import { BannerWithSpinnerLogo } from './ReportInProgressBanner.js'
import PatentsContainer from '../patent_family_list/PatentsContainer.js'
import MainReportItemHeader from './MainReportItemHeader.js'
import TimerangeControl from './TimerangeControl.js'
import HistogramRangeControl from './HistogramRangeControl.js'
import RegionGroupingControls from './RegionGroupingControls.js'
import ViewSelector from './ViewSelector.js'
import Spinner from '../widgets/Spinner.js'
import NoDataInSelection from '../NoDataInSelection.js'
import { track_download_event, track_report_viewer_event } from '../../utils/tracking_utils.js'
import { MainReportItemDesc } from './MainReportItemDesc.js'
import DataOrientationControls from './DataOrientationControls.js'
import {
  add_spotlights_to_deref_data,
  get_deselected,
  get_next_agglom_visibility,
  should_show_item_filters,
  should_show_item_rollups,
} from '../../utils/main_items_utils.js'
import { DATASET_HELP_LINK_CLASSNAME } from '../../utils/help_utils.js'
import MainReportItemFilters from './MainReportItemFilters.js'
import MainReportItemAgglom from './MainReportItemAgglom.js'
import DisputesContainer from '../disputes/DisputesContainer.js'
import { get_selections_minimal_for_main_item } from '../../model/user_state.js'
import { get_report_region_column } from '../../utils/regions_utils.js'
import { generate_subset_id, is_subset_path } from '../../utils/viewer_utils.js'
import { SUBSET_SUBPATH } from '../../constants/viewer_paths.js'
import DataIncludeAllZerosControl from './DataIncludeAllZerosControl.js'
import { get_default_summary_sort_props, get_filtered_disputes } from '../../utils/disputes_utils.js'

import s from './MainReportItem.module.scss'

class MainReportItem extends React.Component {
  constructor(props) {
    super(props)

    const {item} = props
    const [default_summary_sort_field_id, default_summary_sort_direction_id] = get_default_summary_sort_props(item)

    this.state = {
      initial_render: true,

      is_export_in_progress: false,
      download_excel_error: null,
      download_powerpoint_error: null,
      families_subselections: null,
      selected_sort_field_id: START_DATE_FIELD.id,
      selected_sort_direction_id: DESCENDING,

      is_summary_expanded: true,
      summary_selected_sort_field_id: default_summary_sort_field_id,
      summary_selected_sort_direction_id: default_summary_sort_direction_id,
      filters_visible: true
    }

    this.view_ref = React.createRef()

    this.check_for_temp_item                                 = this.check_for_temp_item.bind(this)
    this.download_png                                        = this.download_png.bind(this)
    this.download_powerpoint                                 = this.download_powerpoint.bind(this)
    this.download_excel                                      = this.download_excel.bind(this)
    this.download_csv                                        = this.download_csv.bind(this)
    this.download_summary_excel                              = this.download_summary_excel.bind(this)
    this.download_summary_csv                                = this.download_summary_csv.bind(this)
    this.download_summary_powerpoint                         = this.download_summary_powerpoint.bind(this)
    this.clear_families_subselections                        = this.clear_families_subselections.bind(this)
    this.set_families_subselections                          = this.set_families_subselections.bind(this)
    this.toggle_filters                                      = this.toggle_filters.bind(this)
    this.toggle_is_summary_expanded                          = this.toggle_is_summary_expanded.bind(this)
    this.update_summary_selected_sort_field_and_direction_id = this.update_summary_selected_sort_field_and_direction_id.bind(this)
  }

  componentDidMount() {
    // Force react to render spinner, so user sees something immediately.
    window.setTimeout(() => {
      // In the next cycle, render the page.
      // This can sometimes take a while (for very large numbers of DOM elements).
      // So at least the user will see a spinner meanwhile.
      this.setState({ initial_render: false })
    }, 1)
  }

  componentDidUpdate(prevProps) {
    //this.check_for_temp_item()

    const {item, location, selections} = this.props
    if (item && prevProps.item && prevProps.item.spec_id !== item.spec_id) {
      this.reset_sort_selections()
    }

    const prev_selections = prevProps.selections
    if (!_.isEqual(prev_selections, selections) && is_subset_path(location.pathname)) {
      this.show_subselections()
    }
  }

  toggle_filters() {
    this.setState({filters_visible: !this.state.filters_visible})
  }

  check_for_temp_item() {
    const { item, add_temporary_item } = this.props
    if (!item) {
      // Handle case where user is viewing a "temporary" item (not in their report).
      // We need to add this item, and fetch the data for it.
      // Ideally this would be done in the ReportContainer.
      // But this is hard to do there, as route deals with getting the item.
      add_temporary_item()
    }
  }

  download_png(report_title, item, view_id, view, spec_id) {
    track_download_event(`obj="dataset" name="${spec_id}" format="PNG"`)

    const filename = get_download_filename({report_title, view_id, file_ext: PNG_FILE_EXT})

    const view_element = this.view_ref.current

    pre_download_png(view_id, view_element)

    domtoimage.toBlob(view_element, { bgcolor: '#ffffff', height: view_element.clientHeight + 100, filter: (node) => {return !node.classList || !node.classList.contains(DATASET_HELP_LINK_CLASSNAME)}})
      .then(function (blob) {
        post_download_png(view_id, view_element)
        trigger_download(blob, null, filename)
      })
      .catch(err => {
        post_download_png(view_id, view_element)
        this.setState({ download_png_error: err })
        throw err
      })
  }

  download_powerpoint(report_title, item, view_id, view, spec_id, spec, group, data, key_dims, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date) {
    track_download_event(`obj="dataset" name="${spec_id}" format="PPT"`)

    this.setState({ is_export_in_progress: true })
    const input = get_data_export_input({ item, view, spec, data, key_dims, deref_data, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date })

    // Workaround to remove empty series for column charts (powerpoint library can not deal with these at the moment)
    const input_clean = !_.contains([LINE_ID, COLUMN_CLUSTER_ID, COLUMN_STACK_ID, BAR_ID], view_id) ? input : {
      ...input,
      data: input.data.filter(series => series.x.length > 0)
    }

    const filename = get_download_filename({report_title, view_id, file_ext: POWERPOINT_FILE_EXT})
    this.do_powerpoint_download(input_clean, filename)
  }

  download_excel(report_title, item, view_id, view, spec_id, spec, group, data, key_dims, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date) {
    track_download_event(`obj="dataset" name="${spec_id}" format="XLS"`)
    this.setState({ is_export_in_progress: true })
    const input = get_data_export_input({ item, view, spec, data, key_dims, deref_data, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date })

    const filename = get_download_filename({report_title, view_id, file_ext: EXCEL_FILE_EXT})
    this.do_excel_download(input, filename)
  }

  download_csv(report_title, item, view_id, view, spec_id, spec, group, data, key_dims, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date) {
    track_download_event(`obj="dataset" name="${spec_id}" format="CSV"`)
    const filename = get_download_filename({report_title, view_id, file_ext: CSV_FILE_EXT})

    const { ViewComponent } = view
    const value_rows = ViewComponent.get_csv_value_rows({ spec, data, key_dims, item, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date })
    const csv_string = get_csv_string(value_rows)

    trigger_download(csv_string, MIMETYPE_CSV, filename)
  }

  download_summary_csv(report_title, item, view_id, view, spec, data, deref_data, is_summary_expanded, selected_sort_field_id, selected_sort_direction_id) {
    track_download_event(`obj="dataset" name="${spec.id}_summary" format="CSV"`)

    const filename = get_download_filename({report_title, view_id, is_summary: true, file_ext: CSV_FILE_EXT})
    const { ViewComponent } = view
    const value_rows = ViewComponent.get_summary_csv_value_rows({ spec, data, item, deref_data, is_expanded: is_summary_expanded, selected_sort_field_id, selected_sort_direction_id })
    const csv_string = get_csv_string(value_rows)

    trigger_download(csv_string, MIMETYPE_CSV, filename)
  }

  download_summary_powerpoint(report_title, item, view_id, view, spec, data, deref_data, is_summary_expanded, selected_sort_field_id, selected_sort_direction_id) {
    track_download_event(`obj="dataset" name="${spec.id}_summary" format="PPT"`)
    this.setState({ is_export_in_progress: true })

    const filename = get_download_filename({report_title, view_id, is_summary: true, file_ext: POWERPOINT_FILE_EXT})
    const { ViewComponent } = view
    const input = ViewComponent.get_summary_data_for_export({ spec, view_id, data, item, deref_data, is_expanded: is_summary_expanded, selected_sort_field_id, selected_sort_direction_id})

    this.do_powerpoint_download(input, filename)
  }

  download_summary_excel(report_title, item, view_id, view, spec, data, deref_data, is_summary_expanded, selected_sort_field_id, selected_sort_direction_id) {
    track_download_event(`obj="dataset" name="${spec.id}_summary" format="XLS"`)
    this.setState({ is_export_in_progress: true })

    const filename = get_download_filename({report_title, view_id, is_summary: true, file_ext: EXCEL_FILE_EXT})
    const { ViewComponent } = view
    const input = ViewComponent.get_summary_data_for_export({ spec, view_id, data, item, deref_data, is_expanded: is_summary_expanded, selected_sort_field_id, selected_sort_direction_id })

    this.do_excel_download(input, filename)
  }

  do_excel_download(input, filename) {
    fetch_excel_document([input], false, true)
      .then(arraybuffer => {
        this.setState({ is_export_in_progress: false })
        trigger_download(arraybuffer, MIMETYPE_XLSX, filename)
      })
      .catch(err => {
        this.setState({ is_export_in_progress: false })
        this.setState({ download_excel_error: err })
        throw err
      })
  }

  do_powerpoint_download(input, filename) {
    fetch_powerpoint_document([input])
      .then(arraybuffer => {
        this.setState({ is_export_in_progress: false })
        trigger_download(arraybuffer, MIMETYPE_PPTX, filename)
      })
      .catch(err => {
        this.setState({ is_export_in_progress: false })
        this.setState({ download_powerpoint_error: err })
        throw err
      })
  }

  clear_families_subselections() {
    this.setState({ families_subselections: null, subselection_value: null })
  }

  reset_sort_selections() {
    this.setState({selected_sort_field_id: START_DATE_FIELD.id, selected_sort_direction_id: DESCENDING})
  }

  set_families_subselections(families_subselections, subselection_value, from_subset) {
    const { item } = this.props
    const { spec } = get_spec_group_and_view(item) // guaranteed to be non-null (we check for this in ReportContainer)
    if (spec.no_clickthrough) {
      // bypass
      return
    }

    track_report_viewer_event(`obj="dataset" dataset="${spec.id}" action="clickthrough"`)

    // Set subselections in state.
    this.setState({ families_subselections, subselection_value})

    // Show the subselections (this changes the url)
    if (!from_subset) {
      this.show_subselections()
    }
  }

  get_subset_path() {
    const { base_path } = this.props
    return `${base_path}/${SUBSET_SUBPATH}/${generate_subset_id()}`
  }

  show_subselections() {
    const { history } = this.props
    const path = this.get_subset_path()
    history.push(path)
  }

  update_sort_field_and_direction(selected_sort_field_id, selected_sort_direction_id) {
    this.setState({selected_sort_field_id, selected_sort_direction_id})
  }

  get_item_selections() {
    const { item, selections } = this.props

    const { selected_region_grouping } = item

    const { deselected_portfolio_ids=[], deselected_tech_ids=[], deselected_geo_ids=[] } = get_deselected(item)
    const { selected_portfolio_ids, selected_tech_ids, selected_geo_ids } = selections

    return {
      ...selections,
      selected_portfolio_ids: _.without(selected_portfolio_ids, ...deselected_portfolio_ids),
      selected_tech_ids: _.without(selected_tech_ids, ...deselected_tech_ids),
      selected_geo_ids: _.without(selected_geo_ids, ...deselected_geo_ids),

     ...(selected_region_grouping) ? {report_region_column: get_report_region_column(selected_region_grouping)} : {}
    }
  }

  toggle_is_summary_expanded() {
    const {is_summary_expanded} = this.state
    this.setState({is_summary_expanded: !is_summary_expanded})
  }

  update_summary_selected_sort_field_and_direction_id(sort_field_id, sort_direction_id) {
    this.setState({summary_selected_sort_field_id: sort_field_id, summary_selected_sort_direction_id: sort_direction_id})
  }

  render() {
    const {
      base_path, user_settings,
      on_close, on_next_item, on_prev_item, report_title, selections, minimal_selections,
      internal_report_id, external_report_id, report_input, report_has_scores,
      data_creation_date, item, fetch_obj, ref_data, deref_data, available_groups,
      on_change_dataset_timerange,
      on_change_dataset_histogram_range,
      continent_id_to_geo_ids,
      on_change_selected_view_id,
      set_selected_spec_id,
      report_series_sort,
      on_change_report_region_grouping,
      report_region_grouping,
      on_change_data_orientation,
      on_change_data_include_all_zeros,
      on_change_selected_dispute_type,
      on_change_dataset_plaintiff_type_ids,
      on_change_dataset_defendant_type_ids,
      on_change_dataset_outcome_ids,
      on_change_dataset_start_date_range,
      is_selected,
      on_dataset_select,
      on_dataset_deselect,

      // Eval Classifier stuff
      training_set,
      id_to_tsfam,
      id_to_tsfam_pending,
      id_to_tsfam_error,
      phrases_to_highlight,
      no_highlighting,
      eval_training_set_id,
      set_label,
      eval_classifier_data,
      rerun_eval_report,
      on_change_rollup_thresholds,
      on_change_dataset_portfolios_selection,
      on_change_dataset_techs_selection,
      on_change_dataset_geo_selection,
      on_change_report_portfolios_selection,
      on_change_report_techs_selection,
      on_change_report_geo_selection,
      on_change_next_agglom_item_visibility,
      on_change_dataset_spotlighted_portfolios,
      on_change_dataset_spotlighted_techs,
      is_family_tagging_mode_on,
      family_tagging_search_phrase,
      on_toggle_family_tagging_mode,
      on_update_family_tagging_search_phrase
    } = this.props

    const {
      initial_render,
      download_png_error,
      download_powerpoint_error,
      download_excel_error,
      families_subselections,
      subselection_value,
      selected_sort_field_id,
      selected_sort_direction_id,
      filters_visible,
      is_export_in_progress,
      is_summary_expanded,
      summary_selected_sort_field_id,
      summary_selected_sort_direction_id
    } = this.state

    if (!item || initial_render) {
      return (
        <BannerWithSpinnerLogo title='Preparing data...' />
      )
    }

    const { spec_id } = item
    const { spec, group, view } = get_spec_group_and_view(item)

    const { available_view_ids, is_patent_count, filter_by_dispute_type } = spec
    const { view_id, ViewComponent } = view

    const { is_fetching, data, fetch_error } = fetch_obj || {}

    const available_view_items = available_view_ids.map(id => ID_TO_VIEW[id])
    // Filter and limit data/keys
    const item_deref_data = add_spotlights_to_deref_data(item, deref_data, selections)
    const [processed_data, processed_key_dims, original_key_dims] = !data ? [null, null, null] : get_processed_data_and_key_dims(spec, view_id, data_creation_date, item, data, ref_data, item_deref_data, report_series_sort, false, true)

    // Decide whether data is empty by looking at the original data (NOT the filtered data)
    const empty = !data ? true : is_data_empty(data, original_key_dims, selections, spec)

    const is_all_families = (spec.id === ALL_FAMILIES_ID)

    const too_big_for_microsoft_download = !data ? false : is_too_big_for_microsoft_download(processed_key_dims)

    const item_selections = this.get_item_selections()

    const filters_available = spec ? should_show_item_filters({spec, view_id}) : false
    const rollups_available = spec ? should_show_item_rollups({spec, view_id}) : false

    const item_minimal_selections = get_selections_minimal_for_main_item(minimal_selections, item_selections, ref_data)

    const is_disputes_dataset = (filter_by_dispute_type === true)

    const disputes = is_disputes_dataset && processed_data ? get_filtered_disputes({data: processed_data, deref_data, item}) : []

    const is_dataset_empty = empty || _.some(processed_key_dims || [], (dim_keys) => (!dim_keys || dim_keys.length === 0)) || (is_disputes_dataset && (disputes.length === 0))

    return (
      <ErrorBoundary>
        <div>

          {download_png_error &&
            <ErrorModal
              on_hide={() => this.setState({download_png_error: null})}
              error={download_png_error}
              context='downloading png document'
            />
          }

          {download_powerpoint_error &&
            <ErrorModal
              on_hide={() => this.setState({download_powerpoint_error: null})}
              error={download_powerpoint_error}
              context='downloading powerpoint document'
            />
          }

          {download_excel_error &&
            <ErrorModal
              on_hide={() => this.setState({download_excel_error: null})}
              error={download_excel_error}
              context='downloading excel document'
            />
          }

          {/* Header - only render when item is shown (i.e. not for clickthroughs)*/}
          <RouteWithTracing
            exact={true}
            path={base_path}
            render={() => {
              return (
                <MainReportItemHeader
                  base_path={base_path}
                  on_close={on_close}
                  on_next_item={on_next_item}
                  on_prev_item={on_prev_item}
                  is_renderable={!(empty || is_dataset_empty) || is_all_families}
                  view_id={view_id}
                  available_groups={available_groups}
                  spec={spec}
                  item={item}
                  subselections={families_subselections}
                  too_big_for_microsoft_download={too_big_for_microsoft_download}
                  download_powerpoint={ViewComponent.get_plotly_data ? this.download_powerpoint.bind(null, report_title, item, view_id, view, spec_id, spec, group, processed_data, processed_key_dims, deref_data, external_report_id, report_series_sort, null, null, data_creation_date) : null}
                  download_excel={ViewComponent.get_plotly_data ? this.download_excel.bind(null, report_title, item, view_id, view, spec_id, spec, group, processed_data, processed_key_dims, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date) : null}
                  download_csv={ViewComponent.get_csv_value_rows ? this.download_csv.bind(null, report_title, item, view_id, view, spec_id, spec, group, processed_data, processed_key_dims, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date) : null}
                  download_png={is_dom_to_png_available(view_id) ? this.download_png.bind(null, report_title, item, view_id, view, spec_id, spec, group, processed_data, processed_key_dims, deref_data, external_report_id, selected_sort_field_id, selected_sort_direction_id) : null}
                  download_summary_csv={ViewComponent.get_summary_csv_value_rows ? this.download_summary_csv.bind(null, report_title, item, view_id, view, spec, data, deref_data, is_summary_expanded, summary_selected_sort_field_id, summary_selected_sort_direction_id) : null}
                  download_summary_excel={ViewComponent.get_summary_data_for_export ? this.download_summary_excel.bind(null, report_title, item, view_id, view, spec, data, deref_data, is_summary_expanded, summary_selected_sort_field_id, summary_selected_sort_direction_id) : null}
                  download_summary_powerpoint={ViewComponent.get_summary_data_for_export ? this.download_summary_powerpoint.bind(null, report_title, item, view_id, view, spec, data, deref_data, is_summary_expanded, summary_selected_sort_field_id, summary_selected_sort_direction_id) : null}
                  set_selected_spec_id={set_selected_spec_id}
                  toggle_filters_handler={(filters_available || rollups_available) ? this.toggle_filters : null}
                  filters_visible={filters_visible}
                  filters_disabled={empty}
                  is_selected={is_selected}
                  on_dataset_select={on_dataset_select}
                  on_dataset_deselect={on_dataset_deselect}
                  is_export_in_progress={is_export_in_progress}
                  is_disputes_dataset={is_disputes_dataset}
                />
              )
            }}
          />

          {(is_fetching || fetch_error) &&
            <div>
              {is_fetching &&
                <Spinner />
              }

              {fetch_error &&
                <ErrorBody
                  error={fetch_error}
                  context={'fetching report data'}
                />
              }

            </div>
          }

          {!(is_fetching || fetch_error) &&
            <div>

              <RouteWithTracing
                exact={true}
                path={base_path}
                render={() => {

                  const { filter_by_dispute_type } = spec

                  if (filter_by_dispute_type && empty) {
                    return (<NoDataInSelection className='mb-2' />)
                  }

                  if (filter_by_dispute_type) {
                    /* Disputes table */
                    return (
                      <div className='mt-2'>
                        <DisputesContainer
                          disputes={disputes || []}
                          spec={spec}
                          data={processed_data}
                          key_dims={processed_key_dims}
                          item={item}
                          selections={selections}
                          minimal_selections={minimal_selections}
                          deref_data={deref_data}
                          internal_report_id={internal_report_id}
                          external_report_id={external_report_id}
                          report_title={report_title}
                          is_thumbnail={false}
                          set_families_subselections={this.set_families_subselections}
                          report_series_sort={report_series_sort}
                          next_agglom_visibility={get_next_agglom_visibility(item, processed_key_dims.length)}
                          is_summary_expanded={is_summary_expanded}
                          toggle_is_summary_expanded={this.toggle_is_summary_expanded}
                          summary_selected_sort_field_id={summary_selected_sort_field_id}
                          summary_selected_sort_direction_id={summary_selected_sort_direction_id}
                          handle_change_summary_sort_field_and_direction_id={this.update_summary_selected_sort_field_and_direction_id}

                          on_change_selected_dispute_type={on_change_selected_dispute_type}

                          handle_change_sort_field_id_and_sort_direction_id={this.update_sort_field_and_direction.bind(this)}
                          selected_sort_field_id={selected_sort_field_id}
                          selected_sort_direction_id={selected_sort_direction_id}

                          on_change_plaintiff_type_ids={on_change_dataset_plaintiff_type_ids}
                          on_change_defendant_type_ids={on_change_dataset_defendant_type_ids}
                          on_change_outcome_ids={on_change_dataset_outcome_ids}
                          on_change_start_date_range={on_change_dataset_start_date_range}
                        />
                      </div>
                    )
                  }

                  return (
                    <div>
                      {/* Controls */}
                      <div className={cn('d-md-flex flex-wrap flex-lg-nowrap align-items-center pt-3 mb-3 position-relative')}>
                        <ViewSelector
                          className={cn('mr-2 mb-2 mb-md-0', s.view_selector)}
                          available_view_items={available_view_items}
                          selected_view_id={view_id}
                          on_change_selected_view_id={on_change_selected_view_id}
                        />

                        <div className='d-flex flex-wrap flex-lg-nowrap'>
                          {!empty &&
                            <TimerangeControl
                              className={'mt-2 mt-md-0 mb-1 mb-md-0'}
                              spec={spec}
                              original_key_dims={original_key_dims}
                              item={item}
                              data_creation_date={data_creation_date}
                              on_change_timerange={on_change_dataset_timerange}
                            />
                          }

                          {!has_empty_key_dims(original_key_dims) &&
                            <HistogramRangeControl
                              className={'mt-2 mt-md-0 mb-1 mb-md-1'}
                              spec={spec}
                              original_key_dims={original_key_dims}
                              item={item}
                              on_change_histogram_range={on_change_dataset_histogram_range}
                            />
                          }

                          <RegionGroupingControls
                            className={'mt-2 mt-md-0 mb-1 mb-md-0'}
                            spec={spec}
                            report_region_grouping={report_region_grouping}
                            on_change_report_region_grouping={on_change_report_region_grouping}
                          />

                          <DataOrientationControls
                            className={'ml-md-2 mt-2 mt-md-0 mb-1'}
                            item={item}
                            selected_view_id={view_id}
                            on_change_data_orientation={on_change_data_orientation}
                          />

                          {!has_empty_key_dims(original_key_dims) &&
                            <DataIncludeAllZerosControl
                              className={'ml-md-2 mt-2 mt-md-0 mb-1 mb-md-0 mr-5 mr-md-0'}
                              item={item}
                              selected_view_id={view_id}
                              on_change_data_include_all_zeros={on_change_data_include_all_zeros}
                              original_key_dims={original_key_dims}
                              get_expanded_key_dims_handler={() => {
                                return spec.get_key_dims({
                                  data,
                                  ref_data,
                                  deref_data,
                                  item,
                                  data_creation_date,
                                  hide_no_data_dim_keys: false
                                })
                              }}
                            />
                          }
                        </div>

                        {!empty && (filters_available || rollups_available) && filters_visible &&
                          <div className={cn('d-flex', s.filters_wrapper)}>
                            {filters_available &&
                              <div className={cn('p-2', s.filters)}>
                                <MainReportItemFilters
                                  spec={spec}
                                  item={item}
                                  selections={selections}
                                  selected_view_id={view_id}
                                  data={data}
                                  ref_data={ref_data}
                                  deref_data={deref_data}
                                  continent_id_to_geo_ids={continent_id_to_geo_ids}
                                  report_has_scores={report_has_scores}
                                  data_creation_date={data_creation_date}
                                  item_selections={item_selections}
                                  item_processed_key_dims={processed_key_dims}
                                  report_series_sort={report_series_sort}

                                  on_change_rollup_thresholds={on_change_rollup_thresholds}
                                  on_change_dataset_portfolios_selection={on_change_dataset_portfolios_selection}
                                  on_change_dataset_techs_selection={on_change_dataset_techs_selection}
                                  on_change_dataset_geo_selection={on_change_dataset_geo_selection}
                                  on_change_dataset_spotlighted_portfolios={on_change_dataset_spotlighted_portfolios}
                                  on_change_dataset_spotlighted_techs={on_change_dataset_spotlighted_techs}
                                  on_change_report_portfolios_selection={on_change_report_portfolios_selection}
                                  on_change_report_techs_selection={on_change_report_techs_selection}
                                  on_change_report_geo_selection={on_change_report_geo_selection}
                                />
                              </div>
                            }

                            {rollups_available &&
                              <MainReportItemAgglom
                                spec={spec}
                                item={item}
                                selected_view_id={view_id}
                                data={data}
                                ref_data={ref_data}
                                deref_data={deref_data}
                                data_creation_date={data_creation_date}
                                item_processed_key_dims={processed_key_dims}

                                on_change_rollup_thresholds={on_change_rollup_thresholds}
                                on_change_next_agglom_item_visibility={on_change_next_agglom_item_visibility}
                                className={cn('p-2', s.filters)}
                              />
                            }
                          </div>
                        }
                      </div>

                      {!is_dataset_empty && ViewComponent &&
                       <div
                         className='mb-3'
                         ref={this.view_ref}
                       >
                         <ViewComponent
                           spec={spec}
                           data={processed_data}
                           key_dims={processed_key_dims}
                           item={item}
                           selections={item_selections}
                           minimal_selections={item_minimal_selections}
                           deref_data={item_deref_data}
                           internal_report_id={internal_report_id}
                           external_report_id={external_report_id}
                           report_title={report_title}
                           is_thumbnail={false}
                           set_families_subselections={this.set_families_subselections}
                           report_series_sort={report_series_sort}
                           next_agglom_visibility={get_next_agglom_visibility(item, processed_key_dims.length)}
                           /* for table sorting; currently disputes tables only: */
                           handle_change_sort_field_id_and_sort_direction_id={this.update_sort_field_and_direction.bind(this)}
                           selected_sort_field_id={selected_sort_field_id}
                           selected_sort_direction_id={selected_sort_direction_id}
                           data_creation_date={data_creation_date}
                         />
                         <MainReportItemDesc item={item} />
                       </div>
                      }

                      {is_dataset_empty  && <NoDataInSelection className='mb-2' />}

                    </div>
                  )
                }}
              />

              <RouteWithTracing
                path={base_path+`/${SUBSET_SUBPATH}/:subset_id`}
                render={({ match: inner_match }) => {

                  const {subset_id} = inner_match.params

                  return (
                    <PatentsContainer
                      base_path={base_path}
                      is_clickthrough={true}
                      subset_id={subset_id}
                      data_creation_date={data_creation_date}
                      spec={spec}
                      data={processed_data}
                      selections={item_selections}
                      item={item}
                      minimal_selections={item_minimal_selections}
                      subselections={families_subselections}
                      total_patents={is_patent_count ? subselection_value : null} // For patent specs, specify total (as will be different from number of families)
                      internal_report_id={internal_report_id}
                      report_title={report_title}
                      report_input={report_input}
                      report_has_scores={report_has_scores}

                      controls_row_className={s.controls_row} // for setting sticky top
                      table_header_className={s.table_header} // for setting sticky top
                      family_details_controls_row_className={s.family_details_controls_row} // for setting sticky top
                      ref_data={ref_data}
                      user_settings={user_settings}
                      // Eval classifier stuff
                      training_set={training_set}
                      id_to_tsfam={id_to_tsfam}
                      id_to_tsfam_pending={id_to_tsfam_pending}
                      id_to_tsfam_error={id_to_tsfam_error}
                      phrases_to_highlight={phrases_to_highlight}
                      no_highlighting={no_highlighting}
                      eval_training_set_id={eval_training_set_id}
                      set_label={set_label}
                      rerun_eval_report={rerun_eval_report}
                      eval_classifier_data={eval_classifier_data}

                      is_family_tagging_mode_on={is_family_tagging_mode_on}
                      family_tagging_search_phrase={family_tagging_search_phrase}
                      on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                      on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

                      set_families_subselections={this.set_families_subselections}
                    />
                  )
                }}
              />

           </div>
         }
       </div>
     </ErrorBoundary>
    )
  }
}

export default withRouter(MainReportItem)