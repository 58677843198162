import React from 'react'
import cn from 'classnames'

import { STREAM_GROUP_TO_CLASSNAME } from './stream_styles.js'
import StepDisplay from './StepDisplay.js'

const ProgressStep = ({count, is_current, is_final, text, stream_group_id, on_click, className}) => {

  const {background: stream_className} = STREAM_GROUP_TO_CLASSNAME[stream_group_id] || {}

  const is_first = (count === 0)

  return (
    <StepDisplay is_current={is_current} is_first={is_first} is_final={is_final} className={cn(stream_className, className)} on_click={on_click}>
      <div className='px-3 my-auto position-relative d-flex h-100'>
        <span className='my-auto'>{text}</span>
      </div>
    </StepDisplay>
  )
}

export default ProgressStep