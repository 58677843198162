import React, { useState } from 'react'
import cn from 'classnames'

import { EditIcon } from '../../widgets/IconSet.js'
import TextLink from '../../widgets/TextLink.js'
import {
  remove_double_spaces,
  replace_linebreaks_with_spaces,
  replace_tabs_with_spaces
} from '../../../utils/utils.js'
import { FormFeedback } from '../../widgets/FormFeedback.js'
import { Link } from '../../widgets/Link.js'
import NoResizeTextArea from '../../widgets/NoResizeTextArea.js'
import ConfirmOrCancel from '../../widgets/ConfirmOrCancel.js'

import s from './ChartDescription.module.scss'

const DESCRIPTION_MAX_LENGTH = 500

const ChartDescription = (
  {
    description,
    can_edit,
    on_change_handler,
    more_info_url
  }
) => {
  const [is_edit, set_is_edit] = useState(false)

  const [updated_description, set_updated_description] = useState(null)

  function normalise(text) {
    return remove_double_spaces(replace_linebreaks_with_spaces(replace_tabs_with_spaces(text.trim())))
  }

  function toggle_edit() {
    set_is_edit(!is_edit)
  }

  function on_change(value) {
    set_updated_description(value)
  }

  function on_submit() {
    toggle_edit()
    if (updated_description != null) {
      on_change_handler(normalise(updated_description))
      set_updated_description(null)
    }
  }

  function on_cancel() {
    toggle_edit()
    set_updated_description(null)
  }

  function is_valid(text) {
    if (text == null) return true

    if (text === '') return false

    const normalised_description = normalise(text)

    return normalised_description.length <= DESCRIPTION_MAX_LENGTH
  }

  if (!can_edit) {
    return <div className={s.description_block__view}>
      {updated_description || description}
      { more_info_url && <TextLink className='ml-1' element={Link} target='_blank' href={more_info_url}>More information</TextLink> }
    </div>
  }

  const is_updated_description_valid = is_edit ? is_valid(updated_description) : true

  return (
    <div>
      {!is_edit &&
        <div className={cn(s.description_block__view)}>
          <span>{description}</span>
          <TextLink title='Change description' onClick={() => set_is_edit(true)} className={cn('ml-1', s.description_edit_btn)}>
            <EditIcon/>
          </TextLink>
          { more_info_url && <TextLink className='d-block mt-1' element={Link} target='_blank' href={more_info_url}>More information</TextLink> }
        </div>
      }

      {is_edit &&
        <div className={s.description_block}>
          <div className='d-flex w-100'>
            <div className='position-relative flex-grow-1'>
              <NoResizeTextArea
                value={(updated_description != null ? updated_description : description) || ''}
                on_change={on_change}
                rows_count={2}
                className='p-1'
              />
            </div>
            <ConfirmOrCancel
              is_valid={is_updated_description_valid}
              on_submit={on_submit}
              on_cancel={on_cancel}
            />
          </div>

          {!is_updated_description_valid &&
            <FormFeedback
              valid={false}
              validation_text={`Description can't be empty or longer than ${DESCRIPTION_MAX_LENGTH} characters.`}
            />
          }

        </div>
      }
    </div>
  )
}

export default ChartDescription