import React from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import CheckboxStatic from './CheckboxStatic.js'
import BaseDropdown from './BaseDropdown.js'
import ScrollableList from './ScrollableList.js'

import s from './MultiItemDropdownSelector.module.scss'

const MultiItemDropdownSelector = ({
  className,
  rowClassName,
  items,
  selected_item_ids,
  label,
  labelClassName,
  on_change_item_ids,
  right,
  chevron_left,
  buttonClassName,
  menuClassName,
  disabled
}) => {

  function on_toggle_item_id(item_id) {
    // Is item currently selected?
    const is_already_selected = _.contains(selected_item_ids, item_id)

    // Get new items (do in same order as reference data)
    const all_item_ids = items.map(item => item.id)
    const new_selected_item_ids = all_item_ids.filter(id => {
      if (id === item_id) {
        // The selected item, so add/remove it
        return is_already_selected ? false /* remove */ : true /* add */
      }
      return _.contains(selected_item_ids, id)
    })

    on_change_item_ids(new_selected_item_ids)
  }

  return (
    <BaseDropdown
      className={cn(className)}
      label={label}
      labelClassName={labelClassName}
      right={right}
      buttonClassName={buttonClassName}
      menuClassName={menuClassName}
      disabled={disabled}
      chevron_left={chevron_left}
    >
      <ScrollableList>
        {items.map((item, idx) =>
          <DropdownItem
            className={cn('d-flex', rowClassName)}
            key={idx}
            toggle={false} // on click, keep the dropdown open
            onClick={on_toggle_item_id.bind(null, item.id)}
            disabled={item.disabled}
          >
            <CheckboxStatic
              is_checked={_.contains(selected_item_ids, item.id)}
              className='mr-1'
            />
            {' '}
            <span
              className={s.checkbox_label}
            >
              {item.name}
            </span>

          </DropdownItem>
        )}
      </ScrollableList>
    </BaseDropdown>
  )
}

export default MultiItemDropdownSelector