import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { ID_TO_REPORT_STREAM } from './report_streams.js'
import {
  PORTFOLIO_UPLOAD_OPTIONS,
  STEP_INTRO,
  STEP_PORTFOLIO, STEP_PORTFOLIO_FILE_UPLOAD, STEP_PORTFOLIO_BOOLEAN_SEARCH, STEP_PORTFOLIO_KNN_SEARCH,
  STEP_PORTFOLIO_ORG_LIST,
  STEP_PORTFOLIO_ORG_SEARCH,
  STEP_PORTFOLIO_TEXT_UPLOAD, STEP_REPORT_INPUT_PARAMS,
  STEP_TECHNOLOGY,
  STEP_TECHNOLOGY_SPLIT
} from './builder_wizard.js'
import {
  format_integer_with_comma,
  pluralise_text,
  remove_from_array_by_idx
} from '../../../utils/utils.js'

import {
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../../../model/technology_basket.js'
import TextLink from '../../widgets/TextLink.js'
import { RemoveIcon, ShowBasketItemIcon } from '../../widgets/IconSet.js'
import ProgressStep from './ProgressStep.js'
import ProgressStepSummary from './ProgressStepSummary.js'
import { AwaitingStep } from './StepDisplay.js'
import { InfoPopover } from '../../widgets/Tooltip.js'

import cs from '../../cipher_styles.module.scss'
import s from './ProgressDisplay.module.scss'

const SummaryItem = ({item, on_click}) => {
  const {name} = item

  return (
    <div className='d-flex mb-1'>
      <TextLink onClick={on_click}><RemoveIcon /></TextLink>
      <div className='my-auto'>{name}</div>
    </div>
  )
}

const Streams = ({is_current, step_position, selected_stream_id, on_click}) => {

  const { group_id: stream_group_id, label, short_label } = ID_TO_REPORT_STREAM[selected_stream_id] || {}

  return (
    <div>
      <ProgressStep
        is_current={is_current}
        count={step_position}
        text={'What do you want to do?'}
        stream_group_id={!is_current ? stream_group_id : null}

        on_click={on_click}

        className={cn([{[cs.cursor_pointer]: selected_stream_id != null}])}
      />
      {selected_stream_id &&
        <ProgressStepSummary label={short_label || label} title={label} stream_group_id={!is_current ? stream_group_id : null}/>
      }
    </div>
  )
}

const Portfolio = (
  {
    is_included,
    is_current,
    is_current_step_final,
    step_position,
    selected_stream_id,
    portfolio_size_total,
    portfolio_type,
    portfolios,
    on_update_portfolios_handler,
    on_click,
    className
  }) => {
  function get_portfolio_step_text() {
    switch (portfolio_type) {
      case STEP_PORTFOLIO_ORG_SEARCH:
        return 'Search for one or more organisations'
      case STEP_PORTFOLIO_ORG_LIST:
        return 'Add organisations from pre-defined sets'
      case STEP_PORTFOLIO_TEXT_UPLOAD:
      case STEP_PORTFOLIO_FILE_UPLOAD:
        return 'Submit a list of patent/family IDs'
      case STEP_PORTFOLIO_BOOLEAN_SEARCH:
        return 'Find patent families by boolean query'
      case STEP_PORTFOLIO_KNN_SEARCH:
        return 'Find families by technology name'
      default:
        return ''
    }
  }

  function get_total_items_text(count, items_single, items_plural) {
    return `${format_integer_with_comma(count)} ${pluralise_text(count, items_single, items_plural)} selected`
  }

  function get_org_search_step_summary() {
    const total = portfolios.length

    return get_total_items_text(total, 'organisation')
  }

  function get_portfolio_upload_step_summary() {
    let total = 0

    portfolios.forEach(portfolio => {
      const {pat_fam_ids} = portfolio

      total += pat_fam_ids.length
    })

    return get_total_items_text(total, 'family', 'families')
  }

  function get_boolean_search_step_summary() {
    if ((portfolio_size_total || 0) === 0) return ''

    const search_terms = portfolios.map(item => item.search_term).join(' | ')

    return (
      <span className='d-flex justify-content-between'>
        <span className={s.family_search_summary}>
          {get_total_items_text(portfolio_size_total, 'family', 'families')}
        </span>
        <InfoPopover toggler={<span className={cs.font_size_smaller}><ShowBasketItemIcon /></span>}>
          Search terms: {search_terms}
        </InfoPopover>
      </span>
    )
  }

  function on_remove_portfolio_by_idx(i) {
    on_update_portfolios_handler(remove_from_array_by_idx(portfolios, i))
  }

  function on_step_click() {
    if (on_click && !is_current) return on_click()
  }

  if (!is_included) return null

  const { group_id: stream_group_id } = ID_TO_REPORT_STREAM[selected_stream_id] || {}

  const has_portfolios_selected = (portfolios || []).length > 0
  const is_final = is_current && is_current_step_final
  return (
    <div className={className}>
      <ProgressStep
        is_current={is_current}
        is_final={is_final}
        count={step_position}
        text={get_portfolio_step_text()}
        stream_group_id={stream_group_id}

        on_click={on_step_click}
        className={cn([{[cs.cursor_pointer]: !is_current && !is_final}])}
      />

      {_.contains([...PORTFOLIO_UPLOAD_OPTIONS, STEP_PORTFOLIO_KNN_SEARCH], portfolio_type) && has_portfolios_selected &&
        <ProgressStepSummary label={get_portfolio_upload_step_summary()} is_final={is_final} stream_group_id={stream_group_id} />
      }

      {portfolio_type === STEP_PORTFOLIO_BOOLEAN_SEARCH && has_portfolios_selected &&
        <ProgressStepSummary label={get_boolean_search_step_summary()} is_final={is_final} stream_group_id={stream_group_id} title={get_total_items_text(portfolio_size_total, 'family', 'families')}/>
      }

      {[STEP_PORTFOLIO_ORG_SEARCH, STEP_PORTFOLIO_ORG_LIST].indexOf(portfolio_type) > -1 && has_portfolios_selected &&
        <ProgressStepSummary label={get_org_search_step_summary()} is_final={is_final} stream_group_id={stream_group_id}>
          {portfolios.map((item, i) => {
            return (
              <SummaryItem
                key={i}
                item={item}
                on_click={() => on_remove_portfolio_by_idx(i)}
              />
            )
          })}
        </ProgressStepSummary>
      }
    </div>
  )
}

const TechnologyPartitioning = ({is_included, is_current, is_current_step_final, step_position, selected_stream_id, technology_partitioning, classifiers, on_update_classifiers_handler, className}) => {
  function get_technology_partitioning_step_summary() {
    const {type, use_superclasses} = technology_partitioning || {}

    switch (type) {
      case TECH_PARTITIONING_TYPE_CLUSTERING: return 'Clustering'
      case TECH_PARTITIONING_TYPE_UTT:
        return `UTT${use_superclasses ? ' superclasses' : ''}`
      case TECH_PARTITIONING_TYPE_CLASSIFIER:
        return (classifiers || []).length === 0 ? 'Classifiers' : `${classifiers.length} classifiers selected`
      default:
        return ''
    }
  }

  function on_remove_classifier_by_idx(i) {
    on_update_classifiers_handler(remove_from_array_by_idx(classifiers, i))
  }

  if (!is_included) return null

  const { group_id: stream_group_id } = ID_TO_REPORT_STREAM[selected_stream_id] || {}
  const is_final = is_current && is_current_step_final
  return (
    <div className={className}>
      <ProgressStep
        is_current={is_current}
        is_final={is_final}
        count={step_position}
        text='Choose how you want to classify'
        stream_group_id={stream_group_id}
      />

      {technology_partitioning &&
        <ProgressStepSummary label={get_technology_partitioning_step_summary()} is_final={is_final} stream_group_id={stream_group_id}>
          {classifiers && classifiers.map((item, i) => {
            return (
              <SummaryItem
                key={i}
                item={item}
                on_click={() => on_remove_classifier_by_idx(i)}
              />
            )
          })}
        </ProgressStepSummary>
      }
    </div>
  )
}

const Technology = ({is_included, is_current, is_current_step_final, step_position, selected_stream_id, classifiers, on_update_classifiers_handler, on_click, className}) => {
  function on_remove_classifier_by_idx(i) {
    on_update_classifiers_handler(remove_from_array_by_idx(classifiers, i))
  }

  function on_step_click() {
    if (on_click && !is_current) return on_click()
  }

  function get_summary() {
    const how_many = (classifiers || []).length

    if (how_many > 0) return `${how_many} selected`

    return 'No classifiers selected'
  }

  if (!is_included) return null

  const { group_id: stream_group_id } = ID_TO_REPORT_STREAM[selected_stream_id] || {}
  const is_final = is_current && is_current_step_final
  return (
    <div className={className}>
      <ProgressStep
        is_current={is_current}
        is_final={is_final}
        count={step_position}
        text='Select which classifiers to use'
        stream_group_id={stream_group_id}
        on_click={on_step_click}

        className={cn([{[cs.cursor_pointer]: !is_current && !is_final}])}
      />

      <ProgressStepSummary label={get_summary()} is_final={is_final} stream_group_id={stream_group_id}>
        {classifiers && classifiers.map((item, i) => {
          return (
            <SummaryItem
              key={i}
              item={item}
              on_click={() => on_remove_classifier_by_idx(i)}
            />
          )
        })}
      </ProgressStepSummary>
    </div>
  )
}

const Params = ({selected_stream_id, is_included, is_current, is_current_step_final, step_position, className}) => {
  if (!is_included) return null
  const { group_id: stream_group_id } = ID_TO_REPORT_STREAM[selected_stream_id] || {}
  return (
    <div className={className}>
      <ProgressStep
        is_current={is_current}
        is_final={is_current && is_current_step_final}
        count={step_position}
        stream_group_id={stream_group_id}
        text='Choose report options and create report'
      />
    </div>
  )
}

const ProgressDisplay = (
  {
    selected_stream_id,
    steps,
    is_current_step_final,

    portfolio_size_total,
    portfolio_type,
    technology_partitioning,
    portfolios,
    classifiers,

    on_step_click_handler,
    on_update_portfolios_handler,
    on_update_classifiers_handler,
    className
  }) => {

  function is_step_in_the_list(step) {
    if (!steps) return false
    return steps.indexOf(step) > -1
  }

  function get_step_position(step) {
    return (steps || []).indexOf(step)
  }

  function is_step_current(step) {
    if (!steps) return false
    return steps[steps.length - 1] === step
  }

  return (
    <div className={cn('d-flex flex-row flex-nowrap', s.block, className)}>
      <Streams
        is_current={is_step_current(STEP_INTRO)}
        step_position={get_step_position(STEP_INTRO)}
        selected_stream_id={selected_stream_id}

        on_click={() => {
          if (!selected_stream_id) return
          on_step_click_handler(STEP_INTRO)
        }}
      />

      <Portfolio
        is_included={is_step_in_the_list(STEP_PORTFOLIO)}
        is_current={is_step_current(STEP_PORTFOLIO)}
        is_current_step_final={is_current_step_final}
        step_position={get_step_position(STEP_PORTFOLIO)}
        selected_stream_id={selected_stream_id}

        portfolio_size_total={portfolio_size_total}
        portfolios={portfolios}
        portfolio_type={portfolio_type}
        on_update_portfolios_handler={on_update_portfolios_handler}
        className={s.step_wrapper}

        on_click={() => {
          if (is_step_current(STEP_PORTFOLIO)) return
          on_step_click_handler(STEP_PORTFOLIO)
        }}
      />

      <TechnologyPartitioning
        is_included={is_step_in_the_list(STEP_TECHNOLOGY_SPLIT)}
        is_current={is_step_current(STEP_TECHNOLOGY_SPLIT)}
        is_current_step_final={is_current_step_final}
        step_position={get_step_position(STEP_TECHNOLOGY_SPLIT)}
        selected_stream_id={selected_stream_id}

        technology_partitioning={technology_partitioning}
        classifiers={classifiers}

        on_update_classifiers_handler={on_update_classifiers_handler}
        className={s.step_wrapper}
      />

      <Technology
        is_included={is_step_in_the_list(STEP_TECHNOLOGY)}
        is_current={is_step_current(STEP_TECHNOLOGY)}
        is_current_step_final={is_current_step_final}
        step_position={get_step_position(STEP_TECHNOLOGY)}
        selected_stream_id={selected_stream_id}

        classifiers={classifiers}

        on_update_classifiers_handler={on_update_classifiers_handler}
        className={s.step_wrapper}

        on_click={() => {
          if (is_step_current(STEP_TECHNOLOGY)) return
          on_step_click_handler(STEP_TECHNOLOGY)
        }}
      />

      <Params
        selected_stream_id={selected_stream_id}
        is_included={is_step_in_the_list(STEP_REPORT_INPUT_PARAMS)}
        is_current={is_step_current(STEP_REPORT_INPUT_PARAMS)}
        is_current_step_final={is_current_step_final}
        step_position={get_step_position(STEP_REPORT_INPUT_PARAMS)}
        className={s.step_wrapper}
      />

      {!is_current_step_final &&
        <div className={s.step_wrapper}>
          <AwaitingStep/>
        </div>
      }

    </div>
  )
}

export default ProgressDisplay