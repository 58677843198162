import _ from 'underscore'

import { is_array_non_empty_non_null } from '../utils/utils.js'
import { get_all_assignee_ids, is_agglomeration, is_assignee, is_organisation } from '../utils/organisation_utils.js'
import { TYPE_AGGLOMERATED_ASSIGNEES, TYPE_ORGANISATION_ASSIGNEE_GROUP } from './organisation.js'
import { format_search_phrase_as_name } from '../utils/name_utils.js'

export const TYPE_TECH_SEARCH = 'tech_search'
export const TYPE_PATENT_FAMILIES = 'patent_families'

export const META_TYPE_FAMILY_TAG = 'family_tag'
export const META_TYPE_PATENT_UPLOAD = 'patent_upload'

export function get_org_as_portfolio_item(org) {
  const {name, company_list} = org

  if (is_organisation(org) || is_assignee(org)) {
    const {id, type, company_list} = org
    return {id, type, name, ...(company_list ? {company_list} : {})}
  }

  if (is_agglomeration(org)) {
    // saved agglom assignees from an organisation list will already have an array of assignee_ids
    const assignee_ids = is_array_non_empty_non_null(org.assignee_ids) ? org.assignee_ids : get_all_assignee_ids(org)

    return {
      type: TYPE_AGGLOMERATED_ASSIGNEES,
      name,
      assignee_ids,
      ...(company_list ? {company_list} : {})
    }
  }

  return null
}

export function get_org_group_as_portfolio_item(org_group, name) {
  const members = (org_group || []).map(org => get_org_as_portfolio_item(org)).filter(item => item != null)

  return {
    type: TYPE_ORGANISATION_ASSIGNEE_GROUP,
    name,
    members
  }
}

export function get_boolean_search_as_portfolio_item(patent_search_phrase) {
  const name = format_search_phrase_as_name(patent_search_phrase)

  return {
    type: TYPE_TECH_SEARCH,
    name,
    search_term: patent_search_phrase,
    group_by_owner: true
  }
}

export function get_patent_upload_as_portfolio_item(patent_upload) {
  const { name, pat_fam_ids, group_by_owner, lines, inputs, publications, meta: upload_meta } = patent_upload

  const meta = (lines || inputs || publications) ? {
    type: META_TYPE_PATENT_UPLOAD,
    ...lines ? {lines} : {},
    ...inputs ? {inputs} : {},
    ...publications ? {publications} : {},
  } : null

  return {
    type: TYPE_PATENT_FAMILIES,
    name,
    pat_fam_ids: pat_fam_ids.map(id => parseInt(id)), // ensure that any string ids are parsed to ints
    group_by_owner: (group_by_owner != null) ? group_by_owner : true,
    ...(meta || upload_meta) ? {meta: upload_meta || meta} : {}
  }
}

export function get_family_tag_as_portfolio_item(name, pat_fam_ids){

  return {
    type: TYPE_PATENT_FAMILIES,
    name,
    pat_fam_ids,
    group_by_owner: true,
    meta: {
      type: META_TYPE_FAMILY_TAG,
      name: name
    }
  }
}

export function is_patent_families_type(item) {
  const {meta} = item
  const {type: meta_type=META_TYPE_PATENT_UPLOAD} = meta || {}
  return is_selected_type(item, TYPE_PATENT_FAMILIES) && (meta_type === META_TYPE_PATENT_UPLOAD)
}

export function is_tech_search_type(item) {
  return is_selected_type(item, TYPE_TECH_SEARCH)
}

export function is_family_tag_type(item) {
  const {meta} = item
  const {type: meta_type} = meta || {}
  return is_selected_type(item, TYPE_PATENT_FAMILIES) && (meta_type === META_TYPE_FAMILY_TAG)
}

function is_selected_type(item, selected_type) {
  const {type} = item
  return _.isEqual(type, selected_type)
}
