import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Label } from 'reactstrap'
import { SketchPicker } from 'react-color'
import cn from 'classnames'

import { DEFAULT_TEXT_COLOUR_OVER_DARK_BG } from '../../../constants/colours.js'
import {
  add_new_phrase, add_new_phrase_below, remove_phrase, update_phrase, update_phrase_colour
} from '../utils/phrases_to_highlight_utils.js'
import {
  CLIENT_PREFIX,
  get_client_tags,
  get_status_tags,
  get_tag_tags,
  get_tags_string,
  STATUS_PREFIX,
  TAG_PREFIX
} from '../utils/tag_utils.js'
import { withUser } from '../../UserContext.js'
import { is_aistemos } from '../../../utils/user_permissions.js'
import { check_need_light_complimentary_colour } from '../../../utils/highlight_utils.js'
import { DEFAULT_TITLE } from '../constants/constants.js'
import PageContainer from './PageContainer.js'
import AutoResizeTextArea from '../../widgets/AutoResizeTextArea.js'
import TextLink from '../../widgets/TextLink.js'
import {
  TrashIcon,
  ColourPaletteIcon,
  TorchOnIcon,
  TorchOffIcon,
  PlusCircleIcon,
  ShowSimilarIcon
} from '../../widgets/IconSet.js'
import EditTrainingSetSingleValueTagControl from './EditTrainingSetSingleValueTagControl.js'
import { TRAINING_SET_STATUSES } from '../model/training_set_statuses.js'

import s from './Scope.module.scss'


const Scope = (
  {
    title,
    description,
    taxonomy_path,
    tags,
    training_set_id,
    training_set_info,
    training_set_infos,
    set_training_set_infos,
    set_current_training_set_info,
    notes,
    phrases_to_highlight,
    no_highlighting,
    on_change_no_highlighting,
    on_change_title,
    on_change_description,
    on_change_notes,
    on_change_phrases_to_highlight,
    handle_edit_taxonomy_path,
    handle_edit_tags,
    on_search_similar_families_by_scope,
    user
  }) => {

  const [show_phrase_colour_picker_idx, set_show_phrase_colour_picker_idx] = useState(null)

  const name_input_ref = React.createRef()

  const tag_tags = get_tag_tags(tags || [])
  const client_tags = get_client_tags(tags || [])
  const status_tags = get_status_tags(tags || [])
  const is_group_aistemos = is_aistemos(user)

  useEffect(() => {
    if (!name_input_ref || !name_input_ref.current) return

    name_input_ref.current.focus()
    if (title === DEFAULT_TITLE) {
      name_input_ref.current.select()
    }
  }, [name_input_ref, title])

  function on_add_new_phrase_to_highlight(phrases_to_highlight) {
    const new_phrases_to_highlight = add_new_phrase(phrases_to_highlight)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_add_new_phrase_to_highlight_below(phrases_to_highlight, idx) {
    const new_phrases_to_highlight = add_new_phrase_below(phrases_to_highlight, idx)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_delete_phrase(phrases_to_highlight, idx) {
    const new_phrases_to_highlight = remove_phrase(phrases_to_highlight, idx)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_change_phrase(phrases_to_highlight, idx, new_phrase) {
    const new_phrases_to_highlight = update_phrase(phrases_to_highlight, idx, new_phrase)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_change_colour(phrases_to_highlight, idx, new_colour) {
    const new_phrases_to_highlight = update_phrase_colour(phrases_to_highlight, idx, new_colour)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function handle_edit_client_tags() {
    handle_edit_tags(CLIENT_PREFIX)
  }

  function handle_edit_tag_tags() {
    handle_edit_tags(TAG_PREFIX)
  }

  const add_new_phrase_link = (
    <TextLink onClick={() => on_add_new_phrase_to_highlight(phrases_to_highlight)} >
      add more
    </TextLink>
  )

  return (
    <PageContainer>
      <Form>
        <div className='row'>

          <div className='col'>
            <FormGroup>
              <Label className={s.form_label}>Name</Label>
              <AutoResizeTextArea
                ref={name_input_ref}
                className={s.text_area}
                value={title || ''}
                placeholder={DEFAULT_TITLE}
                on_change={on_change_title}
                rows={1}
              />
            </FormGroup>

            {handle_edit_tags && is_group_aistemos &&
              <div className='row'>
                <FormGroup className='col-9'>
                  <Label className={s.form_label}>Client</Label>
                  <AutoResizeTextArea
                    className={s.text_area}
                    value={get_tags_string(client_tags)}
                    placeholder='Add client'
                    on_click={handle_edit_client_tags}
                    on_change={handle_edit_client_tags}
                    on_key_press={handle_edit_client_tags}
                    rows={1}
                  />
                </FormGroup>
                <FormGroup className='col-3'>
                  <Label className={s.form_label}>Status</Label>
                  <div className={s.dropdown_wrapper}>
                    <EditTrainingSetSingleValueTagControl
                      buttonClassName={cn('w-100', 'h-100', s.dropdown_button)}
                      labelClassName={cn('w-100', s.dropdown_label)}
                      training_set_id={training_set_id}
                      training_set_info={training_set_info}
                      training_set_infos={training_set_infos}
                      set_training_set_infos={set_training_set_infos}
                      set_current_training_set_info={set_current_training_set_info}
                      tag_prefix={STATUS_PREFIX}
                      allowed_tag_values={TRAINING_SET_STATUSES}
                      selected_tag_values={status_tags}
                    />
                  </div>
                </FormGroup>
              </div>
            }

            <div className='row'>
              {handle_edit_taxonomy_path &&
                <FormGroup className='col'>
                  <Label className={s.form_label}>Taxonomy path</Label>
                  <AutoResizeTextArea
                    className={s.text_area}
                    value={taxonomy_path.join('/')}
                    placeholder='Add taxonomy path'
                    on_click={handle_edit_taxonomy_path}
                    on_change={handle_edit_taxonomy_path}
                    on_key_press={handle_edit_taxonomy_path}
                    rows={1}
                  />
                </FormGroup>
              }

              {handle_edit_tags &&
                <FormGroup className='col'>
                  <Label className={s.form_label}>Tags</Label>
                  <AutoResizeTextArea
                    className={s.text_area}
                    value={get_tags_string(tag_tags)}
                    placeholder='Add tags'
                    on_click={handle_edit_tag_tags}
                    on_change={handle_edit_tag_tags}
                    on_key_press={handle_edit_tag_tags}
                    rows={1}/>
                </FormGroup>
              }
            </div>


            <FormGroup>
              <div className='d-flex justify-content-between'>
                <Label>Scope</Label>

                {on_search_similar_families_by_scope &&
                  <TextLink className='my-auto' onClick={on_search_similar_families_by_scope} title='Search similar families' disable={(description || '') === ''}><ShowSimilarIcon /></TextLink>
                }
              </div>
              <AutoResizeTextArea
                className={s.text_area}
                value={description || ''}
                on_change={on_change_description}
                rows={4}
              />
            </FormGroup>

            <FormGroup>
              <Label>Build notes</Label>
              <AutoResizeTextArea
                className={s.text_area}
                value={notes}
                on_change={on_change_notes}
                rows={11}
              />
            </FormGroup>
          </div>

          <div className='col'>

            <div>
              <Label className={s.form_label}>
                <span className={cn(s.highlighting_label)}>Highlighting</span>
                <span
                  className={cn(s.highlighting_on_off_action)}
                  onClick={on_change_no_highlighting.bind(null, !no_highlighting)}
                >
                  <span className={cn(s.highlighting_action_link)}> {no_highlighting ? 'off' : 'on'}</span>
                  <span>
                    {no_highlighting &&
                      <TorchOffIcon className={cn(s.highlighting_icon)} />
                    }
                    {!no_highlighting &&
                      <TorchOnIcon className={cn(s.highlighting_icon)} />
                    }
                  </span>
                </span>
              </Label>
            </div>

            <FormGroup>
              <Label className={s.form_label}>
                Phrases to highlight
              </Label>

              <span className='ml-2'>
                {add_new_phrase_link}
              </span>

              {phrases_to_highlight.map(({ phrase, colour }, idx) => {

                const need_light_fg_colour = colour ? check_need_light_complimentary_colour(colour) : false

                return (
                  <div
                    key={idx}
                    className={cn('d-flex', s.phrase_row)}
                  >
                    <input
                      className={cn('form-control', s.phrase_input)}
                      style={{
                        backgroundColor: colour,
                        ...(need_light_fg_colour ? { color: DEFAULT_TEXT_COLOUR_OVER_DARK_BG }: {})
                      }}
                      autoComplete='off'
                      autoCorrect='off'
                      spellCheck='false'
                      //placeholder={`Enter phrase here...`}
                      placeholder={``}
                      value={phrase}
                      type='text'
                      autoFocus={true} // NOTE: don't focus() on tablets, as keyboard will keep popping back up on every following navigation change.
                      onChange={(event) => on_change_phrase(phrases_to_highlight, idx, event.target.value)}
                    />

                    <TextLink
                      title='Choose colour'
                      className={cn('my-auto ml-2', s.colour_picker_button)}
                      onClick={() => set_show_phrase_colour_picker_idx(idx)}
                    >
                      <ColourPaletteIcon/>
                    </TextLink>
                    {show_phrase_colour_picker_idx === idx &&
                      <div className={s.colour_picker_popover}>
                        <div
                          className={s.colour_picker_cover}
                          onClick={() => set_show_phrase_colour_picker_idx(null)}
                        />
                        <SketchPicker
                          color={colour}
                          onChangeComplete={(new_colour) => on_change_colour(phrases_to_highlight, idx, new_colour.hex)}
                        />
                      </div>
                    }

                    <TextLink
                      title='Delete'
                      className={cn('my-auto ml-2', s.delete_phrase_button)}
                      onClick={() => on_delete_phrase(phrases_to_highlight, idx)}
                    >
                      <TrashIcon/>
                    </TextLink>

                    <TextLink
                      title='Add new phrase below this row'
                      className={cn('my-auto ml-2', s.add_phrase_below_button)}
                      onClick={() => on_add_new_phrase_to_highlight_below(phrases_to_highlight, idx)}
                    >
                      <PlusCircleIcon/>
                    </TextLink>

                  </div>
                )
              })}

            </FormGroup>

          </div>
        </div>
      </Form>
    </PageContainer>
  )
}

export default withUser(Scope)