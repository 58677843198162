import React, {useState, useEffect} from 'react'
import _ from 'underscore'
import moment from 'moment'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { DropdownItem } from 'reactstrap'

import SubscriptionsNavigation from './SubscriptionsNavigation.js'
import TextLink from '../widgets/TextLink.js'
import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import { PrimaryButton } from '../widgets/Button.js'
import { CogIcon, EditIcon, ErrorIcon, TrashIcon, HistoryIcon } from '../widgets/IconSet.js'
import EditableTextLink from '../report_management/components/EditableTextLink.js'
import { withUser } from '../UserContext.js'
import ErrorModal from '../ErrorModal.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import AlertScheduleDatePicker from './AlertScheduleDatePicker.js'
import {
  can_edit_alert_params,
  fetch_active_alerts, find_first_alert_date,
  find_next_date_for_saved_alert,
  get_alert_display_name,
  go_to_alert_report_history,
  rebuild_alert_report,
  unsubscribe_alert,
  update_alert
} from '../../utils/alert_report_utils.js'
import { is_creator, is_view_only_user } from '../../utils/user_permissions.js'
import { is_valid_report_name } from '../../utils/name_utils.js'
import { fetch_external_report_ids } from '../../utils/report_created_utils.js'
import { get_as_map, to_local_date } from '../../utils/utils.js'
import { REPORTS, REPORT, CREATE_REPORT, HOME_PAGE } from '../../constants/paths.js'
import { ALERT_FREQUENCIES, ALERT_FREQUENCIES_BY_ID, ALERT_REPORT_TYPE_NAME_DELTA } from '../../model/alert_reports.js'
import {
  ALERT_FREQUENCY_ID_QUARTERLY,
  ALERT_TYPE_ID_DELTA,
  NOTHING_TO_ALERT_DESCRIPTION
} from '../../constants/alert_reports.js'
import { EXPORT_SUBPATH } from '../../constants/viewer_paths.js'
import { ALERT_SETUP_ID } from '../../model/hyperscripts.js'
import { track_subscriptions_event } from '../../utils/tracking_utils.js'
import { ExternalTextLink } from '../widgets/ExternalTextLink.js'
import { get_alerts_help_url } from '../../utils/help_utils.js'

import s from './ReportBasedAlertSubscriptions.module.scss'


const ReportBasedAlertSubscriptions = ({user, history}) => {
  const [is_fetching, set_is_fetching] = useState(true)
  const [subscriptions, set_subscriptions] = useState([])
  const [report_id_lookup, set_report_id_lookup] = useState({})

  const [alert_to_unsubscribe_id, set_alert_to_unsubscribe_id] = useState(null)
  const [alert_to_rename_id, set_alert_to_rename_id] = useState(null)
  const [alert_to_reschedule_id, set_alert_to_reschedule_id] = useState(null)
  const [alert_to_change_freq_id, set_alert_to_change_freq_id] = useState(null)

  const [is_updating, set_is_updating] = useState(false)

  const [error_fetching_data, set_error_fetching_data] = useState(null)
  const [error_updating_subscriptions, set_error_updating_subscriptions] = useState(null)

  const subscriptions_by_id = get_as_map(subscriptions, 'alert_id')

  function load_data() {
    return fetch_active_alerts()
      .then(db_subs => {
        if (_.isEmpty(db_subs)) {
          set_is_fetching(false)
          return
        }
        const original_report_ids = db_subs.map(alert => alert.original_report_internal_id).filter(id => !!id)
        const last_alert_report_ids = db_subs.map(alert => alert.last_alert_report_internal_id).filter(id => !!id)
        return fetch_external_report_ids([...original_report_ids, ...last_alert_report_ids])
          .then(internal_to_external_report_id_mapping => {
            set_subscriptions(db_subs)
            set_report_id_lookup(internal_to_external_report_id_mapping)
            set_is_fetching(false)
          })
      })
  }

  useEffect(() => {
    let did_cancel = false
    load_data()
      .catch(err => {
        if (!did_cancel) {
          set_error_fetching_data(err)
          set_is_fetching(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [])

  function on_unsubscribe() {
    set_is_updating(true)
    track_unsubscribe()
    unsubscribe_alert(alert_to_unsubscribe_id)
      .catch(err => {
        set_is_updating(false)
        set_alert_to_unsubscribe_id(null)
        set_error_updating_subscriptions(err)
        throw err
      })
      .then(() => {
        const updated_subscriptions = subscriptions.filter(subscription => subscription.alert_id !== alert_to_unsubscribe_id)
        set_is_updating(false)
        set_alert_to_unsubscribe_id(null)
        set_alert_to_rename_id(null)
        set_alert_to_change_freq_id(null)
        set_subscriptions(updated_subscriptions)
      })
  }

  function track_unsubscribe() {
    const alert_to_unsubscribe = subscriptions_by_id[alert_to_unsubscribe_id]
    const {alert_type_id} = alert_to_unsubscribe
    const alert_type_name = alert_type_id === ALERT_TYPE_ID_DELTA ? ALERT_REPORT_TYPE_NAME_DELTA : get_alert_display_name(alert_type_id)
    track_subscriptions_event(`obj="report_based_alert" action="unsubscribe_alert" origin="alert_list" alert_type="${alert_type_name}"`)
  }

  function handle_error_updating_subscriptions(err) {
    set_error_updating_subscriptions(err)
    set_is_updating(false)
    set_alert_to_rename_id(null)
    set_alert_to_rename_id(null)
    set_alert_to_change_freq_id(null)
    throw err
  }

  function on_update_alert_name(new_name) {
    set_is_updating(true)
    track_subscriptions_event('obj="report_based_alert" action="edit_alert_report_name" origin="alert_list"')
    update_alert(alert_to_rename_id, {alert_report_name: new_name})
      .catch(err => handle_error_updating_subscriptions(err))
      .then(() => {
        const subscriptions_updated = subscriptions.map(alert => {
          if (alert.alert_id === alert_to_rename_id) {
            return { ...alert, alert_report_name: new_name }
          }
          return alert
        })
        set_is_updating(false)
        set_alert_to_rename_id(null)
        set_subscriptions(subscriptions_updated)
      })
  }

  function on_update_skip_empty_alerts(alert_id, should_skip_empty_alerts) {
    const subscriptions_before_update = subscriptions
    const subscriptions_updated = subscriptions.map(alert => {
      if (alert.alert_id === alert_id) {
        return {...alert, skip_empty_alerts: should_skip_empty_alerts}
      }
      return alert
    })
    set_subscriptions(subscriptions_updated)
    track_subscriptions_event(`obj="report_based_alert" action="set_skip_empty_alerts" origin="alert_list" value="${should_skip_empty_alerts}"`)
    update_alert(alert_id, {skip_empty_alerts: should_skip_empty_alerts})
      .catch(err => {
        set_subscriptions(subscriptions_before_update)
        handle_error_updating_subscriptions(err)
      })
  }

  function on_update_alert_frequency(alert_id, new_alert_frequency_id) {
    set_alert_to_change_freq_id(alert_id)
    set_is_updating(true)
    const new_frequency_name = ALERT_FREQUENCIES_BY_ID[new_alert_frequency_id].name
    track_subscriptions_event(`obj="report_based_alert" action="update_alert_frequency" origin="alert_list" value="${new_frequency_name}"`)
    update_alert(alert_id, {alert_frequency_id: new_alert_frequency_id})
      .catch(err => handle_error_updating_subscriptions(err))
      .then(() => {
        const subscriptions_updated = subscriptions.map(alert => {
          if (alert.alert_id === alert_id) {
            return { ...alert, alert_frequency_id: new_alert_frequency_id }
          }
          return alert
        })
        set_is_updating(false)
        set_subscriptions(subscriptions_updated)
        set_alert_to_change_freq_id(null)
      })
  }

  function on_update_next_alert_date(alert_id, new_next_alert_date) {
    set_is_updating(true)
    track_subscriptions_event('obj="report_based_alert" action="update_next_alert_date" origin="alert_list"')
    update_alert(alert_id, {next_alert: new_next_alert_date})
      .catch(err => handle_error_updating_subscriptions(err))
      .then(() => {
        const subscriptions_updated = subscriptions.map(alert => {
          if (alert.alert_id === alert_id) {
            return { ...alert, next_alert: new_next_alert_date }
          }
          return alert
        })
        set_is_updating(false)
        set_alert_to_reschedule_id(null)
        set_subscriptions(subscriptions_updated)
      })
  }

  function render_update_config_button({row: alert}) {
    const {alert_id, original_report_internal_id, is_owner, is_stopped} = alert
    const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
    const {external_report_id} = original_report || {}
    const edit_url = external_report_id ? `${REPORT}/${external_report_id}/${EXPORT_SUBPATH}/${ALERT_SETUP_ID}/${alert_id}` : null

    if (is_stopped) {
      return render_stopped_alert_message_popup(alert, true)
    }

    return (
      <TextLink
        element={edit_url && Link}
        title='Edit alert parameters'
        disable={!is_owner || !edit_url}
        to={edit_url}
      >
        <CogIcon/>
      </TextLink>
    )
  }

  function render_alert_name({row: alert}) {
    const {alert_report_name, alert_id} = alert

    if (alert_to_rename_id !== alert_id) {
      return (
        <div className='d-flex overflow-hidden'>
          <div className='overflow-hidden'>{alert_report_name}</div>
          {can_edit_alert_params(alert) &&
            <TextLink
              title='Rename'
              onClick={() => set_alert_to_rename_id(alert_id)}
              className='pl-2'
            >
              <EditIcon/>
            </TextLink>
          }
        </div>
      )
    }
    if (is_updating) {
      return <Spinner size='sm'/>
    }
    return (
      <EditableTextLink
        is_edit={true}
        link_text={alert_report_name}
        on_confirm={on_update_alert_name}
        on_cancel={() => set_alert_to_rename_id(null)}
        is_valid={is_valid_report_name}
      />
    )
  }
  
  function render_alert_frequency({row: alert}) {
    const {alert_id, alert_frequency_id} = alert
    const selected_frequency = ALERT_FREQUENCIES_BY_ID[alert_frequency_id].name
    if (is_updating && alert_id === alert_to_change_freq_id) {
      return (<Spinner size='sm'/>)
    }
    if (!can_edit_alert_params(alert)) {
      return (<div>{selected_frequency}</div>)
    }
    return (
      <BaseDropdown
        label={selected_frequency}
        className={s.alert_param_dropdown}
        buttonClassName={s.alert_param_dropdown_button}
        labelClassName={s.alert_param_dropdown_button}
      >
        {ALERT_FREQUENCIES.map(frequency => {
          return (
            <DropdownItem
              className={s.alert_param_dropdown}
              key={frequency.id}
              toggle={true}
              disabled={is_updating || frequency.id === alert_frequency_id}
              onClick={() => on_update_alert_frequency(alert_id, frequency.id)}
            >
              {frequency.name}
            </DropdownItem>
          )
        })}
      </BaseDropdown>
    )
  }

  function render_skip_empty_alerts({row: alert}) {
    const {alert_id, skip_empty_alerts} = alert
    return (
      <div className='mx-auto'>
        <CheckboxStatic is_checked={!skip_empty_alerts} onClick={() => on_update_skip_empty_alerts(alert_id, !skip_empty_alerts)}/>
      </div>
    )
  }

  function render_stopped_alert_message_popup(alert, icon_only) {
    const {original_report_internal_id} = alert
    const can_fix_alert = can_edit_alert_params(alert) && is_creator(user)
    const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
    const {external_report_id} = original_report || {}
    return (
      <InfoPopover
        interactive={true}
        toggler={icon_only ? <ErrorIcon/> : <span className='ml-2'><ErrorIcon/>&nbsp;Alert paused</span>}
        placement='bottom'
      >
        <div className='text-wrap'>
          <div className='mb-2'>
            This alert is currently paused because there is a problem with its input{can_fix_alert ? '.' : ', and the owner has been notified.'}
          </div>
          <div className='mb-2'>
            It is likely that something has changed with the organisations or technologies from the original report, and these parameters now need updating to resume the alert.
          </div>
          {can_fix_alert &&
            <TextLink
              element='div'
              onClick={() => rebuild_alert_report(alert, external_report_id, history)}
            >
              Create {external_report_id ? 'an updated' : 'a new'} base report to update the alert
            </TextLink>
          }
        </div>
      </InfoPopover>
    )
  }

  function render_next_alert_due({row: alert}) {
    const {alert_id, is_stopped} = alert
    const next_alert_date = find_next_date_for_saved_alert(alert)
    const max_date = find_first_alert_date(ALERT_FREQUENCY_ID_QUARTERLY)
    const today_date = moment(new Date()).startOf('day').toDate()

    if (is_stopped) {
      return render_stopped_alert_message_popup(alert)
    }

    if (!can_edit_alert_params(alert)) {
      return <div className={s.next_alert_date}>{to_local_date(next_alert_date)}</div>
    }

    return (
      <AlertScheduleDatePicker
        id={alert_id}
        min_date={today_date}
        max_date={max_date}
        on_select={selected_date => on_update_next_alert_date(alert_id, selected_date)}
        selected_date={next_alert_date}
        on_open={() => set_alert_to_reschedule_id(alert_id)}
        is_open={alert_id === alert_to_reschedule_id}
        handle_close={() => set_alert_to_reschedule_id(null)}
      />
    )
  }

  function render_unsubscribe_link({row: alert}) {
    const {alert_id} = alert
    return (
      <TextLink
        title='Unsubscribe'
        onClick={() => set_alert_to_unsubscribe_id(alert_id)}
      >
        <TrashIcon/>
      </TextLink>
    )
  }

  function render_history_link({row: alert}) {
    return (
      <TextLink
        title='History for this alert'
        onClick={() => go_to_alert_report_history(alert, history)}
      >
        <HistoryIcon/>
      </TextLink>
    )
  }

  function render_recent_report_link({row: alert}) {
    const {last_alert_report_internal_id, last_alert} = alert
    const last_alert_report = last_alert_report_internal_id ? report_id_lookup[last_alert_report_internal_id] : {}
    const {external_report_id} = last_alert_report || {}
    return render_report_link(to_local_date(last_alert), external_report_id)
  }

  function render_original_report_link({row: alert}) {
    const {original_report_internal_id} = alert
    const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
    const {external_report_id, created_at} = original_report || {}
    return render_report_link(to_local_date(created_at), external_report_id)
  }

  function render_report_link(link_text, external_report_id) {
    if (external_report_id) {
      return (
        <TextLink
          element={Link}
          to={`${REPORT}/${external_report_id}`}
        >
          {link_text}
        </TextLink>
      )
    }
    return ''
  }

  const columns = [
    {
      field: 'alert_report_name',
      headerName: 'Alert name',
      renderCell: render_alert_name,
      minWidth: 200,
      flex: 1
    },
    {
      field: 'edit_params',
      headerName: 'Edit',
      renderCell: render_update_config_button,
      maxWidth: 50,
      align: 'center',
      sortable: false
    },
    {
      field: 'alert_type',
      headerName: 'Alert type',
      valueGetter: ({row: alert}) => {
        const {alert_type_id} = alert
        return get_alert_display_name(alert_type_id)
      },
      editable: false,
      minWidth: 140,
    },
    {
      field: 'alert_frequency_id',
      headerName: 'Alert frequency',
      cellClassName: s.field_with_overflow,
      renderCell: render_alert_frequency,
      minWidth: 110,
    },
    {
      field: 'original_report',
      headerName: 'Original report',
      renderCell: render_original_report_link,
      valueGetter: ({row: alert}) => {
        const {original_report_internal_id} = alert
        const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
        const {created_at} = original_report || {}
        return created_at
      },
      align: 'center',
      minWidth: 120
    },
    {
      field: 'last_alert',
      headerName: 'Most recent alert',
      renderCell: render_recent_report_link,
      align: 'center',
      minWidth: 120
    },
    {
      field: 'history',
      headerName: 'Reports',
      renderCell: render_history_link,
      align: 'center',
      maxWidth: 70,
      sortable: false
    },
    {
      field: 'next_alert',
      headerName: 'Next alert due',
      cellClassName: s.field_with_overflow,
      renderCell: render_next_alert_due,
      minWidth: 122,
    },
    {
      field: 'skip_empty_alerts',
      sortable: false,
      renderHeader: () => {
        return (
          <>
            Empty alerts
            <InfoPopover buttonClassName='ml-1' placement='bottom'>
              {NOTHING_TO_ALERT_DESCRIPTION}.
            </InfoPopover>
          </>
        )
      },
      renderCell: render_skip_empty_alerts,
      minWidth: 115
    },
    {
      field: 'remove',
      headerName: 'Remove',
      renderCell: render_unsubscribe_link,
      sortable: false,
      align: 'center',
      maxWidth: 70
    }
  ]

  const alert_to_unsubscribe = alert_to_unsubscribe_id ? _.findWhere(subscriptions, {alert_id: alert_to_unsubscribe_id}).alert_report_name : null
  const has_alerts_to_show = subscriptions && !_.isEmpty(subscriptions)

  return (
    <div className='w-100'>
      {(error_updating_subscriptions || error_fetching_data) &&
        <ErrorModal
          on_hide={() => error_updating_subscriptions ? set_error_updating_subscriptions(null) : error_fetching_data(null)}
          error={error_updating_subscriptions || error_fetching_data}
          context={error_updating_subscriptions ? 'updating subscriptions' : 'fetching subscriptions data'}
        />
      }

      {alert_to_unsubscribe_id &&
        <Modal
          title={`Unsubscribing from '${alert_to_unsubscribe}'`}
          on_hide={() => set_alert_to_unsubscribe_id(null)}
          close_label='Cancel'

          confirm_label='Unsubscribe'
          on_confirm={on_unsubscribe}
          className={s.small_modal}
          footer={
            <>
              {is_updating &&
                <Spinner size='sm'/>
              }
              <PrimaryButton onClick={on_unsubscribe} disabled={is_updating}>
                Unsubscribe
              </PrimaryButton>
            </>
          }
        >
          Are you sure you want to remove this alert?
        </Modal>
      }

      <SubscriptionsNavigation
        current_page={REPORTS}
      />

      <div className='w-100 h-100'>
        {is_fetching &&
          <div className='text-center'>
            <Spinner/>
          </div>
        }

        {!is_fetching && !error_fetching_data && !has_alerts_to_show &&
          <div className='p-5'>
            {is_view_only_user(user) &&
              <p>You don't have any active report-based alerts.</p>
            }
            {!is_view_only_user(user) &&
              <>
                <p>To begin monitoring organisations, technologies or patent families,&nbsp;
                  <TextLink element={Link} to={is_creator(user) ? CREATE_REPORT : HOME_PAGE}>
                    build a report
                  </TextLink>
                  &nbsp;and then choose 'Create an alert' from the Exports menu.
                </p>
                <ExternalTextLink url={get_alerts_help_url()} target='_blank'>
                  Help centre article
                </ExternalTextLink>
              </>
            }
          </div>
        }

        {!is_fetching && !error_fetching_data && has_alerts_to_show &&
          <DataGridPro
            rows={subscriptions}
            columns={columns}
            className='px-4 pb-1'

            getRowId={row => row.alert_id}
            getRowClassName={params => params.row.is_stopped ? s.stopped_alert_row: ''}

            rowHeight={30}
            hideFooterRowCount={true}
            hideFooterSelectedRowCount={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            disableMultipleSelection={true}
            disableExtendRowFullWidth={true}

            classes={{root: s.grid, cell: s.cell, 'cell--editing': s.cell__editing}}
          />
        }
      </div>
    </div>
  )
}

export default withRouter(withUser(ReportBasedAlertSubscriptions))