import React from 'react'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import { COMPARABLES_MODES, COMPARABLES_MODES_BY_ID } from '../../model/organisation.js'

import s from './OrgSuggestionsSwitch.module.scss'

export const OrgSuggestionsSwitch = ({selected_mode, set_org_suggestions_mode_handler, disabled}) => {
  return (
    <UncontrolledDropdown disabled={disabled}>
      <SelectDropdownToggleStatic chevron_left className={s.switch} disabled={disabled}>
        {COMPARABLES_MODES_BY_ID[selected_mode].label}
      </SelectDropdownToggleStatic>
      <DropdownMenu>
        {COMPARABLES_MODES.map((mode,i) => (
          <DropdownItem key={i} onClick={() => set_org_suggestions_mode_handler(mode.id)}>
            {mode.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}