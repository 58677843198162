import React from 'react'

import { PaneHeader } from '../widgets/PaneHeader.js'
import OrgTagSelector from './OrgTagSelector.js'
import TextLink from '../widgets/TextLink.js'

const OrgTags = ({org_tags, on_tag_add_handler, on_tag_remove_handler, on_clear_handler}) => {

  const title = `Tags (${(org_tags || []).length})`
  const has_tags = (org_tags && org_tags.length > 0)

  return (
    <div>
      <div className='d-flex'>
        <PaneHeader text={title}/>
        {has_tags && <TextLink className='ml-1 my-auto' onClick={on_clear_handler}>[clear]</TextLink>}
      </div>

      <div className='d-flex'>
        <OrgTagSelector
          selected_tags={org_tags}
          on_select_handler={on_tag_add_handler}
          on_deselect_handler={on_tag_remove_handler}
          className='mr-3'
        />
      </div>
    </div>
  )

}

export default OrgTags