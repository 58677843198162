import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {
  DropdownItem,
  DropdownMenu,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
} from 'reactstrap'
import * as peg from 'pegjs'
import cn from 'classnames'
import _ from 'underscore'

import {withUser} from '../UserContext.js'
import {PrimaryButton} from '../widgets/Button.js'
import {PaneHeader} from '../widgets/PaneHeader'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import {FormFeedback} from '../widgets/FormFeedback.js'
import ErrorModal from '../ErrorModal.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import Tooltip from '../widgets/Tooltip.js'
import {InfoIcon} from '../widgets/IconSet.js'
import CheckboxStatic from '../widgets/CheckboxStatic'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import AutoResizeTextArea from '../widgets/AutoResizeTextArea.js'
import ReportManagementTable from '../report_management/components/ReportManagementTable'
import SetTheoryReportBuilderOptionsModal from './SetTheoryReportBuilderOptionsModal'
import {DESCENDING} from '../../model/sort_directions'
import {DEFAULT_PAGE_SIZE, PAGE_SIZES} from '../report_management/model/page_sizes'
import PageSizeControl from '../PageSizeControl'
import PageControl from '../PageControl'
import {
  CREATED_AT_FIELD_ID,
  LAST_VIEWED_FIELD_ID,
  NAME_FIELD_ID,
  OWNER_FIELD_ID,
  STATUS_FIELD_ID,
  IS_SELECTED_FIELD_ID, ID_TO_REPORT_FIELD
} from '../report_management/model/report_fields'
import TextLink from '../widgets/TextLink'
import {REPORT} from '../../constants/paths'
import {NO_FILTER} from '../report_management/model/filters'
import {is_creator} from '../../utils/user_permissions.js'
import {fetch_report_history, fetch_report_owners} from '../../utils/report_history_utils.js'
import {
  filter_reports,
  get_new_selected_external_report_ids,
  sort_reports
} from '../report_management/utils/sort_and_filter_utils.js'
import {GROUP_MODES} from "../../utils/ocypod_utils";
import {differentiated_union_formula, PresetDropdownItem} from './model'
import {track_report_management_event} from '../../utils/tracking_utils'
import {pluralise_text} from '../../utils/utils'
import {send_error_to_sentry} from '../../utils/sentry_utils'
import {useInterval} from '../../hooks/general_hooks'
import {ObjectMap} from 'Cipher'
import ReportBuilderNoAccessPanel from '../builder/ReportBuilderNoAccessPanel.js'

import s from './SetTheoryReportBuilder.module.scss'

const op_regex = new RegExp('(R[0-9]+)', 'g')

interface SetTheoryReportBuilderProps {
  user: any
}
const SetTheoryReportBuilder = (props: SetTheoryReportBuilderProps) => {
  /* Modal component that allows a user to create a new report via a set theory formula of exiting reports and grouped by various ordering mechanisms. */
  const MAX_REPORTS_FROM_HISTORY: number = 100
  const TRACKING_CONTEXT = 'report_history'
  const BENCHMARKING_INFO_TEXT = 'Select the company report first (R1) and then the landscape report (R2).'

  const polling_time = 60 * 1000
  const parser = peg.generate(`
    Term = Primary (Ws Op Ws Primary)*
    Op = Union / Intersection / Sub / SymSub
    Union = "∪"
    Intersection = "∩"
    Sub = "-"
    SymSub = "△"
    Primary = Parens / Operand
    Parens = "(" Ws Term Ws ")"
    Operand = "R"[0-9]+
    Ws = [ \\t\\n\\r]*
  `)
  const operators: ObjectMap<string> = {
    'Union "u"': '∪',
    'Intersection "i"': '∩',
    'Difference "d"': '-',
    'Symmetric Difference "s"': '△'
  }
  const operators_key_map: ObjectMap<string> = {
    'KeyU': '∪',
    'KeyI': '∩',
    'KeyD': '-',
    'KeyS': '△'
  }
  const formula_input_ref = useRef<HTMLInputElement>(null)

  const max_preset = 100
  const preset_options : PresetDropdownItem[] = [
    {
      name: 'CPC Merge',
      description: 'Perform set union over the number of operands, group patent families by dominant CPC. ' +
        'Portfolio grouping is done by report creation date in descending order.',
      fn_formula_generation: (op_nr: number) => [...Array(op_nr).keys()].map(idx => `R${idx+1}`).join(' ∪ '),
      group_portfolio_mode: GROUP_MODES.REPORT_TIMESTAMP,
      group_tech_mode: GROUP_MODES.CPC
    },
    {
      name: 'CPC Merge (No duplicate)',
      description: 'Perform set union over the number of operands, group patent families by dominant CPC and ignore portfolio grouping.',
      fn_formula_generation: (op_nr: number) => [...Array(op_nr).keys()].map(idx => `R${idx+1}`).join(' ∪ '),
      group_portfolio_mode: GROUP_MODES.ALL,
      group_tech_mode: GROUP_MODES.CPC
    },
    {
      name: 'Classifier merge',
      description: 'Technology grouping is done by operand ascending order (e.g. for R1, R2 the tech grouping of R1 is selected first and the remainder from R2). ' +
        'Portfolio grouping is done by report creation date in descending order.',
      fn_formula_generation: differentiated_union_formula,
      group_portfolio_mode: GROUP_MODES.REPORT_TIMESTAMP,
      group_tech_mode: GROUP_MODES.OP_ASC
    },
    {
      name: 'Classifier merge (No duplicate)',
      description: 'Technology grouping is done by operand ascending order (e.g. for R1, R2 the tech grouping of R1 is selected first and the remainder from R2). ' +
        'Portfolio grouping is ignored.',
      fn_formula_generation: differentiated_union_formula,
      group_portfolio_mode: GROUP_MODES.ALL,
      group_tech_mode: GROUP_MODES.OP_ASC
    }
  ]
  const benchmarking_preset: PresetDropdownItem = {
    name: 'Benchmarking',
    description: 'Merge a company report and a landscape report.',
    fn_formula_generation: differentiated_union_formula,
    group_portfolio_mode: GROUP_MODES.OP_ASC,
    group_tech_mode: GROUP_MODES.OP_ASC
  }

  const table_fields = [
    IS_SELECTED_FIELD_ID,
    NAME_FIELD_ID,
    OWNER_FIELD_ID,
    CREATED_AT_FIELD_ID,
    LAST_VIEWED_FIELD_ID,
    STATUS_FIELD_ID,
  ].map(field_id => ID_TO_REPORT_FIELD[field_id])

  const [reports, set_reports] = useState<Array<any>>([])
  const [report_owners, set_report_owners] = useState<ObjectMap<string>>({})
  const [is_fetching_owners, set_is_fetching_owners] = useState<boolean>(false)
  const [formula, set_formula] = useState<string>('')
  const [operands, set_operands] = useState<ObjectMap<string>>({})
  const [preset_report_nr, set_preset_report_nr] = useState<number>(1)
  const [group_tech_mode, set_group_tech_mode] = useState<GROUP_MODES>(GROUP_MODES.CPC)
  const [group_portfolio_mode, set_group_portfolio_mode] = useState<GROUP_MODES>(GROUP_MODES.REPORT_TIMESTAMP)

  const [is_options_modal_open, set_is_options_modal_open] = useState<boolean>(false)
  const [formula_parse_error, set_formula_parse_error] = useState<string | null>(null)
  const [error_message, set_error_message] = useState<string | null>(null)

  // Table state
  const [is_fetching, set_is_fetching] = useState(true)
  const [sort_field_id, set_sort_field_id]         = useState(CREATED_AT_FIELD_ID)
  const [sort_direction_id, set_sort_direction_id] = useState(DESCENDING)
  const [page_size, set_page_size]                 = useState(DEFAULT_PAGE_SIZE)
  const [page_number, set_page_number]             = useState(0)

  const [report_search_input, set_report_search_input] = useState('')
  const [selected_reports_external_ids, set_selected_reports_external_ids] = useState<string[]>([])
  const [selected_reports, set_selected_reports] = useState<any[]>([])

  const reports_filtered = filter_reports(reports, report_owners, report_search_input, NO_FILTER, null, null)
  const reports_sorted = sort_reports(reports_filtered, report_owners, sort_field_id, sort_direction_id)
  const has_data_rows = reports_filtered && reports_filtered.length > 0

  const start_index = page_number * page_size
  const page_reports = !has_data_rows ? [] : reports_sorted.slice(start_index, start_index + page_size)
  const num_pages = !has_data_rows ? 0 : Math.ceil(reports_filtered.length / page_size)

  const page_reports_with_owner_names: Array<any> = page_reports.map((r:any) => ({...r, owner_name: report_owners[r.external_report_id]}))

  // Temp view states.
  const [benchmarking_view, set_benchmarking_view] = useState<boolean>(false)

  function on_change_benchmarking_view() {
    set_benchmarking_view(benchmarking_view => !benchmarking_view)
    set_preset_report_nr(2)
  }

  function on_formula_input_button_press(key: string): void {
    const dom_el = formula_input_ref.current
    if (dom_el?.selectionStart && dom_el?.selectionEnd) {
      set_formula(`${formula.substr(0, dom_el.selectionStart)}${key}${formula.substr(dom_el.selectionEnd)}`)
    } else {
      set_formula(formula + key)
    }
    dom_el?.focus()
  }

  function replace_shortcut_with_formula_key(event: any): void {
    if (_.contains(_.keys(operators_key_map), event.code)){
      event.preventDefault()
      on_formula_input_button_press(operators_key_map[event.code])
    }
  }

  function on_preset_report_nr_input_change(event: ChangeEvent<HTMLInputElement>): void {
    set_preset_report_nr(Math.max(Math.min(parseInt(event.target.value), max_preset), 1))
  }

  function on_preset_selection(obj: PresetDropdownItem): void {
    set_formula(obj.fn_formula_generation(preset_report_nr))
    set_group_tech_mode(obj.group_tech_mode)
    set_group_portfolio_mode(obj.group_portfolio_mode)
  }

  function is_form_valid(): boolean {
    return !formula_parse_error && !!formula && !is_fetching
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id: string, sort_direction_id: string) {
    set_sort_field_id(sort_field_id)
    set_sort_direction_id(sort_direction_id)
  }

  function toggle_selected(reports_to_toggle: any[], is_selected: boolean) {
    track_report_management_event(`obj="${pluralise_text(reports_to_toggle.length, 'report')}" action="${is_selected ? 'select': 'deselect'}" context="${TRACKING_CONTEXT}"`)
    const reports_to_toggle_external_ids = reports_to_toggle.map(report => report.external_report_id)
    const selected_reports_external_ids_updated = get_new_selected_external_report_ids(reports_to_toggle_external_ids, is_selected, selected_reports_external_ids)
    set_selected_reports_external_ids(selected_reports_external_ids_updated)

    let new_selected_reports = selected_reports
    if (is_selected) {
      new_selected_reports = _.union(new_selected_reports, reports_to_toggle)
    } else {
      new_selected_reports = _.reject(new_selected_reports,
        (report)=> _.find(reports_to_toggle, r => r.external_report_id === report.external_report_id))
    }
    set_selected_reports(new_selected_reports)
  }

  function load_reports(): void {
    set_is_fetching(true)
    if (!is_fetching_owners) {
      asynchronously_fetch_report_owners()
    }
    fetch_report_history(MAX_REPORTS_FROM_HISTORY, true, false, true)
      .then(data => {
        set_reports(data)
      })
      .catch(err => set_error_message(err))
      .finally(() => {
        set_is_fetching(false)
      })
  }

  useInterval(load_reports, polling_time, true)

  function asynchronously_fetch_report_owners() {
    set_is_fetching_owners(true)
    fetch_report_owners()
      .then(report_id_to_owner => {
        set_report_owners(report_id_to_owner)
        set_is_fetching_owners(false)
      })
      .catch(err => {
        set_is_fetching_owners(false)
        send_error_to_sentry(err, {}) // names are non-essential metadata; don't stop the user from getting on with things
      })
  }

  useEffect(() => {
    // Display errors from PEG parser if any
    try {
      if (formula){
        parser.parse(formula)
        let matches = formula.match(op_regex)
        let missing = new Set()
        // @ts-expect-error
        for (let match of matches) {
          let is_operand_defined = _.some(
            selected_reports,
            (x: string, idx: number) => `R${idx+1}` === match
          )
          if (!is_operand_defined) {
            missing.add(match)
          }
        }
        if (missing.size > 0) {
          let singular = missing.size === 1
          set_formula_parse_error(`Report${singular ? '': 's'} [ ${[...missing].join(', ')} ] ${singular ? 'is' : 'are'} not defined.`)
        }
        else {
          set_formula_parse_error(null)
        }
      } else {
        set_formula_parse_error(null)
      }
    } catch (e: any) {
      set_formula_parse_error(`${e.message} @ [offset: ${e.location.start.offset}, char_pos: ${e.location.start.column}]`)
    }
  }, [parser, formula, selected_reports])

  useEffect(() => {
    const ops: ObjectMap<string> = {}
    selected_reports.forEach((k: any, idx: number) => {
      ops[`R${idx+1}`] = k.internal_report_id
    })
    set_operands(ops)
  }, [selected_reports])

  useEffect(() => {
    if (!benchmarking_view) {
      set_preset_report_nr(selected_reports.length)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_reports])

  useEffect(() => {
    if (benchmarking_view) {
      on_preset_selection(benchmarking_preset)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset_report_nr, benchmarking_view])

  useEffect(() => {
    if (benchmarking_view && selected_reports_external_ids.length > 2 && selected_reports.length > 2) {
      const new_selected_reports = selected_reports.slice(0,2)
      set_selected_reports(new_selected_reports)
      set_selected_reports_external_ids(new_selected_reports.map(report => report.external_report_id))
    }
  }, [selected_reports_external_ids, selected_reports, benchmarking_view])

  function render(): JSX.Element {
    if (error_message) {
      return (
        // @ts-expect-error
        <ErrorModal error={error_message}
                    on_hide={() => set_error_message(null)}
                    context={'building set theory report'}
        />
      )
    }
    if (!is_creator(props.user)) {
      return (
        <ReportBuilderNoAccessPanel/>
      )
    } else {
      return (
        <ContainerFullWidth className='p-0'>
          <div className='h-100 w-100 m-0 d-flex'>
            <div className={cn('order-lg-0 d-lg-flex flex-column', s.input_col)}>
              <div className='m-0 mt-3 w-100 d-flex flex-column justify-content-start'>
                <div className='ml-4 mr-4'>
                  <div className='w-100 d-flex flex-row justify-content-between' style={{height:'40px'}}>
                    {/*// @ts-expect-error*/}
                    <PaneHeader text={'Formula'} />
                    {!benchmarking_view &&
                      <InputGroupAddon addonType={'append'}>
                        {Object.keys(operators).map((k: string) =>
                          <PrimaryButton title={k} key={k}
                                         className={s.operator_button}
                                         onClick={() => on_formula_input_button_press(operators[k])}
                                         outline={true}>
                            {operators[k]}
                          </PrimaryButton>
                        )}
                      </InputGroupAddon>
                    }
                  </div>
                  {/*// @ts-expect-error*/}
                  <AutoResizeTextArea value={formula || ''}
                                      on_change={set_formula}
                                      on_key_press={replace_shortcut_with_formula_key}
                                      rows={4}
                                      required={true}
                                      ref={formula_input_ref}
                                      className={s.text_area}
                                      disabled={benchmarking_view}/>
                  <FormFeedback valid={!formula_parse_error}
                                validation_text={formula_parse_error}
                                style={{wordBreak: 'break-word'}}/>
                  {benchmarking_view &&
                    <div>{BENCHMARKING_INFO_TEXT}</div>
                  }
                </div>
              </div>
              <div className='m-0 mt-3 mb-3 w-100 d-flex flex-column justify-content-start overflow-auto'
                   style={{flex: '1 1 calc(1vh - 20.75rem)'}}>
                <div className='ml-4 mr-4'>
                  {/*// @ts-expect-error*/}
                  <PaneHeader text='Report History' />
                  <div className={cn('d-flex', 'flex-wrap', 'align-items-center')}>
                    {/*// @ts-expect-error*/}
                    <ClearableSearchInput
                      containerClassName={cn('d-flex', 'flex-grow-1', 'mr-2')}
                      value={report_search_input}
                      auto_focus={false}
                      handle_change={set_report_search_input}
                      show_clear={true}
                    />
                    {/* Right aligned items */}
                    <div className={cn('d-flex', 'align-items-center', 'ml-auto')}>
                      <PageSizeControl
                        className={cn('')}
                        page_sizes={PAGE_SIZES}
                        selected_page_size={page_size}
                        on_change_page_size={set_page_size}
                      />
                      <PageControl
                        className={cn('ml-3')}
                        current_page={page_number}
                        num_pages={num_pages}
                        on_change_current_page={set_page_number}
                      />
                    </div>
                  </div>
                  <ReportManagementTable
                    className={cn('mt-3')}
                    fields={table_fields}

                    field_id_to_render_fn={{
                      [NAME_FIELD_ID]: function NameField(report: any) {
                        return (
                          // @ts-expect-error
                          <TextLink
                            element='a'
                            className={s.report_ext_btn}
                            href={`${REPORT}/${report.external_report_id}`}
                            target='_blank'
                            title='Open in new tab'>
                            {report.title}
                          </TextLink>
                        )
                      }
                    }}

                    field_id_to_className={{
                      [NAME_FIELD_ID]: s.report_name_field,
                    }}
                    reports={page_reports_with_owner_names}
                    selected_external_report_ids={selected_reports_external_ids}
                    selected_sort_field_id={sort_field_id}
                    selected_sort_direction_id={sort_direction_id}
                    on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}

                    user={props.user}
                    toggle_selected={toggle_selected}
                    no_data_text={is_fetching ? null : ((report_search_input) ? 'No search results found' : 'Start creating reports to build your report history')}
                    loading_text='Loading report history'
                    loading={is_fetching}
                  />
                </div>
              </div>
            </div>
            <div className={cn('order-lg-1 h-100 w-100 m-0 d-flex flex-column justify-content-between', s.info_col)}>
              <div className='d-flex flex-column ml-4 mr-4'>
                {!benchmarking_view &&
                  <React.Fragment>
                    {/*// @ts-expect-error*/}
                    <PaneHeader text='Presets (Optional)' className={'mt-3 mb-2'}/>
                    <InputGroup>
                      <InputGroupText>Number of reports</InputGroupText>
                      <Input id='preset_report_nr'
                             value={preset_report_nr}
                             type='number'
                             onChange={on_preset_report_nr_input_change}
                             min={0}
                             max={100}/>
                    </InputGroup>
                    <UncontrolledDropdown size='md' className='rounded-0 mt-2'>
                      <SelectDropdownToggleStatic className='w-100 d-flex justify-content-center'>
                        Select preset
                      </SelectDropdownToggleStatic>
                      <DropdownMenu className='w-100'>
                        {preset_options.map((obj: PresetDropdownItem, idx: number) =>
                          <DropdownItem key={idx}
                                        onClick={() => on_preset_selection(obj)}
                                        className='d-flex flex-row flex-nowrap justify-content-between'>
                            {obj.name}
                            {/*// @ts-expect-error*/}
                            <Tooltip toggler={<span className={`my-auto ml-1`}><InfoIcon /></span>}
                                     is_in_modal={true}
                                     placement={'bottom'}>
                              {obj.description}
                            </Tooltip>
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </React.Fragment>
                }

                <div className='d-flex flex-row mt-2'>
                  {/*//@ts-expect-error*/}
                  <CheckboxStatic
                    onClick={() => on_change_benchmarking_view()}
                    is_checked={benchmarking_view}
                  />
                  <div className={cn('ml-1')}>Benchmarking mode</div>
                </div>

                {/*// @ts-expect-error*/}
                <PaneHeader text='Selected reports' className={'mt-2 mb-2'}/>
                {!_.isEmpty(selected_reports)? (
                  <table className={'table table-sm'} style={{
                    background: 'white'
                  }}>
                    <thead>
                    <tr>
                      <th>Operand</th>
                      <th>Report</th>
                    </tr>
                    </thead>
                    <tbody>
                    {selected_reports.map((report, idx) => (
                      <tr key={idx}>
                        <td>R{idx+1}</td>
                        <td>
                          {/*@ts-expect-error*/}
                          <TextLink
                            element='a'
                            className={s.report_ext_btn}
                            href={`${REPORT}/${report.external_report_id}`}
                            target='_blank'
                            title='Open in new tab'>
                            {report.title}
                          </TextLink>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                ) : (
                  'No reports selected.'
                )}
              </div>
              <PrimaryButton onClick={()=> set_is_options_modal_open(true)}
                             disabled={!is_form_valid()}
                             className='w-100'>
                <div>Next</div>
              </PrimaryButton>
            </div>
          </div>

          {is_options_modal_open ? (
            <SetTheoryReportBuilderOptionsModal
              on_hide={()=> {
                set_is_options_modal_open(false)
              }}
              is_open={is_options_modal_open}
              formula={formula}
              operands={operands}
              group_tech_mode={group_tech_mode}
              group_portfolio_mode={group_portfolio_mode}
              benchmarking_view={benchmarking_view}
            />
          ) : null}
        </ContainerFullWidth>
      )
    }
  }

  return render()
}

export default withUser(SetTheoryReportBuilder)