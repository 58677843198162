import React from 'react'
import cn from 'classnames'

import Spinner from './Spinner.js'

/**
 * Renders a light-coloured (whiteish) Spinner.
 * Currently ReactSpinner does not allow us to inject css classes,
 * so colouring it involves using global css (see " react-spinner.override.scss")
 * Perhaps we should consider moving to a different library.
 * @param {} size
 * @param {} className
 * @param {} innerClassName
 * @returns {} 
 */
const LightSpinner = ({ size, className, innerClassName }) => {
  return (
    <Spinner
      className={cn('cipher-spinner-light-1', className)}
      size={size}
      innerClassName={innerClassName}
    />
  )
}

export default LightSpinner
