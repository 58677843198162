import {
  AVERAGE_COST_BY_AGE_ID,
  AVERAGE_COST_BY_ORG_AND_TECH_ID,
  AVERAGE_COST_BY_PORTFOLIO_ID,
  AVERAGE_COST_BY_TECH_ID,
  COST_BY_COUNTRY_BY_YEAR_ID,
  COST_BY_COUNTRY_ID,
  COST_BY_PORTFOLIO_BY_COUNTRY_ID,
  COST_BY_PORTFOLIO_BY_TECH_ID,
  COST_BY_PORTFOLIO_BY_YEAR_ID,
  COST_BY_REGION_ID,
  COST_BY_TECH_BY_YEAR_ID,
  COST_BY_TYPE_BY_YEAR_ID,
  FUTURE_COST_BY_COUNTRY_ID
} from '../spec_ids.js'
import { TAG_GEO, TAG_ORG, TAG_TECH, TAG_TIME } from './spec_tags.js'
import { KEY_COUNTRY, KEY_ORGANISATION, KEY_REGION, KEY_TECHNOLOGY, KEY_YEAR } from './spec_keys.js'
import { COST_GROUP_ID } from './spec_group_ids.js'
import {DEFAULT_RECENT_LAST_FEW_YEARS_COUNT, DEFAULT_RECENT_LAST_FEW_YEARS_COUNT_LABEL} from '../time_ranges.js'
import {
  DEFAULT_1D_NON_TIMESERIES_AVG_VIEW,
  DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS,
  DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
  DEFAULT_1D_NON_TIMESERIES_VIEW,
  DEFAULT_1D_NON_TIMESERIES_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEW,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_VIEW,
  DEFAULT_2D_NON_TIMESERIES_VIEWS
} from '../views.js'
import { BAR_STACK_ID, COLUMN_CLUSTER_ID, COLUMN_STACK_ID, HEATMAP_ID, LINE_ID, TABLE_ID } from '../view_ids.js'
import { not_available } from '../../utils/data_availability_utils.js'
import { get_tabular_view_cost_total } from '../../utils/tabular_view_utils.js'

const HELP_ARTICLE = '28888647088147-Cost-charts-datasets'


export const COST_GROUP =   {
  id: COST_GROUP_ID,
  name: 'Cost',
  short_name: 'Cost',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: AVERAGE_COST_BY_PORTFOLIO_ID,
      name: 'average cost per family',
      short_name: 'average cost family',
      description: 'Overall average cost per patent family, split by organisation. This is calculated from priority year until today. (Only applies for families with an age of 10 years and older to ensure an accurate representation of the average cost per family.)',
      help_article: `${HELP_ARTICLE}#h_673e30aa83`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_AVG_VIEW,
    },
    {
      spec_id: AVERAGE_COST_BY_TECH_ID,
      name: 'average cost per family',
      short_name: 'average cost family',
      description: 'Overall average cost per patent family, split by technology. This is calculated from priority year until today. (Only applies for families with an age of 10 years and older to ensure an accurate representation of the average cost per family.)',
      help_article: `${HELP_ARTICLE}#h_05c758fb85`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS,
      default_view_id: BAR_STACK_ID,
    },
    {
      spec_id: AVERAGE_COST_BY_ORG_AND_TECH_ID,
      name: 'average cost per family',
      short_name: 'average cost family',
      description: 'Overall average cost per patent family, split by organisation and technology. This is calculated from priority year until today. (Only applies for families with an age of 10 years and older to ensure an accurate representation of the average cost per family.)',
      help_article: `${HELP_ARTICLE}#h_7657c25645`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: COST_BY_PORTFOLIO_BY_TECH_ID,
      name: `${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT_LABEL} year average cost`,
      short_name: `${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} year av cost`,
      description: `Average yearly cost for the last ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} full years.`,
      help_article: `${HELP_ARTICLE}#h_93672b1426`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: COST_BY_PORTFOLIO_BY_COUNTRY_ID,
      name: `${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT_LABEL} year average cost`,
      short_name: `${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} year av cost`,
      description: `Average yearly cost for the last ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} full years.`,
      help_article: `${HELP_ARTICLE}#h_d18b7467eb`,
      by: [KEY_ORGANISATION, KEY_COUNTRY],
      tags: [TAG_ORG, TAG_GEO],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: COST_BY_PORTFOLIO_BY_YEAR_ID,
      name: 'total cost',
      description: 'Total costs per year, for each organisation. This includes both prosecution and renewal costs, depending on the status of each family in each year.',
      help_article: `${HELP_ARTICLE}#h_9a17625477`,
      by: [KEY_ORGANISATION, KEY_YEAR],
      tags: [TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEW,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      spec_id: COST_BY_TECH_BY_YEAR_ID,
      name: 'total cost',
      description: 'Total costs per year, for each technology. This includes both prosecution and renewal costs, depending on the status of each family in each year.',
      help_article: `${HELP_ARTICLE}#h_41531b2768`,
      by: [KEY_TECHNOLOGY, KEY_YEAR],
      tags: [TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEW,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      spec_id: COST_BY_COUNTRY_BY_YEAR_ID,
      name: 'total cost',
      description: 'Total costs per territory and year.',
      help_article: `${HELP_ARTICLE}#h_d0e34b2436`,
      by: [KEY_COUNTRY, KEY_YEAR],
      tags: [TAG_TIME, TAG_GEO],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEW,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      spec_id: COST_BY_COUNTRY_ID,
      name: 'total cost',
      description: 'Total costs per territory.',
      help_article: `${HELP_ARTICLE}#h_5b097fc19b`,
      by: [KEY_COUNTRY],
      tags: [TAG_GEO],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      spec_id: COST_BY_REGION_ID,
      name: 'total cost',
      description: 'Total costs per region.',
      help_article: `${HELP_ARTICLE}#h_74650f4bc8`,
      by: [KEY_REGION],
      tags: [TAG_GEO],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      spec_id: FUTURE_COST_BY_COUNTRY_ID,
      name: 'future cost',
      description: 'Projected maximum future costs for all current granted and pending patent families. (assuming the status of pending and granted patents within each family remain unchanged).',
      help_article: `${HELP_ARTICLE}#h_fb83faa405`,
      by: [KEY_COUNTRY],
      tags: [TAG_GEO],
      available_view_ids: [COLUMN_STACK_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      spec_id: COST_BY_TYPE_BY_YEAR_ID,
      short_name: 'spend type',
      name: 'prosecution vs renewal',
      description: 'Split between prosecution and renewal costs by year.',
      help_article: `${HELP_ARTICLE}#h_5cf67e894d`,
      by: [KEY_YEAR],
      tags: [TAG_TIME],
      available_view_ids: [COLUMN_STACK_ID, COLUMN_CLUSTER_ID, LINE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
      get_tabular_view_summary: get_tabular_view_cost_total
    },
    {
      check_if_available: not_available,
      spec_id: AVERAGE_COST_BY_AGE_ID
    }
  ]
}