import React from 'react'

import { InfoPopover } from '../../widgets/Tooltip.js'
import { WRAPPER_ELEMENT_PARENT } from '../../../constants/tooltip.js'
import TextLink from '../../widgets/TextLink.js'
import { format_integer_with_comma } from '../../../utils/utils.js'
import { is_portfolio_id, is_technology_area_id} from '../../../utils/report_deck_filter_utils.js'
import { is_company_list_target_list } from '../../../utils/deck_spec_utils.js'
import { CIPHER_RED } from '../../../constants/colours.js'
import SpotlightControl from '../../widgets/SpotlightControl.js'

import s from './ReportDeckSeriesPopover.module.scss'

const SHORT_BOX_Y_OFFSET = 35 // popover with 2-3 lines of text
const TALL_BOX_Y_OFFSET = 18 // popover with 4-5 lines of text
const BASE_X_OFFSET = -50

const ReportDeckSeriesPopover = (
  {
    active_chart_click,
    is_spotlighted,
    on_toggle_spotlight,
    on_hide,
    ref_data,
    is_family_count,
    value_formatter,
    detail_clickthrough_text,
    on_detail_clickthrough,
    on_families_clickthrough
  }) => {

  const {coords, item, value, relative_value} = active_chart_click
  const {portfolio_to_company_list} = ref_data

  const info = item.items[0]
  const {id, name, color} = info
  const year = item.year_items ? item.year_items[0].id : null

  const clickthrough_subselections = [item, ...(year ? [{key: item.year_key, items: item.year_items}] : [])]

  const is_portfolio = is_portfolio_id(item.key)
  const is_tech_area = is_technology_area_id(item.key)

  const label_color = color === CIPHER_RED ? CIPHER_RED : null
  const company_list_name = is_portfolio ? (portfolio_to_company_list[id] || {}).name : null
  const is_target_org = is_portfolio && is_company_list_target_list((portfolio_to_company_list[id] || {}))
  const can_be_spotlighted = (is_portfolio && company_list_name && !is_target_org) || is_tech_area

  const y_offset = (!is_family_count || is_portfolio || on_detail_clickthrough) ? TALL_BOX_Y_OFFSET : SHORT_BOX_Y_OFFSET
  const tooltip_offset = [coords[1] + y_offset, coords[0] + BASE_X_OFFSET]

  return (
    <InfoPopover
      className={s.popover_frame}
      no_toggler={true}
      visible_by_default={true}
      static_trigger={<span/>}
      placement={'right'}
      offset={tooltip_offset}
      wrapper={WRAPPER_ELEMENT_PARENT}
      on_hide={on_hide}
      hideOnClick={true}
      interactive={true}
      arrow={false}
    >
      <div className={s.popover_content}>
        <div className='d-flex justify-content-between'>
          <div style={{color: label_color}} className='font-weight-bold ml-0 pr-2'>{name}</div>
          {can_be_spotlighted &&
            <SpotlightControl
              is_spotlighted={is_spotlighted}
              on_click={() => on_toggle_spotlight(item)}
              className={s.spotlight_control}
            />
          }
        </div>
        { year &&
          <div>{year}</div>
        }
        {is_portfolio && !is_target_org &&
          <div>{company_list_name ? `List: ${company_list_name}` : 'Not in list'}</div>
        }
        {!is_family_count &&
          <div>{value_formatter ? value_formatter(value) : value}</div>
        }
        {relative_value &&
          <div>{relative_value}% overall</div>
        }
        <div className='mt-1'>
          {on_detail_clickthrough &&
          <div>
            <TextLink onClick={on_detail_clickthrough}>
              {detail_clickthrough_text || 'More data'}
            </TextLink>
          </div>
          }
          <TextLink
            onClick={() => on_families_clickthrough({clickthrough_subselections})}
            disable={is_family_count && !value}
          >
            <span className='mr-1'>{is_family_count ? format_integer_with_comma(value) : 'See'}</span>
            families
          </TextLink>
        </div>

      </div>
    </InfoPopover>
  )
}

export default ReportDeckSeriesPopover