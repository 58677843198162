import React from 'react'

import { PaginationControls } from '../../widgets/Pagination.js'

export const MatchedPatentsPaginationControls = ({total_lines, settings, set_settings, className}) => {

  function on_change_page_size(page_size) {
    set_settings({...settings, selected_page_size: page_size})
  }

  function on_change_current_page(new_page_number) {
    set_settings({...settings, current_page: new_page_number})
  }

  const total = total_lines ? total_lines : 0
  const page_size = (settings && settings.selected_page_size) ? settings.selected_page_size : 1
  const current_page = (settings && settings.current_page) ? settings.current_page : 0

  const num_pages = Math.ceil(total / page_size)

  return (
    <PaginationControls
      className={className}
      num_pages={num_pages}
      selected_page_size={page_size}
      on_change_page_size={on_change_page_size}
      current_page={current_page}
      on_change_current_page={on_change_current_page}>
    </PaginationControls>
  )

}