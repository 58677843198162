import React from 'react'

const IncompleteReportWarning = () => {
  return (
    <div>
      <div className='font-weight-bold'>This report is incomplete.</div>
      <div>Cipher classifier reports are limited where numbers of positives are very high, and some families will be missing from the report. We recommend refining your classifier or narrowing your inputs.</div>
    </div>
  )
}

export default IncompleteReportWarning