import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import CustomPlotLazy from './spec_visualisations/CustomPlotLazy.js'
import GitInfo from 'react-git-info/macro'
import * as Sentry from '@sentry/react'

import DefaultPageContainer from './DefaultPageContainer.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import ErrorBoundary from './ErrorBoundary.js'
import { HeaderNoAuth } from './header/Header.js'
import Footer from './Footer.js'
import MainRoutes from './MainRoutes.js'
import UserContext from './UserContext.js'
import AccessDenied from './AccessDenied.js'
import AssigneeGrouping from './assignee_grouping/AssigneeGrouping.js'
import MonitorRoutes from './admin/MonitorRoutes.js'
import DocNotFound from './DocNotFound.js'
import FamilySourcesView from './family_view/FamilySourcesView.js'

import { remove_from_local_storage } from '../utils/auth_utils.js'
import { set_sentry_user_context, set_sentry_client_tag } from '../utils/sentry_utils.js'
import {
  get_user_object,
  has_access_to_cipher,
  is_account_disabled,
  is_cipher_engineering,
  has_view_admin_page,
  has_assignee_grouping,
  is_user_not_authenticated,
  has_view_create_users_page,
  user_has_account_type_role
} from '../utils/user_permissions.js'
import { track_user_event, track_visit_event } from '../utils/tracking_utils.js'
import { init_time_monitor } from '../utils/browser_activity_utils.js'
import {
  ADMIN,
  MONITOR,
  ASSIGNEE_GROUPING,
  HOME_PAGE,
  LOGOUT,
  DOC_NOT_FOUND,
  FAMILY,
  SOURCES,
  CREATE_USERS,
} from '../constants/paths.js'
import AdminRoutes from './admin/AdminRoutes.js'
import FamilyView from './family_view/FamilyView.js'
import { RouteWithTracing } from './RouteWithTracing.js'
import UserManagement from './admin/UserManagement.js'
import { initialise_user_stats, prepare_url_for_stats, reset_user_for_stats } from '../utils/user_stats_utils.js'

const App = ({kc, history}) => {

  const { location } = window || {}
  const { pathname, origin, hash } = location || {}

  useEffect(() => {

    return history.listen(() => {
      prepare_url_for_stats(history)
    })
  }, [history])


  useEffect(() => {
    const {tokenParsed, subject: user_id} = kc || {}
    const client_git_tags = GitInfo().tags
    const client_git_tag =  (client_git_tags && client_git_tags.length > 0) ? client_git_tags[0] : null

    set_sentry_user_context(tokenParsed)
    set_sentry_client_tag(client_git_tag)
    const user_group = ((tokenParsed || {}).group || [])[0]
    initialise_user_stats(user_id, user_group || '')
    // Initialise TimeMe
    init_time_monitor()
  }, [kc])

  function logout() {
    track_user_event('action="logout"')
    reset_user_for_stats()
    remove_from_local_storage()
    kc.logout({redirectUri: origin + HOME_PAGE})
  }

  function get_reset_password_url() {
    const URL_SPLITTER = '?'

    const account_url = kc.createAccountUrl()
    const account_url_parts = account_url.split(URL_SPLITTER)

    return (kc.authenticated) ?  ([account_url_parts[0], account_url_parts[1]].join(URL_SPLITTER)) + '#/account-security/signing-in' : null
  }

  const user = get_user_object(kc)

  if (pathname.startsWith('/family') && is_user_not_authenticated(user)) {
    //not authenticated, but that's ok for the family details page
    const family_id_from_path = pathname.trim().split('/')[2]
    return (
      <DefaultPageContainer>
        <HeaderNoAuth on_login={() => {kc.login()}}/>
        <DefaultContentContainer>
          <FamilyView
            history={history}
            selected_family={family_id_from_path}
          />
        </DefaultContentContainer>
        <Footer />
      </DefaultPageContainer>
    )
  }

  const is_user_admin_mode = has_view_admin_page(user) && pathname.startsWith(ADMIN)
  const is_view_create_users_mode = has_view_create_users_page(user) && pathname.startsWith(CREATE_USERS)
  const is_monitor_mode    = is_cipher_engineering(user) && pathname.startsWith(MONITOR)
  const is_grouping_mode   = has_assignee_grouping(user) && pathname === ASSIGNEE_GROUPING

  const is_family_sources_page = is_cipher_engineering(user) && pathname.startsWith(`${FAMILY}`) && pathname.endsWith(`${SOURCES}`)

  const is_doc_not_found = pathname === DOC_NOT_FOUND
  const is_core_mode = !(is_user_admin_mode || is_view_create_users_mode || is_grouping_mode || is_monitor_mode || is_doc_not_found || is_family_sources_page)
  const is_logout_page = pathname === LOGOUT

  if (is_logout_page) {
    remove_from_local_storage()
    kc.logout({redirectUri: origin + HOME_PAGE})
    return null
  }

  const location_hash = hash
  if (location_hash && location_hash.startsWith('#newsletter')) {
    const new_path = pathname.split('#')[0]
    track_visit_event(`action="newsletter" tag="${location_hash}" url="${new_path}"`)
    history.push(new_path) //redirect to the url without hashtag
  }

  // workaround to avoid nesting react-context Provider directly with Router (currently this breaks Router)
  const AppWithUserContext = () => (
    <UserContext.Provider value={{
      ...user,
      reset_password_url: get_reset_password_url(),
      logout_handler: logout,
    }}>
      {/*
        Force pre-load of plotly bundle here.
        Previously we pre-loaded by calling the "import" immediately in CustomPlotLazy.js, outside the lazy() call.
        But that seemed to cause ChunkLoadErrors in some cases, perhaps with our auth flow.
        So here, we only pre-load once the user has logged in.
      */}
      <div style={{display: 'none'}}>
        <CustomPlotLazy />
      </div>

      { is_core_mode &&
        <MainRoutes />
      }

      { is_family_sources_page &&
        <FamilySourcesView />
      }

      { is_user_admin_mode &&
        <AdminRoutes />
      }

      { is_view_create_users_mode &&
        <RouteWithTracing exact path={CREATE_USERS} component={UserManagement} />
      }

      { is_monitor_mode &&
        <MonitorRoutes />
      }

      { is_grouping_mode &&
        <AssigneeGrouping />
      }

      {is_doc_not_found &&
        <DocNotFound
          location={location}
        />
      }
    </UserContext.Provider>
  )

  if (!has_access_to_cipher(user)) {
    return (
      <Router history={history}>
        <ErrorBoundary with_fake_header_and_footer={true}>
          <AccessDenied
            user_logout_handler={logout}
            account_disabled={is_account_disabled(user)}
            missing_account_type_role={!user_has_account_type_role(user)}
          />
        </ErrorBoundary>
      </Router>
    )
  }

  return (
    <Router history={history}>
      <ErrorBoundary with_fake_header_and_footer={true}>
        <AppWithUserContext/>
      </ErrorBoundary>
    </Router>
  )
}

export default Sentry.withProfiler(App)