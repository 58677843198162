import React from 'react'
import cn from 'classnames'

import s from './ItemHeaderButton.module.scss'

const ItemHeaderButton = ({on_click, children, is_disabled, className, reference}) => {
  return (
    <div
      className={cn([s.btn, {[s.btn__disabled]: is_disabled}, className])}
      onClick={on_click}
      ref={reference}
    >
      {children}
    </div>
  )
}

export default ItemHeaderButton