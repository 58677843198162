import { get_as_map } from '../../../utils/utils.js'

export const LABEL_PATENT_FAMILY_ID   = 'label_patent_family'
export const LABEL_PATENT_FAMILIES_ID = 'label_patent_families'
export const DELETE_PATENT_FAMILIES_ID = 'delete_patent_families'
export const BATCH_UPLOAD_ID          = 'batch_upload'
export const NO_EVENT_FILTER_ID       = 'no_event_filter'
export const SET_NAME_ID              = 'set_training_set_name'
export const SET_DESCRIPION_ID        = 'set_training_set_description'
export const SET_TAXONOMY_PATH_ID     = 'set_taxonomy_path'
export const CREATE_TRAINING_SET_ID   = 'create_training_set'
export const BUILD_CLASSIFIER_ID      = 'build_classifier'
export const BUILD_CLASSIFIER_FAILED_ID  = 'build_classifier_failed'
export const BUILD_CLASSIFIER_ALREADY_IN_PROGRESS_ID  = 'build_classifier_already_in_progress'
export const ADD_TAGS_ID              = 'add_training_set_tags'
export const REVERT_LATEST_BUILT_CLASSIFIER_ID = 'revert_latest_built_classifier'
export const REVERT_TRAINING_SET_LABELS_ID = 'revert_training_set_labels'
export const GRANT_PERMISSIONS_ID = 'grant_permissions'
export const TRANSFER_OWNERSHIP_ID = 'transfer_ownership'
export const COPY_TRAINING_SET_ID = 'copy_training_set'

export const LABEL_PATENT_FAMILY_EVENT = {
  id: LABEL_PATENT_FAMILY_ID, name: 'Labelling'
}

export const LABEL_PATENT_FAMILIES_EVENT = {
  id: LABEL_PATENT_FAMILIES_ID, name: 'Label multiple families'
}

export const DELETE_PATENT_FAMILIES_EVENT = {
  id: DELETE_PATENT_FAMILIES_ID, name: 'Remove label'
}

// Currently the backend does not distinguish between single and batch labellings
// But we add this here anyways, as there are some places where the UI does make this disctinction.
export const BATCH_UPLOAD_EVENT = {
  id: BATCH_UPLOAD_ID, name: 'Batch upload'
}

export const SET_NAME_EVENT = {
  id: SET_NAME_ID, name: 'Set name'
}

export const SET_DESCRIPION_EVENT = {
  id: SET_DESCRIPION_ID, name: 'Set description'
}

export const SET_TAXONOMY_PATH_EVENT = {
  id: SET_TAXONOMY_PATH_ID, name: 'Set taxonomy path'
}

export const CREATE_TRAINING_SET_EVENT = {
  id: CREATE_TRAINING_SET_ID, name: 'Create training set'
}

export const BUILD_CLASSIFIER_EVENT = {
  id: BUILD_CLASSIFIER_ID, name: 'Build classifier'
}

export const BUILD_CLASSIFIER_FAILED_EVENT = {
  id: BUILD_CLASSIFIER_FAILED_ID, name: 'Failed Build'
}

export const ADD_TAGS_EVENT = {
  id: ADD_TAGS_ID, name: 'Edit tags'
}

export const REVERT_LATEST_BUILT_CLASSIFIER_EVENT = {
  id: REVERT_LATEST_BUILT_CLASSIFIER_ID, name: 'Revert build'
}

export const REVERT_TRAINING_SET_LABELS_EVENT = {
  id: REVERT_TRAINING_SET_LABELS_ID, name: 'Revert labels'
}

export const GRANT_PERMISSIONS_EVENT = {
  id: GRANT_PERMISSIONS_ID, name: 'Grant permissions'
}

export const TRANSFER_OWNERSHIP_EVENT = {
  id: TRANSFER_OWNERSHIP_ID, name: 'Transfer ownership'
}

export const COPY_TRAINING_SET_EVENT = {
  id: COPY_TRAINING_SET_ID, name: 'Copy training set'
}

export const NO_EVENT_FILTER = {
  id: NO_EVENT_FILTER_ID, name: 'All'
}

export const EVENT_FILTERS = [
  NO_EVENT_FILTER,
  LABEL_PATENT_FAMILY_EVENT,
  LABEL_PATENT_FAMILIES_EVENT,
  DELETE_PATENT_FAMILIES_EVENT,
  SET_NAME_EVENT,
  SET_DESCRIPION_EVENT,
  SET_TAXONOMY_PATH_EVENT,
  CREATE_TRAINING_SET_EVENT,
  BATCH_UPLOAD_EVENT, // not supported by backened yet
  BUILD_CLASSIFIER_EVENT,
  BUILD_CLASSIFIER_FAILED_EVENT,
  ADD_TAGS_EVENT,

  REVERT_LATEST_BUILT_CLASSIFIER_EVENT,
  REVERT_TRAINING_SET_LABELS_EVENT,

  GRANT_PERMISSIONS_EVENT,
  TRANSFER_OWNERSHIP_EVENT,
  COPY_TRAINING_SET_EVENT,

]

export const ID_TO_EVENT_FILTER = get_as_map(EVENT_FILTERS, 'id')
