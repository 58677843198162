import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { DeselectDatasetIcon, SelectDatasetIcon } from '../widgets/IconSet.js'

const DatasetSelectToggle = (
  {
    spec_id,
    is_selected,
    on_dataset_select,
    on_dataset_deselect,
    className
}) => {

  function select_dataset_handler(e, spec_id) {
    e.stopPropagation()
    return is_selected ? on_dataset_deselect(spec_id) : on_dataset_select(spec_id)
  }

  return (
    <TextLink element='div'  onClick={(e) => select_dataset_handler(e, spec_id)} className={className} >
      {!is_selected &&  <SelectDatasetIcon />}
      {is_selected && <DeselectDatasetIcon />}
    </TextLink>
  )
}

export default DatasetSelectToggle