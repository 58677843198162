import React from 'react'
import cn from 'classnames'

import { PrimaryButton } from './widgets/Button.js'

const CreateReportButtonGroup = ({
  outer_className,
  button_className,
  existing_report,
  in_progress_report,
  disabled,
  build_report,       // function with no args
  build_from_existing // function that takes a single arg: an existing report_id
 }) => {
  return (
    <span className={cn('mr-3', outer_className)}>
      {existing_report &&
        <PrimaryButton
          className={cn('mr-3', button_className,)}
          disabled={disabled}
          onClick={() => build_from_existing(existing_report.report_id)}
        >
          Use cached report
        </PrimaryButton>
      }

      {in_progress_report &&
        <PrimaryButton
          className={cn('mr-3', button_className,)}
          disabled={disabled}
          onClick={() => build_from_existing(in_progress_report.report_id)}
        >
          View running report
        </PrimaryButton>
      }

      <PrimaryButton
        onClick={() => build_report()}
        outline={existing_report}
        disabled={disabled}
      >
        {(existing_report || in_progress_report) ? 'Run new report' : 'Run report'}
      </PrimaryButton>
    </span>
  )
}

export default CreateReportButtonGroup