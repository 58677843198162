import React, {useState} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import {ID_TO_TIME_RANGE_FIELD, NO_FILTER_ID} from '../../model/time_range_filter_fields.js'

import s from './ControlsBarDropdown.module.scss'
// NOTE: adds to global css in bootstrap.overrides.scss

function get_full_label(label, items, selected_item_ids, items_count, selected_count) {
  const num_selected = selected_count != null ? selected_count : selected_item_ids.length
  const denominator = items_count != null ? items_count : items.length
  return `${label} [${num_selected}/${denominator}]`
}

const ControlsBarDropdown = ({selected_item_ids, items, items_count, selected_count, label, short_label, disable, toggle_classname, className, menu_className, selected_timerange, children}) => {

  const [dropdown_open, set_dropdown_open] = useState(false)

  function toggle() {
    set_dropdown_open(!dropdown_open)
  }

  function get_short_year(year) {
    const year_string = year + ''
    return year_string.slice(2)
  }

  function get_short_timerange_string(extent) {
    const [from_date, to_date] = extent

    const yearrange = [new Date(from_date).getFullYear(), new Date(to_date).getFullYear()]

    const [from, to] = yearrange

    const short_from = get_short_year(from)
    const short_to = get_short_year(to)
    return `'${short_from} to '${short_to}` // ranges are min inclusive, max exclusive
  }

  function get_label({label, items, selected_item_ids, items_count, selected_count, selected_timerange, short}) {
    if (items && selected_item_ids) {
      return get_full_label(label, items, selected_item_ids, items_count, selected_count)
    }

    if (selected_timerange) {
      const {field_id, timerange} = selected_timerange

      const selected_timerange_field = ID_TO_TIME_RANGE_FIELD[field_id]

      const {name, short_name} = selected_timerange_field

      const timerange_string = (timerange == null) ? null : get_short_timerange_string(timerange)

      return `${label} [${short_name || name}${timerange_string && !short ? ' '+timerange_string : ''}]`

    }

    return label
  }

  function is_highlighted({selected_timerange, items, selected_item_ids, items_count, selected_count}) {
    if (selected_timerange) {
      const {field_id} = selected_timerange
      return field_id !== NO_FILTER_ID
    }

    const num_selected = selected_count != null ? selected_count : (selected_item_ids || []).length
    const denominator = items_count != null ? items_count : (items || []).length

    return num_selected !== denominator
  }

  const full_label = get_label({label, items, selected_item_ids, items_count, selected_count, selected_timerange})
  const shortened_label = get_label({label: short_label, items, selected_item_ids, items_count, selected_count, selected_timerange, short: true})

  const highlighted =  is_highlighted({selected_timerange, items, selected_item_ids, items_count, selected_count})

  return (
    <Dropdown
      isOpen={dropdown_open}
      toggle={toggle}
      disabled={disable}
      className={cn(s.dropdown, {[s.dropdown_toggle__highlighted]: highlighted}, className)}
    >

      <DropdownToggle
        tag='div'
        className={
          cn(
            'py-1 px-2',
            s.dropdown_toggle,
            {
              [s.dropdown_toggle__open]: dropdown_open,
              [s.dropdown_toggle__highlighted]: highlighted,
              [s.dropdown_toggle__disabled]: disable
            },
            toggle_classname
          )
        }
        disabled={disable}
        title={full_label}
      >
        <span className='d-block d-sm-none'>{short_label}</span>
        <span className='d-none d-sm-block d-md-none'>{shortened_label}</span>
        <span className='d-none d-md-block'>{full_label}</span>
      </DropdownToggle>

      <DropdownMenu
        className={cn(s.dropdown_menu, 'px-3 pt-2 mt-0', menu_className)}
        right={true}
      >
        {children}
      </DropdownMenu>

    </Dropdown>
  )
}

export default ControlsBarDropdown

ControlsBarDropdown.propTypes = {
  label: PropTypes.string,
  disable: PropTypes.bool
}
