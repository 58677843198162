import React, { useEffect, useState } from 'react'
import { Nav, NavItem } from 'reactstrap'
import cn from 'classnames'

import { ScrollModal } from '../../widgets/Modal.js'
import { is_company_list_target_list } from '../../../utils/deck_spec_utils.js'
import {
  ORG_LISTS_KEY_DATA_PARAM_NAME,
  TECH_AREAS_KEY_DATA_PARAM_NAME
} from '../../../constants/report_deck.js'
import { NavLink } from '../../widgets/TextLink.js'
import {
  fetch_any_built_training_sets_meta,
  get_clean_training_set_description
} from '../../../utils/training_set_grpc_utils.js'
import Spinner from '../../widgets/Spinner.js'
import { fetch_benchmarking_techs } from '../../../utils/benchmarking_report_builder_utils.js'
import { InfoPopover } from '../../widgets/Tooltip.js'
import { get_as_map } from '../../../utils/utils.js'
import Grid from '../../widgets/Grid.js'
import GridElement from '../../widgets/GridElement.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'

import s from './DeckKeyDataDisplay.module.scss'

const ItemWrapper = ({children}) => {
  return (
    <GridElement>
      <div className={cn('h-100 p-2', s.item_wrapper)}>
        {children}
      </div>
    </GridElement>
  )
}

const Item = ({name, children}) => {
  return (
    <ItemWrapper>
      <div className={cn('mb-2',s.item_name)}>{name}</div>
      <div className='pl-3 mb-1'>
       {children}
      </div>
    </ItemWrapper>
  )
}

const DeckKeyDataDisplay = (
  {
    is_open,
    param,
    deck_key_data,
    internal_report_id,
    deck_type,
    on_close,
  }) => {

  const [mode, set_mode] = useState(null)
  const [show_spinner, set_show_spinner] = useState(true)
  const [training_sets_meta, set_training_sets_meta] = useState(null)

  useEffect(() => {
    if (!is_open || (training_sets_meta != null)) return

    fetch_benchmarking_techs(internal_report_id)
      .then(technology_partitioning => {
        const {classifier_sources} = technology_partitioning || {}

        const classifier_ids = (classifier_sources || []).map(item => item.id).filter(item => item != null)
        if (classifier_ids.length === 0) {
          return null
        }

        return fetch_any_built_training_sets_meta(classifier_ids)
      })
      .then(response => {
        const training_sets = (response.training_sets || []).map(training_set => {
          const {description} = training_set
          return {
            ...training_set,
            description: get_clean_training_set_description(description)
          }
        })
        set_training_sets_meta(training_sets)
        set_show_spinner(false)
      })
      .catch(() => {
        set_show_spinner(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_open, training_sets_meta])

  const {org_lists, tech_areas} = deck_key_data || {}

  const selected_mode = mode || param

  const show_org_lists = (selected_mode === ORG_LISTS_KEY_DATA_PARAM_NAME)
  const show_tech_areas = (selected_mode === TECH_AREAS_KEY_DATA_PARAM_NAME)

  const name_to_training_set_meta = (training_sets_meta) ? get_as_map(training_sets_meta, 'name') : {}

  function on_selected_mode_change(mode) {
    track_deck_viewer_event(`obj="report" action="show_key_data" key_data_type="${mode}" context="display" deck_type="${deck_type}"`)
    set_mode(mode)
  }

  function on_hide() {
    set_mode(null)
    on_close()
  }

  if (!is_open) return null

  return (
    <ScrollModal
      is_open={is_open}
      on_hide={on_hide}
      no_footer={true}
      header={(
        <div className='mt-3'>
          <Nav tabs className='justify-content-between'>
            <div className='d-flex'>
              <NavItem className='ml-3'>
                <NavLink active={show_org_lists} onClick={() => on_selected_mode_change(ORG_LISTS_KEY_DATA_PARAM_NAME)}>Organisation lists</NavLink>
              </NavItem>

              <NavItem>
                <NavLink active={show_tech_areas} onClick={() => on_selected_mode_change(TECH_AREAS_KEY_DATA_PARAM_NAME)}>Technology areas</NavLink>
              </NavItem>
              </div>
              <button type='button' onClick={on_hide} className={cn('close mr-3', s.close_btn)}>&#x2715;</button>
          </Nav>
        </div>
      )}
    >
      {show_org_lists &&
        <Grid>
          {org_lists.map((org_list, i) => {
            if (is_company_list_target_list(org_list)) {
              return null
            }

            const {name, portfolios} = org_list

            return (
              <Item
                key={i}
                name={name}
              >
                {portfolios.map((item, j) => {
                  return(
                    <div key={j} className={s.member_name}>{item.name}</div>
                )})}
              </Item>
            )})}
        </Grid>
      }
      {show_tech_areas &&
        <Grid>
          {show_spinner &&
            <div>
              <Spinner />
            </div>
          }
          {!show_spinner && tech_areas.map((tech_area, i) => {
            const {name, techs} = tech_area
            return (
              <Item
                key={i}
                name={name}
              >
                {techs.map((item, j) => {
                  const {name} = item
                  const {description} = name_to_training_set_meta[name] || {}

                  return(
                    <div key={j} className={s.member_name}>{name}
                      {description && (description !== '') &&
                        <InfoPopover is_in_modal={true} interactive={true}>
                          {description}
                        </InfoPopover>
                      }
                    </div>
                  )
                })}
              </Item>
            )
          })}
        </Grid>
      }
    </ScrollModal>
  )
}

export default DeckKeyDataDisplay