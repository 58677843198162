import React from 'react'
import ReactSpinner from 'react-spinner'
import cn from 'classnames'

import s from './Spinner.module.scss'
// NOTE: adds overrides to global css in react-spinner.override.scss (to set colour etc)

const SIZE_TO_CLASS = {
  xs: s.spinner__xs,
  sm: s.spinner__sm,
  md: s.spinner__md,
  lg: s.spinner__lg,
  xl: s.spinner__xl
}

/**
 * @param {} size (optional) can be 'sm', 'md', 'lg' or 'xl' (default is 'md').
 */
const Spinner = ({ size, className, innerClassName }) => {
  return (
    <span className={cn(s.block, className)}>
      <ReactSpinner
        className={cn(
          s.spinner,
          SIZE_TO_CLASS[size] || SIZE_TO_CLASS['md'],
          innerClassName
        )}
      />
    </span>
  )
}

export const DownloadSpinner = ({text, className}) => {

  const text_to_display = (text != null) ? text : 'Downloading'

  return (
    <div className={cn('d-flex', className)}>
      <Spinner size='sm'/><div className={cn('ml-1 my-auto', s.download_text)}>{text_to_display}</div>
    </div>
  )
}

export default Spinner
