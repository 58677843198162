import { get_as_map } from '../utils/utils.js'

export const FEEDBACK_CATEGORIES = [
  {id: 'help', label: 'Help/Support' },
  {id: 'grouping', label: 'Grouping Organisations'},
  {id: 'request', label: 'Feedback/Requests'},
  {id: 'error', label: 'Report an error'},
  {id: 'other', label: 'Other'}
]

export const FEEDBACK_CATEGORIES_BY_ID = get_as_map(FEEDBACK_CATEGORIES, 'id')