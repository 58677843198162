import React from 'react'
import cn from 'classnames'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic'
import { get_report_region_grouping_by_id } from '../../utils/regions_utils.js'
import { REPORT_REGION_GROUPING_OPTIONS } from '../../constants/regions.js'

import s from './RegionGroupingControls.module.scss'

const RegionGroupingControls = ({spec, report_region_grouping, on_change_report_region_grouping, className}) => {

  const {show_region_grouping_selector} = spec

  if (!show_region_grouping_selector) {
    return null
  }

  const selected_region_grouping_label = get_report_region_grouping_by_id(report_region_grouping).label

  return (
    <div
      className={cn('d-inline-flex', 'align-items-center', className)}
    >
      <span
        className='mr-2'
      >
          Group by
      </span>

      <span
        className='mr-2'
      >
        <UncontrolledDropdown
          className={cn('d-inline')}
        >
          <SelectDropdownToggleStatic
            className={cn(s.dropdown_toggle, 'mr-1')}
            chevron_left={true}
          >
            {selected_region_grouping_label}
          </SelectDropdownToggleStatic>

          <DropdownMenu
            right
            className={s.dropdown_menu}
          >
            {REPORT_REGION_GROUPING_OPTIONS.map((item, i) => (
              <DropdownItem
                className={'d-flex'}
                key={i}
                onClick={() => on_change_report_region_grouping(item.id)}
              >
                {item.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </span>
    </div>
  )
}

export default RegionGroupingControls