import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { scroll_to_section_ref } from '../../utils/family_view_utils.js'

import s from './FamilyDetailsNavigation.module.scss'
import { NAV_COSTS_SECTION_ID } from '../../model/family_view.js'

const FamilyDetailsNavigation = (
  {
    sections = [],
    section_id_to_ref,
    allow_public_access,
    className,
}) => {
  const filtered_sections = allow_public_access ? sections.filter(section => section.id !== NAV_COSTS_SECTION_ID) : sections

  if (filtered_sections.length === 0) {
    return (<div className={cn(s.navigation_wrapper, className)} />)
  }

  return (
    <div className={cn([s.navigation_wrapper, 'd-flex flex-row flex-wrap', className])} >
      <div className={cn(['list-inline-item', s.nav_label])}>Jump to:</div>
      {
        filtered_sections.map((section, i) => {
          const { id, label } = section
          const ref = section_id_to_ref[id]

          return (
            <div
              className={cn(['list-inline-item', s.nav_item])}
              key={i}
            >
              <TextLink
                onClick={scroll_to_section_ref.bind(null, ref)}
                className={s.nav_link}
              >
                {label}
              </TextLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default FamilyDetailsNavigation