import React from 'react'
import { DropdownItem, Label } from 'reactstrap'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './ThresholdControl.module.scss'

const ThresholdControl = ({ on_change_value, threshold_options, threshold }) => {

  return (
    <span className={'d-flex ml-auto my-0 mr-0'}>
      <Label className='m-0'>score threshold:</Label>
      <BaseDropdown menuClassName={s.threshold_dropdown}
                    buttonClassName='py-0 mb-1'
                    label={threshold}>

        {threshold_options.map((value, idx) =>
          <DropdownItem className={s.dropdown_item} key={idx} onClick={on_change_value.bind(this, value)}>
            {value}
          </DropdownItem>
        )}

      </BaseDropdown>
    </span>
  )
}

export default ThresholdControl