import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { get_as_map } from '../../utils/utils.js'

import MultiItemDropdownSelector from './MultiItemDropdownSelector.js'

import s from './GenericMultiItemDropdownSelector.module.scss'

function get_button_label(selected_items, is_all_selected, is_none_selected) {
  if (is_all_selected) {
    return 'All'
  }

  if (is_none_selected) {
    return 'None'
  }

  return selected_items.map(item => item.name).join(', ')
}

const DefaultLabelMultiItemDropdownSelector = ({
  className,
  label_className,
  items,
  selected_item_ids,
  on_change_item_ids,
  right
}) => {

  const id_to_item = get_as_map(items)

  const is_all_selected = items.every(item => _.contains(selected_item_ids, item.id))
  const is_none_selected = selected_item_ids.length === 0

  const selected_items = selected_item_ids.map(id => id_to_item[id])

  const button_label = get_button_label(selected_items, is_all_selected, is_none_selected)

  return (
    <MultiItemDropdownSelector
      className={className}
      rowClassName={s.row_container}
      items={items}
      selected_item_ids={selected_item_ids}
      label={button_label}
      labelClassName={cn(s.button_label, label_className)}
      on_change_item_ids={on_change_item_ids}
      right={right}
      menuClassName={s.menu}
    />
  )
}

export default DefaultLabelMultiItemDropdownSelector