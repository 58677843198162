import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ACTIONS_FIELD_ID, ID_TO_COLUMN, INPUT_STATUS_FIELD_ID } from './utils/families_table.js'
import {
  CIPHER_FAMILY_ID_FIELD_ID,
  CPC_CODES_FIELD_ID,
  EXPIRED_TERRITORIES_FIELD_ID,
  GRANTED_TERRITORIES_FIELD_ID,
  PENDING_TERRITORIES_FIELD_ID,
  PRIMARY_CPC_CODE_FIELD_ID,
  SIMILARITY_SCORE_ID,
  TITLE_FIELD_ID
} from '../../model/patent_family_fields.js'
import CipherFamilyLink from '../widgets/CipherFamilyLink.js'
import { format_score } from '../classifiers_editor/utils/training_set_utils.js'
import { OWNERS_FIELDS_ID } from '../classifiers_editor/model/patent_family_underscore_fields.js'
import FamilyActions from './FamilyActions.js'
import InputStatusMarker from './InputStatusMarker.js'
import { ASCENDING, DESCENDING } from '../../model/sort_directions.js'
import CpcWithHover, { CpcCodesWithHover } from '../family_view/CpcWithHover.js'
import { withUser } from '../UserContext.js'
import { get_field_ids_without_restricted_fields } from '../../utils/patent_family_list_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './FamiliesTable.module.scss'

const FamiliesTable = (
  {
    user,
    column_ids,
    families,
    family_ids,
    blocklist,
    bookmarked,

    selected_sort_field_id,
    selected_sort_direction_id,

    on_add_to_family_ids,
    on_add_to_blocklist,
    on_add_to_bookmarked,
    on_change_sort_field_id_and_sort_direction_id,
    on_show_details,
    className,
    headerClassName,
  }) => {

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up('xs'))
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))

  const [screens, set_screens] = useState(null)

  useEffect(() => {
    const screens = {
      xs, sm, md, lg, xl
    }

    set_screens(screens)
  }, [xs, sm, md, lg, xl])

  function on_column_header_click(column) {
    const {sortable, id} = column || {}

    if (sortable !== true) return

    if (selected_sort_field_id !== id) return on_change_sort_field_id_and_sort_direction_id(id, ASCENDING)

    return on_change_sort_field_id_and_sort_direction_id(selected_sort_field_id, selected_sort_direction_id === ASCENDING ? DESCENDING : ASCENDING)
  }

  const cipher_family_ids = (families || []).map(item => item[CIPHER_FAMILY_ID_FIELD_ID])

  const columns = get_field_ids_without_restricted_fields(column_ids, user)
    .map(id => {
      return ID_TO_COLUMN[id] || null
    })
    .filter(column => {
      if ((!column) || (screens == null)) return false
      const {min_screen_breakpoint} = column || {}
      return !min_screen_breakpoint || screens[min_screen_breakpoint]
    })
    .map(column => {
    const {id, field, label} = column || {}

    if (CIPHER_FAMILY_ID_FIELD_ID === id) {
      return {
        ...column,
        render_cell: ({row}) => {
          const {cipherFamilyId, primaryPublication} = row || {}
          return (
            <div>
              <CipherFamilyLink
                family_id={cipherFamilyId}
                display_text_as_link={true}
                on_family_id_click={() => on_show_details(cipher_family_ids.indexOf(cipherFamilyId), cipherFamilyId)}

                display_link_icon={true}
                show_similar_families_search={true}
              />
              <div>{primaryPublication}</div>
            </div>
          )
        }
      }
    }

    if (SIMILARITY_SCORE_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const {similarity_score=''} = row || {}
        return (<div className='text-right pr-3'>{format_score(similarity_score)}</div>)
      }
    }

    if (OWNERS_FIELDS_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const owners_list = (row[field] || []).join(' | ')
        return (<div className={cn('pr-2', s.cell__text)} title={owners_list}>{owners_list}</div>)
      }
    }

    if (TITLE_FIELD_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const title = row[field] || ''

        return (<div className={cn('pr-2', s.cell__text)} title={title}>{title}</div>)
      }
    }

    if ([PRIMARY_CPC_CODE_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      render_header: () => {
        return (<span className={cs.white_space_nowrap}>{label}</span>)
      },
      render_cell: ({row}) => {
        const value = row[field]
        if (!value) return ''

        return (
          <div className={cn('pr-2', s.cell__text)}>
            <CpcWithHover
              cpc_codes={[value]}
              className='mr-1'
            />
          </div>
        )
      }
    }

    if ([CPC_CODES_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      render_header: () => {
        return (<span className={cs.white_space_nowrap}>{label}</span>)
      },
      render_cell: ({row}) => {
        const value = row[field]
        if (!value) return ''

        return (
          <div className={cn('pr-2', s.cell__text)}>
            <CpcCodesWithHover
              cpc_codes={value}
            />
          </div>
        )
      }
    }

    if ([GRANTED_TERRITORIES_FIELD_ID, PENDING_TERRITORIES_FIELD_ID, EXPIRED_TERRITORIES_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      render_cell: ({row}) => {
        const countries_list = (row[field] || []).join(', ')
        return (<div className={cn('pr-2', s.cell__text)} title={countries_list}>{countries_list}</div>)
      }
    }

    if (ACTIONS_FIELD_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const {patFamId} = row || {}

        return (
          <FamilyActions
            family_id={patFamId}
            family_ids={family_ids}
            blocklist={blocklist}
            bookmarked={bookmarked}
            on_add_to_family_ids={on_add_to_family_ids}
            on_add_to_blocklist={on_add_to_blocklist}
            on_add_to_bookmarked={on_add_to_bookmarked}
          />
        )
      }
    }

    if (INPUT_STATUS_FIELD_ID === id) return {
      ...column,

      render_cell: ({row}) => {
        const { patFamId } = row || {}

        return (
          <div className={cn('h-100', s.input_status_wrapper)}>
            <InputStatusMarker family_id={patFamId} positives={family_ids} negatives={blocklist} bookmarked={bookmarked} display='table' />
          </div>
        )}
    }

    return column
  })

  const row_height = 49

  return (
    <div className={cn('w-100', className)}>
      <table width='100%' cellPadding={0} cellSpacing={0} className={s.families_table}>
        <thead>
          <tr className={cn(s.table_header, headerClassName)}>
            {columns.map(column => {
              const {render_header, label, id, width, sortable} = column || {}

              const is_sorted_by = (id === selected_sort_field_id)

              return (
                <th key={id} style={{width: width + 'px'}} className={cn('fs-unmask', cs.cursor_pointer, s.column_header)} onClick={() => on_column_header_click(column)}>
                  <div className='d-flex h-100'>
                    <span className='my-auto'>{render_header ? render_header({column}) : label}</span>
                    {is_sorted_by &&
                      <span className={cn('my-auto', s.sort, s.sort__active)}>
                        {selected_sort_direction_id === ASCENDING && <ArrowUpwardIcon />}
                        {selected_sort_direction_id === DESCENDING && <ArrowDownwardIcon />}
                      </span>
                    }

                    {(sortable === true) && !is_sorted_by &&
                      <span className={cn('my-auto', s.sort)}><ArrowUpwardIcon /></span>
                    }
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody>
          {families.map((item, i) => {

            return (
              <tr key={i}>
                {columns.map(column => {
                  const {render_cell, field, id} = column

                  return (
                    <td key={`${i}_${id}`} style={{height: `${row_height}px`}} >
                      {render_cell ? render_cell({row: item, field}) : (item[field] || '')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default withUser(FamiliesTable)