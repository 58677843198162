import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import cn from 'classnames'

import DefaultPageContainer from './DefaultPageContainer.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import Header from './header/Header.js'
import Footer from './Footer.js'
import ContainerFullWidth from './ContainerFullWidth.js'
import SupportInfoDisplay from './widgets/SupportInfoDisplay.js'

import s from './NotSupported.module.scss'

const NotSupported = ({ children }) => {
  const history = createBrowserHistory()

  return (
    <Router history={history}>
      <DefaultPageContainer className="d-block">
        <Header
          no_controls={true}
        />
        <DefaultContentContainer className={cn('d-block', s.body_container)}>
          <ContainerFullWidth>

            <div className='container'>
            <section className="jumbotron text-center mt-5">
              <div className="container">
                <h1 className="jumbotron-heading mb-3">Sorry</h1>

                {children}

                <SupportInfoDisplay
                  className='lead text-muted'
                />
              </div>
            </section>
          </div>

          </ContainerFullWidth>
        </DefaultContentContainer>
        <Footer/>
      </DefaultPageContainer>

    </Router>
  )
}

export default NotSupported