import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import cn from 'classnames'

import { DashboardTileDesc } from './DashboardTileDesc.js'
import { DashboardSmallTile } from './DashboardTile.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { PrimaryButton } from '../widgets/Button.js'
import { match_pub_or_pat_fams } from '../../utils/custom_search_utils.js'
import {
  get_as_map,
  get_object_values,
  is_int,
  remove_double_spaces,
  replace_tabs_with_spaces
} from '../../utils/utils.js'
import {
  fetch_organisations_by_search_phrase,
} from '../../utils/organisation_utils.js'
import { create_cipher_family_id_from_family_id } from '../../utils/family_view_utils.js'
import { track_knn_events, track_report_builder_event } from '../../utils/tracking_utils.js'
import { has_utt, is_creator } from '../../utils/user_permissions.js'
import {
  PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID,
  PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID,
  PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID,
  run_speedy_org_report,
  run_speedy_utt_landscape_report
} from '../../utils/report_builder_utils.js'
import { withUser } from '../UserContext.js'
import TextLink from '../widgets/TextLink.js'
import { BUILD_REPORT, KNN } from '../../constants/paths.js'
import { CrossIcon } from '../widgets/IconSet.js'
import { get_utt_classes } from '../../utils/static_data_utils.js'
import {
  filter_classes_by_search_phrase,
  get_default_utt_landscape_report_name,
  transform_classes_to_groups
} from '../../utils/utt_utils.js'
import Spinner from '../widgets/Spinner.js'
import { normalise_name } from '../../utils/name_utils.js'
import { fetch_report_history } from '../../utils/report_history_utils.js'
import {
  FamiliesDisplay,
  QuickSearchOrgsDisplay,
  QuickSearchReportsDisplay,
  ResultsMenu,
  SectionWrapper,
  SimilarFamiliesSearch
} from './DashboardDisplays.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import {
  get_patent_families_by_ids,
} from '../../utils/patent_family_list_utils.js'
import {
  CONTEXT,
  create_search_name,
  find_similar_families_by_input_id,
  generate_input_id, get_default_count_setting,
  has_user_selected_anonymous_mode,
  is_history_enabled,
  NAME_MAX_LENGTH,
  parse_text_input,
  save_similar_families_search_input,
  TEXT_TYPE_TECH_NAME,
} from '../../utils/knn_search.js'
import { TITLE_FIELD_ID, OWNERS_FIELD_ID, PAT_FAM_ID_FIELD_ID } from '../../model/patent_family_fields.js'
import { ASCENDING } from '../../model/sort_directions.js'
import { TileHelpLink } from './TileHelpLink.js'
import { get_multi_search_help_url } from '../../utils/help_utils.js'
import { get_default_utt_version, get_save_knn_searches } from '../../utils/user_settings_utils.js'
import { DEFAULT_REPORT_TYPE, UTT_REPORT_TYPE } from '../../constants/constants.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { is_400_error } from '../../utils/axios_utils.js'
import { get_list_of_hashed_classifier_ids } from '../../utils/tracking_hashed_id_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './MultiSearchPanel.module.scss'

const MAX_RESULTS = 3

const CipherToolLink = ({link, label, on_click, className}) => {
  return (
    <div className={cn('d-block', className)}>
      <TextLink className={cn('px-3 py-1', s.tool_link)} element='a' href={link} onClick={on_click}>
        {label}
      </TextLink>
    </div>
  )
}

const MultiSearchPanel = ({user}) => {

  const { user_settings } = useContext(UserSettingsContext)
  const utt_version = get_default_utt_version(user_settings)
  const should_save_knn_searches = get_save_knn_searches(user_settings)
  const user_knn_anonymous_mode_preference = has_user_selected_anonymous_mode()
  const user_is_creator = is_creator(user)

  const [show_spinner_on_search, set_show_spinner_on_search] = useState(false)
  const [show_spinner_on_report_build, set_show_spinner_on_on_report_build] = useState(false)
  const [search_phrase, set_search_phrase] = useState(null)

  const [families, set_families] = useState(null)
  const [orgs, set_orgs] = useState(null)
  const [utt_classes, set_utt_classes] = useState(null)
  const [reports, set_reports] = useState(null)
  const [similar_families, set_similar_families] = useState(null)

  const [show_no_results_msg, set_show_no_results_msg] = useState(false)

  const [report_id, set_report_id] = useState(null)

  const [search_error, set_search_error] = useState(null)
  const [build_report_error, set_build_report_error] = useState(null)

  const is_knn_history_enabled = is_history_enabled({should_save_knn_searches, anonymous_mode_enabled: user_knn_anonymous_mode_preference})

  function do_clean_up() {
    set_families(null)
    set_orgs(null)
    set_utt_classes(null)
    set_reports(null)
    set_similar_families(null)
    set_search_error(null)
    set_build_report_error(null)
    set_show_no_results_msg(false)
  }

  function normalise_search_phrase() {
    return remove_double_spaces(replace_tabs_with_spaces((search_phrase || '').trim()))
  }

  function update_search_phrase(input) {
    const should_clean_up = (search_phrase === '') || ((input === '') && show_no_results_msg)

    set_search_phrase(input)

    if (should_clean_up) {
      do_clean_up()
    }
  }

  function search_orgs() {
    const search_phrase_normalised = normalise_search_phrase()

    return fetch_organisations_by_search_phrase(search_phrase_normalised, false, true)
      .catch(e => {
        send_error_to_sentry(e, {})
        throw e
      })
      .then((response) => {
        const {results=[]} = response
        if (results.length > 0) {
          set_orgs(results)
          return results
        }
        return null
      })
  }

  function search_families() {
    const search_phrase_normalised = normalise_search_phrase()
    return match_pub_or_pat_fams(search_phrase_normalised, true, false, false, false)
      .catch(e => {
        send_error_to_sentry(e, {})
        throw e
      })
      .then(response => {
        const {data} = response || {}
        const results = get_object_values(data || [])
        if (results.length > 0) {
          const results = get_object_values(data || [])[0] || []

          const matched_families = results.filter(item => item.family != null)

          if (matched_families.length > 0) {
            set_families(matched_families)
            return matched_families
          }

          if (is_int(search_phrase_normalised * 1)) {
            const cipher_family_id = create_cipher_family_id_from_family_id(search_phrase_normalised)
            const families = [{family: cipher_family_id}]
            set_families(families)
            return families
          }

          return null
        }

        return null
      })
  }

  function search_utt_classes(utt_version) {
    if (!has_utt(user) || !user_is_creator) {
      return Promise.resolve(null)
    }
    const search_phrase_normalised = normalise_search_phrase()
    return get_utt_classes(utt_version)
      .catch(e => {
        send_error_to_sentry(e, {})
        throw e
      })
      .then(response => {
        const classifier_groups = transform_classes_to_groups(response)
        const classifier_groups_filtered = filter_classes_by_search_phrase(classifier_groups, search_phrase_normalised.toLowerCase())
        if (classifier_groups_filtered && classifier_groups_filtered.length > 0) {
          set_utt_classes(classifier_groups_filtered)
          return classifier_groups_filtered
        }
        return null
      })
  }

  function search_reports() {
    const search_phrase_normalised = normalise_search_phrase()

    fetch_report_history(1000, true)
      .catch(e => {
        send_error_to_sentry(e, {})
        throw e
      })
      .then(reports => {
        if (reports && reports.length > 0) {

          const filtered_reports = reports.filter(report => {
            const {title} = report

            return title.toLowerCase().indexOf(search_phrase_normalised.toLowerCase()) !== -1
          })

          if (filtered_reports.length > 0) {
            set_reports(filtered_reports)

            return filtered_reports
          }
          return null
        }

        return null
      })
  }

  function search_similar_families() {
    if (!user_is_creator) return null

    return parse_text_input(search_phrase)
      .then(({ query }) => {
        const { pat_fam_ids, text, search_name: service_search_name } = query || {}

        const query_params = {
          ...pat_fam_ids ? { pat_fam_ids } : {},
          ...(text) ? {text} : {},
          k: get_default_count_setting()
        }

        const name = ((service_search_name != null) && (service_search_name !== '')) ? service_search_name.slice(0, NAME_MAX_LENGTH) : create_search_name(query_params)

        return save_similar_families_search_input(
          { input: query_params, context: 'dashboard', name} ,
          generate_input_id(),
          is_knn_history_enabled ? generate_input_id() : null,
          is_knn_history_enabled ? generate_input_id() : null,
          is_knn_history_enabled
        )
          .then(({id}) => {
            return {id, query}
          })
      })
      .then(({id, query}) => {
        const { text } = query || {}

        const {type, value} = (text || [])[0] || {}
        const is_tech_name_search =  (type === TEXT_TYPE_TECH_NAME)

        if (is_tech_name_search) {
          set_similar_families({tech_name: value, id})
          return query
        }

        return find_similar_families_by_input_id(id)
          .then(response => {
            const { results } = response || {}

            if (!results || results.length === 0) {
              return null
            }

            const family_ids = results.slice(0, MAX_RESULTS)

            return get_patent_families_by_ids(family_ids, '', family_ids.length, 0, TITLE_FIELD_ID, ASCENDING, [TITLE_FIELD_ID, OWNERS_FIELD_ID])
              .then(family_data_response => {
                const {searchResults=[]} = family_data_response || {}

                const id_to_data = get_as_map(searchResults, PAT_FAM_ID_FIELD_ID)

                const families = family_ids.map(pat_fam_id => {
                  const data = id_to_data[pat_fam_id]
                  const owner = (data[OWNERS_FIELD_ID] || []).join(', ')
                  const family = create_cipher_family_id_from_family_id(pat_fam_id)
                  return { pat_fam_id, ...data, family, owner }
                })

                set_similar_families({families, id})
                return results
              })
          })
      })
      .catch(e => {
        if (!is_400_error(e)) {
          send_error_to_sentry(e, {})
          throw e
        }}
      )
  }

  function start_search() {
    if (!is_search_phrase_valid) return

    track_report_builder_event('action="multi_search" context="dashboard"')

    set_show_spinner_on_search(true)

    do_clean_up()

    Promise.all(
      [
        search_orgs(),
        search_families(),
        search_utt_classes(utt_version),
        search_reports(),
        search_similar_families()
      ])
        .then((
        [
          orgs,
          families,
          utt_classes,
          reports,
          similar_families
        ]) => {
        if (!(orgs || families || utt_classes || reports || similar_families)) {
          set_show_no_results_msg(true)
        }

        set_show_spinner_on_search(false)
      })
      .catch(e => {
        set_search_error(e)
        set_show_spinner_on_search(false)
      })
  }

  function do_speedy_report(org) {
    track_report_builder_event('action="build_report" report_type="quick_org" context="dashboard"')

    set_show_spinner_on_on_report_build(true)

    const report_type = has_utt(user) ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE

    run_speedy_org_report(org, report_type, false, utt_version)
      .then((external_report_id) => {
        set_show_spinner_on_on_report_build(false)
        set_report_id(external_report_id)
      })
      .catch((error) => {
        set_show_spinner_on_on_report_build(false)
        set_build_report_error(error)
      })
  }

  function do_utt_landscape_report(classifier) {
    if (!is_creator(user)) {return}
    const classifier_ids_for_tracking = get_list_of_hashed_classifier_ids([classifier.classifier_id])
    track_report_builder_event(`action="build_report" report_type="quick_utt_landscape" context="dashboard" classifier_ids="${classifier_ids_for_tracking}"`)

    set_show_spinner_on_on_report_build(true)
    const report_name = normalise_name(get_default_utt_landscape_report_name(classifier))
    return run_speedy_utt_landscape_report(classifier, report_name, utt_version)
      .then((external_report_id) => {
        set_show_spinner_on_on_report_build(false)
        set_report_id(external_report_id)
      })
      .catch((error) => {
        set_show_spinner_on_on_report_build(false)
        set_build_report_error(error)
      })
  }

  if (report_id) {
    return (<Redirect to={`/report/${report_id}`} />)
  }

  const is_search_phrase_valid = normalise_search_phrase().length > 0

  const has_any_results = families || orgs || utt_classes || reports || similar_families
  const has_error = search_error || build_report_error

  const show_dropdown = has_any_results || has_error || show_spinner_on_report_build || show_no_results_msg

  return (
    <DashboardSmallTile title='Search Cipher' className='d-flex flex-column' hint={<TileHelpLink help_url={get_multi_search_help_url()}/>}>

      <div className='d-flex mt-3'>
        <ClearableSearchInput
          placeholder='Enter a patent number, company name, or technology'
          value={search_phrase || ''}
          handle_change={(input) => update_search_phrase(input)}
          handle_key_down={({ keyCode }) => {
            if (is_search_phrase_valid && keyCode === 13) {
            start_search()
          }}}
          containerClassName='flex-grow-1'
          show_spinner={show_spinner_on_search}
          show_clear={true}
        />
        <PrimaryButton onClick={() => start_search()} disabled={!is_search_phrase_valid || show_spinner_on_search || show_spinner_on_report_build}>Search</PrimaryButton>
      </div>

      {show_dropdown &&
        <ResultsMenu className={'px-2 pt-2'}>
          {!show_spinner_on_report_build &&
            <div className='d-flex justify-content-end mb-2' onClick={() => do_clean_up()}><CrossIcon className={cs.cipher_red_svg_icon}/></div>
          }
          {show_spinner_on_report_build &&
            <div className='text-center'>
              <Spinner />
              <div>Building report...</div>
            </div>
          }

          {!show_spinner_on_report_build && families &&
            <FamiliesDisplay
              families={families}
              show_similar_families_search={user_is_creator}
            />
          }

          {!show_spinner_on_report_build && orgs &&
            <QuickSearchOrgsDisplay
              orgs={orgs.slice(0, MAX_RESULTS)}
              search_phrase={normalise_search_phrase()}
              on_click={do_speedy_report}

              className='mb-2'
            />
          }

          {!show_spinner_on_report_build && utt_classes &&
            <SectionWrapper title='Universal Technology Taxonomy'>
              {utt_classes.map((group, i) => {
                const { name: group_name, classifiers } = group
                return (
                  <div key={i} className='d-flex flex-wrap mb-1'>
                    <span className='mr-2'>{group_name}:</span>

                    {classifiers.map((classifier, j) => {
                      const { name } = classifier
                      return (<TextLink key={j} element='div' className='mr-2' disable={!is_creator(user)} onClick={() => do_utt_landscape_report({...classifier, group: group_name})}>{name}</TextLink>)
                    })}
                  </div>
                )
              })}
            </SectionWrapper>
          }

          {!show_spinner_on_report_build && reports &&
            <QuickSearchReportsDisplay
              reports={reports.slice(0, MAX_RESULTS)}
              total_count={reports.length}
              search_phrase={search_phrase}
            />
          }

          {!show_spinner_on_report_build && similar_families &&
            <SimilarFamiliesSearch
              {...similar_families}
            />
          }

          {!show_spinner_on_search && show_no_results_msg &&
            <SectionWrapper>No results to display</SectionWrapper>
          }

          {has_error && !has_any_results &&
            <SectionWrapper>There was an error fetching results for <b>{search_phrase}</b></SectionWrapper>
          }
        </ResultsMenu>
      }

      {user_is_creator &&
        <DashboardTileDesc className='flex-grow-1'>
          <div className='d-flex flex-wrap'>
            <span className='mr-2 mt-2'>or start with one of Cipher&apos;s advanced tools:</span>
            <CipherToolLink label='Tech Explorer' link={`${KNN}`} className='mt-2 mr-2' on_click={() => track_knn_events(`context="${CONTEXT}" action="knn_search" obj="dashboard_btn"`)}/>
            <CipherToolLink label='organisation search' link={`${BUILD_REPORT}?portfolio_search_mode=${PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID}`} className='mt-2 mr-2' />
            <CipherToolLink label='boolean search' link={`${BUILD_REPORT}?portfolio_search_mode=${PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID}`} className='mt-2 mr-2'  />
            <CipherToolLink label='patent upload' link={`${BUILD_REPORT}?portfolio_search_mode=${PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID}`} className='mt-2'  />
          </div>
        </DashboardTileDesc>
      }
    </DashboardSmallTile>
  )
}

export default withUser(MultiSearchPanel)