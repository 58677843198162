import React from 'react'

import Footer from './Footer.js'
import Header from './header/Header.js'
import DefaultPageContainer from './DefaultPageContainer.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import ContainerFullWidth from './ContainerFullWidth.js';
import { Card } from './widgets/Block.js'
import TextLink from './widgets/TextLink.js'
import { track_user_event } from '../utils/tracking_utils.js'
import SupportInfoDisplay from './widgets/SupportInfoDisplay.js'

import s from './AccessDenied.module.scss'


function get_title_message_and_issue(account_disabled, missing_account_type_role) {
            // Title                   // Issue                                // Message
  if (account_disabled) {
     return ['Account disabled',       'disabled_account',                     'Sorry, it seems you do not have access to Cipher.']
  }
  if (missing_account_type_role) {
    return  ['Account preparation',    'account_missing_account_type_role',    'Sorry, your account is being prepared. Please try again later.']
  }
  return    ['Login unsuccessful',     'invalid_account',                      'Sorry, there\'s been an error. It looks like there is a problem with your account set-up.']
}

const AccessDenied = ({user_logout_handler, account_disabled, missing_account_type_role}) => {
  const [title, issue, message] = get_title_message_and_issue(account_disabled, missing_account_type_role)
  track_user_event(`issue="${issue}" action="log_in"`)

  return (
    <DefaultPageContainer>
      <Header no_controls={true} />

      <DefaultContentContainer className={s.access_denied_container}>
        <ContainerFullWidth>
          <Card title={title}>
            <div className={s.access_denied_text}>
              <div>{message}</div>
              <SupportInfoDisplay className='my-2'/>
              <div>To log in again, click <TextLink onClick={user_logout_handler}>here</TextLink></div>
            </div>
          </Card>
        </ContainerFullWidth>
      </DefaultContentContainer>

      <Footer/>
    </DefaultPageContainer>
  )
}

export default AccessDenied