import React from 'react'
import cn from 'classnames'

import ChildRoleRows from './ChildRoleRows.js'

import s from './RoleRow.module.scss'

const RoleRow = ({
  role,
  on_remove,
  id_to_child_roles
}) => {

  return (
    <div className={cn(s.container)}>
      <div>
        <span
          className={cn(s.action_link, 'mr-1')}
          onClick={() => on_remove(role)}
        >
          [x]
        </span>
        {role.name}
      </div>
      <ChildRoleRows
        role={role}
        id_to_child_roles={id_to_child_roles}
      />
    </div>
  )
}

export default RoleRow