import React from 'react'
import { withRouter } from 'react-router-dom'

import { MonitorCard } from './MonitorCard.js'
import { TileDropdown } from '../dashboard_tiles/TileDropdown.js'
import { ID_TO_MONITOR_TOOL, MONITOR_TOOLS } from '../../model/monitor_tools.js'

import s from './MonitorNavigationPanel.module.scss'


const MonitorNavigationPanel = ({current_page_id, report_id, history}) => {

  const current_page = ID_TO_MONITOR_TOOL[current_page_id]

  function go_to_monitoring_tool(tool_id) {
    if (tool_id !== current_page_id) {
      history.push(ID_TO_MONITOR_TOOL[tool_id].get_path(report_id))
    }
  }

  return (
    <MonitorCard className={s.navigation_panel} top_logo={true}>
      <div className='ml-auto mr-0 w-25'>
        <TileDropdown
          selected_value={current_page ? current_page.name : null}
          items={MONITOR_TOOLS}
          label={current_page ? current_page.name : 'Monitoring tools'}
          on_change_handler={(tool_id) => go_to_monitoring_tool(tool_id)}
        />
      </div>
    </MonitorCard>
  )
}

export default withRouter(MonitorNavigationPanel)