import React from 'react'
import cn from 'classnames'

import { ScrollModal } from '../widgets/Modal.js'
import ForwardBackSelector from '../widgets/ForwardBackSelector.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import FamilyDetails from './FamilyDetails.js'
import CipherFamilyLink from '../widgets/CipherFamilyLink'
import { create_cipher_family_id_from_family_id } from '../../utils/family_view_utils.js'

import s from './FamilyViewModal.module.scss'

const FamilyViewModal = (
  {
    on_hide,
    patfam,
    on_prev,
    on_next,
    idx,
    total_num_families,
    is_fetching,
    error_fetching,
    on_retry,
    patent_link_option_id,
    on_change_patent_link_option_id,

    is_family_tagging_mode_on,
    family_tagging_search_phrase,
    on_toggle_family_tagging_mode,
    on_update_family_tagging_search_phrase,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,

    hide_paging_controls,

  }) => {
  const {patFamId} = patfam || {}

  return (
    <ScrollModal
      headerClassName={cn('w-100')}
      bodyClassName={s.modal_body}
      title={
        <div className={cn('d-flex')}>
          <CipherFamilyLink
            className={s.header_family_label}
            family_id={create_cipher_family_id_from_family_id(patFamId)}
            display_text={true}
            display_link_icon={true}
          />

          {!hide_paging_controls &&
            <ForwardBackSelector
              className={cn('ml-auto', s.forward_backward_selector)}
              label={'family'}
              current_idx={idx}
              min_idx_incl={0}
              max_idx_excl={total_num_families}
              on_prev={on_prev}
              on_next={on_next}
            />
          }

        </div>
      }
      footer={null}
      on_hide={on_hide}
    >
      {/* BODY of modal */}

      {!is_fetching && !error_fetching &&
        <FamilyDetails
          is_in_modal={true}
          family={patfam}

          patent_link_option_id={patent_link_option_id}
          on_change_patent_link_option_id={on_change_patent_link_option_id}

          is_family_tagging_mode_on={is_family_tagging_mode_on}
          family_tagging_search_phrase={family_tagging_search_phrase}
          on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
          on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

          knn_family_ids={knn_family_ids}
          knn_blocklist={knn_blocklist}
          knn_bookmarked={knn_bookmarked}
          on_add_to_knn_family_ids={on_add_to_knn_family_ids}
          on_add_to_knn_blocklist={on_add_to_knn_blocklist}
          on_add_to_knn_bookmarked={on_add_to_knn_bookmarked}
        />
      }

      {is_fetching &&
        <Spinner/>
      }

      {error_fetching &&
        <ErrorBody
          on_hide={on_hide}
          on_retry={on_retry}
          error={error_fetching}
          context='fetching patent families'
        />
      }
    </ScrollModal>
  )
}

export default FamilyViewModal