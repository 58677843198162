import axios from 'axios'
import _ from 'underscore'
import { LRUMap } from 'lru_map'

import { add_source_err_to_target_err } from './axios_utils.js'

import {
  ORG_SEARCH_BY_EXACT_NAME_URL,
  ORG_SEARCH_BY_NAME_MULTIPLE_URL,
  ORG_SEARCH_BY_NAME_URL,
  ORG_SEARCH_BY_TAG_URL,
  ORG_SUGGESTIONS_BY_ASSIGNEE_IDS_URL,
  ORG_SUGGESTIONS_BY_ORG_IDS_URL,
  ORG_SUGGESTIONS_BY_PAT_FAM_IDS_URL,
  ORGANISATION_BASE_URL,
  SAMPLES_BASE_URL,
  TRANSLATE_URL
} from '../constants/urls.js'

import {
  GROUP_BY_TYPE_ORDER,
  SORT_TYPE_BY_ID,
  TYPE_ORGANISATION,
  TYPE_ASSIGNEE,
  TYPE_AGGLOMERATION,
  TYPE_AGGLOMERATED_ASSIGNEES,
  TYPE_ORGANISATION_ASSIGNEE_GROUP
} from '../model/organisation.js'
import { DESCENDING } from '../model/sort_directions.js'
import { generate_random_hash, get_as_map, get_cipher_hostname, is_int } from './utils.js'
import { create_grouping_suggestion_resource, GROUPING_SUGGESTIONS_KEY } from './static_data_utils.js'
import { send_feedback_email } from './feedback_utils.js'
import { FEEDBACK_CATEGORIES_BY_ID } from '../model/feedback.js'
import { ASSIGNEE_GROUPING } from '../constants/paths.js'
import { BASKET_S3_PARAM } from '../constants/ag_tool.js'
import { normalise_search_phrase } from './url_utils.js'

export const SEARCH_DEBOUNCE_PERIOD = 500 //delay searching for results

const AG_SEARCH_LIMIT = 100
const CORE_SEARCH_LIMIT = 50
const MULTIPLE_SEARCH_LIMIT = 6
const ORG_SERVICE_MAX_BATCH_SIZE = 250

export const SEARCH_RESULTS_ORDER_BY_RELEVANCE = 'relevance'
export const SEARCH_RESULTS_ORDER_BY_ORG_ID_DESC = 'org_id_desc'

export const SEARCH_RESULTS_ORDER_OPTIONS = [
  {id: SEARCH_RESULTS_ORDER_BY_RELEVANCE, name: 'Relevance'},
  {id: SEARCH_RESULTS_ORDER_BY_ORG_ID_DESC, name: 'Time (newest first)'}
]

export const BY_PAT_FAMS_PORTFOLIO = 'by_pat_fams'
export const BY_PAT_ASSIGNEES_PORTFOLIO = 'by_assignees'

function normalise_full_company_name(name) {
  return normalise_search_phrase(name)
    .replace(/,$/, '')                // remove trailing comma
    .replace(/Corporation$/i, 'Corp') // shorten "Corporation" to "Corp" at end of string (better matching in most cases)
}

export function translate_name(name_to_translate) {
  return axios.post(TRANSLATE_URL + '/phrase', {'source': name_to_translate})
    .then(response => {
      return response.data[0]
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to translate organisation name')
      throw wrapped_err
    })
}

export function extract_multiple_org_names_to_search(input_value) {
  const input_names = input_value.trim().split("\n").filter(name => search_phrase_has_enough_alphanumeric_characters(name))
  const normalised_names = input_names.map(name => normalise_full_company_name(name))
  return _.uniq(normalised_names)
}
function search_phrase_has_enough_alphanumeric_characters(search_phrase) {
  return search_phrase && search_phrase.replace(/\W/g, '').length > 1
}

function search_organisations(search_phrase, roots_only, filter_empty) {
  const [type, id=''] = search_phrase.split('/')

  const is_organisation_key = (type === TYPE_ORGANISATION)
  const is_assignee_key = (type === TYPE_ASSIGNEE)

  const is_id_valid = id.length > 0 && is_int(id*1)

  const is_ag_mode = (roots_only === true) //roots only is set to true only in AG mode

  if ((is_organisation_key || is_assignee_key) && is_id_valid && is_ag_mode) {
    return search_results_by_key(search_phrase, is_organisation_key)
  } else {
    return search_results_by_phrase(search_phrase, roots_only, filter_empty, is_ag_mode ? AG_SEARCH_LIMIT : CORE_SEARCH_LIMIT)
  }
}

function search_results_by_phrase(search_phrase, roots_only, filter_empty, limit) {
  const url = `${ORG_SEARCH_BY_NAME_URL}${encodeURIComponent(search_phrase)}&return_roots_only=${roots_only}&filter_empty=${filter_empty}&limit=${limit}`
  return axios.get(url)
    .then((response) => {
      const {data} = response
      const results = data
      return {search_phrase, results}
    })
}

export function search_multiple_orgs_by_phrase_list(search_phrases, return_roots_only, filter_empty) {
  const data = {
    names: search_phrases,
    return_children: false,
    limit: MULTIPLE_SEARCH_LIMIT,
    return_roots_only,
    filter_empty
  }
  return axios.post(ORG_SEARCH_BY_NAME_MULTIPLE_URL, data)
    .then(response => response.data)
}

function search_results_by_key(organisation_key, is_tree) {
  const url = `${ORGANISATION_BASE_URL}/${organisation_key}${is_tree ? '/tree' : ''}`

  return axios.get(url)
    .then((response) => {
      const {data} = response
      const results = [data]
      return {search_phrase: organisation_key, results}
    })
}

export function fetch_organisations_by_search_phrase(search_phrase, roots_only, filter_empty) {
  const normalised_phrase = normalise_search_phrase(search_phrase) // normalisation occurs server-side also, but do client-side to prevent strange searches (i.e. otherwise '&' will return a large number of org_results)

  if(normalised_phrase.trim().length > 0) {

    return search_organisations(normalised_phrase, roots_only, filter_empty)
      .catch(err => {
        const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch org search results (by search phrase) from service: ')
        throw wrapped_err
      })
  } else {
    return new Promise((resolve) => {
      resolve({search_phrase, results: []})
    })
  }
}

export function fetch_organisation_by_exact_name(search_phrase) {
  const normalised_phrase = normalise_search_phrase(search_phrase)
  return axios.get(ORG_SEARCH_BY_EXACT_NAME_URL + `${encodeURIComponent(normalised_phrase)}`)
    .then((response) => {
      return {search_phrase, results: response.data}
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch org search results (by exact name) from service: ')
      throw wrapped_err
    })
}

export function fetch_organisation_details_by_id(org_id) {
  return axios
    .get(`${ORGANISATION_BASE_URL}/${org_id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch org details by id: ')
      throw wrapped_err
    })
}

export function fetch_multiple_organisations_by_ids(org_ids, include_assignee_ids=true) {
  const params = {include_assignee_ids}
  return axios.get(`${ORGANISATION_BASE_URL}/organisations/${org_ids.join(',')}`, {params})
    .then(response => response.data)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch orgs\' details by ids: ')
      throw wrapped_err
    })
}

export function fetch_multiple_assignees_by_ids(assignee_ids) {
  return axios.get(`${ORGANISATION_BASE_URL}/assignees/${assignee_ids.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch assignees\' details by ids: ')
      throw wrapped_err
    })
}

export function fetch_organisations_by_tags(tags) {
  /*  GET /organisation/search_tags?q=<tag dsl>, where the DSL is of the form:
      a AND b
      a OR b OR c AND (d OR e)
      (a AND !b) OR (c AND !d)*/

  return axios
    .get(ORG_SEARCH_BY_TAG_URL  + `${encodeURIComponent(tags)}`)
    .then((response) => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch org search results (by tags) from service: ')
      throw wrapped_err
    })
}

export function fetch_organisation_tree_by_id(org_id) {
  return axios
    .get(`${ORGANISATION_BASE_URL}/${org_id}/tree`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch org tree by id: ')
      throw wrapped_err
    })
}

export function update_organisation_meta({org_id, name, tags, non_practicing_entity_override, notes, grouping_only, user_id}) {
  return axios
    .post(`${ORGANISATION_BASE_URL}/${org_id}/update_metadata`, {user_id, fields: {name, tags, non_practicing_entity_override, notes, grouping_only}})
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to update org: ')
      throw wrapped_err
    })
}

export function create_new_organisation({name, tags, notes, user_id, child_ids}) {
  return axios
    .post(`${ORGANISATION_BASE_URL}/organisation`, {name, tags, notes, child_ids: child_ids || [], user_id})
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to create new org: ')
      throw wrapped_err
    })
}

export function update_organisation_tree({org_id, child_ids, user_id}) {
  return axios
    .post(`${ORGANISATION_BASE_URL}/${org_id}/add_children`, {user_id, child_ids})
    .then(() => {
      return {org_id, child_ids}
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to do grouping: ')
      throw wrapped_err
    })
}

export function remove_organisation_from_parent({org_id, user_id}) {
  return axios
    .post(`${ORGANISATION_BASE_URL}/${org_id}/clear_parent`, {user_id})
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to remove org from parent: ')
      throw wrapped_err
    })
}

export function get_organisation_changelog_by_id(org_id) {
  return axios
    .get(`${ORGANISATION_BASE_URL}/log?organisation_id=${org_id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch change log for organisation: ')
      throw wrapped_err
    })
}

export function fetch_empty_organisations() {
  return axios
    .get(`${ORGANISATION_BASE_URL}/organisation/empty`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch empty organisations: ')
      throw wrapped_err
    })
}

export function delete_organisation({org_id, user_id}) {
  return axios
    .post(`${ORGANISATION_BASE_URL}/${org_id}/delete`, {user_id})
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to delete organisation: ')
      throw wrapped_err
    })
}

export function delete_all_empty_organisations({user_id}) {
  return axios
    .post(`${ORGANISATION_BASE_URL}/organisation/delete_empty`, {user_id})
    .then(response => {
      return response.data
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to delete empty organisations: ')
      throw wrapped_err
    })
}


function get_missing_organisations_promise(org_ids) {
  return axios.get(`${ORGANISATION_BASE_URL}/organisation/${org_ids.join(',')}/missing`)
}

export function get_assignee_ids_for_organisation(organisation_id) {
  return axios.get(`${ORGANISATION_BASE_URL}/organisation/${organisation_id}/assignee_ids`)
    .then(response => response.data)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch assignee ids for organisation: ')
      throw wrapped_err
    })
}


export function get_organisation_size({ organisation_ids, assignee_ids }) {
  const params = {
    ...organisation_ids && organisation_ids.length > 0 ? {organisation_ids} : {},
    ...assignee_ids && assignee_ids.length > 0 ? {assignee_ids} : {}
  }

  return axios.post(`${ORGANISATION_BASE_URL}/total_size`, params)
    .then(response => response.data)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch organisation size: ')
      throw wrapped_err
    })
}

export function get_org_item_size_input(item) {
  const {id, assignee_ids} = item
  if (is_organisation(item)) {
    return {organisation_ids: [id]}
  }

  if (is_assignee(item)) {
    return {assignee_ids: [id]}
  }

  if (is_agglomeration(item)) {
    return {assignee_ids}
  }

  if (is_org_group_type(item)) {
    const {members} = item

    const organisation_ids = []
    let assignee_ids = []

    members.forEach(group_item => {
      const {id, assignee_ids: group_item_assignee_ids} = group_item || {}

      if (is_organisation(group_item)) {
        organisation_ids.push(id)
      }

      if (is_assignee(group_item)) {
        assignee_ids.push(id)
      }

      if (is_agglomeration(group_item)) {
        assignee_ids = [...assignee_ids, ...group_item_assignee_ids]
      }
    })

    return ({organisation_ids, assignee_ids})
  }

  return {}
}

function get_org_item_size(item) {
  return get_organisation_size(get_org_item_size_input(item))
}

export function get_organisations_sizes(organisations) {
  if (!organisations || organisations.length === 0) return Promise.resolve(null)

  return Promise.all(organisations.map(item => get_org_item_size(item)))
    .then(sizes => {
      const orgs_to_sizes = {}
      organisations.forEach((item, i) => {
        orgs_to_sizes[get_org_id(item)] = sizes[i] || 0
      })

      return orgs_to_sizes
    })
}

export function check_for_invalid_organisations({org_ids}) {
  if (!org_ids || org_ids.length === 0)  return Promise.resolve([])

  const org_ids_chunks = _.chunk(org_ids, ORG_SERVICE_MAX_BATCH_SIZE)

  return Promise.all(org_ids_chunks.map(ids => get_missing_organisations_promise(ids)))
    .then(responses => {
      let missing = []

      responses.forEach(response => {
        missing = [...missing, ...response.data]
      })

      return missing
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to verify invalid organisations: ')
      throw wrapped_err
    })
}

export function add_suborganisation(parent_org, org_to_add, user_id) {
  const org_id = get_org_id(parent_org)

  return create_new_organisation({...org_to_add, user_id})
    .then(new_org_key => {
      const new_org_id = `${TYPE_ORGANISATION}/${new_org_key}`
      return update_organisation_tree({org_id, child_ids: [new_org_id], user_id})
    })
    .then(({child_ids}) => {
      const [, id] =  get_org_type_and_id(child_ids[0])
      return {...org_to_add, size: 0, size_active: 0, type: TYPE_ORGANISATION, id}
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to add suborganisation: ')
      throw wrapped_err
    })
}

export function turn_agglomeration_into_organisation({parent_id, agglomeration, user_id}) {
  const { name } = agglomeration
  const child_ids = agglomeration.children.map(item => (get_org_id(item)))

  return create_new_organisation({name, tags: null, child_ids,  user_id})
    .then((new_org_key) => {
      const new_org_id = `${TYPE_ORGANISATION}/${new_org_key}`
      return update_organisation_tree({org_id: parent_id, child_ids: [new_org_id], user_id})
    })
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to turn agglomeration into organisation: ')
      throw wrapped_err
    })
}

export function send_grouping_suggestion(group_name, group) {
  const resource = `user_custom_${generate_random_hash()}.json`
  const basket_items = group.reduce((basket, org={}) => {
    if (is_agglomeration(org))  {

      const assignees = (get_all_assignees(org) || []).map(assignee => {
        const {id, type, name} = assignee || {}
        return {id, type, name}
      })

      return [...basket, ...assignees || []]
    }
    const {id, type, name} = org
    return [...basket, {id, type, name}]
  }, [])

  const ag_tool_url = `${get_cipher_hostname()}${ASSIGNEE_GROUPING}?${BASKET_S3_PARAM}=${resource}&key=${GROUPING_SUGGESTIONS_KEY}`

  return Promise.all([
    create_grouping_suggestion_resource(resource, {target_org: group_name, basket_items}),
    send_feedback_email({
      url: ag_tool_url,
      category: FEEDBACK_CATEGORIES_BY_ID.grouping.id,
      subject: `Grouping suggestion: ${group_name}`,
      comment: `suggested grouping: ${group_name}`,
      user_state_url: ag_tool_url
    })
  ])
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to notify about grouping suggestion: ')
      throw wrapped_err
    })
}

export function is_agglomeration(org) {
  if (!org) return false
  const {type} = org
  return type === TYPE_AGGLOMERATION || type === TYPE_AGGLOMERATED_ASSIGNEES
}

export function is_organisation(org) {
  if (!org || is_agglomeration(org)) return false
  const {type} = org
  return type === TYPE_ORGANISATION
}

export function is_assignee(org) {
  if (!org || is_agglomeration(org))  return false
  const {type} = org
  return type === TYPE_ASSIGNEE
}

export function is_org_group_type(item) {
  const {type} = item
  return _.isEqual(type, TYPE_ORGANISATION_ASSIGNEE_GROUP)
}

export function is_org_type(item) {
  return is_organisation(item) || is_assignee(item) || is_agglomeration(item)
}

export function get_org_id(org) {
  if (!org) return ''

  const {type, id} = org

  const updated_type = (type === TYPE_AGGLOMERATED_ASSIGNEES) ? TYPE_AGGLOMERATION : type

  const id_to_use = (updated_type === TYPE_AGGLOMERATION) && !id ? get_org_id_if_agglom(org) : id

  return `${updated_type}/${id_to_use}`
}

function get_org_id_if_agglom(org) {
  const {assignee_ids} = org
  const agglomerated_assignee_ids = assignee_ids || get_all_assignee_ids(org)
  return agglomerated_assignee_ids.sort().join(',')
}

export function get_org_type_and_id(org_id='') {
  return org_id.split('/')
}

export function get_org_suggestions_for_items(base_items, search_type) {
  if (search_type === BY_PAT_FAMS_PORTFOLIO) {
    return get_org_suggestions_by_pat_fam_ids(base_items)
  }

  const orgs = base_items.filter(item => (is_organisation(item)))
  const handler = orgs.length === base_items.length ? get_org_suggestions_by_org_ids : get_org_suggestions_by_assignee_ids
  return handler(base_items)
}

function get_org_suggestions_by_assignee_ids(items) {
  let assignee_ids = []

  items.forEach(item => {

    if (is_assignee(item)) {
      assignee_ids.push(item.id)
    } else {
      //quick reports have different input structure and comparables are returned with list of assignee ids rather than org id
      const item_assignee_ids = item ? item.assignee_ids || get_all_assignee_ids(item) : []
      assignee_ids = [...assignee_ids, ...item_assignee_ids]
    }
  })
  return axios.get(ORG_SUGGESTIONS_BY_ASSIGNEE_IDS_URL + `/${assignee_ids.join(',')}`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch org suggestions by assignee ids from comparables service: ')
      throw wrapped_err
    })
}

export function get_org_suggestions_by_org_ids(items) {
  const org_ids = items.map(item => (item.id))

  return axios.get(ORG_SUGGESTIONS_BY_ORG_IDS_URL + `/${org_ids.join(',')}`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch org suggestions by organisation ids from comparables service: ')
      throw wrapped_err
    })
}

export function get_org_suggestions_by_pat_fam_ids(pat_fam_ids) {
  return axios.post(ORG_SUGGESTIONS_BY_PAT_FAM_IDS_URL, pat_fam_ids)
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch org suggestions by patent family ids from comparables service: ')
      throw wrapped_err
    })
}

export function get_ordered_children(children, sort) {
  const { sort_by, sort_dir } = sort || {}

  switch (sort_by) {
    case SORT_TYPE_BY_ID.size.id: return order_nodes_by_size(children, sort_dir === DESCENDING)
    case SORT_TYPE_BY_ID.name.id: return order_nodes_by_name(children, sort_dir === DESCENDING)
    default: return children
  }
}

function order_nodes_by_size(children, desc) {
  return _.sortBy(children, item => item.size_active * (desc ? -1 : 1))
}

function order_nodes_by_name(children, desc) {
  const ordered_children = _.sortBy(children, item => item.name.toLowerCase())

  return (desc) ? ordered_children.reverse() : ordered_children
}

export function get_grouped_children(children) {
  const groups = children.reduce((arr, el) => {
    (arr[el['type']] = arr[el['type']] || []).push(el)
    return arr
  }, {})

  let grouped_children = []

  GROUP_BY_TYPE_ORDER.forEach(type => {
    grouped_children = [...grouped_children, ...groups[type] || []]
  })

  return grouped_children
}

export function get_all_assignee_ids(org_tree) {
  const ids = []

  visit_all_assignees(org_tree, ids, (item) => (item.id))

  return ids
}

export function get_all_assignees(org_tree) {
  const {children = []} = org_tree
  const assignees = []

  if (children.length === 0) {
    return assignees
  }

  visit_all_assignees(org_tree, assignees, (item) => (item))

  return assignees
}

function visit_all_assignees(org, arr, return_property_handler) {
  const children = org.children || []

  if (children.length === 0 && is_assignee(org)){
    arr.push(return_property_handler(org))
  }

  children.forEach(child => {visit_all_assignees(child, arr, return_property_handler)})
}

export function get_all_children_names(org_tree) {
  const names = []

  visit_all_children(org_tree, names, (item) => (item.name))

  return names
}

function visit_all_children(org, arr, return_property_handler) {
  const children = org.children || []

  children.forEach(child => {
    arr.push(return_property_handler(child))

    visit_all_children(child, arr, return_property_handler)
  })
}

export function update_tree_with_agglomeration_ids(org_tree) {
  if (!org_tree) return {}
  const tree = {...org_tree}

  add_id_to_agglomerations(tree)

  return tree
}

function add_id_to_agglomerations(org) {
  const {children = [] } = org

  children.forEach(item => {
    if (is_agglomeration(item)) {
      item['id'] = get_org_id_if_agglom(item)
    }

    if (is_organisation(item)) {
      add_id_to_agglomerations(item)
    }
  })
}

export function update_tree_with_parents(org_tree) {
  if (!org_tree) return {}

  const tree = (org_tree.parents) ? org_tree : {...org_tree, parents: [], parent_tags: []}

  add_parents_to_children(tree)

  return tree
}

function add_parents_to_children(org) {
  const {children = [], parents, parent_tags, tags } = org

  children.forEach(child => {
    child['parents'] = [...parents, get_org_id(org)]
    child['parent_tags'] = [...parent_tags, tags || []]
    add_parents_to_children(child)
  })
}

export function are_same_orgs(org1, org2) {
  if (org1 == null || org2 == null) return false

  return get_org_id(org1) === get_org_id(org2)
}

//samples

function get_with_caching(org, cache, getter) {
  const key = get_org_id(org)
  const time_now = new Date().getTime()
  const { promise, time_fetched } = cache.get(key) || {}

  const is_stale = (time_fetched != null) && ((time_now - time_fetched) > SAMPLES_CACHE_TTL)

  if (promise && !is_stale) {
    return promise
  }

  const new_promise = getter(org)
  cache.set(key, { promise: new_promise, time_fetched: new Date().getTime() })
  return new_promise
}

// The samples APIs tend to get called when users hover. Because this can result in users scanning
// back and forth between the same result, it's useful to have a local in memory cache

const SAMPLES_CACHE_MAX_SIZE = 100
const SAMPLES_CACHE_TTL      = 1000 * 60 * 5                       // 5 minutes (in milliseconds)

const patents_cache          = new LRUMap(SAMPLES_CACHE_MAX_SIZE)
const litigations_cache      = new LRUMap(SAMPLES_CACHE_MAX_SIZE)

export function get_patents_sample_with_cache(org) {
  return get_with_caching(org, patents_cache, get_patents_sample)
}

export function get_litigations_sample_with_cache(org) {
  return get_with_caching(org, litigations_cache, get_litigations_sample)
}

function get_url_for_samples(org, url_suffix) {
  const { id } = org
  const url_id_part = is_organisation(org) ? `organisation/${id}` : `assignee/${get_all_assignee_ids(org)}`

  return `${SAMPLES_BASE_URL}/${url_id_part}/${url_suffix}`
}

function get_patents_sample(org) {
  const url = get_url_for_samples(org, 'patents')

  return axios.get(url, {responseType: 'json'})
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patents sample: ')
      throw wrapped_err
    })
}

function get_litigations_sample(org) {
  const url = get_url_for_samples(org, 'litigations')

  return axios.get(url, {responseType: 'json'})
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch litigations sample: ')
      throw wrapped_err
    })
}

export function is_organisation_empty(org_tree) {
  return get_all_assignee_ids(org_tree).length === 0
}

export function get_parent_details(org, results) {
  if (!org) return null
  const { root_organisation_ids } = org
  if (!root_organisation_ids || root_organisation_ids.length === 0) return null
  const results_by_id = get_as_map(results, 'id')
  const parents = root_organisation_ids.map(root_id => (results_by_id[root_id])).filter(item => (item != null))
  return parents.length > 0 ? parents : null
}

export function is_any_name_match(org_tree, filter_by='') {
  //check if anything in the org tree or the organisation itself matches the search phrase

  if (filter_by === '') return true

  const lc_filter_by = filter_by.toLowerCase()
  const names = get_all_children_names(org_tree)
  names.push(org_tree.name)
  return _.some(names, name => (name.toLowerCase().indexOf(lc_filter_by) !== -1))
}
