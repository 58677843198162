import axios from 'axios'
import _ from 'underscore'
import sha1 from 'js-sha1'
import { struct } from 'pb-util'

import { TRAINING_SET_SERVICE_BASE_URL } from '../constants/urls.js'
import { axios_save_instance, add_source_err_to_target_err } from './axios_utils.js'
import { extract_date_string_from_date, get_as_key_to_val, get_as_map } from './utils.js'
import {
  get_patent_families_by_ids,
} from './patent_family_list_utils.js'

import {
  get_tag_tags,
  get_client_tags,
  get_status_tags
} from '../components/classifiers_editor/utils/tag_utils.js'

import { POSITIVE, NEGATIVE, IGNORE, LABEL_TO_ENUM, UNKNOWN, TEST_POSITIVE, TEST_NEGATIVE } from '../components/classifiers_editor/constants/labels.js'
import { DEFAULT_TITLE } from '../components/classifiers_editor/constants/constants.js'

import { EDIT, ID_TO_PERMISSION, OWNER, PERMISSION_EDIT, PERMISSION_OWNER } from '../components/classifiers_editor/model/permission_levels.js'
import { IS_ME } from '../components/classifiers_editor/model/permission_entities.js'
import { ASCENDING } from '../model/sort_directions.js'
import { ALL_ES_FIELD_IDS, PRIORITY_DATE_FIELD_ID } from '../model/patent_family_fields.js'
import { BUILD_CLASSIFIER_FAILED_ID, BUILD_CLASSIFIER_ID, REVERT_LATEST_BUILT_CLASSIFIER_ID } from '../components/classifiers_editor/model/log_events.js'
import { poll } from './choreo_utils.js'
import { BUILD_CLASSIFIER_STATUS_NOT_BUILDING, BUILD_CLASSIFIER_STATUS_UNKNOWN } from '../components/classifiers_editor/constants/build_classifier_status.js'

const SHORT_TIMEOUT = { timeout: 30 * 1000 } // 30 seconds (workaround as ReactTable with 500 rows render so slow it blocks the request/response. TODO: once ReactTable is replaced, set timeout to 1 second)

const SLOW_TABLE_RENDER_WARNING = 'NOTE timeout can be caused by slow table render blocking: '

function check_is_timeout(err) {
  return (err && err.message && err.message.indexOf('timeout') !== -1)
}

// For protocol buffers definitions, see 'training_set.proto'
// For notes on using submodules, see README.md

export function get_v2_training_set_id(user_email, v1_classifier_id) {
  const owner_id = sha1(user_email)
  return `${owner_id}__${v1_classifier_id}`
}

export function get_eval_training_set_id(evaluation_classifier_id, evaluation_classifier_owner_id) {
  if (evaluation_classifier_owner_id != null) {
    // Classifiers v1 (2018-2021) eval reports have owner_id and classifier_id
    // Here, we convert this to a single compound id (to match the migrated classifier)
    // TODO: once Classifiers v2 has been live for a few months, we can delete the
    //       old eval reports, remove the 'owner' field from the db, and remove this logic.
    return `${evaluation_classifier_owner_id}__${evaluation_classifier_id}`
  }

  // Classifiers v2
  return evaluation_classifier_id
}

export function process_training_set_info(training_set_info, id_to_user) {
  const {
    name,
    owner_user_uuid, labelled_pat_fam_counts, requester_permission,
    created_at, modified_at,
    tags: prefixed_tags,
    alias,
  } = training_set_info

  // join and merge with users data (owner_user_uuid)
  const owner_obj   = id_to_user[owner_user_uuid]
  const owner_email = owner_obj ? owner_obj.email : owner_user_uuid

  const tag_tags     = get_tag_tags(prefixed_tags)
  const client_tags  = get_client_tags(prefixed_tags)
  const status_tags  = get_status_tags(prefixed_tags)

  const { num_positives, num_negatives, num_ignores } = labelled_pat_fam_counts || {}

  const created_at_seconds    = created_at.seconds
  const last_modified_seconds = modified_at.seconds

  const requester_permission_level = ID_TO_PERMISSION[requester_permission] || {}
  const requester_permission_level_string = requester_permission_level.access || 'No access'

  return {
    ...training_set_info,
    id: alias, // MUI datagrid needs a unique id
    name: name || DEFAULT_TITLE,
    owner_email, client_tags, tag_tags, status_tags, num_positives, num_negatives, num_ignores,
    requester_permission, requester_permission_level_string, last_modified_seconds, created_at_seconds,
  }
}

export function get_new_training_sets_to_add(is_grant_edit_to_copying_user, is_copy_tags, is_copy_patfams, selected_destinations, copyable_training_sets, new_aliases, new_names) {
  const now_in_seconds = new Date().getTime() / 1000
  const now_in_ts_time_format = { seconds: now_in_seconds, nanos: 0}

  const new_training_sets_to_add_per_destination = selected_destinations.map(destination => {
    const { [IS_ME]: destination_is_me, id: destination_id } = destination

    if (!destination_is_me && !is_grant_edit_to_copying_user) {
      // These copies are not visible to the current account
      return []
    }

    // These copies are visible to current user, so create them
    const new_training_sets = copyable_training_sets.map((training_set, i) => {
      const { owner_user_uuid } = training_set
      const original_owner_not_me_but_dest_is_me = destination_is_me && (owner_user_uuid !== destination_id)

      const new_alias = new_aliases[i]
      const new_name  = new_names[i]
      return {
        ...training_set,
        name: new_name,
        alias: new_alias,
        id:    new_alias,
        created_at: now_in_ts_time_format,
        modified_at: now_in_ts_time_format,
        starred: false,
        ...(!destination_is_me                   ? { owner_user_uuid: destination_id, requester_permission: EDIT, requester_permission_level_string: PERMISSION_EDIT.access } : {}), // destination not me, but give me access the to the copy
        ...(original_owner_not_me_but_dest_is_me ? { owner_user_uuid: destination_id, requester_permission: OWNER, requester_permission_level_string: PERMISSION_OWNER.access  } : {}),
        ...(!is_copy_tags ? { tags: []} : {}),
        ...(!is_copy_patfams ? { labelled_pat_fam_counts: { num_ignores: 0, num_negatives: 0, num_positives: 0 } } : {})
      }
    })

    return new_training_sets
  })

  const new_training_sets_to_add = _.flatten(new_training_sets_to_add_per_destination, 1)

  return new_training_sets_to_add
}

export function extract_date(pb_timestamp) {
  if (!pb_timestamp) {
    return null
  }
  const { seconds } = pb_timestamp
  return new Date(+seconds * 1000)
}

export function extract_date_as_iso_string(pb_timestamp) {
  if (!pb_timestamp) {
    return null
  }
  const date = extract_date(pb_timestamp)
  return extract_date_string_from_date(date)
}

export function get_latest_built_classifier(training_set_alias) {
  return fetch_classifier_versions(training_set_alias)
    .then(versions => {
      if (versions.length === 0) {
        return {}
      }

      // Need to sort by timestamp (as AWS does not guarantee the order)
      const versions_sorted = _.sortBy(versions, (version) => -version.last_modified.seconds)
      const latest_version = versions_sorted[0]
      const { version_id } = latest_version

      return fetch_classifier_metadata(training_set_alias, version_id)
        .then(metadata => {
          return {
            latest_built_classifier_version:    version_id,
            latest_built_classifier_metadata:   metadata
          }
        })
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to get latest built classifier: ')
      throw wrapped_err
    })
}

export function fetch_classifier_versions(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListClassifierVersions`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch classifier versions: ')
      throw wrapped_err
    })
    .then(response => response.data.versions)
}

export function fetch_classifier_metadata(training_set_alias, classifier_version) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/GetClassifierMetadata`, { classifier_id: training_set_alias, classifier_version })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch classifier metadata: ')
      throw wrapped_err
    })
    .then(response => response.data.metadata)
}

export function fetch_classifiers() {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListClassifiers`, {})
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch list of classifiers: ')
      throw wrapped_err
    })
    .then(response => response.data.classifiers)
}

/**
 * This does a synchronous build.
 * It is better to use the "job" based endpoints below, as these will not have timeout issues for large jobs.
 */
export function build_classifier_sync(training_set_alias, classifier_engine, params) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/BuildClassifier`, { alias: training_set_alias, classifier_engine, params })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to build classifier: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function create_build_classifier_job(training_set_alias, classifier_engine, params) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/CreateBuildClassifierJob`, { alias: training_set_alias, classifier_engine, params })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create build classifier job: ')
      throw wrapped_err
    })
    .then(response => response.data.build_job_id)
}

export function get_build_classifier_job_status(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/GetBuildClassifierJobStatus`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to build classifier job status: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function poll_build_classifier_job_status_till_not_building(training_set_alias) {
  return poll(
    get_build_classifier_job_status.bind(this, training_set_alias),     // req_fn
    ({ status }) => (status === BUILD_CLASSIFIER_STATUS_NOT_BUILDING ), // is_done_fn
    null,                                                               // is_fail_fn
    ({ status }) => (status === BUILD_CLASSIFIER_STATUS_UNKNOWN),       // is_error_fn
    null,                                                               // progress_fn
    null,                                                               // interrupt_fn
    1000 * 15                                                           // interval (in milliseconds)
  )
}

export function classify_patfams(training_set_alias, classifier_version, patfam_ids) {
  if (patfam_ids.length === 0) {
    return Promise.resolve([])
  }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ClassifyPatFams`, { classifier_id: training_set_alias, classifier_version, pat_fam_ids: patfam_ids })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to classify patfams: ')
      throw wrapped_err
    })
    .then(response => response.data.scores)
}

export function fetch_training_sets() {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListTrainingSets`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch training_sets by user from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.training_sets)
}

export function fetch_library_training_sets() {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListLibraryTrainingSets`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch library training_sets by user from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.library_training_sets)
}

export function fetch_group_training_sets(id_to_user_in_group) {
  // Ideally there would be a separate backend call for this, but for now we filter manually here.
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListLibraryTrainingSets`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch group training_sets by user from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.library_training_sets)
    .then(training_set_infos => {
      return training_set_infos.filter(training_set_info => {
        const { owner_user_uuid } = training_set_info
        return (id_to_user_in_group[owner_user_uuid] != null)
      })
    })
}

/**
 * Fetches information about a trainingset, including name, counts
 */
export function fetch_training_set_info(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/GetTrainingSet`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch training_set info from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.training_set)
}

export function fetch_training_set_permissions(training_set_alias, include_admin_ids) {
  const headers = { include_admin_ids }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListTrainingSetPermissions`, { alias: training_set_alias }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch training_set permissions from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.grants)
}

export function grant_permissions_on_training_set(training_set_alias, grants, include_admin_ids) {
  const headers = { include_admin_ids }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/GrantPermissions`, { alias: training_set_alias, grants }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to grant permissions on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function grant_permissions_on_training_sets(training_set_aliases, grants, include_admin_ids) {
  const headers = { include_admin_ids }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/GrantPermissionsOnTrainingSets`, { aliases: training_set_aliases, grants }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to grant permissions to multiple training sets on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function revoke_permissions_on_training_set(training_set_alias, permission_entities, include_admin_ids) {
  const headers = { include_admin_ids }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/RevokePermissions`, { alias: training_set_alias, permission_entities }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to revoke permissions on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

function get_transfer_ownership_flags(grant_edit_to_previous_owner) {
  return { grant_edit_to_previous_owner: grant_edit_to_previous_owner || false }
}

export function transfer_ownership(training_set_alias, destination_user_id, destination_group_id, grant_edit_to_previous_owner) {
  const dst_user = {
    user_uuid: destination_user_id,
    group_uuid: destination_group_id,
    role_ids: [] // for now, leave this empty
  }
  const transfer_ownership_flags = get_transfer_ownership_flags(grant_edit_to_previous_owner)

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/TransferOwnership`, { alias: training_set_alias, dst_user, transfer_ownership_flags })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to transfer ownership on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function transfer_ownerships(training_set_aliases, destination_user, grant_edit_to_previous_owner, include_admin_ids) {
  const headers = { include_admin_ids }

  const transfer_ownership_flags = get_transfer_ownership_flags(grant_edit_to_previous_owner)

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/TransferOwnerships`, { aliases: training_set_aliases, dst_user: destination_user, transfer_ownership_flags }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to transfer ownerships on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function copy_training_set(
  training_set_alias, destination_user_id, destination_group_id, destination_name,
  { copy_built_classifier, copy_highlight_phrases, copy_search_history, copy_tags, hide_copy_event, grant_edit_to_copying_user } // boolean flags
) {
  const dst_user = {
    user_uuid: destination_user_id,
    group_uuid: destination_group_id,
    role_ids: [] // for now, leave this empty
  }
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/CopyTrainingSet`, {
    alias: training_set_alias, dst_user, dst_name: destination_name,
    copy_flags: { copy_built_classifier, copy_highlight_phrases, copy_search_history, copy_tags, hide_copy_event, grant_edit_to_copying_user /* when copying to another user's account, grants edit permission to the user doing the copy */ }
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to copy training set on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function copy_training_sets(
  training_set_aliases, destination_user_objects, new_names,
  {
    copy_built_classifier,
    copy_highlight_phrases,
    copy_search_history,
    copy_tags,
    hide_copy_event,
    grant_edit_to_copying_user,
    skip_copy_pat_fams
  },
  include_admin_ids
) {
  const headers = { include_admin_ids }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/CopyTrainingSets`, {
    aliases: training_set_aliases, dst_users: destination_user_objects, dst_names: new_names,
    copy_flags: { copy_built_classifier, copy_highlight_phrases, copy_search_history, copy_tags, hide_copy_event, grant_edit_to_copying_user /* when copying to another user's account, grants edit permission to the user doing the copy */, skip_copy_pat_fams }
  }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to copy training sets on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function fetch_log_entries({ training_set_alias, limit, offset, ascending, filter_events }) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListLogEntries`, { alias: training_set_alias, limit, offset, ascending, filter_events })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch event log on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function fetch_latest_build_log_entry(training_set_alias, include_fails) {
  const filter_events = [
    BUILD_CLASSIFIER_ID,
    REVERT_LATEST_BUILT_CLASSIFIER_ID,
    ...(include_fails ? [BUILD_CLASSIFIER_FAILED_ID] : [])
  ]

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListLogEntries`, { alias: training_set_alias, limit: 1, filter_events })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch event log on training_set_service: ')
      throw wrapped_err
    })
    .then(response => {
      const { log_entries } = response.data
      if (log_entries.length === 0) {
        return {}
      }

      const entry = log_entries[0]
      const { details, event } = entry

      const details_parsed = JSON.parse(details)
      const { version, metadata } = details_parsed

      return {
        latest_built_classifier_version:    version,  // may be null if 'include_fails==true' and it's a fail
        latest_built_classifier_metadata:   metadata, // may be null if 'include_fails==true' and it's a fail
        event
      }
    })
}

export function create_new_training_set({ training_set_alias, name, notes, description, labelled_pat_fams }) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/CreateTrainingSet`, {
    name,
    alias: training_set_alias, // optional - Alias to create the training set with. If left blank, a new UUID will be used.
    notes,                     // optional
    description,               // optional
    labelled_pat_fams,         // optional
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create new training_set on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function delete_training_set(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/DeleteTrainingSet`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete training_set from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function delete_training_sets(training_set_aliases, include_admin_ids) {
  const headers = { include_admin_ids }

  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/DeleteTrainingSets`, { aliases: training_set_aliases }, { headers })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete training_sets from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

/**
 * Fetches the labelled patent families for a given training set
 */
export function fetch_training_set_labels(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListPatFams`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch labelled patents from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

/**
 * Input has shape { positives: [id1, id2, ....], negatives: [...] etc... }
 * Output has has shape { id1: 'positive', id2: 'positive', etc... }
 */
export function get_label_to_ids_as_id_to_label(label_to_ids) {
  const { positives, negatives, ignores, test_positives, test_negatives } = label_to_ids

  const id_to_label__pos      = get_as_key_to_val(positives,      POSITIVE)
  const id_to_label__neg      = get_as_key_to_val(negatives,      NEGATIVE)
  const id_to_label__ign      = get_as_key_to_val(ignores,        IGNORE)
  const id_to_label__test_pos = get_as_key_to_val(test_positives, TEST_POSITIVE)
  const id_to_label__test_neg = get_as_key_to_val(test_negatives, TEST_NEGATIVE)

  return {
    ...id_to_label__pos,
    ...id_to_label__neg,
    ...id_to_label__ign,
    ...id_to_label__test_pos,
    ...id_to_label__test_neg,
  }
}


export function fetch_training_set_patfams(training_set_alias, latest_built_classifier_version) {
  return fetch_training_set_labels(training_set_alias)
    .then(({ positives, negatives, ignores, test_positives, test_negatives }) => {
      const input_patfams = [
        ...positives.map(patfam_id => ({ patfam_id, user_class: POSITIVE })),
        ...negatives.map(patfam_id => ({ patfam_id, user_class: NEGATIVE })),
        ...test_positives.map(patfam_id => ({ patfam_id, user_class: TEST_POSITIVE })),
        ...test_negatives.map(patfam_id => ({ patfam_id, user_class: TEST_NEGATIVE })),
        ...ignores.map(  patfam_id => ({ patfam_id, user_class: IGNORE }))
      ]

      if (input_patfams.length < 1) {
        return Promise.resolve([])
      }

      return fetch_and_classify_patfams(input_patfams, null, training_set_alias, latest_built_classifier_version)
    })
}

/**
 *
 * @param {Array} input_patfams where each patfam has a property "user_class" (i.e. positive / negative / ignore)
 */
 export function fetch_and_classify_patfams(input_patfams, search_phrase="", training_set_alias, latest_built_classifier_version) {
   // NOTE: we fetch patents from text-search-service as this is way faster than domain-service.
  // Ideally we would use the pf-cache, but it currently does not have fields such as owners and assignees.
  const pat_fam_ids = input_patfams.map(p => p.patfam_id)
  // 1. In parallel: fetch text-search-service patents, classify patfams

  return Promise.all([
    get_patent_families_by_ids(pat_fam_ids, search_phrase, pat_fam_ids.length, 0, PRIORITY_DATE_FIELD_ID, ASCENDING, ALL_ES_FIELD_IDS),
    latest_built_classifier_version ? classify_patfams(training_set_alias, latest_built_classifier_version, pat_fam_ids) : Promise.resolve(null)
  ])
    .then(([text_search_patfams, scores]) => {
      // 2. Merge scores, user labels into text-search-service patents
      const {searchResults = []} = text_search_patfams || {}

      const id_to_text_search_patfam = get_as_map(searchResults, 'patFamId')

      const families_with_scores = []

      const is_filtering_by_search_phrase = (search_phrase && search_phrase !== '')

      input_patfams.forEach((input_patfam, idx) => {
        const { patfam_id, user_class } = input_patfam
        const text_search_patfam = id_to_text_search_patfam[patfam_id]

        if (!text_search_patfam && !is_filtering_by_search_phrase) {
          throw new Error(`patfam ${patfam_id} not found in text-search-service`)
        }


        if (text_search_patfam) {
          const score = (scores || [])[idx]
          const family = {
            ...input_patfam,
            ...text_search_patfam,
            patfam_id,
            id: patfam_id,
            user_class,
            ...(score != null ? { score } : {})
          }

          families_with_scores.push(family)
        }
      })

      return families_with_scores
    })
}

export function revert_training_set_labels(training_set_alias, log_entry_id) {
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/RevertTrainingSetLabels`, { alias: training_set_alias, log_entry_id }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to revert training set labels: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function revert_latest_built_classifier(training_set_alias, log_entry_id) {
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/RevertLatestBuiltClassifier`, { alias: training_set_alias, log_entry_id }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to revert latest built classifier: ')
      throw wrapped_err
    })
    .then(response => response.data)
    .then(data => {
      const { metadata } = data
      const decoded_metadata = struct.decode(metadata) // convert metadata "struct" to a js obj
      return { ...data, metadata: decoded_metadata }
    })
}

export function bulk_add_patents_to_training_set(training_set_alias, pos_pat_fam_ids, neg_patfam_ids, ignore_patfam_ids, test_pos_pat_fam_ids, test_neg_patfam_ids, ) {
  const labelled_pat_fams = {
    positives     : pos_pat_fam_ids,
    negatives     : neg_patfam_ids,
    ignores       : ignore_patfam_ids,
    test_positives: test_pos_pat_fam_ids,
    test_negatives: test_neg_patfam_ids
  }
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/LabelPatFams`, { alias: training_set_alias, labelled_pat_fams }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to bulk add labels to training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function remove_patents_from_training_set(training_set_alias, family_ids_to_remove) {
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/DeletePatFams`, { alias: training_set_alias, pat_fams: family_ids_to_remove }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const is_timeout = check_is_timeout(err)
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to remove label from training_set_service: ' + (is_timeout ? SLOW_TABLE_RENDER_WARNING : ''))
      throw wrapped_err
    })
    .then(response => response.data)
}

export function add_patents_to_training_set(training_set_alias, pat_fam_ids, label) {
  const labelled_pat_fams = { [`${label}s`]: pat_fam_ids }
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/LabelPatFams`, { alias: training_set_alias, labelled_pat_fams }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to add labels to training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function add_or_remove_patent_from_training_set(training_set_alias, pat_fam_id, label) {
  const label_enum = LABEL_TO_ENUM[label]

  if (label === UNKNOWN) {
    // REMOVE from training set
    return remove_patents_from_training_set(training_set_alias, [pat_fam_id])
  }

  // ADD to training set
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/LabelPatFam`, { alias: training_set_alias, pat_fam_id, label: label_enum }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const is_timeout = check_is_timeout(err)
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to add label to training_set_service: ' + (is_timeout ? SLOW_TABLE_RENDER_WARNING : ''))
      throw wrapped_err
    })
    .then(response => response.data)
}

export function save_name(training_set_alias, name) {
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/SetName`, { alias: training_set_alias, name }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to set name on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data) // returns counts
}

export function save_description(training_set_alias, description) {
  return axios_save_instance.post(TRAINING_SET_SERVICE_BASE_URL + `/SetDescription`, { alias: training_set_alias, description }, { ...SHORT_TIMEOUT })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to set description on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data) // returns counts
}

export function save_notes(training_set_alias, notes) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetNotes`, { alias: training_set_alias, notes })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save notes on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function fetch_phrases_to_highlight(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListHighlightPhrases`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch phrases to highlight from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.phrases)
}

export function save_phrases_to_highlight(training_set_alias, phrases_to_highlight) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetHighlightPhrases`, { alias: training_set_alias, phrases: phrases_to_highlight })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save phrases to highlight from training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function save_no_highlighting(training_set_alias, no_highlighting) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetHighlighting`, { alias: training_set_alias, highlighting: !no_highlighting })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save highlighting on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function save_starred(training_set_alias, starred) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetStarred`, { alias: training_set_alias, starred })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save starred on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function save_is_private(training_set_alias, is_private) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetPrivateTrainingSet`, { alias: training_set_alias, private: is_private })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save is_private on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function save_multiple_is_private(training_set_aliases, is_private) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetPrivateTrainingSets`, { aliases: training_set_aliases, private: is_private })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save is_private multiple on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function fetch_user_settings(training_set_alias) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/GetUserSettings`, { alias: training_set_alias })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch user settings on training_set_service: ')
      throw wrapped_err
    })
    .then(response => {
      const user_settings = response.data
      const { highlighting } = user_settings
      return { ...user_settings, no_highlighting: !highlighting }
    })
}

export function add_boolean_search_history_phrase(training_set_alias, phrase) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/AddSearchHistoryPhrase`, { alias: training_set_alias, phrase })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to add to boolean search history on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function delete_boolean_search_history_phrase(training_set_alias, phrase_id) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/DeleteSearchHistoryPhrase`, { alias: training_set_alias, phrase_id })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete boolean search history entry on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function fetch_boolean_search_history(training_set_alias, limit) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/ListSearchHistoryPhrases`, { alias: training_set_alias, limit })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch boolean search history on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data.phrases)
}

export function add_tags(training_set_alias, tags) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/AddTags`, { alias: training_set_alias, tags })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to add tags on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function delete_tags(training_set_alias, tags) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/DeleteTags`, { alias: training_set_alias, tags })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete tags on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function replace_tags(training_set_alias, old_tags, new_tags) {
  return Promise.resolve()
    .then(() => {
      if (!old_tags || _.isEmpty(old_tags)) {
        // nothing to delete
        return
      }
      // if multiple existing values, delete them all in favour of the new value(s)
      return delete_tags(training_set_alias, old_tags)
    })
    .then(() => {
      if (!new_tags || _.isEmpty(new_tags)) {
        // nothing to add
        return
      }
      // add new values
      return add_tags(training_set_alias, new_tags)
    })
}

export function set_taxonomy_path(training_set_alias, paths) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + `/SetTaxonomyPath`, { alias: training_set_alias, taxonomy_path: paths })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to set Taxonomy Path on training_set_service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function fetch_library_taxonomy_paths() {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + '/ListLibraryTaxonomyPaths', {})
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch library taxonomy paths: ')
      throw wrapped_err
    })
    .then(response => response.data)
    .then(({ taxonomy_paths }) => {
      return taxonomy_paths.map(paths_obj => paths_obj.taxonomy_path)
    })
}

export function fetch_any_built_training_sets_meta(training_set_aliases) {
   // Fetch the most basic descriptive metadata (id, name, description) for any classifier(s).
   // Does not require the requesting user to have classifier read/write permissions.
   return axios.post(TRAINING_SET_SERVICE_BASE_URL + '/ListBuiltTrainingSetsNoAuth', { aliases: training_set_aliases })
     .catch(err => {
       const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch classifiers: ')
       throw wrapped_err
     })
     .then(response => response.data)
}

export function fetch_group_export_to_patentsight_count() {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + '/GetGroupExportToPatentSightCount', {})
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch group export to patentsight count: ')
      throw wrapped_err
    })
    .then(response => response.data.count)
}

export function save_export_to_patentsight(training_set_alias, export_to_patentsight) {
  return axios.post(TRAINING_SET_SERVICE_BASE_URL + '/SetExportToPatentSight', { alias: training_set_alias, export_to_patentsight})
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save export to patentsight: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

export function get_clean_training_set_description(description='') {
   // removes tags in square brackets from classifier descriptions (as we do for taxonomy json files)
   return description.replace(/\s*\[.*?\]\s*/g, '')
}