import React from 'react'
import cn from 'classnames'

import { POSITIVE, NEGATIVE, IGNORE, UNKNOWN, TEST_POSITIVE, TEST_NEGATIVE } from '../constants/labels.js'

import { TickIcon, CrossIcon, BlockedIcon, RemoveLabelsIcon } from '../../widgets/IconSet.js'
import { TestNegativeIcon, TestPositiveIcon } from './TestSetIcons.js'
import { withUser } from '../../UserContext.js'
import { get_static_classname } from '../../../utils/tracking_utils.js'

import s from './ClassifierLabelControl.module.scss'


/**
 * Renders buttons (pos / neg / ignore) for adding/updating patents to a Classifier.
 * @param {} className
 * @param {} set_label single arg function: set_label(label)
 * @param {} selected_label (optional)
 */
const ClassifierLabelControl = ({
  user,
  className,
  disable,
  set_label,
  selected_label
}) => {

  const is_positive      = selected_label === POSITIVE
  const is_negative      = selected_label === NEGATIVE
  const is_test_positive = selected_label === TEST_POSITIVE
  const is_test_negative = selected_label === TEST_NEGATIVE
  const is_ignore        = selected_label === IGNORE

  const is_labelled = ((selected_label != null) && (selected_label !== UNKNOWN))

  return (
    <div
      className={cn(
        'd-flex',
        'align-items-center',
        className
      )}
    >

      <div
        className={cn(
          get_static_classname('btn_classifier_label_pos'),
          s.button_container,
          s.button_container__positive,
          {
            [s.button_container__disabled]: disable,
            [s.button_container__selected]: is_positive,
          }
        )}
      >
        <TickIcon
          title={'Add to Training Set as a "positive"'}
          className={cn(
            s.label_icon,
            s.label_as_positive_icon,
            {
              [s.label_icon__disabled]: disable,
              [s.label_icon__selected]: is_positive
            },
          )}
          onClick={(event) => {
            event.stopPropagation()
            set_label(POSITIVE)
          }}
        />
      </div>

      <div
        className={cn(
          get_static_classname('btn_classifier_label_neg'),
          'ml-2',
          s.button_container,
          s.button_container__negative,
          {
            [s.button_container__disabled]: disable,
            [s.button_container__selected]: is_negative,
          }
        )}
      >
        <CrossIcon
          title={'Add to Training Set as a "negative"'}
          className={cn(
            s.label_icon,
            s.label_as_negative_icon,
            {
              [s.label_icon__disabled]: disable,
              [s.label_icon__selected]: is_negative
            }
          )}
          onClick={(event) => {
            event.stopPropagation()
            set_label(NEGATIVE)
          }}
        />
      </div>

      <div className={cn(s.left_separator, 'ml-2', 'pl-2')}>
        <div
          className={cn(
            get_static_classname('btn_classifier_label_ign'),
            s.button_container,
            s.button_container__ignore,
            {
              [s.button_container__disabled]: disable,
              [s.button_container__selected]: is_ignore,
            }
          )}
        >
          <BlockedIcon
            title={'Mark as "ignore"'}
            className={cn(
              s.label_icon,
              s.label_as_ignore_icon,
              {
                [s.label_icon__disabled]: disable,
                [s.label_icon__selected]: is_ignore
              },
            )}
            onClick={(event) => {
              event.stopPropagation()
              set_label(IGNORE)
            }}
          />
        </div>
      </div>

      <div className={cn('d-flex', 'align-items-center', s.left_separator, 'ml-2', 'pl-2')}>

        <div
          className={cn(
            get_static_classname('btn_classifier_label_pos_test'),
            s.button_container,
            s.button_container__test_positive,
            {
              [s.button_container__disabled]: disable,
              [s.button_container__selected]: is_test_positive,
            }
          )}
        >
          <TestPositiveIcon
            title={'Add to Test Set as a "positive"'}
            className={cn(
              s.label_icon,
              {
                [s.label_icon__disabled]: disable,
                [s.label_icon__selected]: is_test_positive,
              },
              s.label_as_test_positive_icon,
            )}
            onClick={(event) => {
              event.stopPropagation()
              set_label(TEST_POSITIVE)
            }}
          />
        </div>

        <div
          className={cn(
            get_static_classname('btn_classifier_label_neg_test'),
            'ml-2',
            s.button_container,
            s.button_container__test_negative,
            {
              [s.button_container__disabled]: disable,
              [s.button_container__selected]: is_test_negative,
            }
          )}
        >
          <TestNegativeIcon
            title={'Add to Test Set as a "negative"'}
            className={cn(
              s.label_icon,
              {
                [s.label_icon__disabled]: disable,
                [s.label_icon__selected]: is_test_negative
              },
              s.label_as_test_negative_icon,
            )}
            onClick={(event) => {
              event.stopPropagation()
              set_label(TEST_NEGATIVE)
            }}
          />
        </div>

      </div>

      <div className={cn('d-flex', 'align-items-center', s.left_separator, 'ml-2', 'pl-2')}>
        <div
          className={cn(
            get_static_classname('btn_classifier_label_remove'),
            s.button_container,
            s.button_container__remove,
            {
              [s.button_container__disabled]: !is_labelled || disable,
            }
          )}
        >
          <RemoveLabelsIcon
            title={'Remove label'}
            className={cn(
              s.label_icon,
              {[s.label_icon__disabled]: !is_labelled || disable},
              s.remove_label_icon,
            )}
            onClick={(event) => {
              event.stopPropagation()
              set_label(UNKNOWN)
            }}
          />
        </div>
      </div>
    </div>

  )
}

export default withUser(ClassifierLabelControl)