import { GridSortModel } from '@mui/x-data-grid-pro'
import { get_as_map } from '../../../utils/utils.js'
import {IS_SELECTED_FIELD_ID, ACTIONS_FIELD_ID} from '../../report_management/model/report_fields.js'

export enum EntityType {
  User = 1,
  Group = 2
}

export interface EntityPermissions {
  view: boolean,
  edit: boolean
}

export interface Entity {
  entity_id: string,
  entity_type: EntityType,
}

export interface ProjectPermissions {
  user: {[key: string]: EntityPermissions},
  group: {[key: string]: EntityPermissions}
}

export interface ProjectUserViewState {
  reports_table: {
    sort_field_id: string,
    sort_direction_id: string,
    page_size: number,
  },
  files_table: {
    sortModel: GridSortModel
  },
  permissions_table: {
    sortModel: GridSortModel
  }
}

export interface Project {
  project_id: string,
  name: string,
  created_at: string,
  last_viewed?: string,
  permissions: ProjectPermissions,
  current_user_permissions: EntityPermissions,
  files_count: number,
  view_state?: ProjectUserViewState
}

export interface Report {
  external_report_id: string,
  internal_report_id: string,
  title: string,
  owner_id: string,
  created_at: string,
  report_type: string,
  evaluation_classifier_id?: string,
  status: string,
  last_viewed: string,
  is_saved: number
}

export interface UserFile {
  s3_resource_id: string,
  filename: string,
  path: string,
  size: number,
  last_modified: string,
  id: string // S3 version id
}

const PROJECT_FIELDS = [
  { id: IS_SELECTED_FIELD_ID, not_sortable: true},
  { id: 'project_id', not_sortable: true},
  { id: 'name', name: 'Name'},
  { id: 'created_at', name: 'Created'},
  { id: 'permissions', name: 'Permissions'},
  { id: 'current_user_permissions', name: 'My permissions'},
  { id: ACTIONS_FIELD_ID, name: 'Actions', not_sortable: true}
]

export const ID_TO_PROJECT_FIELD = get_as_map(PROJECT_FIELDS, 'id', null)