import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { HEATMAP_ID } from '../../model/view_ids.js'
import { get_should_display_all_zeros_series } from '../../utils/main_items_utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'

const DataIncludeAllZerosControl = ({item, selected_view_id, on_change_data_include_all_zeros, original_key_dims, get_expanded_key_dims_handler, className}) => {
  function check_if_should_show(original_key_dims, expanded_key_dims) {
    return _.some(original_key_dims.map((item, i) => {return item.length !== expanded_key_dims[i].length}))
  }

  if (selected_view_id !== HEATMAP_ID) {
    return null
  }

  const include_all_zeros = get_should_display_all_zeros_series(item)

  const is_show = include_all_zeros ? true : check_if_should_show(original_key_dims, get_expanded_key_dims_handler())

  if (!is_show) {
    return null
  }

  return (
    <div className={cn('d-flex',className)}>
      <CheckboxAndLabel
        label='Expanded'
        on_click={() => {on_change_data_include_all_zeros(!include_all_zeros)}}
        is_checked={include_all_zeros}
        className='mr-1'
      />
      <InfoPopover>
        Expanded view shows all series, including ones with no data.
      </InfoPopover>
    </div>
  )
}

export default DataIncludeAllZerosControl