import React from 'react'
import cn from 'classnames'

import ReactTableWrapper from './ReactTableWrapper.js'

import { is_IE11 } from '../../utils/browser_utils.js'

const BROWSER_IS_IE_11 = is_IE11()

const RT_COLUMN_DEFAULTS = {
  id: '', /*mandatory*/
  Header: '', /*mandatory*/
  accessor: null, /*mandatory*/
  sortable: true,
  resizable: true,
  className: '',
  minWidth: 100,
  maxWidth: undefined,
  ...(BROWSER_IS_IE_11 ? { width: 'inherit'} : {}), // workaround for IE11 (without this, ReactTable's explicit widths seem to confuse the flex-wrap, and row heights can be really big)
  Cell: row => (<span>{row.value}</span>),
  Footer: null,
  sortMethod: null
}

export const Table = ({columns, data, className, no_data_text, defaultSorted, pagination, getProps, getTrProps, getTdProps, getTheadThProps, ...rest}) => {
  const min_rows = data.length || 3

  const columns_to_render = columns.map((column) => ({
    ...RT_COLUMN_DEFAULTS,
    id: column.id,
    Header: (column.header || column.label),
    accessor: column.field,
    ...(column.sortable != null) ? {sortable: column.sortable} : {},
    ...(column.resizable != null) ? {resizable: column.resizable} : {},
    ...(column.className) ? {className: column.className} : {},
    ...(column.cell_render) ? {Cell: column.cell_render} : {},
    ...(column.min_width) ? {minWidth: column.min_width} : {},
    ...(column.max_width) ? {maxWidth: column.max_width} : {},
    ...(column.width) ? {width: column.width} : {},
    ...(column.foot_render) ? {Footer: column.foot_render} : {},
    ...(column.sort) ? {sortMethod: column.sort} : {},
    ...(column.headerClassName) ? {headerClassName: column.headerClassName} : {}
  }))

  return (
    <ReactTableWrapper
      columns={columns_to_render}
      data={data}
      pageSize={data.length || min_rows}
      minRows={min_rows}
      className={cn('-striped', className)}
      showPagination={pagination || false}
      noDataText={no_data_text || 'No data found'}
      defaultSorted={defaultSorted} // optional: specifies default column to sort by
      getProps={getProps}
      getTrProps = {getTrProps}
      getTdProps = {getTdProps}
      getTheadThProps = {getTheadThProps}
      {...rest}
    />
  )

}