import React from 'react'
import cn from 'classnames'

import s from './FooterLink.module.scss'

export const FooterLink = ({url, className, children}) => {
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={url}
      className={cn(s.link, className)}
    >
      {children}
    </a>
  )
}