import React from 'react'
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import cn from 'classnames'

import SelectDropdownToggleStatic from './SelectDropdownToggleStatic.js'

import s from './BaseDropdown.module.scss'

/**
 * Renders a dropdown menu.
 * @param {} children Array of react-strap DropdownItem (or any elements)
 * @param {} label Label to show on the button
 * @param {} chevron_left If true, renders a chevron on left of button label (by default rendered on right)
 * @param {} right If true, aligns dropdown to right of button (by default aligned left)
 * @param {} className Outer className
 * @param {} buttonClassName className for button - can set width of button here.
 * @param {} menuClassName className for menu - can set width of menu (when dropdown revealed) here.
 * @param {} disabled if true, disables the dropdown
 * @param {} direction
 * @param {} flip
 * @param {} always_visible_when_open
 * @param {} on_toggle function to execute when the dropdown opens/closes
 */
const BaseDropdown = ({ children, label, labelClassName, chevron_left, right, className, buttonClassName, menuClassName, disabled, direction, flip, always_visible_when_open, no_button_padding, on_toggle, hide_chevron }) => {
  return (
    <UncontrolledDropdown
      className={cn(className)}
      direction={direction || 'down'}
      inNavbar={!always_visible_when_open}
      onToggle={on_toggle}
    >
      <SelectDropdownToggleStatic
        className={cn(s.dropdown_toggle, buttonClassName)}
        chevron_left={chevron_left}
        disabled={disabled}
        labelClassName={labelClassName}
        no_button_padding={no_button_padding}
        hide_chevron={hide_chevron}
      >
        {label}
      </SelectDropdownToggleStatic>

      <DropdownMenu
        right={right}
        flip={(flip != null) ? flip : false} // By default flip is false, so prevents dropups (as can be underneath the sticky header)
        className={cn(s.dropdown_menu, menuClassName)}
      >
        {children}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default BaseDropdown