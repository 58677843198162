import React from 'react'

import { PdfViewer } from './widgets/PdfViewer.js'
import ContainerFullWidth from './ContainerFullWidth.js'

import { track_visit_event } from '../utils/tracking_utils.js'
import { get_domain_prefix } from '../utils/utils.js'
import { get_ifi_doc_url } from '../utils/patent_utils.js'

const PatentPdfView = ({ match }) => {
  document.title = 'Cipher: Patent document'
  track_visit_event('page="patent_doc"')

  const patent_id = match.params.id
  const src_url = get_domain_prefix() + get_ifi_doc_url(patent_id)

  return (
    <ContainerFullWidth className='px-0'>
      <PdfViewer
        className='w-100 h-100'
        src={src_url}
        title={patent_id}
      />
    </ContainerFullWidth>
  )
}

export default PatentPdfView