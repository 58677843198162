import React from 'react'

import { PrimaryButton, SecondaryButton } from './widgets/Button.js'
import ButtonGroup from './widgets/ButtonGroup.js'

const ITEMS = [
  { id: 0, name: 'One'},
  { id: 1, name: 'Two'},
  { id: 2, name: 'Three'},
  { id: 3, name: 'Four'}
]

const StyleGuide = () => {
  return (
    <div className='m-5'>

      <div className='mt-3'>
        <PrimaryButton>PrimaryButton</PrimaryButton>
      </div>

      <div className='mt-3'>
        <SecondaryButton>SecondaryButton</SecondaryButton>
      </div>

      <div className='mt-3'>
        <PrimaryButton outline>PrimaryButton outline</PrimaryButton>
      </div>

      <div className='mt-3'>
        <SecondaryButton outline>SecondaryButton outline</SecondaryButton>
      </div>

      <div className='mt-3'>
        <PrimaryButton disabled>PrimaryButton disabled</PrimaryButton>
      </div>

      <div className='mt-3'>
        <SecondaryButton disabled>SecondaryButton disabled</SecondaryButton>
      </div>

      <div className='mt-3'>
        <PrimaryButton outline disabled>PrimaryButton outline disabled</PrimaryButton>
      </div>

      <div className='mt-3'>
        <SecondaryButton outline disabled>SecondaryButton outline disabled</SecondaryButton>
      </div>

      <div className='mt-5'>
        <ButtonGroup
          items={ITEMS}
          selected_id={2}
          on_change_selected_id={() => console.log('woooop')}
        />
      </div>

      <div className='mt-5'>
        <ButtonGroup
          items={ITEMS}
          selected_id={2}
          size={'sm'}
          on_change_selected_id={() => console.log('woooop')}
        />
      </div>



    </div>
  )
}

export default StyleGuide