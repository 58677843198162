import axios from 'axios'
import _ from 'underscore'

import { REPORT_READER_BASE_URL } from '../constants/urls.js'
import { ID_TO_SPEC } from '../model/specs.js'
import {
  GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID, PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,  GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  COST_BY_PORTFOLIO_BY_YEAR_ID, AVERAGE_COST_BY_PORTFOLIO_ID,
  LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID, LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID
} from '../model/spec_ids.js'

import { delay, is_array_non_empty_non_null } from '../utils/utils.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { IS_NEXT_AGGLOM, CHILD_IDS } from '../utils/column_data_utils.js'
import { get_current_year, prepare_date_filter_for_query } from './time_range_utils.js'
import { COST_BUCKET_SIZE, PVIX_BUCKET_SIZE } from '../constants/specs_params.js'
import { get_report_region_column } from './regions_utils.js'
import { NO_FILTER_ID } from '../model/time_range_filter_fields.js'
import { RR_REPORT_COMBINED_SUMMARY, RR_DATA_SUMMARY, RR_REPORT_BUILD_STATUS, RR_REPORT_INPUT } from '../constants/paths.js'
import {
  get_family_tag_as_portfolio_item,
  get_boolean_search_as_portfolio_item,
  get_org_as_portfolio_item,
  get_org_group_as_portfolio_item,
  get_patent_upload_as_portfolio_item,
} from '../model/portfolio_basket.js'
import { build_meta } from './report_input_utils.js'
import {
  get_technology_partitioning_by_utt,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CUSTOM,
  TECH_PARTITIONING_TYPE_MULTICLASS
} from '../model/technology_basket.js'
import {
  PATENT_FAMILIES_PORTFOLIO_TYPE,
  S3_ALL_FAMILIES_PORTFOLIO,
  UTT_PORTFOLIO_TYPE
} from '../model/portfolios.js'
import { get_updated_report_type, } from './report_utils.js'
import { SET_THEORY_REPORT_TYPE } from '../constants/constants.js'
import {
  TYPE_AGGLOMERATION,
  TYPE_ASSIGNEE,
  TYPE_ORGANISATION
} from '../model/organisation.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../constants/report_input.js'
import { PORTFOLIOS } from '../model/ref.js'
import { send_error_to_sentry } from './sentry_utils.js'

function prepare_report_reader_call(internal_report_id, endpoint) {
  endpoint = endpoint || 'report'
  const url = `${endpoint}/${internal_report_id}`
  const report_reader_url = `${REPORT_READER_BASE_URL}/${url}`

  return report_reader_url
}

function log_error_and_throw(err) {
  const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch data from report reader: ')
  throw wrapped_err
}

export function fetch_data_from_report_reader(internal_report_id, query, endpoint) {
  const report_reader_url = prepare_report_reader_call(internal_report_id, endpoint)
  return axios.post(report_reader_url, JSON.stringify(query))
  .then(response => {
    return response.data
  })
  .catch(err => {
      log_error_and_throw(err)
  })
}

export function fetch_report_data_summary(internal_report_id) {
  return axios.get(prepare_report_reader_call(internal_report_id, RR_DATA_SUMMARY))
    .then(response => response.data)
}

export function fetch_report_build_status(internal_report_id) {
  return axios.get(prepare_report_reader_call(internal_report_id, RR_REPORT_BUILD_STATUS))
    .then(response => response.data)
}

function report_summary_looks_valid(summary_with_status) {
  const {report_summary} = summary_with_status || {}
  // check for 'faux no-data' summary, believed to be caused by a strange response from the combined summary endpoint
  // (indicated by a null summary or key arrays; portfolios, technologies, or geos, being missing rather than empty arrays)
  if (!report_summary || _.any([PORTFOLIOS, 'technologies', 'country_codes'], key => !report_summary[key])) {
    send_error_to_sentry('Report data summary looks suspiciously empty', summary_with_status)
    return false
  }
  return true
}

function fetch_report_summary_with_status_from_rr(internal_report_id) {
  return axios.get(prepare_report_reader_call(internal_report_id, RR_REPORT_COMBINED_SUMMARY))
    .then(response => response.data)
}

export function fetch_report_summary_with_status(internal_report_id) {
  // one endpoint to fetch both the data summary and the build status in one request
  return fetch_report_summary_with_status_from_rr(internal_report_id)
    .then(summary_with_status => {
      if (report_summary_looks_valid(summary_with_status)) {
        return summary_with_status
      }
      // try again, after a short delay
      return delay(5000)  // 5s
        .then(() => fetch_report_summary_with_status_from_rr(internal_report_id))
    })
}

/**
 * Add selections into query (i.e. selected_portfolio_ids, selected_tech_ids, etc...)
 */
export function add_selections_to_query(query, selections, no_timefilter) {
  const { selected_portfolio_ids, selected_tech_ids, selected_geo_ids, selected_timerange } = selections || {}

  const should_include_date_filter = selected_timerange && (selected_timerange.field_id !== NO_FILTER_ID) && !no_timefilter

  // Selections do NOT contain defaults (i.e. will be "undefined" if user has not explicitly set them)
  // Only add non-null properties to the query
  return {
    ...query,
    ...(is_array_non_empty_non_null(selected_portfolio_ids) && { portfolio: selected_portfolio_ids }),
    ...(is_array_non_empty_non_null(selected_tech_ids)      && { technology: selected_tech_ids }),
    ...(is_array_non_empty_non_null(selected_geo_ids)       && { territory: selected_geo_ids}),
    ...(should_include_date_filter                          && { date_filter: prepare_date_filter_for_query(selected_timerange)})
  }
}

export function fetch_item_data(internal_report_id, item, selections, created_at) {
  const { spec_id, selected_region_grouping } = item
  const spec = ID_TO_SPEC[spec_id]
  const { get_query, add_custom_selections_to_query, report_reader_endpoint, no_timefilter } = spec
  const { report_region_column } = selections

  const region_column = (selected_region_grouping) ? get_report_region_column(selected_region_grouping) : report_region_column

  const query =  get_query({ data_creation_date: created_at, region_column })
  const query_with_selections = add_custom_selections_to_query ? add_custom_selections_to_query(query, selections, no_timefilter) : add_selections_to_query(query, selections, no_timefilter)

  return fetch_data_from_report_reader(internal_report_id, query_with_selections, report_reader_endpoint)
}

export function fetch_original_report_input(internal_report_id) {
  return axios.get(prepare_report_reader_call(internal_report_id, RR_REPORT_INPUT))
    .then(response => response.data)
}

export function translate_report_input(original_report_input) {
  const {
    name,
    report_type: data_report_type,
    portfolios: data_portfolios,
    technology_partitioning: data_technology_partitioning,
    portfolio_group_by_owner_ancestor_type,
    portfolio_roll_up_limit,
    meta: data_meta,
    company_lists: data_company_lists,
    input_schema_version
  } = original_report_input || {}

  const {
    report_type: meta_report_type,
    evaluation_classifier_id,
    portfolio_meta=[],
    // set theory meta items:
    group_tech_mode,
    group_portfolio_mode,
    operands,
    formula,
    operable_selection
  } = data_meta || {}

  let company_list_to_member_idx = {}

  const portfolios = data_portfolios ? data_portfolios.map((item, i) => {
    const {type, name, group_by_owner, pat_fam_ids, search_term, search_term_title, assignee_ids, organisation_ids, lines, inputs, publications, class_ids, meta} = item

    if (_.contains(['custom-families', 'custom_families', PATENT_FAMILIES_PORTFOLIO_TYPE], type)) {
      if (search_term_title === 'Custom tag value') {
        const [tag_name, tag_value] = name.split(':')
        return get_family_tag_as_portfolio_item({name: tag_name} , {value: tag_value}, pat_fam_ids)
      }

      return get_patent_upload_as_portfolio_item({name, pat_fam_ids, group_by_owner, lines, inputs, publications, meta})
    }

    if (type === 'tech-search') {
      return get_boolean_search_as_portfolio_item(search_term)
    }

    if (type === 'assignee' && (assignee_ids || organisation_ids)) {
      const item_meta = portfolio_meta[i]

      const { org, group, eset } = item_meta || {}
      const { company_list } = org || {}

      if (company_list) {
        const other_list_members = company_list_to_member_idx[company_list] || []
        company_list_to_member_idx[company_list] = [...other_list_members, i]
      }

      if ((!org && !group) || eset) {

        const org_id = (organisation_ids || [])[0]
        const assignee_id = (assignee_ids || [])[0]

        const is_org = org_id && !assignee_id
        const is_assignee = assignee_id && assignee_ids.length === 1
        const is_agglom = assignee_id && !is_assignee

        if (is_org) return get_org_as_portfolio_item({id: org_id, name, type: TYPE_ORGANISATION})
        if (is_assignee) return get_org_as_portfolio_item({id: assignee_id, name, type: TYPE_ASSIGNEE})
        if (is_agglom) return get_org_as_portfolio_item({name, type: TYPE_AGGLOMERATION, assignee_ids})
      }

      if (group) {
        return get_org_group_as_portfolio_item(group, name)
      }

      return _.omit(get_org_as_portfolio_item(org), 'company_list')
    }

    if (_.contains(['multiclass', UTT_PORTFOLIO_TYPE], type)) {
      return { type: UTT_PORTFOLIO_TYPE, class_ids }
    }

    if (_.contains(['custom_families_s3', 'custom-families-s3'], type)) {
      return S3_ALL_FAMILIES_PORTFOLIO
    }

    return item
  }) : null

  const technology_partitioning = translate_technology_partitioning(data_technology_partitioning)

  const meta = build_meta({
    evaluation_classifier_id,
    group_tech_mode,
    group_portfolio_mode,
    operands,
    formula,
    operable_selection
  })

  const original_report_type = formula ? SET_THEORY_REPORT_TYPE : (data_report_type || meta_report_type)

  const meta_company_lists = _.isEmpty(company_list_to_member_idx) ? null : _.keys(company_list_to_member_idx).map(name => ({name, member_index: company_list_to_member_idx[name], tags: []}))
  const company_lists = data_company_lists || meta_company_lists

  return {
    name,
    portfolios,
    technology_partitioning,
    report_type: get_updated_report_type(original_report_type),
    ...(portfolio_group_by_owner_ancestor_type ? {portfolio_group_by_owner_ancestor_type} : {}),
    ...(portfolio_roll_up_limit ? {portfolio_roll_up_limit} : {}),
    ...(company_lists ? {company_lists} : {}),
    ...(meta ? {meta} : {}),
    ...(input_schema_version ? {input_schema_version} : {})
  }
}

function translate_technology_partitioning(original_technology_partitioning) {
  const { type: tech_partitioning_type, use_superclasses, version_id, class_ids } = original_technology_partitioning || {}

  if (tech_partitioning_type === TECH_PARTITIONING_TYPE_MULTICLASS) {
    return get_technology_partitioning_by_utt({
      use_utt_superclasses: use_superclasses,
      utt_version: version_id,
      utt_class_ids: class_ids
    })
  }
  if (tech_partitioning_type === 'manual-clustering') {
    // translate legacy three column upload format
    const {families_in_clusters} = original_technology_partitioning
    const technologies = families_in_clusters.map(technology => {
      const {pat_fam_ids: original_pat_fam_ids, path} = technology
      const name = (path || [])[0] || ''
      const pat_fam_ids = original_pat_fam_ids.map(id => parseInt(id)) // ensure that any string ids are parsed to ints
      return { name, pat_fam_ids, path }
    })
    return {
      type: TECH_PARTITIONING_TYPE_CUSTOM,
      technologies
    }
  }
  if (tech_partitioning_type === TECH_PARTITIONING_TYPE_CLASSIFIER) {
    const {classifier_sources: original_classifiers, threshold: original_threshold} = original_technology_partitioning
    const classifier_sources = original_classifiers.map(classifier => {
      const {id, owner_id, classifier_id, path, name, version, description} = classifier
      const classifier_source_id = id || `${owner_id}__${classifier_id}` // format ids from classifiers v1
      return {
        id: classifier_source_id,
        path,
        name: name || classifier_source_id, // older inputs don't include classifier names
        ...(version ? {version} : {}),
        ...(description ? {description} : {})
      }
    })
    return {
      ..._.omit(original_technology_partitioning, 'filters'),
      classifier_sources,
      threshold: original_threshold || CLASSIFIER_SCORE_THRESHOLD_DEFAULT
    }
  }
  return original_technology_partitioning
}

export function fetch_report_input(internal_report_id) {
  return fetch_original_report_input(internal_report_id)
    .then(translate_report_input)
}

function should_wrap_with_quotes(report_reader_key) {
  // regex identifies known keys whose values need to be wrapped with quotes in a report reader query
  return report_reader_key.search(/country_code|first_filing_country|cpc|tag|status|region|continent/) !== -1
}

function get_report_reader_value_string(report_reader_key, value) {
  // string values need to be wrapped with quotes, eg "IN PFC.country_code (UK)" would throw an error; we need "IN PFC.country_code ('UK')"
  return should_wrap_with_quotes(report_reader_key) ? `'${value}'` : value
}

export function get_constraint(report_reader_key, key_items) {
  const values = key_items.reduce((values, key_item) => {
    if (key_item[IS_NEXT_AGGLOM]) {
      return [...values, ...key_item[CHILD_IDS]]
    }

    return [...values, key_item.id]
  }, [])

  const value_strings = values.map(value => get_report_reader_value_string(report_reader_key, value))
  return `IN ${report_reader_key} (${value_strings.join(', ')})`
}

export function get_pvix_bucket_constraints(value) {
  return [
    `>= PF.pvix_score ${value}`,
    `< PF.pvix_score ${value + PVIX_BUCKET_SIZE}`
  ]
}

export function get_cost_bucket_constraints(value) {
  return [
    `>= SUM PFC.cost ${value}`,
    `< SUM PFC.cost ${value + COST_BUCKET_SIZE}`
  ]
}


function extract_as_cube_obj(res_data, return_func) {
  return res_data.data[0].map((v,i) => {
      return return_func(res_data,i)
    }
  )
}

function size_by_portfolio_obj (res_data, i) {
  //data is of the form of: {columns: Array(3), types: Array(3), data: Array(3)}
  //columns: (3) ["PFSBY.year", "PFTP.portfolio_id", "COUNT DISTINCT PF.pat_fam_id"]
  // data: Array(3)
  // types: (3) ["INTEGER", "INTEGER", "BIGINT"]

  return {
    'year': res_data.data[0][i].toString(), //TODO: remove the str function when the Landscape component is refactored
    'company_id': res_data.data[1][i],
    'value': res_data.data[2][i]
  }
}

function size_by_technology_obj(res_data,i){
  //data is of the form of: {columns: Array(3), types: Array(3), data: Array(3)}
  //columns: (3) ["PFTP.portfolio_id", "PFTT.technology_id", "COUNT DISTINCT PF.pat_fam_id"]
  //data: Array(3)
  //types: (3) ["INTEGER", "INTEGER", "BIGINT"]
  return {
    'company_id': res_data.data[0][i],
    'cluster_id': res_data.data[1][i].toString(),
    'value': res_data.data[2][i]
  }
}

function size_by_country_obj(res_data, i) {
  //data is of the form of: {columns: Array(3), types: Array(3), data: Array(3)}
  //columns: (3) ["PFT.country_code", "PFTP.portfolio_id", "COUNT DISTINCT PFT.pat_fam_id"]
  //data: Array(3)
  //types: (3) ["VARCHAR", "INTEGER", "BIGINT"]
  return {
    'country_code': res_data.data[0][i].toString(),
    'company_id': res_data.data[1][i],
    'value': res_data.data[2][i]
  }
}

function size_by_priority_year_by_portfolio_obj(res_data, i){
  //data is of the form of: {columns: Array(3), types: Array(3), data: Array(3)}
  //columns: (3) ["PF.priority_year", "PFTP.portfolio_id", "COUNT DISTINCT PF.pat_fam_id"]
  //data: Array(3)
  //types: (3)  ["BIGINT", "INTEGER", "BIGINT"]
  return {
    'year': res_data.data[0][i].toString(),
    'company_id': res_data.data[1][i],
    'value': res_data.data[2][i]
  }

}

function cost_by_portfolio_obj(res_data, i) {
  //data is of the form of: {columns: Array(3), types: Array(3), data: Array(3)}
  //columns: (3) ["PFC.year", "PFTP.portfolio_id", "SUM PFC.cost"]
  //data: Array(3)
  //types: (3) ["INTEGER", "INTEGER", "BIGINT"]
  return {
    'year': res_data.data[0][i].toString(),
    'company_id': res_data.data[1][i],
    'value': res_data.data[2][i]
  }
}

function average_cost_by_portfolio_obj(res_data, i) {
  //data is of the form of: {columns: Array(2), types: Array(2), data: Array(2)}
  //columns: (2) ["PFTP.portfolio_id", "/ SUM PFC.cost COUNT DISTINCT PFC.pat_fam_id"]
  //data: Array(2)
  //types: (2) ["INTEGER", "BIGINT"]
  return {
    'company_id': res_data.data[0][i],
    'value': res_data.data[1][i]
  }
}

function litigations_by_portfolio_obj(res_data, i) {
  //data is of the form of: {columns: Array(3), types: Array(3), data: Array(3)}
  //columns: (3) ["D.start_or_end_year", "P.portfolio_id", "COUNT DISTINCT D.dispute_id"]
  //data: Array(3)
  //types: (3)  ["BIGINT", "INTEGER", "BIGINT"]
  return {
    'year': res_data.data[0][i],
    'company_id': res_data.data[1][i],
    'value': res_data.data[2][i]
  }
}

//TODO: consider refactoring/reuse of this code in fetch_item_data. Add report_reader_utils.test.js and consider mocking promises
function get_query_from_spec_and_apply_selections(spec_id, report_creation_date, selections) {
  const spec = ID_TO_SPEC[spec_id]
  const { get_query, add_custom_selections_to_query, no_timefilter } = spec

  const { report_region_column } = selections

  // Only add non-null properties to the query
  const query = get_query({data_creation_date: report_creation_date, region_column: report_region_column})

  return add_custom_selections_to_query ? add_custom_selections_to_query(query, selections, no_timefilter) : add_selections_to_query(query, selections, no_timefilter)
}

export function fetch_data_for_exec_summary_report(input_data){
  const report_reader_url = prepare_report_reader_call(input_data.internal_report_id)
  const report_creation_date = input_data.data_creation_date
  const selections = input_data.selections_for_exec_summary
  const granted_size_by_portfolio_query = get_query_from_spec_and_apply_selections(GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,report_creation_date, selections)
  const application_size_by_portfolio_query = get_query_from_spec_and_apply_selections(PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,report_creation_date, selections)
  const granted_size_by_tech_query = get_query_from_spec_and_apply_selections(GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,report_creation_date, selections)
  const grants_by_country_query = get_query_from_spec_and_apply_selections(GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,report_creation_date, selections)
  const granted_by_priority_year_by_portfolio_query = get_query_from_spec_and_apply_selections(GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,report_creation_date, selections)
  const application_by_priority_year_by_portfolio_query = get_query_from_spec_and_apply_selections(PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,report_creation_date, selections)
  const cost_by_year_and_portfolio_query = get_query_from_spec_and_apply_selections(COST_BY_PORTFOLIO_BY_YEAR_ID,report_creation_date, selections)
  const average_cost_by_portfolio_query = get_query_from_spec_and_apply_selections(AVERAGE_COST_BY_PORTFOLIO_ID,report_creation_date, selections)
  const defendant_litigations_query = get_query_from_spec_and_apply_selections(LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID,report_creation_date, selections)
  const offensive_litigations_query =  get_query_from_spec_and_apply_selections(LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID,report_creation_date, selections)


  return axios.all([
    axios.post(report_reader_url, JSON.stringify(granted_size_by_portfolio_query)),
    axios.post(report_reader_url, JSON.stringify(application_size_by_portfolio_query)),
    axios.post(report_reader_url, JSON.stringify(granted_size_by_tech_query)),
    axios.post(report_reader_url, JSON.stringify(grants_by_country_query)),
    axios.post(report_reader_url, JSON.stringify(granted_by_priority_year_by_portfolio_query)),
    axios.post(report_reader_url, JSON.stringify(application_by_priority_year_by_portfolio_query)),
    axios.post(report_reader_url, JSON.stringify(cost_by_year_and_portfolio_query)),
    axios.post(report_reader_url, JSON.stringify(average_cost_by_portfolio_query)),
    axios.post(report_reader_url, JSON.stringify(defendant_litigations_query)),
    axios.post(report_reader_url, JSON.stringify(offensive_litigations_query))
  ]).catch(err => {
      log_error_and_throw(err)
    }).then(axios.spread(function (granted_size_by_portfolio_res, app_size_res, granted_size_by_tech_size,
                                   grants_by_country_res, granted_by_priority_year_by_portfolio_res,
                                   application_by_priority_year_by_portfolio_res, cost_by_year_and_portfolio_res,
                                   average_cost_by_portfolio_res, defendant_litigations_res, offensive_litigations_res) {
    let data = {}
    data['granted_size_by_portfolio'] = extract_as_cube_obj(granted_size_by_portfolio_res.data, size_by_portfolio_obj)
    data['application_size_by_portfolio'] = extract_as_cube_obj(app_size_res.data, size_by_portfolio_obj)
    data['granted_size_by_tech'] = extract_as_cube_obj(granted_size_by_tech_size.data, size_by_technology_obj)
    data['grants_by_country_by_portfolio'] = extract_as_cube_obj(grants_by_country_res.data, size_by_country_obj)
    data['granted_size_by_priority_year_by_by_portfolio'] = extract_as_cube_obj(granted_by_priority_year_by_portfolio_res.data, size_by_priority_year_by_portfolio_obj)
    data['application_size_by_priority_year_by_by_portfolio'] = extract_as_cube_obj(application_by_priority_year_by_portfolio_res.data, size_by_priority_year_by_portfolio_obj)
    data['cost_by_portfolio'] = extract_as_cube_obj(cost_by_year_and_portfolio_res.data, cost_by_portfolio_obj)
    data['average_cost_by_portfolio'] = extract_as_cube_obj(average_cost_by_portfolio_res.data, average_cost_by_portfolio_obj)
    data['defendant_lit_by_porfolio'] = extract_as_cube_obj(defendant_litigations_res.data, litigations_by_portfolio_obj)
    data['plaintiff_lit_by_porfolio'] = extract_as_cube_obj(offensive_litigations_res.data, litigations_by_portfolio_obj)
    data['companies'] = [...input_data.portfolios_for_exec_summary]
    data['clusters'] = [...input_data.selected_techs]
    data['report_date'] = input_data.data_creation_date
    data['report_year'] = get_current_year(input_data.data_creation_date)

    return data
  }))
}

export function fetch_drill_sql_translation(internal_report_id, dsl_query) {
  const report_reader_translation_url = `${prepare_report_reader_call(internal_report_id)}/translateQuery`
  const params = {query: JSON.stringify(dsl_query)}
  return axios.get(report_reader_translation_url, {params})
    .then(response => {
      return response.data["drill SQL query"]
    })
}