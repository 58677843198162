import React, {useState} from 'react'
import Tippy from '@tippyjs/react'
import cn from 'classnames'

import { InfoIcon } from './IconSet.js'
import { DEFAULT_Z_INDEX, IN_MODAL_Z_INDEX, WRAPPER_ELEMENT_DEFAULT } from '../../constants/tooltip.js'

import s from './Tooltip.module.scss'

const TippyTooltip = ({toggler, content, placement, on_show, on_hide, on_click_outside, visible, zindex, wide, delay, wrapper, interactive, offset, arrow, disabled, className}) => {
  return (
    <Tippy
      content={content}
      placement={placement || 'auto'}
      arrow={arrow != null ? arrow : true}
      delay={delay}
      offset={offset}
      disabled={disabled}

      onShow={on_show ? on_show : () => {}}
      onHide={on_hide ? on_hide : () => {}}
      onClickOutside={on_click_outside ? on_click_outside : () => {}}

      {...(visible != null) ? {visible} : {}}
      {...(zindex != null) ? {zIndex: zindex} : {}}
      {...(wrapper && (wrapper !== WRAPPER_ELEMENT_DEFAULT)) ? {appendTo: wrapper} : {}}
      {...(interactive != null) ? {interactive} : {}}
      className={cn(s.block, {[s.__wide]: wide}, className)}
    >
      {toggler}
    </Tippy>
  )
}

const Tooltip = ({placement, on_show, on_hide, toggler, title, children, light, delay, is_in_modal, className}) => {
  const content = (
    <div className={cn('text-left', className)}>
      {title &&
        <div className={cn('p-1', s.header)}>{title}</div>
      }
      <div className={cn('p-1', {[s.content__light]: light})}>{children}</div>
    </div>
  )

  return (
    <TippyTooltip
      toggler={toggler}
      content={content}
      placement={placement}
      zindex={is_in_modal ? IN_MODAL_Z_INDEX : DEFAULT_Z_INDEX}
      on_show={on_show}
      on_hide={on_hide}
      delay={delay}
    />
  )
}

export const InfoPopover = (
  {
    label,
    title,
    placement,
    children,
    className,
    buttonClassName,
    contentClassName,
    popupClassName,
    wide,
    is_in_modal,
    wrapper,
    interactive,
    toggler,
    visible_by_default,
    static_trigger,
    on_show,
    on_hide,
    offset,
    arrow,
    disabled
  }) => {
  const [visible, set_visible] = useState(visible_by_default != null ? visible_by_default : false)
  function toggle(e) {
    e.stopPropagation()
    set_visible(!visible)

    if (!visible && on_show) {
      on_show()
    }

    if (visible && on_hide) {
      on_hide()
    }
  }

  function on_click_outside() {
    set_visible(false)
    if (on_hide) {
      on_hide()
    }
  }

  const content = (
    <div className={cn(className)}>
      {title && <div>{title}</div>}
      <div className={cn( contentClassName || 'p-1' )}>
        {children}
      </div>
    </div>
  )

  const popover_toggler = <span onClick={toggle} className={cn(s.btn, buttonClassName)}> {toggler || label || <InfoIcon/>}</span>

  return (
    <TippyTooltip
      toggler={static_trigger || popover_toggler}
      content={content}
      placement={placement}
      visible={visible}
      on_click_outside={on_click_outside}
      wide={wide}
      wrapper={wrapper}
      zindex={is_in_modal ? IN_MODAL_Z_INDEX : DEFAULT_Z_INDEX}
      interactive={interactive}
      offset={offset}
      arrow={arrow}
      disabled={disabled}


      className={popupClassName}
    />
  )
}

export default Tooltip