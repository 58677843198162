import React, {useState} from 'react'
import cn from 'classnames'
import _ from 'underscore'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { SaveIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { PrimaryButton, SecondaryButton } from '../widgets/Button.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import {
  check_for_reason_portfolios_cannot_be_saved,
  MAX_LIST_NAME_LENGTH,
  MAX_UNSAVEABLE_ITEMS_TO_DISPLAY,
  save_portfolio_basket_as_company_list,
  SHARED_LIST_INFO,
  update_company_list_from_portfolio_basket
} from '../../utils/company_list_utils.js'
import { is_enter_key_down, is_escape_key_down } from '../../utils/keyboard_shortcuts/keyboard_utils.js'
import { WRAPPER_ELEMENT_PARENT } from '../../constants/tooltip.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { is_org_type } from '../../utils/organisation_utils.js'
import { withUser } from '../UserContext.js'
import { is_aistemos } from '../../utils/user_permissions.js'

import s from './PortfolioBasketSaveControl.module.scss'

const PortfolioBasketSaveControl = (
  {
    user,
    portfolio_basket,
    handle_portfolio_basket_saved,
    user_company_lists,
    switch_to_org_sets_handler,
    can_switch_to_org_sets
  }) => {

  const [dropdown_is_open, set_dropdown_is_open] = useState(false)
  const [list_name_input, set_list_name_input] = useState('')
  const [is_shared_list, set_is_shared_list] = useState(false)
  const [is_saving, set_is_saving] = useState(false)
  const [error_saving_list, set_error_saving_list] = useState(null)
  const [is_newly_saved_as_list, set_is_newly_saved_as_list] = useState(false)

  const [portfolios_to_save, unsaveable_portfolios] = _.partition(portfolio_basket, portfolio => is_org_type(portfolio))

  const is_group_aistemos = is_aistemos(user)
  const cannot_save_basket_reason = check_for_reason_portfolios_cannot_be_saved(portfolios_to_save, unsaveable_portfolios, is_group_aistemos)
  // disable the icon button only if nothing in the basket can be saved
  const is_disabled = cannot_save_basket_reason != null
  // otherwise, allow saving of orgs/assignees and provide some explanation where basket items can't be saved
  const has_unsaveable_portfolios = unsaveable_portfolios.length > 0

  const existing_list = _.find(user_company_lists, list => _.isMatch(list, {'list_name': list_name_input, 'is_shared': is_shared_list}))
  const is_name_valid = list_name_input.trim().length <= MAX_LIST_NAME_LENGTH
  const can_save_new_list = list_name_input !== '' && is_name_valid && !existing_list

  function save_list() {
    set_is_saving(true)
    update_or_save_new()
      .catch(err => {
        set_is_saving(false)
        set_error_saving_list(err)
        handle_portfolio_basket_saved()
        toggle_dropdown()
        throw err
      })
      .then(() => {
        set_is_saving(false)
        set_is_newly_saved_as_list(true)
        handle_portfolio_basket_saved(list_name_input)
        toggle_dropdown()
      })
  }

  function update_or_save_new() {
    track_report_builder_event(`action="${existing_list ? 'update' : 'create'}" obj="org_list" group="${is_shared_list ? 'shared' : 'personal'}"`)

    if (existing_list) {
      const {company_list_id} = existing_list
      return update_company_list_from_portfolio_basket(company_list_id, portfolios_to_save)
    }
    return save_portfolio_basket_as_company_list(list_name_input, portfolios_to_save, is_shared_list)
  }

  function handle_key_down(e) {
    if (is_escape_key_down(e)) {
      toggle_dropdown()
    }
    if (is_enter_key_down(e)) {
      save_list()
    }
  }

  function toggle_dropdown() {
    if (dropdown_is_open) {
      set_list_name_input('')
      set_is_shared_list(false)
    }
    set_dropdown_is_open(!dropdown_is_open)
  }

  function switch_to_org_sets() {
    switch_to_org_sets_handler()
    set_is_newly_saved_as_list(false)
  }

  function get_unsaveable_items_to_display() {
    const unsaveable_items_to_display = _.first(unsaveable_portfolios, MAX_UNSAVEABLE_ITEMS_TO_DISPLAY)
    const additional_unsaveable_items = unsaveable_portfolios.length - MAX_UNSAVEABLE_ITEMS_TO_DISPLAY
    return (
      <div className='mt-1'>
        { unsaveable_items_to_display.map((item, idx) => {
          return <div key={idx}>{item.name}</div>
        }) }
        { additional_unsaveable_items > 0 &&
          <div>and {additional_unsaveable_items} more</div>
        }
      </div>
    )
  }

  return (
    <>
      { error_saving_list &&
        <ErrorModal
          on_hide={() => set_error_saving_list(null)}
          error={error_saving_list}
          context='saving company list'
        />
      }
      <Dropdown className={s.save_list_control} isOpen={dropdown_is_open && !is_saving} toggle={toggle_dropdown}>
        { is_saving &&
          <Spinner size='sm' className='d-flex'/>
        }
        { !is_saving &&
          <DropdownToggle tag='div' className='d-flex' disabled={is_disabled}>
            <span title={cannot_save_basket_reason}>
              <TextLink title='Save to Org Sets' disable={is_disabled}>
                <SaveIcon />
              </TextLink>
            </span>
          </DropdownToggle>
        }

        <DropdownMenu tag='div' className={cn('mt-2 p-1', s.save_list_dropdown)} right >
          { has_unsaveable_portfolios &&
            <div className='d-block small my-1'>
              <span>Saving {portfolios_to_save.length} of {portfolio_basket.length} items as a list</span>
              <InfoPopover interactive={true} wide={true} placement='right'>
                Only whole organisations from searches or existing lists can be saved to Org Sets.
                <div className='mt-1'>Your saved list will not include:{get_unsaveable_items_to_display()}</div>
              </InfoPopover>
            </div>
          }
          <ClearableSearchInput
            placeholder={'Save list as...'}
            value={list_name_input}
            handle_change={input_string => set_list_name_input(input_string)}
            handle_key_down={(e) => handle_key_down(e)}
            auto_focus={true}
            show_clear
          />
          <div className='d-flex align-items-center mt-1'>
            <CheckboxStatic
              is_checked={is_shared_list}
              onClick={() => set_is_shared_list(!is_shared_list)}
              className='mr-1'
            />
            <span className='d-block small pt-1 align-items-center'>
              Save as a shared list
              <InfoPopover interactive={true} placement='right'>{SHARED_LIST_INFO}</InfoPopover>
            </span>
          </div>

          { !is_name_valid &&
            <div className='d-block invalid-feedback'>
              <span>List names should be no longer than {MAX_LIST_NAME_LENGTH} characters.</span>
            </div>
          }
          { existing_list &&
            <div className='d-block small mt-2'>
              <span>You already {is_shared_list ? 'have access to a shared' : 'have a'} list with this name. Do you want to replace it?</span>
            </div>
          }
          <div className='d-flex mt-2'>
            { existing_list &&
              <PrimaryButton size='xs' onClick={save_list}>Replace</PrimaryButton>
            }
            <div className='d-flex ml-auto'>
              <SecondaryButton size='xs' onClick={toggle_dropdown} className='mr-1'>Close</SecondaryButton>
              <PrimaryButton size='xs' onClick={save_list} disabled={!can_save_new_list}>Save</PrimaryButton>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
      { is_newly_saved_as_list &&
        <InfoPopover
          no_toggler={true}
          static_trigger={<span></span>}
          visible_by_default={true}
          placement='right'
          is_in_modal={true}
          contentClassName='d-flex px-1 py-0'
          interactive={true}
          on_hide={() => set_is_newly_saved_as_list(false)}
          wrapper={WRAPPER_ELEMENT_PARENT}
        >
          <div className={cn('d-flex', s.newly_saved_list_message)}>
            Saved to
            { can_switch_to_org_sets &&
              <TextLink className='ml-1' onClick={() => switch_to_org_sets()}>Org Sets</TextLink>
            }
            { !can_switch_to_org_sets &&
              <span className='ml-1'>Org Sets</span>
            }
          </div>
        </InfoPopover>
      }
    </>
  )
}

export default withUser(PortfolioBasketSaveControl)