import React from 'react'
import { FormGroup } from 'reactstrap'
import moment from 'moment'

import cn from 'classnames'

import s from './ExistingReportInfo.module.scss'


const ExistingReportInfo = ({ existing_report, className }) => {
  return (
    <FormGroup className={cn(className)}>
      <div className={s.cached_report_intro}>There is a cached version of this exact report that was recently created.</div>
      <div className={cn('mt-3', s.cached_report_details)}>Report created {moment(existing_report.date || existing_report.timestamp).fromNow()}</div>
    </FormGroup>
  )
}

export default ExistingReportInfo