import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import { Autocomplete, Chip, createFilterOptions, TextField } from '@mui/material'
import cn from 'classnames'

import SearchBar from '../widgets/SearchBar.js'
import ScrollableList from '../widgets/ScrollableList.js'
import { CrossIcon } from '../widgets/IconSet.js'
import Spinner from '../widgets/Spinner.js'
import { Choice, filter_tags, is_editable, is_multi_choice, is_single_choice, Tag, TagValue } from './family_tag_utils'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

import s from './TagsDisplay.module.scss'

interface FreeFormatTextType {
  inputValue?: string,
  value: string,
  id: number,
  families_count: number,
}

const DEFAULT_AUTOCOMPLETE_VALUE = {inputValue: '', value: '', id: 0, families_count: 0}

const filter = createFilterOptions<FreeFormatTextType>()

function filterFFTOptions(options: any, params: any) {
  const filtered = filter(options, params)

  const {inputValue} = params
  // Suggest the creation of a new value
  const is_existing = options.some((option: FreeFormatTextType) => inputValue === option.value)
  if (inputValue.length > 0 && !is_existing) {
    filtered.push({
      inputValue,
      value: `Add: "${inputValue}"`,
      id: -1,
      families_count: 1
    })
  }

  return filtered
}

function getFFTLabel(option: FreeFormatTextType | string) {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue
  }
  // Regular option
  return option.value
}

interface TagsDisplayProps {
  user_tags: Array<Tag>,
  is_tag_selected: Function,
  is_changing: boolean,
  on_tag_select: Function,
  on_remove_tag: Function,
  search_input: string,
  set_search_input: Function
}

export const TagsDisplay = ({
  user_tags,
  is_tag_selected,
  is_changing,
  on_tag_select,
  on_remove_tag,
  search_input,
  set_search_input
}: TagsDisplayProps) => {
    const [fft_autocomplete_values, set_fft_autocomplete_values] = useState<{ [key: number]: FreeFormatTextType }>({})
    const [fft_options, set_fft_options] = useState<{ [key: number]: Array<FreeFormatTextType> }>({})

    useEffect(() => {
      const editable_options = user_tags.filter((tag) => tag.type === Choice.FREE_FORMAT).reduce((acc, tag) => {
        //@ts-expect-error
        acc[tag.id] = tag.values
        return acc
      }, {})
      set_fft_options(editable_options)
    }, [user_tags])

    const filtered_user_tags = !_.isEmpty(search_input) ? filter_tags(user_tags, search_input) : user_tags

    function on_autocomplete_input_change(tag_id: number, new_value: string) {
      const new_autocomplete_values = {...fft_autocomplete_values}
      const filtered: Array<FreeFormatTextType> = fft_options[tag_id].filter((option: any) => option.value === new_value)
      if (filtered.length > 0) {
        const existing_option = filtered[0]
        existing_option.value = new_value
        new_autocomplete_values[tag_id] = existing_option
      } else {
        new_autocomplete_values[tag_id] = {inputValue: new_value, value: new_value, id: -1, families_count: 0}
      }
      set_fft_autocomplete_values(new_autocomplete_values)
    }

    function on_autocomplete_change(tag_id: number, reason: string, selection: any) {
      if (reason === 'selectOption' && selection) {
        const filtered_user_tag: Tag = user_tags.filter((tag) => {
          return tag.id === tag_id
        })[0]
        const tag_to_change: Tag = {
          id: filtered_user_tag.id,
          name: filtered_user_tag.name,
          type: filtered_user_tag.type,
          sort_index: filtered_user_tag.sort_index,
          values: [{
            id: selection.option.id,
            value: selection.option.inputValue || selection.option.value,
            families_count: selection.families_count
          }],
          section: filtered_user_tag.section
        }

        if (!is_tag_selected(tag_to_change)) {
          const new_tag_value_id = on_tag_select(tag_to_change)
          if (selection && selection.option.inputValue && new_tag_value_id) {
            const options_for_tag: Array<FreeFormatTextType> = [...fft_options[tag_id], {
              id: new_tag_value_id,
              value: selection.option.inputValue,
              families_count: 1
            }]
            const new_fft_options: { [key: number]: Array<FreeFormatTextType> } = {...fft_options}
            new_fft_options[tag_id] = options_for_tag
            set_fft_options(new_fft_options)
          }
        }
      }
    }

    function on_delete_editable_tag_value(tag: Tag, tag_value: TagValue) {
      on_tag_select({...tag, values: [tag_value]})//remove the tag value
      on_autocomplete_input_change(tag.id, '')//clear out the input
    }

    return (
      <>
        {user_tags.length > 0 &&
          // @ts-expect-error
          <SearchBar
            className={'my-1'}
            placeholder={'Search...'}
            search_input_ref={null}
            search_input={search_input}
            on_change_search_input={set_search_input}
            no_button={true}
            autofocus={false}
            is_search_valid={true}
            no_enter_trigger={true}
          />
        }
        {is_changing &&
          <div>
            <Spinner size={'sm'}/>
            <span> Adding/removing ... </span>
          </div>
        }


        {filtered_user_tags.length > 0 &&
          <ScrollableList>
            {filtered_user_tags.map((tag, i: number) =>
              <div key={i}>
                <div className='d-flex mb-1 mt-2'>
                  <span className={s.heading}>{tag.section}/{tag.name}</span>
                  {is_tag_selected(tag) &&
                    <CrossIcon
                      title={'Remove tag values'}
                      className={cn(s.label_icon, s.label_as_close_icon)}
                      onClick={() => on_remove_tag(tag)}
                    />
                  }
                </div>

                <div className='d-flex flex-wrap'>
                  {tag.values.map((tag_value: TagValue) => (
                    <div
                      key={tag_value.id}
                      className='mb-2'
                    >
                      {is_single_choice(tag) &&
                        //@ts-expect-error
                        <RadiobuttonWithLabel
                          label={tag_value.value}
                          on_click={() => on_tag_select({...tag, values: [tag_value]})}
                          is_checked={is_tag_selected({...tag, values: [tag_value]})}
                          className={'mr-2'}
                        />
                      }
                      {is_multi_choice(tag) &&
                        //@ts-expect-error
                        <CheckboxAndLabel
                          label={tag_value.value}
                          on_click={() => on_tag_select({...tag, values: [tag_value]})}
                          is_checked={is_tag_selected({...tag, values: [tag_value]})}
                          className={'mr-2'}
                        />
                      }
                      {is_editable(tag) && is_tag_selected({...tag, values: [tag_value]}) &&
                        <Chip
                          label={tag_value.value}
                            variant={'outlined'}
                            size={'small'}
                            key={tag_value.id}
                            className={cn('mr-1 mb-1')}
                            classes={{
                              root: s.free_format_text,
                              deleteIcon: s.free_format_text_delete_icon,
                              label: s.free_format_text_label
                            }}
                            onDelete={() => on_delete_editable_tag_value(tag, tag_value)}
                        />
                      }
                    </div>
                  ))}

                </div>
                {is_editable(tag) &&
                  <Autocomplete
                    freeSolo
                    handleHomeEndKeys
                    clearOnEscape
                    clearOnBlur
                    blurOnSelect
                    classes={{inputRoot: s.free_format_text_autocomplete}}
                    size={'small'}
                    autoHighlight={true}
                    options={fft_options[tag.id] || [DEFAULT_AUTOCOMPLETE_VALUE]}
                    getOptionLabel={getFFTLabel}
                    inputValue={fft_autocomplete_values[tag.id] ? fft_autocomplete_values[tag.id].value : ''}
                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                    filterOptions={filterFFTOptions}
                    onInputChange={(event, newInputValue) => on_autocomplete_input_change(tag.id, newInputValue)}
                    onChange={(_event, _value, reason, new_input_value) => {
                      on_autocomplete_change(tag.id, reason, new_input_value)
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.defaultPrevented = true
                      }
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label={'Enter new or choose existing'}/>}
                  />
                }
              </div>
            )}
          </ScrollableList>
        }

        {filtered_user_tags.length === 0 &&
          <div className='text-center pb-2'>
            No matches found
          </div>
        }
      </>
    )
  }