import React, { useCallback, useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'

import { fetch_families_for_tag_group, fetch_families_for_tag_value } from '../family_tagging/family_tag_utils.ts'
import { Pane } from '../widgets/Block.js'
import Modal, { ScrollModal } from '../widgets/Modal.js'
import PortfolioSearchNavigation from './PortfolioSearchNavigation.js'
import OrgSearch from '../orgs/OrgSearch.js'
import OrgSearchMultiple from '../orgs/OrgSearchMultiple.js'
import ReportBuilderBasket from './ReportBuilderBasket.js'
import ReportBuilderOptionsModal from './ReportBuilderOptionsModal.js'
import OrgSetsDisplay from '../org_sets/OrgSetsDisplay.js'
import FamilyTagSectionsDisplay from '../family_tagging/FamilyTagSectionsDisplay.tsx'
import PatentFamilyGlobalSearch from '../patent_family_list/PatentFamilyGlobalSearch.js'
import ErrorModal from '../ErrorModal.js'
import ErrorBody from '../ErrorBody'
import PortfolioUpload from './PortfolioUpload.js'
import Spinner from '../widgets/Spinner.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { ReportBuilderInfoPanel } from './ReportBuilderInfoPanel.js'
import { withUser } from '../UserContext.js'
import OrgGroup from '../orgs/OrgGroup.js'
import ReportBasketContext from './ReportBasketContext.js'
import ClassifiersDisplay from '../classifiers/ClassifiersDisplay.js'
import { DEFAULT_REPORT_TYPE, ND_REPORT_TYPE } from '../../constants/constants.js'
import {
  get_family_tag_as_portfolio_item,
  get_patent_upload_as_portfolio_item,
  get_boolean_search_as_portfolio_item,
  get_org_as_portfolio_item,
  get_org_group_as_portfolio_item,
  is_patent_families_type,
  is_family_tag_type
} from '../../model/portfolio_basket.js'
import {
  is_report_with_classifiers,
  is_report_with_clustered_techs,
  is_report_with_multiclass
} from '../../model/technology_basket.js'
import { EDIT_CLASSIFIER_SELECTION_LINK_TEXT, SELECT_CLASSIFIERS_LINK_TEXT } from '../../constants/technology_basket.js'
import {
  is_creator,
  has_cipher_product, has_utt,
} from '../../utils/user_permissions.js'
import {
  fetch_input_by_id,
  get_evaluation_report_input,
  get_portfolio_basket_type_for_tracking,
  PORTFOLIO_SEARCH_TYPE_BY_ID,
  REPORT_BUILDER_MODE_BY_ID,
  get_technology_object,
  get_report_type_by_flag,
  get_preselected_technology_basket_from_taxonomy,
  build_report,
  prepare_base_report_portfolios,
  get_portfolio_basket_orgs_total_size,
  save_new_report_with_existing_internal_id,
  get_preselected_technology_basket_for_eval_classifier,
  get_subpath_for_report_link
} from '../../utils/report_builder_utils.js'
import { fetch_report_input } from '../../utils/report_reader_utils.js'

import {
  get_leaf_nodes_as_array,
} from '../../utils/classifier_tree_utils.js'
import { fetch_company_lists } from '../../utils/company_list_utils.js'
import { are_same_orgs, is_org_group_type, is_org_type } from '../../utils/organisation_utils.js'
import { get_normalised_search_phrase } from '../../utils/patent_family_list_utils.js'
import { fetch_report_metadata } from '../../utils/report_created_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { is_not_found } from '../../utils/axios_utils.js'
import { get_classifier_groups } from '../../utils/classifier_group_utils.js'

import { PRODUCT_GOOGLE, PRODUCT_ND } from '../../constants/cipher_product_ids.js'
import { REPORT } from '../../constants/paths.js'
import { get_default_utt_version } from '../../utils/user_settings_utils.js'
import { get_as_map, get_object_values, pluralise_text, sum } from '../../utils/utils.js'
import { check_for_existing_and_in_progress_reports } from '../../utils/choreo_utils.js'
import { build_generic_builder_report_input } from '../../utils/report_input_utils.js'
import { filter_matched_patents } from '../patent_upload/table/matched_patents_table_utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import InvalidOrganisationsWarning from '../orgs/InvalidOrganisationsWarning.js'
import UnavailableClassifiersWarning from '../classifiers/UnavailableClassifiersWarning.js'
import {
  get_negatives_processing,
  NEGATIVES_PROCESSING_EXCLUDE,
  NEGATIVES_PROCESSING_INCLUDE,
  NEGATIVES_PROCESSING_UTT
} from '../../model/negatives_processing.js'
import { save_eval_report_selected_charts_in_state } from '../../utils/report_state_utils.js'
import { is_utt_report_type } from '../../utils/report_utils.js'
import ReportBuilderNoAccessPanel from './ReportBuilderNoAccessPanel.js'
import { get_list_of_hashed_classifier_ids } from '../../utils/tracking_hashed_id_utils.js'

import list_view_styles from './builder_list_view_styles.module.scss'
import s from './ReportBuilder.module.scss'

const ReportBuilder = (
  {
    user,
    history,
    location,
    is_nd_report,
    is_valuation_report,
  }) => {

  const query_params = qs.parse(location.search)

  const { user_settings } = useContext(UserSettingsContext)

  const [is_fetching_initial_data, set_is_fetching_initial_data] = useState(true)
  const [show_spinner, set_show_spinner] = useState(false)
  const [classifier_groups, set_classifier_groups] = useState([])
  const [mode, set_mode] = useState(REPORT_BUILDER_MODE_BY_ID[query_params.mode] || REPORT_BUILDER_MODE_BY_ID.portfolio)
  const [portfolio_search_mode, set_portfolio_search_mode] = useState(PORTFOLIO_SEARCH_TYPE_BY_ID[query_params.portfolio_search_mode] || get_default_portfolio_search_mode())

  const [report_type, set_report_type] = useState(get_report_type())
  const [use_utt_superclasses, set_use_utt_superclasses] = useState(null)
  const [utt_version, ] = useState(get_default_utt_version(user_settings))
  const [show_report_options, set_show_report_options] = useState(false)
  const [org_group, set_org_group] = useState([])
  const [orgs_for_suggestions, set_orgs_for_suggestions] = useState(null)
  const [families_for_suggestions, set_families_for_suggestions] = useState(null)
  const [portfolio_basket, set_portfolio_basket] = useState([])
  const [portfolio_basket_sizes, set_portfolio_basket_sizes] = useState([])
  const [is_calculate_orgs_total_size, set_is_calculate_orgs_total_size] = useState(false)
  const [portfolio_basket_orgs_total_size, set_portfolio_basket_orgs_total_size] = useState(0)
  const [portfolio_roll_up_limit, set_portfolio_roll_up_limit] = useState(null)
  const [group_by_owner_level, set_group_by_owner_level] = useState(null)
  const [technology_basket, set_technology_basket] = useState({})
  const [is_multiple_org_search, set_is_multiple_org_search] = useState(query_params.search_multiple || false)
  const [invalid_portfolios, set_invalid_portfolios] = useState(null)
  const [unavailable_classifiers, set_unavailable_classifiers] = useState(null)
  const [user_company_lists, set_user_company_lists] = useState(null)
  const [invalid_tagged_families, set_invalid_tagged_families] = useState(null)
  const [start_report, set_start_report] = useState(false)
  const [report_options, set_report_options] = useState(null)
  const [evaluation_classifier_id, set_evaluation_classifier_id] = useState(null)

  const [custom_upload_job_id,] = useState(query_params.job_id)

  const [input_id,] = useState(query_params.input || null)
  const [knn_search_input,] = useState(query_params.knn_search_input_id || null)

  const [base_report_id,] = useState(query_params.base_report || null)
  const [alert_id,] = useState(query_params.alert || null)
  const [fetch_base_report_input, set_fetch_base_report_input] = useState(false)
  const [loading_base_report, set_loading_base_report] = useState(false)
  const [base_report_title, set_base_report_title] = useState(null)
  const [base_technology_options, set_base_technology_options] = useState(null)
  const [base_portfolios_to_cluster, set_base_portfolios_to_cluster] = useState(null)

  const [error_fetching_initial_data, set_error_fetching_initial_data] = useState(null)
  const [build_report_error, set_build_report_error] = useState(null)
  const [loading_base_report_error, set_loading_base_report_error] = useState(null)
  const [loading_clickthrough_input_error, set_loading_clickthrough_input_error] = useState(null)
  const [verify_base_report_portfolios_error, set_verify_base_report_portfolios_error] = useState(null)
  const [fetch_user_company_lists_error, set_fetch_user_company_lists_error] = useState(null)
  const [duplicated_basket_item_error, set_duplicated_basket_item_error] = useState(false)

  function get_report_type() {
    if (is_nd_report) return ND_REPORT_TYPE

    return DEFAULT_REPORT_TYPE
  }

  function get_default_portfolio_search_mode() {
    return is_valuation_report ? PORTFOLIO_SEARCH_TYPE_BY_ID.upload : PORTFOLIO_SEARCH_TYPE_BY_ID.org_search
  }

  useEffect(() => {
    document.title = 'Cipher: Custom report builder'

    Promise.all([
      fetch_company_lists(),
      get_classifier_groups(user, true /* include nd */)
    ])
      .catch(err => {
        set_error_fetching_initial_data(err)
        set_is_fetching_initial_data(false)
        throw err
      })
      .then(([company_lists, classifier_groups]) => {
        set_classifier_groups(classifier_groups)
        set_user_company_lists(company_lists)
        set_is_fetching_initial_data(false)

        const id_to_classifier_group = get_as_map(classifier_groups, 'id')

        if (is_nd_report) {
          const nd_classifiers = id_to_classifier_group[PRODUCT_ND]
          set_technology_basket(get_preselected_technology_basket_from_taxonomy(nd_classifiers))
        }

        if (is_valuation_report) {
          const google_classifiers = id_to_classifier_group[PRODUCT_GOOGLE]
          set_technology_basket(get_preselected_technology_basket_from_taxonomy(google_classifiers))
        }

        if (base_report_id) {
          set_fetch_base_report_input(true)
          return
        }

        if (input_id || knn_search_input) {
          set_show_spinner(true)
          fetch_input_by_id({input_id, knn_search_input}) //could be input from click through or wizard
            .then(report_input => {

              const {report_name, portfolios, portfolio_sizes, report_type, evaluation_classifier_id, technology_basket: technology_basket_from_input, use_superclasses, organisations_total_size, invalid_portfolios} = report_input || {}
              const technology_basket = technology_basket_from_input || (evaluation_classifier_id ? get_preselected_technology_basket_for_eval_classifier(evaluation_classifier_id, classifier_groups) : null)

              set_portfolio_basket(portfolios)
              set_portfolio_basket_sizes(portfolio_sizes)
              set_report_type(report_type)
              set_evaluation_classifier_id(evaluation_classifier_id)
              set_technology_basket(technology_basket)
              set_base_report_title(report_name)

              set_portfolio_basket_orgs_total_size(organisations_total_size)
              set_invalid_portfolios(invalid_portfolios)

              if (use_superclasses != null) {
                set_use_utt_superclasses(use_superclasses)
              }
              set_show_spinner(false)
            })
            .catch(error => {
              set_loading_clickthrough_input_error(error)
              set_show_spinner(false)
            })
          return
        }

        const evaluation_report_input = get_evaluation_report_input(user)
        if (evaluation_report_input) {
          const {
            start_report: eval_start_report,
            portfolio_basket: eval_portfolio_basket,
            technology_basket: eval_technology_basket,
            report_options: eval_report_options,
            report_type: eval_report_type,
            evaluation_classifier_id: eval_evaluation_classifier_id
          } = evaluation_report_input

          set_technology_basket(eval_technology_basket)
          set_report_options(eval_report_options)
          set_base_report_title(eval_report_options.auto_report_name)
          set_base_technology_options(eval_report_options)
          set_report_type(eval_report_type)
          set_evaluation_classifier_id(eval_evaluation_classifier_id)
          set_loading_base_report(true)

          prepare_base_report_portfolios({portfolios: eval_portfolio_basket})
            .then(data => {
              const {portfolios, invalid_portfolios, organisations_total_size, portfolio_sizes} = data
              set_portfolio_basket(portfolios)
              set_portfolio_basket_sizes(portfolio_sizes)
              set_invalid_portfolios(invalid_portfolios)
              set_portfolio_basket_orgs_total_size(organisations_total_size)

              set_start_report((invalid_portfolios || []).length > 0 ? false : eval_start_report)

              set_loading_base_report(false)
            })
            .catch(error => {
              set_verify_base_report_portfolios_error(error)
              set_loading_base_report(false)
            })
        }
      })
  }, [base_report_id, input_id, knn_search_input, is_nd_report, is_valuation_report, user])

  const get_all_classifiers = useCallback(() => {
    // Get a flat list of all classifiers (regardless of level in a taxonomy)
    const classifiers_leaves = classifier_groups.map(classifier_group => get_leaf_nodes_as_array(classifier_group))
    return [].concat(...classifiers_leaves)
  }, [classifier_groups])

  const prepare_base_report_basket = useCallback((report_input) => {

    const {
      portfolios = [],
      technology_partitioning,
      technologyPartitioning,
      portfolio_roll_up_limit,
      portfolio_group_by_owner_ancestor_type,
      report_type
    } = report_input

    set_portfolio_roll_up_limit(portfolio_roll_up_limit)
    set_group_by_owner_level(portfolio_group_by_owner_ancestor_type)
    set_report_type(report_type)
    const tech_partitioning = technology_partitioning || technologyPartitioning
    const classifiers = get_all_classifiers()

    const {
      type: tech_partitioning_type,
      classifier_sources,
      include_negatives,
      negatives_processing,
      multi_label,
      threshold,
      portfolios_to_cluster
    } = tech_partitioning

    if (is_report_with_classifiers({tech_partitioning_type})) {
      const selected_classifiers = classifier_sources.map(item => {

        const { path: training_set_path, id: training_set_id } = item

        const filtered = classifiers.filter(classifier => {

          if (classifier.classifier_id === training_set_id) {
            const {taxonomy_path, parent} = classifier

            if ((training_set_path.length === 0) && taxonomy_path && (taxonomy_path.length === 0)) { return true}

            return parent[0] === training_set_path[0]
          }

          return false
        })

        return (filtered.length > 0) ? filtered[0] : null
      }).filter(classifier => classifier) // remove any not-found classifiers

      const unavailable = classifier_sources.filter(classifier => {
        return !_.any(selected_classifiers, selected_classifier => selected_classifier.classifier_id === classifier.id)
      })

      let basket = {}

      selected_classifiers.forEach(item => {
        if (item && item.name) {
          const {classifier_id} = item

          basket = {...basket, [classifier_id]: get_technology_object(item)}
        }
      })

      const updated_negatives_processing = (include_negatives != null) ?
        get_negatives_processing({type: (include_negatives ? (has_utt(user) ? NEGATIVES_PROCESSING_UTT : NEGATIVES_PROCESSING_INCLUDE) : NEGATIVES_PROCESSING_EXCLUDE), utt_version}) :
        negatives_processing
      set_technology_basket(basket)
      set_base_technology_options({negatives_processing: updated_negatives_processing, multi_label, threshold})
      set_unavailable_classifiers(unavailable)
    }

    if (is_report_with_clustered_techs({tech_partitioning_type})) {
      const base_portfolios_to_cluster = portfolios.map((item, i) => ({
        name: item.name,
        to_cluster: (portfolios_to_cluster || []).indexOf(i) !== -1
      }))
      set_base_portfolios_to_cluster(base_portfolios_to_cluster)
    }

    if (is_report_with_multiclass({tech_partitioning_type: tech_partitioning.type})) {
      const {use_superclasses} = tech_partitioning
      set_use_utt_superclasses(use_superclasses)
    }

    prepare_base_report_portfolios({portfolios})
      .then(data => {
        const {portfolios, invalid_portfolios, organisations_total_size, portfolio_sizes} = data
        set_portfolio_basket(portfolios)
        set_portfolio_basket_sizes(portfolio_sizes)
        set_invalid_portfolios(invalid_portfolios)
        set_portfolio_basket_orgs_total_size(organisations_total_size)
        set_loading_base_report(false)
      })
      .catch(error => {
        set_verify_base_report_portfolios_error(error)
        set_loading_base_report(false)
      })
  }, [user, utt_version, get_all_classifiers])

  useEffect(() => {
    if (!fetch_base_report_input || !base_report_id) return

    track_report_builder_event('action="preload_report_parameters"')
    set_loading_base_report(true)
    set_fetch_base_report_input(false)
    fetch_report_metadata(base_report_id)
      .then(({internal_report_id, title, evaluation_classifier_id}) => {
        set_base_report_title(title)
        set_evaluation_classifier_id(evaluation_classifier_id)
        return fetch_report_input(internal_report_id)
      })
      .catch(error => {
        set_loading_base_report_error(error)
        set_loading_base_report(false)
        throw error
      })
      .then(report_input => {
        prepare_base_report_basket(report_input) // this is not a Promise, but actually an async call which sets state (handles its own errors etc...)
      })

  }, [fetch_base_report_input, prepare_base_report_basket, base_report_id])

  const redirect_to_created_external_id = useCallback((external_report_id) => {
    const subpath = get_subpath_for_report_link({is_nd_report, is_valuation_report, evaluation_classifier_id, alert_id})
    const url = `${REPORT}/${external_report_id}${subpath}`
    history.replace({pathname: url})
  }, [history, is_nd_report, is_valuation_report, evaluation_classifier_id, alert_id])

  const build_report_input_with_options = useCallback((options) => {
    const { auto_report_name: report_name, portfolios_to_cluster, negatives_processing, multi_label, threshold } = options
    return build_generic_builder_report_input({
      report_name,
      report_type,
      portfolios: portfolio_basket.map(item => _.omit(item, 'original_name')),
      portfolios_to_cluster,
      group_by_owner_level,
      portfolio_roll_up_limit,
      classifiers: technology_basket && !_.isEmpty(technology_basket) ? get_object_values(technology_basket) : null,
      evaluation_classifier_id,
      negatives_processing,
      multi_label,
      threshold,
      use_utt_superclasses,
      utt_version
    })
  }, [evaluation_classifier_id, group_by_owner_level, portfolio_basket, portfolio_roll_up_limit, report_type, technology_basket, use_utt_superclasses, utt_version])

  useEffect(() => {
    if (!start_report) return
    const with_classifiers = technology_basket && (Object.keys(technology_basket).length !== 0)
    const classifier_ids_for_tracking = with_classifiers ? get_list_of_hashed_classifier_ids(Object.keys(technology_basket)) : null
    const classifier_ids_part = with_classifiers ? ` classifier_ids="${classifier_ids_for_tracking}"` : ''
    track_report_builder_event(`action="build_report" report_type="${get_report_type_by_flag(report_type, is_nd_report)}" classifiers="${with_classifiers ? 'yes' : 'no'}" context="builder"${classifier_ids_part}`)

    const report_input = build_report_input_with_options(report_options)
    const {user_report_name} = report_options
    build_report(report_input, user_report_name)
      .then(external_report_id => {

        if (evaluation_classifier_id !== null) {
          return save_eval_report_selected_charts_in_state(external_report_id)
        }

        return external_report_id
      })
      .then((external_report_id) => {
        redirect_to_created_external_id(external_report_id)
      })
      .catch(handle_report_creation_error)
  }, [start_report, is_nd_report, report_options, report_type, technology_basket, build_report_input_with_options, redirect_to_created_external_id, evaluation_classifier_id])

  useEffect(() => {
    if (!is_calculate_orgs_total_size) return

    if (!portfolio_basket || portfolio_basket.length === 0) {
      set_is_calculate_orgs_total_size(false)
      set_portfolio_basket_orgs_total_size(0)
      return
    }

    get_portfolio_basket_orgs_total_size(portfolio_basket)
      .then(total_size => {
        set_portfolio_basket_orgs_total_size(total_size)
        set_is_calculate_orgs_total_size(false)
      })
      .catch((error) => {
        send_error_to_sentry(error, {})
        set_is_calculate_orgs_total_size(false)
      })
  }, [is_calculate_orgs_total_size, portfolio_basket])

  function on_set_report_type({report_type, use_utt_superclasses = null}) {
    track_report_builder_event(`action="set_report_type" limit="${report_type}" context="builder"`)
    set_use_utt_superclasses(use_utt_superclasses)
    set_report_type(report_type)
  }

  function on_set_portfolio_roll_up_limit(portfolio_roll_up_limit) {
    track_report_builder_event(`action="set_portfolio_roll_up_limit" limit="${portfolio_roll_up_limit}" context="builder"`)
    set_portfolio_roll_up_limit(portfolio_roll_up_limit)
  }

  function on_set_group_by_owner_level(group_by_owner_level) {
    track_report_builder_event(`action="set_group_by_owner_level" group_by_owner_level="${group_by_owner_level}" context="builder"`)
    set_group_by_owner_level(group_by_owner_level)
  }

  function create_report_from_existing(existing_internal_report_id, report_options) {
    const report_input = build_report_input_with_options(report_options)
    const {user_report_name} = report_options
    save_new_report_with_existing_internal_id({
      existing_internal_report_id,
      report_name: user_report_name,
      report_input
    })
      .then(redirect_to_created_external_id)
      .catch(handle_report_creation_error)
  }

  function handle_report_creation_error(error) {
    set_build_report_error(error)
    set_start_report(false)
    throw error
  }

  function toggle_mode() {
    //switch between portfolio search and technology filters
    const updated_mode = (mode === REPORT_BUILDER_MODE_BY_ID.portfolio) ? REPORT_BUILDER_MODE_BY_ID.technology : REPORT_BUILDER_MODE_BY_ID.portfolio

    const new_query = {...qs.parse(location.search), mode: updated_mode.id}
    history.replace({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})

    set_mode(updated_mode)
  }

  function on_toggle_is_multiple_org_search(updated_is_multiple_org_search) {
    if (updated_is_multiple_org_search !== is_multiple_org_search) {
      const new_query = {...qs.parse(location.search), search_multiple: updated_is_multiple_org_search}
      history.replace({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})
      set_is_multiple_org_search(updated_is_multiple_org_search)
    }
  }

  function handle_save_portfolio_basket_as_list(company_list) {
    if (company_list) {
      // list has been saved successfully; add metadata to the relevant basket items
      const portfolio_basket_updated = portfolio_basket.map(portfolio => is_org_type(portfolio) ? {
        ...portfolio,
        company_list
      } : portfolio)
      set_portfolio_basket(portfolio_basket_updated)
    }
    fetch_user_created_company_lists()
  }

  function fetch_user_created_company_lists() {
    fetch_company_lists()
      .catch((error) => {
        set_fetch_user_company_lists_error(error)
        throw error
      })
      .then(user_company_lists => set_user_company_lists(user_company_lists))
  }

  function portfolio_search_mode_change(mode) {
    const new_query = {...qs.parse(location.search), portfolio_search_mode: mode.id}
    history.replace({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})
    set_orgs_for_suggestions(null)
    set_families_for_suggestions(null)
    set_portfolio_search_mode(mode)
  }

  function add_search_results_to_portfolio_basket(search_phrase, total) {
    const portfolio_item = get_boolean_search_as_portfolio_item(get_normalised_search_phrase(search_phrase))

    const basket_items_with_the_same_name = portfolio_basket.filter((item) => (item.name === portfolio_item.name))

    if (basket_items_with_the_same_name.length !== 0) {
      set_duplicated_basket_item_error(true)
      return
    }

    set_portfolio_basket([...portfolio_basket, portfolio_item])
    set_portfolio_basket_sizes([...portfolio_basket_sizes, total])
  }

  function add_org_set_to_portfolio_basket(org_set) {
    if (!org_set || !Array.isArray(org_set) || org_set.length === 0) return

    const portfolio_basket_by_name = get_as_map(portfolio_basket, 'name')

    const new_orgs = org_set.filter(org => portfolio_basket_by_name[org.name] == null)
    const new_basket_items = new_orgs.map(org => get_org_as_portfolio_item(org))
    const new_basket_items_sizes = new_orgs.map(org => (org || {}).size || 0)

    set_portfolio_basket([...portfolio_basket, ...new_basket_items])
    set_portfolio_basket_sizes([...portfolio_basket_sizes, ...new_basket_items_sizes])
    set_is_calculate_orgs_total_size(true)
  }

  function add_tag_set_to_portfolio_basket(tag, tag_value) {
    const fetch_families_promise = (tag_value ?
      fetch_families_for_tag_value(tag_value.id) :
      fetch_families_for_tag_group(tag.id))
    const portfolio_name = (tag_value ?
      tag.name + ': ' +
      tag_value.value : tag.name)

    fetch_families_promise
      .then(tagged_families_array => {
        const tagged_families = tagged_families_array.reduce((total, families_i)=> {return total.concat(families_i)},[])
        const portfolio_item = get_family_tag_as_portfolio_item(portfolio_name, tagged_families)

        if (is_valuation_report) {
          portfolio_item['group_by_owner'] = false
        }

        const basket_items_with_the_same_name = portfolio_basket.filter((item) => (item.name === portfolio_item.name))

        if (basket_items_with_the_same_name.length !== 0) {
          set_duplicated_basket_item_error(true)
          return
        }

        set_portfolio_basket([...portfolio_basket, portfolio_item])
        set_portfolio_basket_sizes([...portfolio_basket_sizes, (tagged_families || []).length])
      })
      .catch(error => {
        set_invalid_tagged_families(error)
        throw error
      })
  }

  function update_orgs_in_portfolio_basket({add, remove}) {
    const orgs_to_add = add || []
    const orgs_to_remove = remove || []

    let basket = [...portfolio_basket]
    let basket_sizes = [...portfolio_basket_sizes]

    orgs_to_remove.forEach(org => {
      let idx = -1

      basket.forEach((item, i) => {
        if (is_org_type(item) && are_same_orgs(item, org)) {
          idx = i
        }
      })

      if (idx !== -1) {
        basket.splice(idx, 1)
        basket_sizes.splice(idx, 1)
      }
    })

    orgs_to_add.forEach(org => {
      basket = [...basket, get_org_as_portfolio_item(org)]
      basket_sizes = [...basket_sizes, (org || {}).size || 0]
    })

    set_portfolio_basket(basket)
    set_portfolio_basket_sizes(basket_sizes)
    set_is_calculate_orgs_total_size(true)
  }

  function add_custom_search_to_portfolio_basket(patent_search) {
    const {name, found_families, group_by_owner, table_rows} = patent_search

    const found = table_rows ? filter_matched_patents(table_rows) : []

    const patent_upload_item = {
      name,
      pat_fam_ids: found_families,
      ...(is_valuation_report) ? {group_by_owner: false} : {group_by_owner},
      lines: found.map(row => row.lineno),
      inputs: found.map(row => row.source),
      publications: found.map(row => row.publication)
    }

    const portfolio_item = get_patent_upload_as_portfolio_item(patent_upload_item)

    const basket_items_with_the_same_name = portfolio_basket.filter((item) => (item.name === portfolio_item.name))

    if (basket_items_with_the_same_name.length !== 0) {
      set_duplicated_basket_item_error(true)
      return
    }

    set_portfolio_basket([...portfolio_basket, portfolio_item])
    set_portfolio_basket_sizes([...portfolio_basket_sizes, found_families.length])
  }

  function rename_portfolio_basket_item(item_id, new_name) {
    const item = portfolio_basket[item_id] || {}
    const {name, original_name} = item
    update_portfolio_basket_item({...item, name: new_name, original_name: original_name || name}, item_id)
  }

  function update_portfolio_basket_item(item, item_id) {
    let new_portfolio_basket = [...portfolio_basket]

    new_portfolio_basket[item_id] = item

    set_portfolio_basket(new_portfolio_basket)
  }

  function update_all_portfolio_basket_items(idxs, property_name, value) {
    const idxs_set = new Set(idxs)
    const new_portfolio_basket = portfolio_basket.map((item, idx) => {
      if (idxs_set.has(idx)) {
        return {...item, [property_name]: value}
      }
      return item
    })
    set_portfolio_basket(new_portfolio_basket)
  }

  function update_technology_basket(selections) {
    if (!selections) {
      return
    }

    if (is_utt_report_type(report_type)) {
      //can happen when base report was UTT
      set_report_type(DEFAULT_REPORT_TYPE)
    }

    const basket = {}
    selections.forEach(item => {
      const {classifier_id} = item

      basket[classifier_id] = item
    })

    set_technology_basket(basket)
  }

  function clear_portfolio_basket() {
    set_portfolio_basket([])
    set_portfolio_basket_sizes([])
    set_portfolio_basket_orgs_total_size(0)
  }

  function clear_technology_basket() {
    set_technology_basket({})
  }

  function delete_from_portfolio_basket(basket_item_id_to_delete) {
    let new_portfolio_basket = [...portfolio_basket]
    let new_portfolio_basket_sizes = [...portfolio_basket_sizes]

    const item = portfolio_basket[basket_item_id_to_delete]

    track_report_builder_event(`action="remove_from_portfolio_basket" obj="${get_portfolio_basket_type_for_tracking(item)}"`)

    new_portfolio_basket.splice(basket_item_id_to_delete, 1)
    new_portfolio_basket_sizes.splice(basket_item_id_to_delete, 1)

    set_portfolio_basket(new_portfolio_basket)
    set_portfolio_basket_sizes(new_portfolio_basket_sizes)
    set_is_calculate_orgs_total_size(is_org_type(item) || is_org_group_type(item))
  }

  function toggle_report_options() {
    set_show_report_options(!show_report_options)
  }

  function on_finish_from_basket() {
    set_show_report_options(true)
  }

  function check_for_existing_reports(options) {
    const report_input = build_report_input_with_options(options)
    return check_for_existing_and_in_progress_reports(report_input)
  }

  function on_start_report(options) {
    set_report_options(options)
    set_start_report(true)
  }

  function update_org_group({add, remove}) {
    const orgs_to_add = add || []
    const orgs_to_remove = remove || []

    let updated_group = [...org_group]

    orgs_to_remove.forEach(org => {
      let idx = -1
      updated_group.forEach((org_in_group, i) => {
        if (are_same_orgs(org_in_group, org)) {
          idx = i
        }
      })

      if (idx !== -1) {
        updated_group.splice(idx, 1)
      }
    })

    orgs_to_add.forEach(org => {
      updated_group = [...updated_group, org]
    })

    set_org_group(updated_group)
  }

  function remove_from_grouping(i) {
    track_report_builder_event('action="remove_from_org_group" obj="org"')
    const group_copy = [...org_group]
    group_copy.splice(i, 1)

    set_org_group(group_copy)
  }

  function cancel_grouping() {
    track_report_builder_event('action="reset" obj="org_group"')
    set_org_group([])
  }

  function add_group_to_portfolio_basket(group_name) {
    track_report_builder_event('action="add_to_basket" obj="org_group"')

    const portfolio_item = get_org_group_as_portfolio_item(org_group, group_name)
    const group_sizes = org_group.map(org => (org || {}).size || 0)

    const path = location.pathname
    const new_query = {...qs.parse(location.search), org_search: ''}
    history.replace({pathname: path, search: `?${qs.stringify(new_query)}`})

    set_org_group([])
    set_portfolio_basket([...portfolio_basket, portfolio_item])
    set_portfolio_basket_sizes([...portfolio_basket_sizes, sum(group_sizes)])
    set_is_calculate_orgs_total_size(true)
  }

  function get_similar_orgs_for_basket_item(i) {
    const item = portfolio_basket[i]
    on_toggle_is_multiple_org_search(false)

    if (is_org_type(item) || is_org_group_type(item)) {
      return get_similar_orgs_for_organisation(item)
    }

    if (is_patent_families_type(item)) {
      return get_similar_org_for_patent_upload(item)
    }

    if (is_family_tag_type(item)) {
      return get_similar_org_for_family_tag(item)
    }
  }

  function get_similar_orgs_for_organisation(item) {
    const {name, members} = item

    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.org_search)
    set_orgs_for_suggestions({name, items: is_org_group_type(item) ? members : [item]})
    set_families_for_suggestions(null)
  }

  function get_similar_org_for_patent_upload(item) {
    const {name, pat_fam_ids} = item

    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.upload)
    set_families_for_suggestions({name, items: pat_fam_ids})
    set_orgs_for_suggestions(null)
  }

  function get_similar_org_for_family_tag(item) {
    const {name, pat_fam_ids} = item

    set_portfolio_search_mode(PORTFOLIO_SEARCH_TYPE_BY_ID.tag_sets)
    set_families_for_suggestions({name, items: pat_fam_ids})
    set_orgs_for_suggestions(null)
  }

  function reset_orgs_for_suggestions() {
    set_orgs_for_suggestions(null)
  }

  function reset_families_for_suggestions() {
    set_families_for_suggestions(null)
  }

  function rename_user_company_list(selected_company_list_id, new_name) {
    const updated_lists = user_company_lists.map(list => {
      const {company_list_id} = list

      if (company_list_id === selected_company_list_id) {
        return {...list, list_name: new_name}
      }

      return list
    })

    set_user_company_lists(updated_lists)
  }

  function change_user_company_list_sharing_status(selected_company_list_id) {
    const updated_lists = user_company_lists.map(list => {
      const {company_list_id, is_shared} = list

      if (company_list_id === selected_company_list_id) {
        return {...list, is_shared: !is_shared}
      }

      return list
    })

    set_user_company_lists(updated_lists)
  }

  if (error_fetching_initial_data) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={error_fetching_initial_data}
          context='fetching initial data'
        />
      </ContainerFullWidth>
    )
  }

  if (fetch_user_company_lists_error) {
    return (
      <ErrorModal
        on_hide={() => set_fetch_user_company_lists_error(null)}
        error={fetch_user_company_lists_error}
        context='fetching user created org lists'
      />
    )
  }

  if (build_report_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={build_report_error}
          context='building a report'
        />
      </ContainerFullWidth>
    )
  }

  if (loading_base_report_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          on_hide={() => set_loading_base_report_error(null)}
          error={loading_base_report_error}
          context={`fetching base report data${is_not_found(loading_base_report_error) ? '. Input selections could not be found for the report' : ''}`}
        />
      </ContainerFullWidth>
    )
  }

  if (loading_clickthrough_input_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={loading_clickthrough_input_error}
          context={'fetching report input by id'}
        />
      </ContainerFullWidth>
    )
  }

  if (start_report) {
    return (
      <ReportBuilderInfoPanel className={s.report_builder_container}>
        <Pane title='Report in progress'>
          <div>
            Your report is being created.
          </div>
        </Pane>
      </ReportBuilderInfoPanel>
    )
  }

  const is_org_group = org_group && org_group.length > 0

  const has_classifiers = (classifier_groups.length > 0)

  if ((is_nd_report && !has_cipher_product(user, PRODUCT_ND)) || (!is_nd_report && !is_creator(user))) {
    return (
      <ReportBuilderNoAccessPanel/>
    )
  }

  if (is_fetching_initial_data) {
    return (
      <div className={cn('d-lg-flex', s.report_builder_container)}>
        <Pane className='text-center w-100' light>
          <Spinner/>
          <p>Fetching initial data...</p>
        </Pane>
      </div>
    )
  }

  if (show_spinner) {
    return (
      <div className={cn('d-lg-flex', s.report_builder_container)}>
        <Pane className='text-center w-100' light>
          <Spinner/>
          <p>Fetching results...</p>
        </Pane>
      </div>
    )
  }

  return (
    <ReportBasketContext.Provider value={{
      portfolio_basket,
      portfolio_basket_sizes,
      portfolio_basket_orgs_total_size,
      technology_basket,
      org_group
    }}>
      <div className={cn('d-lg-flex', s.report_builder_container)}>

        {duplicated_basket_item_error &&
          <Modal on_hide={() => set_duplicated_basket_item_error(false)}>
            <div>This item is already in the basket</div>
          </Modal>
        }

        {invalid_portfolios &&
          <ScrollModal on_hide={() => set_invalid_portfolios(null)}>
            <InvalidOrganisationsWarning
              invalid_portfolios={invalid_portfolios}
              suggested_action_text='Please re-add them using the search system.'
            />
          </ScrollModal>
        }

        {unavailable_classifiers && unavailable_classifiers.length > 0 &&
          <ScrollModal
            title={`${pluralise_text(unavailable_classifiers.length, 'Classifier')} not found`}
            className='h-50 w-75'
            on_hide={() => set_unavailable_classifiers(null)}
          >
            <UnavailableClassifiersWarning
              unavailable_classifiers={unavailable_classifiers}
              suggested_action_text={has_classifiers ? `Any other classifiers needed for the report can be added by going to '${_.isEmpty(technology_basket) ? SELECT_CLASSIFIERS_LINK_TEXT : EDIT_CLASSIFIER_SELECTION_LINK_TEXT}'.`: null}
            />
          </ScrollModal>
        }

        {invalid_tagged_families &&
          <ErrorModal
            error={invalid_tagged_families}
            on_hide={() => set_invalid_tagged_families(null)}
            context='error fetching tagged families'
          />
        }

        {verify_base_report_portfolios_error &&
          <ErrorModal
            error={verify_base_report_portfolios_error}
            on_hide={() => set_verify_base_report_portfolios_error(null)}
            context='verifying base report organisations'
          />
        }

        <ReportBuilderOptionsModal
          is_open={show_report_options}
          user_settings={user_settings || {}}
          on_close_handler={toggle_report_options}
          do_report_handler={on_start_report}
          check_existing_reports_handler={check_for_existing_reports}
          create_report_from_existing_handler={create_report_from_existing}
          update_portfolio_basket_item_handler={update_portfolio_basket_item}
          update_all_portfolio_basket_items_handler={update_all_portfolio_basket_items}
          base_report_name={base_report_title || null}
          is_nd_report={is_nd_report}
          is_valuation_report={is_valuation_report}
          base_portfolios_to_cluster={base_portfolios_to_cluster}
          base_technology_options={base_technology_options}
          portfolio_roll_up_limit={portfolio_roll_up_limit}
          set_portfolio_roll_up_limit={on_set_portfolio_roll_up_limit}
          group_by_owner_level={group_by_owner_level}
          set_group_by_owner_level={on_set_group_by_owner_level}
          report_type={report_type}
          utt_version={utt_version}
          use_utt_superclasses={use_utt_superclasses}
          set_report_type={on_set_report_type}
        />

        {loading_base_report &&
          <ReportBuilderInfoPanel className={s.report_builder_container}>
            <Pane title='Loading report'>
              <div className='text-center'>
                <div><Spinner/></div>
                <div>Loading report {base_report_title ? `"${base_report_title}"` : base_report_id}</div>
              </div>
            </Pane>
          </ReportBuilderInfoPanel>
        }

        {!loading_base_report && (!mode || (REPORT_BUILDER_MODE_BY_ID.portfolio === mode)) &&
          <div className={cn('order-lg-0', s.store_container, s.store_container_portfolio)}>
            <PortfolioSearchNavigation
              active={portfolio_search_mode}
              on_change={portfolio_search_mode_change}
              is_nd_report={(is_nd_report === true)}
              is_valuation_report={is_valuation_report === true}
              org_group_started={is_org_group}
            />

            {(PORTFOLIO_SEARCH_TYPE_BY_ID.org_search === portfolio_search_mode) &&
              <div className='mb-3'>
                {is_multiple_org_search ?
                  <OrgSearchMultiple
                    portfolio_basket={portfolio_basket}
                    update_basket_handler={update_orgs_in_portfolio_basket}
                    update_group_handler={update_org_group}
                    external_items_for_suggestions={orgs_for_suggestions}
                    reset_items_for_suggestions_handler={reset_orgs_for_suggestions}
                    on_search_mode_change={() => on_toggle_is_multiple_org_search(false)}
                    enable_remove_action={true}
                    enable_ignore_action={true}
                  /> :
                  <OrgSearch
                    update_basket_handler={update_orgs_in_portfolio_basket}
                    update_group_handler={update_org_group}
                    external_items_for_suggestions={orgs_for_suggestions}
                    reset_items_for_suggestions_handler={reset_orgs_for_suggestions}
                    toggle_is_search_multiple={() => on_toggle_is_multiple_org_search(true)}
                  />
                }
              </div>
            }

            {(PORTFOLIO_SEARCH_TYPE_BY_ID.org_sets === portfolio_search_mode) &&
              <OrgSetsDisplay
                add_to_basket_handler={add_org_set_to_portfolio_basket}
                user_company_lists={user_company_lists}
                user_company_list_updated_handler={fetch_user_created_company_lists}
                user_company_list_rename_handler={rename_user_company_list}
                user_company_list_sharing_handler={change_user_company_list_sharing_status}
                invalid_portfolios_in_list_handler={(invalid_portfolios) => set_invalid_portfolios(invalid_portfolios)}
              />
            }
            {(PORTFOLIO_SEARCH_TYPE_BY_ID.tag_sets === portfolio_search_mode) &&
              <FamilyTagSectionsDisplay
                add_to_basket_handler={add_tag_set_to_portfolio_basket}
                portfolio_name={(families_for_suggestions || {}).name}
                portfolio_items={(families_for_suggestions || {}).items}
                on_click_similar_portfolio={update_orgs_in_portfolio_basket}
                update_group_handler={update_org_group}
                reset_similar_portfolio={reset_families_for_suggestions}
              />
            }
            {(PORTFOLIO_SEARCH_TYPE_BY_ID.families_search === portfolio_search_mode) &&
              <PatentFamilyGlobalSearch
                results_action_handler={add_search_results_to_portfolio_basket}
                controls_row_className={list_view_styles.controls_row} // for setting sticky top
                table_header_className={list_view_styles.table_header} // for setting sticky top
              />
            }

            {(PORTFOLIO_SEARCH_TYPE_BY_ID.upload === portfolio_search_mode) &&
              <PortfolioUpload
                add_to_basket_handler={add_custom_search_to_portfolio_basket}
                update_group_handler={update_org_group}
                on_select_org_handler={update_orgs_in_portfolio_basket}
                external_items_for_suggestions={families_for_suggestions}
                reset_items_for_suggestions_handler={reset_families_for_suggestions}
                custom_portfolio_job_id={custom_upload_job_id}
              />
            }

          </div>
        }

        {!loading_base_report && (REPORT_BUILDER_MODE_BY_ID.technology === mode) &&
          <div className={cn('order-lg-0', s.store_container, s.store_container_technology)}>
            <ClassifiersDisplay
              technology_basket={technology_basket}
              update_selections_handler={update_technology_basket}
              can_edit_technology_filters={has_classifiers}
              report_builder_change_mode_handler={toggle_mode}
              is_nd_report={(is_nd_report === true)}

              classifier_groups={classifier_groups}

              is_basket={true}
            />
          </div>
        }

        {!loading_base_report &&
          <div className={cn('order-lg-1', s.basket_container)}>
            {!is_org_group &&
              <ReportBuilderBasket
                delete_from_portfolio_basket_handler={delete_from_portfolio_basket}
                rename_portfolio_basket_item={rename_portfolio_basket_item}
                clear_portfolio_basket_handler={clear_portfolio_basket}
                create_report_handler={on_finish_from_basket}
                report_builder_change_mode_handler={toggle_mode}
                report_builder_mode={mode}
                report_builder_change_search_mode_handler={portfolio_search_mode_change}
                report_builder_search_mode={portfolio_search_mode}
                show_technologies_basket={has_classifiers}
                clear_technology_basket_handler={clear_technology_basket}
                get_similar_orgs_handler={get_similar_orgs_for_basket_item}
                portfolio_basket_save_handler={handle_save_portfolio_basket_as_list}
                user_company_lists={user_company_lists}
                user={user}
                is_calculate_orgs_total_size={is_calculate_orgs_total_size}
              />
            }
            {is_org_group &&
              <OrgGroup
                orgs={org_group}
                on_remove_org={remove_from_grouping}
                on_cancel={cancel_grouping}
                on_submit={add_group_to_portfolio_basket}
              />
            }
          </div>
        }
      </div>
    </ReportBasketContext.Provider>
  )
}

export default withRouter(withUser(ReportBuilder))