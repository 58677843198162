import React from 'react'
import { withRouter, matchPath } from 'react-router'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import cn from 'classnames'
import _ from 'underscore'

import { BAR_CLUSTER_ID, BUBBLE_ID, CHOROPLETH_MAP_ID, GROWTH_TREEMAP_ID, TREEMAP_ID } from '../../model/view_ids.js'
import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from '../../model/spec_groups.js'
import { get_spec_name } from '../../utils/spec_utils.js'
import ItemHeader from './ItemHeader.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import { DownloadSpinner } from '../widgets/Spinner.js'

import cs from '../cipher_styles.module.scss'
import s from './MainReportItemHeader.module.scss'

const MainReportItemHeader = (
  {
    className,
    base_path,
    view_id,
    on_close,
    on_next_item,
    on_prev_item,
    is_renderable,
    spec,
    item,
    too_big_for_microsoft_download,
    download_powerpoint,
    download_excel,
    download_csv,
    download_png,
    download_summary_csv,
    download_summary_excel,
    download_summary_powerpoint,
    toggle_filters_handler,
    filters_visible,
    filters_disabled,
    is_selected,
    on_dataset_select,
    on_dataset_deselect,
    is_export_in_progress,
    is_disputes_dataset
}) => {

  const main_spec_id = spec.id
  const group = SPEC_ID_TO_GROUP[main_spec_id]

  const main_spec_name = get_spec_name(spec, SPEC_ID_TO_SPEC_REF)

  const is_chart_export_available = !_.contains([TREEMAP_ID, GROWTH_TREEMAP_ID, CHOROPLETH_MAP_ID, BUBBLE_ID, BAR_CLUSTER_ID], view_id)

  const base_path_exact_match = matchPath(window.location.pathname, { path: base_path, exact: true })

  const has_summary_download_options = download_summary_csv || download_summary_excel || download_summary_powerpoint

  return (
    <ItemHeader
      className={className}
      on_close={on_close}
      on_next_item={on_next_item}
      on_prev_item={on_prev_item}
      group_name={group.name}
      spec_name={main_spec_name}
      spec_id={main_spec_id}
      item={item}
      level_1_path={base_path}
      toggle_filters_handler={toggle_filters_handler}
      filters_visible={filters_visible}
      filters_disabled={filters_disabled}
      is_selected={is_selected}
      on_dataset_select={on_dataset_select}
      on_dataset_deselect={on_dataset_deselect}
    >

      {base_path_exact_match &&
        <div className='ml-auto d-flex'>
          {is_export_in_progress &&
            <DownloadSpinner
              className='my-auto mr-2'
            />
          }
          {/* Options dropdown */}
          <div className='ml-2'>
            <UncontrolledDropdown>
              <SelectDropdownToggleStatic
                className={s.dropdown_button}
                disabled={!is_chart_export_available && !download_png}
                chevronClassName={cs.cipher_white_text}
                tag='div'
              >
                Export
              </SelectDropdownToggleStatic>
              <DropdownMenu right>
                { has_summary_download_options &&
                  <DropdownItem header>Summary</DropdownItem>
                }
                { download_summary_powerpoint &&
                  <DropdownItem
                    onClick={download_summary_powerpoint}
                    disabled={!is_renderable || is_export_in_progress}
                  >
                    Powerpoint
                  </DropdownItem>
                }
                { download_summary_excel &&
                  <DropdownItem
                    onClick={download_summary_excel}
                    disabled={!is_renderable || is_export_in_progress}
                  >
                    Excel
                  </DropdownItem>
                }
                { download_summary_csv &&
                  <DropdownItem
                    onClick={download_summary_csv}
                    disabled={!is_renderable || is_export_in_progress}
                  >
                    CSV
                  </DropdownItem>
                }

                { is_disputes_dataset && has_summary_download_options &&
                  <DropdownItem header>Disputes</DropdownItem>
                }

                <DropdownItem
                  onClick={download_powerpoint}
                  disabled={too_big_for_microsoft_download || !download_powerpoint || !is_renderable || is_export_in_progress}
                >
                  PowerPoint{!is_chart_export_available ? '*' : ''}
                </DropdownItem>

                <DropdownItem
                  onClick={download_excel}
                  disabled={!download_excel || !is_renderable || is_export_in_progress}
                >
                  Excel{!is_chart_export_available ? '*' : ''}
                </DropdownItem>

                <DropdownItem
                  onClick={download_csv}
                  disabled={!download_csv || !is_renderable || is_export_in_progress}
                >
                  CSV{!is_chart_export_available ? '*' : ''}
                </DropdownItem>

                {download_png &&
                  <DropdownItem
                    onClick={download_png}
                    disabled={!is_renderable || is_export_in_progress}
                  >
                    PNG
                  </DropdownItem>
                }

                {!is_chart_export_available &&
                  <DropdownItem className={cn('mt-2', cs.font_size_x_small, cs.cursor_default, s.info)}>
                    * This chart type cannot be exported but you can download the data.
                  </DropdownItem>
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

        </div>
      }

    </ItemHeader>

  )
}

export default withRouter(MainReportItemHeader)