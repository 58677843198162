import React from 'react'
import cn from 'classnames'

import s from './Connector.module.scss'

const Connector = ({className}) => {
  return (
    <div className={cn(s.block, className)} />
  )
}

export default Connector