import {
  DATA_INTEGRATION_GROUP_ID,
  PATENTS_ASSESSMENT_GROUP_ID,
  PATENTS_CLASSIFICATION_GROUP_ID,
  TECH_LANDSCAPES_GROUP_ID
} from './report_streams.js'

import sg from './stream_groups.module.scss'

export const STREAM_GROUP_TO_CLASSNAME = {
  [TECH_LANDSCAPES_GROUP_ID]: {
    background: sg.tech_landscapes_group,
    light_background: sg.tech_landscapes_group_light_bg,
    text: sg.tech_landscapes_group_text,
    border: sg.tech_landscapes_group_border,
  },
  [PATENTS_CLASSIFICATION_GROUP_ID]: {
    background: sg.patents_classification_group,
    light_background: sg.patents_classification_group_light_bg,
    text: sg.patents_classification_group_text,
    border: sg.patents_classification_group_border,
  },
  [PATENTS_ASSESSMENT_GROUP_ID]: {
    background: sg.patents_assessment_group,
    light_background: sg.patents_assessment_group_light_bg,
    text: sg.patents_assessment_group_text,
    border: sg.patents_assessment_group_border,
  },
  [DATA_INTEGRATION_GROUP_ID]: {
    background: sg.data_integration_group,
    light_background: sg.data_integration_group_light_bg,
    text: sg.data_integration_group_text,
    border: sg.data_integration_group_border,
  },
}