import React, {useState} from 'react'
import {
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown
} from 'reactstrap'
import {AxiosError, AxiosResponse} from 'axios'
import cn from 'classnames'
import _ from 'underscore'

import {withUser} from '../UserContext.js'
import {PrimaryButton} from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import Tooltip from '../widgets/Tooltip.js'
import {InfoIcon} from '../widgets/IconSet.js'
import ReportNameInput from '../ReportNameInput.js'
import {ScrollModal} from '../widgets/Modal.js'
import ErrorModal from '../ErrorModal.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import {PaneHeader} from '../widgets/PaneHeader'

import {GroupingDropdownItem, portfolio_group_dropdown_items, tech_group_dropdown_items} from './model'
import {track_report_builder_event} from '../../utils/tracking_utils.js'
import {remove_not_allowed_chars_from_text} from '../../utils/name_utils.js'
import {
  GROUP_MODES,
  queue_ocypod_job,
  PostJobRequest,
  SetTheoryReportPayload,
} from '../../utils/ocypod_utils'
import {ObjectMap} from 'Cipher'

import s from './SetTheoryReportBuilderModal.module.scss'

interface SetTheoryReportBuilderOptionsModalProps {
  user: any,
  on_hide: Function,
  is_open: boolean,
  formula: string,
  operands: ObjectMap<string>,
  group_tech_mode: GROUP_MODES,
  group_portfolio_mode: GROUP_MODES,
  benchmarking_view: boolean
}
const SetTheoryReportBuilderOptionsModal = (props: SetTheoryReportBuilderOptionsModalProps) => {
  const QUEUEING_REPORT_TEXT: string = 'Sending report request'
  const FORMULA_USAGE_TEXT: string = 'Use results from formula calculation'
  const QUEUE_NAME: string = 'sts-reports'

  const [new_report_title, set_new_report_title] = useState<string>('')
  const [group_tech_mode, set_group_tech_mode] = useState<GROUP_MODES>(props.group_tech_mode)
  const [group_portfolio_mode, set_group_portfolio_mode] = useState<GROUP_MODES>(props.group_portfolio_mode)
  const [group_tech_obj, set_group_tech_obj] = useState<GroupingDropdownItem | undefined>(
    _.find(tech_group_dropdown_items, x => x.mode === props.group_tech_mode)
  )
  const [group_portfolio_obj, set_group_portfolio_obj] = useState<GroupingDropdownItem | undefined>(
    _.find(portfolio_group_dropdown_items, x => x.mode === props.group_portfolio_mode)
  )
  const [operable_by_tech, set_operable_by_tech] = useState<boolean>(false)
  const [operable_by_portfolio, set_operable_by_portfolio] = useState<boolean>(false)

  const [queue_report_flag, set_queue_report_flag] = useState<boolean>(false)
  const [error_message, set_error_message] = useState<string | null>(null)

  function on_report_name_input_change(value: string): void {
    set_new_report_title(remove_not_allowed_chars_from_text(value))
  }

  function is_form_valid(): boolean {
    return !!new_report_title
  }

  function send_to_queue(): Promise<AxiosResponse | void> {
    track_report_builder_event('obj="queue_set_theory_report" action="request"')
    set_queue_report_flag(true)

    const input: SetTheoryReportPayload = {
      operands: props.operands,
      report_name: new_report_title,
      owner_id: props.user.user_id,
      group_tech_mode: group_tech_mode,
      group_portfolio_mode: group_portfolio_mode,
      formula: props.formula,
      operable_selection: {
        by_tech: operable_by_tech,
        by_portfolio: operable_by_portfolio
      },
      use_new_choreo: true
    }

    const payload: PostJobRequest = { input }

    return queue_ocypod_job(payload, QUEUE_NAME)
      .catch((err: AxiosError) => set_error_message(err.message))
      .then(() => {
        set_queue_report_flag(false)
        props.on_hide()
      })
  }

  function build_modal_footer(): JSX.Element {
    return (
      <div className={cn('m-0 mt-3 mb-3')}>
        <PrimaryButton onClick={()=> send_to_queue()} disabled={!is_form_valid()} className='w-100'>
          {!queue_report_flag ? (
            <div>Run Report</div>
          ) : (
            <div className='m-auto w-auto d-flex justify-content-center align-items-center'>
              <Spinner size='sm'/>
              {QUEUEING_REPORT_TEXT}
            </div>
          )}
        </PrimaryButton>
      </div>
    )
  }

  function render(): JSX.Element {
    if (error_message) {
      return (
        // @ts-expect-error
        <ErrorModal error={error_message}
                    on_hide={() => set_error_message(null)}
                    context={'building set theory report'}
        />
      )
    } else {
      return (
        // @ts-expect-error
        <ScrollModal
          on_hide={props.on_hide}
          footer={build_modal_footer()}
          title='Report options'
          is_open={props.is_open}
          className='h-50'
        >
          <div className='d-flex flex-column h-100'>
            <ReportNameInput
              report_name={new_report_title}
              on_change={on_report_name_input_change}
              on_key_up={null}
              className={cn(s.report_name_input, 'mb-1')}
            />
            <div className='m-0 mt-2 d-flex flex-column justify-content-start align-items-start'>
              {/*//@ts-expect-error*/}
              <PaneHeader text={'Technology grouping'} className={cn('mr-1', s.label)}/>
              <div className='d-flex flex-row'>
                {/*//@ts-expect-error*/}
                <CheckboxStatic
                  onClick={() => set_operable_by_tech(!operable_by_tech)}
                  is_checked={operable_by_tech}
                  is_disabled={props.benchmarking_view}
                />
                <div className={cn('ml-1')}>{FORMULA_USAGE_TEXT}</div>
              </div>
              {!operable_by_tech &&
                <UncontrolledDropdown size='md'>
                  <SelectDropdownToggleStatic
                    className={cn('rounded-0', s.dropdown_button)}
                    disabled={props.benchmarking_view}
                  >
                    {group_tech_obj?.name}
                  </SelectDropdownToggleStatic>
                  <DropdownMenu>
                    {tech_group_dropdown_items.map((obj: GroupingDropdownItem, idx: number) =>
                      <DropdownItem key={idx}
                                    onClick={() => {
                                      set_group_tech_mode(obj.mode)
                                      set_group_tech_obj(obj)
                                    }}
                                    active={group_tech_mode === tech_group_dropdown_items[idx].mode}
                                    className={cn('d-flex flex-row flex-nowrap justify-content-between',
                                      group_tech_mode === tech_group_dropdown_items[idx].mode ? s.item_active : '')
                                    }
                      >
                        {obj.name}
                        {/*// @ts-expect-error*/}
                        <Tooltip toggler={<span className={`my-auto ml-1`}><InfoIcon /></span>}
                                 is_in_modal={true}
                                 placement={'bottom'}>
                          {obj.info}
                        </Tooltip>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </div>
            <div className='m-0 mt-2 d-flex flex-column justify-content-start align-items-start'>
              {/*//@ts-expect-error*/}
              <PaneHeader text={'Portfolio grouping'} className={cn('mr-1', s.label)}/>
              <div className='d-flex flex-row'>
                {/*//@ts-expect-error*/}
                <CheckboxStatic
                  onClick={() => set_operable_by_portfolio(!operable_by_portfolio)}
                  is_checked={operable_by_portfolio}
                  is_disabled={props.benchmarking_view}
                />
                <div className={cn('ml-1')}>{FORMULA_USAGE_TEXT}</div>
              </div>
              {!operable_by_portfolio &&
                <UncontrolledDropdown size='md'>
                  <SelectDropdownToggleStatic
                    className={cn('rounded-0', s.dropdown_button)}
                    disabled={props.benchmarking_view}
                  >
                    {group_portfolio_obj?.name}
                  </SelectDropdownToggleStatic>
                  <DropdownMenu>
                    {portfolio_group_dropdown_items.map((obj: GroupingDropdownItem, idx: number) =>
                      <DropdownItem key={idx}
                                    onClick={() => {
                                      set_group_portfolio_mode(obj.mode)
                                      set_group_portfolio_obj(obj)
                                    }}
                                    active={group_portfolio_mode === portfolio_group_dropdown_items[idx].mode}
                                    className={cn('d-flex flex-row flex-nowrap justify-content-between',
                                      group_portfolio_mode === portfolio_group_dropdown_items[idx].mode ? s.item_active : '')
                                    }
                      >
                        {obj.name}
                        {/*// @ts-expect-error*/}
                        <Tooltip toggler={<span className={`my-auto ml-1`}><InfoIcon /></span>}
                                 is_in_modal={true}
                                 placement={'bottom'}>
                          {obj.info}
                        </Tooltip>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </div>
          </div>
        </ScrollModal>
      )
    }
  }
  return render()
}

export default withUser(SetTheoryReportBuilderOptionsModal)