import $ from 'jquery'

const DEFAULT_SCROLL_DURATION = 250 // milliseconds

export function smooth_scroll_to_y(y, scroll_container_element, duration) {
  duration = duration != null ? duration : DEFAULT_SCROLL_DURATION
  const scroll_container = scroll_container_element ? $(scroll_container_element) : $('html, body')
  scroll_container.animate({ scrollTop: y }, duration)
}

export function scroll_to_y(y, scroll_container_element) {
  smooth_scroll_to_y(y, scroll_container_element, 0)
}

export function is_element_vertically_onscreen(el, top_margin, bottom_margin) {
  top_margin = top_margin || 0
  bottom_margin = bottom_margin || 0

  if (!el || !el.getBoundingClientRect) {
    return true
  }

  const rect   = el.getBoundingClientRect()
  const el_top = rect.top
  const el_bottom = rect.top + rect.height

  const v_height = window.innerHeight || document.documentElement.clientHeight

  const min_top = 0 + top_margin
  const max_bottom = v_height - bottom_margin

  return (el_top >= min_top) && (el_bottom <= max_bottom)
}

export function get_scroll_y() {
  // IE11 does not have window.scrollY
  // Safari does not have html.scrollTop
  // Chrome and Firefox have both
  return window.scrollY != null ? window.scrollY : document.documentElement.scrollTop
}