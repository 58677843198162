import React from 'react'
import cn from 'classnames'

import { AddressIcon } from '../widgets/IconSet.js'

import s from './AddressDisplay.module.scss'

const AddressControl = ({address}) => {
  return (
    <div className={cn(['clearfix', {[s.address_wrapper]: address, [s.inactive]: !address}])}>
      <div className='float-left mr-1'><AddressIcon /></div>
      <div>{(address) ? address : '(no address)'}</div>
    </div>
  )
}

export default AddressControl