import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import { get_report_state_url, save_report_state } from '../../utils/viewer_utils.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import { InputGroup, InputGroupAddon } from 'reactstrap'
import { FormFeedback } from '../widgets/FormFeedback.js'
import { ReadOnlyInput } from '../widgets/Input.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import { get_user_idp } from '../../utils/user_info'
import CopyToClipboard from '../widgets/CopyToClipboard.js'

const ShareableLink = ({link}) => {
  const [copy_error, set_copy_error] = useState(null)

  const input_ref = useRef(null)

  function on_copy() {
    track_report_viewer_event('obj="shareable_report_link" action="copy"')
  }

  return (
    <div>
      <span>Copy the link below to share your report:</span>
      <InputGroup>
        <ReadOnlyInput className={cn('d-flex flex-grow-1')} value={link} inner_ref={input_ref} on_focus={(e) => e.target.select()}/>
        <InputGroupAddon addonType='append'>
          <CopyToClipboard btn_size='xs' className='px-2' input_ref={input_ref} on_error={set_copy_error} on_click={on_copy} text_to_copy={link}/>
        </InputGroupAddon>
        {(copy_error != null) &&
          <FormFeedback valid={false} validation_text={'Sorry, link couldn\'t be copied to clipboard but you can still copy it manually'} />
        }
      </InputGroup>
    </div>
  )
}

const ReportShareableLink = ({history, state_id, set_state_id, user_state, external_report_id, share_url}) => {
  const [show_spinner, set_show_spinner] = useState(false)
  const [error, set_error] = useState(null)
  const [idp_meta, set_idp_meta] = useState([])

  useEffect(() => {
    let did_cancel = false
    if (!state_id) {
      set_show_spinner(true)
      save_report_state({external_report_id, user_state})
        .catch(error => {
          if (!did_cancel) {
            set_error(error)
            set_show_spinner(false)
            throw error
          }
        })
        .then(state_id => {
          if (!did_cancel) {
            set_state_id(state_id)
            set_show_spinner(false)
          }
        })
    }

    set_show_spinner(true)
    get_user_idp()
      .then(result => {
        if (!did_cancel){
          set_idp_meta(result)
          set_show_spinner(false)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_error(error)
          set_show_spinner(false)
          throw error
        }
      })

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id])

  function format_shareable_url() {
    const { location } = history
    return get_report_state_url({ location, external_report_id, state_id, idp_meta, share_url })
  }

  return (
    <div>
      {show_spinner &&
        <Spinner />
      }

      {!show_spinner && state_id &&
        <ShareableLink
          link={format_shareable_url()}
        />
      }

      {!show_spinner && error &&
        <ErrorBody
          error={error}
          context='generating report shareable link'
        />
      }
    </div>
  )
}

export default withRouter(ReportShareableLink)