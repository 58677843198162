import React from 'react'
import cn from 'classnames'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import { get_dispute_type_by_id } from '../../model/user_state.js'

import s from './DisputeTypeFilterControl.module.scss'

const DisputeTypeFilterControl = ({spec, available_dispute_types, selected_dispute_type, on_change_selected_dispute_type, className}) => {

  const {filter_by_dispute_type} = spec

  if (!filter_by_dispute_type) {
    return null
  }

  const selected_region_grouping_label = get_dispute_type_by_id(selected_dispute_type).label

  return (
    <UncontrolledDropdown
      className={cn(className)}
    >
      <SelectDropdownToggleStatic
        className={cn(s.dropdown_toggle)}
        labelClassName={s.dropdown_toggle_label}
        chevron_left={false}
      >
        {selected_region_grouping_label}
      </SelectDropdownToggleStatic>

      <DropdownMenu
        right
        className={s.dropdown_menu}
      >
        {available_dispute_types.map((item, i) => (
          <DropdownItem
            className={'d-flex'}
            key={i}
            onClick={() => on_change_selected_dispute_type(item.id)}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default DisputeTypeFilterControl