import React from 'react'
import cn from 'classnames'

import s from './TileContent.module.scss'

export const TileContent = ({children, className, style}) => {

  return (
    <div
      className={cn(s.block, className)}
      style={style}
    >
      {children}
    </div>
  )
}