import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { update_spotlighted_items } from '../../utils/viewer_utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'

import s from './CheckboxGroup.module.scss'

function is_checked(item, selected_item_ids, id_key, next_agglom_selected) {
  const {is_next_agglom} = item

  if (is_next_agglom && next_agglom_selected != null) {
    return next_agglom_selected
  }

  const id = item[id_key]
  return _.contains(selected_item_ids, id)
}

export const LINE_HEIGHT = 25

export const CheckboxWrapper = ({is_next_agglom, is_multiple_pages, on_click, children}) => {
  return (
    <div
      className={cn('d-flex', s.row_container, 'mb-1', {[s.next_agglom_item]: is_next_agglom}, {[s.next_agglom_item__stick_to_end]: is_next_agglom && is_multiple_pages} )}
      onClick={on_click}
    >
      {children}
    </div>
  )
}

export const CheckboxLabel = ({label, search_phrase, selected_to_spotlight, className}) => {
  return (
    <div
      className={cn(s.text__single_line, {[s.label__spotlighted]: selected_to_spotlight}, className)}
      title={label}
    >
      <Highlighter
        search_words={[search_phrase]}
        text_to_highlight={label}
      />
    </div>
  )
}

const CheckboxGroup = (
  {
    items,
    selected_item_ids,
    set_selected_item_ids,
    spotlighted_item_ids,
    set_spotlighted_item_ids,
    id_key,
    search_phrase,
    fixed_page_size,
    next_agglom_item,
    next_agglom_selected,
    toggle_next_agglom_visibility,
    no_rollups,
    is_multiple_pages,
    get_description,
    className
  }) => {

  function on_toggle_item_selection(item) {
    const item_id = item[id_key]
    const is_checked = _.contains(selected_item_ids, item_id)

    const new_selected_item_ids = !is_checked ?
            [...selected_item_ids, item_id]                   // add
            : selected_item_ids.filter(id => id !== item_id)  // remove

    set_selected_item_ids(new_selected_item_ids)
  }

  function on_toggle_item_spotlight(item) {
    const item_id = item[id_key]
    const is_checked = _.contains(selected_item_ids, item_id)

    return update_spotlighted_items(item_id, is_checked, spotlighted_item_ids, set_spotlighted_item_ids)
  }

  function on_item_click(item, e) {

    const { is_next_agglom } = item

    if (is_next_agglom) {
      return toggle_next_agglom_visibility()
    }
    const { altKey } = e

    if (altKey && set_spotlighted_item_ids) {
      e.preventDefault()
      return on_toggle_item_spotlight(item)
    }

    return on_toggle_item_selection(item)
  }

  if (!items) {
    return null
  }

  const container_height = fixed_page_size ? { height: ((fixed_page_size + (next_agglom_item ? 1 : 0)) * LINE_HEIGHT)} : {}

  return (
    <div
      className={cn({'position-relative' : next_agglom_item && fixed_page_size}, className)}
      style={container_height}
    >
      {items.map((item, i) => {

        const { is_disabled, name } = item

        const checked =  is_disabled ? false : is_checked(item, selected_item_ids, id_key, next_agglom_selected)
        const spotlighted = is_disabled || !set_spotlighted_item_ids ? false : _.contains(spotlighted_item_ids || [], item[id_key])

        const description = get_description ? get_description(item) : null

        return (
          <CheckboxWrapper
            key={i}
            on_click={is_disabled ? null : (e) => on_item_click(item, e)}
          >
            <CheckboxStatic
              is_checked={checked}
              className={cn('flex-shrink-0', 'flex-grow-0')} // prevent weird flex behaviour
              is_disabled={is_disabled}
            />

            <CheckboxLabel
              label={name}
              search_phrase={search_phrase}
              selected_to_spotlight={spotlighted}
              className='ml-2 mr-1'
            />

            {description &&
              <InfoPopover wide interactive={true}>{description}</InfoPopover>
            }
          </CheckboxWrapper>
        )
      })}

      {next_agglom_item && !no_rollups &&
        <CheckboxWrapper
          is_next_agglom={true}
          is_multiple_pages={is_multiple_pages}
          on_click={next_agglom_item.is_disabled ? null : (e) => on_item_click(next_agglom_item, e)}
        >
          <CheckboxStatic
            is_checked={next_agglom_item.is_disabled ? false : next_agglom_selected}
            className={cn('flex-shrink-0', 'flex-grow-0')} // prevent weird flex behaviour
            is_disabled={next_agglom_item.is_disabled}
          />
    
          <CheckboxLabel
            label={next_agglom_item.name}
            search_phrase={search_phrase}
            selected_to_spotlight={false}
            className='ml-2 mr-1'
          />
        </CheckboxWrapper>
        
      }
    </div>
  )
}

export default CheckboxGroup