import React from 'react'
import cn from 'classnames'
import {DropdownItem} from 'reactstrap'

import BaseDropdown from '../../widgets/BaseDropdown.js'

import s from './SelectablePublication.module.scss'

export const SelectablePublication = ({options, selected, change_selection, ignore, row_number}) => {

  function get_items(chosen) {
    return options.map((match, i) => {
      return (
        <DropdownItem
          onClick={() => change_selection(row_number, i)}
          key={row_number + '_' + i}
          active={i === chosen}
        >
          {match.serial}
        </DropdownItem>
      )})
  }

  const valid_choice_idx = (selected === -1) ? 0 : selected
  const label = (options && options.length > 0) ? options[valid_choice_idx].serial : 'No options'

  return (
    <BaseDropdown
      label={label}
      className={cn('position-absolute fs-mask')}
      buttonClassName={cn('mb-1', s.dropdown_button, {[s.transparent]: selected === -1})}
      disabled={ignore}
      menuClassName={s.dropdown_menu}
    >
      {get_items(valid_choice_idx)}
    </BaseDropdown>
  )
}