import React from 'react'
import _ from 'underscore'

import {
  STEP_PORTFOLIO_BOOLEAN_SEARCH,
  STEP_PORTFOLIO_ORG_LIST,
  STEP_PORTFOLIO_ORG_SEARCH,
  STEP_PORTFOLIO_TEXT_UPLOAD, STEP_PORTFOLIO_FILE_UPLOAD, STEP_PORTFOLIO_KNN_SEARCH
} from './builder_wizard.js'
import PortfolioUpload from './PortfolioUpload.js'
import PortfolioBooleanSearch from './PortfolioBooleanSearch.js'
import { useToggle } from '../../../hooks/general_hooks.js'
import { ND_REPORT_STREAM_ID, SIMILAR_PORTFOLIOS_REPORT_STREAM_ID } from './report_streams.js'
import { get_boolean_search_as_portfolio_item, get_org_as_portfolio_item } from '../../../model/portfolio_basket.js'
import PortfolioOrgsList from './PortfolioOrgsList.js'
import { get_org_id, is_org_type } from '../../../utils/organisation_utils.js'
import OrgSearchMultiple from '../../orgs/OrgSearchMultiple.js'
import PortfolioSimilarOrgs from './PortfolioSimilarOrgs.js'
import PortfolioOrgSingle from './PortfolioOrgSingle.js'
import PortfolioTechNameSearch from './PortfolioTechNameSearch.js'
import {
  FILE_UPLOAD_MODE_ID,
  TEXT_UPLOAD_MODE_ID
} from '../../../utils/custom_search_utils.js'

const PortfolioStep = (
  {
    is_final,
    stream_id,

    portfolio_type,
    portfolios,
    on_select_portfolios,

    on_complete,

    className
  }) => {

  const [is_search_multiple, toggle_is_search_multiple] = useToggle(stream_id !== ND_REPORT_STREAM_ID)
  const is_multiple_org_search = (STEP_PORTFOLIO_ORG_SEARCH === portfolio_type) && is_search_multiple
  function select_organisations({ add, remove} ) {

    const org_ids_to_remove = (remove || []).map(item => get_org_id(item))

    const selected_portfolios_filtered = org_ids_to_remove.length === 0 ? portfolios : (portfolios || []).filter(item => !is_org_type(item) || (org_ids_to_remove.indexOf(get_org_id(item)) === -1))

    const selected_organisation_ids = (selected_portfolios_filtered || []).filter(item => is_org_type(item)).map(item => get_org_id(item))
    const orgs_to_add = (add || []).filter(item => selected_organisation_ids.indexOf(get_org_id(item)) === -1).map(org => get_org_as_portfolio_item(org))

    on_select_portfolios([...selected_portfolios_filtered || [], ...orgs_to_add], is_multiple_org_search)
  }

  const can_continue= portfolios && portfolios.length > 0
  const PROGRESS_PROPS = {
    is_wizard: true,
    is_wizard_final_step: is_final,
    can_wizard_continue: can_continue,
    on_step_complete: on_complete
  }

  if (_.contains([STEP_PORTFOLIO_TEXT_UPLOAD, STEP_PORTFOLIO_FILE_UPLOAD], portfolio_type)) {

    return (
      <div className={className}>
        <PortfolioUpload
          {...PROGRESS_PROPS}

          available_upload_mode_ids={[STEP_PORTFOLIO_FILE_UPLOAD === portfolio_type ? FILE_UPLOAD_MODE_ID : TEXT_UPLOAD_MODE_ID]}
          on_select_portfolios_handler={(uploaded_portfolios) => {
            const portfolio_to_add = can_continue && uploaded_portfolios == null ? portfolios : uploaded_portfolios

            return on_select_portfolios(portfolio_to_add, true)
          }}
          stream_id={stream_id}
        />
      </div>
    )
  }

  if (STEP_PORTFOLIO_BOOLEAN_SEARCH === portfolio_type) {
    return (
      <div className={className}>
        <PortfolioBooleanSearch
          {...PROGRESS_PROPS}

          on_select_portfolios_handler={(search_phrase, total) => {
            const portfolio_to_add = can_continue && total === 0 ? portfolios : [get_boolean_search_as_portfolio_item(search_phrase)]

            return on_select_portfolios(portfolio_to_add, true)
          }}
        />
      </div>
    )
  }

  if (STEP_PORTFOLIO_KNN_SEARCH === portfolio_type) {
    return (
      <div className={className}>
        <PortfolioTechNameSearch
          on_select_portfolios_handler={(uploaded_portfolios) => {
            const portfolio_to_add = can_continue && uploaded_portfolios == null ? portfolios : uploaded_portfolios

            return on_select_portfolios(portfolio_to_add, true)
          }}
          {...PROGRESS_PROPS}
        />
      </div>
    )
  }

  if ([STEP_PORTFOLIO_ORG_SEARCH, STEP_PORTFOLIO_ORG_LIST].indexOf(portfolio_type) > -1) {
    const is_search_comparables = stream_id === SIMILAR_PORTFOLIOS_REPORT_STREAM_ID

    return (
      <div className={className}>
        {portfolio_type === STEP_PORTFOLIO_ORG_LIST &&
          <PortfolioOrgsList
            select_organisations={select_organisations}

            {...PROGRESS_PROPS}
          />
        }

        {portfolio_type === STEP_PORTFOLIO_ORG_SEARCH && !is_multiple_org_search && !is_search_comparables &&
          <PortfolioOrgSingle
            selected_organisations={portfolios}
            select_organisations={select_organisations}
            on_search_mode_change={toggle_is_search_multiple}

            {...PROGRESS_PROPS}
          />
        }

        {portfolio_type === STEP_PORTFOLIO_ORG_SEARCH && is_multiple_org_search && !is_search_comparables &&
          <OrgSearchMultiple
            portfolio_basket={portfolios}
            update_basket_handler={select_organisations}
            on_search_mode_change={toggle_is_search_multiple}
            enable_remove_action={true}

            {...PROGRESS_PROPS}
          />
        }

        {portfolio_type === STEP_PORTFOLIO_ORG_SEARCH && is_search_comparables &&
          <PortfolioSimilarOrgs
            {...PROGRESS_PROPS}
            on_step_complete={(portfolio_to_add) => on_select_portfolios(portfolio_to_add, true)}
          />
        }
      </div>
    )
  }
}

export default PortfolioStep