import React, { useEffect, useState } from 'react'
import _ from 'underscore'

import OrgSearchModeWrapper from './OrgSearchModeWrapper.js'
import OrgSearchInput from './OrgSearchInput.js'
import { useOrgSearch } from '../../hooks/organisation_hooks.js'
import { COMPARABLES_BY_SIMILAR_SIZE, ORG_SEARCH_CONTEXT } from '../../model/organisation.js'
import OrgSearchResultsDisplay from './OrgSearchResultsDisplay.js'
import ReportBuilderStartScreen from '../builder/ReportBuilderStartScreen.js'
import { is_400_error } from '../../utils/axios_utils.js'
import ErrorModal from '../ErrorModal.js'
import BadSyntaxAlertModal from '../patent_family_list/BadSyntaxAlertModal.js'
import { get_org_suggestions_for_items } from '../../utils/organisation_utils.js'
import Spinner from '../widgets/Spinner.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import OrgSuggestionsHeading from './OrgSuggestionsHeading.js'
import TextLink from '../widgets/TextLink.js'

const OrgSearchIncludeSimilar = ({selected_org, on_org_select_handler, selected_similar_orgs, on_update_selected_similar_orgs_handler, similar_orgs_limit, className}) => {
  const [search_phrase, set_search_phrase] = useState(null)

  const [is_fetching_similar, set_is_fetching_similar] = useState(false)
  const [similar_orgs, set_similar_orgs] = useState(null)
  const [fetch_org_suggestions_error, set_fetch_org_suggestions_error] = useState(null)

  const [show_spinner, results, org_search_error, clear_org_search_error ] = useOrgSearch(search_phrase, false, true)

  useEffect(() => {
    if (!selected_org) {
      set_search_phrase('')
      set_similar_orgs(null)
      return
    }

    set_is_fetching_similar(true)
    get_org_suggestions_for_items([selected_org])
      .then(response => {
        const {[COMPARABLES_BY_SIMILAR_SIZE]: org_suggestions} = response || {}

        const similar_orgs_list = org_suggestions.slice(0, similar_orgs_limit != null ? similar_orgs_limit : 7)

        set_similar_orgs(similar_orgs_list)
        on_update_selected_similar_orgs_handler(similar_orgs_list)
        set_is_fetching_similar(false)
      })
      .catch(error => {
        set_fetch_org_suggestions_error(error)
        set_is_fetching_similar(false)
      })

  }, [selected_org, similar_orgs_limit, on_update_selected_similar_orgs_handler])

  function on_change_from_search_input(new_search_phrase) {
    if (search_phrase === new_search_phrase) {
      return
    }

    set_search_phrase(new_search_phrase)
  }

  const is_bad_syntax_error = is_400_error(org_search_error)

  if (org_search_error && !is_bad_syntax_error) {
    return(
      <ErrorModal
        on_hide={clear_org_search_error}
        error={org_search_error}
        context='fetching organisation results'
      />
    )
  }

  if (is_bad_syntax_error) {
    return(
      <BadSyntaxAlertModal
        on_hide={clear_org_search_error}
      />
    )
  }

  if (fetch_org_suggestions_error) {
    return(
      <ErrorModal
        on_hide={() => {set_fetch_org_suggestions_error(null)}}
        error={fetch_org_suggestions_error}
        context='fetching organisation suggestions'
      />
    )
  }

  function select_all() {
    on_update_selected_similar_orgs_handler(similar_orgs)
  }

  function deselect_all() {
    on_update_selected_similar_orgs_handler([])
  }

  function update_selected_similar_orgs(id) {
    const updated_selected_orgs = _.contains(selected_similar_orgs_ids, id) ?
      selected_similar_orgs.filter(item => item.id !== id) :
      similar_orgs.filter(item => (item.id === id) || _.contains(selected_similar_orgs_ids, item.id))
      on_update_selected_similar_orgs_handler(updated_selected_orgs)
  }

  const has_results = (results && results.length > 0)

  const selected_similar_orgs_ids = (selected_similar_orgs || []).map(item => item.id)

  const is_none_selected = selected_similar_orgs_ids.length === 0

  return (
    <div className={className}>

      {!similar_orgs &&
        <OrgSearchModeWrapper search_mode={'single'}>
          <OrgSearchInput
            on_change_handler={on_change_from_search_input}
            value={search_phrase}
            autofocus
          />
        </OrgSearchModeWrapper>
      }

      {has_results && !is_fetching_similar && !similar_orgs &&
        <OrgSearchResultsDisplay
          results={results}
          selected_organisations={[]}
          show_spinner={show_spinner}
          search_phrase={search_phrase}
          on_result_check_in={on_org_select_handler}
          context={ORG_SEARCH_CONTEXT}
        />
      }

      {!is_fetching_similar && selected_org && similar_orgs &&
        <div>
          {similar_orgs.length === 0 &&
            <div>No organisations were found with portfolios similar to <span className='font-weight-bold'>{selected_org.name}</span></div>
          }

          {similar_orgs.length > 0 &&
            <div className='d-sm-flex mb-2'>
              <OrgSuggestionsHeading
                org_name={selected_org.name}
              />
              <TextLink className='ml-0 ml-sm-2' onClick={is_none_selected ? select_all : deselect_all}>{`${is_none_selected ? 'Select' : 'Deselect'} all`}</TextLink>
            </div>
          }
          {similar_orgs.map(item => {
            const {name, id} = item
            const is_selected = _.contains(selected_similar_orgs_ids, id)
            return (
              <div key={id}>
                <CheckboxAndLabel
                  label={name}
                  is_checked={is_selected}
                  on_click={() => update_selected_similar_orgs(id)}
                  className='mb-1'
                />
              </div>
            )
          })}
        </div>
      }

      {is_fetching_similar &&
        <div>
          <Spinner/>
        </div>
      }

      {!show_spinner && !has_results &&
        <ReportBuilderStartScreen
          text='Enter the name of an organisation to search for similar companies.'
        />
      }

    </div>
  )
}

export default OrgSearchIncludeSimilar