import React, { useState } from 'react'

import BaseDropdown from '../widgets/BaseDropdown.js'
import { Tag } from './family_tag_utils'

import { DropdownItem } from 'reactstrap'
import { TagsDisplay } from './TagsDisplay'

import s from './FamilyTagSelector.module.scss'

interface FamilyTagSelectorProps {
  className: string,
  user_tags:Array<Tag>,
  is_tag_selected: Function,
  is_changing_tags:boolean,
  on_tag_select: Function,
  on_remove_tag: Function,
  search_input: string,
  set_search_input: Function,
  disabled: boolean,
  on_close: Function
}

export const FamilyTagSelector = ({className, user_tags, is_tag_selected, is_changing_tags, on_tag_select, on_remove_tag, search_input, set_search_input, disabled, on_close}: FamilyTagSelectorProps) => {

  const [dropdown_open, set_dropdown_open] = useState(false)

  function on_toggle () {
    if (dropdown_open) {
      on_close()
    }
    set_dropdown_open(!dropdown_open)
  }

  function render(): JSX.Element {
    return (
      <BaseDropdown
        className={className}
        label={'Tags'}
        right={true}
        buttonClassName={s.button}
        menuClassName={s.menu}
        chevron_left={false}
        disabled={disabled}
        on_toggle={on_toggle}
      >
        <DropdownItem toggle={false} className={s.no_shadow}>
          <TagsDisplay
            on_tag_select={on_tag_select}
            is_tag_selected={is_tag_selected}
            user_tags={user_tags || []}
            on_remove_tag={on_remove_tag}
            search_input={search_input}
            set_search_input={set_search_input}
            is_changing={is_changing_tags}
          />
        </DropdownItem>
      </BaseDropdown>
    )
  }
  return render()
}
