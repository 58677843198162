import React, { useEffect, useState } from 'react'
import OrgSetsDisplay from '../../org_sets/OrgSetsDisplay.js'
import Spinner from '../../widgets/Spinner.js'
import { ScrollModal } from '../../widgets/Modal.js'
import InvalidOrganisationsWarning from '../../orgs/InvalidOrganisationsWarning.js'
import ErrorModal from '../../ErrorModal.js'
import { fetch_company_lists } from '../../../utils/company_list_utils.js'
import ProgressPrompt from './ProgressPrompt.js'

const PortfolioOrgsList = (
  {
    select_organisations,
    is_wizard_final_step,
    can_wizard_continue,
    on_step_complete
  }) => {
  const [show_spinner, set_show_spinner] = useState(false)
  const [user_company_lists, set_user_company_lists] = useState(null)
  const [invalid_portfolios, set_invalid_portfolios] = useState(null)

  const [fetch_user_company_lists_error, set_fetch_user_company_lists_error] = useState(null)

  useEffect(() => {
    set_show_spinner(true)

    fetch_company_lists()
      .catch((error) => {
        set_fetch_user_company_lists_error(error)
        set_show_spinner(false)
        throw error
      })
      .then(user_company_lists => {
        set_user_company_lists(user_company_lists)
        set_show_spinner(false)
      })
  }, [])

  return (
    <div>
      <ProgressPrompt
        is_wizard_final_step={is_wizard_final_step}
        can_wizard_continue={can_wizard_continue}
        on_step_complete={on_step_complete}
      >
        <span>Search for organisations and add them to the report.</span>
      </ProgressPrompt>


      <div className='mt-3'>
        {show_spinner &&
          <div>
            <Spinner />
          </div>
        }

        {!show_spinner &&
          <OrgSetsDisplay
            add_to_basket_handler={(orgs) => select_organisations({add: orgs})}
            user_company_lists={user_company_lists}
            invalid_portfolios_in_list_handler={(invalid_portfolios) => set_invalid_portfolios(invalid_portfolios)}
          />
        }

        {invalid_portfolios &&
          <ScrollModal on_hide={() => set_invalid_portfolios(null)}>
            <InvalidOrganisationsWarning
              invalid_portfolios={invalid_portfolios}
            />
          </ScrollModal>
        }

        {fetch_user_company_lists_error &&
          <ErrorModal
            on_hide={() => set_fetch_user_company_lists_error(null)}
            error={fetch_user_company_lists_error}
            context='fetching user created org lists'
          />
        }
      </div>
    </div>
  )
}

export default PortfolioOrgsList