import React from 'react'
import cn from 'classnames'

import {
  AddNewIcon,
  AddNewUserIcon,
  AddressIcon, AddToClipboardIcon,
  AddToReportIcon, AreaViewIcon,
  BackwardIcon, BellIcon,
  BlockedIcon, BubbleViewIcon, CardsViewIcon,
  ChangesIcon,
  CheckIcon, ChevronCircleRightIcon,
  ChevronRightIcon, CogIcon,
  ColourPaletteIcon, ColumnsClusterViewIcon, ColumnsStackViewIcon, ConfigureIcon,
  CrossIcon,
  DeckColumnsStackIcon,
  DeckColumnsBoldIcon,
  DeckColumnsLightIcon,
  DeckColsSpotlightIcon,
  DeckColsPosNegSpotlightIcon,
  DeckBarsSpotlightIcon,
  DeckBarsPosNegSpotlightIcon,
  DeckLandscapeBarsIcon,
  DeckLineIcon,
  DeselectDatasetIcon,
  EditIcon, EnlargeIcon,
  ErrorIcon,
  ExclamationIcon,
  ExpandClosedIcon,
  ExpandOpenedIcon, ExportIcon,
  ExternalLinkIcon,
  ExternalLinkIconInline,
  ExtractIcon, EyeBlockedIcon, EyeIcon,
  FastBackwardIcon,
  FastForwardIcon,
  FeedbackIcon,
  FeedbackOutlineIcon, FilterIcon,
  ForwardIcon,
  GoogleSearchIcon, GridIcon, HeatmapViewIcon,
  HistoryIcon,
  IgnoreIcon,
  InfoIcon, InvestigateIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  KeyboardIcon,
  LanguageIcon, LineViewIcon,
  LinkedInIcon, ListIcon,
  LitigationIcon, LoopIcon,
  MailIcon,
  MakeGroupIcon, MapViewIcon, MenuIcon, MinusCircleIcon,
  MoveAsGroupOutIcon, MoveAssigneeUpIcon,
  NewReportFromParamsIcon, NotesIcon, PieViewIcon, PlusCircleIcon,
  PortfolioIcon, PropagateUpIcon,
  QuestionIcon,
  RemoveIcon,
  RemoveLabelsIcon, RepeatInLeftIcon, RepeatInRightIcon, ReportDataIcon,
  TagIcon,
  TagsIcon,
  TagsOutlineIcon,
  RetryIcon,
  RotateLeftIcon,
  RotateRightIcon,
  SaveIcon, ScatterViewDotsIcon, ScatterViewIcon,
  SearchIcon, SelectDatasetIcon,
  ShareIcon, ShieldIcon,
  ShowBasketItemIcon,
  ShowMoreIcon,
  ShowSimilarIcon, ShrinkIcon,
  SimpleErrorIcon,
  SortedADecIconInline,
  SortedAscIconInline,
  StartIcon,
  StepBackwardIcon,
  StepForwardIcon,
  TableViewIcon,
  TabularViewIcon,
  TickIcon,
  ToggleDownIcon,
  ToggleDownIconInline,
  ToggleOffIcon,
  ToggleOnIcon,
  ToggleUpIconInline,
  TorchOffIcon,
  TorchOnIcon,
  TransformResetIcon,
  TrashIcon, TreemapViewIcon,
  TwitterIcon,
  UndoIcon, UndoIgnoreIcon,
  UnsaveIcon,
  UserIcon,
  WikipediaIcon,
  ZoomInIcon,
  ZoomOutIcon, BarsIcon
} from './widgets/IconSet.js'

import s from './IconFinder.module.scss'

const IconInfo = ({Icon, component_name, library, used_for}) => {
  const is_svg = library === 'SVG'

  return (
    <div className={cn('d-flex mb-1 px-2 py-1', s.icon_info)}>
      <div className={cn(s.icon)}><Icon className={cn({[s.icon__svg]: is_svg})} /></div>
      <div className={s.component_name}>{component_name}</div>
      <div className={s.library}>{library}</div>
      <div>{used_for}</div>
    </div>
  )
}

const IconFinder = () => {
  return (
    <div className={cn('mx-auto d-flex flex-wrap my-2', s.wrapper)}>
      <IconInfo
        Icon={StartIcon}
        component_name='StartIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={AddressIcon}
        component_name='AddressIcon'
        library={'IM'}
        used_for='org search'
      />
      <IconInfo
        Icon={AddToReportIcon}
        component_name='AddToReportIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={CheckIcon}
        component_name='CheckIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={RemoveIcon}
        component_name='RemoveFromReportIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={ForwardIcon}
        component_name='ForwardIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={BackwardIcon}
        component_name='BackwardIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={FastForwardIcon}
        component_name='FastForwardIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={FastBackwardIcon}
        component_name='FastBackwardIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={StepForwardIcon}
        component_name='StepForwardIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={StepBackwardIcon}
        component_name='StepBackwardIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={MailIcon}
        component_name='MailIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={PortfolioIcon}
        component_name='PortfolioIcon'
        library={'IM'}
        used_for={'org search'}
      />
      <IconInfo
        Icon={ToggleOnIcon}
        component_name='ToggleOnIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={ToggleOffIcon}
        component_name='ToggleOffIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={ToggleDownIcon}
        component_name='ToggleDownIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ToggleDownIconInline}
        component_name='ToggleDownIconInline'
        library={'SVG'}
      />
      <IconInfo
        Icon={ToggleUpIconInline}
        component_name='ToggleUpIconInline'
        library={'SVG'}
      />
      <IconInfo
        Icon={LitigationIcon}
        component_name='PortfolioIcon'
        library={'IM'}
        used_for={'org search'}
      />
      <IconInfo
        Icon={LinkedInIcon}
        component_name='LinkedInIcon'
        library={'IM'}
        used_for={'footer'}
      />
      <IconInfo
        Icon={TwitterIcon}
        component_name='TwitterIcon'
        library={'IM'}
        used_for={'footer'}
      />
      <IconInfo
        Icon={InfoIcon}
        component_name='InfoIcon'
        library={'FA'}
        used_for={'header, dashboard tiles'}
      />
      <IconInfo
        Icon={ShowBasketItemIcon}
        component_name='ShowBasketItemIcon'
        library={'IM'}
        used_for={'report builder basket'}
      />
      <IconInfo
        Icon={ShowSimilarIcon}
        component_name='ShowSimilarIcon'
        library={'IM'}
        used_for={'report builder basket'}
      />
      <IconInfo
        Icon={NewReportFromParamsIcon}
        component_name='NewReportFromParamsIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ShareIcon}
        component_name='ShareIcon'
        library={'IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={HistoryIcon}
        component_name='HistoryIcon'
        library={'IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={ChangesIcon}
        component_name='ChangesIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={FeedbackIcon}
        component_name='FeedbackIcon'
        library={'IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={FeedbackOutlineIcon}
        component_name='FeedbackOutlineIcon'
        library={'FA'}
        used_for={'dashboard tiles'}
      />
      <IconInfo
        Icon={UserIcon}
        component_name='UserIcon'
        library={'IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={EditIcon}
        component_name='EditIcon'
        library={'IM'}
        used_for={'report (title)'}
      />
      <IconInfo
        Icon={TrashIcon}
        component_name='TrashIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={UndoIcon}
        component_name='UndoIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={ShowMoreIcon}
        component_name='ShowMoreIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={SaveIcon}
        component_name='SaveIcon'
        library={'FA'}
        used_for={'report header'}
      />
      <IconInfo
        Icon={UnsaveIcon}
        component_name='UnsaveIcon'
        library={'FA'}
        used_for={'report header'}
      />
      <IconInfo
        Icon={TagIcon}
        component_name='TagIcon'
        library={'FA'}
        used_for={'report header, history'}
      />
      <IconInfo
        Icon={TagsIcon}
        component_name='TagsIcon'
        library={'FA'}
        used_for={'report header, history, family tagging'}
      />
      <IconInfo
        Icon={TagsOutlineIcon}
        component_name='TagsOutlineIcon'
        library={'FA'}
        used_for={'family tagging, AG UI'}
      />
      <IconInfo
        Icon={RetryIcon}
        component_name='RetryIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={SortedAscIconInline}
        component_name='SortedAscIconInline'
        library={'SVG'}
      />
      <IconInfo
        Icon={SortedADecIconInline}
        component_name='SortedADecIconInline'
        library={'SVG'}
      />
      <IconInfo
        Icon={AddNewUserIcon}
        component_name='AddNewUserIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={AddNewIcon}
        component_name='AddNewIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ErrorIcon}
        component_name='ErrorIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ExternalLinkIcon}
        component_name='ExternalLinkIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ExternalLinkIconInline}
        component_name='ExternalLinkIconInline'
        library={'SVG'}
      />
      <IconInfo
        Icon={ExpandClosedIcon}
        component_name='ExpandClosedIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ExpandOpenedIcon}
        component_name='ExpandOpenedIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ChevronRightIcon}
        component_name='ChevronRightIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ZoomInIcon}
        component_name='ZoomInIcon'
        library={'FA'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={ZoomOutIcon}
        component_name='ZoomOutIcon'
        library={'FA'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={RotateRightIcon}
        component_name='RotateRightIcon'
        library={'FA'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={RotateLeftIcon}
        component_name='RotateLeftIcon'
        library={'FA'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={TransformResetIcon}
        component_name='TransformResetIcon'
        library={'FA'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={ColourPaletteIcon}
        component_name='ColourPaletteIcon'
        library={'FA'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={TickIcon}
        component_name='TickIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={CrossIcon}
        component_name='CrossIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={BlockedIcon}
        component_name='BlockedIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={RemoveLabelsIcon}
        component_name='RemoveLabelsIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={ExclamationIcon}
        component_name='ExclamationIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={QuestionIcon}
        component_name='QuestionIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={SimpleErrorIcon}
        component_name='SimpleErrorIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={SearchIcon}
        component_name='SearchIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={TorchOnIcon}
        component_name='TorchOnIcon'
        library={'SVG'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={TorchOffIcon}
        component_name='TorchOffIcon'
        library={'SVG'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={KeyboardArrowLeftIcon}
        component_name='KeyboardArrowLeftIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={KeyboardArrowRightIcon}
        component_name='KeyboardArrowRightIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={KeyboardIcon}
        component_name='KeyboardIcon'
        library={'SVG'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={MoveAsGroupOutIcon}
        component_name='MoveAsGroupOutIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={TableViewIcon}
        component_name='TableViewIcon'
        library={'SVG'}
      />
      <IconInfo
        Icon={MakeGroupIcon}
        component_name='MakeGroupIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={GoogleSearchIcon}
        component_name='GoogleSearchIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={WikipediaIcon}
        component_name='WikipediaIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={LanguageIcon}
        component_name='LanguageIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={ExtractIcon}
        component_name='ExtractIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={BellIcon}
        component_name='BellIcon'
        library={'IM'}
      />
      <IconInfo
        Icon={ShieldIcon}
        component_name='ShieldIcon'
        library={'IM'}
        used_for={'report basket (warning info)'}
      />
      <IconInfo
        Icon={RepeatInLeftIcon}
        component_name='RepeatInLeftIcon'
        library={'FA'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={RepeatInRightIcon}
        component_name='RepeatInRightIcon'
        library={'FA'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={CardsViewIcon}
        component_name='CardsViewIcon'
        library={'SVG'}
        used_for={'chart view (families as cards)'}
      />
      <IconInfo
        Icon={ColumnsStackViewIcon}
        component_name='ColumnsStackViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={ColumnsClusterViewIcon}
        component_name='ColumnsClusterViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={PieViewIcon}
        component_name='PieViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={HeatmapViewIcon}
        component_name='HeatmapViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={LineViewIcon}
        component_name='LineViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={TabularViewIcon}
        component_name='TabularViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={TreemapViewIcon}
        component_name='TreemapViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={MapViewIcon}
        component_name='MapViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={BubbleViewIcon}
        component_name='BubbleViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={AreaViewIcon}
        component_name='AreaViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={ScatterViewIcon}
        component_name='ScatterViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={ScatterViewDotsIcon}
        component_name='ScatterViewDotsIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={DeckColumnsStackIcon}
        component_name='DeckColumnsStackIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColumnsBoldIcon}
        component_name='DeckColumnsBoldIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColumnsLightIcon}
        component_name='DeckColumnsLightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColsSpotlightIcon}
        component_name='DeckColsSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColsPosNegSpotlightIcon}
        component_name='DeckColsPosNegSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckBarsSpotlightIcon}
        component_name='DeckBarsSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckBarsPosNegSpotlightIcon}
        component_name='DeckBarsPosNegSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckLandscapeBarsIcon}
        component_name='DeckLandscapeBarsIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckLineIcon}
        component_name='DeckLineIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={EyeIcon}
        component_name='EyeIcon'
        library={'IM'}
        used_for={'next xx series'}
      />
      <IconInfo
        Icon={EyeBlockedIcon}
        component_name='EyeBlockedIcon'
        library={'IM'}
        used_for={'next xx series'}
      />
      <IconInfo
        Icon={ConfigureIcon}
        component_name='ConfigureIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={InvestigateIcon}
        component_name='InvestigateIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={ReportDataIcon}
        component_name='ReportDataIcon'
        library={'FA'}
        used_for={'report monitor'}
      />
      <IconInfo
        Icon={FilterIcon}
        component_name='FilterIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={GridIcon}
        component_name='GridIcon'
        library={'FA'}
        used_for={'report (grid link)'}
      />
      <IconInfo
        Icon={ChevronCircleRightIcon}
        component_name='ChevronCircleRightIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={PlusCircleIcon}
        component_name='PlusCircleIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={MinusCircleIcon}
        component_name='MinusCircleIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={NotesIcon}
        component_name='NotesIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={MoveAssigneeUpIcon}
        component_name='MoveAssigneeUpIcon'
        library={'FA'}
        used_for={'AG tool'}
      />

      <IconInfo
        Icon={PropagateUpIcon}
        component_name='PropagateUpIcon'
        library={'FA'}
        used_for={'report (copy to global)'}
      />
      <IconInfo
        Icon={ListIcon}
        component_name='ListIcon'
        library={'FA'}
        used_for={'report (list view link)'}
      />
      <IconInfo
        Icon={ExportIcon}
        component_name='ExportIcon'
        library={'FA'}
        used_for={'report (exports link)'}
      />
      <IconInfo
        Icon={IgnoreIcon}
        component_name='IgnoreIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={UndoIgnoreIcon}
        component_name='UndoIgnoreIcon'
        library={'FA'}
      />
      <IconInfo
        Icon={LoopIcon}
        component_name='LoopIcon'
        library={'IM'}
        used_for={'report (refresh thumbnails)'}
      />
      <IconInfo
        Icon={CogIcon}
        component_name='CogIcon'
        library={'IM'}
        used_for={'report header'}
      />
      <IconInfo
        Icon={ShrinkIcon}
        component_name='ShrinkIcon'
        library={'IM'}
        used_for={'report (group)'}
      />
      <IconInfo
        Icon={EnlargeIcon}
        component_name='EnlargeIcon'
        library={'IM'}
        used_for={'report (group)'}
      />
      <IconInfo
        Icon={SelectDatasetIcon}
        component_name='SelectDatasetIcon'
        library={'IM'}
        used_for={'report (thumbnail)'}
      />
      <IconInfo
        Icon={DeselectDatasetIcon}
        component_name='DeselectDatasetIcon'
        library={'IM'}
        used_for={'report (thumbnail)'}
      />
      <IconInfo
        Icon={MenuIcon}
        component_name='MenuIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={AddToClipboardIcon}
        component_name='AddToClipboardIcon'
        library={'IM'}
        used_for={'shareable link modal'}
      />
      <IconInfo
        Icon={BarsIcon}
        component_name='BarsIcon'
        library={'FA'}
        used_for={'benchmarking UI - header'}
      />
    </div>
  )
}

export default IconFinder