import React from 'react'
import cn from 'classnames'

import { PaneHeader } from '../widgets/PaneHeader.js'
import { calculate_duration, format_duration } from '../../utils/utils.js'
import { format_status_with_role } from '../../utils/disputes_utils.js'
import { PartiesPanel } from './PartiesPanel.js'
import { PatentsPanel } from './PatentsPanel.js'
import { AttachmentsPanel } from './AttachmentsPanel.js'
import { ROLE_DEFENDANT, ROLE_PLAINTIFF } from '../../model/dispute_roles.js'
import { has_restricted_family_fields } from '../../utils/user_permissions.js'
import { PATENT_LINK_AS_GOOGLE_OPTION_ID } from '../../model/patent_links.js'
import { get_patent_link_option_id_preference } from '../../utils/patent_family_list_utils.js'

import s from './Dispute.module.scss'

export const Dispute = ({ is_page_view, user, title, docket_number, start_date, end_date, jurisdiction, route, outcome, parties, patents, attachments, dispute_role, allow_public_access}) => {

  const defendant_parties = parties.filter(party => (party.role === ROLE_DEFENDANT))
  const plaintiff_parties = parties.filter(party => (party.role === ROLE_PLAINTIFF))

  const hide_restricted_fields = has_restricted_family_fields(user)

  return (
    <div>

      {is_page_view &&
        <div className={s.title}>
          {title || ''}
        </div>
      }

      <div className={cn('d-flex justify-content-between', {[s.panel]: is_page_view},)}>
        {!hide_restricted_fields &&
        <div>
          <PaneHeader text='Docket'/>
          <div>{docket_number || ''}</div>
        </div>
        }
        <div>
          <PaneHeader text='Start date'/>
          <div>{start_date || ''}</div>
        </div>
        <div>
          <PaneHeader text='End date'/>
          <div>{end_date || ''}</div>
        </div>
        <div>
          <PaneHeader text='Duration'/>
          <div>{format_duration(calculate_duration(start_date, end_date))}</div>
        </div>
        <div>
          <PaneHeader text='Jurisdiction'/>
          <div>{jurisdiction || ''}</div>
        </div>
        <div>
          <PaneHeader text='Route'/>
          <div>{route || ''}</div>
        </div>

        {!is_page_view &&
          <div>
            <PaneHeader text='Outcome'/>
            <div>{format_status_with_role(outcome || '', dispute_role)}</div>
          </div>
        }
      </div>

      {plaintiff_parties.length > 0 &&
        <div className={s.panel}>
          <PartiesPanel title='Plaintiffs' parties={plaintiff_parties}/>
        </div>
      }

      {defendant_parties.length > 0 &&
        <div className={s.panel}>
          <PartiesPanel title='Defendants' parties={defendant_parties}/>
        </div>
      }

      {!hide_restricted_fields && patents.length > 0 &&
        <div className={s.panel}>
          <PatentsPanel title='Patents' patents={patents} patent_link_mode_id={allow_public_access ? PATENT_LINK_AS_GOOGLE_OPTION_ID : get_patent_link_option_id_preference()}/>
        </div>
      }

      {attachments.length > 0 &&
        <div className={s.panel}>
          <AttachmentsPanel title='Documents' attachments={attachments} show_document_link={allow_public_access ? false : true}/>
        </div>
      }

    </div>
  )
}