import React from 'react'
import * as d3 from 'd3'
import _ from 'underscore'
import cn from 'classnames'

import { KEY_COLUMN_IDXS_2D } from '../../model/column_data.js'

import {
  get_keys_to_value,
  get_value,
  get_value_column_idx,
  IS_NEXT_AGGLOM,
  get_display_value,
  get_subselections
} from '../../utils/column_data_utils.js'
import { sum } from '../../utils/utils.js'
import { to_hex_string } from '../../utils/color_utils.js'
import {
  is_data_transposed,
  is_report_series_sort_by_size
} from '../../utils/report_utils.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import {
  get_data_orientation,
  get_should_display_all_zeros_series,
  should_hide_next_agglom_item,
  THUMBNAIL_MAX_SERIES
} from '../../utils/main_items_utils.js'
import { CIPHER_BLUE } from '../../constants/colours.js'

import s from './Heatmap.module.scss'

const X_COLUMN_IDX = KEY_COLUMN_IDXS_2D[0]
const Y_COLUMN_IDX = KEY_COLUMN_IDXS_2D[1]

const LIGHTEST_BG_COLOUR = '#ffffff'
const DARKEST_BG_COLOUR = CIPHER_BLUE

const TOTAL_LABEL = 'TOTAL'

function get_export_numeric_value(value, value_rounder, no_value_label) {
  if (value == null && no_value_label) {
    return null
  }

  if (value_rounder && _.isNumber(value)) {
    return value_rounder(value)
  }

  return value || 0
}

/**
 * Used by data export functions (i.e. csv, excel, powerpoint etc)
 */
function get_data({ spec, data, key_dims, item, report_series_sort }) {
  const { x_items_sorted, y_items_sorted, keys_to_value, num_unique_values, bg_color_fn, dark_thresh } = get_processed_data({ spec, data, key_dims, item, report_series_sort })

  const { value_rounder, no_value_label } = spec

  // xs
  const xs = x_items_sorted.map(item => item.name)
  const ys = y_items_sorted.map(item => item.name)

  // Get empty 2d arrays
  const values = x_items_sorted.map(() => [])
  const bg_colors = x_items_sorted.map(() => [])
  const colors = x_items_sorted.map(() => [])

  // Fill the 2d arrays
  x_items_sorted.forEach((x_item, i) => {
    y_items_sorted.forEach((y_item, j) => {
      const value          = get_value(keys_to_value, [x_item.id, y_item.id], no_value_label) // may be null

      const is_next_agglom = get_is_next_agglom(x_item, y_item)
      const bg_color       = get_bg_color(value || 0, bg_color_fn, is_next_agglom, num_unique_values)
      const color          = get_color(value || 0, bg_color_fn, is_next_agglom, dark_thresh, num_unique_values)
      const bg_color_hex   = to_hex_string(bg_color)
      const color_hex      = to_hex_string(color)

      const export_value = get_export_numeric_value(value, value_rounder, no_value_label)
      values[i][j]    = export_value // may be null
      bg_colors[i][j] = bg_color_hex
      colors[i][j]    = color_hex
    })
  })

  return {
    x: xs,
    y: ys,
    z: values,
    z_bg_color: bg_colors,
    z_color: colors
  }
}

function get_plotly_data({ spec, data, key_dims, item, report_series_sort }) {
  const { value_rounder, hide_totals, hide_row_totals } = spec

  const { x, y, z, z_bg_color, z_color } = get_data({ spec, data, key_dims, item, report_series_sort })

  const can_show_row_totals = !(hide_totals || hide_row_totals)

  const x_and_total =  [...x, ...(can_show_row_totals ? [TOTAL_LABEL] : [])]
  const y_and_total =  [...y, ...(!hide_totals ? [TOTAL_LABEL] : [])]

  const values_with_totals = z.map(item => ([...item, ...(!hide_totals ? [sum(item)] : [])])) // 2d array

  const totals = y.map((item_y, j) => {
    const y_values = x.map((item_x, i) => (z[i][j]))
    return sum(y_values)
  })

  const bg_color = [
    ...z_bg_color.map(item => ([...item, LIGHTEST_BG_COLOUR])),
    y_and_total.map(() => (LIGHTEST_BG_COLOUR))
  ]

  const color = [
    ...z_color.map(item => ([...item, null])),
    y_and_total.map(() => (null))
  ]

  const z_values = [
    ...values_with_totals,
    ...(can_show_row_totals ? [[...totals, sum(totals)]] : [])
  ] // 2d array

  const z_values_rounded = !value_rounder ? z_values : z_values.map(zs => zs.map(z => value_rounder(z)))

  return {
    x: x_and_total,
    y: y_and_total,
    z: z_values_rounded,
    z_bg_color: bg_color,
    z_color: color
  }
}

function get_axis_titles(spec) {
  const { column_names } = spec

  const x_axis_title = column_names[0]
  const y_axis_title = column_names[1]

  return { y_axis_title, x_axis_title }
}


function get_csv_value_rows({ spec, data, key_dims, item, report_series_sort }) {
  const { value_rounder, hide_totals } = spec

  const { x, y, z } = get_data({ spec, data, key_dims, item, report_series_sort })

  if (!x.length || !y.length || !z.length) {
    return []
  }

  const values_rounded = !value_rounder ? z : z.map(zs => zs.map(z_value => value_rounder(z_value)))

  const header_row = ['', ...x, ...(!hide_totals ? [TOTAL_LABEL] : [])]

  const rows = y.map((y_label, j) => {
    const values = x.map((x_label, i) => {
      return values_rounded[i][j]
    })
    const row_header = y[j]
    return [row_header, ...values, ...(!hide_totals ? [sum(values)] : [])]
  })

  const totals = x.map((item, i) => (sum(values_rounded[i])))

  const totals_row = [TOTAL_LABEL, ...totals, sum(totals)]

  return [header_row, ...rows, ...(!hide_totals ? [totals_row] : [])]
}

function get_is_next_agglom(x_item, y_item) {
  return x_item[IS_NEXT_AGGLOM] || y_item[IS_NEXT_AGGLOM]
}

function get_bg_color(value, bg_color_fn, is_next_agglom, num_unique_values) {
  if (num_unique_values === 1) {
    // If only one value, show it as dark (otherwise thumbnail seems to be empty)
    return DARKEST_BG_COLOUR
  }
  return is_next_agglom ? null : bg_color_fn(value || 0)
}

function get_color(value, bg_color_fn, is_next_agglom, dark_thresh, num_unique_values) {
  if (num_unique_values === 1) {
    // If only one value, show background as dark (otherwise thumbnail seems to be empty)
    return LIGHTEST_BG_COLOUR
  }
  return is_next_agglom ? null : ((value && (value > dark_thresh)) ? bg_color_fn(0) : null)
}

function get_processed_data({ spec, item, data, key_dims, report_series_sort, report_region_column, is_thumbnail }) {
  const { no_value_label } = spec
  const should_transpose_data = is_data_transposed(get_data_orientation(item))
  const should_display_all_zeros_series = get_should_display_all_zeros_series(item)

  const { timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs, value_formatter } = spec

  const x_column_id_applied = !should_transpose_data ? X_COLUMN_IDX : Y_COLUMN_IDX
  const y_column_id_applied = !should_transpose_data ? Y_COLUMN_IDX : X_COLUMN_IDX

  const hide_next_x = should_hide_next_agglom_item(spec, item, x_column_id_applied)
  const hide_next_y = should_hide_next_agglom_item(spec, item, y_column_id_applied)
  const key = get_key_from_spec(spec, report_region_column)

  const [ x_key, y_key ] = key

  const x_items = key_dims[x_column_id_applied]
  const y_items = key_dims[y_column_id_applied]

  const is_x_items_timeseries = _.contains(timeseries_key_dim_idxs, x_column_id_applied)
  const is_y_items_timeseries = _.contains(timeseries_key_dim_idxs, y_column_id_applied)

  const is_x_items_histogram = _.contains(histogram_bucket_key_dim_idxs, x_column_id_applied)
  const is_y_items_histogram = _.contains(histogram_bucket_key_dim_idxs, y_column_id_applied)

  const value_column_idx = get_value_column_idx(data)
  
  // Get key_to_value
  const keys_to_value = get_keys_to_value(data, [x_column_id_applied, y_column_id_applied], value_column_idx, spec.show_average)

  // Get values excluding "next" (we want to exclude "next" from colouring)
  const x_items_filtered = hide_next_x ? x_items.filter(item => !item[IS_NEXT_AGGLOM]) : x_items
  const y_items_filtered = hide_next_y ? y_items.filter(item => !item[IS_NEXT_AGGLOM]) : y_items

  const x_items_with_totals = x_items_filtered.map(x_item => {
    // column total
    const values = y_items_filtered.map(y_item => {
      return get_value(keys_to_value, [x_item.id, y_item.id], no_value_label) || 0
    })
    const total = sum(values)
    return {
      ...x_item,
      total
    }
  }).filter(item => (is_x_items_timeseries || should_display_all_zeros_series) ? true : (item.total !== 0))

  const y_items_with_totals = y_items_filtered.map(y_item => {
    // row total
    const values = x_items_filtered.map(x_item => {
      return get_value(keys_to_value, [x_item.id, y_item.id], no_value_label) || 0
    })
    const total = sum(values)
    return {
      ...y_item,
      total
    }
  }).filter(item => (is_y_items_timeseries || should_display_all_zeros_series) ? true : (item.total !== 0))

  const full_total = sum(x_items_with_totals.map(item => item.total))

  const all_vals_excl_next = []
  x_items_with_totals.forEach(x_item => {
    y_items_with_totals.forEach(y_item => {
      const value = get_value(keys_to_value, [x_item.id, y_item.id], no_value_label)

      if (value !== no_value_label) {
        all_vals_excl_next.push(value)
      }
    })
  })

  // If data is missing values, add a zero
  const combinations = x_items_with_totals.reduce((acc, x_item) => {
    return [
      ...acc,
      ...y_items_with_totals.map(y_item => [x_item, y_item]) // all combinations of [x, y]
    ]
  }, [])
  const is_missing_data = combinations.some(([x_item, y_item]) => {
    return !get_value(keys_to_value, [x_item.id, y_item.id], no_value_label) // returns true if null or 0
  })

  const all_vals_excl_next_with_missing = (is_missing_data && !no_value_label) ? [...all_vals_excl_next, 0] : all_vals_excl_next

  // Colour scheme
  const min_max = d3.extent(all_vals_excl_next_with_missing)

  const bg_color_fn = d3.scaleSqrt()
      .domain(min_max)
      .range([LIGHTEST_BG_COLOUR, DARKEST_BG_COLOUR])
  const range = min_max[1] - min_max[0];
  const dark_thresh = min_max[0] + (range * 0.4)

  // Sort items by totals (unless it's a timeseries or histogram)
  const x_items_sorted = is_x_items_timeseries || is_x_items_histogram || !is_report_series_sort_by_size(report_series_sort) ? x_items_with_totals : _.sortBy(x_items_with_totals, (item) => {
    if (item[IS_NEXT_AGGLOM] || item.is_unrelated) {
      return null // so at end of sorted list
    }
    return -item.total // otherwise sort by total descending
  })
  const y_items_sorted = is_y_items_timeseries || is_y_items_histogram || !is_report_series_sort_by_size(report_series_sort) ? y_items_with_totals : _.sortBy(y_items_with_totals, (item) => {
    if (item[IS_NEXT_AGGLOM] || item.is_unrelated) {
      return null // so at end of sorted list
    }
    return -item.total // otherwise sort by total descending
  })

  // If no_rollups, remove rolled up rows/

  const x_items_sorted_clean = hide_next_x ? x_items_sorted.filter(item => !item[IS_NEXT_AGGLOM]) : x_items_sorted
  const y_items_sorted_clean = hide_next_y ? y_items_sorted.filter(item => !item[IS_NEXT_AGGLOM]) : y_items_sorted

  return {
    x_items_sorted: is_thumbnail ? x_items_sorted_clean.slice(0, THUMBNAIL_MAX_SERIES) : x_items_sorted_clean,
    y_items_sorted: is_thumbnail ? y_items_sorted_clean.slice(0, THUMBNAIL_MAX_SERIES) : y_items_sorted_clean,
    x_key,
    y_key,
    keys_to_value,
    full_total,
    num_unique_values: _.uniq(all_vals_excl_next_with_missing).length,
    bg_color_fn,
    dark_thresh,
    value_formatter
  }
}

const Heatmap = ({ spec, data, key_dims, item, is_thumbnail, set_families_subselections, selections, report_series_sort }) => {
  const {report_region_column} = selections

  const should_transpose_data = is_data_transposed(get_data_orientation(item))

  const { x_items_sorted, y_items_sorted, x_key, y_key, keys_to_value, full_total, num_unique_values, bg_color_fn, dark_thresh,
    value_formatter } = get_processed_data({ spec, data, key_dims, item, report_series_sort, report_region_column, is_thumbnail })

  const { hide_row_totals, hide_totals } = spec
  const show_totals = !hide_totals && !is_thumbnail

  const can_show_row_totals = !is_thumbnail && !(hide_totals || hide_row_totals)

  // For fixed-width to be respected, we need to set width: 100%;
  // But this means that if there are very few columns, they will be stretched.
  // So we set an explicit width here.
  const width = (x_items_sorted.length * 3.75) + 12.5 // i.e. these values should correspond to css classes ".col_header_cell" and "col_header_cell__first"

  const { no_clickthrough, no_value_label } = spec


  if (((x_items_sorted || []).length === 0) || ((y_items_sorted || []).length === 0)) {
    return (
      <div className='my-5 text-center'>No data to display</div>
    )
  }

  return (
    <div className={cn('mb-1', {'overflow-auto': !is_thumbnail}, { [s.block__thumbnail]: is_thumbnail })}>
      <table
        className={s.table}
        style={!is_thumbnail ? {width: width + 'rem', height: '100%'} : null}
      >

        {/* Table Header */}
        {!is_thumbnail &&
          <thead>
            <tr>
              <th className={cn(s.cell, s.cell__col_header, s.cell__col_first)}></th>
              {x_items_sorted.map((x_item, i) => (
                <th
                  key={i}
                  className={cn(s.cell, s.cell__col_header)}
                >
                  {x_item.short_name}
                </th>
              ))}
              <th className={cn(s.cell, s.cell__spacer_column)}></th>
              {can_show_row_totals &&
                <th className={cn(s.cell, s.cell__col_header)}>{TOTAL_LABEL}</th>
              }
            </tr>
          </thead>
        }

        {/* Table Body */}
        <tbody className={s.tbody}>
          {y_items_sorted.map((y_item, iy) => (
            <tr
              key={iy}
            >
              {!is_thumbnail &&
                <th
                  className={cn(s.cell, s.cell__row_header)}
                >
                  {y_item.name}
                </th>
              }
              {x_items_sorted.map((x_item, ix) => {
                const value = get_value(keys_to_value, [x_item.id, y_item.id], no_value_label) // may be null
                const display_value = get_display_value(value, value_formatter, no_value_label)
                const is_next_agglom = get_is_next_agglom(x_item, y_item)
                const bg_color = (value != null) ? get_bg_color(value, bg_color_fn, is_next_agglom, num_unique_values) : null

                const color = (display_value === no_value_label) ? null : get_color(value || 0, bg_color_fn, is_next_agglom, dark_thresh, num_unique_values)

                const tooltip_id = `ds_${spec.id}_${x_item.id}_${y_item.id}`.replace(/[ .]/g, '_')

                return (
                  <td
                    key={ix}
                    className={cn(s.cell, s.cell__value, {[s.cell__thumbnail]: is_thumbnail})}
                    style={{
                      backgroundColor: bg_color,
                      color: color
                    }}
                    id={tooltip_id}
                  >
                    {!is_thumbnail && (
                      value ?
                        <span
                          onClick={() => set_families_subselections(get_subselections(should_transpose_data, {x_key, y_key}, {x_items: [x_item], y_items: [y_item]}), value)}
                          className={cn({ [s.cell__clickthrough]: !no_clickthrough })}
                        >
                          {display_value}
                        </span>
                        : display_value
                    )}
                  </td>
                )
              })}


              {can_show_row_totals &&
                <td
                  className={cn(s.cell, s.cell__value, s.cell__spacer_column)}
                />
              }

              {/* ROW totals */}
              {can_show_row_totals &&
                <td
                  className={cn(s.cell, s.cell__value, s.cell__column_total)}
                >
                  {y_item.total ?
                  <span
                    onClick={() => {
                      set_families_subselections(get_subselections(should_transpose_data, {x_key, y_key}, {x_items: x_items_sorted, y_items: [y_item]}),
                        y_item.total
                      )
                    }}
                    className={cn({ [s.cell__clickthrough]: !no_clickthrough })}
                  >
                    {get_display_value(y_item.total, value_formatter)}
                  </span> : get_display_value(y_item.total, value_formatter)}
                </td>
              }
            </tr>
          ))}

          {/* Spacer row */}
          {show_totals &&
            <tr>
              <th
                className={cn(s.cell, s.cell__row_header)}
              >
              </th>
              {x_items_sorted.map((x_item, ix) => {
                return (
                  <td
                    key={ix}
                    className={cn(s.cell, s.cell__value)}
                  >
                  </td>
                )
              })}


              {can_show_row_totals && <td className={cn(s.cell, s.cell__spacer_column)}></td> }{/* Spacer column */}
              {can_show_row_totals &&
                <td className={cn(s.cell, s.cell__value, s.cell__column_total)}></td>
              }
            </tr>
          }

          {/* Footer */}
          {show_totals &&
            <tr>
              <th
                className={cn(s.cell, s.cell__row_header, s.cell__row_total)}
              >
                {TOTAL_LABEL}
              </th>
              {x_items_sorted.map((x_item, ix) => {
                return (
                  <td
                    key={ix}
                    className={cn(s.cell, s.cell__value, s.cell__row_total)}
                  >
                    {/* COLUMN totals */}
                    {x_item.total ?
                    <span
                      onClick={() => set_families_subselections(
                        get_subselections(should_transpose_data, {x_key, y_key}, {x_items: [x_item], y_items: y_items_sorted}),
                        x_item.total
                      )}
                      className={cn({ [s.cell__clickthrough]: !no_clickthrough })}
                    >
                       {get_display_value(x_item.total, value_formatter)}
                    </span> : get_display_value(x_item.total, value_formatter)}
                  </td>
                )
              })}
              {can_show_row_totals && <td className={cn(s.cell, s.cell__row_total, s.cell__spacer_column)}></td> }{/* Spacer column */}
              {can_show_row_totals &&
                <td
                  className={cn(s.cell, s.cell__value, s.cell__row_total, s.cell__column_total)}
                >

                  {/* FULL total */}
                  {full_total ?
                    <span
                      onClick={() => set_families_subselections(get_subselections(should_transpose_data, {x_key, y_key}, {x_items: x_items_sorted, y_items: y_items_sorted}), full_total)}
                      className={cn({[s.cell__clickthrough]: !no_clickthrough})}
                    >
                     {get_display_value(full_total, value_formatter)}
                  </span> : get_display_value(full_total, value_formatter)}
                </td>
              }
            </tr>
          }
        </tbody>
      </table>

    </div>
  )
}

Heatmap.get_plotly_data = get_plotly_data

Heatmap.get_axis_titles = get_axis_titles

Heatmap.get_csv_value_rows = get_csv_value_rows

export default Heatmap
