import React, { Component } from 'react'
import cn from 'classnames'

import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import TextLink from '../widgets/TextLink.js'

import s from './ClassifiersPanel.module.scss'

class ClassifiersPanel extends Component {
  constructor(props) {
    super(props)

    this.on_change_from_search_input = this.on_change_from_search_input.bind(this)

    this.state = {
      all_collapsed: true
    }
  }

  on_change_from_search_input(search_input) {
    this.props.classifier_tree_search_handler(search_input)
  }

  render() {
    const { search_phrase, show_classifier_tree_controls, show_select_all, classifier_tree_deselect_all_handler, classifier_tree_select_all_handler, classifier_tree_show_selected_only_handler } = this.props

    return (
      <div className={cn(s.block, 'px-1 px-sm-0')}>
        <div className='d-sm-flex pb-2'>
          <div>Filter all available</div>
          <ClearableSearchInput
            value={search_phrase}
            placeholder='Filter by keyword'
            handle_change={this.on_change_from_search_input}
            containerClassName='w-100'
          />
        </div>
        <div className='d-sm-flex justify-content-sm-left py-2'>
          {show_classifier_tree_controls &&
            [
              <TextLink key='toggle-show-selected' className={cn(s.link, 'mr-2')} onClick={classifier_tree_show_selected_only_handler}>[Show only selected]</TextLink>
            ]
          }
          {show_select_all &&
            [
              <TextLink key='select-all'  className={cn(s.link, 'mr-2')} onClick={classifier_tree_select_all_handler}>[Select]</TextLink>,
              <TextLink key='deselect-all'  className={cn(s.link, 'mr-2')} onClick={classifier_tree_deselect_all_handler}>[Deselect]</TextLink>
            ]
          }
        </div>
      </div>
    )
  }
}

export default ClassifiersPanel