import React from 'react'
import cn from 'classnames'

import s from './RadioStatic.module.scss'

const RadioStatic = ({size='md', is_checked, is_disabled, onClick, className}) => {

  const is_small = size === 'sm'

  is_disabled = (is_disabled != null) ? is_disabled : false

  return (
    <span
      onClick={!is_disabled? onClick : null}
      className={cn(
        s.block,
        {
          [s.block__normal]: !is_small,
          [s.block__small]: is_small,
          [s.__checked]: is_checked,
          [s.__disabled]: is_disabled
        },
        className
      )}
    />
  )
}

export default RadioStatic