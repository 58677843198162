import React from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'

import RadioStatic from '../widgets/RadioStatic.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './TableLineRestrictionControl.module.scss'

// TODO: extract general Radio button control (re-use in TableLineRestrictionControl, Timerange control etc...)

const TableLineRestrictionControl = ({
  className,
  table_line_restrictions,
  id_to_table_line_restriction,
  selected_table_line_restriction_id,
  on_change_table_line_restriction_id,
  right
}) => {

  const selected_table_line_restriction = id_to_table_line_restriction[selected_table_line_restriction_id]

  return (
    <span className={cn('d-flex', 'align-items-center', className)}>
      <div>
        Lines
      </div>
      <BaseDropdown
        className={cn('ml-1')}
        label={selected_table_line_restriction.name}
        labelClassName={s.button_label}
        right={right}
        buttonClassName={s.button}
        menuClassName={s.menu}
      >
        {table_line_restrictions.map((table_line_restriction, idx) => {
          const { name, id } = table_line_restriction
          const is_selected = (id === selected_table_line_restriction_id)

          return (
            <DropdownItem
              className={cn('d-flex', 'align-items-start', s.row_container)}
              key={idx}
              toggle={true} // on click, close the dropdown
              onClick={on_change_table_line_restriction_id.bind(null, id)}
            >
              <RadioStatic
                is_checked={is_selected}
              />
              <span
                className={cn(s.label, 'ml-1')}
              >
                {name}
              </span>
            </DropdownItem>
          )
          })}
      </BaseDropdown>
    </span>
  )
}

export default TableLineRestrictionControl