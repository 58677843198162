import React, { useState } from 'react'
import cn from 'classnames'

import { Table } from '../widgets/Table.js'
import Modal from '../widgets/Modal.js'
import DisputeDetails from './DisputeDetails.js'
import { PaginationControls } from '../widgets/Pagination.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import TextLink from '../widgets/TextLink.js'
import { DisputeLink } from './DisputeLink.js'
import {
  DEFENDANT_FIELD,
  DETAILS_FIELD,
  DURATION_FIELD,
  IS_DEFENDANT_NPE_FIELD,
  IS_PLAINTIFF_NPE_FIELD,
  OUTCOME_FIELD,
  PATENTS_FIELD,
  PLAINTIFF_FIELD,
  PORTFOLIO_NAME_FIELD,
  START_DATE_FIELD,
  END_DATE_FIELD,
  TITLE_FIELD,
  ROUTE_FIELD,
  JURISDICTION_FIELD,
  IS_NPE_FIELD
} from '../../model/disputes.js'
import {
  format_is_npe,
  format_route,
  format_patents,
  format_status_with_role,
  get_dispute_details
} from '../../utils/disputes_utils.js'
import { format_duration, format_integer_with_comma } from '../../utils/utils.js'
import { sort_table_data } from '../../utils/item_utils.js'
import { DISPUTE_DETAILS_VIEWS_BY_ID } from '../../utils/disputes_utils.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'

import { ROLE_DEFENDANT, ROLE_PLAINTIFF } from '../../model/dispute_roles.js'
import { PAGE_SIZES, DEFAULT_PAGE_SIZE } from '../../model/disputes_table_page_sizes.js'
import NoDataInSelection from '../NoDataInSelection.js'

const NARROW_COL_MAX_WIDTH = 85
const MEDIUM_COL_MAX_WIDTH = 120

const DisputesTable = ({data, selected_sort_field_id, selected_sort_direction_id, external_report_id, internal_report_id, dispute_role, handle_change_sort_field_id_and_sort_direction_id}) => {

  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(DEFAULT_PAGE_SIZE)
  const [show_modal, set_show_modal] = useState(false)
  const [selected_dispute, set_selected_dispute] = useState(null)

  function show_dispute_details(dispute) {
    set_selected_dispute(dispute)
    set_show_modal(true)
  }

  function hide_dispute_details() {
    set_selected_dispute(null)
    set_show_modal(false)
  }

  function set_selected_page_size(selected_page_size) {
    set_page_size(selected_page_size)
    set_current_page(0)
  }

  function set_current_page(current_page) {
    set_page(current_page)
  }

  function on_change_sort_field_id_and_sort_direction_id(selected_sort_field_id, selected_sort_direction_id) {
    handle_change_sort_field_id_and_sort_direction_id(selected_sort_field_id, selected_sort_direction_id)
    set_current_page(0)
  }

  function get_sorted_paginated_table_data() {
    const sorted_data = sort_table_data(data, selected_sort_field_id, selected_sort_direction_id)

    const first_row_idx = page * page_size
    const last_row_idx = ( page * page_size) + page_size

    return sorted_data.slice(first_row_idx, last_row_idx)
  }

  function get_is_npe_columns(dispute_role){
    switch (dispute_role){
     case ROLE_DEFENDANT : return [
       {...IS_PLAINTIFF_NPE_FIELD, cell_render: (row) => (<span>{format_is_npe(row.original[IS_PLAINTIFF_NPE_FIELD.id])}</span>), max_width: MEDIUM_COL_MAX_WIDTH}
     ]
     case ROLE_PLAINTIFF : return [
       {...IS_NPE_FIELD, cell_render: (row) => (<span>{format_is_npe(row.original[IS_NPE_FIELD.id])}</span>), max_width: MEDIUM_COL_MAX_WIDTH},
       {...IS_DEFENDANT_NPE_FIELD, cell_render: (row) => (<span>{format_is_npe(row.original[IS_DEFENDANT_NPE_FIELD.id])}</span>), max_width: MEDIUM_COL_MAX_WIDTH}
     ]
     //EITHER
     default: return [
       {...IS_DEFENDANT_NPE_FIELD, cell_render: (row) => (<span>{format_is_npe(row.original[IS_DEFENDANT_NPE_FIELD.id])}</span>), max_width: MEDIUM_COL_MAX_WIDTH},
       {...IS_PLAINTIFF_NPE_FIELD, cell_render: (row) => (<span>{format_is_npe(row.original[IS_PLAINTIFF_NPE_FIELD.id])}</span>), max_width: MEDIUM_COL_MAX_WIDTH}
     ]
    }
  }

  function get_columns(data_from_report_reader, dispute_role) {
    function get_details_link(row) {
      return (<TextLink onClick={() => show_dispute_details(get_dispute_details(row.original))}><span>Details</span></TextLink>)
    }

    const fields = [
      {...PORTFOLIO_NAME_FIELD, max_width: MEDIUM_COL_MAX_WIDTH},
      TITLE_FIELD,
      PLAINTIFF_FIELD,
      DEFENDANT_FIELD,
      {...ROUTE_FIELD, cell_render: (row) => (<span>{format_route(row.original['route'])}</span>), max_width: NARROW_COL_MAX_WIDTH},
      {...JURISDICTION_FIELD, max_width: NARROW_COL_MAX_WIDTH},
      {...PATENTS_FIELD, cell_render: (row) => (<span>{format_patents(row.original['patents'])}</span>), max_width: MEDIUM_COL_MAX_WIDTH},
      {...START_DATE_FIELD, max_width: NARROW_COL_MAX_WIDTH},
      {...END_DATE_FIELD, max_width: NARROW_COL_MAX_WIDTH},
      {...DURATION_FIELD, cell_render: (row) => (<span>{format_duration(row.original['duration'])}</span>), max_width: NARROW_COL_MAX_WIDTH},
      {...OUTCOME_FIELD, cell_render: (row) => (<span>{format_status_with_role(row.original['outcome'], dispute_role)}</span>), max_width: NARROW_COL_MAX_WIDTH},
      ...get_is_npe_columns(dispute_role),
      {...DETAILS_FIELD, cell_render: (row) => (<span>{get_details_link(row)}</span>), sortable: false, max_width: NARROW_COL_MAX_WIDTH}
    ]

    let table_columns = []

    fields.forEach(field => {

     const { id, label, cell_render } = field

     table_columns.push({
      ...TABLE_COLUMN_HEADER,
      header: function get_header() {
        return (
          <SortingColumnHeaderCell
            field={{...field, name: label}}
            on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
            selected_sort_field_id={selected_sort_field_id}
            selected_sort_direction_id={selected_sort_direction_id}
            sort_disabled={field.sortable === false}
          />
      )},
      id,
      label,
      field: field.id,
      ...(cell_render) ? {cell_render} : {},
      ...(field.className) ? {className: field.className} : {},
      ...(field.max_width) ? {max_width: field.max_width} : {},
      sortable: false
     })
    })

     return table_columns
  }

  function get_dispute_title_for_modal() {

    return (
     <span>{selected_dispute.title} <DisputeLink external_report_id={external_report_id} dispute_id={selected_dispute.dispute_id} className='ml-1'/></span>
    )
  }

  if (!data || data.length === 0) {
    return (
      <NoDataInSelection />
    )
  }

  const table_columns = get_columns(data, dispute_role)

  const total_data_rows = data.length
  const num_pages = Math.ceil(total_data_rows / page_size)

  if (page >= num_pages) {
    set_current_page(0)
  }

  const table_data = get_sorted_paginated_table_data()

  return (
    <div>
      {show_modal &&
        <Modal on_hide={hide_dispute_details} title={get_dispute_title_for_modal()}>
          <DisputeDetails
            dispute_id={selected_dispute.dispute_id}
            dispute_role={dispute_role}
            internal_report_id={internal_report_id}
            outcome={selected_dispute.outcome}
            view={DISPUTE_DETAILS_VIEWS_BY_ID.modal.id}
          />
        </Modal>
      }

      <div className={cn('d-flex', 'align-items-center')}>
        <span>
          This selection contains {format_integer_with_comma(total_data_rows)} disputes.
        </span>
        <PaginationControls
          className={'ml-auto'}
          num_pages={num_pages}
          selected_page_size={page_size}
          on_change_page_size={set_selected_page_size}
          current_page={page}
          on_change_current_page={set_current_page}
          available_page_sizes={PAGE_SIZES}
        />
      </div>

      <Table
        className={'mt-1'}
        columns={table_columns}
        data={table_data}
        manual
      />

      <div className={cn('d-flex', 'mt-1')}>
        <PaginationControls
          className={'ml-auto'}
          num_pages={num_pages}
          selected_page_size={page_size}
          on_change_page_size={set_selected_page_size}
          current_page={page}
          on_change_current_page={set_current_page}
          available_page_sizes={PAGE_SIZES}
        />
      </div>
    </div>
  )
}

export default DisputesTable