import React, { useEffect, useReducer, useState } from 'react'
import _ from 'underscore'

import Modal from '../../widgets/Modal.js'
import Spinner from '../../widgets/Spinner.js'
import { REPORT_SERIES_SORT_BY_SIZE } from '../../../utils/report_utils.js'
import {
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_chart_subtype,
  get_clean_filename,
  get_clean_view_id,
  MIMETYPE_XLSX,
  trigger_download
} from '../../../utils/download_utils.js'
import { ID_TO_VIEW } from '../../../model/views.js'
import {
  get_deck_spec,
  get_description_for_display,
  get_title_for_display
} from '../../../utils/report_deck_utils.js'
import {
  calculate_custom_y_range,
  get_processed_data_and_key_dims,
  get_selected_timerange_items,
  is_data_empty
} from '../../../utils/column_data_utils.js'
import { get_extent_from_key_items } from '../../../utils/time_range_utils.js'
import ErrorBody from '../../ErrorBody.js'

function get_inputs_reducer(state, data) {
  return {...state, ...data}
}

const DeckExport = (
  {
    pages_to_export=[],
    spec_id_to_fetch_obj={},

    ref_data,
    deref_data,
    data_creation_date,
    selections,
    chart_selections,
    report_title,
    clickthrough_item,

    on_close,
  }) => {
  const report_series_sort = REPORT_SERIES_SORT_BY_SIZE.id

  const [is_preparing_inputs, set_is_preparing_inputs] = useState(true)
  const [export_inputs, get_export_inputs] = useReducer(get_inputs_reducer, {})
  const [download_error, set_download_error] = useState(null)

  useEffect(() => {
    const inputs = {}
    const _in_fetching = []

    pages_to_export.forEach(page => {
      const {charts} = page
      charts.forEach(chart => {
        const {spec_id} = chart
        const { is_fetching, data } = spec_id_to_fetch_obj[spec_id] || {}

        if (is_fetching) {
          _in_fetching.push(spec_id)
          return
        }

        if (!export_inputs || !export_inputs[spec_id]) {
          inputs[spec_id] = (spec_id_to_fetch_obj[spec_id] && data) ? prepare_download_input(chart) : null
        }
      })
    })

    get_export_inputs(inputs)

    if (_in_fetching.length === 0) {
      set_is_preparing_inputs(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spec_id_to_fetch_obj])

  useEffect(() => {
    if (is_preparing_inputs) return

    const export_data = pages_to_export.map(page => {
      const {charts, short_title, title, section} = page

      const {short_title: section_title = ''} = section || {}

      return charts.map(chart => {
        const {spec_id} = chart
        const input = export_inputs[spec_id]
        const page_name = `${short_title || title}${section_title !== ''? ` (${section_title})`: ''}`.trim()
        return input ? {...input, page_name} : null
      })
    })

    const export_title = `${report_title}${pages_to_export.length === 1 ? `_${pages_to_export[0].title.toLowerCase()}` : ''}`

    const filename = get_clean_filename(export_title + EXCEL_FILE_EXT)

    fetch_excel_document(export_data, false, true)
      .then(arraybuffer => {
        trigger_download(arraybuffer, MIMETYPE_XLSX, filename)
        on_close()
      })
      .catch(error => {
        set_download_error(error)
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_preparing_inputs])

  function prepare_download_input(chart) {
    const {view_id, spec_id} = chart

    const view = ID_TO_VIEW[view_id]
    const { ViewComponent } = view
    const {data} = spec_id_to_fetch_obj[spec_id]

    const chart_selection = chart_selections[spec_id]

    const spec = get_deck_spec(spec_id)
    const chart_subtype = get_chart_subtype(view_id)
    const { value_prefix, value_suffix } = spec
    const { timeseries_key_dim_idxs, default_status, get_hidden_dim_full_timerange } = spec
    const { description: custom_description, status_filter: custom_status_filter, selected_timerange } = chart_selection || {}
    const is_any_timeseries = (timeseries_key_dim_idxs && timeseries_key_dim_idxs.length) || get_hidden_dim_full_timerange
    const item = {...chart, ...is_any_timeseries ? {timerange: selected_timerange} : {}}

    const [processed_data, processed_key_dims, original_key_dims] = get_processed_data_and_key_dims(
      spec,
      view_id,
      data_creation_date,
      item,
      data,
      ref_data,
      deref_data,
      report_series_sort,
      false,
      (chart.rollup_thresholds != null)
    )

    const is_dataset_empty = (_.some(processed_key_dims, (dim_keys) => (!dim_keys || dim_keys.length === 0)))
    const is_processed_data_empty = is_data_empty(processed_data, processed_key_dims, selections, {})
    const selected_timerange_is_empty = (selected_timerange && ((selected_timerange[1] - selected_timerange[0]) <= 1)) || (processed_data && processed_data.data[0].length === 1)
    if (is_dataset_empty || selected_timerange_is_empty || is_processed_data_empty) return null

    const { x_axis_title, y_axis_title } = ViewComponent.get_axis_titles ? ViewComponent.get_axis_titles(spec) : {}

    const { status_filter: deck_status_filter, spotlighted_tech_areas=[] } = selections

    const chart_status_filter = custom_status_filter || deck_status_filter || default_status

    const plotly_data = ViewComponent.get_plotly_data({
      spec,
      data: processed_data,
      key_dims: processed_key_dims,
      item,
      ref_data,
      deref_data,
      external_report_id: null,
      data_creation_date,
      report_series_sort,
      selections,
      is_report_deck: true,
      status_filter: chart_status_filter,
      clickthrough_item
    })

    const y_axis_range = spec.axis_range ? calculate_custom_y_range({spec, plotly_data}) : null

    const title_to_render = get_title_for_display({
      chart,
      status_filter: chart_status_filter,
      timerange: is_any_timeseries && data ? get_extent_from_key_items(get_selected_timerange_items((timeseries_key_dim_idxs ? original_key_dims[timeseries_key_dim_idxs[0]] : get_hidden_dim_full_timerange(data_creation_date)), selected_timerange, spec, data_creation_date)) : null,
      spotlighted_tech_areas,
      tech_areas: ref_data.tech_areas,
      org_lists: ref_data.org_lists,
      clickthrough_item
    })

    const input = {
      title: title_to_render,
      description: custom_description || get_description_for_display({chart, status_filter: chart_status_filter, spotlighted_tech_areas, clickthrough_item}),
      view_id: get_clean_view_id(view_id),
      data: plotly_data,
      ...(x_axis_title ? { x_axis_title } : {}),
      ...(y_axis_title ? { y_axis_title } : {}),
      ...(chart_subtype ? {chart_subtype} : {}),
      ...(value_prefix ? {value_prefix} : {}),
      ...(value_suffix ? {value_suffix} : {}),
      ...(y_axis_range ? {y_axis_range} : {}),
    }

    return input
  }

  return (
    <Modal is_open={true} on_hide={on_close}>
      {!download_error &&
        <div className='text-center'>
          <Spinner/>
          <div>Preparing charts to export</div>
        </div>
      }

      {download_error &&
        <ErrorBody
          error={download_error}
          context='exporting charts to Excel'
        />
      }
    </Modal>
  )
}

export default DeckExport