import axios from 'axios'

import { add_source_err_to_target_err } from './axios_utils.js'
import { USER_INFO_BY_IDS_URL, USER_INFO_BASE_URL } from '../constants/urls.js'

export function get_user_info_by_id(user_ids) {
  return axios.post(USER_INFO_BY_IDS_URL, user_ids)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user info: ')
    })
}

export function get_user_idp() {
  return axios.get(`${USER_INFO_BASE_URL}/idp`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user idp info: ')
    })
}