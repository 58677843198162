export const CIPHER_RED = '#D91E3E'
export const CIPHER_BLUE = '#1a3d59'

export const LIGHT_GREY_COLOUR   = '#D3D3D3'

export const DEFAULT_TEXT_COLOUR = '#212529'
export const DEFAULT_TEXT_COLOUR_OVER_DARK_BG = '#FFFFFF'

export const REPORT_TITLE_BAR_COLOUR = '#A5ADB5'

export const CIPHER_LIGHT_PINK = '#EA9388'
export const CIPHER_COBALT = '#004996'
export const CIPHER_BRIGHT_BLUE = '#447c94'


export const CATEGORY_20 = [
  CIPHER_BRIGHT_BLUE,
  '#b4d3e0',
  '#f58e33',
  '#ffcb78',
  '#015c44',
  '#56ba70',
  CIPHER_RED,
  '#de8392',
  '#764e9c',
  '#c5a1e3',
  CIPHER_BLUE,
  '#3e79b0',
  '#630e3d',
  '#b8518a',
  '#726d7d',
  '#b6b0c2',
  '#97b06a',
  '#e8e288',
  '#17becf',
  '#c4f6ff'
]

export const PORTFOLIO_COLOR_SCHEME = [
  CIPHER_RED,
  CIPHER_BRIGHT_BLUE,
  '#B4D3E0',
  '#9226a3',
  '#E59500',
  '#E8E288',
  CIPHER_BLUE,
  '#de8392',
  '#630e3d',
  '#a4bf71',
  '#44ad96',
  '#b8a9c9',
  '#5f48c7',
  '#99ACFF',
  '#42cceb'
]

export const TECH_COLOR_SCHEME = [
  '#42cceb',
  '#5f48c7',
  '#de8392',
  '#630e3d',
  '#99ACFF',
  '#a4bf71',
  '#44ad96',
  '#b8a9c9',
  CIPHER_BLUE,
  '#E59500',
  '#E8E288',
  '#9226a3',
  '#B4D3E0',
  CIPHER_BRIGHT_BLUE,
  CIPHER_RED,
]

export const GEO_COLOR_SCHEME = CATEGORY_20

export const MISC_COLOR_SCHEME = [
  CIPHER_BLUE,
  '#f0ec78',
  '#a4bf71',
  CIPHER_RED,
  '#5f48c7',
  '#630e3d',
  CIPHER_LIGHT_PINK,
  '#17becf',
  '#9226a3',
  '#b4d3e0'
]

export const DEFAULT_1D_COLOR_SCHEME = CATEGORY_20

export const POSITIVE_LABEL = '#27AE60'
export const NEGATIVE_LABEL = CIPHER_RED

//patent family status colours
export const GRANTED_COLOR     = POSITIVE_LABEL
export const PENDING_COLOR     = CIPHER_COBALT
export const EXPIRED_COLOR     = CIPHER_RED
export const OTHER_COLOR       = LIGHT_GREY_COLOUR
export const INACTIVE_COLOR    = '#809CC2'
export const REJECTED_COLOR    = CIPHER_LIGHT_PINK
// end of patent family status colours

export const PLAINTIFF_COLOR = '#9467bd'
export const DEFENDANT_COLOR = '#aec7e8'

export const APPLICATIONS_COLOR = '#69B2B6'
export const RENEWALS_COLOR = '#F19310'

export const DEFAULT_CHART_COLOR = CIPHER_RED

export const DEFAULT_NEXT_AGGLOM_COLOR = LIGHT_GREY_COLOUR

export const CIPHER_WHITE = '#ffffff'
export const CIPHER_BLACK = '#000000'

export const TREEMAP3D_MIN_COLOR = '#3777FE'
export const TREEMAP3D_MAX_COLOR = '#FF6600'
export const TREEMAP3D_ZERO_COLOR = '#CDD2D6'


//report deck colours
export const DECK_TARGET_ORG_COLUMN_COLOUR = CIPHER_RED
export const DECK_DEFAULT_COLUMN_COLOR = '#A8B8C1'
export const DECK_LIGHTER_DEFAULT_COLUMN_COLOR = '#CAD5DC' // non-highlighted series in line charts
export const DECK_MISC_SPOTLIGHT_COLOR = CIPHER_BRIGHT_BLUE
export const DECK_SPOTLIGHTED_ORG_COLUMN_COLOR = DECK_MISC_SPOTLIGHT_COLOR
export const DECK_BACKGROUND_ORG_COLUMN_COLOR = '#E3EAEE' // orgs not in lists
export const DECK_TECH_AREAS_COLOR_SCHEME = [
  '#E59500',
  '#5f48c7',
  '#97b06a',
  '#d15641',
  '#17becf',
  '#b8518a',
  '#726d7d',
  '#f0ec78',
  '#3e79b0',
  CIPHER_LIGHT_PINK,
  '#56ba70',
  '#9226a3'
]
//end of report deck colours



