import React, { useState } from 'react'

import OrganisationModal from './OrganisationModal.js'
import { fetch_organisation_by_exact_name } from '../../utils/organisation_utils.js'
import ErrorModal from '../ErrorModal.js'
import { ScrollModal } from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

const OrgNewModal = ({provisional_name, parent_org, on_confirm, should_find_similar, on_select_similar, on_hide, FoundOrgDisplay}) => {
  const [new_org, set_new_org] = useState(null)

  const [fetch_by_name_error, set_fetch_by_name_error] = useState(null)

  const {name} = parent_org || {}
  const title = `Add new organisation${name ? ' to ' + name : ''}`

  function add_new_org({new_name, tags, notes}) {
    on_confirm({
      name: new_name,
      ...(tags.length) ? {tags: tags} : {},
      ...(notes != null) ? {notes} : {}
    })
  }

  function select_similar(org) {
    on_select_similar(org)
    on_hide()
  }

  function validate({new_name, tags, notes}) {
    fetch_organisation_by_exact_name(new_name)
      .then(response => {
        const {results = []} = response

        if (results.length === 0) {
          add_new_org({new_name, tags, notes})
        } else {
          set_new_org({
            new_name,
            tags,
            notes,
            similar_orgs: results
          })
        }

      })
      .catch(error => {
        set_fetch_by_name_error(error)
      })
  }

  if (fetch_by_name_error) {
    return (
      <ErrorModal
        error={fetch_by_name_error}
        on_hide={() => set_fetch_by_name_error(null)}
        context={'fetching organisation by name'}
      />
    )
  }

  if (new_org) {
    return (
      <ScrollModal is_open={true} on_hide={on_hide} footer={<PrimaryButton onClick={() => add_new_org(new_org)}>Add new organisation</PrimaryButton>}>
        <div>Cipher has found organisations with the same name. Select from the list below or continue adding a new organisation:</div>
        {new_org.similar_orgs.map((org, i) => (
          <FoundOrgDisplay
            org={org}
            key={i}
            result_reference={[i]}
            selected_organisations={[]}
            selected_parents={[]}
            on_result_check_in={(selected_result) => {select_similar(selected_result.org)}}
          />
        ))}

      </ScrollModal>
    )
  }

  return (
    <OrganisationModal
      show_spinner={false}
      parent_org={parent_org}
      org={{name: provisional_name}}
      on_hide={on_hide}

      on_confirm={should_find_similar ? validate : add_new_org}

      title={title}
      confirm_label='Add organisation'
    />
  )
}

export default OrgNewModal