import React from 'react'
import cn from 'classnames'

import { PaneHeader } from '../../widgets/PaneHeader.js'

import cs from '../../cipher_styles.module.scss'

export const CustomClusteredResultItem = ({className, section_name, items}) => {
  const item_keys = Object.keys(items || {})

  return (
    <div className={className}>
      <PaneHeader text={section_name}/>
      <div className='d-flex flex-wrap'>
        {item_keys.map((key, i) => {
          const item = items[key]

          const {pat_fam_ids, name} = item

          return (
            <div className={cn('mr-3', {[cs.cipher_black_text]: !name})} key={i}><span
              className='font-weight-bold'>{name || 'None'}</span> ({pat_fam_ids.length} {pat_fam_ids.length === 1 ? 'family' : 'families'})
            </div>
          )
        })}
      </div>
    </div>
  )
}