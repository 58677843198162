import React, { useState } from 'react'

import OrgSearchInput from '../orgs/OrgSearchInput.js'
import OrgSearchResultsDisplay from '../orgs/OrgSearchResultsDisplay.js'
import { ORG_SEARCH_CONTEXT } from '../../model/organisation.js'
import { useOrgSearch } from '../../hooks/organisation_hooks.js'
import ErrorModal from '../ErrorModal.js'
import BadSyntaxAlertModal from '../patent_family_list/BadSyntaxAlertModal.js'
import { is_400_error } from '../../utils/axios_utils.js'
import ReportBuilderStartScreen from '../builder/ReportBuilderStartScreen.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import OrgSearchModeWrapper from '../orgs/OrgSearchModeWrapper.js'
import OrgSuggestionsSearch from './OrgSuggestionsSearch.js'

const OrgSearchSingle = ({ selected_organisations, update_basket_handler, on_search_mode_change }) => {

  const [search_phrase, set_search_phrase] = useState(null)
  const [org_for_suggestions, set_org_for_suggestions] = useState(null)

  const [show_spinner, results, org_search_error, clear_org_search_error ] = useOrgSearch(search_phrase, false, true)

  function on_change_from_search_input(new_search_phrase) {
    if (search_phrase === new_search_phrase) {
      return
    }

    set_search_phrase(new_search_phrase)
  }

  function on_click_add(org, result_reference) {
    track_report_builder_event(`action="add_to_basket" obj="org" context="search" result_reference="${(result_reference || []).join(',')}"`)
    update_basket_handler({add: [org]})
  }

  function on_click_remove(org) {
    track_report_builder_event('action="remove_from_basket" obj="org" context="search"')
    update_basket_handler({ remove: [org]})
  }

  function on_org_click_handler({org, add, result_reference}) {
    add ? on_click_add(org, result_reference) : on_click_remove(org)
  }
  
  const is_bad_syntax_error = is_400_error(org_search_error)

  if (org_search_error && !is_bad_syntax_error) {
    return(
      <ErrorModal
        on_hide={clear_org_search_error}
        error={org_search_error}
        context='fetching organisation results'
      />
    )
  }

  if (is_bad_syntax_error) {
    return(
      <BadSyntaxAlertModal
        on_hide={clear_org_search_error}
      />
    )
  }

  const has_results = (results && results.length > 0)

  return (
    <div>
      <OrgSearchModeWrapper on_search_mode_change={on_search_mode_change} search_mode={'single'}>
        <OrgSearchInput
          on_change_handler={on_change_from_search_input}
          value={search_phrase}
          autofocus
        />
      </OrgSearchModeWrapper>

      {!org_for_suggestions &&
        <OrgSearchResultsDisplay
          results={results}
          selected_organisations={selected_organisations}
          show_spinner={show_spinner}
          search_phrase={search_phrase}
          on_result_check_in={on_org_click_handler}
          on_show_similar_organisations={set_org_for_suggestions}
          context={ORG_SEARCH_CONTEXT}
        />
      }

      {!show_spinner && org_for_suggestions &&
        <OrgSuggestionsSearch
          base_name={org_for_suggestions.name}
          base_items={[org_for_suggestions]}
          on_result_select={on_org_click_handler}
          reset_handler={() => set_org_for_suggestions(null)}
          on_show_similar_organisations={set_org_for_suggestions}
          selected_organisations={selected_organisations}
        />
      }

      {!show_spinner && !has_results &&
        <ReportBuilderStartScreen
          text={'Enter the name of an organisation to add it to the report.'}
        />
      }

    </div>
  )
}

export default OrgSearchSingle