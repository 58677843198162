import React from 'react'
import cn from 'classnames'

import {
  get_key_items_sorted_by_value,
  get_keys_to_value,
  get_processed_data_and_key_dims,
  get_value_column_idx,
  is_rollup_possible
} from '../../utils/column_data_utils.js'
import {
  get_cpc_code_column_idx,
  get_first_filing_country_column_idx,
  get_next_agglom_visibility,
  get_rollup_thresholds
} from '../../utils/main_items_utils.js'
import ControlsBarDropdown from './ControlsBarDropdown.js'
import RollupLimitControls from './RollupLimitControls.js'
import { AVAILABLE_ROLLUP_LIMITS, DEFAULT_ROLLUP_LIMIT } from '../../model/rollups.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { CheckboxLabel, CheckboxWrapper } from './CheckboxGroup.js'
import { REPORT_SERIES_SORT_BY_SIZE } from '../../utils/report_utils.js'

const MainReportItemAgglom = ({spec, item, data, ref_data, data_creation_date, deref_data, selected_view_id, item_processed_key_dims, on_change_rollup_thresholds, on_change_next_agglom_item_visibility, className}) => {

  function update_threshold_limits(key_dim_idx, new_limit, threshold_limits) {
    const updated_thresholds = (threshold_limits || []).map((item, i) => {
      if (i === key_dim_idx) {
        return new_limit
      }
      return item
    })

    on_change_rollup_thresholds(updated_thresholds)
  }

  function toggle_next_agglom_item(key_dim_idx, next_agglom) {
    const updated_next_agglom_visibility = (next_agglom || []).map((item, i) => {
      if (i === key_dim_idx) {
        const show = next_agglom[key_dim_idx]
        return !show
      }
      return item
    })

    on_change_next_agglom_item_visibility(updated_next_agglom_visibility)
  }

  function filter_next_agglom_item(column_idx) {
    return item_processed_key_dims[column_idx].filter(item => item.is_next_agglom)[0]
  }

  function get_rollup_items() {
    const [processed_data, processed_key_dims, ] = !data ? [null, null, null] : get_processed_data_and_key_dims(spec, selected_view_id, data_creation_date, item, data, ref_data, deref_data, REPORT_SERIES_SORT_BY_SIZE.id, false, false )

    if (!processed_data) return []

    const rollup_items = []

    const value_column_idx = get_value_column_idx(processed_data)

    const cpc_dim_column_idx = get_cpc_code_column_idx(spec)
    const first_filing_country_dim_column_idx = get_first_filing_country_column_idx(spec)

    if (cpc_dim_column_idx > -1) {
      const cpc_to_value = get_keys_to_value(processed_data, [cpc_dim_column_idx], value_column_idx, spec.show_average)
      const cpc_sorted = get_key_items_sorted_by_value(processed_key_dims[cpc_dim_column_idx], cpc_to_value)

      if (is_rollup_possible(AVAILABLE_ROLLUP_LIMITS[0], cpc_sorted.length)) {
        rollup_items.push({
          label: 'CPC codes',
          short_label: 'CPC',
          items: cpc_sorted,
          column_idx: cpc_dim_column_idx
        })
      }
    }

    if (first_filing_country_dim_column_idx > -1) {
      const country_to_value = get_keys_to_value(processed_data, [first_filing_country_dim_column_idx], value_column_idx, spec.show_average)
      const countries_sorted = get_key_items_sorted_by_value(processed_key_dims[first_filing_country_dim_column_idx], country_to_value)

      if (is_rollup_possible(AVAILABLE_ROLLUP_LIMITS[0], countries_sorted.length)) {
        rollup_items.push({
          label: 'First filing countries',
          short_label: 'Geos',
          items: countries_sorted,
          column_idx: first_filing_country_dim_column_idx
        })
      }
    }

    return rollup_items
  }

  const { selected_rollup_thresholds } = item
  const rollup_thresholds = selected_rollup_thresholds || item_processed_key_dims ? get_rollup_thresholds(item, item_processed_key_dims.length) : []
  const rollup_items = get_rollup_items()
  const next_agglom_visible = item_processed_key_dims ? get_next_agglom_visibility(item, item_processed_key_dims.length) : []

  if (rollup_items.length === 0) return null

  return (
    <div className={cn('d-flex', className)}>
      {rollup_items.map((rollup_item, i) => {
        const {label, short_label, items, column_idx} = rollup_item
        const next_agglom_item = filter_next_agglom_item(column_idx)

        return (
          <ControlsBarDropdown
            label={label}
            short_label={short_label}
            disable={false}
            className={'my-auto'}
            right={true}
            key={i}
          >
            <RollupLimitControls
              items={items}
              selected_rollup_thresholds={rollup_thresholds[column_idx] || DEFAULT_ROLLUP_LIMIT}
              on_limit_select={(new_limit) => update_threshold_limits(column_idx, new_limit, rollup_thresholds)}
              className='mb-2'
            />

            {next_agglom_item &&
              <CheckboxWrapper
                is_next_agglom={true}
                on_click={() => toggle_next_agglom_item(column_idx, next_agglom_visible)}
              >
                <CheckboxStatic
                  is_checked={next_agglom_visible[column_idx]}
                  className='flex-shrink-0 flex-grow-0 my-auto' // prevent weird flex behaviour
                />

                <CheckboxLabel
                  label={next_agglom_item.name}
                  className='ml-2 mr-1'
                />

              </CheckboxWrapper>
            }

          </ControlsBarDropdown>
      )})}
    </div>
  )
}

export default MainReportItemAgglom