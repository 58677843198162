import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { Tag } from './family_tag_utils'
import { AddToReportIcon } from '../widgets/IconSet'
import { PaneHeader } from '../widgets/PaneHeader'

import s_block from '../widgets/Block.module.scss'

interface FamilyTagLinkProps {
  className: string,
  tag: Tag,
  on_click_handler: Function
}

export const FamilyTagLink = ({className, tag, on_click_handler}: FamilyTagLinkProps) => {

  const total_tag_group_families = tag.values.reduce((total, tag_value) => (total + tag_value.families_count), 0)
  const any_families: boolean = total_tag_group_families > 0
  const title = tag.name || ''

  function render(): JSX.Element {
    return (
      <div className={cn('p-2', s_block.card, className)}>
        {title &&
          <div className={'d-flex flex-row'}>
            {/*@ts-expect-error*/}
            <PaneHeader text={title}/>
            {/*@ts-expect-error*/}
            <TextLink
              className={'ml-2'}
              onClick={() => on_click_handler(tag)}
              title={'Add tag group to the basket (' + total_tag_group_families +')' }
              disable={!any_families}
            >
              <AddToReportIcon/>
            </TextLink>
          </div>
        }
        <div className='d-flex flex-wrap'>
          {tag.values.map((tag_value, i) => {
            // @ts-expect-error
            return (<TextLink
              key={i}
              className='mr-3'
              onClick={() => on_click_handler(tag, tag_value)}
              disable={tag_value.families_count === 0}
              title={tag_value.families_count + ' families'}
            >
              {tag_value.value + ' (' + tag_value.families_count + ')'}
            </TextLink>)
          })}
        </div>
      </div>

    )
  }

  return render()
}


