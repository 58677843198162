import React from 'react'
import cn from 'classnames'

import ValueDropdownSelector from './widgets/ValueDropdownSelector.js'

import s from './PageSizeControl.module.scss'

/**
 * @param {} className
 * @param {} page_sizes An array of values
 * @param {} selected_page_size
 * @param {} on_change_page_size
 */
const PageSizeControl = ({
  className,
  page_sizes,
  selected_page_size,
  on_change_page_size
}) => {

  return (
    <span className={cn('d-flex', 'align-items-center', className)}>
      <div>
        Page size
      </div>
      <ValueDropdownSelector
        className={cn('ml-1')}
        values={page_sizes}
        selected_value={selected_page_size}
        on_change_value={on_change_page_size}
        right={true}
        buttonClassName={s.button}
        menuClassName={s.menu}
      />
    </span>
  )
}

export default PageSizeControl