import React, { useEffect } from 'react'
import cn from 'classnames'

import { PaneHeader } from '../widgets/PaneHeader.js'
import TextLink from '../widgets/TextLink.js'
import { RemoveIcon, ConfigureIcon } from '../widgets/IconSet.js'
import Spinner from '../widgets/Spinner.js'
import { set_sticky_top } from '../../utils/sticky_utils.js'
import { PrimaryButton } from '../widgets/Button.js'
import ThresholdControl from './ThresholdControl.js'

import s from './ClassifiersBasket.module.scss'

const ClassifiersBasket = (
  { heading_text, empty_basket_text, selected_classifiers, is_saving, remove_from_basket, update_success,
    show_config_controls, configure_threshold_handler, threshold_options, toggle_show_config_controls, submit_basket_handler
  }) => {

  useEffect(() => {
    set_sticky_top('.' + s.subs_basket_container)
  }, [])

  const is_basket_empty = (selected_classifiers || []).length === 0

  return (
    <div className={cn('mt-lg-1 sticky-top', s.subs_basket_container)}>
      <div className={cn('p-2', s.subs_basket_header_wrapper)}>
        <PaneHeader
          text={heading_text}
          className={s.subs_basket_header}
          menu={toggle_show_config_controls && (<ConfigButton toggle_show_config_controls={toggle_show_config_controls}/>)}
        />
      </div>
      <div className={cn(s.subs_basket_items_container)}>
        {(selected_classifiers || []).length < 1 &&
          <span className={s.subs_basket_prompt}>
            {empty_basket_text}
          </span>
        }
        <div>
          {(selected_classifiers || []).map((tech, idx) => (
            <div key={idx} className='d-flex'>
              <span>{tech.name}</span>
              <TextLink onClick={() => remove_from_basket(tech)}>
                <RemoveIcon />
              </TextLink>

              {show_config_controls && configure_threshold_handler &&
                <ThresholdControl
                  threshold={tech.threshold}
                  threshold_options={threshold_options}
                  on_change_value={(value) => configure_threshold_handler(tech, value)}
                />
              }
            </div>
          ))}
        </div>
        { is_saving &&
          <Spinner/>
        }
        { update_success &&
          <div className={s.subs_basket_success_text}>Update saved.</div>
        }
      </div>
      {submit_basket_handler &&
        <div>
          <PrimaryButton className='w-100' disabled={(is_basket_empty)} onClick={submit_basket_handler}>Next</PrimaryButton>
        </div>
      }
    </div>
  )
}

const ConfigButton = ({toggle_show_config_controls}) => {
  return (
    <TextLink className='float-right' onClick={toggle_show_config_controls}>
      <ConfigureIcon/>
    </TextLink>
  )
}

export default ClassifiersBasket