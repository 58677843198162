import { CIPHER_RED, MISC_COLOR_SCHEME } from '../../../constants/colours.js'

export const BASE_COLOUR = CIPHER_RED

export const LIGHT_GREY = '#e5e5e5'
export const DARK_GREY = '#999999'

export const NUM_COMPANIES = 5

export const MAX_CLUSTERS = 8

export const EXECUTIVE_SUMMARY_COLOURS = [
  '#d4223d', // red
  '#fbb757',  // yellow
  '#1070b7',  // blue
  '#201d4f',  // navy
  '#6b308f'   // purple
]

export const DEFAULT_CIPHER2_COLOURS = [
  CIPHER_RED, // target
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#98df8a',
  '#ff9896',
  '#c5b0d5',
  '#c49c94',
  '#f7b6d2',
  '#c7c7c7',
  '#dbdb8d',
  '#9edae5',
  '#aec7e8',
  '#ffbb78'
]

export const GROWTH_TIME_SPAN = 3
export const CI_KEY = '/static/doc/Cipher_Executive_Summary_key.pdf'
//from cipher 2
// export const CLUSTER_COLOURS = d3.scale.category20().domain(d3.range(100)) // Explicit domain ensures consistent coloring regardless of order (otherwise d3 creates the domain dynamically)
export const CLUSTER_COLOURS = MISC_COLOR_SCHEME // Explicit domain ensures consistent coloring regardless of order (otherwise d3 creates the domain dynamically)
