import React from 'react'
import cn from 'classnames'
import { DropdownToggle } from 'reactstrap'

import DropdownChevron from './DropdownChevron.js'

import s from './SelectDropdownToggleStatic.module.scss'

/**
 * Toggle for a react-strap Dropdown menu.
 * i.e. it is assumed this component will be used in the context of a
 *      react-strap Dropdown or UncontrolledDropdown.
 *      see https://reactstrap.github.io/components/dropdowns/
 * Shows a red "v" (chevron) to the right of the label.
 * Size can be overriden by setting "font-size" in the css (via className).
 * Likewise with background colour.
 * @param {} chevron_left if set, puts red chevron on the left (otherwise it's on the right).
 * @param {} disabled if set, disables the dropdown
 */
const SelectDropdownToggleStatic = ({ className, labelClassName, chevronClassName, chevron_left, disabled, tag, hide_chevron, children }) => {

  return (
    <DropdownToggle
      tag={tag}
      disabled={disabled}
      className={cn(s.block, className)}
    >
      <span className={'d-flex'}>
        {!hide_chevron && chevron_left &&
          <DropdownChevron className='mr-1' />
        }

        <span className={labelClassName}>{children}</span>

        {!hide_chevron && !chevron_left &&
          <DropdownChevron className={cn('ml-1', chevronClassName)} />
        }
      </span>

    </DropdownToggle>
  )
}

export default SelectDropdownToggleStatic