import React from 'react'

import Modal from './widgets/Modal.js'

const AlertModal = ({title, className, on_hide, children}) => {

  return (
    <Modal
      className={className}
      on_hide={on_hide}
      title={title}
      close_label={'OK'}
    >
      {children}
    </Modal>
  )

}

export default AlertModal