import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { get_value } from '../../utils/column_data_utils.js'
import { format_integer_with_comma, sum } from '../../utils/utils.js'

import s from './Legend.module.scss'

const Legend = ({ items, keys_to_value, value_formatter, show_total, on_click_item, no_clickthrough }) => {

  function get_value_or_zero(keys_to_value, item) {
    if (!keys_to_value) {
      return 0
    }
    return get_value(keys_to_value, [item.id]) || 0
  }

  function format_value(value) {
    return value_formatter ? value_formatter(value) : format_integer_with_comma(value)
  }

  const total = sum(_.values(keys_to_value))

  return (
    <div className={s.block}>
      <table>
        <tbody>
          {items.map((item, i) => {

            const value = get_value_or_zero(keys_to_value, item)

            if (total && value === 0) {
              // if showing values, don't show 0 series
              return null
            }

            const is_clickable = value && !no_clickthrough

            return (
              <tr
                key={i}
                title={item.name}
                onClick={() => is_clickable ? on_click_item(item, value) : null}
                className={is_clickable ? s.item_is_clickable : ''}
              >
                <td
                  className={cn(s.cell, 'pt-1')}
                >
                  <div
                    className={s.color}
                    style={{backgroundColor: item.color}}
                  >
                  </div>
                </td>
                <td
                  className={cn(s.cell, 'w-100 pr-2')}
                >
                  {item.name}
                </td>

                {keys_to_value &&
                  <td className={cn(s.cell, 'text-right')}>
                    {format_value(value)}
                  </td>
                }
              </tr>
            )
          })}
        </tbody>

        {show_total &&
          <tfoot>
            <tr className={s.spacer_row}/>
            <tr className={!no_clickthrough ? s.item_is_clickable : ''}
                onClick={() => !no_clickthrough ? on_click_item({is_total: true}, total) : ''}>
              <td className={cn(s.cell)}></td>
              <td
                className={cn(s.cell, s.cell__total)}
              >
                TOTAL
              </td>
              <td className={cn(s.cell, s.cell__total, 'text-right')}>
                {format_value(total)}
              </td>
            </tr>
          </tfoot>
        }
      </table>
    </div>
  )
}

export default Legend