import _ from 'underscore'
import { createTheme } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import { CIPHER_RED } from './colours.js'

LicenseInfo.setLicenseKey(
  '1c32ca46b922a9afb3550fb154e1814bT1JERVI6MzQxNjYsRVhQSVJZPTE2NzA3NjQ4MzgwMDAsS0VZVkVSU0lPTj0x',
)

// MUI has a set of default Z-values, see https://mui.com/material-ui/customization/z-index/
// Here we increase them all by 10000 (so that modals appear over our header etc)
const DEFAULT_Z_INDEX = {
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
}
const CUSTOM_Z_INDEX = _.mapObject(DEFAULT_Z_INDEX, (val) => val + 10000)

export const mui_theme = createTheme({
  typography: {
    fontFamily: [
      '"Source Sans Pro"',
      'sans-serif'
    ].join(','),
  },

  palette: {
    primary: {
      main: CIPHER_RED
    }
  },

  zIndex: CUSTOM_Z_INDEX,

  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          transform: "scale(0.7)",  // smaller checkboxes (useful in the TrainingSets table)
        }
      }
    }
  }
})