import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { Link } from '../widgets/Link.js'
import { InfoIcon } from '../widgets/IconSet.js'
import { on_click_from_help_link } from '../../utils/help_utils.js'

export const TileHelpLink = ({help_url, on_click, className, icon}) => {

  function render_icon() {
    return icon || (<InfoIcon/>)
  }

  if (help_url) {
    return (
      <TextLink element={Link} href={help_url} target='_blank' className={className} onClick={() => on_click_from_help_link(help_url)}>{render_icon()}</TextLink>
    )
  }

  return (
    <TextLink onClick={on_click} className={className}>{render_icon()}</TextLink>
  )
}