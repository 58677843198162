import React from 'react'

import { TECH_PARTITIONING_TYPE_CLASSIFIER } from '../../../model/technology_basket.js'
import TechnologyPartitioning from './TechnologyPartitioning.js'
import ProgressPrompt from './ProgressPrompt.js'

const TechnologyPartitioningStep = (
  {
    is_fetching,
    classifier_groups,
    selected_stream_id,
    selected_classifier_group_id,
    technology_partitioning,
    classifiers,
    user_has_utt,
    user_has_classifiers,
    utt_version,
    is_report_big,
    is_report_too_big,

    on_select_technology_split,
    on_select_classifier_group,
    on_select_classifiers,
    on_complete,

    className
  }) => {
  return (
    <div className={className}>
      <ProgressPrompt
        is_wizard_final_step={true}
        on_step_complete={on_complete}
        can_wizard_continue={!is_report_too_big && technology_partitioning && ((technology_partitioning.type !== TECH_PARTITIONING_TYPE_CLASSIFIER) || ((technology_partitioning.type === TECH_PARTITIONING_TYPE_CLASSIFIER) && ((classifiers || []).length !== 0)))}
      >
        <span>How do you want to classify families?</span>
      </ProgressPrompt>

      <TechnologyPartitioning
        is_fetching={is_fetching}
        classifier_groups={classifier_groups}
        selected_stream_id={selected_stream_id}
        user_has_utt={user_has_utt}
        user_has_classifiers={user_has_classifiers}
        selected_technology_split={technology_partitioning}
        selected_classifier_group_id={selected_classifier_group_id}
        selected_classifiers={classifiers}
        utt_version={utt_version}

        is_report_big_for_clustering={is_report_big}

        on_technology_split_select_handler={on_select_technology_split}
        on_select_classifier_group={on_select_classifier_group}
        on_select_classifiers={on_select_classifiers}
        className='mt-2'
      />
    </div>
  )
}

export default TechnologyPartitioningStep