import { get_as_map } from '../utils/utils.js'

export const SAMPLE_LIMIT = 50

export const COST_PER_YEAR_FIELD = 'costPerYear'

export const FIELDS = [
  'cipherFamilyId',
  'status',
  COST_PER_YEAR_FIELD,
  'pendingTerritories',
  'grantedTerritories',
  'expiredTerritories',
  'priorityDate',
  'grantedDate',
  'expiryDate',
  'inactiveDate',
  'title',
  'abstract',
  'activeGrants',
  'patentNumbers',
  'claims',
  'owners',
  'assignees',
  'inventors',
  'cpcCodes',
  'inCitations',
  'outCitationsPatFams',
  'accessionDate',
  'primaryPublication',
  'primaryCpc',
  'firstFilingCountry',
  'deprecatedBy'
]

export const NAV_TOP_SECTION_ID           = 'top'
export const NAV_COSTS_SECTION_ID         = 'costs'
export const NAV_LEGAL_SECTION_ID         = 'legal'
export const NAV_DISPUTES_SECTION_ID      = 'disputes'
export const NAV_DESCRIPTION_SECTION_ID   = 'description'
export const NAV_CLAIMS_SECTION_ID        = 'claims'
export const NAV_PAT_MEMBERS_SECTION_ID   = 'members'
export const NAV_OUT_CITATIONS_SECTION_ID = 'out'
export const NAV_IMAGES_SECTION_ID        = 'images'

export const NAV_TOP_SECTION           = { id: NAV_TOP_SECTION_ID,           label: 'top' }
export const NAV_COSTS_SECTION         = { id: NAV_COSTS_SECTION_ID,         label: 'costs' }
export const NAV_LEGAL_SECTION         = { id: NAV_LEGAL_SECTION_ID,         label: 'legal events' }
export const NAV_DISPUTES_SECTION      = { id: NAV_DISPUTES_SECTION_ID,      label: 'disputes' }
export const NAV_CLAIMS_SECTION        = { id: NAV_CLAIMS_SECTION_ID,        label: 'claims' }
export const NAV_DESCRIPTION_SECTION   = { id: NAV_DESCRIPTION_SECTION_ID,   label: 'description' }
export const NAV_IMAGES_SECTION        = { id: NAV_IMAGES_SECTION_ID,        label: 'images' }

const COST_OPTION_ALL = {id: 'all', name: 'All'}
const COST_OPTION_APPLICATIONS = {id: 'application', name: 'Prosecution'}
const COST_OPTION_RENEWALS = {id: 'renewal', name: 'Renewal'}

export const COST_OPTIONS = [
  COST_OPTION_ALL,
  COST_OPTION_APPLICATIONS,
  COST_OPTION_RENEWALS
]

export const COST_OPTIONS_BY_ID = get_as_map(COST_OPTIONS, 'id')

export const LEGAL_EVENT_CATEGORY_GRANT       = 'grant'
export const LEGAL_EVENT_CATEGORY_EXPIRY      = 'expiry'
export const LEGAL_EVENT_CATEGORY_REASSIGNED  = 'reassigned'
export const LEGAL_EVENT_CATEGORY_PUBLICATION = 'publication'
export const LEGAL_EVENT_CATEGORY_LAPSED      = 'lapsed'
export const LEGAL_EVENT_CATEGORY_SECURITISED = 'securitised'
export const LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT = 'examinationReport'
export const LEGAL_EVENT_CODE_EP_INTG         = 'EP INTG' //intention to grant

export const LEGAL_EVENT_CATEGORY_NAME_MAP = {
  [LEGAL_EVENT_CATEGORY_LAPSED]: 'Lapsed',
  [LEGAL_EVENT_CATEGORY_EXPIRY]: 'Expired',
  withdrawn: 'Withdrawn',
  nullificationOfParts: 'Nullification of parts',
  reinstated: 'Reinstated',
  feePayment: 'Fee payment',
  opposed: 'Opposed',
  examinationReport: 'Examination report',
  extension: 'Extension',
  [LEGAL_EVENT_CATEGORY_REASSIGNED]: 'Reassigned',
  changeOfRepresentative: 'Change of representative',
  spcOrSimilar: 'SPC',
  classification: 'Classification',
  licensed: 'Licensed',
  entryIntoNationalPhase: 'Entry into national phase',
  divisional: 'Divisional',
  [LEGAL_EVENT_CATEGORY_PUBLICATION]: 'Publication',
  correction: 'Correction',
  erratum: 'Erratum',
  other: 'Other',
  uncategorised: 'Uncategorised',
  spcFeePaid: 'SPC fee paid',
  spcChangeOfOwner: 'SPC change of owner',
  spcExpiry: 'SPC expiry',
  licenseCancelled: 'License cancelled',
  applicationWithdrawn: 'Application withdrawn',
  [LEGAL_EVENT_CATEGORY_SECURITISED]: 'Securitised',
  securityCancelled: 'Security cancelled',
  reassignmentCancelled: 'Reassignment cancelled',
  [LEGAL_EVENT_CATEGORY_GRANT]: 'Grant',
  designatedStates: 'Designated states',
  reportOnRetrieval: 'Report on retrieval',
  noOpposition: 'No opposition',
  refusalReasons: 'Refusal reasons',
  requestForExamination: 'Request for examination',
  beginExamination: 'Begin examination',
  correspondsTo: 'Corresponds to',
  administrative: 'Administrative',
  applicationRevival: 'Application revival',
  ipRightMaintenance: 'IP right maintenance',
  republicationAfterModification:'Re-publication after modification',
  applicationFiling: 'Application filing',
  classificationPending:'Classification pending',
  appeal: 'Appeal',
  preGrantReviewRequest: 'Pre-grant review request',
  ipRightReviewRequest: 'IP right review request'
}

export const PREFERRED_COUNTRIES = ['US','EP','WO','GB', 'DE','FR','CA','AU','ES','AT','IT','CH','BR','DK','NL','SE','FI','NO','PL','BE','IL','MX','ZA','HU','JP','CN','KR','TW','SU','RU']

//based on https://www.epo.org/searching-for-patents/data/coverage/regular.html
export const PREFERRED_KIND_CODES_BY_COUNTRY = {
  'US': ['B9', 'B3', 'B8', 'B2', 'B1', 'A', 'C3', 'C2', 'C1', 'E', 'F3', 'F2', 'F1', 'K3', 'K2', 'K1', 'J3', 'J2', 'J1', 'O3', 'O2', 'O1', 'P', 'P2', 'P3', 'P1', 'A9', 'A2', 'A1', 'P9', 'P4', 'H'],
  'EP': ['B9', 'B8', 'B3', 'B2', 'B1', 'A9', 'A8', 'A4', 'A3', 'A1', 'A2', 'C0'],
  'WO': ['A9', 'B1', 'A1', 'A2', 'A8', 'A3', 'A4'],
  'GB': ['C', 'C2', 'C3', 'B', 'B8', 'B', 'A', 'A9', 'A', 'A8', 'D0'],
  'DE': ['C9', 'B9', 'C5', 'B4', 'B3', 'B8', 'C8', 'T4', 'T3', 'T2', 'T1', 'C4', 'C3', 'C2', 'C1', 'C', 'C', 'C2', 'T4', 'T3', 'T2', 'B2', 'A1', 'B1', 'B', 'B'],
  'FR': ['B1', 'A5', 'A6', 'B2', 'A', 'A1']
}
