import {
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID, ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
  ACTIVE_FAMILIES_BY_TECH_ID,
  ALL_FAMILIES_BY_PORTFOLIO_ID,
  ALL_FAMILIES_BY_TECH_ID,
  ALL_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID, EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID,
  EXPIRED_FAMILIES_BY_PORTFOLIO_ID,
  EXPIRED_FAMILIES_BY_TECH_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_TECH_ID,
  GRANTS_BY_TECH_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_TECH_ID
} from '../spec_ids.js'
import { PORTFOLIO_SIZE_GROUP_ID } from './spec_group_ids.js'
import { KEY_ORGANISATION, KEY_STATUS, KEY_TECHNOLOGY } from './spec_keys.js'
import { TAG_ACTIVE, TAG_EXPIRED, TAG_GRANTED, TAG_ORG, TAG_PENDING, TAG_TECH } from './spec_tags.js'
import {
  DEFAULT_1D_NON_TIMESERIES_VIEW,
  DEFAULT_1D_NON_TIMESERIES_VIEWS, DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS, DEFAULT_2D_NON_TIMESERIES_VIEW,
  DEFAULT_2D_NON_TIMESERIES_VIEWS
} from '../views.js'
import { BAR_STACK_ID, COLUMN_CLUSTER_ID, COLUMN_STACK_ID, HEATMAP_ID, TABLE_ID } from '../view_ids.js'
import { get_tabular_view_patents_total } from '../../utils/tabular_view_utils.js'

const HELP_ARTICLE = '28884737166483-Portfolio-Size-charts-datasets'

export const PORTFOLIO_SIZE_GROUP = {
  id: PORTFOLIO_SIZE_GROUP_ID,
  name: 'Portfolio size',
  short_name: 'Size',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
      name: 'active families',
      description: 'Currently active patent families (granted or pending) by organisation.',
      help_article: `${HELP_ARTICLE}#h_01HXE7NVNT2QS4X0C2T1CGQWSX`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ACTIVE, TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_TECH_ID,
      name: 'active families',
      description: 'Currently active patent families (granted or pending) by technology.',
      help_article: `${HELP_ARTICLE}#h_8ea1c8c05e`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_ACTIVE, TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
      name: 'active families',
      description: 'Currently active patent families (granted or pending) by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_01HXE7PPWY1J2C86AAJSG2DGAZ`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ACTIVE, TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: PENDING_FAMILIES_BY_PORTFOLIO_ID,
      name: 'pending families',
      description: 'Currently pending (and actively prosecuted) patent families by organisation.',
      help_article: `${HELP_ARTICLE}#h_b221cb7fac`,
      by: [KEY_ORGANISATION],
      tags: [TAG_PENDING, TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: PENDING_FAMILIES_BY_TECH_ID,
      name: 'pending families',
      description: 'Currently pending (and actively prosecuted) patent families by technology.',
      help_article: `${HELP_ARTICLE}#h_147482dabe`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_PENDING, TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
      name: 'pending families',
      description: 'Currently pending (and actively prosecuted) patent families by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_297bd59384`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_PENDING, TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_PORTFOLIO_ID,
      name: 'granted families',
      description: 'Currently granted patent families by organisation.',
      help_article: `${HELP_ARTICLE}#h_01HXE7Q1ZBQVCCGK227Q9Q0X5H`,
      by: [KEY_ORGANISATION],
      tags: [TAG_GRANTED, TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW
    },
    {
      spec_id: GRANTED_FAMILIES_BY_TECH_ID,
      name: 'granted families',
      description: 'Currently granted patent families by technology.',
      help_article: `${HELP_ARTICLE}#h_b4370859d2`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_GRANTED, TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
      name: 'granted families',
      description: 'Currently granted patent families by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_68e4d535c5`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_GRANTED, TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID,
      name: 'active families',
      description: 'Currently active patent families (granted vs pending) by organisation.',
      help_article: `${HELP_ARTICLE}#h_76ca63b840`,
      by: [KEY_ORGANISATION, KEY_STATUS],
      tags: [TAG_ACTIVE, TAG_PENDING, TAG_GRANTED, TAG_ORG],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
      name: 'active families',
      description: 'Currently active patent families (granted vs pending) by technology.',
      help_article: `${HELP_ARTICLE}#h_adf3a24a8a`,
      by: [KEY_TECHNOLOGY, KEY_STATUS],
      tags: [TAG_ACTIVE, TAG_PENDING, TAG_GRANTED, TAG_TECH],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: GRANTS_BY_TECH_BY_PORTFOLIO_ID,
      name: 'granted patents',
      description: 'Granted patents by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_98efd6ccd3`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      get_tabular_view_summary: get_tabular_view_patents_total,
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID,
      name: 'applications and granted patents',
      short_name: 'apps and grants',
      description: 'Live applications and granted patents by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_1d382517e2`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      get_tabular_view_summary: get_tabular_view_patents_total,
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: EXPIRED_FAMILIES_BY_PORTFOLIO_ID,
      name: 'expired families',
      description: 'Expired patent families by organisation.',
      help_article: `${HELP_ARTICLE}#h_8f1069ec3d`,
      by: [KEY_ORGANISATION],
      tags: [TAG_EXPIRED, TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: EXPIRED_FAMILIES_BY_TECH_ID,
      name: 'expired families',
      description: 'Expired patent families by technology.',
      help_article: `${HELP_ARTICLE}#h_aa53f933b6`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_EXPIRED, TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID,
      name: 'expired families',
      description: 'Expired patent families by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_5d0e5bdd3a`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_EXPIRED, TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: ALL_FAMILIES_BY_PORTFOLIO_ID,
      name: 'all families',
      description: 'All patent families (of any status) by organisation.',
      help_article: `${HELP_ARTICLE}#h_f78070ea0a`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: ALL_FAMILIES_BY_TECH_ID,
      name: 'all families',
      description: 'All patent families (of any status) by technology.',
      help_article: `${HELP_ARTICLE}#h_d5e59b75eb`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: ALL_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
      name: 'all families',
      description: 'All patent families (of any status) by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_4afff5f19d`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
  ]
}
