import React, { useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'
import {RouteWithTracing} from '../RouteWithTracing'

import PatentFamilyListControlsRow from './PatentFamilyListControlsRow.js'
import {
  CIPHER_FAMILY_ID_FIELD_ID,
  DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS,
  ID_TO_FIELD, PAT_FAM_ID_FIELD_ID,
  PRIORITY_DATE_FIELD_ID, SIMILARITY_SCORE_ID
} from '../../model/patent_family_fields.js'
import { DESCENDING, ID_TO_SORT_DIRECTION, SORT_DIRECTIONS } from '../../model/sort_directions.js'
import {
  ID_TO_TABLE_LINE_RESTRICTION,
  SINGLE_LINE_ID,
  TABLE_LINE_RESTRICTIONS
} from '../../model/table_line_restrictions.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../model/patent_family_list_page_sizes.js'
import {
  FAMILIES_TO_DOWNLOAD_THRESHOLD,
  get_es_only_fields,
  get_list_field_ids_preference,
  get_list_view_preference,
  get_page_size_preference,
  get_patent_families_by_ids_as_csv,
  get_patent_link_option_id_preference, get_sorted_families,
  get_table_line_restriction_id_preference,
  remove_unavailable_field_ids,
  update_list_field_ids_preferences,
  update_list_view_preference, update_page_size_preferences,
  update_patent_link_option_id_preference,
  update_table_line_restriction_id_preference
} from '../../utils/patent_family_list_utils.js'
import { CARDS, TABLE } from '../../model/patent_family_list_views.js'
import { PATENT_LINK_OPTION_DEFAULT_ID } from '../../model/patent_links.js'
import { withUser } from '../UserContext.js'
import PatentFamiliesTable from './PatentFamiliesTable.js'
import { GLOBAL_SEARCH_PAGE_TOP, GLOBAL_SEARCH_TABLE_TOP } from '../../constants/layout.js'
import PatentCardList from '../classifiers_editor/components/PatentCardList.js'
import { get_as_map } from '../../utils/utils.js'
import { with_next_subidx, with_prev_subidx } from '../../utils/pagination_utils.js'

import PatentFamilyListActions, { PatentFamilyListActionsWrapper } from './PatentFamilyListActions.js'
import { track_download_event, track_list_event } from '../../utils/tracking_utils.js'
import {
  CSV_FILE_EXT,
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_clean_filename,
  MIMETYPE_CSV, MIMETYPE_XLSX,
  trigger_download
} from '../../utils/download_utils.js'
import ErrorModal from '../ErrorModal.js'
import FamilyDetails from '../family_view/FamilyDetails.js'
import ForwardBackSelector from '../widgets/ForwardBackSelector.js'
import { usePatentKeyboardShortcuts } from '../../utils/keyboard_shortcuts/patent_keyboard_utils.js'
import { KNN_SEARCH } from '../classifiers_editor/constants/classifier_paths.js'
import { FAMILY_SUBPATH } from '../../constants/viewer_paths.js'
import { is_family_details_selected_patent_page, is_family_details_view_page } from '../../utils/viewer_utils.js'
import { BackBtn } from '../widgets/Button.js'
import { has_utt, is_creator } from '../../utils/user_permissions.js'
import TextLink from '../widgets/TextLink.js'
import { get_csv_string, parse_csv } from '../../utils/csv_utils.js'
import CopyToClipboard from '../widgets/CopyToClipboard.js'
import { order_patent_numbers_and_select_best_representation } from '../../utils/family_view_utils.js'
import { add_family_subpath } from '../../utils/url_utils.js'
import { TABLE_ID } from '../../model/view_ids.js'
import PatentFamilyListCountInfo from './PatentFamilyListCountInfo.js'
import PatentFamilyListBooleanSearchBar from './PatentFamilyListBooleanSearchBar.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { get_language_preference } from '../../utils/user_settings_utils.js'
import FamilyViewModal from '../family_view/FamilyViewModal.js'

import cs from '../cipher_styles.module.scss'
import s from './PatentFamilyStaticListContainer.module.scss'

const ListActionBuildReport = ({build_report_link, label}) => {
  return (
    <DropdownItem>
      <TextLink element='a' target='_blank' href={build_report_link} className='d-inline-block'>{label || 'Build report from list'}</TextLink>
    </DropdownItem>
  )
}

const ListActionCopyToClipboard = ({results}) => {
  const text_to_copy = (results || []).map(item => item[CIPHER_FAMILY_ID_FIELD_ID]).join('\n')

  const label = 'Copy family IDs to clipboard'

  return (
    <DropdownItem toggle={false}>
      <CopyToClipboard
        label={label}
        title={label}
        text_to_copy={text_to_copy}
        disabled={(results || []).length === 0}

        trigger_element='div'

        className={cs.no_outline}
      />
    </DropdownItem>
  )
}

const PatentFamilyStaticListContainer = (
  {
    history,
    location,
    base_path,
    context,
    user,
    similar_search_input_family_ids,
    similar_search_input,
    families_list,
    build_report_from_list_path,
    build_clustered_report_from_list_path,
    build_utt_report_from_list_path,
    all_field_ids,
    all_sort_field_ids,
    sort_field_id: sort_field_id_prop,
    sort_direction_id: sort_direction_id_prop,
    size: size_prop,
    search_phrase,

    export_title,
    export_file_name,

    on_change_sort_field_id_handler,
    on_change_sort_direction_id_handler,
    on_change_sort_field_id_and_sort_direction_id_handler,
    on_change_page_size_handler,

    on_change_search_phrase_handler,
    on_include_family_id_in_similar_search_input,

    get_selected_fields_handler,

    is_wizard,

    className
  }) => {
  const { user_settings } = useContext(UserSettingsContext) || {}
  const preferred_language = get_language_preference(user_settings)

  const total_results_count = (families_list || []).length

  const table_line_restriction_id_preference = get_table_line_restriction_id_preference()
  const patent_link_option_id_preference = get_patent_link_option_id_preference()

  const all_fields                 = all_field_ids.map(id => ID_TO_FIELD[id])
  const all_sort_fields            = all_sort_field_ids.map(id => ID_TO_FIELD[id])
  const default_selected_field_ids = remove_unavailable_field_ids(DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS, { is_keyword_search: true, user })

  const selected_field_ids_preference = get_list_field_ids_preference() // try to read from localStorage

  const [should_reorder_list, set_should_reorder_list] = useState(true)
  const [families_list_to_display, set_families_list_to_display] = useState(null)

  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(size_prop || get_page_size_preference() || DEFAULT_PAGE_SIZE)
  const [patent_family_list_view_id, set_patent_family_list_view_id] = useState(get_list_view_preference() || TABLE)
  const [table_line_restriction_id, set_table_line_restriction_id] = useState(table_line_restriction_id_preference || SINGLE_LINE_ID)
  const [patent_link_option_id, set_patent_link_mode_id] = useState(patent_link_option_id_preference || PATENT_LINK_OPTION_DEFAULT_ID)
  const [sort_field_id, set_sort_field_id] = useState(sort_field_id_prop || PRIORITY_DATE_FIELD_ID)
  const [sort_direction_id, set_sort_direction_id] = useState(sort_direction_id_prop || DESCENDING)
  const [selected_field_ids, set_selected_field_ids] = useState(get_initial_selected_field_ids(selected_field_ids_preference || DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS))

  const [subidx, set_subidx] = useState(0)
  const [selected_patent_number, set_selected_patent_number] = useState(null)
  const [show_details, set_show_details] = useState(false)

  const [is_csv_downloading, set_is_csv_downloading] = useState(false)
  const [is_xls_downloading, set_is_xls_downloading] = useState(false)

  const [is_family_tagging_mode_on, set_is_family_tagging_mode_on] = useState(false)
  const [family_tagging_search_phrase, set_family_tagging_search_phrase] = useState(null)

  const [csv_download_error, set_csv_download_error] = useState(null)
  const [xls_download_error, set_xls_download_error] = useState(null)

  const search_input_ref = useRef()

  const num_pages = get_total_num_pages()

  const selected_fields = all_fields.filter(field => _.contains(selected_field_ids, field.id))

  const families_for_page_display = get_families_by_page()

  const is_details_view = is_family_details_view_page(location.pathname)

  const user_has_utt = has_utt(user)

  usePatentKeyboardShortcuts({
    disable: (total_results_count === 0),
    source: KNN_SEARCH,
    on_prev_patent: on_prev_family,
    on_next_patent: on_next_family,
    selected_patent: (families_for_page_display || [])[subidx],
    on_show_details: () => on_show_details(subidx),
    on_hide_details,
  })

  useEffect(() => {
    const _sorted_and_reversed_with_idx = get_sorted_families(families_list, sort_field_id, sort_direction_id).map((item, idx) => ({...item, idx}))
    const cipher_family_id = is_family_details_view_page(location.pathname)
    const patent_number_in_path = is_family_details_selected_patent_page(location.pathname)
    if (cipher_family_id && families_list_to_display == null) {
      const selected_family = _.find(_sorted_and_reversed_with_idx, item => item[CIPHER_FAMILY_ID_FIELD_ID] === cipher_family_id)

      const {idx=0} = selected_family || {}

      const page_updated = Math.floor(idx / page_size )
      const subidx_updated = idx % page_size
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)

      set_page(page_updated)
      set_subidx(subidx_updated)
      set_selected_patent_number(patent_number_in_path || representative_patent_number)

      if (!patent_number_in_path) {
        navigate_to_family_details(selected_family, representative_patent_number)
      }
    }

    set_families_list_to_display(_sorted_and_reversed_with_idx)
    set_should_reorder_list(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [should_reorder_list])

  if (families_list == null) {
    return null
  }

  function get_initial_selected_field_ids(field_ids) {
    if (get_selected_fields_handler) {
      return get_selected_fields_handler(field_ids)
    }

    return get_es_only_fields(field_ids)
  }

  function on_change_page(page) {
    track_list_event(`action="pager" page="${page}" context="${context}"`)
    set_page(page)
  }

  function on_change_page_size(page_size) {
    track_list_event(`action="change_page_size"  page_size="${page_size}" context="${context}"`)
    set_page_size(page_size)
    update_page_size_preferences(page_size)

    if (on_change_page_size_handler) {
      on_change_page_size_handler(page_size)
    }
  }

  function on_change_table_line_restriction_id(table_line_restriction_id) {
    set_table_line_restriction_id(table_line_restriction_id)
    update_table_line_restriction_id_preference(table_line_restriction_id) // save to localStorage
  }

  function on_change_patent_family_list_view_id(patent_family_list_view_id) {
    set_patent_family_list_view_id(patent_family_list_view_id)
    update_list_view_preference(patent_family_list_view_id)
  }

  function on_change_patent_link_option_id(patent_link_option_id) {
    set_patent_link_mode_id(patent_link_option_id)
    update_patent_link_option_id_preference(patent_link_option_id)
  }

  function on_change_selected_field_ids(selected_field_ids) {
    set_selected_field_ids(selected_field_ids)
    update_list_field_ids_preferences(selected_field_ids)
  }

  function on_change_sort_direction_id(sort_direction_id) {
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="${context}"`)
    set_sort_direction_id(sort_direction_id)
    set_should_reorder_list(true)
    if (on_change_sort_direction_id_handler) {
      on_change_sort_direction_id_handler(sort_direction_id)
    }
  }

  function on_change_sort_field_id(sort_field_id) {
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="${context}"`)
    set_sort_field_id(sort_field_id)
    set_should_reorder_list(true)
    if (on_change_sort_field_id_handler) {
      on_change_sort_field_id_handler(sort_field_id)
    }
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="${context}"`)
    set_sort_field_id(sort_field_id)
    set_sort_direction_id(sort_direction_id)
    set_should_reorder_list(true)
    if (on_change_sort_field_id_and_sort_direction_id_handler) {
      on_change_sort_field_id_and_sort_direction_id_handler(sort_field_id, sort_direction_id)
    }
  }

  function on_change_subidx(subidx) {
    set_subidx(subidx)
  }

  function on_show_details(subidx) {
    if (!families_list_to_display || families_list_to_display.length === 0) return
    set_subidx(subidx)

    const selected_family = families_list_to_display[subidx]

    if (!selected_family) return

    const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)
    set_selected_patent_number(representative_patent_number)

    if (base_path) {
      navigate_to_family_details(selected_family, representative_patent_number)
    } else {
      set_show_details(true)
    }
  }

  function on_hide_details() {
    if (is_details_view) {
      history.replace(base_path)
    } else {
      set_show_details(false)
    }
  }

  function on_prev_family() {
    const { page: new_page, subidx: new_subidx } = with_prev_subidx(subidx, page, page_size)
    set_page(new_page)
    set_subidx(new_subidx)

    if (is_details_view) {
      const start_index = new_page * page_size
      const idx = start_index + new_subidx

      const selected_family = families_list_to_display[idx]
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)
      set_selected_patent_number(representative_patent_number)
      navigate_to_family_details(selected_family, representative_patent_number)
    }
  }

  function on_next_family() {
    const total_num_pages = get_total_num_pages()

    const { page: new_page, subidx: new_subidx } = with_next_subidx(subidx, page, page_size, total_num_pages, total_results_count)
    set_page(new_page)
    set_subidx(new_subidx)

    if (is_details_view) {
      const start_index = new_page * page_size
      const idx = start_index + new_subidx

      const selected_family = families_list_to_display[idx]
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)
      set_selected_patent_number(representative_patent_number)
      navigate_to_family_details(selected_family, representative_patent_number)
    }
  }

  function navigate_to_family_details(family, patent_number) {
    const url = add_family_subpath(base_path, family[CIPHER_FAMILY_ID_FIELD_ID], patent_number)

    history.replace(url)
  }

  function get_total_num_pages() {
    return Math.ceil(total_results_count / page_size)
  }

  function prepare_csv_to_download() {
    const family_ids = families_list.map(family => family.patFamId)

    const field_ids = [PAT_FAM_ID_FIELD_ID, ...get_es_only_fields(selected_field_ids)]

    return get_patent_families_by_ids_as_csv(family_ids, field_ids, PRIORITY_DATE_FIELD_ID, DESCENDING)
      .then(csv_response => {
        //response from the service is nicely formatted for download but it doesn't contain custom fields and can't be custom ordered so we transform it here and add values that were missing
        const parsed_response = parse_csv(csv_response)
        const families_from_response_as_objects = parsed_response.slice(1, parsed_response.length).map(list => _.object(field_ids, list))

        const families_from_response_to_patfamid = get_as_map(families_from_response_as_objects, 'patFamId')

        const header_row = selected_field_ids.map(field_id => ID_TO_FIELD[field_id].name)

        const value_rows = families_list_to_display.map(family => {
          const {patFamId} = family
          const formatted_family = families_from_response_to_patfamid[patFamId]
          return selected_field_ids.map(field_id => {
            if (field_id === SIMILARITY_SCORE_ID) {
              const similarity_score = family[SIMILARITY_SCORE_ID]

              if (!_.isNumber(similarity_score)) {
                return similarity_score || ''
              }

              return similarity_score.toFixed(2)
            }
            return formatted_family[field_id]
          })
        })

        return get_csv_string([header_row, ...value_rows])
      })
  }

  function download_as_csv() {
    set_is_csv_downloading(true)

    track_download_event(`obj="families_list" format="CSV" context="${context}"`)

    prepare_csv_to_download()
      .then(csv_response => {
        const csv = csv_response
        set_is_csv_downloading(false)
        const filename = get_clean_filename( `${export_file_name || 'export_file'}${CSV_FILE_EXT}`)
        trigger_download(csv, MIMETYPE_CSV, filename)
      })
      .catch(error => {
        set_is_csv_downloading(false)
        set_csv_download_error(error)
      })
  }

  function download_as_xls() {
    set_is_xls_downloading(true)

    track_download_event(`obj="families_list" format="XLS" context="${context}"`)

    prepare_csv_to_download()
      .then(csv_response => {

        const input = {
          title: export_title || '',
          description: '',
          view_id: TABLE_ID,
          data: csv_response,
          publication_link_type: patent_link_option_id
        }

        return fetch_excel_document([input], false, true)
      })
      .then(arraybuffer => {
        set_is_xls_downloading(false)
        const filename = get_clean_filename( `${export_file_name || 'export_file'}${EXCEL_FILE_EXT}`)
        trigger_download(arraybuffer, MIMETYPE_XLSX, filename)
      })
      .catch(error => {
        set_is_xls_downloading(false)
        set_xls_download_error(error)
      })
  }

  function get_family_idx_by_subix() {
    const start_index = page * page_size
    return start_index + subidx
  }

  function get_families_by_page() {
    const start_index = page * page_size
    return families_list_to_display ? families_list_to_display.slice(start_index, start_index + page_size) : []
  }

  function on_toggle_family_tagging_mode() {
    set_is_family_tagging_mode_on(!is_family_tagging_mode_on)
  }

  function on_update_family_tagging_search_phrase(text) {
    set_family_tagging_search_phrase(text)
  }

  function change_selected_patent_number(family, patent_number) {
    set_selected_patent_number(patent_number)
    navigate_to_family_details(family, patent_number)
  }

  function apply_search_phrase(search_phrase) {
    if (search_phrase == null) return
    on_change_search_phrase_handler(search_phrase)
  }

  function get_custom_actions() {
    const has_any_report_building_option = is_creator(user) && (build_report_from_list_path || build_clustered_report_from_list_path || (user_has_utt && build_utt_report_from_list_path))

    const custom_actions = (
      <>
        {has_any_report_building_option &&
          <>
            <DropdownItem header>Report</DropdownItem>
            {build_report_from_list_path && <ListActionBuildReport build_report_link={build_report_from_list_path} label='Start report from list' /> }
            {build_clustered_report_from_list_path && <ListActionBuildReport build_report_link={build_clustered_report_from_list_path} label={user_has_utt ? 'Cluster this list' : null} /> }
            {user_has_utt && build_utt_report_from_list_path && <ListActionBuildReport build_report_link={build_utt_report_from_list_path} label='Build UTT report from list'/> }
          </>
        }

        <DropdownItem header>Misc</DropdownItem>
        <ListActionCopyToClipboard results={families_list_to_display} />
      </>
    )

    return custom_actions
  }

  return (
    <div className={className}>
      <RouteWithTracing
        exact={true}
        path={base_path}
        render={() => (

          <div>
            {!is_wizard &&
              <PatentFamilyListActionsWrapper is_csv_downloading={is_csv_downloading} is_xls_downloading={is_xls_downloading} className='pb-2 mb-2 fs-unmask'>
                <PatentFamilyListActions
                  download_as_csv_handler={download_as_csv}
                  download_as_xls_handler={download_as_xls}

                  is_export_in_progress={is_csv_downloading}
                  no_field_ids_selected={selected_field_ids.length === 0}

                  list_no_results={total_results_count === 0}
                  list_too_long={total_results_count > FAMILIES_TO_DOWNLOAD_THRESHOLD}

                  CustomActions={get_custom_actions()}

                  className='fs-unmask'
                />
              </PatentFamilyListActionsWrapper>
            }

            <PatentFamilyListBooleanSearchBar
              search_input_ref={search_input_ref}
              search_phrase={search_phrase || ''}
              on_change_search_phrase_handler={apply_search_phrase}
              is_fetching={false}
            />

            <PatentFamilyListControlsRow
              className={cn('mt-3 fs-unmask', s.controls_row)}
              context={context || ''} // for tracking

              hide_status={true}
              hide_timerange={true}

              sort_fields={all_sort_fields}
              id_to_sort_field={ID_TO_FIELD}
              selected_sort_field_id={sort_field_id}
              on_change_sort_field_id={on_change_sort_field_id}
              sort_directions={SORT_DIRECTIONS}
              id_to_sort_direction={ID_TO_SORT_DIRECTION}
              selected_sort_direction_id={sort_direction_id}
              on_change_sort_direction_id={on_change_sort_direction_id}

              patent_family_list_view_id={patent_family_list_view_id}
              on_change_patent_family_list_view_id={on_change_patent_family_list_view_id}

              all_fields={all_fields}
              selected_field_ids={selected_field_ids}
              default_selected_field_ids={default_selected_field_ids}
              on_change_selected_field_ids={on_change_selected_field_ids}

              table_line_restrictions={TABLE_LINE_RESTRICTIONS}
              id_to_table_line_restriction={ID_TO_TABLE_LINE_RESTRICTION}
              selected_table_line_restriction_id={table_line_restriction_id}
              on_change_table_line_restriction_id={on_change_table_line_restriction_id}

              page_sizes={PAGE_SIZES}
              selected_page_size={page_size}
              on_change_page_size={on_change_page_size}

              current_page={page}
              num_pages={num_pages}
              on_change_current_page={on_change_page}
            />

            <PatentFamilyListCountInfo
              count={total_results_count || 0}
              className='fs-unmask'
            />

            {(patent_family_list_view_id === TABLE) &&
              <PatentFamiliesTable
                className='mt-3'
                headerClassName={s.table_header}
                page_top={GLOBAL_SEARCH_PAGE_TOP}
                table_top={GLOBAL_SEARCH_TABLE_TOP}
                similar_search_input_family_ids={similar_search_input_family_ids}
                similar_search_input={similar_search_input}
                patfams={families_for_page_display}

                subidx={subidx}

                fields={selected_fields}
                selected_table_line_restriction_id={table_line_restriction_id}
                selected_sort_field_id={sort_field_id} // relates to 'fields' prop
                selected_sort_direction_id={sort_direction_id}
                on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
                on_include_family_id_in_similar_search_input={on_include_family_id_in_similar_search_input}
                on_change_subidx={on_change_subidx}
                on_show_detail={on_show_details}

                selected_patent_link_option_id={patent_link_option_id}
              />
            }

            {(patent_family_list_view_id === CARDS) &&
              <PatentCardList
                className='mt-3'
                top_margin={GLOBAL_SEARCH_PAGE_TOP}

                patents={families_for_page_display}
                subidx={subidx}
                on_show_details={on_show_details}

                num_pages={num_pages}
                current_page={page}
                on_change_current_page={on_change_page}
                on_change_subidx={on_change_subidx}
                similar_search_input_family_ids={similar_search_input_family_ids}
                similar_search_input={similar_search_input}
                on_include_family_id_in_similar_search_input={on_include_family_id_in_similar_search_input}
              />
            }

            {show_details &&
              <FamilyViewModal
                on_hide={on_hide_details}

                patfam={(families_list_to_display || [])[get_family_idx_by_subix()]}
                on_prev={on_prev_family}
                on_next={on_next_family}
                idx={get_family_idx_by_subix()}
                total_num_families={total_results_count}

                /*is_fetching={is_fetching}
                error_fetching={fetching_pat_families_error}
                on_retry={this.fetch}*/

                patent_link_option_id={patent_link_option_id}
                on_change_patent_link_option_id={on_change_patent_link_option_id}

                //family tagging
                is_family_tagging_mode_on={is_family_tagging_mode_on}
                family_tagging_search_phrase={family_tagging_search_phrase || ''}
                on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}
                //end of family tagging
              />
            }
          </div>
        )}
      />

      <RouteWithTracing
        path={`${base_path}/${FAMILY_SUBPATH}/:cipher_family_id`}
        render={() => {
          const idx = get_family_idx_by_subix()
          const selected_family = families_list_to_display ? families_list_to_display[idx] : null

          if (!selected_family) return null

          return (
            <div>
              <div className={cn('d-flex justify-content-between sticky-top', s.details_nav_container)}>
                <BackBtn
                  on_click={on_hide_details}
                />

                <ForwardBackSelector
                  className='ml-auto'
                  label={'family'}
                  current_idx={idx}
                  min_idx_incl={0}
                  max_idx_excl={total_results_count}
                  on_prev={on_prev_family}
                  on_next={on_next_family}
                />
              </div>

              <FamilyDetails
                family={selected_family}
                top_className={s.family_details_controls_row}

                patent_link_option_id={patent_link_option_id}
                on_change_patent_link_option_id={on_change_patent_link_option_id}

                selected_patent_number={selected_patent_number}
                on_change_selected_patent_number={(patent_number) => {change_selected_patent_number(selected_family, patent_number)}}

                is_family_tagging_mode_on={is_family_tagging_mode_on}
                family_tagging_search_phrase={family_tagging_search_phrase}
                on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}
              />
            </div>
          )}}
      />

      {csv_download_error &&
        <ErrorModal
          on_hide={() => set_csv_download_error(null)}
          error={csv_download_error}
          context='exporting families as csv'
        />
      }

      {xls_download_error &&
        <ErrorModal
          on_hide={() => set_xls_download_error(null)}
          error={xls_download_error}
          context='exporting families to Excel'
        />
      }

    </div>
  )
}

export default withRouter(withUser(PatentFamilyStaticListContainer))