import axios from 'axios'

import { add_source_err_to_target_err, is_4xx_error } from './axios_utils.js'
import {
  CHECK_MATCHING_JOB_URL,
  MATCH_PATENT_URL,
  SYNCH_MATCH_PATENTS_URL,
  ASYNCH_MATCH_PATENTS_URL,
  CUSTOM_CLUSTERED_MATCHING_URL
} from '../constants/urls.js'
import { get_mime_type } from './download_utils.js'
import { get_as_map } from './utils.js'
import { CN, EP, KR, US, WO, JP } from '../constants/countries.js'

export const MODE_1_COLUMN = {id: '1c', name: 'Flat upload'}
export const MODE_3_COLUMN = {id: '3c', name: 'Full upload'}
export const MODE_2_COLUMN = {id: '2c', name: 'Full 2 upload'}

export const TEXT_UPLOAD_MODE_ID = 'text'
export const FILE_UPLOAD_MODE_ID = 'file'
export const SPIF_FILE_UPLOAD_MODE_ID = 'spif'

export const UPLOAD_MODE_TEXT = {id: TEXT_UPLOAD_MODE_ID, name: 'Paste text', short_name: 'text'}
export const UPLOAD_MODE_FILE = {id: FILE_UPLOAD_MODE_ID, name: 'Upload Excel or CSV', short_name: 'Excel/CSV'}
export const UPLOAD_MODE_SPIF = {id: SPIF_FILE_UPLOAD_MODE_ID, name: 'Upload SPIF', short_name: 'SPIF'}

export const ONE_COLUMN_UPLOAD_MODES = [
  UPLOAD_MODE_TEXT, UPLOAD_MODE_FILE, UPLOAD_MODE_SPIF
]
export const THREE_COLUMN_UPLOAD_MODES = [
  UPLOAD_MODE_TEXT, {...UPLOAD_MODE_FILE, name: 'Upload CSV or TSV', short_name: 'CSV/TSV'}
]
export const DEFAULT_UPLOAD_MODE = TEXT_UPLOAD_MODE_ID
export const ID_TO_UPLOAD_MODE = get_as_map(ONE_COLUMN_UPLOAD_MODES, 'id')

export const COUNTRY_PREFIXES = [US, EP, CN, JP, KR, WO]
export const DEFAULT_COUNTRY_PREFIX = US
export const APPS = 'Applications'
export const PUBS = 'Publications'
export const DOCUMENT_KIND = [APPS, PUBS]

const MATCHING_ERROR_MSG = 'Unable to fetch patent families by publication from patent matching'

export const MAX_CHARS_FOR_SYNC_MATCHING =  1400000

export const QUEUED = 'Queued'

export function file_details(file) {
  const { size=0, name='' } = file || {}

  const size_in_kb = parseFloat(size * 0.001).toFixed(2)
  const size_in_mb = parseFloat(size * 0.000001).toFixed(2)
  return `${name} (${size_in_mb < 1 ? (size_in_kb + 'KB') : (size_in_mb + 'MB')})`
}

export function validate_custom_clustered_text_upload(input, data_format){
  const url = CUSTOM_CLUSTERED_MATCHING_URL + '/families/match?format_type='+ data_format
  return axios.post(url, input)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to validate custom clustered families: ')
      throw wrapped_err
    })
}

export function validate_custom_clustered_file_upload(input_file){
  const body_form = new FormData()
  body_form.append('file', input_file)
  const file_type = get_mime_type(input_file)
  const uri = CUSTOM_CLUSTERED_MATCHING_URL + '/families/match'
  return axios({
      method: 'POST',
      url: uri,
      params: { file_type },
      data: body_form,
    })
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to validate custom clustered from file: ')
      throw wrapped_err
    })
}

/**
 * Given a *big* list of either publications or families it tries to match them against our patent family database
 * It validates the input when the user tries to upload a custom portfolio
 * @param patent_list
 * @returns {Promise<AxiosResponse<any>>}
 */

//TODO: return the data only rather than the old code? This implies to change the code in the calling function
export function match_pub_or_pat_fams(patent_list, strict_mode, spif, apps_only, pubs_only) {
  return axios({
    method: 'POST',
    url: SYNCH_MATCH_PATENTS_URL,
    params: {
      strict_mode,
      from_spif: spif || false,
      document_kind: (apps_only ? 'app' : pubs_only ? 'pub' : null)
    },
    timeout: 600000,
    data: patent_list,
    headers: {
      'Content-Type': 'text/plain'
    }
  })
  .catch(err => {
    const error_msg = `${MATCHING_ERROR_MSG}${is_4xx_error(err) ? `: ${spif ? 'Non SPIF compliant' : 'Incorrect patent format'}`: ''}`
    const wrapped_err = add_source_err_to_target_err(err, new Error(error_msg))
    throw wrapped_err
  })
}

export function match_pub_or_pat_fams_from_file_asynch(input_file, spif, user_id) {
  return match_pub_or_pat_fams_from_file(input_file, ASYNCH_MATCH_PATENTS_URL, spif, user_id)
}

export function match_pub_or_pat_fams_from_file_synch(input_file, spif) {
  return match_pub_or_pat_fams_from_file(input_file, SYNCH_MATCH_PATENTS_URL, spif)
}

export const BIG_SIZE_BYTES = 2000000 //units in bytes (~2MB)

export function is_big_file(file_to_upload, max_size){
  const {size = 0} = file_to_upload || {}
  return size > (max_size || BIG_SIZE_BYTES)
}

export const MAX_SIZE_BYTES = 40000000//units in bytes (~40MB)
export function is_too_big_file(file_to_upload, max_size) {
  const {size = 0} = file_to_upload || {}
  return size > (max_size || MAX_SIZE_BYTES)
}

function match_pub_or_pat_fams_from_file(input_file, uri, spif=false, uuid='') {
  const body_form = new FormData()
  body_form.append('file', input_file)
  const file_type = get_mime_type(input_file)
  return axios({
    method: 'POST',
    url: uri,
    params: {strict_mode: false, file_type: file_type, uuid: uuid, from_spif: spif},
    data: body_form,
  })
    .catch(err => {
      const {response} = err || {}
      const {status} = response || {}

      const error_msg = `${MATCHING_ERROR_MSG}${(status && status >= 400 && status < 500) ? `: ${spif ? 'Non SPIF compliant' : 'Incorrect patent format'}`: ''}`
      throw new Error(error_msg)
    })
}

export function check_patent_matching_job_progress(job_id){
  return axios({
    method: 'GET',
    url: CHECK_MATCHING_JOB_URL + '?job_id=' + job_id,
    timeout: 12000000,
  })
    .catch(() => {
      throw new Error(MATCHING_ERROR_MSG)
    })

}

export function cancel_patent_matching(job_number){
  return axios({
    method: 'PUT',
    url: `${CHECK_MATCHING_JOB_URL}?job_id=${job_number}`,
    timeout: 600000,
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), MATCHING_ERROR_MSG)
      throw wrapped_err
    })
}
/**
 * Give a *small* list of publications or a family it validates them against our patent family database
 * This function is invoked when the user amends the input already first time validated of a custom portfolio
 * @param patent_list
 * @returns {Promise<AxiosResponse<any>>}
 */
export function match_patent_family(patent_list, lineno) {
  return axios({
    method: 'GET',
    url: `${MATCH_PATENT_URL}?text=${patent_list}&lineno=${lineno}`,
    timeout: 600000,
    headers: {'Content-Type': 'text/plain'}
  })
  .catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), MATCHING_ERROR_MSG)
    throw wrapped_err
  })
}


//TODO: rather than adding the cc in the client better to send it to the backend for the matching service to deal with it
export function add_cc_prefix_to_patents (patents_list, country_code) {
  const lines = patents_list.split('\n')
  const prefixed_patents = lines.map(line => country_code + line).join('\n')
  return prefixed_patents
}