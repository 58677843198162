import { GEOGRAPHY_GROUP_ID } from './spec_group_ids.js'
import {
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID,
  ACTIVE_FAMILIES_BY_REGION_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID,
  APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID,
  APPLICATIONS_BY_COUNTRY_ID,
  APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID,
  APPLICATIONS_BY_TECH_BY_COUNTRY_ID,
  APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID,
  GRANTS_BY_COUNTRY_ID,
  GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID,
  GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID,
  PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PORTFOLIO_ID,
  PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PRIORITY_YEAR_ID,
  PATFAMS_BY_FIRST_FILING_COUNTRY_BY_TECH_ID,
  PATFAMS_WITH_GRANTS_BY_COUNTRY_ID,
  PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID,
  PATFAMS_WITH_GRANTS_BY_REGION_ID,
  PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID,
  PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID,
  PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID
} from '../spec_ids.js'
import { TAG_ACTIVE, TAG_GEO, TAG_GRANTED, TAG_ORG, TAG_TECH, TAG_TIME } from './spec_tags.js'
import {
  KEY_COUNTRY,
  KEY_FIRST_FILING_COUNTRY,
  KEY_ORGANISATION,
  KEY_PRIORITY_YEAR,
  KEY_PUBLICATION_YEAR,
  KEY_REGION,
  KEY_TECHNOLOGY
} from './spec_keys.js'
import {
  BAR_STACK_ID,
  BUBBLE_ID,
  CHOROPLETH_MAP_ID,
  COLUMN_CLUSTER_ID,
  COLUMN_STACK_ID,
  HEATMAP_ID,
  LINE_ID,
  PIE_ID,
  TABLE_ID,
  TREEMAP_ID
} from '../view_ids.js'
import {
  DEFAULT_1D_NON_TIMESERIES_VIEW,
  DEFAULT_1D_NON_TIMESERIES_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
  DEFAULT_2D_TIMESERIES_VIEWS
} from '../views.js'
import { is_schema_version_15_or_above, not_available } from '../../utils/data_availability_utils.js'
import { get_tabular_view_patents_total } from '../../utils/tabular_view_utils.js'

const HELP_ARTICLE = '28886441815443-Geography-charts-datasets'

export const GEOGRAPHY_GROUP =   {
  id: GEOGRAPHY_GROUP_ID,
  name: 'Geography',
  short_name: 'Geog',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: ACTIVE_FAMILIES_BY_REGION_ID,
      name: 'active families',
      description: 'Currently active patent families (granted or pending) by region.',
      help_article: `${HELP_ARTICLE}#h_f55fa3eca3`,
      by: [KEY_REGION],
      tags: [TAG_ACTIVE, TAG_GEO],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID,
      name: 'active families',
      description: 'Currently active patent families (granted or pending) by organisation and region.',
      help_article: `${HELP_ARTICLE}#h_82192998b9`,
      by: [KEY_REGION, KEY_ORGANISATION],
      tags: [TAG_ACTIVE, TAG_GEO, TAG_ORG],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID,
      name: 'active families',
      description: 'Currently active patent families (granted or pending) by technology and region.',
      help_article: `${HELP_ARTICLE}#h_c6c5e0caac`,
      by: [ KEY_REGION, KEY_TECHNOLOGY],
      tags: [TAG_ACTIVE, TAG_GEO, TAG_TECH],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID,
      name: 'granted families',
      description: 'Priority year of granted families, and territory.',
      help_article: `${HELP_ARTICLE}#h_ce6d75f3f1`,
      by: [KEY_PRIORITY_YEAR, KEY_COUNTRY],
      tags: [TAG_GRANTED, TAG_GEO, TAG_TIME],
      available_view_ids: [COLUMN_STACK_ID, LINE_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID,
      name: 'granted families',
      description: 'Publication year of granted families, and territory.',
      help_article: `${HELP_ARTICLE}#h_0cd17c9142`,
      by: [KEY_PUBLICATION_YEAR, KEY_COUNTRY],
      tags: [TAG_GRANTED, TAG_GEO, TAG_TIME],
      available_view_ids: [COLUMN_STACK_ID, LINE_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: PATFAMS_WITH_GRANTS_BY_COUNTRY_ID,
      name: 'families with grants',
      description: 'Number of patent families that have at least one current grant in the respective territory.',
      help_article: `${HELP_ARTICLE}#h_45963b5132`,
      by: [KEY_COUNTRY],
      tags: [TAG_GEO],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
      name: 'families with grants',
      description: 'Number of patent families that have at least one current grant in the respective territory, by organisation.',
      help_article: `${HELP_ARTICLE}#h_9e3ecf94ed`,
      by: [KEY_COUNTRY, KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID,
      name: 'families with grants',
      description: 'Number of patent families that have at least one current grant in the respective territory, by technology.',
      help_article: `${HELP_ARTICLE}#h_47ecd5f5a2`,
      by: [KEY_COUNTRY, KEY_TECHNOLOGY],
      tags: [TAG_GEO, TAG_TECH],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: PATFAMS_WITH_GRANTS_BY_REGION_ID,
      name: 'families with grants',
      description: 'Number of patent families that have at least one current grant in the respective region.',
      help_article: `${HELP_ARTICLE}#h_2fdab8262b`,
      by: [KEY_REGION],
      tags: [TAG_GEO],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID
    },
    {
      spec_id: PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID,
      name: 'families with grants',
      description: 'Number of patent families that have at least one current grant in the respective region, by organisation.',
      help_article: `${HELP_ARTICLE}#h_704ecf9138`,
      by: [KEY_REGION, KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID
    },
    {
      spec_id: PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID,
      name: 'families with grants',
      description: 'Number of patent families that have at least one current grant in the respective region, by technology. ',
      help_article: `${HELP_ARTICLE}#h_2790625592`,
      by: [KEY_REGION, KEY_TECHNOLOGY],
      tags: [TAG_GEO, TAG_TECH],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: APPLICATIONS_BY_COUNTRY_ID,
      name: 'applications',
      description: 'Currently active individual patent applications per territory.',
      help_article: `${HELP_ARTICLE}#h_814dd5b738`,
      by: [KEY_COUNTRY],
      tags: [TAG_GEO],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID,
      name: 'applications',
      description: 'Currently active individual patent applications per territory, by organisation.',
      help_article: `${HELP_ARTICLE}#h_d622894876`,
      by: [KEY_COUNTRY, KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: APPLICATIONS_BY_TECH_BY_COUNTRY_ID,
      name: 'applications',
      description: 'Currently active individual patent applications per territory, by technology.',
      help_article: `${HELP_ARTICLE}#h_09a36888d2`,
      by: [KEY_COUNTRY, KEY_TECHNOLOGY],
      tags: [TAG_GEO, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: GRANTS_BY_COUNTRY_ID,
      name: 'granted patents',
      description: 'Currently active individual patent grants per territory.',
      help_article: `${HELP_ARTICLE}#h_1691af23fb`,
      by: [KEY_COUNTRY],
      tags: [TAG_GEO],
      available_view_ids: [CHOROPLETH_MAP_ID, PIE_ID, COLUMN_STACK_ID, BAR_STACK_ID, TREEMAP_ID, TABLE_ID],
      default_view_id: CHOROPLETH_MAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
      name: 'granted patents',
      description: 'Currently active individual patent grants per territory, by organisation.',
      help_article: `${HELP_ARTICLE}#h_a40e7a851c`,
      by: [KEY_COUNTRY, KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID,
      name: 'granted patents',
      description: 'Currently active individual patent grants per territory, by technology.',
      help_article: `${HELP_ARTICLE}#h_a40e7a851c`,
      by: [KEY_COUNTRY, KEY_TECHNOLOGY],
      tags: [TAG_GEO, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
      name: 'applications and granted patents',
      short_name: 'apps and grants',
      description: 'Live applications and granted patents per territory, by organisation.',
      help_article: `${HELP_ARTICLE}#h_89e648f728`,
      by: [KEY_COUNTRY ,KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID,
      name: 'applications and granted patents',
      short_name: 'apps and grants',
      description: 'Live applications and granted patents per territory, by technology.',
      help_article: `${HELP_ARTICLE}#h_40adddb282`,
      by: [KEY_COUNTRY, KEY_TECHNOLOGY],
      tags: [TAG_GEO, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID,
      name: 'applications versus granted patents',
      short_name: 'apps vs. grants',
      description: 'Split between applications and granted patents per territory.',
      help_article: `${HELP_ARTICLE}#h_0215bed653`,
      by: [KEY_COUNTRY],
      tags: [TAG_GEO],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: HEATMAP_ID,
      get_tabular_view_summary: get_tabular_view_patents_total,
    },
    {
      spec_id: PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PRIORITY_YEAR_ID,
      name: 'families',
      description: 'Families by first filing territory and priority year.',
      help_article: `${HELP_ARTICLE}#01HWN4XH0ZDVDP03PMBVGWDWDB`,
      by: [KEY_FIRST_FILING_COUNTRY, KEY_PRIORITY_YEAR],
      tags: [TAG_TIME, TAG_GEO],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: COLUMN_STACK_ID,
      check_if_available: is_schema_version_15_or_above,
    },
    {
      spec_id: PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PORTFOLIO_ID,
      name: 'families',
      description: 'Families by first filing territory, by organisation.',
      help_article: `${HELP_ARTICLE}#h_914d4168d6`,
      by: [KEY_FIRST_FILING_COUNTRY, KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: COLUMN_STACK_ID,
      check_if_available: is_schema_version_15_or_above
    },    {
      spec_id: PATFAMS_BY_FIRST_FILING_COUNTRY_BY_TECH_ID,
      name: 'families',
      description: 'Families by first filing territory, by technology.',
      help_article: `${HELP_ARTICLE}#h_44602041eb`,
      by: [KEY_FIRST_FILING_COUNTRY, KEY_TECHNOLOGY],
      tags: [TAG_GEO, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS,
      default_view_id: COLUMN_STACK_ID,
      check_if_available: is_schema_version_15_or_above
    },
    {
      check_if_available: not_available,
      spec_id: GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID
    },
    {
      check_if_available: not_available,
      spec_id: PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID
    },
    {
      check_if_available: not_available,
      spec_id: GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID
    },
    {
      check_if_available: not_available,
      spec_id: GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID
    },
  ]
}