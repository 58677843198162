import React from 'react'
import cn from 'classnames'

import { OnOffButton } from './Button.js'

/**
 * Renders a bunch of item objects as buttons. Shows the item with selected_id as selected.
 * @param {} size (optional) can be 'sm' or 'lg' (default size is medium).
 * @param {} className (optional)
 * @param {} items Array of item objects (i.e. with id and name properties)
 * @param {} selected_id
 * @param {} on_change_selected_id
 * @returns {}
 */
const ButtonGroup = ({ size, className, btnClassName, activeBtnClassName, items, selected_id, on_change_selected_id }) => {

  function handle_click(id) {
    const is_selected = (id === selected_id)

    if (is_selected) return

    on_change_selected_id(id)
  }

  return (
    <div className={className}>
      {items.map((item, i) => {
        const { id, name, title } = item
        const is_selected = (id === selected_id)
        return (
          <OnOffButton
            key={i}
            is_on={is_selected}
            size={size}
            onClick={() => handle_click(id)}
            title={title || name}
            className={cn(
              btnClassName,
              {[activeBtnClassName]: is_selected && activeBtnClassName}
            )}
          >
            {name}
          </OnOffButton>
        )
      })}
    </div>
  )
}

export default ButtonGroup
