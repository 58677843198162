import React from 'react'
import cn from 'classnames'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

import OrganisationSimple from './OrganisationSimple.js'
import { IgnoreIcon, ToggleDownIcon } from '../widgets/IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './OrgSearchMultipleMatchesDropdown.module.scss'

const IgnoreDisplay = ({className}) => {
  return (
    <div className={className}>
      <OrganisationSimple
        org={{name: '(ignore)'}}
      >
        <div className={cn(cs.font_size_large, cs.cipher_blue_text)}>
          <IgnoreIcon />
        </div>
      </OrganisationSimple>
    </div>
  )
}

const MenuOptionWrapper = ({on_click, children}) => {
  return (
    <DropdownItem onClick={on_click} tag='div'>
      {children}
    </DropdownItem>
  )
}

const OrgSearchMultipleMatchesDropdown = ({ best_match, all_match_options, on_update_best_match, is_ignore, on_ignore }) => {
  const is_menu_disabled = is_ignore && !on_ignore

  const show_ignored_as_best_match = is_ignore && on_ignore

  return (
    <div className='d-flex flex-wrap'>
      <UncontrolledDropdown className='w-100 mr-4'>
        <DropdownToggle disabled={is_menu_disabled} className={cn('py-1', 'w-100', s.org_match_dropdown_toggle)}>
          <span className='d-flex'>
            <span className='w-100'>
              {show_ignored_as_best_match && <IgnoreDisplay />}
              {!show_ignored_as_best_match &&
                <OrganisationSimple org={best_match}/>
              }
            </span>
            <span className='d-flex flex-row'>
              <div className={cn('d-flex', 'align-items-center', s.chevron_icon)}>
              <ToggleDownIcon />
            </div>
            </span>
          </span>
        </DropdownToggle>

        <DropdownMenu className={s.org_match_dropdown} right >
          {all_match_options.map((result, i) => (
            <MenuOptionWrapper key={i} on_click={() => on_update_best_match(result)}>
              <OrganisationSimple
                org={result}
              />
            </MenuOptionWrapper>
          ))}

          {on_ignore &&
            <MenuOptionWrapper on_click={on_ignore}>
              <IgnoreDisplay/>
            </MenuOptionWrapper>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default OrgSearchMultipleMatchesDropdown