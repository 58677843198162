import React from 'react'

import { DISPUTE_TYPE_OPTIONS, DISPUTE_TYPE_OPTION_ALL } from '../../constants/dispute_routes.js'

import { get_csv_string } from '../../utils/csv_utils.js'
import {
  get_disputes,
  filter_disputes,
  what_dispute_role_from_spec,
  format_status_with_role,
  format_is_npe,
  get_dispute_details_link,
  format_route,
  get_start_dates_extent,
  get_disputes_summary_data,
  is_cross_disputes_role,
  get_disputes_summary_columns_to_export
} from '../../utils/disputes_utils.js'
import { sort_table_data } from '../../utils/item_utils.js'
import { get_group_and_spec_name } from '../../utils/spec_utils.js'
import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from '../../model/spec_groups.js'
import { get_clean_view_id } from '../../utils/download_utils.js'

import {
  DETAILS_FIELD,
  DETAILS_LINK_FIELD,
  DISPUTE_ID_FIELD,
  DURATION_FIELD,
  get_columns_to_export,
  IS_DEFENDANT_NPE_FIELD,
  IS_NPE_FIELD,
  IS_PLAINTIFF_NPE_FIELD,
  OUTCOMES,
  OUTCOME_FIELD,
  ALL_PARTY_TYPES,
  ROUTE_FIELD,
  get_dispute_filter_values_or_defaults
} from '../../model/disputes.js'
import { ROLE_DEFENDANT } from '../../model/dispute_roles.js'

import DisputesTable from './DisputesTable.js'
import DisputeTypeFilterControl from './DisputeTypeFilterControl.js'

import { PaneHeader } from '../widgets/PaneHeader.js'
import { TableSummary } from './TableSummary.js'
import DefaultLabelMultiItemDropdownSelector from '../widgets/DefaultLabelMultiItemDropdownSelector.js'
import DateRangeSelectorWithSliderAndCalendars from '../widgets/DateRangeSelectorWithSliderAndCalendars.js'
import { format_dispute_status, format_duration, format_integer_with_comma, pluralise_text } from '../../utils/utils.js'
import TableThumbnail from '../widgets/TableThumbnail.js'
import { CrossDisputesSummaryTable } from './CrossDisputesSummaryTable.js'
import { RoleDisputesSummaryTable } from './RoleDisputesSummaryTable.js'
import NoDataInSelection from '../NoDataInSelection.js'
import { MainReportItemDesc } from '../viewer/MainReportItemDesc.js'

import s from './DisputesContainer.module.scss'

const OUTCOME_ITEMS = OUTCOMES.map(outcome => ({ id: outcome, name: format_dispute_status(outcome)}))

function get_plotly_data({ chart_type, spec, data, key_dims, item, deref_data, external_report_id, selected_sort_field_id, selected_sort_direction_id }) {
  const csv_data_rows = get_main_table_data_value_rows({ chart_type, spec, data, key_dims, item, deref_data, external_report_id, is_csv: false, selected_sort_field_id, selected_sort_direction_id })
  return get_csv_string(csv_data_rows)
}

function get_csv_value_rows({ spec, data, item, deref_data, external_report_id, selected_sort_field_id, selected_sort_direction_id }) {
  return get_main_table_data_value_rows({ spec, data, item, deref_data, external_report_id, is_csv: true, selected_sort_field_id, selected_sort_direction_id })
}

function get_filtered_disputes({ spec, data, item, deref_data }) {
  const dispute_role = what_dispute_role_from_spec(spec.id)
  const available_start_dates_extent = get_start_dates_extent(data)
  const { plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range, selected_dispute_type } = get_dispute_filter_values_or_defaults(item, available_start_dates_extent)

  const disputes = get_disputes(data, deref_data)
  return filter_disputes(disputes, selected_dispute_type, dispute_role, plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range)
}

function get_main_table_data_value_rows({ spec, data, item, deref_data, external_report_id, is_csv, selected_sort_field_id, selected_sort_direction_id }) {
  const dispute_role = what_dispute_role_from_spec(spec.id)
  const disputes_filtered = get_filtered_disputes({ spec, data, item, deref_data })

  const table_data = sort_table_data(disputes_filtered, selected_sort_field_id, selected_sort_direction_id)
  const csv_table_rows = get_table_value_rows({table_data, dispute_role, external_report_id, is_csv})

  return [
    ...csv_table_rows
  ]
}

function get_table_value_rows({table_data, dispute_role, external_report_id, is_csv}) {
  const columns = get_columns_to_export(dispute_role, is_csv)
  const tabular_data_rows = table_data.map(item => {
    const values = columns.map(column => {
      const field = column.id

      const url = get_dispute_details_link({external_report_id, dispute_id: item[DISPUTE_ID_FIELD.id]})

      switch (field) {
        case DURATION_FIELD.id:
          return format_duration(item[field])
        case OUTCOME_FIELD.id:
          return format_status_with_role(item[field], dispute_role)
        case IS_DEFENDANT_NPE_FIELD.id:
        case IS_PLAINTIFF_NPE_FIELD.id:
        case IS_NPE_FIELD.id:
          return format_is_npe(item[field])
        case DETAILS_FIELD.id:
          return (is_csv) ?  url : 'Details'
        case DETAILS_LINK_FIELD.id:
          return url
        case ROUTE_FIELD.id:
          return format_route(item[field])
        default:
          return item[column.id]
      }
    })

    return values
  })

  const column_row = columns.map(column => (column.label))

  return [
    column_row,
    ...tabular_data_rows
  ]
}

function get_summary_csv_value_rows({ spec, data, item, deref_data, is_expanded, selected_sort_field_id, selected_sort_direction_id }) {
  return get_summary_data_value_rows({ spec, data, item, deref_data, is_expanded, selected_sort_field_id, selected_sort_direction_id })
}

function get_summary_data_value_rows({ spec, data, item, deref_data, is_expanded, selected_sort_field_id, selected_sort_direction_id }) {
  const dispute_role = what_dispute_role_from_spec(spec.id)
  const disputes = get_filtered_disputes({ spec, data, item, deref_data })
  const table_data = disputes ? get_disputes_summary_data(disputes, deref_data, dispute_role) : []
  const sorted_table_data = sort_table_data(table_data, selected_sort_field_id, selected_sort_direction_id)
  return get_dispute_summary_table_value_rows({ table_data: sorted_table_data, dispute_role, disputes, is_expanded })
}

function get_summary_data_for_export({ spec, view_id, data, item, deref_data, is_expanded, selected_sort_field_id, selected_sort_direction_id }) {
  const title = get_group_and_spec_name(spec, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF) + ' (summary)'
  const clean_view_id = get_clean_view_id(view_id)
  const { description } = item
  const data_value_rows = get_summary_data_value_rows({ spec, data, item, deref_data, is_expanded, selected_sort_field_id, selected_sort_direction_id })
  const summary_csv = get_csv_string(data_value_rows)
  return {
    title,
    description,
    view_id: clean_view_id,
    data: summary_csv
  }
}

function get_dispute_summary_table_value_rows({ table_data, dispute_role, disputes, is_expanded }) {
  const columns = get_disputes_summary_columns_to_export({table_data, dispute_role, disputes, is_expanded})
  const tabular_data_rows = table_data.map(item => {
    return columns.map(column => item[column.id] || 0)
  })
  const column_row = columns.map(column => column.label)
  return [
    column_row,
    ...tabular_data_rows
  ]
}

const DisputesContainer = (
  {
    disputes,
    spec,
    item,
    data,
    deref_data,
    internal_report_id,
    external_report_id,
    is_thumbnail,

    on_change_selected_dispute_type,

    on_change_plaintiff_type_ids,
    on_change_defendant_type_ids,
    on_change_outcome_ids,
    on_change_start_date_range,

    handle_change_sort_field_id_and_sort_direction_id,
    selected_sort_field_id,
    selected_sort_direction_id,

    is_summary_expanded,
    toggle_is_summary_expanded,
    summary_selected_sort_field_id,
    summary_selected_sort_direction_id,
    handle_change_summary_sort_field_and_direction_id,
    thumbnail_className
  }) => {

  // All dates here are in string form (YYYY-MM-DD) i.e. '2015-11-31'
  const { spec_id } = item
  const available_start_dates_extent = get_start_dates_extent(data)
  const { plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range, selected_dispute_type } = get_dispute_filter_values_or_defaults(item, available_start_dates_extent)

  const dispute_role = what_dispute_role_from_spec(spec_id)

  if (is_thumbnail) {
    return (
      <TableThumbnail
        label={`${format_integer_with_comma(disputes.length)} ${pluralise_text(disputes.length, 'dispute')}`}
        thumbnail_className={thumbnail_className}
      />
    )
  }

  const SummaryView = is_cross_disputes_role(dispute_role) ? CrossDisputesSummaryTable : RoleDisputesSummaryTable

  return (
    <>
      {/* Controls */}
      <div className='d-md-flex flex-wrap flex-lg-nowrap align-items-center'>

        <span className='d-inline-flex align-items-center mr-3 mb-3'>
          <div>Dispute types</div>
          <DisputeTypeFilterControl
            className='ml-1'
            spec={spec}
            available_dispute_types={DISPUTE_TYPE_OPTIONS}
            selected_dispute_type={selected_dispute_type || DISPUTE_TYPE_OPTION_ALL.id}
            on_change_selected_dispute_type={on_change_selected_dispute_type}
          />
        </span>

        <span className='d-inline-flex align-items-center mr-3 mb-3'>
          <div>Plaintiff types</div>
          <DefaultLabelMultiItemDropdownSelector
            className='ml-1'
            label_className={s.short_dropdown_label}
            items={ALL_PARTY_TYPES}
            selected_item_ids={plaintiff_type_ids}
            on_change_item_ids={on_change_plaintiff_type_ids}
          />
        </span>

        {(dispute_role !== ROLE_DEFENDANT) &&
          <span className='d-inline-flex align-items-center mr-3 mb-3'>
            <div>Defendant types</div>
            <DefaultLabelMultiItemDropdownSelector
              className='ml-1'
              label_className={s.short_dropdown_label}
              items={ALL_PARTY_TYPES}
              selected_item_ids={defendant_type_ids}
              on_change_item_ids={on_change_defendant_type_ids}
            />
          </span>
        }

        <span className='d-inline-flex align-items-center mr-3 mb-3'>
          <div>Outcomes</div>
          <DefaultLabelMultiItemDropdownSelector
            className='ml-1'
            label_className={s.short_dropdown_label}
            items={OUTCOME_ITEMS}
            selected_item_ids={outcome_ids}
            on_change_item_ids={on_change_outcome_ids}
          />
        </span>

        <span className='d-inline-flex align-items-center ml-auto mb-3'>
          <div>Start dates</div>
          <DateRangeSelectorWithSliderAndCalendars
            className='ml-1 d-inline-flex align-items-center'
            datesWrapperClassName='ml-2 align-items-center'
            extent={available_start_dates_extent}
            date_range={start_date_range}
            on_change_date_range={on_change_start_date_range}
          />
        </span>
      </div>

      {(disputes || []).length === 0 &&
        <NoDataInSelection className='my-2'/>
      }

      {disputes && disputes.length > 0 &&
        <>
          <TableSummary header_className='mb-2'>
            <SummaryView
              key={spec.id}
              disputes={disputes}
              summary={disputes ? get_disputes_summary_data(disputes, deref_data, dispute_role) : []}
              is_expanded={is_summary_expanded}
              toggle_is_expanded={toggle_is_summary_expanded}
              deref_data={deref_data}
              dispute_role={dispute_role}
              selected_sort_field_id={summary_selected_sort_field_id}
              selected_sort_direction_id={summary_selected_sort_direction_id}
              handle_change_sort_field_and_direction_id={handle_change_summary_sort_field_and_direction_id}
            />
          </TableSummary>

          <div className='mt-4'>
            <PaneHeader text='Disputes'/>
            <DisputesTable
              key={spec.id}

              data={disputes}
              dispute_role={dispute_role}
              internal_report_id={internal_report_id}
              external_report_id={external_report_id}
              selected_sort_field_id={selected_sort_field_id}
              selected_sort_direction_id={selected_sort_direction_id}
              handle_change_sort_field_id_and_sort_direction_id={handle_change_sort_field_id_and_sort_direction_id}
            />
          </div>
          <MainReportItemDesc item={item}/>
        </>
      }
    </>
  )
}

DisputesContainer.get_plotly_data = get_plotly_data

DisputesContainer.get_csv_value_rows = get_csv_value_rows

DisputesContainer.get_summary_csv_value_rows = get_summary_csv_value_rows

DisputesContainer.get_summary_data_for_export = get_summary_data_for_export

export default DisputesContainer