import React from 'react'
import cn from 'classnames'

import { STATUS_TAGS, NON_STATUS_TAGS, TAGS_BY_ID } from '../../model/spec_groups/spec_tags.js'
import { CheckIcon, CrossIcon } from '../widgets/IconSet.js'
import { Badge } from '../widgets/Badge.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import RadioStatic from '../widgets/RadioStatic.js'

import s from './ChartTag.module.scss'

const ChartTag = ({is_selected, on_click, children, className}) => {
  return (
    <Badge className={cn( 'd-flex', s.tag, {[s.tag__selected]: is_selected}, className)} on_click={on_click}>
      {children}
    </Badge>
  )
}

const SelectedTag = ({selected_only_enabled, on_selected_only_click, show_selected_only}) => {
  const on_click = selected_only_enabled ? on_selected_only_click : null
  return (
    <ChartTag
      className={cn('my-1 ml-1', {[s.tag__disabled]: !selected_only_enabled})}
      on_click={on_click}
      is_selected={show_selected_only}
    >
      <div className='d-flex flex-nowrap'>
        <CheckIcon /><span>Selected</span>
      </div>
    </ChartTag>
  )
}

const ChartTags = ({children, className}) => {
  return (
    <div className={cn('d-flex flex-wrap', className)}>
      {children}
    </div>
  )
}

export const ChartTagsDisplay = ({on_select, selected_tags, selected_only_enabled, show_selected_only, on_selected_only_click, className}) => {
  return (
      <div className={cn(className, 'd-none')}>
        <ChartTags>
          <div className={cn('pr-1 d-flex flex-nowrap', s.section)}>
            {STATUS_TAGS.map((item, i) => {
              const {label, id} = item
              const is_selected = (selected_tags || []).indexOf(id) !== -1
              return (
                <ChartTag
                  key={i}
                  className={cn('my-1', {'ml-1': i !== 0})}
                  is_selected={is_selected}
                  on_click={() => on_select(id)}
                >
                  <div className='d-flex'><RadioStatic is_checked={is_selected} size='sm' /><span>&nbsp;{label}</span></div>
                </ChartTag>
              )
            })}
          </div>

          <div className={cn('d-flex flex-nowrap pr-1', s.section)}>
            {NON_STATUS_TAGS.map((item, i) => {
              const {label, id} = item

              const is_selected = (selected_tags || []).indexOf(id) !== -1

              return (
                <ChartTag
                  key={i}
                  className='my-1 ml-1'
                  is_selected={is_selected}
                  on_click={() => on_select(id)}
                >
                  <div className='d-flex'><CheckboxStatic is_checked={is_selected} size='sm' /><span>&nbsp;{label}</span></div>
                </ChartTag>
              )
            })}
          </div>
          <SelectedTag
            selected_only_enabled={selected_only_enabled}
            on_selected_only_click={on_selected_only_click}
            show_selected_only={show_selected_only}

            className={cn( {[s.tag__disabled]: !selected_only_enabled})}
          />

        </ChartTags>
      </div>
  )
}

export const SelectedChartTagsDisplay = ({selected_tags, selected_only_enabled, on_selected_only_click, show_selected_only, on_deselect}) => {
  return (
    <div className='d-none'>
      <ChartTags className='my-1'>
        <span className='my-auto mr-2'>Filters:&nbsp;</span>
        {selected_tags.map((item, i) => {
          const {label, id} = TAGS_BY_ID[item]
          return (
            <ChartTag
              key={i}
              className='mr-2 mt-1'
              is_selected={true}
              on_click={() => on_deselect(id)}
            >
              <div className='d-flex flex-nowrap'>
                <span>{label}</span>
                <div className='ml-1 my-auto'><CrossIcon className={s.cross_icon} /></div>
              </div>
            </ChartTag>
          )
        })}

        <SelectedTag
          selected_only_enabled={selected_only_enabled}
          on_selected_only_click={on_selected_only_click}
          show_selected_only={show_selected_only}
        />

      </ChartTags>
    </div>
  )
}