import React, { useContext, useEffect, useState } from 'react'

import {
  create_cipher_family_id_from_family_id,
  get_family_data,
  get_family_id_from_cipher_family_id,
  order_patent_numbers_and_select_best_representation
} from '../../utils/family_view_utils.js'
import { is_int } from '../../utils/utils.js'
import { FAMILY } from '../../constants/paths.js'
import ErrorBody from '../ErrorBody.js'
import Spinner from '../widgets/Spinner.js'
import FamilyDetails from './FamilyDetails.js'
import { PATENT_NUMBER_SUBPATH } from '../../constants/viewer_paths.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { get_language_preference } from '../../utils/user_settings_utils.js'

export const FamilyFetchAndDisplay = (
  {
    history,
    match,
    selected_family,
    selected_patent_number: selected_patent_number_prop,
    allow_public_access=false,
    top_className
  }) => {

  const { user_settings } = useContext(UserSettingsContext) || {}
  const preferred_language = get_language_preference(user_settings)

  const [family_id_to_fetch, set_family_id_to_fetch] = useState(null)
  const [is_fetching, set_is_fetching] = useState(true)
  const [patfam, set_patfam] = useState(null)
  const [selected_patent_number, set_selected_patent_number] = useState(selected_patent_number_prop || get_selected_patent_number_from_url)
  const [fetching_error, set_fetching_error] = useState(null)

  const [is_family_tagging_mode_on, set_is_family_tagging_mode_on] = useState(false)
  const [family_tagging_search_phrase, set_family_tagging_search_phrase] = useState(null)

  useEffect(() => {
    const {id: family_id_url_param} = (match || {}).params || {}
    const pat_fam_id = get_family_id_from_cipher_family_id(selected_family || family_id_url_param)
    if (selected_family) {
      set_family_id_to_fetch(pat_fam_id)
      return
    }

    const cipher_family_id_from_pat_fam_id = create_cipher_family_id_from_family_id(pat_fam_id)
    if (!(cipher_family_id_from_pat_fam_id && is_int(pat_fam_id * 1))) {
      set_fetching_error(new Error('wrong id'))
      set_is_fetching(false)
      return
    }

    if (family_id_url_param !== cipher_family_id_from_pat_fam_id) {
      history.replace({pathname: `${FAMILY}/${cipher_family_id_from_pat_fam_id}`})
    } else {
      set_family_id_to_fetch(pat_fam_id)
    }
  }, [selected_family, match, history])

  useEffect(() => {
    if (!family_id_to_fetch) return

    let did_cancel = false

    get_family_data(family_id_to_fetch, allow_public_access)
      .catch(err => {
        if (!did_cancel) {
          set_fetching_error(err)
          set_is_fetching(false )
          throw err
        }})
      .then((patfam) => {
        if (!did_cancel) {
          const patfam_with_id = {...patfam, patFamId: family_id_to_fetch}

          const selected_patent_number = get_selected_patent_number_from_url()

          if (!selected_patent_number && !selected_patent_number_prop) {
            const { representative_patent_number } = order_patent_numbers_and_select_best_representation(patfam_with_id, preferred_language)
            update_url_with_selected_patent_number(representative_patent_number)
            set_selected_patent_number(representative_patent_number)
          }

          set_patfam(patfam_with_id)
          set_is_fetching(false)
        }})
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family_id_to_fetch])

  function split_url_by_patent_number_param() {
    const {location} = history || {}
    const {pathname} = location || {}

    const [family_path, selected_patent_number] = (pathname || '').split(`/${PATENT_NUMBER_SUBPATH}/`)

    const family_path_formatted = family_path[family_path.length - 1] === '/' ? family_path.slice(0, -1) : family_path

    return [family_path_formatted, selected_patent_number]
  }

  function get_selected_patent_number_from_url() {
    const [,selected_patent_number] = split_url_by_patent_number_param()
    return selected_patent_number
  }

  function on_change_selected_patent_number(patent_number) {
    return (selected_patent_number_prop) ?
      set_selected_patent_number(patent_number) :
      update_url_with_selected_patent_number(patent_number)
  }

  function update_url_with_selected_patent_number(patent_number) {
    const [family_path,] = split_url_by_patent_number_param()
    set_selected_patent_number(patent_number)

    if (history && patent_number) {
      history.replace(family_path + `/${PATENT_NUMBER_SUBPATH}/${patent_number}`)
    }
  }

  function on_toggle_family_tagging_mode() {
    set_is_family_tagging_mode_on(!is_family_tagging_mode_on)
  }

  function on_update_family_tagging_search_phrase(text) {
    set_family_tagging_search_phrase(text)
  }

  return (
    <>
      {fetching_error &&
        <ErrorBody
          error={fetching_error}
          context={'fetching patent family data'}
        />
      }

      {is_fetching &&
        <div className='text-center mt-3'>
          <Spinner/>
        </div>
      }

      {(!is_fetching && patfam) &&
        <FamilyDetails
          family={patfam}
          selected_patent_number={selected_patent_number}
          top_className={top_className}

          is_family_tagging_mode_on={is_family_tagging_mode_on}
          family_tagging_search_phrase={family_tagging_search_phrase || ''}
          on_change_selected_patent_number={on_change_selected_patent_number} //selected patent comes as a prop, no url to update
          on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
          on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

          allow_public_access={allow_public_access}
        />
      }

      {(!is_fetching && !patfam) &&
        <div>Family not found</div>
      }

    </>
  )
}

export default FamilyFetchAndDisplay