import React from 'react'

import ProgressPrompt from './ProgressPrompt.js'
import OrgSearchSingle from '../../orgs/OrgSearchSingle.js'

const PortfolioOrgSingle = ({is_wizard_final_step, can_wizard_continue, on_step_complete, selected_organisations, select_organisations, on_search_mode_change}) => {
  return (
    <div>
      <ProgressPrompt
        is_wizard_final_step={is_wizard_final_step}
        can_wizard_continue={can_wizard_continue}
        on_step_complete={on_step_complete}
      >
        <span>Search for organisations and add them to the report.</span>
      </ProgressPrompt>
      <div className='mt-3'>
        <OrgSearchSingle
          selected_organisations={selected_organisations}
          update_basket_handler={select_organisations}
          on_search_mode_change={on_search_mode_change}
        />
      </div>
    </div>
  )
}

export default PortfolioOrgSingle