import React, { useEffect, useState } from 'react'

import { PrimaryButton } from '../../widgets/Button.js'
import AutoResizeTextArea from '../../widgets/AutoResizeTextArea.js'
import { remove_double_spaces } from '../../../utils/utils.js'
import SaveSetsModal from './SaveSetsModal.js'

const AddSet = (
  {
    selected_target_user_or_group,
    current_sets,
    on_save_handler
  }) => {
  const {subject: target_subject, type: target_type} = selected_target_user_or_group || {}

  const [is_save_modal_open, set_is_save_modal_open] = useState(false)
  const [to_add, set_to_add] = useState(null)
  const [updated_sets, set_updated_sets] = useState(null)
  const [parse_error, set_parse_error] = useState(null)

  useEffect(() => {
    set_is_save_modal_open(false)
    set_to_add(null)
    set_updated_sets(null)
  }, [target_subject, target_type])

  function parse_to_add_input(to_add, check_only_if_correct) {
    try {
      const parsed = JSON.parse(remove_double_spaces((to_add || '').trim().replace(/\n/g, ' ')))
      if (check_only_if_correct) {
        set_parse_error(null)
      }
      return check_only_if_correct ? true : Array.isArray(parsed) ? parsed : [parsed]
    } catch (e) {
      if (!check_only_if_correct) {
        set_parse_error(e)
        throw e
      }
      return check_only_if_correct ? false : []
    }
  }

  function apply_new_set_to_add() {
    const sets_to_add = parse_to_add_input(to_add, false)
    const set_names = sets_to_add.map(item => item.name || '')

    //names must be unique; leave sets that have the same names as sets to add
    const filtered_sets = (current_sets || []).filter(item => {
      const { name } = item

      return (set_names || []).indexOf(name) === -1
    })

    const new_sets = [...filtered_sets, ...sets_to_add ]
    set_updated_sets(new_sets)
    set_is_save_modal_open(true)
  }

  function on_save() {
    set_is_save_modal_open(false)
    on_save_handler(updated_sets)
    set_to_add(null)
    set_updated_sets(null)
  }

  function update_input(new_input) {
    if (parse_error) {
      parse_to_add_input(new_input, true)
    }
    set_to_add(new_input)
  }

  return (
    <div>

      <div>Type or paste new chart sets:</div>

      <AutoResizeTextArea
        value={to_add || ''}
        on_clear={() => set_to_add(null)}
        on_change={update_input}
      />

      <PrimaryButton className='mt-3' disable={!to_add} onClick={apply_new_set_to_add} disabled={parse_error || !to_add}>Apply</PrimaryButton>

      <SaveSetsModal
        is_open={is_save_modal_open}
        on_hide={() => set_is_save_modal_open(false)}
        on_save={on_save}
        sets={updated_sets || []}
        sets_to_highlight={is_save_modal_open ? (parse_to_add_input(to_add, false) || []).map(item => item.name) : []}
      />

    </div>
  )
}

export default AddSet