import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { HELP, SEARCH_GUIDE } from '../../constants/paths.js'

const PatentFamilyListGuideLink = ({ className, title }) => {
  return (
    <TextLink
      target='_blank'
      rel='noopener noreferrer'
      href={`${HELP}/${SEARCH_GUIDE}`}
      element='a'
      className={className}
      title='Boolean search guide'
    >
      {title || 'See guide'}
    </TextLink>
  )
}

export default PatentFamilyListGuideLink