import React from 'react'
import cn from 'classnames'

import { Label } from './FamilyDetailsLabel.js'
import { TileContent } from './TileContent.js'
import { create_cipher_family_id_from_family_id } from '../../utils/family_view_utils.js'
import { SAMPLE_LIMIT } from '../../model/family_view.js'
import TextLink from '../widgets/TextLink.js'
import { FAMILY } from '../../constants/paths.js'
import { is_array_non_empty_non_null, pluralise_text } from '../../utils/utils.js'

import s from './FamiliesListDisplay.module.scss'

const FamiliesListDisplay = ({ title, families_list, is_limit, on_show_full_set, allow_public_access, error_fetching}) => {

  function get_families_to_display() {
    return is_limit ? families_list.slice(0, Math.min(families_list.length, SAMPLE_LIMIT)) : families_list
  }

  const is_list_empty = !is_array_non_empty_non_null(families_list)

  const families_to_display = is_list_empty ? [] : get_families_to_display()

  return (
    <div className='block'>
      <Label>
        {title &&
          <span className='mr-2'>{title}</span>
        }
      </Label>

      {error_fetching &&
        <div>There was an error fetching patent data.</div>
      }

      {is_list_empty &&
        <TileContent>(none)</TileContent>
      }

      {!is_list_empty && !allow_public_access &&
        <div className='d-flex flex-wrap'>
          {families_to_display.map((family_id, i) => {
            const cipher_family_id = create_cipher_family_id_from_family_id(family_id)
            return (
              <TextLink
                element='a'
                target='_blank'
                title={cipher_family_id}
                href={`${FAMILY}/${cipher_family_id}`}
                key={i}
                className='mr-5'
              >
                {cipher_family_id}
              </TextLink>
            )
          })}

          {on_show_full_set && (families_list.length > families_to_display.length) &&
            <span
              className={cn('mr-3')}
            >
               <TextLink
                 onClick={on_show_full_set}
                 className='font-weight-bold'
               >
                 Show full set
               </TextLink>
            </span>
          }

        </div>
      }

      {!is_list_empty && allow_public_access &&
        <div className={cn('font-weight-bold', s.summary_text)}>{families_to_display.length} {pluralise_text(families_to_display.length, 'family', 'families')}</div>
      }
    </div>
  )

}

export default FamiliesListDisplay