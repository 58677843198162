import React, { useContext } from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../widgets/Button.js'
import Modal from '../widgets/Modal.js'
import { track_knn_events } from '../../utils/tracking_utils.js'
import { USER_SAVE_KNN_SEARCHES } from '../../model/user_settings.js'
import { UserSettingsDispatchContext } from '../UserSettingsContext.js'
import { CONTEXT } from '../../utils/knn_search.js'

import s from './WelcomeModal.module.scss'

const WelcomeModal = () => {
  const { save_user_settings_handler } = useContext(UserSettingsDispatchContext)
  function change_save_knn_searches(save_knn_searches) {
    track_knn_events(`context="${CONTEXT}" action="change_privacy_mode" value="${save_knn_searches}"`)
    save_user_settings_handler(USER_SAVE_KNN_SEARCHES, save_knn_searches)
  }

  return (
    <Modal
      title={'Choose your search settings'}
      backdrop={true}
      footer={(
        <div className='d-flex'>
          <PrimaryButton outline onClick={() => change_save_knn_searches(false)}>Use privately</PrimaryButton>
          <PrimaryButton onClick={() => change_save_knn_searches(true)} className='ml-2'>Keep search history</PrimaryButton>
        </div>
      )}
      zIndex={10001}
      centered={true}
      className={cn('fs-unmask', s.block)}
    >
      <div>Your searches are anonymous by default. You can change this in User Settings if you want to save or access your previous searches. We won’t track your search usage unless you give us permission.</div>
    </Modal>
  )
}

export default WelcomeModal