import React, { useState } from 'react'
import { Input } from 'reactstrap'

import { PrimaryButton } from '../widgets/Button.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { BadInputAlertModal } from './BadInputAlertModal.js'

const MAX_CHAR_LENGTH_TEXT_AREA = 6000000 //size in chars

export const TextInputUpload = ({
  className,
  input_value,
  input_rows,
  placeholder,
  on_change_input,
  on_submit,
  max_processing_size_input, //text input size in number of chars
}) => {

  const [is_too_big, set_is_too_big] = useState(false)

  function on_submit_input(){
    if (max_processing_size_input && input_value.length > max_processing_size_input){
      set_is_too_big(true)
    } else {
      on_submit()
    }
  }

  return (
    <div className={className}>
      {is_too_big &&
        <BadInputAlertModal
          error_msg={'Only the first ' + format_integer_with_comma(max_processing_size_input) + ' characters can be processed via this mode. Use a file upload if available'}
          title='Large input'
          on_hide={()=>set_is_too_big(false)}
        />
      }
      <PrimaryButton
        onClick={on_submit_input}
        disabled={input_value === ''}
        className='mb-2'
        size='sm'
      >
        Upload
      </PrimaryButton>

      <Input
        type='textarea'
        name='text'
        placeholder={placeholder}
        onChange={on_change_input}
        value={input_value}
        rows={input_rows || '10'}
        autoFocus
        className='mb-3'
        maxLength={MAX_CHAR_LENGTH_TEXT_AREA}
      />

    </div>)
}