import React, {useState} from 'react'
import cn from 'classnames'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { PrimaryButton, SecondaryButton } from '../widgets/Button.js'
import TextLink from '../widgets/TextLink.js'
import { RemoveIcon } from '../widgets/IconSet.js'
import { get_as_map } from '../../utils/utils.js'
import { CIPHER_CHART_SETS } from '../../model/chart_sets.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { get_default_chart_selection_value } from '../../utils/default_chart_selection_utils.js'
import { get_normalised_name } from '../../utils/name_utils.js'

import s from './SelectionMenu.module.scss'
import dsg from './dataset_groups.module.scss'

const MAX_SELECTION_NAME_LENGTH = 50

const SelectChartSetAsDefault = ({item, default_chart_selection, on_change_default_chart_selection}) => {
  const value = get_default_chart_selection_value(item)
  const is_selected_as_default = (default_chart_selection === value)

  if (is_selected_as_default) {
    return (
      <span className={s.selected_as_default}>default</span>
    )
  }

  return (
    <TextLink className={s.select_as_default} onClick={() => on_change_default_chart_selection(value)}>
      set as default
    </TextLink>
  )
}

export const UserChartSetsList = ({user_chart_sets=[], default_chart_selection, on_select, on_remove, on_change_default_chart_selection}) => {
  const is_selected_as_default_enabled = (on_change_default_chart_selection != null)

  function get_sets_sorted(sets) {
    if (!sets || sets.length < 2) return sets

    const sets_by_name = get_as_map(sets, 'name')

    return Object.keys(sets_by_name).sort().map(name => sets_by_name[name])
  }

  const user_defined_sets = get_sets_sorted(user_chart_sets.filter(item => !item.is_shared_set))
  const shared_sets = get_sets_sorted(user_chart_sets.filter(item => item.is_shared_set))

  return (
    <div>
      {user_defined_sets && user_defined_sets.length > 0 &&
        <div>
          <PaneHeader text='Saved selections' className={s.sets_heading}/>
          {
            user_defined_sets.map((item, i) => {
              const { name } = item
              const is_selected_as_default = (default_chart_selection === get_default_chart_selection_value(item))

              return (
                <div key={i} className={cn('d-flex justify-content-between', s.chart_selection)}>
                  <div className='d-flex'>
                    {on_remove &&
                      <TextLink
                        onClick={() => {on_remove(item)}}
                        className={cn('mr-2 my-auto pt-1', s.remove)}
                        disable={is_selected_as_default}
                      >
                        <RemoveIcon/>
                      </TextLink>
                    }
                    <TextLink onClick={() => on_select(item)}>{name}</TextLink>
                  </div>
                  {is_selected_as_default_enabled &&
                    <SelectChartSetAsDefault
                      item={item}
                      default_chart_selection={default_chart_selection}
                      on_change_default_chart_selection={on_change_default_chart_selection}
                    />
                  }
                </div>
              )
            })
          }
        </div>
      }

      {shared_sets && shared_sets.length > 0 &&
        <div className={cn('mt-2', {[s.sets_block] : user_defined_sets && user_defined_sets.length > 0})}>
          <PaneHeader text='Shared with my organisation' className={cn(s.sets_heading)}/>
          {shared_sets.map((item, i) => {
            const {name} = item
            return (
              <div key={i} className={cn('d-flex justify-content-between', s.chart_selection)}>
                <TextLink onClick={() => on_select(item)}>{name}</TextLink>
                {is_selected_as_default_enabled &&
                  <SelectChartSetAsDefault
                    item={item}
                    default_chart_selection={default_chart_selection}
                    on_change_default_chart_selection={on_change_default_chart_selection}
                  />
                }
              </div>
            )
          })}
        </div>
      }

      <div className={cn('mt-2', {[s.sets_block]: user_chart_sets.length !== 0})}>
        <PaneHeader text='Cipher selections' className={s.sets_heading}/>
        {CIPHER_CHART_SETS.map((item, i) => {
          const {name} = item
          return (
            <div key={i} className={cn('d-flex justify-content-between', s.chart_selection)}>
              <TextLink onClick={() => on_select(item)}>{name}</TextLink>
              {is_selected_as_default_enabled &&
                <SelectChartSetAsDefault
                  item={item}
                  default_chart_selection={default_chart_selection}
                  on_change_default_chart_selection={on_change_default_chart_selection}
                />
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const LoadSelectionDropdown = ({user_chart_sets=[], default_chart_selection, on_remove_user_chart_set, on_select_user_chart_set, on_change_default_chart_selection, right_menu, className}) => {
  const [is_open, set_is_open] = useState(false)

  function toggle() {
    set_is_open(!is_open)
  }

  function select(set) {
    on_select_user_chart_set(set)
    toggle()
  }

  return (
    <Dropdown isOpen={is_open} toggle={toggle} className={className}>
      <DropdownToggle tag='div'><TextLink className={cn({[s.__selected]: is_open})} title='Choose from saved chart selections'>Load</TextLink></DropdownToggle>
      <DropdownMenu right={right_menu} className={s.menu}>
        <div className={cn('p-2 w-100', s.load_options_container, dsg.selected_spec)}>
          <UserChartSetsList
            user_chart_sets={user_chart_sets}
            default_chart_selection={default_chart_selection}
            on_select={select}
            on_remove={on_remove_user_chart_set}
            on_change_default_chart_selection={on_change_default_chart_selection}
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export const SaveSelectionDropdown = ({user_chart_sets, on_confirm, disabled, right_menu, className}) => {
  const [is_open, set_is_open] = useState(false)
  const [name, set_name] = useState(null)

  function toggle() {
    if (is_open) {
      set_name(null)
    }

    set_is_open(!is_open)
  }

  function submit() {
    const normalised_name = get_normalised_name(name)

    on_confirm(normalised_name)
    set_is_open(false)
    set_name(null)
  }

  function on_key_down(e) {
    if (e.which === 13) {
      submit()
    }
  }

  const name_to_user_set = get_as_map(user_chart_sets || [], 'name')
  const existing_names = Object.keys(name_to_user_set)
  const normalised_name = get_normalised_name(name)
  const is_name_valid = name && (normalised_name.length > 0 && normalised_name.length <= MAX_SELECTION_NAME_LENGTH)
  const has_available_slots = (user_chart_sets || []).length < 20

  const set_with_name_idx = existing_names.indexOf(normalised_name)

  const is_name_already_in_use = set_with_name_idx !== -1

  const is_save_available = name && has_available_slots && is_name_valid && !is_name_already_in_use

  return (
    <Dropdown isOpen={is_open} toggle={toggle} className={className} disabled={disabled}>
      <DropdownToggle tag='div'><TextLink disable={disabled} className={cn({[s.__selected]: is_open})} title={'Save selected charts'}>Save</TextLink></DropdownToggle>
      <DropdownMenu right={right_menu} className={s.menu}>
        <div className={cn('p-2 w-100', dsg.selected_spec)}>
          <ClearableSearchInput
            show_clear
            value={name || ''}
            placeholder='Save selection as ...'
            handle_change={(value) => set_name(value)}
            handle_key_down={is_save_available ? on_key_down : null}
            auto_focus={true}
          />

          <div className={cn('invalid-feedback d-block mb-2', s.msg_container)}>
            {!has_available_slots && <span>You can have up to 20 saved selections</span>}
            {name != null && !is_name_valid && <span>Selection name cannot be empty or longer than {MAX_SELECTION_NAME_LENGTH} characters.</span>}
            {is_name_already_in_use &&
              <span>There is already a selection with name &quot;{existing_names[set_with_name_idx]}&quot;, would you like to update it?</span>
            }
          </div>

          <div className='d-flex justify-content-between pt-1'>
            {is_name_already_in_use &&
              <PrimaryButton size='xs' onClick={() => submit()}>Update</PrimaryButton>
            }

            <div className='d-flex w-100 justify-content-end'>
              <SecondaryButton size='xs' onClick={toggle} className='mr-1'>Close</SecondaryButton>
              <PrimaryButton size='xs' onClick={submit} disabled={!is_save_available}>SAVE</PrimaryButton>
            </div>
          </div>
        </div>
      </DropdownMenu>

    </Dropdown>
  )
}