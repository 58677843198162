import React, { useState } from 'react'
import sqlFormatter from 'sql-formatter'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'

import DefaultPageContainer from '../DefaultPageContainer.js'
import { PrimaryButton, SecondaryButton } from '../widgets/Button.js'
import TextLink from '../widgets/TextLink.js'
import AutoResizeTextArea from '../widgets/AutoResizeTextArea.js'
import Spinner from '../widgets/Spinner.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { MonitorCard } from './MonitorCard.js'
import ReportIdInput from './ReportIdInput.js'
import MonitorErrorDisplay from './MonitorErrorDisplay.js'
import MonitorResponsePanel from './MonitorResponsePanel.js'
import MonitorNavigationBar from './MonitorNavigationPanel.js'
import ErrorModal from '../ErrorModal.js'

import { fetch_data_from_report_reader, fetch_drill_sql_translation } from '../../utils/report_reader_utils.js'
import { fetch_an_internal_report_id, fetch_report_metadata } from '../../utils/report_created_utils.js'
import { trigger_download, JSON_FILE_EXT, MIMETYPE_JSON } from '../../utils/download_utils.js'
import { useToggle } from '../../hooks/general_hooks.js'

import { REPORT_READER_QUERY } from '../../constants/paths.js'

const BASIC_EXAMPLE_QUERY = '{"value":["COUNT DISTINCT PF.pat_fam_id"]}'

const MonitorReportReaderQuery = ({location}) => {

  const query_params = qs.parse(location.search)

  // use report id from url params if available
  const [input_report_id, set_input_report_id] = useState(query_params.report_id)
  const [is_fetching, set_is_fetching] = useState(false)
  const [error_finding_report, set_error_finding_report] = useState(null)
  const [dsl_query, set_dsl_query] = useState(null)
  const [error_parsing_dsl_query, set_error_parsing_dsl_query] = useState(null)
  const [sql_translation, set_sql_translation] = useState(null)
  const [is_show_simple_mode, toggle_is_show_simple_mode] = useToggle(true)
  const [rr_result, set_rr_result] = useState(null)
  const [rr_error, set_rr_error] = useState(null)
  const [download_error, set_download_error] = useState(null)

  const sql_to_display = is_show_simple_mode ? remove_efs_details_from_drill_sql(sql_translation) : sql_translation

  function get_report_reader_response(is_download=false) {
    set_is_fetching(true)
    set_error_finding_report(null)
    set_error_parsing_dsl_query(null)
    set_rr_error(null)
    set_sql_translation(null)
    set_rr_result(null)

    if (input_report_id.length <= 10) {
      // assume this is an external id and we need to fetch the internal one...
      fetch_report_metadata(input_report_id)
        .then(metadata => {
          get_query_results(metadata.internal_report_id, is_download)
        })
        .catch(error => {
          set_error_finding_report(error)
          set_is_fetching(false)
        })
    } else {
      get_query_results(input_report_id, is_download)
    }
  }

  function download_report_reader_response() {
    get_report_reader_response(true)
  }

  function get_query_results(internal_report_id, is_download) {
    try {
      const parsed_query = JSON.parse(dsl_query)

      if (is_download) {
        download_report_data(internal_report_id, parsed_query)
      } else {
        display_query_results(internal_report_id, parsed_query)
      }
    } catch (parsing_error) {
      set_error_parsing_dsl_query(parsing_error)
      set_is_fetching(false)
    }
  }

  function display_query_results(internal_report_id, parsed_query) {
    fetch_drill_sql_translation(internal_report_id, parsed_query)
      .then(set_sql_translation)
      .then(() => {
        fetch_data_from_report_reader(internal_report_id, parsed_query)
          .then(result => {
            set_is_fetching(false)
            set_rr_result(result)
          })
          .catch(error => {
            set_is_fetching(false)
            set_rr_error(error)
          })
      })
      .catch(error => {
        set_is_fetching(false)
        set_rr_error(error)
      })
  }

  function download_report_data(internal_report_id, parsed_query) {
    fetch_data_from_report_reader(internal_report_id, parsed_query)
      .catch(error => {
        set_is_fetching(false)
        set_rr_error(error)
        throw error
      })
      .then(result => {
        set_is_fetching(false)
        trigger_download(format_rr_result_json(result), MIMETYPE_JSON, `report_data_${internal_report_id}${JSON_FILE_EXT}`)
      })
      .catch(error => {
        set_download_error(error)
      })
  }

  function format_rr_result_json(result_json) {
    return JSON.stringify(result_json, null, '\t')
  }

  function fetch_any_internal_report_id() {
    fetch_an_internal_report_id()
      .then(set_input_report_id)
  }

  return (
    <DefaultPageContainer>
      <MonitorNavigationBar current_page_id={REPORT_READER_QUERY} report_id={input_report_id}/>

      <MonitorCard title='Report ID (external or internal)'>
        <ReportIdInput
          value={input_report_id || ''}
          on_change={set_input_report_id}
        />
        <span className='mt-1 float-right'>
          ... or <TextLink onClick={fetch_any_internal_report_id}>use any recent report id</TextLink>
        </span>
      </MonitorCard>

      <MonitorCard title='DSL Query'>
        <AutoResizeTextArea
          value={dsl_query || ''}
          on_change={set_dsl_query}
          rows={10}
          placeholder={`Paste or type a DSL query, for eg:\n${BASIC_EXAMPLE_QUERY}`}
        />
        <div className={'mt-2 float-right'}>
          {is_fetching &&
            <Spinner size='sm'/>
          }
          <SecondaryButton
            onClick={() => set_dsl_query('')}
            disabled={!dsl_query || is_fetching}
          >
              Clear text
          </SecondaryButton>
          <PrimaryButton
            onClick={download_report_reader_response}
            className='ml-2'
            disabled={!dsl_query || !input_report_id || is_fetching}
          >
            Download json
          </PrimaryButton>
          <PrimaryButton
            onClick={() => get_report_reader_response()}
            className='ml-2'
            disabled={!dsl_query || !input_report_id || is_fetching}
          >
            Do query
          </PrimaryButton>
        </div>
      </MonitorCard>

      { download_error &&
        <ErrorModal
          on_hide={() => set_download_error(null)}
          error={download_error}
          context={'downloading queried report data'}
        />
      }

      { error_finding_report &&
        <MonitorCard title={'Report id not valid or not found'} is_error={true}>
          <MonitorErrorDisplay error={error_finding_report}/>
        </MonitorCard>
      }

      { error_parsing_dsl_query &&
        <MonitorCard title='Error parsing DSL... please check your JSON syntax' is_error={true}>
          <MonitorErrorDisplay error={error_parsing_dsl_query} />
        </MonitorCard>
      }

      { sql_translation &&
        <MonitorCard title='Translated drill query'>
          <div className='my-1'>
            <CheckboxAndLabel
              is_checked={is_show_simple_mode}
              label='Simplified'
              on_click={toggle_is_show_simple_mode}
              is_disabled={!sql_translation}
            />
          </div>
          <MonitorResponsePanel className='mt-1'>{sqlFormatter.format(sql_to_display)}</MonitorResponsePanel>
        </MonitorCard>
      }

      { rr_error &&
        <MonitorCard title='Error querying report reader' is_error={true}>
          <MonitorErrorDisplay error={rr_error} />
        </MonitorCard>
      }

      { rr_result &&
        <MonitorCard title={'Report reader result'}>
          <MonitorResponsePanel>{format_rr_result_json(rr_result)}</MonitorResponsePanel>
        </MonitorCard>
      }
    </DefaultPageContainer>
  )
}

function remove_efs_details_from_drill_sql(sql) {
  return (sql || '').replace(/dfs\.`\/data\/([a-zA-Z0-9]){2}\/([a-zA-Z0-9]){2}\/([a-zA-Z0-9-.])*\/|\.parquet`/g, '')
}

export default withRouter(MonitorReportReaderQuery)