import axios from 'axios'
import _ from 'underscore'

import { SELECTED_GROUP_ID } from '../model/spec_groups/spec_group_ids.js'
import { format_string_first_character_capitalised, generate_random_hash, get_cipher_hostname } from './utils.js'
import { REPORT_DECK_URL, REPORT_STATE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'
import {PROJECT, REPORT_STATE} from '../constants/paths.js'
import { FAVICON_FILE_NAME, FAVICON_WAIT_FILE_NAME, MAX_REPORT_NAME_LENGTH } from '../constants/constants.js'
import { remove_last_special_character_from_text } from './name_utils.js'
import { save_report_viewed } from './report_management_utils.js'
import {
  EXPORT_SUBPATH, FAMILIES_SUBPATH,
  FAMILY_SUBPATH,
  GROUP_SUBPATH, PATENT_NUMBER_SUBPATH,
  SELECTED_SUBPATH,
  SUBSET_SUBPATH
} from '../constants/viewer_paths.js'

export const COMPACT_VIEW_THRESHOLD = 3

export function save_report_state({user_state, external_report_id}) {
  return axios.post(`${REPORT_STATE_URL}/save`, {user_state, external_report_id}, {headers: JSON_POST_HEADER})
    .then(response => response.data.state_id)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to save report state for sharable url: ')
      throw wrapped_err
    })
}

export function get_report_state(state_id) {
  return axios.get(`${REPORT_STATE_URL}/${state_id}`)
    .then(response => response.data.external_report_id)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch report data by state_id: ')
      throw wrapped_err
    })
}

export function get_deck_state(state_id) {
  return axios.get(`${REPORT_DECK_URL}/state/${state_id}`)
    .then(response => response.data.external_report_id)
    .catch(error => {
      const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to fetch deck data by state_id: ')
      throw wrapped_err
    })
}

export function get_report_state_url({location, external_report_id, state_id, idp_meta=[], share_url=REPORT_STATE}) {
  const { pathname='', search='' } = location || {}
  const path_parts = pathname.split(external_report_id) || []
  const kc_idp_hint = idp_meta.length > 0 ? `kc_idp_hint=${idp_meta[0].identityProvider}` : ''
  const query_params = kc_idp_hint ? `?${kc_idp_hint}` : ``
  return `${get_cipher_hostname()}${share_url}/${state_id}${path_parts[1]}${search || ''}${query_params}`
}

export function get_report_size(families_count) {
  if (!families_count) return null

  if (families_count < 500001) return 'S'
  if (families_count > 1000000) return 'L'
  return 'M'
}

export function is_large_report(families_count) {
  return get_report_size(families_count) === 'L'
}

export function is_small_report(families_count) {
  return get_report_size(families_count) === 'S'
}

export function is_dataset_bulk_fetch_enabled(families_count, show_charts) {
  if (is_small_report(families_count)) return true
  if (is_large_report(families_count)) return false

  return show_charts
}

export function get_pathname_parts(pathname) {
  return pathname.split('/').filter(item => item && item !== '')
}

export function is_zoomed_dataset_path(pathname) {
  const path_elements = get_pathname_parts(pathname)
  const spec_id = (path_elements && path_elements[4] && path_elements[4] === 'd') ? path_elements[5] : null

  return spec_id
}

export function is_zoomed_group_path(pathname) {
  const path_elements = get_pathname_parts(pathname)
  const group_id = (path_elements && path_elements[2] && path_elements[2] === GROUP_SUBPATH) ? path_elements[3] : null

  return group_id
}

export function is_all_families_path(pathname) {
  const path_elements = get_pathname_parts(pathname)

  return path_elements && path_elements[2] && path_elements[2] === FAMILIES_SUBPATH
}

export function is_export_path(pathname) {
  const path_elements = get_pathname_parts(pathname)

  return path_elements && path_elements[2] && path_elements[2] === EXPORT_SUBPATH
}

export function is_selected_path(pathname) {
  const path_elements = get_pathname_parts(pathname)
  const group_id = (path_elements && path_elements[2] && path_elements[2] === GROUP_SUBPATH) ? path_elements[3] : null

  return group_id === SELECTED_GROUP_ID || (path_elements && path_elements[2] && path_elements[2] === SELECTED_SUBPATH)
}

export function is_subset_path(pathname) {
  return (pathname.indexOf(`/${SUBSET_SUBPATH}/`) !== -1)
}

export function is_family_details_view_page(pathname) {
  const path_elements = get_pathname_parts(pathname)
  if (!path_elements) return null
  const family_subpath_id = path_elements.indexOf(FAMILY_SUBPATH)
  return  family_subpath_id > -1 ? path_elements[family_subpath_id + 1] : null
}

export function is_family_details_selected_patent_page(pathname) {
  const path_elements = get_pathname_parts(pathname)
  if (!path_elements) return null
  const patent_number_subpath_id = path_elements.indexOf(PATENT_NUMBER_SUBPATH)
  return patent_number_subpath_id > -1 ? path_elements[patent_number_subpath_id + 1] : null
}

export function is_any_path(pathname) {
  const path_elements = get_pathname_parts(pathname)
  return path_elements && path_elements[2]
}

export function get_mutual_items_count(set_1, set_2) {
  return _.intersection(set_1, set_2).length
}

export function format_dataset_name_for_thumbnail(spec_name) {
  return spec_name.split(' ').map(part => {
    if (_.contains(['PVIX', 'CAGR'], part.toUpperCase())) return part.toUpperCase()
    return (['and', 'at', 'vs.', 'with', 'per', 'to', 'since'].indexOf(part) !== -1) ? part : format_string_first_character_capitalised(part)
  }).join(' ')
}

export function update_spotlighted_items(item_id, is_checked, spotlighted_item_ids, update_handler) {
  const is_spotlighted = _.contains(spotlighted_item_ids || [], item_id)

  if (!is_checked && !is_spotlighted) {
    return
  }

  const new_spotlighted_item_ids = !is_spotlighted ?
    [...spotlighted_item_ids, item_id]                   // add
    : spotlighted_item_ids.filter(id => id !== item_id)  // remove

  update_handler(new_spotlighted_item_ids)
}

export function get_report_name_with_given_suffix(current_report_name='', suffix) {
  const allowed_length = MAX_REPORT_NAME_LENGTH - suffix.length - 1

  if (current_report_name.length < allowed_length) {
    return current_report_name + ' ' + suffix
  }

  const report_title_chunks = current_report_name.split(' ')

  if (report_title_chunks[0].length >= allowed_length) {
    return report_title_chunks[0].slice(0, allowed_length) + ' ' + suffix
  }

  let new_report_name = ''
  for (let i = 0; i < report_title_chunks.length; i++) {
    if (new_report_name.length + report_title_chunks[i].length < allowed_length) {
      new_report_name = new_report_name + report_title_chunks[i] + ' '
    } else {
      return remove_last_special_character_from_text(new_report_name.trim()) + ' ' + suffix
    }
  }
}

export function set_favicon(is_in_progress_flag) {
  const favicon = document.getElementById('favicon')

  if (favicon) {
    favicon.href = `${process.env.PUBLIC_URL}/${is_in_progress_flag ? FAVICON_WAIT_FILE_NAME : FAVICON_FILE_NAME}`
  }
}

export function generate_subset_id() {
  return generate_random_hash(10)
}

export function create_subset_url_with_new_subset_id(pathname) {
  const parts = pathname.split('/')
  const idx = parts.indexOf(SUBSET_SUBPATH)

  if (idx + 1 < parts.length) {
    parts[idx + 1] = generate_subset_id()
  }

  return parts.join('/')
}

export function show_title_in_browser(report_title) {
  document.title = `Cipher: ${report_title}`
}

export function save_to_report_history(external_report_id, has_viewed, pathname) {
  // If pathname contains project, i.e. user is viewing reports/benchmarks from withing a project do not save viewed history
  if (pathname && pathname.includes(PROJECT)) {
    return
  }

  save_report_viewed(external_report_id, has_viewed)
    .catch(err => {
      // Update report_history failed. Don't rethrow the error, as other promises don't depend on it.
      if (console && console.error) { console.error(err)}
    })
}

export function scroll_window_to_top() {
  window.scrollTo(0, 0)
}

export function is_selections_empty(selections, spec_ignores_tech_selections) {
  if (selections.selected_org_lists && selections.selected_tech_areas) {
    // indicates a benchmarking report, which will have different selections params
    return !selections.selected_org_lists.length || !selections.selected_tech_areas.length
  }
  return [
    selections.selected_portfolio_ids,
    selections.selected_geo_ids,
    ...(spec_ignores_tech_selections ? [] : [selections.selected_tech_ids])
  ].some(ids => !ids || !ids.length)
}

export function set_title_in_browser(report_title) {
  document.title = `Cipher: ${report_title}`
}