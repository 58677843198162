import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { Input } from 'reactstrap'

import { remove_last_special_character_from_text, remove_not_allowed_chars_from_text } from '../../../utils/name_utils.js'
import { EditIcon } from '../../widgets/IconSet.js'
import TextLink from '../../widgets/TextLink.js'
import {
  ESC_KEY,
  ENTER_KEY,
  SPACE_KEY,
  LETTER_A_KEY,
  ARROW_LEFT, ARROW_RIGHT
} from '../../../utils/keyboard_shortcuts/keyboard_utils.js'

import { FormFeedback } from '../../widgets/FormFeedback.js'

import s from './EditableTextLink.module.scss'

/**
 * @param on_click Required (although can be a no-op function)
 * @param to       Optional (a url, rendered as an anchor tag)
 */
const EditableTextLink = ({link_text, to, on_click, on_edit, on_confirm, on_cancel, is_valid, disable, is_edit, is_clickable, is_editable, invalid_message, input_id}) => {
  const [input_value, set_input_value] = useState(null)
  const [update_started, set_update_started] = useState(false)

  function on_change_link_text(e) {
    const value = e.target.value
    set_update_started(true)
    set_input_value(remove_not_allowed_chars_from_text(value))
  }

  function handle_edit() {
    on_edit()
  }

  function handle_cancel() {
    if (on_cancel) {
      on_cancel()
    }
    set_input_value(null)
    set_update_started(false)
  }

  function handle_confirm(with_enter_key) {
    on_confirm(remove_last_special_character_from_text((input_value || '').trim()), with_enter_key)
    set_input_value(null)
    set_update_started(false)
  }

  function input_text_is_valid() {
    return is_valid ? is_valid(input_value) : true
  }

  function on_key_down(e) {
    switch (e.keyCode) {
      case ENTER_KEY:
        e.preventDefault()
        if (input_text_is_valid()) {
          handle_confirm(true)
        }
        break
      case ESC_KEY:
        e.stopPropagation()
        e.preventDefault()
        handle_cancel()
        break
      case SPACE_KEY: case LETTER_A_KEY: case ARROW_LEFT: case ARROW_RIGHT:
        // avoid unwanted behaviours if this component sits inside a MUI datagrid
        // previously the user could not type spaces or use keyboard shortcuts to select all text in the input
        e.stopPropagation()
        break
      default:
        return
    }
  }

  function on_focus_out() {
    return input_text_is_valid() ? handle_confirm() : handle_cancel()
  }

  const is_value_invalid = update_started && !input_text_is_valid()

  return (
    <>
      {!is_edit &&
        <span>
          {on_click &&
            <TextLink
              to={to}
              element={to != null ? Link : null} // if 'to' url is set, then show an anchor link
              onClick={on_click}
              disable={disable || is_clickable === false}
            >
              {link_text}
            </TextLink>
          }
          {(on_click == null) &&
            <span>{link_text}</span>
          }
          <TextLink
            className={cn(is_clickable ? 'show-on-hover' : '', 'ml-1')}
            onClick={handle_edit}
            title='Rename'
            disable={disable || !is_editable}
          >
            <EditIcon/>
          </TextLink>
        </span>
      }

      {is_edit &&
        <div>
          <div className='w-100 d-flex'>
            <Input
              id={input_id || 'editable_text_input'}
              className={cn('my-auto', s.edit_text_input)}
              value={update_started ? input_value : link_text}
              onChange={on_change_link_text}
              onKeyDown={on_key_down}
              onBlur={on_focus_out}
              invalid={is_value_invalid}
              autoFocus
            />

          </div>

          {is_value_invalid && invalid_message &&
            <FormFeedback className='mt-0' valid={false} validation_text={invalid_message} />
          }
        </div>
      }
    </>
  )
}

export default EditableTextLink