import React from 'react'

import Modal from '../../widgets/Modal.js'

import Scope from './Scope.js'


const ScopeModal = (
  {
    on_hide,

    title,
    description,
    taxonomy_path,
    tags,
    training_set_id,
    training_set_infos,
    set_training_set_infos,
    set_current_training_set_info,
    notes,
    phrases_to_highlight,

    no_highlighting,
    on_change_no_highlighting,

    on_change_title,
    on_change_description,
    on_change_notes,
    on_change_phrases_to_highlight,
    handle_edit_taxonomy_path,
    handle_edit_tags,
    on_search_similar_families_by_scope
  }) => {
  return (
    <Modal
      title={`SCOPE`}
      close_label={'Hide'}
      on_hide={on_hide}
    >
      <Scope
        title={title}
        description={description}
        taxonomy_path={taxonomy_path}
        tags={tags}
        training_set_id={training_set_id}
        training_set_infos={training_set_infos}
        set_training_set_infos={set_training_set_infos}
        set_current_training_set_info={set_current_training_set_info}
        notes={notes}
        phrases_to_highlight={phrases_to_highlight}

        no_highlighting={no_highlighting}
        on_change_no_highlighting={on_change_no_highlighting}

        on_change_title={on_change_title}
        on_change_description={on_change_description}
        on_change_notes={on_change_notes}
        on_change_phrases_to_highlight={on_change_phrases_to_highlight}
        handle_edit_taxonomy_path={handle_edit_taxonomy_path}
        handle_edit_tags={handle_edit_tags}
        on_search_similar_families_by_scope={on_search_similar_families_by_scope}
      />
    </Modal>
  )
}

export default ScopeModal