import React, { useState, useEffect } from 'react'

import { ScrollModal } from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'
import { Table } from '../widgets/Table.js'
import {AddToReportIcon, TrashIcon} from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import {
  delete_all_empty_organisations,
  delete_organisation,
  fetch_empty_organisations,
  get_org_id
} from '../../utils/organisation_utils.js'
import { withUser } from '../UserContext.js'

const EmptyOrganisations = ({user, on_hide, on_org_select_handler}) => {
  const [show_spinner, set_show_spinner] = useState(true)
  const [organisations, set_organisations] = useState(null)
  const [is_fetching, set_is_fetching] = useState(true)
  const [is_deleting, set_is_deleting] = useState(false)

  const [fetch_error, set_fetch_error] = useState(null)
  const [delete_error, set_delete_error] = useState(null)

  useEffect(() => {
    let did_cancel = false

    fetch_empty_organisations()
      .then(organisations => {
        if (!did_cancel) {
          set_organisations(organisations)
          set_show_spinner(false)
          set_is_fetching(false)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_show_spinner(false)
          set_fetch_error(error)
          set_is_fetching(false)
        }
      })

    return () => {
      did_cancel = true
    }
  }, [])

  function on_close() {
    set_organisations(null)
    set_fetch_error(null)
    set_show_spinner(true)
    set_is_fetching(true)
    set_is_deleting(false)
    on_hide()
  }

  function on_delete(row) {
    const {index, original} = row
    const org_id = get_org_id(original)
    const {user_id} = user

    delete_organisation({org_id, user_id})
      .then(() => {
        const organisations_copy = [...organisations]
        organisations_copy.splice(index, 1)
        set_organisations(organisations_copy)
      })
      .catch(error => {
        set_delete_error(error)
      })
  }

  function on_select(row) {
    const { original } = row
    on_org_select_handler(original)
  }


  function on_delete_all_empty_organisations() {
    const {user_id} = user

    set_show_spinner(true)
    set_is_deleting(true)

    delete_all_empty_organisations({ user_id})
      .then(() => {
        set_show_spinner(false)
        set_is_deleting(false)
        set_organisations(null)
      })
      .catch(error => {
        set_show_spinner(false)
        set_is_deleting(false)
        set_delete_error(error)
      })
  }

  if (fetch_error) {
    return (
      <ErrorModal
        error={fetch_error}
        on_hide={() => {set_fetch_error(null)}}
        context='fetching empty organisations'
      />
    )
  }

  if (delete_error) {
    return (
      <ErrorModal
        error={delete_error}
        on_hide={() => {set_delete_error(null)}}
        context='deleting organisations'
      />
    )
  }

  function get_actions_cell(row) {
    return (
      <div className='d-flex justify-content-around'>
        <TextLink className='mr-2' onClick={() => {on_select(row)}} title='Use in grouping'>
          <AddToReportIcon />
        </TextLink>
        <TextLink onClick={() => {on_delete(row)}} title='Delete'>
          <TrashIcon/>
        </TextLink>
      </div>
    )
  }

  return (
    <ScrollModal is_open={true} on_hide={on_close} title={`Empty organisations${organisations ? ` (${organisations.length}) ` : ''}`}>
      {show_spinner &&
        <div className='text-center'>
          <Spinner />
          <p>
            {is_fetching && <span>Fetching results...</span>}
            {is_deleting && <span>Removing empty organisations...</span>}
            <br />(this may take a few minutes)
          </p>
        </div>
      }

      {!show_spinner && (!organisations || organisations.length === 0) &&
        <div className='text-center'>[nothing to display]</div>
      }

      {!show_spinner && organisations && organisations.length > 0 &&
        <div>
          <div className='d-flex justify-content-end mb-2'><TextLink  onClick={on_delete_all_empty_organisations}>[clear all]</TextLink></div>

          <Table
            columns={[
              {id: 'name', field: 'name', label: 'Name', resizable: false},
              {id: 'actions', field: 'actions', label: 'Actions', sortable: false, resizable: false, cell_render: (row) => (get_actions_cell(row)), width: 60}
            ]}
            data={organisations}
          />
        </div>
      }
    </ScrollModal>
  )
}

export default withUser(EmptyOrganisations)