import React from 'react'
import cn from 'classnames'

import { CrossIcon } from './IconSet.js'

import s from './TagButton.module.scss'

const TagButton = ({ name, on_click, className,  display_close_icon, disabled}, ref) => {

  return (
    <span ref={ref}>
      <span
        className={cn('mr-1', s.tag_shape, {[s.tag__disabled]: disabled})}
        onClick={on_click}
      >
        <span className={cn(s.tag_name, className)}>{name}</span>
        {display_close_icon && !disabled &&
          <span className={'ml-1'}><CrossIcon className={s.label_as_close_icon}/></span>
        }
      </span>
    </span>
  )
}
export default React.forwardRef(TagButton)