import React, { useState } from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../widgets/Button.js'
import ErrorBody from '../ErrorBody.js'
import {
  CONTEXT,
  create_search_name,
  generate_input_id,
  mask_knn_id,
  is_history_enabled,
  NAME_MAX_LENGTH,
  parse_text_input,
  save_similar_families_search_input,
  get_search_unique_name,
} from '../../utils/knn_search.js'
import { SIMILARITY_SCORE_ID } from '../../model/patent_family_fields.js'
import { DESCENDING } from '../../model/sort_directions.js'
import { KNN } from '../../constants/paths.js'
import { withRouter } from 'react-router-dom'
import { is_400_error } from '../../utils/axios_utils.js'
import BadInputAlertModal from './BadInputAlertModal.js'
import UserSearchesDisplay from './UserSearchesDisplay.js'
import SearchTextInput from './SearchTextInput.js'
import ContainerFixedWidth from '../ContainerFixedWidth.js'
import PrivateSearchModeControl from './PrivateSearchModeControl.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { track_knn_events, track_knn_search_input } from '../../utils/tracking_utils.js'
import { Heading } from '../widgets/PaneHeader.js'
import { SearchIcon } from './TechExplorerIcons.js'

import cs from '../cipher_styles.module.scss'
import s from './InputUnparsed.module.scss'

const InputUnparsed = (
  {
    history,
    should_save_knn_searches,
    anonymous_mode_enabled,
    toggle_anonymous_mode,
  }) => {

  const [is_fetching, set_is_fetching] = useState(false)
  const [input, set_input] = useState(null)

  const [input_error, set_input_error] = useState(null)

  const is_search_valid = (input || '').trim().length > 0

  function on_parse_input() {
    set_input_error(null)
    set_is_fetching(true)

    parse_text_input(input)
      .then(({ query }) => {
        const { pat_fam_ids, text, search_name: service_search_name } = query || {}

        const query_params = {
          ...pat_fam_ids ? { pat_fam_ids } : {},
          ...(text) ? {text} : {},
        }

        const name = ((service_search_name != null) && (service_search_name !== '')) ? service_search_name.slice(0, NAME_MAX_LENGTH) : create_search_name(query_params)

        return get_search_unique_name(name, history_enabled)
          .then(({name}) => {

            return save_similar_families_search_input(
              {
                input: query_params,
                settings: {
                  sort_field_id: SIMILARITY_SCORE_ID,
                  sort_direction_id: DESCENDING,
                },
                name
              },
              generate_input_id(),
              history_enabled ? generate_input_id() : null,
              history_enabled ? generate_input_id() : null,
              history_enabled
            )
              .then(({ id }) => {
                track_knn_search_input(query, id)
                return {id}
              })
        })
      })
      .then(({ id }) => {
        set_is_fetching(false)
        track_knn_events(`context="${CONTEXT}" action="knn_search" obj="start_search_screen" id="${mask_knn_id(id)}"`)
        history.replace(`${KNN}/${id}`)
      })
      .catch(err => {
        set_input_error(err)
        set_is_fetching(false)
      })
  }

  const history_enabled = is_history_enabled({should_save_knn_searches, anonymous_mode_enabled})

  return (
    <div className={cn('w-100 position-relative', s.block)}>
      <div className={cn('d-flex justify-content-center align-items-center', s.background)}>
        <div className='text-center'>
          <div id={s.page_bg_logo}/>
          <div className={cn('mt-2', cs.font_size_x_large, cs.cipher_blue_text)}>Tech Explorer</div>
        </div>
      </div>

      <div className='position-relative'>
        {should_save_knn_searches &&
          <ContainerFullWidth>
            <div className='d-flex justify-content-end py-2'>
              <PrivateSearchModeControl
                is_enabled={should_save_knn_searches}
                is_checked={anonymous_mode_enabled}
                on_click={toggle_anonymous_mode}
                className='my-auto mr-2'
              />
            </div>
          </ContainerFullWidth>
        }

        <ContainerFixedWidth>

          <Heading
            text={(<span><span><SearchIcon /></span>{' '}<span>Enter one or more search criteria</span></span>)}
            className='fs-unmask'
          />

          <SearchTextInput
            input_text={input}
            on_change_input_text={set_input}
            auto_focus={true}
            containerClassName='p-2'
          />

          <div className='d-flex justify-content-end mt-2'>
            <UserSearchesDisplay className='mr-2 my-auto'/>
            <PrimaryButton className='fs-unmask' onClick={() => on_parse_input()} disabled={!is_search_valid || is_fetching}>Search</PrimaryButton>
          </div>

        </ContainerFixedWidth>

        {input_error && !is_400_error(input_error) &&
          <div className='mt-4'>
            <ErrorBody
              context={'searching for similar families'}
              error={input_error}
            />
          </div>
        }

        {input_error && is_400_error(input_error) &&
          <BadInputAlertModal
            error={input_error}
            on_hide={() => set_input_error(null)}
          />
        }
      </div>
    </div>
  )
}

export default withRouter(InputUnparsed)