import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { HOME_PAGE, HISTORY, BUILD_REPORT } from '../../constants/paths.js'
import { toggle_notify_when_report_completes } from '../../utils/report_progress_utils.js'

import TextLink from '../widgets/TextLink.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'

import s from './ReportInProgressBanner.module.scss'


export const BannerWithSpinnerLogo = ({title, className, children}) => {
  return (
    <div className={cn('p-3', s.banner, className)}>
      <div className={s.spinner_logo}/>
      <div className={s.spinner_card_content}>
        {title && <PaneHeader text={title} />}
        {children}
      </div>
    </div>
  )
}

export const ReportInProgressBanner = ({external_report_id, user_is_creator, is_eval_report, notify_when_complete, className}) => {
  const [notify_flag, set_notify_flag]= useState(notify_when_complete || false)
  const [is_loading, set_is_loading]= useState(false)
  const [notify_err, set_notify_err]= useState(null)

  function toggle_notification() {
    set_is_loading(true)
    toggle_notify_when_report_completes(external_report_id, !notify_flag)
      .then(() => {
        set_notify_flag(!notify_flag)
        set_is_loading(false)
      })
      .catch((e) => {
        set_notify_err(e)
        set_is_loading(false)
      })
  }

  const history_page_name = is_eval_report ? 'Evaluation' : 'History'
  const label_text = 'Email me when this report completes.'
  const error_context = 'notifying user on report completion'

  const new_report_link = <TextLink element={Link} to={ user_is_creator ? BUILD_REPORT : HOME_PAGE }>create a new report</TextLink>
  const history_link = <TextLink element={Link} to={HISTORY}>view your report history</TextLink>
  return (
    <BannerWithSpinnerLogo className={className} title='Your report is being created'>
      <div>You can safely close this window, {new_report_link} or {history_link}.</div>
      <div className='mb-2'>From the {history_page_name} page you can check the progress of this report and see when it has completed.</div>

      <div className={s.notify_checkbox_container}>
        {is_loading &&
          <div>
            <Spinner size='sm'/>
          </div>
        }

        {!is_loading && !notify_err &&
          <CheckboxAndLabel
            is_checked={notify_flag}
            label={label_text}
            on_click={toggle_notification}
          />
        }
      </div>
      {notify_err &&
        <ErrorModal
          context={error_context}
          on_retry={toggle_notification}
          error={notify_err}
          on_hide={() => set_notify_err(null)}>
        </ErrorModal>
      }
    </BannerWithSpinnerLogo>
  )
}
