import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import GitInfo from 'react-git-info/macro'

import { get_server_version } from '../utils/version_utils.js'
import { is_cipher_engineering } from '../utils/user_permissions.js'

import ContainerFullWidth from './ContainerFullWidth.js'
import { withUser } from './UserContext.js'

import s from './About.module.scss'


const client_git_info = GitInfo()


const About = ({ user }) => {

  const is_engineering = is_cipher_engineering(user)

  const [server_version_data, set_server_version_data] = useState(null)
  const [is_fetching, set_is_fetching] = useState(true)
  const [error, set_error] = useState(null)

  useEffect(() => {
    get_server_version()
      .then(version_data => {
        set_is_fetching(false)
        set_server_version_data(version_data)
      })
      .catch(err => {
        set_is_fetching(false)
        set_error(err)
      })
  }, [])

  return (
    <ContainerFullWidth>
      <div className={cn('m-5')}>
        <div>
          <h5>Client</h5>
          <table className='table'>
            <tbody>
              <tr>
                <th className={s.first_column}>branch</th>
                <td>{client_git_info.branch}</td>
              </tr>
              <tr>
                <th className={s.first_column}>tags</th>
                <td>{client_git_info.tags}</td>
              </tr>
              <tr>
                <th className={s.first_column}>date</th>
                <td>{client_git_info.commit.date}</td>
              </tr>
              <tr>
                <th className={s.first_column}>hash</th>
                <td>{client_git_info.commit.hash}</td>
              </tr>

              {is_engineering &&
                <tr>
                  <th className={s.first_column}>message</th>
                  <td>{client_git_info.commit.message}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <div className={cn('mt-5')}>
          <h5>Server</h5>
          <div>
            {is_fetching && <span>fetching...</span>}
            {error && <span>(there was an error)</span>}
            {server_version_data &&
              <table className='table'>
                <tbody>
                  <tr>
                    <th className={s.first_column}>Aistemos-FrontendService-Version</th>
                    <td>{server_version_data[0]['Aistemos-FrontendService-Version']}</td>
                  </tr>
                  <tr>
                    <th className={s.first_column}>Aistemos-Software-Version</th>
                    <td>{server_version_data[0]['Aistemos-Software-Version']}</td>
                  </tr>
                  <tr>
                    <th className={s.first_column}>Aistemos-Application-Id</th>
                    <td>{server_version_data[0]['Aistemos-Application-Id']}</td>
                  </tr>
                  <tr>
                    <th className={s.first_column}>Aistemos-Data-Version</th>
                    <td>{server_version_data[0]['Aistemos-Data-Version']}</td>
                  </tr>
                  <tr>
                    <th className={s.first_column}>Aistemos-AWS-Env</th>
                    <td>{server_version_data[0]['Aistemos-AWS-Env']}</td>
                  </tr>
                </tbody>
              </table>
            }
          </div>
        </div>

      </div>
    </ContainerFullWidth>
  )
}

export default withUser(About)