import './ie_imports.js'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from 'history'
import qs from 'query-string'
import { ThemeProvider } from '@mui/material'

// Global css
import 'bootstrap' // bootstrap js (pulls in jquery and popper.js - these need to be in package.json)
import 'bootstrap/dist/css/bootstrap.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import 'react-spinner/react-spinner.css'
import 'react-table/react-table.css'
import 'react-splitter-layout/lib/index.css'

import 'tippy.js/dist/border.css'

import './index.css'

// Global css (overrides)
import './css_overrides/bootstrap.override.scss'
import './css_overrides/plotly.override.scss'
import './css_overrides/react-checkbox-tree.override.scss'
import './css_overrides/react-slider.override.scss'
import './css_overrides/react-spinner.override.scss'
import './css_overrides/react-table.override.scss'
import './css_overrides/react-tippy.override.scss'
import './css_overrides/sentry.override.scss'

import App from './components/App'
import NotSupported from './components/NotSupported.js'

import { track_user_event, tracking_init } from './utils/tracking_utils.js'
import { create_keycloak_client, init_keycloak } from './utils/auth_utils.js'
import { init_axios_middlewares } from './utils/axios_utils.js'
import { check_local_storage_available } from './utils/local_storage_utils.js'
import { is_browser_supported } from './utils/browser_utils.js'
import { init_sentry } from './utils/sentry_utils.js'
import { BM_PREV_HOSTNAME, CIPHER31_PREV_HOSTNAME, CIPHER_AWS_HOSTNAME, CIPHER_HOSTNAME } from './utils/switch_cipher.js'
import { mui_theme } from './constants/mui_config.js'

const NO_AUTH_ID         = process.env.REACT_APP_NO_AUTH_USER_ID
const NO_AUTH_NAME       = process.env.REACT_APP_NO_AUTH_USER_NAME
const NO_AUTH_EMAIL      = process.env.REACT_APP_NO_AUTH_USER_EMAIL
const NO_AUTH_GROUP      = process.env.REACT_APP_NO_AUTH_GROUP
const NO_AUTH_GROUP_NAME = process.env.REACT_APP_NO_AUTH_GROUP_NAME
const NO_AUTH_GROUP_ID   = process.env.REACT_APP_NO_AUTH_GROUP_ID

const NO_AUTH_KEYCLOAK_OBJ = {
  tokenParsed: {
    name: NO_AUTH_NAME,
    email: NO_AUTH_EMAIL,
    sub: NO_AUTH_ID,
    realm_access: {
      roles: [
        'is_admin',
        'is_internal',
        'is_cipher_engineering',
        'is_poweruser', 'has_view_tagged_families', 'has_utt',

        'is_classifiers_admin', // admin role

        'has_view_admin_page',
        'has_view_create_users_page',
        'has_cipher',
        'has_classifiers_edit',

        'has_tag_families',
        'has_nd_report',
        'has_cipher_automotive',
        'has_cipher_abb',
        'has_cipher_schott',

        // 'has_cipher_bae',
        // 'has_cipher_baeair',
        // 'has_cipher_baeairnew',
        // 'has_cipher_baeelectrical',
        // 'has_cipher_baeland',
        // 'has_cipher_baemaritime',

        // For PatentSight Alpha export
        'has_patentsight_sync_classifiers',
      ]
    },
    group: [NO_AUTH_GROUP],
    group_ids: [NO_AUTH_GROUP_ID],
    group_names: [NO_AUTH_GROUP_NAME],

    // For PatentSight Alpha export
    patentsight: {
      classifier_limit: 10,
      classifier_grace: 11,
      positive_limit: 200000,
      positive_grace: 250000,
      contact_emails: 'dave.challis@cipher.ai,dave.challis@aistemos.com,dave.challis+ps1@cipher.ai',
      user_id: 'foo',
    },

    // // To mark as a WAM user
    // identity_provider:          'wam-pdc1c',
    // identity_provider_identity: 'urn:user:PA194613540'
  },
  logout: () => {},
  createAccountUrl: () => '',
  subject: NO_AUTH_ID
}

// History init
const tracker = tracking_init()
const history = createBrowserHistory()

function render_app(kc) {
  createRoot(document.getElementById('root')).render(
    <ThemeProvider theme={mui_theme}>
      <App kc={kc} history={history} tracker={tracker}/>
    </ThemeProvider>
  )
}

function render_login_error() {
  track_user_event('issue="login_error"')

  createRoot(document.getElementById('root')).render(
    <div className='cipher_init_error_container'>
      <h3>
        There was a problem logging into Cipher.
      </h3>
      <br/>
      <h4>
        Please reload the page to try again
        {' '}
        (or
        {' '}
        <button
          className='cipher_init_error_link'
          onClick={() => window.location.reload()}
        >
          click here
        </button>
        )
      </h4>
    </div>
    )
}

function render_browser_not_supported() {
  track_user_event('issue="browser"')

  createRoot(document.getElementById('root')).render(
    <NotSupported>
      <h1 className='text-muted mb-5'>
        Your browser is not yet supported in this version of Cipher.
      </h1>

      <p className='lead text-muted'>
        Currently supported browsers are:<br/>
        Microsoft Edge, Internet Explorer 11, Google Chrome, Firefox.
      </p>
    </NotSupported>
  )
}

function render_local_storage_not_available() {
  track_user_event('issue="local_storage"')

  createRoot(document.getElementById('root')).render(
    <NotSupported>
      <h1 className='text-muted mb-5'>
        Local storage is not available.
      </h1>

      <p className='lead text-muted'>
        Please check your browser.
      </p>
    </NotSupported>
  )
}

function main() {
  const {origin, pathname, search} = window.location

  if (
    (origin.indexOf(CIPHER31_PREV_HOSTNAME) > -1) ||
    (origin.indexOf(BM_PREV_HOSTNAME) > -1) ||
    (origin.indexOf(CIPHER_AWS_HOSTNAME) > -1)
  ) {
    window.location.replace(CIPHER_HOSTNAME + pathname + (search || ''))
  }

  const browser_supported = is_browser_supported()
  const local_storage_available = check_local_storage_available()

  if (!browser_supported) {
    render_browser_not_supported()
    return
  }

  if (!local_storage_available) {
    render_local_storage_not_available()
    return
  }

  init_sentry(history)

  if (process.env.REACT_APP_NO_AUTH === 'true') {
    render_app(NO_AUTH_KEYCLOAK_OBJ)
    return
  }

  const idp_hint = qs.parse(search).kc_idp_hint

  const kc = create_keycloak_client()

  init_keycloak(kc)
    .catch(err => {
      // There was a problem initialising keycloak
      render_login_error()
      if (console && console.log) {
        console.log('init error')
      }
      throw err
    })
    .then((authenticated) => {
      if (!authenticated) {

        if (pathname.startsWith('/family')) {
          init_axios_middlewares(kc, false)
          render_app(kc)
          return
        }

        init_axios_middlewares(kc, true)
        // User needs to be authenticated, so redirect to keycloak
        kc.login({ idpHint: idp_hint })

        return
      }
      init_axios_middlewares(kc, true)
      // User is authenticated ok, so we can render app
      render_app(kc)
    })
}

main()

