import React, { useState, useEffect } from 'react'
import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { EditIcon } from '../../widgets/IconSet.js'
import TextLink from '../../widgets/TextLink.js'
import ConfirmOrCancel from '../../widgets/ConfirmOrCancel.js'
import { remove_double_spaces, replace_tabs_with_spaces } from '../../../utils/utils.js'
import { remove_not_allowed_chars_from_text } from '../../../utils/name_utils.js'

import s from './EditableSource.module.scss'

/**
 * Component that displays user input publications in editable mode
 * @param on_click Required (although can be a no-op function)
 * @param to       Optional (a url, rendered as an anchor tag)
 */
//TODO: see EditableTextLink.js from the report management functionality and refactor to use common functionality

export const EditableSource = ({initial_text, do_validate, row_number, do_cancel, disable, className}) => {
  const [input_value, set_input] = useState(initial_text)
  const [previous_input_value, set_previous_input] = useState(initial_text)
  const [is_edit, set_edit] = useState(false)

  useEffect(() => {
    set_input(initial_text)
  }, [initial_text])


  function on_input_change(event) {
    const new_input = event.target.value.trim()
    set_input(new_input)
  }

  function set_edit_mode() {
    set_previous_input(input_value)
    set_input(input_value)
    set_edit(true)
  }

  function on_cancel() {
    set_input(previous_input_value)
    if (do_cancel) {
      do_cancel()
    }
    set_edit(false)
  }

  function on_confirm() {
    const input = normalise_value(input_value)
    do_validate(input, row_number)
    set_input(input)
    set_edit(false)
  }

  function on_key_down(e) {
    switch (e.keyCode) {
      case 13:
        e.preventDefault()
        on_confirm()
        break
      case 27:
        e.preventDefault()
        on_cancel()
        break
      default:
        return
    }
  }

  function normalise_value(input_value) {
    return remove_double_spaces(replace_tabs_with_spaces(remove_not_allowed_chars_from_text( input_value || ''))).trim()
  }

  //COMPONENT RENDERING
  return (
    <div className={className}>
      {!is_edit &&
      <div>
        <InputGroup>
          <span className={s.text_input}>{input_value || ''}</span>
          <TextLink
            onClick={set_edit_mode}
            title='Edit'
            disable={disable}
          >
            <EditIcon
              className={s.edit_icon}
            />
          </TextLink>
        </InputGroup>
      </div>
      }

      {(is_edit) &&
      <div>
        <InputGroup>
          <Input
            className={s.edit_text_input}
            value={input_value || ''}
            onChange={on_input_change}
            onKeyDown={on_key_down}
            autoFocus
          />
          <InputGroupAddon addonType='append'>
            <ConfirmOrCancel
              is_valid={normalise_value(input_value) !== ''}
              on_submit={() => on_confirm(input_value)}
              on_cancel={on_cancel}
            />
          </InputGroupAddon>
        </InputGroup>
      </div>
      }
    </div>
  )
}


