import React from 'react'
import cn from 'classnames'

import { IGNORE, NEGATIVE, POSITIVE, TEST_NEGATIVE, TEST_POSITIVE, UNKNOWN } from '../constants/labels.js'
import { BlockedIcon, CrossIcon, RemoveLabelsIcon, TickIcon } from '../../widgets/IconSet.js'
import { TestNegativeIcon, TestPositiveIcon } from './TestSetIcons.js'

import s from './LabelIcon.module.scss'

const LabelIcon = ({ label, className, no_colour, unknown_as_delete, unknown_as_empty, explicit_size }) => {
  if (label === POSITIVE ) {
    return <TickIcon className={cn(s.label_icon, {[s.positive_label__coloured]: !no_colour, [s.label_icon__small]: !explicit_size}, className)}/>
  }

  if (label === NEGATIVE) {
    return <CrossIcon className={cn(s.label_icon, {[s.negative_label__coloured]: !no_colour, [s.label_icon__small]: !explicit_size}, className)}/>
  }

  if (label === TEST_POSITIVE) {
    return <TestPositiveIcon className={cn(s.label_icon, s.label_icon__test, {[s.test_positive_label__coloured]: !no_colour, [s.label_icon__small]: !explicit_size}, className)}/>
  }


  if (label === TEST_NEGATIVE) {
    return <TestNegativeIcon className={cn(s.label_icon, s.label_icon__test, {[s.test_negative_label__coloured]: !no_colour, [s.label_icon__small]: !explicit_size}, className)}/>
  }

  if (label === IGNORE) {
    return <BlockedIcon className={cn(s.label_icon, {[s.ignore_label__coloured]: !no_colour, [s.label_icon__small]: !explicit_size}, className)}/>
  }

  if (label === UNKNOWN) {
    if (unknown_as_empty) {
      return <span/>
    }
    if (unknown_as_delete) {
      return <RemoveLabelsIcon className={cn(s.label_icon, {[s.label_icon__small]: !explicit_size}, className)}/>
    } else {
      return <span className={className}>unknown</span>
    }
  }

  return null
}

export default LabelIcon