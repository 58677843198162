import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { send_error_to_sentry } from '../utils/sentry_utils.js'
import { is_aistemos, is_cipher_engineering } from '../utils/user_permissions.js'
import { extract_external_report_id } from '../utils/report_url_utils.js'

import TextLink from './widgets/TextLink.js'
import Modal from './widgets/Modal.js'
import { PrimaryButton } from './widgets/Button.js'
import Spinner from './widgets/Spinner.js'
import { ErrorDetails } from './ErrorDetails.js'
import { withUser } from './UserContext.js'
import { MONITOR, REPORT } from '../constants/paths.js'
import { FEEDBACK_CATEGORIES_BY_ID } from '../model/feedback.js'
import FeedbackModal from './FeedbackModal.js'
import { add_source_err_to_target_err } from '../utils/axios_utils.js'

const ErrorBody = ({ error, errorInfo, context, extra_info, show_details_in_modal, user, on_retry, is_retrying, is_expected_error }) => {
  const [show_details, set_show_details] = useState(false)
  const [event_id, set_event_id] = useState(null)

  useEffect(() => {
    send_error_to_sentry(error, errorInfo)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function setup_and_open_feedback_modal() {
    // PLEASE NOTE:
    //
    // Sentry may have sent the error itset.lf previously, BEFORE the ErrorBody component even opens.
    // This is because 'GlobalHandlers' attaches to onunhandledreject (i.e. re-thrown errors in a handler promise),
    // and 'TryCatch' wraps async functions such as setTimeout
    // See https://docs.sentry.io/platforms/javascript/configuration/integrations/default/#globalhandlers
    // See https://docs.sentry.io/platforms/javascript/configuration/integrations/default/#trycatch
    //
    // In such cases, we will not have the correct event id here.
    //
    // Hence here we wrap the error (bypassing Sentry deduplication logic), and re-send it.
    // This may result in a duplicate error in Sentry.
    //
    // But it guarantees that we will have the the correct event id for the FeedbackForm.

    const wrapped_err = add_source_err_to_target_err(error, new Error(), '')
    const event_id = send_error_to_sentry(wrapped_err, errorInfo) || -1 // When running locally without Sentry enabled, event_id will be null, so we add a fake "-1" here.
    set_event_id(event_id)
  }

  function toggle_show_details() {
    set_show_details(!show_details)
  }


  const Wrapper = show_details_in_modal ?  Modal : 'div'

  const is_aistemos_user = is_aistemos(user)
  const is_engineering = is_cipher_engineering(user)

  const report_id = extract_external_report_id(window.location.href)

  const is_online = window.navigator.onLine

  if (!is_online) {
    return (
      <>
        <p>There seems to be no internet connection.</p>

        <p>Please check your connection.</p>

        <PrimaryButton
          onClick={() => {
            if (on_retry) {
              on_retry()
            } else {
              window.location.reload() // reload page
            }
          }}
        >
          Retry
        </PrimaryButton>
      </>
    )
  }

  return (
    <span>
      <p>
        There was an error{context ? ' while ' + context : ''}.
      </p>

      {extra_info &&
        <p>
          {extra_info}
        </p>
      }

      {(error == null) &&
        <p>No details available</p>
      }

      {(error != null) &&
        <div>
          {error.context &&
            <p>
              context: {error.context}
            </p>
          }

          {is_engineering && report_id &&
            <span className='mr-1'>
              <TextLink element={Link} className='mr-1' target='_blank' to={`${MONITOR}${REPORT}/${report_id}`}>
                View in report monitor
              </TextLink>
              |
            </span>
          }

          {is_aistemos_user &&
            <TextLink
              onClick={toggle_show_details}
            >
              {show_details ? 'Hide' : 'Show'} error details
            </TextLink>
          }

          {show_details &&
            <Wrapper {...(show_details_in_modal) ? { on_hide: toggle_show_details } : {}}
                     className={cn({ 'mt-3': !show_details_in_modal })}>
              <ErrorDetails error={error}/>
            </Wrapper>
          }

          <p className='mt-3'>
            {!is_expected_error ?
              <span>Our team has already been notified, but please click <TextLink onClick={() => setup_and_open_feedback_modal()}>here</TextLink> to fill out a report.</span>
              : <span>If you would like to fill out an error report, please click <TextLink onClick={() => setup_and_open_feedback_modal()}>here</TextLink></span>
            }

          </p>
       </div>
      }

      {on_retry &&
        <PrimaryButton
          onClick={() => {
            on_retry()
          }}
          disabled={is_retrying}
        >
          Retry
        </PrimaryButton>
      }

      {is_retrying &&
        <span className='ml-2 mt-2 position-absolute'>
          <Spinner size='sm'/>
        </span>
      }

      {event_id &&
        <FeedbackModal
          title='Report Cipher error'
          category={FEEDBACK_CATEGORIES_BY_ID.error.label}
          subject={event_id ? `Cipher error ${event_id}` : null}
          disable_subject_change={true}
          additional_comment={error.response ? JSON.stringify(error.response) : (error.message || 'No error message')}

          on_hide={() => set_event_id(null)}
        />
      }

    </span>
  )
}

export default withUser(ErrorBody)