import React from 'react'

import PatentUpload from '../../patent_upload/PatentUpload.js'
import { get_patent_upload_as_portfolio_item } from '../../../model/portfolio_basket.js'
import { filter_matched_patents } from '../../patent_upload/table/matched_patents_table_utils.js'
import { GOOGLE_VALUATION_REPORT_STREAM_ID } from './report_streams.js'

const PortfolioUpload = ({ is_wizard_final_step, available_upload_mode_ids, on_select_portfolios_handler, stream_id, can_wizard_continue, className }) => {
  function select_patent_upload(patent_search) {
    const { name, found_families, table_rows } = patent_search
    const found = table_rows ? filter_matched_patents(table_rows) : []

    if ((found_families || {}).length === 0) {
      return on_select_portfolios_handler(null)
    }

    const patent_upload_item = {
      name,
      pat_fam_ids: found_families,
      group_by_owner: true,
      lines: found.map(row => row.lineno),
      inputs: found.map(row => row.source),
      publications: found.map(row => row.publication)
    }
    on_select_portfolios_handler([get_patent_upload_as_portfolio_item(patent_upload_item)])
  }

  return (
    <div className={className}>
      <PatentUpload
        parsed_results_action_handler={select_patent_upload}
        is_wizard={true}
        is_wizard_final_step={is_wizard_final_step}
        can_wizard_continue={can_wizard_continue}
        available_upload_mode_ids={available_upload_mode_ids}
        enable_rename={stream_id===GOOGLE_VALUATION_REPORT_STREAM_ID}
      />
    </div>
  )
}

export default PortfolioUpload