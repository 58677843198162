import { useEffect } from 'react'

import { ok_to_proceed_with_shortcuts } from '../../../../utils/keyboard_shortcuts/keyboard_utils.js'

import { S_KEY } from '../../../../constants/keys.js'
import { toggle_scope_modal } from '../../utils/scope_modal_utils.js'

/**
 * Custom react hook, which registers keyboard shortcuts.
 */
export function useClassifierKeyboardShortcuts(props) {
  const { disable } = props

  useEffect(() => {
    if (disable) {
      return undefined
    }

    // Register handler (post-render)
    const on_keyup_with_props = on_keyup.bind(null, props)
    document.addEventListener('keyup', on_keyup_with_props)

    return () => {
      // Unregister handler (on pre-render/unmount)
      document.removeEventListener('keyup', on_keyup_with_props)
    }
  })
}

function on_keyup(props, event) {
  const {
    history
  } = props

  const { keyCode } = event

  if (keyCode === S_KEY) {
    // Special logic to allow keyboard shortcuts while the Scope modal is showing...
    if (!ok_to_proceed_with_shortcuts(event, true /* allow modal */)) {
      return
    }
    toggle_scope_modal(history)
    return
  }
}