export const WELCOME_TAG = 'welcome_tag'
export const NEW_REPORT_TAG = 'new_report_tag'
export const QUICK_ORGANISATION_SEARCH_TAG = 'quick_organisation_search_tag'
export const MULTI_SEARCH_TAG = 'multi_search_tag'
export const CUSTOM_CLASSIFIERS_TAG = 'custom_classifiers_tag'
export const ALERT_SUBSCRIPTIONS_TAG = 'alert_subscriptions_tag'
export const AUTO_INSIGHTS_TAG = 'auto_insights_tag'
export const IA_INSIGHTS_TAG = 'ia_insights_tag'
export const N_D_TAG = 'n_d_tag'
export const GOOGLE_VALUATION_TAG = 'google_valuation_tag'
export const CUSTOM_UPLOAD_TAG = 'custom_upload_tag'
export const CLASSIFIER_REPORT_TAG = 'classifier_report_tag'
export const UTT_LANDSCAPE_REPORT_TAG = 'utt_landscape_report_tag'
export const FEEDBACK_TAG = 'feedback_tag'
export const SUPPORT_AND_TRAINING_TAG = 'support_and_training_tag'
export const SET_THEORY_TAG = 'set_theory_tag'