import React from 'react'
import _ from 'underscore'

import Filters from './Filters.js'
import { get_count_inc_rollups } from '../../utils/ref_data_utils.js'
import RollupLimitControls from './RollupLimitControls.js'
import { DEFAULT_ROLLUP_LIMIT } from '../../model/rollups.js'
import {
  get_processed_data_and_key_dims,
  is_rollup_possible,
  sort_and_rollup_data
} from '../../utils/column_data_utils.js'
import {
  get_geo_column_idx,
  get_spotlighted,
  get_next_agglom_visibility,
  get_portfolio_column_idx,
  get_rollup_thresholds,
  get_tech_column_idx
} from '../../utils/main_items_utils.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import { GEO_SELECTOR_ID, ORG_SELECTOR_ID, TECH_SELECTOR_ID } from '../../constants/filters.js'
import {
  DESELECTED_GEO_IDS,
  DESELECTED_PORTFOLIO_IDS,
  DESELECTED_TECH_IDS
} from '../../constants/main_items_state_params.js'

const MainReportItemFilters = (
  {
    spec,
    item,
    data,
    selections,
    ref_data,
    data_creation_date,
    deref_data,
    report_has_scores,
    selected_view_id,
    item_processed_key_dims,
    report_series_sort,
    on_change_rollup_thresholds,
    on_change_dataset_portfolios_selection,
    on_change_dataset_techs_selection,
    on_change_dataset_geo_selection,
    on_change_report_portfolios_selection,
    on_change_report_techs_selection,
    on_change_report_geo_selection,
    on_change_dataset_spotlighted_portfolios,
    on_change_dataset_spotlighted_techs
}) => {

  const has_no_rollups = get_has_no_rollups({spec, item})

  function get_has_no_rollups({spec, item}) {
    const {no_rollups} = spec || {}
    return (no_rollups) ? no_rollups({item}) : false
  }

  function update_threshold_limits(key_dim_idx, new_limit, threshold_limits, deselected_items_to_reset) {
    const updated_thresholds = (threshold_limits || []).map((item, i) => {
      if (i === key_dim_idx) {
        return new_limit
      }
      return item
    })

    on_change_rollup_thresholds(updated_thresholds, deselected_items_to_reset)
  }

  function is_next_agglom_present(items) {
    if (!items || items.length === 0) return false

    return items[items.length - 1].is_next_agglom
  }

  function update_next_agglom_visibility(selected_items, items, is_next_agglom_present, dim_column_idx, next_agglom_visible) {
    const is_all_selected = selected_items.length === items.length
    const is_none_selected = selected_items.length === 0

    let updated_next_agglom_visibility = null

    if ((is_none_selected || is_all_selected) && is_next_agglom_present) {
      updated_next_agglom_visibility = [...next_agglom_visible]
      updated_next_agglom_visibility[dim_column_idx] = (is_none_selected) ? false : true
    }

    return updated_next_agglom_visibility
  }

  function update_portfolios_selection(new_selected_portfolio_ids, portfolios, portfolios_full, dim_column_idx, next_agglom_visible) {
    const { selected_portfolio_ids=[] } = selections
    const deselected_portfolio_ids = _.without(selected_portfolio_ids, ...new_selected_portfolio_ids)
    const updated_next_agglom_visibility = update_next_agglom_visibility(new_selected_portfolio_ids, portfolios_full, is_next_agglom_present(portfolios), dim_column_idx, next_agglom_visible)
    on_change_dataset_portfolios_selection(deselected_portfolio_ids, updated_next_agglom_visibility)
  }

  function update_techs_selection(new_selected_tech_ids, techs, techs_full, dim_column_idx, next_agglom_visible) {
    const { selected_tech_ids=[] } = selections
    const deselected_tech_ids = _.without(selected_tech_ids, ...new_selected_tech_ids)
    const updated_next_agglom_visibility = update_next_agglom_visibility(new_selected_tech_ids, techs_full, is_next_agglom_present(techs), dim_column_idx, next_agglom_visible)
    on_change_dataset_techs_selection(deselected_tech_ids, updated_next_agglom_visibility)
  }

  function update_geos_selection(new_selected_geo_ids, geos, geos_full, dim_column_idx, next_agglom_visible) {
    const { selected_geo_ids=[] } = selections
    const deselected_geo_ids = _.without(selected_geo_ids, ...new_selected_geo_ids)
    const updated_next_agglom_visibility = update_next_agglom_visibility(new_selected_geo_ids, geos_full, is_next_agglom_present(geos), dim_column_idx, next_agglom_visible)
    on_change_dataset_geo_selection(deselected_geo_ids, updated_next_agglom_visibility)
  }

  function on_change_portfolios_next_agglom_item_visibility() {
    const updated_next_agglom_visible = toggle_next_agglom_item(portfolio_dim_column_idx, next_agglom_visible)
    const {deselected_portfolio_ids=[]} = item
    const new_deselected_portfolio_ids = get_deselected_items_on_agglom_toggle(portfolios, deselected_portfolio_ids, updated_next_agglom_visible[portfolio_dim_column_idx])
    on_change_dataset_portfolios_selection(new_deselected_portfolio_ids, updated_next_agglom_visible)
  }

  function on_change_techs_next_agglom_item_visibility() {
    const updated_next_agglom_visible = toggle_next_agglom_item(technology_dim_column_idx, next_agglom_visible)
    const {deselected_tech_ids=[]} = item
    const new_deselected_tech_ids = get_deselected_items_on_agglom_toggle(techs, deselected_tech_ids, updated_next_agglom_visible[technology_dim_column_idx])
    on_change_dataset_techs_selection(new_deselected_tech_ids, updated_next_agglom_visible)
  }

  function on_change_geos_next_agglom_item_visibility() {
    const updated_next_agglom_visible = toggle_next_agglom_item(geo_dim_column_idx, next_agglom_visible)
    const {deselected_geo_ids=[]} = item
    const new_deselected_geo_ids = get_deselected_items_on_agglom_toggle(geos, deselected_geo_ids, updated_next_agglom_visible[geo_dim_column_idx])
    on_change_dataset_geo_selection(new_deselected_geo_ids, updated_next_agglom_visible)
  }

  function get_deselected_items_on_agglom_toggle(items, deselected_ids, is_next_agglom_visible) {
    const {child_ids=[]} = items.filter(item => item.is_next_agglom)[0] || {}
    return is_next_agglom_visible ? _.difference(deselected_ids, child_ids) : _.uniq([...deselected_ids, ...child_ids])
  }

  function toggle_next_agglom_item(key_dim_idx, next_agglom) {
    return (next_agglom || []).map((item, i) => {
      if (i === key_dim_idx) {
        const show = next_agglom[key_dim_idx]
        return !show
      }
      return item
    })
  }

  function get_selected_items(items, is_next_agglom_visible, key_id) {
    if (!items || items.length === 0) return []

    const selected_ids = items.reduce((ids, item) => {
      const {is_next_agglom, id, child_ids} = item || {}
      if (!is_next_agglom) {
        return [...ids, id]
      }

      if (is_next_agglom && is_next_agglom_visible) {
        const next_agglom_ids = child_ids.map(item => (key_id === 'country_code') ? item : item * 1)
        return [...ids, ...next_agglom_ids]
      } else {
        return ids
      }

    }, [])

    return selected_ids
  }

  function copy_portfolios_selection_to_global(portfolios, selected_portfolio_ids) {
   const ids = portfolios
     .filter(item => !item.is_next_agglom && (selected_portfolio_ids.indexOf(item.id) !== -1))
     .map(item => item.id)
    
    track_report_viewer_event(`obj="report" filter="${ORG_SELECTOR_ID}" action="update" option="copy_to_global"`)
    on_change_report_portfolios_selection(ids)
  }

  function copy_techs_selection_to_global(techs, selected_tech_ids) {
    const ids = techs
      .filter(item => !item.is_next_agglom && (selected_tech_ids.indexOf(item.id) !== -1))
      .map(item => item.id)
    track_report_viewer_event(`obj="report" filter="${TECH_SELECTOR_ID}" action="update" option="copy_to_global"`)
    on_change_report_techs_selection(ids)
  }

  function copy_geos_selection_to_global(geos, selected_geo_ids) {
    const ids = geos
      .filter(item => !item.is_next_agglom && (selected_geo_ids.indexOf(item.country_code) !== -1))
      .map(item => item.country_code)
    track_report_viewer_event(`obj="report" filter="${GEO_SELECTOR_ID}" action="update" option="copy_to_global"`)
    on_change_report_geo_selection(ids)
  }

  function rollup_items_if_possible(data, items_sorted, column_idx, value_column_idx, selected_thresholds ) {
    const selected_threshold = selected_thresholds[column_idx] || (is_rollup_possible(DEFAULT_ROLLUP_LIMIT, items_sorted.length) ? DEFAULT_ROLLUP_LIMIT : null)

    const [, items] = (selected_threshold && selected_threshold !== -1) ? sort_and_rollup_data(data, items_sorted, column_idx, value_column_idx, selected_threshold, null, false) : [null, items_sorted]
    return items
  }

  function get_filter_items() {
    const { value_to_sort_column_idx } = spec

    const [processed_data, processed_key_dims, ] = !data ? [null, null, null] : get_processed_data_and_key_dims(spec, selected_view_id, data_creation_date, item, data, ref_data, deref_data, report_series_sort, false, false )

    if (!processed_data) return {
      portfolios: null,
      techs: null,
      geos: null
    }

    const value_column_idx = value_to_sort_column_idx || processed_data.data.length - 1

    const portfolio_dim_column_idx = get_portfolio_column_idx(spec)
    const technology_dim_column_idx = get_tech_column_idx(spec)
    const geo_dim_column_idx = get_geo_column_idx(spec)

    let portfolios = null
    let techs = null
    let geos = null

    let portfolios_full = null
    let techs_full = null
    let geos_full = null

    let portfolios_next_agglom_visible = null
    let techs_next_agglom_visible = null
    let geos_next_agglom_visible = null

    let selected_portfolio_ids = null
    let selected_tech_ids = null
    let selected_geo_ids = null
    
    let selected_portfolios_count = null
    let selected_techs_count = null
    let selected_geos_count = null
    
    const rollup_thresholds = selected_rollup_thresholds || item_processed_key_dims ? get_rollup_thresholds(item, item_processed_key_dims.length) : []
    if ((portfolio_dim_column_idx !== -1)) {
      const portfolios_sorted = processed_key_dims[portfolio_dim_column_idx]
      portfolios_full = portfolios_sorted
      const server_side_rollup_item = portfolios_sorted.filter(item => item.rollup)[0] || null
      const dataset_portfolios = item_processed_key_dims[portfolio_dim_column_idx] || []
      portfolios = rollup_items_if_possible(processed_data, portfolios_sorted, portfolio_dim_column_idx, value_column_idx, rollup_thresholds)
      portfolios_next_agglom_visible = !has_no_rollups && next_agglom_visible[portfolio_dim_column_idx]
      selected_portfolio_ids = get_selected_items(dataset_portfolios, true, 'portfolio_id')
      const visible_portfolio_ids =  get_selected_items(dataset_portfolios, portfolios_next_agglom_visible, 'portfolio_id')
      selected_portfolios_count = (server_side_rollup_item && visible_portfolio_ids.indexOf(server_side_rollup_item.id) > -1) ? visible_portfolio_ids.length + server_side_rollup_item.rollup.count -1 : visible_portfolio_ids.length
    }
  
    if (technology_dim_column_idx !== -1) {
      const technologies_sorted = processed_key_dims[technology_dim_column_idx]
      techs_full = technologies_sorted
      const dataset_techs = item_processed_key_dims[technology_dim_column_idx] || []
      techs = rollup_items_if_possible( processed_data, technologies_sorted, technology_dim_column_idx, value_column_idx, rollup_thresholds)
      techs_next_agglom_visible = !has_no_rollups && next_agglom_visible[technology_dim_column_idx]
      selected_tech_ids = get_selected_items(dataset_techs, true, 'technology_id')
      const visible_tech_ids = get_selected_items(dataset_techs, techs_next_agglom_visible, 'technology_id')
      selected_techs_count = visible_tech_ids.length
    }

    if (geo_dim_column_idx !== -1) {
      const geos_sorted = processed_key_dims[geo_dim_column_idx]
      geos_full = geos_sorted
      const dataset_geos = item_processed_key_dims[geo_dim_column_idx] || []
      geos = rollup_items_if_possible( processed_data, geos_sorted, geo_dim_column_idx, value_column_idx, rollup_thresholds)
      geos_next_agglom_visible = !has_no_rollups && next_agglom_visible[geo_dim_column_idx]
      selected_geo_ids = get_selected_items(dataset_geos, true, 'country_code')
      const visible_geo_ids = get_selected_items(dataset_geos, geos_next_agglom_visible, 'country_code')
      selected_geos_count = visible_geo_ids.length
    }

    return {
      portfolio_dim_column_idx,
      technology_dim_column_idx,
      geo_dim_column_idx,
      portfolios,
      portfolios_full,
      portfolios_next_agglom_visible,
      selected_portfolio_ids,
      selected_portfolios_count,

      techs,
      techs_full,
      techs_next_agglom_visible,
      selected_tech_ids,
      selected_techs_count,

      geos,
      geos_full,
      geos_next_agglom_visible,
      selected_geo_ids,
      selected_geos_count
    }
  }

  const { selected_rollup_thresholds } = item
  const next_agglom_visible = item_processed_key_dims ? get_next_agglom_visibility(item, item_processed_key_dims.length) : []
  const rollup_thresholds = selected_rollup_thresholds || item_processed_key_dims ? get_rollup_thresholds(item, item_processed_key_dims.length) : []

  const {
    portfolio_dim_column_idx,
    technology_dim_column_idx,
    geo_dim_column_idx,
    portfolios,
    portfolios_full,
    portfolios_next_agglom_visible,
    selected_portfolio_ids,
    selected_portfolios_count,
    techs,
    techs_full,
    techs_next_agglom_visible,
    selected_tech_ids,
    selected_techs_count,
    geos,
    geos_full,
    geos_next_agglom_visible,
    selected_geo_ids,
    selected_geos_count
  } = get_filter_items()

  const { spotlighted_portfolios: global_spotlighted_portfolios, spotlighted_techs: global_spotlighted_techs } = selections || {}
  const { spotlighted_portfolios: item_spotlighted_portfolios, spotlighted_techs: item_spotlighted_techs } = get_spotlighted(item)
  const spotlighted_portfolios = {...(global_spotlighted_portfolios || {}), ...(item_spotlighted_portfolios || {})}
  const spotlighted_techs = {...(global_spotlighted_techs || {}), ...(item_spotlighted_techs || {})}

  const can_clear_portfolio_spotlights = ((item_spotlighted_portfolios || {}).ids || []).length > 0
  const can_clear_tech_spotlights =((item_spotlighted_techs || {}).ids || []).length > 0

  return (
    <Filters
      chart_filters={true}

      portfolios={portfolios}
      portfolios_count_incl_rollups={portfolios ?  get_count_inc_rollups(portfolios) : null}
      portfolios_agglom_controls={
        <RollupLimitControls
          items={portfolios_full}
          selected_rollup_thresholds={rollup_thresholds[portfolio_dim_column_idx] || DEFAULT_ROLLUP_LIMIT}
          on_limit_select={(new_limit) => update_threshold_limits(portfolio_dim_column_idx, new_limit, rollup_thresholds, DESELECTED_PORTFOLIO_IDS)}
          className='mb-2'
        />
      }
      portfolios_next_agglom_visible={portfolios_next_agglom_visible}
      on_change_portfolios_next_agglom_item_visibility={on_change_portfolios_next_agglom_item_visibility}
      selected_portfolio_ids={selected_portfolio_ids}
      set_selected_portfolio_ids={(portfolio_ids) => update_portfolios_selection(portfolio_ids, portfolios, portfolios_full, portfolio_dim_column_idx, next_agglom_visible)}
      selected_portfolios_count={selected_portfolios_count}
      update_report_selected_portfolios={() => copy_portfolios_selection_to_global(portfolios, selected_portfolio_ids)}
      spotlighted_portfolios={spotlighted_portfolios}
      set_spotlighted_portfolios={on_change_dataset_spotlighted_portfolios}
      can_clear_portfolio_spotlights={can_clear_portfolio_spotlights}

      techs={techs}
      techs_count_incl_rollups={techs ? get_count_inc_rollups(techs) : null}
      techs_agglom_controls={
        <RollupLimitControls
          items={techs_full}
          selected_rollup_thresholds={rollup_thresholds[technology_dim_column_idx] || DEFAULT_ROLLUP_LIMIT}
          on_limit_select={(new_limit) => update_threshold_limits(technology_dim_column_idx, new_limit, rollup_thresholds, DESELECTED_TECH_IDS)}
          className='mb-2'
        />
      }
      techs_next_agglom_visible={techs_next_agglom_visible}
      on_change_techs_next_agglom_item_visibility={on_change_techs_next_agglom_item_visibility}
      selected_tech_ids={selected_tech_ids}
      set_selected_tech_ids={(tech_ids) => update_techs_selection(tech_ids, techs, techs_full, technology_dim_column_idx, next_agglom_visible)}
      selected_techs_count={selected_techs_count}
      update_report_selected_techs={() => copy_techs_selection_to_global(techs, selected_tech_ids)}
      spotlighted_techs={spotlighted_techs}
      set_spotlighted_techs={on_change_dataset_spotlighted_techs}
      can_clear_tech_spotlights={can_clear_tech_spotlights}

      geos={geos}
      geos_count_incl_rollups={geos ? get_count_inc_rollups(geos) : null}
      geos_agglom_controls={
        <RollupLimitControls
          items={geos_full}
          selected_rollup_thresholds={rollup_thresholds[geo_dim_column_idx] || DEFAULT_ROLLUP_LIMIT}
          on_limit_select={(new_limit) => update_threshold_limits(geo_dim_column_idx, new_limit, rollup_thresholds, DESELECTED_GEO_IDS)}
          className='mb-2'
        />
      }
      geos_next_agglom_visible={geos_next_agglom_visible}
      on_change_geos_next_agglom_item_visibility={on_change_geos_next_agglom_item_visibility}
      selected_geo_ids={selected_geo_ids}
      set_selected_geo_ids={(geo_ids) => update_geos_selection(geo_ids, geos, geos_full, geo_dim_column_idx, next_agglom_visible)}
      selected_geos_count={selected_geos_count}
      update_report_selected_geos={() => copy_geos_selection_to_global(geos, selected_geo_ids)}

      report_has_scores={report_has_scores}
      no_rollups={has_no_rollups}
    />
  )
}

export default MainReportItemFilters