export const PERMISSION_ENTITY_ENTITY_TYPE_INVALID = 'PERMISSION_ENTITY_ENTITY_TYPE_INVALID'
export const PERMISSION_ENTITY_ENTITY_TYPE_USER    = 'PERMISSION_ENTITY_ENTITY_TYPE_USER'
export const PERMISSION_ENTITY_ENTITY_TYPE_GROUP   = 'PERMISSION_ENTITY_ENTITY_TYPE_GROUP'
export const PERMISSION_ENTITY_ENTITY_TYPE_ROLE    = 'PERMISSION_ENTITY_ENTITY_TYPE_ROLE'

export const USER = 'user'
export const GROUP = 'group'

export const IS_ME = 'is_me'
export const IS_SOLUTIONS = 'is_solutions'

