import React from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'
import { Slider } from '@mui/material'

import { CUSTOM_SCORE_RANGE } from '../model/filters.js'

import BaseDropdown from '../../widgets/BaseDropdown.js'

import s from './ScoreFilterControl.module.scss'


/**
 * @param {} className
 * @param {} filter_groups An array of group objects, where each has 'name' and 'children' properties. Each child item has 'id' and 'name' properties
 * @param {} selected_filter_name
 * @param {} on_change_filter_id
 */
const ScoreFilterControl = ({
  className,
  filter_groups,
  selected_filter_id,
  on_change_filter_id,
  custom_score_range,
  on_change_custom_score_range,
  title
}) => {
  const selected_filter = _.find(_.flatten(filter_groups.map(group => group.children)), filter => filter.id === selected_filter_id)

  if (!selected_filter) return null

  const { name } = selected_filter

  return (
    <span className={cn('d-flex', 'align-items-center', className)}>
      <div>{title || 'Show'}</div>

      <BaseDropdown
        className={cn('ml-1')}
        label={name}
        right={false}
        buttonClassName={s.button}
        menuClassName={s.menu}
        labelClassName={s.button_label}
      >
        {filter_groups.map(({ name: group_name, children }, i) => {
          // for each group of filter ids, show the filter options and a Divider line
          return (
            <span key={i}>
              {(i > 0) &&
                <DropdownItem divider/>
              }
              { group_name != null &&
                <DropdownItem header>
                  {group_name}
                </DropdownItem>
              }
              {children.map((filter, j) => {
                const { id, name, short_name, disabled, is_subfilter, is_container } = filter

                const name_clean = short_name || name

                return (
                  <DropdownItem
                    key={j}
                    onClick={on_change_filter_id.bind(null, id)}
                    disabled={disabled}
                    className={cn({
                      [s.sub_container]: is_container,
                      [s.sub]:           is_subfilter,
                      'px-2':            is_subfilter,
                      'font-italic':     is_subfilter,

                      // Subfilters and containers are shown in-line
                      'd-inline':        is_subfilter || is_container,
                      'w-auto':          is_subfilter || is_container,
                    })}
                    toggle={false} // Keep the dropdown open always
                  >
                    {name_clean}
                    {((selected_filter.id === CUSTOM_SCORE_RANGE) && (id === CUSTOM_SCORE_RANGE)) &&
                      <div className={cn('d-flex', 'align-items-center', 'ml-3')}>
                        <Slider
                          size="small"
                          value={custom_score_range}
                          onChange={(event, value) => on_change_custom_score_range(value)}
                          valueLabelDisplay="off"
                          step={0.1}
                          min={0}
                          max={1}
                        />
                        <div
                          className={cn('ml-3', s.slider_label)}
                        >
                          {custom_score_range[0]} to {custom_score_range[1]}
                        </div>
                      </div>
                    }
                  </DropdownItem>
                )
              })}
            </span>
          )
        })}
      </BaseDropdown>

    </span>
  )
}

export default ScoreFilterControl