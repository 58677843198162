import React, { useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import {
  ID_TO_REPORT_STREAM,
  NEW_FILINGS_IN_TECH_STREAM_ID,
  REPORT_STREAM_GROUPS, TECH_LANDSCAPE_STREAM_ID,
} from './report_streams.js'
import { withUser } from '../../UserContext.js'
import { NewFilingsReportParamsSelect, TechLandscapeParamsSelect, PortfolioTypeSelect } from './StreamParams.js'
import {
  ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION,
  STEP_PORTFOLIO_BOOLEAN_SEARCH, STEP_TECHNOLOGY_BINARY_CLASS,
  STEP_TECHNOLOGY_UTT
} from './builder_wizard.js'
import { STREAM_GROUP_TO_CLASSNAME } from './stream_styles.js'
import Connector from './Connector.js'

import cs from '../../cipher_styles.module.scss'
import s from './StreamsDisplay.module.scss'

const StreamsDisplay = ({user, user_has_classifiers, user_has_utt, on_select_stream_handler, className}) => {
  const user_can_select_tech_type = user_has_classifiers && user_has_utt

  const [selected_stream_id, set_selected_stream_id] = useState(null)
  const [selected_portfolio_type, set_selected_portfolio_type] = useState(null)
  const [selected_landscape_tech_type, set_selected_landscape_tech_type] = useState(null)
  const [accession_date_threshold, set_accession_date_threshold] = useState(null)

  function is_group_available(group) {
    const {streams} = group || {}

    if (!streams || streams.length === 0) return false

    const streams_availability = streams.map((stream) => {
      const {check_if_available} = stream
      return check_if_available ? check_if_available({user, user_has_classifiers}) : true
    })

    return _.some(streams_availability, item => item === true)
  }

  function on_select_stream(stream_id) {
    if (selected_stream_id && stream_id === selected_stream_id) {
      set_selected_stream_id(null)
      return
    }

    const {portfolios=[]} = ID_TO_REPORT_STREAM[stream_id] || {}
    const default_tech_type = user_has_utt ? STEP_TECHNOLOGY_UTT : STEP_TECHNOLOGY_BINARY_CLASS
    if (stream_id === TECH_LANDSCAPE_STREAM_ID && user_can_select_tech_type) {
      set_selected_stream_id(stream_id)
      set_selected_landscape_tech_type(default_tech_type)
      return
    }

    if (stream_id === NEW_FILINGS_IN_TECH_STREAM_ID) {
      set_selected_stream_id(stream_id)
      set_accession_date_threshold(ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION)
      set_selected_landscape_tech_type(default_tech_type)
      return
    }

    if (portfolios.length > 1) {
      set_selected_stream_id(stream_id)
      set_selected_portfolio_type((portfolios[0] || {}).id)
      return
    }

    const {id: portfolio_type} = portfolios[0] || {}
    on_select_stream_handler({stream_id, portfolio_type, landscape_tech_type: stream_id === TECH_LANDSCAPE_STREAM_ID ? default_tech_type : null })
  }

  function on_confirm_from_portfolio_type_select() {
    on_select_stream_handler({stream_id: selected_stream_id, portfolio_type: selected_portfolio_type})
  }

  function on_confirm_from_landscape_tech_type() {
    on_select_stream_handler({stream_id: selected_stream_id, landscape_tech_type: selected_landscape_tech_type})
  }

  function on_confirm_from_new_filings_report_params() {
    on_select_stream_handler({stream_id: selected_stream_id, portfolio_type: STEP_PORTFOLIO_BOOLEAN_SEARCH, accession_date_threshold, landscape_tech_type: selected_landscape_tech_type})
  }

  return (
    <div className={className}>
      {REPORT_STREAM_GROUPS.map((stream_group => {
        const {id: group_id, label: group_label, streams} = stream_group || {}

        if (!is_group_available(stream_group)) return null

        const {background: group_className, light_background: group_light_background_className} = STREAM_GROUP_TO_CLASSNAME[group_id] || {}

        const {group_id: selected_stream_group_id, portfolios} = ID_TO_REPORT_STREAM[selected_stream_id] || {}

        const is_group_stream_selected = (selected_stream_group_id === group_id)

        return (
          <div key={group_id} className={cn('mt-3 d-sm-flex align-items-start')}>
            <div className={cn('mb-3', group_className, s.panel)}>
              <div className={cn('p-1', cs.font_size_larger, s.panel_header)}>
                {group_label}
              </div>
              <div className={s.panel_links_wrapper}>
                <div className={cn(s.panel_links)}>
                  {streams.map(item => {
                    const {id, label, check_if_available} = item || {}

                    const is_available = check_if_available ? check_if_available({user, user_has_classifiers}) : true

                    if (!is_available) return null

                    const is_selected = (id === selected_stream_id)
                    return (
                      <div className={cn([{[group_light_background_className]: is_selected}])} key={id}>
                        <div
                          onClick={() => on_select_stream(id)}
                          className={cn(['p-2', cs.cursor_pointer, s.stream, {[s.stream__selected]: is_selected}])}
                        >
                          {label}
                        </div>
                      </div>
                  )})}
                </div>
              </div>
            </div>

            {is_group_stream_selected &&
              <Connector className={cn('d-none d-sm-block', s.connect, group_className)} />
            }

            {is_group_stream_selected && portfolios &&
              <PortfolioTypeSelect
                portfolio_types={portfolios}
                selected_portfolio_type={selected_portfolio_type}
                on_portfolio_type_select_handler={set_selected_portfolio_type}
                on_confirm={on_confirm_from_portfolio_type_select}
                on_close={() => set_selected_stream_id(null)}
                className={group_className}
              />
            }

            {is_group_stream_selected && (selected_stream_id === TECH_LANDSCAPE_STREAM_ID) &&
              <TechLandscapeParamsSelect
                selected_landscape_tech_type={selected_landscape_tech_type}
                on_landscape_tech_type_select_handler={set_selected_landscape_tech_type}

                on_confirm={on_confirm_from_landscape_tech_type}
                on_close={() => set_selected_stream_id(null)}
                className={group_className}
              />
            }

            {is_group_stream_selected && (selected_stream_id === NEW_FILINGS_IN_TECH_STREAM_ID) &&
              <NewFilingsReportParamsSelect
                user_can_select_tech_type={user_can_select_tech_type}
                selected_landscape_tech_type={selected_landscape_tech_type}
                on_landscape_tech_type_select_handler={set_selected_landscape_tech_type}
                accession_date_threshold={accession_date_threshold}
                on_accession_date_change_handler={set_accession_date_threshold}

                on_confirm={on_confirm_from_new_filings_report_params}
                on_close={() => set_selected_stream_id(null)}
                className={group_className}
              />
            }
          </div>
        )
      }))}
    </div>
  )
}

export default withUser(StreamsDisplay)