import _ from 'underscore'

export function get_priority_year(priority_date) {
  return priority_date ? new Date(priority_date).getFullYear() : null
}

export function get_owners_string(owners) {
  return (owners && owners.length) ? owners.join(' | ') : '(private)'
}

export function get_cpc_string(cpc_codes) {
  return cpc_codes && cpc_codes.length ? _.unique(cpc_codes.map(get_group_cpc_code)).join(', ') : null
}

export function get_group_cpc_code(cpc_code) {
  return cpc_code.slice(0, 4)
}

export function get_group_cpc_to_base_cpcs(cpc_codes) {
  const group_cpc_to_base_cpcs = cpc_codes.reduce((acc, cpc) => {
    const group_cpc = get_group_cpc_code(cpc)
    const base_cpcs = (acc[group_cpc] || [])
    const new_base_cpcs = _.unique([...base_cpcs, cpc])
    return { ...acc, [group_cpc]: new_base_cpcs }
  }, {})

  return group_cpc_to_base_cpcs
}

export function get_metadata_fields_only(classifier) {
  // Only send metadata fields (not patent fams etc)
  return _.pick(classifier, ['title', 'description'])
}

export function get_score(patent) {
  const { score } = patent
  return score
}