import React from 'react'
import cn from 'classnames'

import s from './StepDisplay.module.scss'

const StepDisplay = ({is_current, is_first, is_final, on_click, className, children}) => {

  if (is_current) {
    return (
      <div className={cn(['d-flex', s.step, {[s.step__first]: is_first}, {[s.step__final]: is_final}, className])}>
        <div className={cn(['m-auto', s.step, {[s.step__first]: is_first}, {[s.step__final]: is_final}, s.step__current])}>
          {children}
        </div>
      </div>
    )
  }

  return (
    <div className={cn([s.step, {[s.step__first]: is_first}, className])} onClick={on_click}>
      {children}
    </div>
  )
}


export const AwaitingStep = () => {
  return (
    <div>
      <div className={cn(s.step, s.step__awaiting)} />
    </div>
  )
}

export default StepDisplay