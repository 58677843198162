import React from 'react'
import { matchPath } from 'react-router'
import cn from 'classnames'

import { PATENT_NUMBER_SUBPATH } from '../../constants/viewer_paths.js'
import { FAMILY } from '../../constants/paths.js'

import { has_access_to_cipher, is_creator } from '../../utils/user_permissions.js'

import { ExternalLinkIconInline } from './IconSet'
import TextLink from './TextLink'
import { withUser } from '../UserContext.js'
import SimilarFamiliesLink from '../family_view/SimilarFamiliesLink.js'

import s from './CipherFamilyLink.module.scss'

const CipherFamilyLink = (
  {
    user,

    family_id,
    selected_patent_number,
    display_text,
    display_text_as_link,
    display_link_icon,

    on_family_id_click,
    show_similar_families_search,

    disabled,

    is_missing,

    eval_training_set_id,  // optional
    eval_classifier_data,  // optional

    className,
}) => {
  if (!family_id) return ''

  let family_to_display = family_id

  const is_family_details_page = matchPath(window.location.pathname, {path: `${FAMILY}/:cipher_family_id`})
  if ((family_id.indexOf('NaN') > -1) && is_family_details_page) {
    //retrieve the value from the URI to match
    family_to_display = is_family_details_page.params.cipher_family_id
  }

  const url = `${FAMILY}/${family_to_display}${selected_patent_number ? `/${PATENT_NUMBER_SUBPATH}/${selected_patent_number}` : ''}`

  return (
    <div className={cn('d-flex', 'align-items-center', className)}>
      {(display_text || display_text_as_link) &&
        <span>
          {display_text && !display_text_as_link && family_to_display}
          {display_text_as_link &&
            <TextLink
              element={on_family_id_click ? 'div' : 'a'}
              href={on_family_id_click ? null : url}
              onClick={on_family_id_click}
              disable={disabled}
            >
              {family_to_display}
            </TextLink>
          }
        </span>
      }

      {display_link_icon && !is_family_details_page &&
        <TextLink
          element='a'
          target='_blank'
          rel='noopener noreferrer'
          href={url}
          disable={disabled}
          className='ml-2 my-auto'
        >
          <ExternalLinkIconInline className={s.external_link_icon}/>
        </TextLink>
      }

      {show_similar_families_search && !is_missing && has_access_to_cipher(user) && is_creator(user) &&
        <SimilarFamiliesLink
          className='ml-2 my-auto'
          patfam_id={family_id}
          eval_training_set_id={eval_training_set_id}
          eval_classifier_data={eval_classifier_data}
        />
      }

    </div>
)}

export default withUser(CipherFamilyLink)