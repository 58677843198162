import React, { useState, useEffect, useCallback } from 'react'
import cn from 'classnames'
import { FormGroup } from 'reactstrap'

import { withUser } from '../UserContext.js'
import { withReportBasket } from './ReportBasketContext.js'
import { ScrollModal } from '../widgets/Modal.js'
import ReportNameInput from '../ReportNameInput.js'
import { Heading } from '../widgets/PaneHeader.js'
import ReportBuilderPortfolioOptions from './ReportBuilderPortfolioOptions.js'
import TextLink from '../widgets/TextLink.js'

import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { get_as_map } from '../../utils/utils.js'
import { is_valid_report_name, normalise_name, remove_not_allowed_chars_from_text } from '../../utils/name_utils.js'
import { get_data_version } from '../../utils/domain_utils.js'
import {
  AVAILABLE_SCORE_THRESHOLDS,
} from '../../model/technology_basket.js'
import {
  get_basket_portfolio_total_size,
  get_report_type_by_flag,
  get_version_appropriate_existing_reports,
  pick_report_name,
  PORTFOLIO_SIZE_WARNING_THRESHOLD
} from '../../utils/report_builder_utils.js'
import { has_utt } from '../../utils/user_permissions.js'
import ErrorBody from '../ErrorBody.js'
import { usePrevious } from '../../hooks/general_hooks.js'
import MultiLabelOptions from './MultiLabelOptions.js'
import NegativesProcessingOptions from './NegativesProcessingOptions.js'
import ExistingReportInfo from '../ExistingReportInfo.js'
import CreateReportButtonGroup from '../CreateReportButtonGroup.js'
import { get_default_utt_version, should_include_unrelated_technologies } from '../../utils/user_settings_utils.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../../constants/report_input.js'
import {
  get_negatives_processing,
  NEGATIVES_PROCESSING_EXCLUDE,
  NEGATIVES_PROCESSING_INCLUDE,
  NEGATIVES_PROCESSING_UTT,
} from '../../model/negatives_processing.js'

import s from './ReportBuilderOptionsModal.module.scss'
import ScoreThresholdControl from './ScoreThesholdControl.js'

const ReportBuilderOptionsModal = (
  {
    is_open,
    user,
    portfolio_basket,
    portfolio_basket_sizes,
    portfolio_basket_orgs_total_size,
    base_report_name,
    technology_basket,
    on_close_handler,
    base_portfolios_to_cluster,
    is_nd_report,
    is_valuation_report,
    base_technology_options,
    check_existing_reports_handler,
    do_report_handler,
    create_report_from_existing_handler,
    update_portfolio_basket_item_handler,
    update_all_portfolio_basket_items_handler,
    portfolio_roll_up_limit,
    set_portfolio_roll_up_limit,
    group_by_owner_level,
    set_group_by_owner_level,
    report_type,
    use_utt_superclasses,
    set_report_type,
    user_settings
  }) => {

  const user_has_utt = has_utt(user)

  const previously_open = usePrevious(is_open)

  const portfolio_size = get_basket_portfolio_total_size(portfolio_basket, portfolio_basket_sizes, portfolio_basket_orgs_total_size)
  const is_report_big = portfolio_size > PORTFOLIO_SIZE_WARNING_THRESHOLD

  const utt_version = get_default_utt_version(user_settings)

  function get_default_negatives_processing_type() {
    if (is_report_big) return NEGATIVES_PROCESSING_EXCLUDE

    if (is_valuation_report) return NEGATIVES_PROCESSING_INCLUDE

    const include_unrelated_technologies_from_settings = should_include_unrelated_technologies(user_settings)

    if (is_nd_report) {
      return ((include_unrelated_technologies_from_settings == null) || !include_unrelated_technologies_from_settings) ?
        NEGATIVES_PROCESSING_EXCLUDE :
        NEGATIVES_PROCESSING_INCLUDE
    }

    if ((include_unrelated_technologies_from_settings != null) && !include_unrelated_technologies_from_settings) return NEGATIVES_PROCESSING_EXCLUDE

    return user_has_utt ? NEGATIVES_PROCESSING_UTT : NEGATIVES_PROCESSING_INCLUDE
  }

  const [portfolio_to_cluster, set_portfolio_to_cluster] = useState(null)
  const [technology_filters, set_technology_filters] = useState([])
  const [technology_multi_label, set_technology_multi_label] = useState(is_nd_report === true)
  const [technology_negatives_processing_type, set_technology_negatives_processing_type] = useState(get_default_negatives_processing_type())

  const [technology_score_threshold, set_technology_score_threshold] = useState(CLASSIFIER_SCORE_THRESHOLD_DEFAULT)
  const [is_create_existing_clicked, set_is_create_existing_clicked] = useState(false)
  const [show_advanced_options, set_show_advanced_options] = useState(false)
  const [report_name, set_report_name] = useState('')
  const [custom_report_name_provided, set_custom_report_name_provided] = useState(false)

  const [existing_report, set_existing_report] = useState(null)
  const [in_progress_report, set_in_progress_report] = useState(null)
  const [should_check_existing_reports, set_should_check_existing_reports] = useState(false)
  const [existing_reports_fetch_error, set_existing_reports_fetch_error] = useState(null)

  const get_report_name_callback = useCallback(() => {
    if (custom_report_name_provided) {
      return report_name
    }

    if (base_report_name) {
      return base_report_name.trim()
    }

    const auto_report_name = pick_report_name(portfolio_basket)

    return `${(is_nd_report) ? 'n/d ': ''}${auto_report_name}`.trim()
  }, [custom_report_name_provided, report_name, base_report_name, portfolio_basket, is_nd_report])

  const build_options_callback = useCallback(() => {
    return {
      user_report_name: normalise_name(get_report_name_callback()),
      auto_report_name: pick_report_name(portfolio_basket),
      portfolios_to_cluster: (portfolio_to_cluster || []).map((item, i) => item ? i : '').filter(String),
      negatives_processing: get_negatives_processing({type: technology_negatives_processing_type, utt_version}),
      filters: technology_filters,
      multi_label: technology_multi_label,
      threshold: technology_score_threshold
    }
  }, [portfolio_basket, portfolio_to_cluster, technology_filters, technology_multi_label, technology_negatives_processing_type, technology_score_threshold, utt_version, get_report_name_callback])

  const get_default_portfolio_to_cluster_callback = useCallback(() => {
    if (!base_portfolios_to_cluster) {
      return portfolio_basket.map(() => { return !is_report_big })
    }

    const base_portfolio_clustered = base_portfolios_to_cluster.filter(item => item.to_cluster)

    //if less than a half of portfolios was selected to cluster then probably the intention wasn't to select all
    const should_new_item_be_clustered_by_default = is_report_big ? false : base_portfolios_to_cluster.length/base_portfolio_clustered.length <= 2

    const base_portfolios_to_cluster_by_names = get_as_map(base_portfolios_to_cluster, 'name')

    const portfolio_to_cluster = portfolio_basket.map(item => {
      const base_portfolio = base_portfolios_to_cluster_by_names[item.name]

      if (base_portfolio) {
        return is_report_big ? false : base_portfolio.to_cluster
      }

      return should_new_item_be_clustered_by_default
    })

    return portfolio_to_cluster
  }, [portfolio_basket, base_portfolios_to_cluster, is_report_big ])

  useEffect(() => {
    if (!is_open) return
    const {include_negatives /*deprecated by negatives_processing*/, negatives_processing, filters, multi_label, threshold} = base_technology_options || {}
    if (!previously_open) {
      set_portfolio_to_cluster(get_default_portfolio_to_cluster_callback())
      if (base_technology_options) {
        const {type: negatives_processing_type} = negatives_processing || {}

        set_technology_filters(filters || [])
        set_technology_multi_label((multi_label != null) ? multi_label : (is_nd_report === true))
        const negatives = is_report_big ? NEGATIVES_PROCESSING_EXCLUDE : (
          include_negatives != null ? (include_negatives ? (user_has_utt ? NEGATIVES_PROCESSING_UTT : NEGATIVES_PROCESSING_INCLUDE) : NEGATIVES_PROCESSING_EXCLUDE) : negatives_processing_type
        )

        set_technology_negatives_processing_type(negatives)
        set_technology_score_threshold(threshold || CLASSIFIER_SCORE_THRESHOLD_DEFAULT)
      }

      set_report_name(base_report_name || '')
    }
  }, [is_open, previously_open, base_technology_options, is_report_big, is_nd_report, get_default_portfolio_to_cluster_callback, base_report_name, user_has_utt])

  useEffect(() => {
    // Check for existing reports
    if (!(is_open && should_check_existing_reports)) return
    const options_obj = build_options_callback()
    set_existing_reports_fetch_error(null)
    Promise.all([check_existing_reports_handler(options_obj), get_data_version()])
      .then(([{completed_report, running_report}, {data_version}]) => {
        const { existing_report, in_progress_report } = get_version_appropriate_existing_reports(completed_report, running_report, data_version)

        set_existing_report(existing_report)
        set_in_progress_report(in_progress_report)
        set_should_check_existing_reports(false)
      })
      .catch(error => {
        set_existing_report(null)
        set_in_progress_report(null)
        set_existing_reports_fetch_error(error)
        set_should_check_existing_reports(false)
      })
  }, [is_open, should_check_existing_reports, check_existing_reports_handler, build_options_callback])

  useEffect(() => {
    if (!is_open) return
    set_should_check_existing_reports(true)
  }, [is_open, portfolio_basket, portfolio_roll_up_limit, group_by_owner_level, report_type, use_utt_superclasses])

  function build_report() {
    const options_obj = build_options_callback()
    do_report_handler(options_obj)
  }

  function build_from_existing(existing_internal_report_id) {
    track_report_builder_event(`action="select_cached" report_type="${get_report_type_by_flag(report_type, is_nd_report)}" context="builder"`)

    create_report_from_existing_handler(existing_internal_report_id, build_options_callback())
    set_is_create_existing_clicked(true)
  }

  function toggle_portfolio_to_cluster(item_id) {
    const updated_portfolio_to_cluster = [...portfolio_to_cluster]
    updated_portfolio_to_cluster[item_id] = !updated_portfolio_to_cluster[item_id]
    set_portfolio_to_cluster(updated_portfolio_to_cluster)
    set_should_check_existing_reports(true)
  }

  function toggle_group_by_owner(item_id) {
    let item = {...portfolio_basket[item_id]}
    item.group_by_owner = !item.group_by_owner
    update_portfolio_basket_item_handler(item, item_id)
  }

  function update_technology_multi_label(value) {
    set_technology_multi_label(value)
    set_should_check_existing_reports(true)
  }

  function update_technology_negatives_processing_type(value) {
    set_technology_negatives_processing_type(value)
    set_should_check_existing_reports(true)
  }

  function toggle_advanced_options() {
    set_show_advanced_options(!show_advanced_options)
  }

  function on_change_from_report_name_input(value) {
    set_report_name(remove_not_allowed_chars_from_text(value))
    set_custom_report_name_provided(true)
  }

  function on_technology_score_threshold_change(value) {
    set_technology_score_threshold(value)
    set_should_check_existing_reports(true)
  }

  function select_none_to_cluster() {
    set_portfolio_to_cluster(portfolio_basket.map(() => { return false }))
    set_should_check_existing_reports(true)
  }

  function select_all_to_cluster() {
    set_portfolio_to_cluster(portfolio_basket.map(() => { return true }))
    set_should_check_existing_reports(true)
  }

  const technology_basket_count = Object.keys(technology_basket || {}).length
  const is_technology_basket = (technology_basket_count !== 0)

  const has_multiple_techs = technology_basket_count > 1

  const is_create_buttons_disabled =  (custom_report_name_provided && !is_valid_report_name(report_name)) || is_create_existing_clicked

  return (
    <ScrollModal
      on_hide={on_close_handler}
      footer={(
        <CreateReportButtonGroup
          existing_report={existing_report}
          in_progress_report={in_progress_report}
          disabled={is_create_buttons_disabled}
          build_report={build_report}
          build_from_existing={build_from_existing}
        />
      )}
      title='Report options'
      is_open={is_open}

      bodyClassName={s.modal_scroll_body}
    >
      <div className='d-flex flex-column h-100'>

        <ReportNameInput
          report_name={get_report_name_callback()}
          on_change={on_change_from_report_name_input}
          className={cn(s.report_name_input, 'mb-3')}
        />

        {is_technology_basket &&
          <div>
            <Heading text='Technology settings'/>

            <div className='d-flex flex-wrap flex-sm-nowrap'>

              {has_multiple_techs &&
                <MultiLabelOptions
                  technology_multi_label={technology_multi_label}
                  update_technology_multi_label={update_technology_multi_label}
                  className={cn('mb-2 mb-sm-1', s.tech_option__multi_label)}
                />
              }

              {!is_valuation_report &&
                <NegativesProcessingOptions
                  selected={technology_negatives_processing_type}
                  is_nd_report={is_nd_report}
                  on_select={(id) => update_technology_negatives_processing_type(id)}

                  is_report_big={is_report_big}

                  className={cn('mb-2 mb-sm-1 mr-2', s.tech_option__unrelated)}
                />
              }

              {show_advanced_options &&
                <FormGroup className={cn('mb-1', s.tech_option__score_band)}>
                  <ScoreThresholdControl
                    score_threshold_objs={AVAILABLE_SCORE_THRESHOLDS}
                    selected_score_threshold_value={technology_score_threshold}
                    on_change_score_threshold={on_technology_score_threshold_change}
                    user={user}
                 />
                </FormGroup>
              }

            </div>

            {!is_nd_report &&
              <div className='mb-1'>
                <TextLink onClick={toggle_advanced_options}>
                  [{show_advanced_options ? 'hide' : 'show'} advanced]
                </TextLink>
              </div>
            }
          </div>
        }

        <ReportBuilderPortfolioOptions
          portfolio_basket={portfolio_basket}
          portfolio_to_cluster={portfolio_to_cluster}
          is_technology_basket={is_technology_basket}
          select_all_to_cluster_handler={select_all_to_cluster}
          select_none_to_cluster_handler={select_none_to_cluster}
          toggle_portfolio_to_cluster_handler={toggle_portfolio_to_cluster}
          update_all_portfolio_basket_items_handler={update_all_portfolio_basket_items_handler}
          toggle_group_by_owner_handler={toggle_group_by_owner}
          is_clustering_disabled={!is_technology_basket && is_report_big}
          portfolio_roll_up_limit={portfolio_roll_up_limit}
          set_portfolio_roll_up_limit={set_portfolio_roll_up_limit}
          group_by_owner_level={group_by_owner_level}
          set_group_by_owner_level={set_group_by_owner_level}
          report_type={report_type}
          use_utt_superclasses={use_utt_superclasses}
          set_report_type={set_report_type}
          is_valuation_report={is_valuation_report}
          className={cn(s.portfolio_wrapper, 'mt-3')}
        />

        {existing_reports_fetch_error &&
          <ErrorBody
            context={'fetching reports from cache'}
            error={existing_reports_fetch_error}
          />
        }

        {existing_report &&
          <ExistingReportInfo
            className={cn(s.existing_report_section, 'mt-3')}
            existing_report={existing_report}
          />
        }

      </div>
    </ScrollModal>
  )
}

export default withUser(withReportBasket(ReportBuilderOptionsModal))