import React from 'react'
import cn from 'classnames'

import { format_integer_with_comma } from '../../utils/utils.js'

import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from './IconSet.js'
import { PrimaryButton } from './Button.js'

import s from './ForwardBackSelector.module.scss'


const ForwardBackSelector = ({
  className,
  buttonClassName,
  no_label,
  label,
  current_idx,
  min_idx_incl, // inclusive
  max_idx_excl, // exclusive
  on_next,
  on_prev
}) => {
  return (
    <span
      className={cn('d-flex', 'align-items-center', className)}
    >
      <PrimaryButton
        className={cn(s.button, buttonClassName)}
        size={'xs'}
        disabled={current_idx <= min_idx_incl}
        onClick={() => {
          on_prev()
        }}
      >
        <KeyboardArrowLeftIcon
          className={s.button_icon}
        />
      </PrimaryButton>

      {!no_label &&
        <div className={cn('ml-1 d-flex flex-nowrap', s.label_text)} key={current_idx}>
          <div>{label || <span>page</span>}&nbsp;<span>{current_idx + 1}</span></div>
          {max_idx_excl > 0 &&
            <div>&nbsp;of&nbsp;{format_integer_with_comma(max_idx_excl)}</div>
          }
        </div>
      }

      <PrimaryButton
        className={cn(s.button, 'ml-1', buttonClassName)}
        size={'xs'}
        disabled={current_idx >= (max_idx_excl - 1)}
        onClick={() => {
          on_next()
        }}
      >
        <KeyboardArrowRightIcon
          className={cn(s.button_icon)}
        />
      </PrimaryButton>

    </span>
  )
}

export default ForwardBackSelector