import React from 'react'
import { withRouter } from 'react-router-dom'
import { matchPath } from 'react-router'

import ItemHeaderButton from './ItemHeaderButton.js'
import { UndoIcon } from '../widgets/IconSet.js'

const ItemHeaderUndoButton = (
  {
    history,
    on_refresh,
    on_close,
    level_1_path,
    level_2_path,
    level_3_path,
    return_btn_ref,
    className
  }) => {
  const path = window.location.pathname

  const level_1_exact_match = level_1_path == null ? null : matchPath(path, { path: level_1_path, exact: true })
  const level_2_exact_match = level_2_path == null ? null : matchPath(path, { path: level_2_path, exact: true })
  const level_3_exact_match = level_3_path == null ? null : matchPath(path, { path: level_3_path, exact: true })
  return (
    <ItemHeaderButton
      className={className}
      on_click={() => {
        // Move one level up
        if (level_1_exact_match) {
          on_close()
          return
        }
        if (level_2_exact_match) {
          history.push(level_1_path)
          on_refresh()
          return
        }
        if (level_3_exact_match) {
          history.push(level_2_path)
          return
        }
      }}
      reference={return_btn_ref}
    >
      <UndoIcon/>
    </ItemHeaderButton>
  )
}

export default withRouter(ItemHeaderUndoButton)