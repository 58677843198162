import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import Modal from '../widgets/Modal.js'
import TextLink from '../widgets/TextLink.js'
import Spinner from '../widgets/Spinner.js'

import { withUser } from '../UserContext.js'
import UserSettingsDisplay from '../UserSettingsDisplay.js'
import ErrorModal from '../ErrorModal.js'
import AlertModal from '../AlertModal.js'

import { fetch_wam_oidc_token as fetch_wam_oidc_data } from '../../utils/auth_utils.js'
import { IS_WAM_USER, is_sso_user } from '../../utils/user_permissions'
import { get_default_chart_selection } from '../../utils/user_settings_utils.js'

import s from './UserSettingsModal.module.scss'

function check_is_token_expired(idp_data) {
  // NOTE: WAM access token ttl is one hour from issue (i.e. when user last logged in via WAM)
  const { accessTokenExpiration } = idp_data
  const expires_at = accessTokenExpiration * 1000
  const now = new Date().getTime()
  const DELTA = 10 * 1000 // i.e. ten seconds
  if (now >= (expires_at - DELTA)) {
    // Token has expired
    // For now in this case, we ask user to log out and in again.
    // In future, possibly we could try and refresh the token.
    return true
  }
  return false
}

const UserSettingsModal = ({
  user,
  on_hide,
  user_settings,
  user_chart_sets,
  change_region_grouping_handler,
  change_thumbnails_handler,
  change_should_show_ungrouped_families_handler,
  change_default_chart_selection_handler,
  change_default_utt_version_handler,
  change_starting_page_handler,
  change_should_include_unrelated_techs_handler,
  toggle_notify_before_report_expiry_handler,
  change_language_preference_handler,
  change_save_knn_searches_handler,
  reset_settings_handler
}) => {

  const { reset_password_url, [IS_WAM_USER]: is_wam_user } = user || {}

  const [is_fetching_wam_data,    set_is_fetching_wam_data]    = useState(false)
  const [error_fetching_wam_data, set_error_fetching_wam_data] = useState(null)
  const [is_show_logout,          set_is_show_logout]          = useState(false)
  const [wam_idp_data,            set_wam_idp_data]            = useState(null)

  useEffect(() => {
    if (is_wam_user) {
      fetch_wam_idp_data()
    }
  }, [is_wam_user])

  function fetch_wam_idp_data() {
    set_is_fetching_wam_data(true)
    set_error_fetching_wam_data(null)

    fetch_wam_oidc_data()
      .then((wam_idp_data) => {
        set_wam_idp_data(wam_idp_data)
      })
      .catch((error) => {
        set_error_fetching_wam_data(error)
        throw error
      })
      .finally(() => {
        set_is_fetching_wam_data(false)
      })
  }

  const is_expired = !wam_idp_data ? null : check_is_token_expired(wam_idp_data)

  const wam_change_password_url = `https://signin.lexisnexis.com/lnaccess/updatep?cls=y&aci=ciph`

  return (
    <Modal is_open={true} on_hide={on_hide} title={'User settings'} no_footer={true}>

      {(is_fetching_wam_data) &&
        <Spinner
          size={'md'}
          className={cn('')}
        />
      }

      {error_fetching_wam_data &&
        <ErrorModal
          on_hide={() => set_error_fetching_wam_data(null)}
          on_retry={() => fetch_wam_idp_data()}
          error={error_fetching_wam_data}
          context='getting WAM data'
        />
      }

      {is_show_logout &&
        <AlertModal
          on_hide={() => set_is_show_logout(false)}
          title={'Logout required'}
        >
          Please logout and log in again before changing your password.
        </AlertModal>
      }

      {!is_fetching_wam_data && !error_fetching_wam_data &&
        <div>
          {is_wam_user &&
            <div className='mb-3'>
              {/* WAM user - but no token, so disable link */}
              {!wam_idp_data &&
                <TextLink element='div' disable={true}>Change password</TextLink>
              }

              {/* WAM user - but token expired, so on click show message that user needs to log in aagain. */}
              {(wam_idp_data && is_expired) &&
                <TextLink element='div' onClick={() => set_is_show_logout(true)}>Change password</TextLink>
              }

              {/* 
                  WAM user - with valid token, so show form.
                  On click this redirects to the /oidc/sso endpoint to get a cookie, and then redirects to the "change password" page.
                  We need to open this in a new tab, as there is no "back" functionality in the change password page.
                  See https://confluence.lexisnexis.dev/display/GLOBAL/WAM+Post+OIDC+SSO+Process+for+Legacy+SSO
              */}
              {(wam_idp_data && !is_expired) &&
                <form
                  action='https://signin.lexisnexis.com/lnaccess/oidc/redirectsso'
                  method='POST'
                  target='_blank'
                >
                  <input type='hidden' name='id_token' value={wam_idp_data.id_token} />
                  <input type='hidden' name='RelayState' value='aci=ciph' />
                  <input type='hidden' name='redirectUrl' value={wam_change_password_url} />
                  <button type='submit' className={cn(s.change_password_link)}>Change password</button>
                </form>
              }
            </div>
          }

          {(!is_sso_user(user) && !is_wam_user) &&
            <div className='mb-3'>
              {/* Keycloak user - so show the Keycloak change password page */}
              <TextLink element='a' href={reset_password_url}>Change password</TextLink>
            </div>
          }

          <UserSettingsDisplay
            user_settings={user_settings}
            user_chart_sets={user_chart_sets}
            default_chart_selection={get_default_chart_selection(user_settings)}
            change_default_chart_selection_handler={change_default_chart_selection_handler}
            change_default_utt_version_handler={change_default_utt_version_handler}
            change_starting_page_handler={change_starting_page_handler}
            change_region_grouping_handler={change_region_grouping_handler}
            change_thumbnails_handler={change_thumbnails_handler}
            change_should_show_ungrouped_families_handler={change_should_show_ungrouped_families_handler}
            change_should_include_unrelated_techs_handler={change_should_include_unrelated_techs_handler}
            toggle_notify_before_report_expiry_handler={toggle_notify_before_report_expiry_handler}
            change_language_preference_handler={change_language_preference_handler}
            change_save_knn_searches_handler={change_save_knn_searches_handler}
            reset_settings_handler={reset_settings_handler}
          />

        </div>
      }

    </Modal>
  )
}

export default withUser(UserSettingsModal)