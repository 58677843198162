import React from 'react'
import { DropdownItem } from 'reactstrap'

import { CIPHER_FAMILY_ID_FIELD_ID } from '../../model/patent_family_fields.js'
import CopyToClipboard from '../widgets/CopyToClipboard.js'

import cs from '../cipher_styles.module.scss'

export const CopyToClipboardAction = ({results}) => {
  const text_to_copy = (results || []).map(item => item[CIPHER_FAMILY_ID_FIELD_ID]).join('\n')

  const label = 'Copy family IDs to clipboard'

  return (
    <DropdownItem toggle={false}>
      <CopyToClipboard
        label={label}
        title={label}
        text_to_copy={text_to_copy}
        disabled={(results || []).length === 0}

        trigger_element='div'

        className={cs.no_outline}
      />
    </DropdownItem>
  )
}
