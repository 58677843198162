import React from 'react'
import cn from 'classnames'

import s from './FamilyDetailsTile.module.scss'

export const FamilyDetailsTile = ({children, className}) => {
  return (
    <div className={cn([s.block, className])}>
      {children}
    </div>
  )
}