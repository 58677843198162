import React from 'react'
import cn from 'classnames'

import { CrossIcon, TickIcon, TestIcon } from '../../widgets/IconSet.js'

import s from './TestSetIcons.module.scss'

export const TestPositiveIcon = ({ className, title, onClick }) => {
  return (
    <div
      title={title}
      onClick={onClick}
      className={cn(s.block, className)}
    >
      <TestIcon
        className={cn(s.t_icon)}
      />
      <TickIcon
        className={cn(s.tick_icon)}
      />
    </div>
  )
}

export const TestNegativeIcon = ({ className, title, onClick }) => {
  return (
    <div
      title={title}
      onClick={onClick}
      className={cn(s.block, className)}
    >
      <TestIcon
        className={cn(s.t_icon)}
      />
      <CrossIcon
        className={cn(s.cross_icon)}
      />
    </div>
  )
}