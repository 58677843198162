import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import ForwardBackSelector from '../widgets/ForwardBackSelector.js'
import {
  ExternalLinkIcon,
  RotateLeftIcon,
  RotateRightIcon,
  TransformResetIcon,
  ZoomInIcon,
  ZoomOutIcon
} from '../widgets/IconSet.js'

import s from './ImageControls.module.scss'

export const ImageTransformControls = ({className, on_flip_left, on_flip_right, on_zoom_out, on_zoom_in, on_reset, image_meta}) => {
  const { magnify=1 } = image_meta
  
  return (
    <div className={cn('d-flex justify-content-end', className)}>
      <TextLink title='Rotate left' className={cn('mr-2', s.control)} onClick={on_flip_left}><RotateLeftIcon/></TextLink>
      <TextLink title='Rotate right' className={cn('mr-3', s.control)} onClick={on_flip_right}><RotateRightIcon/></TextLink>
      <TextLink title='Zoom out' disable={magnify === 1} className={cn('mr-2 ml-1', s.control)} onClick={on_zoom_out}><ZoomOutIcon/></TextLink>
      <TextLink title='Zoom in' disable={magnify === 20} className={cn('mr-2', s.control)} onClick={on_zoom_in}><ZoomInIcon/></TextLink>
      <TextLink title='Reset' className={cn('pl-2', s.control, s.control__separator)} onClick={on_reset}><TransformResetIcon/></TextLink>
    </div>
  )
}

export const ImageExportControls = ({className, selected_image_url, show_link}) => {
  
  return (
    <div className={cn(className)}>
      {show_link &&
        <TextLink
          className={s.control}
          element={'a'}
          target='_blank'
          href={selected_image_url}
        >
          <ExternalLinkIcon/>
        </TextLink>
      }
    </div>
  )
}

export const ImageBrowseControls = ({className, current_idx, max_idx, on_change_handler}) => {
  return (
    <div className={cn('d-flex justify-content-center', className)}>
      <ForwardBackSelector
        current_idx={current_idx}
        min_idx_incl={0}
        max_idx_excl={max_idx}
        on_prev={on_change_handler.bind(null, current_idx - 1)}
        on_next={on_change_handler.bind(null, current_idx + 1)}
        label='Image'
      />
    </div>
  )
}