import React from 'react'

import { RadiobuttonWithLabel } from '../../widgets/RadiobuttonWithLabel.js'
import TechnologiesDisplay from './TechnologiesDisplay.js'
import {
  get_technology_partitioning_by_classifiers,
  get_technology_partitioning_by_clustering,
  get_technology_partitioning_by_utt,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../../../model/technology_basket.js'

const TechnologyPartitioning = (
  {
    is_fetching,
    classifier_groups,

    selected_stream_id,
    user_has_utt,
    user_has_classifiers,
    selected_technology_split,
    selected_classifier_group_id,
    selected_classifiers,
    utt_version,
    is_report_big_for_clustering,

    on_select_classifier_group,
    on_select_classifiers,
    on_technology_split_select_handler,

    className
  }) => {

  const {type: selected_type, use_superclasses} = selected_technology_split || {}

  return (
    <div className={className}>
      <div>
        {user_has_classifiers &&
          <RadiobuttonWithLabel
            is_checked={selected_type === TECH_PARTITIONING_TYPE_CLASSIFIER}
            label='Classifiers'
            on_click={() => on_technology_split_select_handler(get_technology_partitioning_by_classifiers())}
          />
        }

        {user_has_utt &&
          <>
            <RadiobuttonWithLabel
              is_checked={selected_type === TECH_PARTITIONING_TYPE_UTT && !use_superclasses}
              label='UTT'
              on_click={() => on_technology_split_select_handler(get_technology_partitioning_by_utt({utt_version}))}
            />

            <RadiobuttonWithLabel
              is_checked={selected_type === TECH_PARTITIONING_TYPE_UTT && use_superclasses}
              label='UTT superclasses'
              on_click={() => on_technology_split_select_handler(get_technology_partitioning_by_utt({use_utt_superclasses: true, utt_version}))}
            />
          </>
        }

        <RadiobuttonWithLabel
          is_checked={selected_type === TECH_PARTITIONING_TYPE_CLUSTERING}
          label={is_report_big_for_clustering ? 'No classification': 'Clustering'}
          on_click={() => on_technology_split_select_handler(get_technology_partitioning_by_clustering())}
        />

      </div>

      {selected_type === TECH_PARTITIONING_TYPE_CLASSIFIER &&
        <TechnologiesDisplay
          is_fetching={is_fetching}
          classifier_groups={classifier_groups}
          selected_stream_id={selected_stream_id}
          selected_classifier_group_id={selected_classifier_group_id}
          selected_classifiers={selected_classifiers}
          on_select_classifier_group={on_select_classifier_group}
          on_select_classifiers={on_select_classifiers}
        />
      }
    </div>
  )
}

export default TechnologyPartitioning