import React from 'react'
import cn from 'classnames'

import s from './Badge.module.scss'

export const Badge = ({on_click, className, outline, children}) => {
  const is_clickable = (on_click != null)
  return (
    <div className={cn('badge badge-pill', s.badge, {[s.badge__outline]: outline}, {[s.badge__clickable]: is_clickable}, className)} onClick={on_click}>{children}</div>
  )
}