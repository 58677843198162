import React from 'react'
import cn from 'classnames'

import s from './AdminPageWrapper.module.scss'

const AdminPageWrapper = ({children, className}) => {
  return (
    <div className={cn(s.block, className)} >
      {children}
    </div>
  )
}

export default AdminPageWrapper