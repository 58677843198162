import React, { useState } from 'react'
import cn from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import { get_long_date_string } from '../../utils/time_utils.js'
import FamiliesCountDisplay from '../report_info/FamiliesCountDisplay.js'
import { HeaderAction } from './HeaderAction.js'
import { EditIcon, ExternalLinkIconInline, InfoIcon } from '../widgets/IconSet.js'
import Tooltip from '../widgets/Tooltip.js'
import { Badge } from '../widgets/Badge.js'
import IncompleteReportWarning from '../report_info/IncompleteReportWarning.js'
import { toggle_scope_modal } from '../classifiers_editor/utils/scope_modal_utils.js'
import TextLink from '../widgets/TextLink.js'
import ReportDetailsModal from '../report_info/ReportDetailsModal.js'

import cs from '../cipher_styles.module.scss'
import s from './ViewerHeaderReportName.module.scss'

const ViewerHeaderReportName = (
  {
    history,

    internal_report_id,
    external_report_id,
    report_input,
    report_title,
    created_at,
    is_saved,
    is_incomplete,
    created_by_user,
    families_count,

    is_eval_report,
    eval_classifier_title,
    eval_classifier_path_to_ui,

    rename_report_handler,
    user_state,
    user_settings,

    className
  }) => {
  const [show_details_modal, set_show_details_modal] = useState(false)

  return (
    <div className={className}>
      {created_at &&
        <div className={cn('d-flex', cs.font_size_small, s.created_at)}>
          {is_saved &&
            <span className='mr-1' >[saved]</span>
          }
          <span>{get_long_date_string(created_at)}</span>
          {families_count &&
            <FamiliesCountDisplay
              families_count={families_count}
              user_state={user_state}
              user_settings={user_settings}
              className='ml-2'
            />
          }

          <HeaderAction
            title='Report details'
            on_click={() => set_show_details_modal(true)}
            className={cn('ml-2', s.title_icon, cs.font_size_small)}
          >
            <InfoIcon />
          </HeaderAction>

          {is_incomplete &&
            <Tooltip
              toggler={(<span><Badge className='my-auto ml-2'>!</Badge></span>)}
              placement={'right'}
              is_in_modal={true}
            >
              <IncompleteReportWarning />
            </Tooltip>
          }

        </div>
      }

      {is_eval_report &&
        <span title={`${eval_classifier_title ? `${eval_classifier_title} | ` : ''}${report_title}`}>
          <span className='mr-1'>
            EVALUATE CLASSIFIER:
          </span>
          {eval_classifier_title &&
            <span>
              <ExternalLinkIconInline
                className={cn('align-middle ml-1 mr-1', s.external_link_icon)}
                onClick={() => toggle_scope_modal(history)}
                title='Open Scope modal'
              />
            </span>
          }
          {eval_classifier_title &&
            <TextLink
              element={Link}
              to={eval_classifier_path_to_ui}
              className={cn(s.classifier_name_link)}
              title={`Open classifier "${eval_classifier_title}"`}
            >
              <span>{eval_classifier_title}</span>
            </TextLink>
          }
          <span className='ml-1'>
            {report_title}
          </span>
        </span>
      }

      {!is_eval_report &&
        <span title={report_title}>{report_title}</span>
      }

      {created_by_user &&
        <HeaderAction title='Rename' on_click={rename_report_handler} className={cn('px-2', cs.font_size_small, s.title_icon, s.edit_title_link)}><EditIcon /></HeaderAction>
      }

      <ReportDetailsModal
        is_open={show_details_modal}
        on_close={() => set_show_details_modal(false)}
        report_input={report_input}

        external_report_id={external_report_id}
        internal_report_id={internal_report_id}
        report_title={report_title}
        created_at={created_at}
        is_eval_report={is_eval_report}
      />

    </div>
  )
}

export default withRouter(ViewerHeaderReportName)