import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { is_sets_equal } from '../../utils/utils.js'
import { track_list_event } from '../../utils/tracking_utils.js'

import TextLink from '../widgets/TextLink.js'
import ScrollableList from '../widgets/ScrollableList.js'
import { GROUPS } from '../../model/patent_family_fields.js'
import {
  get_fields_by_group,
  get_fields_ungrouped,
} from '../../utils/patent_family_list_utils.js'
import { CONTEXT_REPORT } from '../../constants/context.js'
import { InfoIcon } from '../widgets/IconSet.js'
import { get_family_list_fields_help_url, on_click_from_help_link } from '../../utils/help_utils.js'
import DropdownChevron from '../widgets/DropdownChevron.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

import cs from '../cipher_styles.module.scss'
import s from './PatentFamilyFieldsControl.module.scss'

function get_button_label(is_all_selected, is_none_selected, is_default_fields_selected, selected_field_ids) {
  const length_clause = `(${selected_field_ids.length})`

  if (is_all_selected) {
    return 'all ' + length_clause
  }
  if (is_none_selected) {
    return 'NONE'
  }
  if (is_default_fields_selected) {
    return 'default'
  }

  return 'custom ' + length_clause
}


const PatentFamilyFieldsGroup = ({group, all_fields, all_field_ids, selected_field_ids, on_change_selected_field_ids, context}) => {
  const fields_filtered_by_group = group != null ? get_fields_by_group(all_fields, group) : get_fields_ungrouped(all_fields)
  if (!fields_filtered_by_group || fields_filtered_by_group.length === 0) {
    return null
  }

  return (
    <div className={cn('mt-2 mb-2', s.group_block)}>
      {fields_filtered_by_group.map((field, i) => {
        const { name, id } = field
        const is_checked = _.contains(selected_field_ids, id)

        return (
          <CheckboxAndLabel
            className={cn('flex-grow-1', cs.font_size_small, s.label)}
            key={i}
            is_checked={is_checked}
            on_click={() => {
              track_list_event(`action="change_columns" column="${id}" state="${!is_checked ? 'show' : 'hide'}" context="${context}"`)

              // maintain same order as ref data (just in case we want to render it somewhere else)
              const new_selected_field_ids = is_checked ?
                all_field_ids.filter(new_id => _.contains(selected_field_ids, new_id) && new_id !== id) // remove
                : all_field_ids.filter(new_id => _.contains(selected_field_ids, new_id) || new_id === id) // add

              on_change_selected_field_ids(new_selected_field_ids)
            }}
            label={name}
          />
        )
      })}
    </div>
)}

const PatentFamilyFieldsControl = ({
  className,
  context,
  all_fields,
  selected_field_ids,
  default_selected_field_ids,
  on_change_selected_field_ids,
}) => {
  const is_all_selected            = (selected_field_ids.length === all_fields.length)
  const is_none_selected           = (selected_field_ids.length === 0)
  const is_default_fields_selected = is_sets_equal(new Set(selected_field_ids), new Set(default_selected_field_ids))

  const all_field_ids = all_fields.map(field => field.id)

  const button_label = get_button_label(is_all_selected, is_none_selected, is_default_fields_selected, selected_field_ids)

  const needs_multi_columns = (context === CONTEXT_REPORT) && ((all_fields || []).length > 20)

  const help_link = get_family_list_fields_help_url()

  return (
    <div className={cn('d-flex align-items-center', className)}>
      <span>Columns</span>
      <div>
        <InfoPopover
          interactive={true}
          arrow={false}
          placement='bottom-start'
          toggler={(<span className={cn('d-flex px-2 py-1 ml-1', s.button_label)} title={button_label}>{button_label}<DropdownChevron className='ml-1' /></span>)}
          className={cn([s.menu, {[s.menu__multi_columns]: needs_multi_columns}])}
        >
          <div className='d-flex px-2 mb-1'>
            <div className={cn('mr-2', s.select_option, s.select_option_label)}>Select:</div>

            <TextLink
              className={cn('mr-2 my-auto', s.select_option)}
              onClick={() => {
                track_list_event(`action="change_columns" option="all" context="${context}"`)
                on_change_selected_field_ids(all_field_ids)
              }}
              disable={is_all_selected}
            >
              all
            </TextLink>

            <TextLink
              className={cn('mr-2 my-auto', s.select_option)}
              disable={is_default_fields_selected}
              onClick={() => {
                track_list_event(`action="change_columns" option="default" context="${context}"`)
                on_change_selected_field_ids(default_selected_field_ids)
              }}
            >
              default
            </TextLink>

            <TextLink
              className={cn('mr-2 my-auto', s.select_option)}
              onClick={() => {
                track_list_event(`action="change_columns" option="none" context="${context}"`)
                on_change_selected_field_ids([])
              }}
              disable={is_none_selected}
            >
              none
            </TextLink>
          </div>

          <ScrollableList className={cn([ {'d-flex flex-no-wrap flex-sm-wrap flex-column': needs_multi_columns}, {[s.list__multi_columns]: needs_multi_columns}])}>
            {GROUPS.map((group, i) => (
              <PatentFamilyFieldsGroup
                key={i}
                group={group}
                all_fields={all_fields}
                all_field_ids={all_field_ids}
                selected_field_ids={selected_field_ids}
                on_change_selected_field_ids={on_change_selected_field_ids}
                context={context}
              />
            ))}

            <PatentFamilyFieldsGroup
              group={null}
              all_fields={all_fields}
              all_field_ids={all_field_ids}
              selected_field_ids={selected_field_ids}
              on_change_selected_field_ids={on_change_selected_field_ids}
              context={context}
            />
          </ScrollableList>
        </InfoPopover>
      </div>
      <TextLink className='ml-1' element='a' target='_blank' href={help_link} onClick={() => on_click_from_help_link(help_link)}><InfoIcon /></TextLink>
    </div>
  )
}

export default PatentFamilyFieldsControl