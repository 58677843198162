import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { CPC_STRATEGIES } from '../model/suggestions_strategies.js'
import Tooltip from '../../widgets/Tooltip.js'

import s from './SuggestionsStrategyDetails.module.scss'

const SuggestionsStrategyDetails = ({ details, strategy_id, className }) => {
  if (!details) {
    return null
  }

  return (
    <Tooltip
      toggler={(
        <span
          className={cn(s.label, className)}
        >
          details
        </span>
      )}
      delay={[100, 50]}
      placement='top'
      is_in_modal={true}
    >
      {_.contains(CPC_STRATEGIES, strategy_id) &&
        <div>
          <p>CPC set</p>
          <ul>
            {details.map((cpc, i) => (
              <li key={i}>{cpc}</li>
            ))}
          </ul>
        </div>
      }

      {!(_.contains(CPC_STRATEGIES, strategy_id)) &&
        <pre>
          <code>
            {JSON.stringify(details, null, 4)}
          </code>
        </pre>
      }

    </Tooltip>
  )
}

export default SuggestionsStrategyDetails