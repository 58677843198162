import React, { useEffect, useState } from 'react'
import _ from 'underscore'

import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import { get_org_id, get_organisation_changelog_by_id } from '../../utils/organisation_utils.js'
import ChangeLogDisplay from './ChangeLogDisplay.js'
import { get_user_info_by_id } from '../../utils/user_info.js'
import ErrorBody from '../ErrorBody.js'
import Tooltip from '../widgets/Tooltip.js'
import { ChangesIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'

import s from './OrgChangeLog.module.scss'

const MODAL_MODE = 'modal_mode'
const TOOLTIP_MODE = 'tooltip_mode'

const OrgChangeLog = ({org}) => {
  const {name: org_name} = org || {}

  const org_id = get_org_id(org)

  const [show_spinner, set_show_spinner] = useState(true)
  const [should_fetch, set_should_fetch] = useState(false)
  const [change_log, set_change_log] = useState(null)
  const [users, set_users] = useState(null)

  const [display_mode, set_display_mode] = useState(TOOLTIP_MODE)

  const [fetch_error, set_fetch_error] = useState(null)

  useEffect(() => {
    if (!should_fetch) return
    set_show_spinner(true)
    get_organisation_changelog_by_id(org_id)
      .then(response => {
        set_change_log(response)

        if (!response || response.length === 0) return []

        const user_ids = response.map(item => (item.user_id))
        return get_user_info_by_id(_.uniq(user_ids))
      })
      .then(user_response => {
        set_users(user_response)
        set_should_fetch(false)
        set_show_spinner(false)
      })
      .catch(e => {
        set_show_spinner(false)
        set_should_fetch(false)
        set_fetch_error(e)
      })

  }, [org_id, should_fetch])


  function get_title() {
    return `Changes of ${org_name}`
  }

  function close() {
    set_fetch_error(null)
    set_should_fetch(false)
    set_display_mode(TOOLTIP_MODE)
  }

  function get_spinner_display(show_text) {
    return (
      <div className='text-center'>
        <Spinner />
        {show_text && <p>Fetching results ...</p>}
      </div>
    )
  }

  function get_error_display() {
    return (<ErrorBody error={fetch_error} context='fetching organisation change log' />)
  }

  function get_no_changes_display() {
    return (<div className='text-center'>no changes to show yet</div>)
  }

  const has_change_log = change_log && change_log.length !== 0

  if (display_mode === TOOLTIP_MODE) {
    return (
      <Tooltip
        toggler={<span><TextLink onClick={() => set_display_mode(MODAL_MODE)}><ChangesIcon/></TextLink></span>}
        on_show={() => set_should_fetch(true)}
        on_hide={close}
        placement={'bottom'}
        interactive
      >
        <div className={s.pop_block}>
          {show_spinner && get_spinner_display(false)}
          {fetch_error && get_error_display()}
          {!show_spinner && !fetch_error && !has_change_log && get_no_changes_display()}

          {!show_spinner && !fetch_error && has_change_log &&
            <ChangeLogDisplay
              change_log={change_log}
              users={users}
              recent_only={true}
            />
          }
        </div>
      </Tooltip>
    )
  }

  return (
    <Modal is_open={true} on_hide={close} title={get_title()}>
      {show_spinner && get_spinner_display(true)}
      {fetch_error && get_error_display()}
      {!show_spinner && !fetch_error && !has_change_log && get_no_changes_display()}

      {!show_spinner && !fetch_error && has_change_log &&
        <ChangeLogDisplay
          change_log={change_log}
          users={users}
          show_user={true}
          recent_only={false}
        />
      }
    </Modal>
  )
}

export default OrgChangeLog