import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'
import { get_clean_filename, trigger_download} from '../../utils/download_utils.js'
import ErrorModal from '../ErrorModal.js'
import { track_download_event } from '../../utils/tracking_utils.js'
import { build_params, arraybuffer_to_string } from '../../utils/hyperscripts_utils.js'
import {
  ALERT_SETUP_ID,
  BENCHMARKING_REPORT_ID,
  GOOGLE_VALUATION_ID,
  TREND_SPOTTER_ID
} from '../../model/hyperscripts.js'
import { BENCHMARKING } from '../../constants/paths.js'
import TextLink from '../widgets/TextLink.js'
import HyperscriptDescription from './HyperscriptDescription.js'
import HyperscriptSpinner from './HyperscriptSpinner.js'
import { set_favicon } from '../../utils/viewer_utils.js'
import { withUser } from '../UserContext.js'
import { is_families_based } from '../../utils/alert_report_utils.js'

import s from './Hyperscript.module.scss'

const Hyperscript = (
  { user,
    hyperscript,
    internal_report_id,
    external_report_id,
    selections={},
    report_title,
    deref_data,
    benchmarking_merged_inputs,
    benchmarking_params,
    report_input,
    report_has_scores,
    data_creation_date,
    params_id
  }) => {
  const [fetch, set_fetch] = useState(false)
  const [hs_data, set_hs_data] = useState(null)
  const [params, set_params] = useState(build_params(hyperscript))
  const [should_trigger_download, set_should_trigger_download] = useState(false)

  const [fetch_hyperscript_export_error, set_fetch_hyperscript_export_error] = useState(null)
  const [fetch_hyperscript_export_400_error, set_fetch_hyperscript_export_400_error] = useState(null)

  const { id, setup_params, setup_params_component: Setup, is_start_enabled } = hyperscript

  const is_trend_spotter = (id === TREND_SPOTTER_ID)
  const is_benchmarking_report = (id === BENCHMARKING_REPORT_ID)
  const is_valuation_export = (id === GOOGLE_VALUATION_ID)
  const is_alert_setup = (id === ALERT_SETUP_ID)
  const is_report_families_based = report_input && is_families_based(report_input)

  useEffect(() => {
    if (fetch) return
    clean_up()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections])

  function clean_up() {
    set_hs_data(null)
    set_params(build_params(hyperscript))

    set_fetch_hyperscript_export_error(null)
    set_fetch_hyperscript_export_400_error(null)
    set_should_trigger_download(false)
  }

  useEffect(() => {
    if (!hs_data || is_trend_spotter || is_benchmarking_report || is_valuation_export || is_alert_setup) return
    set_should_trigger_download(true)
  }, [hs_data, is_trend_spotter, is_benchmarking_report, is_valuation_export, is_alert_setup])

  useEffect(() => {
    if (hs_data && should_trigger_download) {
      const { id, filename_suffix, get_filename_suffix, file_format } = hyperscript || {}
      const suffix = get_filename_suffix ? get_filename_suffix({user, params}) : filename_suffix
      const filename = get_clean_filename(`${report_title}_${suffix}${file_format.ext}`)
      track_download_event(`obj="hyperscript" name="${id} download"`)
      trigger_download(hs_data, file_format.mime, filename)
      set_should_trigger_download(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [should_trigger_download, hyperscript, report_title])

  useEffect(() => {
    set_favicon(fetch)

    if (!fetch) return

    let did_cancel = false
    const { endpoint } = hyperscript
    endpoint({user, internal_report_id, external_report_id, selections, setup_params: params})
      .then(arraybuffer => {
        if (!did_cancel) {
          if (is_alert_setup) {
            const {alert_id} = arraybuffer
            set_params({...params, alert_id})
          }
          set_hs_data(arraybuffer)
          set_fetch(false)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_fetch(false)
          if (error.status === 400) {
            const decoded_error_response = String.fromCharCode.apply(null, new Uint8Array(error.response.data))
            set_fetch_hyperscript_export_400_error(JSON.parse(decoded_error_response))
            return // i.e. 400 errors should not be logged in sentry.
          }
          set_fetch_hyperscript_export_error(error)
          throw error
        }
      })

    return () => {
      did_cancel = true
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch])

  function on_error_alert_hide() {
    set_hs_data(null)
    set_fetch_hyperscript_export_error(null)
    set_fetch_hyperscript_export_400_error(null)
  }

  function update_params(params) {
    set_hs_data(null)
    set_params(params)
  }

  if (fetch_hyperscript_export_error) {
    return(
      <ErrorModal
        on_hide={() => {on_error_alert_hide()}}
        error={fetch_hyperscript_export_error}
        context={`fetching hyperscript export - ${fetch_hyperscript_export_error.message}`}
      />
    )
  }

  if (fetch_hyperscript_export_400_error) {
    return (
      <Modal
        title={fetch_hyperscript_export_400_error.title || 'Failed to run hyperscript report'}
        on_hide={() => {on_error_alert_hide()}}
      >
        <div>{fetch_hyperscript_export_400_error.detail || 'We cannot generate an export'}</div>
      </Modal>
    )
  }

  return (
    <div>
      <HyperscriptDescription
        hyperscript={hyperscript}
        report_input={report_input}
        params={params}
        className='mt-2 mb-2'
      />

      {setup_params &&
        <div className={cn('mb-3', 'w-100', s.params_block)}>
          <Setup
            setup_params={setup_params || []}
            deref_data={deref_data}
            current_param_values={params || {}}
            user={user}
            on_change={update_params}
            disabled={fetch}

            benchmarking_merged_inputs={benchmarking_merged_inputs}
            benchmarking_params={benchmarking_params}

            report_input={report_input}
            report_has_scores={report_has_scores}
            report_title={report_title}
            internal_report_id={internal_report_id}
            data_creation_date={data_creation_date}
            params_id={params_id}
            selections={selections}
          />
        </div>
      }
      {!fetch && !hs_data && !is_alert_setup &&
        <div>
          <PrimaryButton
            disabled={is_start_enabled ? !is_start_enabled({params: params || {}, deref_data, benchmarking_params, is_report_families_based}) : false}
            className='mt-3 mt-md-0'
            onClick={() => {set_fetch(true)}}
          >
            Start
          </PrimaryButton>
        </div>
      }

      {fetch &&
        <HyperscriptSpinner
          hyperscript={hyperscript}
        />
      }

      {!fetch && hs_data &&
        <div>
          {is_trend_spotter &&
            'Your trends analysis task has been started. The link to the results will be emailed to you soon.'
          }

          {is_benchmarking_report &&
            <>
              The <TextLink element={Link} to={`${BENCHMARKING}/${external_report_id}`}>benchmarking deck</TextLink> for this report is ready to view.
            </>
          }

          {is_valuation_export &&
            arraybuffer_to_string(hs_data)
          }

          {!is_trend_spotter && !is_benchmarking_report && !is_valuation_export && !is_alert_setup &&
            <div className='d-flex'>Your report export is ready to download.&nbsp;
              <TextLink onClick={() => set_should_trigger_download(true)}>Download again</TextLink>&nbsp;or select another export.
            </div>
          }
        </div>
      }
    </div>
  )
}

export default withUser(Hyperscript)