import React, { useEffect, useState, useRef } from 'react'
import cn from 'classnames'

import DeckPageView from './DeckPageView.js'
import { get_deck_spec, get_relevant_selections_from_root_chart } from '../../../utils/report_deck_utils.js'

import s from './DeckSlide.module.scss'

const DeckSlide = (
  {
    page,
    chart_selections,
    ...page_props
  }) => {
  const deck_ref = React.createRef()
  const { charts } = page || {}
  const [chart_l, chart_r] = charts || []

  const [dimensions, set_dimensions] = useState({})

  const movement_timer = useRef()

  const update_wrapper_dimensions = () => {
    if (deck_ref.current) {
      set_dimensions({
        width: deck_ref.current.offsetWidth,
        height: deck_ref.current.offsetHeight
      })
    }
  }

  useEffect(() => {
    const update_wrapper_dimensions_with_delay = () => {
      clearInterval(movement_timer.current)
      movement_timer.current = setTimeout(update_wrapper_dimensions, 100)
    }

    window.addEventListener('resize', update_wrapper_dimensions_with_delay)
    return () => {
      document.removeEventListener('resize', update_wrapper_dimensions_with_delay)
    }
  })

  useEffect(() => {
    update_wrapper_dimensions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function get_chart_selection(chart) {
    const {spec_id} = chart
    const {root_chart_spec_id} = get_deck_spec(spec_id)
    const root_chart_selections = get_relevant_selections_from_root_chart(chart_selections[root_chart_spec_id])

    return {
      ...root_chart_selections,
      ...chart_selections[spec_id]
    }
  }

  //if helper classes are changed regarding breakpoints, it's likely the function calculation chart height in DeckPageView needs to be updated as well
  return (
    <div className='d-lg-flex w-100' ref={deck_ref}>
      {chart_l &&
      <div className={cn('pr-lg-2 pb-3 pb-lg-0', s.page_block)}>
        <DeckPageView
          chart={chart_l}
          chart_selection={get_chart_selection(chart_l)}
          parent_dimensions={dimensions}
          {...page_props}
        />
      </div>
      }

      {chart_r &&
      <div className={cn('pl-lg-2 pb-3 pb-lg-0', s.page_block)}>
        <DeckPageView
          chart={chart_r}
          chart_selection={get_chart_selection(chart_r)}
          parent_dimensions={dimensions}
          {...page_props}
        />
      </div>
      }
    </div>
  )

}

export default DeckSlide