import React from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'

import RadioStatic from '../widgets/RadioStatic.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import ScrollableList from '../widgets/ScrollableList.js'

import s from './SortingControl.module.scss'


// TODO: extract general Radio button control (re-use in SortingControl, Timerange control etc...)

const SortingControl = ({
  className,
  sort_fields,
  id_to_sort_field,
  selected_sort_field_id,
  on_change_sort_field_id,

  sort_directions,
  id_to_sort_direction,
  selected_sort_direction_id,
  on_change_sort_direction_id,

  always_visible_when_open,
  button_class_name
}) => {
  const selected_sort_field = id_to_sort_field[selected_sort_field_id]

  const selected_sort_direction = id_to_sort_direction[selected_sort_direction_id]
  
  const sort_field_name = selected_sort_field.name
  const sort_direction_name = selected_sort_direction.name

  const summary = `${sort_field_name} (${sort_direction_name})`

  return (
    <span className={cn('d-flex', 'align-items-center', className)}>
      <div>
        Sort by
      </div>

      <BaseDropdown
        className={cn('ml-1')}
        label={summary}
        labelClassName={s.button_label}
        right={false}
        buttonClassName={cn(s.button, button_class_name)}
        menuClassName={s.menu}

        always_visible_when_open={always_visible_when_open != null ? always_visible_when_open : true}
      >
        <DropdownItem header>
          Direction
        </DropdownItem>
        {sort_directions.map((sort_direction, idx) => {
            const { name, id } = sort_direction
            const is_selected = (id === selected_sort_direction_id)

            return (
              <DropdownItem
                className={cn('d-flex', 'align-items-start', s.row_container)}
                key={idx}
                toggle={false} // on click, keep the dropdown open
                tag={'div'}
              >
                <RadioStatic
                  is_checked={is_selected}
                  onClick={on_change_sort_direction_id.bind(null, id)}
                />
                <span
                  className={cn(s.label, 'ml-1')}
                  onClick={on_change_sort_direction_id.bind(null, id)}
                >
                  {name}
                </span>
              </DropdownItem>
            )
          })}

        <DropdownItem divider/>
  
        <DropdownItem header>
          Field
        </DropdownItem>

        <ScrollableList>
          {sort_fields.map((sort_field, idx) => {
            const { name, id } = sort_field
            const is_selected = (id === selected_sort_field_id)

            return (
              <DropdownItem
                className={cn('d-flex', 'align-items-center', s.row_container)}
                key={idx}
                toggle={false} // on click, keep the dropdown open
                tag={'div'}
              >
                <RadioStatic
                  is_checked={is_selected}
                  onClick={on_change_sort_field_id.bind(null, id)}
                />
                <span
                  className={cn(s.label, 'ml-1')}
                  onClick={on_change_sort_field_id.bind(null, id)}
                >
                  {name}
                </span>
              </DropdownItem>
            )
          })}
        </ScrollableList>
      </BaseDropdown>

    </span>
  )
}

export default SortingControl