import React from 'react'
import cn from 'classnames'

import s from './Input.module.scss'

export const ReadOnlyInput = ({value, on_focus, inner_ref, className}) => {
  return (
    <Input
      value={value || ''}
      read_only={true}
      on_focus={on_focus}
      inner_ref={inner_ref}
      className={className}
    />
  )
}

export const Input = ({value, read_only, on_focus, inner_ref, className}) => {
  return (
    <input value={value} readOnly={read_only} className={cn(s.input, className)} ref={inner_ref} onFocus={on_focus}/>
  )
}