import React from 'react'
import cn from 'classnames'
import CheckboxStatic from './CheckboxStatic.js'

import s from './CheckboxAndLabel.module.scss'

export const CheckboxAndLabel = ({is_checked, label, post_label, on_click, is_disabled, checkboxClassName, className}) => {
  return (
    <div className={cn('d-flex', className)}>
      <CheckboxStatic
        is_checked={is_checked}
        onClick={on_click}
        className={cn('mr-1', checkboxClassName)}
        is_disabled={is_disabled}
      />
      {' '}
      <p
        className={cn(s.label_container, { [s.label_container__disabled]: is_disabled })}
      >
        <span onClick={!is_disabled ? on_click : null} className={is_disabled ? s.__disabled : ''}>
          {label}
        </span>
        {post_label}
      </p>
    </div>
  )
}