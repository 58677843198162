import { CIPHER_BLUE } from '../../../constants/colours'

export const FONT_WEIGHT = 'normal',
  FONT_SIZE   = '12px',
  TEXT_FONT_FAMILY = 'Source Sans Pro, Calibri, sans-serif',
  SPECIAL_CHAR_FONT_FAMILY = 'Arial, OpenSymbol',
  LINE_HEIGHT = '1.1em',
  FILL = CIPHER_BLUE

export const TEXT_BASE_STYLE_REACT = {
  fontWeight: FONT_WEIGHT,
  fontSize: FONT_SIZE,
  fontFamily: TEXT_FONT_FAMILY,
  lineHeight: LINE_HEIGHT,
  fill: FILL
}

export const TEXT_SPECIAL_CHAR_STYLE_REACT = {
    fontWeight: FONT_WEIGHT,
    fontSize: FONT_SIZE,
    fontFamily: SPECIAL_CHAR_FONT_FAMILY + ',' +  TEXT_FONT_FAMILY ,
    lineHeight: LINE_HEIGHT,
    fill: FILL
}

