import React, { useRef, useState } from 'react'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import SearchBar from '../widgets/SearchBar.js'
import { contains } from '../../utils/utils.js'

import s from './TileDropdown.module.scss'

export const TileDropdown = ({selected_value, label, items, on_change_handler, max_height, disabled, enable_search}) => {
  const [search_phrase, set_search_phrase] = useState(null)

  const search_input_ref = useRef()

  function get_modifiers() {
    // enables us to limit the max height of a long dropdown (useful in a tile or modal)
    // any overflow will be scrollable
    if (max_height) {
      return {
        setMaxHeight: {
          enabled: true,
          order: 890,
          fn: (data) => {
            return {...data, styles: {...data.styles, overflow: 'auto', maxHeight: max_height}
            };
          },
        }
      }
    }
    return {}
  }

  function get_items_to_display() {
    if (!enable_search || !search_phrase) return items

    const search_phrase_clean = search_phrase.toLowerCase()

    return items.filter(item => {
      const {name} = item
      return contains(name.toLowerCase(), search_phrase_clean)
    })
  }

  const filtered_items = get_items_to_display()

  return (
    <UncontrolledDropdown>
      <SelectDropdownToggleStatic chevron_left className={s.dropdown_switch} disabled={disabled}>
        {selected_value || label || ''}
      </SelectDropdownToggleStatic>

      <DropdownMenu modifiers={get_modifiers()} >

        {enable_search &&
          <>
            <SearchBar
              className='m-2'
              placeholder={'Search...'}
              search_input_ref={search_input_ref}
              search_input={search_phrase || ''}
              on_change_search_input={set_search_phrase}
              no_button={true}
              no_enter_trigger={true}
              is_search_valid={true}
              autofocus={true}
            />
            <DropdownItem divider/>
          </>
        }

        {filtered_items.map((item, i) => (
          <DropdownItem key={i} onClick={() => on_change_handler(item.id)}>
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>

    </UncontrolledDropdown>
  )
}
