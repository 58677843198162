import React from 'react'
import cn from 'classnames'

import { BellIcon, ShieldIcon } from '../widgets/IconSet.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { PORTFOLIO_SIZE_NO_PASS_THRESHOLD, PORTFOLIO_SIZE_WARNING_THRESHOLD } from '../../utils/report_builder_utils.js'

import TextLink from '../widgets/TextLink.js'
import { SUPPORT_EMAIL_ADDRESS } from '../../constants/constants.js'
import Tooltip from '../widgets/Tooltip.js'

import s from './ReportBuilderBasketWarning.module.scss'

const ReportBuilderBasketWarningWrapper = ({light, children}) => {
  return (
    <div className={cn(s.block, {[s.block__light]: light})}>
      {children}
    </div>
  )
}

export const ReportBuilderBasketWarning = ({light}) => {
  return (
    <ReportBuilderBasketWarningWrapper light={light}>
      <BellIcon />
      <span className='ml-2'>
        There are over {format_integer_with_comma(PORTFOLIO_SIZE_WARNING_THRESHOLD)} families in the basket. Clustering is not available for reports of this size.
      </span>
    </ReportBuilderBasketWarningWrapper>
  )
}


export const ReportBuilderBasketStopMessage = ({light, user_has_classifiers}) => {
  return (
    <ReportBuilderBasketWarningWrapper light={light}>
      <ShieldIcon />
      <span className='ml-2'>
        {user_has_classifiers && <span>For reports without a classifier,&nbsp;</span>}
        Cipher has a limit of {format_integer_with_comma(PORTFOLIO_SIZE_NO_PASS_THRESHOLD)} families. Please contact <TextLink element='a' href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>{SUPPORT_EMAIL_ADDRESS}</TextLink> for ideas of how to get the data you would like.
      </span>
    </ReportBuilderBasketWarningWrapper>
  )
}

export const ReportBuilderBasketWarningTooltip = ({is_in_modal, buttonClassName}) => {
  return (
    <Tooltip
      toggler={<span className={buttonClassName}><BellIcon /></span>}
      is_in_modal={is_in_modal}
    >
      There are over {format_integer_with_comma(PORTFOLIO_SIZE_WARNING_THRESHOLD)} families in the basket. Clustering is not available for reports of this size.
    </Tooltip>
  )
}

export const ReportBuilderNegativesProcessingDisabledTooltip = ({is_in_modal, buttonClassName}) => {
  return (
    <Tooltip
      toggler={<span className={buttonClassName}><BellIcon /></span>}
      is_in_modal={is_in_modal}
    >
      There are over {format_integer_with_comma(PORTFOLIO_SIZE_WARNING_THRESHOLD)} families in the basket. Unclassified families are not included for reports of this size.
    </Tooltip>
  )
}