import React from 'react'
import Header from './header/Header.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import HelpAndFeedback from './widgets/HelpAndFeedback.js'
import { is_trial } from '../utils/user_permissions.js'
import EulaModal from './EulaModal.js'
import DefaultPageContainer from './DefaultPageContainer.js'
import { withUser } from './UserContext.js'
import Footer from './Footer.js'

const DefaultPageWrapper = ({user, display_header, display_footer, children}) => {
  return (
    <DefaultPageContainer>
      {display_header !== false &&
        <Header />
      }
      <DefaultContentContainer>
        <HelpAndFeedback />
        {is_trial(user) &&
          <EulaModal />
        }
        {children}
      </DefaultContentContainer>

      {display_footer !== false &&
        <Footer />
      }
    </DefaultPageContainer>
  )
}

export default withUser(DefaultPageWrapper)