import React from 'react'

import { get_extent_from_key_items } from '../../utils/time_range_utils.js'
import { get_selected_timerange_items, has_empty_key_dims } from '../../utils/column_data_utils.js'

import LabelledSlider from '../LabelledSlider.js'

const TimerangeControl = (
  {
    className,
    sliderClassName,
    trackClassName,
    sliderLabelClassName,
    handleClassName,
    spec,
    original_key_dims,
    item,
    data_creation_date,
    on_change_timerange
  }) => {
  const { timeseries_key_dim_idxs, hide_timerange_control, get_hidden_dim_full_timerange } = spec

  // For now, assume spec only has a single time column
  const is_any_timeseries = (timeseries_key_dim_idxs && timeseries_key_dim_idxs.length) || get_hidden_dim_full_timerange

  const can_calculate_timerange = !has_empty_key_dims(original_key_dims) || get_hidden_dim_full_timerange

  if (!is_any_timeseries || !can_calculate_timerange || hide_timerange_control) {
    // Don't render this control
    return null
  }

  // Get full timerange
  const full_timerange_items = timeseries_key_dim_idxs ? original_key_dims[timeseries_key_dim_idxs[0]] : get_hidden_dim_full_timerange(data_creation_date) // i.e. assume only one timeseries
  const full_timerange = get_extent_from_key_items(full_timerange_items) // to-exclusive

  // Get selected_range
  const { timerange } = item // optional user-specified timerange
  const selected_timerange_items = get_selected_timerange_items(full_timerange_items, timerange, spec, data_creation_date)
  const selected_timerange = get_extent_from_key_items(selected_timerange_items)

  return (
    <div className={className}>
      <LabelledSlider
        value={selected_timerange}
        show_end_only={false}
        min_distance={1}
        is_inline={true}
        extent={full_timerange}
        handleChange={on_change_timerange}
        sliderClassName={sliderClassName}
        sliderLabelClassName={sliderLabelClassName}
        handleClassName={handleClassName}
        trackClassName={trackClassName}
      />
    </div>
  )
}

export default TimerangeControl