import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { CLASSIFIERS } from '../../constants/paths.js'
import { is_search_match } from '../../utils/classifier_tree_utils.js'
import { has_classifiers_edit } from '../../utils/user_permissions.js'
import { IS_PAGINATABLE, IS_USER_TAXONOMY, get_group_heading } from '../../utils/classifier_group_utils.js'
import ClassifiersCheckboxTree from './ClassifiersCheckboxTree.js'
import CheckboxGroupPaginationControls from '../viewer/CheckboxGroupPaginationControls.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { Heading } from '../widgets/PaneHeader.js'
import IndirectlySelectedMarker from '../widgets/IndirectlySelectedMarker.js'
import { withUser } from '../UserContext.js'

import s from './Classifiers.module.scss'

const PAGE_SIZE = 10

export const Classifiers = (
  {
    user,
    classifiers_data,
    search_phrase,
    selected_classifiers,
    selected_indirectly,
    show_selected_only,
    toggle_show_selected_only_handler,
    update_selection_handler,
    prohibit_parent_level_selection,
    check_icon,
    uncheck_icon,
    is_selection_permitted,
    unpermitted_selection_handler,
    should_exclude_if_partial_node_clicked=false,
    show_heading=true,
    hide_user_taxonomy_edit_link,
    className,
    PostLabel,
  }) => {

  const [page, set_page] = useState(0)

  const has_edit_classifiers_permission = has_classifiers_edit(user)
  const { children, [IS_USER_TAXONOMY]: is_user_taxonomy, [IS_PAGINATABLE]: is_paginatable } = classifiers_data
  const num_children = children.length
  const is_paginated = is_paginatable && (num_children > PAGE_SIZE)

  const heading = show_heading ? get_group_heading(classifiers_data) : null

  useEffect(() => {
    if (is_paginated) {
      // If search phrase changes, reset to page 0
      set_page(0)
    }
  }, [search_phrase, is_paginated])

  // Only filter if paginated (otherwise filtering happens in ClassifiersCheckboxTree)
  const filtered_children = (!is_paginated || search_phrase.trim() === '') ? children : children.filter(item => is_search_match(search_phrase, item.name))
  const num_filtered_children = filtered_children.length
  const num_pages = num_filtered_children < PAGE_SIZE ? 1 : Math.ceil(num_filtered_children / PAGE_SIZE)
  const page_start_idx = page * PAGE_SIZE
  const page_end_idx = (page + 1) * PAGE_SIZE // to_exclusive
  const page_children = filtered_children.slice(page_start_idx, page_end_idx)

  // Sets of selected ids (for easy lookup)
  const selected_classifiers_ids = selected_classifiers.map(classifier => classifier.classifier_id)
  const selected_classifiers_id_set = new Set(selected_classifiers_ids)
  const selected_indirectly_ids = selected_indirectly.map(classifier => classifier.classifier_id)
  const selected_indirectly_id_set = new Set(selected_indirectly_ids)

  return (
    <div className={className}>
      <div className='d-flex justify-content-between'>
        <div>
          {heading &&
            <Heading text={heading} className={cn('mb-2', s.title)}/>
          }
        </div>

        {is_paginated &&
          <CheckboxGroupPaginationControls
            className={cn('d-flex align-items-center justify-content-center mb-2', s.pagination_controls)}
            num_pages={num_pages}
            page={page}
            page_size={PAGE_SIZE}
            total_num_items={children.length}
            handle_change_page={set_page}
          />
        }

        {!hide_user_taxonomy_edit_link &&
          <>
            {is_user_taxonomy && (
              has_edit_classifiers_permission ?
                <Link
                  className={s.edit_link}
                  to={CLASSIFIERS}
                  target='_blank'
                  title='Edit this user taxonomy&apos;s paths (in the Classifiers Editor)'
                >
                  [edit]
                </Link>
                :
                <div
                  className={s.edit_link__disabled}
                  title='This is a user taxonomy. To edit it, you need access to the Classifiers Editor. Please contact Cipher for more information.'
                >
                  [edit]
                </div>
            )}
          </>
        }
      </div>

      {!is_paginatable &&
        <ClassifiersCheckboxTree
          classifiers_data={classifiers_data}
          search_input={search_phrase}
          selected_classifiers={selected_classifiers}
          selected_indirectly={selected_indirectly}
          on_selected_classifiers_updated={update_selection_handler}
          show_selected_only={show_selected_only}
          update_is_showing_selected_only={toggle_show_selected_only_handler}
          prohibit_parent_level_selection={prohibit_parent_level_selection}

          check_icon={check_icon}
          uncheck_icon={uncheck_icon}

          is_selection_permitted={is_selection_permitted}
          unpermitted_selection_handler={unpermitted_selection_handler}

          should_exclude_if_partial_node_clicked={should_exclude_if_partial_node_clicked}

          PostLabel={PostLabel}
        />
      }

      {is_paginatable &&
        <div
          className={cn('mt-3')}
        >
          {page_children.map((classifier, i) => {
            const { name, classifier_id, description } = classifier
            const is_checked = selected_classifiers_id_set.has(classifier_id)

            const is_indirectly_selected = selected_indirectly_id_set.has(classifier_id)

            return (
              <div
                key={i}
                className={cn('my-2')}
              >
                <CheckboxAndLabel
                  label={(
                    <span>
                      <Highlighter
                        search_words={[search_phrase]}
                        text_to_highlight={name}
                      />
                      {is_indirectly_selected &&
                        <IndirectlySelectedMarker />
                      }
                    </span>
                  )}
                  post_label={description ? <InfoPopover wide interactive={true}>{description}</InfoPopover> : null}
                  is_checked={is_checked || is_indirectly_selected}

                  on_click={() => {
                    const new_selections = !is_checked ?
                        [...selected_classifiers, classifier]                                                                 // ADD
                        : selected_classifiers.filter(_classifier => _classifier.classifier_id !== classifier.classifier_id)  // REMOVE

                    update_selection_handler(new_selections)
                  }}
                />
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default withUser(Classifiers)