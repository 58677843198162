import React from 'react'

import { format_integer_with_comma, pluralise_text } from '../../utils/utils.js'

const PatentFamilyListCountInfo = ({count, className}) => {
  return (
    <div className={className}>This selection contains {format_integer_with_comma(count || 0)} {pluralise_text(count || 0, 'family', 'families')}</div>
  )
}

export default PatentFamilyListCountInfo