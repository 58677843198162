import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'

import s from './EditGroupWAMDetailsModal.module.scss'
import { PrimaryButton } from '../widgets/Button.js'
import { update_keycloak_group } from '../../utils/user_group_utils.js'
import { ENTER_CODE } from '../../constants/keys.js'

const EditGroupWAMDetailsModal = ({
  group_details,
  set_group_details,
  on_close
}) => {

  const input_ref = React.createRef()

  const { name, id, attributes } = group_details
  const { customer_id: customer_id_arr } = attributes
  const customer_id__orig = (customer_id_arr && customer_id_arr.length) ? customer_id_arr[0] : undefined // may be undefined

  const [customer_id, set_customer_id] = useState(customer_id__orig) // may undefined

  const [is_saving, set_is_saving] = useState(false)
  const [error_saving, set_error_saving] = useState(null)

  const customer_id__clean = (customer_id && customer_id.trim().length) ? customer_id.trim() : undefined // may be undefined

  const is_ok_to_save = (customer_id__clean !== customer_id__orig)

  useEffect(() => {
    // On open, set focus
    setTimeout(() => {
      if (input_ref && input_ref.current) {
        input_ref.current.focus()
      }  
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function do_save() {

    const attributes__clean = _.omit(attributes, 'customer_id')

    const new_attributes = {
      ...attributes__clean,
      ...(customer_id__clean ? { customer_id: [customer_id__clean] } : {}) // we treat 0-length customer_id as a "remove"
    }

    const new_group_details = {
      ...group_details,
      attributes: new_attributes
    }

    // Save remote
    set_error_saving(null)
    set_is_saving(true)
    update_keycloak_group(id, new_group_details)
      .catch(err => {
        // FAIL
        set_error_saving(err)
        throw err
      })
      .then(() => {
        // SUCCESS
        set_group_details(new_group_details) // update local
        on_close()    
      })
      .finally(() => {
        set_is_saving(false)
      })
  }

  return (
    <Modal
      className={cn(s.modal_container)}
      bodyClassName={cn(s.modal_body)}
      title={`Edit WAM Details for group "${name}"`}
      close_label={'Close'}
      on_hide={on_close}
      footer={
        <div>
          {is_saving &&
            <Spinner
              size={'sm'}
              className={cn('mr-1')}
            />
          }
          <PrimaryButton
            size={'md'}
            onClick={do_save}
            disabled={!is_ok_to_save || is_saving || error_saving}
          >
            Save
          </PrimaryButton>
        </div>
      }
    >

      <div>
        <div className={cn('form-group')}>
          <label className={cn('form-label')}>
            customer_id
          </label>
          <input
            ref={input_ref}
            type={'text'}
            className={cn('form-control')}
            value={customer_id || ''}
            onChange={e => set_customer_id(e.target.value)}
            onKeyUp={(e) => {
              if (e.code === ENTER_CODE) {
                do_save()
              }
            }}
          />
        </div>
      </div>

      {is_saving &&
        <Spinner
          size={'sm'}
          className={cn('')}
        />
      }

      {/* ERRORS */}
      {error_saving &&
        <ErrorBody
          error={error_saving}
          context={'saving group attributes'}
        />
      }

    </Modal>

  )
}

export default EditGroupWAMDetailsModal