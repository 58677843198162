import React from 'react'
import cn from 'classnames'
import { DropdownItem, DropdownMenu, Label, UncontrolledDropdown } from 'reactstrap'

import SelectDropdownToggleStatic from '../../widgets/SelectDropdownToggleStatic.js'

import s from './CustomClusteredOptions.module.scss'

export const CSV_FORMAT = 'csv'
export const TSV_FORMAT = 'tsv'

export const CustomClusteredOptions = ({className, data_format, set_data_format}) => {

  return (
    <div className={className}>
      <Label>Data format:</Label>
      <UncontrolledDropdown>
        <SelectDropdownToggleStatic className={cn('ml-1', s.dropdown_toggle)}>
          {data_format}
        </SelectDropdownToggleStatic>
        <DropdownMenu>
          <DropdownItem onClick={() => set_data_format(CSV_FORMAT)}>
            {CSV_FORMAT}
          </DropdownItem>
          <DropdownItem onClick={() => set_data_format(TSV_FORMAT)}>
            {TSV_FORMAT}
          </DropdownItem>

        </DropdownMenu>
      </UncontrolledDropdown>

    </div>
  )
}