import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import { Switch } from '@mui/material'
import moment from 'moment'

import {
  CIPHER_OTHER_VERSION,
  get_cipher_other_hostname,
  get_cipher_version_cookie_domain,
  get_cipher_version_cookie_name,
  is_should_redirect_to_other_cipher,
  should_enable_switch
} from '../../utils/switch_cipher.js'
import { get_static_classname, track_user_event } from '../../utils/tracking_utils.js'

import cs from '../cipher_styles.module.scss'

const CipherSwitch = ({location}) => {

  const [show_other_cipher, set_show_other_cipher] = useState(false)

  const [cookies, setCookie ] = useCookies([get_cipher_version_cookie_name()])

  useEffect(() => {
    if (is_should_redirect_to_other_cipher(cookies[get_cipher_version_cookie_name()])) {
      set_show_other_cipher(true)
    }
  }, [cookies])

  useEffect(() => {
    if (show_other_cipher) {
      const {pathname, search} = location
      const url = `${get_cipher_other_hostname()}${pathname}${search ? search : ''}`
      window.location.replace(url)
    }
  }, [show_other_cipher, location])

  function switch_to_other_cipher() {
    track_user_event('action="switch_cipher" target="new_design"')

    setCookie(
      get_cipher_version_cookie_name(),
      CIPHER_OTHER_VERSION,
      {
        path: '/',
        domain: get_cipher_version_cookie_domain(),
        expires: new Date(moment().add(60, 'days').format('YYYY-DD-MM')),
        maxAge: (60 * 24 * 60 * 60) /* 60 days in seconds*/
      }
    )
    set_show_other_cipher(true)
  }

  const is_enabled = should_enable_switch()

  if (!is_enabled) return null

  return (
    <div className={cn('d-flex flex-nowrap ml-2', get_static_classname('switch_to_new'), cs.cursor_pointer )} onClick={switch_to_other_cipher} title='Switch to new design'>
      <span className={cn('my-auto', cs.cipher_white_text)}>
        New Design
      </span>
      <Switch
        sx={{
          '.MuiSwitch-track' : {
            backgroundColor: '#999999 !important',
            opacity: '1 !important'
          }
        }}
        className='my-auto'
        checked={false}
      />
    </div>
  )
}

export default withRouter(CipherSwitch)
