import axios from 'axios'
import $ from 'jquery'
import _ from 'underscore'
import * as convert from 'xml-js'

import { add_source_err_to_target_err } from './axios_utils.js'
import { parse_patent, get_ucid } from './patent_utils.js'
import { get_patent_families_by_pat_fam_ids }  from './domain_utils.js'
import { smooth_scroll_to_y } from './scroll_utils.js'

import { PUBLICATION_XML_URL, PUBLICATION_IMAGES_URL } from '../constants/urls.js'
import { STATUS_LABEL_TO_TOOLTIP, STATUS_TO_STATUS_LABEL } from '../constants/family_status.js'
import {
  PREFERRED_COUNTRIES,
  PREFERRED_KIND_CODES_BY_COUNTRY,
  FIELDS,
  LEGAL_EVENT_CATEGORY_GRANT,
  LEGAL_EVENT_CATEGORY_EXPIRY,
  LEGAL_EVENT_CATEGORY_REASSIGNED,
  LEGAL_EVENT_CATEGORY_PUBLICATION,
  LEGAL_EVENT_CATEGORY_LAPSED,
  LEGAL_EVENT_CATEGORY_SECURITISED,
  COST_PER_YEAR_FIELD,
  LEGAL_EVENT_CODE_EP_INTG,
  LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT
} from '../model/family_view.js'
import { compare_iso_date_string, json_make_copy, remove_non_breaking_spaces, startsWith, to_date } from './utils.js'
import { DEFAULT_LANGUAGE, LANGUAGE_CODE_TO_OPTION } from '../model/language_prefs.js'

export const IN_CITATIONS_TITLE = 'Forward citations'
export const OUT_CITATIONS_TITLE = 'Backward citations'

export function get_status(status) {
   return (status) ? STATUS_TO_STATUS_LABEL[status.toLowerCase()] : ''
}

export function get_status_tooltip(status) {
  if (!status) {
    return null
  }
  return STATUS_LABEL_TO_TOOLTIP[status.toLowerCase()]
}

function extract_from_xml_string(xml_string, field) {
  return convert.xml2js(xml_string, {compact: false}).elements[0].elements.filter((element) => {return element.name === field})
}

function extract_claims_structure_from_xml_string(xml_string) {
  try {
    return convert.xml2js(xml_string, {compact: false})
        .elements[0].elements.filter((element) => {return element.name === 'bibliographic-data'})[0]
        .elements.filter((element) => {return element.name==='ifi-integrated-content'})[0]
        .elements.filter((element) => {return element.name === 'ifi-claims-summary'})[0]
        .elements[0].elements
  } catch(e) {
      return null
  }
}

function get_claims_in_preferred_language(claims, language) {
  for (let i = 0; i < claims.length; i++) {
    if (language.toLowerCase() === claims[i].attributes.lang.toLowerCase()) {
      return claims[i]
    }
  }

  return null
}

function select_by_language(claims, preferred_language) {
  if (!claims || (claims[0] === undefined)) {
    return null
  }

  const claims_in_preferred_language = get_claims_in_preferred_language(claims, preferred_language)

  if (claims_in_preferred_language) return claims_in_preferred_language

  if (DEFAULT_LANGUAGE !== preferred_language) {
    const claims_in_default_language = get_claims_in_preferred_language(claims, DEFAULT_LANGUAGE)
    if (claims_in_default_language) return claims_in_default_language
  }

  return claims[0]
}

function replace_open_and_close_html_tag_brackets(text) {
  return (text || '').replace(/</g, '&lt').replace(/>/g, '&gt')
}

//for claims and descriptions
export function prepare_text(text) {
  if (text) {
    return remove_non_breaking_spaces(replace_open_and_close_html_tag_brackets(text), 2)
  }

  return ''
}

export function get_element_content(element) {
  const child = element.elements

  if (!child || !_.isArray(child)) {
    return ''
  }

  const first_child = child[0]
  if (!first_child) return ''

  const {text} = first_child

  //.replace to sort out a special case of malformed html spotted in claims of publication KR100869577B1
  return text ? remove_non_breaking_spaces(replace_open_and_close_html_tag_brackets(text), 2) : ''
}

export function do_common_parse(item) {
  if (item.type === 'text') {
    return prepare_text(item.text)
  }

  if (item.name === 'b') {
    return '<b>' + prepare_text(get_element_content(item)) + '</b>'
  }

  if (item.name === 'u') {
    return '<u>' + prepare_text(get_element_content(item)) + '</u>'
  }

  if (item.name === 'br') {
    return prepare_text(get_element_content(item)) + '<br/>'
  }

  if (item.name === 'sub') {
    return '<sub>' + prepare_text(get_element_content(item)) + '</sub>'
  }

  if (item.name === 'sup') {
    return '<sup>' + prepare_text(get_element_content(item)) + '</sup>'
  }

  return ''
}

function get_ordered_countries(countries, preferred_country) {

  const cipher_ordered_countries = preferred_country ? [preferred_country, ...PREFERRED_COUNTRIES] : PREFERRED_COUNTRIES

  const countries_and_priorities = (countries || []).map((country, i) => {
    const idx = cipher_ordered_countries.indexOf(country)

    const priority = idx > -1 ? idx : (cipher_ordered_countries.length + i)

    return {country, priority}
  })

  const priority_to_country = _.indexBy(countries_and_priorities, 'priority')

  const priority_keys =  Object.keys(priority_to_country).map(key => (key * 1))

  return priority_keys.sort((a,b) => a-b).map(priority => (priority_to_country[priority].country))
}

function get_ordered_kind_codes(kind_codes=[], preferred_kind_codes=[]) {
  let priority_for_missing_code = preferred_kind_codes.length

  const kind_codes_and_priorities = (kind_codes || []).map(code => {
    const idx = preferred_kind_codes.indexOf(code)
    const priority = idx > -1 ? idx : priority_for_missing_code

    if (idx === -1) {
      priority_for_missing_code += priority_for_missing_code
    }

    return {code, priority}
  })

  const priority_to_code = _.indexBy(kind_codes_and_priorities, 'priority')

  const priority_keys =  Object.keys(priority_to_code).map(key => (key * 1))

  return priority_keys.sort((a,b) => a-b).map(priority => (priority_to_code[priority].code))
}

function get_parsed_patents(patents, field) {
  const parsed_patents = patents.map(patent => {
    const parsed_patent = parse_patent(patent)
    return {[field]: parsed_patent[field], patent}
  })

  return _.groupBy(parsed_patents, item => item[field])
}

function order_patents_by_kind_code(patents) {
  const patent_groups_by_kind_code = get_parsed_patents(patents, 'kind_code')
  const kind_codes = Object.keys(patent_groups_by_kind_code)

  const ordered_kind_codes = kind_codes.sort()

  let ordered_patents = []

  ordered_kind_codes.forEach(code => {
    const patents_to_kind_code = patent_groups_by_kind_code[code].map(item => (item.patent))
    ordered_patents = [...ordered_patents, ...patents_to_kind_code.sort()]
  })

  return ordered_patents
}

function get_most_representative_patent(country, patents) {
  const patent_groups_by_kind_code = get_parsed_patents(patents, 'kind_code')
  const kind_codes = Object.keys(patent_groups_by_kind_code)

  const kind_codes_by_priority = PREFERRED_KIND_CODES_BY_COUNTRY[country] || []

  const ordered_kind_codes = (kind_codes_by_priority.length === 0) ? kind_codes.sort().reverse() : get_ordered_kind_codes(kind_codes, kind_codes_by_priority)
  const selected_kind_code = ordered_kind_codes.sort().reverse()[0]

  const patents_by_kind_code = patent_groups_by_kind_code[selected_kind_code].map(item => (item.patent))

  return patents_by_kind_code.sort()[0]
}

export function order_patent_numbers_and_select_best_representation(family, preferred_language) {
  const { country: preferred_country } = LANGUAGE_CODE_TO_OPTION[preferred_language] || {}

  const { patentNumbers: patent_numbers, primaryPublication } = family || {}

  if (!patent_numbers || (patent_numbers.length === 0)) {
    return {}
  }

  if (patent_numbers.length === 1) {
    return { ordered_patent_numbers: patent_numbers, representative_patent_number: patent_numbers[0] }
  }

  const patent_groups_by_country_code = get_parsed_patents(patent_numbers, 'country_code')

  const countries = get_ordered_countries(Object.keys(patent_groups_by_country_code), preferred_country)

  let ordered_patent_numbers = []

  countries.forEach(country => {
    const patents = patent_groups_by_country_code[country].map(item => item.patent)
    ordered_patent_numbers = [...ordered_patent_numbers, ...order_patents_by_kind_code(patents)]
  })

  const top_country = countries[0]
  const representative_patent_number = (preferred_country || !primaryPublication) ?
    get_most_representative_patent(top_country, patent_groups_by_country_code[top_country].map(item => item.patent)) :
    primaryPublication.split('-').join('')
  //patent numbers are ordered by countries priority list, by kind codes alphabet asc, by alphabet asc
  return { ordered_patent_numbers, representative_patent_number }
}

export function fetch_and_process_patent_xml(patent, preferred_language=DEFAULT_LANGUAGE) {
  const ucid = get_ucid(patent)

  if (!ucid || ucid === '') {
    Promise.resolve(null)
  }

  return axios.get(`${PUBLICATION_XML_URL}/${ucid}`)
    .catch(() => {
      return axios.get(`/api/patent/xml/${ucid}`)
    })
    .catch(err => {
      throw err
    })
    .then(function(response) {
      try {
        const xml_data = response.data

        if (!xml_data || xml_data === '') return null
        const claims_summary = extract_claims_structure_from_xml_string(xml_data)
        const claims = select_by_language(extract_from_xml_string(xml_data, 'claims'), preferred_language)
        const description = select_by_language(extract_from_xml_string(xml_data, 'description'), preferred_language)
        const abstract = select_by_language(extract_from_xml_string(xml_data, 'abstract'), preferred_language)
        const drawings = (extract_from_xml_string(xml_data, 'drawings') || [])[0]

        return {
          claims: { claims_list: (claims) ? claims.elements : [], claims_summary },
          description: description ? description.elements : [],
          abstract: abstract ? abstract.elements : [],
          drawings: drawings ? drawings.elements : [],
        }
      } catch (e) {
        throw new Error('Couldn\'t parse claim xml ' + e)
      }
    })
}

export function get_images_by_publication_ids(publication_id) {
  if (! publication_id ) return null

  const ucid = get_ucid(publication_id)

  if (!ucid || ucid === '') {
    return Promise.resolve(null)
  }

  return axios.get(`${PUBLICATION_IMAGES_URL}/${ucid}`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch publication images list: ')
      throw wrapped_err
    })
    .then(response => {
      return response.data
    })
}

/**
 * @param {} pat_fam_id
 */
export function get_family_data(pat_fam_id, is_noauth_access) {
  const filtered_fields = is_noauth_access ? FIELDS.filter(field => field !== COST_PER_YEAR_FIELD) : FIELDS
  return get_patent_families_by_pat_fam_ids([pat_fam_id], filtered_fields)
    .then((families_response) => {
      // We look at the [0]th member in these calls just because the APIs are batch APIs and we're
      // only requesting a single item
      return families_response[0]
    })
}

export function parse_territories(territories) {
  const unique_territories = (territories || []).reduce((x, y) => (x.indexOf(y) > -1)? x : [...x, y], [])
  return (unique_territories.length > 0) ? unique_territories.join(', ') : '(none)'
}

export function get_family_id_from_cipher_family_id(cipher_family_id) {
  if (!cipher_family_id) return null

  if ((cipher_family_id + '')[0].toUpperCase() === 'C') {
    return parseInt(cipher_family_id.slice(1))
  }

  return cipher_family_id
}

export function create_cipher_family_id_from_family_id(family_id) {
  if (!family_id) return null

  const LENGTH = 10
  const PREFIX = 'C'

  if (_.isString(family_id) && startsWith(family_id, PREFIX) && family_id.length === LENGTH) {
    // It's already a cipher_family_id, so do nothing
    return family_id
  }

  const zero_padded_id = (Array(LENGTH).join('0') + family_id).slice(-LENGTH)
  return `C${zero_padded_id}`
}


export function is_cipher_family_id_valid(cipher_family_id) {
  if (!cipher_family_id) return false
  return (cipher_family_id.length === 11) && (cipher_family_id[0].toUpperCase() === 'C') && !isNaN(cipher_family_id.slice(1) * 1)
}
export function scroll_to_section_ref(ref) {
  const element = ref ? ref.current : null
  if (!element) {
    return
  }

  // Get y of element
  const el_top = element.getBoundingClientRect().top

  // Leave a bit of space to compensate for headers etc (NOTE: in future, avoid having floating/sticky headers etc)
  const target_y = el_top + document.documentElement.scrollTop - 160

  // If the ref is really near the top of the screen, just scroll all the way to the top
  const target_y_clean = target_y < 200 ? 0 : target_y

  // Workaround to deal with modals (ideally would be better not to use modals...)
  // If we're in a modal, use that for the scroll context.
  const modal_ancestors = $(element).parents('.modal-body')
  const container_element = modal_ancestors.length ? modal_ancestors[0] : null

  // Scroll
  smooth_scroll_to_y(target_y_clean, container_element, 500)
}

function get_representative_patent_number(publication_ids, preferred_language) {
  return order_patent_numbers_and_select_best_representation({patentNumbers: publication_ids}, preferred_language).representative_patent_number
}

function convert_legal_event_dates_to_iso(event) {
  const event_copy = json_make_copy(event)
  event_copy.dateDeclared = to_date(event_copy.dateDeclared)
  event_copy.relevantDate = to_date(event_copy.relevantDate)
  event_copy.spcExtensionDate = to_date(event_copy.spcExtensionDate)
  return event_copy
}

export function get_legal_events_sorted(legal_events) {
  if (!legal_events) return null

  return sort_legal_events_by_date(legal_events.map(convert_legal_event_dates_to_iso))
}

export function sort_legal_events_by_date(legal_events, field = 'dateDeclared') {
  if (!legal_events) return null
  return legal_events.sort((event_1, event_2) => compare_iso_date_string(event_2[field], event_1[field]))
}

export function get_key_legal_events(legal_events, preferred_language) {
  let events = legal_events.filter(event => {
    const { category, newOwners, code } = event

    return ([LEGAL_EVENT_CATEGORY_GRANT, LEGAL_EVENT_CATEGORY_EXPIRY, LEGAL_EVENT_CATEGORY_LAPSED, LEGAL_EVENT_CATEGORY_SECURITISED, LEGAL_EVENT_CATEGORY_PUBLICATION].indexOf(category) !== -1) ||
      (category === LEGAL_EVENT_CATEGORY_REASSIGNED && newOwners != null && newOwners.length > 0) ||
      (code === LEGAL_EVENT_CODE_EP_INTG && category === LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT)
  })

  let key_events = []

  const category_to_events = _.groupBy(events, 'category')

  Object.keys(category_to_events).forEach(category_key => {

    const events_by_category = category_to_events[category_key] || []

    if ([LEGAL_EVENT_CATEGORY_GRANT, LEGAL_EVENT_CATEGORY_EXPIRY, LEGAL_EVENT_CATEGORY_PUBLICATION, LEGAL_EVENT_CATEGORY_LAPSED].indexOf(category_key) !== -1) {
      //if multiple grants, expiry or publication events, select the earliest one for the summary

      const events_with_sorted_publication_numbers = events_by_category.map(event => {
        const {publicationNumbers} = event

        return {...event, publications_sorted: publicationNumbers.sort()}
      })

      const publications_to_events = _.groupBy(events_with_sorted_publication_numbers, 'publicationNumbers')

      Object.keys(publications_to_events).forEach(publications => {
        const events_by_publications = publications_to_events[publications]
        const idx = (category_key === LEGAL_EVENT_CATEGORY_GRANT) || (category_key === LEGAL_EVENT_CATEGORY_PUBLICATION) ? events_by_publications.length - 1 : 0

        const event = events_by_publications.length === 1 ? events_by_publications[0] : sort_legal_events_by_date(events_by_publications)[idx]
        const { category, publicationNumbers, dateDeclared, relevantDate } = event
        key_events.push({category, publications: [get_representative_patent_number(publicationNumbers, preferred_language)], date: relevantDate || dateDeclared })
      })
    }

    if (category_key === LEGAL_EVENT_CATEGORY_SECURITISED) {
      const events_with_keys = events_by_category.map(event => {
        const { dateDeclared, relevantDate, publicationNumbers } = event

        const key = (relevantDate || dateDeclared) + '#' + publicationNumbers.sort().join(',')
        return {...event, key}
      })

      const events_grouped = _.groupBy(events_with_keys, 'key')

      Object.keys(events_grouped).forEach(key => {
        const events_by_key = events_grouped[key]

        const { dateDeclared, relevantDate, publicationNumbers } = events_by_key[0]
        key_events.push({date: relevantDate || dateDeclared, category: LEGAL_EVENT_CATEGORY_SECURITISED, publications: [get_representative_patent_number(publicationNumbers, preferred_language)]})
      })
    }

    if (category_key === LEGAL_EVENT_CATEGORY_REASSIGNED) {

      //if happened on the same date and to the same new owners try to merge reassignments

      //create key based on date and owners
      const events_with_keys = events_by_category.map(event => {
        const { newOwners, dateDeclared, relevantDate } = event
        const key = (relevantDate || dateDeclared) + '#' + newOwners.sort().join('; ')
        return {...event, key}
      })

      const events_grouped = _.groupBy(events_with_keys, 'key')

      //merge publication ids into a single collection
      Object.keys(events_grouped).forEach(key => {
        const [ date, new_owners ] = key.split('#')
        const events_by_key = events_grouped[key]

        const publications = events_by_key.map(event => {
          const { publicationNumbers } = event
          return get_representative_patent_number(publicationNumbers, preferred_language)
        })

        key_events.push({date, category: LEGAL_EVENT_CATEGORY_REASSIGNED, new_owners, publications})
      })
    }

    if (category_key === LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT) {
      const event = events_by_category[0]

      if (event) {
        const {dateDeclared, relevantDate, publicationNumbers} = event

        const date = relevantDate || dateDeclared
        const publications = [get_representative_patent_number(publicationNumbers, preferred_language)]

        key_events.push({date, category: LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT, publications})
      }
    }
  })

  return sort_legal_events_by_date(key_events, 'date')
}
