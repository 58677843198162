import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import TextLink from './TextLink.js'
import { FeedbackIcon, InfoIcon } from './IconSet.js'
import { ExternalTextLink } from './ExternalTextLink.js'
import { withRouter } from 'react-router-dom'
import { on_click_from_help_link, get_help_url } from '../../utils/help_utils.js'
import { track_support_event } from '../../utils/tracking_utils.js'
import FeedbackModal from '../FeedbackModal.js'
import { is_report_url } from '../../utils/report_url_utils.js'
import { CLASSIFIERS, SAVED_ORGS_LISTS_ADMIN } from '../../constants/paths.js'

import s from './HelpAndFeedback.module.scss'

const HelpAndFeedback = (
  {
    location,
    help_url: help_url_prop,
    on_click_feedback_handler,
  }) => {

  const [feedback_modal_open, set_feedback_modal_open] = useState(false)
  const [help_url, set_help_url] = useState(get_help_url())

  const {pathname} = location
  const is_report_page = is_report_url(pathname)
  const is_classifiers_page = pathname.startsWith(CLASSIFIERS)
  const is_org_lists_page = pathname.startsWith(SAVED_ORGS_LISTS_ADMIN)

  useEffect(() => {
    const url = get_help_url()
    set_help_url(url)
  }, [location])

  function set_show_feedback_modal(show_feedback_modal) {
    if (show_feedback_modal) {
      track_support_event('action="show"')
    }
    set_feedback_modal_open(show_feedback_modal)
  }

  function on_click_feedback() {
    if (on_click_feedback_handler) return on_click_feedback_handler()

    set_show_feedback_modal(true)
  }

  const help_url_to_display = help_url_prop || help_url

  const is_regular_page = !(is_report_page || is_classifiers_page || is_org_lists_page)

  return (
    <div className={cn([
      'd-flex flex-column',
      s.wrapper,
      {[s.help__viewer]: is_report_page},
      {[s.help__classifiers]: is_classifiers_page || is_org_lists_page},
      {[s.help__regular]: is_regular_page},
    ])}
    >
      {feedback_modal_open &&
        <FeedbackModal
          is_open={true}
          on_hide={() => set_show_feedback_modal(false)}
        />
      }

      <TextLink className={cn(s.option, 'mx-auto mt-1')} onClick={on_click_feedback} title='Contact us'><FeedbackIcon/></TextLink>
      <ExternalTextLink className={cn(s.option, 'mx-auto my-1')} url={help_url_to_display} target='_blank' title='Help' on_click={() => on_click_from_help_link(help_url_to_display)}><InfoIcon /></ExternalTextLink>
    </div>
  )
}

export default withRouter(HelpAndFeedback)