import { get_as_map } from '../utils/utils.js'

export const ALL_LINES_ID   = 'ALL_LINES_ID'
export const MAX_4_LINES_ID = 'MAX_4_LINES_ID'
export const SINGLE_LINE_ID = 'SINGLE_LINE_ID'

export const TABLE_LINE_RESTRICTIONS = [
  { id: SINGLE_LINE_ID, name: 'Single' },
  { id: MAX_4_LINES_ID, name: 'First 4' },
  { id: ALL_LINES_ID,   name: 'All' }
]

export const ID_TO_TABLE_LINE_RESTRICTION = get_as_map(TABLE_LINE_RESTRICTIONS, 'id')
