import React from 'react'
import cn from 'classnames'

import { ToggleDownIcon } from './IconSet.js'

import cs from '../cipher_styles.module.scss'

const DropdownChevron = ({className}) => {
  return (
    <span
      className={cn(cs.cipher_red_text, className)}
    >
      <ToggleDownIcon />
    </span>
  )
}

export default DropdownChevron