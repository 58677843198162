import _ from 'underscore'

import { spec_requires_assignee_links } from './spec_utils.js'
import {
  GROUPS,
  SPEC_ID_TO_SPEC_REF
} from '../model/spec_groups.js'
import { SPEC_KEYS_BY_ID } from '../model/spec_groups/spec_keys.js'
import { ID_TO_SPEC } from '../model/specs.js'

export const DISPLAY_COMPACT = 'compact'
export const DISPLAY_ALL = 'all'
export const DISPLAY_NAVIGATION = 'navigation'

function prepare_tsv(data, fields=[]) {
  const body_rows = data.map(item => {
    return fields.map(field => {
      const value = item[field]

      if (_.isArray(value)) {
        return value.join(' | ')
      }

      return item[field]
    })
  })

  const rows = [fields, ...body_rows]

  const tsv_rows = rows.map(row => row.join('\t'))
  return tsv_rows.join('\n')
}

export function get_groups_and_children_tsv() {
  const FIELDS = ['group_name', 'group_id', 'spec_id', 'name', 'slicing', 'description', 'query', 'column_names']

  const items = GROUPS.reduce((acc, group) => {
    const { id: group_id, name: group_name, children } = group

    const items = children.map(item => {
      const {name='', by=[], spec_id, description, get_description} = item

      const {get_query, column_names} = ID_TO_SPEC[spec_id]

      const slicing = by.map(item => {
        const { name } = SPEC_KEYS_BY_ID[item] || {}
        return name || item || ''
      }).join(' and ')


      const query = JSON.stringify(get_query({data_creation_date: new Date()}))

      return {group_name, group_id, spec_id, name, slicing, description: get_description ? get_description({}) : description, query, column_names: JSON.stringify(column_names || [])}
    })

    return [...acc, ...items]
  }, [])

  return prepare_tsv(items.filter(item => item.name !== ''), FIELDS)
}

export function get_base_groups({report_has_scores, schema_version, user}) {
  const groups = GROUPS.filter(group => {
    const {check_if_available} = group || {}
    if (check_if_available) return check_if_available({report_has_scores, schema_version, user})
    return true
  })

  return groups
}

function is_spec_available(spec, {has_missing_assignee_links, schema_version, report_has_scores, user}) {
  const {spec_id, check_if_available} = spec

  const is_available = (check_if_available) ? check_if_available({report_has_scores, schema_version, user}) : true

  if (!is_available) return false

  if (has_missing_assignee_links) {
    return !spec_requires_assignee_links(spec_id)
  }

  return true
}

/**
 * Given a group tree, remove any spec_ids not available for this kind of report (and hence any empty groups/subgroups).
 *
 * @param {} groups An array, where each element either has property 'spec_id' (leaf), or property 'children'.
 * @param {} is_family_based_report Has this report been created from families (eg upload, tech search) rather than assignees
 */
export function get_available_groups({report_has_scores, has_missing_assignee_links, schema_version, user}) {
  const base_groups = get_base_groups({report_has_scores, schema_version, user})
  const available_groups = base_groups.map(group => {
    const specs = group.children
    return { ...group, children: specs.filter(spec => is_spec_available(spec, {has_missing_assignee_links, schema_version, report_has_scores, user})) }
  })

  // remove groups with no items
  const groups_with_children = available_groups.filter(group => group.children.length)
  return [...groups_with_children]
}

export function get_spec_refs(items) {
  return (items || []).map(item => {
    const {spec_id, default_view_id, view_id} = item

    const has_view_id = (default_view_id || view_id)
    return has_view_id ? {...SPEC_ID_TO_SPEC_REF[spec_id], view_id: view_id || default_view_id} : SPEC_ID_TO_SPEC_REF[spec_id]
  })
}
