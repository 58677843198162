import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _ from 'underscore'

import { SUBSCRIPTIONS } from '../../constants/paths.js'
import {
  get_all_user_subscriptions,
  get_subscription_display_name,
  UNKNOWN_ALERT_TECH_NAME
} from '../../utils/alerts_utils.js'
import { get_alerts_help_url, on_click_from_help_link } from '../../utils/help_utils.js'
import { track_visit_event } from '../../utils/tracking_utils.js'

import { DashboardTileDesc } from './DashboardTileDesc.js'
import { DashboardSmallTile } from './DashboardTile.js'
import { TileHelpLink } from './TileHelpLink.js'
import { withUser } from '../UserContext.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import ErrorBody from '../ErrorBody.js'
import TextLink from '../widgets/TextLink.js'
import Spinner from '../widgets/Spinner.js'
import { find_classifier_match_in_trees } from '../../utils/classifier_tree_utils.js'
import { get_classifier_groups } from '../../utils/classifier_group_utils.js'
import { fetch_active_alerts } from '../../utils/alert_report_utils.js'
import { is_view_only_user, has_old_weekly_classifier_alerts } from '../../utils/user_permissions.js'
import { pluralise_text } from '../../utils/utils.js'
import { ExternalTextLink } from '../widgets/ExternalTextLink.js'

const MAX_LISTED_ALERTS = 3
const ALERT_TYPE_CLASSIFIER_ALERT = 'weekly classifier alert'
const ALERT_TYPE_REPORT_BASED = 'report based alert'

const AlertSubscriptionsPanel = ({ user }) => {

  const [show_spinner, set_show_spinner] = useState(true)
  const [recent_subscriptions, set_recent_subscriptions] = useState([])
  const [main_alert_type, set_main_alert_type] = useState('')
  const [additional_subs_count, set_additional_subs_count] = useState(0)
  const [classifier_subs_count_to_show, set_classifier_subs_count_to_show] = useState(0)
  const [error_fetching_subscriptions, set_error_fetching_subscriptions] = useState(null)

  const help_url = get_alerts_help_url()

  function fetch_subscriptions() {
    return Promise.all([
      fetch_active_alerts(),
      ...(has_old_weekly_classifier_alerts(user) ?
        [get_all_user_subscriptions(), get_classifier_groups(user, false /* false here excludes nd */)] :
        [Promise.resolve([]), Promise.resolve({})]),
    ])
      .then(([report_based_alerts, classifier_subscriptions, classifier_groups]) => {
        report_based_alerts = report_based_alerts ? report_based_alerts.map(alert => ({...alert, name: alert.alert_report_name})) : null

        const classifier_alerts = classifier_subscriptions.map(sub => {
          const classifier = find_classifier_match_in_trees(classifier_groups, sub.classifier_id)
          const name = get_subscription_display_name(sub, classifier)
          return {...sub, name}
        }).filter(sub => sub.name !== UNKNOWN_ALERT_TECH_NAME) // can happen if a classifier was recently deleted; alerts sync job will clear up any orphaned subscriptions
        return [report_based_alerts, classifier_alerts]
      })
  }

  useEffect(() => {
    let did_cancel = false
    fetch_subscriptions()
      .then(([report_based_subscriptions, classifier_alert_subscriptions]) => {
        // prefer report based subscriptions, unless the user only has weekly classifier ones
        const has_report_based_subscriptions = report_based_subscriptions && !_.isEmpty(report_based_subscriptions)
        const has_classifier_alert_subscriptions = classifier_alert_subscriptions && !_.isEmpty(classifier_alert_subscriptions)

        const subscriptions_to_display = has_classifier_alert_subscriptions && !has_report_based_subscriptions ? classifier_alert_subscriptions : report_based_subscriptions
        set_main_alert_type((has_classifier_alert_subscriptions && !has_report_based_subscriptions) ? ALERT_TYPE_CLASSIFIER_ALERT : ALERT_TYPE_REPORT_BASED)

        if (!did_cancel) {
          const total = subscriptions_to_display.length
          // if there's only one more alert than the list maximum, show all instead of "and 1 more..."
          const overflow = total > (MAX_LISTED_ALERTS + 1) ? (total - MAX_LISTED_ALERTS) : 0
          const subscriptions = total > (MAX_LISTED_ALERTS + 1) ? subscriptions_to_display.slice(0, MAX_LISTED_ALERTS) : subscriptions_to_display

          set_show_spinner(false)
          set_recent_subscriptions(subscriptions)
          set_additional_subs_count(overflow)

          if (has_report_based_subscriptions && classifier_alert_subscriptions) {
            set_classifier_subs_count_to_show(classifier_alert_subscriptions.length)
          }
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_show_spinner(false)
          set_error_fetching_subscriptions(error)
        }
      })
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function render_alert_list() {
    return recent_subscriptions.map((sub, i) => (
      <div key={i} className='d-sm-flex'>{sub.name}</div>
    ))
  }

  const has_subscriptions = recent_subscriptions.length > 0
  const has_old_classifiers_alerts = has_old_weekly_classifier_alerts(user) && !is_view_only_user(user)
  const show_help_page_as_main_link = !has_old_classifiers_alerts && !has_subscriptions && !is_view_only_user(user)

  return (
    <DashboardSmallTile
      title={'Alerts'}
      hint={!show_help_page_as_main_link && <TileHelpLink help_url={help_url}/>}
    >
      {show_spinner &&
        <div className='text-center'>
          <Spinner/>
        </div>
      }

      {error_fetching_subscriptions &&
        <ErrorBody
          error={error_fetching_subscriptions}
          context='fetching user subscriptions'
          show_details_in_modal={true}
        />
      }

      {!show_spinner && !error_fetching_subscriptions &&
        <DashboardTileInner>
          {!has_subscriptions &&
            <DashboardTileDesc>
              You are not yet subscribed to any alerts.
            </DashboardTileDesc>
          }

          { has_subscriptions &&
            <div className='my-3'>
              { render_alert_list() }
            </div>
          }

          <div className='d-inline'>
            { additional_subs_count > 0 &&
              <span className='mr-1'>plus {additional_subs_count} more {pluralise_text(additional_subs_count, main_alert_type)}</span>
            }

            { classifier_subs_count_to_show > 0 &&
              <span>and {classifier_subs_count_to_show} weekly classifier {pluralise_text(classifier_subs_count_to_show, 'alert')}</span>
            }
          </div>

          <div className='mt-auto'>
            { show_help_page_as_main_link &&
              <ExternalTextLink
                url={help_url}
                target='_blank'
                on_click={() => on_click_from_help_link(help_url)}
              >
                Help centre article
              </ExternalTextLink>
            }
            { (has_subscriptions || has_old_classifiers_alerts) &&
              <TextLink
                element={Link}
                to={SUBSCRIPTIONS}
                onClick={() => {track_visit_event('page="subscriptions"')}}
              >
                <span>{has_subscriptions ? 'Edit subscriptions' : 'Create subscriptions'}</span>
              </TextLink>
            }
            </div>
        </DashboardTileInner>
      }
    </DashboardSmallTile>
  )
}

export default withUser(AlertSubscriptionsPanel)