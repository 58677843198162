
// database ids for alert types and frequencies used in alerting

export const ALERT_TYPE_ID_NEW_FAMILIES = 0
export const ALERT_TYPE_ID_NEW_FILINGS = 1
export const ALERT_TYPE_ID_STATUS_CHANGE = 2
export const ALERT_TYPE_ID_PVIX_INCREASE = 3
export const ALERT_TYPE_ID_DELTA = 4

export const ALERT_FREQUENCY_ID_DAILY = 0
export const ALERT_FREQUENCY_ID_WEEKLY = 1
export const ALERT_FREQUENCY_ID_BIWEEKLY = 2
export const ALERT_FREQUENCY_ID_MONTHLY = 3
export const ALERT_FREQUENCY_ID_QUARTERLY = 4

// other constants

export const ALERT_FILTER_TYPE_STATUS = 'status'
export const ALERT_FILTER_TYPE_TERRITORY = 'territory'
export const ALERT_FILTER_TYPE_TERRITORY_TO_EXCLUDE = 'territory_to_exclude'
export const ALERT_FILTER_TYPE_PVIX_THRESHOLD = 'pvix_threshold'
export const ALERT_PARAM_PUB_TYPE = 'pub_type'
export const ALERT_PARAM_NEW_FAMS = 'new_families_only'

export const ALERT_BASE_INPUT_TYPE_CLASSIFIER = 'classifier'
export const ALERT_BASE_INPUT_TYPE_COMPANY_LIST = 'company_list'
export const ALERT_BASE_INPUT_TYPE_FAMILY = 'family'
export const ALERT_BASE_INPUT_TYPE_ORG = 'org'
export const ALERT_BASE_INPUT_TYPE_ORG_SET = 'org_set'
export const ALERT_BASE_INPUT_TYPE_SEARCH = 'search'

export const ALERT_TECH_PARTITIONING_TYPE_CLUSTERING = 'clustering'
export const ALERT_TECH_PARTITIONING_TYPE_CLASSIFIER = 'classifier'
export const ALERT_TECH_PARTITIONING_TYPE_CUSTOM = 'custom'
export const ALERT_TECH_PARTITIONING_TYPE_UTT = 'utt'

export const ALERT_PUBLICATION_TYPE_APPLICATION = 'application'
export const ALERT_PUBLICATION_TYPE_GRANT = 'grant'

export const ALERT_REPORT_FOCUS_FAMILIES = 'families'

export const NOTHING_TO_ALERT_DESCRIPTION = 'Receive a \'nothing to alert\' email when no updates are found during an alerting period'