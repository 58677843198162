import _ from 'underscore'

import { get_as_map } from '../utils/utils.js'
import {
  has_classifier_scores,
  has_pvix_scores,
  has_tags,
  is_schema_version_15_or_above
} from '../utils/data_availability_utils.js'

// These models are used by PatentsTable / Cards for:
// a) columns to render
// b) sorting

// IDs - note that these are the field names used in ElasticSearch / DomainService / Keycloak restricted_list_view_columns
export const PAT_FAM_ID_FIELD_ID            = 'patFamId'
export const CIPHER_FAMILY_ID_FIELD_ID      = 'cipherFamilyId'
export const PORTFOLIO_FIELD_ID             = 'portfolio'
export const TECHNOLOGY_FIELD_ID            = 'technology'
export const OWNERS_FIELD_ID               = 'owners'
export const ASSIGNEES_FIELD_ID             = 'assignees'
export const PATENT_NUMBERS_FIELD_ID        = 'patentNumbers'
export const TITLE_FIELD_ID                 = 'title'
export const ABSTRACT_FIELD_ID              = 'abstract'
export const STATUS_FIELD_ID                = 'status'
export const PENDING_TERRITORIES_FIELD_ID   = 'pendingTerritories'
export const GRANTED_TERRITORIES_FIELD_ID   = 'grantedTerritories'
export const EXPIRED_TERRITORIES_FIELD_ID   = 'expiredTerritories'
export const PRIORITY_DATE_FIELD_ID         = 'priorityDate'
export const GRANTED_DATE_FIELD_ID          = 'grantedDate'
export const PUBLICATION_DATE_FIELD_ID      = 'publicationDate'
export const EXPIRY_DATE_FIELD_ID           = 'expiryDate'
export const CPC_CODES_FIELD_ID             = 'cpcCodes'
export const INVENTORS_FIELD_ID             = 'inventors'
export const TOTAL_COST_FIELD_ID            = 'costToDate'
export const TOTAL_COST_PROJECTION_FIELD_ID = 'futureCostProjection'
export const SCORE_ID                       = 'score'
export const PVIX_SCORE_ID                  = 'pvixScore'
export const TAGS_ID                        = 'tags'
export const BACKWARD_CITATIONS_NUM_ID      = 'numOutCitations'
export const FORWARD_CITATIONS_NUM_ID       = 'numInCitations'
export const NO_SORT_ID                     = 'NO_SORT_ID' // represents when no sort is selected (default sort order)
export const CLASSIFIER_LABEL_CONTROLS_ID   = 'CLASSIFIER_LABEL_CONTROLS_ID'
export const RANDOM_ID                      = 'random' // text-search-service accepts this as a sort_by field (and then sorts using a random score)
export const USER_CLASS_FIELD_ID            = 'USER_CLASS_FIELD_ID'
export const SELECT_FIELD_ID                = 'bulk_selecting'
export const SIMILARITY_SCORE_ID            = 'similarity_score'
export const SIMILARITY_SEARCH_CONTROLS_FIELD_ID  = 'similarity_search_controls'
export const CLAIMS_FIELD_ID                = 'claims'
export const PRIMARY_CPC_CODE_FIELD_ID      = 'primaryCpc'
export const FIRST_FILING_COUNTRY_FIELD_ID  = 'firstFilingCountry'
export const PRIMARY_PUBLICATION_FIELD_ID   = 'primaryPublication'

// These fields are only available in Reports (not GlobalSearch).
// This is because they are only stored in the ReportReader (not ElasticSearch).
const REPORT_ONLY_FIELD_IDS = [
  PORTFOLIO_FIELD_ID,
  TECHNOLOGY_FIELD_ID,
  BACKWARD_CITATIONS_NUM_ID,
  FORWARD_CITATIONS_NUM_ID,
  PVIX_SCORE_ID,
  TAGS_ID,
]

const IDENTIFIER_GROUP_ID = 'identifier'
const TEXT_GROUP_ID       = 'text'
const OWNERSHIP_GROUP_ID  = 'ownership'
const TECH_GROUP_ID       = 'tech'
const GEO_GROUP_ID        = 'territories'
const DATES_GROUP_ID      = 'dates'
const COST_GROUP_ID       = 'cost'
const SCORE_GROUP_ID      = 'score'
const STATUS_GROUP_ID     = 'status'
const CITATIONS_GROUP_ID  = 'citations'
const TAG_GROUP_ID        = 'tag'

const GROUP_IDENTIFIER = {id: IDENTIFIER_GROUP_ID, label: 'Identifiers'}
const GROUP_TEXT       = {id: TEXT_GROUP_ID,       label: 'Patent text'}
const GROUP_OWNERSHIP  = {id: OWNERSHIP_GROUP_ID,  label: 'Ownership'  }
const GROUP_TECH       = {id: TECH_GROUP_ID,       label: 'Technology' }
const GROUP_COST       = {id: COST_GROUP_ID,       label: 'Cost'       }
const GROUP_DATES      = {id: DATES_GROUP_ID,      label: 'Dates'      }
const GROUP_GEO        = {id: GEO_GROUP_ID,        label: 'Territories'}
const GROUP_SCORE      = {id: SCORE_GROUP_ID,      label: 'Scores'     }
const GROUP_STATUS     = {id: STATUS_GROUP_ID,     label: 'Status'     }
const GROUP_CITATIONS  = {id: CITATIONS_GROUP_ID,  label: 'Citations'  }
const GROUP_TAG        = {id: TAG_GROUP_ID,        label: 'Tags'       }

export const GROUPS = [
  GROUP_IDENTIFIER,
  GROUP_OWNERSHIP,
  GROUP_TECH,
  GROUP_STATUS,
  GROUP_TEXT,
  GROUP_COST,
  GROUP_DATES,
  GROUP_GEO,
  GROUP_CITATIONS,
  GROUP_SCORE,
  GROUP_TAG
]

// Extra field for download/upload classifier
export const USER_CLASS_FIELD = { id: USER_CLASS_FIELD_ID, name: 'User class' }
export const CIPHER_FAMILY_ID_FIELD = { id: CIPHER_FAMILY_ID_FIELD_ID, group: IDENTIFIER_GROUP_ID, name: 'Cipher Family ID' }
const PATENT_FAMILY_FIELDS = [
  //{ id: PAT_FAM_ID_FIELD_ID,            name: 'Family ID',                                                       }, // never visible - if required, add it explicitly (i.e. in patent_family_list_utils.js)
  CIPHER_FAMILY_ID_FIELD,
  { id: PORTFOLIO_FIELD_ID,             group: OWNERSHIP_GROUP_ID,  name: 'Organisation',                                      in_es: false,                                                   },
  { id: OWNERS_FIELD_ID,               group: OWNERSHIP_GROUP_ID,  name: 'Owners',                                                                                                            },
  { id: ASSIGNEES_FIELD_ID,             group: OWNERSHIP_GROUP_ID,  name: 'Assignees',                                                                                                         },
  { id: TECHNOLOGY_FIELD_ID,            group: TECH_GROUP_ID,       name: 'Technology',                                        in_es: false,                                                   },
  { id: PRIMARY_PUBLICATION_FIELD_ID,   group: IDENTIFIER_GROUP_ID, name: 'Representative Publication',                                      check_if_available: is_schema_version_15_or_above },
  { id: PATENT_NUMBERS_FIELD_ID,        group: IDENTIFIER_GROUP_ID, name: 'Patent Numbers',                sortable: false,                                                                    },
  { id: TITLE_FIELD_ID,                 group: TEXT_GROUP_ID,       name: 'Title',                                                                                                             },
  { id: ABSTRACT_FIELD_ID,              group: TEXT_GROUP_ID,       name: 'Abstract',                      sortable: false,                                                                    },
  { id: CLAIMS_FIELD_ID,                group: TEXT_GROUP_ID,       name: 'Claims',                        sortable: false,    in_es: false,                                                   },
  { id: STATUS_FIELD_ID,                group: STATUS_GROUP_ID,     name: 'Status',                                                                                                            },
  { id: PENDING_TERRITORIES_FIELD_ID,   group: GEO_GROUP_ID,        name: 'Pending Territories',           sortable: false,                                                                    },
  { id: GRANTED_TERRITORIES_FIELD_ID,   group: GEO_GROUP_ID,        name: 'Granted Territories',           sortable: false,                                                                    },
  { id: EXPIRED_TERRITORIES_FIELD_ID,   group: GEO_GROUP_ID,        name: 'Expired Territories',           sortable: false,                                                                    },
  { id: FIRST_FILING_COUNTRY_FIELD_ID,  group: GEO_GROUP_ID,        name: 'First Filing Country',                                            check_if_available: is_schema_version_15_or_above },
  { id: PRIORITY_DATE_FIELD_ID,         group: DATES_GROUP_ID,      name: 'Priority Date',                                                                                                     },
  { id: GRANTED_DATE_FIELD_ID,          group: DATES_GROUP_ID,      name: 'Granted Date',                                                                                                      },
  { id: PUBLICATION_DATE_FIELD_ID,      group: DATES_GROUP_ID,      name: 'Publication Date',                                                                                                  },
  { id: EXPIRY_DATE_FIELD_ID,           group: DATES_GROUP_ID,      name: 'Expiry Date',                                                                                                       },
  { id: PRIMARY_CPC_CODE_FIELD_ID,      group: TECH_GROUP_ID,       name: 'Primary CPC Code',                                                check_if_available: is_schema_version_15_or_above },
  { id: CPC_CODES_FIELD_ID,             group: TECH_GROUP_ID,       name: 'CPC Codes',                     sortable: false,                                                                    },
  { id: INVENTORS_FIELD_ID,             group: OWNERSHIP_GROUP_ID,  name: 'Inventors',                     sortable: false,                                                                    },
  { id: TOTAL_COST_FIELD_ID,            group: COST_GROUP_ID,       name: 'Cost to Date [USD]',                                                                                                },
  { id: TOTAL_COST_PROJECTION_FIELD_ID, group: COST_GROUP_ID,       name: 'Future Cost  Projection [USD]',                                                                                     },
  { id: SCORE_ID,                       group: SCORE_GROUP_ID,      name: 'Score',                                             in_es: false, check_if_available: has_classifier_scores         },
  { id: PVIX_SCORE_ID,                  group: SCORE_GROUP_ID,      name: 'PVIX Score',                                        in_es: false, check_if_available: has_pvix_scores               },
  { id: BACKWARD_CITATIONS_NUM_ID,      group: CITATIONS_GROUP_ID,  name: 'Backward Citations',                                in_es: false,                                                   },
  { id: FORWARD_CITATIONS_NUM_ID,       group: CITATIONS_GROUP_ID,  name: 'Forward Citations',                                 in_es: false,                                                   },
  { id: TAGS_ID,                        group: TAG_GROUP_ID,        name: 'Family Tags',                   sortable: false,    in_es: false, check_if_available: has_tags                      },
]

// Extra fields for classifiers (not in the PATENT_FAMILY_FIELDS array above)
export const NO_SORT_FIELD                   = { id: NO_SORT_ID,                   name: 'Default' }
export const CLASSIFIER_LABEL_CONTROLS_FIELD = { id: CLASSIFIER_LABEL_CONTROLS_ID, sortable: false }
const EXTRA_FIELDS = [NO_SORT_FIELD, CLASSIFIER_LABEL_CONTROLS_FIELD]

export const SUGGESTION_STRATEGY_FIELD_ID = 'SUGGESTION_STRATEGY_FIELD_ID'
export const SUGGESTION_STRATEGY_FIELD    = { id: SUGGESTION_STRATEGY_FIELD_ID, name: 'Suggestion Strategy', sortable: false}
export const SUGGESTION_DETAIL_FIELD_ID   = 'SUGGESTION_DETAIL_FIELD_ID'
export const SUGGESTION_DETAIL_FIELD      = { id: SUGGESTION_DETAIL_FIELD_ID, name: 'Suggestion Detail', sortable: false}

//Extra fields

export const SIMILARITY_SCORE_FIELD =  { id: SIMILARITY_SCORE_ID, name: 'Similarity Score',  in_es: false }
export const SIMILARITY_SEARCH_CONTROLS_FIELD =  { id: SIMILARITY_SEARCH_CONTROLS_FIELD_ID, name: 'Add to search input',  in_es: false, sortable: false }

// We disable/enable sorting (via the 'sortable' property) for:
// a) 'abstract' - sorting by abstract takes too long (minutes)
// b) Multi-valued fields (i.e. 'pending_territories') - the results are often confusing and unhelpful.
//    But we make an exception for multi-valued fields 'assignees' and 'owners', which are often only single-valued.

function get_sortable_fields(fields) {
  return fields.filter(field => field.sortable !== false) // remove unsortable fields
}

// Report - pretty much all fields (client needs to filter by user permission, 'score' availability etc)
export const REPORT_PATENT_FAMILY_FIELDS      = PATENT_FAMILY_FIELDS
export const REPORT_PATENT_FAMILY_SORT_FIELDS = get_sortable_fields(REPORT_PATENT_FAMILY_FIELDS)

// Classifiers
export const CLASSIFIERS_PATENT_FAMILY_FIELDS = PATENT_FAMILY_FIELDS
  .filter(field => !_.contains([...REPORT_ONLY_FIELD_IDS, INVENTORS_FIELD_ID, TOTAL_COST_FIELD_ID, TOTAL_COST_PROJECTION_FIELD_ID, CLAIMS_FIELD_ID], field.id))
export const CLASSIFIERS_PATENT_FAMILY_SORT_FIELDS__NO_DEFAULT = get_sortable_fields(CLASSIFIERS_PATENT_FAMILY_FIELDS)
export const CLASSIFIERS_PATENT_FAMILY_SORT_FIELDS = [NO_SORT_FIELD, ...CLASSIFIERS_PATENT_FAMILY_SORT_FIELDS__NO_DEFAULT]

export const DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS = [
  CIPHER_FAMILY_ID_FIELD_ID,
  OWNERS_FIELD_ID,
  PRIMARY_PUBLICATION_FIELD_ID,
  TITLE_FIELD_ID,
  STATUS_FIELD_ID,
  PRIORITY_DATE_FIELD_ID
]

export const DEFAULT_SELECTED_CLASSIFIERS_FIELD_IDS = [
  CIPHER_FAMILY_ID_FIELD_ID,
  OWNERS_FIELD_ID,
  TITLE_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  SCORE_ID
]

export function get_default_selected_report_field_ids({schema_version}) {
  return [
    CIPHER_FAMILY_ID_FIELD_ID,
    TECHNOLOGY_FIELD_ID,
    OWNERS_FIELD_ID,
    is_schema_version_15_or_above({schema_version}) ? PRIMARY_PUBLICATION_FIELD_ID : PATENT_NUMBERS_FIELD_ID,
    TITLE_FIELD_ID,
    STATUS_FIELD_ID,
    PRIORITY_DATE_FIELD_ID,
    SCORE_ID
  ]
}

export const ALL_ES_FIELDS          = PATENT_FAMILY_FIELDS.filter(field => field.in_es !== false)
export const ALL_SORTABLE_ES_FIELDS = ALL_ES_FIELDS.filter(field => field.sortable !== false)

export const ALL_ES_FIELD_IDS       = ALL_ES_FIELDS.map(field => field.id)

export const ALL_FIELD_IDS = PATENT_FAMILY_FIELDS.map(field => field.id)

export const ID_TO_FIELD = get_as_map([...PATENT_FAMILY_FIELDS, ...EXTRA_FIELDS, SIMILARITY_SCORE_FIELD, SIMILARITY_SEARCH_CONTROLS_FIELD], 'id')
