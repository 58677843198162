import React, { useState } from 'react'
import { InputWithAutofocus } from './InputWithAutofocus.js'
import { PrimaryButton } from './Button.js'
import Modal from './Modal.js'
import { disable_event_when_enter_pressed } from '../../utils/utils.js'
import { is_enter_key_down, is_escape_key_down } from '../../utils/keyboard_shortcuts/keyboard_utils.js'

const RenamingModal = ({title, original_value, handle_rename, is_valid, on_hide}) => {
  const [value, set_value] = useState(original_value)

  function on_key_up(e) {
    if (is_enter_key_down(e)) {
      e.stopPropagation()
      e.preventDefault()
      if (is_valid(value)) {
        handle_rename(value)
      }
    } else if (is_escape_key_down(e)) {
      e.stopPropagation()
      e.preventDefault()
      on_hide()
    }
  }

  return (
    <Modal
      title={title}
      on_hide={on_hide}
      close_label={'Cancel'}
    >
      <div className='d-flex'>
        <InputWithAutofocus
          value={value}
          on_change={(e) => set_value(e.target.value)}
          on_key_down={/* to avoid unwanted interactions */ disable_event_when_enter_pressed}
          on_key_up={on_key_up}
          invalid={!is_valid(value)}
        />
        <PrimaryButton className='ml-2' disabled={!is_valid(value)} onClick={() => handle_rename(value)}>
          Rename
        </PrimaryButton>
      </div>

    </Modal>
  )
}

export default RenamingModal