import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import TextLink from '../widgets/TextLink.js'
import { Link, withRouter } from 'react-router-dom'
import { DashboardSmallTile } from './DashboardTile.js'
import ErrorBody from '../ErrorBody.js'
import Spinner from '../widgets/Spinner.js'
import { fetch_report_history } from '../../utils/report_history_utils.js'
import { fetch_saved_reports_count } from '../../utils/report_management_utils.js'
import { get_user_project_history } from '../../utils/project_and_versioning_utils'
import { REPORT_FILTER_PARAM } from '../report_management/utils/sort_and_filter_utils.js'
import {PROJECTS_FILTER, SAVED_REPORTS} from '../report_management/model/filters.js'
import { get_recent_history_help_url } from '../../utils/help_utils.js'
import { TileHelpLink } from './TileHelpLink.js'
import { HISTORY } from '../../constants/paths.js'
import { HistoryListDisplay } from './DashboardDisplays.js'
import { withUser } from '../UserContext.js'
import { is_creator } from '../../utils/user_permissions.js'

import s from './RecentHistoryPanel.module.scss'

const MAX_RECENT_HISTORY = 5
const IGNORE_FAILED_REPORTS = true
const FETCH_REPORT_TAGS = false
const EXCLUDE_EVAL_REPORTS = true

const RecentHistoryPanel = ({user}) => {

  const [is_fetching, set_is_fetching] = useState(true)
  const [recent_history, set_recent_history] = useState(null)
  const [fetching_recent_reports_error, set_fetching_recent_reports_error] = useState(null)
  const [show_saved, set_show_saved] = useState(false)
  const [show_projects, set_show_projects] = useState(false)

  // message to display when a user doesn't have any report history
  const new_user_message = is_creator(user) ? 'Create your first report' : 'You don\'t have any report history yet'

  useEffect(() => {
    Promise.all([
      fetch_report_history(MAX_RECENT_HISTORY, IGNORE_FAILED_REPORTS, FETCH_REPORT_TAGS, EXCLUDE_EVAL_REPORTS),
      get_user_project_history(),
      fetch_saved_reports_count()]
    )
      .then(([reports, projects, saved_count]) => {
        const {saved_reports_count=0} = saved_count

        const elements = _.union(reports, projects)
          .sort((a,b) => (a.last_viewed || a.created_at) < (b.last_viewed || b.created_at) ? 1 : -1)
          .slice(0, MAX_RECENT_HISTORY)
        set_recent_history(elements)
        set_show_saved(saved_reports_count > 0)
        set_show_projects(projects.length > 0)
        set_is_fetching(false)
      })
      .catch(error => {
        set_fetching_recent_reports_error(error)
        set_is_fetching(false)
      })
  }, [])

  const is_new_user = !is_fetching && !fetching_recent_reports_error && recent_history && (recent_history.length === 0)

  return (
    <DashboardSmallTile title='Recent history' hint={<TileHelpLink help_url={get_recent_history_help_url()}/>}>
      {is_fetching &&
        <div className='text-center'>
          <Spinner/>
        </div>
      }
      {fetching_recent_reports_error &&
        <ErrorBody
          error={fetching_recent_reports_error}
          context='fetching recent reports'
          show_details_in_modal={true}
        />
      }
      {!is_fetching && !fetching_recent_reports_error &&
        <div className={cn(s.wrapper, 'my-3')}>

          <HistoryListDisplay
            elements={recent_history}
          />

          { is_new_user &&
            <div>{new_user_message}</div>
          }
        </div>
      }
      { !is_fetching && !is_new_user && recent_history &&
        <span>
          <TextLink element={Link} to={HISTORY}>See more</TextLink>
          { show_saved &&
            <span> | <TextLink element={Link} to={`${HISTORY}?${REPORT_FILTER_PARAM}=${SAVED_REPORTS}`}>Go to saved reports</TextLink></span>
          }
          { show_projects &&
            <span> | <TextLink element={Link} to={`${HISTORY}?${REPORT_FILTER_PARAM}=${PROJECTS_FILTER}`}>Go to projects</TextLink></span>
          }
        </span>
      }
    </DashboardSmallTile>
  )
}

export default withUser(withRouter(RecentHistoryPanel))