import React, { useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import BaseDropdown from '../../widgets/BaseDropdown.js'
import { DropdownItem } from 'reactstrap'
import Spinner from '../../widgets/Spinner.js'
import { get_tags_string, replace_tag_in_training_set_infos } from '../utils/tag_utils.js'
import { replace_tags } from '../../../utils/training_set_grpc_utils.js'
import ErrorModal from '../../ErrorModal.js'


const EditTrainingSetSingleValueTagControl = ({
  className,
  buttonClassName,
  labelClassName,
  training_set_id,
  training_set_info,
  training_set_infos,
  set_training_set_infos,
  set_current_training_set_info,
  tag_prefix,
  allowed_tag_values,
  selected_tag_values,
  disabled
}) => {

  const [is_updating, set_is_updating] = useState(false)
  const [error_updating, set_error_updating] = useState(null)

  // should just be one value here but some multivalues may exist from before this was enforced
  // so display the whole thing
  const selected_value = selected_tag_values && selected_tag_values.length ? get_tags_string(selected_tag_values) : null

  function update_tag_value(new_selected_value) {
    const tags_to_remove = selected_tag_values.map(old_tag => tag_prefix + old_tag)
    const tags_to_add = new_selected_value ? [tag_prefix + new_selected_value] : []

    set_is_updating(true)

    replace_tags(training_set_id, tags_to_remove, tags_to_add)
      .catch(err => {
        // FAIL
        set_error_updating(err)
        throw err
      })
      .then(() => {
        // SUCCESS
        const new_training_set_infos = replace_tag_in_training_set_infos(training_set_infos, training_set_id, selected_tag_values, new_selected_value, tag_prefix)
        if (set_current_training_set_info && training_set_info) {
          const updated_ts_tags = _.findWhere(new_training_set_infos, {alias: training_set_id}).tags
          // local ts state may include other fresh updates -- only override tags property
          set_current_training_set_info({...training_set_info, tags: updated_ts_tags})
        }
        set_training_set_infos(new_training_set_infos)
      })
      .finally(() => {
        set_is_updating(false)
      })
  }

  if (is_updating) {
    return (
      <div className={cn('pt-1', buttonClassName)}>
        <Spinner size='sm'/>
      </div>
    )
  }

  return (
    <>
      {error_updating &&
        <ErrorModal
          on_hide={() => set_error_updating(null)}
          error={error_updating}
          context='replacing tag value'
        />
      }
      <BaseDropdown
        labelClassName={labelClassName}
        buttonClassName={buttonClassName}
        className={className}
        label={selected_value}
        disabled={disabled}
        id={'tag_dropdown'}
      >
        {allowed_tag_values.map((tag, idx) => {
          return (
            <DropdownItem
              className={className}
              key={idx}
              toggle={true}
              disabled={tag.value === selected_value}
              onClick={() => update_tag_value(tag.value)}
            >
              {tag.label}
            </DropdownItem>
          )
        })}
      </BaseDropdown>
    </>
  )
}

export default EditTrainingSetSingleValueTagControl