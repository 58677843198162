import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { get_cpc_info } from '../../utils/cpc_utils.js'

import ErrorBody from '../ErrorBody.js'

import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import { Highlighter } from '../widgets/Highlighter.js'

import s from './AllCpcsModal.module.scss'

const AllCpcsModal = ({
  cpc_codes,
  primary,
  highlighter_props,
  on_close
}) => {

  const [is_fetching, set_is_fetching] = useState(true)
  const [error_fetching, set_error_fetching] = useState(null)
  const [cpc_id_to_description, set_cpc_id_to_description] = useState(null)

  const cpc_codes_sorted = _.sortBy(cpc_codes)

  useEffect(() => {
    let cancelled = false

    set_is_fetching(true)
    set_error_fetching(null)
    set_cpc_id_to_description(null)

    get_cpc_info(cpc_codes)
      .then(cpc_id_to_description => {
        if (!cancelled) {
          set_is_fetching(false)
          set_cpc_id_to_description(cpc_id_to_description)
        }
      })
      .catch(err => {
        if (!cancelled) {
          set_is_fetching(false)
          set_error_fetching(err)
      }})

    // Cleanup
    return () => cancelled = true
  }, [cpc_codes])

  return (
    <Modal
      className={cn(s.modal_container)}
      title={`All CPC codes`}
      on_hide={on_close}
    >
      {is_fetching &&
        <Spinner />
      }

      {error_fetching &&
        <ErrorBody
          error={error_fetching}
          context={'fetching cpc codes'}
        />
      }

      {cpc_id_to_description &&
        <div>
          <table className='table table-bordered table-striped table-sm mt-4'>
            <thead>
              <tr>
                <th scope='col'>CPC</th>
                <th scope='col'>Description</th>
              </tr>
            </thead>
            <tbody>
              {cpc_codes_sorted.map((cpc, idx) => {
                const description = cpc_id_to_description[cpc]
                return (
                  <tr
                    key={idx}
                  >
                    <td
                      className={cn(s.cpc_cell)}
                    >
                      <Highlighter
                        {...highlighter_props}
                        text_to_highlight={cpc}
                      />
                      {cpc === primary && <div>(primary)</div>}
                    </td>
                    <td>
                      <Highlighter
                        {...highlighter_props}
                        text_to_highlight={description}
                      />
                    </td>
                  </tr>
                )})}
            </tbody>
          </table>
        </div>
      }

    </Modal>
  )
}

export default AllCpcsModal
