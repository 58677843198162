import React, { useEffect, useState } from 'react'
import {
  DropdownItem,
  DropdownMenu,
  FormFeedback,
  FormGroup, Input,
  InputGroup,
  Label,
  UncontrolledDropdown
} from 'reactstrap'
import { Autocomplete, Chip, TextField } from '@mui/material'
import _ from 'underscore'
import cn from 'classnames'

import { withUser } from '../UserContext.js'
import ConfirmModal from '../ConfirmModal.js'

import {
  Choice, fetch_tag_permissions, is_valid_name,
  Tag,
  TagPermission, TagValue,
  UUIDPermissions,
  UUIDType
} from './family_tag_utils'

import Spinner from '../widgets/Spinner.js'
import { remove_not_allowed_chars_from_text } from '../../utils/name_utils.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import { FamilyTagSharing } from './FamilyTagSharing'
import { InputWithAutofocus } from '../widgets/InputWithAutofocus.js'
import { MAX_FAMILY_TAG_NAME_LENGTH } from '../../constants/constants.js'
import TextLink from '../widgets/TextLink.js'
import { ESC_KEY, ENTER_KEY } from '../../utils/keyboard_shortcuts/keyboard_utils.js'
import { createFilterOptions } from '@mui/material/Autocomplete'

import s from './TagDetailsModal.module.scss'

interface TagDetailsModalProps {
  user: any,
  title:string,
  existing_tag:Tag | null,
  on_submit: Function,
  submit_label:string,
  on_close: Function,
  is_valid_tag_name: Function,
  sections: Array<string>,
  selected_section: string,
  set_selected_section: Function,
  allow_groups_sharing:boolean,
  className: string,
}

interface AutocompleteSectionType {
  inputValue?: string,
  label: string,
}

const filterSectionOptions = createFilterOptions<AutocompleteSectionType>()

const TagDetailsModal = ({user, title, existing_tag, on_close, on_submit, submit_label, is_valid_tag_name, sections,
    selected_section, set_selected_section, allow_groups_sharing, className}: TagDetailsModalProps) => {

  const [first_time_load, set_first_time_load] = useState<boolean>(true)
  const [tag_name, set_tag_name] = useState<string>(existing_tag?.name || '')
  const [new_label, set_new_label] = useState<string>('')

  enum Type {
    CONTROLLED_VOCABULARY = 'Known vocabulary',
    FREE_FORMAT = 'Free format text'
  }

  enum Mode {
    SINGLE = 'Single Choice',
    MULTIPLE = 'Multiple Choice'
  }

  function get_initial_type(){
    if (!existing_tag){
      return Type.CONTROLLED_VOCABULARY
    } else {
      switch(existing_tag.type){
        case Choice.FREE_FORMAT: return Type.FREE_FORMAT
        default: return Type.CONTROLLED_VOCABULARY
      }
    }
  }

  function get_initial_mode(){
    if (existing_tag && existing_tag.type === Choice.MULTI){
      return Mode.MULTIPLE
    } else {
      return Mode.SINGLE
    }
  }

  const [selected_type, set_selected_type] = useState<Type>(get_initial_type())
  const [selected_mode, set_selected_mode] = useState<Mode>(get_initial_mode())
  const [permissions, set_permissions] = useState<Array<UUIDPermissions>>([])
  const [predefined_labels, set_predefined_labels] = useState<Array<string>>(existing_tag?.values.map((value) => value.value) || [])
  const [permissions_error, set_permissions_error] = useState(null)
  const [show_permissions_spinner, set_permissions_spinner] = useState(false)

  const [section_options, set_section_options] = useState<Array<AutocompleteSectionType>>([])
  const [section_input_value, set_section_input_value] = useState<string>(selected_section)

  useEffect(() => {
    const owner_permission = {uuid: user.user_id, name: user.email, level: TagPermission.EDIT, type: UUIDType.USER}
    const tagger_permission = {uuid: user.user_id, name: user.email, level: TagPermission.TAG, type: UUIDType.USER}
    const initial_permissions = []
    if (!existing_tag) {
      initial_permissions.push(owner_permission)
    }
    if (!allow_groups_sharing){
      //means the user is creating their own tags
      initial_permissions.push(tagger_permission)
    }
    set_permissions(initial_permissions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user_id])

  useEffect(() => {
    let did_cancel = false
    if (existing_tag) {
      set_permissions_spinner(true)
      fetch_tag_permissions(existing_tag.id)
        .catch(error => {
          if (!did_cancel) {
            set_permissions_error(error)
            set_permissions_spinner(false)
          }
        })
        .then(tag_permissions => {
          if (!did_cancel && tag_permissions) {
            set_permissions_error(null)
            set_permissions_spinner(false)
            const fetched_permissions = tag_permissions.map((tag_permission:any)=>{
              const permission = {uuid: tag_permission.entity_uuid, name: tag_permission.name, level: tag_permission.permission_id, type: tag_permission.entity_type_id}
              return permission
            })
            set_permissions(fetched_permissions)
          }
        })
    }
    return () => {
        did_cancel = true
      }

  },[existing_tag])

  useEffect(()=>{
    const options = sections.map(section=>({label:section, inputValue:section}))
    set_section_options(options)
  },[sections])

  const is_mode_disabled = selected_type === Type.FREE_FORMAT
  const is_controlled_vocab_tag = selected_type === Type.CONTROLLED_VOCABULARY
  const are_labels_required = is_controlled_vocab_tag && _.isEmpty(predefined_labels) && !first_time_load
  const valid_tag = (existing_tag && first_time_load) || (!are_labels_required && (is_valid_tag_name(selected_section, tag_name) || existing_tag?.name === tag_name ) && !_.isEmpty(permissions))


  return (
    //@ts-expect-error
    <ConfirmModal
      title={title}
      on_cancel={on_close}
      on_confirm={submit_tag}
      confirm_label={submit_label}
      confirm_disabled={!valid_tag}
      className={className}
    >
      <FormGroup>
        <InputGroup className={'p-2 d-flex flex-row'}>
          <Label className={cn('m-2', s.label)}> Name: </Label>
          <span className={s.field}>
            {/*//@ts-expect-error*/}
            <InputWithAutofocus
              value={tag_name}
              on_change={on_change_input}
              invalid={!first_time_load && !is_valid_tag_name(selected_section, tag_name) && (!existing_tag || (existing_tag && tag_name!==existing_tag?.name))}
            />
            <FormFeedback>Tag names must be unique for this section and cannot be longer
              than {MAX_FAMILY_TAG_NAME_LENGTH} characters.</FormFeedback>
          </span>
        </InputGroup>

        <InputGroup className={'p-2 d-flex flex-row'}>
          <Label className={cn('m-2', s.label)}> Section: </Label>
          <Autocomplete
            freeSolo
            handleHomeEndKeys
            clearOnEscape
            clearOnBlur
            blurOnSelect
            classes={{inputRoot:s.field}}
            size={'small'}
            sx={{width:300 }}
            options={section_options}
            value={selected_section}
            onChange={(_e, _v, reason, selection) => {
              if (reason === 'selectOption' && selection){
                const new_section_name = selection.option.inputValue || selection.option.label
                set_selected_section(new_section_name)
                const new_section_options = [...section_options, {label:new_section_name, inputValue: new_section_name}]
                set_section_options(new_section_options)
              }
            }}
            inputValue = {section_input_value}
            onInputChange = {(_,inputValue) => set_section_input_value(inputValue)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.defaultPrevented = true;
              }
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.label;
            }}
            renderInput={(params) => <TextField {...params} label={'Enter new or choose existing'} />}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            filterOptions={(options, params) => {
              const filtered = filterSectionOptions(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.label);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  label: `Add "${inputValue}"`,
                  inputValue,
                });
              }
              return filtered;
            }}
          />
        </InputGroup>

        <InputGroup className={'p-2 d-flex flex-row'}>
          <Label className={cn('m-2', s.label)}> Type: </Label>
          <div className={s.field}>
            <div className={''}>
              <UncontrolledDropdown className={'pb-3'}>
                <SelectDropdownToggleStatic>
                  {selected_type}
                </SelectDropdownToggleStatic>
                <DropdownMenu>
                  {Object.values(Type).map((option, i) => (
                    <DropdownItem key={i} onClick={() => on_click_tag_type(option)}>
                      {option}
                    </DropdownItem>
                  ))
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
              {is_controlled_vocab_tag &&
              <div className={'mb-2'}>
                <div className={'d-flex flex-row mb-2'}>
                <Input
                  value={new_label}
                  onChange={(e:any) => set_new_label(e.target.value)}
                  onKeyUp={on_key_up}
                  invalid={!first_time_load && !is_valid_name(new_label, predefined_labels,null)}
                  className={'w-50'}
                />
                {/*// @ts-expect-error*/}
                <TextLink
                  onClick={add_predefined_label}
                  className={'m-2'}
                  disable={_.isEmpty(new_label) || !is_valid_name(new_label, predefined_labels,null)}
                >
                  <span>Add tag</span>
                </TextLink>
                </div>
                <div>
                  {predefined_labels.map((label, i) =>
                    (
                      <Chip
                        label={label}
                        variant={'outlined'}
                        size={'small'}
                        key={i}
                        className={'m-1'}
                        onDelete={() => remove_predefined_label(i)}
                    />))}
                </div>
              </div>
              }
            </div>
            {are_labels_required && !first_time_load &&
            <div className={cn('d-block', 'invalid-feedback')}>A predefined list of values is required for this
              type of tags</div>
            }
          </div>
        </InputGroup>

        <InputGroup className={'p-2 d-flex flex-row'}>
          <Label className={cn('m-2', s.label)}> Mode: </Label>
          <UncontrolledDropdown className={s.field}>
            <SelectDropdownToggleStatic disabled={is_mode_disabled}>
              {selected_mode}
            </SelectDropdownToggleStatic>
            <DropdownMenu>
              {Object.values(Mode).map((option, i) => (
                <DropdownItem key={i} onClick={() => set_selected_mode(option)}>
                  {option}
                </DropdownItem>
              ))
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </InputGroup>

        <InputGroup className={'p-2 d-flex flex-row'}>
          <Label className={cn('m-2', s.label)}> Permissions: </Label>
          <div className={cn(s.field,'mt-2')}>
            {!permissions_error && show_permissions_spinner &&
            <span className={'d-flex align-items-center'}>
              <Spinner size='sm'/>
              <span>Fetching data ...</span>
            </span>
            }
            {permissions_error && !show_permissions_spinner &&
            <span className={''}>
              {'Error fetching permissions for the tag'}
            </span>
            }
            {!permissions_error && !show_permissions_spinner &&
            <FamilyTagSharing
              user={user}
              permissions={permissions}
              set_permissions={on_change_permissions}
              allow_groups_sharing={allow_groups_sharing}
            />
            }
          </div>
        </InputGroup>
      </FormGroup>


    </ConfirmModal>
  )

  function on_key_up(e:any){
    switch (e.keyCode) {
      case ENTER_KEY:
        e.stopPropagation()
        e.preventDefault()
        if (is_valid_name(new_label, predefined_labels,null)) {
          add_label(new_label.trim())
        }
        break
      case ESC_KEY:
        e.preventDefault()
        on_close()
        break
      default:
        return
    }

  }

  function define_tag_type() {
    let tag_type = Choice.SINGLE
    if (selected_type === Type.FREE_FORMAT)
      tag_type = Choice.FREE_FORMAT
    else if (selected_mode === Mode.MULTIPLE) {
      tag_type = Choice.MULTI
    }
    return tag_type
  }

  function submit_tag() {
    const tag_type:Choice = define_tag_type()
    const labels:Array<TagValue> = check_and_reuse_values()
    on_submit(selected_section, existing_tag? existing_tag.id : -1, tag_name, tag_type, permissions, labels)
  }

  function check_and_reuse_values(){
    const existing_tag_values:Array<TagValue> = existing_tag?.values.filter(tv => _.contains(predefined_labels, tv.value)) || []
    const existing_labels = existing_tag_values.map(tv => tv.value)
    const filtered_to_new_labels:Array<string> = predefined_labels.filter(label => !_.contains(existing_labels,label))
    const new_tag_values:Array<TagValue> = filtered_to_new_labels.map((label) => ({id:-1, value:label, families_count:0}))

    return [...existing_tag_values, ...new_tag_values]
  }

  function on_change_input(event: any) {
    const input = event.target.value
    if (first_time_load) {
      set_first_time_load(false)
    }
    set_tag_name(remove_not_allowed_chars_from_text(input))
  }

  function add_label(name: string) {
    const new_labels = [...predefined_labels,remove_not_allowed_chars_from_text(name)]
    set_predefined_labels(new_labels)
    set_new_label('')
  }

  function remove_predefined_label(i: number) {
    const new_predefined_labels:Array<string> = predefined_labels.filter((_,idx) => idx !==i)
    set_predefined_labels(new_predefined_labels)

  }

  function add_predefined_label() {
    add_label(new_label.trim())
    if (first_time_load) {
      set_first_time_load(false)
    }
  }

  function on_change_permissions(new_permissions:Array<UUIDPermissions> ) {
    set_permissions(new_permissions)
    if (first_time_load) {
      set_first_time_load(false)
    }
  }

  function on_click_tag_type(option: any) {
    set_selected_type(option)
    if (first_time_load) {
      set_first_time_load(false)
    }
  }

}
export default withUser(TagDetailsModal)