import React from 'react'
import moment from 'moment'
import cn from 'classnames'
import _ from 'underscore'

import TextLink from '../widgets/TextLink.js';
import { InfoIcon } from '../widgets/IconSet.js'
import ErrorModal from '../ErrorModal.js'
import Modal from '../widgets/Modal.js'

import { get_tasks } from '../../utils/report_progress_utils.js'
import { format_integer, format_integer_with_comma } from '../../utils/utils.js';

import {
  STATUS_QUEUED,
  STATUS_STARTED,
  STATUS_WAITING,
  STATUS_COMPLETED,
  STATUS_NOT_STARTED,
  STATUS_FAILED
} from '../../model/report_tasks_and_statuses.js'

import s from './ReportProgress.module.scss'


const SECONDS = 'seconds'

const SEGMENT_PADDING_REM = 0.4
const SEGMENTED_BAR_WIDTH_REM = 9

const STATUS_TO_LABEL = {
  [STATUS_QUEUED]: 'QUEUED',
  [STATUS_NOT_STARTED]: 'NOT STARTED',
  [STATUS_WAITING]: 'WAITING',
  [STATUS_STARTED]: 'RUNNING',
  [STATUS_COMPLETED]: 'COMPLETE',
  [STATUS_FAILED]: 'FAILED'
}

function format_start_time(time_iso_string) {
  if (!time_iso_string) {
    return null
  }
  return moment(time_iso_string).fromNow()
}

function get_classifier_remaining_time_string(estimated_duration) {
  if (!estimated_duration || !estimated_duration.secs) {
    return ''
  }

  return moment.duration(estimated_duration.secs * 1000).humanize()
}

function format_duration(task) {
  if (task.total_finished_runtime != null) {
    // for a group of tasks, show their combined, finished runtime if available
    return moment.duration(task.total_finished_runtime, SECONDS).humanize()
  } else if (task.end_time && task.start_time) {
    // if end time available, show time taken
    return moment(task.end_time).from(task.start_time, true)
  } else if (task.status === STATUS_STARTED && task.start_time && task.task_details && task.task_details.estimatedDuration) {
    // for CC tasks, show estimated time remaining
    return `${get_classifier_remaining_time_string(task.task_details.estimatedDuration)} remaining`
  }
  // TODO later: add estimated remaining for other types of tasks when we have the data to estimate
  return null
}

function render_status_or_progress_bar(task) {
  switch (task.status) {
    case STATUS_QUEUED:
    case STATUS_NOT_STARTED:
      return (
        <div className={cn(s.status_bar, s.status_bar_waiting)}>{STATUS_TO_LABEL[task.status]}</div>
      )
    case STATUS_STARTED:
    case STATUS_WAITING:
      return (
        <span>
          { render_task_in_progress(task) }
          { task.details &&
            <TaskDetails
              classifiers_task={task}
            />
          }
        </span>
      )
    case STATUS_COMPLETED:
      return (
        <div className={cn(s.status_bar, s.status_bar_completed)}>{STATUS_TO_LABEL[task.status]}</div>
      )
    case STATUS_FAILED:
      return (
        <div className={cn(s.status_bar, s.status_bar_failed)}>{STATUS_TO_LABEL[task.status]}</div>
      )
    default:
      return null
  }
}

function render_task_in_progress(task) {
  if (task.details && task.details.progress_percent) {
    // task has progress % value: display as progress bar
    return render_progress_bar(task.details.progress_percent)
  } else if (task.task_count && task.task_count > 1) {
    // task consists of multiple sub-tasks: display in segmented bar
    return render_segmented_progress_bar(task)
  }
  return (
    <div className={cn(s.status_bar, (task.status && task.status === STATUS_STARTED ? s.status_bar_active_running : s.status_bar_waiting))}>
      {task.status && STATUS_TO_LABEL[task.status]}
    </div>
  )
}

function render_progress_bar(progress) {
  return (
    <span>
      <div className={cn(s.progress_bar_wrapper, "progress")}>
        <div
          className={cn(s.progress_bar)}
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{width: `${progress}%`}}
        />
      </div>
      <span className={s.progress_desc}>{`${progress || 0}%`}</span>
    </span>
  )
}

function render_segmented_progress_bar(task) {
  const segment_width = (SEGMENTED_BAR_WIDTH_REM - (task.task_count - 1) * SEGMENT_PADDING_REM) / task.task_count
  return (
    <span>
      {_.range(0, task.task_count).map(i => {
        const left_margin = i > 0 ? SEGMENT_PADDING_REM : 0
        const style = {width: `${segment_width}rem`, marginLeft: `${left_margin}rem`}
        const segment_class = segment_classname_by_status(i, task.completed_count, task.status)
        return (
          <div key={i} style={style} className={cn(s.status_bar_segment, segment_class)}/>
        )
      })}
      <span className={s.progress_desc}>{task.completed_count} of {task.task_count} tasks</span>
    </span>
  )
}

function segment_classname_by_status(segment_index, completed_count, task_status) {
  if (segment_index < completed_count) {
    return s.status_bar_active_completed
  } else if (segment_index > completed_count) {
    return s.status_bar_waiting
  }
  return task_status === STATUS_STARTED ? s.status_bar_active_running : s.status_bar_waiting
}

class TaskDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_details: false
    }
    this.toggle_show_details = this.toggle_show_details.bind(this)
  }

  toggle_show_details() {
    this.setState({ show_details: !this.state.show_details })
  }

  render() {
    const { classifiers_task, error } = this.props
    const { show_details } = this.state

    return (
      <span className={s.task_details_icon}>
        <TextLink onClick={this.toggle_show_details}>
          <InfoIcon/>
        </TextLink>
        { show_details && classifiers_task && classifiers_task.details &&
          <Modal
            title={classifiers_task.label}
            on_hide={this.toggle_show_details}
            className={s.classifier_task_metadata_modal}
          >
            <ClassifierTaskMetadata task_details={classifiers_task.details}/>
          </Modal>
        }
        { show_details && error &&
          <ErrorModal error={error} context='building your report' on_hide={this.toggle_show_details}/>
        }
      </span>
    )
  }
}

const ClassifierTaskMetadata = ({task_details}) => {
  // NOTE: There is some stub data for this in /src/stub_data/choreo_progress_classifier.js
  //       The stub data can be helpful for modifying this component.
  //       To use the stub data, in "choreo_utils.js", modify fetch_choreo_status() to return Promise.resolve(choreo_progress_classifier)

  const { num_classifiers, num_positives_found, num_pat_fams, progress_percent, estimated_duration } = task_details

  const num_classified = (num_pat_fams != null && progress_percent != null) ? num_pat_fams * (progress_percent / 100) : 0
  const progress_percent_string = progress_percent ? `${format_integer(progress_percent)}%` : '0%'

  const estimated_duration_string = get_classifier_remaining_time_string(estimated_duration)

  return (
    <table className={cn('table')}>
      <thead className={cn('thead-light')}>
        <tr>
          <th className={'text-center'}># Classifiers</th>
          <th className={'text-center'}>Positives</th>
          <th className={'text-center'}>Number classified / total</th>
          <th className={'text-center'}>Time remaining</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={'text-center'}>{num_classifiers}</td>
          <td className={'text-center'}>{format_integer_with_comma(num_positives_found)}</td>
          <td className={'text-center'}>{format_integer_with_comma(num_classified)} / {format_integer_with_comma(num_pat_fams)} ({progress_percent_string})</td>
          <td className={'text-center'}>{estimated_duration_string}</td>
        </tr>
      </tbody>
    </table>
  )
}

const ReportProgress = ({ choreo_status }) => {
  const tasks = get_tasks(choreo_status)
  const show_tasks = tasks && tasks.length > 0

  return (
    <div>
      {show_tasks &&
        <table className={cn('table', s.progress_table)}>
          <thead>
          <tr>
            <th className={s.task_cell}>Task</th>
            <th className={s.progress_cell}>Progress</th>
            <th>Started</th>
            <th>Duration</th>
          </tr>
          </thead>
          <tbody>
          {tasks.map((task, i) => {
            return (
              <tr key={i}>
                <td className={s.task_cell}>{task.label}</td>
                <td className={s.task_cell}>{render_status_or_progress_bar(task)}</td>
                <td>{format_start_time(task.start_time)}</td>
                <td>{format_duration(task)}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      }
    </div>
  )
}

export default ReportProgress