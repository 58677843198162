import axios from 'axios'

import { CPC_BASE_URL } from '../constants/urls.js'

function get_clean_cpc_id(cpc_id) {
  return cpc_id.replace(/ /g, '')
}

let cached_cpc_id_to_description = {}

export function get_cpc_info(cpc_ids) {

  // If all in cache, use that.
  const all_in_cache = cpc_ids.every(cpc_id => cached_cpc_id_to_description[cpc_id])
  if (all_in_cache) {
    const cpc_id_to_description = cpc_ids.reduce((acc, cpc_id) => ({ ...acc, [cpc_id]: cached_cpc_id_to_description[cpc_id]}), {})
    return Promise.resolve(cpc_id_to_description)
  }

  // Client can show cpc codes with spaces i.e. 'G06Q 40/02'
  // But the server requires without spaces i.e. 'G06Q40/02'
  // So we convert to no-space to make the call, and then convert back to the original.
  const clean_cpc_ids = cpc_ids.map(get_clean_cpc_id)

  return axios.post(`${CPC_BASE_URL}`, { 'ids': clean_cpc_ids })
    .then(response => {

      const clean_cpc_id_to_description = response.data.reduce((result, item) => {
        const {cpc_id, description} = item
        result[cpc_id] = description
        return result
      }, {})

      // Convert back to original
      const cpc_id_to_description = cpc_ids.reduce((acc, cpc_id) => {
        const clean_cpc_id = get_clean_cpc_id(cpc_id)
        const description = clean_cpc_id_to_description[clean_cpc_id]
        return { ...acc, [cpc_id]: description }
      }, {})

      // Add to cache
      cached_cpc_id_to_description = {...cached_cpc_id_to_description, ...cpc_id_to_description}

      return cpc_id_to_description
    })
    .catch(err => {
      throw err
    })
}