import React from 'react'

import { withReportBasket } from './ReportBasketContext.js'
import PatentUpload from '../patent_upload/PatentUpload.js'

const PortfolioUpload = ({ add_to_basket_handler, on_select_org_handler, external_items_for_suggestions, reset_items_for_suggestions_handler, update_group_handler, custom_portfolio_job_id}) => {
  return (
    <PatentUpload
      update_group_handler={update_group_handler}
      parsed_results_action_handler={add_to_basket_handler}
      on_select_org_handler={on_select_org_handler}
      external_items_for_suggestions={external_items_for_suggestions}
      reset_items_for_suggestions_handler={reset_items_for_suggestions_handler}
      job_id={custom_portfolio_job_id}
      is_wizard={false}
      enable_rename={true}
    />
  )
}

export default withReportBasket(PortfolioUpload)