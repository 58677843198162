import React, { Component } from 'react'
import ReactTable from 'react-table'
import _ from 'underscore'
import cn from 'classnames'

import { to_local_datetime, format_string_first_character_capitalised } from '../../../utils/utils.js'
import { is_failed_status } from '../../../utils/report_progress_utils.js'
import { is_admin } from '../../../utils/user_permissions.js'
import { is_IE11 } from '../../../utils/browser_utils.js'

import {
  NAME_FIELD_ID,
  IS_SELECTED_FIELD_ID,
  BUILD_NEW_FIELD_ID,
  RERUN_FIELD_ID,
  OWNER_FIELD_ID,
  CREATED_AT_FIELD_ID,
  LAST_VIEWED_FIELD_ID,
  STATUS_FIELD_ID,
  ACTIONS_FIELD_ID
} from '../model/report_fields.js'

import SortingColumnHeaderCell from '../../patent_family_list/SortingColumnHeaderCell.js'

import CheckboxStatic from '../../widgets/CheckboxStatic.js'
import Spinner from '../../widgets/Spinner.js'
import TextLink from '../../widgets/TextLink.js'
import { RetryIcon } from '../../widgets/IconSet.js'

import s from './ReportManagementTable.module.scss'

const DEFAULT_MIN_TABLE_SIZE = 5

const BROWSER_IS_IE_11 = is_IE11()

function get_width_props(field_id) {
  if (_.contains([IS_SELECTED_FIELD_ID, BUILD_NEW_FIELD_ID, RERUN_FIELD_ID], field_id)) {
    return { maxWidth: 28 }
  }

  if (_.contains([CREATED_AT_FIELD_ID, LAST_VIEWED_FIELD_ID, STATUS_FIELD_ID], field_id)) {
    return { maxWidth: 90 }
  }

  if (_.contains([OWNER_FIELD_ID], field_id)) {
    return { maxWidth: 130 }
  }

  if (_.contains([NAME_FIELD_ID], field_id)) {
    if (BROWSER_IS_IE_11) {
      return { width: 'inherit' } // workaround for IE11 (without this, ReactTable's explicit widths seem to confuse the flex-wrap, and row heights can be really big)
    }
    return { minWidth: 200 }
  }

  if (_.contains([ACTIONS_FIELD_ID], field_id)) {
    return { minWidth: 70, maxWidth: 70 } // workaround for IE
  }

  return {}
}

/**
 * This function will be called by ReactTable, to render the contents of each cell.
 * Would probably be better to pass in individual functions for each Column, but
 * we need a way of binding the handlers so it's convenient to do it in one go here.
 */
function render_cell(
  field_id, custom_render_fn, selected_external_report_ids, selected_project_ids, toggle_selected, user, retry_failed_report, // bind these arguments
  props                                                                                                                    // passed in by ReactTable
) {
  const { value, original: data_row } = props

  const report = data_row.external_report_id ? data_row : null
  const {external_report_id} = report || {}

  const project = !report ? data_row : null

  const is_disabled = (report && external_report_id == null) || (project && project.project_id == null)

  if (custom_render_fn) {
    // Custom render function, so use that.
    return custom_render_fn(data_row)
  }

  if (field_id === IS_SELECTED_FIELD_ID) {
    const is_selected = (report && _.contains(selected_external_report_ids, external_report_id)) || (project && _.contains(selected_project_ids, project.project_id))
    const to_select_arr = report ? [report] : [project]

    return (
      <CheckboxStatic
        is_checked={is_selected}
        onClick={() => toggle_selected(to_select_arr, !is_selected)}
        is_disabled={is_disabled}
      />
    )
  }

  if (field_id === OWNER_FIELD_ID && report) {
    const {owner_name} = report
    // show spinner if we're still waiting for this to be fetched from keycloak
    return (
      <span key={external_report_id}>
        {owner_name ? owner_name : <Spinner size={'sm'}/>}
      </span>
    )
  }

  if (field_id === CREATED_AT_FIELD_ID ) {
    return <span>{to_local_datetime(value)}</span>
  }

  if (field_id === LAST_VIEWED_FIELD_ID ) {
    return <span>{to_local_datetime(value)}</span>
  }

  if (field_id === STATUS_FIELD_ID && report) {
    const { status, created_by_user } = report
    if (!status) {
      // show spinner if we're still waiting for the first status response from the choreo
      return (<Spinner size={'sm'}/>)
    }
    if (is_failed_status(status)) {
      const can_rerun_report = created_by_user || is_admin(user)
      return (
        <span>{format_string_first_character_capitalised(status)}
          { can_rerun_report &&
            <TextLink
              className='ml-1'
              onClick={retry_failed_report.bind(null, report)}
              title='Retry'
            >
              <RetryIcon/>
            </TextLink>
          }
        </span>
      )
    }
    return <span>{format_string_first_character_capitalised(status)}</span>
  }

  if (value == null) {
    return null
  }

  return value // simple value with no formatting
}


class ReportManagementTable extends Component {

  get_columns() {

    const { fields, field_id_to_render_fn={}, field_id_to_width_props={}, field_id_to_className={}, selected_external_report_ids, selected_project_ids, toggle_selected, user, retry_failed_report } = this.props

    return fields.map((field) => {

      const { id } = field

      const custom_render_fn = field_id_to_render_fn[id] // optional (may be null)

      const custom_width_props = field_id_to_width_props[id]
      const width_props = custom_width_props || get_width_props(id)

      const className = field_id_to_className[id]

      // Bind data and handlers to the render cell function (it will be called by ReactTable later)
      const render_cell_fn = render_cell.bind(null, id, custom_render_fn, selected_external_report_ids, selected_project_ids, toggle_selected, user, retry_failed_report)

      return {
        Header: this.render_column_header(field),
        headerClassName: 'no-sort-mark',
        className: cn(className),
        accessor: id,
        Cell: render_cell_fn,
        ...width_props,
        resizable: false,
        sortable: false
      }
    })
  }

  render_column_header(field) {
    const {
      reports,
      selected_sort_field_id,
      selected_sort_direction_id,
      on_change_sort_field_id_and_sort_direction_id
    } = this.props

    const has_results = reports && reports.length > 0

    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
        sort_disabled={!has_results}
      />
    )
  }

  render() {
    const {
      className,
      reports,
      min_rows, no_data_text,
      loading_text, loading, resizable
    } = this.props

    const columns = this.get_columns()

    return (
      <ReactTable
        className={cn('border-0', '-striped', className)}

        manual={true}
        showPagination={false}
        filterable={false}
        sortable={false}

        columns={columns}
        data={reports}

        minRows={min_rows || DEFAULT_MIN_TABLE_SIZE}
        resizable={resizable}
        getTableProps={() => ({className: s.table__inner})}
        getTbodyProps={() => ({className: s.table__inner})}

        noDataText={no_data_text}
        loadingText={loading_text}
        loading={loading}

        defaultSortDesc={true}
      />
    )
  }
}

export default ReportManagementTable