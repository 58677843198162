import React, { useEffect, useState, useRef } from 'react'
import cn from 'classnames'

import { FamilyDetailsTile } from './FamilyDetailsTile.js'
import { Label, LabelInline } from './FamilyDetailsLabel.js'
import { DropdownItem,  Input } from 'reactstrap'
import ScrollableList from '../widgets/ScrollableList.js'
import { PrimaryButton } from '../widgets/Button.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import { TileContent } from './TileContent.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import SearchBar from '../widgets/SearchBar.js'
import { contains, get_as_map } from '../../utils/utils.js'
import { Highlighter } from '../widgets/Highlighter.js'
import {
  get_family_class_suggestion_stats,
  get_family_class_suggestions,
  save_family_class_suggestion
} from '../../utils/utt_utils.js'
import Spinner from '../widgets/Spinner.js'
import TextLink from '../widgets/TextLink.js'

import s from './FamilyClass.module.scss'

const OUTSIDE_TAXONOMY = 'OUTSIDE TAXONOMY'

const FamilyClass = ({family, current_class, available_classes, className}) => {

  const [show_spinner, set_show_spinner] = useState(false)
  const [suggested_class, set_suggested_class] = useState(null)
  const [comment, set_comment] = useState(null)
  const [saved_suggestion, set_saved_suggestion] = useState(null)
  const [search_phrase, set_search_phrase] = useState('')
  const [stats, set_stats] = useState(null)
  const [fetch_stats, set_fetch_stats] = useState(true)
  const [save_error, set_save_error] = useState(null)
  const [fetch_suggestion_error, set_fetch_suggestion_error] = useState(null)
  const [fetch_stats_error, set_fetch_stats_error] = useState(null)
  const [is_class_dropdown_open, set_is_class_dropdown_open] = useState(false)

  const search_input_ref = useRef()

  useEffect(() => {
    if (is_class_dropdown_open) {
      search_input_ref.current.focus()
    }
  }, [is_class_dropdown_open])

  useEffect(() => {
    let did_cancel = false

    set_show_spinner(true)
    set_suggested_class(null)
    set_comment(null)
    set_saved_suggestion(null)
    set_save_error(null)
    set_fetch_suggestion_error(null)
    set_fetch_stats_error(null)
    set_is_class_dropdown_open(false)

    get_family_class_suggestions(family.patFamId)
      .then(suggestions => {
        if (!did_cancel && (suggestions.length > 0)) {
          const suggestions_by_current_class = get_as_map(suggestions, 'current_class')
          const suggestion = suggestions_by_current_class[current_class]
          if (suggestion) {
            set_saved_suggestion(suggestion)
          }
        }
        set_show_spinner(false)
      })
      .catch(error => {
        if (!did_cancel) {
          set_fetch_suggestion_error(error)
          set_show_spinner(false)
        }
      })

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family.patFamId])

  useEffect(() => {
    if (!fetch_stats) return
    set_fetch_stats_error(null)
    let did_cancel = false

    get_family_class_suggestion_stats()
      .then(response => {
        if (!did_cancel) {
          set_stats(response)
          set_fetch_stats(false)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_fetch_stats_error(error)
          set_fetch_stats(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [fetch_stats])

  function on_clear() {
    set_suggested_class(null)
    set_comment(null)
  }

  function on_submit() {
    set_show_spinner(true)
    save_family_class_suggestion({family_id: family.patFamId, current_class, suggested_class, comment})
      .then(() => {
        set_saved_suggestion({suggested_class, comment})
        set_suggested_class(null)
        set_comment(null)
        set_fetch_stats(true)
        set_show_spinner(false)
      })
      .catch(error => {
        set_save_error(error)
        set_show_spinner(false)
      })
  }

  function on_change_from_comment_input(e) {
    const value = e.target.value
    set_comment(value)
    if ((saved_suggestion || {}).suggested_class && !suggested_class) {
      set_suggested_class(saved_suggestion.suggested_class)
    }
  }

  function get_class_options() {

    const search_phrase_clean = search_phrase.toLowerCase()

    const classes_filtered = Object.keys(available_classes).filter(name => {
      if (!search_phrase) {
        return true
      }
      return contains(name.toLowerCase(), search_phrase_clean)
    })

    return [OUTSIDE_TAXONOMY, ...classes_filtered]

  }

  function get_stats() {
    if (!stats || stats.length === 0) {
      return []
    }

    const available_class_names = Object.keys(available_classes).map(name => name.toLowerCase())

    const recently_used_classes = stats.map(item => item.suggested_class).filter(name => ((name !== OUTSIDE_TAXONOMY) && (available_class_names.indexOf(name.toLowerCase()) > -1)))

    return recently_used_classes.slice(0, 5)
  }

  const label_options = get_class_options()

  const { suggested_class: saved_suggested_class, comment:saved_comment } = saved_suggestion || {}

  const recently_used_classes = get_stats()

  return (
    <FamilyDetailsTile
      className={cn(className)}
    >
      <div className='d-flex mb-2'>
        <LabelInline>Current class</LabelInline>
        <TileContent>{current_class}</TileContent>
      </div>

      <div className='block mb-2'>
        <Label className='d-flex flex-wrap'>
          Suggested class
          {suggested_class &&
            <TextLink onClick={on_clear} className={cn(s.label_link, 'ml-2 my-auto')}>[clear]</TextLink>
          }
        </Label>
        <BaseDropdown
          right={true}
          label={suggested_class || saved_suggested_class || 'Select'}
          buttonClassName={cn('w-100', s.button)}
          menuClassName='w-100'
          chevron_left={true}
          disabled={show_spinner}
          on_toggle={(e, is_open) => {
            set_is_class_dropdown_open(is_open)
          }}
        >
          <SearchBar
            className='m-2'
            placeholder={'Search...'}
            search_input_ref={search_input_ref}
            search_input={search_phrase}
            on_change_search_input={set_search_phrase}
            no_button={true}
            no_enter_trigger={true}
            is_search_valid={true}
            autofocus={true}
          />

          <DropdownItem divider/>
          <ScrollableList>
            {label_options.map((item, i) => {
              const is_selected = (item === suggested_class)
              return (
                <DropdownItem key={i} onClick={() => set_suggested_class(item)} className={cn([{[s.selected]: is_selected}, {[s.first_option]: (i === 0)}])}>
                  {<Highlighter search_words={[search_phrase]} text_to_highlight={item} />}
                </DropdownItem>
              )})
            }
          </ScrollableList>
        </BaseDropdown>
      </div>

      {!fetch_stats_error && (recently_used_classes.length > 0) &&
        <div className={cn(s.recent_block, 'mb-2')}>
          {recently_used_classes.map((name, i) => {
            return (
              <div key={i} className='mt-1'>
                <TextLink onClick={() => set_suggested_class(name)}>{name}</TextLink>
              </div>
            )
          })}
        </div>
      }

      {fetch_stats_error &&
        <div className='mb-2'>
          <FormFeedback validation_text={'(Recent class suggestions are not available.)'} />
        </div>
      }

      <div className='mb-2'>
        <Label>Comment</Label>
        <Input
          value={((suggested_class != null) ? comment : saved_comment) || ''}
          onChange={on_change_from_comment_input}
          bsSize='sm'
          disabled={show_spinner}
        />

        {(suggested_class === OUTSIDE_TAXONOMY) && !comment &&
          <FormFeedback validation_text='Please add comment.' />
        }
      </div>

      <div className='d-flex justify-content-between'>
        <div>
          {show_spinner && <Spinner size='xs' />}
          {!show_spinner && save_error && <FormFeedback validation_text='There was a problem when saving the suggestion.' />}
          {!show_spinner && fetch_suggestion_error && <FormFeedback validation_text='There was a problem when fetching saved suggestions.' />}
        </div>
        <PrimaryButton
          size='sm'
          disabled={
            !suggested_class ||
            ((suggested_class === OUTSIDE_TAXONOMY) && !comment) ||
            ((current_class === suggested_class) && !saved_suggested_class)||
            ((saved_suggested_class === suggested_class) && (saved_comment === comment)) ||
            show_spinner
          }
          onClick={on_submit}>Submit</PrimaryButton>
      </div>
    </FamilyDetailsTile>
  )
}

export default FamilyClass