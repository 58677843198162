import React from 'react'
import cn from 'classnames'

import { ID_TO_SUGGESTIONS_STRATEGY, ID_TO_SUGGESTION_STRATEGY_GROUP } from '../model/suggestions_strategies.js'
import Tooltip from '../../widgets/Tooltip.js'

import s from './SuggestionsStrategyName.module.scss'

const SuggestionsStrategyName = ({ strategy_id, strategy_group_id, className }) => {
  const strategy = strategy_group_id ? ID_TO_SUGGESTION_STRATEGY_GROUP[strategy_group_id] : ID_TO_SUGGESTIONS_STRATEGY[strategy_id]
  const { name, description } = strategy
  return (

    <Tooltip
      toggler={(
        <span
          className={cn(s.label, className)}
        >
          {name}
        </span>
      )}
      delay={[100, 50]}
      placement='top'
      is_in_modal={true}
    >
    <div
      className={''}
    >
      {description}
    </div>
  </Tooltip>
  )
}

export default SuggestionsStrategyName