import React from 'react'
import { FamilyPatentsDisplay } from './FamilyPatentsDisplay.js'

export const FamilyPatentsSample = (
  {
    family,
    patent_link_mode_id,
    set_patent_link_mode_id,
    on_show_full_set,
    show_graph_link,
    show_patent_link_mode_switch,
}) => {
  const { patentNumbers, cipherFamilyId } = family
  return (
    <FamilyPatentsDisplay
      title='Patent members'
      patent_list={patentNumbers}

      patent_link_mode_id={patent_link_mode_id}
      set_patent_link_mode_id={set_patent_link_mode_id}

      is_limit={true}

      on_show_full_set={on_show_full_set}
      show_graph_link={show_graph_link != null ? show_graph_link : false}
      show_patent_link_mode_switch={show_patent_link_mode_switch}
      cipher_family_id={cipherFamilyId}
    />
  )
}