import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import CustomUploadPanel from './dashboard_tiles/CustomUploadPanel.js'
import NewNDReportPanel from './dashboard_tiles/NewNDReportPanel.js'
import NewReportPanel from './dashboard_tiles/NewReportPanel.js'
import RecentReportsPanel from './dashboard_tiles/RecentHistoryPanel.js'
import RecentClassifiersPanel from './dashboard_tiles/RecentClassifiersPanel.js'
import SpeedyReportForOrgPanel from './dashboard_tiles/SpeedyReportForOrgPanel.js'
import AlertSubscriptionsPanel from './dashboard_tiles/AlertSubscriptionsPanel.js'
import ClassifierReportPanel from './dashboard_tiles/ClassifierReportPanel.js'
import NewSetTheoryReportPanel from './dashboard_tiles/NewSetTheoryReportPanel.tsx'
import UTTLandscapeReportPanel from './dashboard_tiles/UTTLandscapeReportPanel.js'

import Grid from './widgets/Grid.js'
import GridElement from './widgets/GridElement.js'
import { Pane } from './widgets/Block.js'
import { ExternalTextLink } from './widgets/ExternalTextLink.js'
import { withUser } from './UserContext.js'
import {
  is_creator,
  has_classifiers_edit,
  has_cipher_product,
  is_poweruser,
  has_set_theory_service,
  has_any_cipher_product,
  has_utt,
  has_hs_google_valuation,
  has_quick_org_search,
  is_view_only_user
} from '../utils/user_permissions.js'

import {PRODUCT_ND} from '../constants/cipher_product_ids.js'

//welcome tour tags that work as element classes 
import {
  WELCOME_TAG,
  NEW_REPORT_TAG,
  QUICK_ORGANISATION_SEARCH_TAG,
  CUSTOM_CLASSIFIERS_TAG,
  ALERT_SUBSCRIPTIONS_TAG,
  N_D_TAG,
  CUSTOM_UPLOAD_TAG,
  CLASSIFIER_REPORT_TAG,
  SET_THEORY_TAG,
  UTT_LANDSCAPE_REPORT_TAG,
  GOOGLE_VALUATION_TAG,
  MULTI_SEARCH_TAG
} from '../model/joyride_intro_tour.js'
import { track_visit_event } from '../utils/tracking_utils.js'
import { fetch_classifiers } from '../utils/training_set_grpc_utils.js'
import NewGoogleValuationReportPanel from './dashboard_tiles/NewGoogleValuationReportPanel.js'
import MultiSearchPanel from './dashboard_tiles/MultiSearchPanel.js'
import WelcomeTour from './WelcomeTour.js'

import s from './Dashboard.module.scss'

const Dashboard = ({user}) => {
  const [user_has_private_classifiers, set_user_has_private_classifiers] = useState(false)

  useEffect(() => {
    document.title = 'Cipher: Home'
    track_visit_event('page="build-report"')
    fetch_classifiers()
      .then(built_classifiers => {
        set_user_has_private_classifiers(built_classifiers.length > 0)
      })
  }, [])

  const user_has_classifiers = user_has_private_classifiers || has_any_cipher_product(user)
  const user_has_utt = has_utt(user)

  return (
    <div className={s.block}>

      <WelcomeTour />

      { !is_creator(user) &&  //show upsell blurb to Lite users
        <Pane className='mb-3'>
          This is a Cipher {is_view_only_user(user) ? "'view only'" : 'Lite'} account with limited functionality.
          If you would like to know more about the capabilities of Cipher, or would like to upgrade,
          please contact your Administrator or <ExternalTextLink url='http://cipher.ai/contact/' target='_blank' title='contact us'>contact us</ExternalTextLink>
        </Pane>
      }

      { !is_view_only_user(user) &&
        <div
          className={MULTI_SEARCH_TAG} // tag for welcome tour
        >
          <MultiSearchPanel />
        </div>
      }

      <Grid
        className={cn('mt-3', WELCOME_TAG)} // tag for welcome tour
      >

        { is_creator(user) &&
          <GridElement
            className={NEW_REPORT_TAG} // tag for welcome tour
          >
            <NewReportPanel
              user_has_classifiers={user_has_classifiers}
            />
          </GridElement>
        }

        { has_quick_org_search(user) && !is_view_only_user(user) &&
          <GridElement
            className={QUICK_ORGANISATION_SEARCH_TAG} // tag for welcome tour
          >
            <SpeedyReportForOrgPanel/>
          </GridElement>
        }

        { is_creator(user) && (user_has_classifiers) &&
          <GridElement
            className={CLASSIFIER_REPORT_TAG} // tag for welcome tour
          >
            <ClassifierReportPanel user={user} />
          </GridElement>
        }

        { is_creator(user) && user_has_utt &&
          <GridElement
            className={UTT_LANDSCAPE_REPORT_TAG} // tag for welcome tour
          >
            <UTTLandscapeReportPanel user={user} />
          </GridElement>
        }

        <GridElement>
          <RecentReportsPanel />
        </GridElement>

        { is_creator(user) && has_classifiers_edit(user) &&
          <GridElement
            className={CUSTOM_CLASSIFIERS_TAG} // tag for welcome tour
          >
            <RecentClassifiersPanel />
          </GridElement>
        }

        <GridElement
          className={ALERT_SUBSCRIPTIONS_TAG} // tag for welcome tour
        >
          <AlertSubscriptionsPanel />
        </GridElement>

        { has_cipher_product(user, PRODUCT_ND) && !is_view_only_user(user) &&
          <GridElement
            className={N_D_TAG} // tag for welcome tour
          >
            <NewNDReportPanel />
          </GridElement>
        }

        { is_creator(user) && has_hs_google_valuation(user) &&
          <GridElement
            className={GOOGLE_VALUATION_TAG} // tag for welcome tour
          >
            <NewGoogleValuationReportPanel />
          </GridElement>
        }

        { is_creator(user) && is_poweruser(user) &&
          <GridElement
            className={CUSTOM_UPLOAD_TAG} // tag for welcome tour
          >
            <CustomUploadPanel />
          </GridElement>
        }

        { is_creator(user) && has_set_theory_service(user) &&
          <GridElement
            className={SET_THEORY_TAG}
          >
            <NewSetTheoryReportPanel />
          </GridElement>
        }

      </Grid>
    </div>
  )
}

export default withUser(Dashboard)