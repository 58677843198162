import React from 'react'
import cn from 'classnames'

import { FormFeedback as RSFormFeedback } from 'reactstrap'

import s from './FormFeedback.module.scss'

export const FormFeedback = ({valid, validation_text, ...rest}) => {
  return (
    <RSFormFeedback
      valid={valid}
      className={cn([{[s.form_feedback__visible]: !valid}])}
      {...rest}
    >
      {validation_text}
    </RSFormFeedback>
  )
}