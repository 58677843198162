import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'

import s from './OrgSearchModeWrapper.module.scss'

const OrgSearchModeWrapper = ({search_mode, on_search_mode_change, children}) => {
  return (
    <div className={cn('mb-1 d-flex flex-column flex-sm-row justify-content-between', s.block)}>
      <div className={cn('p-1 flex-grow-1 my-auto order-1 order-sm-0 h-100 d-flex', s.custom)}>
        {children}
      </div>

      {on_search_mode_change &&
        <TextLink onClick={on_search_mode_change} className='ml-2 my-auto order-0 order-sm-1'>Switch to {search_mode === 'single' ? 'multi' : 'single'} organisation search</TextLink>
      }
    </div>
  )
}

export default OrgSearchModeWrapper