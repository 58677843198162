import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import CheckboxGroupHeader from '../viewer/CheckboxGroupHeader.js'
import ScrollableList from '../widgets/ScrollableList.js'
import CheckboxTree from '../widgets/CheckboxTree.js'
import BaseDropdown from '../widgets/BaseDropdown'
import CheckboxStatic from '../widgets/CheckboxStatic'

import { get_tag_value_ids, Tag } from './family_tag_utils'
import Spinner from '../widgets/Spinner'

import s from './FamilyTagsFilter.module.scss'

interface FamilyTagsFilterProps {
  user: any,
  className: string,
  viewable_tags: Array<Tag>,
  on_filter_change: Function,
  selected_tags: Array<number>,
  apply_filter: boolean,
  set_apply_filter: Function,
  tags_error: any,
  show_spinner: boolean,
}

const FamilyTagsFilter = (props: FamilyTagsFilterProps) => {

  const [search_phrase, set_search_phrase] = useState<string>('')
  const [all_tags, set_all_tags] = useState<Array<Tag>>([])

  const [checked_tags, set_checked_tags] = useState<Array<number>>([])
  const [expanded_tags, set_expanded_tags] = useState<Array<number>>([])

  useEffect(() => {
    let did_cancel = false
    if (!did_cancel) {
      set_all_tags(props.viewable_tags || [])
    }
    return () => {
      did_cancel = true
    }
  }, [props.viewable_tags])

  useEffect(() => {
    let did_cancel = false
    if (!did_cancel) {
      set_checked_tags(props.selected_tags)
    }
    return () => {
      did_cancel = true
    }
  }, [props.selected_tags])

  function on_check_node(new_checked_tags: Array<string>) {
    const new_selection = new_checked_tags.map(id => parseInt(id))
    set_checked_tags(new_selection)
    props.on_filter_change(new_selection)
  }

  function on_expand_node(new_expanded_tags: Array<string>) {
    const new_selection = new_expanded_tags.map(id => parseInt(id))
    set_expanded_tags(new_selection)
  }

  function select_all_tags() {
    const all_tag_value_ids = get_tag_value_ids(all_tags)
    set_checked_tags(all_tag_value_ids)
    props.on_filter_change(all_tag_value_ids)//no restrictions, to include patfams with tags the user don't have access to
  }

  function deselect_all_tags() {
    const new_selection: Array<number> = []
    set_checked_tags(new_selection)
    props.on_filter_change(new_selection)
  }

  function on_selected_filtered_ids(filtered_tag_ids: Array<number>) {
    set_checked_tags(filtered_tag_ids)
    props.on_filter_change(filtered_tag_ids)
  }


  function get_filtered_tags() {
    const search_phrase_lower_case = search_phrase.toLowerCase()
    const searched_tags = _.isEmpty(search_phrase) ? all_tags : all_tags.map(tag => {
      const any_tag_name_match = tag.name.toLowerCase().indexOf(search_phrase_lower_case) !== -1
      const matched_tag_values = tag.values.filter(tag_value => tag_value.value.toLowerCase().indexOf(search_phrase_lower_case) !== -1)
      if (any_tag_name_match) {
        // the phrase matches the name of the tag (parent)
        return tag
      }
      //the phrase matches the name of the tag value or none
      return matched_tag_values.length > 0 ? {...tag, values: matched_tag_values} : {...tag, id: -1}
    })
    return searched_tags.filter(tag => tag.id !== -1)
  }

  function get_tags_as_nodes(filtered_tags: Array<Tag>) {
    return filtered_tags.map((tag) => {
      const tag_values_as_nodes: Array<any> = tag.values.map(tag_value => {
        return {value: tag_value.id, label: tag_value.value}
      })
      return {value: -tag.id, label: tag.name, children: tag_values_as_nodes}
    })
  }

  function select_deselect_filter() {
    const new_apply_filter = !props.apply_filter
    if (new_apply_filter === false) {
      //list all patfams in the list with no restrictions
      props.on_filter_change([], [])
    } else {
      props.on_filter_change(checked_tags)
    }
    props.set_apply_filter(new_apply_filter)
  }

  function render(): JSX.Element {
    const filtered_tags: Array<Tag> = get_filtered_tags()
    const tags_as_nodes = get_tags_as_nodes(filtered_tags)
    const is_filtered = (all_tags && filtered_tags) ? all_tags.length !== filtered_tags.length : false
    const num_filtered_results = filtered_tags ? filtered_tags.length : 0
    const filtered_tags_as_items = _.flatten(filtered_tags.map((tag: Tag) => tag.values))
    const all_tags_as_items = _.flatten(all_tags.map((tag: Tag) => tag.values))

    return (
      <span className={cn('d-flex', 'align-items-center', props.className)}>
      <div className={s.label}>
        Family Tags
        {props.tags_error && !props.show_spinner &&
        <span className={s.error_message}>: Fetching error </span>
        }
      </div>
        {!props.tags_error &&
        <BaseDropdown
          label={(!props.apply_filter) ? 'No filter' : (all_tags_as_items.length === checked_tags.length) ? 'All' : 'selected (' + checked_tags.length + ')'}
          className={cn('ml-1', s.dropdown)}
          menuClassName={cn(s.dropdown_content)}
        >
          <div className={cn('px-2', 'mb-1', 'pt-2', s.dropdown_content)}>
            {props.show_spinner &&
            <Spinner size={'sm'} />
            }
            {!props.show_spinner &&
            <div>
              <div className={'mb-1'}>
                <CheckboxStatic
                  className={''}
                  onClick={select_deselect_filter}
                  is_checked={props.apply_filter}
                  is_partial={false}
                  is_disabled={all_tags.length === 0}
                  title={'Filter by tags'}
                />
                <span onClick={select_deselect_filter}  className={all_tags.length === 0 ? s.disabled : ''}> Filter by tags </span>
              </div>
              {all_tags.length === 0 &&
              <span>No family tags</span>
              }
              {all_tags.length > 0 &&
              <div className={!props.apply_filter ? s.disabled : ''}>
                {/*//@ts-expect-error*/}
                <CheckboxGroupHeader
                  id_key={'id'}
                  page_items={filtered_tags}
                  filtered_items={filtered_tags_as_items}
                  all_items={all_tags_as_items}
                  selected_item_ids={checked_tags}
                  is_filtered={is_filtered}
                  num_filtered_results={num_filtered_results}
                  num_selectable_items={all_tags.length}
                  select_all={select_all_tags}
                  deselect_all={deselect_all_tags}
                  search_phrase={search_phrase || ''}
                  set_selected_item_ids={on_selected_filtered_ids}
                  handle_change_search_phrase={set_search_phrase}
                  no_options_dropdown={true}
                />

                <ScrollableList className={s.checkbox_tree}>
                  {/*//@ts-expect-error*/}
                  <CheckboxTree
                    nodes={tags_as_nodes}
                    checked={checked_tags}
                    expanded={expanded_tags}
                    on_check={on_check_node}
                    on_expand={on_expand_node}
                    show_expand_all={true}
                  />
                </ScrollableList>
              </div>
              }
            </div>
            }
          </div>
        </BaseDropdown>
        }
    </span>
    )
  }

  return render()
}

export default FamilyTagsFilter