import React, { useState } from 'react'

import { Table } from '../widgets/Table.js'
import {
  get_portfolio_ids_for_cross_disputes_columns,
  PAGING_THRESHOLD
} from '../../utils/disputes_utils.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../model/disputes_table_page_sizes.js'
import { PaginationControls } from '../widgets/Pagination.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { sort_table_data } from '../../utils/item_utils.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { get_as_map } from '../../utils/utils.js'
import { PORTFOLIO_NAME_FIELD_ID } from '../../model/disputes.js'

import s from './CrossDisputesSummaryTable.module.scss'

export const CrossDisputesSummaryTable = ({disputes, summary, deref_data, is_expanded, toggle_is_expanded, selected_sort_field_id,
                                            selected_sort_direction_id, handle_change_sort_field_and_direction_id}) => {
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(DEFAULT_PAGE_SIZE)

  function set_selected_page_size(selected_page_size) {
    set_page_size(selected_page_size)
    set_current_page(0)
  }

  function set_current_page(current_page) {
    set_page(current_page)
  }

  function on_change_sort_field_and_direction_id(sort_field_id, sort_direction_id) {
    handle_change_sort_field_and_direction_id(sort_field_id, sort_direction_id)
  }

  function get_sorted_paginated_table_data() {
    const sorted_data = sort_table_data(summary || [], selected_sort_field_id, selected_sort_direction_id)

    if (!data_needs_pagination) return sorted_data

    const first_row_idx = page * page_size
    const last_row_idx = ( page * page_size) + page_size

    return sorted_data.slice(first_row_idx, last_row_idx)
  }

  function get_columns() {
    if (!summary) return []

    const {selected_portfolios} = deref_data || {}
    const selected_portfolios_to_portfolio_id = get_as_map(selected_portfolios || '', 'portfolio_id')

    const filtered_portfolio_ids = get_portfolio_ids_for_cross_disputes_columns({summary, disputes, is_expanded})
    const organisation_columns = filtered_portfolio_ids.map(id => (
      {
        ...TABLE_COLUMN_HEADER,
        id,
        label: (selected_portfolios_to_portfolio_id[id] || {}).name,
        field: id,
        className: 'text-center'
      }
    ))

    const table_columns = [
      {
        ...TABLE_COLUMN_HEADER,
        id: PORTFOLIO_NAME_FIELD_ID,
        label: '',
        field: PORTFOLIO_NAME_FIELD_ID
      },
      ...organisation_columns
    ]

    const columns_with_sort = table_columns.map(column => {
      const {id, label} = column

      return {
        ...column,
        sortable: false,
        header: function get_header() {
          return (
            <SortingColumnHeaderCell
              field={{id, label, name: label}}
              on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_and_direction_id}
              selected_sort_field_id={selected_sort_field_id}
              selected_sort_direction_id={selected_sort_direction_id}
            />
          )},
      }
    })

    return columns_with_sort
  }

  const table_columns = get_columns()
  const total_data_rows = (summary || []).length
  const num_pages = Math.ceil(total_data_rows / page_size)

  const data_needs_pagination = total_data_rows > PAGING_THRESHOLD

  if ((page >= num_pages) && data_needs_pagination) {
    set_current_page(0)
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>

        <CheckboxAndLabel
          label='Expanded'
          is_checked={is_expanded}
          on_click={toggle_is_expanded}
        />

        {data_needs_pagination &&
          <PaginationControls
            className={'ml-auto'}
            num_pages={num_pages}
            selected_page_size={page_size}
            on_change_page_size={set_selected_page_size}
            current_page={page}
            on_change_current_page={set_current_page}
            available_page_sizes={PAGE_SIZES}
          />
        }
      </div>

      <div>
        <div className={s.column_caption}>Defendants</div>
        <div className='d-flex'>
          <div className={s.row_caption}>Plaintiffs</div>
          <Table
            columns={table_columns}
            data={get_sorted_paginated_table_data()}
            className='w-100'
          />
        </div>
      </div>
    </div>
  )
}