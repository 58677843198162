import React, { useState } from 'react'
import cn from 'classnames'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

import ContainerFullWidth from '../../ContainerFullWidth.js'
import { is_tech_insights } from '../../../utils/user_permissions.js'
import { Logo } from '../../Logo.js'
import { withUser } from '../../UserContext.js'
import PageControl from '../../PageControl.js'
import { CogIcon, UndoIcon } from '../../widgets/IconSet.js'
import ConfirmModal from '../../ConfirmModal.js'
import { REPORT_REGION_GROUPING_OPTIONS } from '../../../constants/regions.js'
import { RadiobuttonWithLabel } from '../../widgets/RadiobuttonWithLabel.js'
import RenameReportModal from '../../report_management/components/RenameReportModal.js'
import {
  LANDING_PAGE_ID,
  ORG_LISTS_KEY_DATA_PARAM_NAME,
  TECH_AREAS_KEY_DATA_PARAM_NAME
} from '../../../constants/report_deck.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'

import s from './Footer.module.scss'
const Footer = (
  {
    user,
    deck_type,
    selections,
    report_title,
    created_by_user,
    report_deck,
    current_page,
    set_current_page,
    on_region_grouping_change,
    on_deck_reset_to_default,
    on_report_name_change,
    on_show_deck_key_data,
    disable
  }
) => {
  const [show_reset_changes_modal, set_show_reset_changes_modal] = useState(false)
  const [show_rename_modal, set_show_rename_modal] = useState(false)

  const pages = [LANDING_PAGE_ID, ...report_deck.page_ids_for_main_nav]
  const root_page_id = (report_deck.get_page_by_id(current_page) || {}).root_page_id
  const back_button_page = report_deck.get_page_by_id(root_page_id)

  function change_page(new_page_idx) {
    const page_id = pages[new_page_idx]
    track_deck_viewer_event(`obj="report" action="navigate" page="${page_id}" context="pager" deck_type="${deck_type}"`)
    set_current_page(page_id)
  }

  function back_to_root_page() {
    track_deck_viewer_event(`obj="report" action="back_to_root_page" page="${root_page_id}" context="footer" deck_type="${deck_type}"`)
    set_current_page(root_page_id)
  }

  function show_key_data(key_data_type) {
    track_deck_viewer_event(`obj="report" action="show_key_data" key_data_type="${key_data_type}" context="menu" deck_type="${deck_type}"`)
    on_show_deck_key_data(key_data_type)
  }

  function on_reset_changes() {
    on_deck_reset_to_default()
    set_show_reset_changes_modal(false)
  }

  const { region_grouping } = selections || {}
  const show_paging = !disable && current_page && (pages.indexOf(current_page) > -1) && !back_button_page

  return (
    <ContainerFullWidth className={cn('d-flex justify-content-between', s.footer)}>
      <div className={cn('pt-1', s.logo_link)}>
        <Logo
          is_group_tech_insights={is_tech_insights(user)}
          title='Cipher'
          small_logo
        />
      </div>

      {back_button_page &&
        <div className={cn('d-flex justify-content-center', s.back_button)} onClick={back_to_root_page}>
          <span className={s.back_button_logo}>
            <UndoIcon/>
          </span>
          {back_button_page.title}
        </div>
      }

      {show_paging &&
        <div className='d-flex justify-content-center flex-grow-1'>
          <PageControl
            className={s.pager}
            buttonClassName={s.pager_button}
            current_page={pages.indexOf(current_page)}
            num_pages={pages.length}
            on_change_current_page={change_page}
          />
        </div>
      }

      <UncontrolledDropdown className='my-auto'>
        <DropdownToggle
          tag='div'
          disabled={disable}
        >
          <div className={cn([s.settings_toggle, {[s.settings_toggle__disabled]: disable}])}><CogIcon /></div>
        </DropdownToggle>
        <DropdownMenu
          container='body' // ensure renders outside of parent div, overcomes overflow hidden problems
        >
          <DropdownItem onClick={() => show_key_data(ORG_LISTS_KEY_DATA_PARAM_NAME)}>Show org list members</DropdownItem>
          <DropdownItem onClick={() => show_key_data(TECH_AREAS_KEY_DATA_PARAM_NAME)}>Show tech area classifiers</DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Region Grouping</DropdownItem>
          {REPORT_REGION_GROUPING_OPTIONS.map((item, i) => {
            const {id, label} =item

            return (
              <DropdownItem key={i} tag='div' toggle={false}>
                <RadiobuttonWithLabel
                  is_checked={id === region_grouping}
                  label={label}
                  on_click={() => on_region_grouping_change(id)}
                />
              </DropdownItem>
            )
          })}

          <DropdownItem divider />

          {created_by_user &&
            <DropdownItem onClick={() => set_show_rename_modal(true)}>Rename report</DropdownItem>
          }
          <DropdownItem onClick={() => set_show_reset_changes_modal(true)}>Reset to defaults</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      {show_reset_changes_modal &&
        <ConfirmModal
          confirm_label='Confirm'
          on_confirm={on_reset_changes}
          on_cancel={() => set_show_reset_changes_modal(false)}
        >
          Are you sure you want to reset all filters and labels to their default settings?
        </ConfirmModal>
      }

      {show_rename_modal &&
        <RenameReportModal
          on_close={() => set_show_rename_modal(false)}
          report_name={report_title}
          rename_report_handler={on_report_name_change}
        />
      }

    </ContainerFullWidth>
  )
}

export default withUser(Footer)