import React, { useState, useEffect, useContext } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router'

import { get_project } from '../../utils/project_and_versioning_utils'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { get_long_date_string } from '../../utils/time_utils.js'
import { get_pathname_parts } from '../../utils/viewer_utils.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import OnlineDetector from '../OnlineDetector.js'
import { ProjectIcon } from '../widgets/IconSet.js'
import { set_sticky_top } from '../../utils/sticky_utils.js'
import { Logo } from '../Logo.js'
import { is_project_url } from '../../utils/report_url_utils.js'
import { SecondaryButton } from '../widgets/Button.js'
import HeaderControls from './HeaderControls.js'
import { UserSettingsContext, UserSettingsDispatchContext } from '../UserSettingsContext.js'
import UserSettingsModal from '../viewer/UserSettingsModal.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import {
  NOTIFY_BEFORE_REPORT_EXPIRY,
  USER_CHARTS_IN_THUMBNAILS,
  USER_DEFAULT_CHART_SELECTION,
  USER_DEFAULT_UTT_VERSION,
  USER_INCLUDE_UNRELATED_TECHS,
  USER_LANGUAGE_PREFERENCE,
  USER_REGION_GROUPING,
  USER_SAVE_KNN_SEARCHES,
  USER_SHOW_UNGROUPED_FAMILIES,
  USER_STARTING_PAGE
} from '../../model/user_settings.js'
import { get_custom_chart_sets } from '../../utils/main_items_selection_utils.js'
import ErrorModal from '../ErrorModal.js'
import { is_tech_explorer_url } from '../../utils/knn_search.js'
import CipherSwitch from './CipherSwitch.js'

import s from './Header.module.scss'

// NOTE: adds global css to bootstrap.override.scss

const Header = ({ no_controls, location }) => {
  const {pathname} = location
  const is_project_page = is_project_url(pathname)
  const is_tech_explorer_page = is_tech_explorer_url(pathname)

  const { user_settings, user_group_settings } = useContext(UserSettingsContext) || {}
  const { save_user_settings_handler, reset_user_settings_handler } = useContext(UserSettingsDispatchContext) || {}

  const [project, set_project] = useState(null)
  const [is_user_settings_modal_open, set_is_user_settings_modal_open] = useState(false)
  const [error_updating_user_settings, set_error_updating_user_settings] = useState(null)

  useEffect(() => {
    set_sticky_top('.' + s.header_container)
  }, [])

  useEffect(() => {
    if (is_project_page) {
      const parts = get_pathname_parts(pathname)
      get_project(parts[1])
        .then(project => set_project(project))
        .catch(error => send_error_to_sentry(error, {}))
    } else {
      set_project(null)
    }
  }, [location, is_project_page, pathname])

  function change_region_grouping(grouping) {
    track_report_viewer_event(`obj="user_settings" action="change_user_regions" grouping="${grouping}"`)
    save_user_setting_by_key_and_value(USER_REGION_GROUPING, grouping, {fetch_all_datasets: true})
  }

  function change_should_show_ungrouped_families(should_show_ungrouped_families) {
    track_report_viewer_event(`obj="user_settings" action="show_ungrouped_families" show="${should_show_ungrouped_families}"`)
    save_user_setting_by_key_and_value(USER_SHOW_UNGROUPED_FAMILIES, should_show_ungrouped_families)
  }

  function change_default_chart_selection(default_chart_selection) {
    track_report_viewer_event(`obj="user_settings" action="change_default_chart_selection" chart_selection="${default_chart_selection}"`)
    save_user_setting_by_key_and_value(USER_DEFAULT_CHART_SELECTION, default_chart_selection)
  }

  function change_default_utt_version(default_utt_version) {
    track_report_viewer_event(`obj="user_settings" action="change_default_utt_version" utt_version="${default_utt_version}"`)
    save_user_setting_by_key_and_value(USER_DEFAULT_UTT_VERSION, default_utt_version)
  }

  function change_thumbnails(show_charts) {
    track_report_viewer_event(`obj="user_settings" action="change_user_thumbnails" show_charts="${show_charts}"`)
    save_user_settings_handler(USER_CHARTS_IN_THUMBNAILS, show_charts)
  }

  function change_starting_page(starting_page) {
    track_report_viewer_event(`obj="user_settings" action="change_user_starting_page" starting_page="${starting_page}"`)
    save_user_setting_by_key_and_value(USER_STARTING_PAGE, starting_page)
  }

  function change_should_include_unrelated_techs(include_unrelated) {
    track_report_viewer_event(`obj="user_settings" action="change_should_include_unrelated_techs" include_unrelated="${include_unrelated}"`)
    save_user_setting_by_key_and_value(USER_INCLUDE_UNRELATED_TECHS, include_unrelated)
  }

  function toggle_notify_before_report_expiry(should_notify) {
    track_report_viewer_event(`obj="user_settings" action="change_${NOTIFY_BEFORE_REPORT_EXPIRY}" should_notify="${should_notify}"`)
    save_user_setting_by_key_and_value(NOTIFY_BEFORE_REPORT_EXPIRY, should_notify)
  }

  function change_language_preference(language_preference) {
    track_report_viewer_event(`obj="user_settings" action="change_${USER_LANGUAGE_PREFERENCE}" language="${language_preference}"`)

    save_user_setting_by_key_and_value(USER_LANGUAGE_PREFERENCE, language_preference)
  }

  function change_save_knn_searches(save_knn_searches) {
    track_report_viewer_event(`obj="user_settings" action="change_${USER_SAVE_KNN_SEARCHES}" save_knn_searches="${save_knn_searches}"`)

    save_user_setting_by_key_and_value(USER_SAVE_KNN_SEARCHES, save_knn_searches)
  }

  function save_user_setting_by_key_and_value(key, value) {
    save_user_settings_handler(key, value)
      .catch(err => {
        set_error_updating_user_settings(err)
        throw err
      })
  }

  return (
    <ContainerFullWidth className={cn('sticky-top pr-0', s.header_container)}>

      <OnlineDetector/>

      <div className={cn('d-flex p-0', s.header_navbar)}>
        <Logo
          small_logo={is_project_page && project}
          is_tech_explorer_page={is_tech_explorer_page}
        />
        {project &&
          <div className={cn(s.title, 'my-auto ml-1 pr-3')}>
            <div className={cn('d-flex', s.created_at)}>{get_long_date_string(project.created_at)}</div>
            <span className='mr-2'><ProjectIcon /></span> {project.name}
          </div>
        }
        <div className='d-flex ml-auto'>
          <CipherSwitch />

          {!no_controls &&
            <HeaderControls
              include_report_controls={false}
              show_user_settings_handler={() => set_is_user_settings_modal_open(true)}
            />
          }
        </div>
      </div>

      {is_user_settings_modal_open &&
        <UserSettingsModal
          on_hide={() => set_is_user_settings_modal_open(false)}
          user_settings={user_settings}
          user_chart_sets={get_custom_chart_sets(user_settings, user_group_settings)}
          change_region_grouping_handler={change_region_grouping}
          change_thumbnails_handler={change_thumbnails}
          change_should_show_ungrouped_families_handler={change_should_show_ungrouped_families}
          change_starting_page_handler={change_starting_page}
          change_default_chart_selection_handler={change_default_chart_selection}
          change_default_utt_version_handler={change_default_utt_version}
          change_should_include_unrelated_techs_handler={change_should_include_unrelated_techs}
          toggle_notify_before_report_expiry_handler={toggle_notify_before_report_expiry}
          change_language_preference_handler={change_language_preference}
          change_save_knn_searches_handler={change_save_knn_searches}
          reset_settings_handler={reset_user_settings_handler}
        />
      }

      {error_updating_user_settings &&
        <ErrorModal
          on_hide={() => set_error_updating_user_settings(null)}
          error={error_updating_user_settings}
          context={'updating user settings'}
        />
      }

    </ContainerFullWidth>
  )
}

export const HeaderNoAuth = ({on_login}) => {
  return (
    <ContainerFullWidth className={cn('sticky-top', s.header_container)}>
      <OnlineDetector/>

      <div className={cn('d-flex justify-content-between', s.header_navbar)}>
        <Logo link_to='https://cipher.ai/'/>

        <SecondaryButton outline={true} onClick={on_login} className={cn('my-auto', s.login_btn)}>
          Login
        </SecondaryButton>
      </div>
    </ContainerFullWidth>
  )
}


export default withRouter(Header)