import React from 'react'
import { Table } from 'reactstrap'
import cn from 'classnames'

import { Heading } from '../widgets/PaneHeader.js'
import TextLink from '../widgets/TextLink.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { is_patent_families_type, is_tech_search_type } from '../../model/portfolio_basket.js'
import { ReportBuilderBasketWarningTooltip } from './ReportBuilderBasketWarning.js'

import { has_utt, is_poweruser } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'
import PortfolioRollupLimitControl from './PortfolioRollupLimitControl.js'
import { DEFAULT_GROUP_BY_OWNER_LEVEL } from '../../model/group_by_owner_level.js'
import OwnerLevelControl from './OwnerLevelControl.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { DEFAULT_REPORT_TYPE, UTT_REPORT_TYPE } from '../../constants/constants.js'
import { InfoPopover } from '../widgets/Tooltip.js'

import cs from '../cipher_styles.module.scss'
import s from './ReportBuilderPortfolioOptions.module.scss'

const ReportBuilderPortfolioOptions = (
  {
    portfolio_basket,
    portfolio_to_cluster,
    is_technology_basket,
    select_all_to_cluster_handler,
    select_none_to_cluster_handler,
    toggle_portfolio_to_cluster_handler,

    update_all_portfolio_basket_items_handler,
    toggle_group_by_owner_handler,

    is_clustering_disabled,
    user,
    portfolio_roll_up_limit,
    set_portfolio_roll_up_limit,
    group_by_owner_level,
    set_group_by_owner_level,
    report_type,
    use_utt_superclasses,
    set_report_type,
    is_valuation_report,
    className
}) => {

  function is_all_selected() {
    const selected = (portfolio_to_cluster || []).filter(item => (item === true))
    return (selected.length === (portfolio_basket || []).length)
  }

  function is_all_group_by_selected() {
    const groupable_items = portfolio_basket.filter(is_group_option_available)
    return groupable_items.every(item => item.group_by_owner === true)
  }

  function get_all_groupable_idxs() {
    const portfolio_basket_with_idxs = portfolio_basket.map((item, idx) => ({...item, idx }))
    const groupable_portfolio_items = portfolio_basket_with_idxs.filter(is_group_option_available)
    return groupable_portfolio_items.map(item => item.idx)
  }

  function any_items_with_group_option_available() {
    const items = (portfolio_basket || []).filter(item => (is_group_option_available(item)))
    return (items.length > 0)
  }

  function is_group_option_available(item) {
    return is_patent_families_type(item) || is_tech_search_type(item)
  }

  const all_selected = is_all_selected()
  const all_group_by_selected = is_all_group_by_selected()
  const group_option_available = any_items_with_group_option_available() && !is_valuation_report
  const groupable_portfolio_idxs = get_all_groupable_idxs()
  const hide_technologies_table = (report_type === UTT_REPORT_TYPE) && portfolio_basket.every((item) => !is_group_option_available(item))

  if (is_technology_basket && !group_option_available) return null

  const user_has_utt = has_utt(user)

  return (
    <div className={className}>

      <div>
        <Heading text='Organisations'/>
        {is_poweruser(user) && !is_valuation_report &&
          <PortfolioRollupLimitControl
            portfolio_roll_up_limit={portfolio_roll_up_limit}
            set_portfolio_roll_up_limit={set_portfolio_roll_up_limit}
            className={''}
          />
        }
        {!is_valuation_report &&
          <OwnerLevelControl
            disabled={!group_option_available}
            selected_level={group_by_owner_level || DEFAULT_GROUP_BY_OWNER_LEVEL}
            on_select={set_group_by_owner_level}
            className={cn('d-flex', 'mt-1')}
          />
        }
      </div>

      {(user_has_utt && !is_technology_basket) &&
        <div className={cn('mt-3')}>
          <Heading text='Technologies'/>
          <div>
            <RadiobuttonWithLabel
              className={cn('d-inline-flex')}
              is_checked={report_type !== UTT_REPORT_TYPE}
              on_click={() => set_report_type({report_type: DEFAULT_REPORT_TYPE})} // assume these controls will never be available in the N/D context
              label='Apply technology Clustering'
            />
            <InfoPopover
              interactive={true}
              placement='right'
            >
              Technology Clustering dynamically groups patent families per report.
            </InfoPopover>
          </div>
          <div className={cn('mt-1')}>
            <RadiobuttonWithLabel
              className={cn('d-inline-flex')}
              is_checked={report_type === UTT_REPORT_TYPE && !use_utt_superclasses}
              on_click={() => set_report_type({report_type: UTT_REPORT_TYPE})}
              label='Use UTT classes'
            />
            <InfoPopover
              interactive={true}
              placement='right'
            >
              Each patent family in Cipher falls into exactly one Universal Technology Taxonomy (UTT) class.
            </InfoPopover>
          </div>
          <div className={cn('mt-1')}>
            <RadiobuttonWithLabel
              className={cn('d-inline-flex')}
              is_checked={report_type === UTT_REPORT_TYPE && use_utt_superclasses}
              on_click={() => set_report_type({report_type: UTT_REPORT_TYPE, use_utt_superclasses: true})}
              label='Use UTT superclasses'
            />
            <InfoPopover
              interactive={true}
              placement='right'
            >
              Each patent family in Cipher falls into exactly one Universal Technology Taxonomy (UTT) superclass.
            </InfoPopover>
          </div>
        </div>
      }

      {!hide_technologies_table &&
        <Table size='sm' className={cn(s.portfolio_table, 'mt-3')}>
          <thead>
            <tr>
              {(!is_technology_basket && (report_type !== UTT_REPORT_TYPE)) &&
                <th className={cn(s.portfolio_table__option, s.column_heading, {[s.portfolio_table__option__inactive]: is_clustering_disabled},'text-center')}>
                  <div className='d-flex justify-content-center'>
                    Clustering
                    {is_clustering_disabled &&
                      <ReportBuilderBasketWarningTooltip is_in_modal={true} buttonClassName='ml-1' />
                    }
                  </div>

                  {!is_clustering_disabled &&
                    <TextLink
                      className={cs.font_weight_normal}
                      onClick={() => all_selected ? select_none_to_cluster_handler() : select_all_to_cluster_handler()}
                    >
                      {all_selected ? 'Deselect' : 'Select'} all
                    </TextLink>
                  }

                </th>
              }

              {group_option_available &&
                <th className={cn(s.portfolio_table__option, s.column_heading,'text-center')}>
                  <div>Group by owner</div>

                  <TextLink
                    className={cs.font_weight_normal}
                    onClick={() => update_all_portfolio_basket_items_handler(groupable_portfolio_idxs, 'group_by_owner', !all_group_by_selected)}
                  >
                    {all_group_by_selected ? 'Deselect' : 'Select'} all
                  </TextLink>

                </th>
              }
              <th className={s.column_heading}>
                <div className='d-flex justify-content-start'>Name</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Classifiers */}
            {is_technology_basket && portfolio_basket.map((item, i) => {
              return (is_group_option_available(item)) ?
                <tr key={i}>
                  <td className='text-center'>
                    <CheckboxStatic
                      is_checked={(item.group_by_owner === true)}
                      onClick={() => toggle_group_by_owner_handler(i)}
                      className='mr-1'
                    />
                  </td>
                  <td>{item.name}</td>
                </tr> : null
            })}

            {/* Clustering / UTT */}
            {!is_technology_basket && portfolio_basket.map((item, i) => {
              const item_has_group_option = is_group_option_available(item)
              if ((report_type === UTT_REPORT_TYPE) && !item_has_group_option) {
                return null // for UTT, hide rows that have no group option
              }
              const is_checked = portfolio_to_cluster ? portfolio_to_cluster[i] : false

              return (
                <tr key={i}>
                  {(!is_technology_basket && (report_type !== UTT_REPORT_TYPE)) &&
                    <td className='text-center'>
                      <CheckboxStatic
                        is_disabled={is_clustering_disabled}
                        is_checked={is_checked && !is_clustering_disabled}
                        onClick={() => toggle_portfolio_to_cluster_handler(i)}
                        className='mr-1'
                      />
                    </td>
                  }

                  {group_option_available &&
                    <td className='text-center'>
                      {is_group_option_available(item)&&
                        <CheckboxStatic
                          is_checked={(item.group_by_owner === true)}
                          onClick={() => toggle_group_by_owner_handler(i)}
                          className='mr-1'
                        />
                      }
                    </td>
                  }
                  <td>{item.name}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
    </div>
  )
}

export default withUser(ReportBuilderPortfolioOptions)
