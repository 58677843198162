import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import cn from 'classnames'

import { fetch_user_permissions_summary } from '../../utils/user_management_utils.js'

import DefaultPageContainer from '../DefaultPageContainer.js'
import ErrorBody from '../ErrorBody.js'
import Spinner from '../widgets/Spinner.js'

import s from './RoleOverview.module.scss'

const RoleOverview = () => {
  const [permissions_overview, set_permissions_overview] = useState(null)
  const [is_fetching, set_is_fetching]                   = useState(true)
  const [error_fetching, set_error_fetching]             = useState(null)

  useEffect(() => {
    fetch_user_permissions_summary()
    .catch(err => {
      // FAIL
      set_error_fetching(err)
      throw err
    })
    .then(permissions_overview => {
      // SUCCESS
      set_permissions_overview(permissions_overview)
    })
    .finally(() => {
      set_is_fetching(false)
    })
  }, [])

  return (
    <DefaultPageContainer className={s.container}>

      <Card className={cn(s.user_management_block, 'w-auto', 'ml-0')} title='Roles overview'>
        {is_fetching &&
          <div>
            <Spinner size={'sm'} className={cn('')} />
          </div>
        }

        {error_fetching &&
          <div>
            <ErrorBody error={error_fetching} />
          </div>
        }

        {permissions_overview &&
          <div className={cn('mt-2 m-4 p-2', s.overview_container)}>
            <table>
              <tbody>
                <tr>
                  <th>Role</th>
                  <th>Users in role</th>
                </tr>
                {permissions_overview.map((p, i) => (
                  <tr key={i}>
                    <td className='pr-5'>{p.role}</td>
                    <td>{p.user_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }

      </Card>
    </DefaultPageContainer>
  )
}

export default RoleOverview