import React from 'react'
import cn from 'classnames'

import { FooterLink } from './FooterLink.js'
import { LinkedInIcon, TwitterIcon } from './widgets/IconSet.js'
import ContainerFullWidth from './ContainerFullWidth.js'
import { is_tech_insights } from '../utils/user_permissions.js'
import { withUser } from './UserContext.js'

import s from './Footer.module.scss'
const Footer = ({user}) => {

  const is_group_tech_insights = is_tech_insights(user)

  const current_year = new Date().getFullYear()

  return (
    <ContainerFullWidth className={s.footer}>
      <div className='mt-4 d-flex' id='cipher_footer'>
        <div>
          <p className={cn('mb-0', s.footer_text)}>Copyright {current_year} - All Rights Reserved</p>

          <p className={cn('mb-0', s.footer_text)}>
            <FooterLink url='https://www.lexisnexis.com/global/privacy/privacy-policy.page'>Privacy Policy</FooterLink>
          </p>
        </div>

        {!is_group_tech_insights &&
          <div className='ml-auto'>
            <ul className='list-unstyled'>
              <li className={s.footer_text}><FooterLink url='http://www.linkedin.com/company/cipher-strategicpatentintelligence'><LinkedInIcon/> LinkedIn</FooterLink></li>
              <li className={s.footer_text}><FooterLink url='https://www.twitter.com/cipher_ai'><TwitterIcon/> Twitter</FooterLink></li>
            </ul>
          </div>
        }

      </div>
    </ContainerFullWidth>
  )
}

export default withUser(Footer)