import React from 'react'
import cn from 'classnames'

import OrgSearchInput from './OrgSearchInput.js'

import s from './OrgFilteringControl.module.scss'

const OrgFilteringControl = ({on_change, text, level}) => {
  const current_level = level || 0

  const input_level_classname = [
    {
      [s.input__even]: (current_level % 2) !== 0,
      [s.input__odd]: (current_level % 2) === 0
    }
  ]


  return (
    <OrgSearchInput
      on_change_handler={on_change}
      placeholder={'Enter a search phrase to filter'}
      show_refresh={false}
      autofocus={false}
      value={text}
      inputClassName={cn(s.input, input_level_classname)}
    />
  )
}

export default OrgFilteringControl