import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import ProgressPrompt from './ProgressPrompt.js'
import SearchBar from '../../widgets/SearchBar.js'
import ResultsCountSelector from '../../tech_explorer/ResultsCountSelector.js'
import {
  check_knn_input_valid,
  find_similar_families,
  get_default_count_setting,
  TEXT_TYPE_TECH_NAME,
} from '../../../utils/knn_search.js'
import { PrimaryButton } from '../../widgets/Button.js'
import Spinner from '../../widgets/Spinner.js'
import {
  ALL_ES_FIELDS,
  ALL_SORTABLE_ES_FIELDS, PAT_FAM_ID_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  SIMILARITY_SCORE_FIELD,
  SIMILARITY_SCORE_ID
} from '../../../model/patent_family_fields.js'
import { ASCENDING, DESCENDING } from '../../../model/sort_directions.js'
import { track_report_builder_event } from '../../../utils/tracking_utils.js'
import { is_400_error } from '../../../utils/axios_utils.js'
import {
  get_patent_families_by_ids,
  order_field_ids_by_group,
  remove_unavailable_field_ids
} from '../../../utils/patent_family_list_utils.js'
import { get_as_map } from '../../../utils/utils.js'
import PatentFamilyStaticListContainer from '../../patent_family_list/PatentFamilyStaticListContainer.js'
import { withUser } from '../../UserContext.js'
import ErrorBody from '../../ErrorBody.js'
import BadInputAlertModal from '../../tech_explorer/BadInputAlertModal.js'
import BadSyntaxAlertModal from '../../patent_family_list/BadSyntaxAlertModal.js'
import { get_patent_upload_as_portfolio_item } from '../../../model/portfolio_basket.js'
import { FormFeedback } from '../../widgets/FormFeedback.js'

import cs from '../../cipher_styles.module.scss'
import s from './PortfolioTechNameSearch.module.scss'

const CONTEXT = 'wizard'
const MAX_TEXT_LENGTH = 60

const PortfolioTechNameSearch = ({user, is_wizard_final_step, on_select_portfolios_handler}) => {
  const search_input_ref = useRef()

  const [should_fetch, set_should_fetch] = useState(false)
  const [should_filter, set_should_filter] = useState(false)
  const [is_fetching, set_is_fetching] = useState(false)

  const [count, set_count] = useState(get_default_count_setting())
  const [text, set_text] = useState(null)
  const [similarity_results, set_similarity_results] = useState(null)
  const [search_results, set_search_results] = useState(null)

  const [size, set_size] = useState(null)
  const [search_phrase, set_search_phrase] = useState(null)
  const [sort, set_sort] = useState({sort_field_id: SIMILARITY_SCORE_ID, sort_direction_id: DESCENDING})

  const [similarity_search_error, set_similarity_search_error] = useState(null)
  const [similarity_search_input_error, set_similarity_search_input_error] = useState(null)
  const [patfam_search_error, set_patfam_search_error] = useState(null)
  const [patfam_search_bad_syntax_error, set_patfam_search_bad_syntax_error] = useState(null)

  useEffect(() => {
    if (!should_fetch) return

    set_is_fetching(true)
    set_similarity_search_error(null)
    track_report_builder_event(`action="search" obj="similar_families" context="${CONTEXT}"`)

    find_similar_families(null, [{value: text, type: TEXT_TYPE_TECH_NAME}], count, null)
      .then(similarity_search_results => {
        set_similarity_results(similarity_search_results)
        set_should_filter(true)
      })
      .catch(err => {
        set_is_fetching(false)
        if (is_400_error(err)) {
          set_similarity_search_input_error(err)
        } else {
          set_similarity_search_error(err)
        }
      })

    set_should_fetch(false)

  }, [should_fetch, text, count])

  useEffect(() => {
    if (!should_filter) return

    if ((similarity_results || []).length === 0) {
      set_should_filter(false)
      set_is_fetching(false)
      return
    }

    set_is_fetching(true)

    const family_ids = (similarity_results || []).map(p => p.patfam_id)

    get_patent_families_by_ids(family_ids, search_phrase, family_ids.length, 0, PRIORITY_DATE_FIELD_ID, ASCENDING)
      .catch(err => {
        if (is_400_error(err)) {
          set_patfam_search_bad_syntax_error(err)
        } else {
          set_patfam_search_error(err)
        }
        set_is_fetching(false)
        throw err
      })
      .then((families_data_search_results) => {
        const { searchResults } = families_data_search_results

        const similarity_search_results_by_patfam_id = get_as_map(similarity_results, 'patfam_id')

        const search_results_extended_by_similarity = (searchResults || []).map(family => {
          const {patFamId} = family || {}

          return {
            ...family || {}, ...similarity_search_results_by_patfam_id[patFamId] || {}
          }
        })

        set_search_results(search_results_extended_by_similarity)
        set_is_fetching(false)
      })

    set_should_filter(false)
  }, [should_filter, search_phrase, similarity_results])

  function start_search() {
    set_should_fetch(true)
  }

  function on_update_from_count_selector(count) {
    set_count(count)

    if (is_search_valid) {
      start_search()
    }
  }

  function on_change_sort_field_id(sort_field_id) {
    set_sort({...sort, sort_field_id})
  }

  function on_change_sort_direction_id(sort_direction_id) {
    set_sort({...sort, sort_direction_id})
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_sort({sort_field_id, sort_direction_id})
  }

  function on_change_page_size(size) {
    set_size(size)
  }

  function on_change_search_phrase(new_search_phrase) {

    if (search_phrase === new_search_phrase) return

    set_search_phrase(new_search_phrase)
    set_patfam_search_error(null)
    set_similarity_search_error(null)

    set_should_filter(true)
  }

  function on_step_complete() {
    const pat_fam_ids = search_results.map(item => item[PAT_FAM_ID_FIELD_ID])
    const portfolio_to_add = get_patent_upload_as_portfolio_item({name: text, pat_fam_ids, group_by_owner: true})

    set_search_phrase(null)

    set_search_results(null)
    set_patfam_search_error(null)
    set_similarity_search_error(null)

    on_select_portfolios_handler([portfolio_to_add])
  }

  function on_input_update(text) {
    if (text.length > MAX_TEXT_LENGTH + 1) return

    set_text(text)
  }

  const FIELDS             = [...ALL_ES_FIELDS, SIMILARITY_SCORE_FIELD]
  const SORTABLE_FIELDS    = [...ALL_SORTABLE_ES_FIELDS, SIMILARITY_SCORE_FIELD]

  const all_field_ids      = order_field_ids_by_group(remove_unavailable_field_ids(FIELDS.map(field => field.id), {is_keyword_search: true, user }))
  const all_sort_field_ids = remove_unavailable_field_ids(SORTABLE_FIELDS.map(field => field.id), {is_keyword_search: true, user })

  const {sort_field_id, sort_direction_id } = sort

  const has_result = search_results != null

  const { is_search_valid } = check_knn_input_valid(null, text)
  const is_input_valid = is_search_valid && (text || '').length <= MAX_TEXT_LENGTH

  return (
    <div>
      <ProgressPrompt
        is_wizard_final_step={is_wizard_final_step}
        can_wizard_continue={has_result}
        on_step_complete={on_step_complete}
      >
        <span>Search for families by typing in technology name and add them to the report.</span>
      </ProgressPrompt>

      <div className='mt-3'>
        <div className='d-md-flex'>
          <SearchBar
            search_input_ref={search_input_ref}
            search_input={text || ''}
            on_change_search_input={on_input_update}
            do_search={start_search}
            on_clear={() => set_text(null)}

            placeholder='Enter technology name'
            no_button={true}
            autofocus={false}
            is_search_valid={is_input_valid}
            search_is_fetching={is_fetching}
            className={cn('flex-grow-1 mr-0 mr-md-2', s.input_wrapper)}
            textAreaClassName={s.input}
          />

          <div className='d-flex mt-2 mt-md-0'>

            <ResultsCountSelector
              selected={count}
              on_select={on_update_from_count_selector}
              disabled={is_fetching}
              className='d-flex mr-3'
            />

            <PrimaryButton size='sm' disabled={!is_input_valid || is_fetching} onClick={start_search} className={cn('my-auto', cs.small_button)}>Search</PrimaryButton>
          </div>
        </div>
        <FormFeedback
          valid={is_input_valid || (text == null)}
          validation_text={`Technology name cannot be empty or longer than ${MAX_TEXT_LENGTH} characters.`}
        />
      </div>

      <div className='mt-2'>
        {is_fetching &&
          <Spinner />
        }

        {!is_fetching && has_result &&
          <PatentFamilyStaticListContainer
            context={CONTEXT}
            families_list={search_results}
            className='mt-3 mb-2'
            all_field_ids={all_field_ids}
            all_sort_field_ids={all_sort_field_ids}
            sort_field_id={sort_field_id || SIMILARITY_SCORE_ID}
            sort_direction_id={sort_direction_id || DESCENDING}
            size={size}
            search_phrase={search_phrase}

            export_title='Similar patent families search results'
            export_file_name='similar_patent_families_search_results'

            on_change_sort_field_id_handler={on_change_sort_field_id}
            on_change_sort_direction_id_handler={on_change_sort_direction_id}
            on_change_sort_field_id_and_sort_direction_id_handler={on_change_sort_field_id_and_sort_direction_id}
            on_change_page_size_handler={on_change_page_size}
            on_change_search_phrase_handler={on_change_search_phrase}

            is_wizard={true}
          />
        }
      </div>

      {similarity_search_error &&
        <ErrorBody
          context={'searching for similar families'}
          error={similarity_search_error}
        />
      }

      {patfam_search_error &&
        <ErrorBody
          context={'searching for families data'}
          error={patfam_search_error}
        />
      }

      {similarity_search_input_error &&
        <BadInputAlertModal
          error={similarity_search_input_error}
          on_hide={() => set_similarity_search_input_error(null)}
        />
      }

      {patfam_search_bad_syntax_error &&
        <BadSyntaxAlertModal
          on_hide={() => set_patfam_search_bad_syntax_error(null)}
        />
      }
    </div>
  )
}

export default withUser(PortfolioTechNameSearch)