import React, { useEffect, useState } from 'react'

import UserGroupSearch from '../UserGroupSearch.js'
import { PrimaryButton } from '../../widgets/Button.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import SaveSetsModal from './SaveSetsModal.js'
import { USER_CUSTOM_CHART_SETS } from '../../../model/user_settings.js'

const CopySet = (
  {
    selected_target_user_or_group,
    groups,
    current_sets,
    on_save_handler
  }) => {
  const [is_save_modal_open, set_is_save_modal_open] = useState(false)
  const [selected_group, set_selected_group] = useState(null)
  const [selected_group_chart_sets, set_selected_group_chart_sets] = useState(null)
  const [selected_user, set_selected_user] = useState(null)
  const [selected_user_chart_sets, set_selected_user_chart_sets] = useState(null)
  const [to_copy, set_to_copy] = useState([])
  const [updated_sets, set_updated_sets] = useState(null)

  useEffect(() => {
    on_clean_up()
  }, [selected_target_user_or_group])

  function on_clean_up() {
    set_is_save_modal_open(false)
    set_selected_user(null)
    set_selected_group(null)
    set_selected_user_chart_sets(null)
    set_selected_group_chart_sets(null)
    set_to_copy([])
    set_updated_sets(null)
  }

  function on_toggle(item) {
    const {name} = item
    const is_selected = to_copy.indexOf(name) > -1

    const updated_to_remove = is_selected ? to_copy.filter(set_name => set_name !== name) : [...to_copy, name]

    set_to_copy(updated_to_remove)
  }

  function on_apply() {
    const sets_to_copy = selected_sets.filter(item => {
      const {name} = item

      return to_copy.indexOf(name) > -1
    })

    //names must be unique; leave sets that have the same names as selected to copy
    const filtered_sets = (current_sets || []).filter(item => {
      const { name } = item

      return to_copy.indexOf(name) === -1
    })
    set_updated_sets([...filtered_sets, ...sets_to_copy])
    set_is_save_modal_open(true)
  }

  function on_save() {
    set_is_save_modal_open(false)
    on_save_handler(updated_sets)
    set_to_copy([])
    set_updated_sets(null)
  }

  function on_get_group_sets(settings={}) {
    const group_sets = settings['group_custom_chart_sets'] || []
    set_selected_group_chart_sets(group_sets)
  }

  function on_get_user_sets(settings={}) {
    const user_sets = settings[USER_CUSTOM_CHART_SETS] || []
    set_selected_user_chart_sets(user_sets)
  }

  const selected_user_or_group = selected_user || selected_group
  const selected_sets = (selected_user) ? selected_user_chart_sets : (selected_group) ? selected_group_chart_sets : []
  
  return (
    <div>
      <div>
        Select a group or user to copy existing chart sets:
      </div>
      <UserGroupSearch
        groups={groups}
        selected_group={selected_group}
        selected_group_chart_sets={selected_group ? selected_group_chart_sets || [] : null}
        selected_user={selected_user}
        selected_user_chart_sets={selected_user ? selected_user_chart_sets || [] : null}
        on_select_group_handler={set_selected_group}
        on_get_group_settings_handler={on_get_group_sets}
        on_select_user_handler={set_selected_user}
        on_get_user_settings_handler={on_get_user_sets}
      />

      {selected_user_or_group && selected_sets && selected_sets.length !== 0 &&
        <div className='mt-3'>
          <div>Choose from the list of sets belonging to selected {selected_user ? 'user' : 'group'}:</div>

          {(selected_sets || []).map((item, i) => {
            const {name} = item

            const is_selected = to_copy.indexOf(name) !== -1

            return (
              <CheckboxAndLabel
                key={i}
                is_checked={is_selected}
                on_click={() => on_toggle(item)}
                label={name}
              />
            )
          })}
          <PrimaryButton className='mt-3' disabled={to_copy.length === 0} onClick={on_apply}>Apply</PrimaryButton>
        </div>
      }

      <SaveSetsModal
        is_open={is_save_modal_open}
        on_hide={() => set_is_save_modal_open(false)}
        on_save={on_save}
        sets={updated_sets || []}
        sets_to_highlight={to_copy || []}
      />

    </div>
  )
}

export default CopySet