import React from 'react'
import cn from 'classnames'
//https://stackoverflow.com/questions/48358529/failed-to-execute-removechild-on-node-with-fontawesome-in-react

// This file wraps icons from
// a) FontAwesome: https://fontawesome.com/icons?d=gallery
// b) IcoMoon:  https://icomoon.io/#preview-essential
// c) Google Material Design: https://material.io/tools/icons

// For Font Awesome
// We currently have 5.2.0 (August 2018) paid version, so all icons are available.
// To include a new icon...
// 1. find the name from the font awesome website
// 2. add the className as <prefix> fa-<name>
// Prefixes include: 'fa' (default), 'fal' (light), 'fas' (solid)
// To update version of Font Awesome, download a new version to /public/static/font_awesome
// (user is 'admin@aistemos.com', ask Monika/Ben for password)

// For IcoMoon
// We have downloaded the free version.
// It includes specific selections.
// To update it...
// 1. Go to IcoMoon website
// 2. Click on "icomoon app"
// 3. Click "import", and upload selection.json (existing selection)
// 4. Create a new selection (i.e. search and add new icons)
// 5. Download the new selection
// 6. Add the fonts directory, selection.json and style.css to /public/static/font_icomoon

// For Google Material Design
// We currently just copy and inline the raw SVG, removing any width/height/fill properties,
// so that these properties can easily be set in css.
// We also need to relabel any hyphenated properties in react camelcase
// (i.e. clip-rule="xxx" becomes clipRule="xxx").

import { REPORT_TITLE_BAR_COLOUR } from '../../constants/colours.js'
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ChevronRight from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'

const DATASET_ICON_STYLES = {'paddingTop': 0, width: '1.7rem', height: '1.7rem'}

export const StartIcon = () => {
  return (<span><i className='fas fa-play-circle'></i></span>)
}

export const AddressIcon = () => {
  return (<span><i className='icon-sphere'></i></span>)
}

export const AddToReportIcon = () => {
  return (<span><i className='icon-checkmark'></i></span>)
}

export const CheckIcon = () => {
  return (<span><i className='icon-checkmark1'></i></span>)
}

export const RemoveIcon = () => {
  return (<CloseIcon />)
}

export const BackwardIcon = () => {
  return (<span><i className='fas fa-caret-left'></i></span>)
}

export const MailIcon = () => {
  return (<span><i className='far fa-envelope'></i></span>)
}

export const ForwardIcon = () => {
  return (<span><i className='fas fa-caret-right'></i></span>)
}

export const FastForwardIcon = () => {
  return (<span><i className='fas fa-fast-forward'></i></span>)
}

export const StepForwardIcon = () => {
  return (<span><i className='fas fa-step-forward'></i></span>)
}

export const StepBackwardIcon = () => {
  return (<span><i className='fas fa-step-backward'></i></span>)
}

export const FastBackwardIcon = () => {
  return (<span><i className='fas fa-fast-backward'></i></span>)
}

export const PortfolioIcon = () => {
  return (<span><i className='icon-briefcase'></i></span>)
}

export const ToggleOnIcon = () => {
  return (<span><i className='icon-arrow-down'></i></span>)
}

export const ToggleOffIcon = () => {
  return (<span><i className='icon-arrow-right'></i></span>)
}

export const ToggleDownIcon = () => {
  return (<span><i className="fas fa-chevron-down"></i></span>)
}

export const AddToProjectIcon = () => {
  return (<span><i className='fas fa-books-medical'></i></span>)
}

export const ProjectIcon = () => {
  return (<span><i className='fas fa-books'></i></span>)
}

export const UnlinkIcon = () => {
  return (<span><i className='fas fa-unlink'></i></span>)
}

export const ToggleDownIconInline = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={className}
      onClick={onClick}
    >
      <path
        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
      </path>
    </svg>
  )
}

export const ToggleUpIconInline = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={className}
      onClick={onClick}
    >
      <path
        d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z">
      </path>
    </svg>
  )
}

export const LitigationIcon = () => {
  return (<span><i className='icon-balance'></i></span>)
}

export const LinkedInIcon = () => {
  const iconEntity = '&#xe90a;'
  return (<span><i dangerouslySetInnerHTML={{__html: iconEntity }}></i></span>)
}

export const TwitterIcon = () => {
  const iconEntity = '&#xe909;'
  return (<span><i dangerouslySetInnerHTML={{__html: iconEntity }}></i></span>)
}

export const InfoIcon = ({bold}) => {
  return (<span><i className={cn('fal', {'far': bold}, 'fa-info-circle')}></i></span>)
}

export const RemoveFromBasketIcon = () => {
  return (<span><i className='icon-cross'></i></span>)
}

export const ShowBasketItemIcon = () => {
  return (<span><i className='icon-question'></i></span>)
}

export const ShowSimilarIcon = () => {
  return (<span className='fs-unmask'><i className='icon-folder-search'></i></span>)
}

export const NewReportFromParamsIcon = () => {
  return (<span><i className='far fa-clone'></i></span>)
}

export const ShareIcon = () => {
  const iconEntity = '&#xea82;'
  return (<span><i dangerouslySetInnerHTML={{__html: iconEntity }}></i></span>)
}

export const HistoryIcon = () => {
  return (<span className='fs-unmask'><i className='icon-clock'></i></span>)
}

export const ChangesIcon = () => {
  return (<span><i className='icon-hour-glass'></i></span>)
}

export const FeedbackIcon = () => {
  return (<span className='fs-unmask'><i className='icon-bubbles'></i></span>)
}

export const FeedbackOutlineIcon = () => {
  return (<span><i className='fal fa-comments'></i></span>)
}

export const UserIcon = () => {
  return (<span><i className='icon-user'></i></span>)
}

export const GroupIcon = () => {
  return (<span><i className='fas fa-users'></i></span>)
}

export const EditIcon = ({className, onClick, title}) => {
  return (<span><i className={cn('icon-pencil', className)} onClick={onClick} title={title}></i></span>)
}

export const TrashIcon = () => {
  return (<span><i className='fa fa-trash-alt'></i></span>)
}

export const UndoIcon = () => {
  return (<span><i className='icon-undo2'></i></span>)
}

export const ShowMoreIcon = () => {
  return (<span><i className='fas fa-ellipsis-v'></i></span>)
}

export const SaveIcon = () => {
  return (<span><i className='fas fa-save'></i></span>)
}

export const UnsaveIcon = ({ is_report_title_bar }) => {
  const background_color = is_report_title_bar ? REPORT_TITLE_BAR_COLOUR : 'white'
  const max_size = is_report_title_bar ? '1.8rem' : '1rem'
  return (
    <span className='fa-stack fa-1x' style={{'maxWidth': max_size, 'maxHeight': max_size}}>
      <i className="fas fa-save fa-stack-1x m-0"></i>
      <i className="fas fa-inverse fa-slash fa-stack-1x ml-0" style={{'marginTop': '0.1rem', color: background_color}}></i>
      <i className="far fa-slash fa-stack-1x m-0"></i>
    </span>
  )
}

export const TagIcon = () => {
  return (<span><i className='fas fa-tag'></i></span>)
}

export const TagsIcon = () => {
  return (<span><i className='fas fa-tags'></i></span>)
}

export const TagsOutlineIcon = () => {
  return (<span><i className='fal fa-tags'></i></span>)
}

export const RetryIcon = () => {
  return (<span><i className='far fa-redo-alt'></i></span>)
}

export const ConfirmIcon = () => {
  return (<span><i className='icon-checkmark'/></span>)
}

export const SortedAscIconInline = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0 432V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48zm207.029-281.941L71.515 285.573c-9.373 9.373-9.373 24.569 0 33.941l16.971 16.971c9.373 9.373 24.569 9.373 33.941 0L224 234.912l101.573 101.574c9.373 9.373 24.569 9.373 33.941 0l16.971-16.971c9.373-9.373 9.373-24.569 0-33.941L240.971 150.059c-9.373-9.373-24.569-9.373-33.942 0z">
      </path>
    </svg>
  )
}

export const SortedADecIconInline = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={className}
      onClick={onClick}
    >
      <path
        d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zM240.971 361.941l135.515-135.515c9.373-9.373 9.373-24.569 0-33.941l-16.971-16.971c-9.373-9.373-24.569-9.373-33.941 0L224 277.088 122.427 175.515c-9.373-9.373-24.569-9.373-33.941 0l-16.971 16.971c-9.373 9.373-9.373 24.569 0 33.941L207.03 361.942c9.372 9.372 24.568 9.372 33.941-.001z">
      </path>
    </svg>

  )
}

export const AddNewUserIcon = () => {
  return (<span><i className='fas fa-user-plus' /></span>)
}

export const AddNewIcon = () => {
  return (<span><i className='fas fa-plus' /></span>)
}

export const ErrorIcon = () => {
  return (<span><i className='far fa-exclamation-circle'/></span>)
}

export const ExternalLinkIcon = () => {
  return (<span><i className='far fa-external-link' /></span>)
}

export const ExternalLinkIconInline = ({ className, onClick, title }) => {
  return (
    <svg viewBox="0 0 576 512" className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" title={title}>
      {title && <title>{title}</title>}
      <path
        fill="currentColor"
        d="M576 14.4l-.174 163.2c0 7.953-6.447 14.4-14.4 14.4H528.12c-8.067 0-14.56-6.626-14.397-14.691l2.717-73.627-2.062-2.062-278.863 278.865c-4.686 4.686-12.284 4.686-16.971 0l-23.029-23.029c-4.686-4.686-4.686-12.284 0-16.971L474.379 61.621l-2.062-2.062-73.626 2.717C390.626 62.44 384 55.946 384 47.879V14.574c0-7.953 6.447-14.4 14.4-14.4L561.6 0c7.953 0 14.4 6.447 14.4 14.4zM427.515 233.74l-24 24a12.002 12.002 0 0 0-3.515 8.485V458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h301.976c10.691 0 16.045-12.926 8.485-20.485l-24-24A12.002 12.002 0 0 0 331.976 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V242.225c0-10.691-12.926-16.045-20.485-8.485z">
      </path>
    </svg>
  )
}

export const ExpandClosedIcon = () => {
  return (<span><i className='fas fa-chevron-right' /></span>)
}

export const ExpandOpenedIcon = () => {
  return (<span><i className='fas fa-chevron-down' /></span>)
}

export const ChevronRightIcon = () => {
  return (<span><i className='fa fa-chevron-right' /></span>)
}

export const ZoomInIcon = () => {
  return (<span><i className='far fa-plus-circle' /></span>)
}

export const ZoomOutIcon = () => {
  return (<span><i className='far fa-minus-circle' /></span>)
}

export const RotateRightIcon = () => {
  return (<span><i className='far fa-redo' /></span>)
}

export const RotateLeftIcon = () => {
  return (<span><i className='far fa-undo' /></span>)
}

export const TransformResetIcon = () => {
  return (<span><i className='far fa-crosshairs' /></span>)
}

export const ColourPaletteIcon = () => {
  return (<span><i className='fas fa-palette'/></span>)
}

export const TickIcon = ({ className, onClick, title }) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M8.79502 15.875L4.62502 11.705L3.20502 13.115L8.79502 18.705L20.795 6.70498L19.385 5.29498L8.79502 15.875Z"/>
    </svg>
  )
}

export const CrossIcon = ({ className, onClick, title }) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"/>
    </svg>
  )
}

export const BlockedIcon = ({ className, onClick, title }) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM7.1 5.69L18.31 16.9C19.37 15.55 20 13.85 20 12C20 7.58 16.42 4 12 4C10.15 4 8.45 4.63 7.1 5.69Z" />
    </svg>
  )
}

export const RemoveLabelsIcon = ({ className, onClick, title }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M13.05 42q-1.25 0-2.125-.875T10.05 39V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z"/>
    </svg>
  )
}

export const TestIcon = ({ className, onClick, title }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M21.5 40V13H10V8h28v5H26.5v27Z"/>
    </svg>
  )
}

export const CompareIcon = ({ className, onClick, title }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M13.55 42 4 32.45l9.6-9.6 2.1 2.1-6 6h32.45v3H9.7l5.95 5.95Zm20.9-16.85-2.1-2.1 5.95-5.95H5.85v-3H38.3l-6-6L34.4 6l9.6 9.6Z"/>
    </svg>
  )
}

export const ExclamationIcon = () => {
  return (<span><i className='fas fa-exclamation'/></span>)
}

export const QuestionIcon = () => {
  return (<span><i className='fas fa-question'/></span>)
}


export const SimpleErrorIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
    </svg>
  )
}

export const SearchIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  )
}

export const TorchOnIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M6 14l3 3v5h6v-5l3-3V9H6v5zm2-3h8v2.17l-3 3V20h-2v-3.83l-3-3V11zm3-9h2v3h-2zM3.502 5.874L4.916 4.46l2.122 2.12-1.414 1.415zm13.458.708l2.123-2.12 1.413 1.416-2.123 2.12z"/>
    </svg>
  )
}

export const TorchOffIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M6 14l3 3v5h6v-5l3-3V9H6v5zm2-3h8v2.17l-3 3V20h-2v-3.83l-3-3V11zm3-9h2"/>
    </svg>
  )
}

export const KeyboardArrowLeftIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
      <path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>
  )
}

export const KeyboardArrowRightIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
      <path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>
  )
}

export const ForwardArrowIcon = ({ className, onClick, title }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M12 8V4l8 8-8 8v-4H4V8z"/>
    </svg>
  )
}

export const KeyboardIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z"/>
      <path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
    </svg>
  )
}

export const StarIcon = ({ className, onClick, title }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      {title && <title>{title}</title>}
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>
  )
}

export const MoveAsGroupOutIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" className={className}>
      <g id="Canvas_1" stroke="none">
        <title>Canvas 1</title>
        <g id="Canvas_1: Layer 1">
          <title>Layer 1</title>
          <g id="Graphic_6">
            <rect x="75" y="0" width="50" height="725"/>
          </g>
          <g id="Graphic_4">
            <circle cx="100" cy="100" r="100.00015979019"/>
            <circle cx="100" cy="100" r="100.00015979019" stroke="white" />
          </g>
          <g id="Graphic_9">
            <rect x="100" y="675" width="200" height="50"/>
          </g>
          <g id="Graphic_10">
            <rect x="125" y="375" width="200" height="50"/>
          </g>
          <g id="Graphic_8">
            <circle cx="300" cy="700" r="100.00015979019"/>
            <circle cx="300" cy="700" r="100.00015979019" stroke="white" />
          </g>
          <g id="Graphic_7">
            <circle cx="300" cy="400" r="100.00015979019"/>
            <circle cx="300" cy="400" r="100.00015979019" stroke="white"/>
          </g>
          <g id="Graphic_18">
            <path d="M 450 250 L 625 550 L 800 250 Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const TableViewIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  )
}

export const MakeGroupIcon = () => {
  return (<span><i className='icon-make-group' /></span>)
}

export const GoogleSearchIcon = () => {
  return (<span><i className='icon-google' ></i></span>)
}

export const WikipediaIcon = ({className}) => {
  return (<span><i className={cn('icon-wikipedia1', className)} ></i></span>)
}

export const LanguageIcon = () => {
  return (<span><i className='icon-translate'/></span>)
}

export const ExtractIcon = () => {
  return (<span><i className='icon-share'/></span>)
}

export const BellIcon = () => {
  return (<span><i className='icon-bell'/></span>)
}

export const ShieldIcon = () => {
  return (<span><i className='icon-shield-notice'/></span>)
}

export const RepeatInLeftIcon = () => {
  return (<span><i className='fal fa-chevron-double-left'/></span>)
}

export const RepeatInRightIcon = () => {
  return (<span><i className='fal fa-chevron-double-right'/></span>)
}

export const CardsViewIcon = ({ className, onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className} onClick={onClick}>
      <path d="M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  )
}

export const ColumnsStackViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 21 h5v-8.75h-5v8.75z M10 21 h5v-9.75h-5v9.75z M17 21 h5v-5.75h-5v5.75z"/>
      <path d="M3 11.75 h5v-8.75h-5v8.75z M10 10.75 h5v-4.75h-5v4.75h-5z M17 14.75 h5v-2.75h-5v2.75z" opacity="0.4"/>
    </svg>
  )
}

export const BarsStackViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 3 v5h8.75v-5h-8.75z M3 10 v5h9.75v-5h-9.75z M3 17 v5h5.75v-5h-5.75z"/>
      <path d="M12.25 3 v5h8.75v-5h-8.75z M13.25 10 v5h4.75v-5h-4.75z M9.25 17 v5h2.75v-5h-2.75z" opacity="0.4"/>
    </svg>
  )
}

export const ColumnsClusterViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 21 h2.25v-17h-2.25v17z M10 21 h2.25v-19h-2.25v19z M17 21 h2.25v-12h-2.25v12z"/>
      <path d="M5.75 21 h2.25v-16h-2.25v16z M12.75 21 h2.25v-10h-2.25v-10z M19.75 21 h2.25v-6h-2.25v6z" opacity="0.4"/>
    </svg>
  )
}

export const BarsClusterViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 3 v2.25h17v-2.25h-17z M3 10 v2.25h19v-2.25h-19z M3 17 v2.25h12v-2.25h-12z"/>
      <path d="M3 5.75 v2.25h16v-2.25h-16z M3 12.75 v2.25h10v-2.25h-10z M3 19.75 v2.25h6v-2.2h-6z" opacity="0.4"/>
    </svg>
  )
}

export const PieViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M12.25 2 C15 2 22 4 22 12 S15 22 12.25 22 V16 C16 15.5 16 12 16 12 S16 8.5 12.25 8 V2 z" opacity="0.6"/>
      <path d="M11.75 2.1 C 12 1.7 2.2 2.7 2 11.75 H 8 C 8.5 8.2 11.3 8.2 11.75 8 V2 z" opacity="0.3" />
      <path d="M2 12.25 H 8 C8.5 16 12 16 11.75 16 V22 C 7 21.8 2 18 2 12.25 Z"/>
    </svg>
  )
}

export const HeatmapViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path d="M0 0h25v24H0z" fill="none"/>
      <path d="M1 4 h4v1.5h-4v-1.5z M5.5 4 h4v1.5h-4v-1.5z M10 4 h4v1.5h-4v-1.5z M1 6 h4v1.5h-4v-1.5z M19 4 h4v1.5h-4v-1.5z M14.5 6 h4v1.5h-4v-1.5z M5.5 8 h4v1.5h-4v-1.5z M1 10 h4v1.5h-4v-1.5z M5.5 10 h4v1.5h-4v-1.5z M10 10 h4v1.5h-4v-1.5z M19 10 h4v1.5h-4v-1.5z M19 12 h4v1.5h-4v-1.5z M5.5 18 h4v1.5h-4v-1.5z M10 18 h4v1.5h-4v-1.5z" />
      <path d="M14.5 4 h4v1.5h-4v-1.5z M5.5 6 h4v1.5h-4v-1.5z M10 6 h4v1.5h-4v-1.5z M1 8 h4v1.5h-4v-1.5z M19 8 h4v1.5h-4v-1.5z M1 12 h4v1.5h-4v-1.5z M5.5 12 h4v1.5h-4v-1.5z M10 12 h4v1.5h-4v-1.5z M14.5 12 h4v1.5h-4v-1.5z M10 14 h4v1.5h-4v-1.5z M14.5 14 h4v1.5h-4v-1.5z M19 14 h4v1.5h-4v-1.5z M14.5 18 h4v1.5h-4v-1.5z M19 18 h4v1.5h-4v-1.5z" opacity="0.8" />
      <path d="M19 6 h4v1.5h-4v-1.5z M10 8 h4v1.5h-4v-1.5z M14.5 8 h4v1.5h-4v-1.5z M1 14 h4v1.5h-4v-1.5z M5.5 14 h4v1.5h-4v-1.5z M14.5 16 h4v1.5h-4v-1.5z M19 16 h4v1.5h-4v-1.5z M1 18 h4v1.5h-4v-1.5z" opacity="0.5" />
      <path d="M14.5 10 h4v1.5h-4v-1.5z M1 16 h4v1.5h-4v-1.5z M5.5 16 h4v1.5h-4v-1.5z M10 16 h4v1.5h-4v-1.5z" opacity="0.3" />
    </svg>
  )
}

export const LineViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <circle cx="3" cy="18" r="1"/>
      <circle cx="9" cy="15" r="1"/>
      <circle cx="15" cy="9" r="1"/>
      <circle cx="21" cy="11" r="1"/>
      <circle cx="3" cy="10" r="1" opacity="0.3"/>
      <circle cx="9" cy="12" r="1" opacity="0.3"/>
      <circle cx="15" cy="5" r="1" opacity="0.3"/>
      <circle cx="21" cy="16" r="1" opacity="0.3"/>
      <line x1="3" y1="18" x2="9" y2="15" style={{"strokeWidth":1}}/>
      <line x1="9" y1="15" x2="15" y2="9" style={{"strokeWidth":1}}/>
      <line x1="15" y1="9" x2="21" y2="11" style={{"strokeWidth":1}}/>
      <line x1="3" y1="10" x2="9" y2="12" style={{"strokeWidth":1, "opacity": 0.3}}/>
      <line x1="9" y1="12" x2="15" y2="5" style={{"strokeWidth":1, "opacity": 0.3}}/>
      <line x1="15" y1="5" x2="21" y2="16" style={{"strokeWidth":1, "opacity": 0.3}}/>
    </svg>
  )
}

export const TabularViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25" style={style}>
      <path d="M0 0h24v25H0z" fill="none"/>
      <path d="M1 4 h7v2.5h-7v-2.5z M9 4 h4v2.5h-4v-2.5z M14 4 h4v2.5h-4v-2.5z M19 4 h4v2.5h-4v-2.5z" opacity="0.8"/>
      <path d="M1 7.5 h7v2.5h-7v-2.5z M9 7.5 h4v2.5h-4v-2.5z M14 7.5 h4v2.5h-4v-2.5z M19 7.5 h4v2.5h-4v-2.5z" opacity="0.1"/>
      <path d="M1 11 h7v2.5h-7v-2.5z M9 11 h4v2.5h-4v-2.5z M14 11 h4v2.5h-4v-2.5z M19 11 h4v2.5h-4v-2.5z" opacity="0.2"/>
      <path d="M1 14.5 h7v2.5h-7v-2.5z M9 14.5 h4v2.5h-4v-2.5z M14 14.5 h4v2.5h-4v-2.5z M19 14.5 h4v2.5h-4v-2.5z" opacity="0.1"/>
      <path d="M1 18 h7v2.5h-7v-2.5z M9 18 h4v2.5h-4v-2.5z M14 18 h4v2.5h-4v-2.5z M19 18 h4v2.5h-4v-2.5z" opacity="0.2"/>
    </svg>
  )
}

export const TreemapViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M2 4 h10.75v16h-10.75v-16z" opacity="0.6"/>
      <path d="M13.25 13.25 h8.75v6.75h-8.75v-6.75z" />
      <path d="M13.25 4 h5.5v8.75h-5.5v-8.75z" opacity="0.2"/>
      <path d="M19.25 4 h2.75v4.25h-2.75v-4.25" opacity="0.5"/>
      <path d="M19.25 8.75 h2.75v4h-2.75v-4" opacity="0.3"/>
    </svg>
  )
}

export const MapViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 43" style={style}>
      <path d="M0 0h45v43H0z" fill="none"/>
      <path d="M3 7 H4 V8 H3 V7z M1 8 H2 V9 H1 V8z M4 10 H5 V11 H4 V10z M6 9 H7 V10 H6 V9z M9 7 H11 V8 H10 V9 H9 V7z M10 11 H11 V12 H12 V14 H11 V13 H10 V11z M12 9 H15 V8 H19 V9 H18 V11 H17 V12 H16 V13 H15 V14 H14 V12 H13 V10 H12 V9z M17 14 H18 V15 H17 V14z" opacity="0.4"/>
      <path d="M0 12 H1 V13 H2 V12 H4 V13 H7 V12 H8 V13 H9 V14 H8 V15 H7 V16 H8 V17 H9 V18 H10 V15 H11 V16 H12 V17 H13 V18 H12 V19 H11 V20 H10 V23 H9 V22 H6 V23 H5 V22 H4 V21 H3 V18 H2 V17 H1 V16 H0 V12z" />
      <path d="M6 23 H7 V24 H6 V23z
      M7 24 H12 V25 H14 V26 H15 V27 H16 V29 H15 V30 H14 V31 H13 V34 H14 V35 H13 V34 H11 V29 H9 V27 H8 V25 H7 V24z" opacity="0.6" />
      <path d="M18 22 H19 V21 H21 V20 H22 V21 H25 V22 H26 V23 H27 V25 H28 V26 H27 V29 H26 V31 H25 V32 H23 V29 H22 V26 H19 V25 H18 V22z M27 29 H28 V30 H27 V29z M28 28 H29 V29 H28 V28 " opacity="0.4"/>
      <path d="M38 29 H39 V28 H40 V27 H41 V28 H42 V27 H43 V29 H44 V31 H43 V32 H41 V31 H38 V29z M42 33 H43 V34 H42 V33z" opacity="0.5"/>
      <path d="M23 9 H24 V10 H23 V9z
      M20 20 V18 H21 V17 H24 V16 H25 V15 H24 V14 H23 V16 V22 V14 H22 V13 H23 V12 H27 V13 H25 V14 H28 V13 H30 V12 H31 V11 H33 V10 H39 V11 H40 V12 H41 V11 H44 V16 H41 V17 H42 V19 H41 V20 H39 V22 H38 V23 H37 V25 H36 V24 H35 V23 H34 V24 H33 V25 H32 V23 H31 V22 H29 V24 H27 V22 H26 V21 H25 V20 H27 V19 H25 V20 H23 V19 H21 V20 H20z
      M41 9 H42 V10 H41 V9z" opacity="0.8"/>
      <path d="M37 26 H38 V25 H39 V27 H37 V26z M41 25 H43 V26 H41 V25z M40 21 H41 V22 H40 V21z M41 20 H42 V21 H41 V20z" opacity="0.8" />
    </svg>
  )
}

export const BubbleViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <circle cx="3" cy="18" r="0.8"/>
      <circle cx="9" cy="18" r="0.5"/>
      <circle cx="15" cy="18" r="1.2"/>
      <circle cx="21" cy="18" r="1.5"/>
      <circle cx="3" cy="12" r="1.2" opacity="0.6"/>
      <circle cx="9" cy="12" r="1.5" opacity="0.6"/>
      <circle cx="15" cy="12" r="2" opacity="0.6"/>
      <circle cx="21" cy="12" r="2.3" opacity="0.6"/>
      <circle cx="3" cy="6" r="2.2" opacity="0.3"/>
      <circle cx="9" cy="6" r="1.2" opacity="0.3"/>
      <circle cx="15" cy="6" r="1.6" opacity="0.3"/>
      <circle cx="21" cy="6" r="1.2" opacity="0.3"/>
    </svg>
  )
}

export const AreaViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23.5" style={style}>
      <path d="M0 0h23v24H0z" fill="none"/>
      <path d="M1 4 h21 v0 l-3 1.4 l-3 1.4 l-3 1 l-3 -1 l-3 -0.2 l-3 0.1 l-3 -0.2Z" opacity="0.5"/>
      <path d="M22 4.5 l-3 2 l-3 2 l-3 1 l-3 -1 l-3 1 l-3 0.6 l-3 1 V7 l3 0.2 l3 -0.1 l3 0.2 l3 1 l3 -1 l3 -1.4 l3 -1.4Z" opacity="0.1" />
      <path d="M1 13.5 l3 -0.5 l3 -0.2 l3 -1.3 l3 -0.2 l3 -0.5 l3 -2 l3 -3 V5 l-3 2 l-3 2 l-3 1 l-3 -1 l-3 1 l-3 0.6 l-3 1Z" opacity="0.3"/>
      <path d="M1 14 l3 -0.5 l3 -0.2 l3 -1.3 l3 -0.2 l3 -0.5 l3 -2 l3 -3 V17 l-3 -2 l-6 -1 l-3 0 l-3 0.5 l-3 0.3 l-3 0.2Z" opacity="0.6"/>
      <path d="M1 19.5 h21v-2 l-3 -2 l-6 -1 l-3 0 l-3 0.5 l-3 0.3 l-3 0.2Z" opacity="0.9"/>
    </svg>
  )
}

export const ScatterViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <rect x="2" y="18" width="2.5" height="2.5"/>
      <rect x="6" y="10" width="2.5" height="2.5"/>
      <rect x="9" y="15" width="2.5" height="2.5"/>
      <rect x="16" y="9" width="2.5" height="2.5"/>
      <rect x="21" y="11" width="2.5" height="2.5"/>
      <rect x="16" y="16" width="2.5" height="2.5"/>
      <rect x="9" y="12" width="2.5" height="2.5" opacity="0.3"/>
      <rect x="13" y="10" width="2.5" height="2.5" opacity="0.3"/>
      <rect x="15" y="5" width="2.5" height="2.5" opacity="0.3"/>
      <rect x="5" y="17" width="2.5" height="2.5" opacity="0.3"/>
    </svg>
  )
}

export const ScatterViewDotsIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <circle cx="3" cy="19" r="1.4"/>
      <circle cx="7" cy="11" r="1.4"/>
      <circle cx="10" cy="16" r="1.4"/>
      <circle cx="17" cy="10" r="1.4"/>
      <circle cx="17" cy="17" r="1.4"/>
      <circle cx="22" cy="12" r="1.4"/>
      <circle cx="10" cy="13" r="1.4" opacity="0.3"/>
      <circle cx="14" cy="11" r="1.4" opacity="0.3"/>
      <circle cx="16" cy="6" r="1.4" opacity="0.3"/>
      <circle cx="6" cy="18" r="1.4" opacity="0.3"/>
    </svg>
  )
}

// added for benchmarking report deck
export const DeckColumnsStackIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-8.75h-3.25v8.75z M7 21 h3.25v-9.75h-3.25v9.75z M11 21 h3.25v-5.75h-3.25v5.75z M15 21 h3.25v-3.75h-3.25v3.75z M19 21 h3.25v-1.75h-3.25v1.75z"/>
      <path d="M3 11.75 h3.25v-8.75h-3.25v8.75z M7 10.75 h3.25v-4.75h-3.25v4.75 M11 14.75 h3.25v-5.75h-3.25v5.75z M15 16.75 h3.25v-2.75h-3.25v2.75 M19 18.75 h3.25v-1.75h-3.25v1.75" opacity="0.4"/>
    </svg>
  )
}

export const DeckColumnsBoldIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-18h-3.25v18z M7 21 h3.25v-15h-3.25v15z M11 21 h3.25v-10h-3.25v10z M15 21 h3.25v-6h-3.25v6z M19 21 h3.25v-4h-3.25v4z"/>
    </svg>
  )
}

export const DeckColumnsLightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-18h-3.25v18z M7 21 h3.25v-15h-3.25v15z M11 21 h3.25v-10h-3.25v10z M15 21 h3.25v-6h-3.25v6z M19 21 h3.25v-4h-3.25v4z" opacity="0.4"/>
    </svg>
  )
}

export const DeckColsSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-18h-3.25v18z M11 21 h3.25v-10h-3.25v10z M15 21 h3.25v-6h-3.25v6z M19 21 h3.25v-4h-3.25v4z" opacity="0.4"/>
      <path d="M7 21 h3.25v-15h-3.25v15z"/>
    </svg>
  )
}

export const DeckColsPosNegSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 17 h3.25v-13h-3.25v13z M7 17 h3.25v-11h-3.25v11z M15 17 h3.25v2h-3.25v-2z M19 17 h3.25v4h-3.25v-4z" opacity="0.4"/>
      <path d="M11 17 h3.25v-4h-3.25v4z"/>
    </svg>
  )
}

export const DeckBarsSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" style={style}>
      <path fill="none" d="M0 0h25v25H0V0z"/>
      <path d="M3 3 v3.25h18v-3.25h-18z M3 11 v3.25h10v-3.25h-10z M3 15 v3.25h6v-3.25h-6z M3 19 v3.25h4v-3.25h-4z" opacity="0.4"/>
      <path d="M3 7 v3.25h15v-3.25h-15z"/>
    </svg>
  )
}

export const DeckBarsPosNegSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" style={style}>
      <path fill="none" d="M0 0h25v25H0V0z"/>
      <path d="M7 3 v3.25h13v-3.25h-13z M7 7 v3.25h11v-3.25h-11z M7 15 v3.25h-2v-3.25h2z M7 19 v3.25h-4v-3.25h4z" opacity="0.4"/>
      <path d="M7 11 v3.25h4v-3.25h-4z"/>
    </svg>
  )
}

export const DeckLandscapeBarsIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 4.5 v1.5h15v-1.5h-15z M3 9 v1.5h9v-1.5h-9z M3 12 v1.5h6.5v-1.5h-6.5z M3 13.5 v1.5h6v-1.5h-6z M3 16.5 v1.5h3.5v-1.5h-3.5z M3 18 v1.5h3v-1.5h-3z v1.5h2v-1.5h-2z" opacity="0.2"/>
      <path d="M3 3 v1.5h18v-1.5h-18z M3 7.5 v1.5h10v-1.5h-10z M3 10.5 v1.5h8v-1.5h-8z M3 15 v1.5h5v-1.5h-5z M3 19.5" opacity="0.6"/>
      <path d="M3 6 v1.5h12v-1.5h-12z"/>
    </svg>
  )
}

export const DeckLineIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <circle cx="3" cy="14" r="0.75"/>
      <circle cx="9" cy="12" r="0.75"/>
      <circle cx="15" cy="7" r="0.75"/>
      <circle cx="21" cy="9" r="0.75"/>
      <line x1="3" y1="14" x2="9" y2="12" style={{"strokeWidth":0.75}}/>
      <line x1="9" y1="12" x2="15" y2="7" style={{"strokeWidth":0.75}}/>
      <line x1="15" y1="7" x2="21" y2="9" style={{"strokeWidth":0.75}}/>

      <circle cx="3" cy="8" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="10" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="3" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="11" r="0.75" opacity="0.4"/>
      <line x1="3" y1="8" x2="9" y2="10" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="10" x2="15" y2="3" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="3" x2="21" y2="11" style={{"strokeWidth":0.75, "opacity": 0.4}}/>

      <circle cx="3" cy="12" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="15" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="10" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="8" r="0.75" opacity="0.4"/>
      <line x1="3" y1="12" x2="9" y2="15" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="15" x2="15" y2="10" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="10" x2="21" y2="8" style={{"strokeWidth":0.75, "opacity": 0.4}}/>

      <circle cx="3" cy="19" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="19" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="16" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="18" r="0.75" opacity="0.4"/>
      <line x1="3" y1="19" x2="9" y2="19" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="19" x2="15" y2="16" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="16" x2="21" y2="18" style={{"strokeWidth":0.75, "opacity": 0.4}}/>

      <circle cx="3" cy="18" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="20" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="19" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="15" r="0.75" opacity="0.4"/>
      <line x1="3" y1="18" x2="9" y2="20" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="20" x2="15" y2="19" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="19" x2="21" y2="15" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
    </svg>
  )
}

export const EyeIcon = () => {
  return (<span><i className='icon-eye' /></span>)
}

export const EyeBlockedIcon = () => {
  return (<span><i className='icon-eye-blocked'/></span>)
}

export const ConfigureIcon = () => {
  return (<span><i className='fas fa-sliders-h'/></span>)
}

export const InvestigateIcon = () => {
  return (<span><i className='fas fa-user-secret' /></span>)
}

export const ReportDataIcon = () => {
  return (<span><i className='fas fa-database'/></span>)
}

export const FilterIcon = () => {
  return (<span><i className='fal fa-filter' /> </span>)
}

export const GridIcon = () => {
  return  (<span><i className='fas fa-th' /></span>)
}

export const ChevronCircleRightIcon = () => {
  return (<span><i className='fas fa-chevron-circle-right' /> </span>)
}

export const PlusCircleIcon = () => {
  return (<span><i className='icon-plus-circle'/></span>)
}

export const MinusCircleIcon = () => {
  return (<span><i className='icon-minus-circle'/></span>)
}

export const NotesIcon = () => {
  return (<span><i className='fal fa-sticky-note' /> </span>)
}

export const MoveAssigneeUpIcon = () => {
  return (<span><i className='fas fa-sort-amount-up' /> </span>)
}

export const PropagateUpIcon = () => {
  return (<span><i className='fas fa-level-up-alt fa-flip-horizontal' /></span>)
}

export const ListIcon = () => {
  return (<span><i className='far fa-list' /></span>)
}

export const ExportIcon = () => {
  return (<span><i className='far fa-download' /></span>)
}

export const UploadIcon = () => {
  return (<span><i className='far fa-upload' /></span>)
}

export const IgnoreIcon = () => {
  return (<span><i className='far fa-minus-circle' /></span>)
}

export const UndoIgnoreIcon = () => {
  return (<span><i className='far fa-plus-circle' /></span>)
}

export const LoopIcon = () => {
  return (<span><i className='icon-loop1' /></span>)
}

export const CogIcon = () => {
  return (<span className='fs-unmask'><i className='icon-cog' /></span>)
}

export const ShrinkIcon = () => {
  return (<span><i className='icon-shrink5' /></span>)
}

export const EnlargeIcon = () => {
  return (<span><i className='icon-enlarge' /></span>)
}
export const SelectDatasetIcon = () => {
  return (<span><i className='icon-checked' /></span>)
}

export const DeselectDatasetIcon = () => {
  return (<span><i className='icon-error' /></span>)
}

export const MenuIcon = () => {
  return (<span><i className='icon-menu' /></span>)
}

export const AddToClipboardIcon = () => {
  return (<span><i className='icon-clipboard' /></span>)
}

export const LightbulbCrossedIcon = () => {
  return (<span><i className='fa fa-lightbulb-slash' /></span>)
}

export const LightbulbOnIcon = () => {
  return (<span><i className='fa fa-lightbulb-on' /></span>)
}

export const LightbulbOffIcon = () => {
  return (<span><i className='fal fa-lightbulb' /></span>)
}

export const BarsIcon = () => {
  return (<span><i className='fal fa-bars' /></span>)
}

export const BallotCheckIcon = () => {
  return (<span><i className='far fa-ballot-check'></i></span>)
}

export const AddToListIcon = () => {
  return (<PlaylistAddCheckOutlinedIcon />)
}

export const ExpandIcon = () => {
  return (<ExpandMore />)
}

export const CollapseIcon = () => {
  return (<ChevronRight/>)
}

export const MergeIcon = () => {
  return (<span><i className='fa fa-object-group'/></span>)
}

export const DeselectAllIcon = () => {
  return (<span><i className='far fa-minus-square'/></span>)
}