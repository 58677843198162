import React from 'react'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import cn from 'classnames'

import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import ScrollableList from '../widgets/ScrollableList.js'

import s from './PatentNumberSelector.module.scss'

export const PatentNumberSelector = ({patent_numbers, selected_patent_number, on_click_from_selected_patent_handler}) => {

  if (!patent_numbers || (patent_numbers.length < 2)) {
    return null
  }

  return (
    <UncontrolledDropdown>
      <SelectDropdownToggleStatic className='fs-mask'>
        {selected_patent_number}
      </SelectDropdownToggleStatic>
      <DropdownMenu className='fs-mask'>
        <ScrollableList
          className='py-2 px-1'
        >
          {patent_numbers.map((item, i) => {
            const is_selected = (item === selected_patent_number)
            return (
              <DropdownItem key={i} onClick={() => on_click_from_selected_patent_handler(item)} className={cn({[s.selected]: is_selected})}>
                {item}
              </DropdownItem>
            )})}
        </ScrollableList>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}