import React, {useEffect, useState} from 'react'
import cn from 'classnames'

import { format_duration } from '../../utils/utils.js'
import { process_patfam_disputes, get_patent_family_disputes_by_pat_fam_id } from '../../utils/domain_utils.js'

import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { DETAILS_FIELD, DURATION_FIELD, END_DATE_FIELD, JURISDICTION_FIELD, START_DATE_FIELD, TITLE_FIELD } from '../../model/disputes.js'

import { Table } from '../widgets/Table.js'
import TextLink from '../widgets/TextLink.js'
import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'

import { Dispute } from '../disputes/Dispute.js'
import { Heading } from './FamilyDetailsLabel.js'
import { withUser } from '../UserContext.js'
import { format_route } from '../../utils/disputes_utils.js'

const FamilyDisputesView = ({patfam_id, user, className, disputes_ref, allow_public_access}) => {
  const [disputes, set_disputes] = useState(null )
  const [selected_dispute, set_selected_dispute] = useState(null )
  const [is_fetching, set_is_fetching] = useState(false)

  const [error_fetching, set_error_fetching] = useState(null)

  useEffect(() => {
    set_is_fetching(true)
    set_selected_dispute(null)

    let did_cancel = false
    get_patent_family_disputes_by_pat_fam_id(patfam_id)
      .catch(err => {
        if (!did_cancel) {
          set_is_fetching(false)
          set_error_fetching(err)
          throw err
        }
      })
      .then(disputes => {
        if (!did_cancel) {
          const underscore_disputes = disputes.map(process_patfam_disputes)
          set_disputes(underscore_disputes)
          set_is_fetching(false)
        }
      })

    return () => {
      did_cancel = true
    }
  }, [patfam_id])

  function get_columns() {
    const fields = [
      TITLE_FIELD,
      {...START_DATE_FIELD, className: 'text-center'},
      {...END_DATE_FIELD, className: 'text-center'},
      {...DURATION_FIELD, cell_render: (row) => (format_duration(row.original['duration']))},
      {...JURISDICTION_FIELD, className: 'text-center'},
      {
        ...DETAILS_FIELD,
        cell_render: function Details(row) {
          return (
            <TextLink
              onClick={() => set_selected_dispute(row.original)}
            >
              Details
            </TextLink>
          )
        },
        className: 'text-center',
        sortable: false
      }
    ]

    let table_columns = []

    fields.forEach(field => {

      const { id, label, cell_render } = field

      table_columns.push({
        ...TABLE_COLUMN_HEADER,
        id,
        label,
        field: field.id,
        ...(cell_render) ? {cell_render} : {},
        ...(field.className) ? {className: field.className} : {},
        ...(field.sortable != null) ? {sortable: field.sortable} : {}
      })
    })

    return table_columns
  }

  const { title, docket_number, start_date, end_date, jurisdiction, parties, patents, attachments, route } = selected_dispute || {}

  const have_disputes = (disputes && disputes.length > 0)

  return (
    <div
      ref={disputes_ref}
      className={cn(className)}
      key={`disputes_${patfam_id}`}
    >
      <Heading>Disputes {have_disputes ? `(${disputes.length})` : ''}</Heading>

      {is_fetching &&
        <Spinner />
      }

      {!is_fetching && !error_fetching && !have_disputes &&
        <div>(none)</div>
      }

      {error_fetching &&
        <div>There was an error fetching disputes</div>
      }

      {!is_fetching && !error_fetching && have_disputes &&
        <Table
          columns={get_columns()}
          data={disputes}
          defaultSorted={[
            {
              id: START_DATE_FIELD.id,
              desc: true
            }
          ]}
        />
      }

      {selected_dispute &&
        <Modal
          on_hide={() => set_selected_dispute(null)}
        >
          <Dispute
            is_page_view={true}
            user={user}
            title={title}
            docket_number={docket_number}
            jurisdiction={jurisdiction}
            start_date={start_date}
            end_date={end_date}
            parties={parties}
            patents={patents}
            attachments={attachments}
            route={format_route(route)}
            allow_public_access={allow_public_access}
          />
        </Modal>
      }

    </div>
  )
}

export default withUser(FamilyDisputesView)