import React from 'react'

import { LabelInline } from './FamilyDetailsLabel.js'
import { get_status, get_status_tooltip } from '../../utils/family_view_utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { WRAPPER_ELEMENT_PARENT } from '../../constants/tooltip.js'

import s from './FamilyStatus.module.scss'

export const FamilyStatus = ({family, is_in_modal}) => {
  const {status, patFamId} = family
  const non_null_status = status? status : 'no-status'
  const family_status = get_status(non_null_status) || non_null_status
  const family_status_hint = get_status_tooltip(family_status)

  return (
    <div className='d-flex' key={`status_${patFamId}`}>
      <LabelInline>Status</LabelInline>
      <div className={s.content}><span className={s.status}>{family_status} </span>
        {family_status_hint &&
          <InfoPopover is_in_modal={is_in_modal} wrapper={WRAPPER_ELEMENT_PARENT} placement='bottom'><span className='font-weight-normal'>{family_status_hint}</span></InfoPopover>
        }
      </div>
    </div>
  )
}