import React, {Component} from 'react'
import {withRouter} from 'react-router'
import qs from 'query-string'

import Modal from './widgets/Modal.js'
import {PrimaryButton} from './widgets/Button.js'
import CheckboxStatic from './widgets/CheckboxStatic.js'
import ErrorBody from './ErrorBody.js'

import {fetch_user_settings, save_user_setting} from '../utils/user_settings_utils.js'
import {HAS_ACCEPTED_TRIAL_AGREEMENT} from '../model/user_settings.js'
import {send_error_to_sentry} from '../utils/sentry_utils.js'
import {track_user_event} from '../utils/tracking_utils.js'

import s from './EulaModal.module.scss'

const SHOW_EULA_PARAM = 'show_eula'

class EulaModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const {location} = this.props
    const query_params = qs.parse(location.search)

    if (query_params[SHOW_EULA_PARAM]) {
      this.setState({agreement_needed: true, has_checked_agree: false})
      return
    }

    fetch_user_settings()
      .catch(error => {
        // No point in notifying user, but log error in sentry.
        send_error_to_sentry(error, {})
        throw error
      })
      .then(user_settings => {
        if (!user_settings[HAS_ACCEPTED_TRIAL_AGREEMENT]) {

          track_user_event('obj="eula" action="show"')

          this.setState({agreement_needed: true, has_checked_agree: false})
        }
      })
  }

  toggle_agreed_checkbox() {
    const {has_checked_agree} = this.state
    this.setState({has_checked_agree: !has_checked_agree})
  }

  update_eula_agreed_user_setting() {
    if (this.state.has_checked_agree) {

      track_user_event('obj="eula" action="accept"' )

      save_user_setting(HAS_ACCEPTED_TRIAL_AGREEMENT, true)
        .catch(error => {
          this.setState({error_updating_user_setting: error})
          throw error
        })
        .then(() => this.setState({agreement_needed: false}))
    }
  }

  build_modal_footer() {
    const {has_checked_agree} = this.state

    return (
      <div>
        <PrimaryButton
          onClick={() => this.update_eula_agreed_user_setting()}
          disabled={!has_checked_agree}
        >
          Activate access to Cipher
        </PrimaryButton>
      </div>
    )
  }


  render() {

    const {agreement_needed, has_checked_agree, error_updating_user_setting} = this.state
    return (
      <div>
        {agreement_needed &&
          <Modal
            title={'Welcome to Cipher!'}
            backdrop={false}
            footer={this.build_modal_footer()}
            zIndex={10001}
          >
            <div className={s.eula_container}>
              Please accept the Trial Agreement and activate your access to Cipher.
              <div className={s.eula_text}>
                <h4 className='mb-3'>Cipher Trial Agreement</h4>
                <h5>1. General</h5>
                <p>1.1. The Cipher Trial Agreement sets out terms and conditions on which Aistemos Limited (company number: 08644182, t/a Cipher) whose registered office is at 12 East Passage, London EC1A 7LP will provide you access to Cipher on a trial basis, free of charge.</p>
                <p>By agreeing to these terms and conditions you agree to use Cipher in accordance with the Cipher Trial Agreement.</p>
                <p>1.2. The trial imposes no obligation to purchase.</p>
                <h5>2. Interpretation</h5>
                <p>Capitalised terms used in this Agreement have the meanings set out in <strong>Annex</strong> (Definitions).</p>
                <h5>3. Provision of Cipher</h5>
                <p>3.1. <strong>Access to Cipher.</strong> Aistemos agrees to enable you access to Cipher for the agreed period from the Activation Date. It is at your responsibility to ensure that the systems you use to access Cipher conform to the applicable System Requirements.</p>
                <h5>4. Licence</h5>
                <p>4.1. <strong>Scope of licence</strong>. Subject to Clauses 4.2, Aistemos grants you a non-exclusive, non-transferable licence:</p>
                <p>4.1.1. to access Cipher using the Access Code provided by Aistemos, for the sole purpose of using Cipher; and</p>
                <p>4.1.2. to use the Data for the purpose of generating Cipher Reports, but for noncommercial purposes only.</p>
                <p>4.2. <strong>Restrictions on use.</strong> Except as expressly permitted by this Agreement, you may not, directly or indirectly:</p>
                <p>4.2.1. attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Documentation or the Data, in any form or media or by any means;</p>
                <p>4.2.2. combine Data derived from multiple Cipher searches into new Cipher Reports, if such use would go beyond fair and reasonable use of Cipher or use the Data to create any new actuarial or other model;</p>
                <p>4.2.3. supply, sell, rent or otherwise make Cipher Reports available to third parties or access any part of Cipher, the Documentation or the Data in order to build a product or service which competes either with Cipher or the businesses of the owners of Third Party Data;</p>
                <p>4.2.4. generate Cipher Reports that, by themselves or in the aggregate, constitute a substantial portion of any of the data held within Cipher in its unaltered state;</p>
                <p>4.2.5. include information obtained from Cipher Reports in any publicly-issued document (including documents such as circulars, annual reports and accounts or research notes); or</p>
                <p>4.2.6. use the Data, or allow the use, export, re-export or other transfer of the Data, in contravention of any applicable export control or other laws and regulations (including without limitation those of the United Kingdom and the United States).</p>
                <p>4.3. <strong>Attribution.</strong> You must not remove, alter or otherwise interfere with any of the trade marks or trade names of Aistemos or its licensors, or any notices contained in the Cipher Reports, and shall ensure that all those such trade marks, trade names and notices are reproduced completely and legibly on all Cipher Reports. Where you reproduce all or part of a Cipher Report, you must ensure that the copy includes the following attribution: &quot;Source: Cipher, © Aistemos Limited 2021, all rights reserved&quot;, and include the date on which the Cipher search was performed.</p>
                <h5>5. Security</h5>
                <p>5.1. <strong>Access Code.</strong> In order to enable you to access and use Cipher, Aistemos will provide you with the Access Code. You must treat such information as confidential, and must not disclose it to any third party.</p>
                <p>5.2. <strong>Misuse of Access Code.</strong> You agree to be liable for any breach of this Agreement committed by any person who accesses or uses Cipher using the Access Code you have been provided with. You must ensure to:</p>
                <p>5.2.1. only use the Access Code assigned to you and do not permit other individuals to use this Access Code to access Cipher;</p>
                <p>5.2.2. do not attempt to gain unauthorised access to the underlying Data that forms part of the aggregated data held in Cipher; and</p>
                <p>5.2.3. do not attack Cipher via a denial-of-service attack or a distributed denial-of-service attack.</p>
                <p>5.3. <strong>Interference with Cipher.</strong> You must not do anything to interfere with or disrupt the integrity or performance of Cipher, or (except as may be allowed by any Applicable Law which is incapable of exclusion by agreement between the parties) attempt to decompile, disassemble, reverse engineer or use any other means to attempt to discover any source code, algorithms or trade secrets underlying all or any part of Cipher or the Data.</p>
                <p>5.4. <strong>Disablement of access.</strong> Aistemos may disable your Access Code, at any time, if in Aistemos&#39; opinion you fail to comply with any of the provisions of this Agreement.</p>
                <h5>6. Term and expiration</h5>
                <p>This Agreement shall come into force on the Activation Date and expire after the agreed period.</p>
                <h5>7. Effect of expiry</h5>
                <p>7.1. On expiry of this Agreement for any reason:</p>
                <p>7.1.1. Aistemos shall disable the Access Code allocated to the Authorised User;</p>
                <p>7.1.2. all licences granted under this Agreement shall terminate with immediate effect, and you shall immediately cease all use of Cipher, and not endeavour to access it.</p>
                <h5>8. Warranties</h5>
                <p>8.1. <strong>Aistemos warranties.</strong> Aistemos warrants, represents and undertakes that it has, and will continue to have, all necessary rights to grant you the rights set out in this Agreement; and</p>
                <p>8.2. <strong>No other warranties.</strong> The parties agree that the representations and warranties set out in this Clause 8 are in lieu of and exclude all other terms, conditions or warranties implied by statute, law or otherwise as to the merchantability, satisfactory quality or fitness for any particular purpose of Cipher, to the fullest extent permitted by law.</p>
                <h5>9. Intellectual Property Rights</h5>
                <p>You acknowledge that Aistemos and/or its Data Providers own all intellectual property rights in Cipher, the Documentation and the Data. Except as expressly stated in this Agreement, you are not granted any rights or licences in respect thereof.</p>
                <h5>10. Training Sets and Classifiers</h5>
                <p>10.1 All intellectual property rights relating to Training Sets, Derivative Training Sets and Classifiers shall be owned by Aistemos and Subscriber assigns all intellectual property rights relating to such sets and classifiers to Aistemos.</p>
                <p>10.2 Subscriber shall have the right to use Library Classifiers, and be permitted access to the underlying Training Sets for the term of this Agreement.</p>
                <p>10.3 Aistemos shall not use or disclose to any third party any patent data provided by Subscriber or otherwise disclosed by Subscriber to Aistemos for the purpose of building a Training Set or a Derivative Training Set, except for the purpose of testing and evaluation within Cipher’s machine learning software.</p>
                <p>10.4 Notwithstanding any other provision, nothing in this Agreement shall operate as an assignment of any Aistemos intellectual property right in a Classifier.</p>
                <h5>11. Liability</h5>
                <p>11.1. Aistemos shall not be liable whether in tort (including negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses, however arising, under this Agreement.</p>
                <p>11.2. You acknowledge that Cipher contains information and data which are compiled from a variety of sources, including publicly-available sources, and which have not been verified independently by Aistemos or its licensors, and that neither Aistemos nor any of its licensors is responsible for the accuracy, currency or completeness of the information and data obtained through the use of Cipher.</p>
                <h5>12. General</h5>
                <p>12.1. <strong>Entire agreement.</strong> This Agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, statements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject-matter.</p>
                <p>12.2. <strong>Governing law and jurisdiction.</strong> This Agreement and any dispute or claim arising out of or in connection with it or with its subject-matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.</p>
                <h5>Annex: Definitions</h5>
                <p>In this Agreement:</p>
                <p><strong><u>Activation date</u></strong> means the date on which Aistemos provides you with access to Cipher pursuant to this Agreement;</p>
                <p><strong><u>Agreement</u></strong> means the Cipher Trial Agreement;</p>
                <p><strong><u>Access Code</u></strong> means an access code or password provided by Aistemos that enables Cipher access for a specified period of time;</p>
                <p><strong><u>Applicable Law</u></strong> means the laws of England and Wales and any other laws or regulations, regulatory policies, guidelines or industry codes which apply to the provision or receipt of Cipher;</p>
                <p><strong><u>Cipher</u></strong> means the intellectual property data aggregation and analytics service known as Cipher which is accessed through the website with the domain <i>app.cipher.ai</i>;</p>
                <p><strong><u>Cipher Report</u></strong> means any content (including charts, graphs, maps, tables, visualisations or other means of presenting information) generated by the Subscriber from a single search using Cipher, and downloaded, printed or recorded in any other way;</p>
                <p><strong><u>Classifier</u></strong> means the software derived from the Training Sets used by Cipher to identify similar patents;</p>
                <p><strong><u>Data</u></strong> means all data contained within Cipher, including Third Party Data;</p>
                <p><strong><u>Data Providers</u></strong> means the data providers who licence their datasets to Aistemos for inclusion in Cipher from time to time;</p>
                <p><strong><u>Derivative Training Set</u></strong> means a Training Set derived from any other Training Set with help or contribution from a Subscriber;</p>
                <p><strong><u>Documentation</u></strong> means user manuals, guides, know-how or other documentation published by Aistemos (whether within Cipher or otherwise) from time to time;</p>
                <p><strong><u>Library Classifier</u></strong> means any Classifier developed by Aistemos for the benefit of subscribers generally;</p>
                <p><strong><u>System Requirements</u></strong> means the browser version and other system requirements required for the use of Cipher, as specified in the Documentation or otherwise notified to the Subscriber from time to time;</p>
                <p><strong><u>Third Party Data</u></strong> means data licensed to Aistemos by third parties, and which has been aggregated or otherwise used within Cipher;</p>
                <p><strong><u>Training Set</u></strong> means the patent data used by Cipher to create a Classifier.</p>
              </div>
              <div className='d-flex'>
                <CheckboxStatic
                  onClick={this.toggle_agreed_checkbox.bind(this)}
                  is_checked={has_checked_agree}
                />
                <span className='ml-1'>I accept the terms of the Cipher Trial Agreement</span>
              </div>

              {error_updating_user_setting &&
                <div className='mt-4'>
                  <ErrorBody
                    error={error_updating_user_setting}
                    context={'updating trial agreement user setting'}
                  />
                </div>
              }

            </div>

          </Modal>
        }
      </div>
    )
  }
}

export default withRouter(EulaModal)