import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import cn from 'classnames'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import ContainerFullWidth from '../../ContainerFullWidth.js'
import { BarsIcon, CheckIcon, FilterIcon } from '../../widgets/IconSet.js'
import { get_long_date_string } from '../../../utils/time_utils.js'
import TextLink from '../../widgets/TextLink.js'
import { BENCHMARKING, REPORT, SHARE_DECK } from '../../../constants/paths.js'
import StatusToggle from './StatusToggle.js'
import Filters from './Filters.js'
import ShareReportModal from '../../report_management/components/ShareReportModal.js'

import {
  EXPORT_MODE_ALL_PAGES,
  EXPORT_MODE_CURRENT_PAGE,
  LANDING_PAGE_ID,
} from '../../../constants/report_deck.js'

import { check_if_deck_type_is_benchmarking } from '../../../utils/report_deck_utils.js'
import { is_subset_path } from '../../../utils/viewer_utils.js'
import { withUser } from '../../UserContext.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'

import s from './Header.module.scss'

const Header = (
  {
    history,
    disable,
    deck_type,
    external_report_id,
    report_title,
    created_at,

    is_menu_open,
    toggle_menu,
    is_edit_mode,
    toggle_edit_mode,
    report_deck,
    current_page,
    set_current_page,

    deck_config,

    org_lists,
    tech_areas,
    selections,
    on_deck_status_filter_change,
    on_deck_selected_org_lists_change,
    on_deck_selected_tech_areas_change,
    on_deck_selected_geo_filters_change,
    on_deck_spotlighted_tech_areas_change,
    on_deck_export,
  }
) => {
  const wrapper_ref = React.createRef()
  const filters_ref = React.createRef()
  const menu_ref = React.createRef()

  const [show_share_modal, set_show_share_modal] = useState(false)
  const [title_container_width, set_title_container_width] = useState(null)

  const movement_timer = useRef()
  const update_title_container_width = () => {
    if (wrapper_ref.current && filters_ref.current && menu_ref.current) {
      set_title_container_width(wrapper_ref.current.offsetWidth - filters_ref.current.offsetWidth - menu_ref.current.offsetWidth)
    }
  }

  useLayoutEffect(() => {
    update_title_container_width()
  })

  useEffect(() => {
    update_title_container_width()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_edit_mode])

  useEffect(() => {
    const update_title_container_width_with_delay = () => {
      clearInterval(movement_timer.current)
      movement_timer.current = setTimeout(update_title_container_width, 100)
    }

    window.addEventListener('resize', update_title_container_width_with_delay)
    return () => {
      document.removeEventListener('resize', update_title_container_width_with_delay)
    }
  })

  function on_share_deck() {
    track_deck_viewer_event(`obj="report" action="share" deck_type="${deck_type}"`)
    set_show_share_modal(true)
  }

  function on_export(export_mode) {
    track_deck_viewer_event(`obj="report" action="export" format="Excel" export_mode="${export_mode}" deck_type="${deck_type}"`)
    on_deck_export(export_mode)
  }

  function on_navigate_to_page(page_id) {
    track_deck_viewer_event(`obj="report" action="navigate" page="${page_id}" context="menu" deck_type="${deck_type}"`)
    set_current_page(page_id)
  }

  const is_benchmarking = check_if_deck_type_is_benchmarking(deck_type)
  const {status_filter} = selections || {}

  const is_families_list_mode = is_subset_path(history.location.pathname)

  return (
    <ContainerFullWidth
      className={cn({[s.header__benchmarking]: is_benchmarking})}
    >
      <div className={cn('d-flex justify-content-between', s.header)} ref={wrapper_ref}>
        <div className='d-flex'>
          <Dropdown
            isOpen={is_menu_open}
            toggle={toggle_menu}
            size='sm'
            className='my-auto'
          >
            <DropdownToggle
              tag='div'
              className={cn(s.menu_toggle, {[s.disabled]: disable})}
              disabled={disable}
            >
              <div ref={menu_ref} className='pr-2'><BarsIcon /></div>
            </DropdownToggle>
            <DropdownMenu
              container='body' // ensure renders outside of parent div, overcomes overflow hidden problems
            >
              <DropdownItem className='mt-1' disabled={!current_page || (current_page === LANDING_PAGE_ID) || disable} onClick={() => set_current_page(LANDING_PAGE_ID)}>Intro page</DropdownItem>

              {report_deck.contents.map(section => {
                return (
                  <div key={section.id}>
                    <DropdownItem header>{section.title}</DropdownItem>
                    {section.pages.filter(page => !page.hidden_in_main_nav).map(page => {
                      const {id, title} = page
                      const sub_pages = report_deck.get_sub_pages(id)
                      return (
                        <div key={id}>
                          <DropdownItem
                            onClick={() => on_navigate_to_page(id)}
                            disabled={(current_page === id) || disable}
                          >
                            {title}
                          </DropdownItem>
                          { sub_pages.map(sub_page => {
                            return (
                              <DropdownItem
                                key={sub_page.id}
                                className={'py-0'}
                                onClick={() => on_navigate_to_page(sub_page.id)}
                                disabled={(current_page === sub_page.id) || disable}
                              >
                                <span className='ml-3'>
                                  {sub_page.title}
                                </span>
                              </DropdownItem>
                            )
                          })
                          }
                        </div>
                      )
                    })}
                  </div>
                )

              })}
              <DropdownItem divider />
              <DropdownItem onClick={() => on_export(EXPORT_MODE_CURRENT_PAGE)} disabled={current_page === LANDING_PAGE_ID}>Export current page</DropdownItem>
              <DropdownItem onClick={() => on_export(EXPORT_MODE_ALL_PAGES)}>Export deck</DropdownItem>
              <DropdownItem onClick={() => on_share_deck()}>Share deck</DropdownItem>
              <DropdownItem tag='div'><TextLink className='d-block w-100' element={Link} to={`${REPORT}/${external_report_id}`} target='_blank'>View full report</TextLink></DropdownItem>
            </DropdownMenu>
          </Dropdown>


          <div className='my-auto position-relative' style={{'width': title_container_width + 'px'}}>
            <div className={s.title}>
              {report_title}
            </div>
            {created_at &&  <div className={s.created_at}>{get_long_date_string(created_at)}</div>}
          </div>
        </div>

        <div className={cn(['my-auto', {'d-flex ': !is_families_list_mode}, {'d-none': is_families_list_mode}])} ref={filters_ref}>
          {is_edit_mode &&
            <div className='d-flex'>

              <StatusToggle
                status_filter={status_filter}
                on_click={on_deck_status_filter_change}
                className='my-auto'
              />

              <Filters
                org_lists={org_lists || []}
                tech_areas={tech_areas || []}
                selections={selections}
                on_deck_selected_org_lists_change={on_deck_selected_org_lists_change}
                on_deck_selected_tech_areas_change={on_deck_selected_tech_areas_change}
                on_deck_spotlighted_tech_areas_change={on_deck_spotlighted_tech_areas_change}
                on_deck_selected_geo_filters_change={on_deck_selected_geo_filters_change}
                className={'mx-2'}
              />
            </div>
          }

          <div
            onClick={toggle_edit_mode}
            className={cn(
              'ml-2 my-auto',
              (disable ? s.disabled : s.filters_toggle),
            )}
          >
            {!is_edit_mode && <FilterIcon />}
            {is_edit_mode && <CheckIcon />}
          </div>
        </div>

        <ShareReportModal
          is_open={show_share_modal}
          link_only={true}
          share_url={`${SHARE_DECK}${is_benchmarking ? BENCHMARKING : ''}`}
          external_report_id={external_report_id}
          selections={selections}
          report_title={report_title}
          user_state={deck_config}
          on_close={() => set_show_share_modal(false)}
        />

      </div>
    </ContainerFullWidth>
  )
}

export default withUser(withRouter(Header))