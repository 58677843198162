import React, { useEffect, useState } from 'react'
import { FamilyTagsSection } from './FamilyTagsSection'
import { GroupedTags, fetch_grouped_displayable_tags } from './family_tag_utils'
import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody'
import OrgSuggestionsByFamiliesDisplay from '../orgs/OrgSuggestionsByFamiliesDisplay'
import TextLink from '../widgets/TextLink'
import FamilyTagsManagement from './FamilyTagsManagement'
import { withUser } from '../UserContext'
import { can_tag_families } from '../../utils/user_permissions'


interface FamilyTagSetsDisplayProps {
  user:any,
  add_to_basket_handler: Function,
  portfolio_name: string,
  portfolio_items: Array<any>,
  on_click_similar_portfolio: Function,
  update_group_handler: Function,
  reset_similar_portfolio: Function
}

const FamilyTagSectionsDisplay = (props: FamilyTagSetsDisplayProps) => {

  const [user_tags, set_user_tags] = useState<Array<GroupedTags>>([])
  const [fetch_data_error, set_fetch_data_error] = useState(null)
  const [show_spinner, set_show_spinner] = useState(false)
  const [show_tag_mngt_ui, set_show_tag_mngt_ui] = useState(false)

  useEffect(() => {
    let did_cancel = false
    set_show_spinner(true)
    fetch_grouped_displayable_tags()
      .catch(error => {
        if (!did_cancel) {
          set_fetch_data_error(error)
          set_show_spinner(false)
        }
      })
      .then(custom_tags => {
        if (!did_cancel && custom_tags) {
          set_fetch_data_error(null)
          set_user_tags(custom_tags)
          set_show_spinner(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [show_tag_mngt_ui])

  const show_similar_portfolios = props.portfolio_name && props.portfolio_items
  const can_user_tag_families = can_tag_families(props.user)

  return (
    <div>
      {fetch_data_error && !show_spinner && !show_tag_mngt_ui &&
      <ErrorBody
        error={fetch_data_error}
        context={'Error fetching family tags'}
      />
      }
      {!fetch_data_error && !show_spinner && !show_tag_mngt_ui && can_user_tag_families && !show_similar_portfolios &&
      //@ts-expect-error
      <TextLink
        className='float-right mb-2'
        onClick={() => set_show_tag_mngt_ui(true)}
      >
        Manage family tags
      </TextLink>
      }
      {!fetch_data_error && !show_spinner && show_tag_mngt_ui && can_user_tag_families &&
      <div>
        {/*//@ts-expect-error*/}
        <TextLink
          className='float-right mb-2'
          onClick={() => set_show_tag_mngt_ui(false)}
        >
          Close tag management
        </TextLink>
        <FamilyTagsManagement />
      </div>
      }
      {!fetch_data_error && user_tags.length > 0 && !show_spinner && !show_similar_portfolios && !show_tag_mngt_ui &&
      <div className={'pt-3'}>
        {user_tags.map((grouped: GroupedTags, index) => (
          <div key={index}>
            <FamilyTagsSection
              title={grouped.tag_section}
              tags={grouped.tags}
              on_tag_select_handler={props.add_to_basket_handler}
            />
          </div>
        ))}
      </div>
      }
      {!fetch_data_error && user_tags.length === 0 && !show_spinner && !show_tag_mngt_ui &&
      //@ts-expect-error
      <Pane className={'text-center'}>
        No family tags defined
      </Pane>
      }
      {show_spinner && !show_tag_mngt_ui &&
      //@ts-expect-error
      <Pane className={'text-center'}>
        <Spinner> Fetching tags ... </Spinner>
      </Pane>
      }

      {show_similar_portfolios && !show_tag_mngt_ui &&
      <OrgSuggestionsByFamiliesDisplay
        base_name={props.portfolio_name}
        base_items={props.portfolio_items}
        on_click_suggestion={props.on_click_similar_portfolio}
        update_group_handler={props.update_group_handler}
        on_reset_suggestion={props.reset_similar_portfolio}
        tracking_context={'tagged_families'}
      />
      }

    </div>)

}

export default withUser(FamilyTagSectionsDisplay)