import {
  ENTRANCE_YEAR_BY_ORG_BY_TECH_ID,
  FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID,
  FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
  FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID,
  FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID,
  FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  CAGR_ACTIVE_FAMILIES_BY_TECH_ID,
  PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID
} from '../spec_ids.js'
import { PATENTING_ACTIVITY_GROUP_ID } from './spec_group_ids.js'
import { KEY_GRANT_YEAR, KEY_ORGANISATION, KEY_PRIORITY_YEAR, KEY_PUBLICATION_YEAR, KEY_TECHNOLOGY } from './spec_keys.js'
import { TAG_ACTIVE, TAG_ORG, TAG_PENDING, TAG_TECH, TAG_TIME } from './spec_tags.js'
import {
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS
} from '../views.js'
import {
  BAR_STACK_ID,
  CAGR_SCATTER_ID,
  CAGR_TABLE_ID,
  COLUMN_STACK_ID,
  HEATMAP_ID,
  TABLE_ID
} from '../view_ids.js'

const HELP_ARTICLE = '28885919850259-Patenting-Activity-charts-datasets'

export const PATENTING_ACTIVITY_GROUP = {
  id: PATENTING_ACTIVITY_GROUP_ID,
  name: 'Patenting activity',
  short_name: 'Activity',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
      name: 'families',
      description: 'The number of patent families filed each year by priority year and organisation.',
      help_article: `${HELP_ARTICLE}#h_9a8a61b5d5`,
      by: [KEY_ORGANISATION, KEY_PRIORITY_YEAR],
      tags: [TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID,
      name: 'families',
      description: 'The number of patent families filed each year by priority year and technology. ',
      help_article: `${HELP_ARTICLE}#h_668bdf3152`,
      by: [KEY_TECHNOLOGY, KEY_PRIORITY_YEAR],
      tags: [TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
      name: 'families',
      description: 'The number of patent families published each year by publication year and organisation.',
      help_article: `${HELP_ARTICLE}#h_2ac927de7c`,
      by: [KEY_ORGANISATION, KEY_PUBLICATION_YEAR],
      tags: [TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
      name: 'families',
      description: 'The number of patent families published each year by publication year and technology. ',
      help_article: `${HELP_ARTICLE}#h_ef7cf0677f`,
      by: [KEY_TECHNOLOGY, KEY_PUBLICATION_YEAR],
      tags: [TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID,
      name: 'families',
      description: 'The number of patent families granted each year by grant year and organisation.',
      help_article: `${HELP_ARTICLE}#h_06ac319134`,
      by: [KEY_ORGANISATION, KEY_GRANT_YEAR],
      tags: [TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID,
      name: 'families',
      description: 'The number of patent families granted each year by grant year and technology.',
      help_article: `${HELP_ARTICLE}#h_7fa5a6f7cc`,
      by: [KEY_TECHNOLOGY, KEY_GRANT_YEAR],
      tags: [TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: ENTRANCE_YEAR_BY_ORG_BY_TECH_ID,
      name: 'entrance year',
      description: 'Earliest priority year, by organisation and technology',
      help_article: `${HELP_ARTICLE}#h_06572e76aa`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_TIME, TAG_ORG, TAG_TECH],
      available_view_ids: [HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
      name: 'active families CAGR',
      get_description: ({item}) => {
        const {view_id} = item || {}
        if (view_id === CAGR_SCATTER_ID) {
          return 'Compound Annual Growth Rate (CAGR) in number of active families owned by each organisation over the specified time period, plotted against number of active families at the end of that time period. Excludes portfolios with fewer than 10 active families in either year.'
        }
        return 'Compound Annual Growth Rate (CAGR) in number of active families owned by each organisation over the specified time period. Excludes portfolios with fewer than 10 active families in either year.'
      },
      help_article: `${HELP_ARTICLE}#h_f6d47743a1`,
      by: [KEY_ORGANISATION],
      tags: [TAG_TIME, TAG_ORG, TAG_ACTIVE],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, CAGR_SCATTER_ID, CAGR_TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    },
    {
      spec_id: CAGR_ACTIVE_FAMILIES_BY_TECH_ID,
      name: 'active families CAGR',
      get_description: ({item}) => {
        const {view_id} = item || {}
        if (view_id === CAGR_SCATTER_ID) {
          return 'Compound Annual Growth Rate (CAGR) in number of active families in each technology over the specified time period, plotted against number of active families at the end of that time period. Excludes technologies with fewer than 10 active families in either year.'
        }
        return 'Compound Annual Growth Rate (CAGR) in number of active families in each technology over the specified time period. Excludes technologies with fewer than 10 active families in either year.'
      },
      help_article: `${HELP_ARTICLE}#h_ce08d473bf`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TIME, TAG_TECH, TAG_ACTIVE],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, CAGR_SCATTER_ID, CAGR_TABLE_ID],
      default_view_id: BAR_STACK_ID,
    },
    {
      spec_id: PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
      name: 'pending families %',
      description: 'Families which are at pending (application) stage as a percentage of all active families owned by each organisation.',
      help_article: `${HELP_ARTICLE}#h_15d1b31a97`,
      by: [KEY_ORGANISATION],
      tags: [TAG_PENDING, TAG_ORG],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    }
  ]
}