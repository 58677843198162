import React from 'react'
import cn from 'classnames'

import OrgSetsByGroup from './OrgSetsByGroup.js'

import cs from '../cipher_styles.module.scss'
import s from './OrgSetsPanel.module.scss'

const OrgSetsPanel = ({groups, on_org_set_select_handler, title, className }) => {
  return (
    <div className={className}>
      <div className={cn(cs.font_size_larger, s.heading)}>{title}</div>
      {groups.map((group, i) => (
        <OrgSetsByGroup
          key={i}
          group={group}
          on_org_set_select_handler={on_org_set_select_handler}
          className='pl-2 mt-2'
        />
      ))}
    </div>
  )
}

export default OrgSetsPanel