import React from 'react'
import cn from 'classnames'

import { PortfolioIcon } from '../widgets/IconSet.js'
import { get_patents_sample_with_cache } from '../../utils/organisation_utils.js'
import { format_integer_with_comma, pluralise_text } from '../../utils/utils.js'
import SamplePopup from './SamplePopup.js'

import s from './PatentSamplePopup.module.scss'

const PatentSamplePopup = ({org}) => {

  const { size_active: patent_count=0 } = org
  const patent_count_str = format_integer_with_comma(patent_count)

  function display_family(family) {
    const {title} = family
    return title
  }

  function get_heading_for_sample(families) {
    return `Sample patent ${pluralise_text(families.length,'family', 'families')}:`
  }

  const toggler = (<span className={cn([{[s.inactive]:  patent_count === 0}])}><PortfolioIcon /> {patent_count_str}</span>)
  const title = `${patent_count_str} active patent ${pluralise_text(patent_count,'family', 'families')}`

  return (
    <SamplePopup
      org={org}
      fetch_items_handler={get_patents_sample_with_cache}

      toggler={toggler}
      title={title}
      get_heading_handler={get_heading_for_sample}
      item_display_handler={display_family}
    />
  )
}

export default PatentSamplePopup