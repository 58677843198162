import React from 'react'
import cn from 'classnames'

import { usePrevious } from '../../../hooks/general_hooks.js'
import { is_IE11 } from '../../../utils/browser_utils.js'
import PatentCard, { PageControlCard, InvisibleCard } from './PatentCard.js'

import s from './PatentCardList.module.scss'

const PatentCardList = (
  {
    top_margin,

    patents,
    subidx,

    show_classifier_controls,

    show_suggestion_strategy,
    no_highlighting,
    search_phrases,
    search_colours,
    highlight_prefix_only,
    source,
    set_label,
    className,
    on_show_details,

    eval_training_set_id,
    eval_classifier_data,

    num_pages,
    current_page,
    on_change_current_page,
    on_change_subidx,
    hide_current_subidx,

    similar_search_input_family_ids,
    similar_search_input,
    on_include_family_id_in_similar_search_input,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,

    is_fetching
}) => {
  const prev_subidx = usePrevious(subidx)
  const prev_page   = usePrevious(current_page)
  const is_back_move = (prev_subidx != null) && (subidx === prev_subidx - 1) && (prev_page === current_page) // true if user has moved to previous card

  const show_next_page_control = (num_pages > 0) && (current_page < (num_pages - 1))
  const show_prev_page_control = (num_pages > 0) && (current_page > 0)

  const browser_is_IE_11 = is_IE11()

  if (is_fetching || !patents || (patents.length === 0)) {
    return (
      // return an empty div
      <div/>
    )
  }

  return (
    <div
      className={cn(s.grid_container, className)}
    >
      {show_prev_page_control &&
        <PageControlCard
          show_prev={true}
          num_pages={num_pages}
          current_page={current_page}
          on_change_current_page={on_change_current_page}
        />
      }

      {patents.map((patent, i) => {
        const is_selected = (i === subidx)

        return (
          <PatentCard
            className={cn({ [s.card__IE11]: browser_is_IE_11 })}
            key={i}
            top_margin={top_margin}
            is_back_move={is_back_move}
            idx={i}
            subidx={i}
            patent={patent}
            show_suggestion_strategy={show_suggestion_strategy}
            show_classifier_controls={show_classifier_controls}
            is_selected={is_selected && !hide_current_subidx}
            no_highlighting={no_highlighting}
            search_phrases={search_phrases}
            search_colours={search_colours}
            highlight_prefix_only={highlight_prefix_only}
            source={source}
            set_label={set_label}
            on_show_details={on_show_details}
            on_set_selected={() => {
              if (!is_selected) {
                on_change_subidx(i)
              }
            }}
            eval_training_set_id={eval_training_set_id}
            eval_classifier_data={eval_classifier_data}

            similar_search_input_family_ids={similar_search_input_family_ids}
            similar_search_input={similar_search_input}
            on_include_family_id_in_similar_search_input={on_include_family_id_in_similar_search_input}

            knn_family_ids={knn_family_ids}
            knn_blocklist={knn_blocklist}
            knn_bookmarked={knn_bookmarked}
            on_add_to_knn_family_ids={on_add_to_knn_family_ids}
            on_add_to_knn_blocklist={on_add_to_knn_blocklist}
            on_add_to_knn_bookmarked={on_add_to_knn_bookmarked}
          />
        )
      })}

      {show_next_page_control &&
        <PageControlCard
          show_prev={false}
          num_pages={num_pages}
          current_page={current_page}
          on_change_current_page={on_change_current_page}
        />
      }

      {/* Some Invisible cards, to prevent size changing if user adds/remove cards and columns resize */}
      <InvisibleCard />
      <InvisibleCard />
      <InvisibleCard />

    </div>
  )
}

export default PatentCardList