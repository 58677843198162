import React from 'react'
import cn from 'classnames'
import { Jumbotron } from 'reactstrap'

import { PaneHeader } from './PaneHeader.js'

import s from './Block.module.scss'

export const Pane = ({title, className, children, light, font_size}) => {
  const FONT_SIZE_TO_CLASS = {
    'normal': s.text__normal
  }

  return (
    <Jumbotron
      className={cn(
        s.pane,
        {[s.pane__light]: light},
        FONT_SIZE_TO_CLASS[font_size],
        className,
        'mb-0'
      )}
    >
      {title &&
        <PaneHeader text={title} />
      }
      {children}
    </Jumbotron>
  )
}

export const Card = ({title, className, hint, children}) => {
  return (
    <div className={cn('p-2', s.card, className)}>
      {(title || hint) &&
        <PaneHeader text={title} hint={hint} />
      }
      {children}
    </div>
  )
}