import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { Label, LabelInline } from './FamilyDetailsLabel.js'
import Spinner from '../widgets/Spinner.js'

import { get_key_legal_events } from '../../utils/family_view_utils.js'
import { PatentLink } from '../widgets/PatentLink.js'
import {
  LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT,
  LEGAL_EVENT_CATEGORY_EXPIRY,
  LEGAL_EVENT_CATEGORY_GRANT,
  LEGAL_EVENT_CATEGORY_LAPSED,
  LEGAL_EVENT_CATEGORY_PUBLICATION,
  LEGAL_EVENT_CATEGORY_REASSIGNED, LEGAL_EVENT_CATEGORY_SECURITISED
} from '../../model/family_view.js'
import { pluralise_text } from '../../utils/utils.js'

import s from './FamilyLegalEventsSummary.module.scss'

const MAX_LEGAL_EVENTS = 8

const FamilyLegalEventsSummary = ({ legal_events, on_show_all_events, is_fetching, error_fetching, patent_link_mode_id, preferred_language }) => {

  function get_summary_description(event) {
    const { category, publications, new_owners } = event

    const count = publications.length

    const publication_links = publications.map((item, i) => [
      i > 0 &&  (i !== count-1 ? ', ' : ' and '),
      <PatentLink
        key={i}
        patent_number={item}
        patent_link_mode_id={patent_link_mode_id}
        className={s.patent_link}
      />
    ])

    switch (category) {
      case LEGAL_EVENT_CATEGORY_GRANT: return (<span>{publication_links} <span className={s.highlight}>granted</span></span>)
      case LEGAL_EVENT_CATEGORY_EXPIRY: return (<span>{publication_links} <span className={s.highlight}>expired</span></span>)
      case LEGAL_EVENT_CATEGORY_LAPSED: return (<span>{publication_links} <span className={s.highlight}>lapsed</span></span>)
      case LEGAL_EVENT_CATEGORY_SECURITISED: return (<span>{publication_links} <span className={s.highlight}>securitised</span></span>)
      case LEGAL_EVENT_CATEGORY_REASSIGNED: return (<span>{publication_links} <span className={s.highlight}>assigned</span> to {new_owners}</span>)
      case LEGAL_EVENT_CATEGORY_PUBLICATION: return (<span>Publication of {publication_links}</span>)
      case LEGAL_EVENT_CATEGORY_EXAMINATION_REPORT: return (<span>{publication_links} <span className={s.highlight}>intention to grant</span></span>)

      default: return ''
    }
  }

  function render_summary() {
    const events = get_key_legal_events(legal_events, preferred_language).slice(0, MAX_LEGAL_EVENTS)

    if (events.length === 0) {
      return (
        <div className={s.desc_wrapper}>(no key events to show)</div>
      )
    }

    let last_date = null

    return events.map((event, i) => {
      const { date } = event

      const description = get_summary_description(event)

      let date_to_show = ''

      if (!last_date || (last_date !== date)) {
        date_to_show = date
        last_date = date
      } else {
        date_to_show = ''
      }

      return (
        <div className='d-flex' key={i}>
          <LabelInline className='fs-mask'>{date_to_show}</LabelInline>
          <div className={cn(s.desc_wrapper, {[s.top_border]: (date_to_show === '')})}>
            {description}
          </div>
        </div>
      )
    })
  }

  const have_events = legal_events != null && legal_events.length > 0

  const count = have_events ? legal_events.length : 0

  return (
    <div>
      <Label>Key legal events</Label>

      {is_fetching &&
        <Spinner />
      }

      {error_fetching &&
        <div>There was an error fetching legal events</div>
      }

      {!is_fetching && !error_fetching && !have_events &&
        <div>(none)</div>
      }

      {!is_fetching && have_events &&
        <div>
          {render_summary()}
        </div>
      }

      {!is_fetching  && have_events &&
        <div><TextLink onClick={on_show_all_events}>Show {count} {pluralise_text(count, 'event')} details</TextLink></div>
      }
    </div>
  )
}

export default FamilyLegalEventsSummary