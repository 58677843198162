import React, { useState } from 'react'
import { Slider } from '@mui/material'
import cn from 'classnames'

import ContainerFullWidth from './ContainerFullWidth.js'
import { withUser } from './UserContext.js'
import { RAW_BODY } from './AmatTestBody.js'
import { Input } from 'reactstrap'
import { PrimaryButton } from './widgets/Button.js'
import { JSON_HEADER_CONFIG } from '../utils/choreo_utils.js'
import { CHOREO_BASE_URL } from '../constants/urls.js'
import axios from 'axios'
import ErrorBody from './ErrorBody.js'

const { portfolios }                  = RAW_BODY
const portfolio                       = portfolios[0]
const { pat_fam_ids, meta }           = portfolio
const { lines, inputs, publications } = meta
const MAX_ORGS = pat_fam_ids.length

const URL = `${CHOREO_BASE_URL}/report/existing`

const FULL_URL = `${window.location.protocol}//${window.location.host}${URL}`

function get_request_body(org_range) {
  const new_lines        = lines.slice(org_range[0], org_range[1])
  const new_inputs       = inputs.slice(org_range[0], org_range[1])
  const new_publications = publications.slice(org_range[0], org_range[1])

  const len = new_lines.length
  const new_name = `TEST: ${len} orgs [${org_range[0]} to ${org_range[1]}]`

  const new_pat_fam_ids = pat_fam_ids.slice(org_range[0], org_range[1])

  const new_meta = { ...meta, lines: new_lines, inputs: new_inputs, publications: new_publications }

  const new_portfolio = {
    ...portfolio,
    name: new_name,
    pat_fam_ids: new_pat_fam_ids,
    meta: new_meta
  }
  const new_portfolios = [ new_portfolio ]

  return { ...RAW_BODY, portfolios: new_portfolios, name: new_name  }
}

const AmatTest = ({ user }) => {

  const [org_range, set_org_range] = useState([0, MAX_ORGS])

  const [response, set_response] = useState(null)
  const [error_sending, set_error_sending] = useState(null)

  const req_body = get_request_body(org_range)

  function send_request() {
    set_response(null)
    set_error_sending(null)

    axios.post(URL, req_body, JSON_HEADER_CONFIG)
      .catch(err => {
        set_error_sending(err)
        throw err
      })
      .then(response => {
        set_response(response)
      }) 
  }

  return (
    <ContainerFullWidth className={cn('m-5')}>
      <h1 className={cn('mb-5')}>Applied Materials TEST</h1>

      <div className={cn('d-flex', 'align-items-center')}>
        <span className={cn('mr-3')}>Range:</span>
        <Slider
          value={org_range}
          onChange={(event, value) => set_org_range(value)}
          valueLabelDisplay="on"
          min={0}
          max={MAX_ORGS}
        />
      </div>

      <div className={cn('mt-3')}>
        <Input
          rows={10}
          type='textarea'
          className={cn()}
          value={JSON.stringify(req_body, null, 2)}
          onChange={(event) => { /* on_change(event.target.value) */ } }
          disabled={true}
        />
      </div>

      <div className={cn('mt-3')}>
        <PrimaryButton
          onClick={send_request}
        >
          Send request
        </PrimaryButton>
        <span className={cn('ml-2')}>to {FULL_URL}</span>

      </div>

      <div className={cn('mt-3')}>
        {response && 
          <div>
            <span>sent successfully: </span>
            <div>status {response.status}</div>
          </div>
        }

        {error_sending &&
          <div>
            <ErrorBody error={error_sending} />
            <div>{error_sending.status}</div>
          </div>
        }
      </div>
        


    </ContainerFullWidth>
  )
}

export default withUser(AmatTest)