import React from 'react'

import FamilyId from './FamilyId.js'

export const FamilyIdDisplay = (
  {
    family,
    selected_patent_number,
    alternative_family,
    alternative_label,
    on_show_alternative,
    is_missing,

    report_type,
    eval_training_set_id,
    eval_classifier_data,

    className,
}) => {

  const {patFamId: family_id } = family || {}
  const {patFamId: alternative_id} = alternative_family || {}

  const other_props = {
    is_missing,
    report_type,
    eval_training_set_id,
    eval_classifier_data
  }

  const has_alternative = (alternative_id != null) && (alternative_id !== 0)

  return (
    <div className={className} key={`family_id_${family_id}`}>
      <FamilyId
        family_id={family_id}
        selected_patent_number={selected_patent_number}
        display_as_text_only={true}
        display_as_linkable_icon={true}
        {...other_props}
      />
      {has_alternative &&
        <FamilyId
          family_id={alternative_id}
          heading={alternative_label || 'Replaced by'}
          display_as_linkable_text={true}
          on_family_id_click={on_show_alternative}
          {...other_props}
        />
      }
    </div>
  )
}