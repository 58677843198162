import axios from 'axios'
import _ from 'underscore'
import FileSaver from 'file-saver'

import { DATA_EXPORT_SERVICE_BASE_URL } from '../constants/urls.js'
import { BROWSER } from '../constants/constants.js'

import {
  TABLE_ID,
  DISPUTES_TABLE_ID,
  LICENCES_TABLE_ID,
  HEATMAP_ID,
  GROWTH_TABLE_ID,
  COLUMN_CLUSTER_ID,
  COLUMN_STACK_ID,
  COLUMN_ID,
  AREA_ID,
  CAGR_TABLE_ID,
  BAR_ID,
  BAR_STACK_ID,
  BAR_CLUSTER_ID,
  EXTENDED_TABLE_ID,
  GROWTH_SCATTER_ID,
  SCATTER_ID,
  CAGR_SCATTER_ID,
  BUBBLE_ID,
  CHOROPLETH_MAP_ID,
  TREEMAP_ID,
  GROWTH_TREEMAP_ID,
  UNHANDLED_TABLE
} from '../model/view_ids.js'

import { add_source_err_to_target_err } from './axios_utils.js'
import { get_group_and_spec_name } from './spec_utils.js'
import { calculate_custom_y_range } from './column_data_utils.js'

export const MIMETYPE_PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
export const MIMETYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const MIMETYPE_MS_XLSX = 'application/vnd.ms-excel'
export const MIMETYPE_PDF  = 'application/pdf'
export const MIMETYPE_SVG  = 'image/svg+xml'
export const MIMETYPE_CSV  = 'text/csv'
export const MIMETYPE_TSV  = 'text/tab-separated-values'
export const MIMETYPE_HTML = 'text/html'
export const MIMETYPE_JSON = 'application/json'
export const MIMETYPE_PLAIN = 'text/plain'
export const MIMETYPE_ZIP = 'application/zip'
export const MIMETYPE_BINARY = 'application/octet-stream'

export const EXCEL_FILE_EXT           = '.xlsx'
export const EXCEL_MICROSOFT_FILE_EXT = '.xls'
export const CSV_FILE_EXT             = '.csv'
export const JSON_FILE_EXT            = '.json'
export const POWERPOINT_FILE_EXT      = '.pptx'
export const PNG_FILE_EXT             = '.png'
export const TSV_FILE_EXT             = '.tsv'
export const PDF_FILE_EXT             = '.pdf'
export const TXT_FILE_EXT             = '.txt'
export const ZIP_FILE_EXT             = '.zip'

export const EXCEL_FILE_FORMAT = { ext: EXCEL_FILE_EXT, mime: MIMETYPE_XLSX }
export const PDF_FILE_FORMAT   = { ext: PDF_FILE_EXT,   mime: MIMETYPE_PDF  }
export const ZIP_FILE_FORMAT   = { ext: ZIP_FILE_EXT,   mime: MIMETYPE_ZIP  }

export const MULTI_COLUMN_VIEW_CLUSTERED = 'clustered'
export const MULTI_COLUMN_VIEW_STACKED = 'stacked'
export const AREA_PERCENT_STACKED = 'percent_stacked'
//export const AREA_STACKED = 'stacked' - also supported chart subtype

function is_table_view(view_id) {
  return _.contains([TABLE_ID, DISPUTES_TABLE_ID, LICENCES_TABLE_ID, GROWTH_TABLE_ID, CAGR_TABLE_ID, EXTENDED_TABLE_ID], view_id)
}

export function get_clean_view_id(view_id) {
  if (is_table_view(view_id)) {
    return TABLE_ID
  }

  if (_.contains( [COLUMN_CLUSTER_ID ,COLUMN_STACK_ID], view_id)) {
    return COLUMN_ID
  }

  if (_.contains( [BAR_CLUSTER_ID, BAR_STACK_ID], view_id)) {
    return BAR_ID
  }

  if (_.contains( [GROWTH_SCATTER_ID, CAGR_SCATTER_ID], view_id)) {
    return SCATTER_ID
  }

  if (_.contains([BUBBLE_ID, CHOROPLETH_MAP_ID, TREEMAP_ID, GROWTH_TREEMAP_ID], view_id)) {
    return UNHANDLED_TABLE
  }

  return view_id
}

function get_clean_view_id_for_filename(view_id) {
  if (view_id === COLUMN_CLUSTER_ID || view_id === COLUMN_STACK_ID) {
    return COLUMN_ID
  }
  if (view_id === BAR_CLUSTER_ID || view_id === BAR_STACK_ID) {
    return BAR_ID
  }

  if (view_id === GROWTH_SCATTER_ID || view_id === CAGR_SCATTER_ID) {
    return SCATTER_ID
  }

  return view_id
}

export function get_download_filename({report_title, view_id, is_summary, file_ext}) {
  const clean_view_id = get_clean_view_id_for_filename(view_id)
  return get_clean_filename(report_title + '_' + clean_view_id + (is_summary ? '_summary' : '') + file_ext)
}

export function get_chart_subtype(view_id) {
  switch (view_id) {
    case COLUMN_CLUSTER_ID:
    case BAR_CLUSTER_ID:
      return MULTI_COLUMN_VIEW_CLUSTERED
    case COLUMN_STACK_ID:
    case BAR_STACK_ID:
      return MULTI_COLUMN_VIEW_STACKED
    case AREA_ID: return AREA_PERCENT_STACKED
    default: return null
  }
}

export function get_data_export_input({ item, view, spec, data, key_dims, deref_data, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date }) {
  // Uses plotly data
  const { ViewComponent, view_id } = view
  const plotly_data = ViewComponent.get_plotly_data({ spec, data, key_dims, item, deref_data, external_report_id, report_series_sort, selected_sort_field_id, selected_sort_direction_id, data_creation_date })
  const title = get_group_and_spec_name(spec, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF)
  const clean_view_id = get_clean_view_id(view_id)
  const chart_subtype = get_chart_subtype(view_id)
  
  const { value_prefix, value_suffix } = spec
  const { description, get_description } = item

  const { x_axis_title, y_axis_title } = ViewComponent.get_axis_titles ? ViewComponent.get_axis_titles(spec, {item, key_dims}) : {}

  const view_has_axis = !is_table_view(view_id)

  const y_axis_range = spec.axis_range && view_has_axis ? calculate_custom_y_range({spec, plotly_data}) : null

  return {
    title,
    description: get_description ? get_description({item}) : description,
    view_id: clean_view_id,
    data: plotly_data,
    ...(x_axis_title ? { x_axis_title } : {}),
    ...(y_axis_title ? { y_axis_title } : {}),
    ...(y_axis_range ? { y_axis_range } : {}),
    ...(chart_subtype ? {chart_subtype} : {}),
    ...(value_prefix ? {value_prefix} : {}),
    ...(value_suffix ? {value_suffix} : {})
  }
}

/**
 * Calls the data-export-service.
 * @param {} inputs (array). Each input is used to create a chart.
 * @returns {} A powerpoint document, with one chart per slide
 */
export function fetch_powerpoint_document(inputs) {
  return fetch_file(`${DATA_EXPORT_SERVICE_BASE_URL}/report/pptx`, inputs)
}

/**
 * Calls the data-export-service.
 * @param {} inputs (array). Each input is used to create a chart.
 * @returns {} An excel document, with one chart per sheet.
 */
export function fetch_excel_document(inputs, includes_user_info) {
  let url = DATA_EXPORT_SERVICE_BASE_URL + '/report/xlsx'
  if (includes_user_info) {
    url =  url + '?includes_user_info=' + includes_user_info
  }
  
  return fetch_file(url, inputs, {is_v2_export: true})
}

 export function fetch_file(url, data, params) {
  const config = {
    headers: {'Content-Type': 'application/json'},
    responseType: 'arraybuffer', // need this to force axios to return binary data
    ...params ? {params} : {}
  }

  return axios.post(url, data, config)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch file: ')
      throw wrapped_err
    })
}

export function trigger_download(data, mime_type, filename) {
  const blob = data instanceof Blob ? data : new Blob([data], {type: mime_type})

  FileSaver.saveAs(blob, filename)
}

export function get_clean_filename(filename) {
  // replace all non-alpha-numerics (except . and underscore) with underscore
  return filename.replace(/"+/g, '').replace(/[^a-zA-Z0-9_.]+/g,'_').replace(/_+/g, '_')
}

export function is_too_big_for_microsoft_download() {
  return false
}

export function is_dom_to_png_available(view_id) {
  if (view_id === DISPUTES_TABLE_ID) {
    // Doesn't really make sense as a PNG export
    return false
  }

  if (BROWSER && _.contains(['iOS', 'Android OS'], BROWSER.os)) {
    // Currently not supported on mobile
    return false
  }

  if (BROWSER && _.contains(['ie', 'safari', 'edge'], BROWSER.name)) {
    // Safari, IE and EDGE are not supported at the moment.
    // See https://github.com/tsayen/dom-to-image#browsers
    return false
  }

  return true
}

const HEATMAP_CLASSNAME = 'overflow-auto'

function toggle_heatmap_classes_for_png_download(wrapper_element, add) {
  //heatmap export to png could output incomplete file
  //and the culprit being overflow settings applied to heatmap top element to enable scrolling
  //to fix the download output overflow settings need to be removed first and re-added after file is ready to download

  const heatmap = wrapper_element.children[0]
  const classes = heatmap.classList
  if (add) {
    classes.add(HEATMAP_CLASSNAME)
  } else {
    classes.remove(HEATMAP_CLASSNAME)
  }
}

export function pre_download_png(view_id, view_element) {
  if (view_id === HEATMAP_ID) {
    toggle_heatmap_classes_for_png_download(view_element, false)
  }
}

export function post_download_png(view_id, view_element) {
  if (view_id === HEATMAP_ID) {
    toggle_heatmap_classes_for_png_download(view_element, true)
  }
}

export function convert_svg_to_pdf(svg){
  return axios.post(`${DATA_EXPORT_SERVICE_BASE_URL}/report/pdf`, svg, {
    headers: {'Content-Type': MIMETYPE_SVG},
    responseType: 'blob' // need this to force axios to return binary data
  })
  .then(response => response.data)
  .catch(err => {
     const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to convert svg to pdf from data export service: ')
     throw wrapped_err
  })
}

export function get_mime_type(input_file) {
  if (input_file.type !== null && input_file.type !== '') {
    return input_file.type
  }
  const file_ext = input_file.name.split('.').pop()
  switch ('.' + file_ext) {
    case CSV_FILE_EXT: return MIMETYPE_CSV
    case EXCEL_FILE_EXT: return MIMETYPE_XLSX
    case EXCEL_MICROSOFT_FILE_EXT: return MIMETYPE_MS_XLSX
    case TXT_FILE_EXT: return MIMETYPE_PLAIN
    //todo: add more cases
    default : return ''
  }
}
