import React from 'react'
import mime from 'mime'

import { DISPUTE_ATTACHMENT_DOWNLOAD_URL } from '../constants/urls.js'
import { track_visit_event } from '../utils/tracking_utils.js'
import { get_domain_prefix } from '../utils/utils.js'
import ContainerFullWidth from './ContainerFullWidth.js'
import { DocViewer } from './widgets/DocViewer.js'

const DisputeDocView = ({ match }) => {
  document.title = 'Cipher: Dispute document'
  track_visit_event('page="dispute_doc"')

  const doc_id = match.params.id
  const src_url = get_domain_prefix() + `${DISPUTE_ATTACHMENT_DOWNLOAD_URL}/${doc_id}`

  const mime_type = mime.getType(doc_id)

  return (
    <ContainerFullWidth className='px-0'>
      <DocViewer src={src_url} mime_type={mime_type} className='w-100 h-100' title={doc_id} />
    </ContainerFullWidth>
  )
}

export default DisputeDocView