import React from 'react'
import cn from 'classnames'

import s from './DashboardTileInner.module.scss'

export const DashboardTileInner = ({children}) => {
  return (
    <div className={cn('d-flex align-items-start flex-column', s.panel)}>
      {children}
    </div>
  )
}