import React from 'react'

import { Heading } from './FamilyDetailsLabel.js'

import { Highlighter } from '../widgets/Highlighter.js'

import s from './FamilyTitle.module.scss'

export const FamilyTitle = ({ family, no_highlighting, highlight_prefix_only, search_words, search_colours }) => {
  const { title, patFamId } = family

  return (
    <div
      className={s.family_title}
      key={`title_${patFamId}`}
    >
      <Heading>Title</Heading>
      <Highlighter
        no_highlighting={no_highlighting}
        highlight_prefix_only={highlight_prefix_only}
        search_words={search_words}
        search_colours={search_colours}
        text_to_highlight={title}
      />
    </div>
  )
}