import React from 'react'

import ForwardBackSelector from './widgets/ForwardBackSelector.js'

/**
 *
 * @param {} className
 * @param {} current_page
 * @param {} num_pages
 * @param {} on_change_page
 * @returns {}
 */
const PageControl = ({
  className,
  buttonClassName,
  current_page,
  num_pages,
  on_change_current_page
}) => {
  return (
    <ForwardBackSelector
      className={className}
      buttonClassName={buttonClassName}
      label={'page'}
      current_idx={current_page}
      min_idx_incl={0}
      max_idx_excl={num_pages}
      on_prev={on_change_current_page.bind(null, current_page - 1)}
      on_next={on_change_current_page.bind(null, current_page + 1)}
    />
    )
}

export default PageControl