import React from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../widgets/Button'
import EditableText from '../widgets/EditableText.js'
import { get_normalised_name } from '../../utils/name_utils.js'

import s from './UploadAction.module.scss'

export const ReturnToInputBtn = ({label, size, on_click, className}) => {
  return (
    <PrimaryButton
      outline
      size={size}
      onClick={on_click}
      className={className}
    >
      {label || 'Back to input'}
    </PrimaryButton>
  )
}

const UploadAction = (
  {
    name,
    on_submit_handler,
    on_cancel_handler,
    on_name_change_handler,
    submit_button_label,
    cancel_button_label,

    show_buttons,
    enable_rename,
    can_submit,
    className
  }) => {
  function check_if_name_valid(new_name) {
    return (new_name !== '' ) && (new_name !== name)
  }

  function on_confirm(new_name) {
    on_name_change_handler(new_name)
  }

  if (!enable_rename && !show_buttons) return null

  return (
    <div className={cn('d-flex justify-content-between', s.block, className)}>
      <div className='w-100 my-auto'>
        {enable_rename &&
          <EditableText
            text={name}
            on_confirm={on_confirm}
            on_normalise={get_normalised_name}
            on_check_if_valid={check_if_name_valid}
          />
        }
      </div>


      {show_buttons &&
        <div className={cn('d-flex justify-content-end', s.controls_wrapper)}>
          <PrimaryButton
            size='sm'
            onClick={on_submit_handler}
            disabled={!can_submit}
          >
            {submit_button_label || 'Add to report'}
          </PrimaryButton>

          <ReturnToInputBtn
            on_click={on_cancel_handler}
            className='ml-2'
            label={cancel_button_label}
            size='sm'
          />
        </div>
      }
    </div>
  )
}

export default UploadAction