// Temporary workaround for scrolling to table rows.
// TODO: find a way of calculating this stuff dynamically (or remove sticky-top headers).

export const LIST_VIEW_PAGE_TOP = 227  // y where the headers end
export const LIST_VIEW_TABLE_TOP = 444 // y where the table starts (i.e. there may be some sticky controls in between)

export const GLOBAL_SEARCH_PAGE_TOP = 100
export const GLOBAL_SEARCH_TABLE_TOP = 140

export const CLASSIFIERS_PAGE_TOP = 170  // y where the headers end
export const CLASSIFIERS_TABLE_TOP = 320 // y where the table starts (i.e. there may be some sticky controls in between)

export const REPORT_PAGE_TOP = 160 // y where the header, titlebar and report controls bar ends


// Note the below relate to the constants in _layout.scss (* 16 to convert to pixels)
const HEADER_HEIGHT = 3.5 * 16
const CLASSIFIERS_EDITOR_HEADER_HEIGHT = 2.75 * 16
const CLASSIFIERS_EDITOR_CONTROLS_HEIGHT = 2 * 16
export const CLASSIFIERS_PATENT_LIST_TOP_MARGIN = HEADER_HEIGHT + CLASSIFIERS_EDITOR_HEADER_HEIGHT + CLASSIFIERS_EDITOR_CONTROLS_HEIGHT