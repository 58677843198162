import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { PaneHeader } from '../widgets/PaneHeader.js'

import s from './PortfolioSearchNavigationItem.module.scss'

const PortfolioSearchNavigationItem = ({ item, active, disabled, total_items, on_click }) => {

  function on_click_handler() {
    if (!is_active() && !is_disabled()) {
      on_click(item)
    }
  }

  function is_active() {
    return (item  === active)
  }

  function is_disabled() {
    return disabled || false
  }

  function get_nav_item_classname() {
    switch (total_items) {
      case 4: return s.nav_item_1_of_4
      case 3: return s.nav_item_1_of_3
      case 2: return s.nav_item_1_of_2
      case 1: return 'w-100'
      default:
    }
  }

  const {title, short_title} = item
  const classes = ['px-2', s.nav_item, (is_active() ? s.active : s.inactive), get_nav_item_classname(), (is_disabled() ? s.disabled : null)]

  const class_names = cn(classes)
  return (
    <div onClick={on_click_handler} className={class_names}>
      {!is_active() &&
        <div className='py-2'>
          <div className={s.action}>Switch to</div>
          <div className={s.title}><TextLink disable={is_disabled()}>{title}</TextLink></div>
          <div className={s.title__short}><TextLink disable={is_disabled()}>{short_title}</TextLink></div>
        </div>
      }

      {is_active() &&
        <div className='py-2'>
          <div className={s.title}>
            <PaneHeader text={title} />
          </div>
          <div className={s.title__short}>
            <PaneHeader className={s.title__short__active} text={short_title} />
          </div>
        </div>
      }
    </div>
  )
}

export default PortfolioSearchNavigationItem