import React from 'react'
import cn from 'classnames'

import RadioStatic from './RadioStatic.js'

import s from './RadiobuttonWithLabel.module.scss'

export const RadiobuttonWithLabel = ({label, is_checked, is_disabled, on_click, className, labelClassName}) => {
  return (
    <div className={cn('d-flex', className)}>
      <RadioStatic
        is_checked={is_checked}
        is_disabled={is_disabled}
        onClick={on_click}
      />{' '}<span onClick={is_disabled ? null : on_click} className={cn('ml-1',s.label, labelClassName)}>{label}</span>
    </div>
  )
}