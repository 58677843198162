import React, { useEffect, useState } from 'react'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import { Autocomplete, TextField } from '@mui/material'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'
import {
  tag_permission_as_str,
  TagPermission, UUIDType,
} from './family_tag_utils'
import TextLink from '../widgets/TextLink.js'

import s from './EntityPermission.module.scss'

interface EntityPermissionProps {
  entities: Array<any>,
  placeholder: string,
  disabled: boolean,
  selected_entity: Array<any>,
  set_selected_entity: Function,
  selected_permission: TagPermission,
  set_selected_permission: Function,
  update_permissions: Function,
  className: string,
}

const TagPermissionsKeys = Object.keys(TagPermission).filter((key) => isNaN(Number(key)))
const USER_STR_MARKER = '(USER) '
const GROUP_STR_MARKER = '(GROUP) '

function is_user(entity: any) {
  return entity.type === UUIDType.USER
}

export const EntityPermission = (
  {
    entities,
    placeholder,
    disabled,
    selected_entity,
    set_selected_entity,
    selected_permission,
    set_selected_permission,
    update_permissions,
    className
  }: EntityPermissionProps) => {

  const [autocomplete_entities, set_autocomplete_entities] = useState<Array<any>>([])
  const [invalid_selection, set_invalid_selection] = useState<boolean>(false)

  useEffect(() => {
    const marked_entities = entities.map((entity: any) => {
      return {label: (is_user(entity) ? USER_STR_MARKER : GROUP_STR_MARKER) + entity.display_name, id: entity.id}
    })
    set_autocomplete_entities(marked_entities)

  }, [entities])

  return (
    <div className={cn(className)}>
      <div className={'d-flex mb-2'}>
        <Autocomplete
          id={'cipher-users'}
          disablePortal
          clearOnBlur
          multiple
          limitTags={2}
          placeholder={'users'}
          options={autocomplete_entities}
          size={'small'}
          disabled={disabled}
          className={s.users}
          renderInput={(params) => (<TextField {...params} label={placeholder}/>)}
          onChange={on_change_entities}
        />
        <UncontrolledDropdown className={'ml-2 my-auto'}>
          <SelectDropdownToggleStatic disabled={disabled}>
            {tag_permission_as_str(selected_permission)}
          </SelectDropdownToggleStatic>
          <DropdownMenu>
            {TagPermissionsKeys.map((option, i) => (
              <DropdownItem key={i} onClick={() => set_selected_permission(option)}>
                {tag_permission_as_str(option)}
              </DropdownItem>
            ))
            }
          </DropdownMenu>
        </UncontrolledDropdown>
        {/*//@ts-expect-error*/}
        <TextLink onClick={check_and_update_permissions} className={'ml-2 my-auto'}>
          Add
        </TextLink>
      </div>
      {invalid_selection &&
        <div className={'d-block invalid-feedback mb-2'}>
          At least one user or group should be selected
        </div>
      }
    </div>)

  function check_and_update_permissions() {
    if (_.isEmpty(selected_entity)) {
      set_invalid_selection(true)
    } else {
      update_permissions()
    }
  }

  function on_change_entities(event: any, selection: any) {
    set_selected_entity(selection)
    if (!_.isEmpty(selection)) {
      set_invalid_selection(false)
    }
  }

}