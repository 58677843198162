import React, { useEffect, useRef } from 'react'
import cn from 'classnames'

import AutoResizeTextArea from '../widgets/AutoResizeTextArea.js'

import s from './SearchTextInput.module.scss'

const SearchTextInput = (
  {
    input_text,
    on_change_input_text,
    auto_focus,
    containerClassName
  }) => {

  const input_ref = useRef()

  useEffect(() => {
    if (auto_focus) {
      // Workaround to ensure focus is set
      // This runs when the component first mounts
      input_ref.current.focus()
    }
  }, [auto_focus, input_ref])

  return (
    <AutoResizeTextArea
      placeholder={`Enter patent numbers or families, a technology, paragraphs of text or a combination (use a new line for each criteria type)`}
      value={input_text || ''}
      on_change={on_change_input_text}
      auto_focus={auto_focus}
      rows={6}

      inner_ref={input_ref}

      className={cn('p-1', s.input)}
      containerClassName={cn(s.container, containerClassName)}
    />
  )
}

export default SearchTextInput