import axios from 'axios'
import _ from 'underscore'

import { COMPANY_LISTS_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'
import {
  check_for_invalid_organisations, fetch_multiple_assignees_by_ids, fetch_multiple_organisations_by_ids,
  get_all_assignee_ids,
  get_assignee_ids_for_organisation,
  is_agglomeration,
  is_assignee,
  is_organisation
} from './organisation_utils.js'
import { is_array_non_empty_non_null, sum } from './utils.js'

export const SHARED_LIST_INFO = 'Shared lists can be used and edited by any Cipher user in your organisation.'
const MAX_COMPANIES_IN_LIST = 100
const MAX_COMPANIES_IN_LIST_INTERNAL = 250
export const MAX_LIST_NAME_LENGTH = 50
export const MAX_UNSAVEABLE_ITEMS_TO_DISPLAY = 5


export function get_max_companies_allowed_in_list(is_group_aistemos) {
  return is_group_aistemos ? MAX_COMPANIES_IN_LIST_INTERNAL : MAX_COMPANIES_IN_LIST
}

// FETCHING LISTS

export function fetch_company_lists() {
  return axios.get(`${COMPANY_LISTS_BASE_URL}/list`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch company lists: ')
      throw wrapped_err
    })
}

// CREATING AND UPDATING LISTS

export function check_for_reason_portfolios_cannot_be_saved(portfolios_to_save, unsaveble_portfolios, is_group_aistemos) {
  const max_companies_in_list = get_max_companies_allowed_in_list(is_group_aistemos)
  if (_.isEmpty(portfolios_to_save)) {
    return _.isEmpty(unsaveble_portfolios) ? 'Add organisations to create a report or save a list' : 'Only whole organisations from searches or existing lists can be saved to Org Sets'
  } else if (portfolios_to_save.length > max_companies_in_list) {
    return `The maximum size for a saved list is ${max_companies_in_list} organisations`
  }
  return null // no reason; all good
}

export function save_portfolio_basket_as_company_list(list_name, portfolios, is_shared) {
  return prepare_list_companies_to_save(portfolios)
    .then(companies => create_new_company_list(list_name, companies, is_shared))
}

export function update_company_list_from_portfolio_basket(company_list_id, portfolios) {
  return prepare_list_companies_to_save(portfolios)
    .then(companies => update_companies_in_list(company_list_id, companies))
}

function prepare_list_companies_to_save(portfolios) {
  return Promise.all(portfolios.map(item => extract_properties_for_saving_to_list(item)))
}

export function create_new_company_list(list_name, companies, is_shared) {
  const data = {list_name, companies, is_shared}
  return axios.post(`${COMPANY_LISTS_BASE_URL}/list`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create new company list: ')
      throw wrapped_err
    })
}

export function update_companies_in_list(company_list_id, companies) {
  const data = {companies}
  return axios.put(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to update company list: ')
      throw wrapped_err
    })
}

export function delete_companies_from_list(company_list_id, company_ids_to_delete) {
  const data = {company_ids_to_delete}
  return axios.put(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}/delete_companies`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete companies from list: ')
      throw wrapped_err
    })
}

export function rename_list(company_list_id, list_name) {
  const data = {list_name}
  return axios.put(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}/rename`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to rename list: ')
      throw wrapped_err
    })
}

export function share_or_unshare_list(company_list_id, is_shared) {
  const data = {is_shared}

  return axios.put(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}/sharing`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to toggle list sharing status: ')
      throw wrapped_err
    })
}

export function update_companies_fields(company_list_id, data) {
  return axios.put(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}/update_companies`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to update companies in list: ')
      throw wrapped_err
    })
}

export function extract_properties_for_saving_to_list(company) {
  const extracted_properties = _.pick(company, 'name', 'original_name', 'type', 'id', 'assignee_ids')
  const {id, assignee_ids} = extracted_properties

  if (is_agglomeration(company)) {
    // assignee ids array may already be populated if this comes from a saved list rather than a search
    const assignee_ids_to_save = is_array_non_empty_non_null(assignee_ids) ? assignee_ids : get_all_assignee_ids(company)
    return {...extracted_properties, organisation_id: null, assignee_ids: assignee_ids_to_save}

  } else if (is_assignee(company)) {
    return {...extracted_properties, organisation_id: null, assignee_ids: [id]}

  } else if (is_organisation(company)) {
    return get_assignee_ids_for_organisation(id)
      .then(assignee_ids_to_save => {
        return {...extracted_properties, organisation_id: id, assignee_ids: assignee_ids_to_save}
      })
  }
  return {...extracted_properties, organisation_id: id || null} // this probably shouldn't ever happen? but just in case...
}

// FETCHING COMPANIES FOR A REPORT

export function fetch_list_companies_for_basket(company_list_id, fetch_sizes) {
  return fetch_companies_in_list(company_list_id)
    .then(companies => prepare_list_companies_for_basket(companies, fetch_sizes))
}

export function fetch_companies_in_list(company_list_id) {
  return axios.get(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}/company`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch companies in list: ')
      throw wrapped_err
    })
}

function prepare_list_companies_for_basket(companies, fetch_sizes) {
  // separate orgs, assignees and agglom assignees before fetching sizes
  const [orgs, assignees_and_agglom] = _.partition(companies, company => is_organisation(company))

  return separate_out_missing_orgs(orgs)
    .then(([verified_orgs, missing_orgs]) => {
      // fetching org sizes is expensive, so only do this when we really need to (ie in the org lists ui)
      if (!fetch_sizes) {
        return [[...verified_orgs, ...assignees_and_agglom], missing_orgs]
      }
      const [assignees, agglomerated_assignees] = _.partition(assignees_and_agglom, assignee => is_assignee(assignee))
      return Promise.all([
        fetch_org_sizes(verified_orgs),
        fetch_assignee_sizes(assignees),
        fetch_agglom_assignee_sizes(agglomerated_assignees)
      ])
        .then(([orgs_with_sizes, assignees_with_sizes, aggloms_with_sizes]) => {
          return [[...orgs_with_sizes, ...assignees_with_sizes, ...aggloms_with_sizes], missing_orgs]
        })
    })
}

function separate_out_missing_orgs(saved_orgs) {
  if (_.isEmpty(saved_orgs)) {
    return Promise.resolve([[],[]])
  }
  // TODO later: fetch assignees and if there's a big discrepancy in assignees since an org was saved in the list then query/ make suggestions in the UI
  const org_ids = saved_orgs.map(org => org.id)
  return check_for_invalid_organisations({org_ids})
    .then(ids_of_missing_orgs => _.partition(saved_orgs, org => !_.contains(ids_of_missing_orgs, org.id)))
}

function fetch_org_sizes(verified_orgs) {
  if (_.isEmpty(verified_orgs)) {
    return Promise.resolve([])
  }
  const org_ids = verified_orgs.map(org => org.id)
  return fetch_multiple_organisations_by_ids(org_ids)
    .then(fetched_orgs => {
      return verified_orgs.map(org => {
        const org_latest = _.find(fetched_orgs, fetched_org => fetched_org.id === org.id)
        const {size, size_active, assignee_ids} = org_latest
        return {...org, size_active, size, assignee_ids}
      })
    })
}

function fetch_assignee_sizes(saved_assignees) {
  if (_.isEmpty(saved_assignees)) {
    return Promise.resolve([])
  }
  const assignee_ids = saved_assignees.map(assignee => assignee.id)
  return fetch_multiple_assignees_by_ids(assignee_ids)
    .then(fetched_assignees => {
      return saved_assignees.map(assignee => {
        const assignee_latest = _.find(fetched_assignees, fetched_assignee => fetched_assignee.id === assignee.id)
        const {size, size_active} = assignee_latest
        return {...assignee, size_active, size}
      })
    })
}

function fetch_agglom_assignee_sizes(saved_aggloms) {
  // it should be pretty rare to see these in saved lists so hopefully fine to request their assignees all at once
  return Promise.all(saved_aggloms.map(agglom => {
    const {assignee_ids} = agglom
    return fetch_multiple_assignees_by_ids(assignee_ids)
      .then(assignees => {
        const size = sum(assignees.map(assignee => assignee.size))
        const size_active = sum(assignees.map(assignee => assignee.size_active))
        return {...agglom, size, size_active}
      })
  }))
}


// DELETING LISTS

export function delete_company_list(company_list_id) {
  return axios.delete(`${COMPANY_LISTS_BASE_URL}/list/${company_list_id}`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete company list: ')
      throw wrapped_err
    })
}

export function list_is_empty(list) {
  const {company_count} = list
  return !company_count || company_count < 1
}