import {

  ALERT_PARAM_PUB_TYPE,
  ALERT_FILTER_TYPE_PVIX_THRESHOLD,
  ALERT_FILTER_TYPE_STATUS,
  ALERT_FREQUENCY_ID_BIWEEKLY,
  ALERT_FREQUENCY_ID_MONTHLY,
  ALERT_FREQUENCY_ID_QUARTERLY,
  ALERT_FREQUENCY_ID_WEEKLY,
  ALERT_PUBLICATION_TYPE_APPLICATION,
  ALERT_PUBLICATION_TYPE_GRANT,
  ALERT_TYPE_ID_NEW_FAMILIES,
  ALERT_TYPE_ID_NEW_FILINGS,
  ALERT_TYPE_ID_PVIX_INCREASE,
  ALERT_TYPE_ID_STATUS_CHANGE,
  ALERT_PARAM_NEW_FAMS
} from '../constants/alert_reports.js'
import { STATUS_EXPIRED, STATUS_GRANTED, STATUS_PENDING } from './statuses.js'
import { get_as_map } from '../utils/utils.js'

export const ALERT_REPORT_TYPES = [
  { id: ALERT_TYPE_ID_NEW_FAMILIES, name: 'New patent families'},
  { id: ALERT_TYPE_ID_NEW_FILINGS, name: 'New patent filings'},
  { id: ALERT_TYPE_ID_STATUS_CHANGE, name: 'Family status'},
  { id: ALERT_TYPE_ID_PVIX_INCREASE, name: 'PVIX increases', description: 'Alert on families whose PVIX score has increased above a particular threshold for the first time during the alert reporting period.'},
]
export const ALERT_REPORT_TYPE_NAME_DELTA = 'Delta'

export const ALERT_REPORT_TYPES_BY_ID = get_as_map(ALERT_REPORT_TYPES, 'id')

export const PUBLICATION_TYPES = [
  { id: ALERT_PUBLICATION_TYPE_APPLICATION, name: 'Application', label: 'New applications'},
  { id: ALERT_PUBLICATION_TYPE_GRANT, name: 'Grant', label: 'New grants' }
]

export const DEFAULT_SELECTED_PUBLICATION_TYPES = PUBLICATION_TYPES.map(pub_type => pub_type.id)

export const ALERT_FAMILY_STATUSES = [
  STATUS_PENDING,
  STATUS_GRANTED,
  STATUS_EXPIRED
].map(status => ({...status, label: `Newly ${status.name.toLowerCase()}`}))

export const DEFAULT_SELECTED_STATUSES = [STATUS_PENDING.id, STATUS_GRANTED.id]
export const ALL_ALERT_STATUSES = ALERT_FAMILY_STATUSES.map(status => status.id)

export const DEFAULT_ALERT_FILTERS = {
  [ALERT_FILTER_TYPE_STATUS]: DEFAULT_SELECTED_STATUSES,
  [ALERT_FILTER_TYPE_PVIX_THRESHOLD]: 50,
  [ALERT_PARAM_PUB_TYPE]: DEFAULT_SELECTED_PUBLICATION_TYPES,
  [ALERT_PARAM_NEW_FAMS]: true
}

export const ALERT_FREQUENCIES = [
  { id: ALERT_FREQUENCY_ID_WEEKLY, name: 'Weekly'},
  { id: ALERT_FREQUENCY_ID_BIWEEKLY, name: 'Biweekly'},
  { id: ALERT_FREQUENCY_ID_MONTHLY, name: 'Monthly'},
  { id: ALERT_FREQUENCY_ID_QUARTERLY, name: 'Quarterly'}
]

export const ALERT_FREQUENCIES_BY_ID = get_as_map(ALERT_FREQUENCIES, 'id')