import React, { useEffect, useState } from 'react'

import FamilyClass from './FamilyClass.js'
import { TileContent } from './TileContent.js'
import Spinner from '../widgets/Spinner.js'
import { get_available_utt_labels } from '../../utils/static_data_utils.js'
import { DEPRECATED_UTT_VERSION_IDS } from '../../model/utt_version.js'

const CIPHER_UTT_NODE_NAME = 'Cipher UTT'

const FamilyClassWrapper = ({className, family, family_technology, report_input}) => {

  const {technology_partitioning} = report_input || {}
  const {version_id} = technology_partitioning || {}

  const is_deprecated_version = (version_id == null || DEPRECATED_UTT_VERSION_IDS.indexOf(version_id) !== -1)

  const [classes, set_classes] = useState(null)
  const [is_fetching, set_is_fetching] = useState(false)
  const [fetching_error, set_fetching_error] = useState(null)

  useEffect(() => {
    if (is_deprecated_version) return

    set_is_fetching(true)
  }, [is_deprecated_version])

  useEffect(() => {
    if (!is_fetching) return

    get_available_utt_labels(version_id)
      .then(response => {
        set_classes(response)
        set_is_fetching(false)
      })
      .catch(error => {
        set_fetching_error(error)
        set_is_fetching(false)
      })
  }, [is_fetching, version_id])

  if (is_deprecated_version) return null

  if (is_fetching) {
    return (<Spinner />)
  }

  if (fetching_error) {
    return (<TileContent>Error</TileContent>)
  }

  function get_current_family_class() {
    return (family_technology || []).map(technology => {
      const {tree_path, name} = technology || {}

      if (!tree_path) {
        return name || ''
      }

      return (tree_path || []).filter(name => name !== CIPHER_UTT_NODE_NAME).join('/')
    }).join(', ')
  }

  return (
    <FamilyClass
      family={family}
      current_class={get_current_family_class()}
      available_classes={classes || {}}

      className={className}
    />
  )
}

export default FamilyClassWrapper