import React from 'react'
import { DropdownItem, Label } from 'reactstrap'

import BaseDropdown from '../widgets/BaseDropdown.js'
import {
  DEFAULT_GROUP_BY_OWNER_LEVEL,
  GROUP_BY_OWNER_LEVELS,
  ID_TO_GROUP_BY_OWNER_LEVELS,
} from '../../model/group_by_owner_level.js'

const OwnerLevelControl = ({selected_level, on_select, className, disabled}) => {
  const label = disabled ? 'n/a' : (ID_TO_GROUP_BY_OWNER_LEVELS[selected_level || DEFAULT_GROUP_BY_OWNER_LEVEL].name)

  return (
    <div className={className}>
      <Label className='my-auto mr-1'>Group by owner level:</Label>
      <BaseDropdown
        disabled={disabled}
        label={label}
        right={false}
        menuClassName='w-auto'
      >
        {GROUP_BY_OWNER_LEVELS.map((item, i) => {
          const {id, name} = item
          return (
            <DropdownItem
              key={i}
              disabled={id === selected_level}
              onClick={() => on_select(id)}
            >
              {name}
            </DropdownItem>
          )
        })}
      </BaseDropdown>
    </div>
  )
}

export default OwnerLevelControl