import React from 'react'

import { has_user_selected_anonymous_mode } from '../../utils/knn_search.js'
import { PinIcon, UnpinIcon } from './TechExplorerIcons.js'
import TextLink from '../widgets/TextLink.js'

const PinSearchControl = ({is_saved, on_change, className}) => {
  return (
    <TextLink disable={has_user_selected_anonymous_mode() === true} onClick={on_change} className={className} title={`${is_saved ? 'Unpin' : 'Pin'} this search`}>
      {is_saved ? <PinIcon /> : <UnpinIcon />}
    </TextLink>
  )
}

export default PinSearchControl