import React from 'react'
import cn from 'classnames'

import { DEFAULT_TEXT_COLOUR_OVER_DARK_BG } from '../../constants/colours.js'

import { find_all, check_need_light_complimentary_colour, contains_sub_or_superscript_tags_only } from '../../utils/highlight_utils.js'
import { remove_double_spaces } from '../../utils/utils.js'

import s from './Highlighter.module.scss'

export const Highlighter = ({
  highlight_class_names,
  search_words,
  search_colours,
  highlight_prefix_only,
  text_to_highlight,
  no_highlighting,
  input_is_raw_html // if true, outputs using dangerouslySetInnerHTML
}) => {
  if (!text_to_highlight) {
    return <span/>
  }

  if (no_highlighting || !search_words) {
    if (input_is_raw_html) {
      // input is raw html, so render it directly
      return (
        <span dangerouslySetInnerHTML={{__html: text_to_highlight}}/>
      )
    }
    return <span>{text_to_highlight}</span>
  }

  const text_to_highlight_clean = remove_double_spaces(text_to_highlight)

  const chunks = find_all({
    search_words,
    text_to_highlight: text_to_highlight_clean,
    highlight_prefix_only,
    auto_escape: true,
    input_is_raw_html
  })

  return (
    <span>
      {chunks.map((chunk, index) => {
        const { start, end, highlight, search_words_idx } = chunk
        const text = text_to_highlight_clean.substring(start, end)
        if (highlight) {
          const custom_bg_hex_colour   = search_colours ? search_colours[search_words_idx]           : null

          const need_light_text = search_colours ? check_need_light_complimentary_colour(custom_bg_hex_colour) : false

          if (contains_sub_or_superscript_tags_only(text)) {
            return (
              <span
                key={index}
                className={cn(
                  s.highlight,
                  {[s.highlight__default]: !custom_bg_hex_colour},
                  highlight_class_names
                )}
                style={{
                  ...(custom_bg_hex_colour ? { backgroundColor: custom_bg_hex_colour }   : {}),
                  ...(need_light_text      ? { color: DEFAULT_TEXT_COLOUR_OVER_DARK_BG } : {})
                }}
                dangerouslySetInnerHTML={{__html: text}}
              />
            )
          }

          // It's a highlighted chunk, so render with classes etc
          return (
            <span
              key={index}
              className={cn(
                s.highlight,
                {[s.highlight__default]: !custom_bg_hex_colour},
                highlight_class_names
              )}
              style={{
                ...(custom_bg_hex_colour ? { backgroundColor: custom_bg_hex_colour }   : {}),
                ...(need_light_text      ? { color: DEFAULT_TEXT_COLOUR_OVER_DARK_BG } : {})
              }}
            >
              {text}
            </span>
          )
        } else if (input_is_raw_html) {
          // No highlight - input is raw html, so render it directly
          return <span key={index} dangerouslySetInnerHTML={{__html: text}}/>

        } else {

          // No highlight - just render input normally
          return (
            <span key={index}>{text}</span>
          )
        }
      })}
    </span>
  )
}