import React from 'react'
import cn from 'classnames'

import s from './PageContainer.module.scss'

const PageContainer = ({ children }) => {
  return (
    <div className={cn(s.block)}>
      {children}
    </div>
  )
}

export default PageContainer