import React from 'react'

import { LabelInline } from './FamilyDetailsLabel.js'
import { TileContent } from './TileContent.js'
import { to_date } from '../../utils/utils.js'
import { FAMILY_STATUS_EXPIRED_ID, STATUS_TO_STATUS_LABEL } from '../../constants/family_status.js'

export const FamilyDates = ({family}) => {
  const { priorityDate, grantedDate, expiryDate } = family

  function get_expired_label() {
    const { status } = family
    const is_expired = (status === STATUS_TO_STATUS_LABEL[FAMILY_STATUS_EXPIRED_ID])

    return `Expiry${(is_expired || !grantedDate) ? '' : ' (est.)'}`
  }

  return (
    <div>
      <div className='d-flex'>
        <LabelInline>Priority</LabelInline>
        <TileContent>{to_date(priorityDate, '')}</TileContent>
      </div>

      <div className='d-flex'>
        <LabelInline>Granted</LabelInline>
        <TileContent>{to_date(grantedDate, 'Not yet granted')}</TileContent>
      </div>

      <div className='d-flex'>
        <LabelInline>{get_expired_label()}</LabelInline>
        <TileContent>{(grantedDate) ? to_date(expiryDate, '') : 'N/A' }</TileContent>
      </div>
    </div>
  )
}