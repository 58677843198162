import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import { PaneHeader } from '../widgets/PaneHeader.js'
import { HYPERSCRIPT_BY_ID } from '../../utils/hyperscripts_utils.js'
import { withUser } from '../UserContext.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import Hyperscript from '../hyperscripts/Hyperscript.js'
import TextLink from '../widgets/TextLink.js'
import { is_aistemos, is_lotnet, has_lotnet_export } from '../../utils/user_permissions.js'
import { ChevronCircleRightIcon } from '../widgets/IconSet.js'
import LotnetExport from '../hyperscripts/LotnetExport.js'
import {
  EXECUTIVE_SUMMARY_ID,
  BENCHMARKING_REPORT_ID,
  HYPERSCRIPT_GROUPS,
  UTILITY_GROUP_ID,
  LOTNET_REPORT_ID,
  UTT_REPORT_ID,
  TREND_SPOTTER_ID,
  ALERT_SETUP_ID
} from '../../model/hyperscripts.js'

import { Pane } from '../widgets/Block.js'
import ExecSummaryHyperscript from '../hyperscripts/ExecSummaryHyperscript.js'
import { scroll_window_to_top } from '../../utils/viewer_utils.js'
import ErrorModal from '../ErrorModal.js'
import {
  fetch_benchmarking_input_orgs_and_techs,
  fetch_existing_benchmarking_params
} from '../../utils/benchmarking_report_builder_utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import Spinner from '../widgets/Spinner.js'
import { EXPORT_SUBPATH } from '../../constants/viewer_paths.js'
import { InfoPopover } from '../widgets/Tooltip.js'

import s from './ExportsView.module.scss'

const Option = ({selected, label, on_click, show_spinner, disabled}) => {
  return (
    <div className={cn('d-flex', s.option)}>
      <div className={cn('my-auto',s.option_icon)}>
        {selected && <ChevronCircleRightIcon />}
      </div>
      <TextLink disable={disabled} onClick={on_click} className={s.option}>{label}</TextLink>
      {show_spinner &&
        <span><Spinner size='xs'/></span>
      }
    </div>
  )
}

const ExportsView = (
  {
    history,
    location,
    user,
    report_title,
    report_input,
    report_has_scores,
    no_data,
    is_incomplete,
    created_by_user,
    is_set_theory_report,
    selected_export_id,
    params_id,

    has_missing_assignee_links,
    report_type,
    ref_data,
    deref_data,
    internal_report_id,
    external_report_id,
    data_creation_date,
    selections,
  }) => {

  const can_use_lotnet_export = is_aistemos(user) || is_lotnet(user) || has_lotnet_export(user)

  const [benchmarking_merged_inputs, set_benchmarking_merged_inputs] = useState(null)
  const [benchmarking_params, set_benchmarking_params] = useState([null, null])
  const [is_fetching_benchmarking_merged_inputs, set_is_fetching_benchmarking_merged_inputs] = useState(true)
  const [error_fetching_benchmarking_params, set_error_fetching_benchmarking_params] = useState(null)

  const has_benchmarking_org_lists = (benchmarking_merged_inputs !== null)

  const selected_hyperscript = get_selected_hyperscript_by_id(selected_export_id)

  if (selected_export_id && !selected_hyperscript) {
    const [base_path,] = location.pathname.split(EXPORT_SUBPATH)
    history.replace({pathname: `${base_path}${EXPORT_SUBPATH}`})
  }

  useEffect(() => {
    scroll_window_to_top()
    asynchronously_fetch_benchmarking_merged_inputs(internal_report_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function get_selected_hyperscript_by_id(id) {
    if (id === LOTNET_REPORT_ID) {
      return can_use_lotnet_export ? {id: LOTNET_REPORT_ID} : null
    }

    const hs = HYPERSCRIPT_BY_ID[id]

    if (!hs) return null

    const {permissions_endpoint, option_disabled} = hs
    const is_allowed = (permissions_endpoint) ? permissions_endpoint({
      user,
      report_type,
      has_missing_assignee_links,
      created_by_user,
      is_set_theory_report,
      report_input,
      ref_data
    }) : true
    const is_disabled_for_this_report = option_disabled && option_disabled({report_input, report_type, data_creation_date, created_by_user, has_benchmarking_org_lists, is_incomplete, params_id})

    return (is_allowed && !is_disabled_for_this_report) ? hs : null
  }

  function set_selected_hyperscript(hyperscript) {
    const [base_path,] = location.pathname.split(EXPORT_SUBPATH)

    const {id} = hyperscript || {}
    const new_path = hyperscript ? `${base_path}${EXPORT_SUBPATH}/${id}` : `${base_path}${EXPORT_SUBPATH}`
    history.push({ pathname: new_path })
  }

  function asynchronously_fetch_benchmarking_merged_inputs(internal_report_id) {
    fetch_benchmarking_input_orgs_and_techs(internal_report_id)
      .then(merged_inputs => {
        if (merged_inputs) {
          const [input_org_lists, input_techs] = merged_inputs
          set_benchmarking_merged_inputs({input_org_lists, input_techs})
        }
        set_is_fetching_benchmarking_merged_inputs(false)
      })
      .catch(error => {
        // for the majority of reports and users an error here would be irrelevant, so just send to sentry
        send_error_to_sentry(error, {})
        set_is_fetching_benchmarking_merged_inputs(false)
      })
  }

  function fetch_benchmarking_params(internal_report_id) {
    fetch_existing_benchmarking_params(internal_report_id)
      .catch(error => {
        set_error_fetching_benchmarking_params(error)
        throw error
      })
      .then(existing_benchmarking_params => set_benchmarking_params(existing_benchmarking_params))
  }

  function select_hyperscript(hyperscript) {
    track_report_viewer_event(`obj="hyperscript" state="export" name="${hyperscript.id}"`)

    if (hyperscript.id === BENCHMARKING_REPORT_ID) {
      // make sure we have the most up to date params from the report
      fetch_benchmarking_params(internal_report_id)
    }

    set_selected_hyperscript(hyperscript)
  }

  function select_lotnet_report() {
    set_selected_hyperscript({id: LOTNET_REPORT_ID})
  }

  function get_heading() {
    if (is_lotnet_report_selected) return 'LOTNet report'

    if (is_multiclass_report_selected) return 'Universal Technology Taxonomy report'

    if (selected_hyperscript) return selected_hyperscript.name

    return ''
  }

  const is_multiclass_report_selected = selected_hyperscript && (selected_export_id === UTT_REPORT_ID)
  const is_lotnet_report_selected = selected_hyperscript && (selected_export_id === LOTNET_REPORT_ID)
  const is_benchmarking_report_selected = selected_hyperscript && (selected_export_id === BENCHMARKING_REPORT_ID)
  const is_executive_summary_selected = selected_hyperscript && (selected_export_id === EXECUTIVE_SUMMARY_ID)
  const is_trendspotter_report_selected = selected_hyperscript && (selected_export_id === TREND_SPOTTER_ID)

  return (
    <div className={s.content_wrapper}>
      <PaneHeader text='Export reports' />
      <div className='d-flex h-100 pt-3'>

        {error_fetching_benchmarking_params &&
          <ErrorModal
            error={error_fetching_benchmarking_params}
            on_hide={set_error_fetching_benchmarking_params(null)}
            context={'Fetching benchmarking deck parameters'}
          />
        }

        <div className={cn('h-100 pr-3', s.sidebar)}>
          <div className='d-flex flex-column'>
            {HYPERSCRIPT_GROUPS.map((group, i) => {
              const {id: group_id, name: group_name, hyperscripts: group_hyperscript_ids} = group

              const hyperscripts = (group_hyperscript_ids || [])
                .map(id => HYPERSCRIPT_BY_ID[id] || {})
                .filter(hyperscript => {
                const {permissions_endpoint} = hyperscript
                return (permissions_endpoint) ? permissions_endpoint({
                  user,
                  report_type,
                  has_missing_assignee_links,
                  created_by_user,
                  is_set_theory_report,
                  ref_data,
                  report_input
                }) : true
              })

              if (
                (group_id === UTILITY_GROUP_ID && hyperscripts.length===0 && !can_use_lotnet_export) ||
                ((group_id !== UTILITY_GROUP_ID) && (hyperscripts.length === 0))
              ) return null

              return (
                <div key={i} className='mb-2 ml-2'>
                  <div className='mb-1'>{group_name}</div>
                  <div className={cn('d-flex flex-column')}>
                    {hyperscripts.map((hyperscript, j) => {
                      const {name, option_disabled} = hyperscript

                      const option_is_disabled = option_disabled && option_disabled({has_benchmarking_org_lists, report_input, report_type, data_creation_date, created_by_user, is_incomplete, params_id})

                      return (
                        <span key={j} className='d-flex'>
                          <Option
                            disabled={(no_data && hyperscript.id !== ALERT_SETUP_ID) || option_is_disabled}
                            on_click={() => select_hyperscript(hyperscript)}
                            label={name}
                            show_spinner={hyperscript.id === BENCHMARKING_REPORT_ID && is_fetching_benchmarking_merged_inputs}
                            selected={selected_hyperscript === hyperscript}
                          />
                          {option_is_disabled && option_is_disabled.reason &&
                            <InfoPopover buttonClassName='ml-1'>
                              {option_is_disabled.reason}
                            </InfoPopover>
                          }
                        </span>

                      )
                    })}

                    {(group_id === UTILITY_GROUP_ID) && can_use_lotnet_export &&
                      <Option selected={is_lotnet_report_selected} on_click={select_lotnet_report} label='LOTNet Powerpoint Deck' />
                    }
                  </div>
                </div>
              )

            })}
          </div>
        </div>

        <div className='h-100 w-100 pl-3'>

          {!selected_hyperscript &&
            <Pane className='text-center'>
              Select export report from the menu
            </Pane>
          }

          {selected_hyperscript &&
            <PaneHeader text={get_heading()}/>
          }

          {selected_hyperscript && !(is_executive_summary_selected || is_benchmarking_report_selected || is_multiclass_report_selected || is_lotnet_report_selected || is_trendspotter_report_selected) &&
            <Hyperscript
              key={selected_hyperscript.id}
              hyperscript={selected_hyperscript}
              internal_report_id={internal_report_id}
              external_report_id={external_report_id}
              selections={selections}
              report_title={report_title}
              report_input={report_input}
              report_has_scores={report_has_scores}
              deref_data={deref_data}
              data_creation_date={data_creation_date}
              params_id={params_id}
            />
          }

          {is_benchmarking_report_selected &&
            <Hyperscript
              key={selected_hyperscript.id}
              hyperscript={selected_hyperscript}
              internal_report_id={internal_report_id}
              external_report_id={external_report_id}
              selections={selections}
              deref_data={deref_data}
              benchmarking_merged_inputs={benchmarking_merged_inputs}
              benchmarking_params={benchmarking_params}
            />
          }

          {is_executive_summary_selected &&
            <ExecSummaryHyperscript
              hyperscript={selected_hyperscript}
              internal_report_id={internal_report_id}
              external_report_id={external_report_id}
              selections={selections}
              report_title={report_title}
              ref_data={ref_data}
              deref_data={deref_data}
            />
          }

          {is_lotnet_report_selected &&
            <LotnetExport
              internal_report_id={internal_report_id}
            />
          }

          {is_trendspotter_report_selected &&
            <Hyperscript
              key={selected_hyperscript.id}
              hyperscript={selected_hyperscript}
              internal_report_id={internal_report_id}
              external_report_id={external_report_id}
              selections={selections}
              report_title={report_title}
              deref_data={deref_data}
            />
          }

        </div>
      </div>
    </div>
  )
}

export default withRouter(withUser(ExportsView))
