import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import ResultsCountSelector from './ResultsCountSelector.js'
import { PrimaryButton } from '../widgets/Button.js'
import { useToggle } from '../../hooks/general_hooks.js'
import {
  CONTEXT,
  get_default_count_setting,
  NAME_MAX_LENGTH
} from '../../utils/knn_search.js'
import { EditIcon } from '../widgets/IconSet.js'
import { remove_from_array_by_idx } from '../../utils/utils.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import InputFamiliesDisplay from './InputFamiliesDisplay.js'
import {
  BookmarkIcon,
  RemoveIcon,
  AddIcon,
  SearchAgainIcon,
  CrossIcon
} from './TechExplorerIcons.js'
import UserSearchesDisplay from './UserSearchesDisplay.js'
import RenameModal from '../RenameModal.js'
import { is_valid_name } from '../../utils/name_utils.js'
import { withUser } from '../UserContext.js'
import PinSearchControl from './PinSearchControl.js'
import SearchTextInput from './SearchTextInput.js'
import TextLink from '../widgets/TextLink.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import PrivateSearchModeControl from './PrivateSearchModeControl.js'
import { track_knn_events } from '../../utils/tracking_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './InputParsed.module.scss'

const FamiliesSummary = ({label, count, updated_count, Icon, className, countClassName, labelClassName}) => {
  return (
    <div className={cn('d-flex', className)} title={label}>
      <span className={cn('my-auto', labelClassName)}>{label}</span>
      <div className={cn('d-flex ml-2 my-auto', countClassName)}>
        <span className='my-auto'><Icon /></span>
        <span className={cn('ml-1 my-auto d-flex', cs.font_size_large)}>
          <span>{count}</span>
          {(updated_count != null) && (updated_count - count !== 0) &&
            <span>
              ({updated_count - count > 0 ? <span>+</span> : <span>-</span>}<span>{Math.abs(updated_count - count)}</span>)
            </span>
          }
        </span>
      </div>
    </div>
  )
}

const Wrapper = ({children, className}) => {
  return (
    <div className={cn('px-2', s.wrapper, className)}>
      <ContainerFullWidth>
        {children}
      </ContainerFullWidth>
    </div>
  )
}

const InputParsed = (
  {
    current_id,
    search_query_params,
    family_ids,
    blocklist,
    bookmarked,
    text,
    count,

    families_list,
    unparsed_text,
    include_search_families_in_results,
    search_name,
    is_saved,
    should_save_knn_searches,
    anonymous_mode_enabled,

    on_change_text,
    on_change_count,
    on_change_unparsed_text,
    on_add_to_family_ids,
    on_add_to_blocklist,
    on_add_to_bookmarked,
    on_change_search_name,
    on_toggle_include_search_families_in_results,
    on_fetch_search_results,
    on_save_search_handler,
    on_search_restart,
    toggle_anonymous_mode,

    topClassName,
    bottomClassName
  }) => {

  const {
    pat_fam_ids: family_ids_from_input,
    text: text_from_input,
    blocklist: blocklist_from_input,
    k: count_from_input,
  } = search_query_params || {}

  const [show_family_ids, toggle_show_family_ids] = useToggle(false)
  const [show_edit_name_modal, toggle_show_edit_name_modal] = useToggle(false)
  const [show_more_text_input, set_show_more_text_input] = useState(false)
  const [text_idx_to_edit, set_text_idx_to_edit] = useState(null)
  const [should_fetch, set_should_fetch] = useState(false)


  useEffect(() => {
    if (!should_fetch) return
    set_should_fetch(false)
    on_fetch_search_results({text_idx_to_edit})
    set_text_idx_to_edit(null)

  }, [should_fetch, on_fetch_search_results, text_idx_to_edit])

  const family_ids_to_display = family_ids || []
  const blocklist_to_display = blocklist || []
  const bookmarked_to_display = bookmarked || []

  const has_families_to_display = (family_ids_to_display.length > 0) || (blocklist_to_display.length > 0) || (bookmarked_to_display.length > 0)

  const has_input_changes = (!_.isEqual(family_ids_from_input || [],family_ids || [])) ||
    (!_.isEqual(blocklist_from_input || [],blocklist || [])) ||
    ((text || []).length !== (text_from_input || []).length) ||
    (count !== count_from_input)

  const has_empty_input = !has_families_to_display && ((text || []).length === 0) && ((unparsed_text || '') === '')

  const has_unparsed_text_changes = ((unparsed_text || '') !== '') && ((text_idx_to_edit != null) ?  (((text || [])[text_idx_to_edit] || {}).value !== unparsed_text) : true)

  function on_text_remove(event, idx) {
    event.stopPropagation()

    const {type} = (text || [])[idx] || {}
    track_knn_events(`context="${CONTEXT}" action="remove_from_search" obj="${type}"`)

    delete_parsed_text_item(idx)
  }

  function delete_parsed_text_item(idx) {
    const updated_text = remove_from_array_by_idx(text, idx)

    on_change_text(updated_text)
  }

  function on_close_more_text_input() {
    set_text_idx_to_edit(null)
    on_change_unparsed_text(null)
    set_show_more_text_input(false)
  }

  function on_set_text_to_edit(i) {
    const {value: text_to_edit} = text[i] || {}
    set_text_idx_to_edit(i)

    on_change_unparsed_text(text_to_edit)
    set_show_more_text_input(true)
  }

  function on_update_search() {
    set_show_more_text_input(false)

    if (text_idx_to_edit != null) {
      const {type, value} = (text || [])[text_idx_to_edit] || {}
      if (value !== unparsed_text) {
        track_knn_events(`context="${CONTEXT}" action="edit_text" obj="${type}"`)
      } else {
        on_change_unparsed_text(null)
        set_text_idx_to_edit(null)
      }
    }

    set_should_fetch(true)
  }

  return (
    <>
      <ContainerFullWidth className={cn('d-flex justify-content-between mb-2', topClassName)}>
        <div className='d-flex'>
          <TextLink onClick={on_search_restart} title='Start a new search' className={cn('fs-unmask my-auto', s.new_search_btn)}>
            <span className={cn('d-flex justify-content-center align-items-center', s.new_search_btn_label)}><SearchAgainIcon /></span>
          </TextLink>

          <span className={cn('ml-2 my-auto', cs.font_size_larger, cs.cipher_blue_text, cs.font_weight_bolder)}>{search_name}</span>
          <span className={cn('ml-2 my-auto fs-unmask', cs.cipher_red_text)} onClick={toggle_show_edit_name_modal}><EditIcon /></span>
          {should_save_knn_searches &&
            <PinSearchControl
              is_saved={is_saved}
              on_change={on_save_search_handler}
              className='ml-1 my-auto fs-unmask'
            />
          }
        </div>

        {should_save_knn_searches &&
          <PrivateSearchModeControl
            is_enabled={should_save_knn_searches}
            is_checked={anonymous_mode_enabled}
            on_click={toggle_anonymous_mode}
            className={cn('my-auto mr-2 ml-2', cs.white_space_nowrap)}
          />
        }
      </ContainerFullWidth>

      <Wrapper className={cn(s.wrapper__top, s.wrapper__summary_row)}>
        <div className={cn('pt-2 pb-1', s.summary)}>
          <div className='d-flex justify-content-between fs-unmask'>
            <div className='d-flex'>
              <div className='mr-4 my-auto'>
                <span className='d-none d-sm-block'>Your patent family selections:</span>
                <span className='d-block d-sm-none'>Selections:</span>
              </div>

              <div className='d-flex flex-nowrap'>
                <FamiliesSummary
                  label='Relevant'
                  count={(family_ids_from_input || []).length}
                  updated_count={family_ids_to_display.length}
                  Icon={AddIcon}
                  labelClassName='d-none d-sm-block ml-2 ml-sm-4'
                  countClassName={s.positive_info}
                />
                <FamiliesSummary
                  label='Irrelevant'
                  count={(blocklist_from_input || []).length}
                  updated_count={blocklist_to_display.length}
                  Icon={RemoveIcon}
                  labelClassName='d-none d-sm-block ml-2 ml-sm-4'
                  countClassName={s.negative_info}
                />
                <FamiliesSummary
                  label='Review&nbsp;later'
                  count={bookmarked_to_display.length}
                  Icon={BookmarkIcon}
                  className='mr-2'
                  labelClassName='d-none d-sm-block ml-2 ml-sm-4'
                  countClassName={s.bookmark_info}
                />
              </div>
            </div>

            <PrimaryButton outline size='sm' onClick={toggle_show_family_ids} disabled={!has_families_to_display} className='d-flex my-auto'>
              <span className='my-auto d-flex'>
                <span>{show_family_ids ? 'Hide' : 'View'}</span>
                <span className='d-none d-sm-block'>&nbsp;selections</span>
              </span>
            </PrimaryButton>

          </div>
        </div>
      </Wrapper>

      {show_family_ids &&
        <Wrapper>
          <InputFamiliesDisplay
            current_id={current_id}

            families_list={families_list}
            family_ids={family_ids}
            blocklist={blocklist}
            bookmarked={bookmarked}

            on_add_to_family_ids={on_add_to_family_ids}
            on_add_to_blocklist={on_add_to_blocklist}
            on_add_to_bookmarked={on_add_to_bookmarked}

            className='pt-2'
          />
        </Wrapper>
      }

      <Wrapper>
        <div className='py-2'>
          <div className='d-flex flex-wrap'>
            {(text || []).map((item, i) => {
              const { value, type } = item || {}
              const value_chunks = value.split(' ')

              const is_in_edit = (i === text_idx_to_edit)
              const is_tech = type === 'tech_name'

              return (
                <span key={i} className={cn('mr-2 pl-2 pr-1 mb-1 d-flex', cs.cursor_pointer, s.text_item, {[s.text_item__in_edit]: is_in_edit})} title={`${is_tech ? 'technology' : 'description'}: ${value}`} onClick={() => on_set_text_to_edit(i)}>
                  <span className='my-auto'>{value_chunks.length > 6 ? `${value_chunks.slice(0, 5).join(' ')}...` : value}</span>
                  <span className={cn('ml-1', {[cs.cipher_red_text]: !is_in_edit}, cs.font_size_small)} onClick={(e) => on_text_remove(e, i)}><CrossIcon font_size='small' /></span>
                </span>
              )
            })}

            <TextLink className={cn('mr-2 px-1 mb-1 d-flex fs-unmask', cs.cursor_pointer)} title='Extend search by adding more text' disable={show_more_text_input} onClick={() => set_show_more_text_input(true)}>
              <span className={cn('my-auto', cs.font_size_small)}><AddIcon font_size='small' /></span>
              <span className='my-auto ml-1'>Add more text</span>
            </TextLink>
          </div>

          {show_more_text_input &&
            <div>
              <div className='d-flex justify-content-end'>
                <TextLink onClick={on_close_more_text_input} title='Cancel changes'>Cancel</TextLink>
              </div>
              <SearchTextInput
                input_text={unparsed_text}
                on_change_input_text={on_change_unparsed_text}
                auto_focus={true}
              />
            </div>
          }

        </div>
      </Wrapper>

      <Wrapper className={cn(s.wrapper__controls_row, bottomClassName)}>
        <div className='d-md-flex justify-content-between pt-1 pb-2'>
          <div className='d-sm-flex'>
            <UserSearchesDisplay
              current_id={current_id}

              on_update_current_search={on_save_search_handler}
              className='my-auto'
              btn_size='sm'
            />

            <div className='my-auto ml-0 ml-sm-4'>
              <CheckboxAndLabel
                label='Include relevant families in results'
                is_checked={include_search_families_in_results}
                on_click={on_toggle_include_search_families_in_results}
                className='fs-unmask'
                checkboxClassName={s.checkbox}
              />
            </div>
          </div>

          <div className='d-flex mt-2 mt-md-0 justify-content-between justify-content-sm-center'>
            <ResultsCountSelector
              selected={count != null ? count : (count_from_input || get_default_count_setting())}
              on_select={on_change_count}
              className='d-flex mr-2 fs-unmask'
              btnClassName={cn(s.count_select_btn, cs.no_outline)}
            />

            <PrimaryButton size='sm' onClick={on_update_search} disabled={!(has_input_changes || has_unparsed_text_changes) || has_empty_input} className='fs-unmask'>
              Apply & update
            </PrimaryButton>
          </div>
        </div>
      </Wrapper>

      {show_edit_name_modal &&
        <RenameModal
          modal_title='Change search name'
          name={search_name}
          invalid_name_message={`Name cannot be empty or longer than ${NAME_MAX_LENGTH} characters.`}

          rename_handler={on_change_search_name}
          is_name_valid_handler={(name) => is_valid_name(name, NAME_MAX_LENGTH)}
          on_close={toggle_show_edit_name_modal}
        />
      }
    </>
  )
}

export default withUser(InputParsed)