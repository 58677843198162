import React from 'react'

import { FILE_UPLOAD_MODE_ID, TEXT_UPLOAD_MODE_ID } from '../../../utils/custom_search_utils.js'

export const CustomClusteredUploadHelp = ({className, upload_mode}) => {
  const patent1 = 'C0001342980'
  const patent2 = 'C0046069534'
  const patent3 = 'C0001276794'
  const patent4 = 'C0001333785'
  const patent5 = 'C0000934560'

  return (
    <div className={className}>
      {upload_mode === TEXT_UPLOAD_MODE_ID &&
      <>
        <div className='mb-2'>
          <div>Paste <abbr title={'comma separated values'}>CSV</abbr> or <abbr title={'tab separated values'}>TSV</abbr> into the box, where the columns are:</div>
          <div><span className='font-weight-bold'>Family:</span> Cipher Family ID</div>
          <div><span className='font-weight-bold'>Organisation:</span> the organisation that patent belongs to</div>
          <div><span className='font-weight-bold'>Technology:</span> the technology that patent belongs to
            (or &#39;other&#39;
            will place it into the Unrelated category)
          </div>
        </div>
        <div className='mb-2'>
          <div>You can use either tabs or commas to separate the columns.</div>
          <div>{patent1},MegaCorp,3D Printing</div>
          <div>{patent2},MegaCorp,3D Printing</div>
          <div>{patent3},MegaCorp,Machine tools</div>
          <div>{patent4},Competitor #1,3D Printing</div>
          <div>{patent5},Competitor #1,other</div>
        </div>
        <div>The above example defines a portfolio of 5 patents split into 2 different Technologies and 2 different
          Organisations
        </div>
        <div>You can omit the heading row of the CSV/TSV if you wish. Any row which doesn&#39;t contain a patent is
          ignored.
        </div>
        <div>The Technology name &#39;other&#39; can be used to mark patents which are considered to not be part of the
          first
          Organisation&#39;s technologies.
        </div>
      </>
      }
      {upload_mode === FILE_UPLOAD_MODE_ID &&
      <div>The file should follow a CSV/TSV format and contain 3 columns to identify Cipher families, organisations and
        technologies respectively.</div>
      }
    </div>
  )
}