import { get_hashed_id_with_salt } from './utils.js'

export function initialise_pendo(user_id, user_group) {
  const {pendo} = window

  if (pendo == null) return

  pendo.initialize({
    visitor: {
      id: get_hashed_id_with_salt(user_id, 'CipherPendo'),
      group: user_group
    },
    account: {
      id: user_group,
      name: user_group,
    }
  })
}

export function prepare_pathname_for_pendo(url) {
  const {pendo} = window

  if (pendo == null) return

  const {location} = pendo

  if (location == null) return

  location.setUrl(url)
}
