import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'
import { update_user } from '../../utils/user_management_utils.js'

import s from './EditUserDetailsModal.module.scss'

function get_invalid_email_msg(email) {
  if (!email) {
    return 'required'
  }
  if (!email.includes('@')) {
    return 'must be valid email'
  }
  if (!(email.length >= 3)) {
    return 'minimum 3 characters'
  }
  if (!(email.length <= 50)) {
    return 'maximum 50 characters'
  }
  return null
}

function get_invalid_name_msg(name) {
  if (!name) {
    return 'required'
  }
  if (!(name.length >= 1)) {
    return 'minimum 1 character'
  }
  if (!(name.length < 50)) {
    return 'maxmimum 50 characters'
  }
  return null
}

const EditUserDetailsModal = ({
  user,
  local_users,
  set_local_users,
  on_close,
}) => {

  const { email: current_email, firstName, lastName } = user

  const [email__curr, set_email__curr]           = useState(current_email)
  const [first_name__curr, set_first_name__curr] = useState(firstName)
  const [last_name__curr, set_last_name__curr]   = useState(lastName)

  const [email, set_email]           = useState(current_email)
  const [first_name, set_first_name] = useState(firstName)
  const [last_name, set_last_name]   = useState(lastName)

  const [is_saving, set_is_saving]       = useState(false)
  const [error_saving, set_error_saving] = useState(null)
  const [is_saved_ok, set_is_saved_ok] = useState(false)

  const invalid_email_msg      = get_invalid_email_msg(email)
  const invalid_first_name_msg = get_invalid_name_msg(first_name)
  const invalid_last_name_msg  = get_invalid_name_msg(last_name)

  const is_changed = (email !== email__curr) || (first_name !== first_name__curr) || (last_name !== last_name__curr)
  const is_valid = (invalid_email_msg == null) && (invalid_first_name_msg == null) && (invalid_last_name_msg == null)
  const is_ok_to_save = is_changed && is_valid

  useEffect(() => {
    // On any change, reset the saved ok flag
    set_is_saved_ok(false)
  }, [email, first_name, last_name])

  function do_save() {
    set_is_saving(true)
    set_is_saved_ok(false)

    update_user(user.id, email /* send email as username always */, email, first_name, last_name)
      .catch(err => {
        // FAIL
        set_error_saving(err)
        throw err
      })
      .then(() => {
        // SUCCESS
        // update local state
        const new_local_users = local_users.map(u => {
          if (u.id === user.id) {
            return {
              ...u,
              email,
              firstName: first_name,
              lastName: last_name,
            }
          }
          return u
        })

        set_email__curr(email)
        set_first_name__curr(first_name)
        set_last_name__curr(last_name)
        set_local_users(new_local_users)
        set_is_saved_ok(true)
      })
      .finally(() => {
        set_is_saving(false)
      })
  }

  return (
    <Modal
      className={cn(s.modal_container)}
      title={`Edit user details`}
      close_label={'Close'}
      on_hide={on_close}
      footer={
        <div>
          {is_saving &&
            <Spinner
              size={'sm'}
              className={cn('mr-1')}
            />
          }
          {is_saved_ok &&
            <span className={cn('mr-3')}>Saved ok</span>
          }
          <PrimaryButton
            size={'md'}
            onClick={do_save}
            disabled={!is_ok_to_save || is_saving || error_saving}
          >
            Save
          </PrimaryButton>
        </div>
      }
    >

      <div>
        <div className={cn('form-group')}>
          <label className={cn('form-label')}>
            Email / Username
          </label>
          <input
            type={'text'}
            className={cn('form-control', {'is-invalid': invalid_email_msg})}
            value={email}
            onChange={e => set_email(e.target.value)}
          />
          {invalid_email_msg &&
            <div className={cn('invalid-feedback')}>
              {invalid_email_msg}
            </div>
          }
        </div>

        <div className={cn('form-group')}>
          <label className={cn('form-label')}>
            First name
          </label>
          <input
            type={'text'}
            className={cn('form-control', {'is-invalid': invalid_first_name_msg})}
            value={first_name}
            onChange={e => set_first_name(e.target.value)}
          />
          {invalid_first_name_msg &&
            <div className={cn('invalid-feedback')}>
              {invalid_first_name_msg}
            </div>
          }
        </div>

        <div className={cn('form-group')}>
          <label className={cn('form-label')}>
            Last name
          </label>
          <input
            type={'text'}
            className={cn('form-control', {'is-invalid': invalid_last_name_msg})}
            value={last_name}
            onChange={e => set_last_name(e.target.value)}
          />
          {invalid_last_name_msg &&
            <div className={cn('invalid-feedback')}>
              {invalid_last_name_msg}
            </div>
          }
        </div>
      </div>

      {/* ERRORS */}
      {error_saving &&
        <ErrorBody
          error={error_saving}
          context={'saving user details'}
        />
      }
    </Modal>
  )
}

export default EditUserDetailsModal