import React, { useContext, useState } from 'react'

import { UserSettingsContext } from '../UserSettingsContext.js'
import { get_save_knn_searches } from '../../utils/user_settings_utils.js'
import UserSearchesModal from './UserSearchesModal.js'
import TextLink from '../widgets/TextLink.js'

const UserSearchesDisplay = (
  {
    current_id,
    on_update_current_search,
    className
  }) => {

  const { user_settings } = useContext(UserSettingsContext)
  const should_save_knn_searches = get_save_knn_searches(user_settings)

  const [is_open, set_is_open] = useState(false)

  if (!should_save_knn_searches) {
    return null
  }

  return (
    <div className={className}>
      <TextLink onClick={() => set_is_open(true)} className='fs-unmask'>Pinned and recent searches</TextLink>

      {is_open &&
        <UserSearchesModal
          current_id={current_id}
          on_update_current_search={on_update_current_search}
          on_modal_hide={() => set_is_open(false)}
        />
      }
    </div>
  )
}

export default UserSearchesDisplay