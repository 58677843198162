import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { get_element_content, do_common_parse, prepare_text } from '../../utils/family_view_utils.js'
import { get_is_windows_os } from '../../utils/utils.js'

import { Highlighter } from '../widgets/Highlighter.js'
import { Heading } from './FamilyDetailsLabel.js'
import Spinner from '../widgets/Spinner.js'

import s from './ClaimsView.module.scss'

const is_windows_os = get_is_windows_os()


const ClaimsView = ({claims_block_ref, show_spinner, claims, no_highlighting, highlight_prefix_only, search_phrases, search_colours, error }) => {

  function prepare_claim_content(elements) {
    if (!elements || elements.length === 0) return ''
    
    const content = elements.map(item => {
      if (item.name === 'figref') {
        return prepare_text(((item.elements || [])[0] || {}).text)
      }

      if (item.name === 'claim-ref') {
        return '<i>' + prepare_text(get_element_content(item)) + '</i>'
      }

      if (item.name === 'claim-text' && item.elements) {
        return (item.elements.length > 1) ? prepare_claim_content(item.elements) : get_element_content(item)
      }
      return do_common_parse(item)
    })

    return content.join('')
  }

  function build_claims_structure() {
    const { claims_summary } = claims

    if ((claims_summary || []).length === 0) {
      return null
    }

    let claims_structure = []

    try {

      claims_summary.forEach((item) => {

        let related = null

        if ((item.attributes.type === 'dependent') && (item.elements !== undefined)) {

          if (parseInt(item.elements[0].attributes.num, 10) >= parseInt(item.attributes.num, 10)) {
            //It was observed that sometimes there are cases when claims incorrectly depend on other claims with higher number,
            //that can cause issues with displaying them properly in the hierarchy order.
            //We decided to not use the claims 'table of content' if it's too problematic

            throw new Error('Poorly formatted claims summary xml')
          }

          related = item.elements[0].attributes.num
        }

        claims_structure[item.attributes.num] = {type: item.attributes.type, related: related}
      })
    } catch (e) {
      return []
    }

    return claims_structure
  }

  function get_class_names(claim_id, summary) {

    let claim_classnames = ['mb-1']

    if (summary[claim_id] !== undefined) {
      claim_classnames.push( ((summary[claim_id].type === 'dependent') && !is_windows_os) ? s.claim_entry_state_dependent : '')
    }

    return claim_classnames
  }


  function calculate_dependency_level(claim_id, summary, level) {
    if ((summary[claim_id] === undefined) || (summary[claim_id].type !== 'dependent')) return level

    return calculate_dependency_level(summary[claim_id].related, summary, level + 1)
  }

  function get_inline_styles(claim_id, summary) {

    let dependency_level = calculate_dependency_level(claim_id, summary, 0)

    let padding = 20 * dependency_level

    return {'paddingLeft': (padding === 0) ? padding : padding + 'px'}
  }

  function process_claims_elements(claim_obj_elements) {
    const CHUNK_SIZE = 100

    //if reasonable number of elements the browser will probably cope with processing all at once
    if (claim_obj_elements.length < 2 * CHUNK_SIZE) return prepare_claim_content(claim_obj_elements)

    //will process elements in chunks as large umber can be disastrous to the browser and memory
    let start = 0
    let end = start + CHUNK_SIZE
    const content_chunks = []

    while (start < claim_obj_elements.length) {
      content_chunks.push(prepare_claim_content(claim_obj_elements.slice(start, end)))
      start = end
      end = Math.min(start + CHUNK_SIZE, claim_obj_elements.length)
    }

    return content_chunks.join(' ')
  }

  function get_claims_to_display() {
    const {claims_list} = claims
    const claims_structure = build_claims_structure()
    const claims_to_display = claims_list.map((claim_obj, i) => {
      const {name='', elements=[], attributes={}} = claim_obj

      if (name !== 'claim') {
        return null
      }

      let classes = ''
      let style = {}

      if (claims_structure) {
        classes = cn(get_class_names(attributes.num, claims_structure))
        style = get_inline_styles(attributes.num, claims_structure)
      }

      if (!elements || elements.length === 0) return null

      const claim_text_html_string = process_claims_elements(elements)
      return (
        <li
          key={i}
          className={classes}
          style={style}
        >
          <Highlighter
            no_highlighting={no_highlighting}
            highlight_prefix_only={highlight_prefix_only}
            search_words={search_phrases}
            search_colours={search_colours}
            text_to_highlight={claim_text_html_string}
            input_is_raw_html={true}
          />
        </li>
      )
    })

    if (_.every(claims_to_display, (item) => item == null)) return null

    return claims_to_display
  }

  const has_claims = (claims != null) && (claims.claims_list != null) && (claims.claims_list.length > 0)

  const claims_to_display = has_claims ? get_claims_to_display() : null

  return (
    <div ref={claims_block_ref} className={cn('mb-3', s.block)}>
      <Heading>Claims</Heading>

      {show_spinner &&
        <Spinner/>
      }

      {!show_spinner && has_claims && claims_to_display &&
        <ul className='list-unstyled'>
          {claims_to_display}
        </ul>
      }

      {!show_spinner && (!has_claims  || !claims_to_display) &&
        <div>(none)</div>
      }

      {error &&
        <div>There was an error fetching claims</div>
      }
    </div>
  )
}

export default ClaimsView