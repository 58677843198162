import React from 'react'
import cn from 'classnames'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import { COUNTRY_PREFIXES, DOCUMENT_KIND } from '../../utils/custom_search_utils.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'

import s from './PatentUploadOptions.module.scss'

export const PatentUploadOptions = ({
  className,
  is_disabled,
  strict_selector,
  toggle_strict_selector,
  country_code_prefix_selector,
  toggle_country_code_prefix_selector,
  selected_country_code,
  set_selected_country_code,
  document_kind_selector,
  toggle_document_kind_selector,
  selected_document_kind,
  set_selected_document_kind
}) => {

  return (
    <div className={className}>
      <CheckboxAndLabel
        is_checked={strict_selector}
        label='One patent per line'
        on_click={toggle_strict_selector}
        is_disabled={is_disabled}
      />
      <div className='d-flex'>
        <CheckboxAndLabel
          is_checked={country_code_prefix_selector}
          label='Prefix patents with a country:'
          on_click={toggle_country_code_prefix_selector}
          is_disabled={is_disabled}
        />
        <UncontrolledDropdown disabled={!country_code_prefix_selector}>
          <SelectDropdownToggleStatic
            className={cn('ml-1', s.dropdown_toggle)}
            disabled={!country_code_prefix_selector}
          >
            {selected_country_code}
          </SelectDropdownToggleStatic>
          <DropdownMenu>
            {COUNTRY_PREFIXES.map((country_code, i) => {
              return (
                <DropdownItem
                  onClick={() => set_selected_country_code(country_code)}
                  key={i}
                >
                  {country_code}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <div className='d-flex'>
        <CheckboxAndLabel
          is_checked={document_kind_selector}
          label='Only:'
          on_click={toggle_document_kind_selector}
          is_disabled={is_disabled}
        />
        <UncontrolledDropdown disabled={!document_kind_selector} className='d-inline-flex'>
          <SelectDropdownToggleStatic className={cn('ml-1', s.dropdown_toggle)} disabled={!document_kind_selector}>
            {selected_document_kind}
          </SelectDropdownToggleStatic>
          <DropdownMenu>
            {DOCUMENT_KIND.map((document_kind, i) => {
              return (
                <DropdownItem
                  onClick={() => set_selected_document_kind(document_kind)}
                  key={i}
                >
                  {document_kind}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}
