import React from 'react'
import cn from 'classnames'

import cs from './cipher_styles.module.scss'
import s from './Logo.module.scss'

export const Logo = ({link_to, is_tech_explorer_page, small_logo, title}) => {
  return (
    <a
      id='cipher_logo'
      className={cn(
        'd-flex',
        'my-auto',
        s.logo,
        s.logo_cipher,
        {[s.logo_mark_only]: small_logo}
      )}
      href={link_to || '/'}
      title={title || 'Home page'}
    >
      {is_tech_explorer_page &&
        <span className={cn(cs.font_size_x_large, cs.cipher_white_text, cs.white_space_nowrap, s.page_title)}>Tech&nbsp;Explorer</span>
      }
      {!is_tech_explorer_page &&
        <span className='d-none'>Home page</span>
      }
    </a>
  )
}
