import React from 'react'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import PushPinIcon from '@mui/icons-material/PushPin'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MagnifyingGlassIcon from '@mui/icons-material/Search'
import MagnifyingGlassAgainIcon from '@mui/icons-material/YoutubeSearchedFor'
import ClearIcon from '@mui/icons-material/Clear'

export const AddIcon = ({font_size}) => {
  return (<AddCircleIcon fontSize={font_size} />)
}

export const RemoveIcon = ({font_size}) => {
  return (<RemoveCircleIcon fontSize={font_size} />)
}

export const BookmarkIcon = ({font_size}) => {
  return (<BookmarkAddIcon fontSize={font_size} />)
}

export const PinIcon = ({font_size}) => {
  return (<PushPinIcon sx={{transform: 'rotate(30deg)'}} fontSize={font_size} />)
}

export const UnpinIcon = ({font_size}) => {
  return (<PushPinOutlinedIcon fontSize={font_size} />)
}

export const DeleteIcon = ({font_size}) => {
  return (<DeleteForeverIcon fontSize={font_size} />)
}

export const SearchIcon = ({font_size}) => {
  return (<MagnifyingGlassIcon fontSize={font_size} />)
}

export const SearchAgainIcon = ({font_size}) => {
  return (<MagnifyingGlassAgainIcon fontSize={font_size} />)
}

export const CrossIcon = ({font_size}) => {
  return (<ClearIcon fontSize={font_size} />)
}