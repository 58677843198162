import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { POSITIVE, NEGATIVE, IGNORE, TEST_POSITIVE, TEST_NEGATIVE } from '../constants/labels.js'
import { IS_PENDING, IS_ERROR } from '../constants/family_state.js'
import { HIGHLIGHT_PHRASE_CONTEXT_MENU_CLASS } from './HighlightPhrasesContextMenu.js'

import { display_score, format_score } from '../utils/training_set_utils.js'
import {
  get_priority_year,
  get_owners_string,
  get_group_cpc_to_base_cpcs,
  get_score
} from '../utils/patent_field_utils.js'
import { get_score_colour } from '../utils/score_utils.js'
import { get_is_show_scope } from '../utils/scope_modal_utils.js'

import { create_cipher_family_id_from_family_id } from '../../../utils/family_view_utils.js'
import { get_scroll_y, smooth_scroll_to_y, is_element_vertically_onscreen } from '../../../utils/scroll_utils.js'

import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from '../../widgets/IconSet.js'
import { Highlighter } from '../../widgets/Highlighter.js'

import ClassifierLabelControl from './ClassifierLabelControl.js'
import ClassifierStatusMessage from './ClassifierStatusMessage.js'
import SuggestionsStrategyName from './SuggestionsStrategyName.js'
import SuggestionsStrategyDetails from './SuggestionsStrategyDetails.js'

import CipherFamilyLink from '../../widgets/CipherFamilyLink'
import CpcWithHover from '../../family_view/CpcWithHover.js'
import AddFamilyToInput from '../../patent_family_list/AddFamilyToInput.js'
import FamilyActions from '../../tech_explorer/FamilyActions.js'
import InputStatusMarker from '../../tech_explorer/InputStatusMarker.js'

import s from './PatentCard.module.scss'

export const InvisibleCard = () => {
  return (
    <div
      className={cn(s.container, s.container__invisible)}
    >
    </div>
  )
}

/**
 * Renders a fake "card" with a prev/next page control.
 * @param {} show_prev If true, shows "prev" page control. Otherwise shows "next" page control.
 * @param {} num_pages
 * @param {} current_page
 * @param {} on_change_current_page
 */
export const PageControlCard = (
  {
    show_prev,
    num_pages,
    current_page,
    on_change_current_page
  }) => {

  return (
    <div
      className={cn('d-flex', 'align-items-center', s.container, s.container__page_control)}
      onClick={() => {
        on_change_current_page(show_prev ? (current_page - 1) : (current_page + 1))
      }}
    >
      <div
        className={cn(s.page_control_body)}
      >

        {!show_prev &&
        <div>
            <span className={cn(
              s.page_control_text
            )}>
              next page (<span>{(current_page + 2)}</span> of <span>{num_pages}</span>)
            </span>

          <KeyboardArrowRightIcon
            className={cn(s.page_control_overlay_icon)}
          />
        </div>
        }

        {show_prev &&
        <div>
          <KeyboardArrowLeftIcon
            className={cn(s.page_control_overlay_icon)}
          />

          <span className={cn(
            s.page_control_text
          )}>
            previous page (<span>{current_page}</span> of <span>{num_pages}</span>)
            </span>
        </div>
        }

      </div>
    </div>
  )
}

class PatentCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.outer_ref = React.createRef()

    // On mount, seems like the text area is active (which blocks ensure_selected_is_visible scroll from working).
    // Weirdly this only started happening in 2023. Perhaps a Chrome update changed the behaviour?
    // Anyways debouncing fixes the issue, as on the next tick the text area is no longer active.
    this.ensure_selected_is_visible = this.ensure_selected_is_visible.bind(this)
    this.ensure_selected_is_visible__debounced = _.debounce(this.ensure_selected_is_visible, 100)
  }

  shouldComponentUpdate(nextProps) {
    const {patent, is_selected, search_phrases, search_colours, no_highlighting, similar_search_input_family_ids, similar_search_input} = this.props

    const {
      patent: next_patent,
      is_selected: next_is_selected,
      search_phrases: next_search_phrases,
      search_colours: next_search_colours,
      no_highlighting: next_no_highlighting,
      similar_search_input_family_ids: next_similar_search_input_family_ids,
      similar_search_input: next_similar_search_input,
    } = nextProps

    // Only re-render if patent or search_phrases has actually changed.
    if (
      !_.isEqual(patent, next_patent)
      || !_.isEqual(is_selected, next_is_selected)
      || !_.isEqual(search_phrases, next_search_phrases)
      || !_.isEqual(search_colours, next_search_colours)
      || !_.isEqual(no_highlighting, next_no_highlighting)
      || !_.isEqual(similar_search_input_family_ids, next_similar_search_input_family_ids)
      || !_.isEqual(similar_search_input, next_similar_search_input)
    ) {
      return true
    }

    return false
  }

  componentDidMount() {
    this.ensure_selected_is_visible__debounced()
  }

  componentDidUpdate() {
    this.ensure_selected_is_visible__debounced()
  }

  ensure_selected_is_visible() {
    const {is_selected, top_margin = 0, is_back_move} = this.props // TODO: can we calculate container height dynamically?

    const is_show_scope = get_is_show_scope()
    if (is_show_scope) {
      // Scope modal is showing, so don't do the scroll
      return
    }

    const is_highlights_modal_showing = (document.getElementsByClassName(HIGHLIGHT_PHRASE_CONTEXT_MENU_CLASS).length > 0)
    if (is_highlights_modal_showing) {
      // Highlight phrases context menu is showing, so don't do the scroll
      return
    }

    const active_element = document.activeElement
    if (active_element && _.contains(['INPUT', 'TEXTAREA'], active_element.tagName)) {
      // User is in a text input, so don't do the scroll
      return
    }

    if (is_selected) {
      const el = this.outer_ref.current
      if (!el) {
        return
      }

      const el_top = el.getBoundingClientRect().top // Y of element, relative to viewport
      const el_bottom = el.getBoundingClientRect().bottom

      const scroll_y = get_scroll_y()

      if (is_element_vertically_onscreen(el, top_margin)) {
        return
      }

      const target_y = is_back_move ?
        (el_bottom + scroll_y) - document.documentElement.clientHeight // show at bottom of screen
        : (el_top + scroll_y) - top_margin                             // show at top of screen

      // Scroll
      smooth_scroll_to_y(target_y, null)
    }
  }

  render() {
    const {
      className, subidx, show_classifier_controls, patent, is_selected, no_highlighting, search_phrases, search_colours, highlight_prefix_only, source, set_label,
      on_show_details, is_inset, show_suggestion_strategy,
      eval_training_set_id, eval_classifier_data,
      similar_search_input_family_ids, similar_search_input, on_include_family_id_in_similar_search_input,
      knn_family_ids, knn_blocklist, knn_bookmarked,
      on_add_to_knn_family_ids, on_add_to_knn_blocklist, on_add_to_knn_bookmarked
    } = this.props

    const {id, title, abstract, owners, cpcCodes, priorityDate, user_class, strategy_name, strategy_details, similarity_score } = patent

    const score = get_score(patent)

    const patfam_id = id || patent.patFamId
    const cipher_family_id = create_cipher_family_id_from_family_id(patfam_id)

    const priority_year = get_priority_year(priorityDate)
    const owners_string = get_owners_string(owners)

    const group_cpc_to_base_cpcs = cpcCodes ? get_group_cpc_to_base_cpcs(cpcCodes) : {}
    const group_cpcs = Object.keys(group_cpc_to_base_cpcs)

    const is_saving = patent[IS_PENDING]
    const is_error = patent[IS_ERROR]

    const is_positive      = user_class === POSITIVE
    const is_negative      = user_class === NEGATIVE
    const is_test_positive = user_class === TEST_POSITIVE
    const is_test_negative = user_class === TEST_NEGATIVE
    const is_ignore        = user_class === IGNORE
    const is_unlabelled    = user_class === null

    const highlighter_props = {
      no_highlighting,
      highlight_prefix_only,
      search_words: search_phrases,
      search_colours: search_colours
    }

    const top_score = score ? (_.isArray(score) ? score[0] : score) : null
    const score_color = get_score_colour(top_score)

    const show_knn_actions = (on_add_to_knn_family_ids || on_add_to_knn_blocklist || on_add_to_knn_bookmarked)

    return (
    <div
      className={cn(
        s.container,
        {
          [s.container__extra_header_line]: show_suggestion_strategy,
          [s.container__inset_card]: is_inset
        },
        className
      )}
      ref={this.outer_ref}
      //onClick={on_show_details.bind(null, subidx)}
    >
      <div
        className={cn(s.selected_overlay, s.selected_overlay__on_hover, {[s.selected_overlay__in_state]: is_selected})}
      />

      {show_suggestion_strategy &&
        <div
          className={cn(
            s.extra_header_inner_container,
            'd-flex',
            'align-items-center',
            {
              [s.positive__shadier]: is_positive,
              [s.negative__shadier]: is_negative,
              [s.test_positive__shadier]: is_test_positive,
              [s.test_negative__shadier]: is_test_negative,
              [s.ignore__shadier]: is_ignore
            }
          )}
        >
          <SuggestionsStrategyName
            strategy_id={strategy_name}
          />
          <SuggestionsStrategyDetails
            details={strategy_details}
            strategy_id={strategy_name}
            className={cn('ml-auto')}
          />
        </div>
      }

      <div
        className={cn(
          s.body_container_inner,
          {
            [s.positive__shady]: is_positive,
            [s.negative__shady]: is_negative,
            [s.test_positive__shady]: is_test_positive,
            [s.test_negative__shady]: is_test_negative,
            [s.ignore__shady]: is_ignore
          }
        )}
      >
        <div className={cn(s.title)}>
          <Highlighter
            {...highlighter_props}
            text_to_highlight={title}
          />
        </div>
        <div className={cn(s.abstract)}>
          {!abstract &&
            <span>(no abstract available)</span>
          }
          <Highlighter
            {...highlighter_props}
            text_to_highlight={abstract}
          />
        </div>
        <div className={cn(s.body_bottom_row, 'd-flex')}>
          <span className={cn(s.year)}>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={priority_year}
            />
          </span>
          <span className={cn(s.owners, 'ml-2')}>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={owners_string}
            />
          </span>
          <span className={cn(s.cpc, 'ml-auto')}>
            {group_cpcs.map((group_cpc_code, i) => {
              const base_cpc_codes = group_cpc_to_base_cpcs[group_cpc_code]

              return (
                <CpcWithHover
                  key={i}
                  highlighter_props={highlighter_props}
                  className={cn('ml-1')}
                  label={group_cpc_code}
                  cpc_codes={base_cpc_codes}
                />
              )
            })}
          </span>
        </div>
      </div>

      <div
        className={cn(
          s.footer,
          'align-items-center',
          {
            [s.positive__shadier]: is_positive,
            [s.negative__shadier]: is_negative,
            [s.test_positive__shadier]: is_test_positive,
            [s.test_negative__shadier]: is_test_negative,
            [s.ignore__shadier]: is_ignore
          }
        )}
      >
        <div className='position-relative h-100'>
          {show_knn_actions &&
            <InputStatusMarker
              family_id={patfam_id}
              positives={knn_family_ids}
              negatives={knn_blocklist}
              bookmarked={knn_bookmarked}

              display='card'
              className={s.knn_status}
            />
          }

          <div className={cn('d-flex h-100', s.footer__inner)}>
            {on_include_family_id_in_similar_search_input &&
              <div className={cn('pr-2 mr-2 my-auto', s.border_right)}>
                <AddFamilyToInput
                  family_id={patfam_id}
                  cipher_family_id={cipher_family_id}
                  family_ids={similar_search_input_family_ids}
                  similar_search_input={similar_search_input}
                  on_click={on_include_family_id_in_similar_search_input}
                />
              </div>
            }

            {show_knn_actions &&
              <div className={cn('pr-2 mr-2 my-auto', s.border_right)}>
                <FamilyActions
                  family_id={patfam_id}
                  family_ids={knn_family_ids}
                  blocklist={knn_blocklist}
                  bookmarked={knn_bookmarked}

                  on_add_to_family_ids={on_add_to_knn_family_ids}
                  on_add_to_blocklist={on_add_to_knn_blocklist}
                  on_add_to_bookmarked={on_add_to_knn_bookmarked}
                />
              </div>
            }

            <CipherFamilyLink
              family_id={cipher_family_id}
              on_family_id_click={on_show_details.bind(null, subidx, cipher_family_id)}
              display_text_as_link={true}
              display_link_icon={true}
              show_similar_families_search={true}

              eval_training_set_id={eval_training_set_id}
              eval_classifier_data={eval_classifier_data}
            />

            {((score != null) || (show_knn_actions && (similarity_score != null)))  &&
              <div
                className={cn(
                  'mx-2',
                  s.score_label,
                  {[s.border_left]: (score != null) || (similarity_score != null)},
                  {'pl-2 my-auto': (score != null) || (similarity_score != null)},
                )}
              >
                {(score != null) &&
                  <span
                    className='fs-unmask'
                    style={score_color != null ? {color: score_color} : null}
                  >
                    {display_score(score)}
                  </span>
                }
                {(similarity_score != null) && show_knn_actions &&
                  <span title='Similarity score'>
                    {format_score(similarity_score)}
                  </span>
                }
              </div>
            }

            {show_classifier_controls &&
              <div
                className={cn(
                  s.labelling_controls_container,
                  'd-flex',
                  'align-items-center',
                  {
                    [s.positive__shadier]: is_positive,
                    [s.negative__shadier]: is_negative,
                    [s.test_positive__shadier]: is_test_positive,
                    [s.test_negative__shadier]: is_test_negative,
                    [s.ignore__shadier]: is_ignore,
                    [s.unlabelled__shadier]: is_unlabelled
                  }
                )}
              >
                <ClassifierStatusMessage
                  is_saving={is_saving}
                  is_error={is_error}
                />
                <ClassifierLabelControl
                  className={cn('ml-1')}
                  is_saving={is_saving}
                  set_label={set_label.bind(null, source, patent)}
                  selected_label={user_class}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>)
  }
}

export default PatentCard