import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { get_supported_publication_formats_help_url, on_click_from_help_link } from '../../utils/help_utils.js'
import { SPIF_GROUP_URI } from '../../constants/constants.js'
import {
  FILE_UPLOAD_MODE_ID,
  SPIF_FILE_UPLOAD_MODE_ID,
  TEXT_UPLOAD_MODE_ID,
} from '../../utils/custom_search_utils.js'

export const PatentUploadHelp = ({className, upload_mode}) => {
  const is_spif_upload = upload_mode === SPIF_FILE_UPLOAD_MODE_ID

  if (!upload_mode) {
    return null
  }

  const help_link = get_supported_publication_formats_help_url()

  return (
    <div className={className}>
      {upload_mode === TEXT_UPLOAD_MODE_ID &&
        <div>Paste a list of patent numbers or families into the box.</div>
      }

      {upload_mode === FILE_UPLOAD_MODE_ID &&
        <div>
          The file should follow a CSV/Excel format and contain enough information to identify patents and or families.
        </div>
      }

      {is_spif_upload &&
        <div>
          <span>The file has to be SPIF compliant. See </span>
          <TextLink
            element='a'
            href={SPIF_GROUP_URI}
            target='_blank'
          >
            {SPIF_GROUP_URI}
          </TextLink>
          <span> for more information. </span>
        </div>
      }

      {!is_spif_upload &&
        <div className='mt-2'>
          <span>For details on the different formats accepted please see our </span>
          <TextLink
            href={help_link}
            target='_blank'
            element='a'
            onClick={() => on_click_from_help_link(help_link)}
          >
            help centre
          </TextLink>
          <span>. Note US design patents (USDnnnnnn) are not supported</span>
        </div>
      }
    </div>
  )
}
