import React, { MouseEventHandler, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { do_nothing_func, fetch_taggers_for_family_and_tag_group, Tag } from '../family_tagging/family_tag_utils'
import { CrossIcon } from '../widgets/IconSet.js'
import Tooltip from '../widgets/Tooltip.js'
import { Taggers } from './Taggers'

import s from './FamilyTagShapeDisplay.module.scss'

interface FamilyTagShapeButtonProps {
  className: string,
  family_id: number,
  tag: Tag,
  disabled: boolean,
  on_close: MouseEventHandler,
}

const FamilyTagShapeDisplay = (
  {
    className,
    family_id,
    disabled,
    tag,
    on_close,
  }: FamilyTagShapeButtonProps, ref: any) => {


  const on_close_func = disabled ? do_nothing_func : on_close
  const tag_shape_css = disabled ? cn('mr-1', s.tag_shape_disabled) : cn('mr-1', s.tag_shape)


  const [show_full_text, set_show_full_text] = useState(false)
  const tag_values_to_display: string = _.sortBy(tag.values.map((tv) => (tv.value))).join(', ')
  const tag_to_display: string = tag.name + ': ' + tag_values_to_display


  const taggers = <Taggers className={s.user_icon} fetch_taggers={fetch_taggers_for_family_and_tag_group}
                           fetch_taggers_params={[family_id, tag]}/>

  return (
    <span
      className={cn(tag_shape_css, className)}
      ref={ref}
    >
      <span className={'mr-1'}>{taggers}</span>
      {/*// @ts-expect-error*/}
      <Tooltip
        className={cn(s.family_tag)}
        placement='bottom'
        delay={[100, 50]}
        toggler={<span className={s.tag_short_name}>{tag_to_display}</span>}
        on_show={() => set_show_full_text(true)}
        on_hide={() => set_show_full_text(false)}
        is_in_modal={true}
      >
              {show_full_text &&
                <span className={'p-1 overflow-scroll d-flex flex-column'}>
                  <span className={s.expanded_tag_name}>{tag.name}</span>
                  {tag.values.map((tv: any, idx: number) =>
                    (
                      <div key={idx} className={'list-group-item-flush ml-2'}>
                        {tv.value}
                      </div>
                    ))}
                </span>
              }
            </Tooltip>
      {!disabled &&
        <span className={'ml-1'} onClick={on_close_func}>
              {/*@ts-expect-error*/}
          <CrossIcon className={s.close_icon}/>
          </span>
      }
    </span>
  )
}

export default React.forwardRef(FamilyTagShapeDisplay)