import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import LabelledSlider from '../LabelledSlider.js'
import {
  get_from_value_from_bin_name,
  get_to_value_from_bin_name,
  is_item_within_histogram_range
} from '../../utils/histogram_range_utils.js'

const HistogramRangeControl = (
  {
    spec,
    item,
    original_key_dims,
    on_change_histogram_range,

    className
  }) => {

  function get_range_items() {
     return original_key_dims[histogram_bucket_key_dim_idxs[0]] || []
  }

  function get_value_range_by_bins(bin_range) {
    const range_items = get_range_items()
    const [start_bin_idx, end_bin_idx] = bin_range || []

    const start_bin = range_items[start_bin_idx] || {}
    const end_bin = range_items[end_bin_idx - 1] || {}

    const from_value = get_from_value_from_bin_name(start_bin)
    const to_value = get_to_value_from_bin_name(end_bin)

    return {from_value, to_value}
  }

  function get_full_range() {
    const range_items = get_range_items()
    return [0, range_items.length]
  }

  function get_selected_range() {
    const { histogram_range } = item

    if (!histogram_range) return null

    const range_items = get_range_items()

    const bin_range = []
    range_items.forEach((item, i) => {
      if (is_item_within_histogram_range(item, histogram_range)) {
        bin_range.push(i)
      }
    })

    return [_.min(bin_range), _.max(bin_range) + 1]
  }

  function change_selected_range(selected_range) {
    const {from_value, to_value} = get_value_range_by_bins(selected_range)

    on_change_histogram_range([from_value, to_value])
  }

  const { histogram_bucket_key_dim_idxs } = spec || {}

  if (!histogram_bucket_key_dim_idxs) return null

  const full_range = get_full_range()
  const selected_range = get_selected_range()
  const { from_value, to_value } = get_value_range_by_bins(selected_range || full_range)

  return (
    <div className={cn('d-flex', className)}>
      <LabelledSlider
        value={selected_range || full_range}
        show_end_only={false}
        min_distance={1}
        is_inline={true}
        extent={full_range}
        hide_label={true}
        handleChange={change_selected_range}
      />

      {from_value != null && to_value != null &&
        <div className='d-flex ml-2'>
          <span>{from_value}</span>
          <span className='mx-1'>to</span>
          <span>{to_value}</span>
        </div>
      }
    </div>
  )
}

export default HistogramRangeControl