import React from 'react'
import _ from 'underscore'

import { Label } from './FamilyDetailsLabel.js'
import { TileContent } from './TileContent.js'

export const FamilyInventors = ({family}) => {
  const { inventors, patFamId } = family
  const have_inventors = inventors && (inventors.length > 0)

  return (
    <div key={`inventors_${patFamId}`}>

      <Label>Inventors</Label>

      <TileContent>
        {!have_inventors && <span>(none)</span>}

        {have_inventors &&
          inventors.map((inventor, idx) => {
            const inventor_name = _.isObject(inventor) ? inventor.name : inventor
            return (
              <span
                key={idx}
              >
                <span>{(idx !== 0) && ', '}</span>
                <span>{inventor_name}</span>
              </span>
            )
          })
        }
      </TileContent>

    </div>
  )
}