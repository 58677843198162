import React, { useState } from 'react'
import cn from 'classnames'

import SearchBar from '../widgets/SearchBar.js'
import PatentFamilyListGuideLink from './PatentFamilyListGuideLink.js'

import s from './PatentFamilyListBooleanSearchBar.module.scss'

const PatentFamilyListBooleanSearchBar = (
  {
    search_input_ref,
    search_phrase: search_phrase_prop,
    on_change_search_phrase_handler,
    is_fetching,
    className
  }) => {
  const [search_phrase, set_search_phrase] = useState(null)

  function on_clear_search_phrase() {
    set_search_phrase('')
    search_input_ref.current.focus()
  }

  function apply_search_phrase() {
    if (search_phrase == null) return
    on_change_search_phrase_handler(search_phrase)
    set_search_phrase(null)
  }

  const search_phrase_to_display = search_phrase != null ? search_phrase : search_phrase_prop

  return (
    <div className={cn('p-1', s.input_wrapper, className)}>
      <div className='fs-unmask'>
        <PatentFamilyListGuideLink />
      </div>
      <SearchBar
        search_input_ref={search_input_ref}
        search_input={search_phrase_to_display || ''}
        on_change_search_input={set_search_phrase}
        do_search={apply_search_phrase}
        on_clear={on_clear_search_phrase}

        placeholder='Enter query to filter results'
        no_button={true}
        autofocus={false}
        is_search_valid={true}
        search_is_fetching={is_fetching}
        textAreaClassName={s.input}
      />
    </div>
  )
}

export default PatentFamilyListBooleanSearchBar