import React from 'react'
import _ from 'underscore'
import { sum, extent } from 'd3'

import Plot from './CustomPlotLazy.js'
import { GRANTED_COUNTRIES_PER_FAMILY_BY_ORG } from '../../model/spec_ids.js'

import { is_report_series_sort_by_size } from '../../utils/report_utils.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import {
  get_keys_to_value,
  get_value,
  get_value_column_idx,
  get_key_items_sorted_by_value,
  IS_NEXT_AGGLOM,
  get_display_value
} from '../../utils/column_data_utils.js'

import { DEFAULT_CHART_COLOR, LIGHT_GREY_COLOUR } from '../../constants/colours.js'
import {
  KEY_COLUMN_IDX_1D,
  KEY_COLUMN_IDXS_2D, CATEGORY_COLUMN_IDX, SERIES_COLUMN_IDX
} from '../../model/column_data.js'

import ChartAndLegendHolder from './ChartAndLegendHolder.js'
import Legend from './Legend.js'
import {
  BAR_CHART_TYPE,
  BAR_ORIENTATION_HORIZONTAL,
  BARMODE_CLUSTER,
  BASE_LAYOUT_PROPS,
  BASE_OTHER_PROPS,
  BUBBLE_CHART_TYPE,
  LINEMODE_AREA,
  SCATTER_CHART_TYPE
} from './base_plotly_props.js'
import { should_hide_next_agglom_item, THUMBNAIL_MAX_SERIES } from '../../utils/main_items_utils.js'
import BasePlotlyXYChartWrapper from './BasePlotlyXYChartWrapper.js'
import {
  NORMAL_MARGIN,
  THUMBNAIL_MARGIN,
  BUBBLE_CHART_NORMAL_MARGIN
} from './base_plotly_props.js'
import ReportDeckLegend from './ReportDeckLegend.js'

const Y_TICK_NAME_LENGTH_LIMIT = 45
const DECK_Y_TICK_NAME_LENGTH_LIMIT = 17

function get_margin({is_thumbnail, chart_type, orientation, is_report_deck, item}) {
  if (is_thumbnail) {
    return THUMBNAIL_MARGIN
  }

  const is_bubble_chart = (chart_type === BUBBLE_CHART_TYPE)

  if (is_bubble_chart) {
    return BUBBLE_CHART_NORMAL_MARGIN
  }

  const is_bar_chart = (chart_type === BAR_CHART_TYPE && orientation === BAR_ORIENTATION_HORIZONTAL)

  const {show_custom_series_labels} = item || {}

  if (is_bar_chart) {
    return is_report_deck ? {...NORMAL_MARGIN, 'l': (show_custom_series_labels) ? 20 : 125} : {...NORMAL_MARGIN, 'l': 300}
  }

  return NORMAL_MARGIN
}

function get_plotly_data({ chart_type, spec, data, key_dims, item, report_series_sort, data_creation_date, orientation, selections, ref_data, is_report_deck, clickthrough_item }) {
  const { plotly_data } = get_processed_data({ chart_type, spec, data, key_dims, item, report_series_sort, data_creation_date, orientation, is_report_deck, selections, ref_data, clickthrough_item })
  return plotly_data
}

function get_axis_titles(spec, status_filter) {
  const column_names = spec.get_column_names ? spec.get_column_names({status_filter}) : spec.column_names

  const x_axis_title = column_names[0]
  const y_axis_title = column_names.slice(-1)[0]

  return { y_axis_title, x_axis_title }
}

function get_csv_value_rows({ chart_type, spec, data, key_dims, item, report_series_sort, data_creation_date, orientation }) {
  const { plotly_data, is_1d } = get_processed_data({ chart_type, spec, data, key_dims, item, report_series_sort, data_creation_date, orientation })

  if (!plotly_data.length) {
    return []
  }

  const header_row = is_1d ? plotly_data[0].x : ['', ...plotly_data[0].x]

  const main_rows = plotly_data.map((element) => {
    return is_1d ? element.y : [element.name, ...element.y]
  })

  return [header_row, ...main_rows]
}

function get_bubbles_series({x_items_filtered, legend_item, keys_to_value, data, full_length_value_formatter, is_thumbnail, is_report_deck}) {
  const size = x_items_filtered.map(x_item => get_value(keys_to_value, [x_item.id, legend_item.id]) || 0)
  const desired_maximum_marker_size = is_thumbnail ? 15 : 30
  return {
    x: x_items_filtered.map(x_item => x_item.name),
    y: x_items_filtered.map(() => (legend_item.name)),
    name: legend_item.name,
    type: BUBBLE_CHART_TYPE,
    text: size.map(item => (get_display_value(item, full_length_value_formatter, ''))),

    marker: {
      color: legend_item.color,
      size: size,
      sizeref: 2.0 * Math.max(...data.data[2]) / (desired_maximum_marker_size**2),
      sizemode: 'area'
    },

    mode: 'markers',
    hoverinfo: is_report_deck ? 'none' : 'text+x+y',
  }
}

function get_scatter_series({x_items_filtered, legend_item, is_1d, keys_to_value, linemode, full_length_value_formatter, is_thumbnail, is_report_deck}) {
  const texts = []

  const series = {
    hoverinfo: is_report_deck ? 'none' : null,
    x: x_items_filtered.map(x_item => x_item.name),
    y: x_items_filtered.map(x_item => {
      const value = get_value(keys_to_value, is_1d ? [x_item.id] : [x_item.id, legend_item.id]) || 0
      texts.push(get_display_value(value, full_length_value_formatter, ''))
      return value
    }),
    name: legend_item.name,
    id: legend_item.id, // not much help, is there a way to add in the x ids?
    type: SCATTER_CHART_TYPE,
    marker: {
      color: legend_item.color
    }
  }

  const is_area = (linemode != null) && (linemode === LINEMODE_AREA)

  return is_area ?
    {...series, stackgroup: 'one', groupnorm:'percent', hoverinfo: 'text+x+y', text: texts, hovertemplate: '%{y}<br>%{text}'} :
    {...series, mode: is_thumbnail ? 'lines' : 'lines+markers'}
}

function get_truncated_text(name, length, with_ellipsis, strict_length) {
  if (name.length <= length) return name

  const parts = name.split(',')

  let i = 0
  let truncated_name = parts[0].trim()

  while ((truncated_name.length < length) && (i < parts.length - 1)) {
    i++
    truncated_name = truncated_name + ', ' + parts[i].trim()
  }

  if (strict_length) {
    truncated_name = truncated_name.substr(0, length)
  }

  if (with_ellipsis) {
    truncated_name = `${strict_length ? truncated_name.substr(0,length - 3) : truncated_name}...`
  }

  return truncated_name
}

function get_bar_series({x_items_filtered, legend_item, is_1d, keys_to_value, families_total_count, is_horizontal_bars_chart, is_report_deck, item, spec, selections, ref_data}) {
  const x_items = x_items_filtered.map(x_item => x_item.name)
  const y_items = x_items_filtered.map(x_item => get_value(keys_to_value, is_1d ? [x_item.id] : [x_item.id, legend_item.id]) || 0)

  const { get_series_colour, show_custom_series_labels } = item
  const { include_relative_values } = spec

  return {
    hoverinfo: is_report_deck ? 'none' : null,
    x: !is_horizontal_bars_chart ? x_items : y_items,
    y: !is_horizontal_bars_chart ? y_items : x_items_filtered.map(item => {
      const {portfolio_to_company_list} = ref_data || {}
      // use special name length limit for benchmarking orgs. todo: need a less hacky solution for when orgs have very similar names
      const y_tick_name_length_limit = (is_report_deck && portfolio_to_company_list && portfolio_to_company_list[item.id]) ? DECK_Y_TICK_NAME_LENGTH_LIMIT : Y_TICK_NAME_LENGTH_LIMIT
      return get_truncated_text(item.name, y_tick_name_length_limit, !is_report_deck, true)
    }),
    name: legend_item.name,
    id:  is_1d ? x_items_filtered.map(x_item => (x_item.id)) : legend_item.id, // ids of x_items, needed for clickthroughs
    type: BAR_CHART_TYPE,
    ...is_horizontal_bars_chart ? {
      orientation: BAR_ORIENTATION_HORIZONTAL,
      text: is_1d ? x_items : get_truncated_text(legend_item.name, 12,true, false),
      hoverinfo: is_report_deck ? 'none' : 'x+text',
      ...show_custom_series_labels ? {
        width: 1,
      } : {}
    } : {},
    marker: {
      color: x_items_filtered.map(x_item => {
          return is_1d ? x_item.color || DEFAULT_CHART_COLOR  // for bar 1d, try to color by the x_item (otherwise fall back to default)
            : (is_report_deck && get_series_colour) ? get_series_colour({x_item, y_item: legend_item, selections, ref_data}) : legend_item.color // for bar 2d
        }),

      line: {
        color: 'white',
        width: 0.25     // Add a white hairline between column stacks (helps to differentiate between series that share the same colour)
      }
    },
    ...(is_report_deck && !is_horizontal_bars_chart && include_relative_values && (families_total_count != null)) ? {
      text: y_items.map(value => {
        const percentage = Math.round((value/families_total_count) * 100)
        return `${percentage}%`
      }),
      textposition: 'auto',
    } : {}
  }
}

function get_plotly_series({x_items_filtered, legend_item, is_1d, keys_to_value, chart_type, data, families_total_count, linemode, full_length_value_formatter, item, spec, is_thumbnail, is_horizontal_bars_chart, is_report_deck, selections, ref_data}) {
  switch (chart_type) {
    case BUBBLE_CHART_TYPE:
      return get_bubbles_series({x_items_filtered, legend_item, keys_to_value, data, full_length_value_formatter, is_thumbnail, is_report_deck })
    case SCATTER_CHART_TYPE:
      return get_scatter_series({x_items_filtered, legend_item, is_1d, keys_to_value, full_length_value_formatter, linemode, is_thumbnail, is_report_deck })
    case BAR_CHART_TYPE:
    default:
      return get_bar_series({x_items_filtered, legend_item, is_1d, keys_to_value, families_total_count, is_horizontal_bars_chart, is_report_deck, item, spec, selections, ref_data })
  }
}

function get_x_items_for_thumbnail(x_items, {is_time_series, is_histogram}) {
  if (x_items.length === 0) return []

  if (!is_time_series && !is_histogram) return  x_items.slice(0, THUMBNAIL_MAX_SERIES)
  if (is_time_series) return x_items.slice(THUMBNAIL_MAX_SERIES * -1)

  if (is_histogram) {
    const start =  Math.ceil(x_items.length / 2)
    return x_items.slice(start - 2, start + 3)
  }
}

function get_processed_data({ chart_type, spec, data, families_total_count, key_dims: native_key_dims, item, report_series_sort, report_region_column, barmode, linemode, is_thumbnail, orientation, is_report_deck, selections, ref_data, status_filter, clickthrough_item }) {
  const key = get_key_from_spec(spec, report_region_column, status_filter)

  const {update_key_dims, show_custom_series_labels, get_custom_series_labels} = item
  const key_dims = is_report_deck && update_key_dims ? update_key_dims({key_dims: native_key_dims, ref_data, selections, clickthrough_item}) : native_key_dims

  const is_horizontal_bars_chart = is_horizontal_bars_chart_view({chart_type, orientation})
  const chart_data = data

  const is_1d = (chart_data.data.length === 2) // For now, assume if only 2 column in data, then we want a 1d chart

  const is_time_series = !spec.can_sort_by_size && (spec.timeseries_key_dim_idxs && spec.timeseries_key_dim_idxs.length) // TODO: probably need this for sorting 1d non-timeseries charts

  const { full_length_value_formatter, histogram_bucket_key_dim_idxs } = spec
  const is_histogram = histogram_bucket_key_dim_idxs && (histogram_bucket_key_dim_idxs.length > 0)

  // legend_items (create a fake one for 1d data)
  const legend_items = is_1d ? [{id: '', name: '', color: DEFAULT_CHART_COLOR}] : key_dims[SERIES_COLUMN_IDX]

  const hide_next_x = should_hide_next_agglom_item(spec, item, 0)
  const hide_next_y = should_hide_next_agglom_item(spec, item, 1)

  // x_items
  const x_items = (is_1d ? key_dims[KEY_COLUMN_IDX_1D] : key_dims[CATEGORY_COLUMN_IDX])
    // remove rolled up items if spec doesn't allow them
    .filter(x_item => !(hide_next_x && x_item[IS_NEXT_AGGLOM]))

  const x_key = key[0] // query key i.e. 'PFSBY.year'
  const y_key = key.length > 1 ? key[1] : null

  const value_column_idx = get_value_column_idx(chart_data)

  const key_idxs = is_1d ? [KEY_COLUMN_IDX_1D] : KEY_COLUMN_IDXS_2D

  const keys_to_value = get_keys_to_value(chart_data, key_idxs, value_column_idx, spec.show_average)

  // For 1d non-timeseries, sort x_items by value descending (but make sure "NEXT" / "OTHER" agglom goes at the end)

  const y_keys_to_value = get_keys_to_value(chart_data, [1], value_column_idx, spec.show_average)
  const filtered_legend_items = legend_items.filter(item => !(hide_next_y && item[IS_NEXT_AGGLOM]))
  const legend_items_sorted =  get_key_items_sorted_by_value(filtered_legend_items, y_keys_to_value)

  const clean_legend_items = is_thumbnail ? legend_items_sorted.slice(0, THUMBNAIL_MAX_SERIES) : legend_items_sorted

  const legend_with_bespoke_sorting = spec.sort_legend_function ? spec.sort_legend_function({legend_items: clean_legend_items, ref_data, selections}) : null
  const legend = legend_with_bespoke_sorting ? legend_with_bespoke_sorting : is_horizontal_bars_chart && (barmode === BARMODE_CLUSTER) ? clean_legend_items.slice().reverse() : clean_legend_items

  //sort items
  const x_items_sorted  = is_report_series_sort_by_size(report_series_sort) && !is_time_series && !is_histogram ? get_key_items_sorted_by_value(x_items, keys_to_value, is_1d) : x_items
  //prepare items for thumbnail
  const x_items_for_display = is_thumbnail ? get_x_items_for_thumbnail(x_items_sorted || [], {is_time_series, is_histogram}) : x_items_sorted
  //filter out 0 series if possible
  let x_items_filtered
  // We can't do this for lines charts, time series / histogram column columns (breaks these charts)
  if (chart_type === SCATTER_CHART_TYPE ||
    chart_type === BUBBLE_CHART_TYPE ||
    is_time_series ||
    is_histogram ||
    (!is_1d && x_items_for_display[x_items_for_display.length - 1] && x_items_for_display[x_items_for_display.length - 1].is_next_agglom)
  ) {
    x_items_filtered = x_items_for_display
  } else {
    x_items_filtered = x_items_for_display.filter(x_item => {

      const values = legend.map(legend_item => {
        return get_value(keys_to_value, is_1d ? [x_item.id] : [x_item.id, legend_item.id], 0)
      })

      return _.some(values, (value) => value !== 0)
    })
  }

  const plotly_data = legend.map(legend_item => {
     return get_plotly_series({x_items_filtered: !is_horizontal_bars_chart ? x_items_filtered : x_items_filtered.slice().reverse(), legend_item, is_1d, keys_to_value, chart_type, data: chart_data, families_total_count, linemode, full_length_value_formatter, item, spec, is_thumbnail, is_horizontal_bars_chart, is_report_deck, selections, ref_data})
  })

  const annotations = show_custom_series_labels && get_custom_series_labels ? get_custom_series_labels({plotly_data, ref_data, selections}): null

  // get range
  const y_values = get_y_values(plotly_data, chart_type, orientation)
  const [y_min, y_max] = extent(y_values)
  const y_min_or_zero = spec.axis_range ? y_min : Math.min(y_min, 0)
  const y_range = spec.axis_range ? spec.axis_range(y_min, y_max) : null

  // Axis titles
  const { x_axis_title, y_axis_title } = get_axis_titles(spec, status_filter)
  return {
    plotly_data,
    is_1d,
    x_items_sorted: !is_horizontal_bars_chart ? x_items_filtered : x_items_filtered.slice().reverse(),
    x_key,
    legend_items: clean_legend_items,
    x_axis_title,
    y_axis_title,
    is_time_series,
    y_key,
    y_min: y_min_or_zero,
    y_max,
    y_range,
    annotations
  }
}

function get_y_values(plotly_data, chart_type, orientation) {
  if (is_bubble_chart_view({chart_type})) {
    return _.flatten(plotly_data.map(item => item.marker.size))
  }
  const is_horizontal_bars_chart = is_horizontal_bars_chart_view({chart_type, orientation})
  return _.flatten(plotly_data.map(item => (is_horizontal_bars_chart) ? item.x : item.y))
}

function is_bubble_chart_view({chart_type}) {
  return chart_type === BUBBLE_CHART_TYPE
}

function is_area_chart_view({chart_type, linemode}) {
  return chart_type === SCATTER_CHART_TYPE && linemode === LINEMODE_AREA
}

function is_horizontal_bars_chart_view({chart_type, orientation}) {
  return chart_type === BAR_CHART_TYPE && orientation === BAR_ORIENTATION_HORIZONTAL
}

function get_axis_props({y_spread, y_range, use_value_prefix, value_prefix, value_suffix, is_time_series, x_axis_title, y_axis_title, hover_value_format, chart_type,  linemode, orientation, item }) {
  const {show_custom_series_labels} = item

  const is_bubble_chart          = is_bubble_chart_view({chart_type})
  const is_area_chart            = is_area_chart_view({chart_type, linemode})
  const is_horizontal_bars_chart = is_horizontal_bars_chart_view({chart_type, orientation})

  const should_use_value_prefix = (use_value_prefix != null) ? use_value_prefix : true

  const should_use_value_suffix = (value_suffix) ? value_suffix : false

  const x_axis_tickformat = 'd'
  const y_axis_tickformat = y_spread <= 10 ? 'd' : null

  const y_axis_tickprefix = should_use_value_prefix ? value_prefix : ''
  const y_axis_ticksuffix = (is_area_chart || should_use_value_suffix) ? (is_area_chart? '%' : value_suffix) : ''

  const axis_props = {
    titlefont: {
      size: 11
    }
  }

  const x_axis_props = {
    ...axis_props,
    title: !is_time_series ? null : x_axis_title,
    zeroline: false,
    fixedrange: true, // disable select/zoom
    tickformat: x_axis_tickformat, // if numbers, only show integers
    showticklabels: !show_custom_series_labels,
    ticks: show_custom_series_labels ? '' : null
  }

  const y_axis_props = {
    ...axis_props,
    title: is_bubble_chart ?  '' : y_axis_title + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', // hack to ensure title takes a whole line (and is not interleaved with result)
    tickformat: y_axis_tickformat,
    // Frustratingly, plotly does not let us pass in a function for the tick format: https://github.com/plotly/plotly.js/issues/1464
    // This means we are stuck with d3 format strings.
    // We want to avoid having y-axis interpolated with non-decimals (i.e. 0.5 patents does not make sense).
    // Using 'd' (integer) everywhere is not possible, as large numbers (i.e. $123,456) will overflow across the y-axis title.
    // So we only use 'd' when the y_spread (difference between min and max) is less than 10.
    // Otherwise we fallback to the default (which uses d3 's' - i.e. $123,456 is displayed as $100k)
    fixedrange: true, // disable select/zoom
    tickprefix: y_axis_tickprefix,
    ticksuffix: y_axis_ticksuffix,
    hoverformat: hover_value_format,
    rangemode: y_range ? 'normal' : 'tozero',
    range: y_range
  }

  return {x_axis_props: (is_horizontal_bars_chart) ? y_axis_props : x_axis_props, y_axis_props: (is_horizontal_bars_chart) ? {...x_axis_props, ticklen: '4', tickcolor: LIGHT_GREY_COLOUR} : y_axis_props}
}

/**
 * Renders various plotly XY charts i.e. 'Bar', 'Scatter' etc...
 * @param {} chart_type Must be one of 'bar' (column), 'scatter' (line).
 */
const BasePlotlyXYChart = ({ chart_type, spec, data, families_total_count, key_dims, item, is_thumbnail, selections, ref_data, set_families_subselections, report_series_sort, barmode, linemode, use_value_prefix, data_creation_date, orientation, is_report_deck, status_filter, chart_wrapper_height, clickthrough_item }) => {
  const { value_prefix, no_clickthrough, value_suffix, include_relative_values } = spec

  const { report_region_column } = selections
  const { plotly_data, is_1d, x_items_sorted, x_key, y_key, legend_items, x_axis_title, y_axis_title, is_time_series, y_min, y_max, y_range, annotations } = get_processed_data({ chart_type, spec, data, families_total_count, key_dims, item, report_series_sort, report_region_column, barmode, linemode, is_thumbnail, data_creation_date, orientation, is_report_deck, selections, ref_data, status_filter, clickthrough_item })

  const is_bubble_chart = is_bubble_chart_view({chart_type})
  const is_horizontal_bars_chart = is_horizontal_bars_chart_view({chart_type, orientation})

  // Only show legend for 2d non-thumbnail if not bubble chart
  const show_legend = !is_thumbnail && !is_1d && !is_bubble_chart
  const filtered_floats_below_1 = plotly_data.filter(series => {
    const any_non_zero_value_below_1 = series.y.filter(value => { return (value > 0 && value < 1)})
    return !_.isEmpty(any_non_zero_value_below_1)
  })

  const is_geo_distr = spec.id === GRANTED_COUNTRIES_PER_FAMILY_BY_ORG
  const show_decimal_places = is_geo_distr && !no_clickthrough && filtered_floats_below_1.length > 0
  const hover_value_format = spec.hover_value_format || (show_decimal_places ? ',.2f' : ',.0f')

  // See https://plot.ly/javascript/reference/#bar
  // See https://plot.ly/javascript/reference/#scatter

  const y_spread = y_max - y_min

  const margin = get_margin({is_thumbnail, chart_type, orientation, is_report_deck, item})

  const thumbnail_axis_props = {
    title: null,
    ticks: '',
    showticklabels: false,
    showgrid: false,
    fixedrange: true
  }

  const {x_axis_props, y_axis_props} = get_axis_props({y_spread, y_range, use_value_prefix, value_prefix, value_suffix, is_time_series, x_axis_title, y_axis_title, hover_value_format, chart_type, linemode, orientation, status_filter, item })

  const chart = (
    <BasePlotlyXYChartWrapper
      is_thumbnail={is_thumbnail}
      no_clickthrough={no_clickthrough}
      chart_wrapper_height={chart_wrapper_height}
    >
      <Plot
        {...BASE_OTHER_PROPS}
        data={is_bubble_chart ? plotly_data.slice().reverse() : plotly_data}
        layout={{
          ...BASE_LAYOUT_PROPS,

          margin: margin,

          barmode: barmode,

          hovermode: ((is_report_deck && !is_1d) || (is_horizontal_bars_chart && !is_thumbnail)) ? 'closest' : !is_thumbnail,

          hoverlabel: {
            bgcolor: 'black' // tooltips all in black
          },

          xaxis: {
            ...x_axis_props,
            ...(is_thumbnail) ? {...thumbnail_axis_props} : {}
          },

          yaxis: {
            side: is_bubble_chart ? 'right' : 'left',
            ...y_axis_props,
            ...(is_thumbnail) ? {...thumbnail_axis_props, zeroline: false} : {},
          },

          annotations: annotations
        }}
        useResizeHandler={true}
        style={{width: '100%', height: '100%'}}
        config={{displayModeBar: false}}

        onClick= {function({points, event}) {
          // NOTE:
          //
          // In December 2018, we noticed an issue where click handlers were not being updated when data changed
          // (for example by dragging the time range slider), resulting in stale data and broken click throughs:
          // https://trello.com/c/nB4bDoyT/1399-some-of-the-columns-arent-clickable-on-safari
          //
          // It seems like this was fixed React-Plotly in October 2019:
          // https://github.com/plotly/react-plotly.js/pull/151

          if (is_thumbnail) {
            return
          }

          if (!points || !points.length) {
            return
          }

          const is_horizontal_bars_chart = is_horizontal_bars_chart_view({chart_type, orientation})
          const value = is_horizontal_bars_chart ? sum(points.map(item => (item.x))) : sum(points.map(item => (item.y)))
          const relative_value = (include_relative_values && families_total_count != null) ? ((value / families_total_count) * 100).toFixed(2): null

          const { pointIndex, data } = points[0] // i.e. might be column for 2017
          const x_item = x_items_sorted[pointIndex]

          if (!x_item) {
            // No item anymore (DOM may have been updated already (i.e. by some network call)).
            // So do nothing.
            return
          }

          const item = { key: x_key, items: [x_item] }

          if (is_report_deck) {
            // where did the click happen?
            // offset properties are more reliably populated, but seem not to be available for touchscreen events
            const x_coord = event.pointerX || event.offsetX
            const y_coord = event.pointerY || event.offsetY

            if ((!x_coord && !y_coord) || (x_coord === 0 && y_coord === 0)) {
              return
            }

            // for a timeseries click we need a little more info
            const deck_clickthrough_item = is_time_series ?
              {
                key: y_key,
                items: [{id: data.id, name: data.name}],
                year_key: x_key,
                year_items: item.items
              } : item

            set_families_subselections(
              {
                coords: [x_coord, y_coord],
                item: deck_clickthrough_item,
                value,
                relative_value
              }
            )
            return
          }

          set_families_subselections(
            [item], // the subselections
            value   // the value
          )
        }}
      />
    </BasePlotlyXYChartWrapper>
  )

  const legend = !show_legend ? null : is_report_deck ? <ReportDeckLegend items={legend_items} /> : <Legend items={legend_items} />

  return (
    <ChartAndLegendHolder
      chart={chart}
      legend={legend}
      is_report_deck={is_report_deck}
    />
  )
}

BasePlotlyXYChart.get_plotly_data = get_plotly_data

BasePlotlyXYChart.get_axis_titles = get_axis_titles

BasePlotlyXYChart.get_csv_value_rows = get_csv_value_rows

export default BasePlotlyXYChart
