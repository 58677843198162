import React from 'react'
import cn from 'classnames'

import FamilyActions from '../tech_explorer/FamilyActions.js'
import { FamilyDetailsTile } from './FamilyDetailsTile.js'
import { LabelInline } from './FamilyDetailsLabel.js'

const TechExplorerSelectionControls = (
  {
    family_id,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,

    className
  }) => {
  return (
    <FamilyDetailsTile
      className={cn('d-flex justify-content-between',className)}
    >
      <LabelInline>Actions</LabelInline>
      <FamilyActions
        family_id={family_id}
        family_ids={knn_family_ids}
        blocklist={knn_blocklist}
        bookmarked={knn_bookmarked}

        on_add_to_family_ids={on_add_to_knn_family_ids}
        on_add_to_blocklist={on_add_to_knn_blocklist}
        on_add_to_bookmarked={on_add_to_knn_bookmarked}
      />
    </FamilyDetailsTile>
  )
}

export default TechExplorerSelectionControls