import React, { useEffect, useState } from 'react'

import { PrimaryButton } from '../../widgets/Button.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import SaveSetsModal from './SaveSetsModal.js'

const RemoveSet = (
  {
    selected_target_user_or_group,
    current_sets,
    on_save_handler
  }) => {

  const [is_save_modal_open, set_is_save_modal_open] = useState(false)
  const [updated_sets, set_updated_sets] = useState(null)
  const [to_remove, set_to_remove] = useState([])

  useEffect(() => {
    set_is_save_modal_open(false)
    set_to_remove([])
    set_updated_sets(null)
  }, [selected_target_user_or_group])

  function on_save() {
    set_is_save_modal_open(false)
    on_save_handler(updated_sets)
    set_to_remove([])
    set_updated_sets(null)
  }

  function on_toggle(item) {
    const {name} = item
    const is_selected = to_remove.indexOf(name) > -1

    const updated_to_remove = is_selected ? to_remove.filter(set_name => set_name !== name) : [...to_remove, name]

    set_to_remove(updated_to_remove)
  }

  function on_apply() {
    const filtered_sets = current_sets.filter(item => {
      const {name} = item

      return to_remove.indexOf(name) === -1
    })
    set_updated_sets(filtered_sets)
    set_is_save_modal_open(true)
  }

  if (!current_sets || current_sets.length === 0) {
    return (
      <div>No sets to remove</div>
    )
  }

  return (
    <div>
      <div>Select sets to remove:</div>

      <div>
        {(current_sets || []).map((item, i) => {
          const {name} = item
          const is_selected = to_remove.indexOf(name) > -1
          return (
            <CheckboxAndLabel
              key={i}
              label={name}
              is_checked={is_selected}
              on_click={() => on_toggle(item)}
            />
          )
        })}

        <PrimaryButton className='mt-3' disable={to_remove.length === 0} onClick={on_apply}>Apply</PrimaryButton>
      </div>

      <SaveSetsModal
        is_open={is_save_modal_open}
        on_hide={() => set_is_save_modal_open(false)}
        on_save={on_save}
        sets={current_sets}
        sets_to_warn={to_remove || []}
      />
    </div>
  )
}

export default RemoveSet