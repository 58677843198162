import React from 'react'
import * as d3 from 'd3'
import _ from 'underscore'

import { format_financial_amount } from './utils/executive_summary_utils.js'
import SvgText from './svg/SvgText.js'
import SvgTitle from './svg/SvgTitle.js'
import MiniLineChart from './charts/MiniLineChart.js'
import MiniBarChart from './charts/MiniBarChart.js'
import { BAR_HEIGHT, BAR_MARGIN } from './charts/MiniBarChart.js'
import { SPECIAL_CHAR_FONT_FAMILY, TEXT_SPECIAL_CHAR_STYLE_REACT, TEXT_FONT_FAMILY } from './svg/svg_styles.js'
import { CIPHER_BLUE } from '../../constants/colours.js'
import { format_number_with_precision } from './utils/executive_summary_utils.js'
import Mini4BubbleChart from './charts/Mini4BubbleChart.js'
import MiniColumnChart from './charts/MiniColumnChart.js'
import MiniAreaChart from './charts/MiniAreaChart.js'
import MiniAreaMirrorChart from './charts/MiniAreaMirrorChart.js'
import {
  filter_cost,
  get_age_chart_data,
  get_cost_chart_data,
  get_geography_portfolio_data,
  get_litigation_chart_data,
  get_litigations_y_domain,
  get_mean_spend, get_portfolio_overlay_data, get_portfolio_size_chart_data,
  get_spend_over_past_years, get_technology_size_chart_data,
  STATUS_DIMENSIONS,
  YEAR_BUCKET_DIMENSIONS
} from './utils/executive_summary_columns_utils.js'
import {
  BASE_COLOUR,
  EXECUTIVE_SUMMARY_COLOURS,
  MAX_CLUSTERS,
  NUM_COMPANIES
} from './utils/executive_summary_constants.js'

/*
 TODO:
 - technologies: max 8 clusters. So where more than this, combine 8 and up into 'other'? Or not?
 - Should each chart type share y axis? If so... probably want to do row-wise, rather than column wise
*/

const LABEL_COLUMN_WIDTH = 100,
  COLUMN_WIDTH = 125,
  COLUMN_MARGIN = 25,
  OVERLAY_MARGIN = 8,
  OVERLAY_LINE_HEIGHT = 12,
  TITLE_HEIGHT = 30,
  MINICHART_HEIGHT = 110,
  BAR_HEIGHT_TOTAL = BAR_HEIGHT + BAR_MARGIN,
  SPEND_FAM_SPACER = 6


function get_positioned_items(items) {
  return items.map(function (item, i) {
    const heights_so_far = items.slice(0, i).map(function (item) {
      return item.height
    })
    const y_pos = d3.sum(heights_so_far)

    return (
      <g transform={'translate(0, ' + y_pos + ')'} key={i}>
        {item.background_colour &&
          <rect
            height={item.height}
            width={COLUMN_WIDTH}
            x='0'
            y='0'
            style={{fill: item.background_colour, stroke: item.background_colour, strokeWidth: 1}}
          />
        }
        {item.element}
      </g>
    )
  })
}

const Columns = ({data}) => {

  const { companies, clusters, report_year, defendant_lit_by_porfolio, plaintiff_lit_by_porfolio } = data || {}
  const selected_companies = companies.slice(0, NUM_COMPANIES)

  if (clusters.length > MAX_CLUSTERS) {
    // We can only show MAX_CLUSTERS clusters in the Technologies bar chart.
    // As there are more than MAX_CLUSTERS clusters, we will need to
    // provide a special 'rolled up' version of the 'granted_size' cube,
    // and also a special 'rolled up' version of the clusters items,
    // where clusters MAX_CLUSTERS+ are rolled into a single 'other' cluster.

    // Get ids for clusters MAX_CLUSTERS+
    const other_cluster_ids = clusters.slice(MAX_CLUSTERS - 1).map(cluster => cluster.id.toString())

    // var old_other_cluster_id = clusters.slice(-1)[0].id;
    const new_other_cluster_id = clusters[MAX_CLUSTERS - 1].id.toString()

    // Get rolled up clusters items
    const clusters_rolled = [
      ...clusters.slice(0, MAX_CLUSTERS - 1), // first clusters
      _.extend({}, clusters.slice(-1)[0], {id: new_other_cluster_id, name: 'Other', short_name: 'Other'}) // the rolled up ('other') cluster
    ]

    // Get rolled_up 'granted_size' cube

    // First, separate out the 'other' and 'non_other' items
    let granted_non_other = []
    let granted_other = []
    data.granted_size_by_tech.forEach(function (item) {
      if (_.contains(other_cluster_ids, item.cluster_id)) {
        granted_other.push(item)
      } else {
        granted_non_other.push(item)
      }
    })

    // Now, group items by (company, year)
    let company_and_year_to_items = {}
    granted_other.forEach(function (item) {
      const key = item.company_id + '_' + item.year
      let items = company_and_year_to_items[key]
      if (!items) {
        items = []
        company_and_year_to_items[key] = items
      }
      items.push(item)
    })

    // For each (company, year), merge the items
    const granted_other_rolled = _.values(company_and_year_to_items)
      .map(function (items) {
        // merge to single item
        const total_value = d3.sum(items.map(function (d) {
          return d.value
        }))
        return _.extend({}, items[0], {value: total_value, cluster_id: new_other_cluster_id})
      })

    // Combine the 'other' and 'non_other' items again
    const granted_size_rolled = granted_non_other.concat(granted_other_rolled)

    // Add rolled cube/clusters to data (without mutating original)
    data = _.extend({}, data, {clusters_rolled: clusters_rolled, granted_size_rolled: granted_size_rolled})
  }

  const total_column_width = COLUMN_WIDTH + COLUMN_MARGIN

  // get min/max for litigation charts
  const LITIGATION_YEARS = [report_year - 9, report_year + 1] // past 10 years including current year. i.e. If current is 2018, gives [2009, 2018] inclusive.
  const litigation_y_domain = get_litigations_y_domain(defendant_lit_by_porfolio, plaintiff_lit_by_porfolio, selected_companies, LITIGATION_YEARS)

  const columns = d3.range(NUM_COMPANIES).map(function (d, i) {
    const x_offset = LABEL_COLUMN_WIDTH + (total_column_width * i)
    return (
      <g transform={'translate(' + x_offset + ', 0)'} key={i}>
        <Column
          data={data}
          selected_company_idx={i}
          litigation_y_domain={litigation_y_domain}
        />
      </g>
    )
  })

  return (
    <g>
      <LabelColumn data={data}/>
      {columns}
    </g>
  )
}

/*
 * The labels for the technologies (at the far left, next to the technologies chart)
 */
const TechnologyLabels = ({clusters}) => {
  const labels = clusters.map(function (cluster, i) {
    const y_pos = i * BAR_HEIGHT_TOTAL
    const label = cluster.short_name
    return (
      <g transform={'translate(0, ' + y_pos + ')'} key={i}>
        <SvgText label={label} colour={BASE_COLOUR} width={LABEL_COLUMN_WIDTH}/>
      </g>
    )
  })
  return (
    <g>
      {labels}
    </g>
  )
}


/*
 * The left-most column (contains titles for each row)
 */
const LabelColumn = ({data}) => {
  const {clusters_rolled, clusters} = data || {}

  // Colour scale (for legend)
  const colour = d3.scaleLinear()
    .domain([0, 100])
    .range(['#ffffff', BASE_COLOUR])


  const clusters_to_display = clusters_rolled || clusters
  const items = [
    {
      // Portfolio header
      height: TITLE_HEIGHT,
      element: (
        <SvgTitle label={'Portfolio'} colour={BASE_COLOUR} width={COLUMN_WIDTH}/>
      )
    },

    {
      // Portfolio overlay
      height: OVERLAY_LINE_HEIGHT,
      element: (
        <SvgText label={'Size (families)'}/>
      )
    },

    {
      // Portfolio chart
      height: MINICHART_HEIGHT - OVERLAY_LINE_HEIGHT
    },

    {
      // Geographies header
      height: TITLE_HEIGHT,
      element: (
        <SvgTitle label={'Geography'} colour={BASE_COLOUR} width={COLUMN_WIDTH}/>
      )
    },

    {
      // Geographies chart
      height: MINICHART_HEIGHT
    },

    {
      // Technologies header
      height: TITLE_HEIGHT,
      element: (
        <SvgTitle label={'Technologies'} colour={BASE_COLOUR} width={COLUMN_WIDTH}/>
      )
    },

    {
      // Technologies chart
      height: MINICHART_HEIGHT,
      element: (
        <TechnologyLabels clusters={clusters_to_display}/>
      )
    },

    {
      // Age header
      height: TITLE_HEIGHT,
      element: (
        <SvgTitle label={'Age'} colour={BASE_COLOUR} width={COLUMN_WIDTH}/>
      )
    },

    {
      // Age chart legend
      height: MINICHART_HEIGHT,
      element: (
        <g>
          <g transform={'translate(0, 57)'}>
            <g transform={'translate(0, 0)'}>
              <rect y='-2' width='15' height='15' style={{fill: colour(50)}}/>
              <g transform={'translate(20, 0)'}>
                <SvgText label={'Applications'} colour={BASE_COLOUR}/>
              </g>
            </g>
            <g transform={'translate(0, 20)'}>
              <rect y='-2' width='15' height='15' style={{fill: colour(100)}}/>
              <g transform={'translate(20, 0)'}>
                <SvgText label={'Grants'} colour={BASE_COLOUR}/>
              </g>
            </g>
          </g>
        </g>
      )
    },

    {
      // Expenditure header
      height: TITLE_HEIGHT,
      element: (
        <SvgTitle label={'Expenditure'} colour={BASE_COLOUR} width={COLUMN_WIDTH}/>
      )
    },

    {
      // spacer
      height: SPEND_FAM_SPACER
    },

    {
      // spend/fam
      height: OVERLAY_LINE_HEIGHT,
      element: (
        <SvgText label={'Spend/family'}/>
      )
    },

    {
      // spend/10yr
      height: OVERLAY_LINE_HEIGHT,
      element: (
        <SvgText label={'Spend/10yr'} colour={BASE_COLOUR}/>
      )
    },

    {
      // Expenditure chart
      height: MINICHART_HEIGHT - (2 * OVERLAY_LINE_HEIGHT)
    },

    {
      // Litigation header
      height: TITLE_HEIGHT,
      element: (
        <SvgTitle label={'Litigation'} colour={BASE_COLOUR} width={COLUMN_WIDTH}/>
      )
    },

    {
      // Offensive
      height: OVERLAY_LINE_HEIGHT,
      element: (
        <SvgText label={'Defensive'} colour={BASE_COLOUR}/>
      )
    },

    {
      // Litigation chart
      height: MINICHART_HEIGHT - TITLE_HEIGHT - (2 * OVERLAY_LINE_HEIGHT)
    },

    {
      // Defensive
      height: OVERLAY_LINE_HEIGHT,
      element: (
        <SvgText label={'Offensive'} colour={BASE_COLOUR}/>
      )
    }
  ]

  // SVG does not have any way of 'flowing' elements.
  // So we specify the elements in the items array,
  // and then wrap each in a 'g' and calculate the y position explicitly.
  const positioned_items = get_positioned_items(items)

  return (
    <g>
      {positioned_items}
    </g>
  )

}

const PortfolioOverlay = ({portfolio_overlay_data}) => {
  const style = _.extend({}, TEXT_SPECIAL_CHAR_STYLE_REACT)

  const BLACK_UP_POINTING_TRIANGLE = '\u25B2'
  const BLACK_DOWN_POINTING_TRIANGLE = '\u25BC'
  const BLACK_RIGHT_POINTING_TRIANGLE = '\u25BA'

  const size_diff = portfolio_overlay_data.current_size - portfolio_overlay_data.prev_size

  let triangle

  switch (true) {
    case (size_diff === 0):
      triangle = BLACK_RIGHT_POINTING_TRIANGLE
      break
    case (size_diff < 0):
      triangle = BLACK_DOWN_POINTING_TRIANGLE
      break
    default:
      triangle = BLACK_UP_POINTING_TRIANGLE
  }

  const current_size = format_number_with_precision(portfolio_overlay_data.current_size)

  return (
    <g>
      <g transform={'translate(-' + OVERLAY_MARGIN + ', ' + OVERLAY_MARGIN + ')'}>
        <text style={style} textAnchor='end' width='125' y='10' x='125'>
          {current_size} {' ' + triangle + ' '} {size_diff}
        </text>
      </g>
    </g>
  )
}

class AgeByStatusXAxisTicks extends React.Component {
  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  componentDidMount() {

    var tickY = this.props.tick_pos_y

    var container_node = this.container_ref.current
    var container = d3.select(container_node)

    var tickValues = ['4', '8', '12', '16', '20']

    tickValues.forEach(function (d, i) {
      var tickX = 2 + i * 26

      var text = container.append('text')
        .style('fill', CIPHER_BLUE)
        .style('font-size', '10px')
        .attr('x', tickX)
        .attr('y', tickY)

      text.append('tspan').text('≤').style('font-family', SPECIAL_CHAR_FONT_FAMILY)
      text.append('tspan').text(d).style('font-family', TEXT_FONT_FAMILY)
    })
  }

  render() {
    return (
      <g transform='translate(0,2)' ref={this.container_ref}></g>
    )
  }
}

function get_row_items(selected_company, selected_company_idx, data, clusters, clusters_rolled, litigation_y_domain, current_year) {
  if (selected_company === undefined) {
    return []
  }

  const LAST_10_YEARS = [current_year - 10, current_year]

  let technology_data = data
  let technology_data_cluster_ids = clusters.map(cluster => cluster.id) //all clusters ids
  if (clusters.length > MAX_CLUSTERS) {
    // use rolled up data
    technology_data = _.extend({}, data, {
      granted_size_by_tech: data.granted_size_rolled,
      clusters: data.clusters_rolled
    })
    technology_data_cluster_ids = clusters_rolled.map(function (d) {
      return d.id
    })
  }

  // Get base colour (based on company_id)
  const base_colour = EXECUTIVE_SUMMARY_COLOURS[selected_company_idx]

  // Get colour scale
  const colour = d3.scaleLinear()
    .domain([0, 100])
    .range(['#ffffff', base_colour])

  const company_label = selected_company.short_name

  const portfolio_size_chart_data = get_portfolio_size_chart_data(data.granted_size_by_portfolio, selected_company, current_year)
  const portfolio_overlay_data = get_portfolio_overlay_data(data.granted_size_by_portfolio, selected_company, current_year)
  const geography_portfolio_data = get_geography_portfolio_data(data.grants_by_country_by_portfolio, selected_company)
  const technology_chart_data = get_technology_size_chart_data(technology_data.granted_size_by_tech, selected_company, technology_data_cluster_ids)
  const age_chart_data = get_age_chart_data(data.granted_size_by_priority_year_by_by_portfolio, data.application_size_by_priority_year_by_by_portfolio, selected_company, current_year)

  const filtered_cost_items = filter_cost(LAST_10_YEARS, data.cost_by_portfolio, selected_company)
  const  spend_over_past_years = get_spend_over_past_years(filtered_cost_items)

  const mean_spend = get_mean_spend(data.average_cost_by_portfolio, selected_company)

  const cost_chart_data = get_cost_chart_data(filtered_cost_items, selected_company, LAST_10_YEARS)

  const litigation_chart_data = get_litigation_chart_data(data.defendant_lit_by_porfolio, data.plaintiff_lit_by_porfolio, selected_company, LAST_10_YEARS)

  const items = [
    {
      // Portfolio header
      height: TITLE_HEIGHT,
      background_colour: colour(10),
      element: (
        <SvgTitle label={company_label} align_centre={true} width={COLUMN_WIDTH}/>
      )
    },

    {
      // Portfolio overlay
      height: TITLE_HEIGHT,
      background_colour: colour(20),
      element: (
        <PortfolioOverlay portfolio_overlay_data={portfolio_overlay_data}/>
      )
    },

    {
      // Portfolio chart
      height: MINICHART_HEIGHT - TITLE_HEIGHT,
      background_colour: colour(20),
      element: (
        <MiniLineChart
          width={COLUMN_WIDTH}
          height={MINICHART_HEIGHT - TITLE_HEIGHT}
          values={portfolio_size_chart_data}
          colours={[colour(100)]}
        />
      )
    },

    {
      // Geographies header
      height: TITLE_HEIGHT,
      background_colour: colour(10)
    },

    {
      // Geographies chart
      height: MINICHART_HEIGHT,
      background_colour: colour(10),
      element: (
        <Mini4BubbleChart
          width={COLUMN_WIDTH}
          height={MINICHART_HEIGHT}
          values={geography_portfolio_data.values}
          dims={geography_portfolio_data.dims}
          base_colour={base_colour}
        />
      )
    },

    {
      // Technologies header
      height: TITLE_HEIGHT,
      background_colour: colour(10)
    },

    {
      // Technologies chart
      height: MINICHART_HEIGHT,
      background_colour: colour(20),
      element: (
        <MiniBarChart
          width={COLUMN_WIDTH}
          height={MINICHART_HEIGHT}
          values={technology_chart_data}
          base_colour={base_colour}
          show_percent={true}
        />
      )
    },

    {
      // Age header
      height: TITLE_HEIGHT,
      background_colour: colour(10)
    },

    {
      // Age chart
      height: MINICHART_HEIGHT,
      background_colour: colour(10),
      element: (
        <g>
          <MiniColumnChart
            width={COLUMN_WIDTH}
            height={MINICHART_HEIGHT}
            values={age_chart_data}
            xdims={YEAR_BUCKET_DIMENSIONS}
            stacked_dims={STATUS_DIMENSIONS}
            colours={[colour(100), colour(50)]}
          />
          <AgeByStatusXAxisTicks
            tick_pos_y={MINICHART_HEIGHT - 10}
          />
        </g>
      )

    },

    {
      // Expenditure header
      height: TITLE_HEIGHT,
      background_colour: colour(10)
    },

    {
      // spacer
      height: SPEND_FAM_SPACER,
      background_colour: colour(20)
    },

    {
      // spend/fam
      height: OVERLAY_LINE_HEIGHT,
      background_colour: colour(20),
      element: (
        <g transform={'translate(-' + OVERLAY_MARGIN + ', 0)'}>
          <SvgText
            label={!mean_spend ? 'n/a' : format_financial_amount(mean_spend)}
            align_right={true}
          />
        </g>
      )
    },

    {
      // spend/10yr
      height: OVERLAY_LINE_HEIGHT,
      background_colour: colour(20),
      element: (
        <g transform={'translate(-' + OVERLAY_MARGIN + ', 0)'}>
          <SvgText
            label={!spend_over_past_years ? 'n/a' : format_financial_amount(spend_over_past_years)}
            align_right={true}
            colour={BASE_COLOUR}
          />
        </g>
      )
    },

    {
      // Expenditure chart
      height: MINICHART_HEIGHT - (2 * OVERLAY_LINE_HEIGHT),
      background_colour: colour(20),
      element: (
        <MiniAreaChart
          width={COLUMN_WIDTH}
          height={MINICHART_HEIGHT - (2 * OVERLAY_LINE_HEIGHT)}
          values={cost_chart_data}
          colours={[colour(100)]}
        />

      )
    },

    {
      // Litigation header
      height: TITLE_HEIGHT,
      background_colour: colour(10)
    },

    {
      // Litigation chart
      height: MINICHART_HEIGHT - TITLE_HEIGHT,
      background_colour: colour(10),
      element: (
        <MiniAreaMirrorChart
          width={COLUMN_WIDTH}
          height={MINICHART_HEIGHT - TITLE_HEIGHT - 10}
          values={litigation_chart_data}
          colours={[colour(100), colour(50)]}
          y_domain={litigation_y_domain}
        />
      )
    },
  ]

  return items
}

/*
 * Renders a bunch of charts for the given company id.
 */
const Column = (
  {
    data,
    selected_company_idx,
  }) => {

  const {
    companies,
    clusters,
    clusters_rolled,
    litigation_y_domain,
    report_year: current_year
  } = data || {}

  const selected_company = companies[selected_company_idx]

  const items = get_row_items(selected_company, selected_company_idx, data, clusters, clusters_rolled, litigation_y_domain, current_year)

  // SVG does not have any way of 'flowing' elements.
  // So we specify the elements in the items array,
  // and then wrap each in a 'g' and calculate the y position explicitly.
  const positioned_items = get_positioned_items(items)

  return (
    <g>
      {positioned_items}
    </g>
  )
}


export default Columns
