import React from 'react'
import cn from 'classnames'

import ContainerFullWidth from '../ContainerFullWidth.js'
import TextLink from '../widgets/TextLink.js'
import Filters from './Filters.js'
import { CheckIcon, ExportIcon, GridIcon, ListIcon } from '../widgets/IconSet.js'
import { NO_DOCDB_COUNTRIES } from '../../constants/countries.js'

import s from './ReportControlsBar.module.scss'

function filter_no_docdb_countries_from_continents(continent_id_to_geo_ids) {
  const continents = {}
  Object.keys(continent_id_to_geo_ids).forEach(continent_id => {
    const continent_geo_ids = continent_id_to_geo_ids[continent_id]
    const filtered_geo_ids = continent_geo_ids.filter(item => NO_DOCDB_COUNTRIES.indexOf(item) === -1)
    continents[continent_id] = filtered_geo_ids
  })
  return continents
}

const ReportControlsBar = (
  {
    portfolios,
    portfolios_count_incl_rollups,
    techs,
    geos,
    timerange_field_id_to_default_extent,
    continent_id_to_geo_ids,
    selected_portfolio_ids,
    selected_tech_ids,
    selected_geo_ids,
    selected_timerange,
    set_selected_portfolio_ids,
    set_selected_tech_ids,
    set_selected_geo_ids,
    set_selected_timerange,
    spotlighted_portfolios,
    spotlighted_techs,
    set_spotlighted_portfolios,
    set_spotlighted_techs,
    fetch_initial_error,
    report_has_scores,
    on_back_to_report,
    navigate_to_exports,
    navigate_to_families_view,
    navigate_to_selected,
    enable_back_to_report_link,
    enable_all_families_link,
    enable_exports_link,
    enable_selected_link,
    show_ungrouped_families,
    toggle_show_ungrouped_families
}) => {
  function back_to_report() {
    if (!enable_back_to_report_link) return
    on_back_to_report()
  }

  const has_report_error = (fetch_initial_error != null)

  const filtered_geos = geos && !show_ungrouped_families ? geos.filter(geo => NO_DOCDB_COUNTRIES.indexOf(geo.country_code) === -1) : geos
  const report_has_ungrouped_families = show_ungrouped_families || ((filtered_geos || []).length < (geos || []).length)
  
  const continent_id_to_filtered_geo_ids = report_has_ungrouped_families && !show_ungrouped_families ? filter_no_docdb_countries_from_continents(continent_id_to_geo_ids) : continent_id_to_geo_ids

  return (
    <ContainerFullWidth className={cn('d-flex', s.block)}>

      <div className={cn('my-auto d-flex', s.mode_bar)}>
        <TextLink className={cn(s.report_icon, {[s.report_icon_active]: enable_back_to_report_link})} onClick={() => back_to_report()}><GridIcon /></TextLink>
        <TextLink className={cn('ml-1 ml-lg-2', s.report_icon, {[s.report_icon_active]: enable_selected_link})} disable={has_report_error} onClick={() => navigate_to_selected()} title='Selected'>
          <CheckIcon />
        </TextLink>
        <TextLink className={cn('ml-1 ml-lg-2', s.report_icon, {[s.report_icon_active]: enable_exports_link})} disable={has_report_error} onClick={() => navigate_to_exports()} title='Exports'>
          <ExportIcon />
        </TextLink>
        <TextLink className={cn('ml-1 ml-lg-2', s.report_icon, {[s.report_icon_active]: enable_all_families_link})} disable={has_report_error} onClick={() => navigate_to_families_view()} title='Families list'>
          <ListIcon />
        </TextLink>
      </div>

      <Filters
        portfolios={portfolios}
        portfolios_count_incl_rollups={portfolios_count_incl_rollups}
        techs={techs}
        geos={filtered_geos}
        continent_id_to_geo_ids={continent_id_to_filtered_geo_ids}
        selected_portfolio_ids={selected_portfolio_ids}
        selected_tech_ids={selected_tech_ids}
        selected_geo_ids={selected_geo_ids}
        set_selected_portfolio_ids={set_selected_portfolio_ids}
        set_selected_tech_ids={set_selected_tech_ids}
        set_selected_geo_ids={set_selected_geo_ids}
        selected_timerange={selected_timerange}
        set_selected_timerange={set_selected_timerange}
        timerange_field_id_to_default_extent={timerange_field_id_to_default_extent}
        report_has_scores={report_has_scores}
        show_ungrouped_families={show_ungrouped_families}
        toggle_show_ungrouped_families={toggle_show_ungrouped_families}
        report_has_ungrouped_families={report_has_ungrouped_families}
        spotlighted_portfolios={spotlighted_portfolios}
        spotlighted_techs={spotlighted_techs}
        set_spotlighted_portfolios={set_spotlighted_portfolios}
        set_spotlighted_techs={set_spotlighted_techs}
        can_clear_portfolio_spotlights={true}
        can_clear_tech_spotlights={true}
        className={cn('w-100 my-auto justify-content-end ml-1 ml-lg-2', s.filters)}
      />

    </ContainerFullWidth>
  )
}

export default ReportControlsBar
