import React from 'react'
import cn from 'classnames'
import PageTitle from '../classifiers_editor/components/PageTitle.js'
import { Link } from 'react-router-dom'
import { CLASSIFIER_SUBSCRIPTIONS, REPORT_BASED_SUBSCRIPTIONS, CLASSIFIERS, REPORTS } from '../../constants/paths.js'
import { has_old_weekly_classifier_alerts, is_view_only_user } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'

import s from './SubscriptionsNavigation.module.scss'

const SubscriptionsNavigation = ({current_page, user}) => {

  return (
    <div className={s.block}>
      <PageTitle className={cn('d-inline')}>
        <Link
          className={cn(s.item, {[s.item__selected]: (current_page === REPORTS)})}
          to={REPORT_BASED_SUBSCRIPTIONS}
        >
          Report-based alerts
        </Link>
      </PageTitle>
      { has_old_weekly_classifier_alerts(user) && !is_view_only_user(user) &&
        <>
          <span className={cn(s.spacer, 'mx-2')}>|</span>
          <PageTitle className={cn('d-inline')}>
            <Link
              className={cn(s.item, {[s.item__selected]: (current_page === CLASSIFIERS)})}
              to={CLASSIFIER_SUBSCRIPTIONS}
            >
              Weekly classifier alerts
            </Link>
          </PageTitle>
        </>
      }
    </div>
  )
}

export default withUser(SubscriptionsNavigation)