import React from 'react'
import cn from 'classnames'

import PatentFamilyFieldsControl from '../../patent_family_list/PatentFamilyFieldsControl.js'
import { ColumnFilter } from './ColumnFilter.js'
import {
  ALL_FIELDS,
  DEFAULT_FIELDS,
  ID_TO_ALL_FIELDS,
  IGNORE,
  IGNORE_VALUES,
  STATUS,
  TYPE,
  TYPE_VALUES,
} from './matched_patents_table_model.js'

export const MatchedPatentsTableControls = (
  {
    selected_columns_ids=[],
    change_column_selection,
    status_available,
    selected_status_ids,
    on_change_status,
    selected_ignore_ids,
    on_change_ignore,
    selected_type_ids,
    on_change_type,

    className
  }) => {

  return (
    <div className={cn('d-flex', className)}>
      <PatentFamilyFieldsControl
        context='Patent upload'
        all_fields={ALL_FIELDS}
        selected_field_ids={selected_columns_ids}
        default_selected_field_ids={DEFAULT_FIELDS}
        on_change_selected_field_ids={change_column_selection}
      />

      <div className='ml-4 my-auto'>Filters:</div>
      {selected_columns_ids.includes(STATUS) &&
        <ColumnFilter
          className='ml-2'
          label={ID_TO_ALL_FIELDS[STATUS].name}
          values={status_available}
          selected_value_ids={selected_status_ids}
          on_change_selection={on_change_status}
        />
      }
      {selected_columns_ids.includes(IGNORE) &&
        <ColumnFilter
          className='ml-2'
          label='Rows'
          values={IGNORE_VALUES}
          selected_value_ids={selected_ignore_ids}
          on_change_selection={on_change_ignore}
        />
      }
      {selected_columns_ids.includes(TYPE) &&
        <ColumnFilter
          className='ml-2'
          label={ID_TO_ALL_FIELDS[TYPE].name}
          values={TYPE_VALUES}
          selected_value_ids={selected_type_ids}
          on_change_selection={on_change_type}
        />
      }
    </div>
  )
}


