import React from 'react'

import Modal from './widgets/Modal.js'
import ErrorBody from './ErrorBody.js'

const ErrorModal = ({title, error, context, className, on_hide, on_retry, on_clear, is_expected_error, extra_info}) => {

  return (
    <Modal
      className={className}
      on_hide={on_hide}
      title={title || 'Error'}
      on_retry={on_retry}
      on_clear={on_clear}
    >
      <ErrorBody
        error={error}
        context={context}
        is_expected_error={is_expected_error}
        extra_info={extra_info}
      />
    </Modal>
  )

}

export default ErrorModal