import React from 'react'
import cn from 'classnames'

import { Card } from '../widgets/Block.js'

import s from './DashboardTile.module.scss'

export const DashboardSmallTile = ({title, hint, className, children}) => {
  return (
    <div  className={s.tile__small}>
      <Card title={title} className={cn('h-100', className)} hint={hint}>
        {children}
      </Card>
    </div>
  )
}

export const DashboardLargeTile = ({title, hint, children}) => {
  return (
    <Card title={title} hint={hint}>
      {children}
    </Card>
  )
}