import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import OrgSearchResultsDisplay from './OrgSearchResultsDisplay.js'
import { withReportBasket } from '../builder/ReportBasketContext.js'
import { OrgSuggestionsSwitch } from './OrgSuggestionsSwitch.js'
import { COMPARABLES_BY_SIMILAR_SIZE, ORG_SEARCH_CONTEXT } from '../../model/organisation.js'
import { PrimaryButton } from '../widgets/Button.js'
import { Pane } from '../widgets/Block.js'
import OrgSuggestionsHeading from './OrgSuggestionsHeading.js'

import s from './OrgSuggestionsDisplay.module.scss'

const OrgSuggestionsDisplay = (
  {
    base_name,
    org_suggestions,
    selected_organisations,
    on_result_select,
    on_show_similar_organisations,
    reset_handler,
    suggestions_mode
}) => {
  const [org_suggestions_mode, set_org_suggestions_mode] = useState(suggestions_mode || COMPARABLES_BY_SIMILAR_SIZE)
  const [results, set_results] = useState(org_suggestions[suggestions_mode || COMPARABLES_BY_SIMILAR_SIZE])
  
  useEffect(() => {
    set_results(org_suggestions[org_suggestions_mode])
  }, [org_suggestions_mode, org_suggestions])

  function on_click_select({org, parents, add, remove, with_special_key}) {
    on_result_select({org, parents, add, remove, with_special_key})
  }

  return (
    <div>
      <div className={cn('p-3 mb-1 d-md-flex justify-content-between', s.hero_wrapper)}>
        <OrgSuggestionsHeading org_name={base_name} className={cn('my-auto', s.hero_text)}/>
        <div className='mt-2 my-md-auto ml-md-3 d-flex justify-content-between justify-content-md-start'>
          <OrgSuggestionsSwitch
            selected_mode={org_suggestions_mode}
            set_org_suggestions_mode_handler={set_org_suggestions_mode}
            className='my-auto'
            disabled={_.isEmpty(results)}
          />
          <PrimaryButton className='ml-3' size='sm' outline onClick={reset_handler}>Close</PrimaryButton>
        </div>
      </div>

      {results && results.length > 0 &&
        <OrgSearchResultsDisplay
          results={results}
          show_spinner={false}
          on_result_check_in={on_click_select}
          on_show_similar_organisations={on_show_similar_organisations}
          context={ORG_SEARCH_CONTEXT}
          selected_organisations={selected_organisations}
        />
      }

      {results && results.length === 0 &&
        <Pane>
          <p>No results found.</p>
        </Pane>
      }

    </div>
  )
}

export default withReportBasket(OrgSuggestionsDisplay)