import React from 'react'

import { PrimaryButton, SecondaryButton } from '../widgets/Button.js'

const OrgSearchMultipleControls = ({ can_add_all, on_clear, on_add_all }) => {

  return (
    <div className='d-flex flex-wrap'>
      <div className='ml-auto mr-0'>
        <SecondaryButton
          size='sm'
          onClick={on_clear}
        >
          Back to input
        </SecondaryButton>

        <PrimaryButton
          size='sm'
          className='ml-2'
          onClick={on_add_all}
          disabled={!can_add_all}
        >
          Add all matched
        </PrimaryButton>
      </div>
    </div>
  )
}

export default OrgSearchMultipleControls
