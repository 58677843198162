import React from 'react'
import cn from 'classnames'

import { useToggle } from '../../../hooks/general_hooks.js'

import { ToggleDownIcon } from '../../widgets/IconSet.js'
import { InfoPopover } from '../../widgets/Tooltip.js'
import { STREAM_GROUP_TO_CLASSNAME } from './stream_styles.js'

import cs from '../../cipher_styles.module.scss'
import s from './ProgressStepSummary.module.scss'

const ProgressStepSummary = ({label, title, is_final, stream_group_id, children}) => {
  const [is_open, toggle_open] = useToggle(false)

  const {border: stream_className} = STREAM_GROUP_TO_CLASSNAME[stream_group_id] || {}

  if (!children || (children.length === 0)) return (
    <div className={cn('px-1', s.summary, s.summary__inactive, {[s.__final_step]: is_final}, stream_className)} title={title || label}>{label}</div>
  )

  const toggler_label = (
    <span className={cn('d-flex px-1 justify-content-between')}><span className={cn(s.label, {[s.label__open]: is_open})}>{label}</span>{' '}<ToggleDownIcon /></span>
  )

  return (
    <InfoPopover
      interactive={true}
      arrow={false}
      placement='bottom-start'

      on_show={toggle_open}
      on_hide={toggle_open}

      toggler={toggler_label}
      className={cn('mt-1', s.menu, {[s.__final_step]: is_final})}
      buttonClassName={cn('d-block', s.summary, {[s.__final_step]: is_final}, stream_className)}
      popupClassName={cs.border_none}
    >
      {children}
    </InfoPopover>
  )

}

export default ProgressStepSummary