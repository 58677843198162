import React from 'react'
import cn from 'classnames'

import DefaultLabelMultiItemDropdownSelector from '../widgets/DefaultLabelMultiItemDropdownSelector.js'

const StatusControl = ({
  className,
  statuses,
  selected_status_ids,
  on_change_status_ids
}) => {

  return (
    <span className={cn('d-flex', 'align-items-center', className)}>
      <div>
        Status
      </div>
      <DefaultLabelMultiItemDropdownSelector
        className={cn('ml-1')}
        items={statuses}
        selected_item_ids={selected_status_ids}
        on_change_item_ids={on_change_status_ids}
      />
    </span>
  )
}

export default StatusControl