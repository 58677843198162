import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import ErrorBody from '../ErrorBody.js'
import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'

import { get_spec_group_and_view } from '../../model/main_items.js'
import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from '../../model/spec_groups.js'

import { is_data_empty, get_processed_data_and_key_dims } from '../../utils/column_data_utils.js'
import {
  trigger_download,
  get_clean_filename,
  get_data_export_input,
  fetch_powerpoint_document,
  fetch_excel_document,
  MIMETYPE_PPTX,
  MIMETYPE_XLSX,
  EXCEL_FILE_EXT,
  POWERPOINT_FILE_EXT,
  is_too_big_for_microsoft_download
} from '../../utils/download_utils.js'
import { track_download_event } from '../../utils/tracking_utils.js'
import { get_only_saved_items } from '../../utils/main_items_selection_utils.js'
import { get_default_summary_sort_props } from '../../utils/disputes_utils.js'

import s from './DownloadAllChartsModal.module.scss'

const IS_FETCHING = 'still fetching data'
const FETCH_ERROR = 'error fetching data'
const DATASET_NOT_DOWNLOADABLE = 'selected chart type is not downloadable'
const DATA_EMPTY = 'no data'
const DATA_TOO_BIG_FOR_MICROSOFT = 'data too big for Microsoft Powerpoint'

function get_all_export_data_inputs(selections, items, spec_id_to_fetch_obj, ref_data, deref_data, data_creation_date, report_series_sort, download_as) {
  return get_only_saved_items(items)// remove 'temp' items (as user has not explicitly saved these - and we may not have loaded data for them anyway)
    .map(item => {

      const { spec_id } = item
      const { spec, group, view } = get_spec_group_and_view(item)

      const fetch_obj = spec_id_to_fetch_obj[spec_id]

      const { is_fetching, data, fetch_error } = fetch_obj || {}
      if (is_fetching) {
        return { undownloadable_reason: IS_FETCHING, spec, group }
      }

      if (fetch_error) {
        return { undownloadable_reason: FETCH_ERROR, spec, group }
      }

      const { view_id, ViewComponent } = view

      // View must implement get_plotly_data
      if (!ViewComponent.get_plotly_data) {
        return { undownloadable_reason: DATASET_NOT_DOWNLOADABLE, spec, group }
      }
      // Filter and limit data/keys
      const [processed_data, processed_key_dims, original_key_dims] = !data ? [null, null, null] : get_processed_data_and_key_dims(spec, view_id, data_creation_date, item, data, ref_data, deref_data, report_series_sort, false, true)
      // Decide whether data is empty by looking at the original data (NOT the filtered data)
      const empty = !data || is_data_empty(data, original_key_dims, selections) || _.every(processed_data.data, item => item.length === 0)

      if (empty) {
        return { undownloadable_reason: DATA_EMPTY, spec, group }
      }

      const too_big_for_microsoft_download = (download_as === POWERPOINT_FILE_EXT ) && is_too_big_for_microsoft_download(processed_key_dims)
      if (too_big_for_microsoft_download) {
        return { undownloadable_reason: DATA_TOO_BIG_FOR_MICROSOFT, spec, group }
      }

      const input = get_data_export_input({ item, view, spec, data: processed_data, key_dims: processed_key_dims, deref_data, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF, report_series_sort, data_creation_date })

      if (ViewComponent.get_summary_data_for_export) {
        const [selected_sort_field_id, selected_sort_direction_id] = get_default_summary_sort_props(item)
        const summary_input = ViewComponent.get_summary_data_for_export({ spec, view_id, data, item, deref_data, is_expanded: true, selected_sort_field_id, selected_sort_direction_id })
        return [{input: summary_input}, {input}]
      }

      return { input}
    })
}

const UndownloadableItems = ({ undownloadable_items }) => {
  return (
    <ul className={cn(s.undownloadable_list, 'ml-3')}>
      {undownloadable_items.map((item, idx) => {
        const { undownloadable_reason, group, spec } = item
          return (
            <li
              key={idx}
            >
              {group.name.toUpperCase()}: {spec.name} ({undownloadable_reason})
            </li>
          )
      })}
    </ul>
  )
}

/**
 * This component encapulates logic/UI for fetching all charts as a powepoint/excel.
 * It initially shows a spinner.
 * In componentDidMount, it processes the data
 * (this reveals whether certain datasets are not downloadable - i.e. empty/errors/toobig etc).
 * If necessary, user is alerted about the undownloadable charts, and asked if ok to proceed.
 * Finally download proceeds.
 */
const DownloadAllChartsModal = ({data_creation_date, on_close, download_as, report_title, selections, items, spec_id_to_fetch_obj, ref_data, deref_data, report_series_sort}) => {

  const [is_processing_data, set_is_processing_data] = useState(true)
  const [is_fetching_download, set_is_fetching_download] = useState(false)
  const [undownloadable_inputs, set_undownloadable_inputs] = useState(null)
  const [downloadable_inputs, set_downloadable_items] = useState(null)
  const [download_error, set_download_error] = useState(null)

  useEffect(() => {
    let did_cancel = false
    const inputs = _.flatten(get_all_export_data_inputs(selections, items, spec_id_to_fetch_obj, ref_data, deref_data, data_creation_date, report_series_sort, download_as))

    const downloadable_inputs = inputs.filter(input => !input.undownloadable_reason)
    const undownloadable_inputs = inputs.filter(input => input.undownloadable_reason)

    if (!did_cancel) {

      if (downloadable_inputs.length && !undownloadable_inputs.length) {
        // all ok - proceed with download
        do_download(downloadable_inputs)
      } else {
        // Set downloadable_inputs, undownloadables so that user can be alerted.
        set_is_processing_data(false)
        set_undownloadable_inputs(undownloadable_inputs)
        set_downloadable_items(downloadable_inputs)
      }
    }

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spec_id_to_fetch_obj])

  function do_download(items) {

    // Show fetching spinner (and hide the proceed stuff)
    set_is_fetching_download(true)
    set_undownloadable_inputs([])

    const inputs = items.map(item => item.input)

    const [file_ext, mime_type, format, fetch_doc_handler] = (download_as === POWERPOINT_FILE_EXT) ?
      [POWERPOINT_FILE_EXT, MIMETYPE_PPTX, 'PPT', fetch_powerpoint_document] :
      [EXCEL_FILE_EXT, MIMETYPE_XLSX, 'XLS', fetch_excel_document]

    track_download_event(`obj="report" format="${format}"`)

    const filename = get_clean_filename(report_title + file_ext)
    const args = file_ext === EXCEL_FILE_EXT ? [inputs, false, true] : [inputs]

    fetch_doc_handler(...args)
      .then(arraybuffer => {
        trigger_download(arraybuffer, mime_type, filename)
        on_close() // i.e. close modal
      })
      .catch(err => {
        track_download_event(`obj="report" format="${format}" status="failed"`)
        set_is_fetching_download(false)
        set_download_error(err)
        throw err
      })
  }

  const download_type = (download_as === POWERPOINT_FILE_EXT) ? 'Powerpoint' : 'Excel'

  const downloadable = downloadable_inputs || []
  const undownloadable = undownloadable_inputs || []

  const some_downloadable_some_not = undownloadable.length > 0 && downloadable.length > 0
  const none_downloadable          = undownloadable.length > 0 && downloadable.length === 0

  const still_fetching_chart_data = _.some(undownloadable, item => item.undownloadable_reason === IS_FETCHING)

  const ok_to_proceed_button = some_downloadable_some_not && !still_fetching_chart_data ? (
    <PrimaryButton
      className='ml-3'
      onClick={() => do_download(downloadable_inputs)}
    >
      Download
    </PrimaryButton>
  ) : null

  return (
    <Modal
      is_open={true}
      close_label={'Cancel'}
      on_hide={on_close}
      title={'Download all charts as ' + download_type}
      footer={ok_to_proceed_button}
    >
      {download_error &&
        <ErrorBody
          error={download_error}
          context={`downloading ${download_type}`}
        />
      }

      {(is_processing_data || (some_downloadable_some_not && still_fetching_chart_data)) &&
        <div>
          <Spinner/>
          <p>Preparing data...</p>
        </div>
      }

      {is_fetching_download &&
        <div>
          <Spinner/>
          <p>Preparing download...</p>
        </div>
      }

      {some_downloadable_some_not && !still_fetching_chart_data &&
        <div>
          <p>The following are not downloadable:</p>
          <UndownloadableItems
            undownloadable_items={undownloadable_inputs}
          />
          <p>But all other charts are downloadable.</p>
        </div>
      }

      {none_downloadable &&
        <div>
          <p>No items in the selection are downloadable.</p>
          <UndownloadableItems
            undownloadable_items={undownloadable_inputs}
          />
        </div>
      }
    </Modal>
  )
}

export default DownloadAllChartsModal