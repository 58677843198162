import React from 'react'
import cn from 'classnames'

import s from './CheckboxStatic.module.scss'

const CheckboxStatic = ({ is_checked, is_partial, is_disabled, title, size='md', onClick, className }) => {
  const is_small = size === 'sm'
  is_disabled = (is_disabled != null) ? is_disabled : false

  return (
    <div
      onClick={!is_disabled? onClick : null}
      title={title}
      className={cn(
        s.block,
        {
          [s.block__normal]: !is_small,
          [s.block__small]: is_small,
          [s.__checked]: is_checked,
          [s.__partial]: is_partial && !is_checked,
          [s.__disabled]: is_disabled
        },
        className
      )}
    />
  )
}

export default CheckboxStatic