import React from 'react'

import { DeleteIcon } from './TechExplorerIcons.js'
import TextLink from '../widgets/TextLink.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { PrimaryButton } from '../widgets/Button.js'

const DeleteSearchControl = (
  {
    handle_delete,
    is_disabled
  }) => {


  return (
    <InfoPopover
      toggler={(<TextLink element='span' title='Remove search' disable={is_disabled}><DeleteIcon /></TextLink>)}
      is_in_modal={true}
      placement='bottom'
      disabled={is_disabled}
      interactive={true}
    >
      <div>Are you sure you want to delete this search?</div>
      <div><PrimaryButton size='xs' onClick={() => handle_delete()}>Delete</PrimaryButton></div>
    </InfoPopover>
  )
}

export default DeleteSearchControl