import React from 'react'

import { FormGroup, FormFeedback, InputGroup } from 'reactstrap'
import { Heading } from './widgets/PaneHeader.js'
import { InputWithAutofocus } from './widgets/InputWithAutofocus.js'
import { disable_event_when_enter_pressed } from '../utils/utils.js'
const NameInput = ({name, heading, invalid_name_message, on_change, on_key_up, is_name_valid_handler, className}) => {

  function on_change_from_input(e) {
    const value = e.target.value
    on_change(value)
  }

  return (
    <FormGroup className={className}>
      <Heading text={heading} className='mb-1'/>

      <InputGroup>

        <InputWithAutofocus
          value={name || ''}
          on_change={on_change_from_input}
          on_key_down={/* to avoid unwanted interactions */ disable_event_when_enter_pressed}
          on_key_up={on_key_up}
          invalid={!is_name_valid_handler(name)}
        />
        <FormFeedback>{invalid_name_message}</FormFeedback>

      </InputGroup>

    </FormGroup>
  )
}

export default NameInput