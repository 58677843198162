import React from 'react'
import cn from 'classnames'
import {DropzoneOptions, DropzoneRootProps, DropzoneState, FileError, FileRejection, FileWithPath, useDropzone} from 'react-dropzone'
import styled from 'styled-components'
import {
  CIPHER_BLACK,
  CIPHER_BRIGHT_BLUE,
  CIPHER_RED,
  CIPHER_WHITE,
  LIGHT_GREY_COLOUR
} from '../../constants/colours.js'
import { withUser } from '../UserContext.js'


export interface FileUploadDropzoneProps {
  dropzone_options?: DropzoneOptions
}

export interface FileWithPathRejection extends FileRejection {
  file: FileWithPath,
  errors: FileError[]
}

// eslint-disable-next-line no-undef
export type DropzoneStateWithPath = Omit<DropzoneState, 'acceptedFiles' | 'fileRejections'> & {
  acceptedFiles: FileWithPath[];
  fileRejections: FileWithPathRejection[];
}

const get_dropzone_color = (p: DropzoneRootProps) => {
  if (p.isDragAccept) {
    return CIPHER_BRIGHT_BLUE
  }
  if (p.isDragReject) {
    return CIPHER_RED
  }
  return LIGHT_GREY_COLOUR
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 20px;
    border-width: 3px;
    border-radius: 0;
    border-color: ${(p: DropzoneRootProps) => get_dropzone_color(p)};
    border-style: solid;
    background-color: ${CIPHER_WHITE};
    color: ${CIPHER_BLACK};
    outline: none;
    transition: border .24s ease-in-out;
  `

const FileUploadDropzone = (props: FileUploadDropzoneProps) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } : DropzoneStateWithPath = useDropzone(props.dropzone_options)

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {pretty_print_filesize(file.size)}
    </li>
  ))

  function pretty_print_filesize(size: number): string {
    const size_in_kb = size * 0.001
    const size_in_mb = size * 0.000001
    return size_in_mb < 1 ? (size_in_kb.toFixed(2) + 'KB') : (size_in_mb.toFixed(2) + 'MB')
  }

  function render() : JSX.Element {
    return (
      <section className={cn('container', 'd-flex', 'flex-column', 'align-items-stretch')} style={{padding: 0}}>
        <Container {...getRootProps({isFocused, isDragAccept, isDragReject, className: 'dropzone disabled'})}>
          <input {...getInputProps()} />
          <span>Drag and drop files here, or click to select files</span>
        </Container>
        {acceptedFileItems.length > 0 &&
          <aside style={{padding: 5}}>
            <h6>Files</h6>
            <ul>{acceptedFileItems}</ul>
          </aside>
        }
      </section>
    )
  }

  return render()
}

export default withUser(FileUploadDropzone)
