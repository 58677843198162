import React from 'react'
import { FormGroup, FormFeedback, InputGroup } from 'reactstrap'

import { MAX_TAG_NAME_LENGTH } from '../../../constants/constants.js'
import { InputWithAutofocus } from '../../widgets/InputWithAutofocus.js'


export const TagNameInput = ({tag_name, on_change, on_key_up, is_valid_input}) => {

  return (
    <FormGroup>
      <InputGroup>
        <InputWithAutofocus
          value={tag_name}
          on_change={(e) => on_change(e.target.value)}
          on_key_up={on_key_up}
          invalid={!is_valid_input}
        />
        <FormFeedback>Tag names must be unique and cannot be longer than {MAX_TAG_NAME_LENGTH} characters.</FormFeedback>
      </InputGroup>
    </FormGroup>
  )
}