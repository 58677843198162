import React from 'react'
import cn from 'classnames'
import { DropdownToggle } from 'reactstrap'

import TextLink from '../widgets/TextLink.js'
import { ExternalTextLink } from '../widgets/ExternalTextLink.js'

import s from './HeaderAction.module.scss'

export const HeaderAction = ({ className, title, on_click, disable,  children, to, element, url }) => {
  const target = (element === 'a') ? '_blank' : null

  return (
    <TextLink title={title} disable={disable} className={cn(s.report_option, {[s.report_option__off]: disable}, className)} onClick={on_click} to={to} element={element} href={url} target={target}>
      {children}
    </TextLink>
  )
}

export const HeaderDropdownToggle = ({title, children, is_open, className}) => {
  return (
    <DropdownToggle className={cn(s.report_option, {[s.report_option__on]: is_open}, className)} tag='div' title={title}>{children}</DropdownToggle>
  )
}

export const HeaderExternalLink = ({url, title, className, children}) => {
  return (
    <ExternalTextLink url={url} target='_blank' title={title} className={cn(s.report_option, className)}>{children}</ExternalTextLink>
  )
}