import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { LoopIcon } from '../widgets/IconSet.js'

const RefreshThumbnailsControl = (
  {
    show_all_datasets_refresh,
    enable_all_dataset_refresh,
    all_datasets_refresh_handler,
    className
  }) => {

  return (
    <div className={cn(className)}>
      {show_all_datasets_refresh && enable_all_dataset_refresh &&
        <TextLink onClick={all_datasets_refresh_handler} className='m-auto' title='Refresh thumbnails'><LoopIcon/></TextLink>
      }
    </div>
  )

}

export default RefreshThumbnailsControl