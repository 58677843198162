import React, { useState } from 'react'
import cn from 'classnames'

import Tooltip from '../widgets/Tooltip'
import { join } from '../../utils/element_list_utils'
import { fetch_taggers_for_family } from './family_tag_utils'
import { Taggers } from './Taggers'

import s from './FamilyTagsColumn.module.scss'

interface FamilyTagsColumnProps {
  className: string,
  pat_fam_id: number,
  full_name_tags: Array<String>,
}

export const FamilyTagsColumn = ({className, pat_fam_id, full_name_tags}: FamilyTagsColumnProps) => {
  const [show_full_name, set_show_full_name] = useState(false)

  const toggler_element = <div className={cn(s.collapsed, 'ml-2')}>
    {join(full_name_tags, <span className={''}> | </span>)}</div>


  function render(): JSX.Element {

    return (
      <div className={cn(className, 'd-flex')}>
        {full_name_tags && full_name_tags.length > 0 &&
          <>
            <Taggers
              className={s.user_icon_red}
              fetch_taggers={fetch_taggers_for_family}
              fetch_taggers_params={[pat_fam_id]}
            />
            {/* @ts-expect-error*/}
            <Tooltip
              placement='top'
              toggler={toggler_element}
              on_show={() => set_show_full_name(true)}
              on_hide={() => set_show_full_name(false)}
              className={show_full_name? '' : 'd-none' }
              is_in_modal={true}
            >
              {show_full_name &&
                <div className={s.expanded}>
                  {full_name_tags.map((tag_value, idx) =>
                    (<div key={idx}> {tag_value} </div>))}
                </div>
              }
            </Tooltip>
          </>
        }
      </div>
    )
  }

  return render()
}
