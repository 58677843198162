import React from 'react'
import cn from 'classnames'

import { Card } from '../widgets/Block.js'
import { ExpandClosedIcon, ExpandOpenedIcon, ErrorIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import { PrimaryButton } from '../widgets/Button.js'
import { JSON_FILE_EXT, MIMETYPE_JSON, trigger_download } from '../../utils/download_utils.js'
import { Logo } from '../Logo.js'

import s from './MonitorCard.module.scss'

export const MonitorCard = (
  { title,
    source_description,
    children,
    toggle_show_more,
    toggle_disabled_reason,
    is_show_more,
    show_info_toggle,
    data_to_download,
    download_filename,
    is_error,
    top_logo,
    className
  }) => {

  function download_data() {
    trigger_download(JSON.stringify(data_to_download, null, '\t'), MIMETYPE_JSON, `${download_filename}${JSON_FILE_EXT}`)
  }

  function toggle_and_source() {
    return (
      <>
        { show_info_toggle &&
          <span className='ml-2 mr-auto'>{show_info_toggle}</span>
        }
        { toggle_show_more &&
          <TextLink className='ml-2 mr-auto' disable={toggle_disabled_reason} onClick={() => toggle_show_more(!is_show_more)}>
            { is_show_more ? <ExpandOpenedIcon/> : <ExpandClosedIcon/> }
            { toggle_disabled_reason && <span className={cn(s.hint_text, 'ml-2')}>{toggle_disabled_reason}</span>}
          </TextLink>
        }
        { data_to_download &&
          <PrimaryButton
            onClick={download_data}
            size='xs'
            className={cn(s.download_button, 'mr-4')}
          >
            Download JSON
          </PrimaryButton>
        }
        { source_description &&
          <span className={cn(s.hint_text, s.source_desc)}>{source_description}</span>
        }
      </>
    )
  }

  function format_title() {
    if (top_logo) {
      return (
        <Logo
          title='Cipher'
          small_logo
        />
      )
    }
    return is_error ? <span><ErrorIcon/>&nbsp;{title}</span> : title
  }

  return (
    <Card
      title={format_title()}
      className={cn(s.monitor_card, className)}
      hint={toggle_and_source()}
    >
      {(!toggle_show_more || is_show_more) &&
        <div className={title && !top_logo && s.child_content}>
          {children}
        </div>
      }
    </Card>
  )
}
