import {GROUP_MODES} from '../../../utils/ocypod_utils'

export interface GroupingDropdownItem {
  name: string,
  mode: GROUP_MODES,
  info: string
}

export interface PresetDropdownItem {
  name: string,
  description: string,
  fn_formula_generation(op_nr: number): string,
  group_tech_mode: GROUP_MODES,
  group_portfolio_mode: GROUP_MODES
}

export const common_group_dropdown_items: GroupingDropdownItem[] = [
  {
    name: 'Operand descending',
    mode: GROUP_MODES.OP_DESC,
    info: 'Given reports R1, R2 both containing the same patent family, the mapping of R2 takes precedence.'
  },
  { name: 'Operand ascending',
    mode: GROUP_MODES.OP_ASC,
    info: 'Given reports R1, R2 both containing the same patent family, the mapping of R1 takes precedence.'},
  { name: 'Report creation date',
    mode: GROUP_MODES.REPORT_TIMESTAMP,
    info: 'Given reports R1, R2 both containing the same patent family, the mapping of the latest report takes precedence.'
  },
]
export const tech_group_dropdown_items: GroupingDropdownItem[] = [
  ...common_group_dropdown_items,
  {
    name: 'Dominant CPC',
    mode: GROUP_MODES.CPC,
    info: 'Group patent families by dominant CPC using Stochastic Gradient Descent classifiers.'
  }
]
export const portfolio_group_dropdown_items: GroupingDropdownItem[] = [
  ...common_group_dropdown_items,
  {
    name: 'All',
    mode: GROUP_MODES.ALL,
    info: 'Ignore portfolio clustering and group all patent families together. This option is useful for avoiding duplications cause by co-ownerships.'
  }
]

export function differentiated_union_formula(op_nr: number): string {
  const operand_arr = [...Array(op_nr).keys()].map(idx => `R${idx+1}`)
  let formula_operands: string[] = []
  operand_arr.forEach((op, idx) => {
    if (idx === 0){
      formula_operands.push(op)
    } else if (idx === 1) {
      formula_operands.push(`(${op} - R${idx})`)
    } else {
      formula_operands.push(`(${op} - (${[...Array(idx).keys()].map(idx => `R${idx+1}`).join(' ∪ ')}))`)
    }
  })
  return formula_operands.join(' ∪ ')
}
