import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import {
  is_family_tag_type,
  is_patent_families_type,
  is_tech_search_type
} from '../../model/portfolio_basket.js'
import TextLink from '../widgets/TextLink.js'
import {
  EditIcon,
  RemoveIcon,
  ShowBasketItemIcon,
  ShowSimilarIcon
} from '../widgets/IconSet.js'
import { InputWithAutofocus } from '../widgets/InputWithAutofocus.js'
import { get_normalised_name } from '../../utils/name_utils.js'
import { is_org_group_type, is_org_type } from '../../utils/organisation_utils.js'
import ConfirmOrCancel from '../widgets/ConfirmOrCancel.js'

import s from './ReportBuilderPortfolioInBasket.module.scss'

const ReportBuilderPortfolioInBasket = (
  {
    item,
    item_size,
    item_names,
    is_in_edit_mode,
    on_click_from_search_phrase,
    on_click_from_similar_orgs,
    on_click_from_org_group,
    toggle_rename_mode,
    delete_from_portfolio_basket_handler,
    rename_item
  }) => {

  const [new_name, set_new_name] = useState(null)

  const { name, search_term } = item
  const is_search = is_tech_search_type(item)
  const is_org = is_org_type(item)
  const is_patent_upload = is_patent_families_type(item)
  const is_family_tag = is_family_tag_type(item)
  const is_org_group = is_org_group_type(item)

  useEffect(() => {
    if (!is_in_edit_mode) {
      set_new_name(null)
    }
  }, [is_in_edit_mode])

  function on_change_from_new_name_input(e) {
    const value = e.target.value
    set_new_name(value)
  }

  function on_new_name_cancel() {
    set_new_name(null)
    toggle_rename_mode()
  }

  function on_new_name_submit() {
    rename_item(get_normalised_name(new_name))
    toggle_rename_mode()
  }

  function on_key_down(e) {
    if (e.which === 13 && is_new_name_valid) {
      on_new_name_submit()
    }
  }

  function check_if_new_name_valid() {
    const normalised_name = get_normalised_name(new_name || '')

    return (normalised_name.length > 0) && (item_names.indexOf(normalised_name) === -1)
  }

  const displayed_name = (new_name != null) ? new_name : name

  const is_new_name_valid = is_in_edit_mode ? check_if_new_name_valid() : true

  if (is_in_edit_mode) {
    return (
      <div  className={cn('d-flex mt-1 w-100', s.item_wrapper)}>
        <div className='position-relative flex-grow-1 my-auto'>
          <InputWithAutofocus
            value={displayed_name}
            on_change={on_change_from_new_name_input}
            size='sm'
            className='m-0 p-0'
            on_key_down={on_key_down}
          />
        </div>

        <ConfirmOrCancel
          is_valid={is_new_name_valid}
          on_submit={on_new_name_submit}
          on_cancel={on_new_name_cancel}
          className='my-auto'
        />

      </div>
    )
  }

  return (
    <div className={cn('d-flex justify-content-between mt-1', s.item_wrapper)} >
      <span className={cn('my-auto', s.item_name)}>{name}</span>

      <div className={cn('d-flex flex-nowrap')}>
        <div className={cn('flex-nowrap', s.actions)}>
          {(is_search || is_family_tag) &&
            <TextLink
              className={cn('ml-1 my-auto', s.basket_show_link)}
              onClick={() => on_click_from_search_phrase(is_family_tag ? name : search_term, is_family_tag ? 'Family tag' : 'Boolean search', item_size)}
            >
              <ShowBasketItemIcon />
            </TextLink>
          }

          {is_org_group &&
            <TextLink
              className={cn('ml-1 my-auto', s.basket_show_link)}
              onClick={() => on_click_from_org_group(item)} title='Show details'
            >
              <ShowBasketItemIcon />
            </TextLink>
          }

          {(is_patent_upload || is_org || is_org_group || is_family_tag) &&
            <TextLink
              className='ml-1 my-auto'
              onClick={on_click_from_similar_orgs}
              title='Show similar organisations'
            >
              <ShowSimilarIcon />
            </TextLink>
          }

          <TextLink
            className='ml-1 my-auto'
            onClick={toggle_rename_mode}
            title='Rename'
          >
            <EditIcon/>
          </TextLink>

        </div>

        <div className={cn('ml-1 my-auto', s.show_more)}>
          <div className='d-flex flex-nowrap'>
            <DragIndicatorIcon />
          </div>
        </div>

        <TextLink
          className='ml-1 my-auto'
          onClick={delete_from_portfolio_basket_handler}
          title='Remove'
        >
          <RemoveIcon />
        </TextLink>
      </div>
    </div>
  )
}

export default ReportBuilderPortfolioInBasket