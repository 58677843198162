import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import { Autocomplete, TextField } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'

import NamerSelector from '../widgets/NamerSelector.js'
import { PrimaryButton, SecondaryButton } from '../widgets/Button.js'
import RenamingModal from '../widgets/RenamingModal.js'
import Modal from '../widgets/Modal.js'
import { CheckboxList } from './HyperscriptSetupParams.js'
import { is_array_non_empty_non_null } from '../../utils/utils.js'

import s from './BenchmarkingReportSetupParams.module.scss'

const MAX_TECH_AREA_NAME_CHARS = 30

const BenchmarkingReportSetupParams = ({deref_data, benchmarking_merged_inputs, on_change, benchmarking_params, setup_params, current_param_values, disabled}) => {
  const api_ref = useGridApiRef()

  const {selected_portfolios=[]} = deref_data

  const [techs_selected_in_table, set_techs_selected_in_table] = useState([])
  const [techs_for_grouping, set_techs_for_grouping] = useState([])

  const [selected_target, set_selected_target] = useState(null)
  const [selected_tech_area, set_selected_tech_area] = useState(null)
  const [techs_with_groupings, set_techs_with_groupings] = useState([])

  const [has_benchmarking_params_in_report, set_has_benchmarking_params_in_report] = useState(false)
  const checkbox_params = setup_params.filter(param => param.is_checkbox)

  const [company_lists_with_final_names, set_company_lists_with_final_names] = useState({})
  const [list_to_rename, set_list_to_rename] = useState(null)

  const {input_techs} = benchmarking_merged_inputs || {}
  const techs_have_parent_classes = _.some((input_techs || []), tech => !_.isEmpty(tech.tree_path))

  // find all the portfolios that are in lists (these cannot be selected as a target)
  const org_list_portfolio_ids = _.flatten(_.keys(company_lists_with_final_names).map(list => company_lists_with_final_names[list].map(org => org.portfolio_id)))
  const portfolio_options = selected_portfolios.map(portfolio => ({id: portfolio.portfolio_id, label: portfolio.name, is_list: false}))
    .filter(portfolio => !_.contains(org_list_portfolio_ids, portfolio.id))

  const list_options = _.keys(company_lists_with_final_names).map(list_name => ({id: list_name, label: `${list_name} (list)`, is_list: true}))
  const target_options = [...portfolio_options, ...list_options]

  const tech_area_options = _.uniq(_.compact(techs_with_groupings.map(tech => tech.tech_area))).map(tech_area_name => ({id: tech_area_name, label: tech_area_name}))

  useEffect(() => {
    if (!benchmarking_merged_inputs || !deref_data) {
      return
    }

    const [tech_areas=[], org_lists=[]] = benchmarking_params || []
    const {input_org_lists} = benchmarking_merged_inputs
    const {selected_techs} = deref_data

    set_techs_with_groupings(selected_techs)
    set_company_lists_with_final_names(input_org_lists)
    const target_org_list = _.first(((org_lists || {}).org_lists || []).filter(list => list.is_target_org))

    if (target_org_list) {
      const matching_list_exists_in_input = _.some(_.values(input_org_lists), input_org_list => all_orgs_belong_to_input_list(target_org_list, input_org_list))

      const first_org = _.first(target_org_list.portfolios)

      const selected_target_from_existing = {
        label: matching_list_exists_in_input ? `${target_org_list.name} (list)` : first_org.name,
        id: matching_list_exists_in_input ? target_org_list.name : first_org.id,
        is_list: matching_list_exists_in_input
      }

      set_selected_target(selected_target_from_existing)
      set_has_benchmarking_params_in_report(true)
    }

    if (org_lists && is_array_non_empty_non_null(org_lists.org_lists)) {
      let updated_company_lists_with_final_names = {}
      _.keys(input_org_lists).forEach(list_name => {
        const matching_list_in_input = _.find(org_lists.org_lists, bm_org_list => all_orgs_belong_to_input_list(bm_org_list, input_org_lists[list_name]))
        if (matching_list_in_input) {
          updated_company_lists_with_final_names[matching_list_in_input.name] = input_org_lists[list_name]
        }
      })
      set_company_lists_with_final_names(updated_company_lists_with_final_names)
    }

    if (is_array_non_empty_non_null(tech_areas)) {
      const techs_with_groupings_from_existing = selected_techs.map(tech => {
        const defined_area = _.find(tech_areas, tech_area => _.contains(tech_area.techs.map(tech_in_area => tech_in_area.id), tech.technology_id))
        return {
          ...tech,
          ...(defined_area ? {tech_area: defined_area.name} : {})
        }
      })
      set_techs_with_groupings(techs_with_groupings_from_existing)
      set_has_benchmarking_params_in_report(true)
    }
  }, [benchmarking_params, benchmarking_merged_inputs, deref_data])

  function all_orgs_belong_to_input_list(benchmarking_org_list, input_org_list) {
    return _.every(benchmarking_org_list.portfolios.map(portfolio => portfolio.id), org => _.contains(input_org_list.map(portfolio => portfolio.portfolio_id), org))
  }

  useEffect(() => {
    if (selected_tech_area) {
      update_tech_area_names_for_selected(selected_tech_area.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_tech_area])

  useEffect(() => {
    const tech_areas_full = _.groupBy((techs_with_groupings.filter(tech => tech.tech_area)), 'tech_area')
    const tech_areas = _.sortBy(_.keys(tech_areas_full)).map(name => ({name, technology_ids: tech_areas_full[name].map(tech => tech.technology_id)}))

    const company_lists = _.sortBy(_.keys(company_lists_with_final_names)).map(name => {
      return {
        name,
        portfolio_ids: company_lists_with_final_names[name].map(org => org.portfolio_id)
          .filter(id => (selected_target && (selected_target.is_list || id !== selected_target.id))),
        is_target_org: (selected_target && selected_target.is_list && name === selected_target.id)
      }
    })

    if (selected_target && !selected_target.is_list) {
      company_lists.push({ name: 'My Organisation', portfolio_ids: [selected_target.id], is_target_org: true })
    }

    on_change({...current_param_values, tech_areas, company_lists})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techs_with_groupings, selected_target, company_lists_with_final_names])

  function get_parent_class(tech) {
    const input_tech = _.find(techs_with_groupings, classifier => classifier.name === tech.name)
    return (input_tech && !_.isEmpty(input_tech.tree_path)) ? _.last(input_tech.tree_path) : null
  }

  function rename_company_list(new_list_name) {
    const updated_companies = company_lists_with_final_names[list_to_rename].map(portfolio => ({...portfolio, company_list: new_list_name}))
    const updated_company_lists = {
      [new_list_name]: updated_companies,
      ..._.omit(company_lists_with_final_names, list_to_rename)
    }
    set_company_lists_with_final_names(updated_company_lists)
    set_list_to_rename(null)
  }

  function update_tech_area_names_for_selected(tech_area_name, use_classifier_names=false, use_parent_classes=false) {
    const techs_with_updated_groupings = techs_with_groupings.map(tech => {
      if (_.contains(techs_for_grouping, tech.technology_id)) {
        const tech_area = use_classifier_names ? tech.name : use_parent_classes ? get_parent_class(tech) : tech_area_name
        return tech_area ? {...tech, tech_area} : _.omit(tech, 'tech_area')
      }
      return tech
    })
    set_techs_with_groupings(techs_with_updated_groupings)
    set_techs_selected_in_table([])
    set_techs_for_grouping([])
    set_selected_tech_area(null)
  }

  function find_tech_by_id(technology_id) {
    return _.find(techs_with_groupings, tech => tech.technology_id === technology_id)
  }

  function get_common_tech_area_name_or_null(technology_ids) {
    const tech_areas = _.uniq(techs_with_groupings.filter(tech => _.contains(technology_ids, tech.technology_id)).map(tech => tech.tech_area))
    if (tech_areas.length === 1 && (tech_areas[0] != null)) {
      const common_tech_area = tech_areas[0]
      return {id: common_tech_area, label: common_tech_area}
    }
    return null
  }

  const techs_columns = [
    {
      field: 'name',
      headerName: 'Classifier',
      flex: 1
    },
    {
      field: 'tech_area',
      headerName: 'Technology area',
      flex: 1,
      renderCell: function tech_area_cell({row}) {
        const {tech_area, technology_id} = row
        const can_select = !is_array_non_empty_non_null(techs_for_grouping)

        return (
          <div
            className='cursor-pointer w-100 h-100'
            onClick={can_select ? () => set_techs_for_grouping([technology_id]) : null}
          >
            {tech_area}
          </div>
        )
      }
    }
  ]

  const company_list_columns = [
    {
      field: 'list_name',
      headerName: 'List name',
      width: 170,
      renderCell: function render_org_list_cell({row}) {
        const {list_name} = row
        return (
          <div
            className='cursor-pointer w-100 h-100'
            onClick={() => set_list_to_rename(list_name)}
          >
            {list_name}
          </div>
        )
      }
    },
    {
      field: 'company_names',
      headerName: 'Organisations',
      flex: 1,
      renderCell: function render_org_list_cell({row}) {
        const {list_name, company_names} = row
        return (
          <div
            className='cursor-pointer w-100 h-100'
            onClick={() => set_list_to_rename(list_name)}
          >
            {company_names}
          </div>
        )
      }
    }
  ]

  function get_grid_footer() {
    return (
      <div className='mt-2'>
        <PrimaryButton
          className='ml-0 mr-auto'
          size='sm'
          onClick={() => set_techs_for_grouping(techs_selected_in_table)}
          disabled={!is_array_non_empty_non_null(techs_selected_in_table)}>Set tech area for selected</PrimaryButton>
      </div>)
  }

  function get_modal_footer() {
    return (
      <div>
        <SecondaryButton
          onClick={() => update_tech_area_names_for_selected(null)}
        >
          Clear tech area
        </SecondaryButton>
      </div>
    )
  }

  const company_list_data = _.sortBy(_.keys(company_lists_with_final_names))
    .map(list_name => {
      return {
        id: list_name,
        list_name,
        company_names: (company_lists_with_final_names[list_name].map(portfolio => portfolio.name)).join(', ')
      }
    })

  return (
    <>
      {list_to_rename &&
        <RenamingModal
          title={`Rename '${list_to_rename}'`}
          on_hide={() => set_list_to_rename(null)}
          original_value={list_to_rename}
          handle_rename={(new_name) => rename_company_list(new_name)}
          is_valid={(value) => value && value.length && value.length < 40}
        />
      }

      {is_array_non_empty_non_null(techs_for_grouping) &&
        <Modal
          title={`Set technology area for ${techs_for_grouping.length > 1 ? 'the selected technologies' : `'${find_tech_by_id(techs_for_grouping[0]).name}'`}`}
          on_hide={() => set_techs_for_grouping([])}
          close_label={'Cancel'}
          footer={get_modal_footer()}
        >
          <PrimaryButton
            className='mr-2'
            size='sm'
            onClick={() => update_tech_area_names_for_selected(null, true)}
          >
            Use classifier names
          </PrimaryButton>
          <PrimaryButton
            size='sm'
            onClick={() => update_tech_area_names_for_selected(null, false, true)}
            disabled={!techs_have_parent_classes}
          >
            Use parent classes
          </PrimaryButton>
          <div className='mt-2'>or</div>
          <div className={'mt-2'}>
            <NamerSelector
              value={get_common_tech_area_name_or_null(techs_for_grouping) || selected_tech_area}
              options={tech_area_options}
              set_selected_value={set_selected_tech_area}
              max_length={MAX_TECH_AREA_NAME_CHARS}
              label={'Technology area'}
              auto_focus={true}
            />
          </div>
        </Modal>
      }
      <div className='my-2'>
        <span className='font-weight-bold'>
          1. Select a target organisation (or a list of organisations) from your report. <br/>In the benchmarking deck this will appear as &lsquo;My Organisation&rsquo;.
        </span>
        <div className='my-2 d-flex flex-column w-75'>
          <Autocomplete
            disablePortal
            clearOnBlur
            className='mt-2'
            size={'small'}
            options={target_options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selected_target}
            onChange={(_, selection) => set_selected_target(selection)}
            includeInputInList={false}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoFocus: true}}
                label={'Benchmarking target (\'My Organisation\')'}
                placeholder={'Select one'}
              />)}
          />
          <div className='mt-2'>
            <span className='font-weight-bold'>
              2. (Optional) Rename any that are not exactly what you want to appear in the benchmarking deck.<br/>Click a row in the table below to rename the list.
            </span>
            <DataGridPro
              apiRef={api_ref}
              rows={company_list_data}
              columns={company_list_columns}
              checkboxSelection={false}
              disableColumnMenu={true}
              hideFooterRowCount={true}
              disableSelectionOnClick={true}
              disableMultipleSelection={true}
              hideFooterSelectedRowCount={true}
              rowHeight={25}
              components={{
                Toolbar: function get_header() {return (<div/>)},
                Footer: function get_header() {return (<div className='mb-2'/>)}
              }}
              classes={{root: s.tech_area_grid}}
              autoHeight
            />
          </div>
        </div>
      </div>
      <div className='my-2 w-75'>
        <span className='font-weight-bold'>
          3. Define technology areas by grouping together the classifier technologies in your report.
        </span>
        <div style={{display: 'flex'}}>
          <div style={{flexGrow: 1}}>
            <DataGridPro
              apiRef={api_ref}
              rows={techs_with_groupings}
              columns={techs_columns}
              selectionModel={techs_selected_in_table}
              onSelectionModelChange={set_techs_selected_in_table}
              checkboxSelection={true}
              disableColumnMenu={true}
              hideFooterRowCount={true}
              disableSelectionOnClick={true}
              hideFooterSelectedRowCount={true}
              rowHeight={25}
              components={{
                Toolbar: function get_header() {return (<div/>)},
                Footer: () => get_grid_footer()
              }}
              classes={{root: s.tech_area_grid}}
              autoHeight
            />
          </div>
        </div>
        { has_benchmarking_params_in_report &&
          <div className='mt-3'>
            <span className='font-weight-bold'>
              4. Target org and/or tech areas have already been defined for this report. Overwrite the original groupings?
            </span>
            <div className='my-2'>
              <CheckboxList
                setup_params={checkbox_params}
                current_param_values={current_param_values}
                on_change={on_change}
                disabled={disabled}
              />
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default BenchmarkingReportSetupParams