import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { PrimaryButton } from '../widgets/Button.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { BUILD_CLASSIFIER_REPORT } from '../../constants/paths.js'
import { TileHelpLink} from './TileHelpLink.js'
import { get_classifier_report_help_url } from '../../utils/help_utils.js'
import {
  AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT,
  CLASSIFIER_LANDSCAPE_LIMIT
} from '../../utils/report_builder_utils.js'
import { is_aistemos } from '../../utils/user_permissions.js'
import TileTitle from './TileTitle.js'
import { get_stream_url } from '../builder/wizard/builder_wizard_utils.js'
import { TECH_LANDSCAPE_STREAM_ID } from '../builder/wizard/report_streams.js'

const ClassifierReportPanel = ({ history, user }) => {

  function on_click_from_new_report_btn() {
    track_report_builder_event('action="select_builder" report_type="classifier_report" context="dashboard"')
    history.push(BUILD_CLASSIFIER_REPORT)
  }

  const title = (
    <TileTitle
      title='Global Landscape Report'
      wizard_url={get_stream_url(TECH_LANDSCAPE_STREAM_ID, {})}
    />
  )

  const basket_limit = is_aistemos(user) ? AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT : CLASSIFIER_LANDSCAPE_LIMIT

  return (
    <DashboardSmallTile title={title} hint={<TileHelpLink help_url={get_classifier_report_help_url()}/>}>
      <DashboardTileInner>
        <DashboardTileDesc>
          Create a new report for all owners globally, by running up to {basket_limit} classifiers against all patent families
        </DashboardTileDesc>
        <PrimaryButton className='w-100 mt-3 mt-md-auto' onClick={on_click_from_new_report_btn}>Start</PrimaryButton>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(ClassifierReportPanel)