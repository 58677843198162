import { get_as_map } from '../utils/utils.js'

export const NO_FILTER_ID           = 'NO_FILTER_ID'
export const BY_PRIORITY_YEAR_ID    = 'BY_PRIORITY_YEAR_ID'
export const BY_PUBLICATION_YEAR_ID = 'BY_PUBLICATION_YEAR_ID'
export const BY_GRANTED_YEAR_ID     = 'BY_GRANTED_YEAR_ID'
export const BY_EXPIRY_YEAR_ID      = 'BY_EXPIRY_YEAR_ID'

export const TIME_RANGE_FILTER_FIELDS = [
  { id: NO_FILTER_ID,           name: 'All',                                                   },
  { id: BY_PRIORITY_YEAR_ID,    name: 'Priority date',   short_name: 'Prio.',  db_field_name: 'PF.priority_date',    date_type: 'priority'},
  { id: BY_PUBLICATION_YEAR_ID, name: 'Publication date',short_name: 'Pub.' ,  db_field_name: 'PF.publication_date', date_type: 'publication'},
  { id: BY_GRANTED_YEAR_ID,     name: 'Grant date',      short_name: 'Grant',  db_field_name: 'PF.granted_date',     date_type: 'granted'},
  { id: BY_EXPIRY_YEAR_ID,      name: 'Expiry date',     short_name: 'Exp.',   db_field_name: 'PF.expiry_date',      date_type: 'expiry'},
]

export const ID_TO_TIME_RANGE_FIELD = get_as_map(TIME_RANGE_FILTER_FIELDS, 'id')