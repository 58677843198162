export function get_year_from_date_string(date_string) {
  // date_string has form YYYY-MM-DD i.e. '2018-11-31'
  return +date_string.slice(0, 4)
}

export function get_years_extent_from_date_string_extent([from_date_string, to_date_string]) {
  // from_date_string and to_date_strings here are inclusive (ie. ['2013-01-21', '2018-11-31'])
  // This function returns years that are 'from-inclusive', 'to-exclusive'
  // i.e. [2013, 2019]
  if (!(from_date_string && to_date_string)) return null
  const from_year = get_year_from_date_string(from_date_string)
  const to_year   = get_year_from_date_string(to_date_string) + 1
  return [from_year, to_year]
}

export function get_date_obj_as_date_string(date_obj) {
  return date_obj.toISOString().split("T")[0]
}