import React, { useState } from 'react'
import cn from 'classnames'

import Tooltip, { InfoPopover } from '../widgets/Tooltip.js'
import { CheckIcon, InfoIcon, ToggleDownIcon } from '../widgets/IconSet.js'
import { format_string_first_character_capitalised } from '../../utils/utils.js'
import { format_spec_name } from '../../utils/spec_utils.js'

import {
  AGE_GROUP_ID, COST_GROUP_ID,
  GEOGRAPHY_GROUP_ID, LITIGATION_GROUP_ID,
  PATENTING_ACTIVITY_GROUP_ID,
  PORTFOLIO_SIZE_GROUP_ID, PORTFOLIO_STATS_GROUP_ID,
  PORTFOLIO_TRENDS_GROUP_ID, PVIX_SCORE_GROUP_ID, SCORE_GROUP_ID, SELECTED_GROUP_ID
} from '../../model/spec_groups/spec_group_ids.js'

import s from './DatasetGroupQuickNav.module.scss'
import dsg from './dataset_groups.module.scss'


const SPEC_GROUP_TO_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec_tooltip,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec_tooltip,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec_tooltip,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec_tooltip,
  [AGE_GROUP_ID]: dsg.age_spec_tooltip,
  [COST_GROUP_ID]: dsg.cost_spec_tooltip,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec_tooltip,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec_tooltip,
  [SCORE_GROUP_ID]: dsg.score_spec_tooltip,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec_tooltip,
  [SELECTED_GROUP_ID]: dsg.selected_spec_tooltip
}

const DatasetGroupQuickNavItem = ({name, description, is_selected, on_click, is_anything_selected, className}) => {
  const [tooltip_visible, set_tooltip_visible] = useState(false)

  function toggle_tooltip_visible() {
    set_tooltip_visible(!tooltip_visible)
  }

  return (
    <div className={cn('pb-1 d-flex justify-content-between', s.group_info_item)} onClick={on_click}>
      <span className='d-flex'>
        {is_anything_selected &&
          <span className={s.icon_wrapper}>
            {is_selected &&
            <CheckIcon/>
            }
          </span>
        }
        <span className={cn({[s.__highlighted]: tooltip_visible})}>{name}</span>
      </span>
      <Tooltip
        is_in_modal={true}
        toggler={<span className={cn('ml-1', s.spec_info)}><InfoIcon /></span>}
        className={className}
        on_show={toggle_tooltip_visible}
        on_hide={toggle_tooltip_visible}
      >
        <>
          <div className='font-weight-bold'>{name}</div>
          <div>{description}</div>
        </>
      </Tooltip>
    </div>
  )
}

const DatasetGroupQuickNav = ({items, group_id, selected_items_ids, selected_count, on_click}) => {

  return (
    <InfoPopover
      toggler={(
        <div className={s.spec_info}>
          <ToggleDownIcon/>
        </div>
      )}
      buttonClassName={s.heading}
      placement='bottom-end'
      interactive={true}
    >
      <div className='pt-2 pb-1'>
        {items.map((item, i) => {
          const { spec_id, description } = item
          const is_selected = selected_items_ids.indexOf(spec_id) > -1
          const nicely_formatted_name = format_string_first_character_capitalised(format_spec_name(item))
          return (
            <DatasetGroupQuickNavItem
              key={i}
              name={nicely_formatted_name}
              description={description}
              on_click={() => on_click(spec_id)}
              is_selected={is_selected}
              className={SPEC_GROUP_TO_CLASSNAME[group_id]}
              is_anything_selected={selected_count > 0}
            />
          )
        })}
      </div>
    </InfoPopover>
  )

}

export default DatasetGroupQuickNav