import React from 'react'
import cn from 'classnames'

import { AVAILABLE_ROLLUP_LIMITS, DEFAULT_ROLLUP_LIMIT, NO_ROLLUP_LIMIT } from '../../model/rollups.js'
import { is_rollup_possible } from '../../utils/column_data_utils.js'

import s from './RollupLimitControls.module.scss'

const RollupLimitControls = ({items, selected_rollup_thresholds, on_limit_select, className}) => {

  const available_rollup_limits = AVAILABLE_ROLLUP_LIMITS.filter(limit => {
    if (limit === NO_ROLLUP_LIMIT) {
      if (items.length > 1000) {
        // More than 1000 items, so don't show the "all" option
        return false
      }
    }

    return is_rollup_possible(limit, items.length)
  })

  const selected_rollup_threshold = selected_rollup_thresholds || DEFAULT_ROLLUP_LIMIT
  const effective_selected_rollup_threshold = !is_rollup_possible(selected_rollup_threshold, items.length) ? -1 /* 'all' */: selected_rollup_threshold

  if (available_rollup_limits && available_rollup_limits.length === 1 && available_rollup_limits[0] === NO_ROLLUP_LIMIT) {
    return null
  }

  return (
    <div className={cn('d-flex', className)}>
      <div className={cn('my-auto', s.label)}>Top:</div>
      {
        available_rollup_limits.map((limit, i) => {

          const label = (limit !== NO_ROLLUP_LIMIT) ? limit : 'All'

          const selected = (limit === effective_selected_rollup_threshold)
          return (
            <div
              key={i}
              onClick={() => {
                if (selected) return
                on_limit_select(limit)}
              }
              className={cn('d-flex ml-1 my-auto', s.option, {[s.option__on]: selected})}>
              <div className='d-flex flex-column m-auto font-weight-bold'>{label}</div>
            </div>
          )
        })
      }

    </div>
  )
}

export default RollupLimitControls