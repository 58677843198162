import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'

import {
  create_new_company_list,
  delete_companies_from_list,
  extract_properties_for_saving_to_list,
  fetch_list_companies_for_basket,
  get_max_companies_allowed_in_list,
  rename_list,
  share_or_unshare_list,
  SHARED_LIST_INFO,
  update_companies_fields,
  update_companies_in_list,
} from '../../utils/company_list_utils.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import EditableTextLink from '../report_management/components/EditableTextLink.js'

import TextLink from '../widgets/TextLink.js'
import { format_integer_with_comma, get_as_map } from '../../utils/utils.js'
import { EditIcon, ExclamationIcon } from '../widgets/IconSet.js'
import { PrimaryButton } from '../widgets/Button.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import OrgSearchMultiple from '../orgs/OrgSearchMultiple.js'
import { get_org_as_portfolio_item } from '../../model/portfolio_basket.js'
import { ScrollModal } from '../widgets/Modal.js'
import ErrorModal from '../ErrorModal.js'
import ListNameModal from './ListNameModal.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { CSV_FILE_EXT, get_clean_filename, MIMETYPE_CSV, trigger_download } from '../../utils/download_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import { withUser } from '../UserContext.js'
import { is_aistemos } from '../../utils/user_permissions.js'

import cs from '../cipher_styles.module.scss'
import s from './CompanyListSettings.module.scss'

function get_footer() {
  return (
    <div></div>
  )
}

function get_toolbar(
  {
    selected_ids=[],
    on_selected_remove_enabled,
    on_selected_remove,
  }) {
  return (
    <div className='d-flex'>
      <TextLink disable={!on_selected_remove_enabled || (selected_ids.length === 0)} onClick={() => on_selected_remove(selected_ids)}>Remove selected companies</TextLink>
    </div>
  )
}

const DISPLAY_NAME_FIELD = 'name'
const COMPANY_ID_FIELD = 'company_id'
const ORIGINAL_NAME_FIELD = 'original_name'
const SIZE_ACTIVE_FIELD = 'size_active'
const SIZE_FIELD = 'size'

const CompanyListSettings = ({user, list, on_rename_handler, on_sharing_change_handler, on_delete_handler, on_copy_handler, is_new_list_name_unique}) => {
  const api_ref = useGridApiRef()

  const [show_spinner, set_show_spinner] = useState(true)
  const [is_rename, set_is_rename] = useState(false)
  const [is_copy, set_is_copy] = useState(false)

  const [items_in_list, set_items_in_list] = useState(null)
  const [selected_item_ids, set_selected_item_ids] = useState([])
  const [in_edit, set_in_edit] = useState(null)
  const [to_delete, set_to_delete] = useState(false)
  const [to_change_share_status, set_to_change_share_status] = useState(false)

  const [should_show_search, set_should_show_search] = useState(false)
  const [should_fetch_items, set_should_fetch_items] = useState(true)

  const [fetch_error, set_fetch_error] = useState(null)
  const [update_error, set_update_error] = useState(null)
  const [copy_error, set_copy_error] = useState(null)

  const {company_list_id, list_name, is_shared} = list || {}

  const has_missing_orgs = _.some(items_in_list || [], item => item.is_missing )
  const max_companies_in_list = get_max_companies_allowed_in_list(is_aistemos(user))

  useEffect(() => {
    if (!should_fetch_items) return
    fetch_list_companies_for_basket(company_list_id, true)
      .then(response => {
        const [orgs, missing_orgs] = response

        const items = [...orgs, ...missing_orgs.map(org => {return {...org, is_missing: true}})]

        set_items_in_list(items)
        set_selected_item_ids((missing_orgs || []).map(org => org.company_id))
        set_show_spinner(false)
        set_should_fetch_items(false)
      })
      .catch(err => {
        set_fetch_error(err)
        set_show_spinner(false)
       set_should_fetch_items(false)
      })
  }, [company_list_id, should_fetch_items])

  function is_new_name_valid(new_name) {
    if (new_name == null) return true
    return new_name.trim().length !== 0
  }

  function on_delete() {
    on_delete_handler(list)
  }

  function on_copy(copy_list_name) {
    set_show_spinner(true)
    set_is_copy(false)
    const companies = items_in_list.map(item => ({...item, organisation_id: item.id || null}))
    create_new_company_list(copy_list_name, companies, false)
      .catch(err => {
        set_show_spinner(false)
        set_copy_error(err)
        throw err
      })
      .then(response => {
        const {company_list_id} = response
        on_copy_handler({list_name: copy_list_name, company_list_id})
        set_show_spinner(false)
      })
  }

  function on_list_new_name(new_name) {
    if (!new_name || (new_name.trim().length === 0)) return

    if (new_name === list_name) {
      set_is_rename(false)
      return
    }

    set_show_spinner(true)

    return rename_list(company_list_id, new_name)
      .then(() => {
        set_is_rename(false)
        set_show_spinner(false)
        on_rename_handler(list, new_name)
      })
      .catch(err => {
        set_fetch_error(err)
        set_show_spinner(false)
      })
  }

  function on_list_sharing_status_change() {
    set_to_change_share_status(false)
    set_show_spinner(true)

    return share_or_unshare_list(company_list_id, !is_shared)
      .then(() => {
        set_show_spinner(false)
        on_sharing_change_handler(list, !is_shared)
      })
      .catch(err => {
        set_fetch_error(err)
        set_show_spinner(false)
      })
  }

  function can_add_more_orgs({orgs_to_add=[]}) {
    return items_in_list.length + orgs_to_add.length < max_companies_in_list + 1
  }

  function on_orgs_select(orgs) {
    const {add=[], remove=[]} = orgs

    if (add.length > 0) {
      on_add_selected(add)
    }

    if (remove.length > 0) {
      const org_ids_to_remove = remove.map(org => org.id)
      const company_ids_to_remove = items_in_list.filter(item => org_ids_to_remove.indexOf(item.org_id) > -1).map(item => item[COMPANY_ID_FIELD])

      on_remove_selected(company_ids_to_remove)
    }
  }

  function on_add_selected(orgs=[]) {
    set_show_spinner(true)

    track_report_builder_event(`action="add_orgs" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)

    Promise.all(orgs.map(org_to_add => extract_properties_for_saving_to_list(org_to_add)))
      .then((items_to_add) => {
        const companies = [...items_in_list.map(item => ({...item, organisation_id: item.id || null})), ...items_to_add.map(item => ({...item, original_name: item.name}))]
        return update_companies_in_list(company_list_id, companies)
      })
      .then(() => {
        set_should_fetch_items(true)
      })
      .catch((error) => {
        set_update_error(error)
        set_show_spinner(false)
      })
  }

  function on_remove_selected(selected_item_ids=[]) {
    track_report_builder_event(`action="remove_orgs" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)
    const filtered_orgs = items_in_list.filter(org => {
      const {company_id} = org
      return selected_item_ids.indexOf(company_id) === -1
    })

    set_show_spinner(true)
    delete_companies_from_list(company_list_id, selected_item_ids)
      .then(() => {
        set_items_in_list(filtered_orgs)
        set_selected_item_ids([])
        set_show_spinner(false)
      })
      .catch((error) => {
        set_update_error(error)
        set_selected_item_ids([])
        set_show_spinner(false)
      })
  }

  function on_display_name_update(params, with_enter_key) {
    const { id:company_id, field, value } = params

    let next_to_edit = null

    if (with_enter_key) {
      const sorted_company_ids = api_ref.current.getSortedRowIds()
      const current_row_idx = sorted_company_ids.indexOf(company_id)
      if (current_row_idx < sorted_company_ids.length - 1) {
       next_to_edit = sorted_company_ids[current_row_idx + 1]
      }
    }

    if ((value == null) || !is_new_name_valid(value)) {
      set_in_edit(next_to_edit)
      return
    }

    const orgs_in_list_by_company_id = get_as_map(items_in_list, COMPANY_ID_FIELD)

    const org = orgs_in_list_by_company_id[company_id] || {}
    const { name } = org

    if ((field !== DISPLAY_NAME_FIELD) || (name === value)) {
      return
    }
    
    set_in_edit(null)
    set_show_spinner(true)

    const items_copy = [...items_in_list]

    const updated_items = items_in_list.map(item => {
      return (item[COMPANY_ID_FIELD] === company_id) ? {...item, name: value} : item
    })
    set_items_in_list(updated_items)

    update_companies_fields(company_list_id, [{company_id, display_name: value}])
      .then(() => {
        set_show_spinner(false)
        set_in_edit(next_to_edit)
      })
      .catch((error) => {
        set_show_spinner(false)
        set_items_in_list(items_copy)
        set_update_error(error)
      })
  }

  function set_display_name_in_edit_mode(id) {
    on_cell_edit_start(id)
  }

  function on_cell_edit_start(id) {
    set_in_edit(id)
  }

  function get_display_name_cell(params) {
    const {row, id} = params
    const {name, is_missing} = row

    const is_edit_enabled = !(to_delete || to_change_share_status) && (in_edit == null) && !is_missing
    const is_in_edit = (id === in_edit)

    if (!is_in_edit) {
      return (
        <div className={s.display_name_wrapper}>
          <span>{name}</span>
          {is_edit_enabled &&
            <TextLink
              title='Change name'
              onClick={() => set_display_name_in_edit_mode(id)}
              className={cn('px-1', s.edit_display_name_icon)}
            >
              <EditIcon/>
            </TextLink>
          }
        </div>
      )
    }

    return (
      <EditableTextLink
        is_edit={true}
        link_text={name}
        on_confirm={(new_name, with_enter_key) => on_display_name_update({id, field: DISPLAY_NAME_FIELD, value: new_name}, with_enter_key)}
        on_cancel={() => set_display_name_in_edit_mode(null)}
        is_valid={(is_new_name_valid)}
      />
    )
  }

  function get_missing_cell(params) {
    const {row} = params
    const {is_missing} = row

    if (!is_missing) return null

    return <TextLink element={'div'} title={'Missing organisation'} className='w-100 text-center' not_clickable={true}><ExclamationIcon /></TextLink>
  }

  function handle_on_delete() {
    set_to_delete(true)
    if (in_edit != null) {
      set_in_edit(null)
    }
  }

  function on_set_selected(selected_ids) {
    if (is_delete_or_change_status) return

    set_selected_item_ids(selected_ids)
  }

  function handle_on_export() {
    const FIELDS = [ORIGINAL_NAME_FIELD, DISPLAY_NAME_FIELD, SIZE_ACTIVE_FIELD, SIZE_FIELD]

    const field_to_column = get_as_map(columns, 'field')

    const headings = FIELDS.map(field => field_to_column[field].headerName || '')

    const items = items_in_list.map(item => {
      return FIELDS.map(field => item[field] || '')
    })

    const csv = get_csv_string([headings, ...items])
    const filename = get_clean_filename(`${list_name} list`)

    trigger_download(csv, MIMETYPE_CSV, `${filename}${CSV_FILE_EXT}`)
  }

  const columns = [
    ...(!has_missing_orgs ? [] : [
      {
        field: 'is_missing',
        headerName: '',
        headerClassName: cs.no_outline,
        width: 20,
        renderCell: get_missing_cell,
        disableReorder: true
      }
    ]),
    {
      field: ORIGINAL_NAME_FIELD,
      headerName: 'Name',
      headerClassName: cs.no_outline,
      flex: 1,
      disableReorder: true
    },
    {
      field: DISPLAY_NAME_FIELD,
      headerName: 'Display name',
      headerClassName: cs.no_outline,
      minWidth: 150,
      flex: 1,
      editable: false,
      cellClassName: 'position-relative',
      renderCell: get_display_name_cell,
      disableReorder: true
    },
    {
      field: SIZE_ACTIVE_FIELD,
      headerName: 'Active size',
      headerClassName: cs.no_outline,
      type: 'number',
      flex: 1,
      renderCell: (params) => format_integer_with_comma(params.row.size_active),
      disableReorder: true
    },
    {
      field: SIZE_FIELD,
      headerName: 'Total size',
      headerClassName: cs.no_outline,
      type: 'number',
      flex: 1,
      renderCell: (params) => format_integer_with_comma(params.row.size),
      disableReorder: true
    }
  ]

  const is_delete_or_change_status = to_delete || to_change_share_status

  const items_count = (items_in_list || []).length
  const is_list_empty = items_count === 0

  const share_link_label = is_shared ? 'Stop sharing' : 'Share'
  return (
    <div className='w-100 position-relative'>

      <div className='d-flex'>
        <TextLink onClick={() => set_is_rename(true)} className='my-auto' disable={is_delete_or_change_status} title='Rename'>Rename</TextLink>
        <TextLink onClick={() => {set_should_show_search(true)}} className='ml-3 my-auto' disable={is_delete_or_change_status || (items_count  === max_companies_in_list) }>Add companies</TextLink>
        <TextLink onClick={() => set_to_change_share_status(true)} className='ml-3 my-auto' disable={is_delete_or_change_status || is_list_empty} title={share_link_label}>{share_link_label}</TextLink>
        <TextLink onClick={() => set_is_copy(true)} className='ml-3 my-auto' disable={is_delete_or_change_status || is_list_empty} title='Copy'>Copy</TextLink>
        <TextLink onClick={handle_on_export} className='ml-3 my-auto' disable={is_list_empty}>Export as CSV</TextLink>
        <TextLink onClick={handle_on_delete} className='ml-3 my-auto' disable={is_delete_or_change_status} title='Delete'>Delete</TextLink>
      </div>

      <div>
        <div className={s.spinner_wrapper}>
          {show_spinner &&
            <div><Spinner size='sm'/></div>
          }
          {to_delete &&
            <div className='d-flex justify-content-center my-auto'>
              <div className={cs.font_weight_bold}>Are you sure you want to delete this list?</div>
              <PrimaryButton size='xs' className='ml-2' onClick={() => on_delete()}>Delete list</PrimaryButton>
              <PrimaryButton size='xs' className='ml-2' outline onClick={() => set_to_delete(false)}>Cancel</PrimaryButton>
            </div>
          }
          {to_change_share_status &&
            <div className='d-flex justify-content-center my-auto'>
              <div><span className={cs.font_weight_bold}>Are you sure you want to {`${is_shared ? 'stop sharing' : 'share'} this list`}? </span><InfoPopover interactive={true} placement='bottom'>{SHARED_LIST_INFO}</InfoPopover></div>
              <PrimaryButton size='xs' className='ml-2' onClick={() => on_list_sharing_status_change()}>{`${is_shared ? 'Stop sharing' : 'Share'}`} list</PrimaryButton>
              <PrimaryButton size='xs' className='ml-2' outline onClick={() => set_to_change_share_status(false)}>Cancel</PrimaryButton>
            </div>
          }
        </div>

        {fetch_error &&
          <ErrorBody
            error={fetch_error}
            context={'fetching organisation in the list'}
          />
        }

        {update_error &&
          <ErrorModal
            error={update_error}
            context={'updating organisations in the list'}
            on_hide={() => set_update_error(null)}
          />
        }

        {copy_error &&
          <ErrorModal
            error={copy_error}
            context={'making list copy'}
            on_hide={() => set_copy_error(null)}
          />
        }

        {items_in_list && (items_in_list.length > 0) &&
          <DataGridPro
            apiRef={api_ref}
            rows={items_in_list}
            columns={columns}

            selectionModel={selected_item_ids}
            onSelectionModelChange={on_set_selected}
            getRowId={row => row.company_id}

            rowHeight={30}
            hideFooterSelectedRowCount={true}
            checkboxSelection={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            disableExtendRowFullWidth={true}
            autoHeight
            components={{
              Toolbar: get_toolbar,
              Footer: get_footer
            }}

            componentsProps={{
              toolbar: {
                selected_ids: selected_item_ids,
                on_selected_remove_enabled: !to_delete,
                on_selected_remove: on_remove_selected,
              },
            }}

            classes={{root: s.grid, cell: s.cell, 'cell--editing': s.cell__editing}}
          />
        }

        {items_in_list && items_count === 0 &&
          <div className='my-3 text-center'>There are no companies in this list</div>
        }

      </div>

      {is_rename &&
        <ListNameModal
           initial_text={list_name}
           on_submit={on_list_new_name}
           submit_label='Rename'
           on_cancel={() => set_is_rename(false)}
           is_name_unique={(name) => is_new_list_name_unique(name, [list])}
           title={'Rename list'}
        />
      }

      {is_copy &&
        <ListNameModal
           initial_text={list_name + ' (copy)'}
           on_submit={on_copy}
           submit_label='Create list'
           on_cancel={() => set_is_copy(false)}
           is_name_unique={is_new_list_name_unique}
           title={`Make a copy of "${list_name}"`}
        />
      }

      {should_show_search &&
        <ScrollModal
          is_open={true}
          on_hide={() => set_should_show_search(false)}
          title={(<div className='d-flex'>
            <span>Add companies to &quot;{list_name}&quot; ({(items_in_list || []).length}/{max_companies_in_list})</span>
            {show_spinner &&
              <div className='ml-1 my-auto d-flex'><Spinner size='sm'/></div>
            }
          </div>)}
        >
          <OrgSearchMultiple
            portfolio_basket={(items_in_list || []).map(item => get_org_as_portfolio_item(item))}
            update_basket_handler={on_orgs_select}
            blockClassName='h-100 position-relative'

            enable_remove_action={true}
            enable_ignore_action={false}
            can_add_orgs_handler={can_add_more_orgs}
            heroClassName={s.org_search_pane}
            inputContainerClassName={s.org_search_input_container}
            inputClassName={s.org_search_input}
          />
        </ScrollModal>
      }

    </div>
  )
}

export default withUser(CompanyListSettings)