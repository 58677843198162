export const CLASSIFIER     = 'classifier' // base classifier path
export const LIBRARY        = 'library'
export const TAXONOMIES     = 'taxonomies'
export const ADMIN          = 'admin'
export const USAGE          = 'usage'

export const SCOPE          = 'scope'
export const BOOLEAN_SEARCH = 'boolean-search'
export const SUGGESTIONS    = 'suggestions'
export const UPLOAD         = 'upload'
export const EVALUATE       = 'evaluate'
export const TRAINING_SET   = 'training-set'
export const METRICS        = 'metrics'
export const HISTORY        = 'history'
export const KNN_SEARCH     = 'similar-families-search'

export const DETAILS        = 'details' // i.e. /<boolean_search>/details

export const KNN_SEARCH_LINK_LABEL = 'similar families'